
    <app-yologram-social *ngIf="logInFlag"></app-yologram-social>
    <app-public *ngIf="!logInFlag"></app-public>
    <div (scroll)="scrollHandler()" #garmentsnapShotFeeds class="roksan">
        <div class="garment-page">
            <div class="contain-fluid col-lg-12  page-scroll" *ngIf="pageScroll > 20 ||  windowScroll > 47">
                <div class="band-bg d-block">
                    <div class="band-col">
                        <span class="d-flex justify-content-center">
                            <div class="sticky-band col-lg-12">
                                <div class="text-center p-1 band-logo-icon ">
                                    <img (click)="openBrandPoup()" class="profileImg-brand"
                                        style="width:35px; height:35px !important; margin-left:-7px"
                                        [src]="getBrandImage(brandName.LogoURL)">
                                </div>
                                <div class="text-center band-name-icon  outer  p-1 d-flex col-sm-10">
                                    <h6 class="band d-flex"><span *ngIf="clothDetailsList.RealType == '0'"
                                            class="btn-garment-band py-1"><img
                                                style="width:18px !important; color:black ;padding-bottom: 3px;     opacity: 0.6;"
                                                src="./assets/realtype/headgear.png"></span>
                                        <span *ngIf="clothDetailsList.RealType == '1'"
                                            class="btn-garment-band py-1"><img
                                                style="width:18px !important; color:black ;padding-bottom: 0px;     opacity: 0.6;"
                                                src="./assets/realtype/upperwear.png"></span><span
                                            *ngIf="clothDetailsList.RealType == '2'" class="btn-garment-band py-1"><img
                                                style="width:18px !important; color:black ;padding-bottom: 0px;     opacity: 0.6;"
                                                src="./assets/realtype/bottomwear.png"></span><span
                                            *ngIf="clothDetailsList.RealType == '3'" class="btn-garment-band py-1"><img
                                                style="width:18px !important; color:black ;padding-bottom: 0px;     opacity: 0.6;"
                                                src="./assets/realtype/footwear.png"></span><span
                                            *ngIf="clothDetailsList.RealType == '4'" class="btn-garment-band py-1"><img
                                                style="width:18px !important; color:black ;padding-bottom: 0px;     opacity: 0.6;"
                                                src="./assets/realtype/heeledfootwear.png"></span><span
                                            *ngIf="clothDetailsList.RealType == '5'" class="btn-garment-band py-1"><img
                                                style="width:18px !important; color:black ;padding-bottom: 0px;     opacity: 0.6;"
                                                src="./assets/realtype/fullbody.png"></span>
                                        <div
                                            [ngClass]="clothDetailsList.Name.length   >  25    ? 'notLess-floating'  : 'less-floating' ">
                                            {{(clothDetailsList.Name)}} </div>
                                        <div class="text-end band-3d-icon p-1 d-flex col-sm-1">
                                            <img class="profileInfo col-sm-2"
                                                *ngIf=" (selectedTab == 'story' || selectedTab =='Menswear')  && !toggaleFlag "
                                                (click)="toggale3D()"
                                                style="width:25px; height:25px !important; margin-left: 10px;     opacity: 0.6;"
                                                src="./assets/realtype/3d-paranoma.png">

                                            <img class="profileInfo col-sm-2"
                                                *ngIf="toggaleFlag  && (selectedTab =='Menswears')"
                                                (click)="toggaleInof()" style="width: 25px !important; height:25px !important; margin-left: 10px;
                                        color: black;     opacity: 0.6;
                                       " src="./assets/realtype/info.png">
                                        </div>
                                    </h6>
                                </div>
                            </div>
                        </span>
                        <span class="d-flex justify-content-center mb-1  scroll-folting-menu"
                            *ngIf="pageScroll > 10 ||  windowScroll > 0">
                            <div class="sticky-band-five-icon col-4 text-center d-flex justify-content-center">
                                <h6 class="text-primary brandbtn m-1 py-2 d-block class" (click)="getStyle()">
                                    <div *ngIf="(selectedTab =='Menswear' || selectedTab =='Menswears')  && !iconselctFlag"
                                        [ngClass]="selectedTab == 'Menswears' || selectedTab =='Menswear'    ? 'story-menu-card'  : 'story-menu-icon'">
                                        <img style="width: 15px;" src="./assets/storystyle/story-white.png">
                                    </div>
                                    <div *ngIf="(selectedTab == 'story')  && !iconselctFlag"
                                        [ngClass]="selectedTab == 'story'    ? 'story-menu-card-main'  : 'story-menu-icon'">
                                        <img style="width: 15px;" src="./assets/storystyle/story-white.png">
                                    </div>
                                    <img *ngIf="iconselctFlag  && (selectedTab == 'story' || selectedTab =='Menswear' || selectedTab =='Menswears')"
                                        class="story-menu-icon" style="color:black ;margin-top: -12px;"
                                        src="./assets/storystyle/storyblack.png">
                                    <h6 *ngIf="!iconselctFlag" class="selected-name m-1">Stories</h6>
                                </h6>
                                <h6 (click)="selectIcon('detail')" *ngIf="clothDetailsList.ThumbCenterURL  != undefined"
                                    class="m-1 brandbtn">
                                    <div *ngIf="selectedIcon == 'detail'"
                                        [ngClass]="selectedIcon == 'detail' ? 'story-link-menu' : 'story-menu-icon'">
                                        <a href="{{clothDetailsList.ThumbCenterURL}}" target="_blank">
                                            <img style="width: 15px;" src="./assets/details.png"></a>
                                    </div>
                                    <a href="{{clothDetailsList.ThumbCenterURL}}" target="_blank"><img
                                            *ngIf="selectedIcon != 'detail'"
                                            [ngClass]="selectedIcon != 'detail' ? 'story-menu-icon' : ''"
                                            style="color:black ;padding-bottom: 5px;"
                                            src="./assets/storystyle/newStoryIcon/detailGray.png"></a>
                                    <h6 *ngIf="selectedIcon == 'detail'" class="selected-name m-1">Details</h6>
                                </h6>
                                <h6 *ngIf="clothDetailsList.ThumbCenterURL  == undefined" class="m-1 brandbtn"><a><img
                                            style="width:15px !important; color:black"
                                            src="./assets/storystyle/newStoryIcon/detailGrayLight.png"></a>
                                </h6>
                                <h6 (click)="selectIcon('gallary')" *ngIf="clothDetailsList.ThumbLeftURL  != undefined"
                                    class="m-1 brandbtn">
                                    <div *ngIf="selectedIcon == 'gallary'"
                                        [ngClass]="selectedIcon == 'gallary' ? 'story-link-menu' : 'story-menu-icon'">
                                        <a href="{{clothDetailsList.ThumbLeftURL}}" target="_blank">
                                            <img style="width: 15px;" src="./assets/gallary.png"></a>
                                    </div>
                                    <a href="{{clothDetailsList.ThumbLeftURL}}" target="_blank"><img
                                            *ngIf="selectedIcon != 'gallary'"
                                            [ngClass]="selectedIcon != 'gallary' ? 'story-menu-icon' : ''"
                                            style="color:black ;padding-bottom: 5px;"
                                            src="./assets/storystyle/newStoryIcon/gallaryGray.png"></a>
                                    <h6 *ngIf="selectedIcon == 'gallary'" class="selected-name m-1">Gallery</h6>
                                </h6>
                                <h6 *ngIf="clothDetailsList.ThumbLeftURL  == undefined" class="m-1 brandbtn"><a><img
                                            style="width:15px !important"
                                            src="./assets/storystyle/newStoryIcon/gallaryGrayLight.png"></a>
                                </h6>
                                <h6 (click)="selectIcon('cart')" *ngIf="clothDetailsList.ThumbRightURL  != undefined"
                                    class="m-1 brandbtn">
                                    <div *ngIf="selectedIcon == 'cart'"
                                        [ngClass]="selectedIcon == 'cart' ? 'story-link-menu' : 'story-menu-icon'">
                                        <a href="{{clothDetailsList.ThumbRightURL}}" target="_blank">
                                            <img style="width: 15px;" src="./assets/shop.png"></a>
                                    </div>
                                    <a href="{{clothDetailsList.ThumbRightURL}}" target="_blank"><img
                                            *ngIf="selectedIcon != 'cart'"
                                            [ngClass]="selectedIcon != 'cart' ? 'story-menu-icon' : ''"
                                            style="color:black ;padding-bottom: 5px;"
                                            src="./assets/storystyle/newStoryIcon/cartGray.png"></a>
                                    <h6 *ngIf="selectedIcon == 'cart'" class="selected-name m-1">Shopping</h6>
                                </h6>
                                <h6 *ngIf="clothDetailsList.ThumbRightURL  == undefined" class="m-1 brandbtn"><a><img
                                            style="width:15px !important; color:black"
                                            src="./assets/storystyle/newStoryIcon/cartGrayLight.png"></a>
                                </h6>
                            </div>
                        </span>
                    </div>
                </div>
            </div>
            <div class="dt-responsive selectlist font-14 
            justify-content-center scroll text-center p-0 m-0" *ngIf="clothDetailsList != undefined">
                <div class="p-1">
                    <div class="container garment-alingn">
                        <div class="col-lg-12 justify-content-center garmentName">
                            <div class="contain-fluid m-1 ">
                                <div class="col-lg-12 text-center pt-4px w-100  d-flex justify-content-center  d-flex">
                                    <div class="text-center   d-flex col-2">
                                        <img *ngIf="brandName.LogoURL != undefined" (click)="openBrandPoup()"
                                            class="profileImg-brand" style="width:35px; height:35px !important;"
                                            [src]="getBrandImage(brandName.LogoURL)">
                                    </div>
                                    <div class="m-0 col-8 ">
                                        <div class="container  d-block col-12 ">
                                            <h6 class="color-class brand-font-size m-1" (click)="openBrandPoup()">
                                                {{brandName.BrandName}}</h6>
                                            <div class=" col-12 text-center details "
                                                *ngIf="clothDetailsList != undefined">
                                                <b>
                                                    <h6 class="color-class"> {{clothDetailsList.Gender == 'F' ?
                                                        'Womenswear'
                                                        :
                                                        (clothDetailsList.Gender == 'M') ? 'Menswear' : 'N/A'}}</h6>
                                                </b>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="m-0 col-2 text-end">
                                        <span><img *ngIf="heartFlag == true" class="heart"
                                                style="width:18px !important; color:black"
                                                src="./assets/storystyle/newStoryIcon/heart.png"></span>
                                        <span (click)="clickOnHeart()"><img *ngIf="heartFlag == false" class="heart"
                                                style="width:18px !important; color:black"
                                                src="./assets/storystyle/newStoryIcon/heartGray.png">
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="blank" *ngIf="pageScroll > 30"></div>
                        <div class="static-band"
                            *ngIf=" windowScroll < 0  || pageScroll == undefined ||  pageScroll < 30    ">
                            <div class="col-lg-12 justify-content-center garmentName-2">
                                <div class="contain-fluid m-1 ptst ">
                                    <div class="col-lg-12 text-center w-100  d-flex justify-content-center  d-flex">
                                        <div class="text-start   d-flex col-2">
                                            <span *ngIf="clothDetailsList.RealType == '0'" class="brandbtn py-1"><img
                                                    style="width:18px !important; color:black ;     padding-bottom: 10px;     opacity: 0.6;"
                                                    src="./assets/realtype/headgear.png"></span>
                                            <span *ngIf="clothDetailsList.RealType == '1'" class="brandbtn py-1 "><img
                                                    style="width:18px !important; color:black ;
                                                   padding-bottom: 10px;     opacity: 0.6;"
                                                    src="./assets/realtype/upperwear.png"></span><span
                                                *ngIf="clothDetailsList.RealType == '2'" class="brandbtn py-1"><img
                                                    style="width:18px !important; color:black  ;    opacity: 0.6;
                                                   padding-bottom: 10px;"
                                                    src="./assets/realtype/bottomwear.png"></span><span
                                                *ngIf="clothDetailsList.RealType == '3'" class="brandbtn py-1"><img
                                                    style="width:18px !important; color:black ;     opacity: 0.6;
                                                   padding-bottom: 10px;"
                                                    src="./assets/realtype/footwear.png"></span><span
                                                *ngIf="clothDetailsList.RealType == '4'" class="brandbtn py-1"><img
                                                    style="width:18px !important; color:black ;    opacity: 0.6;
                                                    padding-bottom: 10px;"
                                                    src="./assets/realtype/heeledfootwear.png"></span><span
                                                *ngIf="clothDetailsList.RealType == '5'" class="brandbtn py-1"><img
                                                    style="width:18px !important; color:black ;    opacity: 0.6;
                                                   padding-bottom: 10px;     opacity: 0.6;"
                                                    src="./assets/realtype/fullbody.png"></span>
                                        </div>
                                        <div class="m-0 col-8 ">
                                            <div class="container  d-block col-12">
                                                <h6 class="color-class-band"
                                                    [ngClass]="clothDetailsList.Name.length   >  25    ? 'color-class-band'  : 'less' ">
                                                    {{(clothDetailsList.Name)}}</h6>
                                            </div>
                                        </div>
                                        <div class="m-0 col-2 text-end">
                                            <img class="profileInfo col-sm-2"
                                                *ngIf=" (selectedTab == 'story' || selectedTab =='Menswear')  && !toggaleFlag "
                                                (click)="toggale3D()" style="width: 25px !important;
                                        color: black;
                                        margin-left: 5px;
                                        opacity: 0.6;" src="./assets/realtype/3d-paranoma.png">

                                            <img class="profileInfo col-sm-2"
                                                *ngIf="toggaleFlag  && (selectedTab =='Menswears')"
                                                (click)="toggaleInof()" style="    width: 25px !important;
                                       height: 25px !important;
                                       color: black;
                                       margin-left: 10px;
                                       margin-top: -2px;
                                       opacity: 0.6;
                                       " src="./assets/realtype/info.png">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div class="static-band-five-icon col-4 text-center d-flex justify-content-center">
                                    <h6 class="text-primary brandbtn m-1 py-2 d-block class" (click)="getStyle()">
                                        <div *ngIf="(selectedTab == 'Menswears' || selectedTab =='Menswear')  && !iconselctFlag"
                                            [ngClass]="selectedTab == 'Menswears' || selectedTab =='Menswear'    ? 'story-menu-card'  : 'story-menu-icon'">
                                            <img style="width: 15px;" src="./assets/storystyle/story-white.png">
                                        </div>
                                        <div *ngIf="(selectedTab == 'story')  && !iconselctFlag"
                                            [ngClass]="selectedTab == 'story'    ? 'story-menu-card-main'  : 'story-menu-icon'">
                                            <img style="width: 15px;" src="./assets/storystyle/story-white.png">
                                        </div>
                                        <img *ngIf="iconselctFlag  && (selectedTab == 'story' || selectedTab =='Menswear' || selectedTab =='Menswears')"
                                            class="story-menu-icon" style="color:black ;margin-top: -12px;"
                                            src="./assets/storystyle/storyblack.png">
                                        <h6 *ngIf="(selectedTab == 'story' || selectedTab =='Menswear' || selectedTab =='Menswears') && !iconselctFlag"
                                            class="selected-name m-1">Stories</h6>
                                    </h6>
                                    <h6 (click)="selectIcon('detail')"
                                        *ngIf="clothDetailsList.ThumbCenterURL  != undefined" class="m-1 brandbtn">
                                        <div *ngIf="selectedIcon == 'detail'"
                                            [ngClass]="selectedIcon == 'detail' ? 'story-link-menu' : 'story-menu-icon'">
                                            <a href="{{clothDetailsList.ThumbCenterURL}}" target="_blank">
                                                <img style="width: 15px;" src="./assets/details.png"></a>
                                        </div>
                                        <a href="{{clothDetailsList.ThumbCenterURL}}" target="_blank"><img
                                                *ngIf="selectedIcon != 'detail'"
                                                [ngClass]="selectedIcon != 'detail' ? 'story-menu-icon' : ''"
                                                style="color:black ;padding-bottom: 5px;"
                                                src="./assets/storystyle/newStoryIcon/detailGray.png"></a>
                                        <h6 *ngIf="selectedIcon == 'detail'" class="selected-name m-1">Details</h6>
                                    </h6>
                                    <h6 *ngIf="clothDetailsList.ThumbCenterURL  == undefined" class="m-1 brandbtn">
                                        <a><img style="width:15px !important; color:black"
                                                src="./assets/storystyle/newStoryIcon/detailGrayLight.png"></a>
                                    </h6>
                                    <h6 (click)="selectIcon('gallary')"
                                        *ngIf="clothDetailsList.ThumbLeftURL  != undefined" class="m-1 brandbtn">
                                        <div *ngIf="selectedIcon == 'gallary'"
                                            [ngClass]="selectedIcon == 'gallary' ? 'story-link-menu' : 'story-menu-icon'">
                                            <a href="{{clothDetailsList.ThumbLeftURL}}" target="_blank">
                                                <img style="width: 15px;" src="./assets/gallary.png"></a>
                                        </div>
                                        <a href="{{clothDetailsList.ThumbLeftURL}}" target="_blank"><img
                                                *ngIf="selectedIcon != 'gallary'"
                                                [ngClass]="selectedIcon != 'gallary' ? 'story-menu-icon' : ''"
                                                style="color:black ;padding-bottom: 5px;"
                                                src="./assets/storystyle/newStoryIcon/gallaryGray.png"></a>
                                        <h6 *ngIf="selectedIcon == 'gallary'" class="selected-name m-1">Gallery</h6>
                                    </h6>
                                    <h6 *ngIf="clothDetailsList.ThumbLeftURL  == undefined" class="m-1 brandbtn"><a><img
                                                style="width:15px !important"
                                                src="./assets/storystyle/newStoryIcon/gallaryGrayLight.png"></a>
                                    </h6>
                                    <h6 (click)="selectIcon('cart')"
                                        *ngIf="clothDetailsList.ThumbRightURL  != undefined" class="m-1 brandbtn">
                                        <div *ngIf="selectedIcon == 'cart'"
                                            [ngClass]="selectedIcon == 'cart' ? 'story-link-menu' : 'story-menu-icon'">
                                            <a href="{{clothDetailsList.ThumbRightURL}}" target="_blank">
                                                <img style="width: 15px;" src="./assets/shop.png"></a>
                                        </div>
                                        <a href="{{clothDetailsList.ThumbRightURL}}" target="_blank"><img
                                                *ngIf="selectedIcon != 'cart'"
                                                [ngClass]="selectedIcon != 'cart' ? 'story-menu-icon' : ''"
                                                style="color:black ;padding-bottom: 5px;"
                                                src="./assets/storystyle/newStoryIcon/cartGray.png"></a>
                                        <h6 *ngIf="selectedIcon == 'cart'" class="selected-name m-1">Shopping</h6>
                                    </h6>
                                    <h6 *ngIf="clothDetailsList.ThumbRightURL  == undefined" class="m-1 brandbtn">
                                        <a><img style="width:15px !important; color:black"
                                                src="./assets/storystyle/newStoryIcon/cartGrayLight.png"></a>
                                    </h6>
                                </div>
                            </div>
                        </div>
                        <div class="imagesDiv">
                            <div class="" *ngIf=" userFiledetails.length != 0 && this.selectedTab !=  'Menswears'">
                                <app-slider-two-d [images]="userFiledetails"></app-slider-two-d>
                            </div>
                            <div class="" *ngIf="userFiledetails.length == 0 && this.selectedTab !=  'Menswears'">
                                <app-slider-two-d [images]="images"></app-slider-two-d>
                            </div>
                            <div class="text-center d-flex upload-image-slider-css twoDImage"
                                *ngIf="this.selectedTab !=  'Menswears' &&  userFiledetails3d.length != 0 ">
                                <div (click)="openModel(fileList.fileurl)"
                                    *ngFor="let fileList of userFiledetails3d;let p=index" class="">
                                    <model-viewer *ngIf="fileList.fileType == 'GarmentUpload3D'" camera-controls
                                        camera-orbit="45deg 55deg 2.5m" ar auto-rotate src={{fileList.fileurl}}
                                        alt="A 3D avatar-model-view" data-js-focus-visible="" ar-status="not-presenting"
                                        style="height: 110px; width: 150px; float: none;">
                                    </model-viewer>
                                </div>
                            </div>
                            <div *ngIf=" this.selectedTab !=  'Menswears'"
                                [ngClass]="userFiledetails3d.length != 0   ? 'details collection-details-sku p-1' : 'collection-details-sku3d details'">
                                <h6 class="color-class "
                                    *ngIf="clothDetailsList.CollectionName != undefined && clothDetailsList.CollectionName != '' ">
                                    Collection: {{filterCollectionName(clothDetailsList.CollectionName)}}</h6>
                                <h6 class=" color-class "
                                    *ngIf="clothDetailsList.CollectionName == '' ||  clothDetailsList.CollectionName == null">
                                    Collection: None</h6>

                                <h6 class=" color-class"
                                    *ngIf="clothDetailsList.Details != undefined &&  userFiledetails3d.length != 0">
                                    {{(showMore) ? clothDetailsList.Details : clothDetailsList.Details | slice:0:120}}
                                    <span *ngIf="!showMore  &&  userFiledetails3d.length != 0 ">...</span>
                                    <a href="javascript:;" *ngIf="!showMore &&  userFiledetails3d.length != 0"
                                        (click)="showMore=true">Read More</a>
                                    <a href="javascript:;" *ngIf="showMore &&  userFiledetails3d.length != 0"
                                        (click)="showMore=false">Read less</a>
                                </h6>

                                <h6 class="color-class"
                                    *ngIf="clothDetailsList.Details != undefined  &&  userFiledetails3d.length == 0 ">
                                    {{(showMore) ? clothDetailsList.Details : clothDetailsList.Details| slice:0:400}}
                                    <span *ngIf="!showMore && clothDetailsList.Details.length < 400">...</span>
                                    <a href="javascript:;"
                                        *ngIf="!showMore && clothDetailsList.Details.length > 400  &&  userFiledetails3d.length == 0"
                                        (click)="showMore=true">Read More</a>
                                    <a href="javascript:;" *ngIf="showMore  &&  userFiledetails3d.length == 0 "
                                        (click)="showMore=false">Read less</a>
                                </h6>
                                <h6 class="color-class"
                                    *ngIf="clothDetailsList.Details == undefined  &&  userFiledetails3d.length < 0 ">
                                    {{(showMore) ? dummyText : dummyText | slice:0:150}} <span
                                        *ngIf="!showMore">...</span>
                                    <a href="javascript:;" *ngIf="!showMore &&  userFiledetails3d.length < 0 "
                                        (click)="showMore=true">Read More</a>
                                    <a href="javascript:;" *ngIf="showMore  && userFiledetails3d.length < 0 "
                                        (click)="showMore=false">Read less</a>
                                </h6>
                                <h6 class="color-class"
                                    *ngIf="clothDetailsList.Details == undefined &&  userFiledetails3d.length == 0">
                                    <!-- {{(showMore) ? dummyText : dummyText}}
                                <span *ngIf="!showMore">.</span> -->
                                    Your brand's apparel is digitised in 3D
                                    <br><br>
                                    On a gamified experience, consumers style avatars & share animated avatar stories
                                    (linked to ecommerce)
                                    <br><br>
                                    Defining a new paradigm in immersive storytelling for social media & the metaverse
                                </h6>
                                <h6 class="color-class"
                                    *ngIf="clothDetailsList.Details == undefined &&  userFiledetails3d.length > 0  && userFiledetails.length > 0">
                                    {{(showMore) ? dummyText : dummyText}} "
                                    <a href="javascript:;" *ngIf="!showMore &&  
                                clothDetailsList.Details == undefined &&  
                                userFiledetails3d.length > 0  && userFiledetails.length > 0 "
                                        (click)="showMore=true">Read More</a>
                                    <a href="javascript:;"
                                        *ngIf="showMore  &&  userFiledetails3d.length > 0  && userFiledetails.length > 0"
                                        (click)="showMore=false">Read less</a>
                                </h6>

                                <h6 class="color-class"
                                    *ngIf="clothDetailsList.Details == undefined &&  userFiledetails3d.length > 0 && userFiledetails.length == 0">
                                    {{(showMore) ? dummyText : dummyText}}<a href="javascript:;"
                                        *ngIf="!showMore && clothDetailsList.Details == undefined &&  userFiledetails3d.length > 0 && userFiledetails.length == 0"
                                        (click)="showMore=true">Read More</a>
                                    <a href="javascript:;" *ngIf="showMore " (click)="showMore=false">Read less</a>
                                </h6>
                                <h6 class="color-class">SKU: {{clothDetailsList.SKU}}</h6>
                            </div>
                        </div>
                    </div>

                    <!-- <div ><a >3D file</a>href -->

                    <div [ngClass]="packJsonObject != undefined  ? 'div-josn' : 'div-josn'">
                        <div class="row form d-block  target-set" id="target"
                            *ngIf="packJsonObject.length > 0 && selectedTab =='Menswears'">
                            <app-twod-image [garmentImg]="this.zipList[this.stroyIdAll]" [garmentType]="this.clothType"
                                [pageType]="style"></app-twod-image>
                        </div>
                        <div class="d-flex justify-content-center" *ngIf="isloading">
                            <div class="spinner-border" role="status">
                                <span class=""></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="div-toggale">
                    <i role="button" *ngIf="this.isPlus" (click)="toggleText()" class="bi bi-plus-circle toggale"></i>
                    <i role="button"  *ngIf="!this.isPlus" (click)="toggleText()" class="'bi bi-dash-circle toggale"></i>
                    <!-- <div class="threeJs-object"  > -->
                    <div class="threeJs-object" [ngClass]="!this.isPlus  ? '': 'd-none' ">
                        <app-new-three-d-file [inputBrandId]="this.selectedBrandId" [inputClothId]="this.clothId"
                            [inputclothType]="this.clothType"
                            [inputGender]="this.selectedGender"></app-new-three-d-file>
                    </div>
                </div>
                <div *ngIf="this.selectedTab ==  'Menswears'">
                    <div class="row scroll my-story-2 justify-content-center  "
                        *ngIf="  toggaleFlag != true && userSnapShotList != undefined && userSnapShotList.length >= 0">
                        <div class="column d-block " *ngFor="let snapshot of userSnapShotList; let i=index;">
                            <img class="columnImg d-block" (click)="scrollToStory(snapshot)"
                                [src]="snapshot.SnapshotFileUrl">
                        </div>
                        <div class="col-12 text-center stify-content-center mb-5">
                            <h6 (click)="nextButtonForGarment()"><b>More</b></h6>
                        </div>
                    </div>
                </div>
                <div *ngIf="selectedTab == 'story' && !isloading " class="text-start p-1 mb-5 user-story ">
                    <app-user-story [thresholdHeightPagination]="0" [userSnapShotsInput]="userSnapShotList"
                        [garmentId]="clothId" [key]="key" [StoryIds]="selectedStoryId" [PageCount]="pagecount"
                        [pageNumber]="pageNumber" (outPageNumber)="setPageNumber($event)"
                        [routLogInFlag]="routLogInFlag">
                    </app-user-story>
                </div>
                <div *ngIf="selectedTab != 'story'" [ngClass]="userSnapShotList != undefined  ? 'div-set' : ''">

                    <div class="col-12 div-center">
                        <div class="row w-100">
                            <div class="col-4 p-0" *ngFor="let snapshot of userSnapShotList  ; let i=index;">
                                <div class="d-flex align-items-center justify-content-center m-1">
                                    <img class="w-100 shadow-1-strong rounded m-1" (click)="scrollToStory(snapshot)"
                                        [src]="snapshot.SnapshotFileUrl">
                                </div>
                            </div>
                        </div>
                        <div class="col-12 text-center stify-content-center mb-5">
                            <h6 (click)="nextButtonForGarment()"><b>More</b></h6>
                        </div>
                    </div>



                    <!-- <div class="row scroll my-story justify-content-center  "
                    *ngIf="userSnapShotList != undefined && userSnapShotList.length >= 0  && selectedTab == 'Menswear'">
                    <div class="column d-block " *ngFor="let snapshot of userSnapShotList; let i=index;">
                        <img class="columnImg d-block" (click)="scrollToStory(snapshot)"
                            [src]="snapshot.SnapshotFileUrl">
                    </div>


                    
                    <div class="col-12 text-center stify-content-center mb-5">
                        <h6 (click)="nextButtonForGarment()"><b>More</b></h6>
                    </div>
                </div> -->
                </div>
            </div>
        </div>
    </div>
