<div class="header bg-white" *ngIf="showHeader">
  <nav class="nav nav-pills flex-column flex-sm-row ">
    <a class="flex-sm-fill text-sm-center nav-link"
      [ngClass]="router.url == '/drip' ? 'active' : ''" aria-current="page"
      (click)="router.navigate(['/drip'])"   ><img class="img-fluid" class="m-1" style="height: 19px; width: 17px !important;
            " src="./assets/upload-icon/film-roll.png">Drip</a>
    <a class="flex-sm-fill text-sm-center nav-link"
      [ngClass]="routFalg   ? 'active' : ''"
      (click)="router.navigate(['/threads'])"  ><i class="bi bi-6x  bi bi-bag-dash-fill
        text-secondary m-1"></i>Threads</a>

    <a class="flex-sm-fill text-sm-center nav-link" [ngClass]="router.url == '/fam' ? 'active' : ''"
    (click)="router.navigate(['/fam'])" > <img class="img-fluid" class="m-1" style="height: 19px; width: 17px !important;
      " src="./assets/upload-icon/trophy-gray.png">
      Fam</a>
    <a (click)="getSnapdata()" 
     class="flex-sm-fill text-sm-center nav-link" aria-disabled="true">
      <h6 class="menu-text">
        <img *ngIf="userDetails?.ProfilePicture != undefined" class="img-thumbnail rounded-circle"
          style="width: 40px !important; height:40px !important;" src={{userDetails?.ProfilePicture}}>
        <img *ngIf="userDetails?.ProfilePicture == undefined" class="img-thumbnail rounded-circle"
          style="width: 40px !important; height:40px !important;" src={{imgpath}} >
      </h6>
    </a>
  </nav>
</div>
<div class="footer row  fixed-bottom justify-content-center" *ngIf="showFooter" style="width: 106%;" >
  <div class="container-fluid row text-center b-menu d-flex w-100">
    <div class="col-3  d-block">
      <h5 class="menu-text"><a (click)="router.navigate(['/drip'])"
          *ngIf="router.url != '/drip'" (click)="clickOnIconHIden()">
          <img *ngIf="router.url != '/drip'"
            [ngClass]="router.url != '/drip' ? 'story-menu-icon' : ''" class="img-fluid" class="m-1"
            src="./assets/upload-icon/film-roll-white.png">
        </a>
      </h5>
      <h5 class="menu-text"><a  (click)="router.navigate(['/drip'])"
          *ngIf="router.url == '/drip'">
          <img *ngIf="router.url == '/drip' && !this.menuFlag"
            [ngClass]="router.url == '/drip' && !this.menuFlag ? 'story-menu' : 'story-menu-icon'"
            class="img-fluid" class="m-1" src="./assets/upload-icon/film-roll-black.png">
          <img *ngIf="router.url == '/drip' && this.menuFlag"
            [ngClass]="router.url == '/drip' && this.menuFlag ? 'story-menu-icon' : ''"
            class="img-fluid" class="m-1" src="./assets/upload-icon/film-roll-white.png"></a>
        <span *ngIf="router.url == '/drip' && !this.menuFlag"
          class="color-class text-white m-1">Drip</span>
      </h5>
    </div>
    <div class="col-3  d-block">
      <h5 class="menu-text"><a (click)="router.navigate(['/threads'])"
          *ngIf="!routFalg" (click)="clickOnIconHIden()">
          <img *ngIf="!routFalg"
            [ngClass]="!routFalg ? 'story-menu-icon' : ''" class="img-fluid"
            class="m-1" src="./assets/bottom-menu/style-white.png">
        </a>
      </h5>
      <h5 class="menu-text"><a (click)="router.navigate(['/threads'])"
          *ngIf="routFalg">
          <img *ngIf="routFalg && !this.menuFlag"
            [ngClass]="routFalg && !this.menuFlag ? 'story-menu' : 'story-menu-icon'"
            class="img-fluid" class="m-1" src="./assets/menu-icon/style-black.png">
          <img *ngIf="routFalg && this.menuFlag"
            [ngClass]="routFalg && this.menuFlag ? 'story-menu-icon' : ''"
            class="img-fluid" class="m-1" src="./assets/bottom-menu/style-white.png"></a>
        <span *ngIf="routFalg && !this.menuFlag"
          class="color-class text-white  m-1">Threads</span>
      </h5>
    </div>
    <div class="col-3  d-block">
      <h5 class="menu-text"><a  (click)="router.navigate(['/fam'])" *ngIf="router.url != '/fam'"
          (click)="clickOnIconHIden()">
          <img *ngIf="router.url != '/fam'"
            [ngClass]="router.url != '/fam'? 'story-menu-icon' : ''" class="img-fluid" class="m-1"
            src="./assets/upload-icon/trophy-white.png">
        </a>
      </h5>
      <h5 class="menu-text"><a href="/fam" *ngIf="router.url == '/fam'">
          <img *ngIf="router.url == '/fam' && !this.menuFlag"
            [ngClass]="router.url == '/fam' && !this.menuFlag ? 'story-menu' : 'story-menu-icon'"
            class="img-fluid" class="m-1" src="./assets/upload-icon/trophy-black.png">
          <img *ngIf="router.url == '/fam' && this.menuFlag"
            [ngClass]="router.url == '/fam' && this.menuFlag ? 'story-menu-icon' : ''"
            class="img-fluid" class="m-1" src="./assets/upload-icon/trophy-white.png"></a>
        <span *ngIf="router.url == '/fam' && !this.menuFlag"
          class="color-class text-white m-1">Fam</span>
      </h5>
    </div>


    <div class="col-3  d-block" (click)="closePopUp()">
      <h5 class="menu-text" (click)="sliderRefresh()" data-bs-toggle="modal" data-bs-target="#exampleModalCenter">
        <img 
          *ngIf="!this.menuFlag && (userDetails?.ProfilePicture != undefined || userDetails?.ProfilePicture != '' || imgpath  != undefined )"
          [ngClass]="!this.menuFlag ? 'story-menu-icon' : ''" class="img-fluid" class="m-1"
          [src]="userDetails?.ProfilePicture != undefined ? userDetails.ProfilePicture :  (imgpath!= undefined ? imgpath :'./assets/profilepic.png')">
        <!-- <img (click)="getSnapdata()" *ngIf="!this.menuFlag  && imgpath == undefined" [ngClass]="!this.menuFlag ? 'story-menu-icon' : ''"class="img-fluid" class="m-1" src="./assets/profilepic.png"> -->
      </h5>
      <h5 class="menu-text" data-bs-toggle="modal" data-bs-target="#exampleModalCenter">
        <img
          *ngIf="this.menuFlag  && (userDetails?.ProfilePicture != undefined || userDetails?.ProfilePicture != '' || imgpath  != undefined )"
          [ngClass]="this.menuFlag ? 'story-menu' : 'story-menu-icon'" class="img-fluid" class="m-1"
          [src]="userDetails?.ProfilePicture != undefined ? userDetails?.ProfilePicture :  (imgpath!= undefined ? imgpath :'./assets/profilepic.png')">
        <!-- <img *ngIf="this.menuFlag  && imgpath == undefined" [ngClass]="this.menuFlag ? 'story-menu' : 'story-menu-icon'" class="img-fluid" class="m-1" src="./assets/profilepic.png"> -->
        <span *ngIf="this.menuFlag" [ngClass]="this.menuFlag ? 'text-white' : 'd-none'" (click)="closePopupByclick()"
          closePopupByclick>Share</span>
      </h5>
    </div>
  </div>
</div>

<!-- Modal -->
<div class="modal hide fade text-center imp-bg" id="exampleModalCenter" tabindex="-1" #myModal
  aria-labelledby="exampleModalCenterLabel" aria-hidden="true" data-bs-backdrop="false">
  <div class="modal-dialog content ">
    <div class="modal-content  imp-block">
      <button type="button" (click)="closePopupByclick()" class="btn-close btan-class btn-close-white"data-bs-dismiss="modal" aria-label="Close"></button>
      <div class="">
        
        <div class="d-flex mar-navbar justify-content-center" [ngClass]=" pageAll  == '' ? 'blank-grp' : ''">
          <div class="text-white p-1">
            <img style="width: 50px !important; height:50px !important; padding: 5px;"
              src="./assets/YLogoTransparent.png" alt="Logo" />
          </div>
          <div>
            <h6 class="text-white p-1 grp-name"
              *ngIf="this.userSecurityGrp.length > 0 && getSecurityGroupNameById(selectedSecGrpId)"
              (click)="router.navigate(['/security-group-change',{'id':id}])" data-bs-dismiss="modal">
              {{selectedSecurityGroupName}}</h6>
          </div>
          <div class="container-fluid navbar navbar-light light-blue lighten-4 m-1 nav-width " *ngIf="pageAll  != '' ">
            <button class="navbar-toggler menu-btn" type="button" data-bs-toggle="collapse"
              data-bs-target="#navbarsExample09" aria-controls="navbarsExample09" aria-expanded="false"
              aria-label="Toggle navigation">
              <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse  navbar-collapse justify-content-md-left " #yologramnavbarProfile id="navbarsExample09">
              <ul class="navbar-nav nav-div" (click)="hideNavigtationProfile()">
                <div class="" *ngFor="let pages of pageAll">
                  <li class="nav-item text-start text-primary nav-item-width" (click)="closePopUp()">
                   <a data-bs-dismiss="modal" (click)="router.navigate([pages.PageAddress])"  class="nav-link px-2 text-white">{{pages.PageName}}</a>
                   <!-- <a  href="{{pages.PageAddress}}" class="nav-link px-2 text-white">{{pages.PageName}}</a> -->
                  </li>
                </div>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="text-center text-white popup-containt">
        <div class="popup-containt m-1">
          <div class="w-100 col-12 d-block band"
            [ngClass]="this.filterSnapShotForProfile != undefined  ? 'band-bottom' : ''">
            <div data-bs-dismiss="modal" class="static-band text-center col-12 justify-content-center m-1">
              <div class="d-flex m-1 profile-popup text-white" data-bs-dismiss="modal" (click)="getProfileForUSer()">
                <img *ngIf="userDetails?.ProfilePicture != undefined" class="img-thumbnail rounded-circle" style="
                width: 60px !important;
                height: 60px !important;
                margin-top: -53px;
            " src={{userDetails?.ProfilePicture}}>
                <img *ngIf="userDetails?.ProfilePicture == undefined" class="img-thumbnail rounded-circle" style="
                width: 60px !important;
                height: 60px !important;
                margin-top: -53px;
            " src={{imgpath}}>
              </div>
              <h6 *ngIf="userDetails?.ProfilePicture != undefined" (click)="getProfileForUSer()" data-bs-dismiss="modal"
                class="m-1 text-start text-black profile-name ">{{userDetails.DisplayName}}
              </h6>
              <h6 *ngIf="userDetails?.ProfilePicture == undefined" (click)="getProfileForUSer()" data-bs-dismiss="modal"
                class="m-1 text-start text-black profile-name ">{{user_name}}
              </h6>
              <div>
                <div class="static-band-five-icon col-4 text-center d-flex justify-content-center">
                  <div class="brandbtn">
                    <h6 class="m-1  story-menu-card" (click)="getProfileForUSer()" data-bs-dismiss="modal">
                      <a><img style="width:15px !important" src="  ./assets/storystyle/story-white.png">
                      </a>
                    </h6>
                    <h6 class="m-1 font-class">Story</h6>
                  </div>
                  <div class="brandbtn" data-bs-dismiss="modal"
                    (click)="router.navigate(['yologram-social/yologram-public-view-file',{'id':id}])">
                    <h6 class="m-1  story-menu-card"><a><img style="width:15px !important"
                          src="./assets/brand_white.png"></a>
                    </h6>
                    <h6 class="m-1 font-class">Brands </h6>
                  </div>
                  <div class="brandbtn">
                    <h6 class="m-1  story-menu-card" data-bs-dismiss="modal"
                      (click)="router.navigate(['edit-news',{'id':id}])"><a><img
                          style="width:15px !important" src="./assets/t_shirt_white.png"></a>
                    </h6>
                    <h6 class="m-1 font-class "> Style </h6>
                  </div>
                </div>
              </div>
            </div>
            <div  class="child" style="height: 147px !important; width: 100% !important;">
              <div>
              <div data-bs-dismiss="modal" class="m-1 " [ngClass]="filterSnapShotForProfile.length < 0 ? '' : 'popup-imfo'">
                <div (click)="getProfileForUSer()" *ngIf="userFiledetails.length == 0 && images != undefined ">
                  <app-slider-two-d [images]="images" style="all:unset !important"></app-slider-two-d>
                </div>
                <div (click)="getProfileForUSer()" *ngIf="userFiledetails !=  0">
                  <app-slider-two-d [images]="userFiledetails" [UserId]="id" style="all:unset !important"></app-slider-two-d>
                </div>
              </div>
            </div>
            </div>
          </div>
          <div class="">
            <div class="row w-100"
              *ngIf="filterSanp.length >= 0  && filterSanp != undefined && filterSanp.length >= 0">
              <div class="column d-block m-1" *ngFor="let snapshot of filterSanp  ; let i=index;">
                <img class="w-100 shadow-1-strong rounded" data-bs-dismiss="modal"
                  (click)="getProfileForUSerFeed(snapshot)" [src]="snapshot.SnapshotFileUrl">
              </div>
              <div class="columnImg d-block m-1" *ngIf="filterSanp.length > 2">
                <img class="w-80 shadow-1-strong rounded image-pop plus-image" (click)="getProfileForUSer()"
                  data-bs-dismiss="modal" src="./assets/plus-8-512.png">
              </div>
            </div>
          </div>
        </div>
        <button  *ngIf="filterSanp.length != 0 " type="button m-2 " data-bs-dismiss="modal" (click)="handleSignOut()"
          class="btn  btn-sm btn-primary btnFoPopUp text-white ">Logout</button>
          <button  *ngIf="filterSanp.length == 0" type="button m-2 " data-bs-dismiss="modal" (click)="handleSignOut()" style="margin-top: -2px;"
          class="btn  btn-sm btn-primary btnFoPopUp text-white ">Logout</button>
        <div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="color-primary">
  <router-outlet></router-outlet>
</div>