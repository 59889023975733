<div>
  <div class="header" *ngIf="showHeader">
    <nav class="nav nav-pills flex-column flex-sm-row ">
      <a class="flex-sm-fill text-sm-center nav-link " aria-current="page" href="/drip"
        [ngClass]="router.url == '/drip' ? 'active' : 'text-secondary'">
        <img class="img-fluid" class="m-1" style="height: 19px; width: 17px !important;
      " src="./assets/upload-icon/film-roll.png">Drip</a>

      <a class="flex-sm-fill text-sm-center nav-link" href="/threads"
        [ngClass]="routFalg ? 'active' : 'text-secondary'"><i
          class="bi bi-bag-dash-fill  text-secondary m-1"></i>Threads</a>

      <a class="flex-sm-fill text-sm-center nav-link" href="/fam"
        [ngClass]="router.url == '/fam' ? 'active' : 'text-secondary'">  <img class="img-fluid" class="m-1" style="height: 19px; width: 17px !important;
        " src="./assets/upload-icon/trophy-gray.png">Fam</a>
        
      <a (click)="signInPopup()" class="flex-sm-fill text-sm-center nav-link" aria-disabled="true"><i class="
      bi bi-6x bi-plus-square-fill  text-secondary m-1" (click)="signInPopup()"></i>Sign In</a>
    </nav>
  </div>
  <div class="footer row fixed-bottom justify-content-center  text-center" *ngIf="showFooter">
    <div class="container-fluid row text-center b-menu d-flex">
      <div class="col-3  d-block">
        <h5 class="menu-text"><a href="/drip" *ngIf="router.url != '/drip'" (click)="clickOnIconHIden()">
            <img *ngIf="router.url != '/drip'" [ngClass]="router.url != '/drip' ? 'story-menu-icon' : ''"
              class="img-fluid" class="m-1" src="./assets/upload-icon/film-roll-white.png">
          </a>
        </h5>
        <h5 class="menu-text"><a href="/drip" *ngIf="router.url == '/drip'">
            <img *ngIf="router.url == '/drip' && !this.menuFlag"
              [ngClass]="router.url == '/drip' && !this.menuFlag ? 'story-menu' : 'story-menu-icon'"
              class="img-fluid" class="m-1" src="./assets/upload-icon/film-roll-black.png">
            <img *ngIf="router.url == '/drip' && this.menuFlag"
              [ngClass]="router.url == '/drip' && this.menuFlag ? 'story-menu-icon' : ''" class="img-fluid"
              class="m-1" src="./assets/upload-icon/film-roll-white.png"></a>
          <span *ngIf="router.url == '/drip' && !this.menuFlag" class="color-class text-white m-1">Drip</span>
        </h5>
      </div>
      
      <div class="col-3  d-block">
        <h5 class="menu-text"><a href="/threads" *ngIf="!routFalg"
            (click)="clickOnIconHIden()">
            <img *ngIf="!routFalg"
              [ngClass]="!routFalg ? 'story-menu-icon' : ''" class="img-fluid" class="m-1"
              src="./assets/bottom-menu/style-white.png">
          </a>
        </h5>
        <h5 class="menu-text"><a href="/threads" *ngIf="routFalg">
            <img *ngIf="routFalg && !this.menuFlag"
              [ngClass]="routFalg && !this.menuFlag ? 'story-menu' : 'story-menu-icon'"
              class="img-fluid" class="m-1" src="./assets/menu-icon/style-black.png">
            <img *ngIf="routFalg && this.menuFlag"
              [ngClass]="routFalg && this.menuFlag ? 'story-menu-icon' : ''" class="img-fluid"
              class="m-1" src="./assets/bottom-menu/style-white.png"></a>
          <span *ngIf="routFalg && !this.menuFlag" class="color-class text-white  m-1">Threads</span>
        </h5>
      </div>


      <div class="col-3  d-block">

        <h5 class="menu-text"><a href="/fam" *ngIf="router.url != '/fam'"
            (click)="clickOnIconHIden()">
            <img *ngIf="router.url != '/fam'"
              [ngClass]="router.url != '/fam'? 'story-menu-icon' : ''" class="img-fluid" class="m-1"
              src="./assets/upload-icon/trophy-white.png">
          </a>
        </h5>
        <h5 class="menu-text"><a href="/fam" *ngIf="router.url == '/fam'">
            <img *ngIf="router.url == '/fam' && !this.menuFlag"
              [ngClass]="router.url == '/fam' && !this.menuFlag ? 'story-menu' : 'story-menu-icon'"
              class="img-fluid" class="m-1" src="./assets/upload-icon/trophy-black.png">
            <img *ngIf="router.url == '/fam' && this.menuFlag"
              [ngClass]="router.url == '/fam' && this.menuFlag ? 'story-menu-icon' : ''" class="img-fluid"
              class="m-1" src="./assets/upload-icon/trophy-white.png"></a>
          <span *ngIf="router.url == '/fam' && !this.menuFlag"
            class="color-class text-white m-1">Fam</span>
        </h5>

      </div>

      <div class="col-3  d-block">
        <h5 class="menu-text">
          <img (click)="signInPopup()" *ngIf="!this.menuFlag" [ngClass]="!this.menuFlag ? 'story-menu-icon' : ''"
            class="img-fluid" class="m-1" src="./assets/bottom-menu/sign-white.png">
        </h5>
        <h5 class="menu-text">
          <img *ngIf="this.menuFlag" [ngClass]="this.menuFlag ? 'story-menu' : 'story-menu-icon'" class="img-fluid"
            class="m-1" src="./assets/menu-icon/sign-black.png">
          <span [ngClass]="this.menuFlag ? 'text-white' : 'd-none'">SignIn</span>
        </h5>
      </div>
    </div>
  </div>
  <router-outlet></router-outlet>
</div>