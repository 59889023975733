import { Component, Input, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';
import { CommonService } from 'src/app/services/common.service';
import { PinterestService } from 'src/app/services/pinterest-service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataSharingService } from 'src/app/services/data-sharing-service';
import { Router } from '@angular/router';



@Component({
  selector: 'app-brand-popup',
  templateUrl: './brand-popup.component.html',
  styleUrls: ['./brand-popup.component.css']
})
export class BrandPopupComponent implements OnInit {
  @Input() garmentId: string | undefined;
  @Input() BrandId: string | undefined;
  // @Input() routeFlageBrandPage: string | undefined;


  garmentsList = this.firestore.collection('garments').valueChanges({ idField: 'id' }) as Observable<any[]>;
  brandsFilesList = this.firestore.collection('brands').valueChanges({ idField: 'id' }) as Observable<any[]>;
  brandBoardList = this.firestore.collection('brand-board').valueChanges({ idField: 'id' }) as Observable<any[]>;
  
  snapByApi: any = [];
  clothShapShotsListAll: any = [];
  clothDetails: any;
  brandDetails: any;
  counter: number = 2;
  userFiledetails: any = [];
  garmentsCollection: any = [];
  brandsCollection: any = [];
  brandboardCollection: any = [];
  userUploadedFileList: any = [];
  clocthCollectionIdcollection: any = [];
  boards: any;
  clothShapShotsList: any = [];
  SnapShotFileDetails: any = [];
  brandObject: any
  brandCollectionIdcollection: any = [];
  brandhShapShotsList: any = [];
  userSnapShotList: any = [];
  allList: any = [];
  brandsnaploading: boolean = true
  brandsnaploadingLogoUrl : boolean = true
  brandDtails: any = {}
  boardDetails: any = {}
  brandBoardDetails: any = {}
  id: any;  
  heartFlag:boolean = false
  selectedBrand: any;
  tab: any;

  constructor(public router: Router,
    private dataSharingService: DataSharingService,
    private modal: NgbModal,
    private firestore: AngularFirestore,
    private pinterestAPI: PinterestService,
    private commonService: CommonService,
  ) {

  }

  ngOnInit(): void {


// console.log(this.routeFlageBrandPage)


    // this.id = grandmentObject.BrandID

    this.brandsFilesList.subscribe((brands) => {
      this.brandsCollection = brands;
    });

   

    this.brandBoardList.subscribe(
      brandboard => {
        this.brandboardCollection = brandboard;
      });
    this.brandsnaploadingLogoUrl = true
    this.garmentsList.subscribe(
      garments => {
        this.garmentsCollection = garments;
        let grandmentObject = this.garmentsCollection.find((b: any) => b.id === this.garmentId);
        this.brandObject = this.brandsCollection.find((b: any) => b.id == grandmentObject.BrandID);
        this.brandsnaploadingLogoUrl = false
        this.getBrandImage(this.brandObject.LogoURL)
        if (grandmentObject && grandmentObject['Name'] != undefined) {
          let brandId = grandmentObject["BrandID"];
          this.brandDetails = this.brandsCollection.find((b: any) => b.id === brandId);
          this.brandDetails['boardName'] = 'Not Link to Board';
          let brandBoardObject = this.brandboardCollection.find((b: any) => b.brandId == brandId);
          if (brandBoardObject != undefined &&  brandId  != undefined &&  this.boards != undefined) {
            let boardId = brandBoardObject && brandBoardObject['boardId'] != undefined ? brandBoardObject['boardId'] : undefined;
            let boardDetails = this.boards.find((b: any) => b.id == boardId);
            // this.brandDetails['boardName'] = boardDetails['name'];
            if (boardDetails != undefined && boardDetails['name'] != undefined) 
                this.brandDetails['boardName'] = boardDetails['name'];
          }
        }
      });

      if (this.boards != undefined) {
      this.pinterestAPI.getBoardList().subscribe((data: {
        name: any; items: any;
      }) => {
        this.boards = data.items
        let filterboardID = this.brandboardCollection.find((b) => b.brandId == this.brandObject.id)
        this.brandBoardDetails = this.boards.find((b) => b.id == filterboardID.boardId)
      })}
      
      let reqInputObject = {id:  this.BrandId , pageNumber:1 }
      this.commonService.yologramSnapshotByBrands(reqInputObject).subscribe(async (response: any) => {
        this.brandsnaploading = false 
        this.allList =  response.object
        this.userSnapShotList.push(...response.object.slice(0,3))
      })
  }

  ngOnChanges(changes) {
    this.selectedBrand 
   }


  getBrandImage(imgURL: string) {
    let brandEncodedurl = encodeURI(imgURL).replace('/', '%2F');
    let url = "https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/" + brandEncodedurl + "?alt=media&token=550e7e88-bed3-4668-84ff-ad491c19e06c"
    return url;
  }

  clickOnHeart(){
    this.heartFlag = true
   } 
  
  getBrandStyle(brandId: any, gender: any,) {
    this.closePopUp()
    this.dataSharingService.sendUserSnapShots(this.allList);
    if(gender == 'M'){this.tab= 'womens-wear'}else{
      this.tab= 'womens-wear'
    }
    this.router.navigate([ '/brand', { 'brandId':  brandId,  'Gender': gender, 'select-tab': 'womens-wear'}])

    // this.router.navigate([ this.routeFlageBrandPage != undefined ? this.routeFlageBrandPage :  'public/public-brand-stories', { 'brandId':  brandId,  'Gender': gender, 'select-tab': 'womens-wear'}])

  }
  getBrandProfile(brand: any, board: any) {
    this.closePopUp()
    this.dataSharingService.sendUserSnapShots(this.allList);
    this.router.navigate([ '/brand', { 'brandId': brand, 'select-tab': 'story' }])

    // this.router.navigate([this.routeFlageBrandPage != undefined ? this.routeFlageBrandPage : 'public/public-brand-stories', { 'brandId': brand, 'select-tab': 'story' }])

  }


  getFeed(brand: any, brandName: any) {
    this.closePopUp()
    this.dataSharingService.sendUserSnapShots(this.allList);   
    this.router.navigate([ '/brand', { 'brandId': brandName.id, 'storyId': brand.StoryID, 'select-tab': 'new-stories' }])

    // this.router.navigate([this.routeFlageBrandPage != undefined ? this.routeFlageBrandPage : 'public/public-brand-stories', { 'brandId': brandName.id, 'storyId': brand.StoryID, 'select-tab': 'new-stories' }])
      }

  closePopUp() {
    this.modal.dismissAll(BrandPopupComponent)
  }

}
