<div class="col-lg-12 m-1 justify-content-center text-center scroll"
  [ngClass]="this.edit == 'edit' ? 'scroll-edit ' : 'scroll'" *ngIf="clothDetailsList != undefined">
  <div class="container garment-alingn">
    <div class="col-lg-12 d-flex justify-content-center">
      <div class="col-lg-12 text-center w-100 m-1 d-flex justify-content-center  d-flex">
        <div class="text-center d-flex heeadWidth "><img class="profileImg-brand"
            style="width:35px; height:35px !important;" [src]="getBrandImage(clothDetailsList.LogoURL)">
        </div>
        <div class="m-0 heeadWidth">
         
            <div class="container d-block col-12  ">
              <h6 class="p-2 color-class" [ngClass]="this.edit == 'edit' ? 'color-class ' : ''">
                {{clothDetailsList.BrandName}}</h6>
              <div class=" col-12 details-for-task " *ngIf="clothDetailsList != undefined">
                <b>
                  <h6 class="color-class"  [ngClass]="this.edit == 'edit' ? 'color-class ' : ''">{{clothDetailsList.Gender == 'F' ? 'Womenswear' :
                    (clothDetailsList.Gender == 'M') ? 'Menswear' : 'N/A'}}</h6>
                </b>
              </div>
            </div>
        </div>
        <div class="heeadWidth text-end">
          <button type="button" class="btn btn-sm btn-outline-info  follow color-class"
            [ngClass]="this.edit == 'edit' ? 'color-class ' : ''">Follow</button>
        </div>
      </div>
    </div>
   
    
    <b>
      <h6 class="clothName color-class "> <span *ngIf="clothDetailsList.RealType == '0'" class="brandbtn py-1">
        
        <img
            style="width:18px !important; color:black ;padding-bottom: 3px;"
            src="./assets/realtype/headgear.png"></span>
        <span *ngIf="clothDetailsList.RealType == '1'" class="brandbtn py-1"><img
            style="width:18px !important; color:black ;padding-bottom: 3px;"
            src="./assets/realtype/upperwear.png"></span><span *ngIf="clothDetailsList.RealType == '2'"
          class="brandbtn py-1"><img style="width:18px !important; color:black ;padding-bottom: 5px;"
            src="./assets/realtype/bottomwear.png"></span><span *ngIf="clothDetailsList.RealType == '3'"
          class="brandbtn py-1"><img style="width:16px !important; color:black ;padding-bottom: 3px;"
            src="./assets/realtype/footwear.png"></span><span *ngIf="clothDetailsList.RealType == '4'"
          class="brandbtn py-1"><img style="width:18px !important; color:black ;padding-bottom: 3px;"
            src="./assets/realtype/heeledfootwear.png"></span><span *ngIf="clothDetailsList.RealType == '5'"
          class="brandbtn py-1"><img style="width:18px !important; color:black ;padding-bottom: 3px;"
            src="./assets/realtype/fullbody.png"></span>
        {{(clothDetailsList.Name)}}
        
      </h6>
    </b>
    <div class="details" (click)="garmentDetailsEdit(clothDetailsList)" data-bs-toggle="modal"
      data-bs-target="#pageModalLabel">
      <h6 class="color-class" [ngClass]="this.edit == 'edit' ? 'color-class ' : ''">Collection: {{clothDetailsList.CollectionName}}</h6>
      <h6  class="color-class" [ngClass]="this.edit == 'edit' ? 'color-class ' : ''">Details: {{clothDetailsList.Details}}</h6>
      <h6  class="color-class" [ngClass]="this.edit == 'edit' ? 'color-class ' : ''">SKU: {{clothDetailsList.SKU}}</h6>
    </div>

    <div class="container d-flex col-12  ">
      <div class=" col-6 details-for-task " *ngIf="clothDetailsList != undefined">
        <b>
          <!-- <h6  *ngIf="edit != undefined" [ngClass]="this.edit == 'edit' ? 'color-class ' : ''">{{clothDetailsList.Gender == 'F' ? 'Womenswear' :
            (clothDetailsList.Gender == 'M') ? 'Menswear' : 'N/A'}}</h6> -->
          <h6 *ngIf="edit != undefined" [ngClass]="this.edit == 'edit' ? 'color-class ' : ''">Cloth Gender
            :{{clothDetailsList.ClothGender}}</h6>
          <h6 *ngIf="edit != undefined" [ngClass]="this.edit == 'edit' ? 'color-class ' : ''">Image Pack :
            {{clothDetailsList.ImagePack}}</h6>
          <h6 *ngIf="edit != undefined" [ngClass]="this.edit == 'edit' ? 'color-class ' : ''">Thumb Size X:
            {{clothDetailsList.ThumbSizeX}}</h6>
            <span *ngIf="edit != undefined" [ngClass]="this.edit == 'edit' ? 'color-class ' : ''" (click)="getuploade()" class="uploadImage"  data-bs-toggle="modal" data-bs-target="#upload3D"><img style="width:18px !important; color:black ;padding-bottom: 3px;"
              src="./assets/upload-icon/2D.png"></span>
        </b>
      </div>
      <div class=" col-6 details-for-task ">
        <b>
          <!-- <h6 *ngIf="edit != undefined">{{clothDetailsList.GarmentType}}</h6> -->
          <!-- <h6 [ngClass]="this.edit == 'edit' ? 'color-class ' : ''"> Real Type:{{clothDetailsList.RealType == '0' ?
            'headgear' : (clothDetailsList.RealType ==
            '1') ? 'upperwear': (clothDetailsList.RealType == '2') ? ' bottomwear ' :
            (clothDetailsList.RealType == '3') ? 'footwear' : (clothDetailsList.RealType == '4') ?
            'heeledfootwear ' : (clothDetailsList.RealType == '5') ? 'fullbody ' :'N/a' }}</h6> -->
          <h6 *ngIf="edit != undefined" [ngClass]="this.edit == 'edit' ? 'color-class ' : ''"> Prefeb :
            {{clothDetailsList.Prefeb}}</h6>
          <h6 *ngIf="edit != undefined" [ngClass]="this.edit == 'edit' ? 'color-class ' : ''"> Thumb Size Y:
            {{clothDetailsList.ThumbSizeY}}</h6>
            <span *ngIf="edit != undefined" [ngClass]="this.edit == 'edit' ? 'color-class ' : ''" (click)="getuploade()" class="uploadImage"  data-bs-toggle="modal" data-bs-target="#upload3D"><img style="width:18px !important; color:black ;padding-bottom: 3px;"
              src="./assets/upload-icon/3D.png"></span>
        </b>
      </div>
    </div>
  
  </div>
  <div class="imagesDiv" >
  <div class="row twoDImage">
    <div class="col-6" *ngFor="let fileList of userFiledetails;let p=index">
      <span *ngIf="p < counter">
        <div *ngIf="fileList.fileType == 'GarmentUpload2D'">
          <img class="img-fluid img-thumbnail" style="height: 100px; width: 100px !important;     object-fit: contain;
                " src={{fileList.fileurl}} data-bs-toggle="modal" data-bs-target="#user-profile"
            (click)="openImageBox(fileList.fileurl)">
        </div>
        <div (click)="openModel(fileList.fileurl)" class="d-inline-block"
          *ngIf="fileList.fileType == 'GarmentUpload3D'">
          <model-viewer camera-controls camera-orbit="45deg 55deg 2.5m" ar auto-rotate src={{fileList.fileurl}}
            alt="A 3D avatar-model-view" data-js-focus-visible="" ar-status="not-presenting"
            style="height: 100px; width: 150px; float: none;">
          </model-viewer>
        </div>
      </span>
    </div>
  </div>
  <div *ngIf="userFiledetails != undefined">
    <button class="btn btn-sm color-class" [ngClass]="this.edit == 'edit' ? 'color-class ' : ''"
      *ngIf="userFiledetails.length != 0 && counter <= userFiledetails.length && counter != userFiledetails.length"
      (click)="incrementImgCounter()">More</button>
  </div>
</div>
  <div class="p-2 m-1 text-center d-flex justify-content-center">
    <h6 class="text-primary brandbtn m-2 color-class"
      [ngClass]=" brandId != undefined ? 'border-bottom border-dark' : ''"
      [ngClass]="this.edit == 'edit' ? 'color-class ' : ''" (click)="getStoryForGenderCloth(clothDetailsList)"><img
        style="width:15px !important; color:black" src="./assets/storystyle/story-white.png">
    </h6>
    <h6 [ngClass]="this.edit == 'edit' ? 'color-class ' : ''" class="m-1 text-primary brandbtn color-class"><img
        style="width:18px !important; color:black" src="./assets/storystyle/style-white.png">
    </h6>
    <h6 *ngIf="clothDetailsList.ThumbCenterURL  != undefined" class="m-1 brandbtn"><a
        href="{{clothDetailsList.ThumbCenterURL}}" target="_blank"><img style="width:15px !important; color:black"
          src="./assets/details.png"></a></h6>
    <h6 *ngIf="clothDetailsList.ThumbCenterURL  == undefined" class="m-1 brandbtn"><a><img
          style="width:15px !important; color:black" src="./assets/details-gray.png"></a> </h6>
    <h6 *ngIf="clothDetailsList.ThumbLeftURL  != undefined" class="m-1 brandbtn"><a
        href="{{clothDetailsList.ThumbLeftURL}}" target="_blank"><img style="width:15px !important"
          src="./assets/gallary.png"></a></h6>
    <h6 *ngIf="clothDetailsList.ThumbLeftURL  == undefined" class="m-1 brandbtn"><a><img style="width:15px !important"
          src="./assets/gallary-gray.png"></a></h6>

    <h6 *ngIf="clothDetailsList.ThumbRightURL  != undefined" class="m-1 brandbtn"><a
        href="{{clothDetailsList.ThumbRightURL}}" target="_blank"><img style="width:15px !important; color:black"
          src="./assets/shop.png"></a></h6>
    <h6 *ngIf="clothDetailsList.ThumbRightURL  == undefined" class="m-1 brandbtn"><a><img
          style="width:15px !important; color:black" src="./assets/cart-gray.png"></a>
    </h6>
  </div>
  <!-- <div class="p-2 m-1 text-center d-flex justify-content-center">
    <h6 class="text-primary brandbtn m-2 color-class"
      [ngClass]=" brandId != undefined ? 'border-bottom border-dark' : ''"
      [ngClass]="this.edit == 'edit' ? 'color-class ' : ''" (click)="getStoryForGenderCloth(clothDetailsList)">Story
    </h6>
    <h6 [ngClass]="this.edit == 'edit' ? 'color-class ' : ''" class="m-1 text-primary brandbtn color-class">Style
    </h6>
  </div> -->



  <div class="row g-2 form d-block  " id="target" *ngIf="packJsonObject.length > 0">
    <div class="slider-css211 " data-bs-toggle="modal" data-bs-target="#twoD-Modal">
      <div *ngFor="let object of packJsonObject[0].value['files'];let i=index" class="slider-img211">
        <img (click)="open2DModelPopUp(zipList[object],'headgear')"
          *ngIf="clothType == packJsonObject[0].title && clothId == object " class="img-responsive1"
          [src]="sanitizer.bypassSecurityTrustUrl(zipList[object])" />
      </div>
    </div>
    <div class="slider-css2" data-bs-toggle="modal" data-bs-target="#2D-view">
      <div *ngFor="let object of packJsonObject[1].value['files'];let i=index" class="slider-img2">
        <img (click)="open2DModelPopUp(zipList[object],'upperwear')"
          *ngIf="clothType == packJsonObject[1].title && clothId == object " class="img-responsive"
          [src]="sanitizer.bypassSecurityTrustUrl(zipList[object])" />
      </div>
    </div>
    <div class="slider-css2" data-bs-toggle="modal" data-bs-target="#2D-view">
      <div *ngFor="let object of packJsonObject[2].value['files'];let i=index" class="slider-img2">
        <img (click)="open2DModelPopUp(zipList[object],'bottomwear')"
          *ngIf="clothType == packJsonObject[2].title && clothId == object " class="img-responsive"
          [src]="sanitizer.bypassSecurityTrustUrl(zipList[object])" />
      </div>
    </div>
    <div class="slider-css211" data-bs-toggle="modal" data-bs-target="#2Dview">
      <div *ngFor="let object of packJsonObject[3].value['files'];let i=index" class="slider-img211">
        <img (click)="open2DModelPopUp(zipList[object],'footwear')"
          *ngIf="clothType == packJsonObject[3].title && clothId == object " class="img-responsive1"
          [src]="sanitizer.bypassSecurityTrustUrl(zipList[object])" />
      </div>
    </div>
  </div>
</div>
<div class="d-flex justify-content-center " *ngIf="isloading">
  <div class="spinner-border" role="status">
    <span class=""></span>
  </div>
</div>
<div class="modal fade imp-bg" id="user-profile" tabindex="-1" aria-labelledby="user-profile" aria-hidden="true"
  data-bs-backdrop="false">
  <div class="modal-dialog justify-content-center ">
    <div class="modal-content imp-block justify-content-center">
      <h5 type="button" class="btn-close  closBtn" data-bs-dismiss="modal" aria-label="Close"></h5>
      <img src={{showImages.fileurl}}>
    </div>
  </div>
</div>

<div *ngIf="edit != undefined" class="modal fade" id="pageModalLabel" tabindex="-1" aria-labelledby="pageModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-sm">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="pageModalLabel">Edit Garment</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <label class="m-2">Collection</label>
        <input type="text" class="form-control" placeholder="Add Collection" (change)="setGarmentCollection($event)"
          [value]="clothDetailsList.CollectionName ">
        <label class="m-2">Details</label>
        <!-- <input type="text" class="form-control" > -->
        <textarea class="form-control" id="exampleFormControlTextarea5" placeholder="Add Details" rows="5"
          (change)="setGarmentDetails($event)" [value]="clothDetailsList.Details"></textarea>
        <label class="m-2">SKU</label>
        <input type="text" class="form-control" placeholder="Add SKU" (change)="setGarmentSKU($event)"
          [value]="clothDetailsList.SKU">
      </div>
      <label class="m-2"><img style="width:20px !important; " src="./assets/details_black.png"></label>
      <input type="text" class="form-control" placeholder="Add Details Link" (change)="setGarmentThumbCenterURL($event)"
        [value]="clothDetailsList.ThumbCenterURL">
      <label class="m-2"><img style="width:20px !important" src="./assets/gallary_black.png"></label>
      <!-- <input type="text" class="form-control" > -->
      <input class="form-control" id="exampleFormControlTextarea5" placeholder="Add Gallery Link" rows="5"
        (change)="setGarmentThumbLeftURL($event)" [value]="clothDetailsList.ThumbLeftURL">
      <label class="m-2"><img style="width:20px !important" src="./assets/shop_black.png"></label>
      <input type="text" class="form-control" placeholder="Add Shop Link" (change)="setGarmentThumbRightURL($event)"
        [value]="clothDetailsList.ThumbRightURL">
      <div class="modal-footer">
        <button type="button" class="btn btn-sm btn-secondary" data-bs-dismiss="modal">Close</button>
        <button type="button" class="btn btn-sm btn-primary" (click)="updateGarmentDetailsEdit()"
          data-bs-dismiss="modal">Save</button>
      </div>
    </div>
  </div>
</div>