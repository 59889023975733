import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';
import { Brand } from 'src/app/models/brand';
import { PinterestService } from 'src/app/services/pinterest-service';
import { environment } from 'src/environments/environment';
import { CommonService } from 'src/app/services/common.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-brand-board',
  templateUrl: './brand-board.component.html',
  styleUrls: ['./brand-board.component.css']
})
export class BrandBoardComponent implements OnInit {

  brandsCollection = this.firestore.collection('brands').valueChanges({ idField: 'id' }) as Observable<Brand[]>;
  brandBoardCollection = this.firestore.collection('brand-board').valueChanges({ idField: 'id' }) as Observable<[]>;
  boards: any = [];
  brands: any = [];
  brandBoardList: any = [];
  linkBrands = [];
  linkbrandsCollection :any = []
  storageUrl = environment.storageWebURL + environment.firebase.storageBucket;
  tosterForBlankEntry :boolean = false
  brandId: any = '';
  boardId: any = '';
  loading = false;
  logInFlag: boolean;
  constructor(private router: Router, public commonService: CommonService, private firestore: AngularFirestore, private pinterestAPI: PinterestService,) {   }

  pinterestResponse = { "items": [{ "name": "YOLngOgram", "id": "929008298072871067", "owner": { "username": "prahulkar0054" }, "privacy": "PUBLIC", "description": "" }], "bookmark": null
 };

  ngOnInit(): void {
    let title = 'Yologram - brand-board'
    this.commonService.changeTitle(title);
    this.logInFlag = this.commonService.isLoggedIn()
    this.brandsCollection.subscribe(
      (brandList) => {
        this.brands = brandList;
      });
    this.getBrandboardDtails()
    this.checkPageAuthorization();
    //this.getPinterestBoards();  
    this.manageBoards();
  }



  getBrandboardDtails()
  {
    this.pinterestAPI.getBoardList().subscribe((data) => {
      this.boards = data.items      
      this.brandBoardCollection.subscribe(
        (brandboard) => { 
          this.brandBoardList = brandboard
          this.brandsCollection.subscribe(
            (brandList) => {
              //this.linkBrands =[]
              brandList.forEach((brand: any) => {
                let boardbrandObject = this.brandBoardList.find((brandBoard: any) => brandBoard.brandId == brand.id);
                if (boardbrandObject == undefined) {
                  this.brands.push(brand);
                } else { 
                  let linkBrand = brand;
                  let boardObject = this.boards.find((board: any) => board.id === boardbrandObject.boardId)
                  if (boardObject != undefined) {
                    linkBrand['boardName'] = boardObject.name;
                    this.linkBrands.push(linkBrand);
                    this.linkbrandsCollection = this.linkBrands
                  }
                }
              }
              )
            })
        }
      )
    });
  }
  getBrandImage(imgURL: string) {
    let brandEncodedurl = encodeURI(imgURL).replace('/', '%2F');
    let url = this.storageUrl + "/o/" + brandEncodedurl + "?alt=media&token=550e7e88-bed3-4668-84ff-ad491c19e06c"
    return url;
  }

  getPinterestBoards() {
    this.pinterestAPI.getBoardList().subscribe((data) => {
      this.boards = data.items
    });
  }

  manageBoards() {
    this.boards = this.pinterestResponse.items;
  }


  linkBrandToBoard() {
    // if (this.boardId == ""){
    //   alert("Please select Board. ")
    //   return false;
    // }else if(this.brandId== ""){
    //   alert("Please select Brand. ")
    //   return false;     
    // }else if(this.brandId == '' && this.boardId == ''){
    //   alert("Please select Brand and Board to link. ")
    //   return false;
    // }

    if (this.brandId == '' && this.boardId == ''){
      alert(" Please select Brand and Board to link. ")
      return false;
    }else if(this.brandId== ""){
      alert("Please select Brand. ")
      return false;     
    }else if(this.boardId == ''){
      alert("Please select Board. ")
      return false;
    }


    let brandBoardMappingObject = {
      'brandId': this.brandId,
      'boardId': this.boardId
    }
    this.firestore.collection('brand-board').add(brandBoardMappingObject)
    return true;
  }
  selectedBrand($event: any) {
    this.brandId = $event.target.value;
 
  }

  selectedBoard($event: any) {
    this.boardId = $event.target.value;
  }
  checkPageAuthorization() {
    this.loading = true;
    this.commonService.getUser().subscribe((user: any) => {
      let userObject = { 'userid': user?.uid, 'pagename': '/brand-board' }
      this.commonService.checkViewPageRights(userObject).subscribe((res) => {
        this.loading = false;
        if (!res) {
          this.router.navigateByUrl("/menu-page");
        }
      });
    });
  }


}
