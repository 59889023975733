
<app-yologram-social *ngIf="logInFlag"></app-yologram-social>
<app-public *ngIf="!logInFlag"></app-public>


<div id="leftContainer">
    <div class="controls">        
        <div class ="AvatarType">
            <button (click)="showMaleAvatar()" class="genderButton" id="avatarSubmitMaleButton">
                <i class="fa-solid fa-person genderIcon"></i>
                <span>MALE</span>
            </button>
    
            <!-- Button to load Female avatar -->
            <button  (click)="showFemaleAvatar()" class="genderButton" id="avatarSubmitFemaleButton">
                <i class="fa-solid fa-person-dress genderIcon"></i>
                <span>FEMALE</span>
            </button>
        </div>

        <div class="inputGroup">
            <h2>Mixamo Animation</h2>
            <div class="dropdownButtonPair">
                <!-- <select>
                    <option>Loading...</option>
                </select> -->
                <select  id="MixamoAnimationDropdown" (change)="generateLinkForFBX($event)" >
                    <option *ngFor="let item of downloadUrls$ | async " [value]="item" >{{item}}</option>
                  </select>
            </div>
        </div>
        
        <div class="inputGroup">
            <h2>Animation</h2>
            <div class="dropdownButtonPair"  (change)="generateLinkForBVH($event)">
                <select id="AnimationDropdown">
                    <option *ngFor="let anim of animationListCollection " [value]="anim.id" >{{anim.displayName != undefined ? anim.displayName : 'Loading...'}}</option>
                </select>
            </div>
        </div>

        <div class="inputGroup">
            <h2>upperWear</h2>
            <div class="dropdownButtonPair">
                <select id="upperWearClothesDropdown" (change)="generateLinkForUpperWear($event)">
                    <option *ngFor="let cloth of upperWearList " [value]="cloth.id ">{{cloth.id != undefined ? cloth.id : 'Loading...'}}</option>
                </select>
            </div>
        </div>

        <div class="inputGroup">
            <h2>bottomWear</h2>
            <div class="dropdownButtonPair">
                <select id="bottomWearClothesDropdown"  (change)="generateLinkForBottomWear($event)">
                    <option *ngFor="let cloth of bottomWearList" [value]="cloth.id ">{{cloth.id != undefined ? cloth.id : 'Loading...'}}</option>
                </select>
            </div>
        </div>

        <div class="inputGroup">
            <h2>Fatness</h2>
            <input type="range" min="0" max="100" value="0" class="slider" id="myRange" [(ngModel)]="fatnessSliderValue" (input)="onFatnessChange()">
            <p>Value: <span id="demo"></span></p>
        </div>
    </div>

    
</div>
<div #threeDModelContainer></div>