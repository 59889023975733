
<!-- <div class="header d-none d-md-block d-lg-block">
    <nav class="nav nav-pills flex-column flex-sm-row ">
        <a class="flex-sm-fill text-sm-center nav-link" aria-current="page" href="#/public/news"><img class="img-fluid" class="m-1" style="height: 19px; width: 17px !important;
            "src="./assets/upload-icon/film-roll.png">Stories</a>
        <a class="flex-sm-fill text-sm-center nav-link"  href="#/public/network"><i class="bi bi-6x bi-people-fill text-secondary m-1"></i>Network</a>
        <a class="flex-sm-fill text-sm-center nav-link"  href="#/public/style"><i class="bi bi-bag-dash-fill text-secondary m-1"></i>Style</a>
        <a class="flex-sm-fill text-sm-center nav-link "  href="#/Login"  [ngClass]="router.url == '/Login' ? 'active' : 'text-secondary'"> <i class="bi bi-6x bi-plus-square-fill text-secondary m-1"></i>Sign In</a>    
    </nav>
  </div>

  <div class="footer row b-menu fixed-bottom justify-content-center d-block d-sm-block d-md-none">
    <div class="container-fluid row text-center  d-flex">
        <div class="col-3  d-block">
            <h5 class="menu-text"><a href="#/public/news" *ngIf="router.url != '/public/news' ">
                <img *ngIf="router.url != '/public/news'" [ngClass]="router.url != '/public/news' ? 'story-menu-icon' : ''"
                  class="img-fluid" class="m-1"
                  src="./assets/upload-icon/film-roll.png">
                <h6 *ngIf="router.url == '/public/news'" class="color-class m-1">Stories</h6>
              </a>
            </h5>
        </div>
        <div class="col-3  d-block">
            <h5 class="menu-text"><a href="#/public/network" *ngIf="router.url != '/public/network' ">
                <img *ngIf="router.url != '/public/network'" [ngClass]="router.url != '/public/network' ? 'story-menu-icon' : ''"
                  class="img-fluid" class="m-1"
                  src="./assets/menu-icon/network-gray.png">
              </a>
            </h5>
        </div>
        <div class="col-3  d-block">
            <h5 class="menu-text"><a href="#/public/style" *ngIf="router.url != '/public/style' ">
                <img *ngIf="router.url != '/public/style'" [ngClass]="router.url != '/public/style' ? 'story-menu-icon' : ''"
                  class="img-fluid" class="m-1"
                  src="./assets/menu-icon/style-gray.png">
                <h6 *ngIf="router.url == '/public/style'" class="color-class m-1">Stories</h6>
              </a>
            </h5>
        </div>
        <div class="col-3  d-block">
            <h5 class="menu-text"><a href="#/Login" *ngIf="router.url != '/Login' ">
                <img *ngIf="router.url != '/Login'" [ngClass]="router.url != '/Login' ? 'story-menu-icon' : ''"
                  class="img-fluid" class="m-1"
                  src="./assets/menu-icon/sign-black.png">
              </a>
            </h5>
            <h5 class="menu-text"><a href="#/Login" *ngIf="router.url == '/Login' ">
                <img *ngIf="router.url == '/Login'"
                  [ngClass]="router.url == '/Login' ? 'story-menu' : 'story-menu-icon'" class="img-fluid" class="m-1"
                 src="./assets/menu-icon/sign-black.png">
                 SignIn</a>
            </h5>
        </div>
    </div>
  </div> -->

  <!-- <div class="header d-none d-md-block d-lg-block">
    <nav class="nav nav-pills flex-column flex-sm-row ">
      <a class="flex-sm-fill text-sm-center nav-link " aria-current="page" href="#/public/news"
        [ngClass]="router.url == '/public/news' ? 'active' : 'text-secondary'">
        <img class="img-fluid" class="m-1" style="height: 19px; width: 17px !important;
        " src="./assets/upload-icon/film-roll.png">Stories</a>
  
      <a class="flex-sm-fill text-sm-center nav-link" href="#/public/network"
        [ngClass]="router.url == '/public/network' ? 'active' : 'text-secondary'"><i
          class="bi bi-6x bi-people-fill text-secondary m-1"></i>Network</a>
  
      <a class="flex-sm-fill text-sm-center nav-link" href="#/public/style"
        [ngClass]="router.url == '/public/style' ? 'active' : 'text-secondary'"><i
          class="bi bi-bag-dash-fill  text-secondary m-1"></i>Style</a>
  
      <a class="flex-sm-fill text-sm-center nav-link" href="#/Login" aria-disabled="true"><i class="
        bi bi-6x bi-plus-square-fill  text-secondary m-1"></i>Sign In</a>
    </nav>
  </div>
  <div class="footer row fixed-bottom justify-content-center  text-center d-block d-sm-block d-md-none">
    <div class="container-fluid row text-center b-menu d-flex">
      <div class="col-3  d-block">
        <h5  class="menu-text"><a href="#/public/news" *ngIf="router.url != '/public/news'" (click)="clickOnIconHIden()">
            <img *ngIf="router.url != '/public/news'" [ngClass]="router.url != '/public/news' ? 'story-menu-icon' : ''"
              class="img-fluid" class="m-1"
              src="./assets/upload-icon/film-roll-white.png">
          </a>
        </h5>
        <h5 class="menu-text"><a href="#/public/news" *ngIf="router.url == '/public/news'">
          <img *ngIf="router.url == '/public/news' && !this.menuFlag"
            [ngClass]="router.url == '/public/news' && !this.menuFlag ? 'story-menu' : 'story-menu-icon'" class="img-fluid" class="m-1"
           src="./assets/upload-icon/film-roll-black.png">
        <img *ngIf="router.url == '/public/news' && this.menuFlag" 
        [ngClass]="router.url == '/public/news' && this.menuFlag ? 'story-menu-icon' : ''" class="img-fluid" class="m-1"
       src="./assets/upload-icon/film-roll-white.png"></a>
       <span *ngIf="router.url == '/public/news' && !this.menuFlag" class="color-class text-white m-1">Stories</span>
  
      </h5>
      </div>
      <div class="col-3  d-block">
        <h5 class="menu-text"><a href="#/public/network" *ngIf="router.url != '/public/network'" (click)="clickOnIconHIden()">
            <img *ngIf="router.url != '/public/network'" [ngClass]="router.url != '/public/network'? 'story-menu-icon' : ''"
              class="img-fluid" class="m-1"
              src="./assets/bottom-menu/network-white.png">
          </a>
        </h5>
        <h5 class="menu-text"><a href="#/public/network" *ngIf="router.url == '/public/network'">
            <img *ngIf="router.url == '/public/network' && !this.menuFlag"
              [ngClass]="router.url == '/public/network' && !this.menuFlag ? 'story-menu' : 'story-menu-icon'" class="img-fluid" class="m-1"
             src="./assets/menu-icon/network-black.png">
          <img *ngIf="router.url == '/public/network' && this.menuFlag" 
          [ngClass]="router.url == '/public/network' && this.menuFlag ? 'story-menu-icon' : ''" class="img-fluid" class="m-1"
         src="./assets/bottom-menu/network-white.png"></a>
         <span *ngIf="router.url == '/public/network' && !this.menuFlag" class="color-class text-white m-1">Network</span>
        </h5>
      </div>
      <div class="col-3  d-block">
        <h5  class="menu-text"><a href="#/public/style" *ngIf="router.url != '/public/style'" (click)="clickOnIconHIden()">
            <img *ngIf="router.url != '/public/style'" [ngClass]="router.url != '/public/style' ? 'story-menu-icon' : ''"
              class="img-fluid" class="m-1"
              src="./assets/bottom-menu/style-white.png">
          </a>
        </h5>
        <h5 class="menu-text"><a href="#/public/style" *ngIf="router.url == '/public/style'">
          <img *ngIf="router.url == '/public/style' && !this.menuFlag"
            [ngClass]="router.url == '/public/style' && !this.menuFlag ? 'story-menu' : 'story-menu-icon'" class="img-fluid" class="m-1"
           src="./assets/menu-icon/style-black.png">
        <img *ngIf="router.url == '/public/style' && this.menuFlag" 
        [ngClass]="router.url == '/public/style' && this.menuFlag ? 'story-menu-icon' : ''" class="img-fluid" class="m-1"
       src="./assets/bottom-menu/style-white.png"></a>
       <span *ngIf="router.url == '/public/style' && !this.menuFlag" class="color-class text-white  m-1">Style</span>
      </h5>
      </div>
      <div class="col-3  d-block">
            <h5 class="menu-text">
              <img (click)="signInPopup()" *ngIf="!this.menuFlag" [ngClass]="!this.menuFlag ? 'story-menu-icon' : ''"
                class="img-fluid" class="m-1"
                src="./assets/bottom-menu/sign-white.png">
          </h5>
          <h5 class="menu-text">
              <img  *ngIf="this.menuFlag"
                [ngClass]="this.menuFlag ? 'story-menu' : 'story-menu-icon'" class="img-fluid" class="m-1"
              src="./assets/menu-icon/sign-black.png">
              <span [ngClass]="this.menuFlag ? 'text-white' : 'd-none'">SignIn</span>
          </h5>
      </div>
    </div>
  </div> -->
<app-news></app-news>