<!-- <div class="card crd-margin slider-css ">
    <div class="col-lg-12 m-1 justify-content-center text-center" >
        <div class="container">
            <div class="col-lg-12 d-flex justify-content-center">
                <div class="col-lg-12 text-center w-100 m-1 d-flex justify-content-center  d-flex">
                    <div class="text-center d-flex ">
                    </div>
                    <div class="m-0">
                        <h4 class="p-2" data-bs-dismiss="modal">{{evnFile}}</h4>
                    </div>
                   
                </div>
              
            </div>
            <div><button data-bs-dismiss="modal" type="button" class="btn btn-outline-info m-2">Preview</button></div>
    
        <div class="row height-avatr-id m-2" *ngIf="userSnapShotList.length>= 0 ">
            <div class="column d-block" *ngFor="let snapshot of userSnapShotList ; let i=index;">
                <img class="w-90 shadow-1-strong rounded mb-1"
                    [src]="snapshot.SnapshotFileUrl">
            </div>
        </div>
    </div>
</div> -->


<app-public-evn-profile [routLogInFlag]='rootFromEven'></app-public-evn-profile>