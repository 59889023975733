<div *ngIf="logInFlag">
    <app-yologram-social></app-yologram-social>
</div>
<div *ngIf="!logInFlag">
    <app-public></app-public>
    <!-- <div class="roksan" [ngStyle]="{ 'height.px': isMobile ?  windowHeight + 60 :  windowHeight - 60}">
        <div class="col-lg-12 m-1 justify-content-center text-center">
            <div class="container">
                <div class="col-lg-12 d-flex justify-content-center">
                    <div class="col-lg-12 text-center w-100 m-1 d-flex justify-content-center  d-flex">
                        <div class="text-center d-flex ">
                        </div>
                        <div *ngIf="evnLoading" class="text-center">
                            <div class="spinner-border m-5" role="status">
                                <span class="sr-only"></span>
                            </div>
                        </div>
                        <div class="m-0 text-center" *ngIf="!evnLoading">
                            <img class="m-2" style="width: 350px; height:200px !important;;"
                                class="w-90 shadow-1-strong rounded mb-1" src="{{evnFileImage}}">
                            <h4 class="p-2 env-set-name">{{evnFile}}</h4>
                            <img class="popup-i-set" (click)="previewEnvironment()" data-bs-toggle="modal" data-bs-target="#env-preview"
                            style="width:25px !important;" src="./assets/360-degrees-8.png">
                        </div>

                    </div>
                </div>
                <div>

                    <div class="justify-content-center story-center d-flex">
                        <h6 (click)="getStoryFeed()" class="m-2 text-primary brandbtn color-class">

                            <img style="width:15px !important; background-color:black"
                                src="./assets/storystyle/story-white.png">
                        </h6>
                        <h6 (click)="getStoryFeed()" class="m-2 text-primary brandbtn color-class">
                            <img (click)="previewEnvironment()" data-bs-toggle="modal" data-bs-target="#env-preview"
                                style="width:15px !important;" src="./assets/360-view.png">
                        </h6>
                    </div>
                </div>
                <div *ngIf="evnLoading2" class="text-center">
                    <div class="spinner-border m-5 p-5" role="status">
                        <span class="sr-only"></span>
                    </div>
                </div>



             

                <div class="col-12 div-center" *ngIf="selectTab == 'story' ">
                    <div class="row w-100 image-margin">
                        <h6 *ngIf="pageiIndex" (click)="previousbutton()"><b *ngIf="callpageNumer > 0 ">Previous</b></h6>
                        <div class="col-4 p-0" *ngFor="let snapshot of userSnapShotList  ; let i=index;">
                            <div class="d-flex align-items-center justify-content-center m-1">
                                <img *ngIf="snapshot.SnapshotFileUrl != ''" class="w-100 shadow-1-strong rounded m-1" (click)="getStory(snapshot)"
                                    [src]="snapshot.SnapshotFileUrl">
                            </div>
                        </div>
                        <div class="col-12 text-center  stify-content-center morebtn">
                            <h6 (click)="nextButton('c')" *ngIf="userSnapShotList.length < pagecount"><b>More</b>
                            </h6>
                        </div>
                    </div>
                </div>

                <div class="modal fade imp-bg" id="env-previe" tabindex="-1" aria-labelledby="env-preview"
                    aria-hidden="true" data-bs-backdrop="false">
                    <div class="modal-dialog justify-content-center ">
                        <div class="modal-content imp-block justify-content-center">
                            <h5 type="button" class="btn-close  closBtn" data-bs-dismiss="modal" aria-label="Close">
                            </h5>
                            <div>
                                <img class="w-90 shadow-1-strong rounded mb-1" [src]="privew"> {{privew}}
                            </div>
                            <model-viewer camera-controls ar auto-rotate camera-orbit="45deg 55deg 2.5m"
                                src="https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/RuntimeAvatarSkinner%2FSource%2Ffu_ft_Jacoblee_2207_02_max.gltf?alt=media&token=91b9c4f6-7c3a-4f06-ac81-7c155ba31994"
                                alt="A 3D avatar-model-view" data-js-focus-visible="" quick-look
                                ar-status="not-presenting" [environment-image]="privew" [skybox-image]="privew">
                            </model-viewer>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="storyFeed" *ngIf="selectTab == 'storyFeed' ">
            <div class="drips-container">
                {{userSnapShotList.lengt}}
                <app-drips (emmitStoryIndex)="handleStoryCall($event)" 
                (visibleIndex)="handlePreviousPage($event)"  [mainMenuFlag]="mainMenuFlag" [userSnapShotsInput]="userSnapShotList" [showpanel]="showpanel"
                    [StoryIds]="selectedStoryId" [PageCount]="pagecount"
                    [pageNumber]="pageNumber"    
                     [nextPage]="nextPage">
                </app-drips>
            </div>
        </div>

    </div> -->
</div>

<!-- 
<div class="roksan main-div" [ngStyle]="{ 'height.px': isMobile ?  windowHeight + 60 :  windowHeight - 60}">
    <div class="col-lg-12 m-1 justify-content-center text-center">
        <div class="container">
            <div class="col-lg-12 d-flex justify-content-center">
                <div class="col-lg-12 text-center w-100 m-1 d-flex justify-content-center  d-flex">
                    <div class="text-center d-flex ">
                    </div>
                    <div *ngIf="evnLoading" class="text-center ">
                        <div class="spinner-border m-5" role="status">
                            <span class="sr-only"></span>
                        </div>
                    </div>
                    <div class="m-0 text-center " *ngIf="!evnLoading">
                        <img class="m-2" style="width: 350px; height:200px !important;;"
                            class="w-90 shadow-1-strong rounded mb-1" (click)="callEvnUrl()" src="{{evnFileImage}}">
                        <h4 class="p-2 env-set-name">{{evnFile}}</h4>
                        <img class="popup-i-set" (click)="previewEnvironment()" data-bs-toggle="modal"
                            data-bs-target="#env-preview" style="width:25px !important;"
                            src="./assets/360-degrees-8.png">
                    </div>
                </div>
            </div>
            <div>
                <div class="justify-content-center story-center d-flex">
                    <h6 (click)="getStoryFeed()" class="m-2 text-primary brandbtn color-class">

                        <img style="width:15px !important; background-color:black"
                            src="./assets/storystyle/story-white.png">
                    </h6>
                    <h6 (click)="getStoryFeed()" class="m-2 text-primary brandbtn color-class">
                        <img (click)="previewEnvironment()" data-bs-toggle="modal" data-bs-target="#env-preview"
                            style="width:15px !important;" src="./assets/360-view.png">
                    </h6>
                </div>
            </div>
            <div *ngIf="evnLoading2" class="text-center">
                <div class="spinner-border m-5 p-5" role="status">
                    <span class="sr-only"></span>
                </div>
            </div>





            <div class="col-12 div-center" *ngIf="selectTab == 'story' ">
                <div class="row w-100 image-margin">
                    <h6 *ngIf="pageiIndex" (click)="previousbutton()"><b *ngIf="callpageNumer > 0 ">Previous</b></h6>
                    <div class="col-4 p-0" *ngFor="let snapshot of userSnapShotList  ; let i=index;">
                        <div *ngIf="snapshot.SnapshotFileUrl != ''"
                            class="d-flex align-items-center justify-content-center m-1">
                            <img class="w-100 shadow-1-strong rounded m-1" (click)="getStory(snapshot)"
                                [src]="snapshot.SnapshotFileUrl">
                        </div>
                    </div>
                </div>
                <div class="col-12 text-center stify-content-center morebtn">
                    <h6 (click)="nextButton('n')"><b>More</b>
                    </h6>
                </div>
            </div>

            <div class="modal fade imp-bg" id="env-previe" tabindex="-1" aria-labelledby="env-preview"
                aria-hidden="true" data-bs-backdrop="false">
                <div class="modal-dialog justify-content-center ">
                    <div class="modal-content imp-block justify-content-center">
                        <h5 type="button" class="btn-close  closBtn" data-bs-dismiss="modal" aria-label="Close">
                        </h5>
                        <div>
                            <img class="w-90 shadow-1-strong rounded mb-1" [src]="privew"> {{privew}}
                        </div>
                        <model-viewer camera-controls ar auto-rotate camera-orbit="45deg 55deg 2.5m"
                            src="https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/RuntimeAvatarSkinner%2FSource%2Ffu_ft_Jacoblee_2207_02_max.gltf?alt=media&token=91b9c4f6-7c3a-4f06-ac81-7c155ba31994"
                            alt="A 3D avatar-model-view" data-js-focus-visible="" quick-look ar-status="not-presenting"
                            [environment-image]="privew" [skybox-image]="privew">
                        </model-viewer>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="storyFeed" *ngIf="selectTab == 'storyFeed'">
        <app-drips (emmitStoryIndex)="handleStoryCall($event)" (visibleIndex)="handlePreviousPage($event)"
            (emmitStoryIndex)="handleStoryCall($event)" (visibleIndex)="handlePreviousPage($event)"
            [mainMenuFlag]="mainMenuFlag" [userSnapShotsInput]="userSnapShotList" [showpanel]="showpanel"
            [StoryIds]="selectedStoryId" [PageCount]="pagecount" [pageNumber]="pageNumber" [nextPage]="nextPage">
        </app-drips>
    </div>
</div> -->

<div [ngClass]="!showCrd ? 'd-none' : ''" [ngStyle]="{ 'height.px': isMobile ? windowHeight + 60 : windowHeight - 60 }"
    (click)="toggleSideNav($event)" class="story-class">
    <div (click)="flipClose($event)" *ngIf="!storyCardflag  &&  environmentData"
        class="text-black text-end class-close-btn">X</div>
    <app-drips (emmitStoryIndex)="handleStoryCall($event)" (visibleIndex)="handlePreviousPage($event)"
        [mainMenuFlag]="mainMenuFlag" [userSnapShotsInput]="userSnapShotList" [showpanel]="showpanel"
        [EnvUrl]="evntempFile" [evntempFile]="evntempFile" [FunctionFlag]="this.functionFlag" [StoryIds]="temp_StoryId"
        [PageCount]="pagecount" [pageNumber]="pageNumber" [nextPage]="nextPage" [teamplateFlag]="teamplateFlag"
        [storyCardflag]="storyCardflag" [storyText]="storyText" [envpageFlage]="envpageFlage"
        (environmentDataEmitter)="handleEvenPanel($event)" [showSearchPanel]="showSearchPanel"
        (emitCloseSearch)="handleClosepanleFlag($event)" (openSearchPanelEmitFlag)="handleOpenpanleFlag($event)"
        [snapInitCall]="flagFunction" [setHdEveUrl]="setHdEveUrl" [setQlty]="setQlty"
        [temp_setHdEveUrl]="temp_setHdEveUrl" [storyCardflag]="winFlag" (emitHdData)="handleHdData($event)">
    </app-drips>
</div>



<div *ngIf="sidenavWidth == 40" class="d-block preview-space">
    <div class="menu-arrow text-end tab-box space-box" (click)="openAvatarPanel('avatar', $event)">
        <div class="image-container">
            <img class="small-image" src="./assets/search/spaces.png" alt="Open Avatar">
            <img class="large-image" [src]=" this.temp_setHdEveUrl || evntempFile" alt="Open Avatar">
        </div>
    </div>
</div>

<div class="brand-close" class="sidenav" [ngStyle]="{'width.px': sidenavWidth}" (click)="toggleSideNav($event)">

    <div id="mySidenav" *ngIf="environmentData">
        <!-- <div class="menu-arrow text-end"><button *ngIf="sidenavWidth == 15"   (click)="toggleOpen($event)" class="yologram-btn rounded-pill text-white"></button></div> -->
        <div *ngIf="sidenavWidth == 40" class="d-block">
            <div class="menu-arrow text-end tab-box" style="margin-top: 55px;;"
                (click)="openAvatarPanel('search',$event)">
                <img style="width:20px;" src="./assets/brand-space/search-button.png" alt="Open Search">
            </div>
        </div>



        <div [ngClass]="isExpanded && environmentData != undefined ? '' : 'd-none'">
            <app-environment-panel [ngClass]="!showAvatarPanel ? 'd-none' : ''" [gltfImgSrc]="gltfImgSrc"
                [skyBoXall]="evntempFile" [twoDprivew]="evntempFile" [evsname]="evnName" [evsdiplaynameALL]="evnFile"
                [evnProfilePageFlag]="evnProfilePageFlag" [environmentFileName]="environmentFileName"
                [hideFlag]="hideFlag" [brandStoryScrollerHeight]="brandStoryScrollerHeight"
                [environmentData]="environmentData" [pageiIndex]="pageiIndex" [spaceName_Page]="spaceName_Pass"
                [callpageNumer]="callpageNumer" (SnapStoryId)="handleStorySelection($event)"
                (previousEvenEmitter)="handlepreviousbutton($event)" (emitSlectedSnapList)="handlesnapListEmit($event)"
                (emitSliderArrow)="handleSlider($event)" (emitHDtoggle)="handleHDtoggle($event)">
            </app-environment-panel>
        </div>
    </div>
</div>


<!-- <div *ngIf="evntempFile != undefined && !mainMenuFlag" [ngStyle]="{ 'height.px': isMobile ? windowHeight + 60 : windowHeight - 60 }">
    <app-news3-d-popup (sceneDone)="setSceneFlag($event)" (calledFunction)="functionLoaded($event)"
        [EnvUrl]="evntempFile" [FunctionFlag]="this.functionFlag">
    </app-news3-d-popup>
</div> -->

<div class="image-slider" *ngIf="isExpanded && !showSpaceSlider" [ngStyle]="{ 'width.px': windowWidth - 170 }">
    <div class="image-slider-inner" *ngFor="let even of environmentFileSlider">
        <div class="image-wrapper" (click)="loadNewSpace(even)">
            <img class="image-slide" [src]="even?.evnCompressUrl" />
            <div class="image-overlay">
                {{ even?.displayName.length > 10 ? (even.displayName | slice:0:10) + '...' : even?.displayName }}
            </div>
        </div>
    </div>
</div>