import { Component } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';
import { Observable, finalize, tap } from 'rxjs';
import { Brand } from 'src/app/models/brand';
import { CommonService } from 'src/app/services/common.service';
import * as JSZip from 'jszip/dist/jszip';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { generateUUID } from 'three/src/math/MathUtils';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/compat/storage';
import { DatePipe } from '@angular/common';




@Component({
  selector: 'app-garment-cache',
  templateUrl: './garment-cache.component.html',
  styleUrls: ['./garment-cache.component.css']
})
export class GarmentCacheComponent {

  brands = this.firestore.collection('brands').valueChanges({ idField: 'id' }) as Observable<Brand[]>;
  garmentsList = this.firestore.collection('garments').valueChanges({ idField: 'id' }) as Observable<any[]>;
  userFiles  = this.firestore.collection('userfiles').valueChanges({ idField: 'id' }) as Observable<any[]>;
 
  task!: AngularFireUploadTask;
  percentage!: Observable<number | undefined>;
  snapshot!: Observable<any>;
  fileUrl!: string;
  user: any = null;
  userid: string = "";
  id: string = "";
  picture!: string;
  file!: File


  logInFlag: boolean;
  clothLoader:boolean=false
  imageCashedFlag :boolean=false
  brandList: any = []
  garmentsCollection: any = []
  selectedCloth: any = '';
  selectedClothType: any = '';
  filterGarmrntList: any = [];
  selectedGender: any = '';
  selectedBrand: any = '';
  packJsonObject: any = [];
  testVar: any = '';
  zipList: any = {};
  garmentObject: any = {};
  panelFlag: boolean;
  ViewFlage: boolean;
  newPacketJson: any = [];
  newzipLoaded: any={};
  fileURL: string | URL;
  newNameList: any = {

  };
  loading: boolean;
  shaowShaveFlage: boolean = false;
  userFilesCollection: any = [];

  constructor(   public datepipe: DatePipe,
    private storage: AngularFireStorage, private db: AngularFirestore,public commonService: CommonService, private firestore: AngularFirestore, public sanitizer: DomSanitizer, public router: Router, private _httpClient: HttpClient,) {
    this.logInFlag = this.commonService.isLoggedIn()
    if (!this.logInFlag) {
      this.router.navigateByUrl("/drip");
    }
    this.checkPageAuthorization()

    this.brands.subscribe((brand) => {
      this.brandList = brand
    })

    this.garmentsList.subscribe((garments) => {
      this.garmentsCollection = garments;
    });

    this.userFiles.subscribe((files) => {
      this.userFilesCollection = files;
    });

    

  }

  ngOnInit(): void {

    let title = 'Yologram - garment-cache';
    this.commonService.changeTitle(title);
  }

  selectBrand($event) {
    this.shaowShaveFlage = false
    this.imageCashedFlag=false
    this.clothLoader= false
    this.newzipLoaded  = {}
    this.filterGarmrntList = []
    this.selectedBrand = $event.target.value;
    console.log("brandId", this.selectedBrand)
    this.selectedGender = '';
    this.selectedClothType = '';
    this.selectedCloth = '';
  }

  selectGender($event) {
    this.shaowShaveFlage = false;
    this.imageCashedFlag=false
    this.clothLoader= false
    this.newzipLoaded  = {}
    this.filterGarmrntList = []
    this.selectedGender = $event.target.value;
    console.log("Gender", this.selectedGender)
    this.filterClothList()
  }

  selectClothType($event) {
    this.shaowShaveFlage = false;
    this.imageCashedFlag=false
    this.clothLoader= false
    this.filterGarmrntList = []
    this.newzipLoaded  = {}
    this.selectedClothType = $event.target.value;
    console.log("clothtype", this.selectedClothType)
    this.filterClothList()

  }

  selectCloth($event) {
    this.imageCashedFlag=false
    this.shaowShaveFlage = false;
  this.clothLoader= false
    this.selectedCloth = $event.target.value;
    console.log("cloth", this.selectedCloth)
    this.newzipLoaded  = {}
  }


  filterClothList() {
    if (this.selectedBrand == "Default") {
      this.filterGarmrntList = this.garmentsCollection.filter((garment) =>
        garment.Gender == this.selectedGender && garment.GarmentType == this.selectedClothType)
    } else {
      this.filterGarmrntList = this.garmentsCollection.filter((garment) => garment.BrandID == this.selectedBrand &&
        garment.Gender == this.selectedGender && garment.GarmentType == this.selectedClothType)
    }
  }

  async downloadPack0(url) {
    console.log("garmentLink", url)
    console.log('pack0 loading intilze...');
    // Check if a download is already in progress
    // Set the download flag to true to indicate a download is in progress

    // let url = 'https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/' + brandEncodedurl + '?alt=media&token=46df48b9-1f7a-4b61-98c6-bff029734da4';
    try {
      const res = await this._httpClient
        .get(url, { observe: 'response', responseType: 'blob' })
        .toPromise();
        
      await this.processZipFile(res.body, this.processResponse.bind(this));
    } catch (error) {
      this.handleErrors(error);
    } finally {
    }
    console.log('pack0 loaded........');
  }
  processZipFile(data, callBackSetJsonObject) {
    // this.processZipFileCopy(data, callBackSetJsonObject)
    this.testVar = 'Change Update';
    JSZip.loadAsync(data, callBackSetJsonObject, this.testVar)
      .then(function (zip) {
        /* Parse json file */
        var jsonExtn = /(.json)$/;
        Object.keys(zip.files)
          .filter((fileName) => {
            return jsonExtn.test(fileName.toLowerCase());
          })
          .map((jsonFileName) => {
            var jsonFile = zip.files[jsonFileName];
            return jsonFile.async('string').then((jsonString) => {
              let packJsonObject = JSON.parse(jsonString);

              let jsonResponseData = [
                {
                  title: 'headgear',
                  value: {
                    files: packJsonObject['hFiles'].filter(data => data !== ''),
                    filesIndex: packJsonObject['hFiles'].filter(data => data === '' || data !== undefined),
                    name: packJsonObject['hName'].filter(data => !data.includes('deleted')), // Filter out names containing 'deleted'
                    count: packJsonObject['hCount'],
                  },
                },
                {
                  title: 'upperwear',
                  value: {
                    files: packJsonObject['uFiles'].filter(data => data !== ''),
                    filesIndex: packJsonObject['uFiles'].filter(data => data === '' || data !== undefined),
                    name: packJsonObject['uName'].filter(data => !data.includes('deleted')), // Filter out names containing 'deleted'
                    count: packJsonObject['uCount'],
                  },
                },
                {
                  title: 'bottomwear',
                  value: {
                    files: packJsonObject['bFiles'].filter(data => data !== ''),
                    filesIndex: packJsonObject['bFiles'].filter(data => data === '' || data !== undefined),
                    name: packJsonObject['bName'].filter(data => !data.includes('deleted')), // Filter out names containing 'deleted'
                    count: packJsonObject['bCount'],
                  },
                },
                {
                  title: 'footwear',
                  value: {
                    files: packJsonObject['fFiles'].filter(data => data !== ''),
                    filesIndex: packJsonObject['fFiles'].filter(data => data === '' || data !== undefined),
                    name: packJsonObject['fName'].filter(data => !data.includes('deleted')), // Filter out names containing 'deleted'
                    count: packJsonObject['fCount'],
                  },
                },
              ];
              


              callBackSetJsonObject(jsonResponseData);
            });
          });
        var re = /(.jpg|.png|.gif|.ps|.jpeg | .json)$/;
        var promises = Object.keys(zip.files)
          .filter(function (fileName) {
            // don't consider non image files
            return re.test(fileName.toLowerCase());
          })
          .map(function (fileName) {
            var file = zip.files[fileName];
            if (fileName.indexOf('.json') > 0) {
            } else {
              return file.async('blob').then(function (blob) {
                return [
                  fileName.substring(0, fileName.lastIndexOf('.')), // keep the link between the file name and the content
                  URL.createObjectURL(blob), // create an url. img.src = URL.createObjectURL(...) will work
                ];
              });
            }
          });
        return Promise.all(promises);
      })
      .then(function (result) {
        return result.reduce(function (acc, val) {
          acc[val[0]] = val[1];
          return acc;
        }, {});
      })
      .then((result) => {
        let newZipList = JSON.parse(JSON.stringify(result));
        this.zipList = newZipList;
        this.newzipLoaded = this.zipList[this.garmentObject.id]
         this.clothLoader=false
        console.log(this.zipList)
        return;
      })
      .catch(function (e) {
        console.error(e);
      });


    // this.reload(data,callBackSetJsonObject)
  }
  handleErrors(error) {
    console.log('error', error);
    this.packJsonObject = [];
  }
  async processResponse(res: any[]) {
    this.packJsonObject = res;
  }


  openClothSlider() {
    if (this.selectedBrand == '' || this.selectedCloth == '' || this.selectedClothType == '' || this.selectedGender == '') {
      alert("Please select all details.");
      return ;
  }
    this.panelFlag = true;
    this.ViewFlage = true;
    this.clothLoader=true;

    this.imageCashedFlag=true;
    let filePath = this.userFilesCollection.find((file) =>file.filename == this.selectedCloth &&   file.fileType == 'Garment360Cache');

    if (filePath) {
      this.newzipLoaded = filePath.fileurl;
    this.clothLoader=false;
    console.log('File path found:', this.newzipLoaded);
        return;
    }

    this.garmentObject = this.garmentsCollection.find((garment) => garment.id === this.selectedCloth);
    let zipName = 'pack0';
    let packLocation =
        'AvatarBlenderClothes%2F' +
        this.garmentObject.BrandID +
        '_' +
        this.garmentObject.Gender +
        '%2F' +
        zipName;
    console.log("packLocation:", packLocation);


    this.loadData(packLocation);

    if (this.selectedBrand !== '' && this.selectedCloth !== '' && this.selectedClothType !== '' && this.selectedGender !== '') {
        this.shaowShaveFlage = true;
    }
}

  async loadData(packlocation) {
    let garmentLink =
      'https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/' +
      packlocation +
      '?alt=media&token=2c1dc261-ac91-4c32-a5ec-ac5e06f0a9bc&_gl=1*goch74*_ga*MjExMjI9MTIyMi4xNjg3NTA3MDk1*_ga_CW55HF8NVT*MTY5NjkzMDY1Ny4xNTcuMS4xNjk2OTM2NzA4LjU5LjAuMA..';
    await this.downloadPack0(garmentLink)

    console.log("packetObject", this.packJsonObject)
    console.log("garmentLink", garmentLink)

  }

  getSelectionRealType(type: string): string {
    let newType;
    switch (type) {
      case 'headgear':
        newType = 0;
        break;
        case 'upperwear':
          newType = 1;
        break;
        case 'bottomwear':
          newType = 2;
        break;
      case 'footwear':
        newType = 3;
        break;
      default:
        newType = '';
    }
    return newType;
  }


  // cacheFileUpload(){
  //   const pathEvn = `Uploads/${this.selectedCloth}/${this.file.name}`;
  //   // const lastIndex =  this.file.name.lastIndexOf('.');
  //   // const fileNameWithoutExtension = lastIndex !== -1 ?  this.file.name.slice(0, lastIndex) :  this.file.name;
  //   // console.log(fileNameWithoutExtension);
  //   // const filename = `${this.file.name}`;
  //   const ref = this.storage.ref(pathEvn);
  //   this.task = this.storage.upload(pathEvn, this.file);
  //   this.percentage = this.task.percentageChanges();
  //   let new_date = new Date();
  //   const date = this.datepipe.transform(new_date, 'd MMM, y, h:mm:ss a');
  //   const uid = generateUUID();
  //   let uploadObject = {
  //     id: uid,
  //     filename: '' ,
  //     fileurl: '',
  //     filepath: pathEvn,
  //     date: date,
  //     fileType: 'GarmentUpload2D',
  //     userId: this.userid,
  //     link: this.selectedCloth
  //   }
  //   this.snapshot = this.task.snapshotChanges().pipe(
  //     tap(console.log),
  //     // The file's download URL
  //     finalize(async () => {
  //       this.fileUrl = await ref.getDownloadURL().toPromise();
  //       uploadObject.fileurl = this.fileUrl
  //       const uid = generateUUID();
  //       this.db.collection('userfiles').add(uploadObject);
  //     }),
  //   );







  // }


  cacheFileName() {
    this.shaowShaveFlage=false;
    let type = this.getSelectionRealType(this.selectedClothType);
    let clothfiles = this.packJsonObject[type].value['files'];
    console.log('type', clothfiles);
    let fileName = this.packJsonObject[type].value['name'];
    console.log('fileName', fileName);
    // Create an object to store the mapping of cloth IDs to file names
    let clothFileMapping: { [key: string]: string } = {};
    // Iterate through the cloth files and store the corresponding file names
    for (let i = 0; i < clothfiles.length; i++) {
      const clothID = clothfiles[i];
      const file = fileName[i]; // Assuming clothfiles and fileName have the same length
      clothFileMapping[clothID] = file;
    }
    this.newNameList = clothFileMapping
    console.log('clothFileMapping', clothFileMapping);
  }
//1

//   cacheFileUpload() {
//     this.cacheFileName()
//     let fileName =  this.newNameList[this.selectedCloth]
//     console.log('type',fileName)
//     const lastIndex =  fileName.lastIndexOf('.');
//      const fileNameWithoutExtension = lastIndex !== -1 ?  this.file.name.slice(0, lastIndex) :  this.file.name;
//     console.log('fileName',fileName)
//     const pathEvn = `Uploads/${this.selectedCloth}/${lastIndex}`;
//     const ref = this.storage.ref(pathEvn);
//     // Download the file locally
//     const xhr = new XMLHttpRequest();
//     xhr.responseType = 'blob';
//     xhr.onload = async () => {
//         if (xhr.status === 200) {
//             // Upload the downloaded file to Firebase Storage
//             const uploadTask = this.storage.upload(pathEvn, xhr.response);
//             this.percentage = uploadTask.percentageChanges();
//             const new_date = new Date();
//             const date = this.datepipe.transform(new_date, 'd MMM, y, h:mm:ss a');
//             const uid = generateUUID();
//             const uploadObject = {
//                 id: uid,
//                 filename: lastIndex,
//                 fileurl: '',
//                 filepath: pathEvn,
//                 date: date,
//                 fileType: 'Garment360Cache',
//                 userId: this.userid,
//                 link: this.selectedCloth
//             };
//             uploadTask.snapshotChanges().pipe(
//                 finalize(async () => {
//                     this.fileUrl = await ref.getDownloadURL().toPromise();
//                     uploadObject.fileurl = this.fileUrl;
//                     this.db.collection('userfiles').add(uploadObject);
//                 })
//             ).subscribe(
//                 (snapshot) => {
//                     // Handle progress or other snapshot changes if needed
//                 },
//                 (error) => {
//                     // Handle error if upload fails
//                     console.error('Upload failed:', error);
//                 }
//             );
//         } else {
//             console.error('File download failed:', xhr.statusText);
//         }
//     };
//     xhr.open('GET', this.newzipLoaded); // Assuming this.fileURL is the URL of the file to download
//     xhr.send();
// }

//2

// cacheFileUpload() {
//   this.openClothSlider()
//   this.cacheFileName();
 
//   const pathEvn = `Uploads/${this.selectedCloth}/${this.selectedCloth}`;
//   const ref = this.storage.ref(pathEvn);
//   // Check if the file already exists in Firebase Storage
//   ref.getDownloadURL().toPromise().then(
//       (url) => {
//           console.log('File already exists:', url);
//           alert('File already exists.')
//           // File already exists, no need to upload again
//       },
//       async (error) => {
//           if (error.code === 'storage/object-not-found') {
//               // File doesn't exist, proceed with download and upload
//               console.log('File does not exist. Proceeding with download and upload...');
//               // Download the file locally
//               const xhr = new XMLHttpRequest();
//               xhr.responseType = 'blob';
//               xhr.onload = async () => {
//                   if (xhr.status === 200) {
//                       // Upload the downloaded file to Firebase Storage
//                       const uploadTask = this.storage.upload(pathEvn, xhr.response);
//                       this.percentage = uploadTask.percentageChanges();
//                       const new_date = new Date();
//                       const date = this.datepipe.transform(new_date, 'd MMM, y, h:mm:ss a');
//                       const uid = generateUUID();
//                       const uploadObject = {
//                           id: uid,
//                           filename: this.selectedCloth, // Use the file name without extension
//                           fileurl: '',
//                           filepath: pathEvn,
//                           date: date,
//                           fileType: 'Garment360Cache',
//                           userId: this.userid,
//                           link: this.selectedCloth
//                       };
//                       uploadTask.snapshotChanges().pipe(
//                           finalize(async () => {
//                               this.fileUrl = await ref.getDownloadURL().toPromise();
//                               uploadObject.fileurl = this.fileUrl;
//                               this.db.collection('userfiles').add(uploadObject);
//                               alert('File saved successfully.');
//                           })
                          
//                       ).subscribe(
//                           (snapshot) => {
                           
//                               // Handle progress or other snapshot changes if needed
//                           },
//                           (error) => {
//                               // Handle error if upload fails
//                               console.error('Upload failed:', error);
//                           }
//                       );
//                   } else {
//                       console.error('File download failed:', xhr.statusText);
//                   }
//               };
//               xhr.open('GET', this.newzipLoaded); // Assuming this.fileURL is the URL of the file to download
//               xhr.send();
//           } else {
//               console.error('Error checking if file exists:', error);
//           }
//       }
//   );
// }

//3

cacheFileUpload() {
  this.openClothSlider();
  this.cacheFileName();

  const pathEvn = `Uploads/${this.selectedCloth}/${this.selectedCloth}`;
  const ref = this.storage.ref(pathEvn);
  // Check if the file already exists in Firebase Storage
  ref.getDownloadURL().toPromise().then(
    (url) => {
      this.clothLoader=false
     console.log('File already exists:', url);
      alert('File already exists.');
      // File already exists, no need to upload again
    },
    async (error) => {
      if (error.code === 'storage/object-not-found') {
        // File doesn't exist, proceed with download and upload
        console.log('File does not exist. Proceeding with download and upload...');
        // Download the file locally
        const xhr = new XMLHttpRequest();
        xhr.responseType = 'blob';
        xhr.onload = async () => {
          if (xhr.status === 200) {
            // Convert blob to PNG
            const blob = xhr.response;
            const img = new Image();
            img.src = URL.createObjectURL(blob);
            img.onload = () => {
              const canvas = document.createElement('canvas');
              canvas.width = img.width;
              canvas.height = img.height;
              const ctx = canvas.getContext('2d');
              ctx.drawImage(img, 0, 0);
              canvas.toBlob(async (pngBlob) => {
                // Upload the PNG blob to Firebase Storage
                const uploadTask = this.storage.upload(pathEvn, pngBlob);
                this.percentage = uploadTask.percentageChanges();
                let new_date = new Date();
                const date = this.datepipe.transform(new_date, 'd MMM, y, h:mm:ss a');
                const uid = generateUUID();
                const uploadObject = {
                  id: uid,
                  filename: this.selectedCloth, // Use the file name without extension
                  fileurl: '',
                  filepath: pathEvn,
                  date: date,
                  fileType: 'Garment360Cache',
                  userId: this.userid,
                  link: this.selectedCloth
                };
                console.log("uploadObject==>",uploadObject)
                uploadTask.snapshotChanges().pipe(
                  finalize(async () => {
                    this.fileUrl = await ref.getDownloadURL().toPromise();
                    uploadObject.fileurl = this.fileUrl;
                    this.db.collection('userfiles').add(uploadObject);
                    alert('File saved successfully.');
                  })
                ).subscribe(
                  (snapshot) => {
                    // Handle progress or other snapshot changes if needed
                  },
                  (error) => {
                    // Handle error if upload fails
                    console.error('Upload failed:', error);
                  }
                );
              }, 'image/png');
            };
          } else {
            console.error('File download failed:', xhr.statusText);
          }
        };
        xhr.open('GET', this.newzipLoaded); // Assuming this.fileURL is the URL of the file to download
        xhr.send();

      } else {
        console.error('Error checking if file exists:', error);
      }
    }
  );
}


checkPageAuthorization() {
  this.loading = true;
  this.commonService.getUser().subscribe((user: any) => {
    let userObject = { 'userid': user?.uid, 'pagename': '/garment-360-cache' }
    this.userid = userObject.userid
    this.commonService.checkViewPageRights(userObject).subscribe((res) => {
      console.log(res)
      this.loading = false;
      if (!res) {
        this.router.navigateByUrl("/drip");
      }
    });
  });
}

}
