import { Directive, ElementRef, Input, Renderer2, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';

@Directive({
  selector: '[appLazyLoad]'
})
export class LazyLoadDirective implements OnInit, OnDestroy {
  @Input() appLazyLoad: string;
  @Input() defaultImage: string;
  @Input() snapshot: any;

  @Output() imageIndex: EventEmitter<any> = new EventEmitter<any>();
  @Output() imageLoaded: EventEmitter<boolean> = new EventEmitter<boolean>();
  private observer: IntersectionObserver;
  private loadTimer: any;
  private isIntersecting: boolean = false;

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngOnInit() {
    // Set the default image initially
    this.renderer.setAttribute(this.el.nativeElement, 'src', this.defaultImage);
    //this.imageIndex.emit(this.index); //
    this.observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            if (!this.isIntersecting) {
              this.isIntersecting = true;
              // this.snapshot.snapshotLoading = true;
              //this.imageLoaded.emit(false); // Emit true value to parent component
              // Start a timer to load the actual image after 5 seconds of continuous intersection
              this.loadTimer = setTimeout(() => {
                this.renderer.setAttribute(this.el.nativeElement, 'src', this.appLazyLoad);
                this.observer.unobserve(this.el.nativeElement); // Stop observing this specific element after loading
                this.snapshot.snapshotLoading = false;
                //this.imageLoaded.emit(true); // Emit true value to parent component
              }, 1500);
            }
          } else {
            if (this.isIntersecting) {
              this.isIntersecting = false;
              // Clear the timer if the element leaves the viewport before 5 seconds
              if (this.loadTimer) {
                clearTimeout(this.loadTimer);
              }
            }
          }
        });
      },
      { threshold: 0.1 } // Adjust this value as needed
    );
    this.observer.observe(this.el.nativeElement);
  }

  ngOnDestroy() {
    if (this.observer) {
      this.observer.disconnect();
    }
    if (this.loadTimer) {
      clearTimeout(this.loadTimer);
    }
  }
}
