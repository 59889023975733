import { Component, Input } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";

@Component({
    selector: 'model-viewer-dilog',
    templateUrl: './pin-publish.html',
  })
  
  export class PinPublishViewerDilog {
    @Input() pinImgSrc: string | undefined;
  
    constructor(public dialog: MatDialog) {
  
    }
  }