
<div *ngIf="loading" class="loading">Loading&#8230;
    <button> cancel </button>
</div>
<div class="manage-w">
    <div *ngIf="filterSnapShotFileDetails.length >= 0 && indexImage" class="justify-content-center">
        <div class="card m-3 container slider-css" #swipeMainContent>
            <div *ngIf="snapLodaingFlag && indexImage"><img class="img-fluid image-top" width="480 !important"
                    height="280" [src]="indexImage" /></div>
            <div [ngClass]="threeDopen == true   ? '' : 'd-none'" >
                <div  [ngClass]="threeDopen == true   ? '' : 'd-none'"  style="width: 290px; height: 470px;"> 
                    <div (click)="closeThreeDBUTTON()">X</div>
                    <app-news3-d-templete [EnvUrl]="envUrl" [GarmentsGender]="genders" [upperwearUrl]="garmenturl"
                    [bottomwearUrl]="garmentbottom" [AvatarUrl]="this.avtarUrl" [headgearUrl]="headgear"
                    [footwearUrl]="footwear"></app-news3-d-templete>
                </div>
            </div>
            <div class="fixed-map d-flex"   *ngIf="!snapLodaingFlag && !threeDopen">
                <div class="slider-swipe-slider"   (scroll)="scrollCard()" #swipeContentPublic>
                    <div class="slider-img" *ngFor="let snapshot of filterSnapShotFileDetails; let i=index;" #target
                        id="{{snapshot.StoryID}}">
                        <div class="d-flex  slider-swipe-scroll" (scroll)="onScroll(snapshot)">
                            <div>
                                <div class="col-lg-12">
                                    <h6>
                                        <div class="col-lg-12 d-flex">
                                            <a class="col-lg-6 w-70 display-name"
                                                (click)="openUserPopup(snapshot)">{{snapshot.profileName}}</a>
                                            <h6 class="col-lg-6 text-end"
                                                (click)="openAvatarPopUp(snapshot,snapshot.AvatarID)"
                                                class="col-lg-6 text-end avatar-diaplay">
                                                🧍🏽{{snapshot.firstName}}{{snapshot.AvatarLastFive}}</h6>
                                        </div>
                                    </h6>
                                    <div class="col-lg-12 d-flex">
                                        <h6 class="col-lg-6 date-class">{{snapshot.timestamp | date : 'MMM d, y, h:mma'}}
                                        </h6>
                                        <h6 class="col-lg-6 " (click)="environmentPopup(snapshot.Environment)"
                                            class="col-lg-6 evn-font text-end">
                                            {{getEnvironmentName(snapshot.Environment)}}</h6>
                                    </div>
                                    <span class="spinner-border second-spineer" *ngIf="isLoading" role="status"></span>
                                </div>

                                <img class="img-fluid" width="480 !important" height="280"  [src]="snapshot.SnapshotFileUrl" />

<!--                                     
                                <img *ngIf="passId == snapshot.id && !threeDopen"
                                    [ngClass]="threeDopen == false   ? '' : 'd-none'" class="img-fluid"
                                    width="480 !important" height="280" [src]="snapshot.SnapshotFileUrl" /> -->
                               
<!--                                
                                    <div *ngIf="passId == snapshot.id"
                                    [ngClass]="threeDopen == true && passId == snapshot.id  ? '' : 'd-none'"
                                    class="img-fluid" width="480 !important" height="280">
                                    <app-news3-d-templete [EnvUrl]="envUrl" [GarmentsGender]="genders"
                                        [StyleGarmentUrl]="garmenturl" [StyleGarmentBottomWear]="garmentbottom"
                                        [AvatarUrl]="this.avtarUrl" [Headgear]="headgear"
                                        [Footwear]="footwear"></app-news3-d-templete>
                                </div> -->


                                <span class="second-share" (click)=" openShareModal(snapshot)"><img
                                        style="width:20px !important; " src="./assets/share.png"></span>

                                <!-- <h6 class="second-txt">
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{snapshot.StoryType}}
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    {{snapshot.StoryID}}
                                </h6> -->

                                <h6 class="alert-Box alert alert-success justify-content-center text-center"
                                    *ngIf="flagAlert">
                                    <h6>Linked Copied.</h6>
                                </h6>
                                |<div class="Link-Box">
                                    <a class="ancar-tag " data-bs-toggle="tooltip" data-bs-placement="left"
                                        title="click, to Copy on clip board">
                                        <a class="text-black"
                                            (click)="copyToClipboard(copyTextToClipAvtar(snapshot.UserID,snapshot.AvatarID))">AvatarID</a><a></a>
                                    </a>
                                    <a class="ancar-tag"
                                        (click)="copyToClipboard(copyTextToClipEven(snapshot.Environment))"><a
                                            class="text-black">Environment</a><a></a>
                                    </a>
                                    <a class="ancar-tag " *ngIf="snapshot.HeadwearID != ''"> <a class="text-black"
                                            (click)="copyToClipboard(copyTextToClipGarment(snapshot.HeadwearID))">HeadGear</a>
                                    </a>
                                    <a class="ancar-tag " *ngIf="snapshot.UpperwearID  != ''"> <a class="text-black"
                                            (click)="copyToClipboard(copyTextToClipGarment(snapshot.UpperwearID))">UpperWear</a>
                                    </a>
                                    <a class="ancar-tag " *ngIf="snapshot.BottomwearID != ''"><a class="text-black"
                                            (click)="copyToClipboard(copyTextToClipGarment(snapshot.BottomwearID))">BottomWear</a></a>
                                    <a class="ancar-tag " *ngIf="snapshot.FootwearID != ''"><a class="text-black"
                                            (click)="copyToClipboard(copyTextToClipGarment(snapshot.FootwearID))">FootWear</a></a>
                                </div>
                                <div class="threeD-image">
                                    <img (click)="openNews3DPassValue(copyTextToClipEven(snapshot.Environment),
                                copyTextToClipAvtar(snapshot.UserID,snapshot.AvatarID),
                                (snapshot),
                                copyTextToClipGarment(snapshot.UpperwearID),
                                copyTextToClipGarment(snapshot.BottomwearID),
                                copyTextToClipGarment(snapshot.FootwearID), 
                                copyTextToClipGarment(snapshot.HeadwearID)
                                )" style="width:20px !important; height: 20px; "
                                        src="./assets/realtype/dress.png">{{this.GarmentsGender}}</div>
                                <div class="iocon-set d-block justify-content-center text-center">
                                    <div class="justify-content-center set-div">
                                        <div
                                            class="d-block justify-content-center text-center select-icon brand-garment-band">
                                            <i class="bi bi-house-door-fill home-iocon-garment-selected  "
                                                (click)="homeclick()"></i>
                                            <img class="rounded-circle m-1" src="./assets/storystyle/home-name.PNG"
                                                class="rounded profileImg-brand "
                                                style="width: 20px !important;   height: 20px !important" />
                                        </div>
                                    </div>
                                    <div class="justify-content-center set-div ">
                                        <a class="pintrest-icon"
                                            *ngIf="snapshot.HeadwearID != undefined && snapshot.HeadwearID != '' && showPintrestIcon(snapshot.HeadwearID)"
                                            (click)="shareOnPinterest(snapshot.HeadwearID,'headwear',snapshot.SnapshotFileUrl,snapshot.StoryID)">
                                            <img style="width:20px !important; color:black"
                                                class="img-thumbnail2 pinImage" src="./assets/Pinterest-logo.png" />
                                        </a>
                                        <div class="d-block justify-content-center text-center brand-garment-band"
                                            *ngIf="getGarmentrealtype(snapshot.HeadwearID) == '0'">
                                            <img (click)="scrollToGarmentId(snapshot,snapshot.HeadwearID,'HeadwearID')"
                                                *ngIf="getGarmentrealtype(snapshot.HeadwearID) == '0'"
                                                style="width: 15px !important;   height: 15px !important;     opacity: 0.4;"
                                                src="./assets/newsfeed/headgear-new.png">
                                            <img (click)="scrollToGarmentId(snapshot,snapshot.HeadwearID,'HeadwearID')"
                                                *ngIf="getGarmentrealtype(snapshot.HeadwearID) == '0'"
                                                class="rounded-circle m-1"
                                                [src]="getBrandImageByGarmetnID(snapshot.HeadwearID)"
                                                class="rounded profileImg-brand "
                                                style="width: 20px !important;   height: 20px !important; opacity: 0.5;" />
                                        </div>
                                    </div>
                                    <div class="justify-content-center set-div  "
                                        *ngIf="getGarmentrealtype(snapshot.UpperwearID) == '5' || getGarmentrealtype(snapshot.UpperwearID) == '1'">
                                        <a class="pintrest-icon"
                                            *ngIf="snapshot.UpperwearID != undefined && snapshot.UpperwearID != '' && showPintrestIcon(snapshot.UpperwearID)"
                                            (click)="shareOnPinterest(snapshot.UpperwearID,'upperwear',snapshot.SnapshotFileUrl,snapshot.StoryID)">
                                            <img style="width:20px !important; color:black"
                                                class="img-thumbnail2 pinImage" src="./assets/Pinterest-logo.png" />
                                        </a>
                                        <div class="d-block justify-content-center text-center brand-garment-band"
                                            *ngIf="getGarmentrealtype(snapshot.UpperwearID) == '1' || getGarmentrealtype(snapshot.UpperwearID) == '5'">
                                            <img *ngIf="getGarmentrealtype(snapshot.UpperwearID) == '1'"
                                                (click)="scrollToGarmentId(snapshot,snapshot.UpperwearID,'UpperwearID')"
                                                style="width: 15px !important;   height: 15px !important;      opacity: 0.4;"
                                                src="./assets/realtype/upperwear.png">
                                            <img *ngIf="getGarmentrealtype(snapshot.UpperwearID) == '5'"
                                                (click)="scrollToGarmentId(snapshot,snapshot.UpperwearID,'UpperwearID')"
                                                style="width: 15px !important;   height: 15px !important;      opacity: 0.4;"
                                                src="./assets/realtype/fullbody.png">
                                            <img (click)="scrollToGarmentId(snapshot,snapshot.UpperwearID,'UpperwearID')"
                                                class="rounded-circle m-1"
                                                [src]="getBrandImageByGarmetnID(snapshot.UpperwearID)"
                                                class="rounded profileImg-brand "
                                                style="width: 20px !important;   height: 20px !important ;opacity: 0.5;" />
                                        </div>
                                    </div>

                                    <div class="justify-content-center set-div ">
                                        <a class="pintrest-icon"
                                            *ngIf="snapshot.BottomwearID != undefined && snapshot.BottomwearID != '' && showPintrestIcon(snapshot.BottomwearID)"
                                            (click)="shareOnPinterest(snapshot.BottomwearID,'upperwear',snapshot.SnapshotFileUrl,snapshot.StoryID)">
                                            <img style="width:20px !important; color:black"
                                                class="img-thumbnail2 pinImage" src="./assets/Pinterest-logo.png" />
                                        </a>
                                        <div class="d-block justify-content-center text-center brand-garment-band"
                                            *ngIf="getGarmentrealtype(snapshot.BottomwearID) == '2'">
                                            <img (click)="scrollToGarmentId(snapshot,snapshot.BottomwearID,'BottomwearID')"
                                                *ngIf="getGarmentrealtype(snapshot.BottomwearID) == '2'"
                                                style="width: 15px !important;   height: 15px !important;    opacity: 0.4;"
                                                src="./assets/newsfeed/bottomwear-new.png">
                                            <img (click)="scrollToGarmentId(snapshot,snapshot.BottomwearID,'BottomwearID')"
                                                *ngIf="getGarmentrealtype(snapshot.BottomwearID) == '2'"
                                                class="rounded-circle m-1"
                                                [src]="getBrandImageByGarmetnID(snapshot.BottomwearID)"
                                                class="rounded profileImg-brand"
                                                style="width: 20px !important; height: 20px !important ;opacity: 0.5;" />
                                        </div>
                                    </div>
                                    <div class="justify-content-center set-div  ">
                                        <a class="pintrest-icon"
                                            *ngIf="snapshot.FootwearID != undefined && snapshot.FootwearID != '' && showPintrestIcon(snapshot.FootwearID)"
                                            (click)="shareOnPinterest(snapshot.FootwearID,'footwear',snapshot.SnapshotFileUrl,snapshot.StoryID)">
                                            <img style="width:20px !important; color:black"
                                                class="img-thumbnail2 pinImage" src="./assets/Pinterest-logo.png" />
                                        </a>
                                        <div class="d-block justify-content-center text-center brand-garment-band"
                                            *ngIf="getGarmentrealtype(snapshot.FootwearID) == '3' ">
                                            <img (click)="scrollToGarmentId(snapshot,snapshot.FootwearID,'FootwearID')"
                                                *ngIf="getGarmentrealtype(snapshot.FootwearID) == '3' "
                                                style="width: 15px !important;   height: 15px !important;    opacity: 0.4;"
                                                src="./assets/realtype/footwear.png">
                                            <img (click)="scrollToGarmentId(snapshot,snapshot.FootwearID,'FootwearID')"
                                                *ngIf="getGarmentrealtype(snapshot.FootwearID) == '3'"
                                                class="rounded-circle m-1"
                                                [src]="getBrandImageByGarmetnID(snapshot.FootwearID)"
                                                class="rounded profileImg-brand"
                                                style="width: 20px !important;   height: 20px !important; ;opacity: 0.5;" />
                                        </div>
                                    </div>
                                </div>&nbsp;
                                <div class="nextForSwipe" (click)="scrollToCard(snapshot)"><i
                                        class="bi  bi-chevron-right right"></i></div>
                                <div class="prevForSwipe" (click)="scrollToPreVew(snapshot)"><i
                                        class="bi bi-chevron-left left "></i></div>
                                <div>
                                    <div class="col-12 col-sm-12 slider-box">
                                        <div class="col-12 col-sm-12  d-flex " *ngIf="filterSnapShotFileDetails">
                                            <div class="col-2 col-sm-2  ">
                                                <img class="m-1" (click)="openBrandPoup(snapshot.HeadwearID)"
                                                    data-bs-toggle="modal"
                                                    style="width:45px; height:45px !important; color:black;"
                                                    *ngIf="getGarmentrealtype(snapshot.HeadwearID) == '0' "
                                                    class="rounded profileImg-brand"
                                                    [src]="getBrandImageByGarmetnID(snapshot.HeadwearID)" />
                                            </div>
                                            <div class="col-8 col-sm-8 clothName p-1 "
                                                *ngIf="snapshot.HeadwearID != undefined && snapshot.HeadwearID != ''">
                                                <img class="gar-icon"
                                                    *ngIf="getGarmentrealtype(snapshot.HeadwearID) == '0' "
                                                    style="width:15px !important; color:black ; opacity: 0.4;"
                                                    src="./assets/newsfeed/headgear-new.png">
                                                <span class="caption-values"
                                                    *ngIf="snapshot.HeadwearID != undefined && snapshot.HeadwearID != ''">
                                                    <span class="garment-name" data-toggle="tooltip"
                                                        data-placement="top" title="Cloth Details"
                                                        (click)="openGarmentPopup('HeadwearID',snapshot.HeadwearID)">
                                                        {{getGarmentName(snapshot.HeadwearID).substring(0,30)}}
                                                    </span>&nbsp;
                                                </span>
                                            </div>
                                        </div>
                                        <div class="col-12 col-sm-12  d-flex" *ngIf="filterSnapShotFileDetails">
                                            <div class="col-2 col-sm-2 ">
                                                <img class="m-1" (click)="openBrandPoup(snapshot.UpperwearID)"
                                                    data-bs-toggle="modal"
                                                    style="width:45px; height:45px !important; color:black ; "
                                                    *ngIf="getGarmentrealtype(snapshot.UpperwearID) == '1' "
                                                    class="rounded profileImg-brand"
                                                    [src]="getBrandImageByGarmetnID(snapshot.UpperwearID)" />
                                                <img class="m-1" (click)="setBrandDetails(snapshot.UpperwearID)"
                                                    data-bs-toggle="modal" data-bs-target="#brand-details"
                                                    (click)="setBrandDetails(snapshot.UpperwearID)"
                                                    data-bs-toggle="modal"
                                                    style="width:45px; height:45px !important; color:black ;"
                                                    *ngIf="getGarmentrealtype(snapshot.UpperwearID) == '5' "
                                                    class="rounded profileImg-brand"
                                                    [src]="getBrandImageByGarmetnID(snapshot.UpperwearID)" />
                                            </div>
                                            <div class="col-8 col-sm-8 clothName p-1"
                                                *ngIf="snapshot.UpperwearID != undefined && snapshot.UpperwearID != ''">
                                                <img class="gar-icon"
                                                    *ngIf="getGarmentrealtype(snapshot.UpperwearID) == '1' "
                                                    style="width:15px !important; color:black; opacity: 0.4;"
                                                    src="./assets/realtype/upperwear.png">
                                                <img class="gar-icon"
                                                    *ngIf="getGarmentrealtype(snapshot.UpperwearID) == '5' "
                                                    style="width:15px !important; color:black ; opacity: 0.4;"
                                                    src="./assets/realtype/fullbody.png">
                                                <span class="caption-values"
                                                    *ngIf="snapshot.UpperwearID != undefined && snapshot.UpperwearID != ''">
                                                    <span class="garment-name" data-toggle="tooltip"
                                                        data-placement="top" title="Cloth Details"
                                                        (click)="openGarmentPopup('UpperwearID',snapshot.UpperwearID)">
                                                        {{getGarmentName(snapshot.UpperwearID).substring(0,30)}}
                                                    </span>&nbsp;
                                                </span>
                                            </div>
                                        </div>
                                        <div class="col-12 col-sm-12 d-flex " *ngIf="filterSnapShotFileDetails">
                                            <div class="col-2 col-sm-2 ">
                                                <img class="m-1" (click)="openBrandPoup(snapshot.BottomwearID)"
                                                    data-bs-toggle="modal"
                                                    style="width:45px; height:45px !important; color:black ; "
                                                    *ngIf="getGarmentrealtype(snapshot.BottomwearID) == '2'"
                                                    class="rounded profileImg-brand"
                                                    [src]="getBrandImageByGarmetnID(snapshot.BottomwearID)" />

                                            </div>
                                            <div class="col-8 col-sm-8 clothName p-1"
                                                *ngIf="snapshot.BottomwearID != undefined && snapshot.BottomwearID != ''">
                                                <img class="gar-icon"
                                                    *ngIf="getGarmentrealtype(snapshot.BottomwearID) == '2'"
                                                    style="width:15px !important; color:black ; opacity: 0.4;"
                                                    src="./assets/newsfeed/bottomwear-new.png">
                                                <span class="caption-values"
                                                    *ngIf="snapshot.BottomwearID != undefined && snapshot.BottomwearID != ''">
                                                    <span class="garment-name" data-toggle="popover"
                                                        data-placement="left" title="Cloth Details"
                                                        (click)="openGarmentPopup('BottomwearID',snapshot.BottomwearID)">
                                                        {{getGarmentName(snapshot.BottomwearID).substring(0,30)}}
                                                    </span>&nbsp;
                                                </span>

                                            </div>
                                        </div>
                                        <div class="col-12 col-sm-12  d-flex" *ngIf="filterSnapShotFileDetails">
                                            <div class="col-2 col-sm-2 ">
                                                <img class="m-1" (click)="openBrandPoup(snapshot.FootwearID)"
                                                    data-bs-toggle="modal"
                                                    style="width:45px; height:45px !important; color:black ;"
                                                    *ngIf="getGarmentrealtype(snapshot.FootwearID) == '3'"
                                                    class="rounded profileImg-brand"
                                                    [src]="getBrandImageByGarmetnID(snapshot.FootwearID)" />
                                            </div>
                                            <div class="col-8 col-sm-8 clothName p-1"
                                                *ngIf="snapshot.FootwearID != undefined && snapshot.FootwearID != ''">
                                                <img class="gar-icon"
                                                    *ngIf="getGarmentrealtype(snapshot.FootwearID) == '3' "
                                                    style="width:15px !important; color:black ; opacity: 0.4;"
                                                    src="./assets/realtype/footwear.png">
                                                <span class="caption-values"
                                                    *ngIf="snapshot.FootwearID != undefined && snapshot.FootwearID != ''">
                                                    <span class="garment-name" data-toggle="popover"
                                                        data-placement="left" title="Cloth Details"
                                                        (click)="openGarmentPopup('FootwearID',snapshot.FootwearID)">
                                                        {{getGarmentName(snapshot.FootwearID).substring(0,30)}}
                                                    </span>&nbsp;
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="!threeDopen"  class="slide-garment  d-block">
                    <div (scroll)="garmentScrollEvent()" #swipeContent class="swipeUp-class-scroll d-flex"
                        *ngIf="scrollSnap != undefined  ">
                        <div [id]="HeadwearID" class="swipeUp-class"
                            *ngIf="scrollSnap.HeadwearID != undefined && scrollSnap.HeadwearID != ''">
                            <app-swipe-popup (scrollValue)="setScrollValue($event)" [key]="HeadwearID"
                                [garmentId]="scrollSnap.HeadwearID">
                            </app-swipe-popup>
                        </div>
                        <div [id]="UpperwearID" class="swipeUp-class"
                            *ngIf="scrollSnap.UpperwearID != undefined && scrollSnap.UpperwearID != ''">
                            <app-swipe-popup (scrollValue)="setScrollValue($event)" [key]="UpperwearID"
                                [garmentId]="scrollSnap.UpperwearID">
                            </app-swipe-popup>
                        </div>
                        <div [id]="BottomwearID" class="swipeUp-class"
                            *ngIf="scrollSnap != undefined && scrollSnap.BottomwearID != ''">
                            <app-swipe-popup (scrollValue)="setScrollValue($event)" [key]="BottomwearID"
                                [garmentId]="scrollSnap.BottomwearID">
                            </app-swipe-popup>
                        </div>
                        <div [id]="FootwearID" class="swipeUp-class"
                            *ngIf="scrollSnap != undefined && scrollSnap.FootwearID != ''">
                            <app-swipe-popup (scrollValue)="setScrollValue($event)" [key]="FootwearID"
                                [garmentId]="scrollSnap.FootwearID">
                            </app-swipe-popup>
                        </div>
                    </div>
                </div>
                <div  class="garment-iocon-set d-block justify-content-center text-center"
                    *ngIf="scrollSnap != undefined">
                    <div class="home-icon-news brand-garment-band-end">
                        <i class="bi bi-house-door-fill home-iocon-garment  " (click)="homeclick()"></i>
                        <img class="rounded-circle m-1" src="./assets/storystyle/home-name.PNG"
                            class="rounded profileImg-brand"
                            style="width: 20px !important;   height: 20px !important ;opacity: 0.5;" />
                    </div>
                    <div *ngIf="scrollSnap.HeadwearID != '' " class="justify-content-center garment-icon-div"
                        [ngClass]="selectedGarmentType == 'HeadwearID'   ? 'floating-menu' : 'brand-garment-band-end'">
                        <img class="sel-garment"
                            (click)="scrollToGarmentId(scrollSnap,scrollSnap.HeadwearID,'HeadwearID')"
                            *ngIf="getGarmentrealtype(scrollSnap.HeadwearID) == '0' && selectedGarmentType != 'HeadwearID'"
                            style="width: 15px !important;   height: 15px !important;     opacity: 0.4;"
                            src="./assets/newsfeed/headgear-new.png">
                        <img class="sel-garment"
                            (click)="scrollToGarmentId(scrollSnap,scrollSnap.HeadwearID,'HeadwearID')"
                            *ngIf="getGarmentrealtype(scrollSnap.HeadwearID) == '0' && selectedGarmentType == 'HeadwearID'"
                            style="width: 15px !important;   height: 15px !important;    "
                            src="./assets/realtype/seleted-icon/headgear-f1f1f1.png">

                        <div class="d-flex justify-content-center text-center">
                            <img (click)="scrollToGarmentId(scrollSnap,scrollSnap.HeadwearID,'HeadwearID')"
                                *ngIf="getGarmentrealtype(scrollSnap.HeadwearID) == '0' "
                                [src]="getBrandImageByGarmetnID(scrollSnap.HeadwearID)"
                                class="rounded-circle sel-brand rounded profileImg-brand"
                                style="width: 20px !important;   height: 20px !important ;opacity: 0.5;" />
                        </div>
                    </div> &nbsp;

                    <div *ngIf="getGarmentrealtype(scrollSnap.UpperwearID) == '1' || getGarmentrealtype(scrollSnap.UpperwearID) == '5'"
                        class="justify-content-center  garment-icon-div"
                        [ngClass]="selectedGarmentType == 'UpperwearID'   ? 'floating-menu' : 'brand-garment-band-end'">
                        <img class="sel-garment"
                            *ngIf="getGarmentrealtype(scrollSnap.UpperwearID) == '1' && selectedGarmentType != 'UpperwearID'"
                            (click)="scrollToGarmentId(scrollSnap,scrollSnap.UpperwearID,'UpperwearID')"
                            style="width: 15px !important;   height: 15px !important; opacity: 0.4;"
                            src="./assets/realtype/upperwear.png">
                        <img class="sel-garment"
                            *ngIf="getGarmentrealtype(scrollSnap.UpperwearID) == '1' && selectedGarmentType == 'UpperwearID'"
                            (click)="scrollToGarmentId(scrollSnap,scrollSnap.UpperwearID,'UpperwearID')"
                            style="width: 15px !important;   height: 15px !important;"
                            src="./assets/realtype/seleted-icon/upperwear-f1f1f1.png">

                        <img class="sel-garment"
                            *ngIf="getGarmentrealtype(scrollSnap.UpperwearID) == '5' && selectedGarmentType != 'UpperwearID' "
                            (click)="scrollToGarmentId(scrollSnap,scrollSnap.UpperwearID,'UpperwearID')"
                            style="width: 15px !important;   height: 15px !important;      opacity: 0.4;"
                            src="./assets/realtype/fullbody.png">

                        <img class="sel-garment"
                            *ngIf="getGarmentrealtype(scrollSnap.UpperwearID) == '5' && selectedGarmentType == 'UpperwearID'"
                            (click)="scrollToGarmentId(scrollSnap,scrollSnap.UpperwearID,'UpperwearID')"
                            style="width: 15px !important;   height: 15px !important; "
                            src="./assets/realtype/selected-icon/fullbody-f1f1f1.png">
                        <div class="d-flex justify-content-center text-center ">


                            <img (click)="scrollToGarmentId(scrollSnap,scrollSnap.UpperwearID,'UpperwearID')"
                                [src]="getBrandImageByGarmetnID(scrollSnap.UpperwearID)"
                                class="rounded-circle sel-brand rounded profileImg-brand"
                                style="width: 20px !important;   height: 20px !important ;opacity: 0.5;" />

                        </div>
                    </div> &nbsp;
                    <div *ngIf="scrollSnap.BottomwearID != '' " class="justify-content-center garment-icon-div  "
                        [ngClass]="selectedGarmentType == 'BottomwearID'    ? 'floating-menu' : 'brand-garment-band-end'">
                        <img class="sel-garment"
                            (click)="scrollToGarmentId(scrollSnap,scrollSnap.BottomwearID,'BottomwearID')"
                            *ngIf="getGarmentrealtype(scrollSnap.BottomwearID) == '2' && selectedGarmentType != 'BottomwearID' "
                            style="width: 15px !important;   height: 15px !important;    opacity: 0.4;"
                            src="./assets/newsfeed/bottomwear-new.png">
                        <img class="sel-garment"
                            (click)="scrollToGarmentId(scrollSnap,scrollSnap.BottomwearID,'BottomwearID')"
                            *ngIf="getGarmentrealtype(scrollSnap.BottomwearID) == '2' && selectedGarmentType == 'BottomwearID'"
                            style="width: 15px !important;   height: 15px !important;   "
                            src="./assets/realtype/seleted-icon/bottomwear-f1f1f1.png">



                        <div class="d-flex justify-content-center text-center">
                            <img (click)="scrollToGarmentId(scrollSnap,scrollSnap.BottomwearID,'BottomwearID')"
                                *ngIf="getGarmentrealtype(scrollSnap.BottomwearID) == '2'"
                                class="rounded-circle sel-brand rounded profileImg-brand"
                                [src]="getBrandImageByGarmetnID(scrollSnap.BottomwearID)"
                                style="width: 20px !important;   height: 20px !important ;opacity: 0.5;" />
                        </div>
                    </div> &nbsp;
                    <div *ngIf="scrollSnap.FootwearID != '' " class="justify-content-center garment-icon-div"
                        [ngClass]="selectedGarmentType == 'FootwearID'    ? 'floating-menu' : 'brand-garment-band-end'">
                        <img class="sel-garment"
                            (click)="scrollToGarmentId(scrollSnap,scrollSnap.FootwearID,'FootwearID')"
                            *ngIf="getGarmentrealtype(scrollSnap.FootwearID) == '3'  && selectedGarmentType != 'FootwearID'  "
                            style="width: 15px !important;   height: 15px !important;    opacity: 0.4;"
                            src="./assets/realtype/footwear.png">

                        <img class="sel-garment"
                            (click)="scrollToGarmentId(scrollSnap,scrollSnap.FootwearID,'FootwearID')"
                            *ngIf="getGarmentrealtype(scrollSnap.FootwearID) == '3' && selectedGarmentType == 'FootwearID'  "
                            style="width: 15px !important;   height: 15px !important;   "
                            src="./assets/realtype/seleted-icon/footwear-f1f1f1.png">




                        <div class="d-flex justify-content-center text-center">
                            <img (click)="scrollToGarmentId(scrollSnap,scrollSnap.FootwearID,'FootwearID')"
                                *ngIf="getGarmentrealtype(scrollSnap.FootwearID) == '3'"
                                class="rounded-circle sel-brand" [src]="getBrandImageByGarmetnID(scrollSnap.FootwearID)"
                                class="rounded profileImg-brand"
                                style="width: 20px !important;   height: 20px !important ;opacity: 0.5;" />
                        </div>
                    </div>&nbsp;
                </div>
                <div class="left-button" (click)="buttonLeft()"></div>
                <div class="right-button" (click)="buttonRight()"></div>
                <div class="horizonatal-button-left" (click)="buttonLeft()"></div>
                <div class="horizonatal-button-right" (click)="buttonRight()"></div>
            </div>
        </div>
        <div  *ngIf="!threeDopen && filterSnapShotFileDetails.length >= 12 && height >= (6120 || 6152 || 6254)"
            class="card justify-content-center more  text-center bg-white">
            <div class="more-btn" (click)="moreButtonForNext()">More</div>
        </div>
    </div>


</div>
<div *ngIf="successMsgs && alertFlag" class="alert alert-dark notify" role="alert">
    <h6 class="alert-message">{{successMsgs}}</h6>
</div>


<!-- <div  [ngClass]="threeDopen == true   ? '' : 'd-none'">
    <div (click)="closeThreeDBUTTON()">X</div>
    <div class="card" #swipeMainContent>
        <app-news3-d-popup [EnvUrl]="envUrl" [GarmentsGender]="genders" [StyleGarmentUrl]="garmenturl" [StyleGarmentBottomWear]="garmentbottom" [AvatarUrl]="this.avtarUrl"
        [Headgear]="headgear" [Footwear]="footwear"
        ></app-news3-d-popup>
    </div>
</div> -->


<!-- <div class="d-none" *ngIf="threeDopen == true">
    <app-news3-d-templete [EnvUrl]="envUrl" [GarmentsGender]="genders" [StyleGarmentUrl]="garmenturl"
        [StyleGarmentBottomWear]="garmentbottom" [AvatarUrl]="this.avtarUrl" [Headgear]="headgear"
        [Footwear]="footwear"></app-news3-d-templete>
</div> -->

<!-- <div  [ngClass]="threeDopen == true   ? '' : 'd-none'">
    <div (click)="closeThreeDBUTTON()">X</div>
    <app-news3-d-templete [EnvUrl]="envUrl" [GarmentsGender]="genders" [StyleGarmentUrl]="garmenturl"
    [StyleGarmentBottomWear]="garmentbottom" [AvatarUrl]="this.avtarUrl" [Headgear]="headgear"
    [Footwear]="footwear"></app-news3-d-templete>
</div> -->