import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';
import { Brand } from 'src/app/models/brand';
import { MatDialog } from '@angular/material/dialog';
import { CommonService } from 'src/app/services/common.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DataSharingService } from 'src/app/services/data-sharing-service';
import { PinterestService } from 'src/app/services/pinterest-service';
import { ApiHttpService } from 'src/app/services/api-http.service';

@Component({
  selector: 'app-public-brand-stories',
  templateUrl: './public-brand-stories.component.html',
  styleUrls: ['./public-brand-stories.component.css']
})
export class PublicBrandStoriesComponent implements OnInit {
  @ViewChild('brandStoriyScroll', { read: ElementRef }) public BrandStoriyScroll: ElementRef<any>;
  @ViewChild("brandSlide") brandSlide: ElementRef;
  @Input() rootFromBrandFlag: any;
  @Input() rootBrandPage: any;
  @Input() routLogInFlag: boolean | undefined;

  // @HostListener('window:scroll', ['$event']) 
  brands = this.firestore.collection('brands').valueChanges({ idField: 'id' }) as Observable<Brand[]>;
  garmentsList = this.firestore.collection('garments').valueChanges({ idField: 'id' }) as Observable<any[]>;
  brandsBoardCollections = this.firestore.collection('brand-board').valueChanges({ idField: 'id' }) as Observable<Brand[]>;
  userFiles = this.firestore.collection("userfiles");
  userFileStateChange = this.userFiles.valueChanges();
  brandBoardList = this.firestore.collection('brand-board').valueChanges({ idField: 'id' }) as Observable<any[]>;
  SnapShotsList = this.firestore.collection('SnapshotDetails').valueChanges({ idField: 'id' }) as Observable<any[]>;
  brandsCollection = this.firestore.collection('brands').valueChanges({ idField: 'id' }) as Observable<Brand[]>;
  brandsCollections = this.firestore.collection('brands').valueChanges({ idField: 'id' }) as Observable<Brand[]>;
  displayedColumns: string[] = ['position', 'name', 'weight', 'symbol'];
  public text = `{ "text": "This is text file!中文" }`;
  public fileName: string | undefined;


  boardDetails: any
  selectedBrand: any;
  getZipcollection: any;
  zipfile: any;
  loading = false;
  brandDetails: any = []
  brandDetailsForStories: any = []
  snapDetails: any = []
  garmentsCollection: any = []
  brandId: any
  brandGarmentIds: any = [];
  SnapShotFileDetails = []
  BrandDetails = []
  snapShotBrandList = [];
  filterStories = []
  storyId: any
  userId: any
  brandIds: any
  boards: any = []
  brandBoard: any = []
  boardNameInBrandStory: any
  brandDetail: any = {}
  showBrandName: boolean = false
  userSnapShotList: any = []
  SnapShotDetails: any = []
  selectedTab: any = "news-stories"
  brandsIDdetailsAll: any = {}
  genderID: any
  counter: number = 12;
  height: any
  loadingforVerticalScroll: boolean = true
  byScrollFlage: boolean = false
  byScroll: boolean = false
  heartFlag: boolean = false
  thresholdHeightPagination: number = 0
  onChangeFlag = true;
  brandNameObject: any = {}
  brandBoardFilter: any = {}
  showBrandFlag: boolean = false
  topScroll: number;
  next: number;
  tabSelecton: string;
  selectedGender: string;
  selectedBrandDeatails: any;
  TabName: string;
  tab: string;
  pageNumber: number = 0;
  clickEventFlag: boolean = false;
  logInFlag: boolean;
  //New

  constructor(private route: ActivatedRoute, private pinterestAPI: PinterestService, private apiHttpService: ApiHttpService, private elementRef: ElementRef,
    private firestore: AngularFirestore, private dataSharingService: DataSharingService, public commonService: CommonService, private router: Router
    , private scrollService: CommonService,

  ) {
    this.route.paramMap.subscribe(paramMap => {
      this.brandId = paramMap.get('brandId')
      this.userId = paramMap.get('id')
      this.storyId = paramMap.get('storyId')
      this.tabSelecton = paramMap.get('select-tab')

      this.selectedGender = paramMap.get('Gender')
      console.log(" this.tabSelecton ", this.tabSelecton);
      this.selectedTab = this.tabSelecton
      this.brandDetail = this.brandDetails.find((bBoard) => bBoard.id == this.brandId)
      this.selectedBrandDeatails = this.brandDetail;
      console.log("this.brandId ", this.brandId)

      if (this.brandId != undefined) {
        this.selectBrandForStory(this.brandId, this)
        this.brandDetail = this.brandDetails.find((bBoard) => bBoard.id == this.brandId)
        this.selectedBrandDeatails = this.brandDetail;
        this.selectetdTab()
        this.getCenter()
      }
    });

    this.changeToSummery()
    this.garmentsList.subscribe((grList) => {
      this.garmentsCollection = grList
      this.loadingforVerticalScroll = false
      this.brands.subscribe((brandList) => {
        this.brandDetails = brandList
        this.brandDetail = this.brandDetails.find((bBoard) => bBoard.id == this.brandId)
        this.selectedBrandDeatails = this.brandDetail;
        let brandIndex = 1;
      })
    })
    this.logInFlag = this.commonService.isLoggedIn()

  }

  ngOnInit() {

    console.log("this.selectedGender==> ", this.selectedGender)
    this.loadingforVerticalScroll = true
    this.pinterestAPI.getBoardList().subscribe((data) => {
      this.boards = data.items;
      this.brandsBoardCollections.subscribe((brandBoard) => {
        this.brandBoard = brandBoard;
        let filterboardID = this.brandBoard.find((b) => b.brandId == this.brandId)
        this.boardDetails = this.boards.find((b) => b.id == filterboardID.boardId)
      });
    })
    this.brandCollecton()
    this.changeTitleOnClick()
    this.changeToSummery()

    console.log('byScroll', this.byScroll)
    setTimeout(() => {
      this.closeButtonForBrand()
    },
      500);
  }

  ngOnChanges(changes: SimpleChanges) {

    this.changeTitleOnClick();
    console.log("changes", changes);
    this.route.paramMap.subscribe(paramMap => {
      this.brandId = paramMap.get('brandId');
      console.log("this.brandId", this.brandId);
      this.storyId = paramMap.get('storyId');
    });

    this.route.queryParams.subscribe(params => {
      const newBrandId = params['brandId'];
      if (newBrandId) {
        this.selectBrandForStory(newBrandId, this);
      }
    });
    if (this.brandId !== undefined) {
      this.selectedBrand = this.brandId;
    }
    if (this.byScroll === true) {
      this.byScrollFlage = true;
    }
  }


  chageBrand(flag) {
    this.selectBrandForStory(flag, flag);
    console.log("flag", flag, this.brandId)
  }
  getCenter() {
    setTimeout(() => {
      this.scrollService.scrollToElementById(this.brandId)
      this.byScroll = false
    },
      500);
    setTimeout(() => {
      this.scrollService.scrollToElementById(this.brandId)
      this.byScroll = false
    },
      600);
  }
  getBrandImage(imgURL: string) {
    let brandEncodedurl = encodeURI(imgURL).replace('/', '%2F');
    let url = "https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/" + brandEncodedurl + "?alt=media&token=550e7e88-bed3-4668-84ff-ad491c19e06c"
    return url;
  }


  selectetdTab() {
    if (this.tabSelecton == 'story') {
      this.selectedTab = "story"
    }
    if (this.selectedGender == 'M') {
      this.selectedTab = "mens-wear"
    }
    if (this.selectedGender == 'F') {
      this.selectedTab = "womens-wear"
    }

  }


  changeTitleOnClick() {
    if (this.brandId != undefined) {
      this.brands.subscribe((brandList) => {
        this.brandDetails = brandList
        let brandDetail = this.brandDetails.find((bBoard) => bBoard.id == this.brandId)
        let title = brandDetail.BrandName + ' Yologram '
        this.commonService.changeTitle(title);
      })



    }
    // if(this.selectedTab == 'mens-wear'){ this.TabName = 'Menswear'}
    // if(this.selectedTab == 'womens-wear'){ this.TabName = 'Womenswear'}
    // if(this.selectedTab == 'news-stories'){ this.TabName = 'Stories'}
    // if(this.selectedTab == 'story'){ this.TabName = 'Stories'}
    // let name = this.tab == 'mens-wear'  ? 'Menswear' : ( this.tab  == 'womens-wear') ? 'Womenswear' : 'Stories' 
    // let title =   this.selectedBrandDeatails.BrandName  + '  ' + this.TabName + ' Yologram '
  }







  clickOnHeart() {
    this.heartFlag = !this.heartFlag
  }
  selectByScroll() {
    this.byScroll = true
  }

  changeToSummery() {
    if (this.storyId == undefined && this.brandId != undefined)
      this.selectedTab = 'story'
    if (this.storyId != undefined && this.brandId != undefined)
      this.selectedTab = 'news-stories'
    if (this.selectedGender == 'M' && this.brandId != undefined)
      this.selectedTab = 'mens-wear'
    if (this.selectedGender == 'F' && this.brandId != undefined)
      this.selectedTab = 'womens-wear'
  }

  closeButtonForBrand() {
    this.byScroll = false
    this.getCenter()
  }
  selectBrandForStory(brandId: any, brands: any) {
    this.byScroll = false
    this.brandId = brandId
    this.selectedBrand = brands
    this.brandDetail = this.brandDetails.find((bBoard) => bBoard.id == this.selectedBrand)
    this.selectedBrandDeatails = this.brandDetail;
    this.selectedTab = "news-stories"
    this.boardDetails = {}
    let brandBoardFilter = this.brandBoard.find((bBoard) => bBoard.brandId == brandId)
    let brandName = this.boards.find((bname) => bname.id == brandBoardFilter.boardId)
    this.boardDetails = brandName
    this.thresholdHeightPagination = 0
    this.next = 1
    this.pageNumber = 1
    this.clickEventFlag = !this.clickEventFlag
    this.addState();

  }
  // scrollToFirst(id: string) {
  //   if(id != '')this.scrollService.scrollToElementById(id);
  // } 


  brandCollecton() {
    let brandsNID = this.brandDetails.filter((nameOfBrand) =>
      nameOfBrand.id === this.garmentsCollection.filter((brandOfGArment) => brandOfGArment.BrandID))
  }

  snapShotInit() {
    this.commonService.snapshotfileDetais().subscribe(async (snapshot: any) => {
      this.SnapShotDetails = snapshot
    })
  }

  setTab(tab) {
    this.selectedTab = "";
    this.selectedTab = tab;
  }

  changeTab(event) {
    this.onChangeFlag = false;
    this.selectedTab = event;
  }

  onScroll() {
    this.height = this.BrandStoriyScroll.nativeElement.scrollTop
  }


  addState() {
    let stateObj = { id: "100" };
    let url = "/brand;brandId=" + this.brandId + ";storyId=" + this.storyId
    window.history.pushState(stateObj, "Page", url);
    // this.selectBrandForStory(this.brandId, this.brandId);
  }
}