<div class="brand-profile-color col-lg-12 scroll container-fluid  ">
        <div class="col-lg-12 m-1 justify-content-center text-center">
            <div class="text-center m-1" *ngIf="brandProfileDetails">
                <div class="text-right">
                    <img class="img-profile img-thumbnail img-fluid rounded profileImg-brand"
                        style="width: 75px; height:75px !important;" [src]="getBrandImage(brandProfileDetails.LogoURL)">
                </div>
                <h3 class="color-class">{{brandProfileDetails.BrandName}}</h3>
                <button *ngIf="brandBoardDetails != undefined" type="button"
                    class="btn btn-sm btn-outline-info m-1 color-class">Follow</button>
                <h6 class="color-class" *ngIf="brandBoardDetails.name != undefined"><img style="width:15px !important"
                        src="./assets/pinterest-512.png">:
                    {{brandBoardDetails.name}}
                </h6>
                <h6 class="color-class" *ngIf="brandBoardDetails.name == undefined"><img style="width:15px !important"
                    src="./assets/pinterest-512.png">:Not Linked to the Board
              
            </h6>
            </div>
            <div class="p-2 m-1 text-center d-flex justify-content-center">
                <h6 class="m-1 text-primary brandbtn color-class"
                    [ngClass]="brandProfileDetails.id == brandId  ? 'border-bottom border-dark' : ''"><b>Story</b></h6>
                <h6 (click)="getBrandStyle(brandProfileDetails,'M')" class="m-1 text-primary brandbtn color-class">
                    Menswear
                </h6>
                <h6 (click)="getBrandStyle(brandProfileDetails,'F')" class="m-1 text-primary brandbtn color-class">
                    Womenswear</h6>
            </div>
            <div *ngIf="snaploader" class="text-center text-primary">
                <div class="spinner-border m-5 p-5" role="status">
                    <span class="sr-only"></span>
                </div>
            </div>
            <div class="row story brand-profile-color my-story " *ngIf="userSnapShotList.length >= 0 && !snaploader">
                <div class="column d-block container" *ngFor="let snapshot of userSnapShotList ; let i=index;">
                    <img class="w-90 shadow-1-strong rounded mb-1" (click)="getFeed(snapshot,brandProfileDetails)"
                        [src]="snapshot.SnapshotFileUrl">
                </div>
            </div>
        </div>
        <div class="col-lg-12 morebtn text-center text-white stify-content-center">
            <h6 (click)=" incrementImgCounter()"><b>More</b></h6>
        </div> 
</div>


