<app-yologram-social *ngIf="logInFlag"></app-yologram-social>
<!-- <div *ngIf="loading" class="loading">&#8230;</div> -->

<!-- lottie-file-templet.component.html -->

<div class="container mt-3">
    <div class="dt-responsive selectlist font-14">
        <div class="dataTables_wrapper dt-bootstrap4" id="add-row-table_wrapper">
            <div class="row">
                <div class="col-md-3 col-6 p-1">
                    <select class="form-select" (change)="selectBrand($event)" [(ngModel)]="selectedBrand">
                        <option selected value="">Select Brand</option>
                        <option [value]="brand.id" *ngFor="let brand of brandList">{{brand.BrandName}}</option>
                    </select>
                </div>
                <div class="col-md-3 col-6 p-1">
                    <select class="form-select" (change)="selectGender($event)" [(ngModel)]="selectedGender">
                        <option selected value="">Gender</option>
                        <option value="M">Menswear</option>
                        <option value="F">Womenswear</option>
                    </select>
                </div>
                <div class="col-md-3 col-6 p-1">
                    <select class="form-select" (change)="selectClothType($event)" [(ngModel)]="selectedClothType">
                        <option selected value="">GarmentType</option>
                        <option value="headgear">Headgear</option>
                        <option value="upperwear">Upperwear</option>
                        <option value="bottomwear">Bottomwear</option>
                        <option value="footwear">Footwear</option>
                    </select>
                </div>
                <div class="col-md-3 col-6 p-1">
                    <select class="form-select" (change)="selectCloth($event)">
                        <option selected value="">Garment</option>
                        <option [value]="garment.id" *ngFor="let garment of filterGarmrntList">{{garment.Name}}</option>
                    </select>
                </div>
                <div class="col-12 d-flex mt-3">
                    <!-- <button type="button" class="btn btn-primary m-1 custom-btn"  (click)="openClothSlider()">View</button> -->
                </div>
            </div>
        </div>
    </div>
</div>

<!-- 
<div *ngIf="this.panelFlag">
    <app-garment-new-panel  [ViewFlage]="ViewFlage"  [ClothId]="selectedCloth" [BrandId]="selectedBrand"></app-garment-new-panel>
</div> -->



    <!-- <div *ngIf="this.garmentObject.Gender =='F'">
        <div class="d-flex my">
            <div class="file womenswear_scroller">
                <div *ngIf="packJsonObject?.length > 0">
                    <div #scrollContainerWHeadGear>
                        <div *ngFor="let object of packJsonObject[0].value['files'];let i=index"
                            id="elementwomens-wearheadgear{{i}}" class="slider-img2-upper">
                            <div class="cloth-text" *ngIf="!zipList?.[object]">
                                Garment Not Available
                            </div>
                            <div *ngIf="zipList?.[object]">
                                <div class="head-div">
                                    <img class="img-responsive-foot"
                                        [src]="sanitizer.bypassSecurityTrustUrl(zipList[object])" />
                                    <img class="profileInfo headgear_info col-sm-2"
                                        style="width:25px; height:25px !important;"
                                        src="/assets/realtype/planinfoicon.png">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div #scrollContainerWUpper class="slider-css2-upper">
                        <div *ngFor="let object of packJsonObject[1].value['files']; let i = index"
                            id="elementwomens-wearupperwear{{i}}" class="slider-img2-upper">
                            <div class="cloth-text" *ngIf="!zipList?.[object.GarmentId]">
                                Garment Not Available
                            </div>
                            <div *ngIf="zipList?.[object.GarmentId]">
                                <div class="upper-div">
                                    <img class="img-responsive-upper"
                                        [src]="sanitizer.bypassSecurityTrustUrl(zipList[object])" />
                                    <img class="profileInfo col-sm-2" style="width:25px; height:25px !important;"
                                        src="/assets/realtype/planinfoicon.png">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div #scrollContainerWBottom class="slider-css2-upper">
                        <div *ngFor="let object of packJsonObject[1].value['files']; let i = index"
                            id="elementwomens-wearbottomwear{{i}}" class="slider-img2-upper">
                            <div class="cloth-text" *ngIf="zipList?.[object]">
                                Garment Not Available
                            </div>
                            <div *ngIf="zipList?.[object]">
                                <div class="bottom-div">
                                    <img class="img-responsive-bottom"
                                        [src]="sanitizer.bypassSecurityTrustUrl(zipList[object])" />
                                    <img class="profileInfo upperwear_info col-sm-2"
                                        style="width:25px; height:25px !important;"
                                        src="/assets/realtype/planinfoicon.png">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div #scrollContainerWFootwear class="slider-css2-upper">
                        <div *ngFor="let object of packJsonObject[1].value['files']; let i = index"
                            id="elementwomens-wearfootwear{{i}}" class="slider-img2-upper">
                            <div class="cloth-text" *ngIf="!zipList?.[object.GarmentId]">
                                Garment Not Available
                            </div>
                            <div *ngIf="zipList?.[object.GarmentId]">
                                <div class="foot-div">
                                    <img class="img-responsive-foot"
                                        [src]="sanitizer.bypassSecurityTrustUrl(zipList[object.GarmentId])" />

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> -->


    <!-- <div #scrollContainerWUpper class="slider-css2-upper">
        <div *ngFor="let object of newPacketJson; let i = index"
            id="elementwomens-wearupperwear{{i}}" class="slider-img2-upper">
            <div class="cloth-text" *ngIf="!zipList?.[object.GarmentId]">
                Garment Not Available
            </div>
            <div *ngIf="zipList?.[object.GarmentId]">
                <div class="upper-div">
                    <img class="img-responsive-upper"
                        [src]="sanitizer.bypassSecurityTrustUrl(zipList[object])" />
                    <img class="profileInfo col-sm-2" style="width:25px; height:25px !important;"
                        src="/assets/realtype/planinfoicon.png">
                </div>
            </div>
        </div> -->

        <div class="center-div">
            <button type="button" class="btn btn-primary m-1 custom-btn"  (click)="openClothSlider()">View</button>

            <div *ngIf="clothLoader" class="spinner-border spn-load text-black loader-cloth" role="status">
            </div>
            <div *ngIf="!newzipLoaded" class="image-garment-text" >Garment not Available</div>
            <div class="upper-div" *ngIf="newzipLoaded !== undefined">
                <img class="img-responsive-upper" [src]="sanitizer?.bypassSecurityTrustUrl(newzipLoaded) != undefined  ? sanitizer?.bypassSecurityTrustUrl(newzipLoaded) : newzipLoaded" />
                <!-- <img class="img-responsive-upper" [src]=" newzipLoaded" /> -->

                <img class="profileInfo col-sm-2" style="width:25px; height:25px !important;" src="/assets/realtype/planinfoicon.png">
            </div>
         
            <div *ngIf="!shaowShaveFlage && imageCashedFlag" class="image-garment-text"> Image Cache</div>
           <div *ngIf="newzipLoaded"> <button *ngIf="shaowShaveFlage && !clothLoader" type="button" class="btn btn-success m-1 custom-btn" (click)="cacheFileUpload()">Save</button>
        </div>
        </div>
        

        <!-- <div class="upper-div" *ngIf="newzipLoaded == undefined ">
           <h6>Garmet is not Available </h6>
        </div> -->