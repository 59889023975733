<!-- <div *ngIf="loading" class="loading">&#8230;</div> -->

<app-yologram-social *ngIf="logInFlag"></app-yologram-social>
<!-- lottie-file-templet.component.html -->
<div class="container mt-3" (click)="closePanel()">
  <div class="dt-responsive selectlist font-14">
    <div class="dataTables_wrapper dt-bootstrap4" id="add-row-table_wrapper">
      <div class="row" (click)="closeGarmentPanel()">
        <div class="col-md-3 col-6 p-1">
          <select class="form-select" (change)="selectBrand($event)" [(ngModel)]="selectedBrand">
            <option selected value="">Select Brand</option>
            <option value="Default">Default</option>
            <option [value]="brand.id" *ngFor="let brand of brandList">{{brand.BrandName}}</option>
          </select>
        </div>
        <div class="col-md-3 col-6 p-1">
          <select class="form-select" (change)="selectGender($event)" [(ngModel)]="selectedGender">
            <option selected value="">Gender</option>
            <option value="M">Menswear</option>
            <option value="F">Womenswear</option>
          </select>
        </div>
        <div class="col-md-3 col-6 p-1">
          <select class="form-select" (change)="selectClothType($event)" [(ngModel)]="selectedClothType">
            <option selected value="">GarmentType</option>
            <option value="headgear">Headgear</option>
            <option value="upperwear">Upperwear</option>
            <option value="bottomwear">Bottomwear</option>
            <option value="footwear">Footwear</option>
          </select>
        </div>
        <div class="col-md-3 col-6 p-1">
          <select class="form-select" [(ngModel)]="selectedCloth" (change)="selectCloth($event)">
            <option selected value="">GarmentName</option>
            <option [value]="garment.id" *ngFor="let garment of filterGarmrntList">{{garment.Name}}</option>
          </select>
        </div>
        <div class="col-12 d-flex mt-3">
          <button type="button" class="btn btn-primary m-1" (click)="openGarmentPanel($event)">View</button>
          <button type="button" class="btn btn-success m-1" (click)="addDefaultBrandsCloth()">Submit</button>
        </div>
      </div>
    </div>
  
    <div class="table-responsive">
      <table class="table">
        <thead class="avtarpage-font">
          <tr>
            <th>Brand</th>
            <th>Gender</th>
            <th style="white-space: nowrap;">Garment Type</th>
            <th class="ms-2"> Garment Name</th>
           <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr class="row-list-detail" *ngFor="let cloth of defaultBrandClothList;let i = index">
            <td class="avtarpage-font">{{getBrandDetails(cloth.BrandId)?.BrandName != undefined
              ?getBrandDetails(cloth.BrandId)?.BrandName : cloth.BrandId }}</td>
            <td class="avtarpage-font">{{ cloth.Gender === 'M' ? 'Menswear' : 'Womenswear' }}</td>
            <td class="catalog-info avtarpage-font">{{cloth.GarmentType}}</td>
            <td class="avtarpage-font" (click)="openGarmentPanelByList(cloth,$event)">
              <div class="text-container">
             <i class="bi bi-eye-fill p-1" (click)="openGarmentPanelByList(cloth,$event)"></i>
             <span>{{getGarmrntDetails(cloth.GarmentId)?.Name != undefined ?
              getGarmrntDetails(cloth.GarmentId)?.Name : cloth.GarmentId }}</span>
            </div></td>
          <td class="d-flex ms-2 avtarpage-font ">
             <i (click)="deleteDefaultBrandsCloth(cloth)" class="bi bi-trash-fill p-1"></i>
             </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>


<div *ngIf="this.panelFlag">
  <app-garment-new-panel [ClothId]="selectedCloth" [BrandId]="selectedBrand"
    [ViewFlage]="ViewFlage" [loading]="loading"></app-garment-new-panel>
</div>