import { Component, OnInit } from '@angular/core';




@Component({
  selector: 'app-login',
  templateUrl: './toast.html',
})
export class ToastComponent implements OnInit {
    pinImgSrc: boolean | undefined;
    ngOnInit(): void {
       
    }
 
}
