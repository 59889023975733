import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/compat/storage';
import { Router } from '@angular/router';
import { Observable, catchError, finalize, map, tap, throwError } from 'rxjs';
import { Brand } from 'src/app/models/brand';
import { CommonService } from 'src/app/services/common.service';
import * as JSZip from 'jszip/dist/jszip';
import { generateUUID } from 'three/src/math/MathUtils';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-edit-news-garment-panel',
  templateUrl: './edit-news-garment-panel.component.html',
  styleUrls: ['./edit-news-garment-panel.component.css']
})
export class EditNewsGarmentPanelComponent implements OnInit, OnChanges {
  @Input() brandObject: any = {};
  @Input() garmentId: any;
  @Input() typeOfGarment: any;
  @Input() typeWear: any;
  @Input() selectedSnapShot: any;
  @Input() brandUrl: any;
  @Input() garmentData: any;
  @Input() source: any;

  @Output() modalpopupEmitter = new EventEmitter<any>();
  @Output() threedtuploadEmitter = new EventEmitter<any>();
  @Output() thowduploadEmitter = new EventEmitter<any>();




  

  @Input() RealType: any;
  
  private loadTimes: { [key: number]: number } = {};

  



  brandheartFlag: boolean = false;
  tab: any = 'story';


  garmentsCollection: any[] = [];
  brandsCollection: any[] = [];
  brandLogourl: string;
  selectedBrandId: any;
  swichImageFlage: boolean = true;
  isloading: boolean;
  clothDetails: any;
  // source: any;
  style: string = 'treads';
  garmentScrollFlag: boolean;
  flipFlag: boolean;
  selectedIcon: string = 'popup';
  iconselctFlag: boolean;
  userUploadedFileList: any  = [];
  userFileList: any = [];
  userFiledetails3d: any = [];
  showMore: boolean = false;
  pageNumberGarment: number = 1;



  images = [
    { image: './assets/garment-upload-dummy/img-1.jpg' },
    { image: './assets/garment-upload-dummy/img-2.jpg' },
    { image: './assets/garment-upload-dummy/img-3.jpg' },
    { image: './assets/garment-upload-dummy/img-4.jpg' },
    { image: './assets/garment-upload-dummy/img-5.jpg' },
    { image: './assets/garment-upload-dummy/img-6.jpg' },
    { image: './assets/garment-upload-dummy/img-7.jpg' },
    { image: './assets/garment-upload-dummy/img-8.jpg' },
    { image: './assets/garment-upload-dummy/img-9.jpg' },
  ];
  dummyText: string;
  collectionData: any[];
  collectionDetails: any;
  brandStoryScrollerHeight: number;
  sideNavHeight: number;
  userSnapShotListGarment: any =[];
  loading: boolean;
  garmentLink: string;
  packJsonObject =[];
  zipList: any ={};
  testVar: string;
  brandlogUrl: any;
  result: string;
  selectedgender: string;
  pageNumberBrand: any;
  selectedImageIndex: number;
  
  clothId: any;
  uploadFileName: any;
  envName: string;
  file: any;
  task!: AngularFireUploadTask;
  percentage!: Observable<number | undefined>;
  userid: any;
  hideName: boolean;
deleteflag: boolean = true;



  constructor(
    private commonService: CommonService,
    private firestore: AngularFirestore,
    private http: HttpClient,
    public datepipe: DatePipe,
    public router: Router,private storage: AngularFireStorage,private _httpClient: HttpClient,
  ) { 

    this.commonService.getUser().subscribe((user: any) => {
      this.userid = user?.uid;
    })
  }

  ngOnInit() {

    const text =
    "Your brand's apparel is digitised in 3D.\n\nOn a gamified experience, consumers style avatars & share\n\nanimated avatar stories (linked to ecommerce)\n\nDefining a new paradigm in immersive storytelling for social media & the metaverse";

  const lines = text.split('\n\n');
  this.dummyText =
    lines[0] + '\n' + lines[1] + '\n' + lines[2] + '\n' + lines;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['garmentId']) {
      this.loadGarmentData();
      console.log('selectedSnapShot',this.selectedSnapShot)
      console.log('brandUrl',this.brandUrl)
    }

    if (changes['garmentData']) {
      console.log('garmentData garmentData garmentData',this.garmentData)
    }

  }



  getLoadTimes() {
    return this.loadTimes;
  }

  





  async loadGarmentData() {
    if (this.garmentData.length !== 0) {
      await this.processGarmentData(this.garmentData);
    } else {
      this.garmentData = await this.garmentPanelDataFunction(this.garmentId);
      await this.processGarmentData(this.garmentData);
    }
    if (this.garmentData) {
      let filePathNewTemps = this.garmentData.userFiles.find(file => file.filename == this.clothDetails.filename && file.fileType == 'Garment360Cache');
      console.log("filePathNewTemps......................",filePathNewTemps)
      if (filePathNewTemps) {
        this.loading = false;
        const storageRef = this.storage.ref(filePathNewTemps.filepath);
        try {
          const url = await storageRef.getDownloadURL().pipe(finalize(() => {})).toPromise();
          console.log('Download URL:', url);
          console.log('Download this.source:', this.source);
          this.source = url;
          if (url.startsWith('https://storage.googleapis.com/')) {
            let filePathNew = this.userUploadedFileList.find(file => file.filename == this.clothDetails.id && file.fileType == 'Garment360Cache');
            if (filePathNew) {
              try {
                // Query the Firestore collection to find the document
                const querySnapshot = await this.firestore.collection('userfiles', ref => 
                  ref.where('filename', '==', this.clothDetails.id)
                     .where('fileType', '==', 'Garment360Cache')
                ).get().toPromise();
                if (!querySnapshot.empty) {
                  querySnapshot.forEach(async (doc) => {
                    await doc.ref.update({ fileurl: url });
                    console.log('File URL updated in Firestore:', doc.id);
                  });
                  this.source = url;
                } else {
                  console.error('No matching documents found in Firestore');
                }
              } catch (error) {
                console.error('Error updating file URL in Firestore:', error);
              }
            } else {
              this.source = url;
            }
          } else {
            console.error('URL does not start with https://storage.googleapis.com/');
          }
        } catch (error) {
          console.error('Error getting download URL:', error);
        }
      } else {
        console.error('File with specified criteria not found in userFiles');
      }
    }
  }
  
  async processGarmentData(garmentData) {
    this.clothDetails = garmentData.garment;
    let filePathNewTemp = garmentData.userFiles.find(file => file.filename == this.clothDetails.id && file.fileType == 'Garment360Cache');
    
    console.log(filePathNewTemp)
    if(filePathNewTemp == undefined){
      await this.fileUrlUpadate(this.clothDetails);
    }

    this.source = filePathNewTemp ? filePathNewTemp.fileurl : null;
    this.brandObject = garmentData.brand;
    this.brandlogUrl = garmentData.brandLogoUrl;
    this.userSnapShotListGarment = garmentData.snapshots.object;
    this.tab = this.clothDetails.Gender === 'M' ? 'mens-wear' : 'womens-wear';
  
    let filelist2d = garmentData.userFiles.filter(uploadlist => uploadlist.link == this.garmentId && uploadlist.fileType == 'GarmentUpload2D');
    let filelist3d = garmentData.userFiles.filter(uploadlist => uploadlist.link == this.garmentId && uploadlist.fileType == 'GarmentUpload3D');
    this.userFileList = filelist2d;
    this.userFiledetails3d = filelist3d;



    const storageRef = this.storage.ref(filePathNewTemp.filepath);
    const url = await storageRef.getDownloadURL().pipe(finalize(() => {})).toPromise();
    this.source = url

   

    if (filePathNewTemp) {
      const storageRef = this.storage.ref(filePathNewTemp.filepath);
      console.log("storageRef",storageRef)
      storageRef.getDownloadURL().subscribe({
        next: (downloadUrl) => {
          console.log("Download URL:", downloadUrl);
        },
        error: async (error) => {
          if (error.code === 'storage/object-not-found') {
            await this.fileUrlUpadate(this.clothDetails);
            console.error("File not found:", filePathNewTemp.filepath);
          } else {
            console.error("Error fetching download URL:", error);
          }
        }
      });
    } else {
      console.error('File with specified criteria not found in userFiles during processGarmentData');
    }
  }
  




















  fetchGarmentData(fileType: string, garmentId: string): void {
    this.commonService.getGarmentPanelData(fileType, garmentId)
      .subscribe(
        data => {
          this.brandObject = data.brand
          this.clothDetails = data.garment
          this.brandlogUrl =  data.brandLogoUrl
          this.userSnapShotListGarment = data.snapshots.object
          console.log('Data fetched:', data);
        },
        error => {
          console.error('Error fetching data:', error);
        }
      );
  }
 


  
  filterCollectionName(id) {
    let garmentCollectionId = (
      this.collectionDetails != undefined && this.collectionDetails
    ).find((colection) => colection.id === id);
    let getCollection = this.collectionData.find(
      (colection) =>
        colection.id === garmentCollectionId.GarmentCollectionID &&
        colection.status == 'A'
    );
    let name =
      getCollection != undefined && getCollection.CollectionName != undefined
        ? getCollection.CollectionName
        : '';
    return name;
  }





  async garmentPanelDataFunction(garmentId){
    let functionUrl = "https://us-central1-yolomoves-fb435.cloudfunctions.net/getGarmentPanelData_v2";
    return this.http.post<any>(functionUrl, { garmentId: garmentId,fileType: this.typeWear }).toPromise();
  }


  clickOnHeart(event: any) {
    event.stopPropagation();
    this.brandheartFlag = !this.brandheartFlag;
  }

  shareBrand() {
    let garmentObjet = this.brandObject.clotObject;
    let brandId = garmentObjet.BrandID;
    let mode = this.tab === 'womens-wear' ? 'W' : this.tab === 'mens-wear' ? 'M' : 'S';
    let link = "https://yolomove-social-live.web.app/threads;brandId=" + brandId + ';M=' + mode + ';Ex=Y';
    // let text = 'Check out '+ this.brandNameSelected +' on Yologram '
    // console.log(text)
    if (navigator.share) {
      navigator.share({
        title: 'Share Brand',
        text: 'Check out ' + this.brandObject.BrandName + ' on Yologram ',
        url: link,
      })
        .then(() => console.log('Successful share'))
        .catch((error) => console.log('Error sharing', error));
    }
  }


  
  callGarmentCloth() {
    let garmentObjet = this.clothDetails;
    let gender = garmentObjet.Gender == 'F' ? 'W' : 'M';
    const dataToSend = {
      garmentId: garmentObjet.id,
      zipSource: this.source,
      typeWear: this.typeWear,
      type: this.typeOfGarment,
         Drap:'Y'
    };
    this.commonService.setSharedObject(dataToSend);
    this.selectedBrandId = garmentObjet.BrandID
    this.router.navigate([
      '/threads',
      {
        brandId: this.selectedBrandId,
        M: gender,
        Ex: 'N',
        garmentId: garmentObjet.id,
        Drape:'Y'
      },
    ]);
  }


  callGarmentClothName() {
    let garmentObjet = this.clothDetails;
    let gender = garmentObjet.Gender == 'F' ? 'W' : 'M';
    const dataToSend = {
      garmentId: garmentObjet.id,
      typeWear: this.typeWear,
      type: this.typeOfGarment,
      Drap:'N'
    };

    this.openopup()
    this.commonService.setSharedObject(dataToSend);
    this.selectedBrandId = garmentObjet.BrandID
    // this.router.navigate([
    //   '/threads',
    //   {
    //     brandId: this.selectedBrandId,
    //     M: gender,
    //     Ex: 'N',
    //     garmentId: garmentObjet.id,
    //     Drape:'N'
    //   },
    // ]);
  }

  setSelectedGarmetCloth(event) {
    if (event == 'Cloth') {
      this.callGarmentCloth()
    }
  }

  switch3DImage(event: any) {
    event.stopPropagation();
    this.swichImageFlage = !this.swichImageFlage;
  }

  flipClose() {
    this.flipFlag = false;
    this.garmentScrollFlag = false;
  }
  selectIcon(icon) {
    this.selectedIcon = '';
    this.selectedIcon = icon;
    this.iconselctFlag = true;
  }

  setBrandStoryScrollerHeight() {

    this.sideNavHeight = window.innerHeight
    this.brandStoryScrollerHeight = window.innerHeight - 270;

  }

  incrementImgGarmentCard() {

    this.pageNumberGarment++;
    let reqInputObject = {
      fileType: this.typeWear,
      id: this.garmentId,
      pageNumber: this.pageNumberGarment,
    };
    this.commonService
      .yologramSnapshotDetailsByType(reqInputObject)
      .subscribe(async (response: any) => {
        this.userSnapShotListGarment.push(...response.object);
      });
  }

  async fileUrlUpadate(data){
    let garment = data
    let zipNmae = 'pack0';
    let packlocation =
      'AvatarBlenderClothes%2F' +
      this.clothDetails.BrandID +
      '_' +
      garment.Gender +
      '%2F' +
      zipNmae;
      let garmentLink =
      'https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/' +
      packlocation +
      '?alt=media&token=2c1dc261-ac91-4c32-a5ec-ac5e06f0a9bc&_gl=1*goch74*_ga*MjExMjI9MTIyMi4xNjg3NTA3MDk1*_ga_CW55HF8NVT*MTY5NjkzMDY1Ny4xNTcuMS4xNjk2OTM2NzA4LjU5LjAuMA..';
    const url = garmentLink;
    const cloth = garment.id;
    
    await this.commonService.downloadAndProcessPack(url, cloth).subscribe({
      next: (response) => console.log('Success:', response),
      error: (error) => console.error('Error:', error)
    });

  }

  navigateOnThread() {

      this.router.navigate([
        '/threads',
        { brandId: this.brandObject.id, M: 'S', Ex: 'N' },
      ]);
   
    }


    
  setTab(selectedtab: any, gender) {
    this.tab = selectedtab;
    this.router.navigate([
      '/threads',
      { brandId: this.brandObject.id, M: gender, Ex: 'N' },
    ]);
  }


  selectGarmentImage(index: number, snapObjet) {
    this.selectedImageIndex = index;
    let garmentObjet = this.clothDetails
    this.selectedBrandId = this.brandObject.id
    const dataToSend = {
      garmentId: garmentObjet.id,
      zipSource: this.source,
      typeWear: this.typeWear,
      type: this.typeOfGarment,
      selectImageFunction: true
    };
    this.commonService.setSharedObject(dataToSend);
    this.commonService.setIndex(index);
    let page = this.pageNumberBrand;
    let count = 12
    const currentPage = Math.floor(index / count) + 1;
    this.selectedgender = this.tab === 'mens-wear' ? 'M' : (this.tab === 'womens-wear' ? 'W' : '');
    this.router.navigate([
      '/threads',
      {
        brandId: this.selectedBrandId,
        M: this.selectedgender,
        Ex: 'N',
        storyId: snapObjet.StoryID,
        garmentId: garmentObjet.id,
        Drape:'N',
        'index':index,
        'pageNumber':currentPage
      },
    ]);
  }





  
  files: File[] = [];

  start3DFileUpload($event: any) {
    // if (this.envName != '' || this.envName != undefined) { return }
    this.clothId = this.clothDetails.id
    let uploadFile = $event.target.files[0];
    this.uploadFileName = uploadFile.name;
    let ext = this.uploadFileName.split('.')[1];
    let uploadCustomerFilename = this.envName + "." + ext
    const pathEvn = `Uploads/${this.clothId}/${this.uploadFileName}`;

    const lastIndex =  this.uploadFileName.lastIndexOf('.');
    const fileNameWithoutExtension = lastIndex !== -1 ?  this.uploadFileName.slice(0, lastIndex) :  this.uploadFileName;

    const filename = `${this.file}`;
    const ref = this.storage.ref(pathEvn);
    this.task = this.storage.upload(pathEvn, uploadFile);
    this.percentage = this.task.percentageChanges();
    let new_date = new Date();
    const date = this.datepipe.transform(new_date, 'd MMM, y, h:mm:ss a');
    const uid = generateUUID();
    let uploadObject = {
      id: uid,
      filename:this.envName != ''  ? this.envName  : fileNameWithoutExtension,
      fileurl: '',
      filepath: pathEvn,
      date: date,
      fileType: 'GarmentUpload3D',
      userId: this.userid,
      link: this.clothId,
    }
    this.task.then(async snapshot => {
      let downloadURLTest = ref.getDownloadURL();
      await downloadURLTest.subscribe(url => {
        uploadObject.fileurl = url
        // console.log("Object adding ",uploadObject);
        // this.db.collection('userfiles').add(uploadObject).then((docRef) => {
        //   console.log("File uploaded", docRef);
        // });
        this.firestore.collection('userfiles').add(uploadObject);
      });
    })
  }

  setEnvName($event) {
    this.hideName = true
    this.envName = $event.target.value;
    console.log("FileName", this.envName)
  }

  clear() {
    this.percentage = new Observable<undefined>();
    this.envName = ""
    this.uploadFileName = ""
    if (this.percentage) {
      this.envName = ''
    }
  }

  async  getThumbnailForVideo(videoUrl: string): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const video = document.createElement('video');
      const canvas = document.createElement('canvas');
      video.style.display = 'none';
      canvas.style.display = 'none';
  
      // Set crossOrigin attribute to handle cross-origin video sources
      video.crossOrigin = 'anonymous';
  
      document.body.appendChild(video);
      document.body.appendChild(canvas);
  
      video.addEventListener('loadedmetadata', () => {
        video.width = video.videoWidth;
        video.height = video.videoHeight;
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        video.currentTime = video.duration * 0.25;
      });
  
      video.addEventListener('seeked', () => {
        const ctx = canvas.getContext('2d');
        if (ctx) {
          ctx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
  
          // Draw play button watermark
          const playButtonSize = Math.min(video.videoWidth, video.videoHeight) / 4;
          const centerX = video.videoWidth / 2;
          const centerY = video.videoHeight / 2;
  
          // Draw circle
          ctx.fillStyle = 'rgba(0, 0, 0, 0.5)';
          ctx.beginPath();
          ctx.arc(centerX, centerY, playButtonSize / 2, 0, 2 * Math.PI);
          ctx.fill();
  
          // Draw triangle (play icon)
          ctx.fillStyle = 'white';
          ctx.beginPath();
          ctx.moveTo(centerX - playButtonSize / 6, centerY - playButtonSize / 4);
          ctx.lineTo(centerX - playButtonSize / 6, centerY + playButtonSize / 4);
          ctx.lineTo(centerX + playButtonSize / 3, centerY);
          ctx.closePath();
          ctx.fill();
  
          const imageUrl = canvas.toDataURL('image/png');
          document.body.removeChild(video);
          document.body.removeChild(canvas);
          resolve(imageUrl);
        } else {

          document.body.removeChild(video);
          document.body.removeChild(canvas);

          reject(new Error('Unable to get canvas context'));
        }
      });
  
      video.addEventListener('error', (e) => {
        document.body.removeChild(video);
        document.body.removeChild(canvas);
        reject(e);
      });
  
      video.src = videoUrl;
    });
  }
  async start2DFileUpload(event: any) {
    this.clothId = this.clothDetails.id
    const uploadFile = event.target.files[0];
    if (!uploadFile) return;
    
    this.uploadFileName = uploadFile.name;
    let ext = this.uploadFileName.split('.').pop().toLowerCase();
    const lastIndex = this.uploadFileName.lastIndexOf('.');
    const fileNameWithoutExtension = lastIndex !== -1 ? this.uploadFileName.slice(0, lastIndex) : this.uploadFileName;

    const pathEvn = `Uploads/${this.clothId}/${this.uploadFileName}`;
    const ref = this.storage.ref(pathEvn);
    

    let new_date = new Date();
    const date = this.datepipe.transform(new_date, 'd MMM, y, h:mm:ss a');
    const uid = generateUUID();
    let uploadObject = {
      id: uid,
      filename: this.envName !== '' ? this.envName : fileNameWithoutExtension,
      fileurl: '',
      fileurlMini: '',
      filepath: pathEvn,
      date: date,
      fileType: 'GarmentUpload2D',
      userId: this.userid,
      link: this.clothId,
    };

    try {
      // Check if file already exists
      const existingFiles = await this.firestore.collection('userfiles', ref => ref.where('filepath', '==', pathEvn)).get().toPromise();
      if (!existingFiles.empty) {
        alert('File already exists. Upload aborted.')
        console.log('File already exists. Upload aborted.');
        return;
      }
    } catch (error) {
      console.error('Error checking existing files:', error);
    }
    this.task = this.storage.upload(pathEvn, uploadFile);
    this.percentage = this.task.percentageChanges()

    const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp'];
    const videoExtensions = ['mp4', 'avi', 'mov', 'wmv', 'flv'];
    let fileType;

    if (imageExtensions.includes(ext)) {
      console.log('File is an image:', this.uploadFileName);
      fileType = 'image';
    } else if (videoExtensions.includes(ext)) {
      console.log('File is a video:', this.uploadFileName);
      fileType = 'video';
    } else {
      console.log('File type is unknown:', this.uploadFileName);
    }

    this.task.snapshotChanges().pipe(
      tap(console.log),
      finalize(async () => {
        const downloadURLTest = ref.getDownloadURL().toPromise();
        uploadObject.fileurl = await downloadURLTest;

        if (fileType === 'video') {
          try {
            const thumbnailUrl = await this.getThumbnailForVideo(uploadObject.fileurl);
            const modifiedFileName = this.uploadFileName.replace(/\.[^/.]+$/, "_thumbnail.png");
            const compressedFilePath = `Uploads/${this.clothId}/${modifiedFileName}`;
            const thumbRef = this.storage.ref(compressedFilePath);
            await thumbRef.putString(thumbnailUrl, 'data_url');
            uploadObject.fileurlMini = await thumbRef.getDownloadURL().toPromise();

            if (uploadObject.fileurlMini) {
              console.log("uploadObject", uploadObject);
              const docRef = await this.firestore.collection('userfiles').add(uploadObject);
              await docRef.update({ '2DType': 'Video' });
            } else {
              console.error('File URL Mini is undefined');
            }
          } catch (error) {
            console.error('Error generating or uploading thumbnail:', error);
          }
        } else {
          const modifiedFileName = this.uploadFileName.replace(/\.[^/.]+$/, "_128x128$&");
          const compressedFilePath = `Uploads/${this.clothId}/${modifiedFileName}`;
          let functionUrl = "https://us-central1-yolomoves-fb435.cloudfunctions.net/compressUploadsFiles";
          try {
            const response = await this.http.post(functionUrl, {
              imageUrl: uploadObject.fileurl,
              path: compressedFilePath
            }).toPromise();
            uploadObject.fileurlMini = response['fileurlMini'];
            console.log("uploadObject.fileurlMini", uploadObject.fileurlMini);

            if (uploadObject.fileurlMini) {
              console.log("uploadObject", uploadObject);
              await this.firestore.collection('userfiles').add(uploadObject);
            } else {
              console.error('File URL Mini is undefined');
            }
          } catch (error) {
            console.error('Error compressing file:', error);
          }
        }
      })
    ).subscribe();
  }


  openopup() {
    this.modalpopupEmitter.emit('s')
  }
  openothreed() {
    this.threedtuploadEmitter.emit('s')
  }
  openothwod() {
    this.thowduploadEmitter.emit('s')
  }
}
