import { Component } from '@angular/core';

@Component({
  selector: 'app-common-news-feed',
  templateUrl: './common-news-feed.component.html',
  styleUrls: ['./common-news-feed.component.css']
})
export class CommonNewsFeedComponent {
  images1 = "./assets/user-upload-dummy/img-3.jpg"
  images2 = "./assets/user-upload-dummy/img-3.jpg"
  images3 = "./assets/user-upload-dummy/img-3.jpg"
  images4 = "./assets/user-upload-dummy/img-3.jpg"
}
