import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-drips-image-templete',
  templateUrl: './drips-image-templete.component.html',
  styleUrls: ['./drips-image-templete.component.css']
})
export class DripsImageTempleteComponent {
  @Input() snaphot: any;
  @Input() flagForShowThreeJs: boolean;
  @Input() findFlag: boolean;
  @Input() passId: any;
  @Input() EnvUrl: any | undefined;
  @Input() AvatarUrl: any;
  @Input() vrmAvatarUrl: any;
  @Input() flagForThreeJs: boolean;
  @Input() upperwearUrl: any;
  @Input() headgearUrl: any;
  @Input() bottomwearUrl: any;
  @Input() footwearUrl: any;

  @Input() GarmentsGender: any;
  @Input() Loading: boolean;
  @Input() StoryID: any;
  @Input() BlobUrl: any;
  @Input() FunctionFlag: any;
  @Input() bvhAnimationUrl: any;
  @Input() functionflagValue: any;
  errorMessage: any = '';

  



  setEroorMessage(message) {
    this.errorMessage = message
  }

  



}
