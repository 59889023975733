<div *ngIf="loading" class="loading">&#8230;</div>
<app-yologram-social *ngIf="logInFlag"></app-yologram-social>

<div class="container m-1">
  <!-- Bootstrap Card -->
  <div class="card">
    <div class="card-body p-1">
      <!-- Input and Add Button Row -->
      <div class="row mb-3">
        <div class="col">
          <input type="text" class="form-control" placeholder="Enter BrandPortFolio..." 
                 [(ngModel)]="portFolioName" (keypress)="preventSpacesAndDots($event)" (paste)="onPaste($event)">
      </div>
        <div class="col-auto">
          <button class="btn btn-primary" (click)="addportfolios()">Add</button>
        </div>
      </div>

      <!-- Dropdown and Delete Button Row -->
      <div class="row">
        <div class="col">
          <select class="form-select" [(ngModel)]="selectedPortfolioId" (change)="onSelectPortfolio($event)">
            <option value="" selected>Select BrandPortfolio</option>
            <option [value]="portfolio.BrandPortfolioId" *ngFor="let portfolio of brandPortfolioList">
              {{ portfolio.BrandPortfolioName }}
            </option>
          </select>
        </div>
        <div class="col-auto">
          <button class="btn btn-danger" (click)="deletePortfolio()">Delete</button>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="col-auto text-center d-flex justify" *ngIf="selectedPortfolioId">
  <h6 class="m-1 link-text"  (click)="copyToClipboard()" > Copy link </h6>
  <h6 class="m-1 link-text"  (click)="copyPortfolio()"> GO </h6>
</div>

<div class="col-8 p-3 m-2">
  <table class="table " *ngIf="brandList.length > 0" id="dataTable" width="100%" cellspacing="0">
    <thead class="custom-header">
      <tr role="row">
        <th>Sr. No.</th>
        <th>Brand Logo</th>
        <th>Brand Name</th>
        <th>Actions</th>
      </tr>
    </thead>
    <tbody>
      <!-- <tr class="row-list-detail" *ngFor="let brand of brandList ;let i=index">
        <td>{{i+1}}</td>
        <td class="">
          <img class="m-1" style="height:30px !important; width:30px !important;"
            [src]="getBrandImage(brand.LogoURL)" />
        </td>
        <td class=""> {{brand.BrandName}} </td>
        <td class="catalog-info ">
          <div class="form-check" *ngIf="selectedPortfolioId" this.selectedPortfolioId>
            <input class="form-check-input m-1" style="box-shadow: 0 0 0 0.5px black; /* Solid border look */"
              id="flexCheckDefault" (change)="updateBrandStatus($event, getBrandRoleId(brand.id), brand)"
              type="checkbox" [checked]="getBrandRoleId(brand.id) != undefined ? true : false">

          </div>
        </td>
      </tr> -->

      <tr class="row-list-detail" *ngFor="let brand of brandList ;let i=index">
        <td>{{i+1}}</td>
        <td class="">
          <img class="m-1" style="height:30px !important; width:30px !important;"
            [src]="getBrandImage(brand.LogoURL)" />
        </td>
        <td> {{brand.BrandName}} </td>
        <td class="catalog-info ">
          <div class="form-check" *ngIf="selectedPortfolioId">
            <input class="form-check-input m-1" style="box-shadow: 0 0 0 0.5px black;"
              id="flexCheckDefault-{{brand.id}}" (change)="updateBrandStatus($event, getBrandRoleId(brand.id), brand)"
              type="checkbox" [checked]="getBrandRoleId(brand.id) != undefined">
          </div>
        </td>
      </tr>
      
    </tbody>
  </table>
</div>