<div class="card m-3 crd-margin brand-profile-color" *ngIf="userSnapShotList">
    <div class="col-lg-12 m-3 justify-content-center text-center">
        <div *ngIf="isLoadingforUserDetails">
            <div class="spinner-border" role="status">
            </div>
        </div>
        <div class="text-start" *ngIf="userDetails">
            <img (click)="getSnapShotListByUserID(userDetails.uid)"
                class="img-profile img-thumbnail img-fluid rounded-circle m-2 profileImg"
                style="width: 70px; height:70px !important;  margin-left: 16px;" [src]="userDetails.photoURL">
            <h5 class="avatr color-class " (click)="getSnapShotListByUserID(userDetails.uid)">{{userDetails.displayName}}</h5>
            <span *ngIf="avatarIdshide">
                <h6 class="avatr color-class-2" *ngIf="avatarId != undefined">🧍🏽{{avatarId}}</h6>
            </span>
        </div>
        <div class="p-1 m-1 text-start d-block">
            <div> <button type="button" class="btn btn-outline-info m-2 color-class-2">Follow</button></div>
            <div *ngIf="avatarId != undefined">
                <button type="button" class="btn btn-outline-info m-2 color-class-2"
                    (click)="getSnapShotListavtarid(avatarId)">Avtar Feed</button>
            </div>
        </div>
        <div class="row d-flex">
            <div data-bs-dismiss="modal"
                class="text-center col-6">
                <span>
                    <div >
                        <img class="img-fluid img-thumbnail" style="height: 100px; width: 100px !important; object-fit: contain;
                    " src="./assets/imgplaceholder.jpg">
                    </div>
                </span>
            </div>
            <div data-bs-dismiss="modal"
                class="text-center col-6">
                <span>
                    <div >
                        <img class="img-fluid img-thumbnail" style="height: 100px; width: 100px !important; object-fit: contain;
                    " src="./assets/imgplaceholder1.jpg">
                    </div>
                </span>
            </div>
        </div>
        
        <div class="justify-content-center story-center d-flex">
            <h6  class="m-2 text-primary brandbtn color-class">
                <img (click)="getSnapShotListByUserID(userDetails.uid)" style="width:15px !important; background-color:black"
                    src="./assets/storystyle/story-white.png">
            </h6>
        </div>
        <div class="row height-avatr-id mb-5 justify-content-center">
            <div class="column d-block" *ngFor="let snapshot of userSnapShotList ; let i=index;">
                <img class="w-90 shadow-1-strong rounded m-2 " (click)="getSelectSnapshotFeed(snapshot)"
                    [src]="snapshot.SnapshotFileUrl">
            </div>
        </div>
    </div>
</div>