
<div class="card m-3 container slider-m-div">
    <div [ngClass]="threeDopen == true   ? '' : 'd-none'" >
        <div  [ngClass]="threeDopen == true   ? '' : 'd-none'"  style="width: 290px; height: 470px;"> 
            <div (click)="closeThreeDBUTTON()">X</div>
            <app-news3-d-templete [EnvUrl]="envUrl" [GarmentsGender]="genders" [upperwearUrl]="garmenturl"
            [bottomwearUrl]="garmentbottom" [AvatarUrl]="this.avtarUrl" [headgearUrl]="headgear"
            [footwearUrl]="footwear"></app-news3-d-templete>
        </div>
    </div>
    <div #verticalSCrolls [ngClass]="threeDopen == false   ? '' : 'd-none'"  *ngIf="SnapshotDetails && SnapshotDetails.length > 0" class="allImage vertical-scroll-container"
        (scroll)="onVerticalScroll()">
        <div class="scroll-item d-flex" *ngFor="let snaphot of SnapshotDetails  let i = index; trackBy: trackBySnapshot" >
            <div #horizontalScroll id="imgrt" class="itemType horizontal-scroll-container"    
                id="horizontal-scroll-container" >
                <div class="scroll-item">
                    <div class="col-sm-12 d-flex">
                        <div class="user-name">
                            <div class="text-left font-weight-bold"><a
                                    (click)="openUserPopup(snaphot)">{{snaphot.profileName}}</a></div>
                            <div class="text-left date-class">{{snaphot.timestamp | date : 'MMM d, y, h:mm a'}}</div>
                        </div>
                        <div class="user-avtar ">
                            <div class="text-right date-class" (click)="openAvatarPopUp(snaphot,snaphot.AvatarID)">
                                🧍🏽{{snaphot.firstName}}{{snaphot.AvatarLastFive}}</div>
                            <div class="text-right date-class" (click)="environmentPopup(snaphot.Environment)">
                                {{getEnvironmentName(snaphot.Environment)}}</div>
                        </div>
                    </div>
                    <img class="img-size" [src]="snaphot.SnapshotFileUrl" alt="First slide">
                    <div class="threeD-image">
                        <img (click)="openNews3DPassValue(copyTextToClipEven(snaphot.Environment),
                    copyTextToClipAvtar(snaphot.UserID,snaphot.AvatarID),
                    (snaphot),
                    copyTextToClipGarment(snaphot.UpperwearID),
                    copyTextToClipGarment(snaphot.BottomwearID),
                    copyTextToClipGarment(snaphot.FootwearID), 
                    copyTextToClipGarment(snaphot.HeadwearID)
                    )" style="width:20px !important; height: 20px; "
                            src="./assets/realtype/dress.png">
                    </div>
                    <div class="col-12 col-sm-12 slider-box">
                        <div class="col-12 col-sm-12  d-flex " *ngIf="SnapshotDetails">
                            <div class="col-2 col-sm-2  ">
                                <img class="m-1" (click)="openBrandPoup(snaphot.HeadwearID)" data-bs-toggle="modal"
                                    style="width:45px; height:45px !important; color:black;"
                                    *ngIf="getGarmentrealtype(snaphot.HeadwearID) == '0' "
                                    class="rounded profileImg-brand"
                                    [src]="getBrandImageByGarmetnID(snaphot.HeadwearID)" />
                            </div>
                            <div class="col-8 col-sm-8 clothName p-1 "
                                *ngIf="snaphot.HeadwearID != undefined && snaphot.HeadwearID != ''">
                                <img class="gar-icon" *ngIf="getGarmentrealtype(snaphot.HeadwearID) == '0' "
                                    style="width:15px !important; color:black ; opacity: 0.4;"
                                    src="./assets/newsfeed/headgear-new.png">
                                <span class="caption-values"
                                    *ngIf="snaphot.HeadwearID != undefined && snaphot.HeadwearID != ''">
                                    <span class="garment-name" data-toggle="tooltip" data-placement="top"
                                        title="Cloth Details"
                                        (click)="openGarmentPopup('HeadwearID',snaphot.HeadwearID)">
                                        {{getGarmentName(snaphot.HeadwearID).substring(0,30)}}
                                    </span>&nbsp;
                                </span>
                            </div>
                        </div>
                        <div class="col-12 col-sm-12  d-flex" *ngIf="SnapshotDetails">
                            <div class="col-2 col-sm-2 ">
                                <img class="m-1" (click)="openBrandPoup(snaphot.UpperwearID)" data-bs-toggle="modal"
                                    style="width:45px; height:45px !important; color:black ; "
                                    *ngIf="getGarmentrealtype(snaphot.UpperwearID) == '1' "
                                    class="rounded profileImg-brand"
                                    [src]="getBrandImageByGarmetnID(snaphot.UpperwearID)" />
                                <img class="m-1" (click)="setBrandDetails(snaphot.UpperwearID)" data-bs-toggle="modal"
                                    data-bs-target="#brand-details" (click)="setBrandDetails(snaphot.UpperwearID)"
                                    data-bs-toggle="modal" style="width:45px; height:45px !important; color:black ;"
                                    *ngIf="getGarmentrealtype(snaphot.UpperwearID) == '5' "
                                    class="rounded profileImg-brand"
                                    [src]="getBrandImageByGarmetnID(snaphot.UpperwearID)" />
                            </div>
                            <div class="col-8 col-sm-8 clothName p-1"
                                *ngIf="snaphot.UpperwearID != undefined && snaphot.UpperwearID != ''">
                                <img class="gar-icon" *ngIf="getGarmentrealtype(snaphot.UpperwearID) == '1' "
                                    style="width:15px !important; color:black; opacity: 0.4;"
                                    src="./assets/realtype/upperwear.png">
                                <img class="gar-icon" *ngIf="getGarmentrealtype(snaphot.UpperwearID) == '5' "
                                    style="width:15px !important; color:black ; opacity: 0.4;"
                                    src="./assets/realtype/fullbody.png">
                                <span class="caption-values"
                                    *ngIf="snaphot.UpperwearID != undefined && snaphot.UpperwearID != ''">
                                    <span class="garment-name" data-toggle="tooltip" data-placement="top"
                                        title="Cloth Details"
                                        (click)="openGarmentPopup('UpperwearID',snaphot.UpperwearID)">
                                        {{getGarmentName(snaphot.UpperwearID).substring(0,30)}}
                                    </span>&nbsp;
                                </span>
                            </div>
                        </div>
                        <div class="col-12 col-sm-12 d-flex " *ngIf="SnapshotDetails">
                            <div class="col-2 col-sm-2 ">
                                <img class="m-1" (click)="openBrandPoup(snaphot.BottomwearID)" data-bs-toggle="modal"
                                    style="width:45px; height:45px !important; color:black ; "
                                    *ngIf="getGarmentrealtype(snaphot.BottomwearID) == '2'"
                                    class="rounded profileImg-brand"
                                    [src]="getBrandImageByGarmetnID(snaphot.BottomwearID)" />

                            </div>
                            <div class="col-8 col-sm-8 clothName p-1"
                                *ngIf="snaphot.BottomwearID != undefined && snaphot.BottomwearID != ''">
                                <img class="gar-icon" *ngIf="getGarmentrealtype(snaphot.BottomwearID) == '2'"
                                    style="width:15px !important; color:black ; opacity: 0.4;"
                                    src="./assets/newsfeed/bottomwear-new.png">
                                <span class="caption-values"
                                    *ngIf="snaphot.BottomwearID != undefined && snaphot.BottomwearID != ''">
                                    <span class="garment-name" data-toggle="popover" data-placement="left"
                                        title="Cloth Details"
                                        (click)="openGarmentPopup('BottomwearID',snaphot.BottomwearID)">
                                        {{getGarmentName(snaphot.BottomwearID).substring(0,30)}}
                                    </span>&nbsp;
                                </span>

                            </div>
                        </div>
                        <div class="col-12 col-sm-12  d-flex" *ngIf="SnapshotDetails">
                            <div class="col-2 col-sm-2 ">
                                <img class="m-1" (click)="openBrandPoup(snaphot.FootwearID)" data-bs-toggle="modal"
                                    style="width:45px; height:45px !important; color:black ;"
                                    *ngIf="getGarmentrealtype(snaphot.FootwearID) == '3'"
                                    class="rounded profileImg-brand"
                                    [src]="getBrandImageByGarmetnID(snaphot.FootwearID)" />
                            </div>
                            <div class="col-8 col-sm-8 clothName p-1"
                                *ngIf="snaphot.FootwearID != undefined && snaphot.FootwearID != ''">
                                <img class="gar-icon" *ngIf="getGarmentrealtype(snaphot.FootwearID) == '3' "
                                    style="width:15px !important; color:black ; opacity: 0.4;"
                                    src="./assets/realtype/footwear.png">
                                <span class="caption-values"
                                    *ngIf="snaphot.FootwearID != undefined && snaphot.FootwearID != ''">
                                    <span class="garment-name" data-toggle="popover" data-placement="left"
                                        title="Cloth Details"
                                        (click)="openGarmentPopup('FootwearID',snaphot.FootwearID)">
                                        {{getGarmentName(snaphot.FootwearID).substring(0,30)}}
                                    </span>&nbsp;
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="scroll-item" *ngIf="snaphot.HeadwearID != ''" [id]="HeadwearID">
                    <div class="swip-card-size">
                        <app-swipe-popup [key]="HeadwearID" [garmentFlagDummy]="dummyFlag"
                            [garmentId]="snaphot.HeadwearID"></app-swipe-popup>
                    </div>
                </div>
                <div class="scroll-item" *ngIf="snaphot.UpperwearID != ''" [id]="UpperwearID">
                    <div class="swip-card-size">
                        <app-swipe-popup [key]="UpperwearID" [garmentFlagDummy]="dummyFlag"
                            [garmentId]="snaphot.UpperwearID"></app-swipe-popup>
                    </div>
                </div>
                <div class="scroll-item" *ngIf="snaphot.BottomwearID != ''" [id]="BottomwearID">
                    <div class="swip-card-size">
                        <app-swipe-popup [key]="BottomwearID" [garmentFlagDummy]="dummyFlag"
                            [garmentId]="snaphot.BottomwearID"></app-swipe-popup>
                    </div>
                </div>
                <div class="scroll-item" *ngIf="snaphot.FootwearID != ''" [id]="FootwearID">
                    <div class="swip-card-size">
                        <app-swipe-popup [key]="FootwearID" [garmentFlagDummy]="dummyFlag"
                            [garmentId]="snaphot.FootwearID"></app-swipe-popup>
                    </div>
                </div>
            </div>
            <div class="iocon-set d-block justify-content-center text-center">
                <div class="justify-content-center set-div">
                    <div class="d-block justify-content-center text-center select-icon brand-garment-band">
                        <i class="bi bi-house-door-fill home-iocon-garment-selected  " (click)="homeclick()"></i>
                        <img class="rounded-circle m-1" src="./assets/storystyle/home-name.PNG"
                            class="rounded profileImg-brand "
                            style="width: 20px !important;   height: 20px !important" />
                    </div>
                </div>
                <div class="justify-content-center set-div ">
                    <a class="pintrest-icon"
                        *ngIf=" snaphot.HeadwearID != undefined && snaphot.HeadwearID != '' && showPintrestIcon(snaphot.HeadwearID)"
                        (click)="shareOnPinterest(snaphot.HeadwearID,'headwear',snaphot.SnapshotFileUrl,snaphot.StoryID)">
                        <img *ngIf=""  style="width:20px !important; color:black" class="img-thumbnail2 pinImage"
                            src="./assets/Pinterest-logo.png" />
                    </a>
                    <div class="d-block justify-content-center text-center brand-garment-band"
                        *ngIf="getGarmentrealtype(snaphot.HeadwearID) == '0'">
                        <img (click)="scrollToGarmentId(snaphot,snaphot.HeadwearID,'HeadwearID')"
                            *ngIf="getGarmentrealtype(snaphot.HeadwearID) == '0'"
                            style="width: 15px !important;   height: 15px !important;     opacity: 0.4;"
                            src="./assets/newsfeed/headgear-new.png">
                        <img (click)="scrollToGarmentId(snaphot,snaphot.HeadwearID,'HeadwearID')"
                            *ngIf="getGarmentrealtype(snaphot.HeadwearID) == '0'" class="rounded-circle m-1"
                            [src]="getBrandImageByGarmetnID(snaphot.HeadwearID)" class="rounded profileImg-brand "
                            style="width: 20px !important;   height: 20px !important; opacity: 0.5;" />
                    </div>
                </div>
                <div class="justify-content-center set-div  "
                    *ngIf="getGarmentrealtype(snaphot.UpperwearID) == '5' || getGarmentrealtype(snaphot.UpperwearID) == '1'">
                    <a class="pintrest-icon"
                        *ngIf="   snaphot.UpperwearID != undefined && snaphot.UpperwearID != '' && showPintrestIcon(snaphot.UpperwearID)"
                        (click)="shareOnPinterest(snaphot.UpperwearID,'upperwear',snaphot.SnapshotFileUrl,snaphot.StoryID)">
                        <img style="width:20px !important; color:black" class="img-thumbnail2 pinImage"
                            src="./assets/Pinterest-logo.png" />
                    </a>
                    <div class="d-block justify-content-center text-center brand-garment-band"
                        *ngIf="getGarmentrealtype(snaphot.UpperwearID) == '1' || getGarmentrealtype(snaphot.UpperwearID) == '5'">
                        <img *ngIf="getGarmentrealtype(snaphot.UpperwearID) == '1'"
                            (click)="scrollToGarmentId(snaphot,snaphot.UpperwearID,'UpperwearID')"
                            style="width: 15px !important;   height: 15px !important;      opacity: 0.4;"
                            src="./assets/realtype/upperwear.png">
                        <img *ngIf="getGarmentrealtype(snaphot.UpperwearID) == '5'"
                            (click)="scrollToGarmentId(snaphot,snaphot.UpperwearID,'UpperwearID')"
                            style="width: 15px !important;   height: 15px !important;      opacity: 0.4;"
                            src="./assets/realtype/fullbody.png">
                        <img (click)="scrollToGarmentId(snaphot,snaphot.UpperwearID,'UpperwearID')"
                            class="rounded-circle m-1" [src]="getBrandImageByGarmetnID(snaphot.UpperwearID)"
                            class="rounded profileImg-brand "
                            style="width: 20px !important;   height: 20px !important ;opacity: 0.5;" />
                    </div>
                </div>
                <div class="justify-content-center set-div ">
                    <a class="pintrest-icon"
                        *ngIf=" snaphot.BottomwearID != undefined && snaphot.BottomwearID != '' && showPintrestIcon(snaphot.BottomwearID)"
                        (click)="shareOnPinterest(snaphot.BottomwearID,'upperwear',snaphot.SnapshotFileUrl,snaphot.StoryID)">
                        <img style="width:20px !important; color:black" class="img-thumbnail2 pinImage"
                            src="./assets/Pinterest-logo.png" />
                    </a>
                    <div class="d-block justify-content-center text-center brand-garment-band"
                        *ngIf="getGarmentrealtype(snaphot.BottomwearID) == '2'">
                        <img (click)="scrollToGarmentId(snaphot,snaphot.BottomwearID,'BottomwearID')"
                            *ngIf="getGarmentrealtype(snaphot.BottomwearID) == '2'"
                            style="width: 15px !important;   height: 15px !important;    opacity: 0.4;"
                            src="./assets/newsfeed/bottomwear-new.png">
                        <img (click)="scrollToGarmentId(snaphot,snaphot.BottomwearID,'BottomwearID')"
                            *ngIf="getGarmentrealtype(snaphot.BottomwearID) == '2'" class="rounded-circle m-1"
                            [src]="getBrandImageByGarmetnID(snaphot.BottomwearID)" class="rounded profileImg-brand"
                            style="width: 20px !important; height: 20px !important ;opacity: 0.5;" />
                    </div>
                </div>
                <div class="justify-content-center set-div  ">
                    <a class="pintrest-icon"
                        *ngIf=" snaphot.FootwearID != undefined && snaphot.FootwearID != '' && showPintrestIcon(snaphot.FootwearID)"
                        (click)="shareOnPinterest(snaphot.FootwearID,'footwear',snaphot.SnapshotFileUrl,snaphot.StoryID)">
                        <img style="width:20px !important; color:black" class="img-thumbnail2 pinImage"
                            src="./assets/Pinterest-logo.png" />
                    </a>
                    <div class="d-block justify-content-center text-center brand-garment-band"
                        *ngIf="getGarmentrealtype(snaphot.FootwearID) == '3' ">
                        <img (click)="scrollToGarmentId(snaphot,snaphot.FootwearID,'FootwearID')"
                            *ngIf="getGarmentrealtype(snaphot.FootwearID) == '3' "
                            style="width: 15px !important;   height: 15px !important;    opacity: 0.4;"
                            src="./assets/realtype/footwear.png">
                        <img (click)="scrollToGarmentId(snaphot,snaphot.FootwearID,'FootwearID')"
                            *ngIf="getGarmentrealtype(snaphot.FootwearID) == '3'" class="rounded-circle m-1"
                            [src]="getBrandImageByGarmetnID(snaphot.FootwearID)" class="rounded profileImg-brand"
                            style="width: 20px !important;   height: 20px !important; ;opacity: 0.5;" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> 




