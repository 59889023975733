<div *ngIf="boxImage" class="modal-content imp-block justify-content-center">
    <h5 type="button" class="btn-close  closBtn" data-bs-dismiss="modal" (click)="closePopUp()" aria-label="Close"></h5>
    <img [src]="boxImage">
</div>


<span  *ngIf="pageType  == 'style'">
    <div class="m-1 " [ngClass]="pageType  == 'style'? 'style-bg' : 'garment-bg'" id="carouselExampleFade"
      *ngIf="garmentType == 'headgear' " data-bs-interval="1000" class="carousel slide carousel-fade"
      data-bs-ride="carousel">
      <div class="carousel-inner footwear-popup">
        <div class="carousel-item ">
          <img class="footwear-img8" [src]="garmentImg" />
        </div>
        <div class="carousel-item ">
          <img class="footwear-img7" [src]="garmentImg" />
        </div>
        <div class="carousel-item ">
          <img class="footwear-img2" [src]="garmentImg" />
        </div>
        <div class="carousel-item ">
          <img class="footwear-img6" [src]="garmentImg" />
        </div>
        <div class="carousel-item active">
          <img class="footwear-img5" [src]="garmentImg" />
        </div>
        <div class="carousel-item ">
          <img class="footwear-img1" [src]="garmentImg" />
        </div>
        <div class="carousel-item ">
          <img class="footwear-img4" [src]="garmentImg" />
        </div>
        <div class="carousel-item ">
          <img class="footwear-img3" [src]="garmentImg" />
        </div>
      </div>
      <button class="carousel-control-prev text-black" type="button" data-bs-target="#carouselExampleFade"
        data-bs-slide="prev">
        <span class="carousel-control-prev-icon text-black" aria-hidden="true"></span>
        <span class="visually-hidden text-black">Previous</span>
      </button>
      <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="next">
        <span class="carousel-control-next-icon text-black" aria-hidden="true"></span>
        <span class="visually-hidden text-black">Next</span>
      </button>
    </div>
    <div class="m-1 class-div" [ngClass]="pageType  == 'style'? 'style-bg' : 'garment-bg'" id="carouselExampleFade"
      *ngIf=" garmentType  == 'upperwear'"     data-bs-interval="1000" class="carousel slide carousel-fade"
      data-bs-ride="carousel" >
      <div class="carousel-inner upperwear-popup "   >
        <div class="carousel-item ">
          <img    class="upperwear-img4" [src]="garmentImg" />
        </div>
        <div class="carousel-item">
          <img    class="upperwear-img6" [src]="garmentImg" />
        </div>
        <div class="carousel-item ">
          <img    class="upperwear-img8" [src]="garmentImg" />
        </div>
        <div class="carousel-item ">
          <img     class="upperwear-img3" [src]="garmentImg" />
        </div>
        <div class="carousel-item active ">
          <img    class="upperwear-img5" [src]="garmentImg" />
        </div>
        <div class="carousel-item ">
          <img    class="upperwear-img7" [src]="garmentImg" />
        </div>
        <div class="carousel-item ">
          <img    class="upperwear-img1" [src]="garmentImg" />
        </div>
        <div class="carousel-item ">
          <img    class="upperwear-img2" [src]="garmentImg" />
        </div>
      </div>
      <button class="carousel-control-prev text-black" type="button" data-bs-target="#carouselExampleFade"
        data-bs-slide="prev">
        <span class="carousel-control-prev-icon text-black" aria-hidden="true"></span>
        <span class="visually-hidden text-black">Previous</span>
      </button>
      <button class="carousel-control-next text-black" type="button" data-bs-target="#carouselExampleFade"
        data-bs-slide="next">
        <span class="carousel-control-next-icon text-black" aria-hidden="true"></span>
        <span class="visually-hidden text-black">Next</span>
      </button>
    </div>
    <div class="m-1" id="carouselExampleFade" [ngClass]="pageType  == 'style'? 'style-bg' : 'garment-bg'"
      *ngIf="garmentType == 'bottomwear'" data-bs-interval="1000" class="carousel slide carousel-fade"
      data-bs-ride="carousel">
      <div class="carousel-inner upperwear-popup ">
        <div class="carousel-item ">
          <img class="upperwear-img4" [src]="garmentImg" />
        </div>
        <div class="carousel-item">
          <img class="upperwear-img6" [src]="garmentImg" />
        </div>
        <div class="carousel-item ">
          <img class="upperwear-img8" [src]="garmentImg" />
        </div>
        <div class="carousel-item ">
          <img class="upperwear-img3" [src]="garmentImg" />
        </div>
        <div class="carousel-item active ">
          <img class="upperwear-img5" [src]="garmentImg" />
        </div>
        <div class="carousel-item ">
          <img class="upperwear-img7" [src]="garmentImg" />
        </div>
        <div class="carousel-item ">
          <img class="upperwear-img1" [src]="garmentImg" />
        </div>
        <div class="carousel-item ">
          <img class="upperwear-img2" [src]="garmentImg" />
        </div>
      </div>
      <button class="carousel-control-prev text-black" type="button" data-bs-target="#carouselExampleFade"
        data-bs-slide="prev">
        <span class="carousel-control-prev-icon text-black" aria-hidden="true"></span>
        <span class="visually-hidden text-black">Previous</span>
      </button>
      <button class="carousel-control-next text-black" type="button" data-bs-target="#carouselExampleFade"
        data-bs-slide="next">
        <span class="carousel-control-next-icon text-black" aria-hidden="true"></span>
        <span class="visually-hidden text-black">Next</span>
      </button>
    </div>
    <div class="m-1" [ngClass]="pageType  == 'style'? 'style-bg' : 'garment-bg'" id="carouselExampleFade"
      *ngIf="garmentType == 'footwear' " data-bs-interval="1000" class="carousel slide carousel-fade"
      data-bs-ride="carousel">
      <div class="carousel-inner footwear-popup">
        <div class="carousel-item ">
          <img class="footwear-img8" [src]="garmentImg" />
        </div>
        <div class="carousel-item ">
          <img class="footwear-img7" [src]="garmentImg" />
        </div>
        <div class="carousel-item ">
          <img class="footwear-img2" [src]="garmentImg" />
        </div>
        <div class="carousel-item ">
          <img class="footwear-img6" [src]="garmentImg" />
        </div>
        <div class="carousel-item active">
          <img class="footwear-img5" [src]="garmentImg" />
        </div>
        <div class="carousel-item ">
          <img class="footwear-img1" [src]="garmentImg" />
        </div>
        <div class="carousel-item ">
          <img class="footwear-img4" [src]="garmentImg" />
        </div>
        <div class="carousel-item ">
          <img class="footwear-img3" [src]="garmentImg" />
        </div>
      </div>
      <button class="carousel-control-prev text-black" type="button" data-bs-target="#carouselExampleFade"
        data-bs-slide="prev">
        <span class="carousel-control-prev-icon text-black" aria-hidden="true"></span>
        <span class="visually-hidden text-black">Previous</span>
      </button>
      <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="next">
        <span class="carousel-control-next-icon text-black" aria-hidden="true"></span>
        <span class="visually-hidden text-black">Next</span>
      </button>
    </div>
  </span>


  
<span *ngIf="pageType  == 'treads'" >
  <div class="m-1 "  id="carouselExampleFade"
    *ngIf="garmentType == 'headgear' " data-bs-interval="1000" class="carousel slide carousel-fade"
    data-bs-ride="carousel" >
    <div class="carousel-inner upperwear-popup-treads"    (click)="showAlert()">
      <div class="carousel-item ">
        <img class="upperwear-img4-theards" [src]="garmentImg" />
      </div>
      <div class="carousel-item">
        <img class="upperwear-img7-theards" [src]="garmentImg" />
      </div>
      <div class="carousel-item">
        <img class="upperwear-img8-theards" [src]="garmentImg" />
      </div>
      <div class="carousel-item ">
        <img class="upperwear-img3-theards" [src]="garmentImg" />
      </div>
      <div class="carousel-item active">
        <img class="upperwear-img5-theards"   [src]="garmentImg" />
      </div>
      <div class="carousel-item">
        <img class="upperwear-img6-theards" [src]="garmentImg" />
      </div>
      <div class="carousel-item ">
        <img class="upperwear-img1-theards" [src]="garmentImg" />
      </div>
      <div class="carousel-item ">
        <img class="upperwear-img2-theards" [src]="garmentImg" />
      </div> 
    </div>

    <button class="carousel-control-prev text-black" type="button" data-bs-target="#carouselExampleFade"
      data-bs-slide="prev">
      <span class="carousel-control-prev-icon text-black" aria-hidden="true"></span>
      <span class="visually-hidden text-black">Previous</span>
    </button>
    <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="next">
      <span class="carousel-control-next-icon text-black" aria-hidden="true"></span>
      <span class="visually-hidden text-black">Next</span>
    </button>
  </div>
  <div class="m-1 class-div"  id="carouselExampleFade"
    *ngIf=" garmentType  == 'upperwear'"class="carousel slide"  #carousel
    data-bs-ride="carousel" data-bs-interval="1000">
    <div class="carousel-inner upperwear-popup-treads"  (click)="showAlert()">
      <div class="carousel-item ">
        <img class="upperwear-img4-theards" [src]="garmentImg" />
      </div>
      <div class="carousel-item">
        <img class="upperwear-img7-theards" [src]="garmentImg" />
      </div>
      <div class="carousel-item">
        <img class="upperwear-img8-theards" [src]="garmentImg" />
      </div>
      <div class="carousel-item ">
        <img class="upperwear-img3-theards" [src]="garmentImg" />
      </div>
      <div class="carousel-item active">
        <img class="upperwear-img5-theards" [src]="garmentImg" />
      </div>
      <div class="carousel-item">
        <img class="upperwear-img6-theards" [src]="garmentImg" />
      </div>
      <div class="carousel-item ">
        <img class="upperwear-img1-theards" [src]="garmentImg" />
      </div>
      <div class="carousel-item ">
        <img class="upperwear-img2-theards" [src]="garmentImg" />
      </div> 
    </div>
    <button class="carousel-control-prev text-black" type="button" data-bs-target="#carouselExampleFade"
      data-bs-slide="prev"  >
      <span class="carousel-control-prev-icon text-black" aria-hidden="true"></span>
      <span class="visually-hidden text-black">Previous</span>
    </button>
    <button class="carousel-control-next text-black" type="button" data-bs-target="#carouselExampleFade"
      data-bs-slide="next" >
      <span class="carousel-control-next-icon text-black" aria-hidden="true"></span>
      <span class="visually-hidden text-black">Next</span>
    </button>
  </div>
  <div class="m-1" id="carouselExampleFade" 
    *ngIf="garmentType == 'bottomwear'" data-bs-interval="1000" class="carousel slide carousel-fade"
    data-bs-ride="carousel">
    <div class="carousel-inner upperwear-popup-treads" (click)="showAlert()">
      <div class="carousel-item ">
        <img class="upperwear-img4-theards" [src]="garmentImg" />
      </div>
      <div class="carousel-item">
        <img class="upperwear-img7-theards" [src]="garmentImg" />
      </div>
      <div class="carousel-item">
        <img class="upperwear-img8-theards" [src]="garmentImg" />
      </div>
      <div class="carousel-item ">
        <img class="upperwear-img3-theards" [src]="garmentImg" />
      </div>
      <div class="carousel-item active">
        <img class="upperwear-img5-theards" [src]="garmentImg" />
      </div>
      <div class="carousel-item">
        <img class="upperwear-img6-theards" [src]="garmentImg" />
      </div>
      <div class="carousel-item ">
        <img class="upperwear-img1-theards" [src]="garmentImg" />
      </div>
      <div class="carousel-item ">
        <img class="upperwear-img2-theards" [src]="garmentImg" />
      </div> 
    </div>

    <button class="carousel-control-prev text-black" type="button" data-bs-target="#carouselExampleFade"
      data-bs-slide="prev">
      <span class="carousel-control-prev-icon text-black" aria-hidden="true"></span>
      <span class="visually-hidden text-black">Previous</span>
    </button>
    <button class="carousel-control-next text-black" type="button" data-bs-target="#carouselExampleFade"
      data-bs-slide="next">
      <span class="carousel-control-next-icon text-black" aria-hidden="true"></span>
      <span class="visually-hidden text-black">Next</span>
    </button>
  </div>
  <div class="m-1"  id="carouselExampleFade"
    *ngIf="garmentType == 'footwear' " data-bs-interval="1000" class="carousel slide carousel-fade"
    data-bs-ride="carousel">
    <div class="carousel-inner upperwear-popup-treads"  (click)="showAlert()"  >
      <div class="carousel-item ">
        <img class="upperwear-img4-theards" [src]="garmentImg" />
      </div>
      <div class="carousel-item">
        <img class="upperwear-img7-theards" [src]="garmentImg" />
      </div>
      <div class="carousel-item">
        <img class="upperwear-img8-theards" [src]="garmentImg" />
      </div>
      <div class="carousel-item ">
        <img class="upperwear-img3-theards" [src]="garmentImg" />
      </div>
      <div class="carousel-item active">
        <img class="upperwear-img5-theards" [src]="garmentImg" />
      </div>
      <div class="carousel-item">
        <img class="upperwear-img6-theards" [src]="garmentImg" />
      </div>
      <div class="carousel-item ">
        <img class="upperwear-img1-theards" [src]="garmentImg" />
      </div>
      <div class="carousel-item ">
        <img class="upperwear-img2-theards" [src]="garmentImg" />
      </div> 
    </div>

    <button class="carousel-control-prev text-black" type="button" data-bs-target="#carouselExampleFade"
      data-bs-slide="prev">
      <span class="carousel-control-prev-icon text-black" aria-hidden="true"></span>
      <span class="visually-hidden text-black">Previous</span>
    </button>
    <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="next">
      <span class="carousel-control-next-icon text-black" aria-hidden="true"></span>
      <span class="visually-hidden text-black">Next</span>
    </button>
  </div>
</span>
