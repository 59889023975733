import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';
import { Brand } from 'src/app/models/brand';
import { MatDialog } from '@angular/material/dialog';
import { CommonService } from 'src/app/services/common.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DataSharingService } from 'src/app/services/data-sharing-service';
import { PinterestService } from 'src/app/services/pinterest-service';
import { ApiHttpService } from 'src/app/services/api-http.service';


@Component({
  selector: 'app-garment-collection',
  templateUrl: './garment-collection.component.html',
  styleUrls: ['./garment-collection.component.css']
})
export class GarmentCollectionComponent {
  @ViewChild('brandStoriyScroll', { read: ElementRef }) public BrandStoriyScroll: ElementRef<any>;
  userGarmentCollection = this.firestore.collection('garment-collection').valueChanges({ idField: 'id' }) as Observable<any[]>;
  userGarmentCollectionItems = this.firestore.collection('garment-collection-items').valueChanges({ idField: 'id' }) as Observable<any[]>;

  brands = this.firestore.collection('brands').valueChanges({ idField: 'id' }) as Observable<Brand[]>;
  garmentsList = this.firestore.collection('garments').valueChanges({ idField: 'id' }) as Observable<any[]>;
  brandsBoardCollections = this.firestore.collection('brand-board').valueChanges({ idField: 'id' }) as Observable<Brand[]>;
  userFiles = this.firestore.collection("userfiles");
  userFileStateChange = this.userFiles.valueChanges();
  brandBoardList = this.firestore.collection('brand-board').valueChanges({ idField: 'id' }) as Observable<any[]>;
  SnapShotsList = this.firestore.collection('SnapshotDetails').valueChanges({ idField: 'id' }) as Observable<any[]>;
  brandsCollection = this.firestore.collection('brands').valueChanges({ idField: 'id' }) as Observable<Brand[]>;
  brandsCollections = this.firestore.collection('brands').valueChanges({ idField: 'id' }) as Observable<Brand[]>;
  userFilesList = this.firestore.collection('userfiles').valueChanges({ idField: 'id' }) as Observable<any[]>;

  displayedColumns: string[] = ['position', 'name', 'weight', 'symbol'];
  public text = `{ "text": "This is text file!中文" }`;
  public fileName: string | undefined;


  boardDetails: any
  selectedBrandType: any
  selectedBrand: any;
  getZipcollection: any;
  zipfile: any;
  loading = true;
  brandDetails: any = []
  brandDetailsForStories: any = []
  snapDetails: any = []
  garmentsCollection: any = []
  brandId: any = ""
  brandGarmentIds: any = [];
  SnapShotFileDetails = []
  BrandDetails = []
  snapShotBrandList = [];
  clocthCollectionIdcollection : any = [];
  brandhShapShotsList : any = [];
  filterStories = []
  storyId: any
  userId: any
  brandIds: any
  boards: any = []
  brandBoard: any = []
  boardNameInBrandStory: any
  brandDetailsId: any = {}
  showBrandName: boolean = false
  userSnapShotList: any = []
  snapShotByType: any = []
  SnapShotDetails: any = []
  // selectedTab:any = "news-stories"
  selectedTab: any = "mens-wear"
  brandsIDdetailsAll: any = {}
  clothDetails: any = {}
  genderID: any
  isloading = true
  brandDetailsAll:any = []
  collectionData:any = []
  byScroll:boolean = false
  showBrandFlag:boolean = false
  heartFlag:boolean = false
  thresholdHeightPagination: number = 0
  pageNumber : number = 0
  clickEventFlag:boolean=false;
  collectionName: string = "";
  uniqueNo: any = "";
  onChangeFlag = true;
  height: any;
  collectionGender: any = "";
  editcolllection: any = {};
  brandname:  any = {};
  genderBlak: string;
  collectionItems:any = []
  linkGarment:any = []
  garmentName: any;
  filteredGarment:any = []
  userUploadedFileList:any = []
  userFiledetails:any = []
  clothShapShotsList:any = []
  counter :any =2
  brandNames: any;
  type: string;
  url: string;
  logInFlag: boolean;


  constructor(private route: ActivatedRoute, private pinterestAPI: PinterestService, private apiHttpService: ApiHttpService,
    private firestore: AngularFirestore, private dataSharingService: DataSharingService, public commonService: CommonService, private router: Router) {
      this.userGarmentCollectionItems.subscribe((collectiondata) => {
        this.collectionItems = collectiondata

      })
      this.userGarmentCollection.subscribe((collectiondata) => {
      this.collectionData = collectiondata
    })

    this.userFilesList.subscribe((userFile) => {
      this.userUploadedFileList = userFile
      let clothId = this.clothDetails.id
      let filelist = this.userUploadedFileList.filter((uploadlist) => uploadlist.link == clothId).slice(0,2)
      this.userFiledetails = filelist
    })


  }



  ngOnChanges() {
  }


  ngOnInit() {

      let title = 'Yologram - garment-collection'
    this.commonService.changeTitle(title);
    this.logInFlag = this.commonService.isLoggedIn()
    this.checkPageAuthorization()
  
    this.loading = true;
    this.showBrandFlag = true
    this.pinterestAPI.getBoardList().subscribe((data) => {
      this.boards = data.items;
      this.brandsBoardCollections.subscribe((brandBoard) => {
        this.brandBoard = brandBoard;
      });
    
    })
    this.brands.subscribe((brandList) => {
      this.brandDetailsAll = brandList
      this.brandDetailsAll.forEach(async (brand) => {
        let type = 'b';
        let url = await this.commonService.compressImage(brand.LogoURL, type); // Assuming each brand has 'imagePath' property
        brand.Brandurl = url.downloadUrl;  // Save the compressed image URL back into the brand object
      });
      this.loading = false;
    })
    this.garmentsList.subscribe((grList) => {
      this.garmentsCollection = grList
      this.brands.subscribe((brandList) => {
        this.brandDetails = brandList
        let object = this.brandDetails
        this.brandDetailsId = object.find((bBoard) => bBoard.id == this.brandId)
        if (this.brandId != undefined) {
          this.selectedBrand = this.brandDetailsId;
          this.boardDetails = this.boardNameInBrandStory
          let brandBoardFilter = this.brandBoard.find((bBoard) => bBoard.brandId == this.brandId)
          this.boardDetails = (brandBoardFilter != undefined) ? this.boards.find((board) => board.id == brandBoardFilter.boardId) : undefined;
        }
      })


      let garmentIds = this.garmentsCollection.map((garment) => garment.id);
      let garmentsObject = this.garmentsCollection.reduce((acc, obj) => {
        const key = obj.id;
        const curGroup = acc[key] ?? [];
        return { ...acc, [key]: obj.BrandID };
      }, {});
      this.commonService.snapshotfileDetais().subscribe(async (snapshot: any) => {
        this.SnapShotFileDetails = snapshot;
        this.SnapShotFileDetails.forEach((snapshot) => {
          if (garmentsObject[snapshot.HeadwearID] != undefined
            || garmentsObject[snapshot.BottomwearID] != undefined
            || garmentsObject[snapshot.UpperwearID] != undefined
            || garmentsObject[snapshot.FootwearID] != undefined
          )
            if (garmentsObject[snapshot.HeadwearID] != undefined && this.snapShotBrandList.indexOf(garmentsObject[snapshot.FootwearID]) == -1) this.snapShotBrandList.push(garmentsObject[snapshot.FootwearID]);
          if (garmentsObject[snapshot.BottomwearID] != undefined && this.snapShotBrandList.indexOf(garmentsObject[snapshot.BottomwearID]) == -1) this.snapShotBrandList.push(garmentsObject[snapshot.BottomwearID]);
          if (garmentsObject[snapshot.UpperwearID] != undefined && this.snapShotBrandList.indexOf(garmentsObject[snapshot.UpperwearID]) == -1) this.snapShotBrandList.push(garmentsObject[snapshot.UpperwearID]);
          if (garmentsObject[snapshot.FootwearID] != undefined && this.snapShotBrandList.indexOf(garmentsObject[snapshot.FootwearID]) == -1) this.snapShotBrandList.push(garmentsObject[snapshot.FootwearID]);
        })
      })
    })

this.filterSnap()

  }


filterSnap()
{
if(this.clothDetails.GarmentType == 'bottomwear'){
  this.type = 'BottomwearID'
}else if(this.clothDetails.GarmentType == 'upperwear'){
  this.type = 'UpperwearID'
}else if(this.clothDetails.GarmentType == 'footwear'){
  this.type= 'FootwearID'
}
else if(this.clothDetails.GarmentType == 'headgear'){
  this.type= 'HeadwearID'
}
  let reqInputObject = {
    fileType: 'BottomwearID',
    id: this.clothDetails.id,
    pageNumber: 1
  }

  this.commonService.yologramSnapshotDetailsByType(reqInputObject).subscribe(async (response: any) => {
    this.clothShapShotsList = response.object
  })
}
  selectByScroll(){
    this.byScroll = true
  }
  closeButtonForBrand(){
    this.byScroll = false
  }
  checkPageAuthorization() {
    this.loading = true;
    this.commonService.getUser().subscribe((user: any) => {
      let userObject = { 'userid': user?.uid, 'pagename': '/garment-collection' }
      this.commonService.checkViewPageRights(userObject).subscribe((res) => {
        this.loading = false;
        if (!res) {
          this.router.navigateByUrl("/menu-page");
        }
      });
    });
  }

  getBrandImage(imgURL: string) {
    let brandEncodedurl = encodeURI(imgURL).replace('/', '%2F');
    let url = "https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/" + brandEncodedurl + "?alt=media&token=550e7e88-bed3-4668-84ff-ad491c19e06c"
    return url;
  }
  getBrandImagetwo(imgURL: string) {
    let brandEncodedurl = encodeURI(imgURL).replace('/', '%2F');
    let url = "https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/" + brandEncodedurl + "?alt=media&token=550e7e88-bed3-4668-84ff-ad491c19e06c"
    return url;
  }


  // showBrandDetails(brand: any) {
  //   this.selectedBrand = brand;
    
  // }


  selectBrandForStory(brandId: any, brands: any) {
    this.thresholdHeightPagination = 0
    this.showBrandFlag = false
    this.byScroll = false
    this.selectedBrand = brands
    this.brandId = brandId;
    this.boardDetails = {}
    let brandBoardFilter = this.brandBoard.find((bBoard) => bBoard.brandId == brandId)
    let brandName = this.boards.find((bname)=>bname.id  == brandBoardFilter.boardId)
    this.boardDetails = brandName
  }

  selectGender($event){
    this.collectionGender = $event.target.value
  }


// filteColllectionByBrand()
// {
//     let filterdata = this.garmentsCollection.filter((data)=>data.BrandID ==  this.brandId)
//     console.log("filtercollection",filterdata)
// }





 

  onScroll(){
    this.height = this.BrandStoriyScroll.nativeElement.scrollTop
    }
    

  selectcollectionName($event){
    this.collectionName = $event.target.value
  }
  addcollection() {
    if(this.collectionName == '' || this.brandId == ''  || (this.collectionGender == '' || this.collectionGender == 'Select Gender') ) 
    {
      alert('Please enter collection name, select Brand and Gender Type')
      return;
    }
    else {
      let duplicateList =  this.collectionData.filter((data:any)=> data.CollectionName.toLowerCase() == this.collectionName.toLowerCase() &&  data.BrandId.toLowerCase() == this.brandId.toLowerCase())
      if(duplicateList.length > 0) {
        alert('Duplicate Brand,Collectin Name not Allowed')
        return;
      } 
    }
    
    let collectionObject = {
      CollectionName: this.collectionName,
      Gender : this.collectionGender,
      BrandId:this.brandId,
      UniqueNo: '',
      status:'A'
    }

    this.firestore.collection('garment-collection').add(collectionObject)
    alert("collection Added Successfully..")
    this.collectionGender = '';
    this.collectionName ='';
    this.genderBlak = ''
    setTimeout(() => {
      this.genderBlak = 'A'
        },
          500);
    return
  }



  callEditcollection(editCollection){
    this.brandname = {}
    this.editcolllection = editCollection;
    this.brandname = this.brandDetailsAll.find((brand)=>brand.id == editCollection.BrandId)

  }
  brandName(id){
    this.brandname = this.brandDetailsAll.find((brand)=>brand.id == id)
    let collectionName =  this.brandname != undefined && this.brandname.BrandName != undefined ? this.brandname.BrandName : '';
    return collectionName
  }
  brandNameInPopup(id){
    this.brandname = this.brandDetailsAll.find((brand)=>brand.id == id)
    let brandNames =  this.brandname != undefined && this.brandname.BrandName != undefined ? this.brandname.BrandName : '';
     this.brandNames = brandNames
  }


  GarmentName(id){
    this.garmentName = this.garmentsCollection.find((garment)=>garment.id == id)
    let garmentName =  this.garmentName != undefined && this.garmentName.Name != undefined ? this.garmentName.Name : '';
    return garmentName
    // let garmentCollectionId = (this.garmentsCollection != undefined && this.garmentsCollection).find((colection) => colection.id === id )
    // let getCollection =  this.collectionData.find((colection) => colection.id === garmentCollectionId.GarmentCollectionID && colection.status == 'A')
    // let name = getCollection !=undefined &&  getCollection.CollectionName != undefined ? getCollection.CollectionName : '';
    // return name
    
  }
   setColllectionName($event) {
    this.editcolllection.CollectionName = $event.target.value
   }

   selectBrandForStoryBrand($event){
    this.editcolllection.BrandId = $event.target.value
   }

   updatecollection()
   {
     let object = {
      CollectionName:  this.editcolllection.CollectionName,
      Gender : this.collectionGender,
      BrandId: this.editcolllection.BrandId,
      UniqueNo: ''
     };
     this.firestore.collection('garment-collection').doc(this.editcolllection["id"]).update(object);
   }

  linkGarmentList(collection){
    let collectionData  = this.collectionData.filter((item)=>item.id == collection.id)
    this.garmentName = this.garmentsCollection.find((garment)=>garment.id == collection.id)   
    let findGId = this.collectionItems.find((item)=>item.GarmentCollectionID == collection.id)
    let linkGarmentList = this.collectionItems.filter((item)=>item.GarmentCollectionID == collection.id)
    this.linkGarment = linkGarmentList;
  }
  garmentId(collection){

    let linkGarmentList = this.collectionItems.filter((item)=>item.GarmentCollectionID === collection.id)
    let garmentId = linkGarmentList;
    this.filteredGarment = garmentId

  }
  updateSecLinkActiveStatus($event, collection) {
    let value = $event.target.checked
    let object = {
      status: ($event.target.checked) ? 'A' : 'I'
    };
    this.firestore.collection('garment-collection').doc(collection.id).update(object);
    // if (value == 'I') {
    //   let object2 = {
    //     CollectionName: null
    //   };
    //   this.firestore.collection('garments').doc(collection.GarmentID).update(object2);
    // }
  }


  garmentPopup(garmentId){
    this.clothDetails = this.garmentsCollection.find((b: any) => b.id === garmentId);
    this.brandNameInPopup(this.clothDetails.BrandID)
    if(this.clothDetails.GarmentType == 'bottomwear'){
      this.type = 'BottomwearID'
    }else if(this.clothDetails.GarmentType == 'upperwear'){
      this.type = 'UpperwearID'
    }else if(this.clothDetails.GarmentType == 'footwear'){
      this.type= 'FootwearID'
    }
    
    let reqInputObject = { fileType:this.type, id: this.clothDetails.id, pageNumber: 1}
    this.commonService.yologramSnapshotDetailsByType(reqInputObject).subscribe(async (response: any) => {

      this.clothShapShotsList = response.object
    })
    this.userFilesList.subscribe((userFile) => {
      this.userUploadedFileList = userFile
      let clothId = garmentId
      let filelist = this.userUploadedFileList.filter((uploadlist) => uploadlist.link == clothId).slice(0,2)
      this.userFiledetails = filelist
    })

   
    let brandId = this.clothDetails.BrandID

    this.brandname = this.brandDetailsAll.find((brand)=>brand.id == brandId)
    let brandEncodedurl = encodeURI(this.brandname.LogoURL).replace('/', '%2F')
    this.url = "https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/" + brandEncodedurl + "?alt=media&token=550e7e88-bed3-4668-84ff-ad491c19e06c"
 }

  getClothProfile(brand: any,) {
    let clothAllDetails = this.clothDetails
    let brandGarments = this.garmentsCollection.filter((gaementList) => gaementList.id == this.clothDetails.id);
    this.clocthCollectionIdcollection = brandGarments.map((garment) => garment.id);
    this.clothShapShotsList = this.SnapShotFileDetails.filter((snapshot) => {
      return this.clocthCollectionIdcollection.indexOf(snapshot.BottomwearID) >= 0 ||
        this.clocthCollectionIdcollection.indexOf(snapshot.HeadwearID.trim()) >= 0 ||
        this.clocthCollectionIdcollection.indexOf(snapshot.FootwearID.trim()) >= 0 ||
        this.clocthCollectionIdcollection.indexOf(snapshot.UpperwearID.trim()) >= 0
    }).slice(0, 9);
    let snanpDetails = this.clothShapShotsList
    this.dataSharingService.sendUserSnapShots(snanpDetails);
    this.dataSharingService.sendBrandGarments(this.clocthCollectionIdcollection);
    this.dataSharingService.sendClothDetails(clothAllDetails)
    this.router.navigate(['yologram-social/yologram-garment-edit', { 'brandId': brand }])
  }


  getBrandProfileByGarment(brand: any,board:any) {
    this.brandGarmentIds = []
    let brandGarments = this.garmentsCollection.filter((gaementList)=>gaementList.BrandID === brand);
    this.brandGarmentIds = brandGarments.map((garment) => garment.id);
    this.brandhShapShotsList = this.SnapShotFileDetails.filter((snapshot) => {
      return  this.brandGarmentIds.indexOf(snapshot.BottomwearID)  >= 0 ||
      this.brandGarmentIds.indexOf(snapshot.HeadwearID.trim())  >= 0 ||
      this.brandGarmentIds.indexOf(snapshot.FootwearID.trim())  >= 0 ||
      this.brandGarmentIds.indexOf(snapshot.UpperwearID.trim()) >= 0
    })
    this.dataSharingService.sendUserSnapShots(this.brandhShapShotsList);
    this.dataSharingService.sendBrandGarments( this.brandGarmentIds);
    this.dataSharingService.sendBrandBrandBoard(board)  
    this.router.navigate(['yologram-social/yologram-brand-profile', {'brandId': brand}])
  }
  getgarmentStyle(cloths: any) {
    let editgarment = 'edit'
    this.dataSharingService.sendClothDetails(cloths)
    this.router.navigate(['yologram-social/yologram-garment-style', { 'brandId': cloths.BrandID, 'clothsType': cloths.GarmentType, 'Gender': cloths.Gender, 'id': cloths.id ,'edit': editgarment}])
  }
  
  getStoryCloth(brandIds) {
    let snap = this.clothShapShotsList
    this.dataSharingService.sendUserSnapShots(snap)
    this.dataSharingService.sendClothDetails(brandIds)
    let brandid = brandIds.BrandID
    this.router.navigate(['yologram-social/yologram-garment-edit', { 'brandId': brandid, }])
  }
  getgarmentfeed(brandName: any, cloths: any) {
    let snap = this.clothShapShotsList 
    this.dataSharingService.sendUserSnapShots(snap)
    this.dataSharingService.sendClothDetails(cloths)
    this.router.navigate(['yologram-social/yologram-garment-feed', { 'brandId': cloths.BrandID, 'clothsType': cloths.GarmentType, 'Gender': cloths.Gender, 'id': cloths.id }])
  }
  

}
