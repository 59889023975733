import { ElementRef, Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFireDatabase, AngularFireList } from '@angular/fire/compat/database';

import { ActivatedRouteSnapshot, NavigationEnd, Router, RouterStateSnapshot } from '@angular/router';
import firebase from 'firebase/compat/app';
import { map } from 'rxjs/operators';

import 'firebase/auth';
import { User } from './user';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { Title } from '@angular/platform-browser';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { ApiHttpService } from './api-http.service';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { promises } from 'dns';


@Injectable({
  providedIn: 'root',
})
export class CommonService {
  user: any;
  fileUploads: any;

  SnapShotFileDetails: any = []
  allUserDetails: any = []
  userAllDetails: any = []
  private openedLinks: string[] = [];

  userid = null;
  private uploadfiles: string = environment.functionBaseUrl + "uploadFileToStorage_V1"
  private zipFiles: string = "http://localhost:5001/yolouploader/us-central1/listAll"
  private acccessPageVal: string = environment.functionBaseUrl + "AccessPageValidation"
  // private SnapDeatailsFile: string = environment.functionBaseUrl + "SnapshotsDetailsFile"
  private SnapDeatailsFile: string = environment.functionBaseUrl + "SnapshotsDetailsFile"
  private fcmTokenAcess: string = environment.functionBaseUrl + "sendNotificationApi"
  private userListAcess: string = environment.functionBaseUrl + "getAllUserApi"
  private systemPrefereceURL: string = environment.functionBaseUrl + "getSystemPreferenceByKey"
  private AllUserDeatails: string = environment.functionBaseUrl + "getAllUserApi"
  private usersProfile: string = environment.functionBaseUrl + "YologramUsersProfiles"
  private yologramNetworkURL: string = environment.functionBaseUrl + "YologramNetwork"
  private yologramSnapDetailsURL: string = environment.functionBaseUrl + "YologramUserSnapshots"
  private getSubcollectionDocuments: string = environment.functionBaseUrl + "YologramUserSnapshots"
  private getSignedUrl: string = environment.functionBaseUrl + "generateSignedUrl"
  private getshortUrl: string = environment.functionBaseUrl + "createShortLink"
  private getNewshortUrl: string = environment.functionBaseUrl + "generateSignedUrlAndShorten"
  private getCompressImage: string = environment.functionBaseUrl + "compressImage"
  private getUserAvatrList: string = environment.functionBaseUrl + "listSubcollections"
  private catchdownloadAndProcessPack: string = environment.functionBaseUrl + "downloadAndProcessPack"
  private saveAvatarDataZip: string = environment.functionBaseUrl + "saveAvatarDataZip"
  private getallSnapShot: string = environment.functionBaseUrl + "YologramPublicSnapshots_P_v1"




  private getBrandList: string = environment.functionBaseUrl + "getUniqueBrandListBySnapshotDetails_v1"
  private getSnapShotByBrandAndUse: string = environment.functionBaseUrl + "getUniqueSnapListByBrandAndUser"
  private getcacheList: string = environment.functionBaseUrl + "getUniqueGarmentListBySnapshotDetails"
  private userCache360Grment: string = environment.functionBaseUrl + 'downloadAndProcessPack_userReferen'
  private getSnapShotByGranetAndUser :string = environment.functionBaseUrl +'getSnapShotsByUserAndGarment'

  // private getBrandListByAvatar: string = environment.functionBaseUrl + "getUniqueBrandListByAvatar_v1"

  private getBrandListByAvatar: string =  environment.functionBaseUrl +  "getUniqueBrandListByAvatar_v1"
  private getSnapShotByBrandAndAvatar: string = environment.functionBaseUrl +  "getUniqueSnapListByBrandAndAvatar_v1"
  private getcacheListByAvatar: string = environment.functionBaseUrl + "getSnapshotListByAvatarGarment"
  private getSnapShotByGranetAndAvatar : string = environment.functionBaseUrl + 'getSnapShotsByAvatarAndGarment'






  startTime: number;
  loadTime: number;

  private imageSelectionSubject: any

  // Observable for other components to listen to
  // imageSelection$ = this.imageSelectionSubject.asObservable();














  private yologramSnapDetailsAvatar: "http://127.0.0.1:5001/yolomoves-fb435/us-central1/YologramUserSnapshotsByAvatar"
  functions: any;

  selectedIndex: number
  sharedObject: any = {};
  data: any = [];

  // private AllUserDeatails: string = "http://127.0.0.1:5001/yolomoves-fb435/us-central1/getAllUserApi"


  constructor(private storage: AngularFireStorage, private firestore: AngularFirestore, private authService: ApiHttpService,
    private titleService: Title, private toastr: ToastrService, private auth: AngularFireAuth, private router: Router, private db: AngularFireDatabase, private http: HttpClient,) {

    this.startTime = performance.timing.navigationStart;
    this.loadTime = 0;

    this.auth.authState.subscribe((res) => {
      this.user = res;
      this.userid = this.user ? this.user['uid'] : null;
    });
  }

  getIdentifier(uid: string) {
    let url: string = environment.functionBaseUrl + "userProfileIdenifierById?id=" + uid;
    return this.http.get(url)
  }

  getZip(uid: string) {
    let url: string = "http://localhost:5001/yolouploader/us-central1/listAll"
    return this.http.get(url)
  }

  signUp(email: string, password: string) {
    return this.auth.createUserWithEmailAndPassword(email, password);
  }

  signIn(email: string, password: string) {
    return this.auth.signInWithEmailAndPassword(email, password);
  }

  getUser() {
    // const userJson = localStorage.getItem('user');
    // return userJson ? JSON.parse(userJson) : null;
    return this.auth.authState;
  }

  getAuth() {
    return this.auth.authState;
  }

  signOut() {
    this.auth.signOut();
    localStorage.removeItem('user'); // or use sessionStorage.removeItem('user');
    this.router.navigateByUrl('/drip');
    window.location.reload();
    return this.auth.signOut();
  }

  // async loginWithGoogle() {
  //   await this.auth
  //     .signInWithPopup(new firebase.auth.GoogleAuthProvider())
  //     .then((res) => {
  //      this.router.navigateByUrl('/yologram-social/yologram-public-news');
  //     })
  //     .catch((err) => {
  //       console.log('error while sign in', err);
  //     });
  // }


  // async loginWithGoogle() {
  //   try {
  //     const res = await this.auth.signInWithPopup(new firebase.auth.GoogleAuthProvider());
  //     this.storeUserData(res.user);
  //     this.router.navigateByUrl('/drip');
  //   } catch (err) {
  //     console.log('error while sign in', err);
  //   }
  // }
  async loginWithGoogle() {
    try {
      const res: any = await this.auth.signInWithPopup(new firebase.auth.GoogleAuthProvider());
      let userInfo = res.user._delegate;
      this.storeUserData(userInfo);
      console.log("userInfo==>", userInfo)
      return userInfo;
    } catch (err) {
      alert("Please check your network and Try Again !!")
      console.log('error while sign in', err);
      return null;
    }
    return null;
  }

  private storeUserData(user: any) {
    localStorage.setItem('user', JSON.stringify(user)); // For local storage
  }
  isLoggedIn(): boolean {

    return localStorage.getItem('user') !== null;
  }


  upload(uploadFileObject: any): Observable<any> {
    return this.http.post(this.uploadfiles, uploadFileObject)
  }


  checkViewPageRights(object) {
    return this.http.post(this.acccessPageVal, object);
  }

  snapshotfileDetais() {
    let url: string = this.SnapDeatailsFile;
    return this.http.get(url)
  }


  // getDocumentsFromSubcollection(inputObject) {
  //   let url:string = this.getSubcollectionDocuments;
  //   return this.http.post(url,inputObject);
  // }


  getDocumentsFromSubcollection(parentDocId: string, subcollectionName: string): Promise<any[]> {
    return this.firestore
      .collection('UserAvatar')
      .doc(parentDocId)
      .collection(subcollectionName)
      .get()
      .toPromise()
      .then((querySnapshot) => {
        const documents = [];
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          documents.push(data);
        });
        return documents;
      })
      .catch((error) => {
        console.error('Error getting documents:', error);
        return [];
      });
  }

  getUserAvatarDocumentById(docId: string): Promise<any> {
    return this.firestore
      .collection('UserAvatar')
      .doc(docId)
      .get()
      .toPromise()
      .then(docSnapshot => {
        if (docSnapshot.exists) {
          return docSnapshot.data();
        } else {
          throw new Error(`No document found with ID: ${docId}`);
        }
      })
      .catch(error => {
        console.error('Error getting document:', error);
        return null;
      });
  }



  getAllUser() {
    let url: string = this.AllUserDeatails;
    return this.http.get(url)
  }

  getUsersProfile(inputObject) {
    let url: string = this.usersProfile;
    return this.http.post(url, inputObject);
  }

  yologramNetwork(pagination) {
    let url: string = this.yologramNetworkURL;
    return this.http.post(url, pagination)
  }

  yologramSnapshotDetailsByUserId(reqObject) {
    // let url:string = environment.functionBaseUrl + "userProfileIdenifierById?id="+uid;
    let url: string = "https://us-central1-yolomoves-fb435.cloudfunctions.net/YologramUserSnapshots"
    // let url:string = this.yologramSnapDetailsURL+"?id="+userId;
    // let url:string = this.yologramSnapDetailsURL+"YologramUserSnapshots";
    return this.http.post(url, reqObject)
  }
  yologramSnapshotDetailsByAvatar(reqObject) {
    let url: string = "https://us-central1-yolomoves-fb435.cloudfunctions.net/YologramUserSnapshotsByAvatar"
    return this.http.post(url, reqObject)
  }

  yologramPublicByAvatar(reqObject) {
    let url: string = "https://us-central1-yolomoves-fb435.cloudfunctions.net/YologramUerByAllSnapshots"
    return this.http.post(url, reqObject)
  }
  yologramSnapshotDetailsByType(reqObject) {
    let url: string = "https://us-central1-yolomoves-fb435.cloudfunctions.net/YologramUserSnapshotsByGarment"
    return this.http.post(url, reqObject)
  }
  avatarPageExtractZip(reqObject) {
    let url: string = "https://us-central1-yolomoves-fb435.cloudfunctions.net/processAvatarDataZipForAvatarPage"
    return this.http.post(url, reqObject)
  }

  YologramWholeSnapshot() {
    let url: string = this.getallSnapShot;
    return this.http.get(url)
  }

  getSnapshots(): Observable<any> {
    return this.http.get<any>(this.getallSnapShot);
  }


  yologramSnapshotEnvironment(reqObject) {
    let url: string = "https://us-central1-yolomoves-fb435.cloudfunctions.net/YologramUserSnapshotsByEnvironment"
    return this.http.post(url, reqObject)
  }

  yologramSnapshotByBrands(reqObject) {
    let url: string = "https://us-central1-yolomoves-fb435.cloudfunctions.net/YologramUserSnapshotsByBrand"
    return this.http.post(url, reqObject)
  }


  yologramSnapshotByStoryID(reqObject) {
    let url: string = "https://us-central1-yolomoves-fb435.cloudfunctions.net/YologramSnapByStoryID"
    return this.http.post(url, reqObject)
  }

  yologramPublicSnapshot(reqObject) {
    let url: string = "https://us-central1-yolomoves-fb435.cloudfunctions.net/YologramPublicSnapshots"
    return this.http.post(url, reqObject)
  }


  fcmTokencheck() {
    let url: string = this.fcmTokenAcess;
    return this.http.get(url)
  }

  getAllUserList() {
    let url: string = this.userListAcess;
    return this.http.get(url);
  }

  getSystemPreferenceByKey(keyObject: any) {
    return this.http.post(this.systemPrefereceURL, keyObject);
  }

  scrollToElementById(id: any) {
    if (id == undefined) return
    const element = this.__getElementById(id);
    if (element) {
      element.parentElement.prepend(element);
    }
    this.scrollToElement(element);
  }

  scrollToElementByIdbrand(id: any, container: any) {
    if (id == undefined) return
    const element = this.__getElementById(id);
    if (element) {
      element.parentElement.prepend(element);
    }
    this.scrollToElementbrand(element, container);
  }

  scrollToElementbrand(element: HTMLElement, containerRef: ElementRef) {
    const container = containerRef.nativeElement;
    if (element && container) {

      element.scrollIntoView({ behavior: 'auto', block: 'start', inline: 'start' });

      setTimeout(() => {
        container.scrollLeft -= 15;
      }, 100);
    }

  }

  elementExistById(id: string) {
    let elementFlag = false;
    const element = this.__getElementById(id);
    if (element != undefined) elementFlag = true;
    return elementFlag;
  }

  private __getElementById(id: string): HTMLElement {
    // const element = <HTMLElement>document.querySelector(`#${id}`);
    return document.getElementById(id);
  }

  scrollToElement(element: HTMLElement) {
    if (element != undefined)
      //element.scrollIntoView({ behavior: 'auto', block: 'start', inline: 'start' });
      element.scrollIntoView({ behavior: 'auto', block: 'nearest', inline: 'start' });
  }

  scrollToElementByIdNavigation(id: any) {
    if (id != undefined) return;
    const elementNAVIGATION = this.__getElementById2(id);
    this.scrollToElementNavigation(elementNAVIGATION);
  }

  private __getElementById2(id: string): HTMLElement {
    // const element = <HTMLElement>document.querySelector(`#${id}`);
    const elementNAVIGATION = document.getElementById(id);
    return elementNAVIGATION;
  }

  scrollToElementNavigation(element: HTMLElement) {
    if (element != undefined)
      element.scrollIntoView({ behavior: 'smooth', inline: 'nearest' });
  }

  changeTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }

  updateUserProfile() {
    this.getAllUser().subscribe(async (allUsers: any) => {
      this.allUserDetails = allUsers.users;
      this.snapshotfileDetais().subscribe(async (snapshot: any) => {
        this.SnapShotFileDetails = snapshot;
        this.allUserDetails.forEach(object => {
          let filterSnapShots = this.SnapShotFileDetails.filter((snapShot) => snapShot.UserID == object.uid);
          if (filterSnapShots.length > 0) {
            this.userAllDetails.push(object)
          }
        });
        this.userAllDetails.forEach(object => {
          let details = this.allUserDetails.find((user) => user.uid == object.uid)
          let userDetail = details.providerData[0]
          let saveUserDetails = {
            "DisplayName": userDetail.displayName != undefined ? userDetail.displayName : '',
            "Email": userDetail.email != undefined ? userDetail.email : '',
            "FirstName": '',
            "Identifier": '',
            "LastName": '',
            "MiddleName": '',
            "ProfilePicture": userDetail.photoURL != undefined ? userDetail.photoURL : '',
            "UserID": details.uid != undefined ? details.uid : '',
          }
          this.firestore.collection('user-profile', ref =>
            ref.where('UserID', '==', saveUserDetails.UserID))
            .get()
            .subscribe(querySnapshot => {
              if (querySnapshot.empty) {
                // No existing document found, add the user profile
                this.firestore.collection('user-profile').add(saveUserDetails);
              } else {
                console.log("User profile already exists in Firestore");
              }
            });
        })
      })
    });
  }

  addUserProfile(userDetails: any): void {
    this.firestore
      .collection('user-profile', (ref) =>
        ref.where('UserID', '==', userDetails.UserID)
      )
      .get()
      .pipe(
        map((querySnapshot) => {
          if (querySnapshot.empty) {
            // No existing document found, add the user profile
            this.firestore.collection('user-profile').add(userDetails);
          } else {
            console.log('User profile already exists in Firestore');
          }
        })
      )
      .subscribe();
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {

    if (this.isLoggedIn()) {
      console.log("this.isLoggedIn()==>", this.isLoggedIn())
      return true; // Allow navigation if the user is logged in
    } else {
      // this.router.navigate(['/login']); // Redirect to login if not authenticated
      return false;
    }
  }

  async urlToFile(url: string, filename: string): Promise<File> {
    try {
      const response = await this.http.get(url, { responseType: 'blob' }).toPromise();
      return new File([response], filename, { type: response.type });
    } catch (error) {
      console.error('Error converting URL to File:', error);
      throw error;
    }

  }

  async findOutFFireBaseStorageUrl(filePath: string) {
    try {
      const ref = this.storage.ref(filePath);
      const downloadURL = await ref.getDownloadURL().toPromise();
      console.log(downloadURL);
      return downloadURL;
    } catch (error) {
      console.error('Error fetching VRM URL:', error);
      return null;
    }
  }

  // async findOutFFireBaseStorageUrl(filePath: string) {
  //   try {
  //     const generateSignedUrl = this.functions.httpsCallable('generateSignedUrl');
  //     const expiresIn = 3600; // Expiration time in seconds (1 hour)
  //     const { data } = await generateSignedUrl({ filePath, expiresIn }).toPromise();
  //     const signedUrl = data.signedUrl;
  //     console.log(signedUrl);
  //     return signedUrl;
  //   } catch (error) {
  //     console.error('Error fetching signed URL:', error);
  //     return null;
  //   }
  // }

  generateSignedUrl(filePath: string, expiresIn: number): Observable<any> {
    const fullUrl = `${this.getSignedUrl}?filePath=${filePath}&expiresIn=${expiresIn}`;
    return this.http.get(fullUrl);
  }

  generateSignednewUrl(filePath: string, expiresIn: number): Observable<any> {
    const fullUrl = `${this.getNewshortUrl}?filePath=${filePath}&expiresIn=${expiresIn}`;
    return this.http.get(fullUrl);
  }

  generateShortUrl(longUrl: string): Observable<any> {
    console.log(longUrl)
    console.log(longUrl)
    const fullUrl = `${this.getshortUrl}?longUrl=${longUrl}`;
    console.log(fullUrl)
    return this.http.get(fullUrl);
  }

  setIndex(index: number) {
    this.selectedIndex = index;
  }

  getIndex(): number {
    return this.selectedIndex;
  }

  setSharedObject(data: any): void {
    this.sharedObject = data;
  }

  getSharedObject(): any {
    return this.sharedObject;
  }

  async compressImage(path, type) {
    const functionUrl = this.getCompressImage;
    try {
      const response = await this.http.post(functionUrl, { path: path, type: type }).toPromise();
      return response;
    } catch (error) {
      return error;
    }
  }

  listSubcollections(docId: string): Promise<any> {
    const url = `${this.getUserAvatrList}?docId=${encodeURIComponent(docId)}`;
    return this.http.get(url).toPromise();
  }



  async findSubcollectionById(subcollectionId: string): Promise<any> {
    const userAvatarsRef = this.firestore.collection('UserAvatar').ref;
    try {
      const querySnapshot = await userAvatarsRef.get();
      for (const doc of querySnapshot.docs) {
        const subcollectionRef = doc.ref.collection(subcollectionId);
        const subcollectionSnapshot = await subcollectionRef.get();
        if (!subcollectionSnapshot.empty) {
          // Found the subcollection, do something with it
          console.log(`Subcollection found in parent document: ${doc.id}`);
          return doc.id;  // or you can return the whole document or subcollection as needed
        }
      }
      console.log('Subcollection not found in any parent document');
    } catch (error) {
      console.error('Error finding subcollection by ID:', error);
    }
  }

  fetchData() {
    const catRef = this.firestore.collection('UserAvatar').snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          this.data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...this.data }; // Concatenate id with data
        });
      })
    );


    return catRef; // This will return an Observable
  }


  downloadAndProcessPack(url: string, cloth: string) {
    const params = {
      url: url,
      cloth: cloth
    };
    return this.http.post(this.catchdownloadAndProcessPack, params);
  }


  downloadAndProcessPackUserReference(requestBody: any) {
    const params = requestBody
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    // Make the HTTP POST request
    return this.http.post<any>(this.userCache360Grment, params, { headers });
    // return this.http.post<any>(this.userCache360Grment, requestBody);
  }

  uploadFileFromUrl(url: string, brandId: string, AvatarId: string): Observable<string> {
    return new Observable<string>(observer => {
      console.log('Upload url' + url);
      this.http.get(url, { responseType: 'blob' }).subscribe(blob => {
        let filePath = '/BrandAvatar/' + brandId + '_' + AvatarId;
        console.log('Upload blob' + blob);
        const fileRef = this.storage.ref(filePath);
        const task = fileRef.put(blob);
        task.percentageChanges().subscribe(percentage => {
          console.log(`Upload is ${percentage}% done`);
        });
        task.then(() => {
          console.log('Upload complete');
          fileRef.getDownloadURL().subscribe(downloadURL => {
            console.log(`File available at ${downloadURL}`);
            observer.next(downloadURL);
            observer.complete();
          }, error => {
            observer.error(error);
          });
        });
      }, error => {
        observer.error(error);
      });
    });
  }




  async saveAvatarData(body: any): Promise<Observable<any>> {
    let functionurl = this.saveAvatarDataZip
    return await this.http.post<any>(functionurl, body);
  }

  /**
    * Save data to a specified document in a subcollection.
    * 
    * @param parentDocId The ID of the parent document.
    * @param subcollectionName The name of the subcollection.
    * @param docId The ID of the document to save data in.
    * @param data The data to save in the document.
    * @returns A Promise resolved with the result of the document creation or update.
    */

  saveDataToDocument(parentDocId: string, subcollectionName: string, docId: string, data: { AvatarPreviewFullBodyUrl: string, AvatarPreviewPortraitUrl: any }): Promise<void> {
    return this.firestore
      .collection('UserAvatar')
      .doc(parentDocId)
      .collection(subcollectionName)
      .doc(docId)
      .set(data, { merge: true })  // Using set with { merge: true } to update or create with merging existing data
      .then(() => {
        console.log(`Document with ID ${docId} updated successfully.`);
      })
      .catch((error) => {
        console.error('Error updating document:', error);
      });
  }

  getFirstDocumentFromSubcollection(parentDocId: string, subcollectionName: string): Promise<any> {
    const url = `http://127.0.0.1:5001/yolomoves-fb435/us-central1/getFirstDocumentFromSubcollection`;
    const params = {
      parentDocId: parentDocId,
      subcollectionName: subcollectionName
    };
    return this.http.post(url, params).toPromise();
  }


  setLoadTime(): void {
    this.loadTime = performance.now();
  }

  getLoadTime(): number {
    return this.loadTime;
  }


  // Get user's avatar details
  getUserAvatarDetails(userId: string, avatarId: string): Promise<any[]> {
    return this.firestore
      .collection('UserAvatar')
      .doc(userId)
      .collection(avatarId)
      .get()
      .toPromise()
      .then((querySnapshot) => {
        const documents = [];
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          documents.push(data);
        });
        return documents;
      })
      .catch((error) => {
        console.error('Error getting documents:', error);
        return [];
      });
  }

  
  getGarmentPanelData(fileType: string, garmentId: string): Observable<any> {
    let baseUrl = 'http://127.0.0.1:5001/yolomoves-fb435/us-central1'
    const params = new HttpParams()
      .set('fileType', fileType)
      .set('garmentId', garmentId);
    return this.http.get(`${baseUrl}/getGarmentPanelData`, { params });
  }

  emitImageSelectionData(data: any) {
    this.imageSelectionSubject = data
    return this.imageSelectionSubject
  }


  // Create a service to handle data fetching for the User, ensuring clean and modular code for efficient data retrieval and management

  getUniqueBrandListByUserId(userId: string): Observable<{ brands: string[] }> {
    const params = new HttpParams().set('userId', userId);
    return this.http.get<{ brands: string[] }>(`${this.getBrandList}`, { params });
  }

  getUniqueCacheImageList(userId: string): Observable<{ garments: string[] }> {
    const params = new HttpParams().set('userId', userId);
    return this.http.get<{ garments: string[] }>(`${this.getcacheList}`, { params });
  }

  getSnapshotsByUserAndBrand(userId: string, brandId: string): Observable<{ snapshots: any[] }> {
    const url = `${this.getSnapShotByBrandAndUse}`;
    const params = new HttpParams().set('userId', userId).set('brandId', brandId);
    return this.http.get<{ snapshots: any[] }>(url, { params });
  }

  getSnapshotsByUserAndGarment(userId: string, clothId: string): Observable<{ snapshots: any[] }> {
    const url = `${this.getSnapShotByGranetAndUser}`;
    const params = new HttpParams().set('userId', userId).set('clothId', clothId);
    return this.http.get<{ snapshots: any[] }>(url, { params });
  }

  //----------------------------------End-----------------------------------------------------------




// Create a service to handle data fetching for the Avatar, ensuring clean and modular code for efficient data retrieval and management

  getUniqueBrandListByAvatar(avatarId: string): Observable<{ brands: string[] }> {
    const params = new HttpParams().set('avatarId', avatarId);
    return this.http.get<{ brands: string[] }>(`${this.getBrandListByAvatar}`, { params });
  }


  getSnapshotsByAvatarAndBrand(avatarId: string, brandId: string): Observable<{ snapshots: any[] }> {
    const url = `${this.getSnapShotByBrandAndAvatar}`;
    const params = new HttpParams().set('avatarId', avatarId).set('brandId', brandId);
    return this.http.get<{ snapshots: any[] }>(url, { params });
  }

  getUniqueCacheImageListByAvatar(avatarId: string): Observable<{ garments: string[] }> {
    const params = new HttpParams().set('avatarId', avatarId);
    return this.http.get<{ garments: string[] }>(`${this.getcacheListByAvatar}`, { params });
  }

  getSnapshotsByAvatarAndGarment(avatarId: string, clothId: string): Observable<{ snapshots: any[] }> {
    const url = `${this.getSnapShotByGranetAndAvatar}`;
    const params = new HttpParams().set('avatarId', avatarId).set('clothId', clothId);
    return this.http.get<{ snapshots: any[] }>(url, { params });
  }

//----------------------------------End-----------------------------------------------------------

getUserAvatars(): Observable<any[]> {
  return this.firestore.collection('UserAvatar').valueChanges({ idField: 'id' }) as Observable<any[]>;
}
  
}


