import { Component, ElementRef, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Brand } from 'src/app/models/brand';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-brand-spaces',
  templateUrl: './brand-spaces.component.html',
  styleUrls: ['./brand-spaces.component.css']
})
export class BrandSpacesComponent {


  brandsFilesList = this.firestore.collection('brands').valueChanges({ idField: 'id' }) as Observable<Brand[]>;
  brandSpacesList = this.firestore.collection('BrandSpaces').valueChanges({ idField: 'id' }) as Observable<Brand[]>;
  environmentCollection = this.firestore.collection('Environments').valueChanges({ idField: 'id' }) as Observable<Brand[]>;


  @ViewChild('brandStoriyScroll', { read: ElementRef }) public BrandStoriyScroll: ElementRef<any>;
  @ViewChild('brandStoriyScrollA', { static: false }) brandStoriyScrollA: ElementRef;

  private scrollEventListener: () => void;
  private slider: HTMLElement;

  logInFlag: boolean;
  hidePanel: boolean;
  mainMenuFlag: boolean = false;
  snaploader: boolean;
  windowHeight: number;
  brandList: any = [];
  evenfileList: any = [];
  byScroll: boolean;
  scrollFlag: boolean
  scrolnewlFlag: boolean
  selectedBrand: any;
  selectedBrandId: any;
  brandNameSelected: any;
  exclosiveMode: any;
  filterBrandListNew: any = [];
  selectedevenUrl: any;
  gltfImgSrc = 'https://firebasestorage.googleapis.com/v0/b/yolouploader.appspot.com/o/userData%2Ffiles%2Fcrystal_ball.glb?alt=media&token=963e0d80-5809-4e1b-9034-caf31fb33945'
  functionFlag: any = '';
  evnSnapList: any = [];
  selectedEvenObj: any;
  sectedEvenFlag: boolean;
  brandSpaces: any = [];
  filteredEvenfileList: any = [];
  isShowBrandSpaces: boolean;
  loading: boolean;
  showPanel: boolean;
  isLoadEnv: boolean;




  constructor(private commonService: CommonService, private firestore: AngularFirestore,private router: Router) {
    this.brandsFilesList.subscribe(brandList => {
      this.brandList = brandList
      this.brandList.forEach(async (brand) => {
        let type = 'b';
        let url = await this.commonService.compressImage(brand.LogoURL, type); // Assuming each brand has 'imagePath' property
        brand.Brandurl = url.downloadUrl;  // Save the compressed image URL back into the brand object
      });
      console.log("brand List", this.brandList)
    });

    this.environmentCollection.subscribe(evenList => {
      this.evenfileList = evenList
      this.evenfileList.forEach(async (even) => {
        let type = 'e';
        let url = await this.commonService.compressImage(even.storageURL, type); // Assuming each brand has 'imagePath' property
        even.evnCompressUrl = url.downloadUrl;  // Save the compressed image URL back into the brand object
      });
      console.log("evenfileList", this.evenfileList)
    });

    this.brandSpacesList.subscribe(brandSpaces => {
      this.brandSpaces = brandSpaces
    });

  }


  ngOnInit(): void {
    this.windowHeight = window.innerHeight;
    let title = 'Yologram - brand-spaces'
    this.commonService.changeTitle(title);
    this.logInFlag = this.commonService.isLoggedIn()
    this.checkPageAuthorization()
  }

  selectByScroll() {
    this.byScroll = true
    this.scrolnewlFlag = true
  }

  selectBrand(brand) {
    // this.selectedEvenObj = {}
    this.closePanle()
    this.selectedBrand = brand
    this.selectedBrandId = brand.id
    this.brandNameSelected = brand.BrandName
    this.scrollFlag = false
    this.scrolnewlFlag = false


    console.log("brand", brand)
    let gender = "g"
    let tab = brand.Gender == 'M' ? 'mens-wear' : brand.Gender == 'F' ? 'womens-wear' : 'story'
    // this.setTab(tab, gender)
    setTimeout(() => {
      let brandId = brand.id
      this.commonService.scrollToElementByIdbrand(brandId, this.brandStoriyScrollA);
      // Adjust the scroll position by -60px after the service call
      const element = document.getElementById(brandId);
      if (element) {
        element.scrollLeft -= 60;
       

      }
    }, 50); // Adjust the timeout duration as needed (500ms in this example)
    this.isShowBrandSpaces = false
    console.log("this.evenfileList", this.evenfileList)
    this.getListOfselectedBrandSpaces()

    setTimeout(() => { 
      this.byScroll = false
      this.scrolnewlFlag = false}, 500);
  }

  suggest($event) {
    this.scrollFlag = true;
    this.byScroll = true;
    console.log("byScroll 7", this.byScroll)
    let inputValue = $event.target.value.toLowerCase();
    const startsWithMatches = this.brandList.filter(brand =>
      brand.BrandName && brand.BrandName.toLowerCase().startsWith(inputValue)
    );

    const includesMatches = this.brandList.filter(brand =>
      brand.BrandName && brand.BrandName.toLowerCase().includes(inputValue)
    ).filter(brand => !startsWithMatches.includes(brand));
    this.filterBrandListNew = startsWithMatches.slice(0, 10).concat(includesMatches.slice(0, 10));
  }

  blankplaceHolder() {
    this.brandNameSelected = ''
  }

  async selectEven(even) {
    this.sectedEvenFlag = true
    this.selectedEvenObj = even
    this.selectedevenUrl = ''
    this.functionFlag = null
    this.hidePanel = false
    this.selectedevenUrl = even.evnCompressUrl
    let storageURL = even.storageURL;

    // Remove "Environments/" from the storageURL
    let filename = storageURL.replace('Environments/', '');
    console.log(filename); // Output: "PhotoOxy.com_16462398f68c46.jpg"
    let reqInputObject = { id: filename, pageNumber: 1 };
    // Decrease callpage by 1
    const response = await this.commonService.yologramSnapshotEnvironment(reqInputObject).toPromise();

    this.evnSnapList = response['object']
    this.snaploader = false;



    console.log('', this.evnSnapList);

    this.isLoadEnv = true
    console.log(even)
    setTimeout(() => {
      this.selectedevenUrl = even.evnCompressUrl
      this.functionFlag = 'skybox';
      console.log('Delayed execution, evntempFile set:', this.selectedevenUrl);
      
    }, 500); // Adjust the delay (in milliseconds) as needed
  }

  saveBrandSpace() {
    let brandSpaceObj = { BrandId: this.selectedBrand.id, EnvironmentId: this.selectedEvenObj.id };
    // Query Firestore to check if the combination of BrandId and EnvironmentId already exists
    this.firestore.collection('BrandSpaces', ref =>
      ref.where('BrandId', '==', this.selectedBrand.id)
         .where('EnvironmentId', '==', this.selectedEvenObj.id)
    ).get().toPromise()
      .then((querySnapshot) => {
        if (querySnapshot.empty) {
          // No duplicates found, proceed to add the new document
          this.firestore.collection('BrandSpaces').add(brandSpaceObj)
            .then(() => {
              console.log('Brand space added successfully');
              alert('Brand space added successfully');
              this.getListOfselectedBrandSpaces()

            }).catch((error) => {
              console.error('Error adding brand space: ', error);
            });
        } else {
          // Duplicate found, do not add the new document
          console.log('Duplicate entry found. Brand space already exists.');
          alert('Duplicate entry: Brand space already exists.');
        }
      })
      .catch((error) => {
        console.error('Error checking for duplicate entries: ', error);
      });
  }
  


  scrollEven() {
    this.sectedEvenFlag = false
  }


  getListOfselectedBrandSpaces() {
    let brand = this.selectedBrand;
    // Step 1: Filter the brandSpaces for the selected brand
    let bSpace = this.brandSpaces.filter((b) => b.BrandId == brand.id);
    // Step 2: Get the list of EnvironmentId from the filtered brand spaces
    let EnvironmentIds = bSpace.map((b) => b.EnvironmentId);
    // Step 3: Filter the evenfileList using the EnvironmentId values from bSpace
    let filteredEvenfileList = this.evenfileList.filter((e) => EnvironmentIds.includes(e.id));
    // Step 4: Log the results
    console.log('bSpace', bSpace);
    console.log('filteredEvenfileList', this.filteredEvenfileList != null );
    console.log('filteredEvenfileList', this.filteredEvenfileList  == undefined );
    console.log('filteredEvenfileList', this.filteredEvenfileList.length == 0);

    // Optionally, save this new array for future use
    this.filteredEvenfileList = filteredEvenfileList;
    if (this.filteredEvenfileList.length == 0) {
      this.isShowBrandSpaces = false
    } else {
      this.isShowBrandSpaces = true
    }
  }

  deleteBrandSpace() {
    // Show a confirmation dialog
    const confirmed = confirm('Are you sure you want to delete this Brand Space?');
  
    // Proceed only if the user confirms the deletion
    if (confirmed) {
      // Query Firestore to find the document with the matching BrandId and EnvironmentId
      this.firestore.collection('BrandSpaces', ref =>
        ref.where('BrandId', '==', this.selectedBrand.id)
           .where('EnvironmentId', '==', this.selectedEvenObj.id)
      ).get().toPromise()
        .then((querySnapshot) => {
          if (!querySnapshot.empty) {
            // If a matching document is found, delete it
            querySnapshot.forEach((doc) => {
              this.firestore.collection('BrandSpaces').doc(doc.id).delete()
                .then(() => {
                  console.log('Brand space deleted successfully');
                  alert('Brand space deleted successfully');
                  this.getListOfselectedBrandSpaces();
                  this.closePanle();
                  this.selectedEvenObj = null;
                }).catch((error) => {
                  console.error('Error deleting brand space: ', error);
                });
            });
          } else {
            // No matching document found
            console.log('No matching Brand space found to delete.');
            alert('No matching Brand space found to delete.');
          }
        })
        .catch((error) => {
          console.error('Error querying for brand space to delete: ', error);
        });
    } else {
      console.log('User canceled the deletion.');
    }
  }
  



  addNew() {
    this.isShowBrandSpaces = false
    this.selectedEvenObj = null
    this.closePanle() 
   }



   checkPageAuthorization() {
    this.loading = true;
    this.commonService.getUser().subscribe((user: any) => {
      let userObject = { 'userid': user?.uid, 'pagename': '/brand-spaces' }
      this.commonService.checkViewPageRights(userObject).subscribe((res) => {
        console.log(res)
        this.loading = false;
        if (!res) {
          this.router.navigateByUrl("/drip");
        }
      });
    });
  }
  

  closePanle() { 
    this.selectedEvenObj =  null
    this.evnSnapList = null
    this.showPanel = false
    this.selectedevenUrl = ''
    this.functionFlag = null;
    setTimeout(() => {
      this.selectedevenUrl = ''

      this.functionFlag = 'skybox';
      console.log('Delayed execution, evntempFile set:', this.selectedevenUrl);
    }, 1000);
  }

  handleSkaybox(data){
    if(data){
      setTimeout(() => {
      this.isLoadEnv = false
    }, 1500);
    }

}

isInFilteredList(EnvironmentId: string): boolean {


  const match = this.brandSpaces?.some(item => 
    item.EnvironmentId === EnvironmentId && item.BrandId === this.selectedBrand?.id
  );


  return match;
}
closepanelbyOverClick(){
  this.hidePanel =  !this.hidePanel
}


}