import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiHttpService } from 'src/app/services/api-http.service';
import { CommonService } from 'src/app/services/common.service';
import { DataSharingService } from 'src/app/services/data-sharing-service';
import { environment } from 'src/environments/environment';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserPopupComponent } from 'src/app/common/templets/user-popup/user-popup.component';

@Component({
  selector: 'app-network',
  templateUrl: './network.component.html',
  styleUrls: ['./network.component.css']
})
export class NetworkComponent implements OnInit {
  garments = this.firestore.collection('garments').valueChanges({ idField: 'id' }) as Observable<any[]>;
  user = this.firestore.collection('user-profile').valueChanges({ idField: 'id' }) as Observable<any[]>;

  SnapShotFileDetails: any = []
  filterSnapShotFileDetails: any = []
  filterByUserFlag:boolean = false
  userSnapShotList: any = []
  userAllDetails: any = []
  profileDetails: any = {};
  garmentdetails: any = []
  userfilterSnapShots: any = []
  filterSnapShotFileDetailsall: any = []
  userdetails: any = []
  userData: any = []
  userDetail: any = []
  rootForNAvigation :any  ='yologram-social/yologram-user-profile-feed'
  routLogInFlag:any = true
  networkList = {};
  userId: string;
  matchingItem: any;
  constructor(private firestore: AngularFirestore,
    private route: ActivatedRoute,
    public router: Router,
    private dataSharingService : DataSharingService,
    private apiHttpService: ApiHttpService,
    private modal: NgbModal,     

     private commonService: CommonService) { 



      this.snapShotInit()
     }

  ngOnInit(): void {
    this.route.paramMap.subscribe(paramMap => {
      this.userId = paramMap.get('id');
    });
    this.garments.subscribe(garmentlist => {
      this.garmentdetails =  garmentlist
    });
    this.user.subscribe(userdetails => {
      this.userData =  userdetails
    });
  }


  
  snapShotInit() {

    // this.commonService.snapshotfileDetais().subscribe(async (snapshot: any) => {
    //   this.networkList = []
    //   this.SnapShotFileDetails = snapshot;
    //   this.SnapShotFileDetails.forEach(async (snapshot) => {
    //     if(this.networkList[snapshot.UserID] == undefined) {
    //     this.networkList[snapshot.UserID] = snapshot;
    //    let url = environment.functionBaseUrl + "userProfileIdenifierById?id=" + snapshot.UserID;
    //     await this.apiHttpService.get(url).subscribe((data: any) => {
    //       let profileName = data.displayName != undefined ? data.displayName : snapshot.UserID;
    //       let networkUserObject = {
    //         'userID' : snapshot.UserID,
    //         'profileName' : profileName
    //       };
    //       this.networkList[snapshot.UserID] = networkUserObject;
    //       this.userdetails = networkUserObject
    //     });
    //   }
 


      
    //   });
    //   })

      
      this.commonService.snapshotfileDetais().subscribe(async (snapshot: any) => {
        this.SnapShotFileDetails = snapshot;
        this.userData.forEach(object => {
            let filterSnapShots = this.SnapShotFileDetails.filter((snapShot) =>  snapShot.UserID == object.UserID);
            this.userfilterSnapShots = filterSnapShots
            if(filterSnapShots.length > 0)
           { 
            this.userAllDetails.push(object) 
          }
        });
        this.update()
      });
    
    }






    //    findCommonData() {
    //     return  this.SnapShotFileDetails.filter(item1 => {
    //         this.matchingItem = this.userData.find(item2 => item2.UserID === item1.UserID);
    //         return this.matchingItem !== undefined;
    //     }
       
    //     );
    // }
    



    update(){
      // this.commonService.snapshotfileDetais().subscribe(async (snapshot: any) => {
      //   this.SnapShotFileDetails = snapshot;
      //   this.allUserDetails.forEach(object => {
      //     let filterSnapShots = this.SnapShotFileDetails.filter((snapShot) => snapShot.UserID == object.uid);
      //     if (filterSnapShots.length > 0) {
      //       this.userAllDetails.push(object)
      //     }
      //   });
      this.commonService.getAllUser().subscribe(async (allUsers: any) => {
        this.userDetail = allUsers.users;
        this.userfilterSnapShots.forEach(object => {
          let details = this.userDetail.find((user) => user.uid == object.uid)
         let userDetail = details.providerData[0]
              let saveUserDetails = {
                "DisplayName": userDetail.displayName != undefined ? userDetail.displayName : '',
                "Email": userDetail.email != undefined ? userDetail.email : '',
                "FirstName": '',
                "Identifier": '',
                "LastName": '',
                "MiddleName": '',
                "ProfilePicture": userDetail.photoURL != undefined ? userDetail.photoURL : '',
                "UserID": userDetail.uid != undefined ? userDetail.uid : '',
                "pageName":'fam'
              }
              this.firestore.collection('user-profile', ref =>
              ref.where( 'UserID', '==', saveUserDetails.UserID))
              .get()
              .subscribe(querySnapshot => {
                  if (querySnapshot.empty) {
                      // No existing document found, add the user profile
                      this.firestore.collection('user-profile').add(saveUserDetails);
                  } else {
                      console.log("User profile already exists in Firestore");
                  }
              });
            })
        })
  
    }
  

      getSnapShotListByUser(snapshot: any) {
        let userId = snapshot.userID;
        this.filterByUserFlag = true
        this.filterSnapShotFileDetails = this.SnapShotFileDetails.filter((snapshot) => snapshot.UserID == userId);
        this.dataSharingService.sendUserSnapShots(this.filterSnapShotFileDetails);
        this.router.navigate(['yologram-social/yologram-public-news-feed', { 'id': userId }])
      }
      getSnapShotListByUserFeed(userIdforFeed:any) {
        let userId = userIdforFeed.userID;
        this.filterSnapShotFileDetails = this.SnapShotFileDetails.filter((snapshot) => snapshot.UserID == userId);
        this.dataSharingService.sendUserSnapShots(this.filterSnapShotFileDetails);
        this.router.navigate(['yologram-social/news-admin', { 'id': userId }])
     }
     getUserSnapShotDashboar(userId) {
      let userSnapShotList = this.SnapShotFileDetails.filter((snapshot) => snapshot.UserID == userId);
      this.dataSharingService.sendUserSnapShots(userSnapShotList);
      this.router.navigate(['yologram-social/yologram-public-news-feed', { 'id': userId }])
    }
    getSnapShotListByUserPopup(userdetails: any) {
      let userId = userdetails.key;
       let url = environment.functionBaseUrl + "userProfileIdenifierById?id=" + userId;
           this.apiHttpService.get(url).subscribe((data: any) => {
            this.profileDetails = data
          })
      this.filterSnapShotFileDetails = this.SnapShotFileDetails.filter((snapshot) => snapshot.UserID == userId).slice(0,3);
      this.filterSnapShotFileDetailsall = this.SnapShotFileDetails.filter((snapshot) => snapshot.UserID == userId)
      // this.dataSharingService.sendUserSnapShots(this.filterSnapShotFileDetails);
    }


    getFeed(brand: any){
      let garment = this.garmentdetails
      let branddetails =  this.garmentdetails.find((snapshot) =>snapshot.id === brand.BottomwearID  || brand.HeadwearID || brand.FootwearID ||  brand.UpperwearID )
      this.dataSharingService.sendUserSnapShots(this.filterSnapShotFileDetailsall);
      this.router.navigate(['yologram-social/yologram-public-news', {'brandId':branddetails.BrandID, 'storyId':brand.StoryID }])
      }


      getSelectSnapshotFeed(userSnap: any) {
        let userId = userSnap.UserID
        let storyid = userSnap.StoryID
        let userSnapShotList = this.userSnapShotList.filter((snapshot) => snapshot.UserID == userId && snapshot.StoryID == storyid);
        this.dataSharingService.sendUserSnapShots(userSnapShotList);
        this.router.navigate(['yologram-social/yologram-public-news', { 'id': userId, 'storyId': storyid }])
      }




      openUserPopup(data:any) {
        const modalRef = this.modal.open(UserPopupComponent,{
          modalDialogClass:"model-garmentPopup"
           });
        modalRef.componentInstance.id = 10;
        modalRef.componentInstance.userData = data;
        modalRef.result.then((result) => {
          console.log(result);
        }).catch((error) => {
          console.log(error);
        });
      }
    
}


