import { Component, OnInit } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import * as JSZip from 'jszip/dist/jszip'
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { Brand } from 'src/app/models/brand';
import { PinterestService } from 'src/app/services/pinterest-service';

@Component({
  selector: 'app-yologram-public-style',
  templateUrl: './yologram-public-style.component.html',
  styleUrls: ['./yologram-public-style.component.css']
})
export class YologramPublicStyleComponent implements OnInit {
  brandsCollection = this.firestore.collection('brands').valueChanges({ idField: 'id' }) as Observable<Brand[]>;
  brandsCollections = this.firestore.collection('brands').valueChanges({ idField: 'id' }) as Observable<Brand[]>;
  brandsBoardCollections = this.firestore.collection('brand-board').valueChanges({ idField: 'id' }) as Observable<Brand[]>;
  displayedColumns: string[] = ['position', 'name', 'weight', 'symbol'];
  public text = `{ "text": "This is text file!中文" }`;
  public fileName: string | undefined;
  //zipFiles: Observable<ZipFile[]>;
  zipFile: any;
  zipList: any = {};
  binaryImg: any;
  testVar: any = 'Prashatn';
  keyvalue: any;
  boardId: any;
  brands: any;
  womensBrands: any;
  mensBrands: any;
  packJsonObject = []
  isShowDiv = false;
  hFiles:any [];
  IdBrands: Brand[];
  brandIDDeatils: any;
  womensBrandId: any;
  brandDetails: any;
  womensBrandss: Brand[];
  bName:any;
  boardDetails :any;

  selectedGender:any = "";
  selectedBrandId: any = "";
  selectedBrand: any;
  errMessage:any = "Select The Brand ";
  isloading= false;
  boards:any=[]
  brandBoard:any=[]
 

  constructor(
    private firestore: AngularFirestore,
    public sanitizer: DomSanitizer,
    private pinterestAPI:PinterestService,
    private _httpClient: HttpClient ) {
    this.testVar = 'Rahulkar';
    this.downloadZipFile();{
      
    }

    this.pinterestAPI.getBoardList().subscribe((data) => {
      this.boards = data.items
    })   
    this.brandsBoardCollections.subscribe((brandBoard)=>{
      this.brandBoard = brandBoard
    })


    this.brandsCollections.subscribe(
      (brandList) => {
        this.brands = brandList;
        this.mensBrands = brandList.filter((b: any) => b.Gender === 'M' || b.Gender === 'B');
        this.womensBrands = brandList.filter((b: any) => b.Gender === 'F' || b.Gender === 'B');
      });

      this.brandsCollection.subscribe(
        (brandList) =>{
          this.brands = brandList;
        });
  }
  ngOnInit(): void {
    // throw new Error('Method not implemented.');
  }
  

  createImageFromBlob(image: Blob) {
    let reader = new FileReader();
    reader.addEventListener(
      'load',
      () => {
        this.binaryImg = reader.result;
      },
      false
    );
    if (image) {
      reader.readAsDataURL(image);
    }
  }

  async getZipFileContent(fileName: any) {
    var binary = await this.zipFile
      .file(fileName)
      .async('blob')
      .then(function (text: any) {
        return text;
      });
    this.binaryImg = URL.createObjectURL(binary); // create an url. img.src = URL.
  }

  downloadZipFile() {
    this.testVar = 'Change';
      let brandname =this.selectedBrand;;
      let brandId = this.selectedBrandId
      let zipfilename =  "%2Fpack0"
      let gender  = this.selectedGender;
      let packlocation = "AvatarBlenderClothes%2F"+brandId+"_"+ gender +zipfilename;
      let brandEncodedurl = packlocation
      this.isloading = true;
      let url = 'https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/'+brandEncodedurl+'?alt=media&token=46df48b9-1f7a-4b61-98c6-bff029734da4'
    this._httpClient
      .get(
        url,
        {
          observe: 'response',
          responseType: 'blob',
        }
        
      )
      .subscribe(async (res) => {
        await this.processZipFile(res.body, this.processResponse.bind(this));
        this.isloading = false;
      },
      (error) => this.handleError(error)
      );
  }
  
  handleError(error){
    this.packJsonObject = [];
    this.isloading = false;
    this.errMessage  = "Pack is not found for this selection !!!" 
    this.isloading = false;
    //alert("The pack is not found !!!");    
  }

  processResponse(res:any[]) {
    this.packJsonObject = res;

  }

    processZipFile(data, callBackSetJsonObject) {
    this.testVar = 'Change 121';

    this.zipList = JSZip.loadAsync(data, callBackSetJsonObject, this.testVar)
      .then(function (zip) {
        /* Parse json file */
        var jsonExtn = /(.json)$/;
        Object.keys(zip.files)
          .filter((fileName) => {
            return jsonExtn.test(fileName.toLowerCase());
          })
          .map((jsonFileName) => {
            var jsonFile = zip.files[jsonFileName];
            return jsonFile.async('string').then((jsonString) => {
              let packJsonObject = JSON.parse(jsonString);
              
              let jsonResponseData =
              [
                {
                  title : 'headgear',
                  value :  
                  {'files': packJsonObject['hFiles'].filter((data) => data != '' ),
                  'name'  : packJsonObject['hName'].filter((data) => data.indexOf('deleted') < 0 )
                  }
                },
                {
                  title : 'upperwear', 
                  value : {'files': packJsonObject['uFiles'].filter((data) => data != ''),
                    'name'  : packJsonObject['uName'].filter((data) => data.indexOf('deleted') < 0 ),
              
                  }
                },
                {
                  title : 'bottomwear',
                  value :  {'files': packJsonObject['bFiles' ].filter((data) => data != '' ),
                 
                    'name'  : packJsonObject['bName'].filter((data) => data.indexOf('deleted') < 0 )
                  }
                },
                { title :  "footwear",
                  value : {'files': packJsonObject['fFiles'].filter((data) => data != ''),
                  'name'  : packJsonObject['fName'].filter((data) => data.indexOf('deleted') < 0 )
                  },
                }
              ]                
              callBackSetJsonObject(jsonResponseData);

            });

          });
        var re = /(.jpg|.png|.gif|.ps|.jpeg | .json)$/;
        var promises = Object.keys(zip.files)
          .filter(function (fileName) {
            // don't consider non image files
            return re.test(fileName.toLowerCase());
          })
          .map(function (fileName) {
            var file = zip.files[fileName];

            if (fileName.indexOf('.json') > 0) {

            } else {
              return file.async('blob').then(function (blob) {
                return [
                  fileName.substring(0, fileName.lastIndexOf('.')), // keep the link between the file name and the content
                  URL.createObjectURL(blob), // create an url. img.src = URL.createObjectURL(...) will work
                ];
              });
            }
          });
        // `promises` is an array of promises, `Promise.all` transforms it
        // into a promise of arrays
        return Promise.all(promises);
      })
      .then(function (result) {
        // we have here an array of [fileName, url]
        // if you want the same result as imageSrc:
        return result.reduce(function (acc, val) {
          acc[val[0]] = val[1];
          return acc;
        }, {});
      })
      .then((result) => {
        this.testVar = 'Prasahtn Rahulkar';
        this.zipList = JSON.parse(JSON.stringify(result));
        // callback(result);
        return;
        //return result.reduce(...);
      })
      .catch(function (e) {
        console.error(e);
      });

  }

  returnZero() {
    return 0
  }

  getBrandImage(imgURL: string) {
    let brandEncodedurl = encodeURI(imgURL).replace('/', '%2F');
    let url = "https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/" + brandEncodedurl + "?alt=media&token=550e7e88-bed3-4668-84ff-ad491c19e06c"
    return url;
  }

  
  showBrandPackDetails(brand: any, gendar:string) {
    this.selectedBrandId = brand.id;
    this.selectedGender = gendar
    this.selectedBrand = brand;
    this.errMessage = ""
    this.downloadZipFile()
    let brandBoardFilter = this.brandBoard.find((bBoard)=>bBoard.brandId == brand.id) 
    this.boardDetails = (brandBoardFilter != undefined) ? this.boards.find((board)=>board.id == brandBoardFilter.boardId) : undefined;
  }
  selectGender($event) {
    this.selectedGender = $event.target.value
    this.isShowDiv = !this.isShowDiv;
  }
  getStyleForGender(brand: any, gendar:string){
   
    this.selectedBrandId = brand.id;
    this.selectedGender = gendar
    this.errMessage = ""
    this.downloadZipFile()
  }
  
  
  selectBrandForScroll(){
    document.querySelector('#target').scrollIntoView({ behavior: 'smooth', block: 'center' });
  }
  
}



