
import { Component, ElementRef, HostListener, Inject, Input, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { PinterestService } from 'src/app/services/pinterest-service';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { catchError, fromEvent, map, Observable, Subscription, tap } from 'rxjs';
import { Brand } from 'src/app/models/brand';
import { Snapshot } from 'src/app/models/snapshot';
import { ApiHttpService } from 'src/app/services/api-http.service';
import { HttpClient } from '@angular/common/http';

import { environment } from 'src/environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { CommonService } from 'src/app/services/common.service';
import { ActivatedRoute, Router, Routes } from '@angular/router';
import { DataSharingService } from 'src/app/services/data-sharing-service';
import { DOCUMENT } from '@angular/common';
import { PopupThreeDComponent } from 'src/app/common/popup-three-d/popup-three-d.component';
import { UserPopupComponent } from 'src/app/common/templets/user-popup/user-popup.component';
import { AnyARecord } from 'dns';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BrandPopupComponent } from 'src/app/common/templets/brand-popup/brand-popup.component';
import { GarmentPopupComponent } from 'src/app/common/templets/garment-popup/garment-popup.component';
import { PinPublishViewerDilog } from 'src/app/common/templets/pin-publish-popup/pin-publish';
import { ToastComponent } from 'src/app/common/templets/toast/toast';
import { NotifierService } from 'angular-notifier';
import { NavigationCard } from 'src/app/services/navigation-card-services';


@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.css']
})
export class PublicNewsComponent implements OnInit {

  @ViewChild('pinModel', { static: false }) pinModal: ElementRef<HTMLInputElement> = {} as ElementRef;
  // @ViewChild("snapshotfeeds") snapShotFeeds?: ElementRef;
  // @ViewChild("snapshotfeeds") snapShotFeeds?: ElementRef;
  @ViewChild('swipeContent', { read: ElementRef }) public swipeContent: ElementRef<any>;
  @ViewChild('swipeMainContent', { read: ElementRef }) public swipeMainContent:   ElementRef<any>;
  @ViewChild('swipeverticalContent', { read: ElementRef }) public swipeverticalContent: ElementRef<any>;
  @ViewChild('swipeContentApp', { read: ElementRef }) public swipeContentApp: ElementRef<any>;
  @ViewChild('swipeContentPublic', { read: ElementRef }) public swipeContentPublic: ElementRef<any>;
  @ViewChildren('horizontalScroll') horizontalScrolls: QueryList<ElementRef>;
  @ViewChildren('horizontalScroll') horizontalScrollss: QueryList<ElementRef>;

  



  environmentCollection = this.firestore.collection('Environments').valueChanges({ idField: 'id' }) as Observable<Brand[]>;
  userFilesList = this.firestore.collection('userfiles').valueChanges({ idField: 'id' }) as Observable<any[]>;
  brandsFilesList = this.firestore.collection('brands').valueChanges({ idField: 'id' }) as Observable<Brand[]>;
  brandBoardList = this.firestore.collection('brand-board').valueChanges({ idField: 'id' }) as Observable<any[]>;
  pinterestBoardPinsStatus = this.firestore.collection('pinterest-board-pins-status').valueChanges({ idField: 'id' }) as Observable<Snapshot[]>;
  garmentsList = this.firestore.collection('garments').valueChanges({ idField: 'id' }) as Observable<any[]>;
  brandBoardCollection = this.firestore.collection('brand-board').valueChanges({ idField: 'id' }) as Observable<any[]>;
  snapshotsCollection = this.firestore.collection('SnapshotDetails').valueChanges({ idField: 'id' }) as Observable<any[]>;
  user = this.firestore.collection('user-profile').valueChanges({ idField: 'id' }) as Observable<any[]>;
  userTest = this.firestore.collection('user-profile-test').valueChanges({ idField: 'id' }) as Observable<any[]>;



  profileName: any
  currentlySelected;
  color = 'primary';
  mode = 'indeterminate';
  selectedAvtar: any
  value = 50;
  displayProgressSpinner = false;
  dummyFlag :boolean = true
  spinnerWithoutBackdrop = false;
  offsetPrevious = 0;
  scrolling = 'down';
  scrollingWidth = 50;
  indexMapping = 0;
  scrollPixel = 0;
  private timeout: any;
  snapshotCollection: any = [];
  garmentsCollection: any = [];
  brandboardCollection: any = [];
  brandsCollection: any = [];
  storyPinterestPinsList: any = [];
  subscription: Subscription | undefined;
  scrollselected: any = [];
  userAllDetails: any = [];

  identifierList: any = {};
  slides: any[] = new Array(3).fill({ id: -1, src: '', title: '', subtitle: '' });
  clothDetails: any = {};
  selectedBrand: any = {};
  brandDetails: any;
  brands: any = [];
  boardId: any = "";
  boards: any;
  pinImg: string = "";
  SnapShotFileDetails: any = []
  filterSnapShotFileDetails: any = []
  isLoading = false;
  topScroll: number;
  pageScrollFlag: boolean = false;
  brandCollectionIdcollection: any = []
  brandhShapShotsList: any = []
  clocthCollectionIdcollection: any = []
  clothShapShotsList: any = []
  clothShapShotsListAll: any = []
  profileDetails: any = {}
  filterSnapShotForProfile: any = []
  brandGarmentIds: any = []
  usersplitted: any = []
  pindetails: any = [];
  urlEvn: any;
  gltfImgSrc: any = '';
  skyBoXall: any;
  evsname: any = '';
  environmentFileName: any = ''
  evsdiplaynameALL: any = '';
  filterSnapShotAvatarFileDetails: any = [];
  filterSnapShotFileDetailsall: any = [];
  popupLoadingIMg: boolean = true
  alertFlag: boolean = false
  pinterstJsonMap = {
    "StoryID": undefined,
    "upperwear": undefined,
    "footwear": undefined,
    "headwear": undefined
  }
  setIndex: number
  imageList: any = this.pinImg;
  brandId: any;
  StoryID: any;
  UpperwearId: any;
  pinId: any;
  loading: any;
  typeSelected: any;
  boardsName: any;
  filterByUserFlag: boolean = false;
  userId: string;
  snpUrl: any
  storyId: string;
  userUploadedFileList: any = [];
  userFiledetails: any = [];
  counter: number = 2;
  next: number = 1;
  showImages: any = {}
  filterEnvironmentSnpShot: any = [];
  evnFileListFileName: any
  evnFileList: any = []
  allUserDetails: any = []
  allUserSnapDetails: any = []
  brandboards: any;
  garmentRealtype: any = {}
  twoDprivew = '';
  twoDprivewGltf: any;
  hideFlag: boolean = true
  filterSnapWitApi: any = []
  popupLoading: boolean = true
  popupLoadingFor2D: boolean = true
  brandsnaploading: boolean = true
  snapByApi: any = []
  Key: any
  clothId: any
  link: any
  UpperwearID: string = "UpperwearID"
  BottomwearID: string = "BottomwearID"
  HeadwearID: string = "HeadwearID"
  FootwearID: string = "FootwearID"
  Gender = 'M'
  upperwear: any
  BrandID: any
  garmentUpper: any
  garmentheadwear: any
  garmentfootwear: any
  garmentbottomwear: any
  selectOption: any
  scrollFlag: boolean = false
  scrollSnap: any;
  garmentCardScroll: boolean = false
  ScrollNumber: number;
  scrollLeftPx: number;
  newScrollValue: any;
  indexValue: any
  height:  number;
  stroyLodaingFlag: boolean = false
  public innerWidth: any;
  myVariable: boolean;
  resizeObservable$: Observable<Event>
  resizeSubscription$: Subscription
  zoom: any = 400;
  nextSwipe: number = 1;
  clothCountAll: number
  clothArray: any = ['FootwearID', 'BottomwearID', 'UpperwearID', 'HeadwearID'];
  clothIndexing: any = [];
  arrowCounter: number = 0;
  clothIndex: number = 0;
  selectedGarmentType: any;
  selectedIndex: number = 0;
  snapDetails: any = [];
  snapLodaingFlag: boolean = true
  indexImage: any;
  successMsgs: string;
  storyIdByindex: any;
  email: any;
  id: any;
  userData: any = [];
  userDataTest: any = [];
  useFeedPagelink:any = '';
  brandPageLink:any = '';
  envirmentPageLink:any ='';
  garmentPageLink :any ='';
  AllDetails: any = []
  selectedSanp: any = []
  userImfo: any = []
  snapShotAllData: any = [];
  logInFlag: boolean;





  //  identifierDetail

  constructor(
    @Inject(DOCUMENT) document: Document,
    private scrollService: CommonService,
    private dataSharingService: DataSharingService,
    public dialog: MatDialog,
    private firestore: AngularFirestore,
    public router: Router,
    private route: ActivatedRoute,
    private commonService: CommonService,
    private modal: NgbModal,
    private apiHttpService: ApiHttpService,
    private pinterestAPI: PinterestService,
    private notifier: NotifierService,
    private navigate: NavigationCard,
    private http: HttpClient





  ) {
    window.addEventListener("scroll", function () {
    }, false);

    this.typeSelected = 'ball-fussion';
    // window.location.reload()
    this.brandBoardList.subscribe(
      brandboard => {
        this.brandboardCollection = brandboard;
      });


    this.garmentsList.subscribe(
      garments => {
        this.garmentsCollection = garments;
      });


    this.brandsFilesList.subscribe((brands) => {
      this.brandsCollection = brands;
    });

    this.pinterestBoardPinsStatus.subscribe(
      response => {
        this.storyPinterestPinsList = response
      });


    this.environmentCollection.subscribe(
      (evnFile) => {
        this.evnFileList = evnFile
      });

    this.user.subscribe(userdetails => {
      this.userData = userdetails
    });

    this.userTest.subscribe(userdetails => {
      this.userDataTest = userdetails
    });



    this.userFilesList.subscribe((userFile) => {
      this.userUploadedFileList = userFile
      let clothId = this.clothDetails.id
      let filelist = this.userUploadedFileList.filter((uploadlist) => uploadlist.link == clothId).slice(0, 2)
      this.userFiledetails = filelist
      this.dataSharingService.sendUrl(this.userFiledetails)
    })



    this.commonService.getAllUser().subscribe(async (allUsers: any) => {
      this.allUserDetails = allUsers.users;
    })

    this.commonService.snapshotfileDetais().subscribe((snapshot: any) => {
      this.snapShotAllData = snapshot
    })
  
 
  }
  ngOnChanges(changes) {
    // this.scrollToFirst(this.storyIdByindex.StoryID)
  }


  ngAfterViewChecked(): void {
    this. horiZonatalScroll()

    }  


  ngOnInit(): void {
    this.logInFlag = this.commonService.isLoggedIn()
    this.snapshotsCollection.subscribe(async (snap: any) => {
      this.snapLodaingFlag = true
      this.snapDetails = snap[0];
      this.indexImage = this.snapDetails['SnapshotFileUrl']
      this.snapShotInit()
    })
    this.onzoom();
    this.route.paramMap.subscribe(paramMap => {
      this.userId = paramMap.get('id');
      this.storyId = paramMap.get('storyId');
    });
    this.slides[0] = {
      src: 'snapshot.SnapshotFileUrl',
    };
    this.getPinterestBoards();
    this.displayProgressSpinner = true;
    this.getAllUserPRofile()
    let title = 'Yologram - news'
    this.commonService.changeTitle(title);

    this.useFeedPagelink = 'public/news-user'
    this.brandPageLink =   'public/public-brand-stories'
    this.envirmentPageLink = '/public/public-evn-profile'
    this.garmentPageLink = 'public/public-garment-feed'
  }

  onResize(event) {
    event.target.innerWidth;
  }


 ngAfterViewInit() {
    setTimeout(() => {
      this.horiZonatalScroll();
    }, 0);
  }

  onzoom() {
    document.body.style.resize = this.zoom;
  }
  getAllUserPRofile() {
    this.commonService.getAllUser().subscribe(async (allUsers: any) => {
      this.allUserDetails = allUsers.users;
      // this.snapShotInit()
    })
  }
  getProfileName(uuid) {
    let userObjet = this.allUserDetails.find(userObject => userObject.uid == uuid)
    let profileName = userObjet.displayName != undefined ? userObjet.displayName : uuid;
    return profileName;
  }

  snapShotInit() {
    this.snapLodaingFlag = true
    let reqInputObject = { "pageNumber": 1 }
    this.commonService.yologramPublicSnapshot(reqInputObject).subscribe(async (snapshot: any) => {
      this.SnapShotFileDetails = await snapshot.object;
      this.SnapShotFileDetails.forEach(async (snap: any, index) => {
        // let UserProfileObject =  this.userData.find((user)=> user.UserID == snap.UserID )
        // let userObjet = this.allUserDetails.find(userObject => userObject.uid == snap.UserID)
        // snap.profileName = userObjet.displayName != undefined ? userObjet.displayName : snap.UserID;

        let UserProfileObject =  this.userData.find((user)=> user.UserID == snap.UserID )
        let userObjet = this.allUserDetails.find(userObject => userObject.uid == snap.UserID)
        snap.profileName = (UserProfileObject.DisplayName != '' || UserProfileObject.DisplayName != undefined)  ? UserProfileObject.DisplayName : userObjet.displayName;
        
        userObjet.profileName = snap.profileName;
        snap.userData = userObjet
        snap.firstName = snap.profileName.split(" ", 1);
        snap.AvatarLastFive = snap.AvatarID.substr(snap.AvatarID.length - 5);
      });
      this.filterSnapShotFileDetails = this.SnapShotFileDetails
      setTimeout(() => {
        this.snapLodaingFlag = false
      },
        10);
    });







    // this.commonService.snapshotfileDetais().subscribe(async (snapshot: any) => {
    //   this.SnapShotFileDetails = snapshot;
    //   var snapShotFilter;
    //   if (this.userId != undefined && this.userId != null) {
    //     snapShotFilter = snapshot.filter((snapshotObject) => snapshotObject.UserID == this.userId);
    //   } else {
    //     snapShotFilter = snapshot;
    //   }
    //   snapShotFilter.forEach(async (snap: any, index) => {
    //     // if(this.storyId != undefined && this.storyId === snap.StoryID) {
    //     //   this.topScroll = index * 670;
    //     // }
    //     let userObjet = this.allUserDetails.find(userObject => userObject.uid == snap.UserID)
    //     snap.profileName = userObjet.displayName != undefined ? userObjet.displayName : snap.UserID;
    //     userObjet.profileName = snap.profileName;
    //     snap.userData = userObjet
    //     snap.firstName = snap.profileName.split(" ", 1);
    //     snap.AvatarLastFive = snap.AvatarID.substr(snap.AvatarID.length - 5);
    //   });
    this.update()
  }

  getSnapShotList() {
    this.filterByUserFlag = false;
    this.filterSnapShotFileDetails = this.SnapShotFileDetails;
  }

  getBrandStyle(brandName: any, gender: any,) {
    this.dataSharingService.sendUserSnapShots(this.brandhShapShotsList);
    this.router.navigate(['public/public-brand-style', { 'brandId': brandName, 'Gender': gender }])
  }


  getUserSnapShotDashboar(userId) {
    this.dataSharingService.sendUserSnapShots(this.filterSnapShotFileDetailsall);
    this.router.navigate(['public/news-user', { 'id': userId }])
  }

  getSelectSnapshotFeed(userSnap) {
    let userId = userSnap.UserID
    let storyid = userSnap.StoryID
    let userSnapShotList = this.filterSnapShotFileDetailsall.filter((snapshot) => snapshot.UserID == userId);
    // this.dataSharingService.sendUserSnapShots();
    this.dataSharingService.sendUserSnapShots(userSnapShotList);

    this.router.navigate(['public/public-userStory-feed', { 'id': userId, 'storyId': storyid }])
  }
  getSnapShotListByUser(snapshot: any) {
    let userId = snapshot.UserID;
    this.filterByUserFlag = true
    this.filterSnapShotFileDetails = this.SnapShotFileDetails.filter((snapshot) => snapshot.UserID == userId);
  }

  get snapshotCollectionSort() {
    return this.filterSnapShotFileDetails.sort((a, b) => {
      return <any>new Date(b.timestamp) - <any>new Date(a.timestamp);
    });
  }

  onItemChange($event: any): void {
    //    this.snapshotFilesList;
  }

  handleError(error: any) {
    this.isLoading = false;
    this.loading = false;
    console.log(error)
  }


  setClothDetails(key: string, garmentId: string) {
    this.popupLoadingFor2D = true
    this.Key = key
    this.clothId = garmentId
    let reqInputObject = {
      fileType: key,
      id: garmentId,
      pageNumber: 1
    }

    this.clothShapShotsListAll = []
    if (this.garmentsCollection.length > 0) {
      let grandmentObject = this.garmentsCollection.find((b: any) => b.id === garmentId);
      this.clothDetails = this.garmentsCollection.find((b: any) => b.id === garmentId);
      let brandObject = this.brandsCollection.find((b: any) => b.id === this.clothDetails.BrandID);
      this.clothDetails['BrandName'] = brandObject != undefined ? brandObject.BrandName : '';
      this.clothDetails['LogoURL'] = brandObject != undefined ? brandObject.LogoURL : '';
      let brandGarments = this.garmentsCollection.filter((gaementList) => gaementList.id == this.clothDetails.id);
      this.clocthCollectionIdcollection = brandGarments.map((garment) => garment.id);
      this.clothShapShotsList = this.SnapShotFileDetails.filter((snapshot) => {
        return this.clocthCollectionIdcollection.indexOf(snapshot.BottomwearID) >= 0 ||
          this.clocthCollectionIdcollection.indexOf(snapshot.HeadwearID.trim()) >= 0 ||
          this.clocthCollectionIdcollection.indexOf(snapshot.FootwearID.trim()) >= 0 ||
          this.clocthCollectionIdcollection.indexOf(snapshot.UpperwearID.trim()) >= 0
      }).slice(0, 9);
      this.clothShapShotsListAll = this.SnapShotFileDetails.filter((snapshot) => {
        return this.clocthCollectionIdcollection.indexOf(snapshot.BottomwearID) >= 0 ||
          this.clocthCollectionIdcollection.indexOf(snapshot.HeadwearID.trim()) >= 0 ||
          this.clocthCollectionIdcollection.indexOf(snapshot.FootwearID.trim()) >= 0 ||
          this.clocthCollectionIdcollection.indexOf(snapshot.UpperwearID.trim()) >= 0
      })
      if (grandmentObject && grandmentObject['Name'] != undefined) {
        let brandId = grandmentObject["BrandID"];
        this.brandDetails = this.brandsCollection.find((b: any) => b.id === brandId);
        this.brandDetails['boardName'] = 'Not Link to Board';
        let brandBoardObject = this.brandboardCollection.find((b: any) => b.brandId == brandId);
        if (brandBoardObject != undefined) {
          let boardId = brandBoardObject && brandBoardObject['boardId'] != undefined ? brandBoardObject['boardId'] : undefined;
          let boardDetails = this.boards.find((b: any) => b.id == boardId);
          // this.brandDetails['boardName'] = boardDetails['name'];
          if (boardDetails != undefined && boardDetails['name'] != undefined) this.brandDetails['boardName'] = boardDetails['name'];
        }
      }
      this.commonService.yologramSnapshotDetailsByType(reqInputObject).subscribe(async (response: any) => {
        this.popupLoadingFor2D = false
        this.snapByApi = response.object
        this.clothShapShotsListAll = this.snapByApi
      })
      let clothId = this.clothDetails.id
      let filelist = this.userUploadedFileList.filter((uploadlist) => uploadlist.link == clothId).slice(0, 2)
      this.userFiledetails = filelist
    }
  }


  setBrandDetails(garmentId: string) {
    if (this.garmentsCollection.length > 0) {
      let grandmentObject = this.garmentsCollection.find((b: any) => b.id === garmentId);
      let brandGarments = this.garmentsCollection.filter((gaementList) => gaementList.BrandID === grandmentObject.BrandID);
      this.brandCollectionIdcollection = brandGarments.map((garment) => garment.id);
      this.brandhShapShotsList = this.SnapShotFileDetails.filter((snapshot) => {
        return this.brandCollectionIdcollection.indexOf(snapshot.BottomwearID.trim()) >= 0 ||
          this.brandCollectionIdcollection.indexOf(snapshot.HeadwearID.trim()) >= 0 ||
          this.brandCollectionIdcollection.indexOf(snapshot.FootwearID.trim()) >= 0 ||
          this.brandCollectionIdcollection.indexOf(snapshot.UpperwearID.trim()) >= 0
      });
      if (grandmentObject && grandmentObject['Name'] != undefined) {
        let brandId = grandmentObject["BrandID"];
        this.brandDetails = this.brandsCollection.find((b: any) => b.id === brandId);
        this.brandDetails['boardName'] = 'Not Link to Board';
        let brandBoardObject = this.brandboardCollection.find((b: any) => b.brandId == brandId);
        if (brandBoardObject != undefined) {
          let boardId = brandBoardObject && brandBoardObject['boardId'] != undefined ? brandBoardObject['boardId'] : undefined;
          let boardDetails = this.boards.find((b: any) => b.id == boardId);
          this.brandDetails['boardName'] = boardDetails['name'];
          if (boardDetails != undefined && boardDetails['name'] != undefined) this.brandDetails['boardName'] = boardDetails['name'];
        }
      }
    }
    // this.openBrandPoup(garmentId)
  }

  getGarmentsDetails(garmentId: string) {
    let garmentTitle = "";
    let name = garmentId;
    let brandName = ""
    if (this.garmentsCollection.length > 0) {

      let grandmentObject = this.garmentsCollection.find((b: any) => b.id === garmentId);
      //   return 
      if (grandmentObject && grandmentObject['Name'] != undefined) {
        name = grandmentObject['Name'];
        let brandId = grandmentObject["BrandID"];
        let brandObject = this.brandsCollection.find((b: any) => b.id === brandId);
        brandName = brandObject != undefined && brandObject.BrandName != undefined ? brandObject.BrandName : '';
      }
    }
    garmentTitle = "<a (click)=\"showBrand('12232')\" href=\"showBrand('12232')\" />" + name + "</a>";
    // if(brandName != " ") garmentTitle += "   ( <a data-bs-toggle='modal' data-bs-target='#brand-details' >"+ brandName + " <a/>)";
    return garmentTitle;
  }


  getGarmentName(garmentId: string) {
    let garmentTitle = "";
    let name = garmentId;
    let brandName = ""
    if (this.garmentsCollection.length > 0) {
      let grandmentObject = this.garmentsCollection.find((b: any) => b.id === garmentId);
      if (grandmentObject && grandmentObject['Name'] != undefined) {
        garmentTitle = grandmentObject['Name'];
      }
    }
    return garmentTitle;
  }

  getGarmentrealtype(garmentId: string) {
    let garmentTitle = "";
    let name = garmentId;
    let brandName = ""
    if (this.garmentsCollection.length > 0) {
      let grandmentObject = this.garmentsCollection.find((b: any) => b.id === garmentId);
      if (grandmentObject && grandmentObject['RealType'] != undefined) {
        garmentTitle = grandmentObject['RealType'];
      }
    }
    return garmentTitle;
  }


  getBrandName(garmentId: string) {
    let garmentTitle = "";
    let name = garmentId;
    let brandName = ""
    if (this.garmentsCollection.length > 0) {
      let grandmentObject = this.garmentsCollection.find((b: any) => b.id === garmentId);
      if (grandmentObject && grandmentObject['Name'] != undefined) {
        let brandId = grandmentObject["BrandID"];
        let brandObject = this.brandsCollection.find((b: any) => b.id === brandId);
        brandName = brandObject != undefined && brandObject.BrandName != undefined ? brandObject.BrandName : '';
      }
    }
    return brandName;
  }



  getBrandLogoURL(garmentId: string) {
    let garmentTitle = "";
    let name = garmentId;
    let brandlogoUrl = ""
    if (this.garmentsCollection.length > 0) {
      let grandmentObject = this.garmentsCollection.find((b: any) => b.id === garmentId);
      if (grandmentObject && grandmentObject['BrandID'] != undefined) {
        let brandId = grandmentObject["BrandID"];
        let brandObject = this.brandsCollection.find((b: any) => b.id === brandId);
        brandlogoUrl = brandObject != undefined && brandObject.LogoURL != undefined ? brandObject.LogoURL : '';
      }
    }
    return brandlogoUrl;
  }
  showBrand(brand: any) {
    this.selectedBrand = brand;
  }

 showPintrestIcon(clothId: any) {
    let flag = false;
    let grandmentObject = this.garmentsCollection.find((b: any) => b.id === clothId);
    let brandBoardObject;
    if (grandmentObject != undefined && grandmentObject.BrandID != undefined) {
      brandBoardObject = this.brandboardCollection.find((b: any) => b.brandId == grandmentObject.BrandID);
      //let show = './assets/Pinterest-logo.png'
    }
    if (brandBoardObject != undefined) {
      flag = true;
    }
    return flag;
  }


  getBrandImageByGarmetnID(garmentId: string) {
    let imgURL = this.getBrandLogoURL(garmentId);
    let brandEncodedurl = encodeURI(imgURL).replace('/', '%2F');
    const newPath = brandEncodedurl.replace('Brands%2F', 'Brands%2Fcompressed%2F');

    let url = "https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/" + newPath + "?alt=media&token=550e7e88-bed3-4668-84ff-ad491c19e06c"
    return url;
  }

  getBrandImage(imgURL: string) {
    let brandEncodedurl = encodeURI(imgURL).replace('/', '%2F');
    const newPath = brandEncodedurl.replace('Brands%2F', 'Brands%2Fcompressed%2F');

    let url = "https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/" + newPath + "?alt=media&token=550e7e88-bed3-4668-84ff-ad491c19e06c"
    return url;
  }

  getPinterestBoards() {
    this.pinterestAPI.getBoardList().subscribe((data: {
      name: any; items: any;
    }) => {
      this.boards = data.items
    });
  }
  selectedBoard($event: any) {
    this.boardId = $event.target.value;
  }

  shareOnPinterest(garmentId: any, type: any, snapshotFileUrl: any, storyID: any) {
    setInterval(() => {
      this.isLoading = false;
      this.loading = false;
    }, 9000);
    this.loading = true;
    let brandId: any;
    let boardId: any;
    if (this.garmentsCollection.length > 0) {
      let grandmentObject = this.garmentsCollection.find((b: any) => b.id === garmentId);
      if (grandmentObject && grandmentObject['Name'] != undefined) {
        brandId = grandmentObject["BrandID"];
        let brandBoardObject = this.brandboardCollection.find((b: any) => b.brandId == brandId);
        boardId = brandBoardObject && brandBoardObject['boardId'] != undefined ? brandBoardObject['boardId'] : undefined;
      }
    }
    if (boardId == undefined || brandId == '') {
      this.isLoading = false;
      this.loading = false;
      alert("This brand is not linked with Pinterest board.")
      return true;
    }

    let jsonObject = {
      'avatarURL': snapshotFileUrl,
      'boardId': boardId
    }
    let jsonInput = this.pinterstJsonMap
    let storyObject = this.storyPinterestPinsList.find((b: any) => b.StoryID == storyID);
    if (storyObject && storyObject[type] != undefined) {
      let jsonInpt = { 'pinId': storyObject[type], };
      this.pinterestAPI.getPinterestPin(jsonInpt).subscribe((data) => {
        this.displayProgressSpinner = false;
        this.pinImg = data.media.images['400x300'].url;
        this.openModleForPublishedPin();
        this.loading = false;
      });
      return true
    }
    this.pinterestAPI.createPinterestPin(jsonObject).subscribe((result) => {
      if (storyObject == undefined) {
        let pinsMappingObject: any = {
          'StoryID': storyID,
        }
        if (type == 'headwear') pinsMappingObject['headwear'] = result.id
        if (type == 'footwear') pinsMappingObject['footwear'] = result.id
        if (type == 'upperwear') pinsMappingObject['upperwear'] = result.id
        if (type == 'headwear') pinsMappingObject['headwear'] = result.id
        this.firestore.collection('pinterest-board-pins-status').add(pinsMappingObject)
      } else if (storyObject && storyObject[type] == undefined) {


        if (type == 'headwear') storyObject['headwear'] = result.id
        if (type == 'footwear') storyObject['footwear'] = result.id
        if (type == 'upperwear') storyObject['upperwear'] = result.id
        if (type == 'headwear') storyObject['headwear'] = result.id
        this.firestore.collection('pinterest-board-pins-status').doc(storyObject['id']).update(storyObject);
      }

      this.isLoading = false;
      this.loading = false;
      alert("Pin published successfully.");
      return true;
    })
    return false;
  }




  openModleForPublishedPin() {
    this.isLoading = false;
    const dialogRef = this.dialog.open(PinPublishViewerDilog);
    dialogRef.componentInstance.pinImgSrc = this.pinImg;
    dialogRef.afterClosed().subscribe(result => {
      this.isLoading = false;
    });
  }



  openShareModal(snapshot) {
    let grandmentObject = this.garmentsCollection.find((b: any) => b.id === snapshot.UpperwearID);
    const dialogRef = this.dialog.open(SocialMediaShareDialog);
    dialogRef.componentInstance.storyId = snapshot
    dialogRef.componentInstance.garmentDetails = grandmentObject.Name;
    dialogRef.afterClosed().subscribe(result => {
      this.isLoading = false;
      this.loading = false;
    });
  }


  openUserPopup(UserShapShotObject: any) {
    const modalRef = this.modal.open(UserPopupComponent, {
      modalDialogClass: "model-garmentPopup"
    });
    //    modalRef.componentInstance.id = 10;
    modalRef.componentInstance.UserShapShotObject = UserShapShotObject;
    modalRef.componentInstance.UserID = UserShapShotObject.UserID;
    modalRef.componentInstance.rootFlag ='/user';
    modalRef.componentInstance.userData = UserShapShotObject.userData;
    modalRef.result.then((result) => {
    }).catch((error) => {
      console.log(error);
    });
  }

  
  openAvatarPopUp(UserShapShotObject: any, AvatarID: any) {
    const modalRef = this.modal.open(UserPopupComponent, {
      modalDialogClass: "model-garmentPopup"
    });
    modalRef.componentInstance.id = 10;
    modalRef.componentInstance.AvatarID = AvatarID;
    modalRef.componentInstance.UserShapShotObject = UserShapShotObject;
    modalRef.componentInstance.UserID = UserShapShotObject.UserID;
    modalRef.componentInstance.userData = UserShapShotObject.userData;
    modalRef.componentInstance.rootFlag = '/user';
    modalRef.result.then((result) => {
      console.log(result);
    }).catch((error) => {
      console.log(error);
    });
  }

  openGarmentPopup(fileType: any, key: any) {
    const modalRef = this.modal.open(GarmentPopupComponent, {
      modalDialogClass: "model-garmentPopupClass"
    });
    modalRef.componentInstance.id = 10;
    modalRef.componentInstance.key = fileType;
    modalRef.componentInstance.garmentId = key;
    modalRef.componentInstance.routeFlageEvenGarment = '/garment';
    modalRef.result.then((result) => {
      console.log(result);
    }).catch((error) => {
      console.log(error);
    });
  }

  openBrandPoup(garmentId: any) {
    let grandmentObject = this.garmentsCollection.find((b: any) => b.id === garmentId);
    const modalRef = this.modal.open(BrandPopupComponent,{
      modalDialogClass: "model-garmentPopup"
    });
    modalRef.componentInstance.BrandId = grandmentObject.BrandID;
    modalRef.componentInstance.garmentId = garmentId;
    modalRef.componentInstance.routeFlageBrandPage = '/brand';
    modalRef.result.then((result) => {
    }).catch((error) => {
      console.log(error);
    });
  }

  linkBrandToBoard() {
    if (this.boardId == "" || this.brandId == '') {
      return false;
    }
    let brandBoardMappingObject = {
      'brandId': this.brandId,
      'boardId': this.boardId
    }
    this.firestore.collection('brand-board').add(brandBoardMappingObject)
    return true;
  }

  // ngAfterViewChecked():void {
  //   if (this.storyId != undefined && this.snapShotFeeds != undefined && this.snapShotFeeds.nativeElement != undefined && !this.pageScrollFlag) {
  //    document.getElementById(this.storyId)
  //     this.snapShotFeeds.nativeElement.scrollTop = this.topScroll;
  //     this.pageScrollFlag = true
  //   }
  // }

  getBrandProfile(brand: any, board: any) {
    this.brandboards = board
    let brandGarments = this.garmentsCollection.filter((gaementList) => gaementList.BrandID === brand);
    this.brandGarmentIds = brandGarments.map((garment) => garment.id);
    let SnapshotWithbrandGarment = this.brandhShapShotsList = this.SnapShotFileDetails.filter((snapshot) => {
      return this.brandCollectionIdcollection.indexOf(snapshot.BottomwearID) >= 0 ||
        this.brandCollectionIdcollection.indexOf(snapshot.HeadwearID.trim()) >= 0 ||
        this.brandCollectionIdcollection.indexOf(snapshot.FootwearID.trim()) >= 0 ||
        this.brandCollectionIdcollection.indexOf(snapshot.UpperwearID.trim()) >= 0
    })
    this.dataSharingService.sendUserSnapShots(SnapshotWithbrandGarment);
    this.dataSharingService.sendBrandGarments(this.brandGarmentIds);
    this.dataSharingService.sendBrandBrandBoard(board)
    this.router.navigate(['public/public-brand-profile', { 'brandId': brand }])
  }

  shareOPtion(storyId: any) {
    // this.link =  this.router.navigate(['public/public-news-common', {'storyId': storyId}
    // this.link  = "http://localhost:4200/#/public/public-news-common;storyId="+storyId
    this.link = "  https://yolomove-social-live.web.app/#/public/public-news-common;storyId=" + storyId
    if (navigator.share) {
      navigator.share({
        title: 'Share Snap',
        text: 'Check out the snap',
        url: this.link,
      })
        .then(() => console.log('Successful share'))
        .catch((error) => console.log('Error sharing', error));
    }
  }



  update() {
    this.commonService.getAllUser().subscribe(async (allUsers: any) => {
      this.AllDetails = allUsers.users;
      this.AllDetails.forEach(object => {
        let filterSnapShots = this.snapShotAllData.filter((snapShot) => snapShot.UserID == object.uid);
        this.selectedSanp = filterSnapShots;
        if (filterSnapShots.length > 0) {
          this.userImfo.push(object);
        }
      });
  
      // Assuming this.userData is an array that holds the UserIDs that should not be added again
      const userIDsInUserData = this.userData.map(user => user.UserID)
      console.log(userIDsInUserData)
      console.log( this.userData)
      console.log(this.userImfo)
      this.userImfo.forEach(object => {
        let details = object;
        let userDetail = details.providerData[0];
        let saveUserDetails = {
          "DisplayName": userDetail.displayName != undefined ? userDetail.displayName : '',
          "Email": userDetail.email != undefined ? userDetail.email : '',
          "FirstName": '',
          "Identifier": '',
          "LastName": '',
          "MiddleName": '',
          "ProfilePicture": userDetail.photoURL != undefined ? userDetail.photoURL : '',
          "UserID": details.uid != undefined ? details.uid : '',
          "pageName":'news'
        };
  
        // Check if UserID is already in this.userData
        if (!userIDsInUserData.includes(saveUserDetails.UserID)) {
          // If not, then check Firestore
          this.firestore.collection('user-profile', ref =>
            ref.where('UserID', '==', saveUserDetails.UserID))
            .get()
            .subscribe(querySnapshot => {
              if (querySnapshot.empty) {
                this.firestore.collection('user-profile').add(saveUserDetails).then((docRef) => {
                  console.log(`User profile added with ID: ${docRef.id}`);
                  alert("yolgram")

                  // Optionally, you could add this ID to this.userData to keep the local array updated
                  // this.userData.push({ ...saveUserDetails, UserID: docRef.id });
                });
              } else {
                console.log("User profile already exists in Firestore");
              }
            });
        } else {
          console.log(`User profile for UserID ${saveUserDetails.UserID} already exists in userData, not adding to Firestore.`);
        }
      });
    });
  }
  

 
  


  getBrandProfileByGarment(brand: any, board: any) {
    this.dataSharingService.sendUserSnapShots(this.clothShapShotsListAll);
    this.dataSharingService.sendBrandBrandBoard(this.brandDetails.boardName)
    this.router.navigate(['public/public-brand-profile', { 'brandId': brand }])
  }


  getProfileByAvator(snapshot: any) {
    let userId = snapshot.UserID;
    let filterSnapShotFileDetails = this.SnapShotFileDetails.filter((snapshotlist) => snapshotlist.AvatarID == snapshot.AvatarID);
    this.dataSharingService.sendUserSnapShots(filterSnapShotFileDetails);
    this.router.navigate(['public/news-user', { 'id': userId, 'avatarID': snapshot.AvatarID }])
  }

  getFeed(brand: any, brandName: any) {
    this.router.navigate(['public/public-brand-stories', { 'brandId': brandName.id, 'storyId': brand.StoryID }])
  }

  getBrandStories(brand: any) {
    this.router.navigate(['public/public-brand-stories', { 'brandId': brand.id, }])
  }
  getClothFeed(brand: any, brandsDetails) {
    this.dataSharingService.sendUserSnapShots(this.clothShapShotsList)
    this.router.navigate(['public/public-brand-stories', { 'brandId': brandsDetails, 'storyId': brand.StoryID }])
  }

  getgarmentStyle(brandName: any, cloths: any) {
    let editgarment = 'edit'
    let snap = this.clothShapShotsList
    this.dataSharingService.sendUserSnapShots(snap)
    this.dataSharingService.sendClothDetails(cloths)
    this.router.navigate(['public/public-garment-style', { 'brandId': cloths.BrandID, 'clothsType': cloths.GarmentType, 'Gender': cloths.Gender, 'id': cloths.id, 'edit': editgarment }])
  }
  getStoryCloth(brandIds) {
    let snap = this.clothShapShotsList
    this.dataSharingService.sendUserSnapShots(snap)
    this.dataSharingService.sendClothDetails(brandIds)
    let brandid = brandIds.BrandID
    this.router.navigate(['public/public-garment-profile', { 'brandId': brandid, }])
  }


  getSnapShotListByUserPopup(snapshot: any) {
    this.popupLoading = true;
    this.popupLoadingFor2D = true;
    setInterval(() => {

      this.popupLoading = false;
    }, 4000);
    this.popupLoading = true;
    let userId = snapshot.UserID;
    //  let url = environment.functionBaseUrl + "userProfileIdenifierById?id=" + userId;
    //      this.apiHttpService.get(url).subscribe((data: any) => {
    //       this.profileDetails = data
    //     })
    // this.filterSnapShotForProfile = this.SnapShotFileDetails.filter((snapshot) => snapshot.UserID == userId).slice(0,3);
    // this.dataSharingService.sendUserSnapShots(this.filterSnapShotForProfile);
    // let clothId = this.clothDetails.id
    // let filelist = this.userUploadedFileList.filter((uploadlist) => uploadlist.link == clothId).slice(0,2)
    // this.userFiledetails = filelist
    this.profileDetails = this.allUserDetails.find(userObject => userObject.uid == userId)
    let reqInputObject = { id: userId, pageNumber: 1 }
    this.commonService.yologramSnapshotDetailsByUserId(reqInputObject).subscribe(async (response: any) => {
      this.allUserSnapDetails = []
      this.allUserSnapDetails = response.object;
      this.filterSnapShotFileDetailsall = this.allUserSnapDetails.filter((snapshot) => snapshot.UserID == userId)
      this.filterSnapWitApi = this.allUserSnapDetails.filter((snapshot) => snapshot.UserID == userId).slice(0, 3);
      this.dataSharingService.sendUserSnapShots(this.filterSnapShotFileDetailsall);
      this.popupLoading = false
    })
    this.popupLoadingIMg = true;
    setInterval(() => {
      this.popupLoadingFor2D = false;
      this.popupLoadingIMg = false;
    }, 500);
  }

  getSnapShotListByUserPopupAvatar(snapshot: any) {
    this.popupLoading = true;
    this.popupLoadingFor2D = true;
    setInterval(() => {
      this.popupLoading = false;
    }, 4000);
    this.popupLoading = true;
    let userId = snapshot.UserID;
    let avatarId = snapshot.AvatarID;
    this.selectedAvtar = avatarId
    this.profileDetails = this.allUserDetails.find(userObject => userObject.uid == userId)
    let reqInputObject = { id: avatarId, pageNumber: 1 }
    this.commonService.yologramSnapshotDetailsByAvatar(reqInputObject).subscribe(async (response: any) => {
      this.allUserSnapDetails = []
      this.allUserSnapDetails = response.object;
      this.filterSnapShotFileDetailsall = this.allUserSnapDetails.filter((snapshot) => snapshot.AvatarID == avatarId)
      this.filterSnapWitApi = this.allUserSnapDetails.filter((snapshot) => snapshot.AvatarID == avatarId).slice(0, 3);
      this.dataSharingService.sendUserSnapShots(this.filterSnapShotFileDetailsall);
      this.popupLoading = false
    })
    this.popupLoadingIMg = true;
    setInterval(() => {
      this.popupLoadingFor2D = false;
      this.popupLoadingIMg = false;
    }, 500);
  }


  getuserfeed(uid) {
    this.filterSnapShotFileDetails = this.SnapShotFileDetails.filter((snapshot) => snapshot.UserID == uid)

  }

  getuserAvatarfeed(uid) {
    this.filterSnapShotFileDetails = this.SnapShotFileDetails.filter((snapshot) => snapshot.AvatarID == uid)

  }


  getSnapShotListAvatarPopup(snapshot: any) {
    let userId = snapshot.UserID;
    let avatarId = snapshot.AvatarID
    this.selectedAvtar = avatarId
    //  let url = environment.functionBaseUrl + "userProfileIdenifierById?id=" + userId;
    //      this.apiHttpService.get(url).subscribe((data: any) => {
    //       this.profileDetails = data
    //     })
    // filterSnapShotForProfile
    //  this.profileDetails = data
    this.profileDetails = this.allUserDetails.find(userObject => userObject.uid == userId)
    this.filterSnapShotForProfile = this.SnapShotFileDetails.filter((snapshot) => snapshot.AvatarID == avatarId).slice(0, 3);
    this.dataSharingService.sendUserSnapShots(this.filterSnapShotForProfile);
  }

  getProfileByAvatorpage(snapshot: any) {
    // calling 12 snap from plus button
    const userId = this.filterSnapShotFileDetailsall.find((snapshotlist) => snapshotlist.UserID)
    this.dataSharingService.sendUserSnapShots(this.filterSnapShotFileDetailsall);
    this.router.navigate(['public/news-user', { 'id': userId.UserID, 'avatarID': snapshot }])
  }

  getProfileByAvatorpagfeed(snapshot: any) {
    this.dataSharingService.sendUserSnapShots(this.filterSnapShotFileDetailsall);
    const userId = snapshot.UserID
    //  this.filterSnapShotFileDetailsall.find((snapshotlist)=> snapshotlist.UserID )
    this.router.navigate(['public/public-userStory-feed', { 'id': userId.UserID, 'avatarID': snapshot }])
  }
  getSelectSnapshotAvatarFeed(snapshot) {
    const userId = this.filterSnapShotFileDetailsall.find((snapshotlist) => snapshotlist.UserID)
    this.dataSharingService.sendUserSnapShots(this.filterSnapShotFileDetailsall);
    this.router.navigate(['public/public-userStory-feed', { 'id': snapshot.UserID, 'avatarID': snapshot.AvatarID }])
    // this.filterSnapShotAvatarFileDetails = this.filterSnapShotFileDetails.filter((snapshotlist) => snapshotlist.AvatarID == snapshot.AvatarID);
    // this.dataSharingService.sendUserSnapShots(this.filterSnapShotFileDetails);

  }

  getClothProfile(brand: any,) {
    let clothAllDetails = this.clothDetails
    let brandGarments = this.garmentsCollection.filter((gaementList) => gaementList.id == this.clothDetails.id);
    this.clocthCollectionIdcollection = brandGarments.map((garment) => garment.id);
    this.clothShapShotsList = this.SnapShotFileDetails.filter((snapshot) => {
      return this.clocthCollectionIdcollection.indexOf(snapshot.BottomwearID) >= 0 ||
        this.clocthCollectionIdcollection.indexOf(snapshot.HeadwearID.trim()) >= 0 ||
        this.clocthCollectionIdcollection.indexOf(snapshot.FootwearID.trim()) >= 0 ||
        this.clocthCollectionIdcollection.indexOf(snapshot.UpperwearID.trim()) >= 0
    }).slice(0, 9);
    let snanpDetails = this.SnapShotFileDetails.filter((snapshot) => {
      return this.clocthCollectionIdcollection.indexOf(snapshot.BottomwearID) >= 0 ||
        this.clocthCollectionIdcollection.indexOf(snapshot.HeadwearID.trim()) >= 0 ||
        this.clocthCollectionIdcollection.indexOf(snapshot.FootwearID.trim()) >= 0 ||
        this.clocthCollectionIdcollection.indexOf(snapshot.UpperwearID.trim()) >= 0
    })

    this.dataSharingService.sendUserSnapShots(this.snapByApi);
    this.dataSharingService.sendBrandGarments(this.clocthCollectionIdcollection);
    this.dataSharingService.sendClothDetails(clothAllDetails)
    this.router.navigate(['public/public-garment-profile', { 'brandId': brand, 'key': this.Key, 'id': this.clothId }])
  }


  openImageBox(fileurl) {
    let url = fileurl
    let showImage = this.userFiledetails.find((file) => file.fileurl == url)

    this.dataSharingService.sendUrl(this.snapByApi);
    this.showImages = showImage
  }

  share() {
    const caption = 'Check out this amazing photo!'; // Customize the caption
    const imageUrl = 'https://example.com/image.jpg'; // Replace with the URL of the image to share

    const url = `https://www.instagram.com/share?url=${encodeURIComponent(imageUrl)}&caption=${encodeURIComponent(caption)}`;
    window.open(url);
  }


  environmentPopup(environment) {
    this.environmentFileName = environment.Environment
    let reqInputObject = { id: environment, pageNumber: 1 }
    this.commonService.yologramSnapshotEnvironment(reqInputObject).subscribe(async (evn: any) => {
      let environmentSnapAllfileAll = evn.object;
      this.dataSharingService.sendUserEnvSnapShots(environmentSnapAllfileAll);
    })
    let evenListName = "Environments/"
    this.evnFileListFileName = this.evnFileList.find((snap) => snap.storageURL == evenListName + environment)
    let environmentSnapAllfile = this.SnapShotFileDetails.filter((snap) => snap.Environment === environment).slice(0, 3)
    this.filterEnvironmentSnpShot = environmentSnapAllfile
    this.evnFileListFileName['envFileName'] = environment
    let evnUrl = evenListName + environment
    this.gltfImgSrc = 'https://firebasestorage.googleapis.com/v0/b/yolouploader.appspot.com/o/userData%2Ffiles%2Fcrystal_ball.glb?alt=media&token=963e0d80-5809-4e1b-9034-caf31fb33945'
    this.openModelevn(evnUrl, environment)

  }

  openModelevn(skyBoXsrc: any, evnName: any) {
    let evnEncodedurl = encodeURI(skyBoXsrc).replace('/', '%2F');
    let evNurl = "https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/" + evnEncodedurl + "?alt=media&token=db5ebcc3-36d0-4651-9250-471d6faf6baf"
    this.twoDprivew = evNurl
    const dialogRef = this.dialog.open(PopupThreeDComponent, {
      panelClass: 'dailog-padding-css'
    });
    this.gltfImgSrc = 'https://firebasestorage.googleapis.com/v0/b/yolouploader.appspot.com/o/userData%2Ffiles%2Fcrystal_ball.glb?alt=media&token=963e0d80-5809-4e1b-9034-caf31fb33945'
    this.skyBoXall = this.twoDprivew
    this.evsname = this.evnFileListFileName['envFileName']
    this.evsdiplaynameALL = this.evnFileListFileName['displayName']
    dialogRef.componentInstance.evsdiplaynameALL = this.evsdiplaynameALL
    dialogRef.componentInstance.evsname = this.evsname
    dialogRef.componentInstance.gltfImgSrc = this.gltfImgSrc;
    dialogRef.componentInstance.skyBoXall = this.skyBoXall;
    dialogRef.componentInstance.hideFlag = this.hideFlag;
    dialogRef.componentInstance.environmentFileName = evnName;
    dialogRef.componentInstance.routeFlageEven = this.envirmentPageLink;
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  getEvnUrl(environment: any) {
    this.twoDprivewGltf = "https://firebasestorage.googleapis.com/v0/b/yolouploader.appspot.com/o/userData%2Ffiles%2Fcrystal_ball.glb?alt=media&token=963e0d80-5809-4e1b-9034-caf31fb33945"
    let evnEncodedurl = encodeURI(environment).replace('/', '%2F');
    let evNurl = "https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/" + evnEncodedurl + "?alt=media&token=db5ebcc3-36d0-4651-9250-471d6faf6baf"
    this.twoDprivew = evNurl

  }

  getEnvironmentName(environment) {
    let evenListName = "Environments/"
    let env = this.evnFileList.find((snap) => snap.storageURL == evenListName + environment)
    return env != undefined ? env['displayName'] : 'default';
  }
  closeEnviro() {
    // this.twoDprivew  = ''
    // this.twoDprivewGltf = ''
    // let evnurl =  this.urlEvn
    // this.environmentPopup(evnurl) 
  }

  getEnvironmentProfile(envFileName) {
    alert("helo")
    let evenListName = "Environments/"
    this.evnFileListFileName = this.evnFileList.find((snap) => snap.storageURL == evenListName + envFileName)
    let environmentSnapAllfile = this.SnapShotFileDetails.filter((snap) => snap.Environment === envFileName)
    this.dataSharingService.sendUserSnapShots(environmentSnapAllfile)
    this.router.navigate(['public/public-evn-profile', { 'evnFile': this.evnFileListFileName.displayName, 'evnName': this.evnFileListFileName['envFileName'] }])
  }


  getgarmentfeed(brandName: any, cloths: any) {
    this.dataSharingService.sendUserSnapShots(this.SnapShotFileDetails);

    // let boardName = this.brandBoard
    this.router.navigate(['public/public-garment-feed', { 'brandId': cloths.BrandID, 'clothsType': cloths.GarmentType, 'Gender': cloths.Gender, 'id': cloths.id }])
  }

  getgarmentStylefeed(brandName: any, cloths: any) {
    let snap = this.clothShapShotsListAll
    this.dataSharingService.sendUserSnapShots(snap)
    this.dataSharingService.sendClothDetails(cloths)
    this.router.navigate(['public/public-garment-feed', { 'brandId': cloths.BrandID, 'clothsType': cloths.GarmentType, 'Gender': cloths.Gender, 'id': cloths.id, 'key': this.Key }])
  }

  swipeCards(upperwear, headwear, bottomwear, footwear) {
    alert("hi boss")
    this.garmentUpper = upperwear
    this.garmentheadwear = headwear
    this.garmentbottomwear = bottomwear
    this.garmentfootwear = footwear
    this.selectOption = (upperwear || headwear || bottomwear || footwear)

    this.UpperwearID
    this.HeadwearID
    this.FootwearID
    this.BottomwearID
  }

  onScrollForGarment($event, snapshot) {
    snapshot.horizontalSnap = true;
  }

  onScroll(snapShot) {
    this.scrollSnap = snapShot
    this.clothIndexing = [];
    if (snapShot.HeadwearID != "") this.clothIndexing.push('HeadwearID');
    if (snapShot.UpperwearID != "") this.clothIndexing.push('UpperwearID');
    if (snapShot.BottomwearID != "") this.clothIndexing.push('BottomwearID');
    if (snapShot.FootwearID != "") this.clothIndexing.push('FootwearID');
  }

  scrollToCard(snapshot) {
    this.onScroll(snapshot)
    let id = 0
    let indexOfCloth = this.clothIndexing[0]
    this.scrollToGarmentId(snapshot, id, indexOfCloth)
    this.swipeMainContent.nativeElement.scrollTo({ left: 541, behavior: 'auto' });
  }

  scrollToPreVew(snapshot) {
    this.onScroll(snapshot)
    let id = 0
    let indexOf = this.clothIndexing.length - 1
    let indexOfCloth = this.clothIndexing[indexOf]
    this.scrollToGarmentprvious(snapshot, id, indexOfCloth)
  }




  scrollToGarmentCard(snapshot, index) {
    this.indexValue = index
    let size = 451;
    let clothCount = 0
    if (snapshot.HeadwearID != "") clothCount++;
    if (snapshot.UpperwearID != "") clothCount++;
    if (snapshot.BottomwearID != "") clothCount++;
    if (snapshot.FootwearID != "") clothCount++;
    for (let i = 0; i < this.clothIndexing.length; i++) {
      if (this.clothIndexing[i] == index)
        this.arrowCounter = i;
    }
    //    this.arrowCounter = clothCount;
    this.clothCountAll = clothCount


    // if (index == 4) {
    //   this.scrollLeftPx = (clothCount - index - 1) * size;
    // } else {
    //   this.scrollLeftPx = (clothCount - index) * size;
    // }


    if (index == 4) {
      this.scrollLeftPx = (clothCount - 4) * size;
    }
    else if (index == 3) {
      this.scrollLeftPx = (clothCount - index - 1) * size;
    }
    else if (index == 2) {
      this.scrollLeftPx = (clothCount - index - 3) * size;
    }
    else if (index == 1) {
      this.scrollLeftPx = (clothCount - index - 4) * size;
    }


    this.onScroll(snapshot)
    // this.swipeMainContent.nativeElement.scrollTo({ left: 451, behavior: 'smooth' });
    // this.swipeContent.nativeElement.scrollTo({ left: this.scrollLeftPx, behavior: 'smooth' });
    /*  if (this.swipeMainContent.nativeElement >= 400) {
        this.swipeMainContent.nativeElement.scrollTo({ left: 451, behavior: 'smooth' });
        this.swipeContent.nativeElement.scrollTo({ left: this.scrollLeftPx, behavior: 'smooth' });
      } else {
        this.swipeMainContent.nativeElement.scrollTo({ left: 451, behavior: 'smooth' });
        this.swipeContent.nativeElement.scrollTo({ left: this.scrollLeftPx, behavior: 'smooth' });
      }
  8*/
  }



  // scrollToId(snap, id) {
  //   this.scrollService.scrollToElementById(id);
  //   if(id != undefined)return
  // }

  scrollToGarmentprvious(snap, id, garmenttype) {
    this.onScroll(snap);
    this.selectedGarmentType = garmenttype
    const selectedValue = garmenttype;
    this.selectedIndex = this.clothIndexing.indexOf(selectedValue);
    setTimeout(() => {
      this.scrollService.scrollToElementById(garmenttype);
    },
      0);
  }


  scrollToElementfast() {
    document.getElementById(this.selectedGarmentType).scrollIntoView({
      behavior: 'smooth'
    });
  }

  scrollToGarmentId(snapShot, id, garmenttype) {
    this.onScroll(snapShot)
    this.scrollSnap = snapShot
    this.selectedGarmentType = garmenttype
    const selectedValue = garmenttype;
    this.selectedIndex = this.clothIndexing.indexOf(selectedValue);
    setTimeout(() => {
      this.navigate.scrollToElementById(garmenttype);
    },
      0);
  }





  scrollToGarmentCardFullBody(snapshot, index) {
    let size = 451;
    this.indexValue = index
    let clothCount = 1
    if (snapshot.HeadwearID != "") clothCount++;
    if (snapshot.UpperwearID != "") clothCount++;
    if (snapshot.BottomwearID != "") clothCount++;
    if (snapshot.FootwearID != "") clothCount++;
    for (let i = 0; i < this.clothIndexing.length; i++) {
      if (this.clothIndexing[i] == index)
        this.arrowCounter = i;
    }
    //    this.arrowCounter = clothCount;
    this.clothCountAll = clothCount
    if (clothCount > 4) {
      this.scrollLeftPx = (clothCount - index - 1) * size;
    } else {
      this.scrollLeftPx = (clothCount - index) * size;
    }
    this.onScroll(snapshot)
    /* if (this.swipeMainContent.nativeElement > 400) {
       this.swipeMainContent.nativeElement.scrollTo({ left: 451, behavior: 'smooth' });
       this.swipeContent.nativeElement.scrollTo({ left: this.scrollLeftPx, behavior: 'smooth' });
     } else {
       this.swipeMainContent.nativeElement.scrollTo({ left: 451, behavior: 'smooth' });
       this.swipeContent.nativeElement.scrollTo({ left: this.scrollLeftPx, behavior: 'smooth' });
     }8*/
  }


  garmentScrollEvent() {
    if (this.swipeContent.nativeElement.scrollLeft < 1 ) {
      this.swipeMainContent.nativeElement.scrollTo({ left: -451 , behavior: 'smooth' });
    //  this.swipeMainContent.nativeElement.scrollTo({ left: -451, behavior: 'smooth' });
    }
    this.onScroll(this.scrollSnap)
  }


  buttonLeft() {
    this.selectedIndex--;
    if (this.selectedIndex >= 0) {
      let id = 0
      this.indexValue = this.clothIndexing[this.selectedIndex];
      setTimeout(() => {
        this.scrollToGarmentId(this.scrollSnap, id, this.indexValue);
      },
        1);
    } else {
      this.selectedIndex = 0;
      this.homeclick()
    }
  }


  buttonRight() {
    this.selectedIndex++;

    if (this.selectedIndex < this.clothIndexing.length) {
      this.indexValue = this.clothIndexing[this.selectedIndex];
      let id = 0

      setTimeout(() => {
        this.scrollToGarmentId(this.scrollSnap, id, this.indexValue);
      },
        1);
    }
    else {
      this.selectedIndex = this.clothIndexing.length - 1;
      this.homeclick()
    }
  }


  getNumber(arr) {
    for (const num of arr) {
      return num;
    }
  }



  homeclick() {
    this.swipeMainContent.nativeElement.scrollTo({ left: -451, behavior: 'smooth' });
    let value = this.selectedIndex * -451
    this.swipeContent.nativeElement.scrollTo({ left: value, behavior: 'smooth' });
  }



  setScrollValue(scrollVal: any) {
    // if (scrollVal <= 4) {
    //   this.swipeMainContent.nativeElement.heiscrollTo({ left: -420, behavior: 'smooth' });
    // }
  }
  scrollCard() {
    let height = this.swipeContentPublic.nativeElement.scrollTop
    this.height = height
    this.scrollConditon()
  }
  
  scrollConditon() {
    this.height = this.swipeContentPublic.nativeElement.scrollTop
  }
  openModleForToast(flag) {
    const dialogRef = this.dialog.open(ToastComponent);
    dialogRef.componentInstance.pinImgSrc = flag;
    dialogRef.afterClosed().subscribe(result => {
      this.isLoading = false;
    });
  }

  // scrollToFirst(id: string) {
  //   if(id != '')this.scrollService.scrollToElementById(id);
  // } 

  moreButtonForNext() {
    this.next = this.next + 1
    let reqInputObject = { pageNumber: this.next }
    this.commonService.yologramPublicSnapshot(reqInputObject).subscribe(async (response: any) => {
      this.filterSnapShotFileDetails.push(...response.object);
      this.filterSnapShotFileDetails.forEach(async (snap: any, index) => {
        // let userObjet = this.allUserDetails.find(userObject => userObject.uid == snap.UserID)
        // snap.profileName = userObjet.displayName != undefined ? userObjet.displayName : snap.UserID;

        let UserProfileObject =  this.userData.find((user)=> user.UserID == snap.UserID )
        let userObjet = this.allUserDetails.find(userObject => userObject.uid == snap.UserID)
        snap.profileName = UserProfileObject.DisplayName != undefined ? UserProfileObject.DisplayName : (userObjet.displayName != undefined ? userObjet.displayName : snap.UserID);
        userObjet.profileName = snap.profileName;
        snap.userData = userObjet
        snap.firstName = snap.profileName.split(" ", 1);
        snap.AvatarLastFive = snap.AvatarID.substr(snap.AvatarID.length - 5);
      });
      if (response.object.length > 0) {
        this.stroyLodaingFlag = false
        this.alertFlag = true
        this.successMsgs = "story loaded successfully";
        this.storyIdByindex = response.object[0]
        let id = this.storyIdByindex.StoryID
        setTimeout(() => {
          this.scrollService.scrollToElementById(id);
        },
          110);
        setTimeout(() => {
          this.homeclick()
        },
          500);
        setTimeout(() => {
          this.alertFlag = false
        },
          2000);
      } else {
        this.stroyLodaingFlag = true
        this.successMsgs = "no more story available";
        this.alertFlag = true
        setTimeout(() => {
          this.alertFlag = false
        },
          2000);
      }
      // this.openModleForToast(this.stroyLodaingFlag)

    })


  }

  inifiniteXScrolls(el) {
    let lastScrollX = 0;
    let overlapCount = 0;
    let clones = [];
    while (el.children[overlapCount] && ((el.children[overlapCount].offsetLeft - el.offsetLeft)) <= el.getClientRects()[0].width) {
      const clone = el.children[overlapCount].cloneNode(true);
      clones.push(clone);
      el.appendChild(clone);
      overlapCount++;
    }
    el.scrollTo({ left: lastScrollX });
    overlapCount -= 1;
    let lastScroll: number = el.scrollLeft = 1;
    function scrollHandler(): void {
      const maxScroll: number = el.scrollWidth - el.clientWidth;
      const overlapOverflow =
        (el.children[overlapCount].offsetLeft - el.offsetLeft)
        - el.getClientRects()[0].width
        + el.children[overlapCount].getClientRects()[0].width;
      if (el.scrollLeft >= maxScroll && el.scrollLeft > lastScroll) {
        el.scrollLeft = overlapOverflow;
      } else if (el.scrollLeft <= 0 && el.scrollLeft < lastScroll) {
        el.scrollLeft = maxScroll - overlapOverflow;
      }
      lastScroll = el.scrollLeft;
    }
    el.addEventListener('scroll', scrollHandler);
    return function () {
      const scroll = el.scrollLeft;
      clones.forEach((el) => el.replaceWith(""));
      el.scrollTo({ auto: scroll });
      el.removeEventListener('scroll', scrollHandler);
    }
  }

  onVerticalScroll(): void {
    this.horizontalScrollss.forEach((scrollContainer: ElementRef) => {
      if (scrollContainer.nativeElement instanceof Element) {
        // Reset the horizontal scroll to the initial position (0px)
        scrollContainer.nativeElement.scrollLeft = 0;
      }
    });
  }

  horiZonatalScroll(){
    this.horizontalScrolls.forEach((scrollContainer: ElementRef) => {
      if (scrollContainer.nativeElement instanceof Element) {
        this.inifiniteXScrolls(scrollContainer.nativeElement)
      }
    });

  }



}

@Component({
  selector: 'model-viewer-dilog',
  templateUrl: './model-viewer-dilog.html',
})
export class ModelViewerDilog {
  @Input() pinImgSrc: string | undefined;

  constructor(public dialog: MatDialog) {

  }
}

@Component({
  selector: 'social-media-model-share-dialog',
  templateUrl: './social-media-share-dialog.html',
})

export class SocialMediaShareDialog {

  public templateFlag = 0;
  @Input() storyId: any | undefined = {};
  @Input() garmentDetails: any | undefined;
  vrmUrl: any;
  apiUrl = 'https://us-central1-yolomoves-fb435.cloudfunctions.net/generateSignedUrl';
  signedUrl: string;
  shorturl: any = '';


  constructor(public dialog: MatDialog,private commonService :CommonService, private http: HttpClient ) {
 

  }
  
  ngOnInit(): void {
    this.getSignedUrlDirectly()
    console.log( 'this.shorturl',this.shorturl)


    }
  setTemplate($event) {
    this.templateFlag = $event.target.value;
  }

 async shareStory() {

    // this.findOutVrmUrl()
    // this. shareImageFromUrl(this.storyId)
    // let link = "  https://yolomove-social-live.web.app/story?storyId=" + this.storyId.StoryID + "&teamplate=" + this.templateFlag
    // let vrm = this.storyId.vrmURL
    let text: string;
  
console.log("this.storyObject===>",this.storyId)
// if (this.storyId.avatarName.trim() !== "" && /\d/.test(this.storyId.avatarName)) {
//  text = 'Check out ' + this.storyId.avatarName + ' wearing ' + this.garmentDetails;
// } else if (this.storyId.DisplayName !== null) {
// text = 'Check out ' + this.storyId.DisplayName + ' wearing ' + this.garmentDetails;
// } else {
 
//  text = 'Check out ' + this.storyId.UserID + ' wearing ' + this.garmentDetails;
// }


if (this.storyId.avatarName.trim() !== "" && /\d/.test(this.storyId.avatarName)) {
  // Avatar name is not empty and contains numbers, go to else part
  text = 'Check out ' + this.storyId.DisplayName + ' wearing ' + this.garmentDetails;
} else if (this.storyId.avatarName.trim() !== "") {
  // Avatar name is not empty and does not contain numbers
  text = 'Check out ' + this.storyId.avatarName + ' wearing ' + this.garmentDetails;
} else if (this.storyId.DisplayName !== null) {
  text = 'Check out ' + this.storyId.DisplayName + ' wearing ' + this.garmentDetails;
} else {
  text = 'Check out ' + this.storyId.UserID + ' wearing ' + this.garmentDetails;
}

console.log("text==========>", text)
    let baseUrl = "https://yolomove-social-live.web.app/story?storyId=" + this.storyId.StoryID;
    let vrmurl = this.storyId.vrmURL != '' ? this.signedUrl : ''
    let link = this.templateFlag == 3 ? vrmurl : `${baseUrl}&teamplate=${this.templateFlag}`;
    // let text = 'Check out ' + this.storyId.avatarName + ' wearing ' + this.garmentDetails

    console.log(link)

    if (navigator.share) {
      const file = await this.commonService.urlToFile(this.storyId.SnapshotFileUrl, this.storyId.id);
      console.log( 'storyObject',this.storyId)
      navigator.share({
        
        files: [file],
        title :"Share Snap",
        text :text,
        url: link,
        // title: 'Share Snap',
        // text: text,
        // url: link,
      })
        .then(() => console.log('Successful share'))
        .catch((error) => console.log('Error sharing', error));
    }
  
  }


  async findOutVrmUrl(){
    const filePath = 'AvatarBlenderSaves/debug/VRM/20240115155006534VRM.vrm'; // Provide the file path here
    this.vrmUrl = await this.commonService.findOutFFireBaseStorageUrl(filePath);
    console.log('firebaseUrl', this.vrmUrl)
  }
  

  async getSignedUrlDirectly() {
    // Directly call the API without a service
    // this.http.get<{ signedUrl: string }>(this.apiUrl).subscribe({
    //   next: (response) => {
    //     console.log('Signed URL:', response.signedUrl);
    //        this.signedUrl =  response.signedUrl
    //     // Use the signed URL here. For example, you can assign it to a variable or use it in a method.
    //   },
    //   error: (error) => {
    //     console.error('Error fetching signed URL:', error);
    //   }
    // });

    let snapObject = this.storyId
    let filePath = 'AvatarBlenderSaves/'+snapObject.UserID +'/VRM/' + snapObject.AvatarID + 'VRM.vrm'
    console.log('filePath',filePath)
    // const filePath = 'AvatarBlenderSaves/debug/VRM/20240115155006534VRM.vrm';
    const expiresIn = 200; // Example values
    await this.commonService.generateSignednewUrl(filePath, expiresIn).subscribe({
      next: (response) => {
        console.log('Signed URL:', response.signedUrl);
        console.log('Signed URL:', response.shortLink);
        this.signedUrl = response.shortLink
      },
      error: (error) => {
        console.error('Error fetching signed URL:', error);
      }
    });
    console.log('Signed this.shorturl:', this.shorturl);


  }
  



async generateShortLink(url) {
  await this.commonService.generateShortUrl(url).subscribe({
    next: (data) => {
      console.log('Short Link:', data.shortLink);
      // Use the short link data as needed
      this.signedUrl = data.shortLink

    },
    error: (error) => console.error('Error creating short link:', error),
  });
}
}
