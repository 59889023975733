<!-- <div *ngIf="loading" class="loading">Loading&#8230;</div>
<div class="modal fade" id="garment-details" tabindex="-1" aria-labelledby="garment-details" aria-hidden="true"
    data-bs-backdrop="false">
    <div class="modal-dialog" id="garment-details">
        <div class="modal-content imp-block-garment">
            <div class="modal-body " *ngIf="clothDetails.BrandID != undefined">
                <h5 type="button" class="btn-close  closBtn" data-bs-dismiss="modal" aria-label="Close"></h5>
                <div class="container">
                    <div class="row text-center justify-content-center">
                        <div class="row text-center p-2 justify-content-center">
                            <div class="col-12 m-2 details">
                                <div class="col-lg-12 text-center w-100 m-1 d-flex justify-content-center  d-flex">
                                    <div class="text-center d-flex  ">
                                        <img data-bs-dismiss="modal"
                                            (click)="getBrandProfile(brandDetails.id,brandDetails.boardName)"
                                            class="rounded profileImg-brand"
                                            style="width:35px !important; height:35px !important;"
                                            [src]="getBrandImage(clothDetails.LogoURL)">
                                    </div>
                                    <div class="p-1"><b data-bs-dismiss="modal"
                                            (click)="getBrandProfile(brandDetails.id,brandDetails.boardName)">{{clothDetails.BrandName}}</b>
                                    </div>
                                    <div><button type="button"
                                            class="btn btn-sm btn-outline-info follow">Follow</button></div>

                                </div>
                                <b>
                                    <h6 class="color-gender "> {{clothDetails.Gender == 'F' ? 'Womenswear' :
                                        (clothDetails.Gender == 'M') ? 'Menswear' : 'N/A'}}</h6>
                                </b>
                                <b>
                                    <h5 data-bs-dismiss="modal" (click)="getClothProfile(clothDetails.BrandID)"
                                        [ngClass]="clothDetails.Name.length >= 20  ? 'class-font' : ''"
                                        class="text-black clothName">
                                        <span *ngIf="clothDetails.RealType == '0'" class="brandbtn py-1"><img
                                                style="width:18px !important; color:black ;padding-bottom: 3px;"
                                                src="./assets/realtype/headgear.png"></span>
                                        <span *ngIf="clothDetails.RealType == '1'" class="brandbtn py-1"><img
                                                style="width:18px !important; color:black ;padding-bottom: 3px;"
                                                src="./assets/realtype/upperwear.png"></span><span
                                            *ngIf="clothDetails.RealType == '2'" class="brandbtn py-1"><img
                                                style="width:18px !important; color:black ;padding-bottom: 5px;"
                                                src="./assets/realtype/bottomwear.png"></span><span
                                            *ngIf="clothDetails.RealType == '3'" class="brandbtn py-1"><img
                                                style="width:18px !important; color:black ;padding-bottom: 3px;"
                                                src="./assets/realtype/footwear.png"></span><span
                                            *ngIf="clothDetails.RealType == '4'" class="brandbtn py-1"><img
                                                style="width:18px !important; color:black ;padding-bottom: 3px;"
                                                src="./assets/realtype/heeledfootwear.png"></span><span
                                            *ngIf="clothDetails.RealType == '5'" class="brandbtn py-1"><img
                                                style="width:18px !important; color:black ;padding-bottom: 3px;"
                                                src="./assets/realtype/fullbody.png"></span>
                                        {{(clothDetails.Name)}}
                                    </h5>
                                </b>
                                <h6 data-bs-dismiss="modal" (click)="getClothProfile(clothDetails.id)" class="details">
                                    Collection: {{clothDetails.CollectionName}}</h6>
                                <h6 data-bs-dismiss="modal" (click)="getClothProfile(clothDetails.id)" class="details">
                                    Details: {{clothDetails.Details}}</h6>
                                <h6 data-bs-dismiss="modal" (click)="getClothProfile(clothDetails.id)" class="details">
                                    SKU: {{clothDetails.SKU}}</h6>
                            </div>

                        </div>
                    </div>
                    <div class="row" data-bs-dismiss="modal" (click)="getClothProfile(clothDetails.id)">
                        <div class=" text-center col-6" *ngFor="let fileList of userFiledetails;let p=index">
                            <span *ngIf="p < counter">
                                <div *ngIf="fileList.fileType == 'GarmentUpload2D'"
                                    (click)="getClothProfile(clothDetails.id)">
                                    <img class="img-fluid img-thumbnail" style="height: 100px; width: 100px !important; object-fit: contain;
                                    " src={{fileList.fileurl}}>
                                </div>
                                <div class="justify-content-center col-6 "
                                    *ngIf="fileList.fileType == 'GarmentUpload3D'"
                                    (click)="getClothProfile(clothDetails.id)">
                                    <div class="text-center col-6 ">
                                        <model-viewer camera-controls camera-orbit="45deg 55deg 2.5m" ar auto-rotate
                                            src={{fileList.fileurl}} alt="A 3D avatar-model-view"
                                            data-js-focus-visible="" ar-status="not-presenting"
                                            style="height: 100px; width: 150px; float: none;">
                                        </model-viewer>
                                    </div>
                                </div>
                            </span>
                        </div>
                    </div>
                </div>
                <div class="p-2 m-1 text-center d-flex justify-content-center">
                    <h6 data-bs-dismiss="modal" class="text-white brandbtn m-1" (click)="getStoryCloth(clothDetails)">
                        <img style="width:15px !important; color:black" src="./assets/storystyle/story-white.png">
                    </h6>
                    <h6 data-bs-dismiss="modal" (click)="getgarmentStyle(brandDetails.id,clothDetails)"
                        class="text-white brandbtn m-1">
                        <img style="width:18px !important; color:black" src="./assets/storystyle/style-white.png">
                    </h6>
                    <h6 *ngIf="clothDetails.ThumbCenterURL  != undefined" class="m-1 brandbtn"><a
                            href="{{clothDetails.ThumbCenterURL}}" target="_blank"><img
                                style="width:15px !important; color:black" src="./assets/details.png"></a></h6>
                    <h6 *ngIf="clothDetails.ThumbCenterURL  == undefined" class="m-1 brandbtn"><a><img
                                style="width:15px !important; color:black" src="./assets/details-gray.png"></a> </h6>

                    <h6 *ngIf="clothDetails.ThumbLeftURL  != undefined" class="m-1 brandbtn"><a
                            href="{{clothDetails.ThumbLeftURL}}" target="_blank"><img style="width:15px !important"
                                src="./assets/gallary.png"></a></h6>
                    <h6 *ngIf="clothDetails.ThumbLeftURL  == undefined" class="m-1 brandbtn"><a><img
                                style="width:15px !important" src="./assets/gallary-gray.png"></a></h6>

                    <h6 *ngIf="clothDetails.ThumbRightURL  != undefined" class="m-1 brandbtn"><a
                            href="{{clothDetails.ThumbRightURL}}" target="_blank"><img
                                style="width:15px !important; color:black" src="./assets/shop.png"></a></h6>
                    <h6 *ngIf="clothDetails.ThumbRightURL  == undefined" class="m-1 brandbtn"><a><img
                                style="width:15px !important; color:black" src="./assets/cart-gray.png"></a>
                    </h6>
                </div>


         


                <div class="row w-100 m-1"
                    *ngIf="brandShapShotsList.length >= 0  && clocthCollectionIdcollection != undefined && clocthCollectionIdcollection.length >= 0 ">
                    <div class="column d-block m-1" *ngFor="let snapshot of clothShapShotsList  ; let i=index;">
                        <img class="w-100 shadow-1-strong rounded" data-bs-dismiss="modal"
                            (click)="getgarmentfeed(brandDetails,clothDetails)" [src]="snapshot.SnapshotFileUrl">
                    </div>
                    <div class="columnImg d-block m-1">
                        <img class="w-80 shadow-1-strong rounded" data-bs-dismiss="modal">
                    </div>
                    <div class="columnImg d-block m-1">
                        <img class="w-80 shadow-1-strong rounded" data-bs-dismiss="modal">
                    </div>
                    <div class="columnImg d-block m-1" *ngIf="clothShapShotsList.length > 8"
                        (click)="getBrandProfile(brandDetails.id,brandDetails.boardName)">
                        <img style="margin-top: -165px;" class="w-80 shadow-1-strong rounded" data-bs-dismiss="modal"
                            src="./assets/plus-8-512.png">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade imp-bg" id="brand-details" tabindex="-1" aria-labelledby="brand-details" aria-hidden="true"
    data-bs-backdrop="false">
    <div class="modal-dialog  ">
        <div class="modal-content imp-block-brand " *ngIf="brandDetails && brandDetails.BrandName">
            <h5 type="button" class="btn-close  closBtn" data-bs-dismiss="modal" aria-label="Close"></h5>
            <div class="container">
                <div class="row text-center">
                    <div class="row text-center p-2">
                        <div class="col-12 m-3">
                            <img style="width: 75px; height:75px !important;" class="rounded profileImg-brand p-1"
                                data-bs-dismiss="modal"
                                (click)="getBrandProfile(brandDetails.id,brandDetails.boardName)"
                                [src]="getBrandImage(brandDetails.LogoURL)" />
                            <h2 class="w-100" data-bs-dismiss="modal"
                                (click)="getBrandProfile(brandDetails.id,brandDetails.boardName)">
                                {{(brandDetails.BrandName)}}</h2>
                            <div><button type="button" class="btn btn-outline-info m-2 follow">Follow</button></div>
                            <h6><img style="width:15px !important" src="./assets/pinterest-512.png">:
                                {{brandDetails.boardName}} </h6>
                        </div>
                        <div class="p-1 text-center d-flex justify-content-center">
                            <h6 class="m-1 text-white brandbtn"
                                [ngClass]="brandDetails.id == brandId  ? 'border-bottom border-light' : 'border-bottom border-light'">
                                <b>Story</b>
                            </h6>
                            <h6 data-bs-dismiss="modal" (click)="getBrandStyle(brandDetails.id,'M')"
                                class="m-1 text-white brandbtn">Menswear</h6>
                            <h6 data-bs-dismiss="modal" (click)="getBrandStyle(brandDetails.id,'F')"
                                class="m-1 text-white brandbtn">Womenswear</h6>
                        </div>
                        <div class="row w-100 m-2"
                            *ngIf="brandShapShotsList.length  >= 0  && brandCollectionIdcollection != undefined && brandCollectionIdcollection.length >= 0">
                            <div class="column d-block m-1" *ngFor="let snapshot of brandShapShotsList  ; let i=index;">
                                <img class="w-100 shadow-1-strong rounded" data-bs-dismiss="modal"
                                    (click)="getFeed(snapshot,brandDetails)" [src]="snapshot.SnapshotFileUrl">
                            </div>
                            <div class="columnImg d-block m-1">
                                <img class="w-80 shadow-1-strong rounded" data-bs-dismiss="modal">
                            </div>
                            <div class="columnImg d-block m-1">
                                <img class="w-80 shadow-1-strong rounded" data-bs-dismiss="modal">
                            </div>
                            <div class="columnImg d-block m-1" *ngIf="brandShapShotsList.length > 8"
                                (click)="getBrandProfile(brandDetails.id,brandDetails.boardName)">
                                <img style="margin-top: -165px;" class="w-80 shadow-1-strong rounded"
                                    data-bs-dismiss="modal" src="./assets/plus-8-512.png">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade imp-bg" id="brand-details" tabindex="-1" aria-labelledby="brand-details" aria-hidden="true"
    data-bs-backdrop="false">
    <div class="modal-dialog  ">
        <div class="modal-content imp-block" *ngIf="brandDetails && brandDetails.BrandName">
            <h5 type="button" class="btn-close  closBtn" data-bs-dismiss="modal" aria-label="Close"></h5>
            <div class="container">
                <div class="row text-center">
                    <div class="row text-center p-2">
                        <div class="col-12 m-3">
                            <img class="rounded-circle" style="width: 70px; height:70px !important;"
                                data-bs-dismiss="modal"
                                (click)="getBrandProfile(brandDetails.id,brandDetails.boardName)"
                                [src]="getBrandImage(brandDetails.LogoURL)" />
                            <h2 class="w-100" data-bs-dismiss="modal"
                                (click)="getBrandProfile(brandDetails.id,brandDetails.boardName)">
                                {{(brandDetails.BrandName)}}</h2>
                            <div><button type="button" class="btn btn-outline-info m-2 follow">Follow</button></div>
                            <h6><img style="width:15px !important" src="./assets/pinterest-512.png">:
                                {{brandDetails.boardName}} </h6>
                        </div>
                        <div class="p-1 text-center d-flex justify-content-center">
                            <h6 class="m-1 text-white brandbtn"
                                [ngClass]="brandDetails.id == brandId  ? 'border-bottom border-light' : 'border-bottom border-light'">
                                <b>Story</b>
                            </h6>
                            <h6 data-bs-dismiss="modal" (click)="getBrandStyle(brandDetails.id,'M')"
                                class="m-1 text-white brandbtn">Menswear</h6>
                            <h6 data-bs-dismiss="modal" (click)="getBrandStyle(brandDetails.id,'F')"
                                class="m-1 text-white brandbtn">Womenswear</h6>
                        </div>
                        <div class="row w-100 m-2"
                            *ngIf="brandShapShotsList.length >= 0  && brandCollectionIdcollection != undefined && brandCollectionIdcollection.length >= 0 ">
                            <div class="column d-block m-1" *ngFor="let snapshot of brandShapShotsList  ; let i=index;">
                                <img class="w-100 shadow-1-strong rounded" data-bs-dismiss="modal"
                                    (click)="getFeed(snapshot,brandDetails)" [src]="snapshot.SnapshotFileUrl">
                            </div>
                            <div class="columnImg d-block m-1">
                                <img class="w-80 shadow-1-strong rounded" data-bs-dismiss="modal">
                            </div>
                            <div class="columnImg d-block m-1">
                                <img class="w-80 shadow-1-strong rounded" data-bs-dismiss="modal">
                            </div>
                            <div class="columnImg d-block m-1" *ngIf="brandShapShotsList.length > 8"
                                (click)="getBrandProfile(brandDetails.id,brandDetails.boardName)">
                                <img style="margin-top: -165px;" class="w-80 shadow-1-strong rounded"
                                    data-bs-dismiss="modal" src="./assets/plus-8-512.png">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade imp-bg" id="user-profile" tabindex="-1" aria-labelledby="user-profile" aria-hidden="true"
    data-bs-backdrop="false">
    <div class="modal-dialog  ">
        <div class="modal-content imp-block" *ngIf="filterSnapShotForProfile">
            <h5 type="button" class="btn-close  closBtn" data-bs-dismiss="modal" aria-label="Close"></h5>
            <div class="container">
                <div class="row text-center">
                    <div class="row text-start p-2">
                        <div class="col-12 m-3" data-bs-dismiss="modal">
                            <img class="rounded-circle" style="width: 70px; height:70px !important;"
                                (click)="getSnapShotListByUser(profileDetails.uid)" data-bs-dismiss="modal"
                                src="{{profileDetails.photoURL}}" />
                            <h2 class="w-100" (click)="getSnapShotListByUser(profileDetails.uid)"
                                data-bs-dismiss="modal">
                                {{profileDetails.displayName}}</h2>
                            <div><button type="button" class="btn btn-outline-info m-1 follow">Follow</button></div>
                

                        </div>
                        <div class="row d-flex">
                            <div data-bs-dismiss="modal" class="text-center col-6">
                                <span>
                                    <div>
                                        <img class="img-fluid img-thumbnail" style="height: 100px; width: 100px !important; object-fit: contain;
                                    " src="./assets/imgplaceholder.jpg">
                                    </div>
                                </span>
                            </div>
                            <div data-bs-dismiss="modal" class="text-center col-6">
                                <span>
                                    <div>
                                        <img class="img-fluid img-thumbnail" style="height: 100px; width: 100px !important; object-fit: contain;
                                    " src="./assets/imgplaceholder1.jpg">
                                    </div>
                                </span>
                            </div>
                        </div>
                        <div class="row w-100 m-2"
                            *ngIf="filterSnapShotForProfile.length >= 0  && filterSnapShotForProfile != undefined && filterSnapShotForProfile.length >= 0 ">
                            <div class="column d-block m-1"
                                *ngFor="let snapshot of filterSnapShotForProfile  ; let i=index;">
                                <img class="w-100 shadow-1-strong rounded"
                                    (click)="getSnapShotListByUser(profileDetails.uid)" [src]="snapshot.SnapshotFileUrl"
                                    data-bs-dismiss="modal">
                            </div>
                            <div class="columnImg d-block m-1">
                                <img class="w-80 shadow-1-strong rounded" data-bs-dismiss="modal">
                            </div>
                            <div class="columnImg d-block m-1">
                                <img class="w-80 shadow-1-strong rounded" data-bs-dismiss="modal">
                            </div>
                            <div class="columnImg d-block m-1" *ngIf="filterSnapShotForProfile.length > 2"
                                (click)="getSnapShotListByUser(profileDetails.uid)">
                                <img style="margin-top: -165px;" class="w-80 shadow-1-strong rounded"
                                    data-bs-dismiss="modal" src="./assets/plus-8-512.png">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade imp-bg" id="Avatar-profile" tabindex="-1" aria-labelledby="Avatar-profile" aria-hidden="true"
    data-bs-backdrop="false">
    <div class="modal-dialog  ">
        <div class="modal-content imp-block" *ngIf="filterSnapShotForProfile">
            <h5 type="button" class="btn-close  closBtn" data-bs-dismiss="modal" aria-label="Close"></h5>
            <div class="container">
                <div class="row text-center">
                    <div class="row text-center p-2">
                        <div class="col-12 m-3">
                            <img class="rounded-circle" style="width: 70px; height:70px !important;"
                                data-bs-dismiss="modal" (click)="getSnapShotListByUser(profileDetails.uid)"
                                src="{{profileDetails.photoURL}}" />
                            <h2 class="w-100" data-bs-dismiss="modal"
                                (click)="getSnapShotListByUser(profileDetails.uid)">
                                {{profileDetails.displayName}}</h2>
                            <a (click)="getProfileByAvator(selectedAvtar)"> 🧍🏽{{selectedAvtar}} </a>
                            <div><button type="button" class="btn btn-outline-info m-1 follow">Follow</button></div>
                            <div><button type="button" class="btn btn-outline-info m-1 follow" data-bs-dismiss="modal"
                                    (click)="getuserfeed(selectedAvtar)">Avatar Feed</button></div>
                        </div>
                        <div class="row d-flex">
                            <div data-bs-dismiss="modal" class="text-center col-6">
                                <span>
                                    <div>
                                        <img class="img-fluid img-thumbnail" style="height: 100px; width: 100px !important; object-fit: contain;
                                    " src="./assets/imgplaceholder.jpg">
                                    </div>
                                </span>
                            </div>
                            <div data-bs-dismiss="modal" class="text-center col-6">
                                <span>
                                    <div>
                                        <img class="img-fluid img-thumbnail" style="height: 100px; width: 100px !important; object-fit: contain;
                                    " src="./assets/imgplaceholder1.jpg">
                                    </div>
                                </span>
                            </div>
                        </div>
                        <div class="row w-100 m-2"
                            *ngIf="filterSnapShotForProfile.length >= 0  && filterSnapShotForProfile != undefined && filterSnapShotForProfile.length >= 0 ">
                            <div class="column d-block m-1"
                                *ngFor="let snapshot of filterSnapShotForProfile  ; let i=index;">
                                <img class="w-100 shadow-1-strong rounded" data-bs-dismiss="modal"
                                    (click)="getFeed(snapshot,brandDetails)" [src]="snapshot.SnapshotFileUrl">
                            </div>
                            <div class="columnImg d-block m-1">
                                <img class="w-80 shadow-1-strong rounded" data-bs-dismiss="modal">
                            </div>
                            <div class="columnImg d-block m-1">
                                <img class="w-80 shadow-1-strong rounded" data-bs-dismiss="modal">
                            </div>
                            <div class="columnImg d-block m-1" *ngIf="filterSnapShotForProfile.length > 2"
                                (click)="getBrandProfile(brandDetails.id,brandDetails.boardName)">
                                <img style="margin-top: -165px;" class="w-80 shadow-1-strong rounded"
                                    data-bs-dismiss="modal" src="./assets/plus-8-512.png">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade imp-bg" id="Envitonment-profile" tabindex="-1" aria-labelledby="Avatar-profile"
    aria-hidden="true" data-bs-backdrop="false">
    <div class="modal-dialog  ">
        <div class="modal-content imp-block" *ngIf="filterEnvironmentSnpShot">
            <h5 type="button" class="btn-close  closBtn" data-bs-dismiss="modal" aria-label="Close"></h5>
            <div class="container">
                <div class="row text-center">
                    <div class="row text-center p-2">
                        <div class="col-12 m-3" *ngIf="evnFileListFileName && evnFileListFileName['displayName']">
                            <h2 (click)="getEnvironmentProfile(evnFileListFileName['envFileName'])">
                                {{evnFileListFileName['displayName']}}</h2>
                            <div><button type="button" class="btn btn-outline-info m-1 follow"
                                    data-bs-dismiss="modal">Preview</button></div>
                        </div>
                        <div class="row w-100 m-2"
                            *ngIf="filterEnvironmentSnpShot.length >= 0  && filterEnvironmentSnpShot != undefined && filterEnvironmentSnpShot.length >= 0 ">
                            <div class="column d-block m-1"
                                *ngFor="let snapshot of filterEnvironmentSnpShot  ; let i=index;">
                                <img class="w-100 shadow-1-strong rounded" data-bs-dismiss="modal"
                                    (click)="getFeed(snapshot,brandDetails)" [src]="snapshot.SnapshotFileUrl">
                            </div>
                            <div class="columnImg d-block m-1">
                                <img class="w-80 shadow-1-strong rounded" data-bs-dismiss="modal">
                            </div>
                            <div class="columnImg d-block m-1">
                                <img class="w-80 shadow-1-strong rounded" data-bs-dismiss="modal">
                            </div>
                            <div class="columnImg d-block m-1" *ngIf="filterEnvironmentSnpShot.length > 8"
                                (click)="getEnvironmentProfile(evnFileListFileName['envFileName'])">
                                <img style="margin-top: -165px;" class="w-80 shadow-1-strong rounded"
                                    data-bs-dismiss="modal" src="./assets/plus-8-512.png">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="manage-w">

    <div class="dt-responsive selectlist font-14">
        <div class="dataTables_wrapper dt-bootstrap4" id="add-row-table_wrapper">
            <div *ngIf="filterSnapShotFileDetails && filterSnapShotFileDetails?.length == 0 || !filterSnapShotFileDetails"
                class="p-3"><span class="danger">
                    <div class="alert alert-danger text-center" role="alert">
                        <b>No Post Found.</b>
                    </div>
                </span>
            </div>
            <div #snapshotfeeds class="card m-3  slider-css ">
                <div class="slider-img  " [ngClass]="{'active': storyId === snapshot.StoryID}"
                    *ngFor="let snapshot of filterSnapShotFileDetails; let i=index; ">
                    <div class="m-2">
              
                        <h6>
                            <div class="col-lg-12 d-flex">
                                <a class="col-lg-6 w-70 display-name"
                                    (click)="getSnapShotListByUserPopup(snapshot)">{{(snapshot.profileName)}}</a>
                                <h6 (click)="openAvatrPopup(snapshot)"
                                    class="col-lg-6 text-end avatar-diaplay">
                                    🧍🏽{{snapshot.firstName}}{{snapshot.AvatarLastFive}}</h6>
                            </div>
                        </h6>
                        <div class="col-lg-12 d-flex" >
                            <h6 class="col-lg-6 date-class">{{snapshot.timestamp | date : 'MMM d, y, h:mm a'}}</h6>
                            <h6 (click)="newEnvironmentPopup(snapshot.Environment)" class="col-lg-6 evn-font text-end">
                                {{getEnvironmentName(snapshot.Environment)}}</h6>
                        </div>
                        <span class="spinner-border second-spineer" *ngIf="isLoading" role="status"></span>
                    </div>
                    <img class="img-fluid" width="500 !important" height="300" [src]="snapshot.SnapshotFileUrl" />
                    <h6 class="second-txt">
                        {{snapshot.StoryType}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        {{snapshot.StoryID}}
                    </h6>
                    <div class="">
                        <div class="col-12 col-sm-12 slider-box">
                            <div class="col-12 col-sm-12" *ngIf="filterSnapShotFileDetails"><img
                                    *ngIf="getGarmentrealtype(snapshot.HeadwearID) == '0' "
                                    style="width:15px !important; color:black" src="./assets/newsfeed/headgear-new.png">
                                <span class="caption-values"
                                    *ngIf="snapshot.HeadwearID != undefined && snapshot.HeadwearID != ''">
                                    <span class="garment-name" data-toggle="tooltip" data-placement="top"
                                        title="Cloth Details" (click)="openGarmentPopup('HeadwearID',snapshot.HeadwearID)"
                                       >
                                        {{getGarmentName(snapshot.HeadwearID)}}
                                    </span>&nbsp;
                                    <span class="brand-name" data-toggle="tooltip" data-placement="top"
                                        title="Brands Details" (click)="openBrandPoup(snapshot.HeadwearID)"
                                      >{{getBrandName(snapshot.HeadwearID)}}</span>
                                    <a
                                        (click)="shareOnPinterest(snapshot.HeadwearID,'headwear',snapshot.SnapshotFileUrl,snapshot.StoryID) ">
                                        <img class="img-thumbnail2" src="./assets/Pinterest-logo.png" />
                                    </a>
                                </span>
                            </div>
                            <div class="col-12 col-sm-12 " *ngIf="filterSnapShotFileDetails">
                                <img *ngIf="getGarmentrealtype(snapshot.UpperwearID) == '1' "
                                    style="width:15px !important; color:black" src="./assets/realtype/upperwear.png">
                                <img *ngIf="getGarmentrealtype(snapshot.UpperwearID) == '5' "
                                    style="width:15px !important; color:black" src="./assets/realtype/fullbody.png">
                                <span class="caption-values"
                                    *ngIf="snapshot.UpperwearID != undefined && snapshot.UpperwearID != ''">
                                    <span class="garment-name" data-toggle="tooltip" data-placement="top"
                                        title="Cloth Details" (click)="openGarmentPopup('UpperwearID',snapshot.UpperwearID)"
                                      >
                                        {{getGarmentName(snapshot.UpperwearID)}}
                                    </span>&nbsp;
                                    <span class="brand-name" data-toggle="tooltip" data-placement="top"
                                        title="Brands Details" (click)="openBrandPoup(snapshot.UpperwearID)"
                                        >{{getBrandName(snapshot.UpperwearID)}}</span>
                                    <a
                                        (click)="shareOnPinterest(snapshot.UpperwearID,'upperwear',snapshot.SnapshotFileUrl,snapshot.StoryID)">
                                        <img class="img-thumbnail2" src="./assets/Pinterest-logo.png" />
                                    </a>
                                </span>
                            </div>
                            <div class="col-12 col-sm-12 " *ngIf="filterSnapShotFileDetails">
                                <img *ngIf="getGarmentrealtype(snapshot.BottomwearID) == '2'"
                                    style="width:15px !important; color:black"
                                    src="./assets/newsfeed/bottomwear-new.png">
                                <span class="caption-values"
                                    *ngIf="snapshot.BottomwearID != undefined && snapshot.BottomwearID != ''">
                                    <span class="garment-name" data-toggle="popover" data-placement="left"
                                        title="Cloth Details" (click)="openGarmentPopup('BottomwearID',snapshot.BottomwearID)"
                                      >
                                        {{getGarmentName(snapshot.BottomwearID)}}
                                    </span>&nbsp;
                                    <span class="brand-name" data-toggle="popover" data-placement="right"
                                        title="Cloth Details" (click)="openBrandPoup(snapshot.BottomwearID)"
                                       >
                                        {{getBrandName(snapshot.BottomwearID)}}
                                    </span>

                                    <a d
                                        (click)="shareOnPinterest(snapshot.BottomwearID,'upperwear',snapshot.SnapshotFileUrl,snapshot.StoryID)">
                                        <img class="img-thumbnail2" src="./assets/Pinterest-logo.png" />
                                    </a>
                                </span>
                            </div>
                            <div class="col-12 col-sm-12 " *ngIf="filterSnapShotFileDetails">
                                <img *ngIf="getGarmentrealtype(snapshot.FootwearID) == '3' "
                                    style="width:15px !important; color:black" src="./assets/realtype/footwear.png">
                                <span class="caption-values"
                                    *ngIf="snapshot.FootwearID != undefined && snapshot.FootwearID != ''">
                                    <span class="garment-name" data-toggle="popover" data-placement="left"
                                        title="Cloth Details" (click)="openGarmentPopup('FootwearID',snapshot.FootwearID)">
                                        {{getGarmentName(snapshot.FootwearID)}}
                                    </span>&nbsp;
                                    <span class="brand-name" data-toggle="popover" data-placement="right"
                                        title="Cloth Details" (click)="openBrandPoup(snapshot.FootwearID)"
                                        >
                                        {{getBrandName(snapshot.FootwearID)}}
                                    </span>

                                    <a
                                        (click)="shareOnPinterest(snapshot.FootwearID,'footwear',snapshot.SnapshotFileUrl,snapshot.StoryID)">
                                        <img class="img-thumbnail2 " src="./assets/Pinterest-logo.png" />
                                    </a>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="d-flex justify-content-center">
            <div class="dots-bars-2" *ngIf="isLoading" role="status">
                <span class=""></span>
            </div>
        </div>

        <div *ngIf="loading" class="loading">Loading&#8230;</div>
    </div>
    <div class="modal fade imp-bg" id="fileView-profile" tabindex="-1" aria-labelledby="user-profile" aria-hidden="true"
        data-bs-backdrop="false">
        <div class="modal-dialog justify-content-center ">
            <div class="modal-content imp-block justify-content-center">
                <h5 type="button" class="btn-close  closBtn" data-bs-dismiss="modal" aria-label="Close"></h5>
                <img src={{showImages.fileurl}}>
            </div>
        </div>
    </div>
</div> -->
<app-drips [menuFlag]="menuFlag" ></app-drips>