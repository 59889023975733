import { Component, ElementRef, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Brand } from 'src/app/models/brand';
import { CommonService } from 'src/app/services/common.service';
import { map } from 'rxjs/operators';
import { Modal } from 'bootstrap';

@Component({
  selector: 'app-brand-color',
  templateUrl: './brand-color.component.html',
  styleUrls: ['./brand-color.component.css']
})
export class BrandColorComponent  {

  brands = this.firestore.collection('brands').valueChanges({ idField: 'id' }) as Observable<Brand[]>;
  brandColors = this.firestore.collection('BrandColor').valueChanges({ idField: 'id' }) as Observable<Brand[]>;
  groupUser = this.firestore.collection('GroupUser').valueChanges({ idField: 'id' }) as Observable<[]>;
  groupRoleCollection = this.firestore.collection('GroupRole').valueChanges({ idField: 'id' }) as Observable<[]>;
  groupBrands = this.firestore.collection('GroupBrands').valueChanges({ idField: 'id' }) as Observable<[]>;




  @ViewChild('brandStoriyScroll', { read: ElementRef }) public BrandStoriyScroll: ElementRef<any>;
  @ViewChild('brandStoriyScrollA', { static: false }) brandStoriyScrollA: ElementRef;

  private editModal: Modal | null = null; // Store the modal instance


  logInFlag: boolean;
  brandList: any = [];
  brandColorList: any = [];
  allBrandColors: any = [];
  byScroll: boolean;
  scrollFlag: boolean;
  scrolnewlFlag: boolean
  selectedBrand: any;
  brandNameSelected: any;
  selectedBrandId: any;
  selectedColor: string = '#000000'; // Default color
  selectedDiscription: any ;
  selectedColorData: any = {}; // Used for editing the selected color
  loading: boolean;

  userSecurityGrp: never[];
  userReferenceList = [];
  selectedSecGrpId: any;
  preferenceDocId: any;
  selectedAssignSecGrpId: any;
  assignBrandList: any = [];
  groupBrandsList: any = [];
  filterBrandListNew: any = [];





  constructor(public commonService: CommonService, private firestore: AngularFirestore, private router: Router) {
    this.logInFlag = this.commonService.isLoggedIn()
    this.checkPageAuthorization()
    // this.checkPageAuthorization()
    this.groupBrands.subscribe((brandGp) => {
      this.groupBrandsList = brandGp
    })


    this.brands.subscribe((brand) => {
      this.brandList = brand
      this.brandList.forEach(async (brand) => {
        let type = 'b';
        let url = await this.commonService.compressImage(brand.LogoURL, type); // Assuming each brand has 'imagePath' property
        brand.Brandurl = url.downloadUrl;  // Save the compressed image URL back into the brand object
      });
      console.log("brand List", this.brandList)
    })
    this.brandColors.subscribe((color) => {
      this.allBrandColors = color
    })
  
    
  }

  
  checkPageAuthorization() {
    this.loading = true;
    this.commonService.getUser().subscribe((user: any) => {
      let userObject = { 'userid': user?.uid, 'pagename': '/brand-color' }
      this.commonService.checkViewPageRights(userObject).subscribe((res) => {
        console.log(res)
        this.loading = false;
        let id = user?.uid
        this.getUserPreference(id)
        if (!res) {
          this.router.navigateByUrl("/drip");
        }
      });
    });
  }



  selectByScroll() {
    this.byScroll = true
    this.scrolnewlFlag = true
  }


  selectBrand(brand) {
    // this.selectedEvenObj = {}
    this.selectedBrand = brand
    this.selectedBrandId = brand.BrandId
    this.scrollFlag = false
    this.scrolnewlFlag = false
    let obj = this.getBrandDetails(this.selectedBrandId)
    this.brandNameSelected = obj.BrandName


    console.log("brand", brand)
    let gender = "g"
    let tab = brand.Gender == 'M' ? 'mens-wear' : brand.Gender == 'F' ? 'womens-wear' : 'story'
    // this.setTab(tab, gender)
    setTimeout(() => {
      let brandId = brand.id
      this.commonService.scrollToElementByIdbrand(brandId, this.brandStoriyScrollA);
      // Adjust the scroll position by -60px after the service call
      const element = document.getElementById(brandId);
      if (element) {
        element.scrollLeft -= 60;
      }
    }, 150); // Adjust the timeout duration as needed (500ms in this example)


    setTimeout(() => { 
      this.byScroll = false
      this.scrolnewlFlag = false}, 500);

      this.filterBrandColors()
  }

  updateHexColorFromPicker(event: any) {
    this.selectedColor = event.target.value;
  }

  colorDiscription(event: any) {
    this.selectedDiscription = event.target.value;
  }

  // Validate and update the color from the input box
  updateHexColorFromInput(event: any) {
    const color = event.target.value;
    
    // Simple validation for hex color
    const isValidHex = /^#([0-9A-F]{3}){1,2}$/i.test(color);
    if (isValidHex) {
      this.selectedColor = color;
    }
  }

  saveBrandColor() {
    if (!this.selectedBrand || !this.selectedColor || !this.selectedDiscription) {
      alert('Please select a brand, disscription and a color.');
      return;
    }

    const brandColorData = {
      BrandId: this.selectedBrandId, // Assuming selectedBrand contains an 'id' property
      ColorText: this.selectedDiscription,
      ColorHexCode: this.selectedColor
    };

    this.firestore.collection('BrandColor').add(brandColorData).then(
      () => {
        console.log('Brand color saved successfully');
        alert('Brand color saved successfully!');
        this.filterBrandColors()
        this.brandColorsClear()

      },
      (error) => {
        console.error('Error saving brand color:', error);
        alert('Error saving brand color.');
      }
    );

  }


  filterBrandColors() {
    if (!this.selectedBrand) {
      this.brandColorList = []; // Clear the list if no brand is selected
      return;
    }

    this.brandColorList = this.allBrandColors.filter(color => color.BrandId === this.selectedBrandId);
  }
  deleteBrandColor(colorId: string) {
    if (confirm('Are you sure you want to delete this color?')) {
      this.firestore.collection('BrandColor').doc(colorId).delete().then(
        () => {
          console.log('Brand color deleted successfully');
          alert('Brand color deleted successfully!');
          this.filterBrandColors(); // Refresh the list after deletion
        },
        (error) => {
          console.error('Error deleting brand color:', error);
          alert('Error deleting brand color.');
        }
      );
    }


}



brandColorsClear() {
  this.selectedDiscription = '';
  this.selectedColor = '';

}

openEditModal(color: any) {
  this.selectedColorData = { ...color }; // Clone the color object for editing
  const modalElement = document.getElementById('editColorModal');
  if (modalElement) {
    this.editModal = new Modal(modalElement); // Store the modal instance
    this.editModal.show();
  }
}

// Close the edit modal
closeEditModal() {
  if (this.editModal) {
    this.editModal.hide();
  }
}

saveChanges() {
  // Update the color in Firestore
  if (this.selectedColorData.id) {
    this.firestore.collection('BrandColor').doc(this.selectedColorData.id).update({
      ColorText: this.selectedColorData.ColorText,
      ColorHexCode: this.selectedColorData.ColorHexCode
    }).then(() => {
      console.log('Brand color updated successfully');
      alert('Brand color updated successfully!');
      this.closeEditModal()
      this.filterBrandColors();
    }).catch((error) => {
      console.error('Error updating brand color:', error);
      alert('Error updating brand color.');
    });
  }
}


getUserPreference(id) {
  this.groupUser.subscribe((grpList) => {
    this.userSecurityGrp = grpList.filter((grp: any) => grp.UserId === id);
    this.firestore.collection("UserPreference").ref
      .where("Key", "==", "LastUserGroupSelected")
      .where("UserId", "==", id.toString())
      .get().
      then((objectList: any) => {
        this.userReferenceList = objectList.docs;
        if (objectList.docs.length > 0 && this.userSecurityGrp.length > 0) {
          objectList.forEach((doc) => {
            if (doc.data().UserId == id && this.userSecurityGrp.find(b => b['SecGpId'] == doc.data().KeyValue) != undefined
            ) {
              this.selectedSecGrpId = doc.data().KeyValue;
              this.preferenceDocId = doc.id;
              this.groupRoleCollection.subscribe(((groupRole: any) => {
                let accessPageListselectGroup = groupRole.find((obj) => obj.SecGpId == this.selectedSecGrpId)
                this.selectedAssignSecGrpId = accessPageListselectGroup.SecGpId
                this.getAssignBrandList(this.selectedAssignSecGrpId)
              }))
            }
          });
        }
      }).catch((error) => {
        console.log("Error getting documents: ", error);
      });
  });
}

async getAssignBrandList(gpId) {
  // this.assignBrandList = this.groupBrandsList.filter(b => b.SecGpId == gpId);
  this.assignBrandList = await Promise.all(
    this.groupBrandsList
      .filter(b => b.SecGpId == gpId)
      .map(async (brand) => {
        const logoUrl = await this.getBrandImage(brand);
        return {
          ...brand,
          logoUrl
        };
      })
  );

  console.log(" this.assignBrandList", this.assignBrandList)

}
async getBrandImage(brand) {
  let brandObject = await this.getBrandDetails(brand.BrandId)
  let imgURL = brandObject.LogoURL
  let brandEncodedurl = encodeURI(imgURL).replace('/', '%2F');
  const newPath = brandEncodedurl.replace('Brands%2F', 'Brands%2Fcompressed%2F');
  let url = "https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/" + newPath + "?alt=media&token=550e7e88-bed3-4668-84ff-ad491c19e06c"
  return url;
}

getBrandDetails(id) {
  let brand = this.brandList.find((brand) => brand.id == id)
  return brand
}

suggest($event) {
  this.scrollFlag = true;
  this.byScroll = true;
  console.log("byScroll 7", this.byScroll)
  let inputValue = $event.target.value.toLowerCase();
  const startsWithMatches = this.brandList.filter(brand =>
    brand.BrandName && brand.BrandName.toLowerCase().startsWith(inputValue)
  );

  const includesMatches = this.brandList.filter(brand =>
    brand.BrandName && brand.BrandName.toLowerCase().includes(inputValue)
  ).filter(brand => !startsWithMatches.includes(brand));
  this.filterBrandListNew = startsWithMatches.slice(0, 10).concat(includesMatches.slice(0, 10));

}

blankplaceHolder() {
  this.brandNameSelected = ''
}

}