import { Component, Input, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AnyNsRecord } from 'dns';
import { MatDialog } from '@angular/material/dialog';
import { CommonService } from 'src/app/services/common.service';
import { doc, updateDoc, deleteField } from "firebase/firestore";
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { environment } from 'src/environments/environment';
import { ApiHttpService } from 'src/app/services/api-http.service';
import { ActivatedRoute, Router } from '@angular/router';
import { nextTick } from 'process';

@Component({
  selector: 'app-user-view-files',
  templateUrl: './user-view-files.component.html',
  styleUrls: ['./user-view-files.component.css']
})
export class UserViewFilesComponent implements OnInit {
  userFiles = this.firestore.collection("userfiles");
  userFileStateChange = this.userFiles.valueChanges();

  userUploadedAvatars: any = [];
  gltfImgSrc = "";
  UserId: any;
  isLoading = false;
  errorMessage: string = "";
  displayProgressSpinner = false;
  spinnerWithoutBackdrop = false;

  userfile: any;
  loading = false;
  userId: any

  constructor(private apiHttpService: ApiHttpService, private router: Router, private route: ActivatedRoute, private storage: AngularFireStorage, public dialog: MatDialog, public commonService: CommonService, private firestore: AngularFirestore) {
  }
  ngOnInit(): void {
    this.errorMessage = ""
    this.isLoading = true;
    this.route.paramMap.subscribe(paramMap => {
      this.userId = paramMap.get('id');
    });

   
this.viewFile()
  }
viewFile(){
  this.userFileStateChange.subscribe(async (object) => {
    this.userUploadedAvatars = []
            object.forEach(async (userFilesObject: any) => {
         let tmpUserFilesObject = userFilesObject
      if (this.userId != undefined && this.userId !== tmpUserFilesObject['userId']) {
        return
      }
      this.isLoading = true;
      let url = environment.functionBaseUrl + "userProfileIdenifierById?id=" + tmpUserFilesObject['userId'];
      await this.apiHttpService.get(url).subscribe((data: any) => {
        tmpUserFilesObject['UserProfileName'] = data.displayName != undefined ? data.displayName : tmpUserFilesObject['userId'];
        this.isLoading = false;
      },
        ((error) => {
          console.log(error);
        }
        ));



      this.userUploadedAvatars.push(tmpUserFilesObject);
    });
    this.isLoading = false;
  });
}

  deleteUserfiles(userFileId: any, fileType: string) {
    var confirmation = confirm('Are you sure to delete this file ?');
    if (!confirmation) return;
    this.userFiles.ref.where("id", "==", userFileId)
      .get()
      .then((objectList) => {
        objectList.forEach((doc) => {
          let docid = doc.id
          let userFilePath = doc.data()['filepath'];
          this.storage.ref(userFilePath).delete();
          if (fileType === 'Environment') {
            let environmentId = doc.data()['link'];
            this.firestore.collection("Environments").doc(environmentId).delete().then(() => {
            }).catch((error) => {
              console.error("Error removing document: ", error);
            });;
          }
          this.userFiles.doc(docid).delete().then(() => {
          }).catch((error) => {
            console.error("Error removing document: ", error);
          });;
        });
      })
  }
  openModel(gltfImgSrc: string) {
    const dialogRef = this.dialog.open(UserViewFilePopUP);
    this.gltfImgSrc = gltfImgSrc
    dialogRef.componentInstance.gltfImgSrc = this.gltfImgSrc;
    dialogRef.afterClosed().subscribe(result => {
      this.isLoading = false;
    });
  }
}



@Component({
  selector: 'avatar-upload-popup',
  templateUrl: './user-view-file-popup.html',
})
export class UserViewFilePopUP {
  @Input() gltfImgSrc: string | undefined;
  constructor(public dialog: MatDialog) {

  }


}
