import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';
import { Brand } from 'src/app/models/brand';
import { MatDialog } from '@angular/material/dialog';
import { CommonService } from 'src/app/services/common.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DataSharingService } from 'src/app/services/data-sharing-service';
import { PinterestService } from 'src/app/services/pinterest-service';
import { environment } from 'src/environments/environment';
import { ApiHttpService } from 'src/app/services/api-http.service';

@Component({
  selector: 'app-brand-stories',
  templateUrl: './brand-stories.component.html',
  styleUrls: ['./brand-stories.component.css']
})

export class BrandStoriesComponent implements OnInit {
  routLogInFlag :boolean = true
  brands = this.firestore.collection('brands').valueChanges({ idField: 'id' }) as Observable<Brand[]>;
  garmentsList = this.firestore.collection('garments').valueChanges({ idField: 'id' }) as Observable<any[]>;
  brandsBoardCollections = this.firestore.collection('brand-board').valueChanges({ idField: 'id' }) as Observable<Brand[]>;
  userFiles = this.firestore.collection("userfiles");
  userFileStateChange = this.userFiles.valueChanges();
  brandBoardList = this.firestore.collection('brand-board').valueChanges({ idField: 'id' }) as Observable<any[]>;
  SnapShotsList = this.firestore.collection('SnapshotDetails').valueChanges({ idField: 'id' }) as Observable<any[]>;
  brandsCollection = this.firestore.collection('brands').valueChanges({ idField: 'id' }) as Observable<Brand[]>;
  brandsCollections = this.firestore.collection('brands').valueChanges({ idField: 'id' }) as Observable<Brand[]>;
  displayedColumns: string[] = ['position', 'name', 'weight', 'symbol'];
  public text = `{ "text": "This is text file!中文" }`;
  public fileName: string | undefined;


  boardDetails: any
  selectedBrand: any = {};
  getZipcollection: any;
  zipfile: any;
  loading = false;
  brandDetails: any = []
  brandDetailsForStories: any = []
  snapDetails: any = []
  garmentsCollection: any = []
  brandId: any
  brandGarmentIds: any = [];
  SnapShotFileDetails = []
  BrandDetails = []
  snapShotBrandList = [];
  filterStories = []
  storyId: any
  userId: any
  brandIds: any
  boards: any = []
  brandBoard: any = []
  boardNameInBrandStory: any
  brandDetailsId: any = {}
  showBrandName: boolean = false
  userSnapShotList: any = []
  SnapShotDetails: any = []
  // selectedTab:any = "news-stories"
  selectedTab: any = "story"
  brandsIDdetailsAll: any = {}
  genderID: any
  rootFromBrandFlag:any ='yologram-social/yologram-user-profile-feed'
  brandFlag:any ='yologram-social/yologram-user-profile-feed'



  onChangeFlag = true;
  //New

  constructor(private route: ActivatedRoute, private pinterestAPI: PinterestService,private elementRef: ElementRef, private apiHttpService: ApiHttpService,
    private firestore: AngularFirestore, private dataSharingService: DataSharingService, public commonService: CommonService, private router: Router) {
    this.route.paramMap.subscribe(paramMap => {
      this.brandId = paramMap.get('brandId')
      this.userId = paramMap.get('id')
      this.storyId = paramMap.get('storyId')
    });



  }



  ngOnChanges() {
  }

  ngAfterViewInit() {
    this.elementRef.nativeElement.window
        .body.style.backgroundColor = '#2d2d2d';
}
  ngOnInit() {
    this.pinterestAPI.getBoardList().subscribe((data) => {
      this.boards = data.items;
      this.brandsBoardCollections.subscribe((brandBoard) => {
        this.brandBoard = brandBoard;
        if (this.garmentsCollection.length >= 0) this.selectBrandForStory(this.brandId, this.brands)
      });
      this.commonService.snapshotfileDetais().subscribe(async (snapshot: any) => {
        this.SnapShotDetails = snapshot

      })

    })
    this.snapShotInit()

    this.brandCollecton()
    // this.checkPageAuthorization()

    this.garmentsList.subscribe((grList) => {
      this.garmentsCollection = grList
      if (this.brandId != undefined) { this.selectBrandForStory(this.brandId, this.brands) }
      this.brands.subscribe((brandList) => {
        this.brandDetails = brandList
        let object = this.brandDetails
        this.brandDetailsId = object.find((bBoard) => bBoard.id == this.brandId)
        if (this.brandId != undefined) {
          this.selectedBrand = this.brandDetailsId;
          this.boardDetails = this.boardNameInBrandStory
          let brandBoardFilter = this.brandBoard.find((bBoard) => bBoard.brandId == this.brandId)
          this.boardDetails = (brandBoardFilter != undefined) ? this.boards.find((board) => board.id == brandBoardFilter.boardId) : undefined;
        }
      })


      let garmentIds = this.garmentsCollection.map((garment) => garment.id);
      let garmentsObject = this.garmentsCollection.reduce((acc, obj) => {
        const key = obj.id;
        const curGroup = acc[key] ?? [];
        return { ...acc, [key]: obj.BrandID };
      }, {});
      this.commonService.snapshotfileDetais().subscribe(async (snapshot: any) => {
        this.SnapShotFileDetails = snapshot;
        this.SnapShotFileDetails.forEach((snapshot) => {
          if (garmentsObject[snapshot.HeadwearID] != undefined
            || garmentsObject[snapshot.BottomwearID] != undefined
            || garmentsObject[snapshot.UpperwearID] != undefined
            || garmentsObject[snapshot.FootwearID] != undefined
          )
            if (garmentsObject[snapshot.HeadwearID] != undefined && this.snapShotBrandList.indexOf(garmentsObject[snapshot.FootwearID]) == -1) this.snapShotBrandList.push(garmentsObject[snapshot.FootwearID]);
          if (garmentsObject[snapshot.BottomwearID] != undefined && this.snapShotBrandList.indexOf(garmentsObject[snapshot.BottomwearID]) == -1) this.snapShotBrandList.push(garmentsObject[snapshot.BottomwearID]);
          if (garmentsObject[snapshot.UpperwearID] != undefined && this.snapShotBrandList.indexOf(garmentsObject[snapshot.UpperwearID]) == -1) this.snapShotBrandList.push(garmentsObject[snapshot.UpperwearID]);
          if (garmentsObject[snapshot.FootwearID] != undefined && this.snapShotBrandList.indexOf(garmentsObject[snapshot.FootwearID]) == -1) this.snapShotBrandList.push(garmentsObject[snapshot.FootwearID]);
        })
      })
    })
  }



  getBrandImage(imgURL: string) {
    let brandEncodedurl = encodeURI(imgURL).replace('/', '%2F');
    let url = "https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/" + brandEncodedurl + "?alt=media&token=550e7e88-bed3-4668-84ff-ad491c19e06c"
    return url;
  }
  showBrandDetails(brand: any) {
    this.selectedBrand = brand;
  }



  selectBrandForStory(brandId: any, brands: any) {
    if (this.brandId == brandId) {
      this.selectedTab = "news-stories"
    } else {
      this.selectedTab = "story"
    }
    this.brandsIDdetailsAll = brandId
    this.brandGarmentIds = [];
    this.onChangeFlag = true;
    let brandGarments = this.garmentsCollection.filter((gaementList) => gaementList.BrandID === brandId);
    this.brandGarmentIds = brandGarments.map((garment) => garment.id);
    this.BrandDetails = brandGarments
    this.selectedBrand = this.brandDetails.find((brand) => brand.id == brandId);
    this.brandIds = brandId
    let brandBoardFilter = this.brandBoard.find((bBoard) => bBoard.brandId == brandId)
    this.boardDetails = (brandBoardFilter != undefined) ? this.boards.find((board) => board.id == brandBoardFilter.boardId) : undefined;
    let selectedStories = this.SnapShotDetails.filter((snap) => snap.BottomwearID || snap.BottomwearID || snap.BottomwearID || snap.BottomwearID == this.BrandDetails['id'])
  }

  selectBrandForScroll() {
    document.querySelector('#target').scrollIntoView({ behavior: 'smooth', block: 'center' });
  }

  brandCollecton() {
    let brandsNID = this.brandDetails.filter((nameOfBrand) => nameOfBrand.id === this.garmentsCollection.filter((brandOfGArment) => brandOfGArment.BrandID))
  }

  snapShotInit() {
    this.commonService.snapshotfileDetais().subscribe(async (snapshot: any) => {
      this.SnapShotDetails = snapshot
    })
  }

  setTab(tab) {
    this.selectedTab = "";
    this.selectedTab = tab;
  }

  changeTab(event) {
    this.onChangeFlag = false;
    this.selectedTab = event;
  }

}
