<!-- <div class="text-danger text-center" *ngIf="errorMessage != ''">
    {{errorMessage}}
</div> -->
<app-yologram-social *ngIf="logInFlag"></app-yologram-social>
<div *ngIf="!loading" class="card ">
  <b class="m-2"> Total Uploaded Files : {{userUploadedAvatars.length}}</b>
  <div class="dt-responsive selectlist font-14">
    <div class="dataTables_wrapper dt-bootstrap4" id="add-row-table_wrapper">
      <div *ngIf="userUploadedAvatars && userUploadedAvatars?.length == 0 || !userUploadedAvatars" class="p-3">
        <div class="alert alert-danger text-center" role="alert">
          <b>No File Found.</b>
        </div>
      </div>
      <div class="col-sm-12 p-0" *ngIf="userUploadedAvatars != undefined && userUploadedAvatars.length > 0">
        <table class="table" id="dataTable" width="100%" cellspacing="0">
          <thead class="custom-header">
            <tr role="row">
              <th>Profile Name</th>
              <th>File Name</th>
              <th>File Type</th>
              <th>Date</th>
              <th>View</th>
              <th *ngIf="this.userId == null"> Delete </th>
            </tr>
          </thead>
          <tbody>
            <tr class="row-list-detail" *ngFor="let list of userUploadedAvatars; let i = index">
              <td class="">
                <span class="user-info text-underline-offset: 3px;">{{list.UserProfileName}}
                  <div class="d-flex justify-content-center">
                    <div class="dots-bars-2" *ngIf="isLoading" role="status">
                      <span class=""></span>
                    </div>
                  </div>
                </span>
              </td>
              <td class="">
                <span class="user-info text-underline-offset: 3px;">{{list.filename}}</span>
              </td>
              <td class="">
                <span class="user-info text-underline-offset: 3px;">{{list.fileType}}</span>
              </td>
              <td class="">
                <span class="user-info">{{list.date}}</span>

              </td>

              <td class="catalog-info ">
                <span *ngIf="list.fileType == 'ClothSource'">
                  <a (click)="openModel(list.fileurl)"> <ins><i class="bi bi-eye-fill"></i></ins></a>
                </span>
                <span *ngIf="list.fileType == 'GarmentUpload2D'">
                  <a href="{{list.fileurl}}" target="_blank"><ins class="text-dark"><i
                        class="bi bi-eye-fill"></i></ins></a>
                </span>
                <span *ngIf="list.fileType == 'UserUploads'">
                  <a href="{{list.fileurl}}" target="_blank"><ins class="text-dark"><i
                        class="bi bi-eye-fill"></i></ins></a>
                </span>
                <span *ngIf="list.fileType == 'GarmentUpload3D'">
                  <a (click)="openModel(list.fileurl)"> <ins><i class="bi bi-eye-fill"></i></ins></a>
                </span>
                <span *ngIf="list.fileType == 'Environment'">
                  <a href="{{list.fileurl}}" target="_blank"><ins class="text-dark"><i
                        class="bi bi-eye-fill"></i></ins></a>
                </span>

              </td>
              <td>
                <button type="button"
                  *ngIf="this.userId == null && list.fileType != 'GarmentUpload2D' &&  list.fileType != 'UserUploads'"
                  (click)="deleteUserfiles(list.id,list.fileType)">
                  <span class=""></span> <i class="bi bi-trash-fill"></i></button>
              
                <button type="button" *ngIf="this.userId == null &&  list.fileType == 'GarmentUpload2D'"
                  (click)="delete2DFile(list,list.filepath)">
                  <span class=""></span> <i class="bi bi-trash-fill"></i></button>
              
                <button type="button" *ngIf="this.userId == null &&  list.fileType == 'UserUploads'"
                  (click)="deleteUserUploadsFile(list.userId,list.fileType,list.link)">
                  <span class=""></span> <i class="bi bi-trash-fill"></i></button>
              
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

</div>
<div class="d-flex justify-content-center m-5">
  <div class="dots-bars-2 m-6" *ngIf="isLoading" role="status">
    <span class=""></span>
  </div>
</div>

<div *ngIf="loading" class="loading">Loading&#8230;</div>