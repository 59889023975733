import { DatePipe } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/compat/storage';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { CaraosalPopupComponent } from 'src/app/common/templets/caraosal-popup/caraosal-popup.component';
import { ApiHttpService } from 'src/app/services/api-http.service';
import { CommonService } from 'src/app/services/common.service';
import { DataSharingService } from 'src/app/services/data-sharing-service';
import { environment } from 'src/environments/environment';
import { generateUUID } from 'three/src/math/MathUtils';

@Component({
  selector: 'app-user-profile-feed',
  templateUrl: './user-profile-feed.component.html',
  styleUrls: ['./user-profile-feed.component.css']
})
export class UserProfileFeedComponent implements OnInit {
  @ViewChild('userFeeds', { read: ElementRef }) public userFeeds: ElementRef<any>;

//   @ViewChild('pinModel', { static: false }) pinModal: ElementRef<HTMLInputElement> = {} as ElementRef;
//   @ViewChild("snapshotfeeds") snapShotFeeds?: ElementRef;


//   brandsFilesList = this.firestore.collection('brands').valueChanges({ idField: 'id' }) as Observable<Brand[]>;
//   brandBoardList = this.firestore.collection('brand-board').valueChanges({ idField: 'id' }) as Observable<any[]>;
//   environmentCollection = this.firestore.collection('Environments').valueChanges({ idField: 'id' }) as Observable<Brand[]>;
//   // snapshotFilesList = this.firestore.collection('SnapshotDetails',
//   //   ref => {
//   //     return ref.orderBy('DateTime', 'desc');
//   //   }).valueChanges({ idField: 'id' }) as Observable<Snapshot[]>;
//   userFilesList = this.firestore.collection('userfiles').valueChanges({ idField: 'id' }) as Observable<any[]>;
//   pinterestBoardPinsStatus = this.firestore.collection('pinterest-board-pins-status').valueChanges({ idField: 'id' }) as Observable<Snapshot[]>;
//   garmentsList = this.firestore.collection('garments').valueChanges({ idField: 'id' }) as Observable<any[]>;
//   brandBoardCollection = this.firestore.collection('brand-board').valueChanges({ idField: 'id' }) as Observable<any[]>;
//   profileDetailsAll:any={}
//   color = 'primary';
//   mode = 'indeterminate';
//   value = 50;
//   selectedAvtar:any
//   displayProgressSpinner = false;
//   spinnerWithoutBackdrop = false;
//   offsetPrevious = 0;
//   scrolling = 'down';
//   scrollingWidth = 50;
//   indexMapping = 0;
//   scrollPixel = 0;
//   delayFlag = false
//   private timeout: any;
//   snapshotCollection: any = [];
//   garmentsCollection: any = [];
//   brandboardCollection: any = [];
//   brandsCollection: any = [];
//   storyPinterestPinsList: any = [];
//   subscription: Subscription | undefined;
//   scrollselected: any = [];
//   identifierList: any = {};
//   slides: any[] = new Array(3).fill({ id: -1, src: '', title: '', subtitle: '' });
//   clothDetails: any = {};
//   selectedBrand: any = {};
//   brandDetails: any;
//   brands: any = [];
//   boardId: any;
//   boards: any;
//   pinImg: string = "";
//   snapShotFileList: any = []
//   brandShapShotsList: any = []
//   filterSnapShotFileDetails: any = []
//   isLoading = false;
//   topScroll: number;
//   idDetails: any;
//   deleteFalse: boolean = false;
//   clothShapShotsList:any=[]
//   clothShapShotsListALL :any=[]
//   pindetails: any = [];
//   pinterstJsonMap = {
//     "StoryID": undefined,
//     "upperwear": undefined,
//     "footwear": undefined,
//     "headwear": undefined
//   }

//   imageList: any = this.pinImg;
//   brandId: any;
//   StoryID: any;
//   UpperwearId: any;
//   pinId: any;
//   loading: boolean = false;
//   typeSelected: any;
//   boardsName: any;
//   filterByUserFlag: boolean = false;
//   userId: string;
//   storyId: any
//   pageScrollFlag: boolean = false;
//   id: any
//   userSnapShotList = []
//   brandGarments = []
//   brandCollectionId: any = []
//   brandStoriesImgLimit: number = 0;
//   brandCollectionIdcollection: any = []
//   clocthCollectionIdcollection:any =[]
//   profileDetails:any={}
//   filterSnapShotForProfile:any=[]
//   selectSnap: any;
//   filterEnvironmentSnpShot:any=[]
//   enViromentName:any
//   evnFileList: any = []
//   evnFileListFileName : any
//   userUploadedFileList: any = [];
//   userFiledetails: any = [];
//   counter: number = 4;
//   showImages:any={}
//   avatarId:any
//   userDetails:any={}




//   //  identifierDetail

//   constructor(public dialog: MatDialog,
//     private firestore: AngularFirestore,
//     private commonService: CommonService,
//     private apiHttpService: ApiHttpService,
//     private pinterestAPI: PinterestService,
//     private route: ActivatedRoute,
//     public router: Router,
//     private dataSharingService: DataSharingService,
//     private storage: AngularFireStorage
//   ) {

//     this.typeSelected = 'ball-fussion';
//     this.brandBoardList.subscribe(
//       brandboard => {
//         this.brandboardCollection = brandboard;
//       });


//       this.route.paramMap.subscribe(paramMap => {
//         this.userId = paramMap.get('id');
//         this.avatarId = paramMap.get('avatarID');
//         let url = environment.functionBaseUrl + "userProfileIdenifierById?id=" + this.userId;
//         this.apiHttpService.get(url).subscribe((data: any) => {
//           this.userDetails = data
//           // this.isLoadingforUserDetails = false
//         })
//       });
//       this.dataSharingService.userSnapShotObservable.subscribe(userSnapShots => {
//         this.userSnapShotList  = []
//         this.userSnapShotList = userSnapShots
//         // this.userSnapShotList.forEach(async (snap: any, index) => {
//         //   if (this.storyId != undefined && this.storyId === snap.StoryID) {
//         //     this.topScroll = index * 670;
//         //   }  });
//       });
//     this.garmentsList.subscribe(
//       garments => {
//         this.garmentsCollection = garments;
//       });


//     this.brandsFilesList.subscribe((brands) => {
//       this.brandsCollection = brands;
//     });

//     this.pinterestBoardPinsStatus.subscribe(
//       response => {
//         this.storyPinterestPinsList = response
//       });
//       this.environmentCollection.subscribe(
//         (evnFile) => {
//           this.evnFileList = evnFile
//         });


//         this.userFilesList.subscribe((userFile) => {
//           this.userUploadedFileList = userFile
//           let clothId = this.clothDetails.id
//           let filelist = this.userUploadedFileList.filter((uploadlist) => uploadlist.link == clothId).slice(0,2)
//           this.userFiledetails = filelist
//         })
        
//   }

//   ngOnInit(): void {
//     this.route.paramMap.subscribe(paramMap => {
//       this.userId = paramMap.get('id');
//       this.storyId = paramMap.get('storyId');
//       this.snapShotInit();
//     });

//     this.slides[0] = {
//       src: 'snapshot.SnapshotFileUrl',
//     };
//     this.getPinterestBoards();
//     this.displayProgressSpinner = true;

//   }

//   snapShotInit() {
//     this.loading = true
//     this.commonService.getUser().subscribe((user: any) => {
//       this.id = user?.uid;
//     })

//     this.commonService.snapshotfileDetais().subscribe(async (snapshot: any) => {
//       this.snapShotFileList = snapshot
//       var userSnapShotList;
//       if (this.userId != undefined && this.userId != null) {
//         userSnapShotList = snapshot.filter((snapshotObject) => snapshotObject.UserID == this.userId);
//       } else {
//         userSnapShotList = snapshot;
//       }
//       this.userSnapShotList.forEach(async (snap: any, index) => {
//         if (this.storyId != undefined && this.storyId === snap.StoryID) {
//           this.topScroll = index * 670;
//         }
//         let url = environment.functionBaseUrl + "userProfileIdenifierById?id=" + snap.UserID;

//         await this.apiHttpService.get(url).subscribe((data: any) => {
//           //this.profileDetails = data
//           snap['profileName'] = data.displayName != undefined ? data.displayName : snap.UserID;
//           this.displayProgressSpinner = false;
//           snap.firstName =  snap.profileName.split(" ", 1); 
//           snap.AvatarLastFive =  snap.AvatarID.substr(snap.AvatarID.length - 5);
          
//         },
//           ((error) => {
//             console.log(error);
//           }
//           ));
//       });

//       this.filterSnapShotFileDetails = userSnapShotList
//       setTimeout(() => {
//         this.loading = false
//         this.delayFlag = true;
//       }, 1000);

//     });

//   }

//   getSnapShotList() {
//     this.filterByUserFlag = false;
//     this.filterSnapShotFileDetails = this.snapShotFileList;
//   }




//   getSnapShotListByUser(snapshot: any) {
//     let userId = snapshot;
//     this.filterByUserFlag = true
//     this.filterSnapShotFileDetails = this.snapShotFileList.filter((snapshot) => snapshot.UserID == userId);
//     this.dataSharingService.sendUserSnapShots(this.filterSnapShotFileDetails);
//     this.router.navigate(['yologram-social/yologram-public-news-feed', { 'id': userId }])
//   }


//   getSnapShotListByUserPopup(snapshot: any) {
//     this.selectSnap = snapshot
//     let userId = snapshot.UserID;
//      let url = environment.functionBaseUrl + "userProfileIdenifierById?id=" + userId;
//          this.apiHttpService.get(url).subscribe((data: any) => {
//           this.profileDetails = data
//         })
//     this.filterByUserFlag = true
//     this.filterSnapShotForProfile = this.snapShotFileList.filter((snapshot) => snapshot.UserID == userId).slice(0,3);
//     this.dataSharingService.sendUserSnapShots(this.filterSnapShotForProfile);
//   }

//   getSnapShotListAvatarPopup(snapshot: any) {
//     let userId = snapshot.UserID;
//     let avatarId =  snapshot.AvatarID
//     this.selectedAvtar = avatarId
//      let url = environment.functionBaseUrl + "userProfileIdenifierById?id=" + userId;
//          this.apiHttpService.get(url).subscribe((data: any) => {
//           this.profileDetails = data
//         })
//     this.filterByUserFlag = true
//     this.filterSnapShotForProfile = this.snapShotFileList.filter((snapshot) => snapshot.AvatarID == avatarId).slice(0,9);
//     this.dataSharingService.sendUserSnapShots(this.filterSnapShotForProfile);
//   }




//   getuserfeed(uid){
//     this.filterSnapShotFileDetails = this.snapShotFileList.filter((snapshot) => snapshot.UserID == uid)
//   }
  
//   getuserfeeduserPopUp(uid){
//     this.filterSnapShotFileDetails = this.snapShotFileList.filter((snapshot) => snapshot.UserID == uid.UserID)
//   }


//   getAvatarfeed(avatar){
//     this.filterSnapShotFileDetails = this.snapShotFileList.filter((snapshot) => snapshot.AvatarID == avatar)
//   }


  
//   getProfileByAvator(snapshot: any) {
//     this.filterByUserFlag = true;
//     this.filterSnapShotFileDetails = this.snapShotFileList.filter((snapshotlist) => snapshotlist.AvatarID == snapshot);
//     const userId =  this.filterSnapShotFileDetails.find((snapshotlist)=> snapshotlist.UserID )
//     this.dataSharingService.sendUserSnapShots(this.filterSnapShotFileDetails);
//     this.router.navigate(['yologram-social/yologram-public-news-feed', { 'id': userId.UserID, 'avatarID': snapshot }])
//   }


//   getEnvironmentName(environment){
//     let evenListName = "Environments/"
//     let env = this.evnFileList.find((snap) => snap.storageURL == evenListName+environment)
//     return env != undefined ? env['displayName'] : 'default';

//   }

//   environmentPopup(environment) {
//     let evenListName = "Environments/"
//     this.evnFileListFileName = this.evnFileList.find((snap) => snap.storageURL == evenListName+environment)
//     let environmentSnapAllfile = this.snapShotFileList.filter((snap) => snap.Environment === environment).slice(0,9)
//     this.filterEnvironmentSnpShot = environmentSnapAllfile
//     this.evnFileListFileName['envFileName'] = environment

//   }

//   getEnvironmentProfile(envFileName) {
//     let evenListName = "Environments/"
//     this.evnFileListFileName = this.evnFileList.find((snap) => snap.storageURL == evenListName+envFileName)
//     let environmentSnapAllfile = this.snapShotFileList.filter((snap) => snap.Environment === envFileName)
//     this.dataSharingService.sendUserSnapShots(environmentSnapAllfile)
//     this.router.navigate(['yologram-social/yologram-env-profile', { 'evnFile': this.evnFileListFileName.displayName }])
//   }

//   onItemChange($event: any): void {
//   }

//   handleError(error: any) {
//     console.log(error)
//   }
//   setClothDetails(garmentId: string) {
//     if (this.garmentsCollection.length > 0) {
//       this.clothDetails = this.garmentsCollection.find((b: any) => b.id === garmentId);
//       let brandObject = this.brandsCollection.find((b: any) => b.id === this.clothDetails.BrandID);
//       this.clothDetails['BrandName'] = brandObject != undefined ? brandObject.BrandName : '';
//       this.clothDetails['LogoURL'] = brandObject != undefined ? brandObject.LogoURL : '';
//       let brandGarments = this.garmentsCollection.filter((gaementList) => gaementList.id == this.clothDetails.id );
//       this.clocthCollectionIdcollection = brandGarments.map((garment) => garment.id);
//       this.clothShapShotsList = this.snapShotFileList.filter((snapshot) => {
//         return this.clocthCollectionIdcollection.indexOf(snapshot.BottomwearID) >= 0 ||
//           this.clocthCollectionIdcollection.indexOf(snapshot.HeadwearID.trim()) >= 0 ||
//           this.clocthCollectionIdcollection.indexOf(snapshot.FootwearID.trim()) >= 0 ||
//           this.clocthCollectionIdcollection.indexOf(snapshot.UpperwearID.trim()) >= 0
//         }).slice(0, 9);

//       this.clothShapShotsListALL = this.snapShotFileList.filter((snapshot) => {
//         return this.clocthCollectionIdcollection.indexOf(snapshot.BottomwearID) >= 0 ||
//           this.clocthCollectionIdcollection.indexOf(snapshot.HeadwearID.trim()) >= 0 ||
//           this.clocthCollectionIdcollection.indexOf(snapshot.FootwearID.trim()) >= 0 ||
//           this.clocthCollectionIdcollection.indexOf(snapshot.UpperwearID.trim()) >= 0
//         })
//         let clothId = this.clothDetails.id
//         let filelist = this.userUploadedFileList.filter((uploadlist) => uploadlist.link == clothId).slice(0,2)
//         this.userFiledetails = filelist
//     }
//   }

//   setBrandDetails(garmentId: string) {
//     if (this.garmentsCollection.length > 0) {
//       let grandmentObject = this.garmentsCollection.find((b: any) => b.id === garmentId);
//       let brandGarments = this.garmentsCollection.filter((gaementList) => gaementList.BrandID == grandmentObject.BrandID);
//       this.brandCollectionIdcollection = brandGarments.map((garment) => garment.id);
//       this.brandShapShotsList = this.snapShotFileList.filter((snapshot) => {
//         return this.brandCollectionIdcollection.indexOf(snapshot.BottomwearID.trim()) >= 0 ||
//           this.brandCollectionIdcollection.indexOf(snapshot.HeadwearID.trim()) >= 0 ||
//           this.brandCollectionIdcollection.indexOf(snapshot.FootwearID.trim()) >= 0 ||
//           this.brandCollectionIdcollection.indexOf(snapshot.UpperwearID.trim()) >= 0
//         }).slice(0, 9);
//         if (grandmentObject && grandmentObject['Name'] != undefined) {
//           let brandId = grandmentObject["BrandID"];
//           this.brandDetails = this.brandsCollection.find((b: any) => b.id === brandId);
//           this.brandDetails['boardName'] = 'Not Link to Board';
//           let brandBoardObject = this.brandboardCollection.find((b: any) => b.brandId == brandId);
//           if (brandBoardObject != undefined) {
//             let boardId = brandBoardObject && brandBoardObject['boardId'] != undefined ? brandBoardObject['boardId'] : undefined;
//             let boardDetails = this.boards.find((b: any) => b.id == boardId);
//             if(boardDetails !=undefined && boardDetails['name'] != undefined )this.brandDetails['boardName'] = boardDetails['name'];
//           }
//         }
//     }
//   }

//   getGarmentsDetails(garmentId: string) {
//     let garmentTitle = "";
//     let name = garmentId;
//     let brandName = ""
//     if (this.garmentsCollection.length > 0) {

//       let grandmentObject = this.garmentsCollection.find((b: any) => b.id === garmentId);

//       //   return 
//       if (grandmentObject && grandmentObject['Name'] != undefined) {
//         name = grandmentObject['Name'];
//         let brandId = grandmentObject["BrandID"];
//         let brandObject = this.brandsCollection.find((b: any) => b.id === brandId);
//         brandName = brandObject != undefined && brandObject.BrandName != undefined ? brandObject.BrandName : '';
//       }
//     }
//     garmentTitle = "<a (click)=\"showBrand('12232')\" href=\"showBrand('12232')\" />" + name + "</a>";
//     // if(brandName != " ") garmentTitle += "   ( <a data-bs-toggle='modal' data-bs-target='#brand-details' >"+ brandName + " <a/>)";
//     return garmentTitle;
//   }

  
//   getGarmentrealtype(garmentId: string) {
//     let garmentTitle = "";
//     let name = garmentId;
//     let brandName = ""
//     if (this.garmentsCollection.length > 0) {
//       let grandmentObject = this.garmentsCollection.find((b: any) => b.id === garmentId);
//       if (grandmentObject && grandmentObject['RealType'] != undefined) {
//         garmentTitle = grandmentObject['RealType'];
//       }
//     }
//     return garmentTitle;
//   }

//   getGarmentName(garmentId: string) {
//     let garmentTitle = "";
//     let name = garmentId;
//     let brandName = ""
//     if (this.garmentsCollection.length > 0) {
//       let grandmentObject = this.garmentsCollection.find((b: any) => b.id === garmentId);
//       if (grandmentObject && grandmentObject['Name'] != undefined) {
//         garmentTitle = grandmentObject['Name'];
//       }
//     }
//     return garmentTitle;
//   }

//   getBrandName(garmentId: string) {
//     let garmentTitle = "";
//     let name = garmentId;
//     let brandName = ""
//     if (this.garmentsCollection.length > 0) {
//       let grandmentObject = this.garmentsCollection.find((b: any) => b.id === garmentId);
//       if (grandmentObject && grandmentObject['Name'] != undefined) {
//         let brandId = grandmentObject["BrandID"];
//         let brandObject = this.brandsCollection.find((b: any) => b.id === brandId);
//         brandName = brandObject != undefined && brandObject.BrandName != undefined ? brandObject.BrandName : '';
//       }
//     }
//     return brandName;
//   }



//   showBrand(brand: any) {
//     this.selectedBrand = brand;
//   }

//   getBrandImage(imgURL: string) {
//     let brandEncodedurl = encodeURI(imgURL).replace('/', '%2F');
//     let url = "https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/" + brandEncodedurl + "?alt=media&token=550e7e88-bed3-4668-84ff-ad491c19e06c"
//     return url;
//   }
//   getPinterestBoards() {
//     this.pinterestAPI.getBoardList().subscribe((data: {
//       name: any; items: any;
//     }) => {
//       this.boards = data.items
//     });

//   }
//   selectedBoard($event: any) {
//     this.boardId = $event.target.value;
//   }

//   shareOnPinterest(garmentId: any, type: any, snapshotFileUrl: any, storyID: any) {
//     this.displayProgressSpinner = true;
//     // this.isLoading = true;
//     this.loading = true;
 


//     let brandId: any;
//     let boardId: any;

//     if (this.garmentsCollection.length > 0) {
//       let grandmentObject = this.garmentsCollection.find((b: any) => b.id === garmentId);
//       if (grandmentObject && grandmentObject['Name'] != undefined) {
//         brandId = grandmentObject["BrandID"];
//         let brandBoardObject = this.brandboardCollection.find((b: any) => b.brandId == brandId);
//         boardId = brandBoardObject && brandBoardObject['boardId'] != undefined ? brandBoardObject['boardId'] : undefined;
//       }

//     }

//     if (boardId == undefined || brandId == '') {
//       this.isLoading = false;

//       alert("This brand is not linked with Pinterest board.")
//       return false;
//     }

//     let jsonObject = {
//       'avatarURL': snapshotFileUrl,
//       'boardId': boardId
//     }
//     let jsonInput = this.pinterstJsonMap
//     let storyObject = this.storyPinterestPinsList.find((b: any) => b.StoryID == storyID)

//     if (storyObject && storyObject[type] != undefined) {
//       let jsonInpt = { 'pinId': storyObject[type], };

//       this.pinterestAPI.getPinterestPin(jsonInpt).subscribe((data) => {
//         this.displayProgressSpinner = false;
//         this.pinImg = data.media.images['400x300'].url;
//         this.openModel();
//         this.loading = false;
//       });
//       return true
//     }
//     this.pinterestAPI.createPinterestPin(jsonObject).subscribe((result) => {
//       if (storyObject == undefined) {
//         let pinsMappingObject: any = {
//           'StoryID': storyID,
//         }
//         if (type == 'headwear') pinsMappingObject['headwear'] = result.id
//         if (type == 'footwear') pinsMappingObject['footwear'] = result.id
//         if (type == 'upperwear') pinsMappingObject['upperwear'] = result.id
//         if (type == 'headwear') pinsMappingObject['headwear'] = result.id
//         this.firestore.collection('pinterest-board-pins-status').add(pinsMappingObject)
//       } else if (storyObject && storyObject[type] == undefined) {
//         if (type == 'headwear') storyObject['headwear'] = result.id
//         if (type == 'footwear') storyObject['footwear'] = result.id
//         if (type == 'upperwear') storyObject['upperwear'] = result.id
//         if (type == 'headwear') storyObject['headwear'] = result.id
//         this.firestore.collection('pinterest-board-pins-status').doc(storyObject['id']).update(storyObject);
//       }

//       this.isLoading = false;
//       this.loading = false;
//       alert("Pin published successfully.");
//       return true;
//     });

//     return false;
//   }



//   openModel() {
//     this.isLoading = false;
//     const dialogRef = this.dialog.open(UserNewsModelDilog);
//     dialogRef.componentInstance.pinImg = this.pinImg;
//     dialogRef.afterClosed().subscribe(result => {
//       this.isLoading = false;
//     });
//   }

//   linkBrandToBoard() {

//     if (this.boardId == "" || this.brandId == '') {
//       return false;
//     }
//     let brandBoardMappingObject = {
//       'brandId': this.brandId,
//       'boardId': this.boardId
//     }
//     this.firestore.collection('brand-board').add(brandBoardMappingObject)
//     return true;
//   }

//   ngAfterViewChecked(): void {
//     if (this.storyId != undefined && this.snapShotFeeds != undefined && this.snapShotFeeds.nativeElement != undefined && !this.pageScrollFlag) {
//       document.getElementById(this.storyId)
//       this.snapShotFeeds.nativeElement.scrollTop = this.topScroll;
//       this.pageScrollFlag = true
//     }
//   }


//   deleteSnapshotFile(id: any) {
//     var confirmation = confirm('Are you sure to delete this file ?');
//     if (!confirmation) return;
//     this.isLoading = true;
//     this.firestore.collection("SnapshotDetails").doc(id).delete().then(() => {
//       let filepath = "Snapshots/" + id
//       this.storage.ref(filepath).delete();
//       this.snapShotInit();
//       this.isLoading = false;
//     }).catch((error) => {
//     });;
//   }

//   // getBrandStories(brand:any){
//   //   this.router.navigate(['yologram-social/yologram-brand-stories',{'brandId':brand}])
//   //

//   getBrandStories(brand: any) {
//     this.router.navigate(['yologram-social/yologram-brand-stories', { 'brandId': brand.id,}])
//   }

//   getBrandProfile(brand: any, board: any) {
//     let brandGarments = this.garmentsCollection.filter((gaementList) => gaementList.BrandID === brand);
//     let brandGarmentIds = brandGarments.map((garment) => garment.id);
//     this.brandCollectionId = brandGarmentIds
//     let brandGarment=  this.brandShapShotsList = this.snapShotFileList.filter((snapshot) => {
//       return this.brandCollectionIdcollection.indexOf(snapshot.BottomwearID) >= 0 ||
//         this.brandCollectionIdcollection.indexOf(snapshot.HeadwearID.trim()) >= 0 ||
//         this.brandCollectionIdcollection.indexOf(snapshot.FootwearID.trim()) >= 0 ||
//         this.brandCollectionIdcollection.indexOf(snapshot.UpperwearID.trim()) >= 0
//     })
//     this.dataSharingService.sendUserSnapShots(brandGarment);
//     this.dataSharingService.sendBrandGarments(brandGarmentIds);
//     this.router.navigate(['yologram-social/yologram-brand-profile', { 'brandId': brand }])
//   }


  
//   getClothProfile(brand: any){
//     let clothAllDetails = this.clothDetails
//     let brandGarments = this.garmentsCollection.filter((gaementList) => gaementList.id == this.clothDetails.id );
//     this.clocthCollectionIdcollection = brandGarments.map((garment) => garment.id);
//     this.clothShapShotsList = this.snapShotFileList.filter((snapshot) => {
//       return this.clocthCollectionIdcollection.indexOf(snapshot.BottomwearID) >= 0 ||
//         this.clocthCollectionIdcollection.indexOf(snapshot.HeadwearID.trim()) >= 0 ||
//         this.clocthCollectionIdcollection.indexOf(snapshot.FootwearID.trim()) >= 0 ||
//         this.clocthCollectionIdcollection.indexOf(snapshot.UpperwearID.trim()) >= 0
//       }).slice(0, 9);
//       let snanpDetails = this.clothShapShotsList
//       this.dataSharingService.sendUserSnapShots(snanpDetails);
//       this.dataSharingService.sendBrandGarments(this.clocthCollectionIdcollection);
//       this.dataSharingService.sendClothDetails(clothAllDetails)
//       this.router.navigate(['yologram-social/yologram-garment-profile', { 'brandId': brand }])
//   }




//   checkImgLimit() {
//     this.brandStoriesImgLimit++;
//     return true;
//   }

//   getFeed(brand: any ,brandName:any){
//     this.router.navigate(['yologram-social/yologram-brand-stories', { 'brandId':brandName.id,'storyId':brand.StoryID }])
//     }
    
//     getUserFeedInPopUp(snapshot,brandName){

//     }


//     getClothFeed(brand: any ,brandName:any){
//       this.dataSharingService.sendUserSnapShots(this.clothShapShotsList)
//       this.router.navigate(['yologram-social/yologram-brand-stories', { 'brandId':brandName.id,'storyId':brand.StoryID}])
//       }
  
//     getBrandStyle(brandName:any,gender:any,){
//       // this.dataSharingService.sendUserSnapShots(this.clothShapShotsList);
//          this.dataSharingService.sendUserSnapShots(this.brandShapShotsList);
//       this.router.navigate(['yologram-social/yologram-brand-style', { 'brandId':brandName, 'Gender': gender  }])
//     }

//     getgarmentStyle(brandName:any,cloths:any){
//       this.dataSharingService.sendUserSnapShots(this.clothShapShotsList);
//       this.dataSharingService.sendClothDetails(cloths)
//       this.router.navigate(['yologram-social/yologram-garment-style', { 'brandId':brandName, 'clothsType': cloths.GarmentType ,'Gender':cloths.Gender,'id':cloths.id }])
//     }


//     getStoryCloth(brandIds) {
//       let snap = this.clothShapShotsList
//       this.dataSharingService.sendUserSnapShots(snap)
//       this.dataSharingService.sendClothDetails(brandIds)
//       let brandid = brandIds.BrandID
//       this.router.navigate(['yologram-social/yologram-garment-profile', { 'brandId': brandid, }])
//     }
    
//     getgarmentfeed(brandName: any, cloths: any) {
//       let snap = this.clothShapShotsListALL
//       this.dataSharingService.sendUserSnapShots(snap)
//       this.dataSharingService.sendClothDetails(cloths)
//       this.router.navigate(['yologram-social/yologram-garment-feed', { 'brandId': cloths.BrandID, 'clothsType': cloths.GarmentType, 'Gender': cloths.Gender, 'id': cloths.id }])
//     }
    
//   openImageBox(fileurl){
//     let url = fileurl
//     let showImage = this.userFiledetails.find((file)=> file.fileurl == url)
//     this.showImages = showImage
//    }

// }


// @Component({
//   selector: 'user-news-model-viewe',
//   templateUrl: './model-viewer.html',
// })
// export class UserNewsModelDilog {
//   @Input() pinImgSrc: string | undefined;
//   pinImg: string;
//   constructor(public dialog: MatDialog) {
//   }
// }

userFilesList = this.firestore.collection('userfiles').valueChanges({ idField: 'id' }) as Observable<any[]>;
  userDetailsList = this.firestore.collection('user-profile').valueChanges({ idField: 'id' }) as Observable<any[]>;

  task!: AngularFireUploadTask;
  percentage!: Observable<number | undefined>;
  currentlySelected;
  storyid: any
  userId: string;
  userSnapShotList: any;
  profileName: any
  userName: any = []
  userDetails: any
  userProfileData :any = {}
  filterSnapShotFileDetails: any = []
  SnapShotFileDetails: any = []
  id: any;
  avatarID: any;
  isLoadingforUserDetails = true
  snapShotFileList: any = []
  avatarIdshide: boolean = true
  hide: boolean = true
  usersplitted: any = []
  allUserSnapDetails: any = []
  filterSnapShotFileDetailsall: any = []
  profileDetails: any = {};
  UserList: any = {};
  userSnapShotDetailsCollection = {};
  counter: number = 0;
  allDetails: any = []
  pageNumber: any = 1
  allUserDetails: any = []
  userDetail: any = []
  filterSnap: any = []
  snaploader: boolean = true
  snaploader2D: boolean = true
  profileloader: boolean = true
  deleteflag: boolean = true
  avatar: any
  user: any
  file: any
  userData: any;
  pagecount: number;
  selectedStoryId: any;
  tab: string;
  avatarFlag: string;
  selectedTab: any
  deleteFlag: any
  userUploadedFileList: any = []
  filelist: any = []
  userFiledetails: any = []
  pageScroll:number;
  secteTab:any = "Story"
  scrollFlag:boolean =false

  images = [
    { "image": "./assets/user-upload-dummy/new-img-1.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-2.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-3.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-4.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-5.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-6.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-7.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-8.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-9.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-10.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-11.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-12.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-13.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-14.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-15.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-16.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-17.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-18.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-19.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-20.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-21.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-22.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-23.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-24.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-25.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-26.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-27.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-28.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-29.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-30.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-31.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-32.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-33.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-34.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-35.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-36.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-37.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-38.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-39.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-40.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-41.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-42.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-43.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-44.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-45.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-46.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-47.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-48.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-49.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-50.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-51.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-52.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-53.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-54.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-55.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-56.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-57.jpg" },
  ]
  uploadFileName: any;
  routLogInFlag:boolean = true
  rootForNAvigation :any  ='yologram-social/yologram-user-profile-feed'
  brandFlag:any ='/yologram-social/yologram-brand-stories'
  constructor(
    private dataSharingService: DataSharingService,
    private route: ActivatedRoute,
    private apiHttpService: ApiHttpService,
    public router: Router,
    private commonService: CommonService,
    private storage: AngularFireStorage,
    public datepipe: DatePipe,
    private db: AngularFirestore,
    private firestore: AngularFirestore,
    private modal: NgbModal,

  ) {
    this.hideAvatar()
    this.closePopUp() 


  }

  ngOnInit(): void {
    this.snaploader2D = true;
    setInterval(() => {
      this.snaploader2D = false;
    }, 2000);

    this.route.paramMap.subscribe(paramMap => {

      this.userId = paramMap.get('id');
      this.storyid = paramMap.get('storyId');
      this.avatarID = paramMap.get('avatarID');
      this.avatar = paramMap.get('avatar');
      this.user = paramMap.get('user');
      this.tab = paramMap.get('pagetab');
      this.avatarFlag = paramMap.get('avatarFlag');
      this.deleteFlag = paramMap.get('flag');
      this.profileloader = true
      this.selectedStoryId = this.storyid
      
    });

    this.selectedTab = this.tab
    this.dataSharingService.userDataObservable.subscribe((userData) => {
      this.userData = userData;
      if (this.userData != undefined && this.userData.uid != undefined) {
        this.userId = this.userData.uid
      } else {
        this.commonService.getIdentifier(this.userId).subscribe(async (response: any) => {
          this.userData = response;
          this.userData.profileName = this.userData.displayName != undefined ? this.userData.displayName : this.userData.uid;
        })
      }
      this.commonService.getAllUser().subscribe(async (allUsers: any) => {
        this.userDetail = allUsers.users;
      })
      this.userFilesList.subscribe((userFile) => {
        this.userUploadedFileList = userFile
        this.filelist = this.userUploadedFileList.filter((uploadlist) => uploadlist.fileType == 'UserUploads' )
        let filelist = this.filelist.filter((uploadlist) => uploadlist.userId == this.userId)
        this.userFiledetails = filelist
      })
    });




    this.userDetailsList.subscribe((userFile) => {
      this.UserList  = userFile
      this.userProfileData =  this.UserList.find((user)=>user.UserID == this.userId )
    })
    this.snapInIt()


  
    // this.dataSharingService.userSnapShotObservable.subscribe(userSnapShots => {
    //   if (userSnapShots == undefined || userSnapShots.length == 0) {
    //     if(this.avatarID == undefined) {
    //       let reqInputObjectUser = { id: this.userId  , pageNumber: 1 }
    //       this.commonService.yologramSnapshotDetailsByUserId(reqInputObjectUser).subscribe(async (response: any) => {
    //         this.snaploader = false;
    //         this.userSnapShotList= response.object;
    //         this.pagecount =  response.count
    //       })
    //     }else {
    //       let reqInputObjectAvatar = { id: this.avatarID, pageNumber: 1 }
    //       this.commonService.yologramSnapshotDetailsByAvatar(reqInputObjectAvatar).subscribe(async (response: any) => {
    //         this.userSnapShotList= response.object;
    //         this.pagecount =  response.count
    //         console.log("UserSnapShotList",this.userSnapShotList)
    //         this.snaploader = false;
    //       })
    //     }
    //   } else {
    //     this.snaploader = false;
    //     this.userSnapShotList = userSnapShots
    //     console.log("UserSnapShotList After Else",this.userSnapShotList)
    //   }
    // });
    if (this.avatarID != undefined) {
      let title = 'Yologram Avatar'
      this.commonService.changeTitle(title);
    } else {
      let title = 'Yologram' + ' - ' + this.userData.profileName
      this.commonService.changeTitle(title);
    }
  }

  closePopUp() {
    this.modal.dismissAll(CaraosalPopupComponent)
  }


  userFileReload(){
    this.userFilesList.subscribe((userFile) => {
      this.userUploadedFileList = userFile
      this.filelist = this.userUploadedFileList.filter((uploadlist) => uploadlist.fileType == 'UserUploads' )
      let filelist = this.filelist.filter((uploadlist) => uploadlist.userId == this.userId)
      this.userFiledetails = filelist
  });
  }
  userFileuploadsd(){
    let filelist =this.filelist.filter((uploadlist) => uploadlist.userId == this.userId)
    this.userFiledetails = filelist
    this.userFileReload()
  }
  getProfileName(uuid) {
    let userObjet = this.allUserDetails.find(userObject => userObject.uid == uuid)
    let profileName = userObjet.displayName != undefined ? userObjet.displayName : uuid;
    return profileName;
  }
  getProfilePhoto(uuid) {
    let userObjet = this.allUserDetails.find(userObject => userObject.uid == uuid)
    let profilephoto = userObjet.photoURL != undefined ? userObjet.photoURL : uuid;
    return profilephoto;
  }

  setPageNumber(pageNumber) {
    this.pageNumber = pageNumber
  }

  getSnapShotListByUser(userId) {
    let userSnapShotList = this.userSnapShotList.filter((snapshot) => snapshot.UserID == userId);
    this.dataSharingService.sendUserSnapShots(userSnapShotList);
    this.router.navigate(['public/public-userStory-feed', { 'id': userId }])
  }



  getSelectSnapshotFeed(userSnap) {
    let userId = userSnap.UserID
    let storyid = userSnap.StoryID
    let userSnapShotList = this.userSnapShotList.filter((snapshot) => snapshot.UserID == userId);
    this.dataSharingService.sendUserSnapShots(userSnapShotList);
    if (this.avatarID != undefined) {
      this.router.navigate(['public/public-userStory-feed', { 'id': userId, 'storyId': storyid, 'avatarID': this.avatarID, 'pagecount': this.pagecount }])
    } else {
      this.router.navigate(['public/public-userStory-feed', { 'id': userId, 'storyId': storyid, 'pagecount': this.pagecount }])

    }
  }

  scrollToStory(snap) {
    this.selectedStoryId = snap.StoryID
    this.selectedTab = 'user-story'
  }

  selectSummeryView(tab) {
    this.selectedTab = 'story'
    this.selectTab(tab)
  }


  getSelectSnapshoAvatartFeed(userSnap) {
    let userId = userSnap.UserID
    let storyid = userSnap.StoryID
    let userSnapShotList = this.userSnapShotList.filter((snapshot) => snapshot.AvatarID == userId);
    this.dataSharingService.sendUserSnapShots(userSnapShotList);
    this.router.navigate(['public/public-userStory-feed', { 'id': userId, 'storyId': storyid }])
  }


  hideAvatar() {
    if (this.avatarID != undefined) {
      this.hide = false
    }
  }

  snapInIt() {
    this.pageNumber = 1;
    if (this.avatarID != undefined && this.userId != undefined) {
      let reqInputObjectAvatar = { id: this.avatarID, pageNumber: 1 }
      this.commonService.yologramSnapshotDetailsByAvatar(reqInputObjectAvatar).subscribe(async (response: any) => {
        this.filterSnap = response.object;
        this.pagecount = response.count
        this.snaploader = false;
        this.filterSnap.forEach(async (snap: any, index) => {
          let userObjet = this.userDetail.find(userObject => userObject.uid == snap.UserID)
          snap.profileName = userObjet.displayName != undefined ? userObjet.displayName : snap.UserID;
          userObjet.profileName = snap.profileName;
          snap.userData = userObjet
          snap.firstName = snap.profileName.split(" ", 1);
          snap.AvatarLastFive = snap.AvatarID.substr(snap.AvatarID.length - 5);
        });
      })
    } else {
      let reqInputObject = { id: this.userId, pageNumber: this.pageNumber }
      this.commonService.yologramSnapshotDetailsByUserId(reqInputObject).subscribe(async (response: any) => {
        this.filterSnap = response.object
        this.pagecount = response.count
        this.snaploader = false;
        this.filterSnap.forEach(async (snap: any, index) => {
          let userObjet = this.userDetail.find(userObject => userObject.uid == snap.UserID)
          snap.profileName = userObjet.displayName != undefined ? userObjet.displayName : snap.UserID;
          userObjet.profileName = snap.profileName;
          snap.userData = userObjet
          snap.firstName = snap.profileName.split(" ", 1);
          snap.AvatarLastFive = snap.AvatarID.substr(snap.AvatarID.length - 5);
        });
      })
    }
  }


  getSnapShotListByUserID() {
    if (this.avatarID != undefined) {
      let reqInputObject = { id: this.avatarID, pageNumber: 1 }
      this.commonService.yologramSnapshotDetailsByAvatar(reqInputObject).subscribe(async (response: any) => {
        this.userSnapShotList = response.object;
      })
    } else {
      let reqInputObject = { id: this.userData.uid, pageNumber: this.counter }
      this.commonService.yologramSnapshotDetailsByUserId(reqInputObject).subscribe(async (response: any) => {
        this.allDetails = response.object
        let allAvatarDetails = this.allDetails.filter((snap) => snap.UserID == this.userId)
        this.userSnapShotList = response.object;
      })
    }
  }

  getSnapShotListavtarid(avatar) {
    let userSnapShotList = this.userSnapShotList.filter((snapshot) => snapshot.AvatarID == avatar);
    this.dataSharingService.sendUserSnapShots(userSnapShotList);
    if (this.avatarID != undefined) {
      this.router.navigate(['public/public-userStory-feed', { 'id': this.userId, 'storyId': this.storyid, 'avatarID': this.avatarID }])
    } else {
      this.router.navigate(['public/public-userStory-feed', { 'id': this.userId, 'storyId': this.storyid }])

    }
  }


  nextButton(count) {
    this.pageNumber++
    this.counter = this.pageNumber;
    let reqInputObject = { id: this.userData.uid, pageNumber: this.counter }
    this.commonService.yologramSnapshotDetailsByUserId(reqInputObject).subscribe(async (response: any) => {
      this.filterSnap.push(...response.object);
    })
  }
  nextButtonForAvatar(c) {
    this.pageNumber++
    this.counter = this.pageNumber;
    let reqInputObject = { id: this.avatarID, pageNumber: this.counter }
    this.commonService.yologramSnapshotDetailsByAvatar(reqInputObject).subscribe(async (response: any) => {
      this.filterSnap.push(...response.object);
    })
  }


  userFileUpload(){
    document.getElementById("file").click();
   }

   start2DFileUpload($event: any) {
    let uploadFile = $event.target.files[0];
    this.uploadFileName = uploadFile.name;
    const pathEvn = `Uploads/${this.userId}/${this.uploadFileName}`;
    const filename = `${this.file}`;
    const ref = this.storage.ref(pathEvn);
    this.task = this.storage.upload(pathEvn, uploadFile);
    this.percentage = this.task.percentageChanges();
    let new_date = new Date();
    const date = this.datepipe.transform(new_date, 'd MMM, y, h:mm:ss a');
    const uid = generateUUID();

    let uploadObject = {
      id:uid,
      filename: this.uploadFileName,
      fileurl: '',
      filepath: pathEvn,
      date: date,
      fileType: 'UserUploads',
      userId:   this.userId ,
      link: '',
    }
    this.task.then(async snapshot => {
      let downloadURLTest = ref.getDownloadURL();
      await downloadURLTest.subscribe(url => {
        uploadObject.fileurl = url
        this.db.collection('userfiles').add(uploadObject);
      });
    })
  }
  
  scrollHandler() {
    this.scrollFlag = true
    this.pageScroll = this.userFeeds.nativeElement.scrollTop
    setTimeout(() => {
      if ((this.scrollFlag == true && this.pageScroll > 100) || this.selectedStoryId != undefined) {
      }
    },
      0);
    let page = window.scrollY

  }

  selectTab(tab:any){
    this.secteTab = tab
  }

} 
