<div *ngIf="loading" class="loading">&#8230;</div>
<app-yologram-social *ngIf="logInFlag"></app-yologram-social>
<div class="container-fluid-lg-8"  *ngIf="!loading">
  <div class="row row justify-content-start p-2">
    
    <div class="col-sm-6 d-flex ">
    <div class="col-lg-4 p-2">
      <div class="">
        <select class="form-select width-select " aria-label="Default select example" (change)="selectedBrand($event)">
          <option value="">Select Brand</option>
          <option [value]="brand.id" *ngFor="let brand of brands ;let i=index">{{brand.BrandName}}</option>
        </select>
      </div>
    </div>
    <div class="col-lg-4 p-2">
      <div class="">
        <select class="form-select  width-select" aria-label="Default select example" (change)="selectedBoard($event)">
          <option value="">Select Board</option>
          <option [value]="board.id" *ngFor="let board of boards;let i=index">{{board.name}}</option>
        </select>
      </div>
    </div>
    <div class="col-lg-4  p-2">
      <div class="">
      <button type="button" class="btn btn-primary" (click)="linkBrandToBoard()">Link</button>
    </div>
  </div>
  </div>
  </div>
  <div class="table-responsive">
    <table class="table table-striped  p-4 justify-content-center mb-md-0">
      <thead>
        <tr>
          <th>Brand Name</th>
          <th>Brand Logo</th>
          <th>Board Name</th>
        </tr>
      </thead>
      <tbody>
        <tr scope="row" *ngFor="let brand of linkBrands  ; let i = index">
          <td>{{ brand.BrandName }}</td>
          <td *ngIf="brand.LogoURL && brand.LogoURL !== ''">
            <img class="img-thumbnail2" [src]="getBrandImage(brand.LogoURL)" />
          </td>
          <td>{{brand.boardName}}
          </td>
        </tr>
      </tbody>

    </table>
  </div>
  