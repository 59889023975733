import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from '../services/common.service';
import { NgForm, NgModel } from '@angular/forms';
import { User } from './../services/user';
import { DatePipe } from '@angular/common';
import { MessageService } from './../services/messaging.service';
import { LoginPopupComponent } from '../common/templets/login-popup/login-popup.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  providers: [DatePipe]
})
export class LoginComponent implements OnInit {
  //private user:User;
//   message:any;
//   activeShare:boolean =false
//   activehome:boolean =false
//   constructor(
//     public router: Router,
//     private auth: CommonService,
//      public datepipe: DatePipe,
//      private messageService: MessageService
//      ) { }

    
//   ngOnInit(): void {
//     let new_date = new Date();
//     const date1 = this.datepipe.transform(new_date, 'd MMM, y');
//     this.messageService.requestPermission();
//     // if(this.router.url == 'Login'){ 
//     //   this.activeShare = false
//     // }else{
//     //   this.activehome = true
//     // }
//   }

//   //Methdo to login with google
//   loginWithGoogle(){
//     this.auth.loginWithGoogle();
//     this.fcmtokenGenrateAndSave()
//     this.messageService.receiveMessage();
//     this.message = this.messageService.currentMessage;

//   }


//   fcmtokenGenrateAndSave(){
//     this.messageService.fcmTokencheck();
//   }
  
// }

activeShare:boolean = false
menuFlag:boolean = true
menuflagInactive :boolean = false
constructor(public router: Router, private modal: NgbModal, ) { }

ngOnInit(): void {
  this.menuFlag = false
if(this.router.url == '/Login')
  this.activeShare = false

}

openSignInPoup(){

 const modalRef = this.modal.open(LoginPopupComponent,{
    modalDialogClass:"model-login-popup"
  });
  modalRef.result.then((result) => {
    this.activeShare = false
    if(!this.activeShare || result == 0 ){
      this.menuFlag = false
    }
  }).catch((error) => {
    if(error == 0 ){
      this.menuFlag = false
    }
    console.log(error);
  });


}

signInPopup(){
  this.menuFlag = true
  this.openSignInPoup()
}
clickOnIconHIden(){
  this.menuFlag = false
}
}