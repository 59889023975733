<app-yologram-social *ngIf="logInFlag"></app-yologram-social>
<div class="container m-1">
    <div class="row">
        <!-- Input Box -->
        <div class="col-12 col-md-2 mb-3">
            <input type="text" class="form-control" placeholder="Enter Texture Name" [(ngModel)]="inputValue">
        </div>

        <!-- Dropdowns with File Upload Icon -->
        <div class="col-6 col-md-2 mb-3">
            <div class="input-group" [ngClass]="{'border border-success': selectedBaseMapFile}">
                <select class="form-select" [(ngModel)]="baseMapValue">
                    <option selected>BaseMap</option>
                    <!-- Other options -->
                </select>
                <span class="input-group-text" style="cursor: pointer;" (click)="triggerFileInput('baseMapFile')">
                    <i class="bi bi-plus-circle"></i>
                </span>
                <input type="file" id="baseMapFile" accept=".jpg, .jpeg, .png" style="display:none"
                    (change)="handleFileUpload($event, 'BaseMap')">
            </div>
        </div>

        <div class="col-6 col-md-2 mb-3">
            <div class="input-group" [ngClass]="{'border border-success': selectedNormalMapFile}">
                <select class="form-select" [(ngModel)]="normalMapValue">
                    <option selected>NormalMap</option>
                    <!-- Other options -->
                </select>
                <span class="input-group-text" style="cursor: pointer;" (click)="triggerFileInput('normalMapFile')">
                    <i class="bi bi-plus-circle"></i>
                </span>
                <input type="file" id="normalMapFile" accept=".jpg, .jpeg, .png" style="display:none"
                    (change)="handleFileUpload($event, 'NormalMap')">
            </div>
        </div>

        <div class="col-6 col-md-2 mb-3" [ngClass]="{'border border-success': selectedMetallicMapFile}">
            <div class="input-group">
                <select class="form-select" [(ngModel)]="metallicMapValue">
                    <option selected>MetallicMap</option>
                    <!-- Other options -->
                </select>
                <span class="input-group-text" style="cursor: pointer;" (click)="triggerFileInput('metallicMapFile')">
                    <i class="bi bi-plus-circle"></i>
                </span>
                <input type="file" id="metallicMapFile" accept=".jpg, .jpeg, .png" style="display:none"
                    (change)="handleFileUpload($event, 'MetallicMap')">
            </div>
        </div>
  

        <!-- Save Button -->
        <div class="col-6 col-md-2 mb-3 d-md-flex justify-content-md-end">
            <button class="btn btn-primary w-100 w-md-auto" (click)="saveValues()">Save</button>
        </div>
    </div>

    <div *ngIf="uploadProgress > 0">Please wait file is uploaing... </div>
    <div *ngIf="uploadProgress > 0" class="progress mt-3">
        <div class="progress-bar" role="progressbar" [style.width.%]="uploadProgress"
            [attr.aria-valuenow]="uploadProgress" aria-valuemin="0" aria-valuemax="100">
            {{ uploadProgress | number: '1.0-0' }}%
        </div>
    </div>

    <!-- List of Textures from Firestore -->
    <li class="list-group-item" *ngFor="let texture of textureLibrary | async">
        <strong class="custom-font-size">{{ texture.TextureName }} <i class="bi bi-pencil-square p-1"
                (click)="openEditModal(texture)"></i>
        </strong><br>
        <small *ngIf="texture.BaseMapPath != ''" (click)="showImage(texture.BaseMapPath, texture)">Base Map
        </small><br>
        <small *ngIf="texture.NormalMapPath != ''" (click)="showImage(texture.NormalMapPath, texture)">Normal Map
        </small><br>
        <small *ngIf="texture.MetallicMapPath != ''" (click)="showImage(texture.MetallicMapPath, texture)">Metallic Map
        </small>
    </li>
</div>


<div class="modal fade" id="imageModal" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content custom-modal-content">
            <div class="modal-body text-center">
                <ng-container *ngIf="imagePath; else loadingSpinner">
                    <img [src]="imagePath" class="img-fluid" alt="Texture Image">
                    <i *ngIf="seletedPath && seletedPath !== selectedtexture.BaseMapPath"
                        (click)="deleteSelectedMap( this.seletedPath, this.selectedtexture)"
                        class="bi bi-trash-fill p-1 selected-delete-button"></i>

                </ng-container>
                <!-- Loading spinner when imagePath is null or undefined -->
                <ng-template #loadingSpinner>
                    <div class="d-flex justify-content-center align-items-center" style="height: 100%;">
                        <div class="spinner-border text-primary" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </div>
                </ng-template>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="editTextureModal" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Edit Texture</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="container">
                    <div class="row">
                        <!-- Texture Name Input -->
                        <div class="col-12 col-md-6 mb-3">
                            <input type="text" class="form-control" placeholder="Enter Texture Name"
                                [(ngModel)]="editInputValue">
                        </div>

                        <!-- Base Map Dropdown with File Upload Icon -->
                        <div class="col-12 col-md-6 mb-3">
                            <div class="input-group" [ngClass]="{'border border-success': editBaseMapFile}">
                                <select class="form-select" [(ngModel)]="editBaseMapValue">
                                    <option selected>BaseMap</option>
                                    <!-- Add other options here if needed -->
                                </select>
                                <span class="input-group-text" style="cursor: pointer;"
                                    (click)="triggerFileInput('editBaseMapFile')">
                                    <i class="bi bi-plus-circle"></i>
                                </span>
                                <input type="file" id="editBaseMapFile" accept=".jpg, .jpeg, .png"
                                    style="display: none;" (change)="handleEditFileUpload($event, 'BaseMap')">
                            </div>
                            <img *ngIf="baseMapUrl" [src]="baseMapUrl" class="img-fluid edit-texture-img"
                                alt="Texture Image">
                    
                        </div>

                        <!-- Normal Map Dropdown with File Upload Icon -->
                        <div class="col-12 col-md-6 mb-3">
                            <div class="input-group" [ngClass]="{'border border-success': editNormalMapFile}">
                                <select class="form-select" [(ngModel)]="editNormalMapValue">
                                    <option selected>NormalMap</option>
                                    <!-- Add other options here if needed -->
                                </select>
                                <span class="input-group-text" style="cursor: pointer;"
                                    (click)="triggerFileInput('editNormalMapFile')">
                                    <i class="bi bi-plus-circle"></i>
                                </span>
                                <input type="file" id="editNormalMapFile" accept=".jpg, .jpeg, .png"
                                    style="display: none;" (change)="handleEditFileUpload($event, 'NormalMap')">
                            </div>
                            <img *ngIf="normalMapUrl" [src]="normalMapUrl" class="img-fluid edit-texture-img"
                                alt="Texture Image">
                                <i *ngIf="this.editableTexture?.NormalMapPath != ''"
                                (click)="deleteSelectedMap(this.editableTexture?.NormalMapPath ,this.editableTexture   )"
                                class="bi bi-trash-fill p-1 "></i>
                        </div>

                        <!-- Metallic Map Dropdown with File Upload Icon -->
                        <div class="col-12 col-md-6 mb-3">
                            <div class="input-group" [ngClass]="{'border border-success': editMetallicMapFile}">
                                <select class="form-select" [(ngModel)]="editMetallicMapValue">
                                    <option selected>MetallicMap</option>
                                    <!-- Add other options here if needed -->
                                </select>
                                <span class="input-group-text" style="cursor: pointer;"
                                    (click)="triggerFileInput('editMetallicMapFile')">
                                    <i class="bi bi-plus-circle"></i>
                                </span>
                                <input type="file" id="editMetallicMapFile" accept=".jpg, .jpeg, .png"
                                    style="display: none;" (change)="handleEditFileUpload($event, 'MetallicMap')">
                            </div>
                            <img *ngIf="metallicMapUrl" [src]="metallicMapUrl" class="img-fluid edit-texture-img"
                                alt="Texture Image">
                                <i *ngIf=" this.editableTexture?.MetallicMapPath != ''"
                                (click)="deleteSelectedMap(this.editableTexture?.MetallicMapPath ,this.editableTexture )"
                                class="bi bi-trash-fill p-1"></i>
                        </div>
                        <div class="col-12 col-md-6 mb-3">
                        <div *ngIf="uploadProgress > 0">Please wait file is uploaing... </div>
                        <div *ngIf="uploadProgress > 0" class="progress mt-3">
                            <div class="progress-bar" role="progressbar" [style.width.%]="uploadProgress"
                                [attr.aria-valuenow]="uploadProgress" aria-valuemin="0" aria-valuemax="100">
                                {{ uploadProgress | number: '1.0-0' }}%
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
               
            </div>
            <div class="modal-footer justify-content-center">
              
                <button type="button" class="btn btn-primary" (click)="updateTexture()">Save</button>
                <button type="button" class="btn btn-dark" (click)="deleteEditableTextures()">Delete</button>
            </div>
        </div>
       
    </div>

</div>