import { DatePipe } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/compat/storage';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { CaraosalPopupComponent } from 'src/app/common/templets/caraosal-popup/caraosal-popup.component';
import { ApiHttpService } from 'src/app/services/api-http.service';
import { CommonService } from 'src/app/services/common.service';
import { DataSharingService } from 'src/app/services/data-sharing-service';
import { environment } from 'src/environments/environment';
import { generateUUID } from 'three/src/math/MathUtils';



@Component({
  selector: 'app-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.css']
})
export class UserEditComponent {

  userFilesList = this.firestore.collection('userfiles').valueChanges({ idField: 'id' }) as Observable<any[]>;
  userDetailsList = this.firestore.collection('user-profile').valueChanges({ idField: 'id' }) as Observable<any[]>;
  @ViewChild('userFeeds', { read: ElementRef }) public userFeeds: ElementRef<any>;
  brandFlag:any ='/yologram-social/yologram-brand-stories'
  routLogInFlag:boolean = true
  task!: AngularFireUploadTask;
  percentage!: Observable<number | undefined>;
  currentlySelected;
  storyid: any
  userId: string;
  userSnapShotList: any;
  profileName: any
  userName: any = []
  userDetails: any
  filterSnapShotFileDetails: any = []
  SnapShotFileDetails: any = []
  id: any;
  avatarID: any;
  isLoadingforUserDetails = true
  snapShotFileList: any = []
  avatarIdshide: boolean = true
  hide: boolean = true
  usersplitted: any = []
  allUserSnapDetails: any = []
  filterSnapShotFileDetailsall: any = []
  profileDetails: any = {};
  userSnapShotDetailsCollection = {};
  counter: number = 0;
  allDetails: any = []
  pageNumber: any = 1
  allUserDetails: any = []
  userDetail: any = []
  filterSnap: any = []
  snaploader: boolean = true
  snaploader2D: boolean = true
  profileloader: boolean = true
  deleteflag: boolean = true
  avatar: any
  user: any
  file: any
  userData: any;
  pagecount: number;
  selectedStoryId: any;
  tab: string;
  avatarFlag: string;
  selectedTab: any
  deleteFlag: any
  userUploadedFileList: any = []
  filelist: any = []
  userFiledetails: any = []
  black: any = []
  alluserSnap: any = []
  userProfileData: any = []
  UserList: any = []
  pageScroll:number;
  secteTab:any = "Story"
  scrollFlag:boolean =false
  images = [
    { "image": "./assets/user-upload-dummy/new-img-1.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-2.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-3.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-4.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-5.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-6.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-7.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-8.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-9.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-10.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-11.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-12.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-13.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-14.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-15.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-16.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-17.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-18.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-19.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-20.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-21.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-22.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-23.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-24.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-25.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-26.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-27.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-28.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-29.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-30.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-31.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-32.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-33.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-34.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-35.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-36.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-37.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-38.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-39.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-40.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-41.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-42.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-43.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-44.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-45.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-46.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-47.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-48.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-49.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-50.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-51.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-52.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-53.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-54.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-55.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-56.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-57.jpg" },
  ]
  uploadFileName: any;
  logInFlag: boolean;
  constructor(
    private dataSharingService: DataSharingService,
    private route: ActivatedRoute,
    private apiHttpService: ApiHttpService,
    public router: Router,
    private commonService: CommonService,
    private storage: AngularFireStorage,
    public datepipe: DatePipe,
    private db: AngularFirestore,
    private firestore: AngularFirestore,
    private modal: NgbModal,


  ) {
    this.closePopUp()
  }

  ngOnInit(): void {
    let title = 'Yologram - edit-user'
    this.commonService.changeTitle(title);
    this.logInFlag = this.commonService.isLoggedIn()
    this.snaploader2D = true;
    setInterval(() => {
      this.snaploader2D = false;
    }, 2000);

    this.route.paramMap.subscribe(paramMap => {
      this.userId = paramMap.get('id');
      this.storyid = paramMap.get('storyId');
      this.avatarID = paramMap.get('avatarID');
      this.avatar = paramMap.get('avatar');
      this.user = paramMap.get('user');
      this.tab = paramMap.get('pagetab');
      this.avatarFlag = paramMap.get('avatarFlag');
      this.deleteFlag = paramMap.get('delete');
      this.profileloader = true
      this.selectedStoryId = this.storyid
    });
    this.userDetailsList.subscribe((userFile) => {
      this.UserList  = userFile
      this.userProfileData =  this.UserList.find((user)=>user.UserID == this.userId )
    })

    this.selectedTab = this.tab
    this.dataSharingService.userDataObservable.subscribe((userData) => {
      this.userData = userData;
      if (this.userData != undefined && this.userData.uid != undefined) {
        this.userId = this.userData.uid
      } else {
        this.commonService.getIdentifier(this.userId).subscribe(async (response: any) => {
          this.userData = response;
          this.userData.profileName = this.userData.displayName != undefined ? this.userData.displayName : this.userData.uid;
        })
      }
      this.commonService.getAllUser().subscribe(async (allUsers: any) => {
        this.userDetail = allUsers.users;
      })


 
    });
this.refresh()
    this.snapInIt()
    if (this.avatarID != undefined) {
      let title = 'Yologram Avatar'
      this.commonService.changeTitle(title);
    } else {
      let title = 'Yologram' + ' - ' + this.userData.profileName
      this.commonService.changeTitle(title);
    }
  }
  ngOnChanges(changes): void {
  }

refresh(){
  this.userFilesList.subscribe((userFile) => {
    this.userUploadedFileList = userFile
    this.filelist = this.userUploadedFileList.filter((uploadlist) => uploadlist.fileType == 'UserUploads' )
    let filelist = this.filelist.filter((uploadlist) => uploadlist.userId == this.userId)
    this.userFiledetails = filelist
    this.alluserSnap =  this.userFiledetails
    if(this.userFiledetails.length == 0){
      this.black =  this.images 
    }
  })
  
}
  userFileReload(){
      this.images = []
      this.userFilesList.subscribe((userFile) => {
      this.userUploadedFileList = userFile
      this.filelist = this.userUploadedFileList.filter((uploadlist) => uploadlist.fileType == 'UserUploads' )
      let filelist = this.filelist.filter((uploadlist) => uploadlist.userId == this.userId)
      this.userFiledetails = filelist
  });
  }
  userFileuploadsd(){
    let filelist =this.filelist.filter((uploadlist) => uploadlist.userId == this.userId)
    this.userFiledetails = filelist
  }
  getProfileName(uuid) {
    let userObjet = this.allUserDetails.find(userObject => userObject.uid == uuid)
    let profileName = userObjet.displayName != undefined ? userObjet.displayName : uuid;
    return profileName;
  }
  getProfilePhoto(uuid) {
    let userObjet = this.allUserDetails.find(userObject => userObject.uid == uuid)
    let profilephoto = userObjet.photoURL != undefined ? userObjet.photoURL : uuid;
    return profilephoto;
  }

  setPageNumber(pageNumber) {
    this.pageNumber = pageNumber
  }

  getSnapShotListByUser(userId) {
    let userSnapShotList = this.userSnapShotList.filter((snapshot) => snapshot.UserID == userId);
    this.dataSharingService.sendUserSnapShots(userSnapShotList);
    this.router.navigate(['public/public-userStory-feed', { 'id': userId }])
  }



  getSelectSnapshotFeed(userSnap) {
    let userId = userSnap.UserID
    let storyid = userSnap.StoryID
    let userSnapShotList = this.userSnapShotList.filter((snapshot) => snapshot.UserID == userId);
    this.dataSharingService.sendUserSnapShots(userSnapShotList);
    if (this.avatarID != undefined) {
      this.router.navigate(['public/public-userStory-feed', { 'id': userId, 'storyId': storyid, 'avatarID': this.avatarID, 'pagecount': this.pagecount }])
    } else {
      this.router.navigate(['public/public-userStory-feed', { 'id': userId, 'storyId': storyid, 'pagecount': this.pagecount }])

    }
  }

  scrollToStory(snap) {
    this.selectedStoryId = snap.StoryID
    this.selectedTab = 'user-story'
  }

  selectSummeryView(tab) {
    this.selectedTab = 'story'
  }


  getSelectSnapshoAvatartFeed(userSnap) {
    let userId = userSnap.UserID
    let storyid = userSnap.StoryID
    let userSnapShotList = this.userSnapShotList.filter((snapshot) => snapshot.AvatarID == userId);
    this.dataSharingService.sendUserSnapShots(userSnapShotList);
    this.router.navigate(['public/public-userStory-feed', { 'id': userId, 'storyId': storyid }])
  }


  hideAvatar() {
    if (this.avatarID != undefined) {
      this.hide = false
    }
  }

  snapInIt() {
    this.pageNumber = 1;
    if (this.avatarID != undefined && this.userId != undefined) {
      let reqInputObjectAvatar = { id: this.avatarID, pageNumber: 1 }
      this.commonService.yologramSnapshotDetailsByAvatar(reqInputObjectAvatar).subscribe(async (response: any) => {
        this.filterSnap = response.object;
        this.pagecount = response.count
        this.snaploader = false;
        this.filterSnap.forEach(async (snap: any, index) => {
          let userObjet = this.userDetail.find(userObject => userObject.uid == snap.UserID)
          snap.profileName = userObjet.displayName != undefined ? userObjet.displayName : snap.UserID;
          userObjet.profileName = snap.profileName;
          snap.userData = userObjet
          snap.firstName = snap.profileName.split(" ", 1);
          snap.AvatarLastFive = snap.AvatarID.substr(snap.AvatarID.length - 5);
        });
      })
    } else {
      let reqInputObject = { id: this.userId, pageNumber: this.pageNumber }
      this.commonService.yologramSnapshotDetailsByUserId(reqInputObject).subscribe(async (response: any) => {
        this.filterSnap = response.object
        this.pagecount = response.count
        this.snaploader = false;
        this.filterSnap.forEach(async (snap: any, index) => {
          let userObjet = this.userDetail.find(userObject => userObject.uid == snap.UserID)
          snap.profileName = userObjet.displayName != undefined ? userObjet.displayName : snap.UserID;
          userObjet.profileName = snap.profileName;
          snap.userData = userObjet
          snap.firstName = snap.profileName.split(" ", 1);
          snap.AvatarLastFive = snap.AvatarID.substr(snap.AvatarID.length - 5);
        });
      })
    }
  }


  getSnapShotListByUserID() {
    if (this.avatarID != undefined) {
      let reqInputObject = { id: this.avatarID, pageNumber: 1 }
      this.commonService.yologramSnapshotDetailsByAvatar(reqInputObject).subscribe(async (response: any) => {
        this.userSnapShotList = response.object;
      })
    } else {
      let reqInputObject = { id: this.userData.uid, pageNumber: this.counter }
      this.commonService.yologramSnapshotDetailsByUserId(reqInputObject).subscribe(async (response: any) => {
        this.allDetails = response.object
        let allAvatarDetails = this.allDetails.filter((snap) => snap.UserID == this.userId)
        this.userSnapShotList = response.object;
      })
    }
  }

  getSnapShotListavtarid(avatar) {
    let userSnapShotList = this.userSnapShotList.filter((snapshot) => snapshot.AvatarID == avatar);
    this.dataSharingService.sendUserSnapShots(userSnapShotList);
    if (this.avatarID != undefined) {
      this.router.navigate(['public/public-userStory-feed', { 'id': this.userId, 'storyId': this.storyid, 'avatarID': this.avatarID }])
    } else {
      this.router.navigate(['public/public-userStory-feed', { 'id': this.userId, 'storyId': this.storyid }])

    }
  }
  closePopUp() {
    this.modal.dismissAll(CaraosalPopupComponent)
  }

  nextButton(count) {
    this.pageNumber++
    this.counter = this.pageNumber;
    let reqInputObject = { id: this.userData.uid, pageNumber: this.counter }
    this.commonService.yologramSnapshotDetailsByUserId(reqInputObject).subscribe(async (response: any) => {
      this.filterSnap.push(...response.object);
    })
  }
  nextButtonForAvatar(c) {
    this.pageNumber++
    this.counter = this.pageNumber;
    let reqInputObject = { id: this.avatarID, pageNumber: this.counter }
    this.commonService.yologramSnapshotDetailsByAvatar(reqInputObject).subscribe(async (response: any) => {
      this.filterSnap.push(...response.object);
    })
  }


  userFileUpload(){
    document.getElementById("file").click();
   }

   start2DFileUpload($event: any) {
    let uploadFile = $event.target.files[0];
    this.uploadFileName = uploadFile.name;
    const pathEvn = `Uploads/${this.userId}/${this.uploadFileName}`;
    const filename = `${this.file}`;
    const ref = this.storage.ref(pathEvn);
    this.task = this.storage.upload(pathEvn, uploadFile);
    this.percentage = this.task.percentageChanges();
    let new_date = new Date();
    const date = this.datepipe.transform(new_date, 'd MMM, y, h:mm:ss a');
    const uid = generateUUID();
    let uploadObject = {
      id:uid,
      filename: this.uploadFileName,
      fileurl: '',
      filepath: pathEvn,
      date: date,
      fileType: 'UserUploads',
      userId:   this.userId ,
      link: '',
    }
    this.task.then(async snapshot => {
      let downloadURLTest = ref.getDownloadURL();
      await downloadURLTest.subscribe(url => {
        uploadObject.fileurl = url
        this.db.collection('userfiles').add(uploadObject);
      });
    })
    let filelist = this.filelist.filter((uploadlist) => uploadlist.userId == this.userId)
    this.userFiledetails = filelist  
  }


  scrollHandler() {
    this.scrollFlag = true
    this.pageScroll = this.userFeeds.nativeElement.scrollTop
    this.selectedStoryId = ''
    setTimeout(() => {
      if ((this.scrollFlag == true && this.pageScroll > 100) || this.selectedStoryId != undefined) {
        this.selectedStoryId = ''
      }
    },
      0);
    let page = window.scrollY
  }

  selectTab(tab:any){
    this.secteTab = tab
  }
} 
