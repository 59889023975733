import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { Router } from '@angular/router';
import { Observable, finalize } from 'rxjs';
import { Brand } from 'src/app/models/brand';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-garment-panel',
  templateUrl: './garment-panel.component.html',
  styleUrls: ['./garment-panel.component.css']
})
export class GarmentPanelComponent {
  @Output() closeGarmentEmitter = new EventEmitter<any>();
  

  brandsFilesList = this.firestore.collection('brands').valueChanges({ idField: 'id' }) as Observable<Brand[]>;
  userGarmentCollectionItems = this.firestore
  .collection('garment-collection-items')
  .valueChanges({ idField: 'id' }) as Observable<any[]>;
  userGarmentCollection = this.firestore
  .collection('garment-collection')
  .valueChanges({ idField: 'id' }) as Observable<any[]>;

  @Input() brandObject: any = {};
  style: string = 'treads';
  brandheartFlag: boolean = false;
  tab: any = '';
  userSnapShotList: any = [];
  brandStoryScrollerHeight: any;
  selectedImageIndex: number;
  pageNumberBrand: number = 1;
  selectedBrandId: any;
  sideNavHeight: number
  brandsCollection: any = [];
  newBrandObject: any;
  isloading: any;

  pageNumberGarment: number = 1;


  swichImageFlage: boolean = true;
  garmentScrollFlag = false;
  flipFlag: boolean = false;

  // swichImageFlage: boolean = false;
  // garmentScrollFlag: boolean = true;
  // flipFlag: boolean = true;

  selectedIcon: string;
  iconselctFlag: boolean = false;
  showMore: boolean = false;


  
  images = [
    { image: './assets/garment-upload-dummy/img-1.jpg' },
    { image: './assets/garment-upload-dummy/img-2.jpg' },
    { image: './assets/garment-upload-dummy/img-3.jpg' },
    { image: './assets/garment-upload-dummy/img-4.jpg' },
    { image: './assets/garment-upload-dummy/img-5.jpg' },
    { image: './assets/garment-upload-dummy/img-6.jpg' },
    { image: './assets/garment-upload-dummy/img-7.jpg' },
    { image: './assets/garment-upload-dummy/img-8.jpg' },
    { image: './assets/garment-upload-dummy/img-9.jpg' },
  ];
  dummyText: string;
  collectionDetails: any = [];
  collectionData: any[];
  selectedgender: string;
  showChild: boolean;
  typeWear: any;
  brandlogUrl: string;
  clothDetails: any;
  source: any;

  constructor(private firestore: AngularFirestore, public router: Router,private storage: AngularFireStorage,private commonService: CommonService,) {
    this.userGarmentCollectionItems.subscribe((collectiondata) => {
      this.collectionDetails = collectiondata;
    });

    this.userGarmentCollection.subscribe((collectiondata) => {
      this.collectionData = collectiondata;
    });

  }


  async ngOnChanges(changes) {
    if (changes['brandObject']) {
      let brandId = this.brandObject;
  
      console.log('brandObject', brandId);
      this.tab = 'mens-wear';
  
      this.selectedBrandId = brandId;
      this.brandlogUrl = this.getBrandImage(this.brandObject.brandObject.LogoURL);
      this.clothDetails = this.brandObject.clotObject;
      console.log('this.clothDetails', this.clothDetails);
      console.log('this.brandObject', this.brandObject);
  
      console.log('this.brandObject', this.brandObject.source);
  
      try {
        // Use refFromURL() if you have a URL
        const storageRef = this.storage.refFromURL(this.brandObject.source);
        const url = await storageRef.getDownloadURL().pipe(finalize(() => {})).toPromise();
        this.source = url;
        console.log('this.source', this.source);
      } catch (error) {
        console.error('Error getting download URL:', error);
      }
    }
  
    const text = "Your brand's apparel is digitised in 3D.\n\nOn a gamified experience, consumers style avatars & share\n\nanimated avatar stories (linked to ecommerce)\n\nDefining a new paradigm in immersive storytelling for social media & the metaverse";
  
    const lines = text.split('\n\n');
    this.dummyText = lines[0] + '\n' + lines[1] + '\n' + lines[2] + '\n' + lines;
  }


  getBrandImage(imgURL: string) {
    let brandEncodedurl = encodeURI(imgURL).replace('/', '%2F');
    let url =
      'https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/' +
      brandEncodedurl +
      '?alt=media&token=550e7e88-bed3-4668-84ff-ad491c19e06c';
    return url;
  }
  
  clickOnHeart(event: any) {
    event.stopPropagation();
    this.brandheartFlag = !this.brandheartFlag;
  }


  switch3DImage(event: any) {
    event.stopPropagation();
    this.swichImageFlage = !this.swichImageFlage;
  }

  flipClose() {
    this.flipFlag = false;
    this.garmentScrollFlag = false;
  }

  selectIcon(icon) {
    this.selectedIcon = '';
    this.selectedIcon = icon;
    this.iconselctFlag = true;
  }


  filterCollectionName(id) {
    let garmentCollectionId = (
      this.collectionDetails != undefined && this.collectionDetails
    ).find((colection) => colection.id === id);
    let getCollection = this.collectionData.find(
      (colection) =>
        colection.id === garmentCollectionId.GarmentCollectionID &&
        colection.status == 'A'
    );
    let name =
      getCollection != undefined && getCollection.CollectionName != undefined
        ? getCollection.CollectionName
        : '';
    return name;
  }


  selectGarmentImage(index: number, snapObjet) {
    this.selectedImageIndex = index;
    // let garmentObjet = this.clothDetails
    // const dataToSend = {
    //   garmentId: garmentObjet.id,
    //   zipSource: this.source,
    //   typeWear: this.typeWear,
    //   type: this.typeOfGarment,

    // };
    // this.commonService.setSharedObject(dataToSend);
    // this.commonService.setIndex(index);
    let page = this.pageNumberBrand;
    this.selectedgender = this.tab === 'mens-wear' ? 'M' : (this.tab === 'womens-wear' ? 'W' : '');
    // this.router.navigate([
    //   '/threads',
    //   {
    //     brandId: this.selectedBrandId,
    //     M: this.selectedgender,
    //     Ex: 'N',
    //     storyId: snapObjet.StoryID,
    //     pageNumber: page,
    //     garmentId: garmentObjet.id,
    //   },
    // ]);
  }

  toggleChild() {
    this.showChild = !this.showChild;
    this.closeGarmentEmitter.emit(this.showChild)
  }

  setTab(selectedtab: any, gender) {
    this.tab = selectedtab;
     let garmentObjet = this.brandObject.clotObject;
    const confirmNavigation = window.confirm('Are you sure you want to navigate to another brand?');
    if (confirmNavigation) {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      // Now navigate to the desired route after reloading
      this.router.navigate([
        '/threads',
        { 'brandId': garmentObjet.BrandID, 'M': gender, 'Ex': 'N' },
      ]);
    });
  }
  }

  setBrandSelection() {
    let garmentObjet = this.brandObject.clotObject;
    const confirmNavigation = window.confirm('Are you sure you want to navigate to another brand?');
    if (confirmNavigation) {
      const currentUrl = this.router.url;
      // Navigate to the current URL to force a reload
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        // Now navigate to the desired route after reloading
        this.router.navigate(['/threads', { 'brandId': garmentObjet.BrandID, 'M': 'S', 'Ex': 'N' }]);
      });
    }
  }

  callGarmentCloth() {
    let garmentObjet = this.brandObject.clotObject;
    let type = this.brandObject.typeOfGarment
   if(type == 'headgear'){
    this.typeWear = "HeadwearID"
   } else   if(type == 'upperwear'){
    this.typeWear = "UpperwearID"
   }  else   if(type == 'bottomwear'){
    this.typeWear = "BottomwearID"
   } else   if(type == 'footwear'){
    this.typeWear = "FootwearID"
   }
 
    let gender = garmentObjet.Gender == 'F' ? 'W' : 'M';
    const dataToSend = {
      garmentId: garmentObjet.id,
      zipSource: this.brandObject.source,
      typeWear: this.typeWear,
      type: this.brandObject.typeOfGarment,
    };
    this.commonService.setSharedObject(dataToSend);
    const confirmNavigation = window.confirm('Are you sure you want to navigate to another brand?');
    if (confirmNavigation) {
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
    this.router.navigate([
      '/threads',
      {
        brandId: garmentObjet.BrandID,
        M: gender,
        Ex: 'N',
        garmentId: garmentObjet.id,
      },
    ]);
  });
  }


  }


  // selectImage(index: number, snapObjet) {
  //   let garmentObjet = this.brandObject.clotObject;
  //   this.selectedImageIndex = index;
  //   console.log('selectedImageIndex drips===>', this.selectedImageIndex);
  //   console.log('snapObjet===>', snapObjet);
  //   this.commonService.setIndex(index);
  //   let dataToSend = { brandFlag:true,brandTab:"news-stories"}
  //   this.commonService.setSharedObject(dataToSend);
  //   let page = this.pageNumberBrand;
  //   this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
  //   this.router.navigate([
  //     '/threads',
  //     {
  //       brandId:  garmentObjet.BrandID ,
  //       M: 'S',
  //       Ex: 'N',
  //       storyId: snapObjet.StoryID,
  //       pageNumber: page,
  //     },
  //   ]);
  // })
  // }
  
  selectImage(index: number, snapObjet) {
    const confirmNavigation = window.confirm('Are you sure you want to navigate to another brand?');
    if (confirmNavigation) {
      let garmentObjet = this.brandObject.clotObject;
      this.selectedImageIndex = index;
      console.log('selectedImageIndex drips===>', this.selectedImageIndex);
      console.log('snapObjet===>', snapObjet);
      this.commonService.setIndex(index);
      let dataToSend = { brandFlag: true, brandTab: "news-stories" };
      this.commonService.setSharedObject(dataToSend);
      let page = this.pageNumberBrand;
      let count = 12
      const currentPage = Math.floor(index / count) + 1;
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate([
          '/threads',
          {
            brandId: garmentObjet.BrandID,
            M: 'S',
            Ex: 'N',
            storyId: snapObjet.StoryID,
            // pageNumber: page,
            'index':index,
            'pageNumber':currentPage
          },
        ]);
      });
    }
  }


  shareBrand() {
    let garmentObjet = this.brandObject.clotObject;
    let brandId = garmentObjet.BrandID;
    let mode = this.tab === 'womens-wear' ? 'W' : this.tab === 'mens-wear' ? 'M' : 'S';
    let link = "https://yolomove-social-live.web.app/threads;brandId=" + brandId + ';M=' + mode + ';Ex=Y';
    // let text = 'Check out '+ this.brandNameSelected +' on Yologram '
    // console.log(text)
    if (navigator.share) {
      navigator.share({
        title: 'Share Brand',
        text: 'Check out ' + this.brandObject.BrandName + ' on Yologram ',
        url: link,
      })
        .then(() => console.log('Successful share'))
        .catch((error) => console.log('Error sharing', error));
    }
  }

  incrementImgGarmentCard() {
    let type = this.brandObject.typeOfGarment
    if(type == 'headgear'){
      this.typeWear = "HeadwearID"
     } else   if(type == 'upperwear'){
      this.typeWear = "UpperwearID"
     }  else   if(type == 'bottomwear'){
      this.typeWear = "BottomwearID"
     } else   if(type == 'footwear'){
      this.typeWear = "FootwearID"
     }
    this.pageNumberGarment++;
    let reqInputObject = {
      fileType: this.typeWear,
      id: this.brandObject.clotObject.id,
      pageNumber: this.pageNumberGarment,
    };
    this.commonService
      .yologramSnapshotDetailsByType(reqInputObject)
      .subscribe(async (response: any) => {
        this.brandObject.garmentStory.push(...response.object);
      });
  }
  
}
