import { Component } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Brand } from 'src/app/models/brand';
import { CommonService } from 'src/app/services/common.service';

interface Cloth {
  BrandId: string;
  Gender: string;
  GarmentId: string;
  GarmentType: string;
}

@Component({
  selector: 'app-default-brand-clothes',
  templateUrl: './default-brand-clothes.component.html',
  styleUrls: ['./default-brand-clothes.component.css']
})
export class DefaultBrandClothesComponent {
  brands = this.firestore.collection('brands').valueChanges({ idField: 'id' }) as Observable<Brand[]>;
  garmentsList = this.firestore.collection('garments').valueChanges({ idField: 'id' }) as Observable<any[]>;
  defaultBrandCloth = this.firestore.collection('DefaultBrandGarments').valueChanges({ idField: 'id' }) as Observable<any[]>;


  panelFlag: boolean = false;
  ViewFlage: boolean = false;
  logInFlag: boolean;
  brandList: any = [];
  garmentsCollection: any = [];
  selectedBrand: any = '';
  selectedGender: any='';
  selectedClothType: any='';
  selectedCloth:  any=''; 
  filterGarmrntList: any;
  defaultBrandClothList: Cloth[] = [];
  loading: boolean = true;
  selectedGarmentObject :any={}
  constructor(public commonService: CommonService, private firestore: AngularFirestore, public router: Router,) {
    this.logInFlag = this.commonService.isLoggedIn()
    if (!this.logInFlag) {
      this.router.navigateByUrl("/drip");
    }
    this.checkPageAuthorization()

    this.brands.subscribe((brand) => {
      this.brandList = brand
    })

    this.garmentsList.subscribe((garments) => {
      this.garmentsCollection = garments;
    });

    // this.defaultBrandCloth.subscribe((item) => {
    //   this.defaultBrandClothList = item;
    // });


  }


  ngOnInit(): void {
    // Placeholder for subscription to data
    let title = 'Yologram - default-brand-garment';
    this.commonService.changeTitle(title);
    this.defaultBrandCloth.subscribe((item) => {
      this.defaultBrandClothList = item;
      this.sortClothList(); // Sort after data is received
    });
  }



  selectBrand($event) {
    this.filterGarmrntList = []
    this.selectedBrand = $event.target.value;
    console.log("brandId", this.selectedBrand)
    this.selectedGender = '';
    this.selectedClothType = '';
    this.selectedCloth = '';
  }

  selectGender($event) {
    this.filterGarmrntList = []
    this.selectedGender = $event.target.value;

    console.log("Gender", this.selectedGender)
    this.filterClothList()
  }

  selectClothType($event) {
    this.filterGarmrntList = []
   this.selectedClothType = $event.target.value;
    console.log("clothtype", this.selectedClothType)
    this.filterClothList()
  }

  selectCloth($event) {
    this.selectedCloth = $event.target.value;
    console.log("cloth", this.selectedCloth)


  }

  filterClothList() {
    if (this.selectedBrand == "Default") {
      this.filterGarmrntList = this.garmentsCollection.filter((garment) =>
        garment.Gender == this.selectedGender && garment.GarmentType == this.selectedClothType)
    } else {
      this.filterGarmrntList = this.garmentsCollection.filter((garment) => garment.BrandID == this.selectedBrand &&
        garment.Gender == this.selectedGender && garment.GarmentType == this.selectedClothType)
    }
  }



  addDefaultBrandsCloth() {
    let newObject = {
      'BrandId': this.selectedBrand,
      'Gender': this.selectedGender,
      'GarmentId': this.selectedCloth,
      'GarmentType': this.selectedClothType
    };

    // Check if newObject properties are defined
    if (!newObject.BrandId || !newObject.Gender || !newObject.GarmentId || !newObject.GarmentType) {
      alert("Please select all details.");
      return;
    }

    // Now using AngularFire's syntax for queries
    this.firestore.collection('DefaultBrandGarments', ref => ref
      .where('BrandId', '==', newObject.BrandId)
      .where('Gender', '==', newObject.Gender)
      .where('GarmentType', '==', newObject.GarmentType))
      .get()
      .subscribe(querySnapshot => {
        if (querySnapshot.empty) {
          // Document doesn't exist, safe to add
          this.firestore.collection('DefaultBrandGarments').add(newObject).then(() => {
            console.log('New Brand details added successfully.');
            // Optionally update local data or UI here
          }).catch(error => {
            console.error('Error adding Brand details:', error);
            alert('There was an error adding the Brand details. Please try again.');
          });
        } else {
          // Document exists, handle duplicate
          alert('This Brand ,Gender ,ClothType detail already exists.');
        }
      }, error => {
        console.error('Error querying for existing Brand details:', error);
        alert('There was an error checking for existing Avatar details. Please try again.');
      });
  }


  // addDefaultBrandsCloth() {
  //   let newObject = {
  //     'BrandId': this.selectedBrand,
  //     'Gender': this.selectedGender,
  //     'GarmentId': this.selectedCloth,
  //     'GarmentType': this.selectedClothType
  //   };
  
  //   // Check if newObject properties are defined
  //   if (!newObject.BrandId || !newObject.Gender || !newObject.GarmentId || !newObject.GarmentType) {
  //     alert('Please make sure all selections are made.');
  //     return;
  //   }
  
  //   // Query Firestore to check for existing document
  //   this.firestore.collection('DefaultBrandGarments', ref => ref
  //     .where('BrandId', '==', newObject.BrandId)
  //     .where('Gender', '==', newObject.Gender)
  //     .where('GarmentType', '==', newObject.GarmentType)
  //     .where('GarmentId', '==', newObject.GarmentId)
  //   )
  //     .get()
  //     .subscribe(querySnapshot => {
  //       if (querySnapshot.empty) {
  //         // Document doesn't exist, safe to add
  //         this.firestore.collection('DefaultBrandGarments').add(newObject)
  //           .then(() => {
  //             alert('New Brand details added successfully.');
  //             // Optionally update local data or UI here
  //           })
  //           .catch(error => {
  //             console.error('Error adding Brand details:', error);
  //             alert('There was an error adding the Brand details. Please try again.');
  //           });
  //       } else {
  //         // Document exists, handle duplicate
  //         alert('This Brand, Gender, ClothType detail already exists.');
  //       }
  //     }, error => {
  //       console.error('Error querying for existing Brand details:', error);
  //       alert('There was an error checking for existing Avatar details. Please try again.');
  //     });
  // }


  getBrandDetails(id) {
    let brand = this.brandList.find((brand) => brand.id == id)
    return brand
  }

  
  getGarmrntDetails(id) {
    let cloth = this.garmentsCollection.find((cloth) => cloth.id == id)
    return cloth
  }


  deleteDefaultBrandsCloth(brand) {
    // Validation: Check if the brand object has an id
    if (!brand || !brand.id) {
      alert('Invalid brand details provided. Cannot delete.');
      return;
    }

    // Confirmation: Ask the user to confirm the deletion
    const confirmation = confirm(`Are you sure you want to delete?`);

    if (confirmation) {
      // User confirmed the deletion, proceed with the delete operation
      this.firestore.collection('DefaultBrandGarments').doc(brand.id).delete()
        .then(() => {
          // Handle successful deletion, maybe refresh the list or navigate away
          console.log(`Successfully deleted Default brand  : ${brand.BrandId}`);
          alert("Record deleted successfully")
        })
        .catch((error) => {
          // Handle errors, such as showing a user-friendly message
          console.error('Error deleting brand:', error);
          alert('An error occurred while deleting the brand. Please try again.');
        });
    } else {
      // User did not confirm the deletion, do not proceed
      console.log('Deletion cancelled by the user.');
    }
  }


  sortClothList(): void {
    this.defaultBrandClothList.sort((a, b) => {
      // Priority for 'Default' brand
      if (a.BrandId === 'Default' && b.BrandId !== 'Default') {
        return -1;
      } else if (a.BrandId !== 'Default' && b.BrandId === 'Default') {
        return 1;
      } else if (a.BrandId === b.BrandId) { // If same brand, sort by Gender
        if (a.Gender === 'M' && b.Gender === 'F') {
          return -1;
        } else if (a.Gender === 'F' && b.Gender === 'M') {
          return 1;
        } else { // If same brand and same gender, then sort by Brand alphabetically
          return a.BrandId.localeCompare(b.BrandId);
        }
      } else { // If not 'Default', sort by specific brand order
        return a.BrandId.localeCompare(b.BrandId); // Fallback to alphabetical
      }
    });
  }


  checkPageAuthorization() {
    this.loading = true;
    this.commonService.getUser().subscribe((user: any) => {
      let userObject = { 'userid': user?.uid, 'pagename': '/default-brand-garments' }
      this.commonService.checkViewPageRights(userObject).subscribe((res) => {
        console.log(res)
        this.loading = false;
        if (!res) {
          this.router.navigateByUrl("/drip");
        }
      });
    });
  }
  openGarmentPanel(event) {
    if (this.selectedBrand == '' || this.selectedCloth == '' || this.selectedClothType == '' || this.selectedGender == '') {
      alert("Please select all details.");
      return ;
  }

  // if(this.selectedBrand =="Default"){
  //   let DefaultBranD = this.garmentsCollection.find((c)=>c.id === this.selectedCloth)
  //   this.selectedBrand  = DefaultBranD
  // }
    event.stopPropagation()
    this.panelFlag = true
    this.ViewFlage = true
    this.loading=true
  }
  closePanel(){
    this.panelFlag = false
    // this.selectedCloth = ''
    // this.selectedBrand = ''
    this.ViewFlage = false
  }
  closeGarmentPanel() {
    // this.panelFlag = false
  }
  openGarmentPanelByList(object,event) {
    this.loading = true;
    console.log("object==",object)
    this.selectedGarmentObject=object
    event.stopPropagation();
    this.panelFlag = true 
    this.ViewFlage = false
    this.selectedBrand = object.BrandId
    this.selectedGender = object.Gender
    this.selectedClothType = object.GarmentType
    this.filterClothList()
    this.selectedCloth = object.GarmentId
   
  }


}
