<!-- <div>
    <iframe  [attr.src]="urlNameSecond" width="100%" height="500"></iframe>
</div> -->
<div *ngIf="loading" class="loading">Loading&#8230;</div>
<div class="app-new-three-d-file" [ngClass]="{'my-custom-class': true}">
    <div #threeDModelContainer></div>
</div>

  
  
  