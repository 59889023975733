
<app-yologram-social *ngIf="logInFlag"></app-yologram-social>
<div (scroll)="onScroll()" #brandStoriyScroll class="div-margin roksan">
    <div class=" brand-profile-color">
        <div class="brand-profile-color m-1">
            <div class="slider-css brand-profile-color" (scroll)="selectByScroll()">
                <div class="" *ngFor="let brand of brandDetailsAll" [ngClass]="byScroll != true ? 'prt-Evt' :''">
                    <img class="m-1" style="height:60px !important; width:80px !important;"
                    [ngClass]="selectedBrand == brand ? 'shadow-brand' : (selectedBrand != undefined && !byScroll ? 'blug-css' :'')" (click)="selectBrandForStory(brand.id,brand)"
                        [src]="brand.Brandurl" />
                    <div *ngIf="snapShotBrandList.indexOf(brand.id) >= 0">
                    </div>
                </div>
            </div>
            <div *ngIf="loading" class="loading">Loading&#8230;</div>
        </div>
</div>
<div class="card">
    <div class="col-8 p-3 m-2 justify-content-center">
        <div class="d-flex">
            <div class="p-1 d-block">
                <!-- (change)="selectcollectionName($event)" -->
                <label for="text1" class="col-form-label">Collection Name</label>
                <input type="text" id="text1" class="form-control" aria-describedby="text1"  [(ngModel)]="collectionName"
                    placeholder="Enter the collection Name">
                <select class="form-select option-select m-1" aria-label="Default select example" (change)="selectGender($event)">
                    <option value="" [selected]="genderBlak == ''">Select Gender</option>
                    <option value="M">Menswear</option>
                    <option value="F">Womenswear</option>
                    <option value="B">Both</option>
                </select>
                <button type="button" class="btn btn-primary btn-sm" (click)="addcollection()">Submit</button>
            </div>
        </div>
    </div>
</div>
<table class="table " id="dataTable" width="100%" cellspacing="0">
    <thead class="custom-header">
        <tr role="row">
            <th>Brand Name</th>
            <th>Collection Name</th>
            <th>Gender</th>
            <th>Edit</th>
            <th>Action</th>
            <th>Link</th>
        </tr>
    </thead>
    <tbody>
        <tr class="row-list-detail" *ngFor="let collection of collectionData let i = index">
            <td class="">{{brandName(collection.BrandId)}}</td>
            <td class="" >{{collection.CollectionName}}</td>
            <!-- <td class="" (click)="linkGarmentList(collection)"  data-bs-toggle="modal" data-bs-target="#linkeGarment">
                <div class="d-flex action">
                    <i *ngIf="filteredGarment.length >  0" class="bi bi-diagram-2" ></i>
                </div>
            </td> -->
            <td class="">{{collection.Gender}}<span *ngIf="garmentId(collection)"></span>
            </td>
            <td>
                <div class="d-flex action">
                    <i class="bi bi-pencil-square p-2 m-1" (click)="callEditcollection(collection)" data-bs-toggle="modal" data-bs-target="#pageModalLabel"></i>
                </div>
            </td>
            <td>
                <div class="form-check form-switch text-center switch">
                    <input class="form-check-input" 
                    [checked]="collection.status == 'A'  ?  true : false  "   type="checkbox" role="switch"
                      id="flexSwitchCheckDefault"
                      (change)="updateSecLinkActiveStatus($event, collection)"
                      >
                  </div>
            </td>
            <td  >
                <div  *ngIf="filteredGarment.length >  0">
                    <i  (click)="linkGarmentList(collection)"  data-bs-toggle="modal" data-bs-target="#linkeGarment" *ngIf="filteredGarment.length >  0" class="bi bi-diagram-2" ></i>                 
                  </div>
            </td>
        </tr>
    </tbody>
</table>
<div class="modal fade" id="pageModalLabel" tabindex="-1" aria-labelledby="pageModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-sm">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="pageModalLabel">Edit Collection</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
            <select class="form-select option-select m-1" disabled aria-label="Default select example"  (change)="selectBrandForStoryBrand($event)" [(ngModel)]="editcolllection['BrandName']">
                <!-- <option *ngIf="brandname != undefined &&  brandname.BrandName  != undefined" selected [value]="brandname.id">{{brandname.BrandName}}</option> -->
                <option  [selected]="editcolllection.BrandId == brand.id" *ngFor="let brand of brandDetailsAll" [value]="brand.id" selecteted>{{brand.BrandName}}</option>
            </select>
          <label class="m-2">Collection Name</label>
          <input type="text" class="form-control" placeholder="Add CollectionName "   (change)="setColllectionName($event)" [value]="editcolllection['CollectionName']">
        <select class="form-select option-select m-1" aria-label="Default select example" (change)="selectGender($event)"  >
            <option [selected]="editcolllection.Gender === 'M'" value="M">Menswear</option>
            <option [selected]="editcolllection.Gender === 'F'" value="F">Womenswear</option>
            <option [selected]="editcolllection.Gender === 'B'" value="B">Both</option>
        </select>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-sm btn-secondary" data-bs-dismiss="modal">Close</button>
          <button type="button" class="btn btn-sm btn-primary"(click)="updatecollection()" data-bs-dismiss="modal" >Save</button>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="modal fade" id="linkeGarment" tabindex="-1" aria-labelledby="linkeGarment" aria-hidden="true">
    <div class="modal-dialog modal-sm">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="linkeGarment">Link Garment</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="d-flex" *ngFor="let linked of linkGarment let i = index ">
                    <!-- <div>{{i}}</div> -->
                    <div (click)="garmentPopup(linked.GarmentID)" data-bs-toggle="modal" data-bs-target="#garment-details">{{GarmentName(linked.GarmentID)}}</div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-sm btn-secondary" data-bs-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>



<div class="modal fade" id="garment-details" tabindex="-1" aria-labelledby="garment-details" aria-hidden="true"
    data-bs-backdrop="false">
    <div class="modal-dialog" id="garment-details">
        <div class="modal-content imp-block-garment">
            <div class="modal-body">
                <h5 type="button" class="btn-close  closBtn" data-bs-dismiss="modal" aria-label="Close"></h5>
                <div class="row text-center p-2 justify-content-center">
                    <div class="col-12 m-2 details" *ngIf="clothDetails.BrandID != undefined">
                        <div class="col-lg-12 text-center w-100 m-1 d-flex justify-content-center  d-flex">
                            <div class="text-center d-flex ">
                                <img data-bs-dismiss="modal"
                                    (click)="getBrandProfileByGarment(clothDetails.BrandID,clothDetails.boardName)"
                                    class="rounded profileImg-brand"
                                    style="width:35px !important; height:35px !important;"
                                    [src]="this.url">
                                 
                            </div>
                            <div class="p-1 brand-name "><b 
                                    (click)="getBrandProfileByGarment(clothDetails.BrandID,clothDetails.boardName)" data-bs-dismiss="modal">{{brandNames}}</b>
                            </div>
                            <div><button type="button" class="btn btn-sm btn-outline-info follow">Follow</button></div>
                        </div>
                        <!-- <b>
                            <h5 class="text-black clothName" [ngClass]="clothDetails.Name.length >= 20  ? 'class-font' : ''"
                                (click)="getClothProfile(clothDetails.BrandID)"  data-bs-dismiss="modal">
                                {{(clothDetails.Name)}}</h5>

                        </b> -->
                        
                       <b>
                        <h5 data-bs-dismiss="modal"  (click)="getClothProfile(clothDetails.BrandID)"
                            [ngClass]="clothDetails.Name.length >= 20  ? 'class-font' : ''"
                            class="text-black clothName">
                                     <span *ngIf="clothDetails.RealType == '0'" class="brandbtn py-1"><img
                                            style="width:16px !important; color:black ;padding-bottom: 3px;"
                                            src="./assets/realtype/headgear.png"></span>
                                    <span *ngIf="clothDetails.RealType == '1'" class="brandbtn py-1"><img
                                            style="width:16px !important; color:black ;padding-bottom: 3px;"
                                            src="./assets/realtype/upperwear.png"></span><span *ngIf="clothDetails.RealType == '2'"
                                        class="brandbtn py-1"><img style="width:16px !important; color:black ;padding-bottom: 5px;"
                                            src="./assets/realtype/bottomwear.png"></span><span *ngIf="clothDetails.RealType == '3'"
                                        class="brandbtn py-1"><img style="width:16px !important; color:black ;padding-bottom: 3px;"
                                            src="./assets/realtype/footwear.png"></span><span *ngIf="clothDetails.RealType == '4'"
                                        class="brandbtn py-1"><img style="width:16px !important; color:black ;padding-bottom: 3px;"
                                            src="./assets/realtype/heeledfootwear.png"></span><span *ngIf="clothDetails.RealType == '5'"
                                        class="brandbtn py-1"><img style="width:16px !important; color:black ;padding-bottom: 3px;"
                                            src="./assets/realtype/fullbody.png"></span>
                            {{(clothDetails.Name)}}</h5>
                    </b>
                    <b>
                        <h6 class="color-class"> {{clothDetails.Gender == 'F' ? 'Womenswear' :
                            (clothDetails.Gender == 'M') ? 'Menswear' : 'N/A'}}</h6>
                    </b>
                       
                        <h6  (click)="getClothProfile(clothDetails.BrandID)"  data-bs-dismiss="modal" >Collection: {{clothDetails.CollectionName}}</h6>
                        <h6  (click)="getClothProfile(clothDetails.BrandID)"  data-bs-dismiss="modal" >Details: {{clothDetails.Details}}</h6>
                        <h6  (click)="getClothProfile(clothDetails.BrandID)"  data-bs-dismiss="modal" >SKU: {{clothDetails.SKU}}</h6>
                    </div>


                    <div class="row"  data-bs-dismiss="modal" (click)="getClothProfile(clothDetails.BrandID)">
                        <div class="col-6" *ngFor="let fileList of userFiledetails;let p=index">
                            <span *ngIf="p < counter">
                                <div *ngIf="fileList.fileType == 'GarmentUpload2D'">
                                    <img class="img-fluid img-thumbnail" style="height: 100px; width: 100px !important;     object-fit: contain;
                                    "
                                        src={{fileList.fileurl}}>
                                </div>
                                <div class="justify-content-center col-6 "
                                    *ngIf="fileList.fileType == 'GarmentUpload3D'">
                                    <div class="justify-content-center col-6 ">
                                        <model-viewer camera-controls camera-orbit="45deg 55deg 2.5m" ar auto-rotate
                                            src={{fileList.fileurl}} alt="A 3D avatar-model-view"
                                            data-js-focus-visible="" ar-status="not-presenting"
                                            style="height: 100px; width: 150px; float: none;">
                                        </model-viewer>
                                    </div>
                                </div>
                            </span>
                        </div>
                    </div>

                 
                 

                    <div class="p-2 m-1 text-center d-flex justify-content-center">
                        <h6 data-bs-dismiss="modal" class="text-white brandbtn m-1" (click)="getStoryCloth(clothDetails)">
                            <img style="width:15px !important; color:black" src="./assets/storystyle/story-white.png">
                        </h6>
                        <h6 data-bs-dismiss="modal" (click)="getgarmentStyle(clothDetails)"
                            class="text-white brandbtn m-1">
                            <img style="width:18px !important; color:black" src="./assets/storystyle/style-white.png">
                        </h6>
                        <h6  *ngIf="clothDetails.ThumbCenterURL  != undefined" class="m-1 brandbtn"><a href="{{clothDetails.ThumbCenterURL}}" target="_blank"><img
                              style="width:15px !important; color:black" src="./assets/details.png"></a></h6>
                              <h6  *ngIf="clothDetails.ThumbCenterURL  == undefined" class="m-1 brandbtn"><a><img style="width:15px !important; color:black" src="./assets/details-gray.png"></a></h6>

                        <h6 *ngIf="clothDetails.ThumbLeftURL  != undefined" class="m-1 brandbtn"><a href="{{clothDetails.ThumbLeftURL}}" target="_blank"><img
                              style="width:15px !important" src="./assets/gallary.png"></a></h6>
                              <h6  *ngIf="clothDetails.ThumbLeftURL  == undefined" class="m-1 brandbtn"><a><img style="width:15px !important" src="./assets/gallary-gray.png"></a></h6>

                        <h6  *ngIf="clothDetails.ThumbRightURL  != undefined" class="m-1 brandbtn"><a href="{{clothDetails.ThumbRightURL}}" target="_blank"><img
                              style="width:15px !important; color:black" src="./assets/shop.png"></a></h6>
                              <h6  *ngIf="clothDetails.ThumbRightURL  == undefined" class="m-1 brandbtn"><a><img style="width:15px !important; color:black" src="./assets/cart-gray.png"></a>
                              </h6>
                      </div>
                    <!-- <div class="p-2 m-2 justify-content-center text-center d-flex" *ngIf="clothDetails != undefined">
                        <h6 data-bs-dismiss="modal" class="text-white brandbtn m-2"
                            (click)="getStoryCloth(clothDetails)">Story</h6>
                        <h6 data-bs-dismiss="modal" (click)="getgarmentStyle(clothDetails)"
                            class="text-white brandbtn m-2">Style</h6>
                    </div> -->

                    <div class="row w-100 m-1"
                        *ngIf="clothShapShotsList.length >= 0  && clocthCollectionIdcollection != undefined && clocthCollectionIdcollection.length >= 0 ">
                        <div class="column d-block m-1" *ngFor="let snapshot of clothShapShotsList  ; let i=index;">
                            <img class="w-100 shadow-1-strong rounded" data-bs-dismiss="modal"
                            (click)="getgarmentfeed(brandDetails,clothDetails)" [src]="snapshot.SnapshotFileUrl">
                        </div>
                        <div class="columnImg d-block m-1">
                            <img class="w-80 shadow-1-strong rounded" data-bs-dismiss="modal">
                        </div>
                        <div class="columnImg d-block m-1">
                            <img class="w-80 shadow-1-strong rounded" data-bs-dismiss="modal">
                        </div>
                        <div class="columnImg d-block m-1" *ngIf="clothShapShotsList.length > 8"
                            (click)="getClothProfile(clothDetails.BrandID)">
                            <img style="margin-top: -246px;
                            margin-left: 121px;
                            width: 50px;
                            height: 50px;" class="w-80 shadow-1-strong rounded"
                                data-bs-dismiss="modal" src="./assets/plus-8-512.png">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>