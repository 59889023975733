<div #scrollContainerMUpper class="slider-css2-upper"   >
    <div  *ngFor="let object of packJsonObject;let i=index"
        id="element{{i}}" class="slider-img2-upper">
        <div class="d-flex justify-content-center" *ngIf="!zipList[object]">
            <div class="spinner-border spn-load text-white" role="status"></div>
        </div>
        <div class="upper-div">
            <img class="img-responsive-upper" *ngIf="zipList[object] != undefined"
                [src]="zipList[object] ? sanitizer.bypassSecurityTrustUrl(zipList[object]) : (zipList[object] == undefined ? './assets/realtype/planinfoicon.png' : '')"
                />
        </div>
    </div>
</div>

<!-- 
<div #slider class="slider-container" (scroll)="onScroll($event)">
    <div *ngFor="let object of packJsonObject[1].value['files'];let i=index">
        <img class="slider-image" *ngIf="zipList[object]"
        [src]="zipList[object] ? sanitizer.bypassSecurityTrustUrl(zipList[object]) : ' '"
        />
    </div>
</div> -->