import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { doc } from 'firebase/firestore';
import { Observable } from 'rxjs';
import { ApiHttpService } from 'src/app/services/api-http.service';
import { CommonService } from 'src/app/services/common.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-group-user',
  templateUrl: './group-user.component.html',
  styleUrls: ['./group-user.component.css']
})
export class GroupUserComponent implements OnInit {
  securityGroupCollection = this.firestore.collection('SecurityGroup').valueChanges({ idField: 'id' }) as Observable<[]>;
  pages = this.firestore.collection('Pages').valueChanges({ idField: 'id' }) as Observable<[]>;
  role = this.firestore.collection('GroupRole').valueChanges({ idField: 'id' }) as Observable<[]>;

  groupUserCollection = this.firestore.collection('GroupUser').valueChanges({ idField: 'id' }) as Observable<[]>;

  // securityGroupUser = this.firestore.collection('SecurityGroup').valueChanges({ idField: 'id' }) as Observable<[]>;
  value = 'Clear me';
  securityGroupAll: any = [];
  pagesObjects: any = {};
  roleobject: any = {};
  groupUser: any = {};
  selectedUser: any = {}
  securityGroupList: any = [];
  securityGroupUserList: any = [];
  uid: any;
  userUploadedAvatars: any
  loading: boolean = false;
  registerUserList: any = [];
  typeahead: FormControl = new FormControl();
  filterRegisterUserList: any = [];
  selectedUserEmail: any = "";

  selectedSecGpId: any;
  allowMultipleRef: any = "N";
  logInFlag: boolean;
  mailID: any;
  constructor(private apiHttpService: ApiHttpService, private router: Router, public commonService: CommonService, private firestore: AngularFirestore) {
    this.getFirebaseUsers();
    this.securityGroupCollection.subscribe((groupList) => {
     groupList.forEach((secGrp: any) => {
        if(secGrp.status == 'A')this.securityGroupAll.push(secGrp)
      });
    });


    this.role.subscribe(
      (role) => {
        role.forEach((role: any) => {
          this.roleobject[role.id] = role.roleobject;
        });
      });
      

      
    this.groupUserCollection.subscribe((groupUserCollection) => {
      this.groupUser = groupUserCollection;
    });
  }

  ngOnInit(): void {

    let title = 'Yologram - group-user'
    this.commonService.changeTitle(title);
    this.logInFlag = this.commonService.isLoggedIn()
    let keyObject = {
      'keyValue' : 'AllowMultipleGroup'
    }
    this.commonService.getSystemPreferenceByKey(keyObject).subscribe((systemPref: any) => {
      this.allowMultipleRef = systemPref.Value
    });

    this.checkPageAuthorization()
  }

  selectActionGroupId($event) {
    this.filterUserBySecuirtyGrpId($event.target.value)
  }

  filterUserBySecuirtyGrpId(selectedSecGrpId) {
    this.loading = true;
    this.selectedSecGpId = selectedSecGrpId
    this.securityGroupUserList = this.groupUser.filter((grpUser: any) => grpUser.SecGpId === this.selectedSecGpId)
    console.log(this.securityGroupUserList)
    this.securityGroupUserList.forEach(async (user: any) => {
      let url = environment.functionBaseUrl + "userProfileIdenifierById?id=" + user.UserId;
      await this.apiHttpService.get(url).subscribe((data: any) => {
        user['profileName'] = data.displayName != undefined ? data.displayName : user.UserId;
        user['email'] = data.email;
      },
        ((error) => {
          console.log(error);
        }
        ));

    });
    this.loading = false;
  }

  getFirebaseUsers() {
    this.commonService.getAllUserList().subscribe((data: any) => {
      this.registerUserList = data.users
      console.log('user',this.registerUserList)
    });
  }


  pageRedirect(pageName) {
    this.router.navigateByUrl(pageName);
  }

  // suggest($event) {
  //   let data 
  //   let name = this.registerUserList.find(c => c.providerData[0].email == data.startsWith($event.target.value))
  //   console.log("Mail",name.providerData[0].email)
  //   this.filterRegisterUserList = this.registerUserList.filter(c => c.email != undefined && c.email.startsWith($event.target.value) || name ).slice(0, 10);
  // }

  suggest($event) {
    let inputValue = $event.target.value.toLowerCase(); // Convert input to lowercase for case-insensitive matching
    this.filterRegisterUserList = this.registerUserList.filter(user => {
      const userEmail = user.email || (user.providerData[0] && user.providerData[0].email);
      return userEmail && userEmail.toLowerCase().startsWith(inputValue);
    }).slice(0, 10);
  }

  
  

  getValue(user) {
    this.selectedUser = user 
    this.mailID =  user.providerData[0].email
    console.log(user.providerData[0].email,user.providerData[0]?.email)
    console.log(this.mailID)
  //   let object = this.securityGroupUserList.find(userSelected => ( userSelected.email == this.selectedUser.email) );
  //   console.log('object',object)
  //   if ((object && object.email != undefined) ||  (object && object.email == undefined)) {
  //     alert("This user already exist in security group.")
  //     this.selectedUser = {};
  //     this.selectedUserEmail = "";
  //   } else {
  //     this.selectedUserEmail = this.selectedUser.email != undefined  ? this.selectedUser.email : this.mailID ;
  //     console.log('this.selectedUserEmail',this.selectedUserEmail)

  //   }
  //   this.filterRegisterUserList = [];

  // }

   let object = this.securityGroupUserList.find(userSelected => userSelected.UserId == this.selectedUser.uid);
       console.log(' object ', this.selectedUser)
    // console.log(' object ', object)
    if (object) {
      alert("This user already exists in the security group.");
      this.selectedUser = {};
      this.selectedUserEmail = "";
    } else {
      this.selectedUserEmail = this.selectedUser.email || this.mailID;
      console.log('this.selectedUserEmail', this.selectedUserEmail);
    }
     this.filterRegisterUserList = [];
  }

  async addUserInSecuirtyGrp() {
    if (this.selectedUser.uid == undefined) {
      alert("Please select user.");
      return;
    }
    let object = {
      SecGpId: this.selectedSecGpId,
      UserId: this.selectedUser.uid
    }
    if(this.allowMultipleRef == 'N') {
      let userLinkToGroupList = this.groupUser.filter((grpUser: any) => grpUser.UserId === this.selectedUser.uid);
      userLinkToGroupList.forEach(async (userGrp:any) =>{
        let id = userGrp.id;
        await this.firestore.collection("GroupUser").doc(id).delete();
      })
    }

    this.firestore.collection('GroupUser').add(object).then(() => {
      this.filterUserBySecuirtyGrpId(this.selectedSecGpId);
      // this.securityGroupUserList.push(object)
      alert("User added successfully !")
      this.selectedUser = {};
      this.selectedUserEmail = "";
    });
  }


  checkPageAuthorization() {
    this.loading = true;
    this.commonService.getUser().subscribe((user: any) => {
      let userObject = {'userid': user?.uid, 'pagename': '/group-user' }
      this.commonService.checkViewPageRights(userObject).subscribe((res) => {
      console.log('res',res)
        this.loading = false;
        if (!res) {
          this.router.navigateByUrl("/menu-page");
        }
      });
    });
  }

  deleteRegisterUser(id) {
    // this.securityGroupUserList = []
    var confirmation = confirm('Are you sure to delete this USer ?');
    if (!confirmation) return;
    this.firestore.collection("GroupUser").doc(id).delete().then(() => {
      this.filterUserBySecuirtyGrpId(this.selectedSecGpId);
    })
  }


}
