import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';
import { delay, Observable } from 'rxjs';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-group-role',
  templateUrl: './group-role.component.html',
  styleUrls: ['./group-role.component.css']
})
export class GroupRoleComponent implements OnInit {
  securityGroupCollection = this.firestore.collection('SecurityGroup').valueChanges({ idField: 'id' }) as Observable<[]>;
  grpRoleCollection = this.firestore.collection('GroupRole').valueChanges({ idField: 'id' }) as Observable<[]>;
  pageCollection = this.firestore.collection('Pages').valueChanges({ idField: 'id' }) as Observable<[]>;
  loading :boolean =false
  grpRoleList:any = []
  securityGroupAll:any=[]
  pageList : any ={}
  secGrpPages:any = [];
  selectedSecGrpId: any = "";
  appModeAlert :boolean = false;
  selectedAppMode: any= "";
  updatingFlag: boolean = false;
  logInFlag: boolean;

  constructor( private firestore: AngularFirestore,public commonService: CommonService, private router: Router) { 
    this.securityGroupCollection.subscribe((groupList) => {
      groupList.forEach((secGrp: any) => {
         if(secGrp.status == 'A') this.securityGroupAll.push(secGrp)
       });
     });

     this.grpRoleCollection.subscribe((groupList) => {
      this.grpRoleList = groupList;
     });
     
     
     this.pageCollection.subscribe((page:any) => {
      this.pageList = page;
    });
  }

  ngOnInit(): void {
    let title = 'Yologram - group-role'
    this.commonService.changeTitle(title);
    this.logInFlag = this.commonService.isLoggedIn()
    this.checkPageAuthorization()
  }
  
  selectSecGroup($event) {
    this.selectedSecGrpId = $event.target.value
    this.getPagesForSecurityGrp();
  }


  getPagesForSecurityGrp(){
    this.secGrpPages = [];   
    if(this.selectedSecGrpId == "" || this.selectedAppMode == "") {
      return;
    }
    //this.secGrpPages = this.pageList;
    if(this.selectedAppMode == "WEB"){
      this.appModeAlert = false   
      this.pageList.forEach((object) => {
        let fitlerObject = this.grpRoleList.filter((grpRole => grpRole.SecGpId == this.selectedSecGrpId && grpRole.PageId == object.id))
        object.groupRoleId = (fitlerObject.length > 0) ? fitlerObject[0].id : undefined;
        this.secGrpPages.push(object); 
      })
      this.updatingFlag = false
    }
  }




  selectAppMode($event) {
    this.selectedAppMode = $event.target.value;
    this.getPagesForSecurityGrp();
  }
  
  


  updatePageStatus($event,selectedPage){
    this.updatingFlag = true;
    let groupRoleId = selectedPage['groupRoleId']
    if(groupRoleId != undefined) {
      this.firestore.collection('GroupRole').doc(groupRoleId).delete().then(()=>{
        this.getPagesForSecurityGrp();        
      });
    } else {
      let groupRoleObject = {
        SecGpId : this.selectedSecGrpId,
        PageId : selectedPage['id']
      }
      this.firestore.collection('GroupRole').add(groupRoleObject).then(()=>{
        this.getPagesForSecurityGrp();

      });
    }
  }

  checkPageAuthorization() {
    this.loading = true;
    this.commonService.getUser().subscribe((user: any) => {
      let userObject = {'userid': user?.uid, 'pagename': '/group-role' }
      this.commonService.checkViewPageRights(userObject).subscribe((res) => {
        this.loading = false;
        if (!res) {
          this.router.navigateByUrl("yologram-social/menu-page");
        }
      });
    });
  }
}
