import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Brand } from 'src/app/models/brand';
import { ApiHttpService } from 'src/app/services/api-http.service';
import { CommonService } from 'src/app/services/common.service';
import { DataSharingService } from 'src/app/services/data-sharing-service';
import { PinterestService } from 'src/app/services/pinterest-service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-public-brand-profile',
  templateUrl: './public-brand-profile.component.html',
  styleUrls: ['./public-brand-profile.component.css']
})
export class PublicBrandProfileComponent implements OnInit {
  brandsFilesList = this.firestore.collection('brands').valueChanges({ idField: 'id' }) as Observable<Brand[]>;
  garmentsList = this.firestore.collection('garments').valueChanges({ idField: 'id' }) as Observable<any[]>;
  brandBoardList = this.firestore.collection('brand-board').valueChanges({ idField: 'id' }) as Observable<any[]>;


  userId: string;
  userSnapShotList: any = [];
  profileName: any
  userName: any = []
  userDetails: any
  filterSnapShotFileDetails: any = []
  SnapShotFileDetails: any = []
  id: any;
  isLoadingforUserDetails: boolean = true;
  profileNameSrc: any = "profile";
  brandCollection: any = {}
  brandId: any
  brandProfileDetails: any = []
  garmentsCollection: any = []
  snapShotBrandList = [];
  brandGarments: any = []
  brandBoard: any = {}
  boardDeatails: any = {}
  womensBrands: any;
  mensBrands: any;
  boardBrandList: any = []
  counter: number = 1;
  snaploader: boolean = true
  boards: any = []
  brandBoardDetails: any = {}


  constructor(
    private firestore: AngularFirestore,
    private dataSharingService: DataSharingService,
    private route: ActivatedRoute,
    private apiHttpService: ApiHttpService,
    public router: Router,
    private commonService: CommonService,
    private pinterestAPI: PinterestService


  ) {
  }
  ngOnInit(): void {
    this.snaploader = true;
    this.route.paramMap.subscribe(paramMap => {
      this.brandId = paramMap.get('brandId');
    })

    this.brandBoardList.subscribe((board) => {
      this.boardBrandList = board
    })

    this.dataSharingService.userBrandGarmentObservable.subscribe(garments => {
      this.brandGarments = garments
    })

    this.pinterestAPI.getBoardList().subscribe((data) => {
      this.boards = data.items;
      this.brandBoardList.subscribe((board) => {
        this.boardBrandList = board
        let filterboardID = this.boardBrandList.find((b) => b.brandId == this.brandId)
        this.brandBoardDetails = this.boards.find((b) => b.id == filterboardID.boardId)
      })

    })

    this.brandsFilesList.subscribe(brandList => {
      this.brandCollection = brandList
      let brandIDforProfile = this.brandCollection.find((brandListProfile) => brandListProfile.id == this.brandId)
      this.brandProfileDetails = brandIDforProfile
      this.mensBrands = brandList.filter((b: any) => b.Gender === 'M' || b.Gender === 'B');
      this.womensBrands = brandList.filter((b: any) => b.Gender === 'F' || b.Gender === 'B');
    })


    let reqInputObject = { id: this.brandId, pageNumber: 1 }
    this.commonService.yologramSnapshotByBrands(reqInputObject).subscribe(async (response: any) => {
      this.userSnapShotList = response.object
      this.snaploader = false;
    })

    this.getBoardName()
  }
  incrementImgCounter() {
    this.counter = this.counter + 1;
    let reqInputObject = { id: this.brandId, pageNumber: this.counter }
    this.commonService.yologramSnapshotByBrands(reqInputObject).subscribe(async (response: any) => {
      this.userSnapShotList.push(...response.object)
    })
  }

  getBrandImage(imgURL: string) {
    let brandEncodedurl = encodeURI(imgURL).replace('/', '%2F');
    let url = "https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/" + brandEncodedurl + "?alt=media&token=550e7e88-bed3-4668-84ff-ad491c19e06c"
    return url;
  }
  getSnapShotListByUser(userId) {
    let userSnapShotList = this.userSnapShotList.filter((snapshot) => snapshot.UserID == userId);
    this.dataSharingService.sendUserSnapShots(userSnapShotList);
    this.router.navigate(['yologram-social/yologram-public-news', { 'id': userId }])
  }

  getSelectSnapshotFeed(userSnap) {
    let userId = userSnap.UserID
    let storyid = userSnap.StoryID
    let userSnapShotList = this.userSnapShotList.filter((snapshot) => snapshot.UserID == userId && snapshot.StoryID == storyid);
    this.dataSharingService.sendUserSnapShots(userSnapShotList);
  }

  getUserProfile() {
    let userId = this.userId
    this.dataSharingService.sendUserSnapShots(userId);
    this.router.navigate(['public/news', { 'id': userId }])
  }

  getBrandStories(brand: any) {
    this.router.navigate(['public/public-brand-stories', { 'brandId': this.brandProfileDetails['id'] }])
  }

  getFeed(brand: any, brandName: any) {
    this.dataSharingService.sendUserSnapShots(this.userSnapShotList);
    if (this.brandId != undefined) { this.router.navigate(['public/public-brand-stories', { 'brandId': this.brandId, 'storyId': brand.StoryID }]) }
    else {
      this.router.navigate(['public/public-brand-stories', { 'brandId': brandName.id, 'storyId': brand.StoryID }])
    }
  }


  getBrandStyle(brandName: any, gender: any,) {
    this.dataSharingService.sendUserSnapShots(this.userSnapShotList);
    let boardName = this.brandBoard
    this.router.navigate(['public/public-brand-style', { 'brandId': brandName.id, 'Gender': gender, 'board': boardName }])
  }

  getBoardName() {
    let filterboardID = this.boardBrandList.find((b) => b.brandId == this.brandId)
  }

}


