<div class="details-all" [ngClass]="this.brandFlag    ? 'no-touch' : ''">
  <div class="text-center banner-div">
    <div class="text-center container-fluid p-1 d-flex sticky-band garment-title col-lg-12">
      <img *ngIf="brandObject.LogoURL != undefined && brandObject != undefined" class="profileImg-brand col-sm-2"
        style="width:35px; height:35px !important;" [src]="getBrandImage(brandObject?.LogoURL)"   (click)="getBrandProfileByGarment(brandObject)"  mat-dialog-close>
      <div class="text-center  garment_logo p-1 d-flex justify-content-center container-fluid  col-sm-6" (click)="getClothProfile()"  mat-dialog-close>
        <h6 class="brand-font-size color-class-band  d-flex">
           <span *ngIf="clothDetailsList?.RealType == '0'"
            class="brandbtn py-1"><img style="width:18px !important; color:black ;padding-bottom: 3px;"
              src="./assets/realtype/headgear.png"></span>
            <div class=" garment_logo">
          <span *ngIf=" clothDetailsList?.RealType == '1'" class="brandbtn py-1"><img
              style="width:18px !important; color:black ;padding-bottom: 3px;"
              src="./assets/realtype/upperwear.png"></span>
              <span *ngIf=" clothDetailsList?.RealType == '2'"
            class="brandbtn py-1"><img style="width:18px !important; color:black ;padding-bottom: 5px;"
              src="./assets/realtype/bottomwear.png"></span>
              <span *ngIf=" clothDetailsList?.RealType == '3'"
            class="brandbtn py-1"><img style="width:18px !important; color:black ;padding-bottom: 3px;"
              src="./assets/realtype/footwear.png"></span>
              <span *ngIf=" clothDetailsList?.RealType == '4'"
            class="brandbtn py-1"><img style="width:18px !important; color:black ;padding-bottom: 3px;"
              src="./assets/realtype/heeledfootwear.png"></span>
              <span *ngIf=" clothDetailsList?.RealType == '5'"
            class="brandbtn py-1"><img style="width:18px !important; color:black ;padding-bottom: 3px;"
              src="./assets/realtype/fullbody.png"></span>
           </div>
          <!-- <span>{{(clothDetailsList.Name).split()}}</span> -->
        </h6>
        <h6 *ngIf="clothDetailsList?.Name" class="class-band-name">
          {{ clothDetailsList.Name.split() }}
        </h6>
      </div>
      <div class="col-sm-2">
        <img class="profileInfo col-sm-2" *ngIf="toggaleFlag" (click)="toggale3D()"
          style="width:35px; height:35px !important;" src="./assets/realtype/info.png">

        <img class="profileInfo col-sm-2" *ngIf="!toggaleFlag" (click)="toggaleInof()" style="width: 35px !important;
          color: black;
         " src="./assets/realtype/3d-paranoma.png">
      </div>
    </div>
    <div class="static-band-five-icon col-4 text-center d-flex justify-content-center">
      <!-- <h6 class="text-primary brandbtn m-1 d-block class" (click)="getClothProfile()"  mat-dialog-close>
        <div *ngIf="(selectedTab == 'story' || selectedTab =='Menswear')  && !iconselctFlag"
                [ngClass]="selectedTab == 'story' || selectedTab =='Menswear'    ? 'story-menu-card'  : 'story-menu-icon'">
                <img style="width: 15px;" src="./assets/storystyle/story-white.png">
            </div>
        <img class="story-menu-icon" style="color:black ;margin-top: 0px; width: 15px;"
          src="./assets/storystyle/storyblack.png">

        <h6 *ngIf="(selectedTab == 'story' || selectedTab =='Menswear') && !iconselctFlag" class="selected-name m-1">
          Story
        </h6>
      </h6> -->
      <h6 class="text-primary brandbtn m-1 d-block class"
      (click)="getClothProfile()" mat-dialog-close>
      <div *ngIf="selectedIcon == 'popup'"
          [ngClass]="selectedIcon == 'popup'    ? 'story-menu-card'  : 'story-menu-icon'">
          <img style="width: 15px;" src="./assets/storystyle/story-white.png">
      </div>
      <img *ngIf="selectedIcon != 'popup'" class="story-menu-icon"
          style="color:black ;margin-top: 0px; width: 15px;"
          src="./assets/storystyle/storyblack.png">

      <h6 *ngIf="selectedIcon == 'popup'" class="selected-name m-1">Story</h6>
  </h6>

  <h6 (click)="selectIcon('detail')" *ngIf="clothDetails?.ThumbCenterURL  != undefined" class="m-1 brandbtn">
        <div *ngIf="selectedIcon == 'detail'"
          [ngClass]="selectedIcon == 'detail' ? 'story-link-menu' : 'story-menu-icon'">
          <a href="{{clothDetails?.ThumbCenterURL}}" target="_blank">
            <img style="width: 15px;" src="./assets/details.png"></a>
        </div>
        <a href="{{clothDetails?.ThumbCenterURL}}" target="_blank"><img *ngIf="selectedIcon != 'detail'"
            [ngClass]="selectedIcon != 'detail' ? 'story-menu-icon' : ''" style="color:black ;"
            src="./assets/storystyle/newStoryIcon/detailGray.png"></a>

        <h6 *ngIf="selectedIcon == 'detail'" class="selected-name m-1">Details</h6>
      </h6>
      <h6 *ngIf="clothDetails?.ThumbCenterURL  == undefined" class="m-1 brandbtn"><a><img
            style="width:15px !important; color:black" src="./assets/storystyle/newStoryIcon/detailGrayLight.png"></a>
      </h6>
      <h6 (click)="selectIcon('gallary')" *ngIf="clothDetails?.ThumbLeftURL  != undefined" class="m-1 brandbtn">
        <div *ngIf="selectedIcon == 'gallary'"
          [ngClass]="selectedIcon == 'gallary' ? 'story-link-menu' : 'story-menu-icon'">
          <a href="{{clothDetails?.ThumbLeftURL}}" target="_blank">
            <img style="width: 15px;" src="./assets/gallary.png"></a>
        </div>
        <a href="{{clothDetails?.ThumbLeftURL}}" target="_blank"><img *ngIf="selectedIcon != 'gallary'"
            [ngClass]="selectedIcon != 'gallary' ? 'story-menu-icon' : ''" style="color:black;"
            src="./assets/storystyle/newStoryIcon/gallaryGray.png"></a>
        <h6 *ngIf="selectedIcon == 'gallary'" class="selected-name m-1">Gallary</h6>
      </h6>
      <h6 *ngIf="clothDetails?.ThumbLeftURL  == undefined" class="m-1 brandbtn"><a><img style="width:15px !important ;"
            src="./assets/storystyle/newStoryIcon/gallaryGrayLight.png"></a>
      </h6>
      <h6 (click)="selectIcon('cart')" *ngIf="clothDetails?.ThumbRightURL  != undefined" class="m-1 brandbtn">
        <div *ngIf="selectedIcon == 'cart'" [ngClass]="selectedIcon == 'cart' ? 'story-link-menu' : 'story-menu-icon'">
          <a href="{{clothDetails?.ThumbRightURL}}" target="_blank">
            <img style="width: 15px;" src="./assets/shop.png"></a>
        </div>
        <a href="{{clothDetails?.ThumbRightURL}}" target="_blank"><img *ngIf="selectedIcon != 'cart'"
            [ngClass]="selectedIcon != 'cart' ? 'story-menu-icon' : ''" style="color:black; width: 15px;"
            src="./assets/storystyle/newStoryIcon/cartGray.png"></a>
        <h6 *ngIf="selectedIcon == 'cart'" class="selected-name m-1">Shopping</h6>
      </h6>
      <h6 *ngIf="clothDetails?.ThumbRightURL  == undefined" class="m-1 brandbtn"><a><img
            style="width:15px !important; color:black" src="./assets/storystyle/newStoryIcon/cartGrayLight.png"></a>
      </h6>
    </div>
  </div>


  <span *ngIf="!toggaleFlag  ">
    <div>
      <div class="d-flex twoDImage">
        <div *ngIf="image2DFlag" class="text-center">
          <div class="spinner-border m-5" role="status">
            <span class="sr-only"></span>
          </div>
        </div>
        <div class="text-center col-12 d-flex" *ngIf="userFiledetails.length == 0">
          <div class="text-center col-6">
            <img class="img-fluid img-thumbnail"
              style="    height: 160px;
              width: 140px !important;
              object-fit: contain;                                                                               "
              src="./assets/storystyle/twoDImage.jpeg">
          </div>
          <div class="text-center col-6">
            <img class="img-fluid img-thumbnail"
              style="    height: 160px;
              width: 150px !important;
              object-fit: contain;                                                                   "
              src="./assets/storystyle/threeDImage.jpeg">
          </div>
        </div>
        <div data-bs-dismiss="modal" class="text-center col-6" *ngFor="let fileList of userFiledetails;let p=index">
          <span *ngIf="!image2DFlag">
            <span *ngIf="p < counter">
              <div *ngIf=" fileList.fileType == 'GarmentUpload2D'">
                <img (click)="getgarmentStylefeed()" class="img-fluid img-thumbnail" style="height: 160px;
                width: 140px !important;
                object-fit: contain;
                margin: 2px;"
                src={{fileList.fileurl}} mat-dialog-close>
              </div>
              <div class="justify-content-center col-6 " *ngIf="fileList.fileType == 'GarmentUpload3D'">
                <div (click)="getgarmentStylefeed()" class="text-center col-6 " mat-dialog-close>
                  <model-viewer camera-controls camera-orbit="45deg 55deg 2.5m" ar auto-rotate src={{fileList.fileurl}}
                    alt="A 3D avatar-model-view" data-js-focus-visible="" ar-status="not-presenting"
                    style="height: 150px; width: 140px; float: none;">
                  </model-viewer>
                </div>
              </div>
            </span>
          </span>
        </div>
      </div>
      <div (click)="getgarmentStylefeed()" class="text-white details-collection p-1" mat-dialog-close>
        <h6>
          <span class="headingName">Collection:</span>
          <h6 *ngIf="clothDetails != undefined && clothDetails.CollectionName != undefined">
            {{(clothDetails?.CollectionName).substring(0,45)}}...</h6>
        </h6>
        <h6><span class="headingName">Details:</span>
          <h5 class="headingcollection" *ngIf="clothDetails != undefined && clothDetails?.Details != undefined">
            {{(clothDetails.Details).substring(0,394)}}...</h5>
          <h5 class="headingcollection" *ngIf="clothDetails != undefined && clothDetails?.Details == undefined">
            Your brand's apparel is transformed onto a 3D gamified
            interface where users enjoy styling their own or the brand’s avatars &
            sharing playful, animated avatar stories that are linked into
            the garment details as well as the options to buy physical or
            digital apparel. This defines a new paradigm in immersive
            storytelling for social media & the metaverse.</h5>
        </h6>
        <h6 class="headingName">SKU: <h6 class="headingName" *ngIf="clothDetails != undefined && clothDetails.SKU != undefined">
          {{(clothDetails?.SKU).substring(0,45)}}...</h6></h6>

       
      </div>
    </div>
  </span>

  <!-- 3D images -->
  <span *ngIf="toggaleFlag">
    <div   class="m-1" [ngClass]="pageType == 'style' ? 'style-bg' : 'garment-bg'" id="carouselExampleFade"
    *ngIf="garmentType == 'headgear'" 
    data-bs-interval="1000"

    class="carousel slide carousel-fade" 
    data-bs-ride="carousel">
      <div class="carousel-inner footwear-popup">
        <div class="carousel-item ">
          <img class="footwear-img8" [src]="garmentImg" />
        </div>
        <div class="carousel-item ">
          <img class="footwear-img7" [src]="garmentImg" />
        </div>
        <div class="carousel-item ">
          <img class="footwear-img2" [src]="garmentImg" />
        </div>
        <div class="carousel-item ">
          <img class="footwear-img6" [src]="garmentImg" />
        </div>
        <div class="carousel-item active">
          <img class="footwear-img5" [src]="garmentImg" />
        </div>
        <div class="carousel-item ">
          <img class="footwear-img1" [src]="garmentImg" />
        </div>
        <div class="carousel-item ">
          <img class="footwear-img4" [src]="garmentImg" />
        </div>
        <div class="carousel-item ">
          <img class="footwear-img3" [src]="garmentImg" />
        </div>
      </div>
      <button  class="carousel-control-prev text-black" type="button" data-bs-target="#carouselExampleFade"
        data-bs-slide="prev">
        <span class="carousel-control-prev-icon text-black" aria-hidden="true"></span>
        <span class="visually-hidden text-black">Previous</span>
      </button>
      <button   class="carousel-control-next" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="next">
        <span class="carousel-control-next-icon text-black" aria-hidden="true"></span>
        <span class="visually-hidden text-black">Next</span>
      </button>
    </div>
    <div    class="m-1 class-div" [ngClass]="pageType  == 'style'? 'style-bg' : 'garment-bg'" id="carouselExampleFade"
      *ngIf=" garmentType  == 'upperwear'"  data-bs-interval="1000"  class="carousel slide carousel-fade"
      data-bs-ride="carousel">
      <div class="carousel-inner upperwear-popup ">
        <div class="carousel-item ">
          <img class="upperwear-img4" [src]="garmentImg" />
        </div>
        <div class="carousel-item">
          <img class="upperwear-img6" [src]="garmentImg" />
        </div>
        <div class="carousel-item ">
          <img class="upperwear-img8" [src]="garmentImg" />
        </div>
        <div class="carousel-item ">
          <img class="upperwear-img3" [src]="garmentImg" />
        </div>
        <div class="carousel-item active ">
          <img class="upperwear-img5" [src]="garmentImg" />
        </div>
        <div class="carousel-item ">
          <img class="upperwear-img7" [src]="garmentImg" />
        </div>
        <div class="carousel-item ">
          <img class="upperwear-img1" [src]="garmentImg" />
        </div>
        <div class="carousel-item ">
          <img class="upperwear-img2" [src]="garmentImg" />
        </div>
      </div>
      <button class="carousel-control-prev text-black" type="button" data-bs-target="#carouselExampleFade"
        data-bs-slide="prev">
        <span class="carousel-control-prev-icon text-black" aria-hidden="true"></span>
        <span class="visually-hidden text-black">Previous</span>
      </button>
      <button   class="carousel-control-next text-black" type="button" data-bs-target="#carouselExampleFade"
        data-bs-slide="next">
        <span class="carousel-control-next-icon text-black" aria-hidden="true"></span>
        <span class="visually-hidden text-black">Next</span>
      </button>
    </div>
    <div   class="m-1" id="carouselExampleFade" [ngClass]="pageType  == 'style'? 'style-bg' : 'garment-bg'"
      *ngIf="garmentType == 'bottomwear'"  data-bs-interval="1000"  class="carousel slide carousel-fade"
      data-bs-ride="carousel">
      <div class="carousel-inner upperwear-popup ">
        <div class="carousel-item ">
          <img class="upperwear-img4" [src]="garmentImg" />
        </div>
        <div class="carousel-item">
          <img class="upperwear-img6" [src]="garmentImg" />
        </div>
        <div class="carousel-item ">
          <img class="upperwear-img8" [src]="garmentImg" />
        </div>
        <div class="carousel-item ">
          <img class="upperwear-img3" [src]="garmentImg" />
        </div>
        <div class="carousel-item active ">
          <img class="upperwear-img5" [src]="garmentImg" />
        </div>
        <div class="carousel-item ">
          <img class="upperwear-img7" [src]="garmentImg" />
        </div>
        <div class="carousel-item ">
          <img class="upperwear-img1" [src]="garmentImg" />
        </div>
        <div class="carousel-item ">
          <img class="upperwear-img2" [src]="garmentImg" />
        </div>
      </div>
      <button   class="carousel-control-prev text-black" type="button" data-bs-target="#carouselExampleFade"
        data-bs-slide="prev">
        <span class="carousel-control-prev-icon text-black" aria-hidden="true"></span>
        <span class="visually-hidden text-black">Previous</span>
      </button>
      <button  class="carousel-control-next text-black" type="button" data-bs-target="#carouselExampleFade"
        data-bs-slide="next">
        <span class="carousel-control-next-icon text-black" aria-hidden="true"></span>
        <span class="visually-hidden text-black">Next</span>
      </button>
    </div>
    <div   class="m-1" [ngClass]="pageType  == 'style'? 'style-bg' : 'garment-bg'" id="carouselExampleFade"
      *ngIf="garmentType == 'footwear' " data-bs-interval="1000"  class="carousel slide carousel-fade"
      data-bs-ride="carousel">
      <div class="carousel-inner footwear-popup">
        <div class="carousel-item ">
          <img class="footwear-img8" [src]="garmentImg" />
        </div>
        <div class="carousel-item ">
          <img class="footwear-img7" [src]="garmentImg" />
        </div>
        <div class="carousel-item ">
          <img class="footwear-img2" [src]="garmentImg" />
        </div>
        <div class="carousel-item ">
          <img class="footwear-img6" [src]="garmentImg" />
        </div>
        <div class="carousel-item active">
          <img class="footwear-img5" [src]="garmentImg" />
        </div>
        <div class="carousel-item ">
          <img class="footwear-img1" [src]="garmentImg" />
        </div>
        <div class="carousel-item ">
          <img class="footwear-img4" [src]="garmentImg" />
        </div>
        <div class="carousel-item ">
          <img class="footwear-img3" [src]="garmentImg" />
        </div>
      </div>
      <button  class="carousel-control-prev text-black" type="button" data-bs-target="#carouselExampleFade"
        data-bs-slide="prev">
        <span class="carousel-control-prev-icon text-black" aria-hidden="true"></span>
        <span class="visually-hidden text-black">Previous</span>
      </button>
      <button   class="carousel-control-next" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="next">
        <span class="carousel-control-next-icon text-black" aria-hidden="true"></span>
        <span class="visually-hidden text-black">Next</span>
      </button>
    </div>
    <div [ngClass]="!this.brandFlag    ? 'd-none' : ''" class="text-white"> 
      <span>Loading{{dots}}</span>
   </div>
  </span>











  
  <!-- 5 icons-->
  <!-- <div class="text-center details d-flex justify-content-center icon-stand">
    <h6 data-bs-dismiss="modal" class="text-white brandbtn ">
      <img (click)="getClothProfile()" style="width:15px !important; color:black"
        src="../assets/storystyle/newStoryIcon/newStoryBlack.png" mat-dialog-close>
    </h6>
    <h6 data-bs-dismiss="modal" class="text-white brandbtn ">
      <img (click)="getgarmentStyle()" style="width:15px !important; color:black"
        src="./assets/storystyle/newStoryIcon/styleIcon.png" mat-dialog-close>
    </h6>
    <h6 *ngIf="clothDetailsList.ThumbCenterURL   != undefined " class="brandbtn"><a
        [href]="clothDetailsList.ThumbCenterURL" target="_blank"><img style="width:15px !important; color:black"
          src="./assets/storystyle/newStoryIcon/detailGray.png"></a>
    </h6>
    <h6 *ngIf="clothDetailsList.ThumbCenterURL  == null" class=" brandbtn"><a><img
          style="width:15px !important; color:black" src="./assets/storystyle/newStoryIcon/detailGrayLight.png"></a>
    </h6>
    <h6 *ngIf="clothDetailsList.ThumbLeftURL  != undefined" class=" brandbtn"><a [href]="clothDetailsList.ThumbLeftURL"
        target="_blank"><img style="width:15px !important"
          src="./assets/storystyle/newStoryIcon/gallaryGrayLight.png"></a></h6>
    <h6 *ngIf="clothDetailsList.ThumbLeftURL == null" class=" brandbtn"><a><img style="width:15px !important"
          src="./assets/storystyle/newStoryIcon/gallaryGrayLight.png"></a></h6>
    <h6 *ngIf="clothDetailsList.ThumbRightURL  != undefined " class=" brandbtn"><a
        [href]="clothDetailsList.ThumbRightURL" target="_blank"><img style="width:15px !important; color:black"
          src="./assets/storystyle/newStoryIcon/cartGray.png"></a>
    </h6>
    <h6 *ngIf="clothDetailsList.ThumbRightURL == null" class=" brandbtn"><a><img
          style="width:15px !important; color:black" src="./assets/storystyle/newStoryIcon/cartGrayLight.png"></a>
    </h6>
  </div> -->


  <!-- bottom 3 images with plus buttom -->
  <div class="row w-90  details image-marg justify-content-center">
    <div class="column d-block " *ngFor="let snapshot of clothShapShotsListAll  ; let i=index;">
      <img (click)="getgarmentStylefeed()" class="w-100 shadow-1-strong rounded" mat-dialog-close
        src="{{snapshot.SnapshotFileUrl}}">
    </div>
    <div class="columnImg d-block">
      <img (click)="getClothProfile()" *ngIf="clothShapShotsListAll.length > 2"
        class="w-80 shadow-1-strong rounded image-pop" mat-dialog-close src="./assets/plus-8-512.png">
    </div>
  </div>
</div>