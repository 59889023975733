import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

interface PerformanceMemory {
  jsHeapSizeLimit: number;
  totalJSHeapSize: number;
  usedJSHeapSize: number;
}

@Injectable({
  providedIn: 'root'
})
export class GlobalStateService implements OnDestroy {
  private cache: Map<string, any> = new Map();
  private beforeUnloadHandler: () => void;
  private unsubscribe$: Subject<void> = new Subject<void>();
  initialMemoryUsage: PerformanceMemory | null = null;
  finalMemoryUsage: PerformanceMemory | null = null;

  constructor() {
    // Listen for beforeunload event to trigger cleanup
    this.beforeUnloadHandler = () => {
      console.log('Cleanup triggered');
      this.logFinalMemoryUsage();
      this.cleanup();
    };
    window.addEventListener('beforeunload', this.beforeUnloadHandler);
  }

  async initializeApp() {
    // Initialization tasks
    await this.cleanup();
    console.log('App initialized');

    // Log initial memory usage if supported
    this.initialMemoryUsage = this.getMemoryUsage();
    if (this.initialMemoryUsage) {
      const memoryUsageMB = this.initialMemoryUsage.usedJSHeapSize / (1024 * 1024);
      console.log('Initial Memory Usage:', memoryUsageMB.toFixed(2), 'MB');
    } else {
      console.log('Initial Memory Usage: null');
    }
    
    // Force garbage collection (in development)
    if ((window as any).gc) {
      console.log('Forcing garbage collection');
      (window as any).gc();
    }

    // Log memory usage after forcing garbage collection
    const postGCUsage = this.getMemoryUsage();
    if (postGCUsage) {
      const postGCMemoryUsageMB = postGCUsage.usedJSHeapSize / (1024 * 1024);
      console.log('Memory Usage after GC:', postGCMemoryUsageMB.toFixed(2), 'MB');
    }
  }

  setCache(key: string, value: any) {
    this.cache.set(key, value);
  }

  async cleanup() {
    // Clear internal cache
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.cache.clear();
    console.log('Internal cache cleared');

    // Clear browser cache
    await this.clearBrowserCache();

    // Clear local storage and session storage


    // Attempt to clear JavaScript heap memory by dereferencing large objects
    this.clearHeapMemory();

    // Force garbage collection (in development)
    if ((window as any).gc) {
      console.log('Forcing garbage collection');
      (window as any).gc();
    }
  }

  private async clearBrowserCache() {
    if ('caches' in window) {
      console.log("caches",'caches' in window)
      const cacheNames = await caches.keys();
      console.log("caches", cacheNames)
      for (const cacheName of cacheNames) {
        const success = await caches.delete(cacheName);
        if (success) {
          console.log(`Cache ${cacheName} deleted successfully`);
        } else {
          console.log(`Cache ${cacheName} could not be deleted`);
        }
      }
    }
  }


  private clearHeapMemory() {
    // Attempt to clear heap memory by dereferencing
    this.cache.forEach((value, key) => {
      this.cache.set(key, null);
    });
    console.log('Attempted to clear heap memory');
  }

  ngOnDestroy() {
    // Remove the event listener to avoid memory leaks
    window.removeEventListener('beforeunload', this.beforeUnloadHandler);

    // Trigger the unsubscribe subject to clean up observables
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private getMemoryUsage(): PerformanceMemory | null {
    if (window.performance && (window.performance as any).memory) {
      return (window.performance as any).memory as PerformanceMemory;
    } else {
      console.warn('Memory API not supported in this browser');
      return null;
    }
  }

  private logFinalMemoryUsage() {
    this.finalMemoryUsage = this.getMemoryUsage();
    if (this.finalMemoryUsage) {
      const memoryUsageMB = this.finalMemoryUsage.usedJSHeapSize / (1024 * 1024);
      console.log('Final Memory Usage:', memoryUsageMB.toFixed(2), 'MB');
    } else {
      console.log('Final Memory Usage: null');
    }
  }
}
