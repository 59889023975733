<div class="font-14 brand-profile-color-all d-flex " (scroll)="scrollHandler()" #stylesnapShotFeeds>
   
    <div class="m-3 p-2" *ngIf="tab =='news-stories'" [ngClass]=" tab =='news-stories' ? '' : 'hidden-class'">
        <app-user-story [userSnapShotsInput]="userSnapShotList" [BrandId]="brandId"
            [thresholdHeightPagination]="thresholdHeightPagination" [StoryIds]="selectedStoryId" [PageCount]="pagecount"
            [pageNumber]="pageNumber" (outPageNumber)="setPageNumber($event)" [clickEventFlag]="clickEventFlag"
            [rootFlag]="stylePageFlag" [stylePage]="stylePage" [routLogInFlag]='routLogInFlag'></app-user-story>
    </div>

    <div *ngIf="tab =='story' " class=" mb-5  my-story ">
        <div class=" row m-1 p-1 justify-content-center"
            *ngIf="userSnapShotList.length >= 0  && userSnapShotList != undefined && userSnapShotList.length >= 0">
            <div class="column d-block " *ngFor="let snapshot of userSnapShotList; let i=index;">
                <img class="columnImg d-block  shadow-1-strong rounded mb-1 " (click)="scrollToStoryId(snapshot)"
                    [src]="snapshot.SnapshotFileUrl">
            </div>
            <div class="col-12 text-center text-white stify-content-center">
                <h6 *ngIf="this.pagecount > this.userSnapShotList.length" (click)="incrementImgCounter()"><b>More</b>
                </h6>
            </div>
        </div>
    </div>
    

   

    <div class="menu-arrow" [ngClass]="closeDiv ? 'menu-arrow-left' : 'menu-arrow'">
        <i (click)="closeNav()" *ngIf="closeDiv" class="bi bi-arrow-left-circle text-white"
            [ngClass]="!isShowBrandBand ? '' : 'd-none'"></i>
        <i (click)="openNav()" *ngIf="!closeDiv" class="bi bi-arrow-right-circle text-white"
            [ngClass]="!isShowBrandBand ? '' : 'd-none'"></i>
    </div>
    <div id="mySidenav" class="sidenav" [ngClass]="!isShowBrandBand ? '' : 'd-none'">
        <!-- && tab !='news-stories' -->
        <div [ngClass]="closeDiv  && (tab =='womens-wear' || tab =='mens-wear') ? '' : 'd-none'">
            <div class="contain-fluid"
                [ngClass]="brandObject != undefined && (tab =='womens-wear' || tab =='mens-wear') ? '' : 'd-none'">
                <div class="pb-2 garment-alingn">
                    <!-- <div *ngIf="boardDetails != undefined || boardDetails == undefined && selectedBrand"
                    class="justify-content-center text-end container garment-alingn container-fluid -1">
                    <h6 class="color-class-board m-2 "
                        *ngIf="boardDetails != undefined  && boardDetails.name != undefined "><img
                            style="width:12px !important" src="./assets/pinterest-512.png">:{{boardDetails.name}}girt 
                    </h6>
                    <h6 class="color-class-board m-2"
                        *ngIf="boardDetails == undefined || boardDetails.name == undefined">
                        <img style="width:12px !important" src="./assets/pinterest-512.png">:Not Linked
                    </h6>
                </div> -->
                    <div class="col-lg-12 justify-content-center garmentName"
                        *ngIf="tab == 'mens-wear' ||  tab == 'womens-wear'">
                        <div class="contain-fluid m-1 " *ngIf="brandObject != undefined">
                            <div class="col-lg-12  pt-4px  text-center w-100  d-flex justify-content-center  d-flex">
                                <div class="text-center d-flex col-2">
                                    <img class="profileImg-brand" style="width:35px; height:35px !important;"
                                        [src]="getBrandImage(brandObject.LogoURL)">
                                </div>
                                <div class="m-0 outer col-8">
                                    <div class="col-12 container vertical-center">
                                        <h6 class="color-class brand-font-size">{{brandObject.BrandName}}</h6>
                                    </div>
                                </div>
                                <div class="m-0 col-2 text-end">
                                    <span><img *ngIf="heartFlag == true" class="heart"
                                            style="width:18px !important; color:black"
                                            src="./assets/storystyle/newStoryIcon/heart.png"></span>
                                    <span (click)="clickOnHeart()"><img *ngIf="heartFlag == false" class="heart"
                                            style="width:18px !important; color:black"
                                            src="./assets/storystyle/newStoryIcon/heartGray.png">
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="justify-content-center text-center d-flex brand-profile-sy p-1" >
                            <div class="justify-content-center text-center d-flex set-margin">
                                <h6 class="text-primary brandbtn m-1 py-2 d-block color-class"
                                    (click)="setTab('story','')">
                                    <div *ngIf="tab == 'story'"
                                        [ngClass]="tab == 'story' ? 'story-menu-card' : 'story-menu-icon'">
                                        <img style="width: 15px;" src="./assets/storystyle/storyblack.png">
                                    </div>
                                    <img *ngIf="tab != 'story'" [ngClass]="tab != 'story' ? 'story-menu-icon' : ''"
                                        style="color:black ;padding-bottom: 5px;"
                                        src="./assets/storystyle/story-white.png">
                                    <h6 *ngIf="tab == 'story'" class="color-class-sy m-1">Stories</h6>
                                </h6>
                                <h6 class="text-primary brandbtn m-1 py-2 d-block color-class" [ngClass]="this.loadingState   ? 'loadingState-disabled' : ''">
                                    <img *ngIf="tab == 'mens-wear'"
                                        [ngClass]="tab == 'mens-wear' ? 'story-menu' : 'story-menu-icon'"
                                        style="color:black ;padding-bottom: 5px;"
                                        src="./assets/storystyle/MenIconBlack.png">
                                    <img (click)="setTab('mens-wear','M')" *ngIf="tab != 'mens-wear'"
                                        [ngClass]="tab != 'mens-wear' ? 'story-menu-icon' : ''"
                                        style="color:black ;padding-bottom: 5px;"
                                        src="./assets/storystyle/MenIconWhite.png">
                                    <h6 *ngIf="tab == 'mens-wear'" class="color-class-sy m-1">MensWear</h6>
                                </h6>
                                <h6 class="text-primary brandbtn m-1 py-2 d-block color-class" [ngClass]="this.loadingState   ? 'loadingState-disabled' : ''">
                                    <img *ngIf="tab == 'womens-wear'"
                                        [ngClass]="tab == 'womens-wear' ? 'story-menu' : 'story-menu-icon'"
                                        style="color:black ;padding-bottom: 5px;"
                                        src="./assets/storystyle/WomenIconBlack.png">
                                    <img (click)="setTab('womens-wear','F')" *ngIf="tab != 'womens-wear'"
                                        [ngClass]="tab != 'womens-wear' ? 'story-menu-icon' : ''"
                                        style="color:black ;padding-bottom: 5px;"
                                        src="./assets/storystyle/WomenIconWhite.png">
                                    <h6 *ngIf="tab == 'womens-wear'" class="color-class-sy m-1">Womenswear</h6>
                                </h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-12 p-1">
                <span *ngIf="errMessage != '' && tab !='story' &&  tab !='news-stories' && this.brandId != undefined "
                    class="danger">
                    <div class="alert alert-danger" role="alert">
                        {{errMessage}}
                    </div>
                </span>
                <div *ngIf="isLoadingStyle" class="d-flex justify-content-center">
                    <div class="spinner-border text-white" role="status">
                        <span class=""></span>
                    </div>
                </div>
            </div>
            <div (click)="closeNav()" *ngIf="tab =='mens-wear' &&  isLoadingStyle == false "
                class="d-flex  my" [ngClass]="this.loadingState   ? 'no-touch' : ''">
                <div class="file " *ngIf="!isLoadingStyle" >
                    <div class="row form d-block " *ngIf="packJsonObject.length >= 0 && packJsonObject[0] != undefined">
                        <div class="slider-css211 ">
                            <div *ngFor="let object of packJsonObject[0].value['files'];let i=index"
                                class="slider-img211">
                                <img class="img-responsive1"
                                    [src]="zipList[object] ? sanitizer.bypassSecurityTrustUrl(zipList[object]) : ' '"
                                    (click)="DeatailsPopup(zipList[object],'headgear', object,'HeadwearID',brandFlag )">
                                <img (click)="open3Dflag(zipList[object],'headgear',object ,'UpperwearID')"
                                    class="profileInfo headgear_info col-sm-2"
                                    style="width:20px; height:20px !important;"
                                    src="./assets/realtype/planinfoicon.png">
                            </div>
                        </div>
                        <div class="slider-css2-upper">
                            <div *ngFor="let object of packJsonObject[1].value['files'];let i=index"
                                class="slider-img2-upper">
                                <div class="upper-div">
                                    <img class="img-responsive-upper"
                                        [src]="zipList[object] ? sanitizer.bypassSecurityTrustUrl(zipList[object]) : ' '"
                                        (click)="DeatailsPopup(zipList[object],'upperwear',object ,'UpperwearID',brandFlag)" />
                                </div>
                                <img (click)="open3Dflag(zipList[object],'upperwear',object ,'UpperwearID')"
                                    class="profileInfo  upperwear_info col-sm-2"
                                    style="width:20px; height:20px !important;" src="/assets/realtype/planinfoicon.png">
                            </div>
                        </div>

                        <div class="slider-css2">
                            <div *ngFor="let object of packJsonObject[2].value['files'];let i=index"
                                class="slider-img2">
                                <img (click)="DeatailsPopup(zipList[object],'bottomwear', object,'BottomwearID',brandFlag )"
                                    class="img-responsive"
                                    [src]="zipList[object] ? sanitizer.bypassSecurityTrustUrl(zipList[object]) : ' '">
                                <img (click)="open3Dflag(zipList[object],'bottomwear', object,'BottomwearID')"
                                    class="profileInfo bottomwear_info col-sm-2"
                                    style="width:20px; height:20px !important;"
                                    src="./assets/realtype/planinfoicon.png">
                            </div>
                        </div>
                        <div class="slider-css211">
                            <div *ngFor="let object of packJsonObject[3].value['files'];let i=index"
                                class="slider-img211">
                                <img class="img-responsive1"
                                    [src]="zipList[object] ? sanitizer.bypassSecurityTrustUrl(zipList[object]) : ' '"
                                    (click)="DeatailsPopup(zipList[object],'footwear', object,'FootwearID' ,brandFlag)">
                                <img (click)="open3Dflag(zipList[object],'footwear', object,'FootwearID')"
                                    class="profileInfo   footwear_info col-sm-2"
                                    style="width:20px; height:20px !important;"
                                    src="./assets/realtype/planinfoicon.png">
                            </div>

                        </div>
                    </div>
                </div>
                <div class="collap-popup p-1" style="min-height: 100px;">
                    <div class="collapse collapse-horizontal">
                        <div class="card card-body d-flex imp-block text-center" style="width: 70px;"
                            saria-expanded="false">
                            <h6 (click)="infoPopup()"><i class="bi bi-info-circle">info</i></h6>
                            <h6 (click)="threeDPopup()"><i class="bi bi-badge-3d p-1"></i>3D</h6>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="tab =='womens-wear' &&  isLoadingStyle == false " class="d-flex  my" [ngClass]="this.loadingState   ? 'no-touch' : ''">
                <div class=" file ">
                    <div class="row form d-block "
                        *ngIf="packJsonObject.length > 0 && packJsonObject[0].value != undefined">
                        <div class=" file" *ngIf="!isLoading ">
                            <div class="row form d-block " *ngIf="packJsonObject.length > 0">
                                <div class="slider-css211 ">
                                    <div *ngFor="let object of packJsonObject[0].value['files'];let i=index"
                                        class="slider-img211">
                                        <img class="img-responsive1"
                                            [src]="zipList[object] ? sanitizer.bypassSecurityTrustUrl(zipList[object]) : ' '"
                                            (click)="DeatailsPopup(zipList[object],'headgear',object, 'HeadwearID',brandFlag )">
                                        <img (click)="open3Dflag(zipList[object],'headgear',object ,'UpperwearID')"
                                            class="profileInfo headgear_info col-sm-2"
                                            style="width:20px; height:20px !important;"
                                            src="./assets/realtype/planinfoicon.png">
                                    </div>

                                </div>
                                <div class="slider-css2-upper">
                                    <div *ngFor="let object of packJsonObject[1].value['files'];let i=index"
                                        class="slider-img2-upper">
                                        <div class="upper-div">
                                            <img class="img-responsive-upper"
                                                [src]="zipList[object] ? sanitizer.bypassSecurityTrustUrl(zipList[object]) : ' '"
                                                (click)="DeatailsPopup(zipList[object],'upperwear',object,'UpperwearID',brandFlag)" />
                                            <img (click)="open3Dflag(zipList[object],'upperwear',object ,'UpperwearID')"
                                                class="profileInfo   col-sm-2"
                                                style="width:20px; height:20px !important;"
                                                src="./assets/realtype/planinfoicon.png">
                                        </div>
                                    </div>
                                </div>
                                <div class="slider-css2">
                                    <div *ngFor="let object of packJsonObject[2].value['files'];let i=index"
                                        class="slider-img2">
                                        <img class="img-responsive"
                                            [src]="zipList[object] ? sanitizer.bypassSecurityTrustUrl(zipList[object]) : ' '"
                                            (click)="DeatailsPopup(zipList[object],'bottomwear',object ,'BottomwearID',brandFlag )">
                                        <img (click)="open3Dflag(zipList[object],'upperwear',object ,'UpperwearID')"
                                            class="profileInfo bottomwear_info col-sm-2"
                                            style="width:20px; height:20px !important;"
                                            src="./assets/realtype/planinfoicon.png">
                                    </div>
                                </div>
                                <div class="slider-css211">
                                    <div *ngFor="let object of packJsonObject[3].value['files'];let i=index"
                                        class="slider-img211">
                                        <img class="img-responsive1"
                                            [src]="zipList[object] ? sanitizer.bypassSecurityTrustUrl(zipList[object]) : ' '"
                                            (click)="DeatailsPopup(zipList[object],'footwear',object,'FootwearID',brandFlag )">
                                        <img (click)="open3Dflag(zipList[object],'footwear',object ,'FootwearID')"
                                            class="profileInfo  footwear_info col-sm-2"
                                            style="width:20px; height:20px !important;"
                                            src="./assets/realtype/planinfoicon.png">
                                    </div>
                                </div>
                                <div *ngIf="isLoading" class="d-flex justify-content-center">
                                    <div class="spinner-border" role="status">
                                        <span class=""></span>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                              
                </div>
                <!-- <div class="collap-popup p-1" style="min-height: 100px;">
                <div class="collapse collapse-horizontal">
                    <div class="card card-body d-flex imp-block text-center" style="width: 70px;" saria-expanded="false">
                        <h6 (click)="infoPopup()"><i class="bi bi-info-circle">info</i></h6>
                        <h6 (click)="threeDPopup()"><i class="bi bi-badge-3d p-1"></i>3D</h6>
                    </div>
                </div>
            </div> -->
            </div>
        </div>
        <div class="modal fade imp-bg" id="Envitonment-profile" tabindex="-1" aria-labelledby="Avatar-profile"
            aria-hidden="true" data-bs-backdrop="false">
            <div class="modal-dialog  ">
                <div class="modal-content imp-block" *ngIf="filterEnvironmentSnpShot">
                    <h5 type="button" class="btn-close  closBtn" data-bs-dismiss="modal" aria-label="Close"></h5>
                    <div class="container">
                        <div class="row text-center">
                            <div class="row text-center p-2">
                                <div class="col-12 m-3"
                                    *ngIf="evnFileListFileName && evnFileListFileName['displayName']">
                                    <h2 (click)="getEnvironmentProfile(evnFileListFileName['envFileName'])"
                                        data-bs-dismiss="modal">{{evnFileListFileName['displayName']}}</h2>
                                    <div><button type="button" class="btn btn-outline-info m-1 follow"
                                            data-bs-dismiss="modal">Preview</button></div>
                                </div>
                                <div class="row w-100 m-2"
                                    *ngIf="filterEnvironmentSnpShot.length >= 0  && filterEnvironmentSnpShot != undefined && filterEnvironmentSnpShot.length >= 0 ">
                                    <div class="column d-block m-1"
                                        *ngFor="let snapshot of filterEnvironmentSnpShot  ; let i=index;">
                                        <img class="w-100 shadow-1-strong rounded" data-bs-dismiss="modal"
                                            (click)="getFeed(snapshot,brandDetails)" [src]="snapshot.SnapshotFileUrl">
                                    </div>
                                    <div class="columnImg d-block m-1">
                                        <img class="w-80 shadow-1-strong rounded" data-bs-dismiss="modal">
                                    </div>
                                    <div class="columnImg d-block m-1">
                                        <img class="w-80 shadow-1-strong rounded" data-bs-dismiss="modal">
                                    </div>
                                    <div class="columnImg d-block m-1" *ngIf="filterEnvironmentSnpShot.length > 8"
                                        (click)="getEnvironmentProfile(evnFileListFileName['envFileName'])">
                                        <img style="margin-top: -165px;" class="w-80 shadow-1-strong rounded"
                                            data-bs-dismiss="modal" src="./assets/plus-8-512.png">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div [ngClass]="closeDiv ? 'select-brand-class' : 'select-brand-class-close-div'" class="container" *ngIf="(byScroll == true || showBrandFlag == true) && (tab == 'womens-wear' || tab == 'mens-wear')">
        <div class="col-lg-12 justify-content-center  info-tag  garmentName outer">
            <div class="close-butn" *ngIf="byScroll == true || showBrandFlag != true"
                (click)="closeButtonForBrand()">X</div>
            <div class="contain-fluid m-1 ">
                <div class="col-lg-12  pt-4px  text-center w-100  d-flex justify-content-center  d-flex">
                    <div class="m-0 col-8">
                        <div class="col-12 container vertical-center-band">
                            <h5 class="p-2 text-white">Please Select Brand.</h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


 

    <div [ngClass]="!isShowBrandBand  ? '' : 'd-none'">
        <app-news3-d-popup (lodingStateFlag)="setPageloading($event)" (triggedUrl)="setSelectedUrl($event)" 
        (errorMessage)="setEroorMessage($event)" 
         [EnvUrl]="evnDefault" [AvatarUrl]="this.avtarUrl" [headgearURL]="headgear"
            [footwearURL]="footwear" [GarmentsGender]="this.selectBrandGender" [upperwearURL]="upperwear"
            [bottomwearURL]="bottomwear" [BrandIdName]="brandId"></app-news3-d-popup>
    </div>


</div>    


