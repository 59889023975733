import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ApiHttpService } from './api-http.service';

@Injectable({
  providedIn: 'root'
})
export class PinterestService {

  private pinURL: string = environment.functionBaseUrl + "getPinterestPin"
  private listBoard: string = environment.functionBaseUrl + "getPinterestBoards"
  private createPin: string = environment.functionBaseUrl + "createPinterestBoardPin"

  constructor(private httpService: ApiHttpService) { }

  getBoardList() {
    return this.httpService.get(this.listBoard);

  }
  createPinterestPin(data: any) {
    return this.httpService.post(this.createPin, data);
  }

  getPinterestPin(data: any) {
    return this.httpService.post(this.pinURL, data);
  }

}
 