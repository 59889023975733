import { Component, ElementRef, Input, OnInit, Renderer2, SimpleChanges, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { SafeUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { Brand } from 'src/app/models/brand';
import { CommonService } from 'src/app/services/common.service';
import { DataSharingService } from 'src/app/services/data-sharing-service';
import { GarmentPopupComponent } from '../templets/garment-popup/garment-popup.component';
import * as bootstrap from 'bootstrap';


@Component({
  selector: 'app-popup-two-d',
  templateUrl: './popup-two-d.component.html',
  styleUrls: ['./popup-two-d.component.css']
})
export class PopupTwoDComponent implements OnInit {
  private nextButton: HTMLElement;
  private carousel: bootstrap.Carousel;


  // @ViewChild('nextButton', { static: false }) nextButton: ElementRef;
  @Input() garmentType: String | undefined;
  @Input() garmentImg: SafeUrl | undefined;
  @Input() fileType: SafeUrl | undefined;
  @Input() pageType: String | undefined;
  @Input() garmentId: String | undefined;
  @Input() typeWear: String | undefined;
  @Input() routLogInFlag: boolean | undefined;
  @Input() brandFlag: boolean | undefined;




  garmentsList = this.firestore.collection('garments').valueChanges({ idField: 'id' }) as Observable<any[]>;
  brandsFilesList = this.firestore.collection('brands').valueChanges({ idField: 'id' }) as Observable<Brand[]>;
  userFilesList = this.firestore.collection('userfiles').valueChanges({ idField: 'id' }) as Observable<any[]>;

  garmentsCollection: any = [];
  brandsCollectionall: any = [];
  brandObject: any = {};
  clothDetailsList: any = {};
  snapByApi: any = [];
  clothShapShotsListSend: any = [];
  clothShapShotsListAll: any = [];
  userUploadedFileList: any = [];
  clothDetails: any = {};
  image2DFlag: boolean = false
  toggaleFlag: boolean = true
  iconselctFlag: boolean = false
  userFiledetails: any = [];
  document: any;
  counter: number = 2;
  selectedIcon: string;
  selectedTab;
  garmentInsideLoginLink: string = '';


  dots = '';
  private maxDots = 3;
  private interval: any;


  constructor(private firestore: AngularFirestore,
    private commonService: CommonService,
    private dataSharingService: DataSharingService,
    public router: Router,
    private modal: NgbModal,
    private renderer: Renderer2, private el: ElementRef
  ) {
    this.brandsFilesList.subscribe((brands) => {
      this.brandsCollectionall = brands;
    });

    this.garmentsList.subscribe(
      garments => {
        this.garmentsCollection = garments;
        this.clothDetails = this.garmentsCollection.find((b: any) => b.id == this.garmentId);
        this.getBrandName()
      });

    if (this.routLogInFlag != undefined) {
      this.garmentInsideLoginLink = 'yologram-social/yologram-garment-feed'
    } else {
      this.garmentInsideLoginLink = 'public/public-garment-feed'
    }

  }
  ngAfterViewInit() {
    // Find the next button after the view has been initialized
    const carouselElement = this.el.nativeElement.querySelector('#carouselExampleFade');
    if (carouselElement) {
      this.carousel = new bootstrap.Carousel(carouselElement);
    }
    this.nextButton = this.el.nativeElement.querySelector('.carousel-control-next');
  }


  ngOnInit(): void {
    this.startLoading();

    this.image2DFlag = true

    console.log("GarmentId", this.garmentId)
    console.log("this.garmentsCollection", this.garmentsCollection)
    console.log("BrandObject", this.brandObject)


    this.userFilesList.subscribe((userFile) => {
      this.userUploadedFileList = userFile
      let clothId = this.clothDetails.id != undefined ? this.clothDetails.id : this.garmentId;
      let filelist = this.userUploadedFileList.filter((uploadlist) => uploadlist.link == clothId).slice(0, 2);
      this.userFiledetails = filelist;
      this.image2DFlag = false
    })

    console.log("root flag", this.routLogInFlag)
    if (this.routLogInFlag != undefined) {
      this.garmentInsideLoginLink = 'yologram-social/yologram-garment-feed'
    } else {
      this.garmentInsideLoginLink = 'public/public-garment-feed'
    }





  }

  ngOnChanges(changes: SimpleChanges): void {
    // console.log(this.nextButton,'nextButton')
    // if (this.nextButton) {
    //   this.nextButton.click();
    // }

    this.goToNextSlide()

  }


  goToNextSlide() {
    if (this.carousel) {
      this.carousel.next();
    }
  }

  getSnapShotByGarment() {
    let garmentType = this.typeWear
    let garmentID = this.garmentId
    let reqInputObject = { fileType: garmentType, id: garmentID, pageNumber: 1 }
    this.commonService.yologramSnapshotDetailsByType(reqInputObject).subscribe(async (response: any) => {
      this.snapByApi = response.object
      this.clothShapShotsListSend = this.snapByApi
      this.clothShapShotsListAll = this.snapByApi.slice(0, 3)
    })
  }

  getBrandImage(imgURL: string) {
    let brandEncodedurl = encodeURI(imgURL).replace('/', '%2F');
    const newPath = brandEncodedurl.replace('Brands%2F', 'Brands%2Fcompressed%2F');
    let url = "https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/" + newPath + "?alt=media&token=550e7e88-bed3-4668-84ff-ad491c19e06c"
    return url;
  }

  // getBrandName() {

  //   console.log("GarmentsCollection", this.garmentsCollection != undefined && this.brandsCollectionall != undefined && this.garmentId != undefined )
  //   console.log("GarmentsCollection 1", this.garmentsCollection)
  //   console.log("GarmentsCollection 2", this.brandsCollectionall)
  //   console.log("GarmentsCollection 3", this.garmentId )



  //   if(this.garmentsCollection != undefined ){
  //     if(this.brandsCollectionall != undefined ){
  //       if( this.garmentId != undefined){
  //         this.clothDetailsList = this.garmentsCollection.find((b: any) => b.id == this.garmentId);
  //         this.brandObject = this.brandsCollectionall.find((b: any) => b.id == this.clothDetailsList.BrandID);
  //       }
  //     }
  //   }


  //   console.log("GarmentId", this.garmentId)
  //   console.log("this.garmentsCollection", this.garmentsCollection)
  //   console.log("BrandObject", this.brandObject)



  // }

  getBrandName() {
    if (this.garmentId && this.brandsCollectionall && this.garmentsCollection) {
      console.log("GarmentsCollection", this.garmentsCollection);
      console.log("BrandsCollectionAll", this.brandsCollectionall);
      console.log("GarmentId", this.garmentId);
      this.clothDetailsList = this.garmentsCollection.find((b) =>b.id == this.garmentId);
      console.log("ClothDetailsList", this.clothDetailsList);
      this.brandObject = this.brandsCollectionall.find((b) =>b.id == this.clothDetailsList.BrandID);
      console.log("BrandObject", this.brandObject);

    }

  }


  getgarmentStylefeed() {

    let snap = this.clothShapShotsListSend
    let cloths = this.clothDetailsList
    this.dataSharingService.sendUserSnapShots(snap)
    this.dataSharingService.sendUserfiles(this.userFiledetails)
    this.dataSharingService.sendClothDetails(cloths)
    // this.router.navigate([this.garmentInsideLoginLink != undefined  ? this.garmentInsideLoginLink :'public/public-garment-feed', { 'brandId': cloths.BrandID, 'clothsType': cloths.GarmentType, 'Gender': cloths.Gender, 'id': cloths.id, 'key': this.typeWear ,'tab':'story'}])
    this.router.navigate(['/garment', { 'brandId': cloths.BrandID, 'clothsType': cloths.GarmentType, 'Gender': cloths.Gender, 'id': cloths.id, 'key': this.typeWear, 'tab': 'story' }])

  }
  getClothProfile() {

    let snap = this.clothShapShotsListSend
    let cloths = this.clothDetailsList
    this.dataSharingService.sendUserSnapShots(snap)
    this.dataSharingService.sendClothDetails(this.clothDetailsList)
    this.dataSharingService.sendUserfiles(this.userFiledetails)
    this.router.navigate(['/garment', {
      'brandId': cloths.BrandID,
      'clothsType': cloths.GarmentType, 'Gender': cloths.Gender, 'id': cloths.id, 'key': this.typeWear, 'tab': 'Menswear'
    }])
    // this.router.navigate([ this.garmentInsideLoginLink != undefined  ? this.garmentInsideLoginLink : 'public/public-garment-feed', { 'brandId': cloths.BrandID, 
    // 'clothsType': cloths.GarmentType, 'Gender': cloths.Gender, 'id': cloths.id, 'key': this.typeWear,'tab':'Menswear'}])
  }
  getgarmentStyle() {

    let snap = this.clothShapShotsListSend
    let cloths = this.clothDetailsList
    this.dataSharingService.sendUserSnapShots(snap)
    this.dataSharingService.sendClothDetails(this.clothDetailsList)
    this.dataSharingService.sendUserfiles(this.userFiledetails)
    this.router.navigate(['/garment', { 'brandId': cloths.BrandID, 'clothsType': cloths.GarmentType, 'Gender': cloths.Gender, 'id': cloths.id, 'key': this.typeWear, 'tab': 'Menswears' }])

    // this.router.navigate([ this.garmentInsideLoginLink != undefined  ? this.garmentInsideLoginLink : 'public/public-garment-feed', { 'brandId': cloths.BrandID, 'clothsType': cloths.GarmentType, 'Gender': cloths.Gender, 'id': cloths.id, 'key': this.typeWear,'tab':'Menswears'}])
  }


  openGarmentPopup() {
    let garmentType = this.typeWear
    let id = this.garmentId
    const modalRef = this.modal.open(GarmentPopupComponent, {
      modalDialogClass: "model-garmentPopupClass"
    });
    modalRef.componentInstance.id = 10;
    modalRef.componentInstance.key = garmentType;
    modalRef.componentInstance.garmentId = id;
    modalRef.result.then((result) => {
      console.log(result);
    }).catch((error) => {
      console.log(error);
    });
  }
  // clickNextButton() {
  //   const nextButton = this.el.nativeElement.querySelector('.carousel-control-next');
  //   if (nextButton) {
  //     this.renderer.invokeElementMethod(nextButton, 'click');
  //   } else {
  //     console.error('Next button not found');
  //   }  }

  toggaleInof() {
    this.toggaleFlag = true
  }
  toggale3D() {
    this.toggaleFlag = false
  }
  selectIcon(icon) {
    this.selectedIcon = ''
    this.selectedIcon = icon
    this.iconselctFlag = true
  }

  getBrandProfileByGarment(brand: any) {
    this.dataSharingService.sendUserSnapShots(this.clothShapShotsListSend);
    this.router.navigate(['/brand', { 'brandId': brand.id, 'select-tab': 'story' }])

    // if(this.routLogInFlag){
    //   this.router.navigate([  'yologram-social/yologram-brand-stories', { 'brandId': brand.id, 'select-tab': 'story' }])
    // }else {
    //   this.router.navigate([  'public/public-brand-stories', { 'brandId': brand.id, 'select-tab': 'story' }])
    // }
  }


  startLoading(): void {
    this.interval = setInterval(() => {
      const dotsLength = this.dots.length;
      this.dots = dotsLength < this.maxDots ? this.dots + '.' : '.';
    }, 300);
    console.log('this.dots', this.dots)
  }
  ngOnDestroy(): void {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

}