<div *ngIf="loading" class="loading">Loading&#8230;
</div>
 <div class="native-component" #screenshot>
<div class="mb-5 manage-w"  >
    <!-- <h1 class="text-white">{{userSnapShotsInput.length}}</h1> -->
    <div (scroll)="scrollCard()" #swipeContentApp class="card p-0 slider-css"
        *ngIf="userSnapShotsInput">
        <div [ngClass]="threeDopen == true   ? '' : 'd-none'"  >
            <div [ngClass]="threeDopen == true   ? '' : 'd-none'" style="width: 290px; height: 470px;">
                <!-- <div style="display: flex; justify-content: space-between; align-items: center;">
                    <div (click)="closeThreeDBUTTON()" style="cursor: pointer;">X</div>
                    <div></div>  
                    <div class="form-check form-switch">
                        <input type="checkbox" class="form-check-input" id="site_state" style="cursor: pointer;"
                            (click)="toggleSwitch()">
                    </div>
                </div> -->
                <div style="display: flex; justify-content: space-between; align-items: center; width: 100%;">
                    <div (click)="closeThreeDBUTTON()" style="cursor: pointer;">X</div>
                    <!-- Middle div with the select dropdown -->
                    <div class="text-center" style="flex-grow: 1;">
                        <select name="animations" id="animations" class="custom-select-dropdown" (change)="onSelectAnimation($event)" >
                            <option value="" disabled selected>Select an animation</option>
                            <option *ngFor="let animation of animationCollection" [value]="animation.storageURL">
                                {{ animation.displayName}}
                            </option>
                        </select>
                    </div>
                    
                    <!-- <div class="form-check form-switch">
                        <input type="checkbox" class="form-check-input" id="site_state" style="cursor: pointer;" [(ngModel)]="isCheckedFocus"  
                         (click)="switchFlagforFocus()">
                    </div>
                    <div class="form-check form-switch">
                        <input type="checkbox" class="form-check-input" id="site_state" style="cursor: pointer;"
                           [(ngModel)]="isChecked"   (click)="toggleSwitch()">
                    </div> -->

                    <label class="switch">
                        <input type="checkbox" id="toggle-switch" [(ngModel)]="isCheckedFocus"  
                        (click)="switchFlagforFocus()">
                        <span class="slider round">
                          <span class="label on">Story</span>
                          <span class="label off">Model</span>
                        </span>
                      </label>
                      <label class="switch">
                        <input type="checkbox" id="toggle-switch"  [(ngModel)]="isChecked"   (click)="toggleSwitch()" >
                        <span class="slider round">
                          <span class="label on">Story</span>
                          <!-- <span class="label off">Blank</span> -->
                        </span>
                      </label>
                </div>
                <app-news3-d-templete [flagForThreeJs]="" [EnvUrl]="envUrl" [GarmentsGender]="genders"
                    [upperwearUrl]="garmenturl" [FunctionFlag]="functionflagValue" [findFlag]="isChecked" [bvhAnimationUrl]="this.selectedAnimation"
                    (errorMessage)="setEroorMessage($event)" [bottomwearUrl]="garmentbottom" [BlobUrl]="dataBlobUrl"
                    [AvatarUrl]="this.avtarUrl" [headgearUrl]="headgear" [footwearUrl]="footwear" [StoryID]="storyID"
                    [vrmAvatarUrl]="this.vrmAvtarUrl">
                </app-news3-d-templete>
            </div>
        </div>

        <div  [ngClass]="threeDopen == false   ? '' : 'd-none'"   #swipeContentFilter class="slider-img" *ngFor="let snapshot of userSnapShotsInput; let i=index;" 
        >
            <!-- id="{{i}}" -->
            <div class="col-lg-12"    [ngClass]="this.deleteFlag == 'delete' ? 'snashot-mar': '' " ><h6>
                    <div class="col-lg-12 d-flex"  >
                        <a class="col-lg-6 w-70 display-name" (click)="openUserPopup(snapshot)">
                            {{snapshot.profileName != undefined ? snapshot.profileName : 'debug' }}
                        </a>
                        <h6 class="col-lg-6 text-end" (click)="openAvatarPopUp(snapshot,snapshot.AvatarID)"
                            class="col-lg-6 m-1 text-end avatar-diaplay">
                            <!-- 🧍🏽{{snapshot.firstName}}{{snapshot.AvatarLastFive}} -->
                            🧍🏽{{snapshot.avatarName == undefined ? this.getDocumentDetails(snapshot):
                                snapshot.avatarName}}
                        </h6>
                    </div>
                </h6>
                <div class="col-lg-12 d-flex">
                    <h6 class="col-lg-6 date-class">{{convertFirestoreTimestampToDate(snapshot.DateTime) | date: 'medium'}}</h6>
                    <h6 class="col-lg-6 " (click)="environmentPopup(snapshot.Environment)"
                        class="col-lg-6 evn-font text-end">
                        {{getEnvironmentName(snapshot.Environment)}}</h6>
                </div>
                <span class="spinner-border second-spineer" *ngIf="isLoading" role="status"></span>
            </div>
            <div class="img-fluid"  id="contentToCapture" >
                <span id='{{i}}'>
                    <div class="col-lg-1 deletbtn" *ngIf="this.deleteFlag == 'delete'">
                        <a class="" (click)="deleteSnapshotFile(snapshot.id)"><i class="bi bi-trash-fill"></i></a>
                    </div>
                    <img #target id="{{snapshot.StoryID}}" class="img-fluid" [class.active]="i == currentIndex"
                        [ngClass]="teamplateFlag == 1  ? 'snashot-watermarking': '' " width="480 !important" height="280"
                        [src]="snapshot.SnapshotFileUrl"  />
      
                        <img    [ngClass]="teamplateFlag == 2  ? '': 'd-none' " class="img-fluid fram-IGS" [class.active]="i == currentIndex" width="480 !important" height="280"
                         src="./assets/realtype/iDGS-small_font.png" >
    
                </span>
            </div>
            <span *ngIf="teamplateFlag == 1 " >
                <div *ngIf="teamplateFlag == 1" class="story-heading">Business Today</div>
                <!-- <div  *ngIf="teamplateFlag == 3"  class="story-heading">IDGS</div> -->
                <div class="bottom-color-box-left">&nbsp;</div>
                <div class="bottom-color-box-right">&nbsp;</div>
            </span>
            <span  *ngIf="imagBlobUrl != undefined"  ><img *ngIf="teamplateFlag != null"class="second-share" (click)=" captureAndShare()" style="width:20px !important; " src="./assets/share_copy.png">
            </span>

                <div class="threeD-image" *ngIf="snapshot.vrmURL != ''">
                    <img style="width:30px !important; height: 30px; margin-top: -277px; "
                    (click)="openNews3DPassVRMValue(copyTextToClipEven(snapshot.Environment),
                    copyTextToClipAvtar(snapshot.UserID,snapshot.AvatarID),
                    (snapshot),
                    copyTextToClipGarment(snapshot.UpperwearID),
                    copyTextToClipGarment(snapshot.BottomwearID),
                    copyTextToClipGarment(snapshot.FootwearID), 
                    copyTextToClipGarment(snapshot.HeadwearID)
                    )"   src="./assets/realtype/dancing.png" />
                </div>
                <div class="threeD-image"  >
                    <img style="width:30px !important; height: 30px; margin-top: -200px; " 
                    (click)="openNews3DPassValue(copyTextToClipEven(snapshot.Environment),
                    copyTextToClipAvtar(snapshot.UserID,snapshot.AvatarID),
                    (snapshot),
                    copyTextToClipGarment(snapshot.UpperwearID),
                    copyTextToClipGarment(snapshot.BottomwearID),
                    copyTextToClipGarment(snapshot.FootwearID), 
                    copyTextToClipGarment(snapshot.HeadwearID)
                    )"
                    src="./assets/realtype/dress-white.png" />
                </div>

            <div class="iocon-set d-block justify-content-center text-center">
                <div class="justify-content-center set-div">
                    <img *ngIf="getGarmentrealtype(snapshot.HeadwearID) == '0' "
                        style="width: 15px !important;   height: 15px !important;     opacity: 0.4;"
                        src="./assets/newsfeed/headgear-new.png">
                    <div class="d-flex">
                        <a class="pintrest-icon"
                            *ngIf=" teamplateFlag == '' &&  snapshot.HeadwearID != undefined && snapshot.HeadwearID != '' && showPintrestIcon(snapshot.HeadwearID)"
                            (click)="shareOnPinterest(snapshot.HeadwearID,'headwear',snapshot.SnapshotFileUrl,snapshot.StoryID)">
                            <img style="width:20px !important; color:black" class="img-thumbnail2"
                                src="./assets/Pinterest-logo.png" />
                        </a>
                        <img *ngIf="getGarmentrealtype(snapshot.HeadwearID) == '0' " class="rounded-circle m-1"
                            [src]="getBrandImageByGarmetnID(snapshot.HeadwearID)" class="rounded profileImg-brand m-1"
                            style="width: 25px !important;   height: 25px !important" />
                    </div>
                </div> &nbsp;
                <div class="justify-content-center set-div">
                    <img *ngIf="getGarmentrealtype(snapshot.UpperwearID) == '1' "
                        style="width: 15px !important;   height: 15px !important;      opacity: 0.4;"
                        src="./assets/realtype/upperwear.png">
                    <div class="d-flex">
                        <a class="pintrest-icon"
                            *ngIf="teamplateFlag == '' &&  snapshot.UpperwearID != undefined && snapshot.UpperwearID != '' && showPintrestIcon(snapshot.UpperwearID) && getGarmentrealtype(snapshot.UpperwearID) == '1'"
                            (click)="shareOnPinterest(snapshot.UpperwearID,'upperwear',snapshot.SnapshotFileUrl,snapshot.StoryID)">
                            <img style="width:20px !important; color:black" class="img-thumbnail2"
                                src="./assets/Pinterest-logo.png" />
                        </a>
                        <img *ngIf="getGarmentrealtype(snapshot.UpperwearID) == '1' " class="rounded-circle m-1"
                            [src]="getBrandImageByGarmetnID(snapshot.UpperwearID)" class="rounded profileImg-brand m-1"
                            style="width: 25px !important;   height: 25px !important" />
                    </div>
                </div> &nbsp;
                <div class="justify-content-center set-div ">
                    <img *ngIf="getGarmentrealtype(snapshot.UpperwearID) == '5' "
                        style="width: 15px !important;   height: 15px !important;     opacity: 0.4;"
                        src="./assets/realtype/fullbody.png">
                    <div class="d-flex">
                        <a class="pintrest-icon"
                            *ngIf="teamplateFlag == '' &&  snapshot.UpperwearID != undefined && snapshot.UpperwearID != '' && showPintrestIcon(snapshot.UpperwearID) && getGarmentrealtype(snapshot.UpperwearID) == '5'"
                            (click)="shareOnPinterest(snapshot.UpperwearID,'upperwear',snapshot.SnapshotFileUrl,snapshot.StoryID)">
                            <img style="width:20px !important; color:black" class="img-thumbnail2"
                                src="./assets/Pinterest-logo.png" />
                        </a>
                        <img *ngIf="getGarmentrealtype(snapshot.UpperwearID) == '5' " class="rounded-circle m-1"
                            [src]="getBrandImageByGarmetnID(snapshot.UpperwearID)" class="rounded profileImg-brand m-1"
                            style="width: 25px !important;   height: 25px !important;" />
                    </div>
                </div> &nbsp;
                <div class="justify-content-center set-div ">
                    <img *ngIf="getGarmentrealtype(snapshot.BottomwearID) == '2'"
                        style="width: 15px !important;   height: 15px !important;    opacity: 0.4;"
                        src="./assets/newsfeed/bottomwear-new.png">
                    <div class="d-flex">
                        <a class="pintrest-icon"
                            *ngIf=" teamplateFlag == '' && snapshot.BottomwearID != undefined && snapshot.BottomwearID != '' && showPintrestIcon(snapshot.BottomwearID)"
                            (click)="shareOnPinterest(snapshot.BottomwearID,'upperwear',snapshot.SnapshotFileUrl,snapshot.StoryID)">
                            <img style="width:20px !important; color:black" class="img-thumbnail2"
                                src="./assets/Pinterest-logo.png" />
                        </a>
                        <img *ngIf="getGarmentrealtype(snapshot.BottomwearID) == '2'" class="rounded-circle m-1"
                            [src]="getBrandImageByGarmetnID(snapshot.BottomwearID)" class="rounded profileImg-brand m-1"
                            style="width: 25px !important;   height: 25px !important" />
                    </div>
                </div> &nbsp;
                <div class="justify-content-center set-div">
                    <img *ngIf="getGarmentrealtype(snapshot.FootwearID) == '3' "
                        style="width: 15px !important;   height: 15px !important;    opacity: 0.4;"
                        src="./assets/realtype/footwear.png">
                    <div class="d-flex">
                        <a class="pintrest-icon"
                            *ngIf="teamplateFlag == '' && snapshot.FootwearID != undefined && snapshot.FootwearID != '' && showPintrestIcon(snapshot.FootwearID)"
                            (click)="shareOnPinterest(snapshot.FootwearID,'footwear',snapshot.SnapshotFileUrl,snapshot.StoryID)">
                            <img style="width:20px !important; color:black" class="img-thumbnail2 "
                                src="./assets/Pinterest-logo.png" />
                        </a>
                        <img *ngIf="getGarmentrealtype(snapshot.FootwearID) == '3'" class="rounded-circle m-1"
                            [src]="getBrandImageByGarmetnID(snapshot.FootwearID)" class="rounded profileImg-brand m-1"
                            style="width: 25px !important;   height: 25px !important;" />
                    </div>
                </div>&nbsp;
            </div>
            <div class="col-12 col-sm-12 slider-box">
                <div class="col-12 col-sm-12 d-flex" *ngIf="filterSnapShotFileDetails">
                    <div class="col-2 col-sm-2  ">
                        <img class="m-1" (click)="openBrandPoup(snapshot.HeadwearID)" data-bs-toggle="modal"
                            style="width:45px; height:45px !important; color:black;"
                            *ngIf="getGarmentrealtype(snapshot.HeadwearID) == '0' " class="rounded profileImg-brand"
                            [src]="getBrandImageByGarmetnID(snapshot.HeadwearID)" />
                    </div>
                    <div class="col-8 col-sm-8 clothName p-1 "
                        *ngIf="snapshot.HeadwearID != undefined && snapshot.HeadwearID != ''">
                        <img class="gar-icon" *ngIf="getGarmentrealtype(snapshot.HeadwearID) == '0' "
                            style="width:15px !important; color:black ; opacity: 0.4;"
                            src="./assets/newsfeed/headgear-new.png">
                        <span class="caption-values"
                            *ngIf="snapshot.HeadwearID != undefined && snapshot.HeadwearID != ''">
                            <span class="garment-name" data-toggle="tooltip" data-placement="top" title="Cloth Details"
                                (click)="openGarmentPopup('HeadwearID',snapshot.HeadwearID)">
                                {{getGarmentName(snapshot.HeadwearID).substring(0,32)}}
                            </span>&nbsp;
                        </span>
                    </div>
                </div>

                <div class="col-12 col-sm-12 d-flex" *ngIf="filterSnapShotFileDetails">
                    <div class="col-2 col-sm-2 ">
                        <img class="m-1" (click)="openBrandPoup(snapshot.UpperwearID)" data-bs-toggle="modal"
                            style="width:45px; height:45px !important; color:black ; "
                            *ngIf="getGarmentrealtype(snapshot.UpperwearID) == '1' " class="rounded profileImg-brand"
                            [src]="getBrandImageByGarmetnID(snapshot.UpperwearID)" />
                        <img class="m-1" (click)="openBrandPoup(snapshot.UpperwearID)" data-bs-toggle="modal"
                            data-bs-target="#brand-details" (click)="openBrandPoup(snapshot.UpperwearID)"
                            data-bs-toggle="modal" style="width:45px; height:45px !important; color:black ;"
                            *ngIf="getGarmentrealtype(snapshot.UpperwearID) == '5' " class="rounded profileImg-brand"
                            [src]="getBrandImageByGarmetnID(snapshot.UpperwearID)" />
                    </div>
                    <div class="col-8 col-sm-8 clothName p-1"
                        *ngIf="snapshot.UpperwearID != undefined && snapshot.UpperwearID != ''">
                        <img class="gar-icon" *ngIf="getGarmentrealtype(snapshot.UpperwearID) == '1' "
                            style="width:15px !important; color:black; opacity: 0.4;"
                            src="./assets/realtype/upperwear.png">
                        <img class="gar-icon" *ngIf="getGarmentrealtype(snapshot.UpperwearID) == '5' "
                            style="width:15px !important; color:black ; opacity: 0.4;"
                            src="./assets/realtype/fullbody.png">
                        <span class="caption-values"
                            *ngIf="snapshot.UpperwearID != undefined && snapshot.UpperwearID != ''">
                            <span class="garment-name" data-toggle="tooltip" data-placement="top" title="Cloth Details"
                                (click)="openGarmentPopup('UpperwearID',snapshot.UpperwearID)">
                                {{getGarmentName(snapshot.UpperwearID).substring(0,32)}}
                            </span>&nbsp;
                        </span>
                    </div>
                </div>
                <div class="col-12 col-sm-12 d-flex" *ngIf="filterSnapShotFileDetails">

                    <div class="col-2 col-sm-2 ">
                        <img class="m-1" (click)="openBrandPoup(snapshot.BottomwearID)" data-bs-toggle="modal"
                            style="width:45px; height:45px !important; color:black ; "
                            *ngIf="getGarmentrealtype(snapshot.BottomwearID) == '2'" class="rounded profileImg-brand"
                            [src]="getBrandImageByGarmetnID(snapshot.BottomwearID)" />
                    </div>

                    <div class="col-8 col-sm-8 clothName p-1"
                        *ngIf="snapshot.BottomwearID != undefined && snapshot.BottomwearID != ''">
                        <img class="gar-icon" *ngIf="getGarmentrealtype(snapshot.BottomwearID) == '2'"
                            style="width:15px !important; color:black ; opacity: 0.4;"
                            src="./assets/newsfeed/bottomwear-new.png">
                        <span class="caption-values"
                            *ngIf="snapshot.BottomwearID != undefined && snapshot.BottomwearID != ''">
                            <span class="garment-name" data-toggle="popover" data-placement="left" title="Cloth Details"
                                (click)="openGarmentPopup('BottomwearID',snapshot.BottomwearID)">
                                {{getGarmentName(snapshot.BottomwearID).substring(0,32)}}
                            </span>&nbsp;
                        </span>
                    </div>
                </div>
                <div class="col-12 col-sm-12 d-flex " *ngIf="filterSnapShotFileDetails">
                    <div class="col-2 col-sm-2 ">
                        <img class="m-1" (click)="openBrandPoup(snapshot.FootwearID)" data-bs-toggle="modal"
                            style="width:45px; height:45px !important; color:black ;"
                            *ngIf="getGarmentrealtype(snapshot.FootwearID) == '3'" class="rounded profileImg-brand"
                            [src]="getBrandImageByGarmetnID(snapshot.FootwearID)" />
                    </div>
                    <div class="col-8 col-sm-8 clothName "
                        *ngIf="snapshot.FootwearID != undefined && snapshot.FootwearID != ''">
                        <img class="gar-icon" *ngIf="getGarmentrealtype(snapshot.FootwearID) == '3' "
                            style="width:15px !important; color:black ; opacity: 0.4;"
                            src="./assets/realtype/footwear.png">
                        <span class="caption-values"
                            *ngIf="snapshot.FootwearID != undefined && snapshot.FootwearID != ''">
                            <span class="garment-name" data-toggle="popover" data-placement="left" title="Cloth Details"
                                (click)="openGarmentPopup('FootwearID',snapshot.FootwearID)">
                                {{getGarmentName(snapshot.FootwearID).substring(0,32)}}
                            </span>&nbsp;
                        </span>
                    </div>
                </div>
            </div>
            <h1 class="second-txt text-black">
            </h1>
        </div>
        <!-- <div *ngIf="userSnapShotsInput.length >= 12 && height > 6000"
            class="justify-content-center more  text-center bg-white">
            <div class="more-btn" (click)="moerButtonForNext()">More</div>
        </div> -->
    </div>
</div>
</div>