import { DatePipe } from '@angular/common';
import { Component, HostListener, Input, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/compat/storage';
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';
import { Observable, Subject, from, switchMap, takeUntil } from 'rxjs';
import { CommonService } from 'src/app/services/common.service';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-lottie-file-templet',
  templateUrl: './lottie-file-templet.component.html',
  styleUrls: ['./lottie-file-templet.component.css']
})
export class LottieFileTempletComponent implements OnInit, OnDestroy {
  options: AnimationOptions = {
    path: './assets/a.json',
  };
  @ViewChild('lottie', { static: true }) lottie: LottieFileTempletComponent;
  public lottieConfig: AnimationOptions;
  public animationProgress: number = 0;
  private animationItem: AnimationItem;
  private unsubscribe$: Subject<void> = new Subject<void>();
  logInFlag: boolean;
  @Input() LottieURL: any;
  @Input() SnapURL: any;

  isLoading: boolean = true;  // Loader flag

  file!: File;
  task!: AngularFireUploadTask;
  percentage!: Observable<number | undefined>;
  snapshot!: Observable<any>;
  fileUrl: any = '';
  urlPromisesData: any = [];
  windowWidth: number = window.innerWidth;
  windowHeight: number = window.innerHeight;

  isDragging = false;
  startX: number = 0; // Initialize startX to 0
  downloadUrls$: Observable<string[]>;
  currentLottieUrl: string = '';

  constructor(public commonService: CommonService, private db: AngularFirestore, private storage: AngularFireStorage, public datepipe: DatePipe) {
    this.logInFlag = this.commonService.isLoggedIn()
    this.onResize() 
  };

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.windowWidth = window.innerWidth;
    this.windowHeight = window.innerHeight;
  }

  ngOnInit(): void {
    
  };

  ngOnChanges(changes: SimpleChanges): void {
    this.selectLottieUrl(this.LottieURL);
  }

  animationCreated(animationItem: AnimationItem): void {
    this.isLoading = true;
    this.animationItem = animationItem;
    animationItem.addEventListener('DOMLoaded', () => {
      this.isLoading = false;
    });
  }

  onSliderChange(): void {
    if (this.animationItem) {
      const goToFrame = this.animationItem.totalFrames * this.animationProgress;
      console.log("deltaX", goToFrame);
      this.animationItem.goToAndStop(goToFrame, true);
    }
  }

  lottieDropdown() {
    const dirRef = this.storage.ref('Snapshots/lottiefiles');
    this.downloadUrls$ = dirRef.listAll().pipe(
      switchMap(response => {
        const urlPromises = response.items.map(item => item.getDownloadURL());
        this.urlPromisesData = response.items.map(item => item.name);
        return from(Promise.all(urlPromises));
      }),
      takeUntil(this.unsubscribe$)
    );
  }

  selectLottieUrl(url: string) {
    this.currentLottieUrl = url;
    this.lottieConfig = {  }
    this.lottieConfig = {
      path: url,
      autoplay: false,
      loop: true
    };
  }

  onTouchStart(event: TouchEvent) {
    this.startX = event.touches[0].clientX / 25;
  }

  onMove(event: MouseEvent | TouchEvent) {
    if (this.animationItem) {
      const rangeMax = this.animationItem.totalFrames;
      let progress = 0;
      let clientX = 0;

      if (event instanceof MouseEvent) {
        clientX = event.clientX;
      } else if (event instanceof TouchEvent) {
        clientX = event.touches[0].clientX;
      }

      if (clientX <= 250) {
        // First half of the container
        progress = clientX / 250;
      } else {
        // Second half of the container
        progress = (clientX - 250) / 250;
      }

      progress = Math.min(Math.max(progress, 0), 1);
      let frame = Math.round(rangeMax * progress);
      frame %= rangeMax;
      if (frame === 0 && rangeMax > 1) {
        frame = 1;
      }

      this.animationItem.goToAndStop(frame, true);
    }
  }

  ngOnDestroy() {
    // Complete the unsubscribe subject to clean up any observables
    this.unsubscribe$.next();
    this.unsubscribe$.complete();

    // Clean up the animation item
    if (this.animationItem) {
      this.animationItem.destroy();
      console.log('Animation destroyed');
    }

    // Other cleanup tasks if necessary
  }
}
