<div class="modal-content imp-block" data-bs-backdrop="false">
    <div class="container">
        <h5 type="button" (click)="closePopUp()" class="btn-close  closBtn" aria-label="Close"></h5>
        <div class="row text-center">
            <div class="container setImage">
                <div class="text-center">
                    <div class="row text-start p-2">
                        <div *ngIf="userDetailsloading" class="text-center">
                            <div class="spinner-border m-5" role="status">
                                <span class="sr-only"></span>
                            </div>
                        </div>
                        <div class=" col-lg-12 band">
                            <div class="static-band" style="margin-left: 0px  !important;">
                                <div (click)="getUserSnapShotDashboard('story','')"
                                    class="d-flex m-1 profile-popup text-white" data-bs-dismiss="modal">
                                    <img *ngIf="userDetailsObject.ProfilePicture != undefined"
                                        class="img-thumbnail rounded-circle" style="
                                          width: 60px !important;
                                          height: 60px !important;
                                          margin-top: -53px;
                                      " [src]="userDetailsObject.ProfilePicture">
                                    <img *ngIf="userDetailsObject.ProfilePicture == undefined"
                                        class="img-thumbnail rounded-circle" style="
                                          width: 60px !important;
                                          height: 60px !important;
                                          margin-top: -53px;
                                      " [src]="userDetailsObject.ProfilePicture">
                                </div>
                                <div class="w-100 col-12 d-flex ">
                                    <div class="col-9 text-start">
                                        <h5 *ngIf="userDetailsObject.DisplayName == undefined"
                                            class="m-1 text-start text-black profile-name"
                                            (click)="getUserSnapShotDashboard('story','')">{{userData.profileName}}</h5>
                                        <h5 *ngIf="userDetailsObject.DisplayName != undefined"
                                            class="m-1 text-black profile-name"
                                            (click)="getUserSnapShotDashboard('story','')">
                                            {{userDetailsObject.DisplayName}}
                                        </h5>
                                    </div>
                                    <div class="col-3 "><button type="button" style="font-size: 10px;"
                                            class="btn btn-outline-info">Follow</button>
                                    </div>
                                </div>
                                <div class="text-start " style="height: 25px; width: 100%;">
                                    <h6 *ngIf="this.AvatarID != undefined && this.UserID != undefined"
                                        class="avatr  text-start text-black"
                                        (click)="getUserSnapShotByAvatarId('story',this.AvatarID)">🧍🏽{{AvatarID}}
                                    </h6>
                                </div>
                                <div>
                                    <div class="static-band-five-icon col-4 text-center d-flex justify-content-center">
                                        <div class="brandbtn">
                                            <h6 class="m-1" (click)="selectSummeryView('Story')"
                                                [ngClass]="secteTab == 'Story'   ? 'story-menu-card' : 'story-menu-card-not-selected'">
                                                <a *ngIf="this.AvatarID == undefined">
                                                    <img (click)="getUserSnapShotDashboard('story','')"
                                                        *ngIf="secteTab  == 'Story'" style="width:15px !important"
                                                        src="  ./assets/storystyle/story-white.png">
                                                    <img *ngIf="secteTab  != 'Story'" style="width:15px !important"
                                                        src=" ./assets/storystyle/storyblack.png">
                                                </a>
                                                <a *ngIf="this.AvatarID != undefined">
                                                    <img (click)="getUserSnapShotByAvatarId('story',this.AvatarID)"
                                                        *ngIf="secteTab  == 'Story'" style="width:15px !important"
                                                        src="  ./assets/storystyle/story-white.png">
                                                    <img *ngIf="secteTab  != 'Story'" style="width:15px !important"
                                                        src=" ./assets/storystyle/storyblack.png">
                                                </a>

                                            </h6>
                                            <h6 *ngIf="secteTab  == 'Story'" class="m-1 font-class">Story</h6>
                                        </div>
                                        <div class="brandbtn" (click)="selectTab('Brands')">
                                            <h6 class="m-1"
                                                [ngClass]="secteTab == 'Brands'   ? 'story-menu-card' : 'story-menu-card-not-selected'">
                                                <a>
                                                    <img *ngIf="secteTab  == 'Brands'" style="width:15px !important"
                                                        src="./assets/brand_white.png">
                                                    <img *ngIf="secteTab  != 'Brands'" style="width:15px !important"
                                                        src="./assets/brand_black.png">

                                                </a>
                                            </h6>
                                            <h6 *ngIf="secteTab  == 'Brands'" class="m-1 font-class">Brands </h6>
                                        </div>
                                        <div class="brandbtn" (click)="selectTab('Style')">
                                            <h6 class="m-1 "
                                                [ngClass]="secteTab == 'Style'   ? 'story-menu-card' : 'story-menu-card-not-selected'">
                                                <a>
                                                    <img *ngIf="secteTab  == 'Style'" style="width:15px !important"
                                                        src="./assets/t_shirt_white.png">
                                                    <img *ngIf="secteTab  != 'Style'" style="width:15px !important"
                                                        src="./assets/t_shirt_black.png">
                                                </a>
                                            </h6>
                                            <h6 *ngIf="secteTab  == 'Style'" class="m-1 font-class">Style</h6>
                                        </div>
                                    </div>
                                </div>

                            </div>


                            <div style="height: 147px !important; width: 100% !important;">
                                <div class="slider-scroll band">
                                    <div *ngIf="this.AvatarID == undefined" class="d-flex "
                                        (click)="getUserSnapShotDashboard('story','') " data-bs-dismiss="modal">
                                        <div *ngIf="userFiledetails.length == 0 " class=" col-12 ">
                                            <app-slider-two-d [images]="images" [dismmis]="dismiss"></app-slider-two-d>
                                        </div>
                                        <div *ngIf="userFiledetails.length != 0" class=" col-12  band">
                                            <app-slider-two-d [images]="userFiledetails" [UserId]="this.UserID"
                                                [UserProfile]="userProfile" [dismmis]="dismiss"></app-slider-two-d>
                                        </div>
                                    </div>
                                    <div class="d-flex " *ngIf="this.AvatarID != undefined"
                                        (click)="getUserSnapShotByAvatarId('story',this.AvatarID)"
                                        data-bs-dismiss="modal">
                                        <div *ngIf="userFiledetails.length == 0 " class=" col-12 band">
                                            <app-slider-two-d [images]="images" [dismmis]="dismiss"></app-slider-two-d>
                                        </div>
                                        <div *ngIf="userFiledetails.length != 0" class=" col-12  band">
                                            <app-slider-two-d [images]="userFiledetails" [UserId]="this.UserID"
                                                [UserProfile]="userProfile" [dismmis]="dismiss"></app-slider-two-d>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 div-center">
                            <div class="row w-100 image-margin">
                                <div class="col-4 p-0" *ngFor="let snapshot of allUserSnapDetails  ; let i=index;">
                                    <div class="d-flex align-items-center justify-content-center m-1">
                                        <img *ngIf="this.AvatarID != undefined"
                                            class="shadow-1-strong rounded story_image" data-bs-dismiss="modal"
                                            (click)="getUserSnapShotByAvatarId('user-story',snapshot)"
                                            [src]="snapshot.SnapshotFileUrl">
                                        <img *ngIf="this.AvatarID == undefined"
                                            class="shadow-1-strong rounded story_image" data-bs-dismiss="modal"
                                            (click)="getUserSnapShotDashboard('user-story',snapshot)"
                                            [src]="snapshot.SnapshotFileUrl">
                                    </div>
                                </div>
                            </div>
                            <div class="columnImg d-block m-1 image-margin" *ngIf="allUserSnapDetails.length > 2">
                                <div class="d-flex justify-content-center align-items-center m-1">
                                    <img *ngIf="this.AvatarID != undefined" class="shadow-1-strong rounded image-pop"
                                        (click)="getUserSnapShotByAvatarId('story',this.AvatarID)"
                                        data-bs-dismiss="modal" src="./assets/plus-8-512.png">
                                    <img *ngIf="this.AvatarID == undefined" class="shadow-1-strong rounded image-pop"
                                        (click)="getUserSnapShotDashboard('story','')" data-bs-dismiss="modal"
                                        src="./assets/plus-8-512.png">
                                </div>
                            </div>
                            <div *ngIf="brandsnaploadingSnap" class="text-center">
                                <div class="spinner-border m-5 p-5" role="status">
                                    <span class="sr-only"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>