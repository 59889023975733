<!--
 Copyright 2020 Google LLC

 Licensed under the Apache License, Version 2.0 (the "License");
 you may not use this file except in compliance with the License.
 You may obtain a copy of the License at

      http://www.apache.org/licenses/LICENSE-2.0

 Unless required by applicable law or agreed to in writing, software
 distributed under the License is distributed on an "AS IS" BASIS,
 WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 See the License for the specific language governing permissions and
 limitations under the License.
-->
<mat-form-field>
  <mat-label>Brand Name</mat-label>
  <input matInput cdkFocusInitial [(ngModel)]="data.brand.BrandName" />
</mat-form-field>


<div class="rdio-btn">
  <mat-label>Accessories</mat-label>
  <div class="rdo-btn-1">
    <label><input type="radio" name="Accessories" value="M" [(ngModel)]="data.brand.Accessories">M</label>
    <label> <input type="radio" name="Accessories" value="F" [(ngModel)]="data.brand.Accessories">F</label>
    <label> <input type="radio" name="Accessories" value="B" [(ngModel)]="data.brand.Accessories">B</label>
  </div>
</div>
<div class="rdio-btn">
  <mat-label>BottomWear</mat-label>
  <div class="rdo-btn-1">
    <label><input type="radio" name="BottomWear" value="M" [(ngModel)]="data.brand.BottomWear">M</label>
    <label> <input type="radio" name="BottomWear" value="F" [(ngModel)]="data.brand.BottomWear">F</label>
    <label> <input type="radio" name="BottomWear" value="B" [(ngModel)]="data.brand.BottomWear">B</label>
  </div>
</div>
<div class="rdio-btn">
  <mat-label>Footwear</mat-label>
  <div class="rdo-btn-1">
    <label><input type="radio" name="footwear" value="M" [(ngModel)]="data.brand.FootWear">M</label>
    <label> <input type="radio" name="footwear" value="F" [(ngModel)]="data.brand.FootWear">F</label>
    <label> <input type="radio" name="footwear" value="B" [(ngModel)]="data.brand.FootWear">B</label>
  </div>
</div>
<div class="rdio-btn">
  <mat-label>Head Gear</mat-label>
  <div class="rdo-btn-1">
    <label><input type="radio" name="HeadGear" value="M" [(ngModel)]="data.brand.HeadGear">M</label>
    <label> <input type="radio" name="HeadGear" value="F" [(ngModel)]="data.brand.HeadGear">F</label>
    <label> <input type="radio" name="HeadGear" value="B" [(ngModel)]="data.brand.HeadGear">B</label>
  </div>
</div>
<div class="rdio-btn">
  <mat-label>Upper Wear</mat-label>
  <div class="rdo-btn-1">
    <label><input type="radio" name="UpperWear" value="M" [(ngModel)]="data.brand.UpperWear">M</label>
    <label> <input type="radio" name="UpperWear" value="F" [(ngModel)]="data.brand.UpperWear">F</label>
    <label> <input type="radio" name="UpperWear" value="B" [(ngModel)]="data.brand.UpperWear">B</label>
  </div>
</div>


<div class="rdio-btn">
    <mat-label>Gender</mat-label>
    <div class="rdo-btn-1-g">
     <label ><input type="radio" name="gender" value="M"  [(ngModel)]="data.brand.Gender" >M</label> 
     <label > <input type="radio" name="gender" value="F"  [(ngModel)]="data.brand.Gender">F</label>
     </div>
    </div>

<mat-form-field>
  <mat-label>Logo SizeX</mat-label>
  <input type="number" min="1" max="9" matInput cdkFocusInitial [(ngModel)]="data.brand.LogoSizeX" />
</mat-form-field>

<mat-form-field>
  <mat-label>Logo SizeY</mat-label>
  <input type="number" id="quantity" name="quantity" min="1" max="9" matInput cdkFocusInitial [(ngModel)]="data.brand.LogoSizeY" />
</mat-form-field>
<mat-form-field>
  <mat-label>Logo Url</mat-label>
  <input type="url" matInput cdkFocusInitial [(ngModel)]="data.brand.LogoURL" />
</mat-form-field>
<div mat-dialog-actions>
  <button [disabled]="!data.brand.LogoURL" mat-raised-button color="primary"  [mat-dialog-close]="{ brand: data.brand }">Done</button>
  <button mat-button (click)="cancel()">Cancel</button>
  <!-- <button *ngIf="data.enableDelete" mat-mini-fab color="primary" aria-label="Delete"
    [mat-dialog-close]="{ brand: data.brand, Delete: true } "onclick="return confirm('Are you sure you want to delete this item?');">
    <mat-icon>Delete</mat-icon>
  </button> -->
</div>