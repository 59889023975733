import { Injectable } from '@angular/core';
import { ApiHttpService } from './api-http.service';

@Injectable({
  providedIn: 'root',
})
export class NavigationCard {
  constructor(private httpService: ApiHttpService) {
   }
   scrollToElementById(id: any) {
    console.log(document.getElementById(id))
    console.log('SAME-id',id);
      if(id == undefined )return
      const element = this.__getElementById(id);
      this.scrollToElement(element);
  }
  private __getElementById(id: string): HTMLElement {
    const element = document.getElementById(id);
    return element;
  }
  scrollToElement(element: HTMLElement) {
    setTimeout(() => {
      if (element) {
          element.scrollIntoView({ behavior: 'smooth', inline: 'nearest' });
      }
  }, 0);
 }
}