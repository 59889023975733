<div *ngIf="loading" class="loading">Loading&#8230;</div>
<div class="dt-responsive selectlist font-14 brand-profile-color   m-0 p-0 " (scroll)="scrollHandler()" #brandpageFeeds>
    <div class="brand-profile-color">
        <div  *ngIf="tab =='news-stories'" [ngClass]=" tab =='news-stories' ? '' : 'hidden-class'">
            <app-user-story 
                 [userSnapShotsInput]="filterSnap" [BrandId]="brandIdForApi"
                 [thresholdHeightPagination]="thresholdHeightPagination" 
                 [StoryIds]="selectedStoryId"
                 [PageCount]="pagecount" 
                 [pageNumber]="pageNumber"
                 (outPageNumber)="setPageNumber($event)"
                 [onChangeFlag]="ChangeFlag"
                 [clickEventFlag]="clickEventFlag"
                 [rootFlag]="rootFromBrandFlag"
                 [brandPageFlag]="rootBrandPage"
                 [routLogInFlag]="routLogInFlag"
                 >
            </app-user-story>
            <!-- Threshould{{thresholdHeightPagination}} -->
            <!-- <h6 class="text-white"><b>{{pagecount}}</b></h6> -->
        </div>
    </div>
    <div *ngIf="tab =='story' " class="story brand-profile-color mb-5 my-story">
        <div class="row justify-content-center" *ngIf="filterSnap != undefined && filterSnap.length >= 0">
            <div *ngIf="loadingforVerticalScroll" class="text-center text-white">
                <div class="spinner-border m-2 p-2" role="status">
                    <span class="sr-only"></span>
                </div>
            </div>



            <!-- <div class="column d-block " *ngFor="let snapshot of filterSnap; let i=index;">
                <img *ngIf="!loadingforVerticalScroll" class="columnImg d-block" (click)="scrollToStoryId(snapshot,i)"
                    [src]="snapshot.SnapshotFileUrl">
            </div>
            <div class="col-12 text-center text-white stify-content-center morebtn">
                <h6 *ngIf="this.pagecount > this.filterSnap.length" (click)="incrementImgCounter()"><b>More</b></h6>
            </div> -->
            <div class="col-12 div-center">
                <div class="row w-100 image-margin">
                    <div class="col-4 p-0"
                        *ngFor="let snapshot of filterSnap  ; let i=index;">
                        <div class="d-flex align-items-center justify-content-center m-1">
                            <img class="w-100 shadow-1-strong rounded m-1"
                            (click)="scrollToStoryId(snapshot,i)"[src]="snapshot.SnapshotFileUrl">
                        </div>
                    </div>
                </div>
                <div class="col-12 text-center text-white stify-content-center morebtn">
                    <h6 *ngIf="this.pagecount > this.filterSnap.length" (click)="incrementImgCounter()"><b>More</b></h6>
                </div>
            </div>


        </div>
    </div>
    
    <!-- <div *ngIf="!styleLoading" class="d-flex justify-content-center">
        <div class="spinner-border m-5 p-5 text-white" role="status">
            <span class=""></span>
        </div>
    </div> -->

    <div *ngIf="tab =='mens-wear' && styleLoading" class="brand-profile-color">
        <div class="col-lg-12 p-1">
            <span *ngIf="errMessage != '' " class="danger">
                <div class="alert alert-danger" role="alert">
                    {{errMessage}}
                </div>
            </span>
        </div>
        <div class="card file brand-profile-color " *ngIf="styleLoading">
            <div class="row   g-2 form d-block " *ngIf="packJsonObject.length > 0">
                <div class="slider-css211 ">
                    <div *ngFor="let object of packJsonObject[0].value['files'];let i=index" class="slider-img211"
                        (click)="DeatailsPopup(zipList[object],'bottomwear', object,'HeadwearID' )">
                        <img class="img-responsive1" [src]="sanitizer.bypassSecurityTrustUrl(zipList[object])" />
                    </div>
                </div>
                <div class="slider-css2-upper">
                    <div *ngFor="let object of packJsonObject[1].value['files'];let i=index" class="slider-img2-upper"
                        data-bs-toggle="collapse" data-bs-target="#info-3D" saria-expanded="false"
                        aria-controls="info-3D">
                        <div class="upper-div"
                            (click)="DeatailsPopup(zipList[object],'bottomwear',object ,'UpperwearID')">
                            <img class="img-responsive-upper"
                                [src]="sanitizer.bypassSecurityTrustUrl(zipList[object])" />
                        </div>
                    </div>
                </div>

                <div class="slider-css2">
                    <div *ngFor="let object of packJsonObject[2].value['files'];let i=index" class="slider-img2"
                        (click)="DeatailsPopup(zipList[object],'bottomwear', object,'BottomwearID' )">
                        <img class="img-responsive" [src]="sanitizer.bypassSecurityTrustUrl(zipList[object])" />
                    </div>
                </div>
                <div class="slider-css211">
                    <div *ngFor="let object of packJsonObject[3].value['files'];let i=index" class="slider-img211"
                        (click)="DeatailsPopup(zipList[object],'bottomwear', object,'FootwearID' )">
                        <img class="img-responsive1" [src]="sanitizer.bypassSecurityTrustUrl(zipList[object])" />
                    </div>
                </div>
                <!-- <div class="d-flex justify-content-center">
                    <div class="spinner-border" *ngIf="isloading" role="status">
                        <span class=""></span>
                    </div>
                </div> -->
            </div>
        </div>

    </div>
    <div *ngIf="tab =='womens-wear' && !styleLoading == false" class="brand-profile-color">
        <div class="col-lg-12 p-1">
            <span *ngIf="errMessage != '' " class="danger">
                <div class="alert alert-danger" role="alert">
                    {{errMessage}}
                </div>
            </span>
        </div>
        <div *ngIf="!styleLoading" class="d-flex justify-content-center">
            <div class="spinner-border m-5 p-5 text-white" role="status">
                <span class=""></span>
            </div>
        </div>
        <div class="card file brand-profile-color " *ngIf="styleLoading">
            <div class="row   g-2 form d-block " *ngIf="packJsonObject.length > 0">
                <div class="slider-css211 ">
                    <div *ngFor="let object of packJsonObject[0].value['files'];let i=index" class="slider-img211"
                        (click)="DeatailsPopup(zipList[object],'bottomwear', object,'HeadwearID' )">
                        <img class="img-responsive1" [src]="sanitizer.bypassSecurityTrustUrl(zipList[object])" />
                    </div>
                </div>
                <div class="slider-css2-upper">
                    <div *ngFor="let object of packJsonObject[1].value['files'];let i=index" class="slider-img2-upper"
                        data-bs-toggle="collapse" data-bs-target="#info-3D" saria-expanded="false"
                        aria-controls="info-3D">
                        <div class="upper-div"
                            (click)="DeatailsPopup(zipList[object],'bottomwear',object ,'UpperwearID')">
                            <img class="img-responsive-upper"
                                [src]="sanitizer.bypassSecurityTrustUrl(zipList[object])" />
                        </div>
                    </div>
                </div>
                <div class="slider-css2">
                    <div *ngFor="let object of packJsonObject[2].value['files'];let i=index" class="slider-img2"
                        (click)="DeatailsPopup(zipList[object],'bottomwear', object,'BottomwearID' )">
                        <img class="img-responsive" [src]="sanitizer.bypassSecurityTrustUrl(zipList[object])" />
                    </div>
                </div>
                <div class="slider-css211">
                    <div *ngFor="let object of packJsonObject[3].value['files'];let i=index" class="slider-img211"
                        (click)="DeatailsPopup(zipList[object],'bottomwear', object,'FootwearID' )">
                        <img class="img-responsive1" [src]="sanitizer.bypassSecurityTrustUrl(zipList[object])" />
                    </div>
                </div>
                <div class="d-flex justify-content-center">
                    <div class="spinner-border" *ngIf="isloading" role="status">
                        <span class=""></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>