<!-- <div *ngIf="isLoading" class="loader-overlay">
    <img *ngIf="isLoading" [src]="SnapURL" alt="Loading..." class="default-image">
</div>
<div *ngIf="!isLoading">
  <ng-lottie #lottie [options]="lottieConfig" [style.transform]="'scale(1.8)'" [styles]="{ width: '350px', height: '350px' }"
    (touchmove)="onMove($event)" (mousemove)="onMove($event)" (animationCreated)="animationCreated($event)">
  </ng-lottie>
</div> -->


<div class="image-container">
    <div [ngClass]="{'hidden': !isLoading}">
        <div class="spinner-border" role="status">
            <span class="visually-hidden"></span>
        </div>
         <img [src]="SnapURL" alt="Loading..." class="default-image">
    </div>

    <div [ngClass]="{'hidden': isLoading}">
        <ng-lottie #lottie [options]="lottieConfig" [style.transform]="'scale(1.8)'"
            [styles]="{ width: '350px', height: '350px' }" (touchmove)="onMove($event)" (mousemove)="onMove($event)"
            (animationCreated)="animationCreated($event)">
        </ng-lottie>
    </div>
</div>