import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { finalize, map, Observable } from 'rxjs';
import { PopupTwoDComponent } from 'src/app/common/popup-two-d/popup-two-d.component';
import { CaraosalPopupComponent } from 'src/app/common/templets/caraosal-popup/caraosal-popup.component';
import { Brand } from 'src/app/models/brand';
import { CommonService } from 'src/app/services/common.service';
interface ModelViewerElement extends HTMLElement {
  toBlob: (options?: { idealAspect?: boolean }) => Promise<Blob>;
}

@Component({
  selector: 'app-store-front-config',
  templateUrl: './store-front-config.component.html',
  styleUrls: ['./store-front-config.component.css']
})
export class StoreFrontConfigComponent {
  [x: string]: any;


  brandColors = this.firestore.collection('BrandColor').valueChanges({ idField: 'id' }) as Observable<Brand[]>;

  textureLibrary = this.firestore.collection('TextureLibrary').valueChanges({ idField: 'id' }) as Observable<any[]>;
  groupBrands = this.firestore.collection('GroupBrands').valueChanges({ idField: 'id' }) as Observable<[]>;
  pageCollection = this.firestore.collection('Pages').valueChanges({ idField: 'id' }) as Observable<[]>;
  securityGrpCollection = this.firestore.collection('SecurityGroup').valueChanges({ idField: 'id' }) as Observable<[]>;
  brands = this.firestore.collection('brands').valueChanges({ idField: 'id' }) as Observable<Brand[]>;
  userAvatar = this.firestore.collection('UserAvatar').valueChanges({ idField: 'id' }) as Observable<Brand[]>;
  userBrandAvatar = this.firestore.collection('BrandAvatar').valueChanges({ idField: 'id' }) as Observable<Brand[]>;
  garmentsList = this.firestore.collection('garments').valueChanges({ idField: 'id' }) as Observable<any[]>;
  userFile = this.firestore.collection('userfiles').valueChanges({ idField: 'id' }) as Observable<any[]>;
  defaultGarmentCollection = this.firestore.collection('DefaultBrandGarments').valueChanges({ idField: 'id' }) as Observable<any[]>;

  groupUser = this.firestore.collection('GroupUser').valueChanges({ idField: 'id' }) as Observable<[]>;
  groupRoleCollection = this.firestore.collection('GroupRole').valueChanges({ idField: 'id' }) as Observable<[]>;





  store = this.firestore.collection('StoreFront').valueChanges({ idField: 'id' }) as Observable<any[]>;
  @ViewChild('brandStoriyScrollA', { static: false }) brandStoriyScrollA: ElementRef;
  @ViewChild('brandStoriyScroll', { read: ElementRef }) public BrandStoriyScroll: ElementRef<any>;
  private scrollEventListener: () => void;
  private slider: HTMLElement;

  @ViewChild('scrollContainerWUpper') scrollContainerWUpper: ElementRef;
  @ViewChild('scrollContainerWBottom') scrollContainerWBottom: ElementRef;
  @ViewChild('scrollContainerWHeadGear') scrollContainerWHeadGear: ElementRef;
  @ViewChild('scrollContainerWFootwear') scrollContainerWFootwear: ElementRef;


  @ViewChild('scrollContainerMUpper') scrollContainerMUpper: ElementRef;
  @ViewChild('scrollContainerMBottom') scrollContainerMBottom: ElementRef;
  @ViewChild('scrollContainerMHeadGear') scrollContainerMHeadGear: ElementRef;
  @ViewChild('scrollContainerMFootwear') scrollContainerMFootwear: ElementRef;


  @ViewChild('modelViewer') modelViewer: ElementRef;
  @ViewChild('thumbnailImage') thumbnailImage: ElementRef;


  dropdownOpen:boolean = false;

  selectedcolor: any;
  packUrl = ""
  deleteFlag: boolean = false
  functionForStore: any;
  logInFlag: boolean
  byScroll: boolean = true
  cachepanel: boolean = false
  mannequinFlag: boolean = false
  scrollFlag: boolean
  filterGarmrntList = [];
  filteredData = [];
  selectedGender: string = '';
  selectedClothType: string = '';
  selectedCloth: string = '';
  brandList: any = [];
  garmentsCollection: any = [];
  selectedBrand: any;
  selectStoreFrontType: any = ''
  selectColunmSide: any = '';
  loading: boolean;
  userObject: { userid: any; pagename: string; };
  selectedSecGrpId: any;
  preferenceDocId: any;
  groupBrandsList: any = []
  securityGroupList: any = []
  userAvatarList: any = []
  userBrandAvatarList = []

  userReferenceList = [];
  selectedAssignSecGrpId: any;
  userSecurityGrp: never[];
  subcollections: any;
  userAvatarDataList: any[];
  assignBrandList: any = [];
  userBrandAvatarFilterList: any[];
  selectedFrontStoreType: any = '';
  modelViewerFlag: boolean;
  userfileCollection: any = [];
  garmetCacheFile: any = {}
  filterGarmrnt2DFiles: any = [];
  gender: any;
  storeFrontCollection: any[];
  selectedBrandID: any;
  image: any;

  selectedImageIndex: number;
  selectStoreColomnType: any = '';
  selectedAvatar: any = '';
  avatarList: any = [];

  tab: any = ''
  loadingState: boolean;
  closeDiv: boolean = true;
  showBrandFlag: boolean = false;
  sideNavHeight: number
  isloadingBrandPanel: boolean = true;
  showTheBrandMode: boolean = true
  showDropDown: boolean = false
  userSnapShotList: any = [];
  storeFlage: boolean;
  flipFlag: boolean;
  isLoadingStyle: boolean;
  isLoading: boolean;
  zipList: any = {}
  isloading: boolean = false;
  errMessage: any = ''
  selectedBrandId: any
  testVar: any = '';
  pack0 = "%2Fpack0";
  pack1 = "%2Fpack1";
  callDownloadcheck: any;
  // private activeDownloads: Set<string> = new Set();
  callDownloadcache: any;
  downloadFlag: boolean = true
  newZipLists: any = {};
  swichImageFlage: boolean = true;
  garmentClothType: any;
  source: any;
  style: string = 'treads';
  clothDetails: any = {};
  garmentScrollFlag = false;
  selectedIcon: any;
  iconselctFlag: boolean;
  showMore: boolean = false;
  isloadingPanel: boolean = false;

  garmentData: any = []
  userFileList: any = []

  userFiledetails3d: any = []
  userSnapShotListGarment: any = []
  brandObject: any
  typeWear: any
  dummyText: any = ''
  userFiledetails: any = []
  garmentImgage: any = ''
  garmentClothId: any = ''

  FunctionFlag = ''
  evnDefault = ''
  showStoreFrontSaveImage: any = {}
  showStoreFrontSaveImageRight: any = {}
  storeFrontData: any = {}
  getStoreDataFlage: boolean
  selectStoreFrontWindowType: any
  selectStoreFrontWindowStyle: any

  selectStoreFrontWindowTexture: any = '';
  selectStoreFrontOuterTexture: any = '';
  selectStoreFromBrandColor: any = '';

  windowStyleflage: boolean = false



  ///Avatar variables 
  defaultMaleAvatars: any = [];
  defaultFemaleAvatars: any = [];
  defaultMaleAvatarsList: any = [];
  defaultFemaleAvatarsList: any = [];
  setBrandAvatarDetails_M: any = {};
  setBrandAvatarDetails_F: any = {};
  defaultSelectedAvatarFeMale: any;
  defaultSelectedAvatarMale: any;
  showSlider: boolean = false;
  defaultSelectedAvatar: any;
  defaultAvatar_M: any = {};
  defaultAvatar_F: any = {};
  functionFlag: any;
  queryHeadgear: any = "";
  queryUpperwear: any = "";
  queryBottomwear: any = "";
  queryFootwear: any = "";
  garmentLinks_F: any = {};
  garmentLinks_M: any = {};
  avatarGarmentId: any = {}
  selectGarmentType: any;
  selectedGarmentCacheUrl: any = {}
  selectedGarmentCacheUrlLeft: any = {}

  selectedGarmentCacheUrlRight: any = {}


  defaultGarment: any = [];
  filteredBrandcolor: any = [];
  brandNameSelected: any

  garmetBinaryImgDetails: any
  garmentTypeDetails: any

  imageObject: any
  keyType: any
  genders: any
  garmentLink: any

  upperwear: any;
  bottomwear: any;
  headgear: any;
  footwear: any;

  garmentH: any;
  garmentB: any;
  garmentF: any;
  garmentU: any;
  allSelectedUrl: any = [];
  dialogRef: any;
  isLoadedLoading = true
  sideCheck: any = ''


  isMouseDown: boolean = false;
  startX: number = 0;
  scrollLeft: number = 0;












  hideBothAvatar: any;

  BrandId: any = '';
  BrandGender: any = '';
  StoreFrontType: any = ''; // Define according to your needs
  LeftColumnType: any = ''; // Default value
  LeftGarmentID: any = '';
  LeftDocumentID: any = ''; // From UserFiles for the corresponding LeftGarmentID
  LeftAvatarID: any = '';
  LeftHeadGearID: any = '';
  LeftUpperwearID: any = '';
  LeftBottomwearID: any = '';
  LeftFootwearID: any = '';
  RightColumnType: any = '' // Default value
  RightGarmentID: any = '';
  RightDocumentID: any = ''; // From UserFiles for the corresponding RightGarmentID
  RightAvatarID: any = '';
  RightHeadGearID: any = '';
  RightUpperwearID: any = '';
  RightBottomwearID: any = '';
  RightFootwearID: any = '';
  BrandMode: any = ''
  getAvatrFlag: boolean

  textureLibraryList: any = []
  brandColor : any = []
  filterBrandListNew : any = []



  packJsonObject = [];
  brandFlag: boolean
  storeFrontFlag: boolean = true
  hasCalledShowBrandPackDetails: boolean;



  constructor(public commonService: CommonService, public sanitizer: DomSanitizer, private storage: AngularFireStorage,
    private _httpClient: HttpClient, private firestore: AngularFirestore, public router: Router, public dialog: MatDialog, private modal: NgbModal,) {
    this.logInFlag = this.commonService.isLoggedIn()

    this.groupBrands.subscribe((brandGp) => {
      this.groupBrandsList = brandGp
    })

    this.securityGrpCollection.subscribe((groupList: any) => {
      this.securityGroupList = groupList
    })

    this.brands.subscribe((brand) => {
      this.brandList = brand
      this.brandList.forEach(async (brand) => {
        let type = 'b';
        let url = await this.commonService.compressImage(brand.LogoURL, type); // Assuming each brand has 'imagePath' property
        brand.Brandurl = url.downloadUrl;  // Save the compressed image URL back into the brand object
      });
      console.log("brand List", this.brandList)
    })

    this.userAvatar.subscribe((avatarData) => {
      this.userAvatarList = avatarData
    })

    this.userBrandAvatar.subscribe((avatarData) => {
      this.userBrandAvatarList = avatarData
    })

    this.garmentsList.subscribe((garments) => {
      this.garmentsCollection = garments;
    });

    this.userFile.subscribe((files) => {
      this.userfileCollection = files;
    })
    this.textureLibrary.subscribe((lib) => {
      this.textureLibraryList = lib;
    })

    this.store.subscribe((store) => {
      this.storeFrontCollection = store
    })

    this.defaultGarmentCollection.subscribe((clothList) => {
      this.defaultGarment = clothList
    })


    this.brandColors.subscribe((colorList) => {
      this.brandColor = colorList
    })

    


    const text = "Your brand's apparel is digitised in 3D.\n\nOn a gamified experience, consumers style avatars & share\n\nanimated avatar stories (linked to ecommerce)\n\nDefining a new paradigm in immersive storytelling for social media & the metaverse";

    const lines = text.split('\n\n');
    this.dummyText = lines[0] + '\n' + lines[1] + '\n' + lines[2] + '\n' + lines
    this.checkPageAuthorization()

  }


  ngOnInit(): void {

    let title = 'Yologram - store-front-config'
    this.commonService.changeTitle(title);

  }

  selectBrand(brand) {
    this.filterGarmrntList = []
    this.selectedBrand = brand;
    this.selectedGender = '';
    this.selectedClothType = '';
    this.selectedCloth = '';
    this.selectColunmSide = ''
    this.tab = ''
    let brandId = brand.id
    this.showDropDown = false
    this.getAvatrFlag = false
    this.showTheBrandMode = true
    this.selectedBrandID = brand.BrandId
    this.functionFlag = 'hideBothAvatar'


    this.selectedBrandId = this.selectedBrandID


    // let obj = this.getBrandDetails(this.selectedBrandId ? this.selectedBrandId : null);
    this.selectStoreColomnType = ''
    this.selectStoreFrontType = ''
    this.selectStoreFrontWindowType = ''
    this.selectStoreFrontWindowStyle = ''
    this.defaultMaleAvatars = [];
    this.defaultFemaleAvatars = [];
    this.defaultMaleAvatarsList = [];
    this.defaultFemaleAvatarsList = [];
    this.defaultSelectedAvatarMale = null
    this.defaultSelectedAvatarFeMale = null

    this.selectedGarmentCacheUrl = {}
    this.StoreFrontType = '';
    this.LeftColumnType = '';
    this.LeftGarmentID = '';
    this.LeftDocumentID = '';
    this.LeftAvatarID = '';
    this.LeftHeadGearID = '';
    this.LeftUpperwearID = '';
    this.LeftBottomwearID = '';
    this.LeftFootwearID = '';
    this.RightColumnType = '';
    this.RightGarmentID = '';
    this.RightDocumentID = '';
    this.RightAvatarID = '';
    this.RightHeadGearID = '';
    this.RightUpperwearID = '';
    this.RightBottomwearID = '';
    this.RightFootwearID = '';
    this.storeFrontData = {}
    let obj = this.getBrandDetails(this.selectedBrandId )
    this.brandNameSelected = obj.BrandName
    console.log("this.selected obj", obj)

    this.getstoreData()
    console.log("this.selectedBrand",)
    setTimeout(() => {
      console.log("this.selectedBrand", this.selectedBrand);
      this.commonService.scrollToElementByIdbrand(brandId, this.brandStoriyScrollA);
      // Adjust the scroll position by -60px after the service call
      const element = document.getElementById(brandId);
      if (element) {
        element.scrollLeft -= 60;
      }
    }, 50); // Adjust the timeout duration as needed (500ms in this example)
    setTimeout(() => {
      this.byScroll = false
    }, 200)


  }

  selectByScroll() {
    this.byScroll = true
  }

  getBrandDetails(id) {
    let brand = this.brandList.find((brand) => brand.id == id)
    return brand
  }


  async getBrandImage(brand) {
    let brandObject = await this.getBrandDetails(brand.BrandId)
    let imgURL = brandObject.LogoURL
    let brandEncodedurl = encodeURI(imgURL).replace('/', '%2F');
    const newPath = brandEncodedurl.replace('Brands%2F', 'Brands%2Fcompressed%2F');
    let url = "https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/" + newPath + "?alt=media&token=550e7e88-bed3-4668-84ff-ad491c19e06c"
    return url;
  }



  checkPageAuthorization() {
    this.loading = true;
    this.commonService.getUser().subscribe((user: any) => {
      let userObject = { 'userid': user?.uid, 'pagename': '/store-front-config' }
      this.userObject = userObject
      this.callgpIdFunction(userObject)
      this.getAvatarSubcollections(userObject.userid)
      this.commonService.checkViewPageRights(userObject).subscribe((res) => {
        this.loading = false;
        if (!res) {
          this.router.navigateByUrl("/drip");
        }
      });
    });
  }


  callgpIdFunction(userObject) {
    this.getUserPreference(userObject.userid)
  }


  getAvatarSubcollections(id) {
    const docId = id; // Use the actual document ID here
    this.commonService.listSubcollections(docId)
      .then(subcollections => {
        this.subcollections = subcollections
        this.getDocumentDetails(subcollections, id)
      })
      .catch(error => {
        console.error('Error fetching subcollections:', error);
      });
  }

  getUserPreference(id) {
    this.groupUser.subscribe((grpList) => {
      this.userSecurityGrp = grpList.filter((grp: any) => grp.UserId === id);
      this.firestore.collection("UserPreference").ref
        .where("Key", "==", "LastUserGroupSelected")
        .where("UserId", "==", id.toString())
        .get().
        then((objectList: any) => {
          this.userReferenceList = objectList.docs;
          if (objectList.docs.length > 0 && this.userSecurityGrp.length > 0) {
            objectList.forEach((doc) => {
              if (doc.data().UserId == id && this.userSecurityGrp.find(b => b['SecGpId'] == doc.data().KeyValue) != undefined
              ) {
                this.selectedSecGrpId = doc.data().KeyValue;
                this.preferenceDocId = doc.id;
                this.groupRoleCollection.subscribe(((groupRole: any) => {
                  let accessPageListselectGroup = groupRole.find((obj) => obj.SecGpId == this.selectedSecGrpId)
                  this.selectedAssignSecGrpId = accessPageListselectGroup.SecGpId
                  this.getAssignBrandList(this.selectedAssignSecGrpId)
                }))
              }
            });
          }
        }).catch((error) => {
          console.log("Error getting documents: ", error);
        });
    });
  }


  async getDocumentDetails(subcollectionIds: any[], id) {
    try {
      // Step 1: Get the list of subcollection IDs.
      let avatarData = {};
      let avatarDataAll = []
      // Step 2: For each subcollection ID, get the documents and add them to `avatarData`.
      await this.subcollections.forEach(async (doc) => {
        await this.commonService
          .getDocumentsFromSubcollection(id, doc)
          .then((documents) => {
            // console.log("this.id, this.avatarID", dummyId,doc)
            if (documents && documents.length > 0) {
              const firstDocument = documents[0]; // Assuming there is only one document
              firstDocument.avatarID = doc
              avatarData = firstDocument;
              avatarDataAll.push(avatarData)
            }
          })
        this.userAvatarDataList = avatarDataAll
      })

      console.log(avatarData);
    } catch (error) {
      console.error('Error in component:', error);
    }
  }

  async getAssignBrandList(gpId) {
    // this.assignBrandList = this.groupBrandsList.filter(b => b.SecGpId == gpId);

    this.assignBrandList = await Promise.all(
      this.groupBrandsList
        .filter(b => b.SecGpId == gpId)
        .map(async (brand) => {
          const logoUrl = await this.getBrandImage(brand);
          return {
            ...brand,
            logoUrl
          };
        })
    );

    console.log(" this.assignBrandList", this.assignBrandList)

    console.log(" this.assignBrandList", this.assignBrandList)
    let groupBrandsList = this.groupBrandsList.filter(grp => grp.SecGpId === gpId);

    this.userBrandAvatar.subscribe(avatarData => {
      this.userBrandAvatarList = avatarData;

      // Step 1: Filter based on BrandId matches.
      this.userBrandAvatarFilterList = [];
      groupBrandsList.forEach(groupBrand => {
        this.userBrandAvatarFilterList = this.userBrandAvatarFilterList.concat(
          this.userBrandAvatarList.filter(avatar => avatar.BrandId === groupBrand.BrandId)
        );
      });
      const hasDefault = this.userBrandAvatarFilterList.some(avatar => avatar.BrandId === 'Default');
      if (!hasDefault) {
        const defaultAvatars = this.userBrandAvatarList.filter(avatar => avatar.BrandId === 'Default');
        this.userBrandAvatarFilterList = this.userBrandAvatarFilterList.concat(defaultAvatars);
      }
    });
  }

  selectStoreFront($event) {
    this.selectStoreColomnType = ''

    this.selectedFrontStoreType = $event.target.value;
    console.log("this.storeFrontData selectStoreFront", this.storeFrontData)

    if ((this.selectedFrontStoreType == '2DImage' || this.selectedFrontStoreType == '2DVideo') && !this.getStoreDataFlage) {
      this.modelViewerFlag = true
      this.showBrandPackDetails()
    }

  }

  selectAutoLoadThestoreFront() {
    //when the sore is already selected the call this function for the showing selected store in column
    console.log("StoreFrontType", this.storeFrontData.StoreFrontType)
    console.log("RightColumnType", this.storeFrontData.RightColumnType)
    console.log("LeftColumnType", this.storeFrontData.LeftColumnType)
    const event = { target: { value: this.selectedFrontStoreType } };
    this.selectStoreFrontType = this.selectedFrontStoreType
    // this.selectStoreFront(event)
    setTimeout(() => {
      // Create a mock event object
      const mockEvent = { target: { value: '3DDouble' } };
      // Call selectStoreFront with the mock event object
      this.selectStoreFront(mockEvent);
    }, 50); // Delay for
    let setSide

    if (this.sideCheck != 'Both') {
      if (this.sideCheck == 'Left') {
        setSide = 'Right'
      } else if (this.sideCheck == 'Right') {
        setSide = 'Left'
      }
      this.selectColunmSide = setSide
      //   setTimeout(() => {
      //     console.log("setSide", setSide)
      //     console.log("this.selectColunmSide", this.selectColunmSide)
      //     this.selectColunmSide == setSide
      //     // this.selectColunm({ target: { value: side } })
      // }, 60); // Delay for

    }
  }

  // selectStoreColoumnType($event) {
  //   this.selectStoreColomnType = $event.target.value;
  //   this.modelViewerFlag = true
  //   if ((this.selectStoreColomnType =='Mannequin' || this.selectStoreColomnType =='Avatar'
  //      || this.selectStoreColomnType =='3DObj') && !this.getStoreDataFlage) {
  //     this.showBrandPackDetails()
  //   }
  //   if (this.selectStoreColomnType == 'Avatar') {
  //     if (this.tab == 'womens-wear') {
  //       this.currentBrandAvatar_FeMale();
  //     }
  //     if (this.tab == 'mens-wear') {
  //       this.currentBrandAvatar_Male();
  //     }
  //   }
  // }

  selectStoreColoumnType($event) {
    this.selectStoreColomnType = $event.target.value;
    this.modelViewerFlag = true;

    if ((this.storeFrontData.LeftColumnType == 'Avatar' || this.storeFrontData.RightColumnType == 'Avatar')
      && this.selectStoreColomnType == 'Avatar') {
      alert('Not allow to the both Column For avatar')
      return
    }






    // Initialize the flag if it's not set
    if (this.hasCalledShowBrandPackDetails === undefined) {
      this.hasCalledShowBrandPackDetails = false;
    }

    // Check if the store column type requires showing brand pack details
    if ((this.selectStoreColomnType == 'Mannequin' || this.selectStoreColomnType == 'Avatar' || this.selectStoreColomnType == '3DObj')) {
      this.showBrandPackDetails();
      if (!this.hasCalledShowBrandPackDetails) {
        this.showBrandPackDetails();
        this.hasCalledShowBrandPackDetails = true; // Set the flag to true after calling the function
      }
    }

    // Handle Avatar-specific logic
    if (this.selectStoreColomnType == 'Avatar') {
      if (this.tab == 'womens-wear') {
        this.currentBrandAvatar_FeMale();
      }
      if (this.tab == 'mens-wear') {
        this.currentBrandAvatar_Male();
      }
    }
  }



  filterByGender($event) {
    this.selectedGender = $event.target.value
  }


  @HostListener('document:click', ['$event'])
  onDocumentClick(event: Event): void {
      const targetElement = event.target as HTMLElement;
      if (!targetElement.closest('.custom-select-container')) {
          this.closeDropdown();
      }
  }
  closeDropdown() {
    this.dropdownOpen = false;
}
  
  selectClothType($event) {
    this.filterGarmrntList = []
    this.selectedClothType = $event.target.value;
    console.log("clothtype", this.selectedClothType)
    this.filterClothList()
  }



  async filterClothList() {
    this.filterGarmrntList = []
    let brand = this.assignBrandList.find((brand) => brand.id == this.selectedBrand.id)
    let brandId = brand.BrandId
    console.log(brandId)
    console.log(brand)

    // this.selectedBrand =  brand.id

    // Ensure selectedBrand is properly set before filtering

    if (this.selectStoreFrontType == 'GarmentUpload2D' || this.selectStoreFrontType == '2Dvideo') {
      this.filterGarmrntList = await this.garmentsCollection.filter((garment) =>
        garment.GarmentType == this.selectedClothType &&
        garment.Gender == this.selectedGender &&
        garment.BrandID == brandId
      );
    } else {
      this.filterGarmrntList = await this.garmentsCollection.filter((garment) =>
        garment.Gender == this.selectedGender && garment.GarmentType == this.selectedClothType &&
        garment.BrandID == brandId
      );
    }


    this.selectedBrandID = brand.BrandId

  }

  selectCloth($event) {
    this.selectedCloth = $event.target.value;
    this.filterGarmrnt2DFiles = this.userfileCollection.filter((file) => file.fileType == this.selectedFrontStoreType && file.link == this.selectedCloth)
    console.log("this.filterGarmrnt2DFiles", this.filterGarmrnt2DFiles)
  }

  selectObjectCloth($event) {
    this.selectedCloth = $event.target.value;
    this.filterGarmrnt2DFiles = this.userfileCollection.filter((file) => file.fileType == 'GarmentUpload3D' && file.link == this.selectedCloth)
    console.log("this.filterGarmrntDFiles", this.filterGarmrnt2DFiles)
  }

  selectImage(image, i) {
    this.image = image.fileurl;
    this.selectedImageIndex = i;
  }

  validateInputs(): boolean {

    if (!this.selectedBrand) {
      alert('Brand is required.');
      return false;
    }

    if (!this.selectedClothType || !this.selectedClothType.trim()) {
      alert('Cloth type is required.');
      return false;
    }
    if (!this.selectedGender || !this.selectedGender.trim()) {
      alert('Gender is required.');
      return false;
    }
    if (!this.selectedCloth) {
      alert('Cloth is required.');
      return false;
    }
    return true;
  }

  // async addInStoreFront() {
  //   let brandObject = await this.getBrandDetails(this.selectedBrandID)
  //   this.BrandId = brandObject.id
  //   this.BrandGender = brandObject.Gender
  //   if(this.selectColunmSide == 'Left'){
  //     this.LeftColumnType = this.selectStoreColomnType
  //   }else if(this.selectColunmSide == 'Right'){
  //     this.RightColumnType = this.selectStoreColomnType
  //   }else {
  //     this.LeftColumnType = this.selectStoreColomnType
  //   }
  //   const brandMode = this.tab === 'mens-wear' ? 'M' : this.tab === 'womens-wear' ? 'W' : this.tab === 'story' ? 'S' : '';
  //   const storeData = {
  //     BrandId: this.BrandId, // Updated to use the class property
  //     BrandMode: brandMode,
  //     StoreFrontType: this.selectStoreFrontType,
  //     LeftColumnType: this.LeftColumnType,
  //     LeftGarmentID: this.LeftGarmentID,
  //     LeftDocumentID: this.LeftDocumentID,
  //     LeftAvatarID: this.LeftAvatarID,
  //     LeftHeadGearID: this.LeftHeadGearID,
  //     LeftUpperwearID: this.LeftUpperwearID,
  //     LeftBottomwearID: this.LeftBottomwearID,
  //     LeftFootwearID: this.LeftFootwearID,
  //     RightColumnType: this.RightColumnType,
  //     RightGarmentID: this.RightGarmentID,
  //     RightDocumentID: this.RightDocumentID,
  //     RightAvatarID: this.RightAvatarID,
  //     RightHeadGearID: this.RightHeadGearID,
  //     RightUpperwearID: this.RightUpperwearID,
  //     RightBottomwearID: this.RightBottomwearID,
  //     RightFootwearID: this.RightFootwearID
  //   };
  //   this.firestore.collection('StoreFront').add(storeData).then(() => {
  //     console.log('Document successfully written!');
  //     this.selectBrand(this.selectedBrand)
  //     alert('Data Saved.')
  //     this.flipFlag = false
  //   }).catch((error) => {
  //     console.error('Error writing document: ', error);
  //   });
  // }

  // async addInStoreFront() {
  //   try {
  //     // Fetch brand details
  //     let brandObject = await this.getBrandDetails(this.selectedBrandID);
  //     this.BrandId = brandObject.id;
  //     this.BrandGender = brandObject.Gender;

  //     // Determine column type based on selected side
  //     if (this.selectColunmSide === 'Left') {
  //       this.LeftColumnType = this.selectStoreColomnType;
  //     } else if (this.selectColunmSide === 'Right') {
  //       this.RightColumnType = this.selectStoreColomnType;
  //     } else {
  //       this.LeftColumnType = this.selectStoreColomnType; // Default case if no side is selected
  //     }

  //     // Determine brand mode based on tab
  //     const brandMode = this.tab === 'mens-wear' ? 'M' :
  //                       this.tab === 'womens-wear' ? 'W' :
  //                       this.tab === 'story' ? 'S' : '';

  //     // Define storeData object
  //     //this.storeFrontData.id

  //     const storeData = {
  //       BrandId: this.BrandId || this.storeFrontData.BrandId,
  //       BrandMode: brandMode || this.storeFrontData.BrandMode,
  //       StoreFrontType: this.selectStoreFrontType || this.storeFrontData.StoreFrontType || "",
  //       LeftColumnType: this.LeftColumnType || this.storeFrontData.LeftColumnType || "",
  //       LeftGarmentID: this.LeftGarmentID || this.storeFrontData.LeftGarmentID || "",
  //       LeftDocumentID: this.LeftDocumentID || this.storeFrontData.LeftDocumentID || "",
  //       LeftAvatarID: this.LeftAvatarID || this.storeFrontData.LeftAvatarID || "",
  //       LeftFootwearID: this.LeftFootwearID || this.storeFrontData.LeftFootwearID || "",
  //       LeftBottomwearID: this.LeftBottomwearID || this.storeFrontData.LeftBottomwearID || "",
  //       LeftHeadGearID: this.LeftHeadGearID || this.storeFrontData.LeftHeadGearID || "",
  //       LeftUpperwearID: this.LeftUpperwearID || this.storeFrontData.LeftUpperwearID || "",
  //       RightColumnType: this.RightColumnType || this.storeFrontData.RightColumnType || "",
  //       RightGarmentID: this.RightGarmentID || this.storeFrontData.RightGarmentID || "",
  //       RightDocumentID: this.RightDocumentID || this.storeFrontData.RightDocumentID || "",
  //       RightAvatarID: this.RightAvatarID || this.storeFrontData.RightAvatarID || "",
  //       RightFootwearID: this.RightFootwearID || this.storeFrontData.RightFootwearID || "",
  //       RightBottomwearID: this.RightBottomwearID || this.storeFrontData.RightBottomwearID || "",
  //       RightHeadGearID: this.RightHeadGearID || this.storeFrontData.RightHeadGearID || "",
  //       RightUpperwearID: this.RightUpperwearID || this.storeFrontData.RightUpperwearID || "",
  //     };

  //     console.log(storeData);

  //     // Check if a document exists
  //     const storeFrontDocRef = this.firestore.collection('StoreFront').doc(this.storeFrontData.id);
  //     const docSnapshot = await storeFrontDocRef.get().toPromise();

  //     if (docSnapshot.exists) {
  //       // Update the document
  //       alert('Updating document');
  //       await storeFrontDocRef.update(storeData);
  //       console.log('Document successfully updated!');
  //     } else {
  //       // Add a new document
  //       await storeFrontDocRef.set(storeData);
  //       console.log('Document successfully written!');
  //     }

  //     this.selectBrand(this.selectedBrand);
  //     alert('Data Saved.');
  //     this.flipFlag = false;

  //   } catch (error) {
  //     console.error('Error processing document: ', error);
  //   }
  // }

  // async addInStoreFront() {
  //   try {
  //     // Fetch brand details
  //     let brandObject = await this.getBrandDetails(this.selectedBrandID);
  //     this.BrandId = brandObject.id;
  //     this.BrandGender = brandObject.Gender;

  //     // Determine column type based on selected side
  //     if (this.selectColunmSide === 'Left') {
  //       this.LeftColumnType = this.selectStoreColomnType;
  //     } else if (this.selectColunmSide === 'Right') {
  //       this.RightColumnType = this.selectStoreColomnType;
  //     } else {
  //       this.LeftColumnType = this.selectStoreColomnType; // Default case if no side is selected
  //     }

  //     // Determine brand mode based on tab
  //     const brandMode = this.tab === 'mens-wear' ? 'M' :
  //                       this.tab === 'womens-wear' ? 'W' :
  //                       this.tab === 'story' ? 'S' : '';

  //     // Define storeData object
  //     const storeData = {
  //       BrandId: this.BrandId,
  //       BrandMode: brandMode,
  //       StoreFrontType: this.selectStoreFrontType,
  //       LeftColumnType: this.LeftColumnType,
  //       LeftGarmentID: this.LeftGarmentID,
  //       LeftDocumentID: this.LeftDocumentID,
  //       LeftAvatarID: this.LeftAvatarID,
  //       LeftFootwearID: this.LeftFootwearID,
  //       LeftBottomwearID: this.LeftBottomwearID,
  //       LeftHeadGearID: this.LeftHeadGearID,
  //       LeftUpperwearID: this.LeftUpperwearID,
  //       RightColumnType: this.RightColumnType,
  //       RightGarmentID: this.RightGarmentID,
  //       RightDocumentID: this.RightDocumentID,
  //       RightAvatarID: this.RightAvatarID,
  //       RightFootwearID: this.RightFootwearID,
  //       RightBottomwearID: this.RightBottomwearID,
  //       RightHeadGearID: this.RightHeadGearID,
  //       RightUpperwearID: this.RightUpperwearID,
  //     };

  //     console.log(storeData);

  //     let storeFrontDocRef;
  //     console.log(this.storeFrontData)
  //     if (this.storeFrontData) {
  //       // Use the existing document ID if available
  //       storeFrontDocRef = this.firestore.collection('StoreFront').doc(this.storeFrontData.id);
  //     } else {
  //       // Create a new document and store its ID in this.storeFrontData
  //       storeFrontDocRef = this.firestore.collection('StoreFront').doc();
  //       // this.storeFrontData = { id: storeFrontDocRef.id };
  //     }

  //     const docSnapshot = await storeFrontDocRef.get().toPromise();

  //     if (docSnapshot.exists) {
  //       // Update existing document
  //       const existingData = docSnapshot.data();
  //       const updatedData = {};

  //       Object.keys(storeData).forEach(key => {
  //         // Only update if the new value is provided

  //         console.log("storeData[key]",storeData[key])
  //         if (storeData[key] !== undefined && storeData[key] !== null && storeData[key] !== '') {
  //           updatedData[key] = storeData[key];
  //         } else {
  //           updatedData[key] = existingData[key];
  //         }
  //       });

  //       alert('Updating document');
  //       await storeFrontDocRef.update(updatedData);
  //       console.log('Document successfully updated!');
  //     } else {
  //       // Set new document
  //       await storeFrontDocRef.set(storeData);
  //       console.log('Document successfully written!');
  //     }

  //     this.selectBrand(this.selectedBrand);
  //     alert('Data Saved.');
  //     this.flipFlag = false;

  //   } catch (error) {
  //     console.error('Error processing document: ', error);
  //   }

  //   this.LeftColumnType = '';
  //   this.LeftGarmentID = '';
  //   this.LeftDocumentID = '';
  //   this.LeftAvatarID = '';
  //   this.LeftHeadGearID = '';
  //   this.LeftUpperwearID = '';
  //   this.LeftBottomwearID = '';
  //   this.LeftFootwearID = '';
  //   this.RightColumnType = '';
  //   this.RightGarmentID = '';
  //   this.RightDocumentID = '';
  //   this.RightAvatarID = '';
  //   this.RightHeadGearID = '';
  //   this.RightUpperwearID = '';
  //   this.RightBottomwearID = '';
  //   this.RightFootwearID = '';
  //   this.storeFrontData = {}
  // }


  async addInStoreFront() {
    try {
      // Fetch brand details
      let brandObject = await this.getBrandDetails(this.selectedBrandID);
      this.BrandId = brandObject.id;
      this.BrandGender = brandObject.Gender;

      // Determine column type based on selected side
      if (this.selectColunmSide === 'Left') {
        this.LeftColumnType = this.selectStoreColomnType;
        this.RightGarmentID = '';
        this.RightDocumentID = '';
        this.RightAvatarID = '';
        this.RightHeadGearID = '';
        this.RightUpperwearID = '';
        this.RightBottomwearID = '';
        this.RightFootwearID = '';
      } else if (this.selectColunmSide === 'Right') {
        this.RightColumnType = this.selectStoreColomnType;
        this.LeftGarmentID = '';
        this.LeftDocumentID = '';
        this.LeftAvatarID = '';
        this.LeftHeadGearID = '';
        this.LeftUpperwearID = '';
        this.LeftBottomwearID = '';
        this.LeftFootwearID = '';
      } else {
        this.LeftColumnType = this.selectStoreColomnType; // Default case if no side is selected
        this.RightGarmentID = '';
        this.RightDocumentID = '';
        this.RightAvatarID = '';
        this.RightHeadGearID = '';
        this.RightUpperwearID = '';
        this.RightBottomwearID = '';
        this.RightFootwearID = '';
      }

      // Determine brand mode based on tab
      const brandMode = this.tab === 'mens-wear' ? 'M' :
        this.tab === 'womens-wear' ? 'W' :
          this.tab === 'story' ? 'S' : '';

      // Define storeData object
      const storeData = {
        BrandId: this.BrandId,
        BrandMode: brandMode,
        WindowType: this.selectStoreFrontWindowType,
        WindowStyle: this.selectStoreFrontWindowStyle,
        WindowTexture: this.selectStoreFrontWindowTexture,
        OuterTexture: this.selectStoreFrontOuterTexture,
        BrandColor:this.selectStoreFromBrandColor,
        StoreFrontType: this.selectStoreFrontType,
        LeftColumnType: this.LeftColumnType,
        LeftGarmentID: this.LeftGarmentID,
        LeftDocumentID: this.LeftDocumentID,
        LeftAvatarID: this.LeftAvatarID,
        LeftFootwearID: this.LeftFootwearID,
        LeftBottomwearID: this.LeftBottomwearID,
        LeftHeadGearID: this.LeftHeadGearID,
        LeftUpperwearID: this.LeftUpperwearID,
        RightColumnType: this.RightColumnType,
        RightGarmentID: this.RightGarmentID,
        RightDocumentID: this.RightDocumentID,
        RightAvatarID: this.RightAvatarID,
        RightFootwearID: this.RightFootwearID,
        RightBottomwearID: this.RightBottomwearID,
        RightHeadGearID: this.RightHeadGearID,
        RightUpperwearID: this.RightUpperwearID,
      };

      let storeFrontDocRef;

      if (this.storeFrontData && this.storeFrontData.id) {
        // Use the existing document ID if available
        storeFrontDocRef = this.firestore.collection('StoreFront').doc(this.storeFrontData.id);
      } else {
        // Create a new document and store its ID in this.storeFrontData
        storeFrontDocRef = this.firestore.collection('StoreFront').doc();
        this.storeFrontData = { id: storeFrontDocRef.id };  // Save the new ID to avoid creating a new document again
      }

      const docSnapshot = await storeFrontDocRef.get().toPromise();

      if (docSnapshot.exists) {
        // Update existing document
        const existingData = docSnapshot.data();
        const updatedData = {};

        Object.keys(storeData).forEach(key => {
          // Only update if the new value is provided
          if (storeData[key] !== undefined && storeData[key] !== null && storeData[key] !== '') {
            updatedData[key] = storeData[key];
          } else {
            updatedData[key] = existingData[key];
          }
        });

        alert('Updating document');
        await storeFrontDocRef.update(updatedData);
        console.log('Document successfully updated!');
      } else {
        // Set new document
        await storeFrontDocRef.set(storeData);
        console.log('Document successfully written!');
      }

      this.selectBrand(this.selectedBrand);
      alert('Data Saved.');
      this.flipFlag = false;

    } catch (error) {
      console.error('Error processing document: ', error);
    }

    // Clear the fields after saving

    this.avatarGarmentId = {}


  }


  getGarmrntDetails(id) {
    let cloth = this.garmentsCollection.find((cloth) => cloth.id == id)
    return cloth
  }

  async filterAvatar($event) {
    this.selectedAvatar = ''
    this.selectedCloth = ''
    this.selectedClothType = ''
    let gender = this.selectedGender == 'F' ? 'FEMALE' : 'MALE';
    let brand = this.assignBrandList.find((brand) => brand.id == this.selectedBrand.id)
    let brandId = brand.BrandId
    this.selectedBrandID = brandId

    this.selectedGender = $event.target.value;
    this.avatarList = []

    let avatarList = this.userBrandAvatarList.filter((avatar) => avatar.BrandId == this.selectedBrandID && avatar.Gender == gender)
    await Promise.all(avatarList.map(async (avatar) => {
      avatar.AvatarName = await this.getAvatartDetails(avatar.CreatedBy, avatar.AvatarId);
    }));


    this.avatarList = avatarList

    console.log("this.selectedBrandID", this.selectedBrandID)
    console.log("this.avatarList", this.avatarList)

  }

  selectAvatar($event) {
    this.selectedAvatar = $event.target.value;

  }

  async getAvatartDetails(userId, avatarId) {
    try {
      const documents = await this.commonService.getUserAvatarDetails(userId, avatarId);
      if (documents?.length > 0) {
        const firstDocument = documents[0];
        return firstDocument.AvatarName || avatarId;
      } else {
        return avatarId;
      }
    } catch (error) {
      console.error('Error in component:', error);
      throw error; // You can choose to re-throw the error or handle it here
    }
  }


  addAvatarInStoreFront = async () => {
    if (!this.validateInputs()) {
      console.error('Validation failed. Cannot add to Store Front.');
      return;
    }



    const storeData = {
      BrandId: this.selectedBrandID,
      FileType: this.selectedFrontStoreType,
      GarmentType: this.selectedClothType,
      Gender: this.selectedGender,
      Link: this.selectedCloth,
      ColomunType: this.selectStoreColomnType,
      StoreFrontType: this.selectStoreFrontType,
      AvatarId: this.selectedAvatar
    };

    try {
      // Get a reference to the collection
      const storeFrontRef = this.firestore.collection('StoreFront').ref;

      // Check for existing document with same BrandId, Gender, and GarmentType
      const querySnapshot = await storeFrontRef
        .where('BrandId', '==', this.selectedBrandID)
        .where('Gender', '==', this.selectedGender)
        .where('GarmentType', '==', this.selectedClothType)
        .where('ColomunType', '==', this.selectStoreColomnType)
        .get();

      if (!querySnapshot.empty) {
        alert('An entry with the same Brand ID, Gender, and Garment Type already exists.');
        return;
      }

      // If no duplicate found, add the new entry
      await this.firestore.collection('StoreFront').add(storeData);
      console.log('Document successfully written!');
    } catch (error) {
      console.error('Error writing document: ', error);
    }
  }


  add3DObjectInStoreFront() {
    if (!this.validateInputs()) {
      console.error('Validation failed. Cannot add to Store Front.');
      return;
    }
    console.log("selectedBrand", this.selectedBrand);
    console.log("clothtype", this.selectedClothType);
    console.log("selectedGender", this.selectedGender);
    console.log("cloth", this.selectedCloth);

    const storeData = {
      BrandId: this.selectedBrandID,
      FileType: this.selectedFrontStoreType,
      GarmentType: this.selectedClothType,
      Gender: this.selectedGender,
      Link: this.selectedCloth,
      ColomunType: this.selectStoreColomnType,
      StoreFrontType: this.selectStoreFrontType
    };

    this.firestore.collection('StoreFront').add(storeData).then(() => {
      console.log('Document successfully written!');
    }).catch((error) => {
      console.error('Error writing document: ', error);
    })
  }

  ngAfterViewInit() {
    this.setupInfiniteScroll();

  }

  private setupInfiniteScroll(): void {
    this.slider = this.brandStoriyScrollA.nativeElement;

    const moveElement = () => {
      // Use requestAnimationFrame for smoother visual updates
      requestAnimationFrame(() => {
        const firstChild = this.slider.firstElementChild as HTMLElement;
        const lastChild = this.slider.lastElementChild as HTMLElement;
        // Calculate the buffer based on the child width
        const childWidth = firstChild.offsetWidth;
        const buffer = childWidth * 2; // Adjust the buffer size as needed
        if (this.slider.scrollLeft + buffer >= this.slider.scrollWidth - this.slider.offsetWidth) {
          // Move the first child to the end and adjust scrollLeft
          this.slider.appendChild(firstChild);
          this.slider.scrollLeft -= firstChild.offsetWidth; // Adjust instantly without animation
        } else if (this.slider.scrollLeft <= buffer) {
          // Move the last child to the beginning and adjust scrollLeft
          this.slider.insertBefore(lastChild, firstChild);
          this.slider.scrollLeft += lastChild.offsetWidth; // Adjust instantly without animation
        }
      });
    };

    // Throttle scroll events
    let isScrolling: any;
    this.scrollEventListener = () => {
      window.clearTimeout(isScrolling);
      isScrolling = setTimeout(moveElement, 0); // Increase the timeout to delay the element movement
    };

    // Add smooth scrolling CSS transition if not already present
    this.slider.style.transition = 'scroll-left 0.1s ease-out';
    this.slider.addEventListener('scroll', this.scrollEventListener);
  }

  ngOnDestroy() {
    // Clean up the scroll event listener to prevent memory leaks
    if (this.slider && this.scrollEventListener) {
      this.slider.removeEventListener('scroll', this.scrollEventListener);
    }
  }


  onScroll() {
    this['height'] = this.BrandStoriyScroll.nativeElement.scrollTop
  }

  toggleDropdown() {
    this.dropdownOpen = !this.dropdownOpen;
}


  setTab(tab, gender) {
    this.selectedcolor = ''
    this.functionFlag = 'hideBothAvatar'
    this.functionForStore = null;
    this.selectStoreColomnType = ''
    this.selectStoreFrontType = ''
    this.selectColunmSide = ''
    this.selectStoreFrontWindowType = ''
    this.selectStoreFrontWindowStyle = ''
    this.selectStoreFrontWindowTexture = ''
    this.selectStoreFrontOuterTexture = ''
    this.selectStoreFromBrandColor = ''
    this.windowStyleflage = false
    this.defaultMaleAvatars = [];
    this.defaultFemaleAvatars = [];
    this.defaultMaleAvatarsList = [];
    this.defaultFemaleAvatarsList = [];
    this.defaultSelectedAvatarMale = null
    this.defaultSelectedAvatarFeMale = null
    this.selectedGarmentCacheUrl = {}
    this.selectedGarmentCacheUrlRight = {}
    this.selectedGarmentCacheUrlLeft = {}
    this.cachepanel = false
    this.showTheBrandMode = true
    this.tab = tab
    this.sideCheck = ''
    this.setBrandColor()

    if (tab == 'story') {
      this.loadBrandPanelData(this.selectedBrandID)
    }
    if (tab == 'womens-wear' || tab == 'mens-wear') {
      this.packJsonObject = []
      // this.activeDownloads.clear();
      this.showDropDown = true
      this.getstoreData()
      this.flipClose()
    }
  }

  async loadBrandPanelData(brand) {
    this.userSnapShotList = null
    this.isloadingBrandPanel = true
    this.tab = 'story';
    let data = await this.brandPanelDataFunction(brand);
    this.userSnapShotList = data.object;
    this.isloadingBrandPanel = false
    // this.branUrl = data.brandLogoUrl
    console.log("data", data)
  }
  async brandPanelDataFunction(brandId) {
    let functionUrl = "https://us-central1-yolomoves-fb435.cloudfunctions.net/brandPanelDetailswithLogoUrl";
    return this._httpClient.post<any>(functionUrl, { brandId: brandId }).toPromise();
  }

  getSaveImage(id) {
    let imageUrl = this.userfileCollection.find((image) => image.id == id)
    return imageUrl
  }

  async showBrandPackDetails() {
    const newGender = this.tab == 'mens-wear' ? 'M' : this.tab == 'womens-wear' ? 'F' : "";
    this.zipList = {};
    this.selectedBrandId = this.selectedBrandID;
    this.showTheBrandMode = true
    this.selectedGender = newGender;
    this.errMessage = "";
    this.isloading = true;
    this.isLoadingStyle = true;
    this.showStoreFrontSaveImage = {}
    this.downloadPack0();
  }
  async downloadPack0() {
    console.log('pack0 loading intilze...')
    this.isloading = true;
    this.isLoadingStyle = true;
    this.testVar = 'Change';
    let brandId = this.selectedBrandId;
    let zipfilename = this.pack0;  // Assuming this is the zip file name
    let gender = this.selectedGender;
    let packlocation = "AvatarBlenderClothes%2F" + brandId + "_" + gender + zipfilename;
    let packlocationTemp = "AvatarBlenderClothes/" + brandId + "_" + gender + '/pack0';
    console.log("packlocation", packlocation)
    let brandEncodedurl = packlocation;
    this.callDownloadcheck = brandId + gender
    let uniqueKey = packlocation;
    this.callDownloadcache = this.callDownloadcheck
    if (!brandId || !gender || !zipfilename) {
      this.isloading = false;
      this.isLoadingStyle = false;
      this.downloadFlag = false;  // Reset the flag
      return;
    }
    let url = 'https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/' + brandEncodedurl + '?alt=media&token=46df48b9-1f7a-4b61-98c6-bff029734da4';
    this.packUrl = url
    try {
      // const res = await this._httpClient.get(url, { observe: 'response', responseType: 'blob' }).toPromise();
      // await this.processZipFile(res.body, this.processResponse.bind(this));
      let newPacketData = await this.downloadprocessPackZip(packlocationTemp)
      this.extractFileData(newPacketData)
    } catch (error) {
      this.handleErrors(error);
    } finally {
      this.isloading = false;
      this.isLoadingStyle = false;
      this.downloadFlag = false;  // Reset the flag after download is complete or fails
    }
    console.log('pack0 loaded........')
  }
  handleErrors(error) {
    console.log('error', error)
    this.packJsonObject = [];
    this.isloading = false;
    this.errMessage = "Pack0 is not found for this selection !!!"
    this.isLoading = false;
    this.isLoadingStyle = false;
  }
  async downloadprocessPackZip(url: string) {
    try {
      let functionUrl = "https://us-central1-yolomoves-fb435.cloudfunctions.net/downloadPack0";
      const response = await this._httpClient.get<any>(functionUrl, { params: { packlocation: url } }).toPromise();
      console.log('Zip response:', response);
      return response;
    } catch (error) {
      console.error('Error processing cloth zip:', error);
      throw error;
    }
  }
  async extractFileData(result) {
    const packJsonObject = result.packJsonObject;
    let data = result.zipList;
    this.newZipLists = data;

    for (const key in this.newZipLists) {
      if (this.newZipLists.hasOwnProperty(key)) {
        const base64String = this.newZipLists[key];
        const blobUrl = await this.base64ToBlobUrl(base64String);
        this.newZipLists[key] = blobUrl; // Assuming you sanitize in base64ToBlobUrl method
      }
    }
    this.zipList = this.newZipLists;
    console.log("Updated newZipLists:", this.zipList);

    // Refactor using loop for categories
    ['hFiles', 'uFiles', 'bFiles', 'fFiles'].forEach((category, index) => {
      this.extractFiles(packJsonObject, category, index);
    });
  }
  extractFiles(packJsonObject: any, category: string, index: number) {
    const filesArray: string[] = packJsonObject.brandSaves[category];
    const nonEmptyFilesArray: string[] = filesArray.filter((file: string) => file !== '');
    let tcount = packJsonObject.brandSaves['totalCount']
    let newPackJsonObject: any = {
      value: {
        files: nonEmptyFilesArray,
        count: tcount
      }
    };
    this.packJsonObject[index] = newPackJsonObject; // Assign to the appropriate index
    console.log(`Updated files for ${category}`, this.packJsonObject);
  }
  async base64ToBlobUrl(base64String: string): Promise<string> {
    return new Promise((resolve, reject) => {
      const byteCharacters = atob(base64String);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray]);
      const blobUrl = URL.createObjectURL(blob);
      resolve(blobUrl);
    });
  }
  async extractNextFileData(result) {
    const packJsonObject = result;
    let nextPackList = packJsonObject.imageUrls;
    let newListObject = {};

    for (const key in nextPackList) {
      if (nextPackList.hasOwnProperty(key)) {
        const base64String = nextPackList[key];
        const blobUrl = await this.base64ToBlobUrl(base64String);
        // Remove file extension from key
        const keyWithoutExtension = key.replace(/\.[^.]+$/, '');
        newListObject[keyWithoutExtension] = blobUrl; // Assuming you sanitize in base64ToBlobUrl method
      }
    }

  }

  scrollAndUnZipFileIntilize(containerType) {
    let garmentType;
    let typeNo;
    let scrollContainer: ElementRef;

    if (this.tab === 'womens-wear') {
      switch (containerType) {
        case 'headgear':
          scrollContainer = this.scrollContainerWHeadGear;
          garmentType = containerType;
          typeNo = 0;
          break;
        case 'upperwear':
          scrollContainer = this.scrollContainerWUpper;
          garmentType = containerType;
          typeNo = 1;
          break;
        case 'bottomwear':
          scrollContainer = this.scrollContainerWBottom;
          garmentType = containerType;
          typeNo = 2;
          break;
        case 'footwear':
          scrollContainer = this.scrollContainerWFootwear;
          garmentType = containerType;
          typeNo = 3;
          break;
      }
    } else {
      switch (containerType) {
        case 'headgear':
          scrollContainer = this.scrollContainerMHeadGear;
          garmentType = containerType;
          typeNo = 0;
          break;
        case 'upperwear':
          scrollContainer = this.scrollContainerMUpper;
          garmentType = containerType;
          typeNo = 1;
          break;
        case 'bottomwear':
          scrollContainer = this.scrollContainerMBottom;
          garmentType = containerType;
          typeNo = 2;
          break;
        case 'footwear':
          scrollContainer = this.scrollContainerMFootwear;
          garmentType = containerType;
          typeNo = 3;
          break;
      }
    }
    let count = this.packJsonObject[typeNo].value['count'];
    window.clearTimeout(0);
    if (count >= 25) {
      // Only start scrolling if the count is greater than or equal to 25
      setTimeout(() => {
        // this.scrollingContainer(scrollContainer, garmentType, typeNo, this.tab);
      }, 100);
    }
    setTimeout(() => {
      // this.setupInfiniteScroll(typeNo);
    }, 100);
  }

  switch3DImage() {
    this.swichImageFlage = !this.swichImageFlage
  }

  setSelectedGarmetCloth(event) {
    if (event == 'Cloth') {
      // this.setCloth()
    }
  }

  flipClose() {
    this.flipFlag = false
    this.garmentScrollFlag = false
    this.swichImageFlage = true
    this.userFileList = []
    this.userFiledetails3d = []
    this.userSnapShotListGarment = []
    this.garmentData = []
    this.clothDetails = {}
  }

  selectIcon(icon) {
    this.selectedIcon = ''
    this.selectedIcon = icon
    this.iconselctFlag = true
  }


  async loadGarmentData(garmentId) {
    if (this.selectColunmSide == 'Right') {
      this.RightGarmentID = garmentId
    } else if (this.selectColunmSide == 'Left') {
      this.LeftGarmentID = garmentId
    } else {
      this.LeftGarmentID = garmentId
    }
    this.isloadingPanel = true
    this.garmentData = []
    this.userFileList = []
    this.userFiledetails3d = []
    this.userSnapShotListGarment = []
    const startTime = performance.now();
    console.log(`Data fetching started at: ${startTime} ms`);
    this.garmentData = await this.garmentPanelDataFunction(garmentId);
    this.isloadingPanel = false
    this.clothDetails = this.garmentData.garment;
    const endTime = performance.now();
    console.log(`Data fetching ended at: ${endTime} ms`);
    const duration = endTime - startTime;
    console.log(`Data fetching duration MicroUrl: ${duration} ms`);
    let filePathNewTemp = this.garmentData.userFiles.find((file) => file.filename == this.clothDetails.id && file.fileType == 'Garment360Cache');
    this.source = filePathNewTemp ? filePathNewTemp.fileurl : null;
    this.brandObject = this.garmentData.brand;
    // this.branUrl = this.garmentData.brandLogoUrl;
    this.userSnapShotListGarment = this.garmentData.snapshots.object;

    let userFiles = this.garmentData.userFiles;
    if (this.selectStoreFrontType === '2DImage') {
      this.filteredData = userFiles.filter((file: any) => file['2DType'] !== 'Video');
    } else if (this.selectStoreFrontType === '2DVideo') {
      this.filteredData = userFiles.filter((file: any) => file['2DType'] === 'Video');
    }

    console.log('this.selectStoreFrontType', this.selectStoreFrontType);
    console.log('this.userSnapShotListGarment', this.userSnapShotListGarment);
    console.log('Filtered Data:', this.filteredData);


    const storageRef = this.storage.ref(filePathNewTemp.filepath);
    const url = await storageRef.getDownloadURL().pipe(finalize(() => { })).toPromise();
    console.log('Download URL:', url);
    this.source = url
    console.log("this.source", this.source);
    this.tab = this.clothDetails.Gender === 'M' ? 'mens-wear' : 'womens-wear';
    let filelist2d = this.filteredData.filter(
      (uploadlist) =>
        uploadlist.link == garmentId && uploadlist.fileType == 'GarmentUpload2D'
    );
    let filelist3d = this.garmentData.userFiles.filter(
      (uploadlist) =>
        uploadlist.link == garmentId && uploadlist.fileType == 'GarmentUpload3D'
    );

    this.userFileList = filelist2d;
    console.log('filelist2d', filelist2d)
    this.userFiledetails3d = filelist3d;
    console.log('filelist3d', filelist3d)
    setTimeout(() => {
      // this.selectGarmentImage(this.selectedImageIndex, this.StoryId);
    }, 500);

    // ource URL is null or undefined, update it
    // this.checkUrl(this.source)

  }

  async garmentPanelDataFunction(garmentId) {
    let functionUrl = "https://us-central1-yolomoves-fb435.cloudfunctions.net/getGarmentPanelData_v2";
    return this._httpClient.post<any>(functionUrl, { garmentId: garmentId, fileType: this.typeWear }).toPromise();
  }



  DeatailsPopup(garmetBinaryImg, garmentType: any, object, garmentTypeForPopup, brandflag) {
    if (this.selectStoreColomnType == 'Mannequin') {
      this.genders = this.tab === 'mens-wear' ? 'M' : 'F';
      let packlocation = "AvatarBlenderClothes%2F" + this.selectedBrandID + "_" + this.genders + "%2F" + garmentType + "%2F" + object;
      this.garmentLink = 'https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/' + packlocation + '?alt=media&token=2c1dc261-ac91-4c32-a5ec-ac5e06f0a9bc&_gl=1*goch74*_ga*MjExMjI9MTIyMi4xNjg3NTA3MDk1*_ga_CW55HF8NVT*MTY5NjkzMDY1Ny4xNTcuMS4xNjk2OTM2NzA4LjU5LjAuMA..';
      this.garmetBinaryImgDetails = garmetBinaryImg
      this.avatarGarmentId[garmentType] = object
      this.selectGarmentType = garmentType
      this.mannequinFlag = false
      this.cachepanel = true
      this.show360cacheFile(object)
      console.log("this.selectedGarmentCacheUrl", this.selectedGarmentCacheUrl)
      this.selectedGarmentCacheUrl[garmentType] = this.garmetCacheFile.fileurl
      console.log("this.selectedGarmentCacheUrl", this.selectedGarmentCacheUrl)
    }

    if (this.selectStoreColomnType != 'Avatar') {
      return
    }

    console.log("garmetBinaryImg", garmetBinaryImg)
    this.garmetBinaryImgDetails = garmetBinaryImg
    this.garmentTypeDetails = garmentType
    this.imageObject = object
    this.keyType = garmentTypeForPopup
    let typeWear = this.keyType
    let garmentId = this.imageObject
    let gBinaryImg = this.garmetBinaryImgDetails
    let gType = this.garmentTypeDetails
    // this.changeSelctedCloth.emit(object);
    this.genders = this.tab === 'mens-wear' ? 'M' : 'F';
    let packlocation = "AvatarBlenderClothes%2F" + this.selectedBrandID + "_" + this.genders + "%2F" + garmentType + "%2F" + object;
    this.garmentLink = 'https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/' + packlocation + '?alt=media&token=2c1dc261-ac91-4c32-a5ec-ac5e06f0a9bc&_gl=1*goch74*_ga*MjExMjI9MTIyMi4xNjg3NTA3MDk1*_ga_CW55HF8NVT*MTY5NjkzMDY1Ny4xNTcuMS4xNjk2OTM2NzA4LjU5LjAuMA..';

    if (garmentType == 'headgear') {
      this.headgear = this.garmentLink
      this.garmentH = garmentType

    } else if (garmentType == 'upperwear') {
      this.upperwear = this.garmentLink
      this.garmentU = garmentType

    } else if (garmentType == 'bottomwear') {
      this.bottomwear = this.garmentLink
      this.garmentB = garmentType

    } else if (garmentType == 'footwear') {
      this.footwear = this.garmentLink
      this.garmentF = garmentType
    }

    this.avatarGarmentId[garmentType] = garmentId
    console.log('this.avatarGarmentId poup', this.avatarGarmentId)
    this.getAvatarGaementId()
    this.functionFlag = null;
    if (this.genders == 'M') {
      setTimeout(() => {
        this.queryHeadgear = this.headgear;
        this.queryUpperwear = this.upperwear;
        this.queryBottomwear = this.bottomwear;
        this.queryFootwear = this.footwear;
        this.functionFlag = "LoadDefaultMaleAvatar";
      }, 100);
    }

    if (!this.allSelectedUrl.includes(this.garmentLink)) {
      this.open2DModelPopUp(gBinaryImg, gType, garmentId, typeWear, brandflag);
    } else {
    }
  }

  async open3Dflag(garmetBinaryImg, garmentType: any, garmentId, typeWear) {
    // await this.subscribeToUserFilesList()
    this.flipFlag = true
    this.garmentImgage = garmetBinaryImg
    this.garmentClothType = garmentType
    this.garmentClothId = garmentId
    this.typeWear = typeWear
    this.clothDetails = this.garmentsCollection.find((b: any) => b.id == garmentId);
    this.selectIcon('popup')
    this.loadGarmentData(garmentId)
    // let clothId = this.garmentClothId
    // let filelist2d = this.userUploadedFileList.filter((uploadlist) => uploadlist.link == clothId && uploadlist.fileType == 'GarmentUpload2D')
    // let filelist3d = this.userUploadedFileList.filter((uploadlist) => uploadlist.link == clothId && uploadlist.fileType == 'GarmentUpload3D')
    // this.userFileList = filelist2d
    // this.userFiledetails3d = filelist3d
    // console.log('this.userFileList', this.garmentClothType)
    // console.log('garmentImgage', this.garmentImgage)
    // console.log('userUploadedFileList', this.userUploadedFileList)
  }

  // handleupladimage(data) {
  //   this.LeftDocumentID = data
  //   console.log(this.LeftDocumentID)
  // }

  // handleSaveData(data) {
  //   this.LeftDocumentID = data.data
  //   this.addInStoreFront()
  // }

  // addI3dObject(list) {
  //   this.LeftDocumentID = list.docId
  //   this.addInStoreFront()
  // }

  // Function to handle image upload with column side consideration
  handleupladimage(data) {
    if (this.selectColunmSide === 'Right') {
      this.RightDocumentID = data;
    } else if (this.selectColunmSide === 'Left') {
      this.LeftDocumentID = data;
    } else {
      this.LeftDocumentID = data; // Default case
    }
    console.log(this.LeftDocumentID);
  }

  // Function to handle save data with column side consideration
  handleSaveData(data) {
    if (this.selectColunmSide === 'Right') {
      this.RightDocumentID = data.data;
    } else if (this.selectColunmSide === 'Left') {
      this.LeftDocumentID = data.data;
    } else {
      this.LeftDocumentID = data.data; // Default case
    }
    this.addInStoreFront();
  }

  // Function to add 3D object with column side consideration
  addI3dObject(list) {
    if (this.selectColunmSide === 'Right') {
      this.RightDocumentID = list.docId;
    } else if (this.selectColunmSide === 'Left') {
      this.LeftDocumentID = list.docId;
    } else {
      this.LeftDocumentID = list.docId; // Default case
    }
    this.addInStoreFront();
  }

  getstoreData() {
    this.cachepanel = false
    const brandMode = this.tab === 'mens-wear' ? 'M' : this.tab === 'womens-wear' ? 'W' : this.tab === 'story' ? 'S' : '';
    this.storeFrontData = {}
    this.getStoreDataFlage = false


    this.selectedGarmentCacheUrlLeft[this.selectGarmentType] = ''
    this.selectedGarmentCacheUrlRight[this.selectGarmentType] = ''



    let storeFrontData = this.storeFrontCollection.find((store) => store.BrandId == this.selectedBrandID && store.BrandMode == brandMode)

    if (storeFrontData) {
      let getUploadImage = this.userfileCollection.find((file) => file.id == storeFrontData.LeftDocumentID)
      let getUploadImageRight = this.userfileCollection.find((file) => file.id == storeFrontData.RightDocumentID)


      this.showStoreFrontSaveImage = getUploadImage
      this.showStoreFrontSaveImageRight = getUploadImageRight
      this.storeFrontData = storeFrontData
      this.getStoreDataFlage = true
      this.selectedFrontStoreType = this.storeFrontData.StoreFrontType; // Adjusted this to match the selectStoreFront function
      // this.selectStoreFront({ target: { value: this.selectedFrontStoreType } })
      this.selectStoreFrontWindowType = this.storeFrontData.WindowType
      this.selectStoreFrontWindowStyle = this.storeFrontData.WindowStyle

      this.selectStoreFrontWindowTexture = this.storeFrontData.WindowTexture
      this.selectStoreFrontOuterTexture = this.storeFrontData.OuterTexture
      this.selectStoreFromBrandColor = this.storeFrontData.BrandColor

      console.log("storeFrontData", this.storeFrontData.StoreFrontType)
      this.selectedcolor = this.filteredBrandcolor.find((color ) => color.id == this.selectStoreFromBrandColor)
      console.log("this.selectedcolor",this.selectedcolor)
      

      this.showTheBrandMode = false
      if (this.storeFrontData.StoreFrontType == '3DDouble') {
        this.sideCheck = this.checkSide(this.storeFrontData)
        this.showTheBrandMode = true
        this.isLoadingStyle = true
        this.selectStoreColomnType = this.storeFrontData.StoreFrontType
        // this.selectStoreFront({ target: { value: this.selectedFrontStoreType } })
        this.selectColunmSide = this.sideCheck
      }

      if (this.storeFrontData.LeftColumnType == 'Avatar') {
        setTimeout(() => {
          this.functionForStore = 'updateWindowSize';
          // alert(this.storeFrontData.LeftColumnType)
        }, 500); // Timeout set to 1000 milliseconds (1 second)
      }

      if (this.storeFrontData.RightColumnType == 'Avatar') {
        setTimeout(() => {
          this.functionForStore = 'updateWindowSize';
          // alert(this.storeFrontData.LeftColumnType)
        }, 500); // Timeout set to 1000 milliseconds (1 second)
      }

      if (this.storeFrontData.LeftColumnType == 'Mannequin') {
        const fields = [
          'LeftBottomwearID',
          'LeftFootwearID',
          'LeftHeadGearID',
          'LeftUpperwearID'
        ];

        this.mannequinFlag = true
        // Loop through each field and call show360cacheFile with the field value
        fields.forEach(async field => {
          if (field == 'LeftHeadGearID') { this.selectGarmentType = 'headgear' }
          if (field == 'LeftUpperwearID') { this.selectGarmentType = 'upperwear' }
          if (field == 'LeftBottomwearID') { this.selectGarmentType = 'bottomwear' }
          if (field == 'LeftFootwearID') { this.selectGarmentType = 'footwear' }
          if (storeFrontData[field]) {
            await this.show360cacheFileLeft(storeFrontData[field]);
          }
        });
      }

      if (this.storeFrontData.RightColumnType == 'Mannequin') {
        this.mannequinFlag = true
        const fields = [
          'RightBottomwearID',
          'RightFootwearID',
          'RightHeadGearID',
          'RightUpperwearID'
        ];
        // Loop through each field and call show360cacheFile with the field value
        fields.forEach(async field => {
          if (field == 'RightHeadGearID') { this.selectGarmentType = 'headgear' }
          if (field == 'RightUpperwearID') { this.selectGarmentType = 'upperwear' }
          if (field == 'RightBottomwearID') { this.selectGarmentType = 'bottomwear' }
          if (field == 'RightFootwearID') { this.selectGarmentType = 'footwear' }
          if (storeFrontData[field]) {
            await this.show360cacheFileRight(storeFrontData[field]);
          }
        });
      }


      if (this.storeFrontData.LeftColumnType == 'Avatar') {
        const fields = [
          'LeftBottomwearID',
          'LeftFootwearID',
          'LeftHeadGearID',
          'LeftUpperwearID'
        ];

        // Loop through each field and call getSelectedAvatarCloth with the field value
        fields.forEach(async field => {
          let type
          if (field == 'LeftHeadGearID') { type = 'headgear' }
          if (field == 'LeftUpperwearID') { type = 'upperwear' }
          if (field == 'LeftBottomwearID') { type = 'bottomwear' }
          if (field == 'LeftFootwearID') { type = 'footwear' }
          if (this.storeFrontData[field]) {
            this.garmentLinks_F[type] = await this.getSelectedAvatarCloth(this.storeFrontData[field]);
            console.log("this.garmentLinks_F", this.garmentLinks_F);
            this.garmentLinks_M[type] = await this.getSelectedAvatarCloth(this.storeFrontData[field]);
            console.log("this.garmentLinks_F", this.garmentLinks_M);
          }
        });

        if (this.storeFrontData.BrandMode == 'W') {
          if (this.storeFrontData.LeftAvatarID) {
            this.getAvatarData_F(this.storeFrontData.LeftAvatarID)
          }
        } if (this.storeFrontData.BrandMode == 'M') {
          if (this.storeFrontData.LeftAvatarID) {
            alert(this.storeFrontData.LeftAvatarID)
            this.getAvatarData_M(this.storeFrontData.LeftAvatarID)
          }
        }


        if (this.storeFrontData.BrandMode == 'W') {
          if (this.storeFrontData.RightAvatarID) {
            this.getAvatarData_F(this.storeFrontData.RightAvatarID)
          }

        } if (this.storeFrontData.BrandMode == 'M') {
          if (this.storeFrontData.RightAvatarID) {
            this.getAvatarData_M(this.storeFrontData.RightAvatarID)
          }
        }
      }
      if (this.storeFrontData.RightColumnType == 'Avatar') {
        const fields = [
          'RightBottomwearID',
          'RightFootwearID',
          'RightHeadGearID',
          'RightUpperwearID'
        ];

        // Loop through each field and call getSelectedAvatarCloth with the field value
        fields.forEach(async field => {
          let type
          if (field == 'RightHeadGearID') { type = 'headgear' }
          if (field == 'RightUpperwearID') { type = 'upperwear' }
          if (field == 'RightBottomwearID') { type = 'bottomwear' }
          if (field == 'RightFootwearID') { type = 'footwear' }
          if (this.storeFrontData[field]) {

            this.garmentLinks_F[type] = await this.getSelectedAvatarCloth(this.storeFrontData[field]);
            console.log("this.garmentLinks_F", this.garmentLinks_F);
            this.garmentLinks_M[type] = await this.getSelectedAvatarCloth(this.storeFrontData[field]);
            console.log("this.garmentLinks_F", this.garmentLinks_M);
          }
        });

        if (this.storeFrontData.BrandMode == 'W' && this.storeFrontData.LeftAvatarID != "") {
          this.getAvatarData_F(this.storeFrontData.LeftAvatarID)

        } if (this.storeFrontData.BrandMode == 'M' && this.storeFrontData.LeftAvatarID != "") {
          this.getAvatarData_M(this.storeFrontData.LeftAvatarID)
        }


        if (this.storeFrontData.BrandMode == 'W' && this.storeFrontData.RightAvatarID != "") {
          this.getAvatarData_F(this.storeFrontData.RightAvatarID)

        } if (this.storeFrontData.BrandMode == 'M' && this.storeFrontData.RightAvatarID != "") {
          this.getAvatarData_M(this.storeFrontData.RightAvatarID)
        }
      }
    }
    if (this.storeFrontData.StoreFrontType == '3DDouble' && (this.sideCheck == 'Right' || this.sideCheck == 'Left')) {
      this.selectAutoLoadThestoreFront()
    }
  }


  deleteStoreFront(data) {
    if (data) {
      var confirmation = confirm('Are you sure to delete this store front?');
      if (!confirmation) return;
      this.firestore.collection("StoreFront").doc(data.id).delete()
        .then(() => {
          console.log('Document successfully deleted!');
          this.storeFrontData = null;  // Ensure this is the correct way to update your state
          this.selectBrand(this.selectedBrand);


          this.showTheBrandMode = true
        })
        .catch((error) => {
          console.error('Error removing document: ', error);
        });
      this.cachepanel = false
      this.functionFlag = 'hideBothAvatar'
      this.getStoreDataFlage = false
      this.selectedGarmentCacheUrl = {}
    }

  }


  captureThumbnail(): void {
    const modelViewerElement = this.modelViewer?.nativeElement;
    const thumbnailImageElement = this.thumbnailImage?.nativeElement;

    if (modelViewerElement && thumbnailImageElement) {
      modelViewerElement.toBlob((blob: Blob) => {
        const url = URL.createObjectURL(blob);
        thumbnailImageElement.src = url;
        thumbnailImageElement.style.display = 'block';
      });
    } else {
      console.error('modelViewer or thumbnailImage is not initialized.');
    }
  }
  async downloadPosterToDataURL() {
    const modelViewer = document.getElementById("viewer") as ModelViewerElement;
    if (modelViewer) {
      const blob = await modelViewer.toBlob({ idealAspect: false });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "modelViewer_toBlob.png";
      a.click();
      URL.revokeObjectURL(url);
    } else {
      console.error('ModelViewer element not found');
    }
  }


  ///calling the Avatar Selecter
  currentBrandAvatar_Male() {
    this.getAvatrFlag = true
    let slectedBrandAvatarId_M = this.userBrandAvatarList.find(avatar => avatar.BrandId === this.selectedBrandID && avatar.Gender === 'MALE');
    if (slectedBrandAvatarId_M) {
      this.defaultMaleAvatars = this.userBrandAvatarList.filter(avatar => avatar.BrandId === this.selectedBrandID && avatar.Gender === 'MALE');
    }

    // else {
    //   this.defaultMaleAvatars = this.userBrandAvatarList.filter(avatar => avatar.BrandId === "Default" && avatar.Gender === 'MALE');
    // }

    this.defaultMaleAvatarsList = this.defaultMaleAvatars;
    const firstDefaultMaleAvatar = this.defaultMaleAvatars.length > 0 ? this.defaultMaleAvatars[0] : null;
    this.defaultSelectedAvatarMale = firstDefaultMaleAvatar;
    this.setBrandAvatarDetails_M = slectedBrandAvatarId_M;
    if (firstDefaultMaleAvatar) {
      this.getAvatrFlag = false
      this.selectMaleAvatar(firstDefaultMaleAvatar)
    }

  }

  currentBrandAvatar_FeMale() {
    this.getAvatrFlag = true

    let slectedBrandAvatarId_F = this.userBrandAvatarList.find(avatar => avatar.BrandId === this.selectedBrandID && avatar.Gender === 'FEMALE');
    if (slectedBrandAvatarId_F) {
      this.defaultFemaleAvatars = this.userBrandAvatarList.filter(avatar => avatar.BrandId === this.selectedBrandID && avatar.Gender === 'FEMALE');
    }
    // else {
    //   this.defaultFemaleAvatars = this.userBrandAvatarList.filter(avatar => avatar.BrandId === "Default" && avatar.Gender === 'FEMALE');
    // }
    const firstDefaultFemaleAvatar = this.defaultFemaleAvatars.length > 0 ? this.defaultFemaleAvatars[0] : null;
    this.defaultSelectedAvatarFeMale = firstDefaultFemaleAvatar;

    this.defaultFemaleAvatarsList = this.defaultFemaleAvatars;
    this.setBrandAvatarDetails_F = slectedBrandAvatarId_F;
    console.log('slectedBrandAvatarId_F', slectedBrandAvatarId_F)
    console.log('this.defaultFemaleAvatarsList', this.defaultFemaleAvatarsList)
    console.log('firstDefaultFemaleAvatar', firstDefaultFemaleAvatar)
    if (firstDefaultFemaleAvatar) {
      this.getAvatrFlag = false
      this.selectFemaleAvatar(firstDefaultFemaleAvatar)
    }
  }


  showAvatarSlider() {
    this.showSlider = !this.showSlider
  }

  async selectMaleAvatar(avatars) {
    // let avatarId
    // let selectedAvatrId
    // if(this.storeFrontData){
    //   avatarId = this.storeFrontData?.LeftAvatarID || this.storeFrontData?.RightAvatarID
    // }
    // selectedAvatrId  = avatarId ? avatarId : 
    await this.testFunctDefaultCloth()
    // await this.currentdefaultCloth_Male
    this.showSlider = false
    const selecteddefaultMaleAvatars = this.defaultMaleAvatarsList.find((avatar) => avatar.AvatarId === avatars.AvatarId);
    this.defaultSelectedAvatarMale = selecteddefaultMaleAvatars
    console.log("selecteddefaultMaleAvatars", selecteddefaultMaleAvatars);

    if (selecteddefaultMaleAvatars) {
      this.defaultAvatar_M = {
        savedHairTex: selecteddefaultMaleAvatars.SavedHairTexUrl,
        savedMetalRoughTex: selecteddefaultMaleAvatars.SavedMetalRoughTexUrl,
        savedNormalTex: selecteddefaultMaleAvatars.SavedNormalTexUrl,
        savedTex: selecteddefaultMaleAvatars.SavedTexUrl,
        savefile: selecteddefaultMaleAvatars.SavefileUrl
      }

      console.log("this.garmentLinks_M:", this.garmentLinks_M);
      this.queryHeadgear = null
      this.queryUpperwear = null
      this.queryBottomwear = null
      this.queryFootwear = null

      this.functionFlag = null;
      setTimeout(() => {
        this.queryHeadgear = this.garmentLinks_M['headgear'];
        this.queryUpperwear = this.garmentLinks_M['upperwear'];
        this.queryBottomwear = this.garmentLinks_M['bottomwear'];
        this.queryFootwear = this.garmentLinks_M['footwear'];
        this.functionFlag = "LoadDefaultMaleAvatar";
      }, 100);

    }
    else {
      console.log("Avatar not found:", avatars);
    }
  }

  async selectFemaleAvatar(avatars) {
    await this.currentdefaultCloth_FeMale()
    this.showSlider = false
    const selecteddefaultFemleAvatars = this.defaultFemaleAvatarsList.find((avatar) => avatar.AvatarId === avatars.AvatarId);
    this.defaultSelectedAvatar = selecteddefaultFemleAvatars
    this.defaultAvatar_F = {
      savedHairTex: selecteddefaultFemleAvatars?.SavedHairTexUrl,
      savedMetalRoughTex: selecteddefaultFemleAvatars?.SavedMetalRoughTexUrl,
      savedNormalTex: selecteddefaultFemleAvatars?.SavedNormalTexUrl,
      savedTex: selecteddefaultFemleAvatars?.SavedTexUrl,
      savefile: selecteddefaultFemleAvatars?.SavefileUrl
    };

    console.log("this.garmentLinks_F", this.garmentLinks_F);
    this.queryHeadgear = null
    this.queryUpperwear = null
    this.queryBottomwear = null
    this.queryFootwear = null




    this.functionFlag = null;
    setTimeout(() => {
      this.queryHeadgear = this.garmentLinks_F['headgear'];
      this.queryUpperwear = this.garmentLinks_F['upperwear'];
      this.queryBottomwear = this.garmentLinks_F['bottomwear'];
      this.queryFootwear = this.garmentLinks_F['footwear'];
      this.functionFlag = 'LoadDefaultFaleAvatar'
    }, 100);
  }

  testFunctDefaultCloth() {
    let selectedBrand_M = this.defaultGarment.find(dBrand => dBrand.BrandId === this.selectedBrandId && dBrand.Gender === 'M');
    let selectedBrand = selectedBrand_M && selectedBrand_M.BrandId !== undefined ? selectedBrand_M.BrandId : "Default";
    let defaultMaleCloth = this.defaultGarment.filter(dBrand => dBrand.BrandId === selectedBrand && dBrand.Gender === 'M');
    console.log("selectedBrand_M", selectedBrand_M)
    console.log("defaultMaleCloth", defaultMaleCloth)
    console.log("selectedBrand", selectedBrand)
    const garmentTypes = ['upperwear', 'footwear', 'bottomwear', 'headgear'];
    const garmentId = {}
    const garmentLinks_M = {}
    for (const garmentType of garmentTypes) {
      const garment = defaultMaleCloth.find(b => b.GarmentType === garmentType);
      if (garment) {
        const selectedDefaultCloth = this.garmentsCollection.find(cloth => cloth.id === garment.GarmentId);
        if (selectedDefaultCloth) {
          const packLocation = `AvatarBlenderClothes%2F${selectedDefaultCloth.BrandID}_${selectedDefaultCloth.Gender}%2F${selectedDefaultCloth.GarmentType}%2F${selectedDefaultCloth.id}`;
          const garmentLink = `https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/${packLocation}?alt=media&token=2c1dc261-ac91-4c32-a5ec-ac5e06f0a9bc&_gl=1*goch74*_ga*MjExMjI9MTIyMi4xNjg3NTA3MDk1*_ga_CW55HF8NVT*MTY5NjkzMDY1Ny4xNTcuMS4xNjk2OTM2NzA4LjU5LjAuMA..`;
          garmentLinks_M[garmentType] = garmentLink;
          console.log("this.garmentLink", garmentLink)
          garmentId[garmentType] = selectedDefaultCloth.id
          console.log("this.garmentLink", garmentId)
        } else {
        }
      }
    }
    this.garmentLinks_M = garmentLinks_M;
    this.avatarGarmentId = garmentId
    console.log("avatarGarmentId:", this.avatarGarmentId)
    this.getAvatarGaementId()
  }

  currentdefaultCloth_Male() {
    // Check if defaultGarment is defined

    let selectedBrand_M = this.defaultGarment.find(dBrand => dBrand.BrandId === this.selectedBrandId && dBrand.Gender === 'M');
    let selectedBrand = selectedBrand_M && selectedBrand_M.BrandId !== undefined ? selectedBrand_M.BrandId : "Default";
    let defaultMaleCloth = this.defaultGarment.filter(dBrand => dBrand.BrandId === selectedBrand && dBrand.Gender === 'M');
    console.log("selectedBrand_M", selectedBrand_M)
    console.log("defaultMaleCloth", defaultMaleCloth)
    console.log("selectedBrand", selectedBrand)
    const garmentTypes = ['upperwear', 'footwear', 'bottomwear', 'headgear'];
    const garmentId = {}
    const promises = garmentTypes.map(async garmentType => {
      const garment = defaultMaleCloth.find(b => b.GarmentType === garmentType);
      console.log("garment", garment)

      if (garment) {
        const selectedDefaultCloth = this.garmentsCollection.find(cloth => cloth.id === garment.GarmentId);
        if (selectedDefaultCloth) {
          const packLocation = `AvatarBlenderClothes%2F${selectedDefaultCloth.BrandID}_${selectedDefaultCloth.Gender}%2F${selectedDefaultCloth.GarmentType}%2F${selectedDefaultCloth.id}`;
          const garmentLink = `https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/${packLocation}?alt=media&token=2c1dc261-ac91-4c32-a5ec-ac5e06f0a9bc&_gl=1*goch74*_ga*MjExMjI9MTIyMi4xNjg3NTA3MDk1*_ga_CW55HF8NVT*MTY5NjkzMDY1Ny4xNTcuMS4xNjk2OTM2NzA4LjU5LjAuMA..`;
          garmentId[garmentType] = selectedDefaultCloth.id
          return { [garmentType]: garmentLink };
        }
      }
      return null;
    });

    Promise.all(promises).then(results => {
      const garmentLinks_M = {};
      results.forEach(result => {
        if (result) {
          Object.assign(garmentLinks_M, result);
        }
      });
      this.garmentLinks_M = garmentLinks_M;
      this.avatarGarmentId = garmentId
      this.getAvatarGaementId()
      console.log('garmentLinks_M:', this.garmentLinks_M);
    }).catch(error => {
      console.error('Error fetching garment links:', error);
    });
  }

  currentdefaultCloth_FeMale() {
    // Check if defaultGarment is defined


    let selectedBrand_F = this.defaultGarment.find(dBrand => dBrand.BrandId === this.selectedBrandId && dBrand.Gender === 'F');

    let selectedBrand = selectedBrand_F && selectedBrand_F.BrandId !== undefined ? selectedBrand_F.BrandId : "Default";

    let defaultFemaleCloth = this.defaultGarment.filter(dBrand => dBrand.BrandId === selectedBrand && dBrand.Gender === 'F');

    const garmentLinks_F = {};
    const garmentTypes = ['upperwear', 'footwear', 'bottomwear', 'headgear'];
    const garmentId = {}
    for (const garmentType of garmentTypes) {

      const garment = defaultFemaleCloth.find(b => b.GarmentType === garmentType);

      if (garment) {
        const selectedDefaultCloth = this.garmentsCollection.find(cloth => cloth.id === garment.GarmentId);
        if (selectedDefaultCloth) {
          const packLocation = `AvatarBlenderClothes%2F${selectedDefaultCloth.BrandID}_${selectedDefaultCloth.Gender}%2F${selectedDefaultCloth.GarmentType}%2F${selectedDefaultCloth.id}`;
          const garmentLink = `https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/${packLocation}?alt=media&token=2c1dc261-ac91-4c32-a5ec-ac5e06f0a9bc&_gl=1*goch74*_ga*MjExMjI9MTIyMi4xNjg3NTA3MDk1*_ga_CW55HF8NVT*MTY5NjkzMDY1Ny4xNTcuMS4xNjk2OTM2NzA4LjU5LjAuMA..`;
          garmentLinks_F[garmentType] = garmentLink;
          console.log("this.garmentLink", garmentLink)
          garmentId[garmentType] = selectedDefaultCloth.id
          console.log("this.garmentLink", garmentId)
        } else {
        }
      }
    }

    this.garmentLinks_F = garmentLinks_F;
    this.avatarGarmentId = garmentId
    console.log("avatarGarmentId:", this.avatarGarmentId)
    this.getAvatarGaementId()
    // console.log("this.garmentLinks_F",garmentLink)
  }

  open2DModelPopUp(garmetBinaryImg, garmentType: any, garmentId, typeWear, brandFlag) {
    this.isLoadedLoading = true
    let garmentImg = this.sanitizer.bypassSecurityTrustUrl(garmetBinaryImg)
    this.isLoading = false;
    this.dialogRef = this.dialog.open(PopupTwoDComponent, {
      panelClass: 'dailog-transparent-css-loading',
      disableClose: true // This will disable closing the dialog when clicking outside
    });
    this.dialogRef.componentInstance.garmentId = garmentId;
    this.dialogRef.componentInstance.typeWear = typeWear;
    this.dialogRef.componentInstance.garmentImg = garmentImg;
    this.dialogRef.componentInstance.garmentType = garmentType;
    // this.dialogRef.componentInstance.routLogInFlag = this.routLogInFlag;
    this.dialogRef.componentInstance.pageType = "style";
    this.dialogRef.componentInstance.brandFlag = brandFlag;
    this.dialogRef.afterClosed().subscribe(result => {
      this.isLoading = false;
    });
  }

  setPageloading(pageload) {
    this.loadingState = pageload
    if (!this.loadingState) {
      this.close2DModelPopUp()
    }
  }

  close2DModelPopUp() {
    if (this.dialogRef) {
      this.dialogRef.close();
      this.isLoadedLoading = false
    }
  }



  openStoreVideoLargView(data) {
    let storeFlag = true
    const modalRef = this.modal.open(CaraosalPopupComponent, {
      modalDialogClass: "model-garmentPopup"
    });
    modalRef.componentInstance.storeSelectedVideo = data;
    modalRef.componentInstance.storeSelectedFlag = storeFlag;

    modalRef.result.then((result) => {
    }).catch((error) => {
    });
  }

  openStoreGltfLargView(data) {
    let storeFlag = true
    const modalRef = this.modal.open(CaraosalPopupComponent, {
      modalDialogClass: "model-garmentPopup"
    });
    modalRef.componentInstance.storeSelectedGLTFFile = data;
    modalRef.componentInstance.storeSelectedFlag = storeFlag;
    modalRef.result.then((result) => {
    }).catch((error) => {
    });
  }

  openStoreImageLargView(data) {
    let storeFlag = true
    const modalRef = this.modal.open(CaraosalPopupComponent, {
      modalDialogClass: "model-garmentPopup"
    });
    modalRef.componentInstance.storeSelectedImage = data;
    modalRef.componentInstance.storeSelectedFlag = storeFlag;
    modalRef.result.then((result) => {
    }).catch((error) => {
    });
  }


  openStoreManquinLargView(data) {
    let storeFlag = true
    const modalRef = this.modal.open(CaraosalPopupComponent, {
      modalDialogClass: "model-garmentPopup"
    });
    modalRef.componentInstance.selectedGarmentCacheUrlLeft = this.selectedGarmentCacheUrlLeft;
    modalRef.componentInstance.selectedGarmentCacheUrlRight = this.selectedGarmentCacheUrlRight;
    modalRef.componentInstance.showStoreFrontSaveImageRight = this.showStoreFrontSaveImageRight;
    modalRef.componentInstance.showStoreFrontSaveImage = this.showStoreFrontSaveImage;
    modalRef.componentInstance.storeFrontData = this.storeFrontData;
    modalRef.componentInstance.storeManquin = storeFlag;
    modalRef.result.then((result) => {
    }).catch((error) => {
    });
  }


  async show360cacheFile(id) {
    // this.cachepanel = true
    this.garmetCacheFile = {}
    let selecteFile = this.userfileCollection.find((file) => file.link === id && file.fileType === 'Garment360Cache');
    this.garmetCacheFile = selecteFile;
    if (selecteFile === undefined) {
      const url = this.packUrl;
      const cloth = id;
      await this.commonService.downloadAndProcessPack(url, cloth).subscribe({
        next: async (response) => {
          console.log('Success:', response);
          if (response && response !== undefined) {
            // If response is successful and not undefined, call show360cacheFile
            await this.getGarmentDownloadedUrl(id);
            this.show360cacheFile(id)
          }
        },
        error: (error) => {
          console.error('Error:', error);
        }
      });

    }




    console.log('selecteFile', selecteFile);
    this.getGarmentDownloadedUrl(id);
    console.log(' this.avatarGarmentId', this.avatarGarmentId);
    // this.selectedGarmentCacheUrl[this.selectGarmentType] = this.garmetCacheFile.fileurl

    // this.selectedGarmentCacheUrlRight[this.selectGarmentType] = this.garmetCacheFile.fileurl

    // this.selectedGarmentCacheUrlLeft[this.selectGarmentType] = this.garmetCacheFile.fileurl

    console.log('this.selectedGarmentCacheUrl', this.selectedGarmentCacheUrl);




    // try {
    //   const storageRef = this.storage.ref(this.garmetCacheFile.filepath);
    //   const url = await storageRef.getDownloadURL().toPromise();
    //   console.log('url', url);
    //   // Reference to the document to be updated
    //   const docRef = this.firestore.collection('userfiles').doc(selecteFile.id);
    //   // Update the document with the new URL
    //   await docRef.update({ fileurl: url });
    //   let select = this.userfileCollection.find((file) => file.link === id && file.fileType === 'Garment360Cache');
    //   this.garmetCacheFile = select;
    //   console.log('Document successfully updated!');
    // } catch (error) {
    //   console.error('Error updating document: ', error);
    //   if (this.garmetBinaryImgDetails) {
    //     try {
    //       // Fetch the binary data from the link
    //       const response = await fetch(this.garmetBinaryImgDetails);
    //       const blob = await response.blob();

    //       // Define metadata with content type
    //       const metadata = {
    //         contentType: 'image/jpeg', // Set the content type to an appropriate image type
    //       };

    //       // Save the binary data to Firebase Storage with metadata
    //       const storageRef = this.storage.ref(this.garmetCacheFile.filepath);
    //       await storageRef.put(blob, metadata);
    //       console.log('Binary image successfully saved!');

    //       // Optionally, you might want to get the download URL again and update the document
    //       const url = await storageRef.getDownloadURL();
    //       const docRef = this.firestore.collection('userfiles').doc(selecteFile.id);
    //       await docRef.update({ fileurl: url });
    //       console.log('Document successfully updated with new URL!');
    //     } catch (saveError) {
    //       console.error('Error saving binary image: ', saveError);
    //     }
    //   }
    // }



  }


  async getGarmentDownloadedUrl(id) {
    let selecteFile = this.userfileCollection.find((file) => file.link === id && file.fileType === 'Garment360Cache');

    if (selecteFile && selecteFile.fileurl && selecteFile.fileurl.startsWith('https://firebasestorage.googleapis.com')) {
      // If fileurl already starts with the Firebase Storage URL, skip downloading the URL and updating
      console.log('URL already exists:', selecteFile.fileurl);
    } else {
      // Fetch the download URL if it doesn't exist or doesn't start with the Firebase Storage URL
      const storageRef = this.storage.ref(selecteFile.filepath);
      const url = await storageRef.getDownloadURL().toPromise();
      console.log('Fetched URL:', url);

      // Reference to the document to be updated
      const docRef = this.firestore.collection('userfiles').doc(selecteFile.id);

      // Update the document with the new URL
      await docRef.update({ fileurl: url });
      console.log('Document successfully updated with new URL!');

      // Update the userfileCollection with the new URL
      selecteFile.fileurl = url;
    }

    // Update garmetCacheFile with the selected file
    this.garmetCacheFile = selecteFile;
  }



  async show360cacheFileRight(id) {
    // this.cachepanel = true
    let selecteFile = this.userfileCollection.find((file) => file.link === id && file.fileType === 'Garment360Cache');
    this.garmetCacheFile = selecteFile;
    this.selectedGarmentCacheUrlRight[this.selectGarmentType] = this.garmetCacheFile.fileurl
    console.log('this.selectedGarmentCacheUrlRight', this.selectedGarmentCacheUrl);
  }

  async show360cacheFileLeft(id) {
    // this.cachepanel = true
    let selecteFile = this.userfileCollection.find((file) => file.link === id && file.fileType === 'Garment360Cache');
    this.garmetCacheFile = selecteFile;
    this.selectedGarmentCacheUrlLeft[this.selectGarmentType] = this.garmetCacheFile.fileurl
    console.log('  this.selectedGarmentCacheUrlLeft', this.selectedGarmentCacheUrlLeft);
  }

  saveMannquinInStoreFront() {
    if (this.selectStoreColomnType == 'Mannequin') {
      if (this.selectColunmSide == 'Left') {
        this.LeftColumnType = this.selectStoreColomnType
      } else if (this.selectColunmSide == 'Right') {
        this.RightColumnType = this.selectStoreColomnType
      } else {
        this.LeftColumnType = this.selectStoreColomnType
      }
      this.getManneqinGaementId()
      this.addInStoreFront()
      this.cachepanel = false
    }

  }

  saveAvatarInStoreFront() {


    let avatarId = this.defaultSelectedAvatar != undefined ? this.defaultSelectedAvatar?.AvatarId : (this.defaultSelectedAvatarFeMale?.AvatarId || this.defaultSelectedAvatarMale?.AvatarId)

    if (this.tab == "mens-wear") {
      avatarId = this.defaultSelectedAvatarMale.AvatarId
    }


    if (this.selectColunmSide == 'Left') {
      this.LeftAvatarID = avatarId
    }
    else if (this.selectColunmSide == 'Right') {
      this.RightAvatarID = avatarId
    } else {
      this.LeftAvatarID = avatarId
    }
    this.addInStoreFront()
  }

  getAvatarGaementId() {
    if (this.selectColunmSide == 'Left') {
      this.LeftHeadGearID = this.avatarGarmentId['headgear'] || ""
      this.LeftUpperwearID = this.avatarGarmentId['upperwear'] || ""
      this.LeftBottomwearID = this.avatarGarmentId['bottomwear'] || ""
      this.LeftFootwearID = this.avatarGarmentId['footwear'] || ""
    } else if (this.selectColunmSide == 'Right') {
      this.RightHeadGearID = this.avatarGarmentId['headgear'] || ""
      this.RightUpperwearID = this.avatarGarmentId['upperwear'] || ""
      this.RightBottomwearID = this.avatarGarmentId['bottomwear'] || ""
      this.RightFootwearID = this.avatarGarmentId['footwear'] || ""
    } else {
      this.LeftHeadGearID = this.avatarGarmentId['headgear'] || ""
      this.LeftUpperwearID = this.avatarGarmentId['upperwear'] || ""
      this.LeftBottomwearID = this.avatarGarmentId['bottomwear'] || ""
      this.LeftFootwearID = this.avatarGarmentId['footwear'] || ""
    }
  }

  getManneqinGaementId() {
    if (this.selectColunmSide === 'Left') {
      this.LeftHeadGearID = this.avatarGarmentId['headgear'] || "";
      this.LeftUpperwearID = this.avatarGarmentId['upperwear'] || "";
      this.LeftBottomwearID = this.avatarGarmentId['bottomwear'] || "";
      this.LeftFootwearID = this.avatarGarmentId['footwear'] || "";
    } else if (this.selectColunmSide === 'Right') {
      this.RightHeadGearID = this.avatarGarmentId['headgear'] || "";
      this.RightUpperwearID = this.avatarGarmentId['upperwear'] || "";
      this.RightBottomwearID = this.avatarGarmentId['bottomwear'] || "";
      this.RightFootwearID = this.avatarGarmentId['footwear'] || "";
    } else {
      // Default case when no specific side is selected
      this.LeftHeadGearID = this.avatarGarmentId['headgear'] || "";
      this.LeftUpperwearID = this.avatarGarmentId['upperwear'] || "";
      this.LeftBottomwearID = this.avatarGarmentId['bottomwear'] || "";
      this.LeftFootwearID = this.avatarGarmentId['footwear'] || "";
    }
  }



  getSelectedAvatarCloth(garment) {
    let garmentLinks_F = '';
    let garmentId = '';
    if (garment) {
      const selectedDefaultCloth = this.garmentsCollection.find(cloth => cloth.id === garment);
      if (selectedDefaultCloth) {
        const packLocation = `AvatarBlenderClothes%2F${selectedDefaultCloth.BrandID}_${selectedDefaultCloth.Gender}%2F${selectedDefaultCloth.GarmentType}%2F${selectedDefaultCloth.id}`;
        const garmentLink = `https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/${packLocation}?alt=media&token=2c1dc261-ac91-4c32-a5ec-ac5e06f0a9bc&_gl=1*goch74*_ga*MjExMjI9MTIyMi4xNjg3NTA3MDk1*_ga_CW55HF8NVT*MTY5NjkzMDY1Ny4xNTcuMS4xNjk2OTM2NzA4LjU5LjAuMA..`;
        garmentLinks_F = garmentLink;
        garmentId = selectedDefaultCloth.id;
      }
    }
    return garmentLinks_F
  }

  getAvatarData_F(AvatarId) {
    console.log(AvatarId)
    this.showSlider = false

    let slectedBrandAvatarId_F = this.userBrandAvatarList.filter(avatar => avatar.BrandId === this.selectedBrandID && avatar.Gender === 'FEMALE');

    console.log("slectedBrandAvatarId_F", slectedBrandAvatarId_F)
    const selecteddefaultFemleAvatars = slectedBrandAvatarId_F.find((avatar) => avatar.AvatarId === AvatarId);
    this.defaultSelectedAvatar = selecteddefaultFemleAvatars
    console.log('this.selecteddefaultFemleAvatars', selecteddefaultFemleAvatars)
    this.defaultAvatar_F = {
      savedHairTex: selecteddefaultFemleAvatars?.SavedHairTexUrl,
      savedMetalRoughTex: selecteddefaultFemleAvatars?.SavedMetalRoughTexUrl,
      savedNormalTex: selecteddefaultFemleAvatars?.SavedNormalTexUrl,
      savedTex: selecteddefaultFemleAvatars?.SavedTexUrl,
      savefile: selecteddefaultFemleAvatars?.SavefileUrl
    };
    console.log('this.defaultAvatar_F', this.defaultAvatar_F)
    console.log("this.garmentLinks_F", this.garmentLinks_F);
    this.queryHeadgear = null
    this.queryUpperwear = null
    this.queryBottomwear = null
    this.queryFootwear = null
    this.functionFlag = null;
    setTimeout(() => {
      this.queryHeadgear = this.garmentLinks_F['headgear'];
      this.queryUpperwear = this.garmentLinks_F['upperwear'];
      this.queryBottomwear = this.garmentLinks_F['bottomwear'];
      this.queryFootwear = this.garmentLinks_F['footwear'];
      this.functionFlag = 'LoadDefaultFaleAvatar'
    }, 100);

  }

  getAvatarData_M(AvatarId) {
    console.log(AvatarId)
    this.showSlider = false
    let slectedBrandAvatarId_M = this.userBrandAvatarList.filter(avatar => avatar.BrandId === this.selectedBrandID && avatar.Gender === 'MALE');
    const selecteddefaulMaleAvatars = slectedBrandAvatarId_M.find((avatar) => avatar.AvatarId === AvatarId);
    this.defaultSelectedAvatar = selecteddefaulMaleAvatars
    console.log('this.selecteddefaultFemleAvatars', selecteddefaulMaleAvatars)
    this.defaultAvatar_M = {
      savedHairTex: selecteddefaulMaleAvatars?.SavedHairTexUrl,
      savedMetalRoughTex: selecteddefaulMaleAvatars?.SavedMetalRoughTexUrl,
      savedNormalTex: selecteddefaulMaleAvatars?.SavedNormalTexUrl,
      savedTex: selecteddefaulMaleAvatars?.SavedTexUrl,
      savefile: selecteddefaulMaleAvatars?.SavefileUrl
    };
    console.log('this.defaultAvatar_F', this.defaultAvatar_F)
    console.log("this.garmentLinks_F", this.garmentLinks_F);
    this.queryHeadgear = null
    this.queryUpperwear = null
    this.queryBottomwear = null
    this.queryFootwear = null
    this.functionFlag = null;
    setTimeout(() => {
      this.queryHeadgear = this.garmentLinks_M['headgear'];
      this.queryUpperwear = this.garmentLinks_M['upperwear'];
      this.queryBottomwear = this.garmentLinks_M['bottomwear'];
      this.queryFootwear = this.garmentLinks_M['footwear'];
      this.functionFlag = 'LoadDefaultMaleAvatar'
    }, 100);
  }

  selectColunm($event) {
    this.selectColunmSide = $event.target.value;
  }


  checkSide(storeData) {
    // Define properties for left and right sides
    const leftSideProperties = [
      'LeftAvatarID',
      'LeftBottomwearID',
      'LeftColumnType',
      'LeftDocumentID',
      'LeftFootwearID',
      'LeftGarmentID',
      'LeftHeadGearID',
      'LeftUpperwearID'
    ];

    const rightSideProperties = [
      'RightAvatarID',
      'RightBottomwearID',
      'RightColumnType',
      'RightDocumentID',
      'RightFootwearID',
      'RightGarmentID',
      'RightHeadGearID',
      'RightUpperwearID'
    ];

    // Check if any left side property has a defined value
    const isLeftDefined = leftSideProperties.some(prop => storeData[prop] && storeData[prop] !== "");

    // Check if any right side property has a defined value
    const isRightDefined = rightSideProperties.some(prop => storeData[prop] && storeData[prop] !== "");

    if (isLeftDefined && isRightDefined) {
      return 'Both';
    } else if (isLeftDefined) {
      return 'Left';
    } else if (isRightDefined) {
      return 'Right';
    } else {
      // Handle cases where neither side is defined
      return 'None'; // Or any other default value or logic you want
    }
  }


  testResize() {
    // this.functionForStore == 'updateWindowSize'
    // alert(this.functionForStore)
  }

  onMouseDown(event: MouseEvent): void {
    this.isMouseDown = true;
    this.startX = event.pageX - this.brandStoriyScrollA.nativeElement.offsetLeft;
    this.scrollLeft = this.brandStoriyScrollA.nativeElement.scrollLeft;
  }

  onMouseLeave(): void {
    this.isMouseDown = false;
  }

  onMouseUp(): void {
    this.isMouseDown = false;
  }

  onMouseMove(event: MouseEvent): void {
    if (!this.isMouseDown) return;
    event.preventDefault();
    const x = event.pageX - this.brandStoriyScrollA.nativeElement.offsetLeft;
    const walk = (x - this.startX) * 1.5; // Scroll faster by multiplying by 2
    this.brandStoriyScrollA.nativeElement.scrollLeft = this.scrollLeft - walk;
  }

  scrollsLeft(): void {
    this.brandStoriyScrollA.nativeElement.scrollBy({
      left: -200, // Adjust the scroll distance as needed
      behavior: 'smooth'
    });
  }

  scrollRight(): void {
    this.brandStoriyScrollA.nativeElement.scrollBy({
      left: 200, // Adjust the scroll distance as needed
      behavior: 'smooth'
    });
  }

  selectStoreWindowType($event) {
    this.selectStoreFrontWindowType = $event.target.value
    this.windowStyleflage = true
  }

  selectStoreWindowStyle($event) {
    this.selectStoreFrontWindowStyle = $event.target.value
  }

  selectStoreWindowtexture($event) {
    this.selectStoreFrontWindowTexture = $event.target.value
  }

  selectStoreoutertexture($event) {
    this.selectStoreFrontOuterTexture = $event.target.value
  }

  selectBrandColor(color) {
    this.selectedcolor = color;
    this.dropdownOpen = false;
    this.selectStoreFromBrandColor = color.id
    console.log()
  }


  setBrandColor() {
   this.filteredBrandcolor = this.brandColor.filter((color) => color.BrandId ==  this.selectedBrandID)
   console.log(this.selectedBrandID )
   console.log( this.filteredBrandcolor )

  }


  



  async suggest($event) {
    this.scrollFlag = true;
    this.byScroll = true;
  
    console.log("byScroll 7", this.byScroll);
    let inputValue = $event.target.value.toLowerCase();
  
    // Extract brand IDs from assignBrandList for filtering
    const assignedBrandIds = new Set(this.assignBrandList.map(item => item.BrandId));
  
    // Filter brandList to include only brands present in assignBrandList
    const filteredBrandList = this.brandList.filter(brand => assignedBrandIds.has(brand.id));
  
    // Apply the startsWith filter
    const startsWithMatches = filteredBrandList.filter(brand =>
      brand.BrandName && brand.BrandName.toLowerCase().startsWith(inputValue)
    );
  
    // Apply the includes filter, excluding those already in startsWithMatches
    const includesMatches = filteredBrandList.filter(brand =>
      brand.BrandName && brand.BrandName.toLowerCase().includes(inputValue)
    ).filter(brand => !startsWithMatches.includes(brand));
  
    // Combine the results and limit the total to 10
    const filterBrandListNew = startsWithMatches.slice(0, 10).concat(includesMatches.slice(0, 10));
    console.log(filterBrandListNew);
  
    // Extract IDs from filterBrandListNew
    const filteredBrandIds = new Set(filterBrandListNew.map(brand => brand.id));
  
    // Filter assignBrandList to include only objects with IDs in filteredBrandIds
    const filteredAssignBrandList = this.assignBrandList.filter(item => filteredBrandIds.has(item.BrandId));
  
    // Fetch the brand image URLs asynchronously and update the filteredAssignBrandList
    this.filterBrandListNew = await Promise.all(
      filteredAssignBrandList.map(async (brand) => {
        const logoUrl = await this.getBrandImage(brand);
        return {
          ...brand,
          logoUrl // Add the logo URL to the brand object
        };
      })
    );
  
    console.log(this.filterBrandListNew);
  }
  

  
  

blankplaceHolder() {
  this.brandNameSelected = ''
}
}


