import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GarmentPopupComponent } from '../garment-popup/garment-popup.component';
@Component({
  selector: 'app-twod-image',
  templateUrl: './twoDpopup.html',
  styleUrls: ['./twoDpopup.css']

 
})
export class TwoDpopupComponent implements OnInit {
    boxImage: string | undefined;
    @Input() garmentType: string | undefined;
    @Input() garmentImg: string | undefined;
    @Input() pageType: string | undefined;
    @Output() triggedCloth = new EventEmitter();

    

    @ViewChild('carousel') carousel: any;

    
    constructor( private modal: NgbModal,){
    }


    ngOnChanges(changes: SimpleChanges) {
      if (changes['garmentImg']) {
        console.log('garmentImg',this.garmentImg,)

        
      }
    }
    ngOnInit(): void {
      console.log('Type',this.garmentType,
        this.garmentImg)

    }
    closePopUp() {
      this.modal.dismissAll(GarmentPopupComponent)
  }
  pauseCarousel() {
    this.carousel.pause();
  }



  showAlert(): void {
    this.triggedCloth.emit('Cloth');
  }
}
