import { HttpClient } from '@angular/common/http';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import * as JSZip from 'jszip/dist/jszip';
import { Observable } from 'rxjs';
import { Brand } from 'src/app/models/brand';
import { SocialMediaShareDialog } from 'src/app/public/news/news.component';
import { CommonService } from 'src/app/services/common.service';
import { DownloadPackZipService } from 'src/app/services/download-pack-zip.service';

@Component({
  selector: 'app-garment-new-panel',
  templateUrl: './garment-new-panel.component.html',
  styleUrls: ['./garment-new-panel.component.css']
})
export class GarmentNewPanelComponent implements OnChanges {
  brands = this.firestore.collection('brands').valueChanges({ idField: 'id' }) as Observable<Brand[]>;
  garmentsList = this.firestore.collection('garments').valueChanges({ idField: 'id' }) as Observable<any[]>;
  defaultBrandCloth = this.firestore.collection('DefaultBrandGarments').valueChanges({ idField: 'id' }) as Observable<any[]>;
  userFilesList = this.firestore.collection('userfiles').valueChanges({ idField: 'id' }) as Observable<any[]>;
  userGarmentCollectionItems = this.firestore.collection('garment-collection-items').valueChanges({ idField: 'id' }) as Observable<any[]>;
  userGarmentCollection = this.firestore.collection('garment-collection').valueChanges({ idField: 'id' }) as Observable<any[]>;
  @Input() ClothId: string | undefined;
  @Input() BrandId: string | undefined;
  @Input() ViewFlage: boolean | undefined;
  @Input() loading: boolean | undefined;


  images = [
    { image: './assets/garment-upload-dummy/img-1.jpg' },
    { image: './assets/garment-upload-dummy/img-2.jpg' },
    { image: './assets/garment-upload-dummy/img-3.jpg' },
    { image: './assets/garment-upload-dummy/img-4.jpg' },
    { image: './assets/garment-upload-dummy/img-5.jpg' },
    { image: './assets/garment-upload-dummy/img-6.jpg' },
    { image: './assets/garment-upload-dummy/img-7.jpg' },
    { image: './assets/garment-upload-dummy/img-8.jpg' },
    { image: './assets/garment-upload-dummy/img-9.jpg' },
  ];
  garmentScrollFlag = false;
  brandheartFlag: boolean = false;
  style: string = 'treads';
  showMore: boolean = false;
  garmentImgage: any;
  garmentsCollection: any = []
  brandList: any = []
  packJsonObject: any = []
  userUploadedFileList: any = []
  garmentObject: any = {};
  brandObject: any = {};
  tab: any;
  selectedIcon: any;
  iconselctFlag: boolean;
  userFileList: any = [];
  allUserDetails: any = []
  userFiledetails3d: any = [];
  collectionDetails: any = [];
  collectionData: any = [];
  dummyText: string;
  userFiledetails: any = [];
  defultBrandCollection: any = [];
  userSnapShotListGarment: any = [];
  data: any[] = [];
  zipList = {}
  zipData: { zipList: any; packJsonObject: any; };
  flipFlag: boolean = true;
  defaultBrandArray: any = [];
  newZipList: { title: any; value: { files: any; filesIndex: any; name: any; count: any; }; }[];
  processedClothData: any = [];
  swichImageFlage: boolean = true;
  source: any;
  typeWear: any;
  //  loading:boolean = true;
  pageNumberGarment: number = 1;
  typeOfGarment: any;
  selectedImageIndex: number;
  testVar: string;
  newzipLoaded: any;
  selectedClothID: any
  constructor(public sanitizer: DomSanitizer, public dialog: MatDialog, private _httpClient: HttpClient,
    public commonService: CommonService, private firestore: AngularFirestore, public router: Router,
    private downloadService: DownloadPackZipService) {
    this.userFilesList.subscribe((userFile) => {
      this.userUploadedFileList = userFile;
    });
    this.brands.subscribe((brand) => {
      this.brandList = brand;
      console.log('brandList', this.brandList);
    });

    this.userGarmentCollectionItems.subscribe((collectiondata) => {
      this.collectionDetails = collectiondata;
    });

    this.userGarmentCollection.subscribe((collectiondata) => {
      this.collectionData = collectiondata;
    });

    this.defaultBrandCloth.subscribe((collectiondata) => {
      this.defultBrandCollection = collectiondata;
    });
    this.commonService.getAllUser().subscribe(async (allUsers: any) => {
      this.allUserDetails = allUsers.users;
    });
    const text =
      "Your brand's apparel is digitised in 3D.\n\nOn a gamified experience, consumers style avatars & share\n\nanimated avatar stories (linked to ecommerce)\n\nDefining a new paradigm in immersive storytelling for social media & the metaverse";
    const lines = text.split('\n\n');
    this.dummyText =
      lines[0] + '\n' + lines[1] + '\n' + lines[2] + '\n' + lines;

    this.garmentsList.subscribe((garments) => {
      this.garmentsCollection = garments;
      console.log('garmentsCollection', this.garmentsCollection);
      // If ClothId is available, call findGarmentCloth
      // if (this.ClothId) {
      //   this.findGarmentCloth();
      // }
    });


  }
  ngOnInit(): void {

  }

  ngOnChanges(changes: SimpleChanges) {
    if ('ClothId' in changes) {
      this.findGarmentCloth();
    }

  }

  selectionBygender() {
    console.log("selectionBygender", this.garmentObject)
    if (this.garmentObject.Gender == "F") {
      this.tab = 'womens-wear'
    }
    else {
      this.tab = 'mens-wear'
    }
  }
  switch3DImage(event: any) {
    event.stopPropagation();
    this.swichImageFlage = !this.swichImageFlage;
  }
  clickOnHeart() {
    this.brandheartFlag = !this.brandheartFlag;
  }
  // findGarmentCloth() {
  //   console.log('this.ClothId', this.ClothId)
  //   console.log('this.garmentsCollection',this.garmentsCollection)
  //   this.garmentObject = this.garmentsCollection.find((garment) => garment.id === this.ClothId);
  //   this.selectionBygender()
  //   console.log(" this.garmentObject ", this.garmentObject);
  //   this.brandObject = this.brandList.find((brand) => brand.id === this.garmentObject.BrandID);
  //   let filelist2d = this.userUploadedFileList.filter(
  //     (uploadlist) =>
  //       uploadlist.link == this.ClothId && uploadlist.fileType == 'GarmentUpload2D'
  //   );
  //   let filelist3d = this.userUploadedFileList.filter(
  //     (uploadlist) =>
  //       uploadlist.link == this.ClothId && uploadlist.fileType == 'GarmentUpload3D'
  //   );
  //   this.userFileList = filelist2d;
  //   this.userFiledetails3d = filelist3d;
  //   this.getGarmentStoryImage(this.ClothId)
  //   this.openClothSlider()
  //   this.callzipDownload(this.ClothId)
  // }
  async findGarmentCloth() {
    console.log('this.ClothId', this.ClothId);
    console.log('this.garmentsCollection', this.garmentsCollection);

    // Check if garmentsCollection is empty
    if (!this.garmentsCollection || this.garmentsCollection.length === 0) {
      // Re-subscribe to garmentsList
      this.garmentsList.subscribe((garments) => {
        this.garmentsCollection = garments;
        this.findGarmentCloth();
      });

      return; // Exit the function early
    }

    // Now proceed with the rest of the logic
    this.garmentObject = this.garmentsCollection.find((garment) => garment.id === this.ClothId);
    this.brandObject = this.brandList.find((brand) => brand.id === this.garmentObject.BrandID);
    let filelist2d = this.userUploadedFileList.filter(
      (uploadlist) =>
        uploadlist.link == this.garmentObject.id && uploadlist.fileType == 'GarmentUpload2D'
    );
    let filelist3d = this.userUploadedFileList.filter(
      (uploadlist) =>
        uploadlist.link == this.garmentObject.id && uploadlist.fileType == 'GarmentUpload3D'
    );
    this.userFileList = filelist2d;
    this.userFiledetails3d = filelist3d;
    this.selectionBygender();
    this.openClothSlider();
    this.callzipDownload(this.ClothId);

    // this.getGarmentStoryImage(this.ClothId);

  }

  flipClose() {
    this.flipFlag = true
  }
  getBrandImage(imgURL: string) {
    let brandEncodedurl = encodeURI(imgURL).replace('/', '%2F');
    const newPath = brandEncodedurl.replace('Brands%2F', 'Brands%2Fcompressed%2F');
    let url ='https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/' + newPath + '?alt=media&token=550e7e88-bed3-4668-84ff-ad491c19e06c';
    return url;
  }

  setTab(selectedtab: any, gender) {
    this.tab = selectedtab;
    this.router.navigate([
      '/threads',
      { brandId: this.garmentObject.BrandID, M: gender, Ex: 'N' },
    ]);
  }

  selectIcon(icon) {
    this.selectedIcon = '';
    this.selectedIcon = icon;
    this.iconselctFlag = true;
  }


  filterCollectionName(id) {
    let garmentCollectionId = (
      this.collectionDetails != undefined && this.collectionDetails
    ).find((colection) => colection.id === id);
    let getCollection = this.collectionData.find(
      (colection) =>
        colection.id === garmentCollectionId.GarmentCollectionID &&
        colection.status == 'A'
    );
    let name =
      getCollection != undefined && getCollection.CollectionName != undefined
        ? getCollection.CollectionName
        : '';
    return name;
  }



  getGarmentStoryImage(garmentId: any) {
    this.userSnapShotListGarment = [];
    let fileType = ''
    fileType = this.getSelectionType(this.garmentObject.GarmentType)
    let reqInputObject = { fileType: fileType, id: garmentId, pageNumber: 1 };
    this.commonService
      .yologramSnapshotDetailsByType(reqInputObject)
      .subscribe(async (response: any) => {
        this.userSnapShotListGarment.push(...response.object);
      });
  }

  getSelectionRealType(type: string): string {
    let newType;
    switch (type) {
      case 'headgear':
        newType = 0;
        break;
      case 'upperwear':
        newType = 1;
        break;
      case 'bottomwear':
        newType = 2;
        break;
      case 'footwear':
        newType = 3;
        break;
      default:
        newType = '';
    }
    return newType;
  }


  getSelectionType(type: string): string {
    let newType: string;
    switch (type) {
      case 'footwear':
        newType = 'FootwearID';
        break;
      case 'bottomwear':
        newType = 'BottomwearID';
        break;
      case 'upperwear':
        newType = 'UpperwearID';
        break;
      case 'headgear':
        newType = 'HeadwearID';
        break;
      default:
        newType = '';
    }
    return newType;
  }

  openClothPanel(garmetBinaryImg, clothId, typeWear) {
    this.typeWear = typeWear
    this.selectedClothID = clothId
    this.userFileList = []
    this.userFiledetails3d = []
    let viewZipList = this.zipData?.zipList?.[this.ClothId]
    this.flipFlag = false
    this.garmentImgage = garmetBinaryImg != undefined ? garmetBinaryImg : viewZipList
    this.garmentObject = this.garmentsCollection.find((garment) => garment.id === clothId);
    this.brandObject = this.brandList.find((brand) => brand.id === this.garmentObject.BrandID);
    let filelist2d = this.userUploadedFileList.filter(
      (uploadlist) =>
        uploadlist.link == clothId && uploadlist.fileType == 'GarmentUpload2D'
    );
    let filelist3d = this.userUploadedFileList.filter(
      (uploadlist) =>
        uploadlist.link == clothId && uploadlist.fileType == 'GarmentUpload3D'
    );
    this.userFileList = filelist2d;
    this.userFiledetails3d = filelist3d;
    this.selectionBygender();
    this.getGarmentStoryImage(clothId)
  }


  openClothSlider() {
    this.flipFlag = true
    let zipNmae = 'pack0';
    let packlocation =
      'AvatarBlenderClothes%2F' +
      this.garmentObject.BrandID +
      '_' +
      this.garmentObject.Gender +
      '%2F' +
      zipNmae;
    console.log("packlocation", packlocation)
    this.loadData(packlocation)
  }

  async loadData(packlocation) {
    let garmentLink =
      'https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/' +
      packlocation +
      '?alt=media&token=2c1dc261-ac91-4c32-a5ec-ac5e06f0a9bc&_gl=1*goch74*_ga*MjExMjI9MTIyMi4xNjg3NTA3MDk1*_ga_CW55HF8NVT*MTY5NjkzMDY1Ny4xNTcuMS4xNjk2OTM2NzA4LjU5LjAuMA..';
    if (!this.ViewFlage) {
      await this.downloadService.downloadAndProcessPack(garmentLink)
        .then(data => {
          this.zipData = data
          this.loading = false
          this.newMethod(data)
          let viewZipList = this.zipData?.zipList?.[this.ClothId]
          this.garmentImgage = viewZipList

          console.log('Data loaded:', this.zipData);
          console.log('Data zipData:', this.zipData.zipList);
        })
        .catch(error => {
          console.error('Failed to load data:', error);
        });
      // this.newMethod()
      await this.downloadPack0(garmentLink)
    }


    console.log("packetObject", this.packJsonObject)
    let filePath = this.userUploadedFileList.find((file) => file.filename == this.garmentObject.id && file.fileType == 'Garment360Cache');
    console.log('File path found:', filePath);
    if (filePath) {
      this.loading = false
      this.garmentImgage = filePath.fileurl;
      console.log('File path found:', this.garmentImgage);
      return;
    }
    const url = garmentLink;
    const cloth = this.ClothId;
    await this.commonService.downloadAndProcessPack(url, cloth).subscribe({
      next: (response) => console.log('Success:', response),
      error: (error) => console.error('Error:', error)
    });
    let filePathNew = this.userUploadedFileList.find((file) => file.filename == this.garmentObject.id && file.fileType == 'Garment360Cache');
    console.log('File path found:', filePath);
    if (filePathNew) {
      this.loading = false
      this.garmentImgage = filePathNew.fileurl;
      console.log('File path found:', this.garmentImgage);
      return;
    }

  }




  newMethod(data) {
    let packJsonObject = data.packJsonObject; // Ensure this is populated correctly.
    console.log("packJsonObject", packJsonObject)
    if (packJsonObject) {
      let jsonResponseData = [
        {
          title: 'headgear',
          value: {
            files: (packJsonObject['hFiles'] || []).filter(data => data !== ''),
            filesIndex: (packJsonObject['hFiles'] || []).filter(data => data === '' || data !== undefined),
            name: (packJsonObject['hName'] || []).filter(data => data.indexOf('deleted') < 0),
            count: packJsonObject['hCount'] || 0,
          },
        },
        {
          title: 'upperwear',
          value: {
            files: (packJsonObject['uFiles'] || []).filter(data => data !== ''),
            filesIndex: (packJsonObject['uFiles'] || []).filter(data => data === '' || data !== undefined),
            name: (packJsonObject['uName'] || []).filter(data => data.indexOf('deleted') < 0),
            count: packJsonObject['uCount'] || 0,
          },
        },
        {
          title: 'bottomwear',
          value: {
            files: (packJsonObject['bFiles'] || []).filter(data => data !== ''),
            filesIndex: (packJsonObject['bFiles'] || []).filter(data => data === '' || data !== undefined),
            name: (packJsonObject['bName'] || []).filter(data => data.indexOf('deleted') < 0),
            count: packJsonObject['bCount'] || 0,
          },
        },
        {
          title: 'footwear',
          value: {
            files: (packJsonObject['fFiles'] || []).filter(data => data !== ''),
            filesIndex: (packJsonObject['fFiles'] || []).filter(data => data === '' || data !== undefined),
            name: (packJsonObject['fName'] || []).filter(data => data.indexOf('deleted') < 0),
            count: packJsonObject['fCount'] || 0,
          },
        },
      ];
      this.newZipList = jsonResponseData
      console.log("jsonResponseData", jsonResponseData);
    }
    // Safely access each array and provide a default empty array if undefined.

  }

  closeBrandPanel() {
  }

  callzipDownload(ClothId) {
    if (this.ViewFlage) {
      this.openClothPanel(this.garmentImgage, this.ClothId, this.typeWear)
      let newTempDefaultGarment = {
        BrandId: this.garmentObject.BrandID,
        GarmentId: this.garmentObject.id,
        GarmentType: this.garmentObject.GarmentType,
        Gender: this.garmentObject.Gender,
      }
      // hdihk
      console.log(this.ClothId)
      this.defaultBrandArray.push(newTempDefaultGarment)
    } else if (this.BrandId == 'Default') {
      this.defaultBrandArray = this.defultBrandCollection.filter((brand) =>
        brand.BrandId == this.BrandId && brand.GarmentId == this.garmentObject.id
      );
    } else {
      this.defaultBrandArray = this.defultBrandCollection.filter((brand) =>
        brand.BrandId == this.brandObject.id && brand.Gender == this.garmentObject.Gender
      );
    }
    console.log("defaultdata", this.defaultBrandArray);
    const clothTypes = ['headgear', 'upperwear', 'bottomwear', 'footwear'];
    // Initialize a new array to store processed data
    this.processedClothData = clothTypes.map((clothType, index) => {
      // Filter data for the current type
      const clothData = this.defaultBrandArray.filter(item => item.GarmentType === clothType);
      console.log("processedClothData", this.processedClothData)
      console.log("Processed clothData Data:", clothData);
      // Return an object with the type and data, or null if no data
      return {
        index: index,      // Store the index
        type: clothType,   // Store the cloth type
        data: clothData.length > 0 ? clothData : null  // Store the data or null
      };
    });


  }

  setSelectedGarmetCloth(event) {
    if (event == 'Cloth') {
      this.callGarmentCloth()
    }
  }

  callGarmentCloth() {
    let garmentObjet = this.garmentObject;
    let gender = garmentObjet.Gender == 'F' ? 'W' : 'M';
    const dataToSend = {
      garmentId: garmentObjet.id,
      zipSource: this.garmentImgage,
      typeWear: this.typeWear,
      type: this.garmentObject.GarmentType,
    };
    this.commonService.setSharedObject(dataToSend);

    this.router.navigate([
      '/threads',
      {
        brandId: this.garmentObject.BrandID,
        M: gender,
        Ex: 'N',
        garmentId: garmentObjet.id,
      },
    ]);
  }
  navigateOnThread() {
    let story = "S"
    this.router.navigate([
      '/threads',
      { brandId: this.BrandId, M: story, Ex: 'N' },
    ]);

  }

  selectGarmentImage(index: number, snapObjet) {
    let newTypeWear = this.getSelectionType(this.garmentObject.GarmentType)
    console.log("snapObjet", snapObjet)
    console.log("snapObjet", snapObjet)
    this.selectedImageIndex = index;
    let garmentObjet = this.garmentObject
    const dataToSend = {
      garmentId: garmentObjet.id,
      zipSource: this.garmentImgage,
      typeWear: newTypeWear,
      type: this.garmentObject.GarmentType,
    };
    let gender = this.tab === 'mens-wear' ? 'M' : (this.tab === 'womens-wear' ? 'W' : '');

    this.commonService.setSharedObject(dataToSend);
    this.commonService.setIndex(index);
    let page = this.pageNumberGarment;
    console.log("snapObjet", garmentObjet)
    console.log("dataToSend", dataToSend)
    this.router.navigate([
      '/threads',
      {
        brandId: this.garmentObject.BrandID,
        M: gender,
        Ex: 'N',
        storyId: snapObjet.StoryID,
        pageNumber: page,
        garmentId: garmentObjet.id,
      },
    ]);
  }

  shareBrand(brandObject: any) {
    console.log("brandObject===>", brandObject)
    let link =
      'https://yolomove-social-live.web.app/threads;brandId=' +
      brandObject.id +
      ';M=' +
      brandObject.Gender +
      ';Ex=Y';
    if (navigator.share) {
      navigator
        .share({
          title: 'Share Brand',
          text: 'Check out ' + this.brandObject.BrandName + ' on Yologram ',
          url: link,
        })
        .then(() => console.log('Successful share'))
        .catch((error) => console.log('Error sharing', error));
    }
  }


  incrementImgGarmentCard() {

    // this.pageNumberGarment++;
    console.log("this,this.garmentObject.id.garmentObject.id", this.garmentObject.id)
    console.log("this.typeWear", this.typeWear)

    let reqInputObject = { fileType: this.typeWear, id: this.garmentObject.id, pageNumber: 2 }
    this.commonService.yologramSnapshotDetailsByType(reqInputObject).subscribe(async (response: any) => {
      this.userSnapShotListGarment.push(...response.object)
      this.userSnapShotListGarment.forEach(async (snap: any, index) => {
        let userObjet = this.allUserDetails.find(userObject => userObject.uid == snap.UserID)
        snap.profileName = userObjet.displayName != undefined ? userObjet.displayName : snap.UserID;
        userObjet.profileName = snap.profileName;
        snap.userData = userObjet
        snap.firstName = snap.profileName.split(" ", 1);
        // snap.pageNumber = reqInputObject.pageNumber
        snap.AvatarLastFive = snap.AvatarID.substr(snap.AvatarID.length - 5);
      });
    })
  }





  ////////////////extra Codeind/////////////////////
  async downloadPack0(url) {
    console.log('pack0 loading intilze...');
    // Check if a download is already in progress
    // Set the download flag to true to indicate a download is in progress

    // let url = 'https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/' + brandEncodedurl + '?alt=media&token=46df48b9-1f7a-4b61-98c6-bff029734da4';
    try {
      const res = await this._httpClient
        .get(url, { observe: 'response', responseType: 'blob' })
        .toPromise();
      await this.processZipFile(res.body, this.processResponse.bind(this));
    } catch (error) {
      this.handleErrors(error);
    } finally {
    }
    console.log('pack0 loaded........');
  }
  processZipFile(data, callBackSetJsonObject) {
    // this.processZipFileCopy(data, callBackSetJsonObject)
    this.testVar = 'Change Update';
    JSZip.loadAsync(data, callBackSetJsonObject, this.testVar)
      .then(function (zip) {
        /* Parse json file */
        var jsonExtn = /(.json)$/;
        Object.keys(zip.files)
          .filter((fileName) => {
            return jsonExtn.test(fileName.toLowerCase());
          })
          .map((jsonFileName) => {
            var jsonFile = zip.files[jsonFileName];
            return jsonFile.async('string').then((jsonString) => {
              let packJsonObject = JSON.parse(jsonString);

              let jsonResponseData = [
                {
                  title: 'headgear',
                  value: {
                    files: packJsonObject['hFiles'].filter((data) => data != ''),
                    filesIndex: packJsonObject['hFiles'].filter((data) => data == '' || data != undefined),
                    name: packJsonObject['hName'].filter((data) => data.indexOf('deleted') >= 0), // Filter only names containing 'deleted'
                    count: packJsonObject['hCount'],
                  },
                },
                {
                  title: 'upperwear',
                  value: {
                    files: packJsonObject['uFiles'].filter((data) => data != ''),
                    filesIndex: packJsonObject['uFiles'].filter((data) => data == '' || data != undefined),
                    name: packJsonObject['uName'].filter((data) => data.indexOf('deleted') >= 0), // Filter only names containing 'deleted'
                    count: packJsonObject['uCount'],
                  },
                },
                {
                  title: 'bottomwear',
                  value: {
                    files: packJsonObject['bFiles'].filter((data) => data != ''),
                    filesIndex: packJsonObject['bFiles'].filter((data) => data == '' || data != undefined),
                    name: packJsonObject['bName'].filter((data) => data.indexOf('deleted') >= 0), // Filter only names containing 'deleted'
                    count: packJsonObject['bCount'],
                  },
                },
                {
                  title: 'footwear',
                  value: {
                    files: packJsonObject['fFiles'].filter((data) => data != ''),
                    filesIndex: packJsonObject['fFiles'].filter((data) => data == '' || data != undefined),
                    name: packJsonObject['fName'].filter((data) => data.indexOf('deleted') >= 0), // Filter only names containing 'deleted'
                    count: packJsonObject['fCount'],
                  },
                },
              ];


              callBackSetJsonObject(jsonResponseData);
            });
          });
        var re = /(.jpg|.png|.gif|.ps|.jpeg | .json)$/;
        var promises = Object.keys(zip.files)
          .filter(function (fileName) {
            // don't consider non image files
            return re.test(fileName.toLowerCase());
          })
          .map(function (fileName) {
            var file = zip.files[fileName];
            if (fileName.indexOf('.json') > 0) {
            } else {
              return file.async('blob').then(function (blob) {
                return [
                  fileName.substring(0, fileName.lastIndexOf('.')), // keep the link between the file name and the content
                  URL.createObjectURL(blob), // create an url. img.src = URL.createObjectURL(...) will work
                ];
              });
            }
          });
        return Promise.all(promises);
      })
      .then(function (result) {
        return result.reduce(function (acc, val) {
          acc[val[0]] = val[1];
          return acc;
        }, {});
      })
      .then((result) => {
        let newZipList = JSON.parse(JSON.stringify(result));
        this.zipList = newZipList;

        console.log('newZipList', newZipList);

        console.log('this.source', this.packJsonObject);
        return;
      })
      .catch(function (e) {
        console.error(e);
      });

    // this.reload(data,callBackSetJsonObject)
  }
  handleErrors(error) {
    console.log('error', error);
    this.packJsonObject = [];
  }
  async processResponse(res: any[]) {
    this.packJsonObject = res;
  }

}
