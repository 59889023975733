import { Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { CANCELLED } from 'dns';
import { Observable, filter } from 'rxjs';
import { CommonService } from 'src/app/services/common.service';
import { AppComponent } from '../app.component';
import { DataSharingService } from '../services/data-sharing-service';
import { MessageService } from '../services/messaging.service';
import * as $ from 'jquery';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ChangeDetectorRef } from '@angular/core';
import { UsermenuPopupComponent } from '../common/templets/usermenu-popup/usermenu-popup.component';
declare const WURFL: any; // This declaration is needed to use WURFL which is loaded globally


@Component({

  selector: 'app-yologram-social',
  templateUrl: './yologram-social.component.html',
  styleUrls: ['./yologram-social.component.css']

})

export class YologramSocialComponent implements OnInit {
  NotificationDetais = this.firestore.collection('garments').valueChanges({ idField: 'id' }) as Observable<any[]>;
  pageCollection = this.firestore.collection('Pages').valueChanges({ idField: 'id' }) as Observable<[]>;
  userPrefCollection = this.firestore.collection('UserPreference').valueChanges({ idField: 'id' }) as Observable<[]>;
  groupUser = this.firestore.collection('GroupUser').valueChanges({ idField: 'id' }) as Observable<[]>;
  securityGrpCollection = this.firestore.collection('SecurityGroup').valueChanges({ idField: 'id' }) as Observable<[]>;
  groupRoleCollection = this.firestore.collection('GroupRole').valueChanges({ idField: 'id' }) as Observable<[]>;
  userFiles = this.firestore.collection("SnapshotDetails");
  userFileStateChange = this.userFiles.valueChanges();
  user = this.firestore.collection('user-profile').valueChanges({ idField: 'id' }) as Observable<any[]>;
  collctTheGpName: any = {}
  backbutton: boolean = false;
  userSnapShotList: any = []
  userData: any = []
  allUserDetails: any = []
  user_name: string = "";
  SampleList = []
  email = null;
  id: any;
  imgpath: string = "";
  username: string = "";
  selectedTab: string = "";
  isOpen = true;
  isDropdownOpen = true;
  public show: boolean = false;
  public buttonName: any = 'Show';
  menuFlag:boolean = true
  userMenuFlag :boolean = false

  value = 50;
  color = 'primary';
  mode = 'indeterminate';
  pageAll: any = []
  preferenceDocId: any;
  groupDetails = []
  groupsDetails: any = {};
  SelectGroupId: any = []
  SnapshotListForProfile: any = []
  filterSnapShotForProfile: any = []
  filterSanp: any = []
  selectedSecurityGroupName:any="";
  userFilesList = this.firestore.collection('userfiles').valueChanges({ idField: 'id' }) as Observable<any[]>;
  deviceInfo: any;


  @Input() name: string | undefined;
  @Input() routMAp: any | undefined;


  @ViewChild('yologramnavbar', { read: ElementRef, static: false })
  yologramnavbar!: ElementRef;
  @ViewChild('myModal') myModal: any;

  @ViewChild('yologramnavbarProfile', { read: ElementRef, static: false })
  yologramnavbarProfile!: ElementRef;

  
  pageAccessList = {
  }

  notificationMessage: any;
  NotificationCollection: any[];
  secGrpList: any = {};
  userReferenceList = [];
  userGrpId: any;
  userSecurityGrp: any = [];
  SnapShotFileDetails: any = [];
  userUploadedFileList: any = [];
  filelist: any = [];
  userFiledetails: any = [];
  alluserSnap: any = [];
  UserList: any
  selectedSecGrpId: any;
  selectedSecGrpIdName: any;
  userDetails: any = {};
  UserId: any
  securityGroupList: any[];
  groupId: any = [];
  black: any = [];
  KeyValue: any = {};
  Uid :any = {}

  images = [
    { "image": "./assets/user-upload-dummy/new-img-1.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-2.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-3.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-4.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-5.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-6.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-7.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-8.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-9.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-10.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-11.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-12.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-13.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-14.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-15.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-16.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-17.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-18.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-19.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-20.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-21.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-22.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-23.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-24.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-25.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-26.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-27.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-28.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-29.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-30.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-31.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-32.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-33.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-34.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-35.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-36.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-37.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-38.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-39.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-40.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-41.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-42.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-43.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-44.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-45.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-46.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-47.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-48.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-49.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-50.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-51.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-52.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-53.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-54.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-55.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-56.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-57.jpg" },
  ]
  closeFlag: any;
  BrandId: string;
  urlThreadFalsg: boolean;
  pageFlagRout: any = '';
  routFalg: boolean;
  showFooter: boolean = false;
  showHeader: boolean = false;
  
  constructor(
    private messageService: MessageService,
    public router: Router,
    private commonService: CommonService,
    private appcmp: AppComponent,
    private firestore: AngularFirestore,
    private dataSharingService: DataSharingService,
    private modal: NgbModal,
    private route: ActivatedRoute,
    private auth: CommonService,
    private cdRef: ChangeDetectorRef,
    ) 
  {
    this.adjustVisibility()
    this.getUserPreference()
    this.commonService.getUser().subscribe((user: any) => {
      this.email = user?.email;
      this.id = user?.uid;
      console.log("user-Id:",this.id)
      console.log("user-details:",user)

      this.imgpath = user?.photoURL;
      this.user_name = user?.displayName;
      if (!this.id) {
        this.router.navigateByUrl('/Login');
      } else {
      }
      // this.snapShotInIt()
    });

    this.deviceInfo = WURFL;


    this.commonService.snapshotfileDetais().subscribe(async (snapshot: any) => {
      this.SnapShotFileDetails = snapshot;})
      this.userFilesList.subscribe((userFile) => {
        this.userUploadedFileList = userFile
        this.filelist = this.userUploadedFileList.filter((uploadlist) => uploadlist.fileType == 'UserUploads' )
        let filelist = this.filelist.filter((uploadlist) => uploadlist.userId ==  this.id )
        this.userFiledetails = filelist
        this.alluserSnap =  this.userFiledetails
        if(this.userFiledetails.length == 0){
          this.black =  this.images 
        }

      })

      this.commonService.snapshotfileDetais().subscribe(async (snapshot: any) => {
        this.SnapshotListForProfile = snapshot
        // this.snapShotInIt()
      })
      this.adjustVisibility()

  }



  ngOnInit(): void {
    this.adjustVisibility()
    this.menuFlag = false
    this.route.paramMap.subscribe(paramMap => {
      this.id = paramMap.get('id');
      this.BrandId = paramMap.get('brandId')
    });


    this.route.url.subscribe(url => {
      console.log('url',url)
      this.pageFlagRout = url[0].path
      
      console.log('url thi',url[0].path)

      if(this.pageFlagRout == 'threads'){
        this.routFalg = true
      }
      
      // Check if the URL matches your condition
  });


    this.securityGrpCollection.subscribe((groupList: any) => {
      this.securityGroupList = groupList
    })

    this.userFileStateChange.subscribe(userSnapShots => {
      this.userSnapShotList = userSnapShots
      this.SampleList = this.userSnapShotList.sort((a, b) => {
        return <any>new Date(a.timestamp) - <any>new Date(b.timestamp);
      })
    });
  
    this.user.subscribe((userFile) => {
      this.UserList  = userFile
      this.userDetails =  this.UserList.find((user)=>user.UserID == this.id )
    })

      this.initModal() 
      // if(this.router.navigate(['/threads']) )
      // {
      //   console.log("Brand Url",this.router.navigate(['/threads']) )
      // }
  }


  ngOnChanges(changes) {
   
    
  }
  

  ngAfterViewInit(): void {

  }

  @HostListener('window:resize')
  adjustVisibility() {
    const width = window.innerWidth;
    const height = window.innerHeight;
  
    // Reset visibility states before applying the new conditions
    this.showFooter = false;
    this.showHeader = false;
  
    // Using the hypothetical 'deviceInfo' object that should contain information about the device
    // This information needs to be obtained beforehand, for example, during component initialization
    // Here, it's assumed 'deviceInfo' is already available and contains 'form_factor' and 'is_mobile' properties
    if (this.deviceInfo) {
      const isDesktopOrSmartTV = this.deviceInfo.form_factor === 'Desktop' || this.deviceInfo.form_factor === 'Smart-TV';
      
      if (isDesktopOrSmartTV) {
        // Show header for Desktop and Smart-TV
        this.showHeader = true;
      } else {
        // Show footer for other devices
        this.showFooter = true;
      }
    } else {
      // Default behavior in case device information is not available
      // This can be adjusted according to your application's needs
      if (width > height) {
        // Assuming landscape orientation generally for desktops and TVs
        this.showHeader = true;
      } else {
        // Assuming portrait orientation for mobile devices
        this.showFooter = true;
      }
    }
  }
  
  

  
  private initModal() {
    const modalElement = document.getElementById('exampleModalCenter');
    const modal = new window['bootstrap'].Modal(modalElement);
    modalElement.addEventListener('hidden.bs.modal', (event) => {
      // Handle the backdrop click here
      console.log('Backdrop was clicked');
      this.closePopupByclick()
      // Add your logic here
    });
  }

  sliderRefresh() {
    this.userFiledetails = []
    console.log(this.userFiledetails)
    this.filelist = this.userUploadedFileList.filter((uploadlist) => uploadlist.fileType == 'UserUploads')
    let filelist = this.filelist.filter((uploadlist) => uploadlist.userId == this.id)
    this.userFiledetails = filelist

  }
  clickOnIconHIden(){
    this.menuFlag = false
    this.userMenuFlag = false
    this.closeModal()
  }


  
  setuserDetails(userDetails){
    this.auth.getAllUser().subscribe(async (allUsers: any) => {
      this.allUserDetails = allUsers.users;
      let userDetail =  this.allUserDetails.find((user)=>user.uid == this.id )
      let dispalydetails = userDetail.providerData[0]
      let saveUserDetails = {
        "DisplayName":dispalydetails.displayName,
        "Email":this.email,
        "FirstName": '',
        "Identifier":'',
        "LastName":'',
        "MiddleName":'',
        "ProfilePicture":dispalydetails.photoURL,
        "UserID":userDetail.uid,
        "pageName":'mainPage'
      }
      this.firestore.collection('user-profile').doc(userDetails.id).update(saveUserDetails);
    })
  }


  setTab(tab: string) {
    this.selectedTab = tab;
    this.NotificationDetais.subscribe(
      notification => {
        this.NotificationCollection = notification;
      });
  }

  hideNavigtation() {
    this.yologramnavbar.nativeElement.classList.remove('show')
  }

  hideNavigtationProfile() {
    this.yologramnavbarProfile.nativeElement.classList.remove('show')
  }


  async handleSignOut() {
    try {
      await this.commonService.signOut();
      this.router.navigateByUrl("/drip");
      window.location.reload()
      this.email = null;
    } catch (error) {
    }
  }



  toggleNavbar() {
    this.isOpen = !this.isOpen;
  }

  toggleDropDown() {
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  getName() {
    this.notificationMessage = JSON.parse(localStorage.getItem("notification"));
  }

  getUserPreference() {
    this.groupUser.subscribe((grpList) => {
      //     let selectedGroupName = grpList.filter((grp: any) => grp.SecGpId === this.KeyValue);
      this.userSecurityGrp = grpList.filter((grp: any) => grp.UserId === this.id);
      this.firestore.collection("UserPreference").ref
        .where("Key", "==", "LastUserGroupSelected")
        .where("UserId", "==", this.id.toString())
        .get().
        then((objectList: any) => {
          this.userReferenceList = objectList.docs;
          if (objectList.docs.length > 0 && this.userSecurityGrp.length > 0) {
            objectList.forEach((doc) => {
              if (doc.data().UserId == this.id && this.userSecurityGrp.find(b=> b.SecGpId ==doc.data().KeyValue) != undefined
              ) {
                this.selectedSecGrpId = doc.data().KeyValue;
                this.preferenceDocId = doc.id;
                this.groupRoleCollection.subscribe(((groupRole: any) => {
                  let accessPageList = groupRole.filter((obj) => obj.SecGpId == this.selectedSecGrpId)
                  let accessPageListselectGroup = groupRole.find((obj) => obj.SecGpId == this.selectedSecGrpId)
                  this.selectedSecGrpIdName = accessPageListselectGroup.SecGpId
                  this.pageCollection.subscribe((pageList) => {
                    this.pageAll = []
                    pageList.forEach((page: any) => {
                      let PageId = page.id
                      let accessPageObject = accessPageList.filter((aPageList) => aPageList.PageId == PageId);
                      if (accessPageObject.length > 0) {
                        this.pageAll.push(page);
                       // this.router.navigateByUrl(doc.data().Page);
                      }
                    })
                  });
                }))
              }

            });
          } else if (objectList.docs.length > 0) {
            this.router.navigateByUrl('/yologram-social/yologram-public-news');
          } else if (this.userSecurityGrp.length > 0) {
            this.router.navigate(['yologram-social/security-group-change', { 'id': this.id }])
          } else {
            this.router.navigateByUrl('/yologram-social/yologram-public-news');
          }
        }).catch((error) => {
          console.log("Error getting documents: ", error);
        });
    });
  }

  getUserProfile() {
    let userId = this.id
    let userObjectForProfile = this.SampleList.filter((snapObject) => snapObject.UserID === userId)
    this.SampleList = userObjectForProfile.sort((a, b) => { return <any>new Date(b.timestamp) - <any>new Date(a.timestamp); })
    this.dataSharingService.sendUserSnapShots(userObjectForProfile)
    this.router.navigate(['yologram-social/yologram-public-news-feed', { 'id': userId }])
  }

  getSecurityGroupNameById(id) {
    let userSecGrp = this.userSecurityGrp.find((b: any) => b.SecGpId === id);
    let securityGroup = this.securityGroupList && this.securityGroupList.find((b: any) => b.id === id);
    this.selectedSecurityGroupName =  userSecGrp != undefined  && securityGroup['name'] != undefined ? securityGroup['name'] : "Select Group";
    return true;
  }

  getProfileForUSer() {
    let userId = this.id
    let userObjectForProfile = this.SnapshotListForProfile.filter((snapObject) => snapObject.UserID === userId)
    this.filterSnapShotForProfile = userObjectForProfile
    this.dataSharingService.sendUserSnapShots(userObjectForProfile)
    let id = userId
    let  pagetab =  'story'
    // this.Uid = {UserId : userId, Pagetab :pagetab}
    // this.router.navigate(['yologram-social/yologram-user-myprofile'],{state: this.Uid})
    this.dataSharingService.setNavigationData({
      UserId : userId,
       Pagetab :pagetab
     
    });
   this.router.navigate(['/me'])
  }


  getSnapdata(){
    let userId = this.id
    let userObjectForProfile = this.SnapshotListForProfile.filter((snapObject) => snapObject.UserID === userId).slice(0,3)
    this.filterSnapShotForProfile = userObjectForProfile
    this.menuFlag = false
    this.openUserMenuPopup()
  }
  


  getProfileForUSerFeed(snapshot){
    let userId = this.id
    let userObjectForProfile = this.SnapshotListForProfile.filter((snapObject) => snapObject.UserID === userId)
    this.filterSnapShotForProfile = userObjectForProfile
    this.dataSharingService.sendUserSnapShots(userObjectForProfile)
    let id = userId
    let  pagetab =  'user-story'
    // this.Uid = {UserId : userId, Pagetab :pagetab, StoryId:snapshot.StoryID}
    // this.router.navigate(['yologram-social/yologram-user-myprofile'],{state: this.Uid})
    this.dataSharingService.setNavigationData({
      UserId : userId,
       Pagetab :pagetab
     
    });
    // this.id = {UserId : userId, Pagetab :pagetab, StoryId:snapshot.StoryID}
    this.router.navigate(['/me'])
  }
  
  closeModal() {
    const modal = document.getElementById('exampleModalCenter');
    if (modal) {
      modal.classList.remove('show');
      modal.style.display = 'none';
      document.body.classList.remove('modal-open');
    }
    this.closeFlag  =+ 1
  }


  openUserMenuPopup() {
    const modalRef = this.modal.open(UsermenuPopupComponent, {
      modalDialogClass: "model-garmentPopup"
    });
    //    modalRef.componentInstance.id = 10;
    modalRef.componentInstance.PageAll = this.pageAll;
    modalRef.componentInstance.userSecurityGrp = this.userSecurityGrp;
    modalRef.componentInstance.userSecurityGrp = this.userSecurityGrp;
    modalRef.componentInstance.selectedSecurityGroupName = this.selectedSecurityGroupName;
    modalRef.componentInstance.securityGroupList = this.securityGroupList;
    modalRef.componentInstance.selectedSecGrpId = this.selectedSecGrpId;
    modalRef.componentInstance.id = this.id;
    modalRef.componentInstance.filterSnapShotForProfile = this.filterSnapShotForProfile;
    modalRef.componentInstance.userDetails = this.userDetails;
    modalRef.componentInstance.SnapshotListForProfile = this.SnapshotListForProfile;
    modalRef.componentInstance.userFiledetails = this.userFiledetails;
    modalRef.componentInstance.images = this.images;
    modalRef.result.then((result) => {
    }).catch((error) => {
      console.log(error);
    });
  }

  closeModals() {
    // const myModal = new window['bootstrap'].Modal(document.getElementById('exampleModalCenter'));
    // // myModal.hide();
    // myModal.close();


    this.modal.open(this.myModal, { backdrop: 'static', keyboard: false }).result.then(
      (result) => {
        console.log('Modal closed with result:', result);
      },
      (reason) => {
        if (reason === 'backdrop-click') {
          console.log('Modal closed due to backdrop click');
          // Add your handling code here
        }
      }
    );
}

snapShotInIt(){
  let userId = this.id
  let userObjectForProfile = this.SnapshotListForProfile.filter((snapObject) => snapObject.UserID === userId).slice(0, 3)
  this.filterSanp = userObjectForProfile
}

closePopUp(){
  this.menuFlag = true
}

closePopupByclick(){
  this.menuFlag = false

}
onModalHidden() {
   console.log('Modal is hidden (including due to backdrop click)');
   let conges = this.cdRef.detectChanges();
   console.log(conges);
}

}
