import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';
import { Brand } from 'src/app/models/brand';
import { MatDialog } from '@angular/material/dialog';
import { CommonService } from 'src/app/services/common.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DataSharingService } from 'src/app/services/data-sharing-service';
import { PinterestService } from 'src/app/services/pinterest-service';
import { ApiHttpService } from 'src/app/services/api-http.service';
export interface ZipFile {
  readonly name: string;
  readonly dir: boolean;
  readonly date: Date;
  readonly data: any;
}
@Component({
  selector: 'app-style',
  templateUrl: './style.component.html',
  styleUrls: ['./style.component.css']
})
export class StyleComponent {
  @ViewChild('brandStoriyScroll', { read: ElementRef }) public BrandStoriyScroll: ElementRef<any>;
  brands = this.firestore.collection('brands').valueChanges({ idField: 'id' }) as Observable<Brand[]>;
  garmentsList = this.firestore.collection('garments').valueChanges({ idField: 'id' }) as Observable<any[]>;
  brandsBoardCollections = this.firestore.collection('brand-board').valueChanges({ idField: 'id' }) as Observable<Brand[]>;
  userFiles = this.firestore.collection("userfiles");
  userFileStateChange = this.userFiles.valueChanges();
  brandBoardList = this.firestore.collection('brand-board').valueChanges({ idField: 'id' }) as Observable<any[]>;
  SnapShotsList = this.firestore.collection('SnapshotDetails').valueChanges({ idField: 'id' }) as Observable<any[]>;
  brandsCollection = this.firestore.collection('brands').valueChanges({ idField: 'id' }) as Observable<Brand[]>;
  brandsCollections = this.firestore.collection('brands').valueChanges({ idField: 'id' }) as Observable<Brand[]>;
  displayedColumns: string[] = ['position', 'name', 'weight', 'symbol'];
  public text = `{ "text": "This is text file!中文" }`;
  public fileName: string | undefined;

  
  @Input() rootFlag: string | undefined;
  @Input() stylePage: string | undefined;

  @Input() routLogInFlag: boolean | undefined;


  


  boardDetails: any
  selectedBrandType: any
  selectedBrand: any;
  getZipcollection: any;
  zipfile: any;
  loading = true;
  brandDetails: any = []
  brandDetailsForStories: any = []
  snapDetails: any = []
  garmentsCollection: any = []
  brandId: any
  brandGarmentIds: any = [];
  SnapShotFileDetails = []
  BrandDetails = []
  snapShotBrandList = [];
  filterStories = []
  storyId: any
  userId: any
  brandIds: any
  boards: any = []
  brandBoard: any = []
  boardNameInBrandStory: any
  brandDetailsId: any = {}
  showBrandName: boolean = false
  showBCollasps: boolean = false;
  showRender: boolean = false;
  brandDetail: any = {}
  userSnapShotList: any = []
  SnapShotDetails: any = []
  // selectedTab:any = "news-stories"
  selectedTab: any = "mens-wear"
  brandsIDdetailsAll: any = {}
  genderID: any
  isloading = true
  brandDetailsAll:any = []
  byScroll:boolean = false
  showBrandFlag:boolean = false
  heartFlag:boolean = false
  thresholdHeightPagination: number = 0
  pageNumber : number = 0
  clickEventFlag:boolean=false;
  genderFlag :boolean;
  logInFlag :boolean;
  onChangeFlag = true;
  height: any;
  url: any;
  urlAvatar:  any = '';
  urlAvatarFeMale: any = 'https://firebasestorage.googleapis.com/v0/b/rishtest-96467.appspot.com/o/20230112114731661.zip?alt=media&token=5ef19c50-0361-46c1-949e-0f78b16c9c20&_gl=1*1i8j5xe*_ga*MTY2MTMzOTE3NC4xNjk2ODgxNDM4*_ga_CW55HF8NVT*MTY5NzQ2MDA1OC4yOC4xLjE2OTc0NjAzMTYuNjAuMC4w';
  urlAvatarMale:any  = 'https://firebasestorage.googleapis.com/v0/b/rishtest-96467.appspot.com/o/20230112115118542.zip?alt=media&token=3ea78817-7886-4806-9963-77717f09be64&_gl=1*1w2i935*_ga*MTY2MTMzOTE3NC4xNjk2ODgxNDM4*_ga_CW55HF8NVT*MTY5NzM2MTkzNi4yMS4xLjE2OTczNjE5NzQuMjIuMC4w';
  GarmentsGender: any;
  garmentLink: any;
  selectedBrandDeatails: any;
  tabSelecton: string;
  selectedGender: string;
  
  //New

  constructor(private route: ActivatedRoute, private pinterestAPI: PinterestService, private apiHttpService: ApiHttpService,
    private firestore: AngularFirestore, private dataSharingService: DataSharingService, public commonService: CommonService, private router: Router) {
    this.route.paramMap.subscribe(paramMap => {
      this.brandId = paramMap.get('brandId')
      this.userId = paramMap.get('id')
      this.storyId = paramMap.get('storyId')
    });

   
    this.logInFlag = this.commonService.isLoggedIn()


    if (this.brandId != undefined) {
      this.selectBrandForStory(this.brandId, this)
    }
  }



  ngOnChanges() {
  }


  ngOnInit() {
    console.log('selectedBrand',this.selectedBrand)
    let title = 'Yologram - style'
    this.commonService.changeTitle(title);
    this.loading = true;
    this.showBrandFlag = true
    this.pinterestAPI.getBoardList().subscribe((data) => {
      this.boards = data.items;
      this.brandsBoardCollections.subscribe((brandBoard) => {
        this.brandBoard = brandBoard;
      });
      // this.commonService.snapshotfileDetais().subscribe(async (snapshot: any) => {
      //   this.SnapShotDetails = snapshot
      //   this.loading = false;
      // })
   
    })
    // this.snapShotInit()
    this.brandCollecton()
    // this.checkPageAuthorization()


    this.brands.subscribe((brandList) => {
      this.brandDetailsAll = brandList
      this.brandDetailsAll.forEach(async (brand) => {
        let type = 'b';
        let url = await this.commonService.compressImage(brand.LogoURL, type); // Assuming each brand has 'imagePath' property
        brand.Brandurl = url.downloadUrl;  // Save the compressed image URL back into the brand object
      });
      this.loading = false;
    })


    this.garmentsList.subscribe((grList) => {
      this.garmentsCollection = grList
      // if (this.brandId != undefined) { this.selectBrandForStory(this.brandId, this.brands) }
      this.brands.subscribe((brandList) => {
        this.brandDetails = brandList
        let object = this.brandDetails
        this.brandDetailsId = object.find((bBoard) => bBoard.id == this.brandId)
        if (this.brandId != undefined) {
          this.selectedBrand = this.brandDetailsId;
          this.boardDetails = this.boardNameInBrandStory
          let brandBoardFilter = this.brandBoard.find((bBoard) => bBoard.brandId == this.brandId)
          this.boardDetails = (brandBoardFilter != undefined) ? this.boards.find((board) => board.id == brandBoardFilter.boardId) : undefined;
        }
      })


      let garmentIds = this.garmentsCollection.map((garment) => garment.id);
      let garmentsObject = this.garmentsCollection.reduce((acc, obj) => {
        const key = obj.id;
        const curGroup = acc[key] ?? [];
        return { ...acc, [key]: obj.BrandID };
      }, {});
      this.commonService.snapshotfileDetais().subscribe(async (snapshot: any) => {
        this.SnapShotFileDetails = snapshot;
        this.SnapShotFileDetails.forEach((snapshot) => {
          if (garmentsObject[snapshot.HeadwearID] != undefined
            || garmentsObject[snapshot.BottomwearID] != undefined
            || garmentsObject[snapshot.UpperwearID] != undefined
            || garmentsObject[snapshot.FootwearID] != undefined
          )
            if (garmentsObject[snapshot.HeadwearID] != undefined && this.snapShotBrandList.indexOf(garmentsObject[snapshot.FootwearID]) == -1) this.snapShotBrandList.push(garmentsObject[snapshot.FootwearID]);
          if (garmentsObject[snapshot.BottomwearID] != undefined && this.snapShotBrandList.indexOf(garmentsObject[snapshot.BottomwearID]) == -1) this.snapShotBrandList.push(garmentsObject[snapshot.BottomwearID]);
          if (garmentsObject[snapshot.UpperwearID] != undefined && this.snapShotBrandList.indexOf(garmentsObject[snapshot.UpperwearID]) == -1) this.snapShotBrandList.push(garmentsObject[snapshot.UpperwearID]);
          if (garmentsObject[snapshot.FootwearID] != undefined && this.snapShotBrandList.indexOf(garmentsObject[snapshot.FootwearID]) == -1) this.snapShotBrandList.push(garmentsObject[snapshot.FootwearID]);
        })
      })
    })

  }



  selectByScroll(){
    this.byScroll = true
  }
  closeButtonForBrand(){
    this.byScroll = false
  }
  selectetdTab() {
    if (this.tabSelecton == 'story') {
      this.selectedTab = "story"
    }
    if (this.selectedGender == 'M') {
      this.selectedTab = "mens-wear"
    }
    if (this.selectedGender == 'F') {
      this.selectedTab = "womens-wear"
    }
  
  }
  getBrandImage(imgURL: string) {
    let brandEncodedurl = encodeURI(imgURL).replace('/', '%2F');
    let url = "https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/" + brandEncodedurl + "?alt=media&token=550e7e88-bed3-4668-84ff-ad491c19e06c"
    return url;
  }


  // showBrandDetails(brand: any) {
  //   this.selectedBrand = brand;
    
  // }


  selectBrandForStory(brandId: any, brands: any) {
    this.thresholdHeightPagination = 0
    this.showBrandFlag = false
    this.byScroll = false
    this.selectedBrand = brands
    this.brandId = brandId;
    this.boardDetails = {}
    let brandBoardFilter = this.brandBoard.find((bBoard) => bBoard.brandId == brandId)
    let brandName = this.boards.find((bname)=>bname.id  == brandBoardFilter.boardId)
    this.boardDetails = brandName
    this.selectedTab = "news-stories"      
    this.clickEventFlag = !this.clickEventFlag
    this.addState()
  }


  colaaps(){
    this.showBCollasps = true
    this.showRender = false
  }
  selectBrandForScroll() {
    document.querySelector('#target').scrollIntoView({ behavior: 'smooth', block: 'center' });
  }
  brandCollecton() {
    let brandsNID = this.brandDetails.filter((nameOfBrand) => nameOfBrand.id === this.garmentsCollection.filter((brandOfGArment) => brandOfGArment.BrandID))
  }

  // snapShotInit() {
  //   this.commonService.snapshotfileDetais().subscribe(async (snapshot: any) => {
  //     this.SnapShotDetails = snapshot
  //   })
  // }

  setTab(tab) {
   
    this.selectedTab = "";
    this.selectedTab = tab;
  // this.urlAvatar = urlMale
    // this.urlAvatar = this.selectedTab == 'mens-wear' ?  this.urlAvatarMale : this.urlAvatarFeMale

    if( tab == 'mens-wear')
      {
        this.genderFlag = true
      }else{
        this.genderFlag = false
      }
    

  }

  changeTab(event) {
    this.onChangeFlag = false;
    this.selectedTab = event;
   
  }

  changeCloth(event){
  let clothId  = event;
   console.log("clothId",clothId)
  this.colaaps()
  this.copyTextToClipGarment(clothId)

    
  }

  clickOnHeart(){
    this.heartFlag = true
   } 
  onScroll(){
    this.height = this.BrandStoriyScroll.nativeElement.scrollTop
    }
    
    addState() {
      let stateObj = { id: "100" };
      let setUrl = this.stylePage
      if(setUrl != undefined){
          this.url = this.stylePage
      }
      let pageUrl = this.stylePage != undefined ||  this.stylePage != ''? this.stylePage+";brandId=" : "/public/public-brand-stories;brandId="

  let styleUrl ="/style;brandId="+this.brandId
  

  console.log('style', this.stylePage)
   let commonUrl =  pageUrl != undefined ? pageUrl : styleUrl
      // let url = "#"+pageUrl+this.brandId
      window.history.pushState(stateObj, "Page", styleUrl);
  }



  copyTextToClipGarment(text: string) {
    let Garments = this.garmentsCollection.find((gaementList) => gaementList.id === text);
    let brandId = Garments.BrandID
    let gender = Garments.Gender;
    this.GarmentsGender = Garments.Gender;
    let garmentType = Garments.GarmentType
    let zipfilename = Garments.id
    let packlocation = "AvatarBlenderClothes%2F" + brandId + "_" + gender + "%2F" + garmentType + "%2F" + zipfilename;
    // console.log("packlocation",packlocation)
    this.garmentLink = 'https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/' + packlocation + '?alt=media&token=2c1dc261-ac91-4c32-a5ec-ac5e06f0a9bc&_gl=1*goch74*_ga*MjExMjI5MTIyMi4xNjg3NTA3MDk1*_ga_CW55HF8NVT*MTY5NjkzMDY1Ny4xNTcuMS4xNjk2OTM2NzA4LjU5LjAuMA..'
    
  }
}
