import { Component, OnInit } from '@angular/core';
// import { AngularFireMessaging } from '@angular/fire/compat/messaging/messaging';
import { AngularFireMessaging } from "@angular/fire/compat/messaging"
import { ActivatedRoute } from '@angular/router';
import { MessageService } from 'src/app/services/messaging.service';

@Component({
  selector: 'app-chat-box',
  templateUrl: './chat-box.component.html',
  styleUrls: ['./chat-box.component.css']
})
export class ChatBoxComponent implements OnInit {


  message:any = "";
  destToken:any;
  messageAlert= false;

  constructor( private messagingServices: AngularFireMessaging,
    private messageService: MessageService,
    private route: ActivatedRoute
    ){ 
      this.destToken = this.route.snapshot.params['desttoken']; 
    }

  ngOnInit(): void {

  }
  sendmessageApi(destToken){
    this.messageAlert =true,1000
    this.messageService.sendMessage(this.destToken,this.message);
    if(!this.message){
      this.messageAlert = false,1000
    }
  }
}
