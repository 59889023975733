import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { PinterestService } from 'src/app/services/pinterest-service';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { catchError, map, Observable, Subscription, tap } from 'rxjs';
import { Brand } from 'src/app/models/brand';
import { Snapshot } from 'src/app/models/snapshot';
import { ApiHttpService } from 'src/app/services/api-http.service';
import { environment } from 'src/environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { CommonService } from 'src/app/services/common.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DataSharingService } from 'src/app/services/data-sharing-service';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { Console } from 'console';
import { collection } from 'firebase/firestore';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserPopupComponent } from 'src/app/common/templets/user-popup/user-popup.component';
import { BrandPopupComponent } from 'src/app/common/templets/brand-popup/brand-popup.component';
import { PopupThreeDComponent } from 'src/app/common/popup-three-d/popup-three-d.component';
import { GarmentPopupComponent } from 'src/app/common/templets/garment-popup/garment-popup.component';


@Component({
  selector: 'app-user-news',
  templateUrl: './user-news.component.html',
  styleUrls: ['./../news.component.css']
})
export class UserNewsComponent implements OnInit {

  @ViewChild('pinModel', { static: false }) pinModal: ElementRef<HTMLInputElement> = {} as ElementRef;
  @ViewChild("snapshotfeeds") snapShotFeeds?: ElementRef;



  brandsFilesList = this.firestore.collection('brands').valueChanges({ idField: 'id' }) as Observable<Brand[]>;
  brandBoardList = this.firestore.collection('brand-board').valueChanges({ idField: 'id' }) as Observable<any[]>;
  environmentCollection = this.firestore.collection('Environments').valueChanges({ idField: 'id' }) as Observable<Brand[]>;
  // snapshotFilesList = this.firestore.collection('SnapshotDetails',
  //   ref => {
  //     return ref.orderBy('DateTime', 'desc');
  //   }).valueChanges({ idField: 'id' }) as Observable<Snapshot[]>;
  user = this.firestore.collection('user-profile').valueChanges({ idField: 'id' }) as Observable<any[]>;
  userFilesList = this.firestore.collection('userfiles').valueChanges({ idField: 'id' }) as Observable<any[]>;
  pinterestBoardPinsStatus = this.firestore.collection('pinterest-board-pins-status').valueChanges({ idField: 'id' }) as Observable<Snapshot[]>;
  garmentsList = this.firestore.collection('garments').valueChanges({ idField: 'id' }) as Observable<any[]>;
  brandBoardCollection = this.firestore.collection('brand-board').valueChanges({ idField: 'id' }) as Observable<any[]>;
  profileDetailsAll:any={}
  color = 'primary';
  mode = 'indeterminate';
  value = 50;
  selectedAvtar:any
  displayProgressSpinner = false;
  spinnerWithoutBackdrop = false;
  offsetPrevious = 0;
  scrolling = 'down';
  scrollingWidth = 50;
  indexMapping = 0;
  scrollPixel = 0;
  delayFlag = false
  private timeout: any;
  snapshotCollection: any = [];
  garmentsCollection: any = [];
  brandboardCollection: any = [];
  allUserDetails: any = [];
  brandsCollection: any = [];
  storyPinterestPinsList: any = [];
  subscription: Subscription | undefined;
  scrollselected: any = [];
  identifierList: any = {};
  slides: any[] = new Array(3).fill({ id: -1, src: '', title: '', subtitle: '' });
  clothDetails: any = {};
  selectedBrand: any = {};
  brandDetails: any;
  brands: any = [];
  boardId: any;
  boards: any;
  pinImg: string = "";
  snapShotFileList: any = []
  brandShapShotsList: any = []
  filterSnapShotFileDetails: any = []
  isLoading = false;
  topScroll: number;
  idDetails: any;
  deleteFalse: boolean = false;
  menuFlag: boolean = true;
  clothShapShotsList:any=[]
  clothShapShotsListALL :any=[]
  clothShapShotsListAll : any=[]
  pindetails: any = [];
  pinterstJsonMap = {
    "StoryID": undefined,
    "upperwear": undefined,
    "footwear": undefined,
    "headwear": undefined
  }

  imageList: any = this.pinImg;
  brandId: any;
  StoryID: any;
  UpperwearId: any;
  pinId: any;
  loading: boolean = false;
  typeSelected: any;
  boardsName: any;
  filterByUserFlag: boolean = false;
  userId: string;
  storyId: any
  pageScrollFlag: boolean = false;
  id: any
  userSnapShotList = []
  brandGarments = []
  brandCollectionId: any = []
  brandStoriesImgLimit: number = 0;
  brandCollectionIdcollection: any = []
  clocthCollectionIdcollection:any =[]
  profileDetails:any={}
  filterSnapShotForProfile:any=[]
  selectSnap: any;
  filterEnvironmentSnpShot:any=[]
  enViromentName:any
  evnFileList: any = []
  evnFileListFileName : any
  userUploadedFileList: any = [];
  userFiledetails: any = [];
  counter: number = 4;
  showImages:any={}
  userData: any = [];
  gltfImgSrc: string;
  twoDprivew: string;
  skyBoXall: string;
  evsname: any;
  evsdiplaynameALL: any = '';
  environmentFileName: any = ''
  hideFlag: boolean = true


  




  //  identifierDetail

  constructor(public dialog: MatDialog,
    private firestore: AngularFirestore,
    private commonService: CommonService,
    private apiHttpService: ApiHttpService,
    private pinterestAPI: PinterestService,
    private route: ActivatedRoute,
    public router: Router,
    private dataSharingService: DataSharingService,
    private storage: AngularFireStorage,
    private modal: NgbModal,     

  ) {

    this.typeSelected = 'ball-fussion';
    this.brandBoardList.subscribe(
      brandboard => {
        this.brandboardCollection = brandboard;
      });

      this.user.subscribe(userdetails => {
        this.userData = userdetails
      });

    this.garmentsList.subscribe(
      garments => {
        this.garmentsCollection = garments;
      });


    this.brandsFilesList.subscribe((brands) => {
      this.brandsCollection = brands;
    });

    this.pinterestBoardPinsStatus.subscribe(
      response => {
        this.storyPinterestPinsList = response
      });
      this.environmentCollection.subscribe(
        (evnFile) => {
          this.evnFileList = evnFile
        });
        this.userFilesList.subscribe((userFile) => {
          this.userUploadedFileList = userFile
          let clothId = this.clothDetails.id
          let filelist = this.userUploadedFileList.filter((uploadlist) => uploadlist.link == clothId).slice(0,2)
          this.userFiledetails = filelist
        })

        this.commonService.getAllUser().subscribe(async (allUsers: any) => {
          this.allUserDetails = allUsers.users;
        })
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe(paramMap => {
      this.userId = paramMap.get('id');
      this.storyId = paramMap.get('storyId');
      this.snapShotInit();
    });

    this.slides[0] = {
      src: 'snapshot.SnapshotFileUrl',
    };
    this.getPinterestBoards();
    this.displayProgressSpinner = true;

  }


  
  snapShotInit() {
    this.loading = true
    this.commonService.getUser().subscribe((user: any) => {
      this.id = user?.uid;
    })

    this.commonService.snapshotfileDetais().subscribe(async (snapshot: any) => {
      this.snapShotFileList = snapshot
      var snapShotFilter;
      if (this.userId != undefined && this.userId != null) {
        snapShotFilter = snapshot.filter((snapshotObject) => snapshotObject.UserID == this.userId);
      } else {
        snapShotFilter = snapshot;
      }
      snapShotFilter.forEach(async (snap: any, index) => {
        if (this.storyId != undefined && this.storyId === snap.StoryID) {
          this.topScroll = index * 670;
        }
        let UserProfileObject =  this.userData.find((user)=> user.UserID == snap.UserID )
        let userObjet = this.allUserDetails.find(userObject => userObject.uid == snap.UserID)
        snap.profileName = UserProfileObject.DisplayName != '' || UserProfileObject.DisplayName != undefined  ? UserProfileObject.DisplayName : (userObjet.displayName != undefined ? userObjet.displayName : snap.UserID);
        userObjet.profileName = snap.profileName;
        snap.userData = userObjet
        snap.firstName = snap.profileName.split(" ", 1);
        snap.AvatarLastFive = snap.AvatarID.substr(snap.AvatarID.length - 5);
        // await this.apiHttpService.get(url).subscribe((data: any) => {
        //   //this.profileDetails = data
        //   snap['profileName'] = data.displayName != undefined ? data.displayName : snap.UserID;
        //   this.displayProgressSpinner = false;
        //   snap.firstName =  snap.profileName.split(" ", 1); 
        //   snap.AvatarLastFive =  snap.AvatarID.substr(snap.AvatarID.length - 5);
        // },
        //   ((error) => {
        //     console.log(error);
        //   }
        //   ));
      });

      this.filterSnapShotFileDetails = snapShotFilter
      setTimeout(() => {
        this.loading = false
        this.delayFlag = true;
      }, 1000);

    });

  }
  
  openUserPopup(item:any) {
    const modalRef = this.modal.open(UserPopupComponent,{
      modalDialogClass:"model-garmentPopup"
       });
    modalRef.componentInstance.id = 10;
    modalRef.componentInstance.UserShapShotObject = item;
    modalRef.componentInstance.UserID = item.UserID;
    modalRef.componentInstance.userData = item.userData;
    modalRef.componentInstance.userProfile = 'userProfile';
    modalRef.componentInstance.rootFlag = 'yologram-social/yologram-user-profile-feed';
    modalRef.result.then((result) => {
      console.log(result);
    }).catch((error) => {
      console.log(error);
    });
  }


  openAvatrPopup(item:any) {
    const modalRef = this.modal.open(UserPopupComponent,{
      modalDialogClass:"model-garmentPopup"
       });
    modalRef.componentInstance.id = 10;
    modalRef.componentInstance.UserShapShotObject = item;
    modalRef.componentInstance.UserID = item.UserID;
    modalRef.componentInstance.AvatarID = item.AvatarID;
    modalRef.componentInstance.userData = item.userData;
    modalRef.componentInstance.userProfile = 'userProfile';
    modalRef.componentInstance.rootFlag = 'yologram-social/yologram-user-profile-feed';
    modalRef.result.then((result) => {
      console.log(result);
    }).catch((error) => {
      console.log(error);
    });
  }


  getSnapShotList() {
    this.filterByUserFlag = false;
    this.filterSnapShotFileDetails = this.snapShotFileList;
  }


  // getSnapShotListByUser(snapshot: any) {
  //   let userId = snapshot;
  //   this.filterByUserFlag = true
  //   this.filterSnapShotFileDetails = this.snapShotFileList.filter((snapshot) => snapshot.UserID == userId);
  //   this.dataSharingService.sendUserSnapShots(this.filterSnapShotFileDetails);
  //   this.router.navigate(['yologram-social/yologram-public-news-feed', { 'id': userId }])
  // }

  getSnapShotListByUser(snapshot: any) {
    let userid = snapshot.UserID
    let userId = this.id
    let userObjectForProfile = this.snapShotFileList.filter((snapObject) => snapObject.UserID === userId)
    this.filterSnapShotForProfile = userObjectForProfile
    this.dataSharingService.sendUserSnapShots(userObjectForProfile)
    this.router.navigate(['yologram-social/yologram-user-myprofile', { 'id': snapshot ,'pagetab' :'story'}])
  }

  
  getProfileForUSerFeed(snapshot){
    let userId = this.id
    let userObjectForProfile = this.snapShotFileList.filter((snapObject) => snapObject.UserID === userId)
    this.filterSnapShotForProfile = userObjectForProfile
    this.dataSharingService.sendUserSnapShots(userObjectForProfile)
    this.router.navigate(['yologram-social/yologram-user-myprofile', { 'id': snapshot.UserID ,'pagetab' :'user-story','storyId':snapshot.StoryID}])
  }

  getSnapShotListByUserPopup(snapshot: any) {
    // this.selectSnap = snapshot
    // let userId = snapshot.UserID;
    //  let url = environment.functionBaseUrl + "userProfileIdenifierById?id=" + userId;
    //      this.apiHttpService.get(url).subscribe((data: any) => {
    //       this.profileDetails = data
    //     })
    // this.filterByUserFlag = true
    // this.filterSnapShotForProfile = this.snapShotFileList.filter((snapshot) => snapshot.UserID == userId).slice(0,3);
    // this.dataSharingService.sendUserSnapShots(this.filterSnapShotForProfile);
    this.openUserPopup(snapshot)
  }

  getSnapShotListAvatarPopup(snapshot: any) {
    let userId = snapshot.UserID;
    let avatarId =  snapshot.AvatarID
    this.selectedAvtar = avatarId
     let url = environment.functionBaseUrl + "userProfileIdenifierById?id=" + userId;
         this.apiHttpService.get(url).subscribe((data: any) => {
          this.profileDetails = data
        })
    this.filterByUserFlag = true
    this.filterSnapShotForProfile = this.snapShotFileList.filter((snapshot) => snapshot.AvatarID == avatarId).slice(0,9);
    this.dataSharingService.sendUserSnapShots(this.filterSnapShotForProfile);
  }




  getuserfeed(uid){
    this.filterSnapShotFileDetails = this.snapShotFileList.filter((snapshot) => snapshot.UserID == uid)
  }
  
  getuserfeeduserPopUp(uid){
    this.filterSnapShotFileDetails = this.snapShotFileList.filter((snapshot) => snapshot.UserID == uid.UserID)
  }


  getAvatarfeed(avatar){
    this.filterSnapShotFileDetails = this.snapShotFileList.filter((snapshot) => snapshot.AvatarID == avatar)
  }


  
  getProfileByAvator(snapshot: any) {
    this.filterByUserFlag = true;
    this.filterSnapShotFileDetails = this.snapShotFileList.filter((snapshotlist) => snapshotlist.AvatarID == snapshot);
    const userId =  this.filterSnapShotFileDetails.find((snapshotlist)=> snapshotlist.UserID )
    this.dataSharingService.sendUserSnapShots(this.filterSnapShotFileDetails);
    this.router.navigate(['yologram-social/yologram-public-news-feed', { 'id': userId.UserID, 'avatarID': snapshot }])
  }


  getEnvironmentName(environment){
    let evenListName = "Environments/"
    let env = this.evnFileList.find((snap) => snap.storageURL == evenListName+environment)
    return env != undefined ? env['displayName'] : 'default';

  }

  environmentPopup(environment) {
    let evenListName = "Environments/"
    this.evnFileListFileName = this.evnFileList.find((snap) => snap.storageURL == evenListName+environment)
    let environmentSnapAllfile = this.snapShotFileList.filter((snap) => snap.Environment === environment).slice(0,9)
    this.filterEnvironmentSnpShot = environmentSnapAllfile
    this.evnFileListFileName['envFileName'] = environment

  }

  getEnvironmentProfile(envFileName) {
    let evenListName = "Environments/"
    this.evnFileListFileName = this.evnFileList.find((snap) => snap.storageURL == evenListName+envFileName)
    let environmentSnapAllfile = this.snapShotFileList.filter((snap) => snap.Environment === envFileName)
    this.dataSharingService.sendUserSnapShots(environmentSnapAllfile)
    this.router.navigate(['yologram-social/yologram-env-profile', { 'evnFile': this.evnFileListFileName.displayName }])
  }

  onItemChange($event: any): void {
  }

  handleError(error: any) {
    console.log(error)
  }
  setClothDetails(garmentId: string) {
    if (this.garmentsCollection.length > 0) {
      this.clothDetails = this.garmentsCollection.find((b: any) => b.id === garmentId);
      let brandObject = this.brandsCollection.find((b: any) => b.id === this.clothDetails.BrandID);
      this.clothDetails['BrandName'] = brandObject != undefined ? brandObject.BrandName : '';
      this.clothDetails['LogoURL'] = brandObject != undefined ? brandObject.LogoURL : '';
      let brandGarments = this.garmentsCollection.filter((gaementList) => gaementList.id == this.clothDetails.id );
      this.clocthCollectionIdcollection = brandGarments.map((garment) => garment.id);
      this.clothShapShotsList = this.snapShotFileList.filter((snapshot) => {
        return this.clocthCollectionIdcollection.indexOf(snapshot.BottomwearID) >= 0 ||
          this.clocthCollectionIdcollection.indexOf(snapshot.HeadwearID.trim()) >= 0 ||
          this.clocthCollectionIdcollection.indexOf(snapshot.FootwearID.trim()) >= 0 ||
          this.clocthCollectionIdcollection.indexOf(snapshot.UpperwearID.trim()) >= 0
        }).slice(0, 9);

      this.clothShapShotsListALL = this.snapShotFileList.filter((snapshot) => {
        return this.clocthCollectionIdcollection.indexOf(snapshot.BottomwearID) >= 0 ||
          this.clocthCollectionIdcollection.indexOf(snapshot.HeadwearID.trim()) >= 0 ||
          this.clocthCollectionIdcollection.indexOf(snapshot.FootwearID.trim()) >= 0 ||
          this.clocthCollectionIdcollection.indexOf(snapshot.UpperwearID.trim()) >= 0
        })
        let clothId = this.clothDetails.id
        let filelist = this.userUploadedFileList.filter((uploadlist) => uploadlist.link == clothId).slice(0,2)
        this.userFiledetails = filelist
    }
  }

  setBrandDetails(garmentId: string) {
    if (this.garmentsCollection.length > 0) {
      let grandmentObject = this.garmentsCollection.find((b: any) => b.id === garmentId);
      let brandGarments = this.garmentsCollection.filter((gaementList) => gaementList.BrandID == grandmentObject.BrandID);
      this.brandCollectionIdcollection = brandGarments.map((garment) => garment.id);
      this.brandShapShotsList = this.snapShotFileList.filter((snapshot) => {
        return this.brandCollectionIdcollection.indexOf(snapshot.BottomwearID.trim()) >= 0 ||
          this.brandCollectionIdcollection.indexOf(snapshot.HeadwearID.trim()) >= 0 ||
          this.brandCollectionIdcollection.indexOf(snapshot.FootwearID.trim()) >= 0 ||
          this.brandCollectionIdcollection.indexOf(snapshot.UpperwearID.trim()) >= 0
        }).slice(0, 9);
        if (grandmentObject && grandmentObject['Name'] != undefined) {
          let brandId = grandmentObject["BrandID"];
          this.brandDetails = this.brandsCollection.find((b: any) => b.id === brandId);
          this.brandDetails['boardName'] = 'Not Link to Board';
          let brandBoardObject = this.brandboardCollection.find((b: any) => b.brandId == brandId);
          if (brandBoardObject != undefined) {
            let boardId = brandBoardObject && brandBoardObject['boardId'] != undefined ? brandBoardObject['boardId'] : undefined;
            let boardDetails = this.boards.find((b: any) => b.id == boardId);
            if(boardDetails !=undefined && boardDetails['name'] != undefined )this.brandDetails['boardName'] = boardDetails['name'];
          }
        }
    }
  }

  getGarmentsDetails(garmentId: string) {
    let garmentTitle = "";
    let name = garmentId;
    let brandName = ""
    if (this.garmentsCollection.length > 0) {

      let grandmentObject = this.garmentsCollection.find((b: any) => b.id === garmentId);

      //   return 
      if (grandmentObject && grandmentObject['Name'] != undefined) {
        name = grandmentObject['Name'];
        let brandId = grandmentObject["BrandID"];
        let brandObject = this.brandsCollection.find((b: any) => b.id === brandId);
        brandName = brandObject != undefined && brandObject.BrandName != undefined ? brandObject.BrandName : '';
      }
    }
    garmentTitle = "<a (click)=\"showBrand('12232')\" href=\"showBrand('12232')\" />" + name + "</a>";
    // if(brandName != " ") garmentTitle += "   ( <a data-bs-toggle='modal' data-bs-target='#brand-details' >"+ brandName + " <a/>)";
    return garmentTitle;
  }

  
  getGarmentrealtype(garmentId: string) {
    let garmentTitle = "";
    let name = garmentId;
    let brandName = ""
    if (this.garmentsCollection.length > 0) {
      let grandmentObject = this.garmentsCollection.find((b: any) => b.id === garmentId);
      if (grandmentObject && grandmentObject['RealType'] != undefined) {
        garmentTitle = grandmentObject['RealType'];
      }
    }
    return garmentTitle;
  }

  getGarmentName(garmentId: string) {
    let garmentTitle = "";
    let name = garmentId;
    let brandName = ""
    if (this.garmentsCollection.length > 0) {
      let grandmentObject = this.garmentsCollection.find((b: any) => b.id === garmentId);
      if (grandmentObject && grandmentObject['Name'] != undefined) {
        garmentTitle = grandmentObject['Name'];
      }
    }
    return garmentTitle;
  }

  getBrandName(garmentId: string) {
    let garmentTitle = "";
    let name = garmentId;
    let brandName = ""
    if (this.garmentsCollection.length > 0) {
      let grandmentObject = this.garmentsCollection.find((b: any) => b.id === garmentId);
      if (grandmentObject && grandmentObject['Name'] != undefined) {
        let brandId = grandmentObject["BrandID"];
        let brandObject = this.brandsCollection.find((b: any) => b.id === brandId);
        brandName = brandObject != undefined && brandObject.BrandName != undefined ? brandObject.BrandName : '';
      }
    }
    return brandName;
  }



  showBrand(brand: any) {
    this.selectedBrand = brand;
  }

  getBrandImage(imgURL: string) {
    let brandEncodedurl = encodeURI(imgURL).replace('/', '%2F');
    let url = "https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/" + brandEncodedurl + "?alt=media&token=550e7e88-bed3-4668-84ff-ad491c19e06c"
    return url;
  }
  getPinterestBoards() {
    this.pinterestAPI.getBoardList().subscribe((data: {
      name: any; items: any;
    }) => {
      this.boards = data.items
    });

  }
  selectedBoard($event: any) {
    this.boardId = $event.target.value;
  }

  shareOnPinterest(garmentId: any, type: any, snapshotFileUrl: any, storyID: any) {
    this.displayProgressSpinner = true;
    // this.isLoading = true;
    this.loading = true;
 


    let brandId: any;
    let boardId: any;

    if (this.garmentsCollection.length > 0) {
      let grandmentObject = this.garmentsCollection.find((b: any) => b.id === garmentId);
      if (grandmentObject && grandmentObject['Name'] != undefined) {
        brandId = grandmentObject["BrandID"];
        let brandBoardObject = this.brandboardCollection.find((b: any) => b.brandId == brandId);
        boardId = brandBoardObject && brandBoardObject['boardId'] != undefined ? brandBoardObject['boardId'] : undefined;
      }

    }

    if (boardId == undefined || brandId == '') {
      this.isLoading = false;

      alert("This brand is not linked with Pinterest board.")
      return false;
    }

    let jsonObject = {
      'avatarURL': snapshotFileUrl,
      'boardId': boardId
    }
    let jsonInput = this.pinterstJsonMap
    let storyObject = this.storyPinterestPinsList.find((b: any) => b.StoryID == storyID)

    if (storyObject && storyObject[type] != undefined) {
      let jsonInpt = { 'pinId': storyObject[type], };

      this.pinterestAPI.getPinterestPin(jsonInpt).subscribe((data) => {
        this.displayProgressSpinner = false;
        this.pinImg = data.media.images['400x300'].url;
        this.openModel();
        this.loading = false;
      });
      return true
    }
    this.pinterestAPI.createPinterestPin(jsonObject).subscribe((result) => {
      if (storyObject == undefined) {
        let pinsMappingObject: any = {
          'StoryID': storyID,
        }
        if (type == 'headwear') pinsMappingObject['headwear'] = result.id
        if (type == 'footwear') pinsMappingObject['footwear'] = result.id
        if (type == 'upperwear') pinsMappingObject['upperwear'] = result.id
        if (type == 'headwear') pinsMappingObject['headwear'] = result.id
        this.firestore.collection('pinterest-board-pins-status').add(pinsMappingObject)
      } else if (storyObject && storyObject[type] == undefined) {
        if (type == 'headwear') storyObject['headwear'] = result.id
        if (type == 'footwear') storyObject['footwear'] = result.id
        if (type == 'upperwear') storyObject['upperwear'] = result.id
        if (type == 'headwear') storyObject['headwear'] = result.id
        this.firestore.collection('pinterest-board-pins-status').doc(storyObject['id']).update(storyObject);
      }

      this.isLoading = false;
      this.loading = false;
      alert("Pin published successfully.");
      return true;
    });

    return false;
  }



  openModel() {
    this.isLoading = false;
    const dialogRef = this.dialog.open(UserNewsModelDilog);
    dialogRef.componentInstance.pinImgSrc = this.pinImg;
    dialogRef.afterClosed().subscribe(result => {
      this.isLoading = false;
    });
  }

  linkBrandToBoard() {

    if (this.boardId == "" || this.brandId == '') {
      return false;
    }
    let brandBoardMappingObject = {
      'brandId': this.brandId,
      'boardId': this.boardId
    }
    this.firestore.collection('brand-board').add(brandBoardMappingObject)
    return true;
  }

  ngAfterViewChecked(): void {
    if (this.storyId != undefined && this.snapShotFeeds != undefined && this.snapShotFeeds.nativeElement != undefined && !this.pageScrollFlag) {
      document.getElementById(this.storyId)
      this.snapShotFeeds.nativeElement.scrollTop = this.topScroll;
      this.pageScrollFlag = true
    }
  }


  deleteSnapshotFile(id: any) {
    var confirmation = confirm('Are you sure to delete this file ?');
    if (!confirmation) return;
    this.isLoading = true;
    this.firestore.collection("SnapshotDetails").doc(id).delete().then(() => {
      let filepath = "Snapshots/" + id
      this.storage.ref(filepath).delete();
      this.snapShotInit();
      this.isLoading = false;
    }).catch((error) => {
    });;
  }

  // getBrandStories(brand:any){
  //   this.router.navigate(['yologram-social/yologram-brand-stories',{'brandId':brand}])
  //

  getBrandStories(brand: any) {
    this.router.navigate(['yologram-social/yologram-brand-stories', { 'brandId': brand.id,}])
  }

  getBrandProfile(brand: any, board: any) {
    let brandGarments = this.garmentsCollection.filter((gaementList) => gaementList.BrandID === brand);
    let brandGarmentIds = brandGarments.map((garment) => garment.id);
    this.brandCollectionId = brandGarmentIds
    let brandGarment=  this.brandShapShotsList = this.snapShotFileList.filter((snapshot) => {
      return this.brandCollectionIdcollection.indexOf(snapshot.BottomwearID) >= 0 ||
        this.brandCollectionIdcollection.indexOf(snapshot.HeadwearID.trim()) >= 0 ||
        this.brandCollectionIdcollection.indexOf(snapshot.FootwearID.trim()) >= 0 ||
        this.brandCollectionIdcollection.indexOf(snapshot.UpperwearID.trim()) >= 0
    })
    this.dataSharingService.sendUserSnapShots(brandGarment);
    this.dataSharingService.sendBrandGarments(brandGarmentIds);
    this.router.navigate(['yologram-social/yologram-brand-profile', { 'brandId': brand }])
  }


  
  getClothProfile(brand: any){
    let clothAllDetails = this.clothDetails
    let brandGarments = this.garmentsCollection.filter((gaementList) => gaementList.id == this.clothDetails.id );
    this.clocthCollectionIdcollection = brandGarments.map((garment) => garment.id);
    this.clothShapShotsList = this.snapShotFileList.filter((snapshot) => {
      return this.clocthCollectionIdcollection.indexOf(snapshot.BottomwearID) >= 0 ||
        this.clocthCollectionIdcollection.indexOf(snapshot.HeadwearID.trim()) >= 0 ||
        this.clocthCollectionIdcollection.indexOf(snapshot.FootwearID.trim()) >= 0 ||
        this.clocthCollectionIdcollection.indexOf(snapshot.UpperwearID.trim()) >= 0
      }).slice(0, 9);

      this.clothShapShotsListAll = this.snapShotFileList.filter((snapshot) => {
        return this.clocthCollectionIdcollection.indexOf(snapshot.BottomwearID) >= 0 ||
          this.clocthCollectionIdcollection.indexOf(snapshot.HeadwearID.trim()) >= 0 ||
          this.clocthCollectionIdcollection.indexOf(snapshot.FootwearID.trim()) >= 0 ||
          this.clocthCollectionIdcollection.indexOf(snapshot.UpperwearID.trim()) >= 0
        })
        
      let snanpDetails = this.clothShapShotsListAll
      this.dataSharingService.sendUserSnapShots(snanpDetails);
      this.dataSharingService.sendBrandGarments(this.clocthCollectionIdcollection);
      this.dataSharingService.sendClothDetails(clothAllDetails)
      this.router.navigate(['yologram-social/yologram-garment-profile', { 'brandId': brand }])
  }




  checkImgLimit() {
    this.brandStoriesImgLimit++;
    return true;
  }

  getFeed(brand: any ,brandName:any){
    this.router.navigate(['yologram-social/yologram-brand-stories', { 'brandId':brandName.id,'storyId':brand.StoryID }])
    }
    
    getUserFeedInPopUp(snapshot,brandName){

    }


    getClothFeed(brand: any ,brandName:any){
      this.dataSharingService.sendUserSnapShots(this.clothShapShotsList)
      this.router.navigate(['yologram-social/yologram-brand-stories', { 'brandId':brandName.id,'storyId':brand.StoryID}])
      }
  
    getBrandStyle(brandName:any,gender:any,){
      // this.dataSharingService.sendUserSnapShots(this.clothShapShotsList);
         this.dataSharingService.sendUserSnapShots(this.brandShapShotsList);
      this.router.navigate(['yologram-social/yologram-brand-style', { 'brandId':brandName, 'Gender': gender  }])
    }

    getgarmentStyle(brandName:any,cloths:any){
      this.dataSharingService.sendUserSnapShots(this.clothShapShotsList);
      this.dataSharingService.sendClothDetails(cloths)
      this.router.navigate(['yologram-social/yologram-garment-style', { 'brandId':brandName, 'clothsType': cloths.GarmentType ,'Gender':cloths.Gender,'id':cloths.id }])
    }


    getStoryCloth(brandIds) {
      let snap = this.clothShapShotsList
      this.dataSharingService.sendUserSnapShots(snap)
      this.dataSharingService.sendClothDetails(brandIds)
      let brandid = brandIds.BrandID
      this.router.navigate(['yologram-social/yologram-garment-profile', { 'brandId': brandid, }])
    }
    
    getgarmentfeed(brandName: any, cloths: any) {
      let snap = this.clothShapShotsListALL
      this.dataSharingService.sendUserSnapShots(snap)
      this.dataSharingService.sendClothDetails(cloths)
      this.router.navigate(['yologram-social/yologram-garment-feed', { 'brandId': cloths.BrandID, 'clothsType': cloths.GarmentType, 'Gender': cloths.Gender, 'id': cloths.id }])
    }
    
  openImageBox(fileurl){
    let url = fileurl
    let showImage = this.userFiledetails.find((file)=> file.fileurl == url)
    this.showImages = showImage
   }


   openBrandPoup(garmentId: any) {
    let grandmentObject = this.garmentsCollection.find((b: any) => b.id === garmentId);
    const modalRef = this.modal.open(BrandPopupComponent,{
      modalDialogClass: "model-garmentPopup"
    });
    modalRef.componentInstance.BrandId = grandmentObject.BrandID;
    modalRef.componentInstance.routeFlageBrandPage = '/yologram-social/yologram-brand-stories';
    modalRef.componentInstance.garmentId = garmentId;
    modalRef.result.then((result) => {
    }).catch((error) => {
      console.log(error);
    });
  }
  

  newEnvironmentPopup(environment) {
    this.environmentFileName = environment.Environment
    let reqInputObject = { id: environment, pageNumber: 1 }
    this.commonService.yologramSnapshotEnvironment(reqInputObject).subscribe(async (evn: any) => {
      let environmentSnapAllfileAll = evn.object;
      this.dataSharingService.sendUserEnvSnapShots(environmentSnapAllfileAll);
    })
    let evenListName = "Environments/"
    this.evnFileListFileName = this.evnFileList.find((snap) => snap.storageURL == evenListName + environment)
    let environmentSnapAllfile = this.filterSnapShotFileDetails.filter((snap) => snap.Environment === environment).slice(0, 3)
    this.filterEnvironmentSnpShot = environmentSnapAllfile
    this.evnFileListFileName['envFileName'] = environment
    let evnUrl = evenListName + environment
    this.gltfImgSrc = 'https://firebasestorage.googleapis.com/v0/b/yolouploader.appspot.com/o/userData%2Ffiles%2Fcrystal_ball.glb?alt=media&token=963e0d80-5809-4e1b-9034-caf31fb33945'
    this.openModelevn(evnUrl, environment)

  }

  openModelevn(skyBoXsrc: any, evnName: any) {
    let evnEncodedurl = encodeURI(skyBoXsrc).replace('/', '%2F');
    let evNurl = "https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/" + evnEncodedurl + "?alt=media&token=db5ebcc3-36d0-4651-9250-471d6faf6baf"
    this.twoDprivew = evNurl
    const dialogRef = this.dialog.open(PopupThreeDComponent, {
      panelClass: 'dailog-padding-css'
    });
    this.gltfImgSrc = 'https://firebasestorage.googleapis.com/v0/b/yolouploader.appspot.com/o/userData%2Ffiles%2Fcrystal_ball.glb?alt=media&token=963e0d80-5809-4e1b-9034-caf31fb33945'
    this.skyBoXall = this.twoDprivew
    this.evsname = this.evnFileListFileName['envFileName']
    this.evsdiplaynameALL = this.evnFileListFileName['displayName']
    dialogRef.componentInstance.evsdiplaynameALL = this.evsdiplaynameALL
    dialogRef.componentInstance.evsname = this.evsname
    dialogRef.componentInstance.gltfImgSrc = this.gltfImgSrc;
    dialogRef.componentInstance.skyBoXall = this.skyBoXall;
    dialogRef.componentInstance.hideFlag = this.hideFlag;
    dialogRef.componentInstance.routeFlageEven =   'yologram-social/yologram-env-profile'
    dialogRef.componentInstance.environmentFileName = evnName;
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  
  openGarmentPopup(fileType: any, key: any) {
    const modalRef = this.modal.open(GarmentPopupComponent, {
      modalDialogClass: "model-garmentPopupClass"
    });
    modalRef.componentInstance.id = 10;
    modalRef.componentInstance.key = fileType;
    modalRef.componentInstance.garmentId = key;
    // modalRef.componentInstance.routeFlageEvenGarment = 'yologram-social/yologram-garment-feed'
    modalRef.result.then((result) => {
      console.log(result);
    }).catch((error) => {
      console.log(error);
    });
  }


}


@Component({
  selector: 'user-news-model-viewe',
  templateUrl: './model-viewer-dilog.html',
})
export class UserNewsModelDilog {
  @Input() pinImgSrc: string | undefined;
  constructor(public dialog: MatDialog) {
  }
}

