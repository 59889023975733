import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ApiHttpService } from 'src/app/services/api-http.service';
import { CommonService } from 'src/app/services/common.service';
import { DataSharingService } from 'src/app/services/data-sharing-service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-user-feed',
  templateUrl: './user-feed.component.html',
  styleUrls: ['./user-feed.component.css']
})

export class UserFeedComponent implements OnInit {
  garmentsList = this.firestore.collection('garments').valueChanges({ idField: 'id' }) as Observable<any[]>;

  userId: string;
  userSnapShotList: any;
  profileName: any
  userName: any = []
  userDetails: any
  filterSnapShotFileDetails: any = []
  SnapShotFileDetails: any = []
  garmentsCollection: any = []
  id: any;
  isLoadingforUserDetails: boolean = true;
  profileNameSrc: any = "profile";
  userGarments = []
  brandsIDs: any = []
  avatarId: any
  snapShotFileList: any = []
  avatarIdshide = true
  topScroll: number;
  storyID:any



  constructor(
    private dataSharingService: DataSharingService,
    private route: ActivatedRoute,
    private apiHttpService: ApiHttpService,
    public router: Router,
    private firestore: AngularFirestore,
    private commonService: CommonService


  ) {
  }
  ngAfterViewChecked(): void {

    if (this.storyID != undefined && this.topScroll != undefined && this.userSnapShotList != undefined && this.userSnapShotList.nativeElement != undefined) {
      this.userSnapShotList.nativeElement.scrollTop = this.topScroll;
      // this.pageScrollFlag = true
    }
  }
  ngOnChanges(){    this.dataSharingService.userSnapShotObservable.subscribe(userSnapShots => {
    this.userSnapShotList  = []
    this.userSnapShotList = userSnapShots
  }); }
  ngOnInit(): void {
    this.commonService.snapshotfileDetais().subscribe(async (snapshot: any) => {
      this.snapShotFileList = snapshot
    })
    this.dataSharingService.userSnapShotObservable.subscribe(userSnapShots => {
      this.userSnapShotList  = []
      this.userSnapShotList = userSnapShots
    });
    this.dataSharingService.userSnapShotObservable.subscribe(userSnapShots => {
      this.userGarments = userSnapShots
    })
    this.route.paramMap.subscribe(paramMap => {
      this.userId = paramMap.get('id');
      this.avatarId = paramMap.get('avatarID');
      let url = environment.functionBaseUrl + "userProfileIdenifierById?id=" + this.userId;
      this.apiHttpService.get(url).subscribe((data: any) => {
        this.userDetails = data
        this.isLoadingforUserDetails = false
      })
    });
    this.garmentsList.subscribe(
      garments => {
        this.garmentsCollection = garments;
      });
  }
  getSnapShotListByUser(userId) {
    let userSnapShotList = this.userSnapShotList.filter((snapshot) => snapshot.UserID == userId);
    this.dataSharingService.sendUserSnapShots(userSnapShotList);
    this.router.navigate(['yologram-social/yologram-public-news', { 'id': userId }])
  }
  getSnapShotListavtarid(avatar) {
    let userSnapShotList = this.userSnapShotList.filter((snapshot) => snapshot.AvatarID == avatar);
    this.dataSharingService.sendUserSnapShots(userSnapShotList);
    this.router.navigate(['yologram-social/yologram-public-news', { 'avatarID': avatar }])
  }
  getSelectSnapshotFeed(userSnap) {
    let userId = userSnap.UserID
    let storyid = userSnap.StoryID
    let userSnapShotList = this.userSnapShotList.filter((snapshot) => snapshot.UserID == userId && snapshot.StoryID == storyid);
    this.dataSharingService.sendUserSnapShots(userSnapShotList);
    this.router.navigate(['yologram-social/yologram-user-profile-feed', { 'id': userId, 'storyId': storyid }])
  }

  getUserProfile() {
    let userId = this.userId
    this.dataSharingService.sendUserSnapShots(userId);
    this.router.navigate(['yologram-social/yologram-public-news', { 'id': userId }])
  }

  getBrandProfile(brand: any) {
    let brandGarments = this.garmentsCollection.filter((gaementList) => gaementList.BrandID === brand.id);
    let brandGarmentIds = brandGarments.map((garment) => garment.id);
    this.brandsIDs = brandGarmentIds
    this.dataSharingService.sendUserSnapShots(this.SnapShotFileDetails);
    this.dataSharingService.sendBrandGarments(brandGarmentIds);
    this.router.navigate(['yologram-social/yologram-brand-profile', { 'brandId': brand }])
  }


  getSnapShotListByUserID(UId: any) {
    let userId = UId;
    let snpList = this.snapShotFileList.filter((snapshot) => snapshot.UserID == userId);
    this.userSnapShotList = snpList
    if (this.snapShotFileList != undefined) {
      this.avatarIdshide = false
    }
  }





}
