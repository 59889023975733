<app-yologram-social *ngIf="logInFlag"></app-yologram-social>
<div class="card">
  <div>
    <div class="col-8 p-3 m-2">
      <div class="d-flex">
        <div class="p-3">
          <input type="text" class="form-control inputbtn" placeholder="Add Group" [(ngModel)]="GroupName">
        </div>
        <div class="p-3">
          <button class="btn btn-primary inputbtn" (click)="addSecurityGp()">Add Group</button>
        </div>
      </div>
    </div>
    <table class="table " id="dataTable" width="100%" cellspacing="0">
      <thead class="custom-header">
        <tr role="row">
          <th>Sr. No.</th>
          <th>Group name</th>
          <th>User</th>
          <th>Pages</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody class="justify-content-center">
        <tr class="row-list-detail " *ngFor="let group of securityGroupAll ;let i=index">
          <td>{{i+1}}</td>
          <td class=""> {{group.name}} </td>
          <td>
            <span class="justify-content-center text-center" *ngIf="group.userList != undefined" (click)="showSecGroupUsers(group.userList)"  data-bs-toggle="modal" data-bs-target="#UserNameModalLabel">
              <i  class="bi bi-people-fill m-1"></i>
            </span>
          </td>
          <td> 
            <span class="justify-content-center text-center" *ngIf="group.groupRoleList != undefined" (click)="showGroupRolePages(group.groupRoleList)" data-bs-toggle="modal" data-bs-target="#pageNameModalLabel">
              <i class="bi bi-file-earmark-text-fill "></i>
            </span>  
          </td>
          <td>
            <div class="d-flex action text-center">
              <i class="bi bi-pencil-square edit" (click)="editSecurityGrp(group)" data-bs-toggle="modal"
                data-bs-target="#groupEditModal"></i>
              <div class="form-check form-switch text-center switch">
                <input class="form-check-input" 
                (change)="updateSecGrpActiveStatus($event, group)"
                  type="checkbox" [checked]="group.status == 'A'  ?  true : false" role="switch"
                  id="flexSwitchCheckDefault"
                  [disabled]=" group.groupRoleList   ||  group.userList  ? true : false" 
                  >
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<!-- Button trigger modal -->
<!-- <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#groupEditModal">
    Launch demo modal
  </button> -->

<!-- Modal -->
<div class="modal fade" id="groupEditModal" tabindex="-1" aria-labelledby="groupModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-sm">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="groupModalLabel">Edit Group Name</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <input type="text" class="form-control" placeholder="Add Group" (change)="setEditSecGrpId($event)"
          [value]="editSecurityGroup.name">
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-sm btn-secondary" data-bs-dismiss="modal">Close</button>
        <button type="button" class="btn btn-sm btn-primary" data-bs-dismiss="modal"
          (click)="updateGroupName()">Save</button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="pageNameModalLabel" tabindex="-1" aria-labelledby="pageNameModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-sm">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="pageNameModalLabel">Pages</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div  class="text-center">
          <table>
            <tbody class="justify-content-center" *ngFor="let filterGroupRole of fitlerGroupRoles; let i=index">
              <td>{{i+1}}.</td>
              <td *ngIf="!fitlerGroupRoles">No Pages Avalable In This Group</td>
              <td>{{this.filterGroupRole.PageName}}</td>
            </tbody>
          </table>
        </div>
      </div>
      <!-- <div class="modal-footer">
        <button type="button" class="btn btn-sm btn-secondary" data-bs-dismiss="modal">Close</button>
        <button type="button" class="btn btn-sm btn-primary" data-bs-dismiss="modal">Save</button>
      </div> -->
    </div>
  </div>
</div>

<div class="modal fade" id="UserNameModalLabel" tabindex="-1" aria-labelledby="UserNameModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-sm">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="UserNameModalLabel">Users</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div  class="">
          <table class="">
            <tbody class="" *ngFor="let GroupRoleUesr of fitlerGroupUser; let i=index">
              <td>{{i+1}}.</td>
              <td>{{GroupRoleUesr.profileName}}</td>
            </tbody>
          </table>
        </div>
      </div>
      <!-- <div class="modal-footer">
        <button type="button" class="btn btn-sm btn-secondary" data-bs-dismiss="modal">Close</button>
        <button type="button" class="btn btn-sm btn-primary" data-bs-dismiss="modal">Save</button>
      </div>
    </div> -->
  </div>
</div>