<div *ngIf="loading" class="loading">&#8230;</div>
<app-yologram-social *ngIf="logInFlag"></app-yologram-social>
<div class="card">
  <div class="dt-responsive selectlist font-14">
    <div class="dataTables_wrapper dt-bootstrap4" id="add-row-table_wrapper">
      <div class="p-3 d-flex">
        <div class="p-3">
          <select (change)="selectSecGroup($event)" class="form-select  width-select">
            <option value="">Select Group</option>
            <option [value]="group.id" *ngFor="let group of securityGroupAll ; let i=index">{{group.name}}</option>
          </select>
        </div>
      </div>
      <div class="col-8 p-3 m-2">
        <table class="table " *ngIf="brandList.length > 0" id="dataTable" width="100%" cellspacing="0">
          <thead class="custom-header">
            <tr role="row">
              <th>Sr. No.</th>
              <th>Brand Logo</th>
              <th>Brand Name</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr class="row-list-detail" *ngFor="let brand of brandList ;let i=index">
              <td>{{i+1}}</td>
              <td class="">
                <img class="m-1" style="height:30px !important; width:30px !important;"
                  [src]="getBrandImage(brand.LogoURL)" />
              </td>
              <td class=""> {{brand.BrandName}} </td>
              <td class="catalog-info ">
                <div class="form-check" *ngIf="selectedSecGrpId != undefined">
                  <input class="form-check-input m-1" value="" id="flexCheckDefault"
                    style="box-shadow: 0 0 0 0.5px black; /* Solid border look */"
                    (change)="updateBrandStatus($event,this.getBrandRoleId(brand.id),brand)" type="checkbox"
                    [checked]="this.getBrandRoleId(brand.id) != undefined ? true : false">
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>