import { Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as JSZip from 'jszip/dist/jszip'
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { Brand } from 'src/app/models/brand';
import { CommonService } from 'src/app/services/common.service';
import { environment } from 'src/environments/environment';
import { ApiHttpService } from 'src/app/services/api-http.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DataSharingService } from 'src/app/services/data-sharing-service';
import { PopupTwoDComponent } from 'src/app/common/popup-two-d/popup-two-d.component';
import { MatDialog } from '@angular/material/dialog';
import { PopupThreeDComponent } from 'src/app/common/popup-three-d/popup-three-d.component';
import { PinterestService } from 'src/app/services/pinterest-service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BrandPopupComponent } from 'src/app/common/templets/brand-popup/brand-popup.component';
import { GarmentPopupComponent } from 'src/app/common/templets/garment-popup/garment-popup.component';
import { TwoDpopupComponent } from 'src/app/common/templets/twoDimagePopUp/twoDpopup';
import { Title } from '@angular/platform-browser';
import { isSignInWithEmailLink } from 'firebase/auth';


@Component({
  selector: 'app-public-garment-feed',
  templateUrl: './public-garment-feed.component.html',
  styleUrls: ['./public-garment-feed.component.css']
})
export class PublicGarmentFeedComponent implements OnInit {
  sticky: boolean = false;
  windowScroll: any
  @Input() routLogInFlag: boolean | undefined;
  @ViewChild('brandStoriyScroll', { read: ElementRef }) public BrandStoriyScroll: ElementRef<any>;
  @ViewChild('garmentsnapShotFeeds', { read: ElementRef }) public garmentsnapShotFeeds: ElementRef<any>;
  @ViewChild('garmentDummySCroll', { read: ElementRef }) public garmentDummySCroll: ElementRef<any>;
  @ViewChild('scrollContaineSlider') scrollContaineSlider: ElementRef;


  // @HostListener('window:scroll', ["$event"]) onScroll(event: Event) {
  //   let window = event.currentTarget as Window;
  //   console.log(window.scrollY);
  //   this.sticky = window.scrollY >= 40;
  //   this.windowScroll = window.scrollY
  // }
  // @HostListener('window:scroll', ['$event']) 
  // @ViewChild("garmentsnapShotFeeds") garmentsnapShotFeeds?: ElementRef;
  
  userFiles = this.firestore.collection("userfiles");
  userFileStateChange = this.userFiles.valueChanges();
  userFilesList = this.firestore.collection('userfiles').valueChanges({ idField: 'id' }) as Observable<any[]>;
  brandsCollection = this.firestore.collection('brands').valueChanges({ idField: 'id' }) as Observable<Brand[]>;
  brandsFilesList = this.firestore.collection('brands').valueChanges({ idField: 'id' }) as Observable<Brand[]>;
  brandsCollections = this.firestore.collection('brands').valueChanges({ idField: 'id' }) as Observable<Brand[]>;
  brandBoardList = this.firestore.collection('brand-board').valueChanges({ idField: 'id' }) as Observable<any[]>;
  garmentsList = this.firestore.collection('garments').valueChanges({ idField: 'id' }) as Observable<any[]>;
  environmentCollection = this.firestore.collection('Environments').valueChanges({ idField: 'id' }) as Observable<Brand[]>;
  userGarmentCollection = this.firestore.collection('garment-collection').valueChanges({ idField: 'id' }) as Observable<any[]>;
  userGarmentCollectionItems = this.firestore.collection('garment-collection-items').valueChanges({ idField: 'id' }) as Observable<any[]>;

  displayedColumns: string[] = ['position', 'name', 'weight', 'symbol'];
  public text = `{ "text": "This is text file!中文" }`;
  public fileName: string | undefined;
  //zipFiles: Observable<ZipFile[]>;
  zipFile: any;
  zipList: any = {};
  binaryImg: any;
  testVar: any = 'Prashatn';
  keyvalue: any;
  boardId: any;
  brands: any;
  womensBrands: any;
  mensBrands: any;
  packJsonObject = []
  isShowDiv = false;
  hFiles: any[];
  IdBrands: Brand[];
  brandIDDeatils: any;
  womensBrandId: any;
  womensBrandss: Brand[];
  bName: any;
  userFiledetails: any = []
  counter: number = 2;
  next: number = 1;
  selectedGender: any = "";
  selectedBrandId: any = "";
  selectedBrand: any;
  errMessage: any = "Select The Brand ";
  isloading: boolean = true;
  userUploadedAvatars: any;
  loading = false;
  brandId: any
  gender: any
  brandDetails: any
  boardName: any
  userSnapShotList: any = [];
  clothType: any;
  clothId: any
  clothDetailsList: any = {}
  userUploadedFileList: any = [];
  showImages: any = {}
  gltfImgSrc = ''
  selectedTab: any = "story"
  profileDetails: any = {}
  evnFileList: any = [];
  evnFileListFileName: any
  filterEnvironmentSnpShot: any = [];
  selectedAvtar: any
  garmentsCollection: any = [];
  clothDetails: any = {};
  clocthCollectionIdcollection: any = []
  brandsCollectionall: any = [];
  clothShapShotsList: any = []
  brandboardCollection: any = []
  boards: any;
  brandCollectionIdcollectionAll: any = {};
  brandCollectionIdcollection: any = []
  brandhShapShotsList: any = []
  brandhShapShotsListgarmentPopup: any = []
  SnapShotFileDetails: any = []
  filterSnapShotForProfile: any = []
  filterSnapShotFileDetails: any = []
  filterByUserFlag: boolean = false;
  scrollFlag: boolean = false;
  toggaleFlag: boolean = true;
  brandGarmentIds: any = []
  topScroll: number;
  cloct: any = {}
  pageScrollFlag: boolean = false;
  storyID: any
  stroyIdAll: any
  avatarSnapShotList: any = []
  userFiledetails3d: any = []
  key: any
  brandName: any
  allUserDetails: any = {}
  scroll: boolean = false;
  pageScroll: number;
  thresholdHeightPagination: number = 0
  heartFlag: boolean = false
  selectedStoryId: any;
  tab: string;
  selectedIcon: any;
  iconselctFlag: boolean = false;
  blobimage: any;
  style: string = 'style';
  pagecount: number;
  isContentToggled: boolean = true;
  nonEditedContent: string;
  completeWords: boolean = false;
  showMore: boolean = false;
  pageNumber: number = 0;
  collectionData: any = [];
  collectionDetails: any = [];
  batchSize = 10;
  externalUrl: string;
  images = [
    { "image": "./assets/garment-upload-dummy/img-1.jpg" },
    { "image": "./assets/garment-upload-dummy/img-2.jpg" },
    { "image": "./assets/garment-upload-dummy/img-3.jpg" },
    { "image": "./assets/garment-upload-dummy/img-4.jpg" },
    { "image": "./assets/garment-upload-dummy/img-5.jpg" },
    { "image": "./assets/garment-upload-dummy/img-6.jpg" },
    { "image": "./assets/garment-upload-dummy/img-7.jpg" },
    { "image": "./assets/garment-upload-dummy/img-8.jpg" },
    { "image": "./assets/garment-upload-dummy/img-9.jpg" },
  ]
  images2 = [
    { "image": "./assets/garment-upload-dummy/img-1.jpg" },
    { "image": "./assets/garment-upload-dummy/img-2.jpg" },
    { "image": "./assets/garment-upload-dummy/img-3.jpg" },
    { "image": "./assets/garment-upload-dummy/img-4.jpg" },
    { "image": "./assets/garment-upload-dummy/img-5.jpg" },
    { "image": "./assets/garment-upload-dummy/img-6.jpg" },
    { "image": "./assets/garment-upload-dummy/img-7.jpg" },
    { "image": "./assets/garment-upload-dummy/img-8.jpg" },
    { "image": "./assets/garment-upload-dummy/img-9.jpg" },
  ]





  dummyText: any ={};
  urlfind: string;
  zipUrl: any;
  urlNameSecond: SafeResourceUrl;
  urlRout: any;
  buttonText: string = '+';
  isPlus: boolean = true;
  logInFlag: boolean;
  classWhite :string = 'text-white'


  // dummyText:string = ''
  // line1 = `Your brand's apparel is digitised in 3D.`
  // line2= `On a gamified experience, consumers style avatars & share animated avatar stories (linked to ecommerce)`
  // line3=`Defining a new paradigm in immersive storytelling for social media & the metaverse`


  constructor(public commonService: CommonService,
    private router: Router,
    private dataSharingService: DataSharingService,
    private apiHttpService: ApiHttpService,
    private firestore: AngularFirestore,
    public sanitizer: DomSanitizer,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private pinterestAPI: PinterestService,
    private modal: NgbModal,
    private elementRef: ElementRef,
    private titleService: Title,
    private _httpClient: HttpClient //, //  private _FileSaverService: FileSaverService
    
  ) {
    this.route.paramMap.subscribe(paramMap => {
      this.clothId = paramMap.get('id')
    })
    this.garmentsList.subscribe(
      garments => {
        this.garmentsCollection = garments;
        this.clothDetailsList = this.garmentsCollection.find((b: any) => b.id == this.clothId);
      });
    // this.downloadZipFile();
    this.brandsCollection.subscribe(
      (brandList) => {
        this.brands = brandList;
        this.brandDetails = this.brands.find((brandList) => brandList.id === this.selectedBrandId)
      });

      const text = "Your brand's apparel is digitised in 3D.\n\nOn a gamified experience, consumers style avatars & share\n\nanimated avatar stories (linked to ecommerce)\n\nDefining a new paradigm in immersive storytelling for social media & the metaverse";

      const lines = text.split('\n\n');
      this.dummyText = lines[0]+'\n'+ lines[1]+'\n'+lines[2]+'\n'+lines[3]
 
    
    // this.dataSharingService.userclothDetailsListObservable.subscribe(cloth => {
    //   this.clothDetailsList = cloth
    // })


    this.dataSharingService.userfilesObservable.subscribe(cloth => {
      this.userFiledetails = cloth
    })

    this.userFilesList.subscribe((userFile) => {
      this.userUploadedFileList = userFile
      let clothId = this.clothDetailsList
      let filelist2d = this.userUploadedFileList.filter((uploadlist) => uploadlist.link == clothId.id && uploadlist.fileType == 'GarmentUpload2D')
      let filelist3d = this.userUploadedFileList.filter((uploadlist) => uploadlist.link == clothId.id && uploadlist.fileType == 'GarmentUpload3D')
      this.userFiledetails = filelist2d
      this.userFiledetails3d = filelist3d
    })


    this.environmentCollection.subscribe(
      (evnFile) => {
        this.evnFileList = evnFile
      });


    this.brandsFilesList.subscribe((brands) => {
      this.brandsCollectionall = brands;
      let brandIDforProfile = this.brands.find((brandListProfile) => brandListProfile.id == this.brandId)
      let brandIDforName = this.brands.find((brandListProfile) => brandListProfile.id == this.clothDetailsList.BrandID)
      this.brandName = brandIDforName
    });

    this.brandBoardList.subscribe(
      brandboard => {
        this.brandboardCollection = brandboard;
      });
    this.commonService.snapshotfileDetais().subscribe(async (snapshot: any) => {
      this.SnapShotFileDetails = snapshot;
    })

    this.userGarmentCollection.subscribe((collectiondata) => {
      this.collectionData = collectiondata
    })
    
    this.userGarmentCollectionItems.subscribe((collectiondata) => {
      this.collectionDetails = collectiondata
    })

    this.logInFlag = this.commonService.isLoggedIn()

  }


  ngAfterViewChecked(): void {
    this.changeTitleOnClick()
  }
  ngOnInit() {
    this.thresholdHeightPagination = 0;
    // window.addEventListener('scroll', this.scrolling)
    this.route.paramMap.subscribe(paramMap => {
      this.isloading = false
      this.boardName = paramMap.get('board')
      this.selectedBrandId = paramMap.get('brandId')
      this.selectedGender = paramMap.get('Gender')
      this.clothType = paramMap.get('clothsType')
      this.clothId = paramMap.get('id')
      this.key = paramMap.get('key')
      this.stroyIdAll = paramMap.get('id')
      this.tab = paramMap.get('tab')
      this.downloadZipFile()
      this.getPinterestBoards();
      this.inItLink()
      if (this.selectedBrandId == undefined) {
        this.errMessage = ''
      }
      if (this.tab == 'Menswear' || this.tab == 'story') {
        this.toggaleFlag = false
        this.selectedStoryId = ''
      }
      if (this.tab == 'Menswears') {
        this.toggaleFlag = true
        this.selectedStoryId = ''
      }
      // if (this.tab == 'Menswears') {
      //   this.selectedTab = this.tab
      //   this.iconselctFlag = true
      //   this.toggaleFlag = true
      //   setTimeout(() => {
      //     let blob = this.zipList[this.stroyIdAll]
      //     console.log('blob', blob)
      //     console.log('blob', this.zipList)
      //     // this.findForthreeDfile()
      //   },
      //     2000);
      // }
     

      const text = "Your brand's apparel is digitised in 3D.\n\n On a gamified experience, consumers style avatars & share\n\n animated avatar stories (linked to ecommerce)\n\n Defining a new paradigm in immersive storytelling for social media & the metaverse";

      const lines = text.split('\n\n');
          })

    this.selectedTab = this.tab
    this.commonService.getAllUser().subscribe(async (allUsers: any) => {
      this.allUserDetails = allUsers.users;
      this.snapinit()
      
    })



 
  }

  ngOnChanges(changes): void {
  }



  changeTitleOnClick() {
    if (this.selectedTab == 'Menswear' || this.tab == 'Menswear' )  {
      let title = this.clothDetailsList.Name
      this.commonService.changeTitle(title);
    }if((this.selectedTab == 'Menswears' || this.tab == 'Menswears' )) {
      let title = this.clothDetailsList.Name + ' 360 view'
      this.commonService.changeTitle(title);
    }
  }


  scrollHandler() {
    this.scrollFlag = true
    this.pageScroll = this.garmentsnapShotFeeds.nativeElement.scrollTop
    this.selectedStoryId = ''
    setTimeout(() => {
      if ((this.scrollFlag == true && this.pageScroll > 100) || this.selectedStoryId != undefined) {
        this.selectedStoryId = ''
      }
    },
      0);
    let page = window.scrollY

  }
  setPageNumber(pageNumber) {
    this.pageNumber = pageNumber
  }

  snapinit() {
    this.pageNumber = 1;
    let reqInputObject = { fileType: this.key, id: this.clothId, pageNumber: this.pageNumber }
    this.commonService.yologramSnapshotDetailsByType(reqInputObject).subscribe(async (response: any) => {
      this.userSnapShotList = response.object
      this.pagecount = response.count
      this.userSnapShotList.forEach(async (snap: any, index) => {
        let userObjet = this.allUserDetails.find(userObject => userObject.uid == snap.UserID)
        snap.profileName = userObjet.displayName != undefined ? userObjet.displayName : snap.UserID;
        userObjet.profileName = snap.profileName;
        snap.userData = userObjet
        snap.firstName = snap.profileName.split(" ", 1);
        snap.AvatarLastFive = snap.AvatarID.substr(snap.AvatarID.length - 5);
      });
    })
  }


  getPinterestBoards() {
    this.pinterestAPI.getBoardList().subscribe((data: {
      name: any; items: any;
    }) => {
      this.boards = data.items
    });

  }





  getStyleForGender(brand: any, gendar: string) {
    let snap = this.userSnapShotList
    this.dataSharingService.sendUserSnapShots(snap)
    this.selectedBrandId = brand.id;
    this.selectedGender = gendar
    this.errMessage = ''
    this.downloadZipFile()
  }
  getStoryForGender(brandIds) {
    let snap = this.userSnapShotList
    this.dataSharingService.sendUserSnapShots(snap)
    let brandid = brandIds.id
    this.router.navigate(['public/public-brand-profile', { 'brandId': brandid, }])
  }



  getGarmentrealtype(garmentId: string) {
    let garmentTitle = "";
    let name = garmentId;
    let brandName = ""
    if (this.garmentsCollection.length > 0) {
      let grandmentObject = this.garmentsCollection.find((b: any) => b.id === garmentId);
      if (grandmentObject && grandmentObject['RealType'] != undefined) {
        garmentTitle = grandmentObject['RealType'];
      }
    }
    return garmentTitle;
  }











  createImageFromBlob(image: Blob) {
    let reader = new FileReader();
    reader.addEventListener(
      'load',
      () => {
        this.binaryImg = reader.result;
      },
      false
    );
    if (image) {
      reader.readAsDataURL(image);
    }
  }

  async getZipFileContent(fileName: any) {
    var binary = await this.zipFile
      .file(fileName)
      .async('blob')
      .then(function (text: any) {
        return text;
      });
    this.binaryImg = URL.createObjectURL(binary); // create an url. img.src = URL.
  }

  downloadZipFile() {
    this.isloading = true;
    this.testVar = 'Change';
    let brandname = this.selectedBrand;;
    let brandId = this.selectedBrandId
    let zipfilename = "%2Fpack0"
    let gender = this.selectedGender;
    let packlocation = "AvatarBlenderClothes%2F" + brandId + "_" + gender + zipfilename;
    let brandEncodedurl = packlocation
    let url = 'https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/' + brandEncodedurl + '?alt=media&token=46df48b9-1f7a-4b61-98c6-bff029734da4'
    this.zipUrl = url
    this._httpClient
      .get(
        url,
        //        'https://firebasestorage.googleapis.com/v0/b/yolouploader.appspot.com/o/AvatarBlenderClothes%2F6XE3yz64QMFyblSyIHpf_M%2FtestforyoloUpload?alt=media&token=0738d062-2c7c-4ca1-8300-5963dba8f93a',
        {
          observe: 'response',
          responseType: 'blob',
        }

      )
      .subscribe(async (res) => {
        await this.processZipFile(res.body, this.processResponse.bind(this));
        this.isloading = false;
      },
        (error) => this.handleError(error)
      );

  }

  handleError(error) {
    this.packJsonObject = [];
    this.isloading = false;
    this.errMessage = "Pack is not found for this selection !!!"
    this.isloading = false;

  }

  processResponse(res: any[]) {
    this.clothId
    this.packJsonObject = res;
    this.errMessage = "";

  }

  processZipFile(data, callBackSetJsonObject) {
    console.log("Zip data",callBackSetJsonObject)
    this.testVar = 'Change 121';
    this.zipList = JSZip.loadAsync(data, callBackSetJsonObject, this.testVar)
      .then(function (zip) {
        /* Parse json file */
        var jsonExtn = /(.json)$/;
        Object.keys(zip.files)
          .filter((fileName) => {
            return jsonExtn.test(fileName.toLowerCase());
          })
          .map((jsonFileName) => {
            var jsonFile = zip.files[jsonFileName];
            return jsonFile.async('string').then((jsonString) => {
              let packJsonObject = JSON.parse(jsonString);

              let jsonResponseData =
                [
                  {
                    title: 'headgear',
                    value:
                    {
                      'files': packJsonObject['hFiles'].filter((data) => data != ''),
                      'name': packJsonObject['hName'].filter((data) => data.indexOf('deleted') < 0)
                    }
                  },
                  {
                    title: 'upperwear',
                    value: {
                      'files': packJsonObject['uFiles'].filter((data) => data != ''),
                      'name': packJsonObject['uName'].filter((data) => data.indexOf('deleted') < 0),

                    }
                  },
                  {
                    title: 'bottomwear',
                    value: {
                      'files': packJsonObject['bFiles'].filter((data) => data != ''),
                      'name': packJsonObject['bName'].filter((data) => data.indexOf('deleted') < 0)
                    }
                  },
                  {
                    title: "footwear",
                    value: {
                      'files': packJsonObject['fFiles'].filter((data) => data != ''),
                      'name': packJsonObject['fName'].filter((data) => data.indexOf('deleted') < 0)
                    },
                  }
                ]
              callBackSetJsonObject(jsonResponseData);

            });

          });
        var re = /(.jpg|.png|.gif|.ps|.jpeg | .json)$/;
        var promises = Object.keys(zip.files)
          .filter(function (fileName) {
            // don't consider non image files
            return re.test(fileName.toLowerCase());
          })
          .map(function (fileName) {
            var file = zip.files[fileName];

            if (fileName.indexOf('.json') > 0) {

            } else {
              return file.async('blob').then(function (blob) {
                return [
                  fileName.substring(0, fileName.lastIndexOf('.')), // keep the link between the file name and the content
                  URL.createObjectURL(blob), // create an url. img.src = URL.createObjectURL(...) will work
                ];
              });
            }
          });
        // `promises` is an array of promises, `Promise.all` transforms it
        // into a promise of arrays
        return Promise.all(promises);
      })
      .then(function (result) {
        // we have here an array of [fileName, url]
        // if you want the same result as imageSrc:
        return result.reduce(function (acc, val) {
          acc[val[0]] = val[1];
          return acc;
        }, {});
      })
      .then((result) => {
        this.testVar = 'Prasahtn Rahulkar';
        this.zipList = JSON.parse(JSON.stringify(result));
        // callback(result);
        return;
        //return result.reduce(...);
      })
      .catch(function (e) {
        console.error(e);
      });
  }

  returnZero() {
    return 0
  }


  findForthreeDfile
    () {
    let type = this.clothType
    let key = this.key
    let blob = this.zipList[this.stroyIdAll]
    this.blobimage = blob
    let id = this.clothId
    this.open2DModelPopUp(blob, type, id, key)
  }



  getBrandImage(imgURL: string) {
    let brandEncodedurl = encodeURI(imgURL).replace('/', '%2F');
    let url = "https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/" + brandEncodedurl + "?alt=media&token=550e7e88-bed3-4668-84ff-ad491c19e06c"
    return url;
  }


  showBrandPackDetails(brand: any, gendar: string) {
    this.selectedBrandId = brand.id;
    this.selectedGender = gendar
    this.selectedBrand = brand;
    this.errMessage = ""
    this.downloadZipFile()
  }

  // selectBrandForScroll(){
  //   document.querySelector('#target').scrollIntoView({ behavior: 'smooth', block: 'center' });
  // }

  selectGender($event) {
    this.selectedGender = $event.target.value
    this.isShowDiv = !this.isShowDiv;
  }
  getBrandStories(brand) {
    this.router.navigate(['public/public-brand-stories', { 'brandId': brand }])
  }
  getBrandStyle(cloths: any) {
    this.dataSharingService.sendUserSnapShots(this.userSnapShotList);
    this.router.navigate(['public/public-garment-style', { 'brandId': cloths.BrandID, 'clothsType': cloths.GarmentType, 'Gender': cloths.Gender, 'id': cloths.id }])
  }


  getBrandStylegender(cloths: any, gender: any) {
    this.dataSharingService.sendUserSnapShots(this.userSnapShotList);
    this.router.navigate(['public/public-garment-style', { 'brandId': cloths.BrandID, 'clothsType': cloths.GarmentType, 'Gender': gender, 'id': cloths.id }])
  }
  // open2DModelPopUp(garmetBinaryImg, garmentType: any) {

  //   let garmentImg = this.sanitizer.bypassSecurityTrustUrl(garmetBinaryImg)
  //   const dialogRef = this.dialog.open(PopupTwoDComponent, {
  //     panelClass: 'dailog-transparent-css'
  //   });
  //   dialogRef.componentInstance.garmentImg = garmentImg;
  //   dialogRef.componentInstance.garmentType = garmentType;
  //   dialogRef.afterClosed().subscribe(result => {
  //   });
  // }
  open2DModelPopUp(garmetBinaryImg, garmentType: any, garmentId, typeWear) {
    // this.findForthreeDfile()
    let garmentImg = this.sanitizer.bypassSecurityTrustUrl(garmetBinaryImg)
    const dialogRef = this.dialog.open(PopupTwoDComponent, {
      panelClass: 'dailog-transparent-css'
    });
    dialogRef.componentInstance.garmentImg = garmentImg;
    dialogRef.componentInstance.garmentType = garmentType;
    dialogRef.componentInstance.pageType = "garment";
    dialogRef.componentInstance.garmentId = garmentId;
    dialogRef.componentInstance.typeWear = typeWear;
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  openImageBox(fileurl) {
    let url = fileurl
    let showImage = this.userFiledetails.find((file) => file.fileurl == url)
    this.showImages = showImage
  }


  incrementImgCounter() {
    this.counter = this.counter + 2;
  }



  getStory() {
    if (this.selectedTab != "Menswears") {
      this.selectedTab = "Menswears"
      this.selectedIcon = ''
      this.iconselctFlag = true
      this.toggaleFlag = true
      // this.findForthreeDfile()
      this.changeTitleOnClick()

    }
  }
  getStyle() {
    this.selectedIcon = ''
    this.iconselctFlag = false
    this.selectedTab = "Menswear"
    this.toggaleFlag = false
    this.changeTitleOnClick()
  }








  setClothDetails(garmentId: string) {
    if (this.garmentsCollection.length > 0) {
      let grandmentObject = this.garmentsCollection.find((b: any) => b.id === garmentId);
      this.clothDetails = this.garmentsCollection.find((b: any) => b.id === garmentId);
      let brandObject = this.brandsCollectionall.find((b: any) => b.id === this.clothDetails.BrandID);
      this.clothDetails['BrandName'] = brandObject != undefined ? brandObject.BrandName : '';
      this.clothDetails['LogoURL'] = brandObject != undefined ? brandObject.LogoURL : '';
      let brandGarments = this.garmentsCollection.filter((gaementList) => gaementList.id == this.clothDetails.id);
      this.clocthCollectionIdcollection = brandGarments.map((garment) => garment.id);
      this.clothShapShotsList = this.userSnapShotList.filter((snapshot) => {
        return this.clocthCollectionIdcollection.indexOf(snapshot.BottomwearID.trim()) >= 0 ||
          this.clocthCollectionIdcollection.indexOf(snapshot.HeadwearID.trim()) >= 0 ||
          this.clocthCollectionIdcollection.indexOf(snapshot.FootwearID.trim()) >= 0 ||
          this.clocthCollectionIdcollection.indexOf(snapshot.UpperwearID.trim()) >= 0
      }).slice(0, 9);

      if (grandmentObject && grandmentObject['Name'] != undefined) {
        let grandmentObject = this.garmentsCollection.find((b: any) => b.id === garmentId);
        let brandId = grandmentObject["BrandID"];
        this.brandDetails = this.brandsCollectionall.find((b: any) => b.id === brandId);
        this.brandDetails['boardName'] = 'Not Link to Board';
        let brandBoardObject = this.brandboardCollection.find((b: any) => b.brandId == brandId);
        if (brandBoardObject != undefined) {
          let boardId = brandBoardObject && brandBoardObject['boardId'] != undefined ? brandBoardObject['boardId'] : undefined;
          let boardDetails = this.boards.find((b: any) => b.id == boardId);
          // this.brandDetails['boardName'] = boardDetails['name'];
          if (boardDetails != undefined && boardDetails['name'] != undefined) this.brandDetails['boardName'] = boardDetails['name'];
        }
      }
      let clothId = this.clothDetails.id
      let filelist = this.userUploadedFileList.filter((uploadlist) => uploadlist.link == clothId).slice(0, 2)
      this.userFiledetails = filelist
    }

  }






  setBrandDetails(garmentId: string) {
    if (this.garmentsCollection.length > 0) {
      let grandmentObject = this.garmentsCollection.find((b: any) => b.id === garmentId);
      let brandGarments = this.garmentsCollection.filter((gaementList) => gaementList.BrandID === grandmentObject.BrandID);
      this.brandCollectionIdcollection = brandGarments.map((garment) => garment.id);
      this.brandhShapShotsListgarmentPopup = this.SnapShotFileDetails.filter((snapshot) => {
        return this.brandCollectionIdcollection.indexOf(snapshot.BottomwearID.trim()) >= 0 ||
          this.brandCollectionIdcollection.indexOf(snapshot.HeadwearID.trim()) >= 0 ||
          this.brandCollectionIdcollection.indexOf(snapshot.FootwearID.trim()) >= 0 ||
          this.brandCollectionIdcollection.indexOf(snapshot.UpperwearID.trim()) >= 0
      }).slice(0, 9);
      if (grandmentObject && grandmentObject['Name'] != undefined) {
        let brandId = grandmentObject["BrandID"];
        this.brandDetails = this.brandsCollectionall.find((b: any) => b.id === brandId);
        this.brandDetails['boardName'] = 'Not Link to Board';
        let brandBoardObject = this.brandboardCollection.find((b: any) => b.brandId == brandId);
        if (brandBoardObject != undefined) {
          let boardId = brandBoardObject && brandBoardObject['boardId'] != undefined ? brandBoardObject['boardId'] : undefined;
          let boardDetails = this.boards.find((b: any) => b.id == boardId);
          this.brandDetails['boardName'] = boardDetails['name'];
          if (boardDetails != undefined && boardDetails['name'] != undefined) this.brandDetails['boardName'] = boardDetails['name'];
        }
      }
    }

  }

  getBrandName(garmentId: string) {
    let garmentTitle = "";
    let name = garmentId;
    let brandName = ""
    if (this.garmentsCollection.length > 0) {
      let grandmentObject = this.garmentsCollection.find((b: any) => b.id === garmentId);
      if (grandmentObject && grandmentObject['Name'] != undefined) {
        let brandId = grandmentObject["BrandID"];
        let brandObject = this.brandsCollectionall.find((b: any) => b.id === brandId);
        brandName = brandObject != undefined && brandObject.BrandName != undefined ? brandObject.BrandName : '';
      }
    }
    return brandName;
  }













  scrollToStory(snap) {
    // this.selectedStoryId = ''
    this.scrollFlag = false
    this.selectedTab = "story"
    this.selectedStoryId = snap.StoryID
    this.iconselctFlag = false
    this.toggaleFlag = false
    this.selectedIcon = ''
  }


  openBrandPoup() {
    let grandmentObject = this.garmentsCollection.find((b: any) => b.id === this.clothId);
    // this.dataSharingService.sendUserSnapShots(this.brandhShapShotsList)
    const modalRef = this.modal.open(BrandPopupComponent,{
      modalDialogClass: "model-garmentPopup"
    });
    modalRef.componentInstance.BrandId = grandmentObject.BrandID;
    modalRef.componentInstance.garmentId = this.clothId;
    // modalRef.componentInstance.routeFlageBrandPage = this.brandPageLink;
    modalRef.result.then((result) => {
      console.log(result);
    }).catch((error) => {
      console.log(error);
    });
  }
  open2dPoup(boxImage) {
    const modalRef = this.modal.open(TwoDpopupComponent, {
      modalDialogClass: "model-garmentPopup"
    });
    modalRef.componentInstance.boxImage = boxImage;
    modalRef.result.then((result) => {
      console.log(result);
    }).catch((error) => {
      console.log(error);
    });
  }







  // nextButtonForGarment() {
  //   this.pageNumber++
  //   let reqInputObject = { fileType: this.key, id: this.clothId, pageNumber: this.pageNumber }
  //   this.commonService.yologramSnapshotDetailsByType(reqInputObject).subscribe(async (response: any) => {
  //     if (this.pagecount > this.userSnapShotList.length) {
  //       this.userSnapShotList.push(...response.object)
  //       this.userSnapShotList.forEach(async (snap: any, index) => {
  //         let userObjet = await this.allUserDetails.find(userObject => userObject.uid == snap.UserID)
  //         snap.profileName = userObjet.displayName != undefined ? userObjet.displayName : snap.UserID;
  //         userObjet.profileName = snap.profileName;
  //         snap.userData = userObjet
  //         snap.firstName = snap.profileName.split(" ", 1);
  //         snap.AvatarLastFive = snap.AvatarID.substr(snap.AvatarID.length - 5);
  //       })
  //     }
  //   })
  //   // }
  // }

  nextButtonForGarment() {
   
    this.pageNumber++;
    let reqInputObject = { fileType: this.key, id: this.clothId, pageNumber: this.pageNumber }
    this.commonService.yologramSnapshotDetailsByType(reqInputObject).subscribe(async (response: any) => {
      this.userSnapShotList.push(...response.object)
      this.userSnapShotList.forEach(async (snap: any, index) => {
        let userObjet = this.allUserDetails.find(userObject => userObject.uid == snap.UserID)
        snap.profileName = userObjet.displayName != undefined ? userObjet.displayName : snap.UserID;
        userObjet.profileName = snap.profileName;
        snap.userData = userObjet
        snap.firstName = snap.profileName.split(" ", 1);
        // snap.pageNumber = reqInputObject.pageNumber
        snap.AvatarLastFive = snap.AvatarID.substr(snap.AvatarID.length - 5);
      });
    })
  }


  selectIcon(icon) {
    this.selectedIcon = ''
    this.selectedIcon = icon
    this.iconselctFlag = true
  }



  clickOnHeart() {
    this.heartFlag = true
  }

  openModel(gltfImgSrc: string) {
    const dialogRef = this.dialog.open(PopupThreeDComponent);
    this.gltfImgSrc = gltfImgSrc
    dialogRef.componentInstance.gltfImgSrc = this.gltfImgSrc;
    dialogRef.afterClosed().subscribe(result => {
    });

  }
  toggaleInof() {
    this.toggaleFlag = false
    this.iconselctFlag = false
    this.getStyle()
  }
  toggale3D() {
    this.toggaleFlag = true
    this.getStory()
    this.iconselctFlag = false
  }
  filterCollectionName(id) {
    // let garmentCollectionId = (this.collectionDetails != undefined && this.collectionDetails).find((colection) => colection.id === id)
    // let getCollection = (this.collectionData != undefined && this.collectionData).find((colection) => colection.id === garmentCollectionId.GarmentCollectionID)

    // let collectionName = getCollection.CollectionName
    // return collectionName
    let garmentCollectionId = (this.collectionDetails != undefined && this.collectionDetails).find((colection) => colection.id === id )
    let getCollection =  this.collectionData.find((colection) => colection.id === garmentCollectionId.GarmentCollectionID && colection.status == 'A')
    let name = getCollection !=undefined &&  getCollection.CollectionName != undefined ? getCollection.CollectionName : '';
    return name

  }

  toggleContent() {
    this.isContentToggled = !this.isContentToggled;
    this.clothDetailsList.Details = this.isContentToggled ? this.nonEditedContent : this.formatContent(this.clothDetailsList.Details);
  }
  formatContent(content: string) {
    let limit = 300
    if (this.completeWords) {
      limit = content.substr(0, limit).lastIndexOf(' ');
    }
    return `${content.substr(0, limit)}...`;
  }



  scrollHorizontalSlider(){
    

  }


  scrollSlider() {
    let width = this.garmentDummySCroll.nativeElement.scrollLeft
    if(width > 710){
      this.images.push(...this.images2)
    }




  }

  open3DExternalUrl(){
    this.isloading = true;
    this.testVar = 'Change';
    let brandname = this.selectedBrand;;
    let brandId = this.selectedBrandId
    let zipfilename = "%2Fpack0"
    let gender = this.selectedGender;
    let packlocation = "AvatarBlenderClothes%2F" + brandId + "_" + gender +"%2F"+ this.clothType +"%2F"+ this.clothId ;
    let brandEncodedurl = packlocation
    let url = 'https://jsontothreejs-parser.web.app/?zipUrl=https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/' + brandEncodedurl + '?alt=media&token=2c1dc261-ac91-4c32-a5ec-ac5e06f0a9bc&_gl=1*goch74*_ga*MjExMjI5MTIyMi4xNjg3NTA3MDk1*_ga_CW55HF8NVT*MTY5NjkzMDY1Ny4xNTcuMS4xNjk2OTM2NzA4LjU5LjAuMA..'
    this.zipUrl = url
    this.zipUrl = url
    this.router.navigate(['public/external',{'brandId' :brandId,'Gender':gender,'ClothType':this.clothType,'ClothId':this.clothId}])
    //  this.urlRout = urlsite
    //  console.log("url",this.urlRout)
   }

   inItLink(){
    this.isloading = true;
    this.testVar = 'Change';
    let brandname = this.selectedBrand;;
    let brandId = this.selectedBrandId
    let zipfilename = "%2Fpack0"
    let gender = this.selectedGender;
    let packlocation = "AvatarBlenderClothes%2F" + brandId + "_" + gender +"%2F"+ this.clothType +"%2F"+ this.clothId ;
    let brandEncodedurl = packlocation
    let url = 'https://jsontothreejs-parser.web.app/?zipUrl=https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/' + brandEncodedurl + '?alt=media&token=2c1dc261-ac91-4c32-a5ec-ac5e06f0a9bc&_gl=1*goch74*_ga*MjExMjI5MTIyMi4xNjg3NTA3MDk1*_ga_CW55HF8NVT*MTY5NjkzMDY1Ny4xNTcuMS4xNjk2OTM2NzA4LjU5LjAuMA..'

    this.zipUrl = url
    // let urlsite = this.router.navigate(['public/external',{'brandId' :brandId,'Gender':gender,'ClothType':this.clothType,'ClothId':this.clothId}])
    this.urlRout = "http://localhost:4200/#//public/external;brandId="+brandId+";Gender="+gender+";ClothType="+this.clothType+";ClothId="+this.clothId
    console.log("url",this.urlRout)
    // this.urlNameSecond = this.sanitizer.bypassSecurityTrustResourceUrl(this.urlRout);
    console.log(this.zipUrl)
   }
   toggleText() {
    if (this.isPlus) {
      this.buttonText = 'bi bi-dash-circle';
    } else {
      this.buttonText = 'bi bi-plus-circle';
    }
    this.isPlus = !this.isPlus;
  }

}


