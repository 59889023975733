<div *ngIf="updatingFlag" class="loading">&#8230;</div>
<app-yologram-social *ngIf="logInFlag"></app-yologram-social>
<div class="card" *ngIf="!updatingFlag" >
    <!-- <div class="card-header font-bold">List of Files</div> -->
    <div class="dt-responsive selectlist font-14">
      <div class="dataTables_wrapper dt-bootstrap4" id="add-row-table_wrapper">
        <div class="p-3 d-flex" >
          <div class="p-3" >
            <select  (change)="selectSecGroup($event)" class="form-select  width-select">
                  <option value="">Select Group</option>
                  <option  [value]="group.id" *ngFor="let group of securityGroupAll ; let i=index">{{group.name}}</option>
            </select>
          </div>
          <div class="p-3" >
            <select (change)="selectAppMode($event)"  class="form-select  width-select" >
              <option value="">Select Role</option>
              <option value="WEB">Web Role</option>
              <option value="APP">APP Role</option>
              <!-- <option [value]="group.id" *ngFor="let group of securityGroupAll ; let i=index">{{group.name}}</option> -->
            </select>
          </div>
        </div>
        <span class="text-center" *ngIf="secGrpPages.length == 0 && selectedAppMode != '' && selectedSecGrpId != ''">
         <h6 class="alert alert-danger">No pages are assigned to this security Group.</h6> 
        </span>
        <div class="col-8 p-3 m-2" >
          <table class="table " *ngIf="secGrpPages.length > 0" id="dataTable" width="100%" cellspacing="0">
            <thead class="custom-header">
              <tr role="row">
                <th>Sr. No.</th>
                <th>Page Name</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              <!-- 
              <tr class="row-list-detail" *ngFor="let secGrp of secGrpPages ;let i=index">
                <td>{{i+1}}</td>
                <td class=""> {{ secGrp.PageId }}- {{this.pageList[secGrp.PageId] && this.pageList[secGrp.PageId]['PageName']}}  </td>
                <td class="catalog-info ">
                    <div class="form-check">
                        <input class="form-check-input m-1"  value="" id="flexCheckDefault" (change)="updatePageStatus($event,secGrp)" type="checkbox" [checked]="secGrp.status == 'A' ? true : false">
                      </div>
                </td>
              </tr> 
            -->

            <tr class="row-list-detail" *ngFor="let secGrp of secGrpPages ;let i=index">
              <td>{{i+1}}</td>
              <td class=""> {{secGrp.PageName}}  </td>
              <td class="catalog-info ">
                  <div class="form-check">
                      <input class="form-check-input m-1"  value="" id="flexCheckDefault" (change)="updatePageStatus($event,secGrp)" type="checkbox" [checked]="secGrp.groupRoleId != undefined ? true : false">
                    </div>
              </td>
            </tr> 

            </tbody>
          </table>
        </div>
      </div>
    </div>
</div>