import { Component, OnInit } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { scan, map, throttleTime, mergeMap, tap } from 'rxjs/operators';
import { CommonService } from 'src/app/services/common.service';
@Component({
  selector: 'app-drips-sample',
  templateUrl: './drips-sample.component.html',
  styleUrls: ['./drips-sample.component.css']
})
export class DripsSampleComponent implements OnInit {
  snapshots: any[] = [];
  paginatedSnapshots: any[] = [];
  currentIndex = 0;
  interval: any; // Variable to hold the interval reference

  constructor(private commonService: CommonService) {}

  ngOnInit(): void {
    this.commonService.getSnapshots().subscribe((snapshots: any) => {
      if (snapshots.object && snapshots.object.length > 0) {
        this.snapshots = snapshots.object;
        this.paginateSnapshots();
        this.startAutoScroll();
      }
    });
  }

  paginateSnapshots(): void {
    this.paginatedSnapshots = this.snapshots.slice(0, 12); // Initial load with 12 items
  }

  startAutoScroll(): void {
    this.interval = setInterval(() => {
      this.currentIndex = (this.currentIndex + 1) % this.snapshots.length;
      this.paginatedSnapshots = this.snapshots.slice(this.currentIndex, this.currentIndex + 12); // Update paginated items
    }, 5000); // Adjust scroll interval as needed (e.g., every 5 seconds)
  }

  ngOnDestroy(): void {
    // Clear the interval to avoid memory leaks
    if (this.interval) {
      clearInterval(this.interval);
    }
  }
}