<!-- <div class="col-lg-12 m-1 justify-content-center scroll text-center" *ngIf="clothDetailsList != undefined">
    <div class="container garment-alingn">
        <div class="col-lg-12 d-flex justify-content-center">
            <div class="col-lg-12 text-center w-100 m-1 d-flex justify-content-center  d-flex">
                <div class="text-center d-flex heeadWidth"><img
                        class="profileImg-brand"
                        style="width:35px; height:35px !important;" [src]="getBrandImage(clothDetailsList.LogoURL)">
                </div>
                <div class="m-0 heeadWidth">
                    <div class="container  d-block col-12  ">
                        <h6 class=" color-class ">{{clothDetailsList.BrandName}}</h6>
                        <div class=" col-12  text-center details-for-task " *ngIf="clothDetailsList != undefined">
                            <b>
                                <h6 class="color-gender ">{{clothDetailsList.Gender == 'F' ? 'Womenswear' :
                                    (clothDetailsList.Gender == 'M') ? 'Menswear' : 'N/A'}}</h6>
                            </b>
                        </div>
                    </div>
                </div>
                <div class="m-0 heeadWidth text-end">
                    <button type="button" class="btn btn-sm btn-outline-info m-2 follow color-class ">Follow</button>
                </div>
            </div>

        </div>
       
  

        <b>
            <h5
                class="text-black clothName  color-class">
                         <span *ngIf="clothDetailsList.RealType == '0'" class="brandbtn py-1"><img
                                style="width:18px !important; color:black ;padding-bottom: 3px;"
                                src="./assets/realtype/headgear.png"></span>
                        <span *ngIf="clothDetailsList.RealType == '1'" class="brandbtn py-1"><img
                                style="width:18px !important; color:black ;padding-bottom: 3px;"
                                src="./assets/realtype/upperwear.png"></span><span *ngIf="clothDetailsList.RealType == '2'"
                            class="brandbtn py-1"><img style="width:18px !important; color:black ;padding-bottom: 5px;"
                                src="./assets/realtype/bottomwear.png"></span><span *ngIf="clothDetailsList.RealType == '3'"
                            class="brandbtn py-1"><img style="width:18px !important; color:black ;padding-bottom: 3px;"
                                src="./assets/realtype/footwear.png"></span><span *ngIf="clothDetailsList.RealType == '4'"
                            class="brandbtn py-1"><img style="width:18px !important; color:black ;padding-bottom: 3px;"
                                src="./assets/realtype/heeledfootwear.png"></span><span *ngIf="clothDetailsList.RealType == '5'"
                            class="brandbtn py-1"><img style="width:18px !important; color:black ;padding-bottom: 3px;"
                                src="./assets/realtype/fullbody.png"></span>
                {{(clothDetailsList.Name)}}</h5>
        </b>
        <div class="details">
            <h6 class="color-class ">Collection: {{clothDetailsList.CollectionName}}</h6>
            <h6 class="color-class ">Details: {{clothDetailsList.Details}}</h6>
            <h6 class="color-class ">SKU: {{clothDetailsList.SKU}}</h6>
        </div>

    </div>
    <div class="imagesDiv">
    <div class="row twoDImage">
        <div class="col-6" *ngFor="let fileList of userFiledetails;let p=index">
            <span *ngIf="p < counter">
                <div *ngIf="fileList.fileType == 'GarmentUpload2D'">
                    <img class="img-fluid img-thumbnail" style="height: 100px; width: 100px !important;     object-fit: contain;
                  " src={{fileList.fileurl}} data-bs-toggle="modal" data-bs-target="#files-view"
                        (click)="openImageBox(fileList.fileurl)">
                </div>
                <div (click)="openModel(fileList.fileurl)" class="d-inline-block"
                    *ngIf="fileList.fileType == 'GarmentUpload3D'">
                    <model-viewer camera-controls camera-orbit="45deg 55deg 2.5m" ar auto-rotate
                        src={{fileList.fileurl}} alt="A 3D avatar-model-view" data-js-focus-visible=""
                        ar-status="not-presenting" style="height: 100px; width: 150px; float: none;">
                    </model-viewer>
                </div>
            </span>
        </div>
    </div>
    <div *ngIf="userFiledetails != undefined">
        <button class="btn btn-sm color-class "
            *ngIf="userFiledetails.length != 0 && counter <= userFiledetails.length && counter != userFiledetails.length "
            (click)="incrementImgCounter()">More</button>
    </div>
   </div>
    <div class="p-2 m-1 text-center d-flex justify-content-center">
        <h6 (click)="getStyle(clothDetailsList)" class="m-2 text-primary brandbtn color-class"><img
            style="width:15px !important; color:black" src="./assets/storystyle/story-white.png"></h6>
        <h6 (click)="getStory()" class="m-2 text-primary brandbtn color-class"><img
            style="width:18px !important; color:black" src="./assets/storystyle/style-white.png"></h6>
        <h6 *ngIf="clothDetailsList.ThumbCenterURL  != undefined" class="m-1 brandbtn"><a
                href="{{clothDetailsList.ThumbCenterURL}}" target="_blank"><img
                    style="width:15px !important; color:black" src="./assets/details.png"></a>
        </h6>
        <h6 *ngIf="clothDetailsList.ThumbCenterURL  == undefined" class="m-1 brandbtn"><a><img
            style="width:15px !important; color:black" src="./assets/details-gray.png"></a> </h6>
        <h6 *ngIf="clothDetailsList.ThumbLeftURL  != undefined" class="m-1 brandbtn"><a
                href="{{clothDetailsList.ThumbLeftURL}}" target="_blank"><img style="width:15px !important"
                    src="./assets/gallary.png"></a>
        </h6>
        <h6 *ngIf="clothDetailsList.ThumbLeftURL  == undefined" class="m-1 brandbtn"><a><img
            style="width:15px !important" src="./assets/gallary-gray.png"></a></h6>
        
        <h6 *ngIf="clothDetailsList.ThumbRightURL  != undefined" class="m-1 brandbtn"><a
                href="{{clothDetailsList.ThumbRightURL}}" target="_blank"><img
                    style="width:15px !important; color:black" src="./assets/shop.png"></a>
        </h6>  
         <h6 *ngIf="clothDetailsList.ThumbRightURL  == undefined" class="m-1 brandbtn"><a><img
            style="width:15px !important; color:black" src="./assets/cart-gray.png"></a>
</h6>
    </div>


    <div class="row g-2 form d-block  " id="target" *ngIf="packJsonObject.length > 0 && selectedTab =='Menswears'">
        <div class="slider-css211 " data-bs-toggle="modal" data-bs-target="#twoD-Modal">
            <div *ngFor="let object of packJsonObject[0].value['files'];let i=index" class="slider-img211">
                <img (click)="open2DModelPopUp(zipList[object],'headgear')"
                    *ngIf="clothType == packJsonObject[0].title && clothId == object " class="img-responsive1"
                    [src]="sanitizer.bypassSecurityTrustUrl(zipList[object])" />
            </div>
        </div>
        <div class="slider-css2" data-bs-toggle="modal" data-bs-target="#2D-view">
            <div *ngFor="let object of packJsonObject[1].value['files'];let i=index" class="slider-img2">
                <img (click)="open2DModelPopUp(zipList[object],'upperwear')"
                    *ngIf="clothType == packJsonObject[1].title && clothId == object " class="img-responsive"
                    [src]="sanitizer.bypassSecurityTrustUrl(zipList[object])" />
            </div>
        </div>
        <div class="slider-css2" data-bs-toggle="modal" data-bs-target="#2D-view">
            <div *ngFor="let object of packJsonObject[2].value['files'];let i=index" class="slider-img2">
                <img (click)="open2DModelPopUp(zipList[object],'bottomwear')"
                    *ngIf="clothType == packJsonObject[2].title && clothId == object " class="img-responsive"
                    [src]="sanitizer.bypassSecurityTrustUrl(zipList[object])" />
            </div>
        </div>
        <div class="slider-css211" data-bs-toggle="modal" data-bs-target="#2Dview">
            <div *ngFor="let object of packJsonObject[3].value['files'];let i=index" class="slider-img211">
                <img (click)="open2DModelPopUp(zipList[object],'footwear')"
                    *ngIf="clothType == packJsonObject[3].title && clothId == object " class="img-responsive1"
                    [src]="sanitizer.bypassSecurityTrustUrl(zipList[object])" />
            </div>
        </div>
    </div>
    <div class="d-flex justify-content-center " *ngIf="isloading">
        <div class="spinner-border" role="status">
            <span class=""></span>
        </div>
    </div>
    <div class="row mb-5 p-2 justify-content-center " *ngIf="selectedTab == 'Menswear'">
        <div class="column d-block m-1" *ngFor="let snapshot of userSnapShotList ; let i=index;">
            <img class="w-90 shadow-1-strong rounded mb-1" (click)="scrollToStory(snapshot)"
                [src]="snapshot.SnapshotFileUrl">
        </div>
    </div>
    <div *ngIf="!isloading">
        <div *ngIf="selectedTab == 'story'  " class="d-flex text-start">
            <div class="dt-responsive selectlist font-14">
                <div class="dataTables_wrapper dt-bootstrap4" id="add-row-table_wrapper">
                    <div #garmentsnapShotFeeds class="card m-3  slider-css-garment " *ngIf="userSnapShotList.length > 0 ">
                        <div class="slider-img-garment" *ngFor="let snapshot of userSnapShotList; let i=index; ">
                            <div class="m-2">
                                <h6>
                                    <div class="col-lg-12 d-flex">
                                        <a class="col-lg-6 w-70 display-name"
                                            (click)="getSnapShotListByUserPopup(snapshot)">{{(snapshot.profileName)}}</a>
                                            <h6 
                                            (click)="getSnapShotListAvatarPopup(snapshot)"
                                            class="col-lg-6 text-end avatar-diaplay" ><span>🧍🏽
                                            {{snapshot.firstName}}{{snapshot.AvatarLastFive}}</span></h6>
                                    </div>
                                </h6>
                                <div class="col-lg-12 d-flex">
                                    <h6 class="col-lg-6 date-class">{{snapshot.timestamp | date : 'MMM d, y, h:mm a'}}</h6>
                                    <h6 data-bs-toggle="modal" data-bs-target="#Envitonment-profile"
                                    (click)="environmentPopup(snapshot.Environment)" class="col-lg-6 evn-font text-end">
                                        {{getEnvironmentName(snapshot.Environment)}}</h6>
                                </div>
                              </div>
                            <img class="img-fluid-2" width="480 !important" height="280"
                                [src]="snapshot.SnapshotFileUrl" />
                            <h6 class="second-txt">
                                {{snapshot.StoryType}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                {{snapshot.StoryID}}
                       
                            </h6>

                            <div class="">
                                <div class="col-12 col-sm-12 slider-box">
                                    <div class="col-12 col-sm-12" *ngIf="filterSnapShotFileDetails"><img
                                            *ngIf="getGarmentrealtype(snapshot.HeadwearID) == '0' "
                                            style="width:15px !important; color:black" src="./assets/newsfeed/headgear-new.png">
                                        <span class="caption-values"
                                            *ngIf="snapshot.HeadwearID != undefined && snapshot.HeadwearID != ''">
                                            <span class="garment-name" data-toggle="tooltip" data-placement="top"
                                                title="Cloth Details" (click)="setClothDetails(snapshot.HeadwearID)"
                                                data-bs-toggle="modal" data-bs-target="#garment-details">
                                                {{getGarmentName(snapshot.HeadwearID)}}
                                            </span>&nbsp;
                                            <span class="brand-name" data-toggle="tooltip" data-placement="top"
                                                title="Brands Details" (click)="setBrandDetails(snapshot.HeadwearID)"
                                                data-bs-toggle="modal"
                                                data-bs-target="#brand-details">{{getBrandName(snapshot.HeadwearID)}}</span>
                                            <a
                                              >
                                                <img class="img-thumb" src="./assets/Pinterest-logo.png" />
                                            </a>
                                        </span>
                                    </div>
                                    <div class="col-12 col-sm-12 " *ngIf="filterSnapShotFileDetails">
                                        <img *ngIf="getGarmentrealtype(snapshot.UpperwearID) == '1' "
                                            style="width:15px !important; color:black" src="./assets/realtype/upperwear.png">
                                        <img *ngIf="getGarmentrealtype(snapshot.UpperwearID) == '5' "
                                            style="width:15px !important; color:black" src="./assets/realtype/fullbody.png">
                                        <span class="caption-values"
                                            *ngIf="snapshot.UpperwearID != undefined && snapshot.UpperwearID != ''">
                                            <span class="garment-name" data-toggle="tooltip" data-placement="top"
                                                title="Cloth Details" (click)="setClothDetails(snapshot.UpperwearID)"
                                                data-bs-toggle="modal" data-bs-target="#garment-details">
                                                {{getGarmentName(snapshot.UpperwearID)}}
                                            </span>&nbsp;
                                            <span class="brand-name" data-toggle="tooltip" data-placement="top"
                                                title="Brands Details" (click)="setBrandDetails(snapshot.UpperwearID)"
                                                data-bs-toggle="modal"
                                                data-bs-target="#brand-details">{{getBrandName(snapshot.UpperwearID)}}</span>
                                            <a
                                              >
                                                <img class="img-thumb" src="./assets/Pinterest-logo.png" />
                                            </a>
                                        </span>
                                    </div>
                                    <div class="col-12 col-sm-12 " *ngIf="filterSnapShotFileDetails">
                                        <img *ngIf="getGarmentrealtype(snapshot.BottomwearID) == '2'"
                                            style="width:15px !important; color:black" src="./assets/newsfeed/bottomwear-new.png">
                                        <span class="caption-values"
                                            *ngIf="snapshot.BottomwearID != undefined && snapshot.BottomwearID != ''">
                                            <span class="garment-name" data-toggle="popover" data-placement="left"
                                                title="Cloth Details" (click)="setClothDetails(snapshot.BottomwearID)"
                                                data-bs-toggle="modal" data-bs-target="#garment-details">
                                                {{getGarmentName(snapshot.BottomwearID)}}
                                            </span>&nbsp;
                                            <span class="brand-name" data-toggle="popover" data-placement="right"
                                                title="Cloth Details" (click)="setBrandDetails(snapshot.BottomwearID)"
                                                data-bs-toggle="modal" data-bs-target="#brand-details">
                                                {{getBrandName(snapshot.BottomwearID)}}
                                            </span>
            
                                            <a>
                                                <img class="img-thumb" src="./assets/Pinterest-logo.png" />
                                            </a>
                                        </span>
                                    </div>
                                    <div class="col-12 col-sm-12 " *ngIf="filterSnapShotFileDetails">
                                        <img *ngIf="getGarmentrealtype(snapshot.FootwearID) == '3' "
                                            style="width:15px !important; color:black" src="./assets/realtype/footwear.png">
                                        <span class="caption-values"
                                            *ngIf="snapshot.FootwearID != undefined && snapshot.FootwearID != ''">
                                            <span class="garment-name" data-toggle="popover" data-placement="left"
                                                title="Cloth Details" (click)="setClothDetails(snapshot.FootwearID)"
                                                data-bs-toggle="modal" data-bs-target="#garment-details">
                                                {{getGarmentName(snapshot.FootwearID)}}
                                            </span>&nbsp;
                                            <span class="brand-name" data-toggle="popover" data-placement="right"
                                                title="Cloth Details" (click)="setBrandDetails(snapshot.FootwearID)"
                                                data-bs-toggle="modal" data-bs-target="#brand-details">
                                                {{getBrandName(snapshot.FootwearID)}}
                                            </span>
            
                                            <a
                                            >
                                                <img class="img-thumb" src="./assets/Pinterest-logo.png" />
                                            </a>
            
                                        </span>
            
                                    </div>
                                </div>
                            </div>
          
                    </div>
                </div>
            </div>
       <div class="modal fade imp-bg" id="Envitonment-profile" tabindex="-1" aria-labelledby="Avatar-profile" aria-hidden="true" data-bs-backdrop="false">
                <div class="modal-dialog  ">
                    <div class="modal-content imp-block" *ngIf="filterEnvironmentSnpShot">
                        <h5 type="button" class="btn-close  closBtn" data-bs-dismiss="modal" aria-label="Close"></h5>
                           <div class="container">
                               <div class="row text-center">
                                <div class="row text-center p-2">
                                    <div class="col-12 m-3" *ngIf="evnFileListFileName && evnFileListFileName['displayName']">
                                        <h2 (click)="getEnvironmentProfile(evnFileListFileName['envFileName'])" data-bs-dismiss="modal">{{evnFileListFileName['displayName']}}</h2> 
                                        <div><button type="button" class="btn btn-outline-info m-1 follow-2 " data-bs-dismiss="modal">Preview</button></div>
                                    </div>
                                    <div class="row w-100 m-2 justify-content-center"
                                        *ngIf="filterEnvironmentSnpShot.length >= 0  && filterEnvironmentSnpShot != undefined && filterEnvironmentSnpShot.length >= 0 ">
                                        <div class="column d-block m-1" *ngFor="let snapshot of filterEnvironmentSnpShot  ; let i=index;">
                                            <img class="w-100 shadow-1-strong rounded" data-bs-dismiss="modal"
                                                (click)="getFeed(snapshot,brandDetails)" [src]="snapshot.SnapshotFileUrl">
                                        </div>
                                        <div class="columnImg  d-block m-1" *ngIf="filterEnvironmentSnpShot.length > 8"
                                            (click)="getEnvironmentProfile(evnFileListFileName['envFileName'])">
                                            <img  style=" margin-top: -163px;
                                            left: 10px;
                                            margin-left: 351px;
                                            width: 30px;
                                            height: 30px;
                                            " class="w-80 shadow-1-strong rounded plus-evn"
                                                data-bs-dismiss="modal" src="./assets/plus-8-512.png">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </div>
        </div>
       <div class="modal fade imp-bg" id="Avatar-profile" tabindex="-1" aria-labelledby="Avatar-profile" aria-hidden="true" data-bs-backdrop="false">
            <div class="modal-dialog  ">
                <div class="modal-content imp-block" *ngIf="filterSnapShotForProfile">
                    <h5 type="button" class="btn-close  closBtn" data-bs-dismiss="modal" aria-label="Close"></h5>
                       <div class="container">
                           <div class="row text-center">
                            <div class="row text-center p-2">
                                <div class="col-12 m-3">
                                    <img style="width: 70px; height:70px !important;" class="rounded-circle" data-bs-dismiss="modal" (click)="getSnapShotListByUser(profileDetails.uid)" src="{{profileDetails.photoURL}}" />
                                    <h2 class="w-100" data-bs-dismiss="modal"
                                    (click)="getUserSnapShotDashboar(profileDetails.uid)">
                                        {{profileDetails.displayName}}</h2>
                                       <a (click)="getProfileByAvatorpage(selectedAvtar)">🧍🏽{{selectedAvtar}} </a>
                                    <div><button type="button" class="btn btn-outline-info m-1 follow-2 ">Follow</button></div>
                                    <div><button type="button" class="btn btn-outline-info m-1 p-1 follow-3 " data-bs-dismiss="modal" (click)="getuserfeed(selectedAvtar)">Avatar Feed</button></div>
                                </div>
                                <div class="row w-100 m-2"
                                    *ngIf="userSnapShotList.length >= 0  && userSnapShotList != undefined && userSnapShotList.length >= 0 ">
                                    <div class="column d-block m-1" *ngFor="let snapshot of userSnapShotList  ; let i=index;">
                                        <img class="w-100 shadow-1-strong rounded" data-bs-dismiss="modal"
                                            (click)="getFeed(snapshot,brandDetails)" [src]="snapshot.SnapshotFileUrl">
                                    </div>
                                    <div class="columnImg d-block m-1" *ngIf="userSnapShotList.length > 8"
                                        (click)="getBrandProfile(brandDetails.id,brandDetails.boardName)">

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade" id="garment-details" tabindex="-1" aria-labelledby="garment-details" aria-hidden="true"
            data-bs-backdrop="false">
            <div class="modal-dialog" id="garment-details">
                <div class="modal-content imp-block">
                    <div class="modal-body">
                        <h5 type="button" class="btn-close  closBtn" data-bs-dismiss="modal" aria-label="Close"></h5>
                        <div class="container">
                            <div class="row text-center justify-content-center">
                                <div class="row text-center p-2 justify-content-center">
                                    <div class="col-12 m-2 details" *ngIf="clothDetails.BrandID != undefined">
                                        <div class="col-lg-12 text-center w-100 m-1 d-flex justify-content-center  d-flex">
                                            <div class="text-center d-flex  ">
                                                <img data-bs-dismiss="modal"
                                                    (click)="getBrandProfileByGarment(clothDetails.BrandID,brandDetails.boardName)"
                                                    class="rounded profileImg-brand"
                                                    style="width:35px !important; height:35px !important;"
                                                    [src]="getBrandImage(clothDetails.LogoURL)">
                                            </div>
                                            <div class="p-1"><b
                                                data-bs-dismiss="modal"  (click)="getBrandProfileByGarment(clothDetails.BrandID,clothDetails.boardName)">{{clothDetails.BrandName}}</b>
                                            </div>
                                            <div><button type="button"
                                                    class="btn btn-sm btn-outline-info follow-garment">Follow</button></div>
                                        </div>
                                        <b>
                                            <h6 class="color-class">{{clothDetailsList.Gender == 'F' ? 'Womenswear' :
                                                (clothDetailsList.Gender == 'M') ? 'Menswear' : 'N/A'}}</h6>
                                        </b>
                                        <b>
                                            <h5  data-bs-dismiss="modal"  (click)="getClothProfile(clothDetails.BrandID)"  [ngClass]="clothDetails.Name.length >= 20  ? 'class-font' : ''"  class=" text-black clothName">{{(clothDetails.Name)}}</h5>
                                        </b>
                                        <h6  data-bs-dismiss="modal" (click)="getClothProfile(clothDetails.BrandID)" >Collection: {{clothDetails.CollectionName}}</h6>
                                        <h6  data-bs-dismiss="modal" (click)="getClothProfile(clothDetails.BrandID)" >Details: {{clothDetails.Details}}</h6>
                                        <h6  data-bs-dismiss="modal" (click)="getClothProfile(clothDetails.BrandID)" >SKU: {{clothDetails.SKU}}</h6>
                                    </div>
        
                                    <div class="row">
                                        <div  data-bs-dismiss="modal" (click)="getClothProfile(clothDetails.BrandID)" class="text-center col-6" *ngFor="let fileList of userFiledetails;let p=index">
                                            <span *ngIf="p < counter">
                                                <div *ngIf="fileList.fileType == 'GarmentUpload2D'">
                                                    <img class="img-fluid img-thumbnail" style="height: 100px; width: 100px !important; object-fit: contain;
                                                    "
                                                        src={{fileList.fileurl}}>
                                                </div>
                                                <div class="justify-content-center col-6 "
                                                    *ngIf="fileList.fileType == 'GarmentUpload3D'">
                                                    <div class="text-center col-6 ">
                                                        <model-viewer camera-controls camera-orbit="45deg 55deg 2.5m" ar auto-rotate
                                                            src={{fileList.fileurl}} alt="A 3D avatar-model-view"
                                                            data-js-focus-visible="" ar-status="not-presenting"
                                                            style="height: 100px; width: 150px; float: none;">
                                                        </model-viewer>
                                                    </div>
                                                </div>
                                            </span>
                                        </div>
                                    </div>
                             

                                      
                                      <div class="p-2 m-1 text-center d-flex justify-content-center">
                                        <h6 *ngIf="clothDetails.ThumbCenterURL  != undefined" class="m-1 brandbtn"><a
                                                href="{{clothDetails.ThumbCenterURL}}" target="_blank"><img
                                                    style="width:15px !important; color:black" src="./assets/details.png"></a>
                                        </h6>
                                        <h6 *ngIf="clothDetails.ThumbCenterURL  == undefined" class="m-1 brandbtn"><a><img
                                                    style="width:15px !important; color:black"
                                                    src="./assets/details-gray.png"></a> </h6>
                                        <h6 *ngIf="clothDetails.ThumbLeftURL  != undefined" class="m-1 brandbtn"><a
                                                href="{{clothDetails.ThumbLeftURL}}" target="_blank"><img
                                                    style="width:15px !important" src="./assets/gallary.png"></a></h6>
                                        <h6 *ngIf="clothDetails.ThumbLeftURL  == undefined" class="m-1 brandbtn"><a><img
                                                    style="width:15px !important" src="./assets/gallary-gray.png"></a></h6>
                                        <h6 *ngIf="clothDetails.ThumbRightURL  != undefined" class="m-1 brandbtn"><a
                                                href="{{clothDetails.ThumbRightURL}}" target="_blank"><img
                                                    style="width:15px !important; color:black" src="./assets/shop.png"></a></h6>
                                        <h6 *ngIf="clothDetails.ThumbRightURL  == undefined" class="m-1 brandbtn"><a><img
                                                    style="width:15px !important; color:black" src="./assets/cart-gray.png"></a>
                                        </h6>
                                    </div>



                                    <div class="p-2 m-2 justify-content-center text-center d-flex"
                                        *ngIf="clothDetails != undefined">
                                        <h6 data-bs-dismiss="modal" class="text-white brandbtn m-2"
                                            (click)="getStoryCloth(clothDetails)">Story</h6>
                                        <h6 data-bs-dismiss="modal" (click)="getgarmentStyle(brandDetails,clothDetails)"
                                            class="text-white brandbtn m-2">Style</h6>
                                    </div>
                                    <div class="row w-100 m-1 justify-content-center" 
                                        *ngIf="clothShapShotsList.length >= 0  && clocthCollectionIdcollection != undefined && clocthCollectionIdcollection.length >= 0 ">
                                        <div class="column d-block m-1"
                                            *ngFor="let snapshot of clothShapShotsList  ; let i=index;">
                                            <img class="w-100 shadow-1-strong rounded" data-bs-dismiss="modal"
                                                *ngIf="clothDetails.BrandID != undefined"
                                                (click)="getgarmentStylefeed(brandDetails,clothDetails)"
                                                [src]="snapshot.SnapshotFileUrl">
                                        </div>
                                        <div class="columnImg d-block m-1">
                                            <img class="w-80 shadow-1-strong rounded" data-bs-dismiss="modal">
                                        </div>
                                        <div class="columnImg d-block m-1">
                                            <img class="w-80 shadow-1-strong rounded" data-bs-dismiss="modal">
                                        </div>
                                        <div class="columnImg d-block m-1" *ngIf="clothShapShotsList.length > 8"
                                            (click)="getClothProfile(clothDetails.BrandID)">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade imp-bg" id="brand-details" tabindex="-1" aria-labelledby="brand-details" aria-hidden="true"
            data-bs-backdrop="false">
            <div class="modal-dialog  ">
                <div class="modal-content imp-block" *ngIf="brandDetails && brandDetails.BrandName">
                    <h5 type="button" class="btn-close  closBtn" data-bs-dismiss="modal" aria-label="Close"></h5>
                    <div class="container">
                        <div class="row text-center">
                            <div class="row text-center p-2">
                                <div class="col-12 m-3">
                                    <img style="width: 75px; height:75px !important;" class="rounded profileImg-brand p-1"
                                    data-bs-dismiss="modal"
                                        (click)="getBrandProfileByGarment(brandDetails.id,brandDetails.boardName)"
                                        [src]="getBrandImage(brandDetails.LogoURL)" />
                                    <h2 class="w-100" data-bs-dismiss="modal"
                                        (click)="getBrandProfileByGarment(brandDetails.id,brandDetails.boardName)">
                                        {{(brandDetails.BrandName)}}</h2>
        
                                    <div><button type="button" class="btn btn-outline-info m-2 follow-2">Follow</button></div>
                                    <h6><img style="width:15px !important" src="./assets/pinterest-512.png">:
                                        {{brandDetails.boardName}} </h6>
        
                                </div>
                                <div class="p-1 text-center d-flex justify-content-center">
                                    <h6 class="m-1 text-white brandbtn"
                                        [ngClass]="brandDetails.id == brandId  ? 'border-bottom border-light' : 'border-bottom border-light'">
                                        <b>Story</b>
                                    </h6>
                                    <h6 data-bs-dismiss="modal" (click)="getBrandStylegender(brandDetails.id,'M')"
                                        class="m-1 text-white brandbtn">Menswear</h6>
                                    <h6 data-bs-dismiss="modal" (click)="getBrandStylegender(brandDetails.id,'F')"
                                        class="m-1 text-white brandbtn">Womenswear</h6>
                                </div>
                                <div class="row w-100 m-2 justify-content-center"
                                    *ngIf="brandhShapShotsList.length >= 0  && brandCollectionIdcollection != undefined && brandCollectionIdcollection.length >= 0 ">
                                    <div class="column d-block m-1"
                                        *ngFor="let snapshot of brandhShapShotsList  ; let i=index;">
                                        <img class="w-100 shadow-1-strong rounded" data-bs-dismiss="modal"
                                            (click)="getFeed(snapshot,brandDetails)" [src]="snapshot.SnapshotFileUrl">
                                    </div>
                                    <div class="columnImg d-block m-1" *ngIf="brandhShapShotsList.length > 8"
                                        (click)="getBrandProfile(brandDetails.id,brandDetails.boardName)">
                                      
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
       <div class="modal fade imp-bg" id="user-profile" tabindex="-1" aria-labelledby="user-profile" aria-hidden="true"
            data-bs-backdrop="false">
            <div class="modal-dialog  ">
                <div class="modal-content imp-block" *ngIf="filterSnapShotForProfile">
                    <h5 type="button" class="btn-close  closBtn" data-bs-dismiss="modal" aria-label="Close"></h5>
        
                    <div class="container">
                        <div class="row text-center">
                            <div class="row text-start p-2">
                                <div class="col-12 m-3">
                                    <img style="width: 70px; height:70px !important;" class="rounded-circle" data-bs-dismiss="modal"
                                        (click)="getUserSnapShotDashboar(profileDetails.uid)"
                                        src="{{profileDetails.photoURL}}" />
                                    <h2 class="w-100" data-bs-dismiss="modal" (click)="(profileDetails.uid)"
                                        (click)="getUserSnapShotDashboar(profileDetails.uid)">
                                        {{profileDetails.displayName}}</h2>
                                    <div><button type="button" class="btn btn-outline-info m-1 follow-2">Follow</button></div>
                               
                                </div>

                                <div class="row d-flex">
                                    <div data-bs-dismiss="modal"
                                        class="text-center col-6">
                                        <span>
                                            <div >
                                                <img class="img-fluid img-thumbnail" style="height: 100px; width: 100px !important; object-fit: contain;
                                            " src="./assets/imgplaceholder.jpg">
                                            </div>
                                        </span>
                                    </div>
                                    <div data-bs-dismiss="modal"
                                        class="text-center col-6">
                                        <span>
                                            <div >
                                                <img class="img-fluid img-thumbnail" style="height: 100px; width: 100px !important; object-fit: contain;
                                            " src="./assets/imgplaceholder1.jpg">
                                            </div>
                                        </span>
                                    </div>
                                </div>

                                <div class="row w-100 m-2 justify-content-center"
                                    *ngIf="userSnapShotList.length >= 0  && userSnapShotList != undefined && userSnapShotList.length >= 0 ">
                                    <div class="column d-block m-1"
                                        *ngFor="let snapshot of userSnapShotList  ; let i=index;">
                                        <img class="w-100 shadow-1-strong rounded" data-bs-dismiss="modal"
                                        (click)="getUserSnapShotDashboar(profileDetails.uid)" [src]="snapshot.SnapshotFileUrl">
                                    </div>
                                    <div class="columnImg d-block m-1" *ngIf="filterSnapShotForProfile.length > 8 && profileDetails.uid"
                                        (click)="getUserSnapShotDashboar(profileDetails.uid)">
                                        <img style="margin-top: -163px;
                                                    margin-left: 351px;
                                                    width: 30px;
                                                    height: 30px;" class="w-80 shadow-1-strong rounded plus-evn"
                                            data-bs-dismiss="modal" src="./assets/plus-8-512.png">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
         <div class="modal fade imp-bg" id="files-view" tabindex="-1" aria-labelledby="files-view"
                aria-hidden="true" data-bs-backdrop="false">
                <div class="modal-dialog justify-content-center ">
                    <h5 type="button" class="btn-close  closBtn" data-bs-dismiss="modal" aria-label="Close"></h5>
                    <div class="modal-content imp-block justify-content-center">
                        <img src={{showImages.fileurl}}>
                    </div>
                </div>
        </div>
        </div>
    </div> -->
   
    <app-public-garment-feed [routLogInFlag]="routLogInFlag"></app-public-garment-feed>