<div *ngIf="loading" class="loading">Loading&#8230;
    <button> cancel </button>
</div>
<div class="dt-responsive selectlist font-14 ">
    <div class="dataTables_wrapper dt-bootstrap4" id="add-row-table_wrapper">
        <div #snapshotfeeds class="card m-3  slider-css " *ngIf="filterSnapShotFileDetails.length >= 0 ">
            <div class="slider-img" *ngFor="let snapshot of filterSnapShotFileDetails; let i=index; ">
                <div class="col-lg-12">
                    <h6>
                        <div class="col-lg-12 d-flex">

                            <a class="col-lg-6 w-70 display-name" (click)="openUserPopup(snapshot)">
                                {{snapshot.profileName}}
                            </a>
                            <h6 class="col-lg-6 text-end" (click)="openAvatarPopUp(snapshot,snapshot.AvatarID)"
                                class="col-lg-6 m-1 text-end avatar-diaplay">
                                🧍🏽{{snapshot.firstName}}{{snapshot.AvatarLastFive}}</h6>
                        </div>
                    </h6>
                    <div class="col-lg-12 d-flex">
                        <h6 class="col-lg-6 date-class">{{snapshot.timestamp | date : 'MMM d, y, h:mm a'}}</h6>
                        <!-- <b  (click)="share(snapshot.StoryID)">share</b> -->
                        <h6 class="col-lg-6 " (click)="environmentPopup(snapshot.Environment)"
                            class="col-lg-6 evn-font text-end">
                            {{getEnvironmentName(snapshot.Environment)}}</h6>
                    </div>
                    <span class="spinner-border second-spineer" *ngIf="isLoading" role="status"></span>
                </div>
                <img class="img-fluid" width="480 !important" height="280" [src]="snapshot.SnapshotFileUrl" />
                <h6 (click)="openUserPopup(snapshot.UserID)" class="second-txt">
                    {{snapshot.StoryType}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    {{snapshot.StoryID}}
                </h6>
                <div class="iocon-set d-block justify-content-center text-center">
                    <div class="justify-content-center set-div">
                        <img *ngIf="getGarmentrealtype(snapshot.HeadwearID) == '0' "
                            style="width: 15px !important;   height: 15px !important;     opacity: 0.4;"
                            src="./assets/newsfeed/headgear-new.png">
                        <div class="d-flex">
                            <a class="pintrest-icon"
                                *ngIf="snapshot.HeadwearID != undefined && snapshot.HeadwearID != '' && showPintrestIcon(snapshot.HeadwearID)"
                                (click)="shareOnPinterest(snapshot.HeadwearID,'headwear',snapshot.SnapshotFileUrl,snapshot.StoryID)">
                                <img style="width:20px !important; color:black" class="img-thumbnail2"
                                    src="./assets/Pinterest-logo.png" />
                            </a>
                            <img *ngIf="getGarmentrealtype(snapshot.HeadwearID) == '0' " class="rounded-circle m-1"
                                [src]="getBrandImageByGarmetnID(snapshot.HeadwearID)"
                                class="rounded profileImg-brand m-1"
                                style="width: 25px !important;   height: 25px !important" />
                        </div>
                    </div> &nbsp;
                    <div class="justify-content-center set-div">
                        <img *ngIf="getGarmentrealtype(snapshot.UpperwearID) == '1' "
                            style="width: 15px !important;   height: 15px !important;      opacity: 0.4;"
                            src="./assets/realtype/upperwear.png">
                        <div class="d-flex">
                            <a class="pintrest-icon"
                                *ngIf="snapshot.UpperwearID != undefined && snapshot.UpperwearID != '' && showPintrestIcon(snapshot.UpperwearID) && getGarmentrealtype(snapshot.UpperwearID) == '1'"
                                (click)="shareOnPinterest(snapshot.UpperwearID,'upperwear',snapshot.SnapshotFileUrl,snapshot.StoryID)">
                                <img style="width:20px !important; color:black" class="img-thumbnail2"
                                    src="./assets/Pinterest-logo.png" />
                            </a>
                            <img *ngIf="getGarmentrealtype(snapshot.UpperwearID) == '1' " class="rounded-circle m-1"
                                [src]="getBrandImageByGarmetnID(snapshot.UpperwearID)"
                                class="rounded profileImg-brand m-1"
                                style="width: 25px !important;   height: 25px !important" />
                        </div>
                    </div> &nbsp;
                    <div class="justify-content-center set-div ">
                        <img *ngIf="getGarmentrealtype(snapshot.UpperwearID) == '5' "
                            style="width: 15px !important;   height: 15px !important;     opacity: 0.4;"
                            src="./assets/realtype/fullbody.png">
                        <div class="d-flex">
                            <a class="pintrest-icon"
                                *ngIf="snapshot.UpperwearID != undefined && snapshot.UpperwearID != '' && showPintrestIcon(snapshot.UpperwearID) && getGarmentrealtype(snapshot.UpperwearID) == '5'"
                                (click)="shareOnPinterest(snapshot.UpperwearID,'upperwear',snapshot.SnapshotFileUrl,snapshot.StoryID)">
                                <img style="width:20px !important; color:black" class="img-thumbnail2"
                                    src="./assets/Pinterest-logo.png" />
                            </a>
                            <img *ngIf="getGarmentrealtype(snapshot.UpperwearID) == '5' " class="rounded-circle m-1"
                                [src]="getBrandImageByGarmetnID(snapshot.UpperwearID)"
                                class="rounded profileImg-brand m-1"
                                style="width: 25px !important;   height: 25px !important;" />
                        </div>
                    </div> &nbsp;
                    <div class="justify-content-center set-div ">
                        <img *ngIf="getGarmentrealtype(snapshot.BottomwearID) == '2'"
                            style="width: 15px !important;   height: 15px !important;    opacity: 0.4;"
                            src="./assets/newsfeed/bottomwear-new.png">
                        <div class="d-flex">
                            <a class="pintrest-icon"
                                *ngIf="snapshot.BottomwearID != undefined && snapshot.BottomwearID != '' && showPintrestIcon(snapshot.BottomwearID)"
                                (click)="shareOnPinterest(snapshot.BottomwearID,'upperwear',snapshot.SnapshotFileUrl,snapshot.StoryID)">
                                <img style="width:20px !important; color:black" class="img-thumbnail2"
                                    src="./assets/Pinterest-logo.png" />
                            </a>
                            <img *ngIf="getGarmentrealtype(snapshot.BottomwearID) == '2'" class="rounded-circle m-1"
                                [src]="getBrandImageByGarmetnID(snapshot.BottomwearID)"
                                class="rounded profileImg-brand m-1"
                                style="width: 25px !important;   height: 25px !important" />
                        </div>
                    </div> &nbsp;
                    <div class="justify-content-center set-div">
                        <img *ngIf="getGarmentrealtype(snapshot.FootwearID) == '3' "
                            style="width: 15px !important;   height: 15px !important;    opacity: 0.4;"
                            src="./assets/realtype/footwear.png">
                        <div class="d-flex">
                            <a class="pintrest-icon"
                                *ngIf="snapshot.FootwearID != undefined && snapshot.FootwearID != '' && showPintrestIcon(snapshot.FootwearID)"
                                (click)="shareOnPinterest(snapshot.FootwearID,'footwear',snapshot.SnapshotFileUrl,snapshot.StoryID)">
                                <img style="width:20px !important; color:black" class="img-thumbnail2 "
                                    src="./assets/Pinterest-logo.png" />
                            </a>
                            <img *ngIf="getGarmentrealtype(snapshot.FootwearID) == '3'" class="rounded-circle m-1"
                                [src]="getBrandImageByGarmetnID(snapshot.FootwearID)"
                                class="rounded profileImg-brand m-1"
                                style="width: 25px !important;   height: 25px !important;" />
                        </div>
                    </div>&nbsp;
                </div>
                <div class="">
                    <div class="col-12 col-sm-12 slider-box">



                        <div class="col-12 col-sm-12 d-flex" *ngIf="filterSnapShotFileDetails">
                            <div class="col-2 col-sm-2  ">
                                <img class="m-1" (click)="openBrandPoup(snapshot.HeadwearID)" data-bs-toggle="modal"
                                    style="width:45px; height:45px !important; color:black;"
                                    *ngIf="getGarmentrealtype(snapshot.HeadwearID) == '0' "
                                    class="rounded profileImg-brand"
                                    [src]="getBrandImageByGarmetnID(snapshot.HeadwearID)" />
                            </div>
                            <div class="col-8 col-sm-8 clothName p-1 "
                                *ngIf="snapshot.HeadwearID != undefined && snapshot.HeadwearID != ''">
                                <img class="gar-icon" *ngIf="getGarmentrealtype(snapshot.HeadwearID) == '0' "
                                    style="width:15px !important; color:black ; opacity: 0.4;"
                                    src="./assets/newsfeed/headgear-new.png">
                                <span class="caption-values"
                                    *ngIf="snapshot.HeadwearID != undefined && snapshot.HeadwearID != ''">
                                    <span class="garment-name" data-toggle="tooltip" data-placement="top"
                                        title="Cloth Details"
                                        (click)="openGarmentPopup('HeadwearID',snapshot.HeadwearID)">
                                        {{getGarmentName(snapshot.HeadwearID).substring(0,32)}}
                                    </span>&nbsp;
                                </span>
                            </div>



                        </div>
                        <div class="col-12 col-sm-12 d-flex " *ngIf="filterSnapShotFileDetails">


                            <div class="col-2 col-sm-2">
                                <img class="m-1" (click)="openBrandPoup(snapshot.UpperwearID)" data-bs-toggle="modal"
                                    style="width:45px; height:45px !important; color:black ; "
                                    *ngIf="getGarmentrealtype(snapshot.UpperwearID) == '1' "
                                    class="rounded profileImg-brand"
                                    [src]="getBrandImageByGarmetnID(snapshot.UpperwearID)" />
                                <img class="m-1" (click)="openBrandPoup(snapshot.UpperwearID)" data-bs-toggle="modal"
                                    data-bs-target="#brand-details" (click)="openBrandPoup(snapshot.UpperwearID)"
                                    data-bs-toggle="modal" style="width:45px; height:45px !important; color:black ;"
                                    *ngIf="getGarmentrealtype(snapshot.UpperwearID) == '5' "
                                    class="rounded profileImg-brand"
                                    [src]="getBrandImageByGarmetnID(snapshot.UpperwearID)" />
                            </div>



                            <div class="col-8 col-sm-8 clothName p-1"
                                *ngIf="snapshot.UpperwearID != undefined && snapshot.UpperwearID != ''">
                                <img class="gar-icon" *ngIf="getGarmentrealtype(snapshot.UpperwearID) == '1' "
                                    style="width:15px !important; color:black; opacity: 0.4;"
                                    src="./assets/realtype/upperwear.png">
                                <img class="gar-icon" *ngIf="getGarmentrealtype(snapshot.UpperwearID) == '5' "
                                    style="width:15px !important; color:black ; opacity: 0.4;"
                                    src="./assets/realtype/fullbody.png">
                                <span class="caption-values"
                                    *ngIf="snapshot.UpperwearID != undefined && snapshot.UpperwearID != ''">
                                    <span class="garment-name" data-toggle="tooltip" data-placement="top"
                                        title="Cloth Details"
                                        (click)="openGarmentPopup('UpperwearID',snapshot.UpperwearID)">
                                        {{getGarmentName(snapshot.UpperwearID).substring(0,32)}}
                                    </span>&nbsp;
                                </span>
                            </div>



                        </div>
                        <div class="col-12 col-sm-12 d-flex" *ngIf="filterSnapShotFileDetails">
                            <div class="col-2 col-sm-2 ">
                                <img class="m-1" (click)="openBrandPoup(snapshot.BottomwearID)" data-bs-toggle="modal"
                                    style="width:45px; height:45px !important; color:black ; "
                                    *ngIf="getGarmentrealtype(snapshot.BottomwearID) == '2'"
                                    class="rounded profileImg-brand"
                                    [src]="getBrandImageByGarmetnID(snapshot.BottomwearID)" />
                            </div>
                            <div class="col-8 col-sm-8 clothName p-1"
                                *ngIf="snapshot.BottomwearID != undefined && snapshot.BottomwearID != ''">
                                <img class="gar-icon" *ngIf="getGarmentrealtype(snapshot.BottomwearID) == '2'"
                                    style="width:15px !important; color:black ; opacity: 0.4;"
                                    src="./assets/newsfeed/bottomwear-new.png">
                                <span class="caption-values"
                                    *ngIf="snapshot.BottomwearID != undefined && snapshot.BottomwearID != ''">
                                    <span class="garment-name" data-toggle="popover" data-placement="left"
                                        title="Cloth Details"
                                        (click)="openGarmentPopup('BottomwearID',snapshot.BottomwearID)">
                                        {{getGarmentName(snapshot.BottomwearID).substring(0,32)}}
                                    </span>&nbsp;
                                </span>
                            </div>
                        </div>


                        <div class="col-12 col-sm-12 d-flex" *ngIf="filterSnapShotFileDetails">
                            <div class="col-2 col-sm-2 ">

                                <img class="m-1" (click)="openBrandPoup(snapshot.FootwearID)" data-bs-toggle="modal"
                                    style="width:45px; height:45px !important; color:black ;"
                                    *ngIf="getGarmentrealtype(snapshot.FootwearID) == '3'"
                                    class="rounded profileImg-brand"
                                    [src]="getBrandImageByGarmetnID(snapshot.FootwearID)" />

                            </div>

                            <div class="col-8 col-sm-8 clothName p-1"
                                *ngIf="snapshot.FootwearID != undefined && snapshot.FootwearID != ''">
                                <img class="gar-icon" *ngIf="getGarmentrealtype(snapshot.FootwearID) == '3' "
                                    style="width:15px !important; color:black ; opacity: 0.4;"
                                    src="./assets/realtype/footwear.png">
                                <span class="caption-values"
                                    *ngIf="snapshot.FootwearID != undefined && snapshot.FootwearID != ''">
                                    <span class="garment-name" data-toggle="popover" data-placement="left"
                                        title="Cloth Details"
                                        (click)="openGarmentPopup('FootwearID',snapshot.FootwearID)">
                                        {{getGarmentName(snapshot.FootwearID).substring(0,32)}}
                                    </span>&nbsp;
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade imp-bg" id="Envitonment-profile" tabindex="-1" aria-labelledby="Avatar-profile"
        aria-hidden="true" data-bs-backdrop="false">
        <div class="modal-dialog">
            <div class="modal-content imp-block" *ngIf="filterEnvironmentSnpShot">
                <h5 type="button" (click)="closeEnviro()" class="btn-close closBtn" data-bs-dismiss="modal"
                    aria-label="Close"></h5>
                <div class="container">
                    <div class="row text-center">
                        <div class="row text-center p-2">
                            <div class="col-12 m-3 justify-content-center text-center"
                                *ngIf="evnFileListFileName && evnFileListFileName['displayName']">
                                <div class="col-12 justify-content-center">
                                    <div class="col-12 justify-content-center">
                                        <h2 class="evn-name "
                                            (click)="getEnvironmentProfile(evnFileListFileName['envFileName'])"
                                            data-bs-dismiss="modal">{{evnFileListFileName['displayName']}}</h2>
                                    </div>
                                    <div>
                                        <img style="width: 350px; height:200px !important;;"
                                            class="w-80 shadow-1-strong rounded p-1" data-bs-dismiss="modal"
                                            src="{{twoDprivew}}">
                                    </div>
                                    <div class=" col-12 text-center justify-content-center d-grid">
                                        <div class="text-center justify-content-center"
                                            *ngIf="twoDprivew != undefined && gltfImgSrc != undefined">
                                            <model-viewer id="poster" ar auto-rotate camera-orbit="45deg 55deg 2.5m"
                                                camera-controls touch-action="pan-y"
                                                style="height: 400px; width: 350px; float: none;"
                                                data-js-focus-visible="false"
                                                alt="A 3D model of a sphere reflecting a sunrise"
                                                [attr.src]="gltfImgSrc" attr.skybox-image={{twoDprivew}}
                                                shadow-intensity="1">
                                            </model-viewer>
                                        </div>
                                    </div>
                                </div>
                                <div class="row w-100 m-2"
                                    *ngIf="filterEnvironmentSnpShot.length >= 0  && filterEnvironmentSnpShot != undefined && filterEnvironmentSnpShot.length >= 0 ">
                                    <div class="column d-block m-1"
                                        *ngFor="let snapshot of filterEnvironmentSnpShot; let i=index;">
                                        <img class="w-100 shadow-1-strong rounded" data-bs-dismiss="modal"
                                            (click)="getFeed(snapshot,brandDetails)" [src]="snapshot.SnapshotFileUrl">
                                    </div>
                                    <div class="columnImg d-block m-1">
                                        <img class="w-80 shadow-1-strong rounded" data-bs-dismiss="modal">
                                    </div>
                                    <div class="columnImg d-block m-1">
                                        <img class="w-80 shadow-1-strong rounded" data-bs-dismiss="modal">
                                    </div>
                                    <div class="columnImg d-block m-1" *ngIf="filterEnvironmentSnpShot.length > 2"
                                        (click)="getEnvironmentProfile(evnFileListFileName['envFileName'])">
                                        <img style="margin-top: -165px;" class="w-80 shadow-1-strong rounded"
                                            data-bs-dismiss="modal" src="./assets/plus-8-512.png">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade imp-bg" id="Avatar-profile" tabindex="-1" aria-labelledby="Avatar-profile" aria-hidden="true"
        data-bs-backdrop="false">
        <div class="modal-dialog  ">
            <div class="modal-content imp-block" *ngIf="filterSnapShotForProfile">
                <h5 type="button" class="btn-close  closBtn" data-bs-dismiss="modal" aria-label="Close"></h5>
                <div class="container">
                    <div class="row text-center">
                        <div class="row text-start p-2">
                            <div class="col-12 m-3">
                                <div *ngIf="popupLoadingIMg" class="spinner-border m-2 p-2" role="status">
                                    <span class="sr-only"></span>
                                </div>
                                <img *ngIf="!popupLoadingIMg" style="width: 70px; height:70px !important;"
                                    class="rounded-circle" data-bs-dismiss="modal"
                                    (click)="getUserSnapShotDashboar(profileDetails.uid)"
                                    src="{{profileDetails.photoURL}}" />

                                <h2 class="w-100" data-bs-dismiss="modal"
                                    (click)="getUserSnapShotDashboar(profileDetails.uid)">
                                    {{profileDetails.displayName}} </h2>

                                <a data-bs-dismiss="modal"
                                    (click)="getProfileByAvatorpage(selectedAvtar)">🧍🏽{{selectedAvtar}} </a>
                                <div><button type="button" class="btn btn-outline-info m-1">Follow</button></div>

                                <!-- <div><button type="button" class="btn btn-outline-info m-1" data-bs-dismiss="modal"
                                        (click)="getProfileByAvatorpagfeed(selectedAvtar)">Avatar
                                        Feed</button></div> -->

                            </div>
                            <div>
                                <div *ngIf="popupLoadingFor2D" class="d-flex justify-content-center">
                                    <div class="spinner-border m-5 p-3" role="status">
                                        <span class="sr-only"></span>
                                    </div>
                                </div>
                                <div *ngIf="!popupLoadingFor2D" class="row d-flex">
                                    <div data-bs-dismiss="modal" class="text-center col-6">
                                        <span>
                                            <div>
                                                <img class="img-fluid img-thumbnail" style="height: 100px; width: 100px !important; object-fit: contain;
                                        " src="./assets/imgplaceholder.jpg">
                                            </div>
                                        </span>
                                    </div>
                                    <div data-bs-dismiss="modal" class="text-center col-6">
                                        <span>
                                            <div>
                                                <img class="img-fluid img-thumbnail" style="height: 100px; width: 100px !important; object-fit: contain;
                                        " src="./assets/imgplaceholder1.jpg">
                                            </div>
                                        </span>
                                    </div>
                                </div>
                                <div class="row w-100 m-2"
                                    *ngIf="!popupLoading && filterSnapWitApi.length >= 0  && filterSnapWitApi != undefined && filterSnapWitApi.length >= 0 ">
                                    <div class="column d-block m-1"
                                        *ngFor="let snapshot of filterSnapWitApi  ; let i=index;">
                                        <img class="w-100 shadow-1-strong rounded" data-bs-dismiss="modal"
                                            (click)="getSelectSnapshotAvatarFeed(snapshot)"
                                            [src]="snapshot.SnapshotFileUrl">
                                    </div>
                                    <div class="columnImg d-block m-1">
                                        <img class="w-80 shadow-1-strong rounded" data-bs-dismiss="modal">
                                    </div>
                                    <div class="columnImg d-block m-1">
                                        <img class="w-80 shadow-1-strong rounded" data-bs-dismiss="modal">
                                    </div>
                                    <div class="columnImg d-block m-1" *ngIf="filterSnapWitApi.length > 2"
                                        (click)="getProfileByAvatorpage(selectedAvtar)">
                                        <img style="margin-top: -165px; margin-left: 50px;"
                                            class="w-80 shadow-1-strong rounded" data-bs-dismiss="modal"
                                            src="./assets/plus-8-512.png">
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="popupLoading" class="d-flex justify-content-center">
                                <div class="spinner-border m-5 p-4" role="status">
                                    <span class="sr-only"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" id="garment-details" tabindex="-1" aria-labelledby="garment-details" aria-hidden="true"
        data-bs-backdrop="false">
        <div class="modal-dialog" id="garment-details-">
            <div class="modal-content imp-block-garment">
                <div class="modal-body">
                    <h5 type="button" class="btn-close  closBtn" data-bs-dismiss="modal" aria-label="Close"></h5>
                    <div class="container">
                        <div class="row text-center justify-content-center">
                            <div class="row text-center p-2 justify-content-center">
                                <div class="col-12 m-2 p-1 details" *ngIf="clothDetails.BrandID != undefined">
                                    <div class=" text-center w-100 m-1 d-flex ">
                                        <div class="d-flex  col-4">
                                            <img data-bs-dismiss="modal"
                                                (click)="getBrandProfileByGarment(clothDetails.BrandID,brandDetails.boardName)"
                                                class="rounded profileImg-brand"
                                                style="width:35px !important; height:35px !important;"
                                                [src]="getBrandImage(clothDetails.LogoURL)">
                                        </div>
                                        <div class="col-4">
                                            <b data-bs-dismiss="modal"
                                                (click)="getBrandProfileByGarment(clothDetails.BrandID,clothDetails.boardName)">{{clothDetails.BrandName}}</b>
                                            <b>
                                                <h6 class="color-gender"> {{clothDetails.Gender == 'F' ? 'Womenswear' :
                                                    (clothDetails.Gender == 'M') ? 'Menswear' : 'N/A'}}</h6>
                                            </b>
                                        </div>
                                        <div class="col-4 text-end">
                                            <button type="button"
                                                class="btn btn-sm btn-outline-info follow m-1">Follow</button>
                                        </div>

                                    </div>
                                    <b>
                                        <div></div>
                                        <h5 class="text-black clothName color-class-band" data-bs-dismiss="modal"
                                            (click)="getClothProfile(clothDetails.BrandID)"
                                            [ngClass]="clothDetails.Name.length >= 20  ? 'class-font' : ''">
                                            <span *ngIf="clothDetails.RealType == '0'" class="brandbtn py-1"><img
                                                    style="width:16px !important; color:black ;padding-bottom: 3px;"
                                                    src="./assets/realtype/headgear.png"></span>
                                            <span *ngIf="clothDetails.RealType == '1'" class="brandbtn py-1"><img
                                                    style="width:16px !important; color:black ;padding-bottom: 3px;"
                                                    src="./assets/realtype/upperwear.png"></span><span
                                                *ngIf="clothDetails.RealType == '2'" class="brandbtn py-1"><img
                                                    style="width:16px !important; color:black ;padding-bottom: 5px;"
                                                    src="./assets/realtype/bottomwear.png"></span><span
                                                *ngIf="clothDetails.RealType == '3'" class="brandbtn py-1"><img
                                                    style="width:16px !important; color:black ;padding-bottom: 3px;"
                                                    src="./assets/realtype/footwear.png"></span><span
                                                *ngIf="clothDetails.RealType == '4'" class="brandbtn py-1"><img
                                                    style="width:16px !important; color:black ;padding-bottom: 3px;"
                                                    src="./assets/realtype/heeledfootwear.png"></span><span
                                                *ngIf="clothDetails.RealType == '5'" class="brandbtn py-1"><img
                                                    style="width:16px !important; color:black ;padding-bottom: 3px;"
                                                    src="./assets/realtype/fullbody.png"></span>
                                            {{(clothDetails.Name)}}
                                        </h5>
                                    </b>
                                    <div *ngIf="popupLoadingFor2D" class="d-flex justify-content-center">
                                        <div class="spinner-border m-5 p-3" role="status">
                                            <span class="sr-only"></span>
                                        </div>
                                    </div>
                                    <span *ngIf="!popupLoadingFor2D">
                                        <h6 data-bs-dismiss="modal" (click)="getClothProfile(clothDetails.BrandID)">
                                            Collection:
                                            {{clothDetails.CollectionName}}</h6>
                                        <h6 data-bs-dismiss="modal" (click)="getClothProfile(clothDetails.BrandID)">
                                            Details:
                                            {{clothDetails.Details}}</h6>
                                        <h6 data-bs-dismiss="modal" (click)="getClothProfile(clothDetails.BrandID)">SKU:
                                            {{clothDetails.SKU}}</h6>
                                    </span>
                                </div>
                                <div *ngIf="popupLoadingFor2D" class="d-flex justify-content-center">
                                    <div class="spinner-border m-5 p-3" role="status">
                                        <span class="sr-only"></span>
                                    </div>
                                </div>
                                <div class="row" *ngIf="!popupLoadingFor2D">
                                    <div data-bs-dismiss="modal" (click)="getClothProfile(clothDetails.BrandID)"
                                        class="text-center col-6" *ngFor="let fileList of userFiledetails;let p=index">
                                        <span *ngIf="p < counter">
                                            <div *ngIf="fileList.fileType == 'GarmentUpload2D'">
                                                <img class="img-fluid img-thumbnail" style="height: 100px; width: 100px !important; object-fit: contain;
                                            " src={{fileList.fileurl}}>
                                            </div>
                                            <div class="justify-content-center col-6 "
                                                *ngIf="fileList.fileType == 'GarmentUpload3D'">
                                                <div class="text-center col-6 ">
                                                    <model-viewer camera-controls camera-orbit="45deg 55deg 2.5m" ar
                                                        auto-rotate src={{fileList.fileurl}}
                                                        alt="A 3D avatar-model-view" data-js-focus-visible=""
                                                        ar-status="not-presenting"
                                                        style="height: 100px; width: 150px; float: none;">
                                                    </model-viewer>
                                                </div>
                                            </div>
                                        </span>
                                    </div>
                                </div>
                                <div class="p-2 m-1 text-center d-flex justify-content-center">
                                    <h6 data-bs-dismiss="modal" class="text-white brandbtn m-1"
                                        (click)="getStoryCloth(clothDetails)">
                                        <img style="width:15px !important; color:black"
                                            src="./assets/storystyle/story-white.png">
                                    </h6>
                                    <h6 data-bs-dismiss="modal" (click)="getgarmentStyle(brandDetails.id,clothDetails)"
                                        class="text-white brandbtn m-1">
                                        <img style="width:18px !important; color:black"
                                            src="./assets/storystyle/style-white.png">
                                    </h6>
                                    <h6 *ngIf="clothDetails.ThumbCenterURL  != undefined" class="m-1 brandbtn"><a
                                            href="{{clothDetails.ThumbCenterURL}}" target="_blank"><img
                                                style="width:15px !important; color:black"
                                                src="./assets/details.png"></a>
                                    </h6>
                                    <h6 *ngIf="clothDetails.ThumbCenterURL  == undefined" class="m-1 brandbtn"><a><img
                                                style="width:15px !important; color:black"
                                                src="./assets/details-gray.png"></a> </h6>

                                    <h6 *ngIf="clothDetails.ThumbLeftURL  != undefined" class="m-1 brandbtn"><a
                                            href="{{clothDetails.ThumbLeftURL}}" target="_blank"><img
                                                style="width:15px !important" src="./assets/gallary.png"></a></h6>

                                    <h6 *ngIf="clothDetails.ThumbLeftURL  == undefined" class="m-1 brandbtn"><a><img
                                                style="width:15px !important" src="./assets/gallary-gray.png"></a></h6>
                                    <h6 *ngIf="clothDetails.ThumbRightURL  != undefined" class="m-1 brandbtn"><a
                                            href="{{clothDetails.ThumbRightURL}}" target="_blank"><img
                                                style="width:15px !important; color:black" src="./assets/shop.png"></a>
                                    </h6>
                                    <h6 *ngIf="clothDetails.ThumbRightURL  == undefined" class="m-1 brandbtn"><a><img
                                                style="width:15px !important; color:black"
                                                src="./assets/cart-gray.png"></a>
                                    </h6>
                                </div>

                                <!-- <div class="p-2 m-2 justify-content-center text-center d-flex"
                                *ngIf="clothDetails != undefined">
                                <h6 data-bs-dismiss="modal" class="text-white brandbtn m-2"
                                    (click)="getStoryCloth(clothDetails)">Story</h6>
                                <h6 data-bs-dismiss="modal" (click)="getgarmentStyle(brandDetails,clothDetails)"
                                    class="text-white brandbtn m-2">Style</h6>
                            </div> -->

                                <div *ngIf="popupLoadingFor2D" class="d-flex justify-content-center">
                                    <div class="spinner-border m-5 p-5" role="status">
                                        <span class="sr-only"></span>
                                    </div>
                                </div>
                                <div class="row w-100 m-1"
                                    *ngIf=" !popupLoadingFor2D && clothShapShotsList.length >= 0  && clocthCollectionIdcollection != undefined && clocthCollectionIdcollection.length >= 0 ">
                                    <div class="column d-block m-1"
                                        *ngFor="let snapshot of clothShapShotsList  ; let i=index;">
                                        <img class="w-100 shadow-1-strong rounded" data-bs-dismiss="modal"
                                            *ngIf="clothDetails.BrandID != undefined"
                                            (click)="getgarmentStylefeed(brandDetails,clothDetails)"
                                            [src]="snapshot.SnapshotFileUrl">
                                    </div>
                                    <div class="columnImg d-block m-1">
                                        <img class="w-80 shadow-1-strong rounded" data-bs-dismiss="modal">
                                    </div>
                                    <div class="columnImg d-block m-1">
                                        <img class="w-80 shadow-1-strong rounded" data-bs-dismiss="modal">
                                    </div>
                                    <div class="columnImg d-block m-1" *ngIf="clothShapShotsList.length > 8"
                                        (click)="getClothProfile(clothDetails.BrandID)">
                                        <img style="margin-top: -165px;" class="w-80 shadow-1-strong rounded"
                                            data-bs-dismiss="modal" src="./assets/plus-8-512.png">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade imp-bg" id="brand-details" tabindex="-1" aria-labelledby="brand-details" aria-hidden="true"
        data-bs-backdrop="false">
        <div class="modal-dialog  ">
            <div class="modal-content imp-block-brand" *ngIf="brandDetails && brandDetails.BrandName">
                <h5 type="button" class="btn-close  closBtn" data-bs-dismiss="modal" aria-label="Close"></h5>
                <div class="container">
                    <div class="row text-center">
                        <div class="row text-center p-2">
                            <div class="col-12 m-3">
                                <img style="width: 75px; height:75px !important;" class="rounded profileImg-brand p-1"
                                    data-bs-dismiss="modal"
                                    (click)="getBrandProfile(brandDetails.id,brandDetails.boardName)"
                                    [src]="getBrandImage(brandDetails.LogoURL)" />
                                <h2 class="w-100" data-bs-dismiss="modal"
                                    (click)="getBrandProfile(brandDetails.id,brandDetails.boardName)">
                                    {{(brandDetails.BrandName)}}</h2>

                                <div><button type="button" class="btn btn-outline-info m-2 follow">Follow</button></div>
                                <h6><img style="width:15px !important" src="./assets/pinterest-512.png">:
                                    {{brandDetails.boardName}} </h6>

                            </div>
                            <div class="p-1 text-center d-flex justify-content-center">
                                <h6 class="m-1 text-white brandbtn"
                                    [ngClass]="brandDetails.id == brandId  ? 'border-bottom border-light' : 'border-bottom border-light'">
                                    <b>Story</b>
                                </h6>
                                <h6 data-bs-dismiss="modal" (click)="getBrandStyle(brandDetails.id,'M')"
                                    class="m-1 text-white brandbtn">Menswear</h6>
                                <h6 data-bs-dismiss="modal" (click)="getBrandStyle(brandDetails.id,'F')"
                                    class="m-1 text-white brandbtn">Womenswear</h6>
                            </div>
                            <div *ngIf="brandsnaploading" class="text-center">
                                <div class="spinner-border m-5" role="status">
                                    <span class="sr-only"></span>
                                </div>
                            </div>
                            <div class="row w-100 m-2"
                                *ngIf="!brandsnaploading && brandhShapShotsList.length >= 0  && brandCollectionIdcollection != undefined && brandCollectionIdcollection.length >= 0 ">
                                <div class="column d-block m-1"
                                    *ngFor="let snapshot of brandhShapShotsList  ; let i=index;">
                                    <img class="w-100 shadow-1-strong rounded" data-bs-dismiss="modal"
                                        (click)="getFeed(snapshot,brandDetails)" [src]="snapshot.SnapshotFileUrl">
                                </div>
                                <div class="columnImg d-block m-1">
                                    <img class="w-80 shadow-1-strong rounded" data-bs-dismiss="modal">
                                </div>
                                <div class="columnImg d-block m-1">
                                    <img class="w-80 shadow-1-strong rounded" data-bs-dismiss="modal">
                                </div>
                                <div class="columnImg d-block m-1" *ngIf="brandhShapShotsList.length > 8"
                                    (click)="getBrandProfile(brandDetails.id,brandDetails.boardName)">
                                    <img style="margin-top: -165px;" class="w-80 shadow-1-strong rounded"
                                        data-bs-dismiss="modal" src="./assets/plus-8-512.png">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade imp-bg" id="user-profile" tabindex="-1" aria-labelledby="user-profile" aria-hidden="true"
        data-bs-backdrop="false">
        <div class="modal-dialog">
            <div class="modal-content imp-block" *ngIf="filterSnapShotForProfile">
                <h5 type="button" class="btn-close  closBtn" data-bs-dismiss="modal" aria-label="Close"></h5>
                <div class="container">
                    <div class="row text-center">
                        <div class="row text-start p-2">
                            <div class="col-12 m-3">
                                <div *ngIf="popupLoadingIMg" class="spinner-border m-2 p-2" role="status">
                                    <span class="sr-only"></span>
                                </div>
                                <img *ngIf="!popupLoadingIMg" style="width: 70px; height:70px !important;"
                                    class="rounded-circle" data-bs-dismiss="modal"
                                    (click)="getUserSnapShotDashboar(profileDetails.uid)"
                                    src="{{profileDetails.photoURL}}" />
                                <h2 class="w-100" data-bs-dismiss="modal" (click)="
                                     getUserSnapShotDashboar(profileDetails.uid)"
                                    (click)="getUserSnapShotDashboar(profileDetails.uid)">
                                    {{profileDetails.displayName}}</h2>
                                <div><button type="button" class="btn btn-outline-info m-1 follow">Follow</button></div>
                            </div>

                            <div>
                                <div *ngIf="popupLoadingFor2D" class="d-flex justify-content-center">
                                    <div class="spinner-border m-5 p-3" role="status">
                                        <span class="sr-only"></span>
                                    </div>
                                </div>
                                <div *ngIf="!popupLoadingFor2D" class="row d-flex">
                                    <div data-bs-dismiss="modal" class="text-center col-6">
                                        <span>
                                            <div>
                                                <img class="img-fluid img-thumbnail" style="height: 100px; width: 100px !important; object-fit: contain;
                                        " src="./assets/imgplaceholder.jpg">
                                            </div>
                                        </span>
                                    </div>
                                    <div data-bs-dismiss="modal" class="text-center col-6">
                                        <span>
                                            <div>
                                                <img class="img-fluid img-thumbnail" style="height: 100px; width: 100px !important; object-fit: contain;
                                        " src="./assets/imgplaceholder1.jpg">
                                            </div>
                                        </span>
                                    </div>
                                </div>
                                <div class="row w-100 m-2"
                                    *ngIf="!popupLoading && filterSnapWitApi.length >= 0  && filterSnapWitApi != undefined && filterSnapWitApi.length >= 0 ">
                                    <div class="column d-block m-1"
                                        *ngFor="let snapshot of filterSnapWitApi  ; let i=index;">
                                        <img class="w-100 shadow-1-strong rounded" data-bs-dismiss="modal"
                                            (click)="getSelectSnapshotFeed(snapshot)" [src]="snapshot.SnapshotFileUrl">
                                    </div>
                                    <div class="columnImg d-block m-1">
                                        <img class="w-80 shadow-1-strong rounded" data-bs-dismiss="modal">
                                    </div>
                                    <div class="columnImg d-block m-1">
                                        <img class="w-80 shadow-1-strong rounded" data-bs-dismiss="modal">
                                    </div>
                                    <div class="columnImg d-block m-1"
                                        *ngIf="filterSnapWitApi.length > 2 && profileDetails.uid"
                                        (click)="getUserSnapShotDashboar(profileDetails.uid)">
                                        <img style="margin-top: -165px;  
                                               margin-left: 31px;" class="w-80 shadow-1-strong rounded image-pop"
                                            data-bs-dismiss="modal" src="./assets/plus-8-512.png">
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="popupLoading" class="d-flex justify-content-center">
                                <div class="spinner-border m-5 p-4" role="status">
                                    <span class="sr-only"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade imp-bg" id="fileView-profile" tabindex="-1" aria-labelledby="user-profile" aria-hidden="true"
    data-bs-backdrop="false">
    <div class="modal-dialog justify-content-center ">
        <div class="modal-content imp-block justify-content-center">
            <h5 type="button" class="btn-close  closBtn" data-bs-dismiss="modal" aria-label="Close"></h5>
            <img src={{showImages.fileurl}}>
        </div>
    </div>
</div>