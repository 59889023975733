<app-yologram-social *ngIf="logInFlag"></app-yologram-social>
<div class="card">
  <!-- <div class="card-header font-bold">List of Files</div> -->
  <div class="dt-responsive selectlist font-14">
    <div class="dataTables_wrapper dt-bootstrap4" id="add-row-table_wrapper">
      <div class="p-3 d-flex">
        <div class="p-3"><select (change)="selectActionGroupId($event)" class="form-select ">
            <option value="">Select Group</option>
            <option [value]="group.id" *ngFor="let group of securityGroupAll ;            
                let i=index">{{group.name}}</option>
          </select>
        </div>
        <div class="d-flex" *ngIf="selectedSecGpId != undefined ">
          <div class="p-3">
            <input type="text" class="form-control" placeholder="Send Notifiacation" [(ngModel)]="message">
          </div>
          <div class="p-3">
            <button class="btn btn-primary" (click)="sendNotificationToSecGrpUsers()">Send</button>
          </div>
          <span class="alert alert-success" *ngIf="SentAlert">Message Sent Successfully</span>
        </div>
      </div>
      <div class="col-8 p-3 m-2" *ngIf="!loading && securityGroupUserList.length > 0">
        <table class="table " id="dataTable" width="100%" cellspacing="0">
          <thead class="custom-header">
            <tr role="row">
              <th>Sr. No.</th>
              <th>User Id</th>
              <th>Email Id</th>
            </tr>
          </thead>
          <tbody>
            <tr class="row-list-detail" *ngFor="let user of securityGroupUserList;let i=index">
              <td>{{i+1}}</td>
              <td class="">{{user.profileName}}</td>
              <td class="catalog-info ">{{user.email}}
            </td>
          
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
