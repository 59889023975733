<div *ngIf="loading" class="loading">&#8230;</div>
<app-yologram-social *ngIf="logInFlag"></app-yologram-social>

<div>
  <div class="dt-responsive selectlist font-14">
    <div class="dataTables_wrapper dt-bootstrap4" id="add-row-table_wrapper">
      <div *ngIf="assignBrandList.length != 0" class="p-3 d-flex flex-column flex-sm-row">
        <div class="row">
          <div class="p-3 avtar-dropdown">
            <select class="form-select" (change)="getfilterBrandId($event)">
              <option value="">Select Brand</option>
              <option value="Default">Default</option>
              <option [value]="brand.BrandId" *ngFor="let brand of assignBrandList">
                {{(getBrandDetails(brand?.BrandId)?.BrandName) != undefined ?
                (getBrandDetails(brand?.BrandId)?.BrandName) : 'Default'}}</option>
            </select>
          </div>
          <div class="p-3 avtar-dropdown" *ngIf="this.selectedbrandId != undefined">
            <select class="form-select" (change)="filterByGender($event)">
              <option value="">Select Gender</option>
              <option value="MALE">Male</option>
              <option value="FEMALE">Female</option>
            </select>
          </div>
          
        </div>
        <div class="avtar-drop_down" *ngIf="this.gender != undefined">
          <select class="form-select" (change)="getfilterAvatrId($event)">
            <option value="">Select Avatar</option>
            <option [value]="avatar.avatarID" *ngFor="let avatar of filterAvatarList" (click)="getfilterNew(avatar)">
              {{avatar.AvatarName}} ({{avatar.avatarID}})
            </option>
          </select>
        </div>
        <div class="row">
          <div class="p-3" *ngIf="this.selectedAvatarId != undefined">
            <button class="btn btn-primary m-1" (click)="avatarOpen()">Veiw</button>
            <button class="btn btn-primary m-1" (click)="addAvatraDetailsInCollection()">Submit</button>
          </div>
        </div>
      </div>
      <!-- <div class="container">
        <div class="row">
          <div class="col-md-6" *ngIf="this.avtarFlag">
            <div class="m-2 p-2">
              <div style="height: 300px; width:260px;">
                <app-news3-d-templete [GarmentsGender]="genders" [EnvUrl]="defaultEnvironment"
                  [upperwearUrl]="garmenturl" [FunctionFlag]="functionflagValue" [bottomwearUrl]="garmentbottom"
                  [AvatarUrl]="avtarUrl" [headgearUrl]="headgear" [footwearUrl]="footwear"
                  (calledVlueIntilized)="calledVlueIntilized($event)">
                </app-news3-d-templete>
              </div>
            </div>
          </div>
          <div class="m-2 p-2" *ngIf="flagforview && newblobData">
            <div *ngFor="let pair of newblobData | keyvalue">
              <img [src]="sanitizer.bypassSecurityTrustUrl(pair.value)" alt="Blob Image">
            </div>
          </div>
          

          <div class="col-md-6 mt-5" *ngIf="avatrPreview?.FullBodyPreviewUrl && !flagforview">
            <div class="m-2 p-2">
              <div style="height: 300px; width:260px;">
                <img [src]="avatrPreview?.FullBodyPreviewUrl" class="img-fluid" alt="Full Body Preview">
              </div>
            </div>
            <div class="m-2 p-2" *ngIf="avatrPreview?.PortraitPreviewUrl">
              <div style="height: 300px; width:260px;">
                <img [src]="avatrPreview?.PortraitPreviewUrl" class="img-fluid" alt="Portrait Preview">
              </div>
            </div>
          </div>
        </div>
      </div> -->



      <div class="container">

        <div class="row col-12" >
          <div class="col-md-4 col-sm-4" >
            <div style=" width:260px;"  [ngClass]="avtarFlag ? '' : 'd-none'" >
              <app-news3-d-templete [GarmentsGender]="genders" [EnvUrl]="defaultEnvironment" [upperwearUrl]="garmenturl"
                [FunctionFlag]="functionflagValue" [bottomwearUrl]="garmentbottom" [AvatarUrl]="avtarUrl"
                [headgearUrl]="headgear" [footwearUrl]="footwear" (calledVlueIntilized)="calledVlueIntilized($event)">
              </app-news3-d-templete>
            </div>
          </div>

          <!-- <div class="col-md-8  col-sm-8" *ngIf="flagforview && blobUrls" style="display: flex; flex-wrap: wrap;">
              <div *ngFor="let pair of blobUrls | keyvalue" style="margin-right: 10px;">
                <img [src]="sanitizer.bypassSecurityTrustUrl(pair)" alt="Blob Image" style="max-width: 100%; height: auto;">
              </div>
            </div> -->

          <div class="col-md-8  col-sm-8" *ngIf="flagforview && blobUrls" style="display: flex; flex-wrap: wrap;">
            <div style="margin-right: 10px;" *ngFor="let key of objectKeys(blobUrls)">
              <img class="m-2" [src]="blobUrls[key]" [alt]="key" alt="Blob Image"
                style="max-width: 100%; height: auto;">
            </div>
          </div>

          <div class="col-md-4  col-sm-4 mt-sm-4" *ngIf="avatrPreview?.FullBodyPreviewUrl">
            <div style="height: 300px; width:260px; margin-top: 50px;">
              <img [src]="avatrPreview?.FullBodyPreviewUrl" class="img-fluid" alt="Full Body Preview">
            </div>
          </div>
          <div class="col-md-4  col-sm-4">
            <div *ngIf="avatrPreview?.PortraitPreviewUrl">
              <div style="height: 300px; width:260px;">
                <img [src]="avatrPreview?.PortraitPreviewUrl" class="img-fluid" alt="Portrait Preview">
              </div>
            </div>
          </div>
        </div>
      </div>


      <!-- Avtar data -->

    </div>

    <div class="col-8 p-3 m-2">
      <table class="table " id="dataTable" width="100%" cellspacing="0">
        <thead class="custom-header">
          <tr role="row">
            <th class="avtarpage-font">Brand </th>
            <th class="avtarpage-font">Gender</th>
            <th class="avtarpage-font">Avatar</th>
            <th class="avtarpage-font">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr class="row-list-detail" *ngFor="let brand of userBrandAvatarFilterList ; let i=index">
            <td class="avtarpage-font">
              {{(getBrandDetails(brand?.BrandId)?.BrandName) != undefined ? (getBrandDetails(brand?.BrandId)?.BrandName)
              : 'Default'}}
            </td>
            <td class="avtarpage-font">{{brand.Gender}} </td>
            <td class="catalog-info avtarpage-font">
              <div class="form-check ">
                <!-- <img *ngIf= class="avatar-image" [src]="userAvatarDat(brand) | async"> -->
                <img class="avatar-image" [src]="brand.PortraitPreviewUrl">
                <!-- {{brand.avatarName}}
                {{brand.AvatarPreviewPortraitUrl}} -->
              
                {{brand.avatarName == undefined ? this.getDocumentDetailsAll(brand):
                brand.avatarName}}
                {{brand.AvatarId}}
              </div>
            </td>
            <td class="d-flex">
              <span class=""></span> <i (click)="deleteUserAvatarDetails(brand)" class="bi bi-trash-fill p-1"></i>
              <i class="bi bi-eye-fill p-1" (click)="passAvatar(brand)"></i>
            </td>

          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>