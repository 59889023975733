<h2 mat-dialog-title class="text-center">
  Share Story
</h2>
<mat-dialog-content class="mat-typography">
  <h4 class="text-center">Select</h4>
  <div class="m-2 justify-content-center ">
    <!-- <h6 >Share without frame </h6> -->
    <select class="form-select w-40" (change)="setTemplate($event) " >
      <option [value]="0" selected>Story</option>
      <option [value]="1" >BT</option>
      <option [value]="2" >IDGS </option>
      <option [value]="3" [disabled]="this.storyId.vrmURL == ''">Vrm</option>
    </select>
    <div class="m-2 text-center justify-content-center">
      <button (click)="shareStory()" class="btn btn-sm btn-primary">Share Story</button>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button class="btn btn-outline-secondary  btn-sm" mat-dialog-close>Close</button>
</mat-dialog-actions>


