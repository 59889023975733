import { Component, ElementRef, Input, ViewChild,Renderer2} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TwoDpopupComponent } from '../templets/twoDimagePopUp/twoDpopup';
import Swiper from 'swiper';
import { NavigationCard } from 'src/app/services/navigation-card-services';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';
import { CaraosalPopupComponent } from '../templets/caraosal-popup/caraosal-popup.component';

@Component({
  selector: 'app-slider-two-single',
  templateUrl: './slider-two-single.component.html',
  styleUrls: ['./slider-two-single.component.css']
})
export class SliderTwoSingleComponent {
  userFilesList = this.firestore.collection('userfiles').valueChanges({ idField: 'id' }) as Observable<any[]>;
  @Input() UserProfile: any;

  @Input() images: any = [];
  @Input() UserId: any;
  @Input() delete: boolean;
  @Input() deleteGarmentUpload: boolean;
  @Input() clothId: any;
  scrollingImages = [];
  itemWidth = 95;
  totalWidth = 780;
  ele:any;
  loading:boolean = false;
  userUploadedFileList: any = [];
  filelist: any = [];
  userFiledetails: any = [];

  @ViewChild('garmentDummySCroll', { read: ElementRef }) public garmentDummySCroll: ElementRef<any>;


  constructor(private modal: NgbModal,
    private firestore: AngularFirestore,

    ){
     

  }

  ngOnInit(): void {
   this.scrollingImages = this.images;
   this.userFilesList.subscribe((userFile) => {
    this.userUploadedFileList = userFile
    this.filelist = this.userUploadedFileList.filter((uploadlist) => uploadlist.fileType == 'UserUploads' )
    let filelist = this.filelist.filter((uploadlist) => uploadlist.userId == this.UserId)
    this.userFiledetails = filelist
});

  }
  
  ngAfterViewInit(){
    let scrollLeft = this.garmentDummySCroll.nativeElement
    this.inifiniteXScrolls(scrollLeft)
  }


  open2dPoup3(data) {
    const modalRef = this.modal.open(CaraosalPopupComponent, {
      modalDialogClass: "model-garmentPopup"
    });
    modalRef.componentInstance.deleteGarmentUpload = this.deleteGarmentUpload;
    modalRef.componentInstance.delete = this.delete;
    modalRef.componentInstance.UserProfile = this.UserProfile;
    modalRef.componentInstance.scrollingImages = this.scrollingImages;
    modalRef.componentInstance.imageID = data.id;
    modalRef.result.then((result) => {
      console.log(result);
    }).catch((error) => {
      console.log(error);
    });    
  }
//   scrollSlider() {
//     let scrollLeft = this.garmentDummySCroll.nativeElement.scrollLeft
//     if(scrollLeft  <  10) {
//       this.loading = true;
//       this.garmentDummySCroll.nativeElement.scrollLeft = this.itemWidth*(this.scrollingImages.length-1)

//         setTimeout(() => {
//             this.loading = true;
//          }, 1000);
//        }
    
 
//     if(scrollLeft  >  this.itemWidth*this.scrollingImages.length) {      
//       this.scrollingImages = [...this.scrollingImages,...Object.values(this.images)];
//             this.scrollingImages.push(...this.images) 

//     }
// }


// scroll()
// { 
//   let scrollLeft = this.garmentDummySCroll.nativeElement.scrollLeft
//   if(scrollLeft  <  10) {
//     this.loading = true;
//     this.garmentDummySCroll.nativeElement.scrollLeft = this.itemWidth*(this.scrollingImages.length-1)
//       setTimeout(() => {
//           this.loading = true;
//        }, 500);
//      }
   
//   if(scrollLeft >  this.scrollingImages.length*9 ){
//     this.scrollingImages.push(...this.images) 
//   }


// }
inifiniteXScrolls(el) {
  let lastScrollX = 0;
  let overlapCount = 0;
  let clones = [];
  while(el.children[overlapCount] && ((el.children[overlapCount].offsetLeft - el.offsetLeft)) <= el.getClientRects()[0].width) {
    const clone = el.children[overlapCount].cloneNode(true);
    clones.push(clone);
    el.appendChild(clone);
    overlapCount++;
  }
  el.scrollTo({left: lastScrollX});
  overlapCount -= 1;
  let lastScroll: number = el.scrollLeft = 1;
  function scrollHandler(): void {
    const maxScroll: number = el.scrollWidth - el.clientWidth;
    // console.log('maxScroll',maxScroll)
    const overlapOverflow =  
          (el.children[overlapCount].offsetLeft - el.offsetLeft) 
          - el.getClientRects()[0].width
          + el.children[overlapCount].getClientRects()[0].width;
    if (el.scrollLeft >= maxScroll && el.scrollLeft > lastScroll) {
      el.scrollLeft = overlapOverflow;
    } else if (el.scrollLeft <= 0  && el.scrollLeft < lastScroll) {
      el.scrollLeft = maxScroll - overlapOverflow;      
    }  
    lastScroll = el.scrollLeft;
  }
  el.addEventListener('scroll', scrollHandler);
  return function () {
      const scroll = el.scrollLeft;
      clones.forEach((el) => el.replaceWith(""));
      el.scrollTo({auto: scroll});
      el.removeEventListener('scroll', scrollHandler);
  }
}
  // const el = document.querySelector('.carousel');
  
  // let stop = inifiniteXScroll(document.querySelector('.carousel'));
  
  // window.onresize = function () {
  //   setTimeout(function () {
  //     stop();
  //     stop = inifiniteXScroll(document.querySelector('.carousel'));
  //   }, 0);
  // }
//}


open2dPoup(boxImage) {
  const modalRef = this.modal.open(TwoDpopupComponent, {
    modalDialogClass: "model-garmentPopup"
  });
  modalRef.componentInstance.boxImage = boxImage;
  modalRef.result.then((result) => {
    console.log(result);
  }).catch((error) => {
    console.log(error);
  });
}













 inifiniteXScroll(el) {
  let lastScrollX = 0;
  let overlapCount = 0;
  let clones = [];
  
  while(el.children[overlapCount] && ((el.children[overlapCount].offsetLeft - el.offsetLeft)) <= el.getClientRects()[0].width) {
    const clone = el.children[overlapCount].cloneNode(true);
    clones.push(clone);
    el.appendChild(clone);
    overlapCount++;
  }
  
  el.scrollTo({left: lastScrollX});
  
  overlapCount -= 1;
  
  // set to one so scrolling to the left works from the get go
  let lastScroll: number = el.scrollLeft = 1;
  
  function scrollHandler(): void {
    const maxScroll: number = el.scrollWidth - el.clientWidth;
    const overlapOverflow =  
          (el.children[overlapCount].offsetLeft - el.offsetLeft) 
          - el.getClientRects()[0].width
          + el.children[overlapCount].getClientRects()[0].width;
    
    if (el.scrollLeft >= maxScroll && el.scrollLeft > lastScroll) {
      el.scrollLeft = overlapOverflow;
    } else if (el.scrollLeft <= 0  && el.scrollLeft < lastScroll) {
      el.scrollLeft = maxScroll - overlapOverflow;      
    }  
    
    lastScroll = el.scrollLeft;
  }
  
  el.addEventListener('scroll', scrollHandler);
  stop()
  return function () {
      const scroll = el.scrollLeft;
      clones.forEach((el) => el.replaceWith(""));
      el.scrollTo({left: scroll});
      el.removeEventListener('scroll', scrollHandler);
  }
  
}



stop(){
const el = document.querySelector('.carousel');

let stop = this.inifiniteXScroll(document.querySelector('.carousel'));

window.onresize = function () {
  setTimeout(function () {
    stop();
    stop = this.inifiniteXScroll(document.querySelector('.carousel'));
  }, 0);

}}



userfile(){
  if(this.deleteGarmentUpload){
    this.userFilesList.subscribe((userFile) => {
      this.userUploadedFileList = userFile
      this.filelist = this.userUploadedFileList.filter((uploadlist) => uploadlist.fileType == 'GarmentUpload2D' )

      let filelist = this.filelist.filter((uploadlist) => uploadlist.link == this.clothId)
      this.images = filelist
      this.scrollingImages =  this.images
    })
  }else{
    this.userFilesList.subscribe((userFile) => {
      this.userUploadedFileList = userFile
      this.filelist = this.userUploadedFileList.filter((uploadlist) => uploadlist.fileType == 'UserUploads' )
      let filelist = this.filelist.filter((uploadlist) => uploadlist.userId == this.UserId)
      this.images = filelist
      this.scrollingImages =  this.images
    })
  }
}


}












