import { Component, Input, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AnyNsRecord } from 'dns';
import { MatDialog } from '@angular/material/dialog';
import { CommonService } from 'src/app/services/common.service';
import { doc, updateDoc, deleteField } from "firebase/firestore";
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { environment } from 'src/environments/environment';
import { ApiHttpService } from 'src/app/services/api-http.service';
import { ActivatedRoute, Router } from '@angular/router';
import { nextTick } from 'process';
import { catchError, forkJoin, of, switchMap } from 'rxjs';




@Component({
  selector: 'app-dashboard',
  templateUrl: './view-files.component.html',
  styleUrls: ['./view-files.component.css']
})
export class ViewsFiles implements OnInit {
  userFiles = this.firestore.collection("userfiles");
  userFileStateChange = this.userFiles.valueChanges();

  userUploadedAvatars: any = [];
  gltfImgSrc = "";
  UserId: any;
  isLoading = false;
  errorMessage: string = "";
  displayProgressSpinner = false;
  spinnerWithoutBackdrop = false;

  userfile: any;
  loading = false;
  userId:any
  logInFlag: boolean;

  constructor(private apiHttpService: ApiHttpService,private router: Router, private route:ActivatedRoute, private storage: AngularFireStorage, public dialog: MatDialog, public commonService: CommonService, private firestore: AngularFirestore) {
  }
  ngOnInit(): void {
    let title = 'Yologram - view-files'
    this.commonService.changeTitle(title);
    this.logInFlag = this.commonService.isLoggedIn()
    this.errorMessage = ""
    this.isLoading = true;
    this.route.paramMap.subscribe(paramMap => {
      this.userId = paramMap.get('id');  
      this.checkPageAuthorization()
    });

   
   
  }
  
  deleteUserfiles(userFileId: any, fileType: string) {
    var confirmation = confirm('Are you sure to delete this file ?' );
    if (!confirmation) return;
    this.userFiles.ref.where("id", "==", userFileId)
      .get()
      .then((objectList) => {
        objectList.forEach((doc) => {
          let docid = doc.id
          let userFilePath = doc.data()['filepath'];
          this.storage.ref(userFilePath).delete();
          if (fileType === 'Environment') {
            let environmentId = doc.data()['link'];
            this.firestore.collection("Environments").doc(environmentId).delete().then(() => {
            }).catch((error) => {
              console.error("Error removing document: ", error);
            });;
          }
          this.userFiles.doc(docid).delete().then(() => {
          }).catch((error) => {
            console.error("Error removing document: ", error);
          });;
        });
      })
  }

deleteGarment2Dfiles(userFileId: any, fileType: string, list: any) {
    console.log("");
    var confirmation = confirm('Are you sure to delete this file?');
    if (!confirmation) return;

    // Extract document IDs from the list object
    const docIds = Object.values(list);
    console.log("docIds", docIds);

    // Use forkJoin to wait for all delete operations to complete
    const deleteOperations = docIds.map((docId:any) =>
        this.userFiles.doc(docId).get().pipe(
            switchMap((doc) => {
                if (doc.exists) {
                    // let garment2DFilePath = doc.data()['filepath'];

                    // Array of image formats to delete mini URLs
                    // let imageFormats = ['jpg', 'jpeg', 'webp', 'png', 'gif'];
                    // const deleteMiniUrls = imageFormats.map(format => {
                    //     let miniUrlPath = `${garment2DFilePath}_128x128.${format}`;
                    //     return this.storage.ref(miniUrlPath).delete();
                    // });

                    // Delete main garment 2D file
                    // const deleteMainFile = this.storage.ref(garment2DFilePath).delete();

                    // Conditionally delete linked document if fileType is 'GarmentUpload2D'
                    // let deleteLinkedDoc = [];
                    // if (fileType === 'GarmentUpload2D') {
                    //     let linkDocId = doc.data()['link'];
                    //     deleteLinkedDoc = [this.firestore.collection("userfiles").doc(linkDocId).delete()];
                    // }

                    // Combine all delete operations
                    return forkJoin([
                        // ...deleteMiniUrls,
                        // deleteMainFile,
                        // ...deleteLinkedDoc,
                        this.userFiles.doc(docId).delete()
                    ]);
                } else {
                    console.error(`Document (${docId}) does not exist.`);
                    return [];
                }
            }),
            catchError(error => {
                console.error(`Error deleting document (${docId}): `, error);
                return [];
            })
        )
    );

    // Execute all delete operations
    forkJoin(deleteOperations).subscribe(() => {
        console.log('All files deleted successfully');
    }, error => {
        console.error('Error deleting files:', error);
    });
}
delete2DFile(list: any, filePath: string): Promise<void> {
  const confirmation = confirm('Are you sure to delete this Post?');
  if (!confirmation) return Promise.reject('User cancelled the deletion.');

  let imageFormats = ['jpg', 'jpeg', 'webp', 'png', 'gif'];
  console.log("list.id", list.id);

  return new Promise((resolve, reject) => {
    // Find the document by list.id field value
    this.firestore.collection("userfiles").ref.where('id', '==', list.id).get()
      .then((querySnapshot) => {
        if (!querySnapshot.empty) {
          // Assuming there's only one document with the given id
          const doc = querySnapshot.docs[0];

          // Delete the found document by its document ID
          return this.firestore.collection("userfiles").doc(doc.id).delete()
            .then(() => {
              // Delete the main file
              const deleteMainFile = this.storage.ref(filePath).delete().toPromise()
                .catch(error => {
                  if (error.code !== 'storage/object-not-found') {
                    throw error;
                  }
                });

              // Delete all mini files
              const deleteMiniUrls = imageFormats.map(format => {
                const baseName = filePath.replace(/\.[^/.]+$/, '');
                let  miniUrlPath 

                if(list['2DType']=='Video'){
                  miniUrlPath =   `${baseName}_thumbnail.${format}`;
              } else{
                 miniUrlPath = `${baseName}_128x128.${format}`;
              }
                return this.storage.ref(miniUrlPath).delete().toPromise()
                  .catch(error => {
                    if (error.code !== 'storage/object-not-found') {
                      throw error;
                    }
                  });
              });

              // Wait for all delete operations to complete
              return Promise.all([deleteMainFile, ...deleteMiniUrls]);
            });
        } else {
          throw new Error('Document not found');
        }
      })
      .then(() => {
        // Resolve the promise when all deletions are successful
        resolve();
      })
      .catch((error) => {
        // Catch any error that occurs during the process
        reject(error);
      });
  });
}


deleteUserUploadsFile(userFileId: any, fileType: string, link: string) {
  var confirmation = confirm('Are you sure to delete this file?');
  if (!confirmation) return;

  this.userFiles.ref.where("userId", "==", userFileId)
      .get()
      .then((objectList) => {
          objectList.forEach((doc) => {
              let docid = doc.id;
              let garment2DFilePath = doc.data()['filepath'];
              
              // Extract the short file path (without the extension)
              let shortFilePath = garment2DFilePath.substring(0, garment2DFilePath.lastIndexOf('.'));
              
              // Array of image formats to delete mini URLs
              let imageFormats = ['jpg', 'jpeg', 'webp', 'png', 'gif'];
              imageFormats.forEach(format => {
                  let miniUrlPath = `${shortFilePath}_128x128.${format}`;
                  this.storage.ref(miniUrlPath).delete()
              });

              this.storage.ref(garment2DFilePath).delete()

              if (fileType === 'UserUploads') {
                  let doctId = doc.data()['link'];
                  this.firestore.collection("userfiles").doc(doctId).delete().catch((error) => {
                      console.error("Error removing document: ", error);
                  });
              }

              this.userFiles.doc(docid).delete().catch((error) => {
                  console.error("Error removing document: ", error);
              });
          });
      })
      .catch((error) => {
          console.error("Error fetching documents: ", error);
      });
}


  openModel(gltfImgSrc: string) {
    const dialogRef = this.dialog.open(GLTFAvatarDialog1);
    this.gltfImgSrc = gltfImgSrc
    dialogRef.componentInstance.gltfImgSrc = this.gltfImgSrc;
    dialogRef.afterClosed().subscribe(result => {
      this.isLoading = false;
    });
  }


  checkPageAuthorization(){
    this.loading = true;
    this.commonService.getUser().subscribe((user: any) => {
      let userObject = { 'userid': user?.uid, 'pagename': '/view-files' }
      this.isLoading = true;


      this.commonService.checkViewPageRights(userObject).subscribe((res) => {
        this.loading = false;
        if (res) {
          this.userFileStateChange.subscribe(async ( object) => {
            this.userUploadedAvatars= []
            object.forEach(async (userFilesObject: any) => {
              let tmpUserFilesObject = userFilesObject
              if(this.userId != undefined && this.userId !== tmpUserFilesObject['userId']) {
                return ;
              } 
              this.isLoading = true;
              let url = environment.functionBaseUrl + "userProfileIdenifierById?id=" + tmpUserFilesObject['userId'];
              await this.apiHttpService.get(url).subscribe((data: any) => {
                tmpUserFilesObject['UserProfileName'] = data.displayName != undefined ? data.displayName : tmpUserFilesObject['userId'];
                this.isLoading = false;
              },
                ((error) => {
                  console.log(error);
                }
                ));  
              this.userUploadedAvatars.push(tmpUserFilesObject);    
            });
            this.isLoading = false;
          });
        } else {
          this.router.navigateByUrl("yologram-social/menu-page");
        }
      });
    });
  }

}
@Component({
  selector: 'avatar-upload-popup',
  templateUrl: './view-avatar-upload-popup.html',
})
export class GLTFAvatarDialog1 {
  @Input() gltfImgSrc: string | undefined;
  constructor(public dialog: MatDialog) {
  }
}
