import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-social-medial-story',
  templateUrl: './social-medial-story.component.html',
  styleUrls: ['./social-medial-story.component.css']
})
export class SocialMedialStoryComponent implements OnInit{
  
  private storyId:string;
   loading:boolean = false;
  userSnapShotList: any;
  filterSnapShotFileDetails: any;
  allUserDetails: any;
  teamplateFlag: any;
  logInFlag: boolean = false;
  mainMenuFlag: boolean = true;
  showpanel: boolean = true;

  constructor(
    private commonService:CommonService,
    private route: ActivatedRoute

  ){

  }
  ngOnInit(): void {
    this.loading = true;
    let title = 'Yologram - Share Story'
    this.commonService.changeTitle(title);
    this.route.queryParams
      .subscribe(params => {
        this.storyId = params['storyId'];
        this.teamplateFlag = params['teamplate'];
        this.getAllUsers()
      }
    );

    this.logInFlag = this.commonService.isLoggedIn()
  }
  
  getAllUsers() {
    this.commonService.getAllUser().subscribe(async (allUsers: any) => {
      this.allUserDetails = allUsers.users;
      this.getStory(this.storyId);
      console.log( this.userSnapShotList)
    })
  }

  getStory(storyId:any){
    let reqInputObject2 = { id:storyId, pageNumber: 1 }
    this.commonService.yologramSnapshotByStoryID(reqInputObject2).subscribe(async (response: any) => {
      this.userSnapShotList = response
       this.userSnapShotList.forEach(async (snap: any,index) => {        
        let userObjet = this.allUserDetails.find(userObject => userObject.uid === snap.UserID) || { displayName: 'debug' };
        snap.profileName = userObjet.displayName;
        snap.imageBase64 = this.convertImageToBase64(snap.SnapshotFileUrl);
        // snap.profileName = userObjet.displayName != undefined ? userObjet.displayName : (snap.DisplayName != undefined ? snap.DisplayName  :  snap.UserID);
        // userObjet.profileName = snap.profileName 
        // snap.userData =  userObjet;
        // snap.firstName =  snap.profileName.split(" ", 1); 
        // snap.AvatarLastFive =  snap.AvatarID.substr(snap.AvatarID.length - 5);
        this.loading = false;
      })
   })   
}

async convertImageToBase64(url: string): Promise<string> {
  return await new Promise((resolve, reject) => {
    const image = new Image();
    image.crossOrigin = 'Anonymous'; // Handle CORS
    image.onload = () => {
      let canvas = document.createElement('canvas');
      canvas.width = image.width;
      canvas.height = image.height;
      let ctx = canvas.getContext('2d');
      ctx.drawImage(image, 0, 0);
      let dataURL = canvas.toDataURL('image/png');
      resolve(dataURL);
      canvas = null;
    };
    image.onerror = reject;
    image.src = url;
  });
}

}
