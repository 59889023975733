<app-yologram-social *ngIf="logInFlag"></app-yologram-social>
<div class="card">
    <div>
      <div class="col-8 p-3 m-2">
        <div class="d-flex">
          <div class="p-1 d-flex">
            <input type="text  " class="form-control m-1 " placeholder="Add Page Name" [(ngModel)]="pageName">
            <input type="text" class="form-control m-1" placeholder="Add Page Address" [(ngModel)]="pageAddress">
          </div>
          <div class="p-2">
            <button class="btn btn-primary" (click)="addSPages()">Add Page</button>
          </div>
        </div>
      </div>
      <table class="table " id="dataTable" width="100%" cellspacing="0">
        <thead class="custom-header">
          <tr role="row">
            <th>Sr. No.</th>
            <th>Page Name</th>
            <th>Page Adress</th>
            <th>Edit</th>
          </tr>
        </thead>
        <tbody>
          <tr class="row-list-detail" *ngFor="let page of pageAll ;let i=index">
            <td>{{i+1}}</td>
            <td class=""> {{page.PageName}} </td>
            <td class=""> {{page.PageAddress}}</td>
            <td>
              <div class="d-flex action">
                <i class="bi bi-pencil-square p-2 m-1"  data-bs-toggle="modal" (click)="callEditPageName(page)" data-bs-target="#pageModalLabel"></i>
              </div>
            </td>
  
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  
  <!-- Button trigger modal -->
  <!-- <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#groupEditModal">
      Launch demo modal
    </button> -->
  
  <!-- Modal -->
  <div class="modal fade" id="pageModalLabel" tabindex="-1" aria-labelledby="pageModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-sm">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="pageModalLabel">Edit Page</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <label class="m-2">Page Name</label>
          <input type="text" class="form-control" placeholder="Add PagesName"   (change)="setPageName($event)" [value]="editPageName['PageName']">
          <label  class="m-2">Page Address</label>
          <input type="text" class="form-control" placeholder="Add PagesAdderess" (change)="setPageAddress($event)" [value]="editPageName['PageAddress']">
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-sm btn-secondary" data-bs-dismiss="modal">Close</button>
          <button type="button" class="btn btn-sm btn-primary"(click)="updatePageNameandAddress()" data-bs-dismiss="modal" >Save</button>
        </div>
      </div>
    </div>
  </div>