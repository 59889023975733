<div>
    <app-yologram-social *ngIf="logInFlag"></app-yologram-social>
    <app-public *ngIf="!logInFlag"></app-public>
    <div *ngIf="!loading" (scroll)="onScroll()" #brandStoriyScroll
        class=" roksan brand-slide m-0 p-0 brand-profile-color-all div-margin">
        <div class="slider-css text-center justify-content-center">
            <div class="slider-css text-center" (scroll)=" selectByScroll()" #brandSlide>
                <div *ngIf="loadingforVerticalScroll" class="text-center text-white">
                    <div class="spinner-border m-2 p-2" role="status">
                        <span class="sr-only"></span>
                    </div>
                </div>
                <div class="" *ngFor="let brand of brandDetails">
                    <div>
                        <img id="{{brand.id}}" class="m-1 " style="height:60px !important; width:80px !important;"
                            [ngClass]="selectedBrandDeatails == brand ? 'shadow-brand' : (selectedBrandDeatails != undefined && !byScroll  ? 'blug-css' :'')"
                            (click)="selectBrandForStory(brand.id,brand.id)" [src]="getBrandImage(brand.LogoURL)" />
                    </div>
                </div>
            </div>
            <div *ngIf="loading" class="loading">Loading&#8230;</div>
        </div>
        <div class="brand-profile-color" *ngIf="selectedBrandDeatails != undefined && (byScroll  || showBrandFlag )">
            <div class="container garment-alingn p-1">
                <div class="col-lg-12 justify-content-center  info-tag  garmentName outer">
                    <div class="close-butn" *ngIf="!byScroll  || !showBrandFlag " (click)="closeButtonForBrand()">X
                    </div>
                    <div class="contain-fluid m-1 ">
                        <div class="col-lg-12  pt-4px  text-center w-100  d-flex justify-content-center  d-flex">
                            <div class="m-0 col-8">
                                <div class="col-12 container vertical-center-band">
                                    <h5 class="p-2 text-white">Please Select Brand.</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="contain-fluid" [ngClass]="selectedBrandDeatails!= undefined  ? 'garment-card-sticky' :''"
            *ngIf="!byScroll && selectedBrandDeatails!= undefined">
            <div class="justify-content-center text-end container garment-alingn container-fluid ">
                <h6 class="color-class m-1 " *ngIf="boardDetails !=  undefined && boardDetails.name != undefined "><img
                        style="width:11px !important" src="./assets/pinterest-512.png">:{{boardDetails.name}}
                </h6>
                <h6 class="color-class m-1" *ngIf="boardDetails == undefined || boardDetails.name == undefined "><img
                        style="width:11px !important" src="./assets/pinterest-512.png">:Not Linked
                </h6>
            </div>
            <div class="container pb-2 garment-alingn" *ngIf="selectedBrandDeatails != undefined">
                <div class="col-lg-12 mb-2 justify-content-center garmentName">
                    <div class="contain-fluid m-1 ">
                        <div class="col-lg-12  pt-4px  text-center w-100  d-flex justify-content-center  d-flex">
                            <div class="text-center d-flex col-2">
                                <img (click)="getCenter()" class="profileImg-brand"
                                    style="width:35px; height:35px !important;"
                                    [src]="getBrandImage(selectedBrandDeatails.LogoURL)">
                            </div>
                            <div class="m-0  outer col-9">
                                <div class="col-12 vertical-center">
                                    <h6
                                        [ngClass]="selectedBrandDeatails.BrandName.length >= 16  ? 'brand-font-size' : 'color-BrandName'">
                                        {{selectedBrandDeatails.BrandName}}</h6>
                                </div>
                            </div>
                            <div class="m-0 col-1 text-end">
                                <span><img *ngIf="heartFlag == true" class="heart"
                                        style="width:18px !important; color:black"
                                        src="./assets/storystyle/newStoryIcon/heart.png"></span>
                                <span (click)="clickOnHeart()"><img *ngIf="heartFlag == false" class="heart"
                                        style="width:18px !important; color:black"
                                        src="./assets/storystyle/newStoryIcon/heartGray.png">
                                </span>
                            </div>
                        </div>
                        <div class="brand-profile-color menu-color" *ngIf="selectedBrandDeatails != undefined ">
                            <div class="justify-content-center text-center d-flex set-margin ">
                                <h6 class="text-primary brandbtn m-1 py-2 d-block class" (click)="setTab('story')">
                                    <div *ngIf="selectedTab == 'story'"
                                        [ngClass]="selectedTab == 'story' ? 'story-menu-card' : 'story-menu-icon'">
                                        <img style="width: 15px;" src="./assets/storystyle/storyblack.png">
                                    </div>
                                    <img *ngIf="selectedTab != 'story'"
                                        [ngClass]="selectedTab != 'story' ? 'story-menu-icon' : ''"
                                        style="color:black ;padding-bottom: 5px;"
                                        src="./assets/storystyle/story-white.png">
                                    <h6 *ngIf="selectedTab == 'story'" class="color-class m-1">Stories</h6>
                                </h6>
                                <h6 class="text-primary brandbtn m-1 py-2 d-block color-class">
                                    <img *ngIf="selectedTab == 'mens-wear'"
                                        [ngClass]="selectedTab == 'mens-wear' ? 'story-menu' : 'story-menu-icon'"
                                        style="color:black ;padding-bottom: 5px;"
                                        src="./assets/storystyle/MenIconBlack.png">
                                    <img (click)="setTab('mens-wear')" *ngIf="selectedTab != 'mens-wear'"
                                        [ngClass]="selectedTab != 'mens-wear' ? 'story-menu-icon' : ''"
                                        style="color:black ;padding-bottom: 5px;"
                                        src="./assets/storystyle/MenIconWhite.png">
                                    <h6 *ngIf="selectedTab == 'mens-wear'" class="color-class m-1">MensWear</h6>
                                </h6>
                                <h6 class="text-primary brandbtn m-1 py-2 d-block color-class">
                                    <img *ngIf="selectedTab == 'womens-wear'"
                                        [ngClass]="selectedTab == 'womens-wear' ? 'story-menu' : 'story-menu-icon'"
                                        style="color:black ;padding-bottom: 5px;"
                                        src="./assets/storystyle/WomenIconBlack.png">
                                    <img (click)="setTab('womens-wear')" *ngIf="selectedTab != 'womens-wear'"
                                        [ngClass]="selectedTab != 'womens-wear' ? 'story-menu-icon' : ''"
                                        style="color:black ;padding-bottom: 5px;"
                                        src="./assets/storystyle/WomenIconWhite.png">
                                    <h6 *ngIf="selectedTab == 'womens-wear'" class="color-class m-1">Womenswear</h6>
                                </h6>
                            </div>
                            <div class="row" *ngIf="userSnapShotList.length >= 0 ">
                                <div class="column d-block" *ngFor="let snapshot of userSnapShotList ; let i=index;">
                                    <img class="w-90 shadow-1-strong rounded mb-1" [src]="snapshot.SnapshotFileUrl">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="brandStoris p-3" *ngIf="selectedBrand != undefined">
            <app-public-brand-stories-news class="brand-profile-color" id="target" [brandGarments]="brandGarmentIds"
                [tab]="selectedTab" [onChangeFlag]="onChangeFlag" (changeSelectedTab)="changeTab($event)"
                [brandsIDdetailsAll]="brandsIDdetailsAll" [selectBrandGender]="genderID" [brandId]="brandId"
                [pageNumber]="pageNumber" [clickEventFlag]="clickEventFlag" [rootFromBrandFlag]="rootFromBrandFlag"
                [routLogInFlag]="routLogInFlag" (changeSelectedBrand)="chageBrand($event)">
            </app-public-brand-stories-news>
        </div>
    </div>
</div>