<h2 mat-dialog-title class="text-center">
  <span *ngIf="skyBoXall == undefined">
    <mat-dialog-content class="mat-typography">
      <h4 class="text-center"></h4>
      <model-viewer camera-controls ar auto-rotate camera-orbit="45deg 55deg 2.5m" [src]="gltfImgSrc"
        alt="A 3D avatar-model-view" data-js-focus-visible="" quick-look ar-status="not-presenting"
        skybox-image={{skyBoXall}} >
      </model-viewer>
    </mat-dialog-content>
    <a class="band"  *ngIf="fileDeletFlag">
      <i class="bi bi-trash-fill class-delete" (click)=" deleteSnapshotFile(fileObject)"></i>
      <div class="justify-content-center text-center div-s-n-s p-2">
        <div class="d-flex col-12 " *ngIf="nameFlag" >
          <div class="col-10 col-sm-8">
            <h6 class="p-1 text-start">{{fileObject.filename}}</h6>
          </div>
          <div class="col-2 col-sm-4 text-end">
            <i class="bi bi-pencil" (click)="selectName()"></i>
          </div>
        </div>
        <div class="d-flex justify-content-center text-center col-12" *ngIf="!nameFlag" >
          <div class="col-10 col-sm-8">
            <input type="text" class="form-control text-start transparent-input text-black" placeholder="Enter File name" (change)="editFileName($event)"  [value]="fileObject.filename">
          </div>
          <div class="col-2 col-sm-4 text-end">
            <button type="button" class="btn btn-sm btn-primary" data-bs-dismiss="modal" (click)="updateFileName(fileObject)">Save</button>
          </div>
        </div>
      </div>
      </a>
    <mat-dialog-actions align="end">
      <button class="btn btn-outline-secondary  btn-sm" mat-dialog-close>Close</button>
    </mat-dialog-actions>
  </span>
  <div *ngIf="skyBoXall != undefined">
    <div *ngIf="snaploader" class="text-center">
      <div class="spinner-border m-5" role="status">
        <span class="sr-only"></span>
      </div>
    </div>
    <div *ngIf="!snaploader && hideFlag != undefined" class="col-12 justify-content-center">
      <img (click)="getEnvironmentProfile()"  mat-dialog-close class="w-90 shadow-1-strong rounded mb-1 image-popup" style="width: 382px;" [src]="skyBoXall">
      <h2 class="evn-name" mat-dialog-close  (click)="getEnvironmentProfile()" data-bs-dismiss="modal">{{evnFileListFileName['displayName']}}</h2>
    </div>
    <div *ngIf="snaploader2D" class="text-center class-model">
      <div class="spinner-border m-5 p-5" role="status">
        <span class="sr-only"></span>
      </div>
    </div>
    <model-viewer *ngIf="!snaploader2D" id="poster" camera-controls touch-action="pan-y" data-js-focus-visible="false"
      [attr.skybox-image]="skyBoXall" alt="A 3D model of a sphere reflecting a sunrise" [attr.src]="gltfImgSrc">
    </model-viewer>

    <div *ngIf="snaploader" class="text-center">
      <div class="spinner-border m-5" role="status">
        <span class="sr-only"></span>
      </div>
    </div> 

    <div class="div-center class-desktop" *ngIf="!snaploader && hideFlag != undefined">
      <div class="row w-100 image-margin">
        <div class="col-4 p-0" *ngFor="let snapshot of userSnapShotListSlice  ; let i=index;">
          <div class="d-flex align-items-center justify-content-center m-1">
            <img class="w-100 shadow-1-strong rounded m-1" (click)="getEnvironmentProfileFeed(snapshot)"
              [src]="snapshot.SnapshotFileUrl">
          </div>
        </div>
      </div>
      <div class="col-12 text-center text-white stify-content-center morebtn">
        <div *ngIf="userSnapShotList.length > 2" (click)="getEnvironmentProfile()" mat-dialog-close>
          <img class="w-80 shadow-1-strong rounded image-pop" data-bs-dismiss="modal" src="./assets/plus-8-512.png" style="width: 50px; height: 50px;">
        </div>
      </div>
    </div>
    <button class="btn btn-outline-secondary  btn-sm" mat-dialog-close>Close</button>
  </div>