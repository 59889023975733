<div class="brand-close" id="closePannel">
    <div id="mySidenav" class="sidenav">
        <div>
            <div class="text-center d-flex col-2 shadow-brand-slide">
                <img  (click)="navigateOnThread()" class="brandLogo" [src]="getBrandImage(this.brandObject.LogoURL)">
                <div class="text-end me-1 mt-1" style="pointer-events: auto;">
                    <div>
                        <img class="heart" *ngIf="!brandheartFlag" (click)="clickOnHeart()"
                            style="width:18px !important; color:#000000 ;margin-left: 57px"
                            src="./assets/storystyle/newStoryIcon/heart.png">

                        <img *ngIf="brandheartFlag" class="heart" (click)="clickOnHeart()"
                            style="width:18px !important; color:black;margin-left: 57px"
                            src="./assets/storystyle/newStoryIcon/heartGray.png">
                    </div>
                    <div>
                        <img (click)="shareBrand(brandObject)" style="width:20px !important; margin-top: 11px"
                            src="./assets/share_copy.png">
                    </div>
                </div>
            </div>
            <div class="brand-name"  (click)="navigateOnThread()">
                <h6 class="text-white">{{brandObject.BrandName}}</h6>
            </div>
            <div class="col-lg-12 garmentName">
                <div class="justify-content-center text-center d-flex set-margin">
                    <h6 class="text-primary brandbtn  py-2 d-block color-class" (click)="setTab('story','S')">
                        <div *ngIf="tab =='story'" [ngClass]="tab == 'story' ? 'story-menu-card' : 'story-menu-icon'">
                            <img style="width: 8px; padding-bottom: 4px;" src="./assets/storystyle/storyblack.png">
                        </div>
                        <img (click)="setTab('story','S')" *ngIf="tab != 'story' && tab != 'news-stories' "
                            [ngClass]="tab != 'story' && tab != 'news-stories' ? 'story-menu-icon' : ''"
                            style="color:black ;padding-bottom: 5px;" src="./assets/storystyle/story-white.png">
                        <h6 *ngIf="tab == 'story' || tab == 'news-stories' " class="color-class-sy ms-3">
                            Stories</h6>
                    </h6>
                    <h6 class="text-primary brandbtn py-2 d-block color-class">
                        <img *ngIf="tab == 'mens-wear'"
                            [ngClass]="tab == 'mens-wear' ? 'story-menu' : 'story-menu-icon'"
                            (click)="setTab('mens-wear','M')" style="color:black ;padding-bottom: 5px;"
                            src="./assets/storystyle/MenIconBlack.png">
                        <img (click)="setTab('mens-wear','M')" *ngIf="tab != 'mens-wear'"
                            [ngClass]="tab != 'mens-wear' ? 'story-menu-icon' : ''"
                            style="color:black ;padding-bottom: 5px;" src="./assets/storystyle/MenIconWhite.png">
                        <h6 *ngIf="tab == 'mens-wear'" class="color-class-sy m-1">Menswear</h6>
                    </h6>
                    <h6 class="text-primary brandbtn py-2 d-block color-class">
                        <img *ngIf="tab == 'womens-wear'" (click)="setTab('womens-wear','W')"
                            [ngClass]="tab == 'womens-wear' ? 'story-menu' : 'story-menu-icon'"
                            style="color:black ;padding-bottom: 5px;" src="./assets/storystyle/WomenIconBlack.png">
                        <img (click)="setTab('womens-wear','W')" *ngIf="tab != 'womens-wear'"
                            [ngClass]="tab != 'womens-wear' ? 'story-menu-icon' : ''"
                            style="color:black ;padding-bottom: 5px;" src="./assets/storystyle/WomenIconWhite.png">
                        <h6 *ngIf="tab == 'womens-wear'" class="color-class-sy  w-margin">Womenswear</h6>
                    </h6>
                </div>
            </div>
        </div>
        <div *ngIf="!flipFlag  || ViewFlage">
            <div class="flipFlag-class" id="scrollableDiv" #setclothBand>


                <div class="set-main-div">
                    <div *ngIf="loading"  class="spinner-border spn-load text-white loader" role="status">
                    </div>
                    <div>
                        <div *ngIf="!ViewFlage" (click)="flipClose()" class="text-white text-end class-close-btn">X
                        </div>
                    
                        <div class="cloth-text" *ngIf="garmentImgage == undefined && !loading">
                            Garment Not Available
                       </div>
                        <div class="set-img-div" *ngIf="!swichImageFlage">
                            <!-- <div *ngIf="isloading" class="d-flex justify-content-center" style="margin-top: 100px;">
                                <div class="spinner-border text-white" role="status">
                                    <span class=""></span>
                                </div>
                            </div> -->
                            <img class="img-responsive-flip-class" [src]="garmentImgage" />
                            <img class="img-responsive-flip-class-bottom" [src]="garmentImgage" />

                        </div>
                        <div  *ngIf="garmentImgage != undefined">
                        <div class="two-dk" id="target" *ngIf="swichImageFlage && !loading">
                            <app-twod-image [garmentImg]="this.garmentImgage"
                                (triggedCloth)="setSelectedGarmetCloth($event)"
                                [garmentType]="this.garmentObject.GarmentType" [pageType]="style"></app-twod-image>
                        </div>
                        </div>
                        <div>



                            <!-- <div class="d-flex justify-content-center" *ngIf="isloading">
                                <div class="spinner-border" role="status">
                                    <span class=""></span>
                                </div>
                            </div> -->
                        </div>

                    </div>
               
                </div>
                <div class="setcloth-band">
                    <div class="mb-2 text-black clothName-brand color-class-band">
                        <div class="d-flex garment-title">
                            <div class="text-center d-inline-flex garmen-name-in-band">
                                <div class="">
                                    <span *ngIf="garmentObject ?.RealType == '0'" class="brandbtn py-1"><img
                                            style="width:18px !important; color:black ;padding-bottom: 3px; opacity: 0.6;"
                                            src="./assets/realtype/headgear.png"></span>
                                    <span *ngIf="garmentObject ?.RealType == '1'" class="brandbtn py-1 "><img style="width:18px !important; color:black ;padding-bottom: 12px; opacity: 0.6; 
                                    margin-right: 4px;" src="./assets/realtype/upperwear.png"></span>
                                    <span *ngIf="garmentObject ?.RealType == '2'" class="brandbtn py-1"><img
                                            style="width:18px !important; color:black ;padding-bottom: 5px; opacity: 0.6;"
                                            src="./assets/realtype/bottomwear.png"></span>
                                    <span *ngIf="garmentObject ?.RealType == '3'" class="brandbtn py-1"><img
                                            style="width:18px !important; color:black ;padding-bottom: 3px; opacity: 0.6;"
                                            src="./assets/realtype/footwear.png"></span>
                                    <span *ngIf="garmentObject ?.RealType == '4'" class="brandbtn py-1"><img
                                            style="width:18px !important; color:black ;padding-bottom: 3px; opacity: 0.6;"
                                            src="./assets/realtype/heeledfootwear.png"></span>
                                    <span *ngIf="garmentObject ?.RealType == '5'" class="brandbtn py-1"><img
                                            style="width:18px !important; color:black ;padding-bottom: 10px; opacity: 0.6;"
                                            src="./assets/realtype/fullbody.png"></span>
                                </div>
                                <div class="garment-name-class">
                                    <h6 (click)="callGarmentCloth()" class="brand-font"
                                        style="overflow-wrap: break-word;">
                                        {{(garmentObject?.Name).split()}}
                                    </h6>
                                </div>

                            </div>
                            <div>
                                <div class="text-center band-3d-icon d-flex col-sm-1">
                                    <h6 class=" text-primary  color-class">
                                        <img *ngIf="!garmentScrollFlag" (click)="switch3DImage($event)"
                                            class="threeD_icon " src="./assets/realtype/3d-paranoma.png">
                                        <img *ngIf="garmentScrollFlag" class="threeD_icon "
                                            src="./assets/realtype/paraCloseBlack.png">
                                    </h6>
                                </div>
                            </div>
                        </div>
                        <div class="static-band-five-icon col-4 text-center d-flex justify-content-center">
                            <h6 (click)="selectIcon('popup')" class="text-primary brandbtn m-1 d-block class">
                                <div *ngIf="selectedIcon == 'popup'"
                                    [ngClass]="selectedIcon == 'popup'    ? 'story-menu-card-band'  : 'story-menu-icon'">
                                    <img style="width: 12px;" src="./assets/storystyle/story-white.png">
                                </div>
                                <img *ngIf="selectedIcon != 'popup'" class="story-menu-icon"
                                    style="color:black ;margin-top: 0px; width: 10px;"
                                    src="./assets/storystyle/storyblack.png">

                                <h6 *ngIf="selectedIcon == 'popup'" class="selected-name p-1">Story</h6>
                            </h6>
                            <h6 (click)="selectIcon('detail')" *ngIf="garmentObject ?.ThumbCenterURL  != undefined"
                                class="m-1 brandbtn">
                                <div *ngIf="selectedIcon == 'detail'"
                                    [ngClass]="selectedIcon == 'detail' ? 'story-link-menu' : 'story-menu-icon'">
                                    <a href="{{garmentObject ?.ThumbCenterURL}}" target="_blank">
                                        <img style="width: 12px;;" src="./assets/details.png"></a>
                                </div>
                                <a href="{{garmentObject ?.ThumbCenterURL}}" target="_blank"><img
                                        *ngIf="selectedIcon != 'detail'"
                                        [ngClass]="selectedIcon != 'detail' ? 'story-menu-icon' : ''"
                                        style="color:black ;" src="./assets/storystyle/newStoryIcon/detailGray.png"></a>

                                <h6 *ngIf="selectedIcon == 'detail'" class="selected-name p-1">Details</h6>
                            </h6>
                            <h6 *ngIf="garmentObject ?.ThumbCenterURL  == undefined" class="m-1 brandbtn">
                                <a><img style="width:15px !important; color:black"
                                        src="./assets/storystyle/newStoryIcon/detailGrayLight.png"></a>
                            </h6>
                            <h6 (click)="selectIcon('gallary')" *ngIf="garmentObject ?.ThumbLeftURL  != undefined"
                                class="m-1 brandbtn">
                                <div *ngIf="selectedIcon == 'gallary'"
                                    [ngClass]="selectedIcon == 'gallary' ? 'story-link-menu' : 'story-menu-icon'">
                                    <a href="{{garmentObject ?.ThumbLeftURL}}" target="_blank">
                                        <img style="width: 12px; " src="./assets/gallary.png"></a>
                                </div>
                                <a href="{{garmentObject ?.ThumbLeftURL}}" target="_blank"><img
                                        *ngIf="selectedIcon != 'gallary'"
                                        [ngClass]="selectedIcon != 'gallary' ? 'story-menu-icon' : ''"
                                        style="color:black;" src="./assets/storystyle/newStoryIcon/gallaryGray.png"></a>
                                <h6 *ngIf="selectedIcon == 'gallary'" class="selected-name p-1">Gallary</h6>
                            </h6>
                            <h6 *ngIf="garmentObject ?.ThumbLeftURL  == undefined" class="m-1 brandbtn">
                                <a><img style="width:15px !important ;"
                                        src="./assets/storystyle/newStoryIcon/gallaryGrayLight.png"></a>
                            </h6>
                            <h6 (click)="selectIcon('cart')" *ngIf="garmentObject ?.ThumbRightURL  != undefined"
                                class="m-1 brandbtn">
                                <div *ngIf="selectedIcon == 'cart'"
                                    [ngClass]="selectedIcon == 'cart' ? 'story-link-menu' : 'story-menu-icon'">
                                    <a href="{{garmentObject ?.ThumbRightURL}}" target="_blank">
                                        <img style="width: 12px; " src="./assets/shop.png"></a>
                                </div>
                                <a href="{{garmentObject ?.ThumbRightURL}}" target="_blank"><img
                                        *ngIf="selectedIcon != 'cart'"
                                        [ngClass]="selectedIcon != 'cart' ? 'story-menu-icon' : ''"
                                        style="color:black; width: 1px;"
                                        src="./assets/storystyle/newStoryIcon/cartGray.png"></a>
                                <h6 *ngIf="selectedIcon == 'cart'" class="selected-name m-1">Shopping</h6>
                            </h6>
                            <h6 *ngIf="garmentObject ?.ThumbRightURL  == undefined" class="m-1 brandbtn">
                                <a><img style="width:15px !important; color:black"
                                        src="./assets/storystyle/newStoryIcon/cartGrayLight.png"></a>
                            </h6>
                        </div>
                    </div>
                </div>
               
                
                <div class="img-detail-story">
                 
                    <div  class="twoDImage d-block mb-2">
                        <div class="" *ngIf="userFileList != undefined">
                            <app-image-slide [images]="userFileList"></app-image-slide>
                        </div>
                        <div class="d-flex" *ngIf="userFileList == undefined || userFileList.length == 0">
                            <app-image-slide [images]="images"></app-image-slide>
                        </div>
                        <div *ngIf="userFiledetails3d != undefined"
                            class="text-center d-flex upload-image-slider-css-both twoDImage-both">
                            <div *ngFor="let fileList of userFiledetails3d;let p=index" class="">
                                <model-viewer *ngIf="fileList.fileType == 'GarmentUpload3D'" camera-controls
                                    camera-orbit="45deg 55deg 2.5m" ar auto-rotate src={{fileList.fileurl}}
                                    alt="A 3D avatar-model-view" data-js-focus-visible="" ar-status="not-presenting"
                                    style="height: 80px; width: 100px; float: none;">
                                </model-viewer>
                            </div>
                        </div>
                    </div>
                    <div
                        [ngClass]="userFiledetails3d.length != 0   ? 'details collection-details-sku p-2' : 'collection-details-sku3d details'">
                        <h6 class=" color-class "
                            *ngIf="garmentObject ?.CollectionName != undefined && garmentObject ?.CollectionName != '' ">
                            Collection: {{filterCollectionName(garmentObject.CollectionName)}}</h6>
                        <h6 class=" color-class "
                            *ngIf="garmentObject ?.CollectionName == '' ||  garmentObject ?.CollectionName == null">
                            Collection: None</h6>
                        <h6 class=" color-class"
                            *ngIf="garmentObject ?.Details != undefined &&  userFiledetails3d.length != 0">
                            {{(showMore) ? garmentObject .Details : garmentObject .Details | slice:0:120}}
                            <span *ngIf="!showMore  &&  userFiledetails3d.length != 0 ">...</span>
                            <a href="javascript:;" *ngIf="!showMore &&  userFiledetails3d.length != 0"
                                (click)="showMore=true">Read More</a>
                            <a href="javascript:;" *ngIf="showMore &&  userFiledetails3d.length != 0"
                                (click)="showMore=false">Read less</a>
                        </h6>

                        <h6 class="color-class"
                            *ngIf="garmentObject ?.Details != undefined  &&  userFiledetails3d.length == 0 ">
                            {{(showMore) ? garmentObject .Details : garmentObject .Details| slice:0:400}}
                            <span *ngIf="!showMore && garmentObject .Details.length < 400">...</span>
                            <a href="javascript:;"
                                *ngIf="!showMore && garmentObject .Details.length > 400  &&  userFiledetails3d.length == 0"
                                (click)="showMore=true">Read More</a>
                            <a href="javascript:;" *ngIf="showMore  &&  userFiledetails3d.length == 0 "
                                (click)="showMore=false">Read less</a>
                        </h6>
                        <h6 class="color-class"
                            *ngIf="garmentObject ?.Details == undefined  &&  userFiledetails3d.length < 0 ">
                            {{(showMore) ? dummyText : dummyText | slice:0:150}} <span *ngIf="!showMore">...</span>
                            <a href="javascript:;" *ngIf="!showMore &&  userFiledetails3d.length < 0 "
                                (click)="showMore=true">Read More</a>
                            <a href="javascript:;" *ngIf="showMore  && userFiledetails3d.length < 0 "
                                (click)="showMore=false">Read less</a>
                        </h6>
                        <h6 class="color-class"
                            *ngIf="garmentObject ?.Details == undefined &&  userFiledetails3d.length == 0">
                            Your brand's apparel is digitised in 3D
                            <br><br>
                            On a gamified experience, consumers style avatars & share animated avatar
                            stories
                            (linked to ecommerce)
                            <br><br>
                            Defining a new paradigm in immersive storytelling for social media & the
                            metaverse
                        </h6>
                        <h6 class="color-class"
                            *ngIf="garmentObject ?.Details == undefined &&  userFiledetails3d.length > 0  && userFiledetails.length > 0">
                            {{(showMore) ? dummyText : dummyText}} "
                            <a href="javascript:;" *ngIf="!showMore &&  
                                    garmentObject.Details == undefined &&  
                                    userFiledetails3d.length > 0  && userFiledetails.length > 0 "
                                (click)="showMore=true">Read More</a>
                            <a href="javascript:;"
                                *ngIf="showMore  &&  userFiledetails3d.length > 0  && userFiledetails.length > 0"
                                (click)="showMore=false">Read less</a>
                        </h6>

                        <h6 class="color-class"
                            *ngIf="garmentObject ?.Details == undefined &&  userFiledetails3d.length > 0 && userFiledetails.length == 0">
                            {{(showMore) ? dummyText : dummyText}}<a href="javascript:;"
                                *ngIf="!showMore && garmentObject ?.Details == undefined &&  userFiledetails3d.length > 0 && userFiledetails.length == 0"
                                (click)="showMore=true">Read More</a>
                            <a href="javascript:;" *ngIf="showMore " (click)="showMore=false">Read less</a>
                        </h6>
                        <h6 class="color-class">SKU: {{garmentObject ?.SKU}}</h6>
                    </div>

                    <!-- <div #storyContainer class="my-story">
                        <div class="row p-1 justify-content-center d-flex"
                            *ngIf="userSnapShotListGarment.length >= 0  && userSnapShotListGarment != undefined && userSnapShotListGarment.length >= 0">
                            <div  *ngFor="let snapshot of userSnapShotListGarment; let i=index;">
                                <img  class="img-fluid rounded mb-1" [src]="snapshot.SnapshotFileUrl">
                            </div>
                            <div class="col-12 text-center text-black stify-content-center">
                                <h6 
                                    *ngIf="this.userSnapShotListGarment.length >= 12 ">
                                    <b>More</b>
                                </h6>
                            </div>
                        </div>
                    </div> 
                -->

                    <div #storyContainer class="my-story">
                        <div class="row p-1 justify-content-center d-flex"
                            *ngIf="userSnapShotListGarment && userSnapShotListGarment.length > 0">
                            <div class="col-6 " *ngFor="let snapshot of userSnapShotListGarment; let i=index;">
                                <div class="d-inline-block mb-3">
                                    <img class="img-fluid rounded mb-1"  (click)="selectGarmentImage(i,snapshot)"
                                    [ngClass]="{'story_image_selection': i === selectedImageIndex}" [src]="snapshot.SnapshotFileUrl"
                                        >
                                </div>

                             

                            </div>
                        </div>
                        <div class="col-12 text-center text-black stify-content-center">
                            <h6 (click)="incrementImgGarmentCard()"
                            *ngIf="this.userSnapShotListGarment.length >= 12 ">
                                <b>More</b>
                            </h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="flipFlag && !ViewFlage">
            <div *ngIf="this.garmentObject.Gender =='F'">
                <div class="d-flex my">
                    <div class="file womenswear_scroller">
                        <div *ngIf="processedClothData?.length > 0">
                            <div #scrollContainerWHeadGear >
                                <div *ngFor="let object of processedClothData[0]?.data; let i = index"
                                    id="elementwomens-wearheadgear{{i}}" class="slider-img2-upper">
                                    <div *ngIf="loading"  class="spinner-border spn-load text-white loader-cloth" role="status">
                                    </div>
                                    <div class="cloth-text" *ngIf="!zipData?.zipList?.[object.GarmentId] && !loading">
                                         Garment Not Available
                                    </div>
                                
                                    <div *ngIf="zipData?.zipList?.[object.GarmentId]">
                                        <div class="head-div">
                                            <img class="img-responsive-foot"
                                                [src]="sanitizer.bypassSecurityTrustUrl(zipData?.zipList?.[object.GarmentId])" />
                                            <img class="profileInfo headgear_info col-sm-2"
                                                (click)="openClothPanel(zipData?.zipList?.[object.GarmentId],object.GarmentId,'HeadwearID')"
                                                style="width:25px; height:25px !important;"
                                                src="/assets/realtype/planinfoicon.png">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- Section for Upper Wear -->
                            <div #scrollContainerWUpper class="slider-css2-upper">
                                <div *ngFor="let object of processedClothData[1]?.data; let i = index"
                                    id="elementwomens-wearupperwear{{i}}" class="slider-img2-upper">
                                    <div *ngIf="loading" class="spinner-border spn-load text-white loader-cloth" role="status">
                                    </div>
                                    <div class="cloth-text" *ngIf="!zipData?.zipList?.[object.GarmentId] && !loading">
                                         Garment Not Available
                                    </div>
                                    
                                    <div *ngIf="zipData?.zipList?.[object.GarmentId]">
                                        <div class="upper-div">
                                            <img class="img-responsive-upper"
                                                [src]="sanitizer.bypassSecurityTrustUrl(zipData.zipList[object.GarmentId])" />
                                            <img (click)="openClothPanel(zipData.zipList[object.GarmentId],object.GarmentId,'UpperwearID')"
                                                class="profileInfo col-sm-2" style="width:25px; height:25px !important;"
                                                src="/assets/realtype/planinfoicon.png">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- Section for Bottom Wear -->
                            <div #scrollContainerWBottom class="slider-css2-upper">
                                <div *ngFor="let object of processedClothData[2]?.data; let i = index"
                                    id="elementwomens-wearbottomwear{{i}}" class="slider-img2-upper">
                                    <div *ngIf="loading"  class="spinner-border spn-load text-white loader-cloth" role="status">
                                    </div>
                                    <div class="cloth-text" *ngIf="!zipData?.zipList?.[object.GarmentId] && !loading">
                                         Garment Not Available
                                    </div>
                                    <div *ngIf="zipData?.zipList?.[object.GarmentId]">
                                        <div class="bottom-div">
                                            <img class="img-responsive-bottom"
                                                [src]="sanitizer.bypassSecurityTrustUrl(zipData.zipList[object.GarmentId])" />
                                            <img class="profileInfo upperwear_info col-sm-2"
                                                (click)="openClothPanel(zipData.zipList[object.GarmentId],object.GarmentId,'BottomwearID')"
                                                style="width:25px; height:25px !important;"
                                                src="/assets/realtype/planinfoicon.png">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- Section for Footwear -->
                            <div #scrollContainerWFootwear class="slider-css2-upper">
                                <div *ngFor="let object of processedClothData[3]?.data; let i = index"
                                    id="elementwomens-wearfootwear{{i}}" class="slider-img2-upper">
                                    <div *ngIf="loading"  class="spinner-border spn-load text-white loader-cloth" role="status">
                                    </div>
                                    <div class="cloth-text" *ngIf="!zipData?.zipList?.[object.GarmentId] && !loading">
                                         Garment Not Available
                                    </div>
                                    <div *ngIf="zipData?.zipList?.[object.GarmentId]">
                                        <div class="foot-div">
                                            <img class="img-responsive-foot"
                                                [src]="sanitizer.bypassSecurityTrustUrl(zipData.zipList[object.GarmentId])" />
                                            <img class="profileInfo upperwear_info col-sm-2"
                                                (click)="openClothPanel(zipData.zipList[object.GarmentId],object.GarmentId,'')"
                                                style="width:25px; height:25px !important;"
                                                src="/assets/realtype/planinfoicon.png">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="this.garmentObject.Gender =='M'">
                <div class="d-flex my">
                    <div class="file womenswear_scroller">
                        <div class="row form d-block"
                            *ngIf="processedClothData.length > 0 && processedClothData != undefined">
                            <div #scrollContainerMHeadGear class="slider-css-head">
                                <div *ngFor="let object of processedClothData[0].data; let i=index"
                                    id="elementmens-wearheadgear{{i}}" class="slider-img2-upper">
                                    <div *ngIf="loading" class="spinner-border spn-load text-white loader-cloth" role="status">
                                    </div>
                                    <div class="cloth-text" *ngIf="!zipData?.zipList[object.GarmentId] && !loading">
                                         Garment Not Available
                                    </div>
                                    <div *ngIf="zipData?.zipList[object.GarmentId]">
                                        <div class="d-flex justify-content-center"
                                            *ngIf="!zipData || !zipData?.zipList || !zipData?.zipList[object.GarmentId]">
                                            <div class="spinner-border spn-load text-white" role="status"></div>
                                        </div>
                                        <div class="head-div">
                                            <img class="img-responsive-foot"
                                                *ngIf="zipData && zipData?.zipList && zipData?.zipList[object.GarmentId]"
                                                [src]="zipData.zipList[object.GarmentId] ? sanitizer.bypassSecurityTrustUrl(zipData.zipList[object.GarmentId]) : ''" />
                                        </div>
                                        <img class="profileInfo headgear_info col-sm-2"
                                            (click)="openClothPanel(zipData?.zipList[object.GarmentId],object.GarmentId,'HeadwearID')"
                                            style="width:25px; height:25px !important;"
                                            src="/assets/realtype/planinfoicon.png">
                                    </div>
                                </div>
                                <!-- Similar changes as above for Upperwear section -->
                                <div #scrollContainerMUpper class="slider-css2-upper">
                                    <div *ngFor="let object of processedClothData[1].data; let i=index"
                                        id="elementmens-wearupperwear{{i}}" class="slider-img2-upper">
                                        <div *ngIf="loading"  class="spinner-border spn-load text-white loader-cloth" role="status">
                                        </div>
                                        <div *ngIf="!zipData?.zipList[object.GarmentId] && !loading">
                                             Garment Not Available
                                        </div>
                                        
                                        <div class="upper-div" *ngIf="zipData?.zipList[object.GarmentId]">
                                            <div class="d-flex justify-content-center"
                                                *ngIf="!zipData || !zipData?.zipList || !zipData?.zipList[object.GarmentId]">
                                                <div class="spinner-border spn-load text-white" role="status"></div>
                                            </div>
                                            <img *ngIf="zipData && zipData?.zipList && zipData?.zipList[object.GarmentId]"
                                                class="img-responsive-upper"
                                                [src]="zipData?.zipList[object.GarmentId] ? sanitizer.bypassSecurityTrustUrl(zipData?.zipList[object.GarmentId]) : ''" />
                                            <img (click)="openClothPanel(zipData?.zipList[object.GarmentId],object.GarmentId,'UpperwearID')"
                                                class="profileInfo col-sm-2" style="width:25px; height:25px !important;"
                                                src="./assets/realtype/planinfoicon.png">
                                        </div>
                                    </div>
                                </div>
                                <!-- Similar changes as above for Bottomwear section -->
                                <div #scrollContainerMBottom class="slider-css2-upper">
                                    <div *ngFor="let object of processedClothData[2].data; let i=index"
                                        id="elementmens-wearbottomwear{{i}}" class="slider-img2-upper">
                                        <div *ngIf="loading" class="spinner-border spn-load text-white loader-cloth" role="status">
                                        </div>
                                        <div class="cloth-text" *ngIf="!zipData?.zipList[object.GarmentId] && !loading">
                                             Garment Not Available
                                        </div>
                                      
                                        <div *ngIf="zipData?.zipList[object.GarmentId]">
                                            <div class="d-flex justify-content-center"
                                                *ngIf="!zipData || !zipData.zipList || !zipData?.zipList[object.GarmentId]">
                                                <div class="spinner-border spn-load text-white" role="status"></div>
                                            </div>
                                            <div class="bottom-div">
                                                <img class="img-responsive-bottom"
                                                    *ngIf="zipData && zipData?.zipList && zipData?.zipList[object.GarmentId]"
                                                    [src]="zipData.zipList[object.GarmentId] ? sanitizer.bypassSecurityTrustUrl(zipData?.zipList[object.GarmentId]) : ''" />
                                            </div>
                                            <img class="profileInfo upperwear_info col-sm-2"
                                                (click)="openClothPanel(zipData?.zipList[object.GarmentId],object.GarmentId,'BottomwearID')"
                                                style="width:25px; height:25px !important;"
                                                src="/assets/realtype/planinfoicon.png">
                                        </div>
                                    </div>
                                </div>
                                <!-- Similar changes as above for Footwear section -->
                                <div #scrollContainerMFootwear class="slider-css2-upper">
                                    <div *ngFor="let object of processedClothData[3].data; let i=index"
                                        id="elementmens-wearfootwear{{i}}" class="slider-img2-upper">
                                        <div *ngIf="loading"  class="spinner-border spn-load text-white loader-cloth" role="status">
                                        </div>
                                        <div class="cloth-text" *ngIf="!zipData?.zipList[object.GarmentId] && !loading">
                                             Garment Not Available
                                        </div>
                                        <div *ngIf="zipData?.zipList[object.GarmentId]">
                                            <div class="d-flex justify-content-center"
                                                *ngIf="!zipData || !zipData?.zipList || !zipData?.zipList[object.GarmentId]">
                                                <div class="spinner-border spn-load text-white" role="status"></div>
                                            </div>
                                            <div class="foot-div">
                                                <img class="img-responsive-foot"
                                                    *ngIf="zipData && zipData?.zipList && zipData?.zipList[object.GarmentId]"
                                                    [src]="zipData.zipList[object.GarmentId] ? sanitizer.bypassSecurityTrustUrl(zipData?.zipList[object.GarmentId]) : ''" />
                                            </div>
                                            <img class="profileInfo upperwear_info col-sm-2"
                                                (click)="openClothPanel(zipData?.zipList[object.GarmentId],object.GarmentId,'FootwearID')"
                                                style="width:25px; height:25px !important;"
                                                src="/assets/realtype/planinfoicon.png">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>









    <!-- <div *ngIf="this.packJsonObject">
    {{this.garmentObject.BrandID}}</div>


    <div *ngIf="this.packJsonObject">


        <div *ngFor="let garment of this.packJsonObject">
            <h3>{{ garment.title }}</h3>
            <ul>
                <li *ngFor="let name of garment.value.name">{{ 'GarmentId: ' + name.replace('deleted_', '') }}</li>
            </ul>
        </div>
    </div> -->



</div>

    <!-- <div *ngIf="isLoading" class="d-flex justify-content-center">
    <div class="spinner-border" role="status">
        <span class=""></span>
    </div>
</div> -->