<div *ngIf="loading" class="loading">Loading&#8230;</div>
<div *ngIf="!loading"    class="brand-profile-color">
  <div class="card brand-profile-color">
    <div class="dt-responsive selectlist font-14">
      <div class="dataTables_wrapper dt-bootstrap4" id="add-row-table_wrapper">
        <div class="container text-center" *ngIf="brandDetails">
          <div class="col-lg-12 d-flex justify-content-center">
            <div class="col-lg-12 text-center w-100 m-1"><img
                class="img-profile img-thumbnail img-fluid rounded profileImg"
                style="width: 70px; height:70px !important;" [src]="getBrandImage(brandDetails.LogoURL)"></div>
          </div>
          <h3 class="color-class">{{brandDetails.BrandName}}</h3>
          <button *ngIf="brandBoardDetails != undefined" type="button"
            class="btn btn-sm btn-outline-info color-class">Follow</button>
          <h6 class="color-class" *ngIf="brandBoardDetails != undefined"><img style="width:15px !important"
              src="./assets/pinterest-512.png">:
            {{brandBoardDetails.name}}</h6>
        </div>
        <div class="p-2 m-2 justify-content-center text-center d-flex ">
          <h6 class="text-primary brandbtn m-2 color-class" (click)="getStoryForGender(brandDetails)"
            [ngClass]="{'active': brandDetails.isClicked}">Story</h6>
          <h6 class="text-primary brandbtn m-2 color-class" (click)="getStyleForGender(brandDetails,'M')"
            [ngClass]="selectedGender === 'M' ? 'border-bottom border-dark' : ''">Menswear</h6>
          <h6 class="text-primary brandbtn m-2 color-class" (click)="getStyleForGender(brandDetails,'F') "
            [ngClass]="selectedGender === 'F' ? 'border-bottom border-dark' : ''">Womenswear</h6>
        </div>
        <div class="col-lg-12 p-1">
          <span *ngIf="errMessage != '' " class="danger">
            <div class="alert alert-danger" role="alert">
              {{errMessage}}
            </div>
          </span>
        </div>
      </div>
      <div class="card brand-profile-color" *ngIf="!isloading">
        <div class="d-flex brand-profile-color my">
          <div class=" file ">
            <div class="row form d-block " *ngIf="packJsonObject.length > 0">
              <div class="slider-css211 ">
                <div *ngFor="let object of packJsonObject[0].value['files'];let i=index" class="slider-img211"
                  data-bs-toggle="collapse" data-bs-target="#info-3D" saria-expanded="false">
                  <img class="img-responsive1" [src]="sanitizer.bypassSecurityTrustUrl(zipList[object])">
                </div>
              </div>
              <div class="slider-css2-upper">
                <div *ngFor="let object of packJsonObject[1].value['files'];let i=index" class="slider-img2-upper">
                  <div class="upper-div">
                    <img class="img-responsive-upper" [src]="sanitizer.bypassSecurityTrustUrl(zipList[object])" />
                  </div>
                </div>
              </div>
              <div class="slider-css2">
                <div *ngFor="let object of packJsonObject[2].value['files'];let i=index" class="slider-img2">
                  <img class="img-responsive" [src]="sanitizer.bypassSecurityTrustUrl(zipList[object])">
                </div>
              </div>
              <div class="slider-css211">
                <div *ngFor="let object of packJsonObject[3].value['files'];let i=index" class="slider-img211">
                  <img class="img-responsive1" [src]="sanitizer.bypassSecurityTrustUrl(zipList[object])">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-center">
      <div class="spinner-border" *ngIf="isloading" role="status">
        <span class=""></span>
      </div>
    </div>
  </div>