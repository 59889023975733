import { Component, Input, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { PopupThreeDComponent } from 'src/app/common/popup-three-d/popup-three-d.component';
import { BrandPopupComponent } from 'src/app/common/templets/brand-popup/brand-popup.component';
import { Brand } from 'src/app/models/brand';
import { ApiHttpService } from 'src/app/services/api-http.service';
import { CommonService } from 'src/app/services/common.service';
import { DataSharingService } from 'src/app/services/data-sharing-service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-public-garment-profile',
  templateUrl: './public-garment-profile.component.html',
  styleUrls: ['./public-garment-profile.component.css']
})
export class PublicGarmentProfileComponent implements OnInit {
  @Input() keys: string | undefined;
  @Input() garmentId: string | undefined;
  @Input() brandDID: any = '2lFKAEuohRzyyqDpwqCd';
  @Input() clothType: any = 'upperwear';
  @Input() gender: string | undefined;



  

  brandsFilesList = this.firestore.collection('brands').valueChanges({ idField: 'id' }) as Observable<Brand[]>;
  userFilesList = this.firestore.collection('userfiles').valueChanges({ idField: 'id' }) as Observable<any[]>;
  garmentsList = this.firestore.collection('garments').valueChanges({ idField: 'id' }) as Observable<any[]>;
  userId: string;
  userSnapShotList: any = [];
  profileName: any
  userName: any = []
  userDetails: any
  filterSnapShotFileDetails: any = []
  SnapShotFileDetails: any = []
  id: any;
  isLoadingforUserDetails: boolean = true;
  profileNameSrc: any = "profile";
  brandCollection: any = {}
  brandId: any
  brandProfileDetails: any = []
  garmentsCollection: any = []
  snapShotBrandList = [];
  brandGarments: any = []
  brandBoard: any
  womensBrands: any;
  mensBrands: any;
  clothDetailsList:any={}
  userUploadedFileList: any = [];
  userFiledetails: any = [];
  counter: number = 2;
  next: number = 1;
  showImages:any={}
  gltfImgSrc=""
  key:any
  loadingForApi:boolean = true
  snaploaderName:boolean = true
  brandName:any
  clothsType: string;
  Gender: string;
  pageScroll: any;
  scroll: boolean;
  heartFlag : boolean = false;


  constructor(
    private firestore: AngularFirestore,
    private dataSharingService: DataSharingService,
    private route: ActivatedRoute,
    private apiHttpService: ApiHttpService,
    public router: Router,
    private dialog: MatDialog,private commonService:CommonService,
    private modal: NgbModal,


  ) {
   

  }
  ngOnInit(): void {
    window.addEventListener('scroll',this.scrolling, true)
    this.route.paramMap.subscribe(paramMap => {
      this.brandId = paramMap.get('brandId');
      this.key = paramMap.get('key');
      this.id = paramMap.get('id');
      this.clothsType = paramMap.get('clothsType'); 
      this.Gender = paramMap.get('Gender')

      // console.log("Data id",this.garmentId)
      // console.log("Data key",this.keys)
      // console.log("Data Gender",this.gender)
      // console.log("Data brandId", this.brandDID)
      // console.log("Data clothsType", this.clothsType)


       if(this.garmentId != undefined){
      this.brandId = '2lFKAEuohRzyyqDpwqCd';
      this.clothsType = this.clothType;
      this.Gender=this.gender;
      this.key = this.keys
      this.id  = this.garmentId
      // console.log("Data id",this.id)
      // console.log("Data key",this.key)
      // console.log("Data Gender",this.Gender)
      // console.log("Data brandId", this.brandId)
      // console.log("Data clothsType", this.clothsType)
    }
    })

    this.loadingForApi = true;    
    this.snaploaderName = true;
    
    



    this.dataSharingService.userBrandGarmentObservable.subscribe(garments => {
      this.brandGarments = garments
    })

    this.dataSharingService.userSnapShotObservable.subscribe(snapshot => {
      if(snapshot != undefined || snapshot.length == 0){
        this.loadingForApi = true;
        let key = this.key
        let reqInputObject = {fileType:key,id:this.id,pageNumber:1}
          this.commonService.yologramSnapshotDetailsByType(reqInputObject).subscribe(async (response: any) => {
            this.loadingForApi = false;
            this.userSnapShotList = response.object;
        })        
        } else { 
          this.loadingForApi = false;
          this.userSnapShotList = snapshot
        }
      })
    this.dataSharingService.userclothDetailsListObservable.subscribe(cloth => {
      this.snaploaderName = false;
      this.clothDetailsList = cloth
    })
    this.dataSharingService.userBrandBoardObservable.subscribe(board => {
      this.brandBoard = board
    })
    this.brandsFilesList.subscribe(brandList => {
      this.brandCollection = brandList
      let brandIDforProfile = this.brandCollection.find((brandListProfile) => brandListProfile.id == this.brandId)
      let brandIDforName = this.brandCollection.find((brandListProfile) => brandListProfile.id == this.clothDetailsList.BrandID)
      this.brandName = brandIDforName
      this.brandProfileDetails = brandIDforProfile
      this.mensBrands = brandList.filter((b: any) => b.Gender === 'M' || b.Gender === 'B');
      this.womensBrands = brandList.filter((b: any) => b.Gender === 'F' || b.Gender === 'B');
    })
   
    this.userFilesList.subscribe((userFile) => {
      this.userUploadedFileList = userFile
      let clothId = this.clothDetailsList.id
      let filelist = this.userUploadedFileList.filter((uploadlist) => uploadlist.link == clothId)
      this.userFiledetails = filelist

    })


  }

  getBrandImage(imgURL: string) {
    let brandEncodedurl = encodeURI(imgURL).replace('/', '%2F');
    let url = "https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/" + brandEncodedurl + "?alt=media&token=550e7e88-bed3-4668-84ff-ad491c19e06c"
    return url;
  }
  getSnapShotListByUser(userId) {
    let userSnapShotList = this.userSnapShotList.filter((snapshot) => snapshot.UserID == userId);
    this.dataSharingService.sendUserSnapShots(userSnapShotList);
    this.router.navigate(['yologram-social/yologram-public-news', { 'id': userId }])
  }

  getSelectSnapshotFeed(userSnap) {
    let userId = userSnap.UserID
    let storyid = userSnap.StoryID
    let userSnapShotList = this.userSnapShotList.filter((snapshot) => snapshot.UserID == userId && snapshot.StoryID == storyid);
    this.dataSharingService.sendUserSnapShots(userSnapShotList);
  }

  getUserProfile() {
    let userId = this.userId
    this.dataSharingService.sendUserSnapShots(userId);
    this.router.navigate(['yologram-social/yologram-public-news', { 'id': userId }])
  }

  getBrandStories(brand: any) {
    this.router.navigate(['yologram-social/yologram-brand-stories', { 'brandId': this.brandProfileDetails['id'] }])
  }

  getFeed(brand: any, brandName: any) {
    let boardName = this.brandBoard
    this.router.navigate(['public/public-brand-stories', { 'brandId': brandName.id, 'storyId': brand.StoryID,'board':boardName }])
  }


  getBrandStyle(brandName:any,cloths:any){
    this.dataSharingService.sendUserSnapShots(this.userSnapShotList);
    let boardName = this.brandBoard
    this.router.navigate(['public/public-garment-style', { 'brandId':cloths.BrandID, 'clothsType': cloths.GarmentType ,'Gender':cloths.Gender,'id':cloths.id, 'key':this.key }])
  }

  getStoryForGenderCloth(brandIds) {
    let snap = this.userSnapShotList
    console.log("HEre",this.userSnapShotList);
    this.dataSharingService.sendUserSnapShots(snap)
    let brandid = brandIds.id
    this.router.navigate(['public/public-garment-profile', { 'brandId': brandid, }])
  }

  incrementImgCounter() {
    this.counter =  this.counter + 2;
  }


  clickOnHeart(){
    this.heartFlag = true
   } 


   openImageBox(fileurl){
    let url = fileurl
    let showImage = this.userFiledetails.find((file) => file.fileurl == url)
    this.showImages = showImage
   }

   
   openModel(gltfImgSrc: string) {
    const dialogRef = this.dialog.open(PopupThreeDComponent);
    this.gltfImgSrc = gltfImgSrc
    dialogRef.componentInstance.gltfImgSrc = this.gltfImgSrc;
    dialogRef.afterClosed().subscribe(result => {
    });
  }


  getgarmentStylefeed(cloths: any) {
    this.userSnapShotList = []
    let key = this.key
    this.dataSharingService.sendUserSnapShots(this.userSnapShotList)
    // this.dataSharingService.sendClothDetails(cloths)
    this.router.navigate(['public/public-garment-feed', { 'brandId': this.brandId, 'clothsType': cloths.GarmentType, 'Gender': cloths.Gender, 'id': cloths.id,'key':key }])
  }

  scrolling=(s)=>{
    // let sc = s.target.scrollingElement.scrollTop;
    // console.log("Scroll",sc)
    // this.pageScroll = sc
    // if(sc >=100){this.scroll=true}
    // else{this.scroll=false}
  }


  nextButtonForGarment(){
    let key = this.key
    this.next =  this.next + 1;
    let reqInputObject = {fileType:key,id:this.id,pageNumber:this.next}
    this.commonService.yologramSnapshotDetailsByType(reqInputObject).subscribe(async (response: any) => {
    this.userSnapShotList.push(...response.object);
    })
  }
  openBrandPoup(){
    // this.dataSharingService.sendUserSnapShots(this.brandhShapShotsList)
   const modalRef = this.modal.open(BrandPopupComponent,{
      modalDialogClass:"model-garmentPopup"
    });
    modalRef.componentInstance.garmentId =  this.id;
    modalRef.result.then((result) => {
      console.log(result);
    }).catch((error) => {
      console.log(error);
    });
  }
}



