import { Component, Input, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';
import { CommonService } from 'src/app/services/common.service';
import { PinterestService } from 'src/app/services/pinterest-service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataSharingService } from 'src/app/services/data-sharing-service';
import { NavigationEnd, Router } from '@angular/router';




@Component({
  selector: 'app-garment-popup',
  templateUrl: './garment-popup.component.html',
  styleUrls: ['./garment-popup.component.css']
})
export class GarmentPopupComponent implements OnInit {
  @Input() key: string | undefined;
  @Input() garmentId: string | undefined;
  @Input() routeFlageEvenGarment: string | undefined;
  garmentsList = this.firestore.collection('garments').valueChanges({ idField: 'id' }) as Observable<any[]>;
  brandsFilesList = this.firestore.collection('brands').valueChanges({ idField: 'id' }) as Observable<any[]>;
  userFilesList = this.firestore.collection('userfiles').valueChanges({ idField: 'id' }) as Observable<any[]>;
  userGarmentCollection = this.firestore.collection('garment-collection').valueChanges({ idField: 'id' }) as Observable<any[]>;
  userGarmentCollectionItems = this.firestore.collection('garment-collection-items').valueChanges({ idField: 'id' }) as Observable<any[]>;
  snapByApi: any = [];
  clothShapShotsListAll: any = [];
  clothDetails: any;
  brandDetails: any;
  counter: number = 2;
  userFiledetails: any = [];
  userFiledetails3d: any = [];
  garmentsCollection: any = [];
  brandsCollection: any = [];
  brandboardCollection: any = [];
  userUploadedFileList: any = [];
  clocthCollectionIdcollection: any = [];
  boards: any;
  clothShapShotsList: any = [];
  SnapShotFileDetails: any = [];
  brandObject: any
  clothShapShotsListSend: any = [];
  snaploadingSnap: boolean = true

  garmentFlag: boolean = true
  image2DFlag: boolean = true
  heartFlag: boolean = false
  iconselctFlag: boolean = false
  selectedIcon: string = 'popup';
  selectedTab: string;
  showMore: boolean = false;
  collectionData: any = [];
  collectionDetails: any = [];
  dummyText: any = `Your brand's apparel is digitised in 3D.\n \n
  On a gamified experience, consumers style avatars & share \n  \n
  animated avatar stories (linked to ecommerce)\n \n
  Defining a new paradigm in immersive storytelling for social media & the metaverse`;
  images = [
    { "image": "./assets/garment-upload-dummy/img-1.jpg" },
    { "image": "./assets/garment-upload-dummy/img-2.jpg" },
    { "image": "./assets/garment-upload-dummy/img-3.jpg" },
    { "image": "./assets/garment-upload-dummy/img-4.jpg" },
    { "image": "./assets/garment-upload-dummy/img-5.jpg" },
    { "image": "./assets/garment-upload-dummy/img-6.jpg" },
    { "image": "./assets/garment-upload-dummy/img-7.jpg" },
    { "image": "./assets/garment-upload-dummy/img-8.jpg" },
    { "image": "./assets/garment-upload-dummy/img-9.jpg" },
  ]
  words: any;
  gName: any;


  constructor(private dataSharingService: DataSharingService,
    private modal: NgbModal,
    private firestore: AngularFirestore,
    private pinterestAPI: PinterestService,
    private commonService: CommonService,
    public router: Router,
  ) {

    this.garmentsList.subscribe(
      garments => {
        this.garmentsCollection = garments;
        this.clothDetails = this.garmentsCollection.find((b: any) => b.id == this.garmentId);
        let brandGarments = this.garmentsCollection.filter((b: any) => b.id == this.garmentId);
        this.clocthCollectionIdcollection = brandGarments.map((garment) => garment.id);
        
      });

    this.garmentFlag = true
    this.brandsFilesList.subscribe((brands) => {
      this.brandsCollection = brands;
      this.brandObject = this.brandsCollection.find((b: any) => b.id === this.clothDetails.BrandID);
      this.garmentFlag = false
    });

    this.image2DFlag = true
    this.userFilesList.subscribe((userFile) => {
      this.userUploadedFileList = userFile
      let clothId = this.clothDetails.id
      let filelist2d = this.userUploadedFileList.filter((uploadlist) => uploadlist.link == clothId && uploadlist.fileType == 'GarmentUpload2D')
      let filelist3d = this.userUploadedFileList.filter((uploadlist) => uploadlist.link == clothId && uploadlist.fileType == 'GarmentUpload3D')
      this.userFiledetails = filelist2d
      this.userFiledetails3d = filelist3d
      this.image2DFlag = false
    })

    this.commonService.snapshotfileDetais().subscribe(async (snapshot: any) => {
      this.SnapShotFileDetails = snapshot;
    })

    this.pinterestAPI.getBoardList().subscribe((data: {
      name: any; items: any;
    }) => {
      this.boards = data.items
    })

    this.userGarmentCollection.subscribe((collectiondata) => {
      this.collectionData = collectiondata
    })
    this.userGarmentCollectionItems.subscribe((collectiondata) => {
      this.collectionDetails = collectiondata
      this.words = this.collectionDetails.name.split(" ");
    })
  }

  ngOnInit(): void {
    let reqInputObject = {
      fileType: this.key,
      id: this.garmentId,
      pageNumber: 1
    }
    this.snaploadingSnap = true
    this.commonService.yologramSnapshotDetailsByType(reqInputObject).subscribe(async (response: any) => {
      this.snapByApi = response.object
      this.clothShapShotsListSend = this.snapByApi
      this.clothShapShotsListAll = this.snapByApi.slice(0, 3)
      this.snaploadingSnap = false
      if (response != undefined) { this.snaploadingSnap = false }
    })
    if (this.router.url == '/garment') {
       this.iconselctFlag = false
    }
    console.log(this.routeFlageEvenGarment+'flg condition')
  }




  getgarmentStyle() {
    this.closePopUp()
    let snap = this.clothShapShotsListSend
    let cloths = this.clothDetails
    this.dataSharingService.sendUserSnapShots(snap)
    this.dataSharingService.sendClothDetails(this.clothDetails)
    this.dataSharingService.sendUserfiles(this.userFiledetails)

    this.router.navigate([ this.routeFlageEvenGarment != undefined  ? this.routeFlageEvenGarment :  '/garment', { 'brandId': cloths.BrandID, 'clothsType': cloths.GarmentType, 'Gender': cloths.Gender, 'id': cloths.id, 'key': this.key, 'tab': 'Menswears' }])
    // this.router.navigate([this.routeFlageEvenGarment != undefined ? this.routeFlageEvenGarment : 'public/public-garment-feed', { 'brandId': cloths.BrandID, 'clothsType': cloths.GarmentType, 'Gender': cloths.Gender, 'id': cloths.id, 'key': this.key, 'tab': 'Menswears' }])
  }

  getgarmenFeed(snapshot) {
    this.closePopUp()
  //   if(this.routeFlageEvenGarment == undefined){
  //   this.router.events.subscribe((event) => {
  //     if (event instanceof NavigationEnd) {
  //       if (this.router.url.includes('/garment')) {
  //         window.location.reload();
  //        }
  //     }
  //   });
  // }
    let snap = this.clothShapShotsListSend
    let cloths = this.clothDetails
    this.dataSharingService.sendUserSnapShots(snap)
    this.dataSharingService.sendClothDetails(this.clothDetails)
    this.dataSharingService.sendUserfiles(this.userFiledetails)
    this.router.navigate([ this.routeFlageEvenGarment != undefined  ? this.routeFlageEvenGarment :  '/garment', { 'brandId': cloths.BrandID, 'clothsType': cloths.GarmentType, 'Gender': cloths.Gender, 'id': cloths.id, 'key': this.key, 'storyId': snapshot.StoryID, 'tab': 'story' }])
    // this.router.navigate([this.routeFlageEvenGarment != undefined ? this.routeFlageEvenGarment : 'public/public-garment-feed', { 'brandId': cloths.BrandID, 'clothsType': cloths.GarmentType, 'Gender': cloths.Gender, 'id': cloths.id, 'key': this.key, 'storyId': snapshot.StoryID, 'tab': 'story' }])
  }


  clickOnHeart() {
    this.heartFlag = true
  }
  getBrandImage(imgURL: string) {
    let brandEncodedurl = encodeURI(imgURL).replace('/', '%2F');
    let url = "https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/" + brandEncodedurl + "?alt=media&token=550e7e88-bed3-4668-84ff-ad491c19e06c"
    return url;
  }
  closePopUp() {
    this.modal.dismissAll(GarmentPopupComponent)
  }

  getBrandProfileByGarment(brand: any, board: any) {
    console.log("flag garment",this.routeFlageEvenGarment)
    this.closePopUp()
    this.dataSharingService.sendUserSnapShots(this.clothShapShotsListSend);

          this.router.navigate(['/brand', { 'brandId': brand.id, 'select-tab': 'story' }])

    // if(this.routeFlageEvenGarment ==  "yologram-social/yologram-garment-feed"){
    //   this.router.navigate(["yologram-social/yologram-brand-stories", { 'brandId': brand.id, 'select-tab': 'story' }])
    // }else {
    //   this.router.navigate(['public/public-brand-stories', { 'brandId': brand.id, 'select-tab': 'story' }])
    // }
  }

     
  // getBrandProfileByGarment(brand: any) {
  //   this.dataSharingService.sendUserSnapShots(this.clothShapShotsListSend);
  //   if(this.routLogInFlag){
  //     this.router.navigate([  'yologram-social/yologram-brand-stories', { 'brandId': brand.id, 'select-tab': 'story' }])
  //   }else {
  //     this.router.navigate([  'public/public-brand-stories', { 'brandId': brand.id, 'select-tab': 'story' }])
  //   }
  // }

  getClothProfile(brand: any) {
    this.closePopUp()
    // if(this.routeFlageEvenGarment == undefined){
    //   this.router.events.subscribe((event) => {
    //     if (event instanceof NavigationEnd) {
    //       if (this.router.url.includes('/garment')) {
    //         window.location.reload();
    //        }
    //     }
    //   });
    // }
    if (this.router.url == '/garment') { window.location.reload() }
    let snap = this.clothShapShotsListSend
    let cloths = this.clothDetails
    this.dataSharingService.sendUserSnapShots(snap)
    this.dataSharingService.sendClothDetails(this.clothDetails)
    this.dataSharingService.sendUserfiles(this.userFiledetails)
    this.router.navigate([ this.routeFlageEvenGarment != undefined  ? this.routeFlageEvenGarment :  '/garment', {
      'brandId': cloths.BrandID,
      'clothsType': cloths.GarmentType, 'Gender': cloths.Gender, 'id': cloths.id, 'key': this.key, 'tab': 'Menswear'
    }])
    // this.router.navigate([this.routeFlageEvenGarment != undefined ? this.routeFlageEvenGarment : 'public/public-garment-feed', {
    //   'brandId': cloths.BrandID,
    //   'clothsType': cloths.GarmentType, 'Gender': cloths.Gender, 'id': cloths.id, 'key': this.key, 'tab': 'Menswear'
    // }])
  }



  selectIcon(icon) {
    this.selectedIcon = ''
    this.selectedIcon = icon
    this.iconselctFlag = true
  }

  getStory(Menswears) {
    if (Menswears == 'Menswears') {
      this.iconselctFlag = true
      this.selectedTab = "Menswears"
    }
    this.selectedIcon = ''
  }
  getStyle(brandIds) {
    this.selectedIcon = ''
    this.iconselctFlag = false
    this.selectedTab = "Menswear"
  }



  filterCollectionName(id) {
    let garmentCollectionId = (this.collectionDetails != undefined && this.collectionDetails).find((colection) => colection.id === id )
    let getCollection =  this.collectionData.find((colection) => colection.id === garmentCollectionId.GarmentCollectionID && colection.status == 'A')
    let name = getCollection !=undefined &&  getCollection.CollectionName != undefined ? getCollection.CollectionName : '';
    this.gName = id
    return name
  }
  filterActiveStatus(){
    let filter = this.clothDetails.find((clothD)=>clothD.id === this.gName)
  }

}