<div class="modal-content imp-block-brand">
    <h5 type="button" (click)="closePopUp()" class="btn-close  closBtn" data-bs-dismiss="modal" aria-label="Close"></h5>
    <div class="container">
        <div class="m-1 text-end">
            <h6 class="brand-font-size" *ngIf="brandBoardDetails != undefined && brandBoardDetails.name != undefined ">
                <img style="width:15px !important" src="./assets/pinterest-512.png">:{{brandBoardDetails.name}}
            </h6>
            <h6 class="brand-font-size" *ngIf="brandBoardDetails != undefined && brandBoardDetails.name == undefined">
                <img style="width:15px !important" src="./assets/pinterest-512.png">:Not Linked
                {{brandBoardDetails.name}}
            </h6>
        </div>

        <div class="contain-fluid m-1 garmentName">
            <div class="col-lg-12  pt-4px  text-center w-100  d-flex justify-content-center  d-flex">
                <div class="text-center d-flex col-2" *ngIf="brandDetails != undefined && brandDetails.LogoURL != undefined">
                    <img class="profileImg-brand" style="width:35px; height:35px !important;"
                    (click)="getBrandProfile(brandDetails.id,brandDetails.boardName)"
                        [src]="getBrandImage(brandDetails.LogoURL)">
                </div>
                <div class="m-0  outer col-9">
                    <div class="col-12 vertical-center" *ngIf="brandDetails != undefined && brandDetails.LogoURL != undefined">
                        <h6 (click)="getBrandProfile(brandDetails.id,brandDetails.boardName)"
                            [ngClass]="brandDetails.BrandName.length >= 16  ? 'brand-font-size' : 'color-BrandName'">
                            {{brandDetails.BrandName}}</h6>
                    </div>
                </div>
                <div class="m-0 col-1 text-end">
                    <span><img *ngIf="heartFlag == true" class="heart" style="width:18px !important; color:black"
                            src="./assets/storystyle/newStoryIcon/heart.png"></span>
                    <span (click)="clickOnHeart()"><img *ngIf="heartFlag == false" class="heart"
                            style="width:18px !important; color:black"
                            src="./assets/storystyle/newStoryIcon/heartGray.png">
                    </span>
                </div>
            </div>
            <div class="brand-profile-color menu-color">
                <div class="justify-content-center text-center d-flex set-margin ">
                    <h6 (click)="getBrandProfile(brandDetails.id,brandDetails.boardName)"
                        class="text-primary brandbtn m-1 py-2 d-block class">
                        <img style="width: 15px;" src="./assets/storystyle/story-white.png">
                    </h6>
                    <h6 (click)="getBrandStyle(brandDetails.id,'M')"
                        class="text-primary brandbtn m-1 py-2 d-block color-class">
                        <img style="color:black ;padding-bottom: 5px; width: 15px"
                            src="./assets/storystyle/MenIconWhite.png">
                    </h6>
                    <h6 (click)="getBrandStyle(brandDetails.id,'F')"
                        class="text-primary brandbtn m-1 py-2 d-block color-class">
                        <img style="color:black ;padding-bottom: 5px; width: 15px"
                            src="./assets/storystyle/WomenIconWhite.png">
                    </h6>
                </div>
            </div>
        </div>
        <div class="row w-100 m-1" *ngIf=" !brandsnaploading && userSnapShotList.length >= 0   ">
            <div class="column d-block m-1" *ngFor="let snapshot of userSnapShotList  ; let i=index;">
                <img class="w-100 shadow-1-strong rounded" data-bs-dismiss="modal"
                    (click)="getFeed(snapshot,brandDetails)" [src]="snapshot.SnapshotFileUrl">
            </div>
            <div class="columnImg d-block m-1" *ngIf="userSnapShotList.length > 2">
                <img class="w-80 shadow-1-strong rounded image-pop" data-bs-dismiss="modal"
                    (click)="getBrandProfile(brandDetails.id,brandDetails.boardName)" src="./assets/plus-8-512.png">
            </div>
        </div>
    </div>
    <div *ngIf="brandsnaploading" class="text-center ">
        <div class="spinner-border m-5 p-5 " role="status">
            <span class="sr-only"></span>
        </div>
    </div>
</div>