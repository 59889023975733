import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiHttpService } from 'src/app/services/api-http.service';
import { CommonService } from 'src/app/services/common.service';
import { DataSharingService } from 'src/app/services/data-sharing-service';
import { environment } from 'src/environments/environment';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Observable, forkJoin, of, switchMap, take } from 'rxjs';
import { UserPopupComponent } from 'src/app/common/templets/user-popup/user-popup.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-yologram-network',
  templateUrl: './yologram-network.component.html',
  styleUrls: ['./yologram-network.component.css']
})
export class YologramNetworkComponent implements OnInit {
  garments = this.firestore.collection('garments').valueChanges({ idField: 'id' }) as Observable<any[]>;
  userList = this.firestore.collection('user-profile').valueChanges({ idField: 'id' }) as Observable<any[]>;
  @Input() userProfile: string;
  @Input() rootFlag: string;
  @Input() routLogInFlag: boolean | undefined;


  isLoading: boolean = false;


  SnapShotFileDetails: any = []
  filterSnapShotFileDetails: any = []
  filteredArray: any = []
  userImfo: any = []
  filterByUserFlag: boolean = false
  userSnapShotList: any = []
  userAllDetails: any = []
  profileDetails: any = {};
  garmentdetails: any = []
  filterSnapShotFileDetailsall: any = []
  useSnap: any = []
  selectedSanp: any = []
  allUserDetails: any = []
  allUserSnapDetails: any = []
  userData: any = []
  filteredUserProfile: any = []
  userA: any = []
  networkList = [];
  userDetail: any = []
  AllDetails: any = []
  userId: string;
  paginationObject = { pageNumber: 1 };
  userSnapShotDetailsCollection = {};
  selectedNumber: any
  popupLoading: boolean = true
  popupLoadingIMg: boolean = true
  logInFlag: boolean;
  popupLoadingFor2D: boolean = true
  userPage: any = 'yologram-social/yologram-user-myprofile'
  useFeedPagelink: any = '';
  selectedUserName: any;
  userCollapseFlag: boolean = false;

  UserData = []
  userDataCache = {};
  userDataCacheOrder = [];
  snapshot = {}
  brandStoryScrollerHeight: number;
  sideNavHeight: number;
  closeDiv:boolean = false
  wholeDiv:boolean = false


  constructor(private firestore: AngularFirestore,
    private route: ActivatedRoute,
    public router: Router,
    private dataSharingService: DataSharingService,
    private apiHttpService: ApiHttpService,
    private http: HttpClient,
    private modal: NgbModal,
    private commonService: CommonService) {
    // this.commonService.updateUserProfile()

    this.logInFlag = this.commonService.isLoggedIn()
    console.log('isLoggedIn', this.commonService.isLoggedIn())
    console.log('isLoggedIn', this.logInFlag)
    this.garments.subscribe(garmentlist => {
      this.garmentdetails = garmentlist
    });

    // this.userList.subscribe(userdetails => {
    //   this.userData = userdetails
    //   this.commonService.yologramNetwork(this.paginationObject).subscribe(async (allSnap: any) => {
    //     this.paginationObject['count'] = allSnap.count;
    //     this.paginationObject['paginationSize'] = allSnap.paginationSize;
    //     this.paginationObject['totalPage'] = Math.floor(this.paginationObject['count'] / this.paginationObject['paginationSize']);
    //     this.paginationObject['pageObject'] = Array(this.paginationObject['totalPage'] + 1).fill(1).map((x, i) => i + 1);
    //     this.networkList = allSnap.object
    //     let inputObject = { 'networkList ': this.networkList }
    //     console.log('inputObject',inputObject)
    //     this.commonService.getUsersProfile(inputObject).subscribe(async (allUsers: any) => {
    //     this.allUserDetails = allUsers.users;
    //     this.userData.forEach(object => {
    //     let filterSnapShots = this.networkList.find((user) => user == object.UserID);
    //     let filterUserProfile =  this.userData.find((user) => user.UserID == filterSnapShots);
    //     console.log('filteruserdata==>',filterUserProfile)
    //     if(filterUserProfile != undefined){
    //       this.filteredUserProfile.push(filterUserProfile)
    //     }
    //     this.filteredUserProfile.forEach(object => {
    //       let data =  this.allUserDetails.find((user)=>user.uid  == object.UserID)
    //       object.ProfileName = data.displayName
    //       object.ProfilePhoto = data.photoURL
    //     })
    //   })

    //   })
    //   })
    // });

    // this.userList.subscribe(async userdetails => {
    //   this.isLoading = true
    //   this.userData = userdetails;
    //   const startTime = performance.now(); // Start timing
    //   await this.commonService.yologramNetwork(this.paginationObject).subscribe(async (allSnap: any) => {
    //     this.filteredUserProfile = allSnap.userProfiles.map(userProfile => userProfile.profile);
    //     console.log("this.filteredUserProfile", this.filteredUserProfile);
    //     this.isLoading = false
    //     this.paginationObject['count'] = allSnap.count;
    //     this.paginationObject['paginationSize'] = allSnap.paginationSize;
    //     this.paginationObject['totalPage'] = Math.floor(this.paginationObject['count'] / this.paginationObject['paginationSize']);
    //     this.paginationObject['pageObject'] = Array(this.paginationObject['totalPage'] + 1).fill(1).map((x, i) => i + 1);
    //     this.networkList = allSnap.object;
    //     // this.allUserDetails = []
    //     // // Removing the getUsersProfile service call
    //     // this.allUserDetails = this.userData; // Assuming userData already contains the required user profiles
    //     // this.filteredUserProfile = [];

    //     // try {
    //     //   // Use Promise.all to handle multiple async operations concurrently
    //     //   const filteredUserProfiles = await Promise.all(this.userData.map(async (object) => {
    //     //     // Find the snapshot corresponding to the user
    //     //     const filterSnapShots = this.networkList.find((user) => user == object.UserID);

    //     //     // If a matching snapshot is found, find the user profile
    //     //     if (filterSnapShots) {
    //     //       const filterUserProfile = this.userData.find((user) => user.UserID == filterSnapShots);

    //     //       // Return the user profile if it exists
    //     //       if (filterUserProfile) {

    //     //         return filterUserProfile;
    //     //       }
    //     //     }

    //     //     // Return null if no profile is found
    //     //     return null;
    //     //   }));

    //     //   // Filter out null values from the results
    //     //   this.filteredUserProfile = filteredUserProfiles.filter(profile => profile !== null);
    //     //   const endTime = performance.now(); // End timing
    //     //   const duration = endTime - startTime; // Calculate duration

    //     //   // Show the time taken in an alert
    //     //   alert(`Function execution time: ${duration.toFixed(2)} ms`);

    //     //   console.log('Filtered User Profiles:', this.filteredUserProfile);
    //     // } catch (error) {
    //     //   console.error('Error filtering user profiles:', error);
    //     // }

    //     // Remove duplicates

    //     // const uniqueUserIds = new Set();
    //     // this.filteredUserProfile = this.filteredUserProfile.filter(user => {
    //     //   if (uniqueUserIds.has(user.UserID)) {
    //     //     return false;
    //     //   } else {
    //     //     uniqueUserIds.add(user.UserID);
    //     //     return true;
    //     //   }
    //     // });

    //     console.log("this.allUserDetails", this.allUserDetails)
    //     console.log("this.filteredUserProfile", this.filteredUserProfile)

    //     // this.filteredUserProfile
    //     // this.filteredUserProfile.forEach(object => {
    //     //   let data = this.allUserDetails.find((user) => user.uid == object.UserID);
    //     //   if (data) {
    //     //     object.ProfileName = data.displayName;
    //     //     object.ProfilePhoto = data.photoURL;
    //     //   }
    //     // });
    //     console.log("this.filteredUserProfile", this.filteredUserProfile)

    //     console.log("this.allUserDetails", this.allUserDetails)
    //   });
    // });

    this.networkdatafetch()
    // this.userList.subscribe(userdetails => {
    //   this.userData = userdetails
    //   this.nextButton(1)
    // })

    this.commonService.snapshotfileDetais().subscribe(async (snapshot: any) => {
      this.SnapShotFileDetails = snapshot;
      this.userData.forEach(object => {
        let filterSnapShots = this.SnapShotFileDetails.filter((snapShot) => snapShot.UserID == object.UserID);
        this.selectedSanp = filterSnapShots
        if (filterSnapShots.length > 0) {
          this.userA.push(object)
        }
      });
      this.update()
    });





  }



  async networkdatafetch() {
    this.isLoading = true
    const startTime = performance.now(); // Start timing
    await this.commonService.yologramNetwork(this.paginationObject).subscribe(async (allSnap: any) => {
      this.filteredUserProfile = allSnap.userProfiles.map(userProfile => userProfile.profile);
      console.log("this.filteredUserProfile", this.filteredUserProfile);
      this.isLoading = false
      this.paginationObject['count'] = allSnap.count;
      this.paginationObject['paginationSize'] = allSnap.paginationSize;
      this.paginationObject['totalPage'] = Math.floor(this.paginationObject['count'] / this.paginationObject['paginationSize']);
      this.paginationObject['pageObject'] = Array(this.paginationObject['totalPage'] + 1).fill(1).map((x, i) => i + 1);
      this.networkList = allSnap.object;
    });
  }

  
  ngOnInit(): void {

    // if(this.routLogInFlag){
    //   this.useFeedPagelink = '/user'
    // }else{
    //   this.useFeedPagelink =  "/user"
    // }

    //  this.nextButton(1);

    //  this.commonService.yologramNetwork(this.paginationObject).subscribe(async (allSnap: any) => {
    //   this.paginationObject['count'] = allSnap.count;
    //   this.paginationObject['paginationSize'] = allSnap.paginationSize;
    //   this.paginationObject['totalPage'] = Math.floor(this.paginationObject['count'] / this.paginationObject['paginationSize']);
    //   this.paginationObject['pageObject'] = Array(this.paginationObject['totalPage'] + 1).fill(1).map((x, i) => i + 1);
    //   this.networkList = allSnap.object;

    //   let inputObject = { 'userIds': this.networkList };
    //   console.log('networkList', inputObject);

    //   this.userData.forEach(object => {
    //     let filterSnapShots = this.networkList.find((user) => user == object.UserID);
    //     let filterUserProfile = this.userData.find((user) => user.UserID == filterSnapShots);
    //     // if (filterUserProfile != undefined) {
    //     //   this.filteredUserProfile.push(filterUserProfile);
    //     // }
    //   });
    //  this.nextButton(1);
    // });

    let title = 'Yologram - fam'
    this.commonService.changeTitle(title);

    this.setBrandStoryScrollerHeight()
    console.log('filteruserdata==>', this.filteredUserProfile)
  }

  nextButton(counter) {
    const startTime = performance.now(); // Start timing
    this.filteredUserProfile = [];
    this.selectedNumber = counter;
    this.isLoading = true
    this.paginationObject.pageNumber = counter;
    this.commonService.yologramNetwork(this.paginationObject).subscribe(async (allSnap: any) => {
      this.networkList = allSnap.object;
      let filteredUserProfile = allSnap.userProfiles.map(userProfile => userProfile.profile);
      this.filteredUserProfile = filteredUserProfile.filter(profile => profile !== null);
      this.isLoading = false
      this.paginationObject['count'] = allSnap.count;
      this.paginationObject['paginationSize'] = allSnap.paginationSize;
      this.paginationObject['totalPage'] = Math.floor(this.paginationObject['count'] / this.paginationObject['paginationSize']);
      this.paginationObject['pageObject'] = Array(this.paginationObject['totalPage'] + 1).fill(1).map((x, i) => i + 1);

     



      let inputObject = { 'userIds': this.networkList };
      console.log('networkList', inputObject);

      // Use Promise.all to handle filtering concurrently
      // const filteredProfiles = await Promise.all(this.userData.map(async (object) => {
      //   let filterSnapShots = this.networkList.find((user) => user == object.UserID);
      //   if (filterSnapShots) {
      //     let filterUserProfile = this.userData.find((user) => user.UserID == filterSnapShots);
      //     return filterUserProfile ? filterUserProfile : null;
      //   }
      //   return null;
      // }));

      // this.filteredUserProfile = filteredProfiles.filter(profile => profile !== null);

      const endTime = performance.now(); // End timing
      const duration = endTime - startTime; // Calculate duration

      // Show the time taken in an alert
      console.log(`Function execution time: ${duration.toFixed(2)} ms`);


      console.log('Filtered User Profiles:', this.filteredUserProfile);
    });
  }



  // nextButton(counter) {
  //   this.filteredUserProfile = []
  //   this.selectedNumber = counter
  //   this.paginationObject.pageNumber = counter
  //   this.commonService.yologramNetwork(this.paginationObject).subscribe(async (allSnap: any) => {
  //     this.paginationObject['count'] = allSnap.count;
  //     this.paginationObject['paginationSize'] = allSnap.paginationSize;
  //     this.paginationObject['totalPage'] = Math.floor(this.paginationObject['count'] / this.paginationObject['paginationSize']);
  //     this.paginationObject['pageObject'] = Array(this.paginationObject['totalPage'] + 1).fill(1).map((x, i) => i + 1);
  //     this.networkList = allSnap.object
  //     let inputObject = { 'userIds': this.networkList}
  //     console.log('networkList',inputObject)
  //     this.userData.forEach(object => {
  //       let filterSnapShots = this.networkList.find((user) => user == object.UserID);
  //       let filterUserProfile =  this.userData.find((user) => user.UserID == filterSnapShots);
  //       if(filterUserProfile != undefined){
  //         this.filteredUserProfile.push(filterUserProfile)
  //       }
  //       })
  //   })
  // }

  // update(){
  //   this.commonService.getAllUser().subscribe(async (allUsers: any) => {
  //     this.AllDetails = allUsers.users;
  //     this.AllDetails.forEach(object => {
  //     let filterSnapShots = this.SnapShotFileDetails.filter((snapShot) => snapShot.UserID == object.uid);
  //     this.selectedSanp = filterSnapShots
  //     if (filterSnapShots.length > 0) {
  //       this.userImfo.push(object)
  //     }
  //   });
  //   this.userImfo.forEach(object => {
  //     let details = object
  //     let userDetail = details.providerData[0]
  //     let saveUserDetails = {
  //       "DisplayName": userDetail.displayName != undefined ? userDetail.displayName : '',
  //       "Email": userDetail.email != undefined ? userDetail.email : '',
  //       "FirstName": '',
  //       "Identifier": '',
  //       "LastName": '',
  //       "MiddleName": '',
  //       "ProfilePicture": userDetail.photoURL != undefined ? userDetail.photoURL : '',
  //       "UserID": details.uid != undefined ? details.uid : '',
  //     }
  //     this.firestore.collection('user-profile', ref =>
  //       ref.where('UserID', '==', saveUserDetails.UserID))
  //       .get()
  //       .subscribe(querySnapshot => {
  //         if (querySnapshot.empty) {
  //           this.firestore.collection('user-profile').add(saveUserDetails);
  //         } else {
  //           console.log("User profile already exists in Firestore");
  //         }
  //       });
  //     })
  //   })
  // }

  update() {
    this.commonService.getAllUser().subscribe(async (allUsers: any) => {
      this.AllDetails = allUsers.users;
      this.AllDetails.forEach(object => {
        let filterSnapShots = this.SnapShotFileDetails.filter((snapShot) => snapShot.UserID == object.uid);
        this.selectedSanp = filterSnapShots;
        if (filterSnapShots.length > 0) {
          this.userImfo.push(object);
        }
      })
      // Assuming this.userData is an array that holds the UserIDs that should not be added again
      const userIDsInUserData = this.userData.map(user => user.UserID);
      this.userImfo.forEach(object => {
        let details = object;
        let userDetail = details.providerData[0];
        let saveUserDetails = {
          "DisplayName": userDetail.displayName != undefined ? userDetail.displayName : '',
          "Email": userDetail.email != undefined ? userDetail.email : '',
          "FirstName": '',
          "Identifier": '',
          "LastName": '',
          "MiddleName": '',
          "ProfilePicture": userDetail.photoURL != undefined ? userDetail.photoURL : '',
          "UserID": details.uid != undefined ? details.uid : '',
          "PageName": 'Fam'
        };
        // Check if UserID is already in this.userData
        if (!userIDsInUserData.includes(saveUserDetails.UserID)) {
          // If not, then check Firestore
          this.firestore.collection('user-profile', ref =>
            ref.where('UserID', '==', saveUserDetails.UserID))
            .get()
            .subscribe(querySnapshot => {
              if (querySnapshot.empty) {
                this.firestore.collection('user-profile').add(saveUserDetails).then((docRef) => {
                  console.log(`User profile added with ID: ${docRef.id}`);
                });
              } else {
                console.log("User profile already exists in Firestore");
              }
            });
        } else {
          console.log(`User profile for UserID ${saveUserDetails.UserID} already exists in userData, not adding to Firestore.`);
        }
      });
    });

  }




  yologramNerworkPagination(uuid) {
    let userObjet = this.allUserDetails.find(userObject => userObject.uid == uuid)
    let profileName = userObjet.displayName != undefined ? userObjet.displayName : uuid;
    return profileName;
  }

  getProfileName(uuid) {
    let userObjet = this.allUserDetails.find(userObject => userObject.uid == uuid)
    let profileName = userObjet.displayName != undefined ? userObjet.displayName : uuid;
    userObjet.profileName = profileName;
    return profileName;
  }

  getUserObject(uuid) {
    let userObjet = this.allUserDetails.find(userObject => userObject.uid == uuid)
    return userObjet;
  }

  getProfilePhoto(uuid) {
    let userObjet = this.allUserDetails.find(userObject => userObject.uid == uuid)
    let profilePhoto = userObjet.photoURL != undefined ? userObjet.photoURL : uuid;
    return profilePhoto;
  }
  getSnapShotListByUser(snapshot: any) {
    let userId = snapshot.userID;
    this.filterByUserFlag = true
    this.filterSnapShotFileDetails = this.SnapShotFileDetails.filter((snapshot) => snapshot.UserID == userId);
    this.dataSharingService.sendUserSnapShots(this.filterSnapShotFileDetails);
    this.router.navigate(['public/news-user', { 'id': userId }])
  }

  getSnapShotListByUserFeed(userIdforFeed: any) {
    let userId = userIdforFeed.userID;
    this.filterSnapShotFileDetails = this.SnapShotFileDetails.filter((snapshot) => snapshot.UserID == userId);
    this.dataSharingService.sendUserSnapShots(this.filterSnapShotFileDetails);
    this.router.navigate(['public/news', { 'id': userId }])
  }
  getUserSnapShotDashboar(userId) {
    let userSnapShotList = this.SnapShotFileDetails.filter((snapshot) => snapshot.UserID == userId);
    this.dataSharingService.sendUserSnapShots(this.allUserSnapDetails);
    this.router.navigate(['public/news-user', { 'id': userId }])
  }

  getSnapShotListByUserPopup(userId) {
    this.popupLoading = true;
    this.popupLoadingFor2D = true
    setInterval(() => {
      this.popupLoading = false;
    }, 5000);
    this.popupLoading = true
    this.profileDetails = this.allUserDetails.find(userObject => userObject.uid == userId)
    if (this.userSnapShotDetailsCollection[userId] == undefined) {
      let reqInputObject = { id: userId, pageNumber: 1 }
      this.commonService.yologramSnapshotDetailsByUserId(reqInputObject).subscribe(async (response: any) => {
        this.allUserSnapDetails = []
        this.filterSnapShotFileDetails = []
        this.allUserSnapDetails = response.object;
        this.filterSnapShotFileDetails = this.allUserSnapDetails.filter((snapshot) => snapshot.UserID == userId).slice(0, 3);
        this.filterSnapShotFileDetailsall = this.allUserSnapDetails.filter((snapshot) => snapshot.UserID == userId)
        this.userSnapShotDetailsCollection[userId] = this.filterSnapShotFileDetailsall
        this.dataSharingService.sendUserSnapShots(this.filterSnapShotFileDetailsall);
        this.popupLoading = false
      })
    } else {
      this.filterSnapShotFileDetailsall = this.userSnapShotDetailsCollection[userId];

    }
    this.popupLoadingIMg = true;
    setInterval(() => {
      this.popupLoadingIMg = false;
      this.popupLoadingFor2D = false;
    }, 500);
  }

  openUserPopup(item: any) {
    const modalRef = this.modal.open(UserPopupComponent, {
      modalDialogClass: "model-garmentPopup"
    });
    modalRef.componentInstance.id = 10;
    modalRef.componentInstance.UserShapShotObject = item;
    modalRef.componentInstance.UserID = item.UserID;
    modalRef.componentInstance.userData = item.userData;
    // modalRef.componentInstance.rootFlag = this.rootFlag
    modalRef.componentInstance.rootFlag = '/user'
    modalRef.componentInstance.routLogInFlag = '/user';



    modalRef.result.then((result) => {
      console.log(result);
    }).catch((error) => {
      console.log(error);
    });
  }

  getSnapShotListBySorted(userId) {
    this.profileDetails = this.allUserDetails.find(userObject => userObject.uid == userId)
    if (this.userSnapShotDetailsCollection[userId] == undefined) {
      let reqInputObject = { id: userId, pageNumber: 1 }
      this.commonService.yologramSnapshotDetailsByUserId(reqInputObject).subscribe(async (response: any) => {
        this.allUserSnapDetails = []
        this.allUserSnapDetails = response.object;
        this.filterSnapShotFileDetails = this.allUserSnapDetails.filter((snapshot) => snapshot.UserID == userId).slice(0, 3);
        this.filterSnapShotFileDetailsall = this.allUserSnapDetails.filter((snapshot) => snapshot.UserID == userId)
        this.userSnapShotDetailsCollection[userId] = this.filterSnapShotFileDetailsall
      })
    } else {
      this.filterSnapShotFileDetailsall = this.userSnapShotDetailsCollection[userId];
    }
    this.dataSharingService.sendUserSnapShots(this.filterSnapShotFileDetailsall);
    this.router.navigate(['public/news-user', { 'id': userId }])
  }

  getFeed(brand: any) {
    let garment = this.garmentdetails
    let userId = brand.UserID
    let storyid = brand.StoryID
    let userSnapShotList = this.userSnapShotList.filter((snapshot) => snapshot.UserID == userId && snapshot.StoryID == storyid);
    let branddetails = this.garmentdetails.find((snapshot) => snapshot.id === brand.BottomwearID || brand.HeadwearID || brand.FootwearID || brand.UpperwearID)
    this.useSnap = userSnapShotList
    this.dataSharingService.sendUserSnapShots(this.filterSnapShotFileDetails);
    this.router.navigate(['public/news', { 'brandId': branddetails.BrandID, 'storyId': brand.StoryID }])
  }



  filterprofilename() {
    this.commonService.snapshotfileDetais().subscribe(async (snapshot: any) => {
      this.SnapShotFileDetails = snapshot
      let name = this.SnapShotFileDetails.filter((names) => names.profileName)
    })
  }


  getSelectSnapshotFeed(userSnap: any) {
    let userId = userSnap.UserID
    let storyid = userSnap.StoryID
    let userSnapShotList = this.userSnapShotList.filter((snapshot) => snapshot.UserID == userId && snapshot.StoryID == storyid);
    this.dataSharingService.sendUserSnapShots(userSnapShotList);
    this.router.navigate(['public/news', { 'id': userId, 'storyId': storyid }])
  }

  async openUserPanel(snapshot: any, event: any) {
    console.log('snapshot',snapshot)
    event.stopPropagation();
    this.closeDiv = true
    this.wholeDiv =  false
    this.snapshot = snapshot
    this.userCollapseFlag = true;
    this.selectedUserName = snapshot.DisplayName;
    const userId = snapshot.UserID;
    console.log('this.UserData', this.UserData)
    this.UserData = []
    if (this.userDataCache[userId]) {
      this.UserData = this.userDataCache[userId];
      console.log("User data from cache", this.UserData);
      const index = this.userDataCacheOrder.indexOf(userId);
      if (index !== -1) {
        this.userDataCacheOrder.splice(index, 1); // Remove existing entry
      }
      this.userDataCacheOrder.push(userId); // Add at the end to maintain order
    } else {
      this.UserData = await this.userPanelDataFunction(userId);
      if (this.userDataCacheOrder.length >= 5) {
        const oldestCacheId = this.userDataCacheOrder.shift(); // Remove the oldest cached entry
        delete this.userDataCache[oldestCacheId];
      }
      this.userDataCache[userId] = this.UserData;
      this.userDataCacheOrder.push(userId); // Add at the end to maintain order

    }
  }

  async userPanelDataFunction(userId) {
    let functionUrl = "https://us-central1-yolomoves-fb435.cloudfunctions.net/getUserPanelDetails";
    return this.http.post<any>(functionUrl, { userId: userId }).toPromise();
  }

  setBrandStoryScrollerHeight() {
    this.sideNavHeight = window.innerHeight
    this.brandStoryScrollerHeight = window.innerHeight - 270;
  }

  clickOnPanel(event) {
    event.stopPropagation()
  }


  closePanel() {
    this.wholeDiv = true;
    this.closeDiv = false;
    this.userCollapseFlag = false;
  }

}


