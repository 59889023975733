<div class="col-lg-12 m-1 justify-content-center text-center scroll " *ngIf="clothDetailsList != undefined">
       <div class="container garment-alingn">
        <div class="col-lg-12 d-flex justify-content-center">
            <div class="col-lg-12 text-center w-100 m-1 d-flex justify-content-center  d-flex">
                <div class="text-center d-flex heeadWidth"><img
                        class="profileImg-brand"
                        style="width:35px; height:35px !important;" [src]="getBrandImage(clothDetailsList.LogoURL)">
                </div>
                <div class="m-0 heeadWidth">
                    <div class="container d-block col-12  ">
                        <h6 class="color-class">{{clothDetailsList.BrandName}}</h6>
                        <div class=" col-12 details-for-task" *ngIf="clothDetailsList != undefined">
                            <b>
                                <h6 class="color-class">{{clothDetailsList.Gender == 'F' ? 'Womenswear' :
                                    (clothDetailsList.Gender == 'M') ? 'Menswear' : 'N/A'}}</h6>
                            </b>
                        </div>
                    </div>
                </div>
                <div class="m-0 heeadWidth text-end" ><button type="button" class="btn btn-sm btn-outline-info m-2 follow color-class">Follow</button>
                </div>
            </div>
        </div>
        <b>
            <h6 class="clothName color-class "> <span *ngIf="clothDetailsList.RealType == '0'"
                    class="brandbtn py-1"><img style="width:18px !important; color:black ;padding-bottom: 3px;"
                        src="./assets/realtype/headgear.png"></span>
                <span *ngIf="clothDetailsList.RealType == '1'" class="brandbtn py-1"><img
                        style="width:18px !important; color:black ;padding-bottom: 3px;"
                        src="./assets/realtype/upperwear.png"></span><span *ngIf="clothDetailsList.RealType == '2'"
                    class="brandbtn py-1"><img style="width:18px !important; color:black ;padding-bottom: 5px;"
                        src="./assets/realtype/bottomwear.png"></span><span *ngIf="clothDetailsList.RealType == '3'"
                    class="brandbtn py-1"><img style="width:18px !important; color:black ;padding-bottom: 3px;"
                        src="./assets/realtype/footwear.png"></span><span *ngIf="clothDetailsList.RealType == '4'"
                    class="brandbtn py-1"><img style="width:18px !important; color:black ;padding-bottom: 3px;"
                        src="./assets/realtype/heeledfootwear.png"></span><span *ngIf="clothDetailsList.RealType == '5'"
                    class="brandbtn py-1"><img style="width:18px !important; color:black ;padding-bottom: 3px;"
                        src="./assets/realtype/fullbody.png"></span>
                {{(clothDetailsList.Name)}}
            </h6>
        </b>
        <div class="details">
            <h6 class="color-class">Collection: {{clothDetailsList.CollectionName}}</h6>
            <h6 class="color-class">Details: {{clothDetailsList.Details}}</h6>
            <h6 class="color-class">SKU: {{clothDetailsList.SKU}}</h6>
        </div>
       </div>
       <div class="imagesDiv">
        <div class="row twoDImage">
            <div class="col-6" *ngFor="let fileList of userFiledetails;let p=index">
                <span *ngIf="p < counter">
                    <div *ngIf="fileList.fileType == 'GarmentUpload2D'">
                        <img class="img-fluid img-thumbnail" style="height: 100px; width: 100px !important;     object-fit: contain;
                            " src={{fileList.fileurl}} data-bs-toggle="modal" data-bs-target="#user-profile"
                            (click)="openImageBox(fileList.fileurl)">
                    </div>
                    <div (click)="openModel(fileList.fileurl)" class="d-inline-block"
                        *ngIf="fileList.fileType == 'GarmentUpload3D'">
                        <model-viewer camera-controls camera-orbit="45deg 55deg 2.5m" ar auto-rotate
                            src={{fileList.fileurl}} alt="A 3D avatar-model-view" data-js-focus-visible=""
                            ar-status="not-presenting" style="height: 100px; width: 150px; float: none;">
                        </model-viewer>
                    </div>
                </span>
            </div>
        </div>
        <div *ngIf="userFiledetails != undefined">

            <button class="btn btn-sm color-class "
                *ngIf="userFiledetails.length != 0 && counter <= userFiledetails.length && counter != userFiledetails.length "
                (click)="incrementImgCounter()">More</button>
        </div>

    </div>
        <div class="p-2 m-1 text-center d-flex justify-content-center">
            <h6 class="m-1 text-primary brandbtn color-class"
                [ngClass]="  clothDetailsList.BrandID == brandId ? 'border-bottom border-dark' : ''"
                (click)="getStoryForGenderCloth(clothDetailsList)"><img
                style="width:15px !important; color:black" src="./assets/storystyle/story-white.png"></h6>

            <h6 (click)="getBrandStyle(clothDetailsList)" class="m-1 text-primary brandbtn color-class"><img
                style="width:18px !important; color:black" src="./assets/storystyle/style-white.png">
            </h6>

            <h6 *ngIf="clothDetailsList.ThumbCenterURL  != undefined" class="m-1 brandbtn"><a
                    href="{{clothDetailsList.ThumbCenterURL}}" target="_blank"><img
                        style="width:15px !important; color:black" src="./assets/details.png"></a></h6>
            <h6 *ngIf="clothDetailsList.ThumbCenterURL  == undefined" class="m-1 brandbtn"><a><img
                        style="width:15px !important; color:black" src="./assets/details-gray.png"></a></h6>

            <h6 *ngIf="clothDetailsList.ThumbLeftURL  != undefined" class="m-1 brandbtn"><a
                    href="{{clothDetailsList.ThumbLeftURL}}" target="_blank"><img style="width:15px !important"
                        src="./assets/gallary.png"></a></h6>
            <h6 *ngIf="clothDetailsList.ThumbLeftURL  == undefined" class="m-1 brandbtn"><a><img
                        style="width:15px !important" src="./assets/gallary-gray.png"></a></h6>

            <h6 *ngIf="clothDetailsList.ThumbRightURL  != undefined" class="m-1 brandbtn"><a
                    href="{{clothDetailsList.ThumbRightURL}}" target="_blank"><img
                        style="width:15px !important; color:black" src="./assets/shop.png"></a></h6>
            <h6 *ngIf="clothDetailsList.ThumbRightURL  == undefined" class="m-1 brandbtn"><a><img
                        style="width:15px !important; color:black" src="./assets/cart-gray.png"></a>
            </h6>
        </div>
        <div class="row pb-5 mb-5 justify-content-center" *ngIf="userSnapShotList.length >= 0 ">
            <div class="column d-block" *ngFor="let snapshot of userSnapShotList ; let i=index;">
                <img class="w-90 shadow-1-strong rounded mb-1" (click)="getgarmentfeed(snapshot,clothDetailsList)"
                    [src]="snapshot.SnapshotFileUrl">
            </div>
        </div>
   
</div>

<div class="modal fade imp-bg" id="user-profile" tabindex="-1" aria-labelledby="user-profile" aria-hidden="true"
    data-bs-backdrop="false">
    <div class="modal-dialog  ">
        <div class="modal-content imp-block justify-content-center">
            <h5 type="button" class="btn-close  closBtn" data-bs-dismiss="modal" aria-label="Close"></h5>
            <img src={{showImages.fileurl}}>
        </div>
    </div>
</div>