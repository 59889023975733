<section style="background-color: #eee;">
  <div class="container ">

    <div class="row d-flex justify-content-center">
      <div class="container-fluid-lg-12  col-md-8 col-lg-6 col-xl-4 ">

        <div class="card" id="chat1" style="border-radius: 15px;">
          <div class="card-header d-flex justify-content-between align-items-center bg-info text-white border-bottom-0"
            style="border-top-left-radius: 15px; border-top-right-radius: 15px;">
            <i class="fas fa-angle-left"></i>
            <p class="mb-0 fw-bold">Send message</p>
            <i class="fas fa-times"></i>
          </div>
          <div class="card-body">
            <div class="form-outline">
              <textarea class="form-control"   [(ngModel)]="message" id="textAreaExample" rows="4"></textarea>
              <label class="form-label" for="textAreaExample">Type your message</label>
                
            </div>

            <button class="btn btn-primary" (click)="sendmessageApi(this)">Send</button>
            <span style="height: 50px;" class="alert alert-success m-1 p-1" *ngIf="messageAlert">Message Sent Successfully
              </span>
              
            
          </div>
        </div>

      </div>
    </div>

  </div>
</section>
