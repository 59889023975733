<div *ngIf="loading" class="loading">Loading&#8230;
</div>
<app-yologram-social *ngIf="logInFlag "></app-yologram-social> 
<app-public *ngIf="!logInFlag"></app-public>
<app-user-story 
[userSnapShotsInput]="userSnapShotList"
[teamplateFlag]="teamplateFlag"
></app-user-story>

<app-drips
[mainMenuFlag]="mainMenuFlag" [showpanel]="showpanel" [userSnapShotsInput]="userSnapShotList" [teamplateFlag]="teamplateFlag">
</app-drips>