import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ApiHttpService } from 'src/app/services/api-http.service';
import { CommonService } from 'src/app/services/common.service';
import { MessageService } from 'src/app/services/messaging.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-notify-user',
  templateUrl: './notify-user.component.html',
  styleUrls: ['./notify-user.component.css']
})
export class NotifyUserComponent implements OnInit {

  securityGroupCollection = this.firestore.collection('SecurityGroup').valueChanges({ idField: 'id' }) as Observable<[]>;
  groupUserCollection = this.firestore.collection('GroupUser').valueChanges({ idField: 'id' }) as Observable<[]>;
  destinationTokenCollection = this.firestore.collection('Tokens').valueChanges({ idField: 'id' }) as Observable<[]>;

  // securityGroupUser = this.firestore.collection('SecurityGroup').valueChanges({ idField: 'id' }) as Observable<[]>;
  securityGroupAll: any = [];
  groupUser: any = {};
  securityGroupUserList: any = [];
  uid: any;
  loading: boolean = false;
  registerUserList: any = [];
  destinationTokenAll: any = {}
  MessageSend: any = "";
  message: any = "";
  SentAlert :boolean = false;logInFlag: boolean;
;
  destToken: any =[];
  selectedSecGpId: any;
  tokenDetails: any ={};
  allUser: any ={};
  tokenDestination: any ={};
  userIDForMEssage: any ={};
  constructor(private messageService: MessageService, private apiHttpService: ApiHttpService, private router: Router, public commonService: CommonService, private firestore: AngularFirestore) {
    this.getFirebaseUsers();
    this.securityGroupCollection.subscribe((groupList) => {
     groupList.forEach((secGrp: any) => {
        if(secGrp.status == 'A') this.securityGroupAll.push(secGrp)
      });
    });


    this.groupUserCollection.subscribe((groupUserCollection) => {
      this.groupUser = groupUserCollection;
    });

    this.destinationTokenCollection.subscribe((tokenList) => {
        this.destinationTokenAll = tokenList
      });
  }


  ngOnInit(): void {
    this.logInFlag = this.commonService.isLoggedIn()
    this.commonService.getUser().subscribe((user: any) => {
      let userId = user?.uid;
    });

    this.checkPageAuthorization()
  }
  selectActionGroupId($event) {
    this.filterUserBySecuirtyGrpId($event.target.value)
  }


  
  filterUserBySecuirtyGrpId(selectedSecGrpId) {
    this.loading = true;
    this.securityGroupUserList = [];
    this.securityGroupUserList
    this.selectedSecGpId = selectedSecGrpId;
    this.securityGroupUserList = this.groupUser.filter((grpUser: any) => grpUser.SecGpId === this.selectedSecGpId)
    this.securityGroupUserList.forEach(async (user: any) => {
      let url = environment.functionBaseUrl + "userProfileIdenifierById?id=" + user.UserId;
      await this.apiHttpService.get(url).subscribe((data: any) => {

        user['profileName'] = data.displayName != undefined ? data.displayName : user.UserId;
        user['email'] = data.email;
        let userObject = this.destinationTokenAll.find((usertokens) => usertokens.userId == user.UserId );
        if(userObject != undefined) { 
          user['token'] = userObject.fcmToken;
        }
      },
        ((error) => {
          console.log(error);
        }
        ));

    });
    this.loading = false;
  }

  getFirebaseUsers() {
    this.commonService.getAllUserList().subscribe((data: any) => {
      this.registerUserList = data.users
    });
  }

  checkPageAuthorization() {
    this.loading = true;
    this.commonService.getUser().subscribe((user: any) => {
      let userObject = { 'userid': user?.uid, 'pagename': '/notify-user' }
      this.commonService.checkViewPageRights(userObject).subscribe((res) => {
        this.loading = false;
        if (!res) {
          this.router.navigateByUrl("/menu-page");
        }
      });
    });
  }

  sendNotificationToSecGrpUsers() {
    let tokenList =  this.securityGroupUserList.map( secGrpList => {
      if(secGrpList.token != undefined)  {
        return secGrpList.token
      }
    }).filter((token) => token != undefined  )
  
    let object ={
      "destToken":tokenList,
      "message":this.message,
      "userId":"test"
        }
    this.messageService.sendMessageToRegisterUser(object);
    this.message = ""  
    this.SentAlert = true ; 
  }

  pageRedirect(pageName) {
    this.router.navigateByUrl(pageName);
  }

}
