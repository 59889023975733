import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/compat/storage';
import { Observable, tap, finalize } from 'rxjs';
import { CommonService } from 'src/app/services/common.service';
import { generateUUID } from 'three/src/math/MathUtils';

interface ModelViewerElement extends HTMLElement {
  toBlob: (options?: { idealAspect?: boolean }) => Promise<Blob>;
}

@Component({
  selector: 'avatar-upload',
  templateUrl: './avatar-upload.component.html',
})
export class AvatarUploadComponent implements OnInit {


  @Input() file!: File;
  @Input() fileType!: String;
  @Input() envName!: String;
  @Input() test!: String;
  @Input() clothId!: any;



  task!: AngularFireUploadTask;
  percentage!: Observable<number | undefined>;
  snapshot!: Observable<any>;
  fileUrl!: string;
  user: any = null;
  userid: string = "";
  id: string = "";
  picture!: string;
  videoUrl:any;
  fileurlMini: any;
  minImage3DUploadFlage: boolean;



  constructor( private http: HttpClient,private storage: AngularFireStorage, private db: AngularFirestore, private commonService: CommonService, public datepipe: DatePipe) {

    this.commonService.getUser().subscribe((user: any) => {
      this.userid = user?.uid;
      this.id
      if (this.fileType == "Environment") {
        this.startEnvFileTypeUpload()
      } else if (this.fileType == "GarmentUpload2D") {
        this.start2DFileUpload()
      }
      else if (this.fileType == "GarmentUpload3D") {
        this.start3DFileUpload()
      } else {
        this.startUpload();
      }
    });

  }

  ngOnInit() {

  }

  startEnvFileTypeUpload() {
    const pathEvn = `Environments/${this.file.name}`;
    const filename = `${this.file.name}`;
    const ref = this.storage.ref(pathEvn);
    this.task = this.storage.upload(pathEvn, this.file);
    this.percentage = this.task.percentageChanges();
    let new_date = new Date();
    const date = this.datepipe.transform(new_date, 'd MMM, y, h:mm:ss a');
    const uid = generateUUID();
    let uploadEnvObject = {
      displayName: this.envName,
      storageURL: pathEvn,
      shared: true,
      userID: (this.test != undefined && this.test != '' ? this.test : this.userid),
    }
    let uploadObject = {
      id: uid,
      filename: this.envName != undefined  ? this.envName :filename ,
      fileurl: '',
      filepath: pathEvn,
      date: date,
      fileType: 'Environment',
      userId: this.userid,
    }

    this.snapshot = this.task.snapshotChanges().pipe(
      tap(console.log),
      // The file's download URL
      finalize(async () => {
        this.fileUrl = await ref.getDownloadURL().toPromise();
        uploadObject.fileurl = this.fileUrl
        const uid = generateUUID();
        this.db.collection('Environments').add(uploadEnvObject).then((docRef) => {
          let refid = docRef.id;
          uploadObject['link'] = refid;
          this.db.collection('userfiles').add(uploadObject);
        });
      }),
    );
  }



  // start2DFileUpload(){
  //   const pathEvn = `Uploads/${this.clothId}/${this.file.name}`;
  //   const lastIndex =  this.file.name.lastIndexOf('.');
  //   const fileNameWithoutExtension = lastIndex !== -1 ?  this.file.name.slice(0, lastIndex) :  this.file.name;
  //   const filename = `${this.file.name}`;
  //   const ref = this.storage.ref(pathEvn);
  //   this.task = this.storage.upload(pathEvn, this.file);
  //   this.percentage = this.task.percentageChanges();
  //   let new_date = new Date();
  //   const date = this.datepipe.transform(new_date, 'd MMM, y, h:mm:ss a');
  //   const uid = generateUUID();
  //   let uploadObject = {
  //     id: uid,
  //     filename: this.envName != ''  ? this.envName :fileNameWithoutExtension ,
  //     fileurl: '',
  //     fileurlMini:"",
  //     filepath: pathEvn,
  //     date: date,
  //     fileType: 'GarmentUpload2D',
  //     userId: this.userid,
  //     link: this.clothId
  //   }
  //   this.snapshot = this.task.snapshotChanges().pipe(
  //     tap(console.log),
  //     // The file's download URL
  //     finalize(async () => {
  //       this.fileUrl = await ref.getDownloadURL().toPromise();
  //       uploadObject.fileurl = this.fileUrl
  //       const uid = generateUUID();
  //       const modifiedFileName = filename.replace(/\.[^/.]+$/, "_128x128$&");
  //       const compressedFilePath = `Uploads/${this.clothId}/${modifiedFileName}`;
  //       let functionUrl = "https://us-central1-yolomoves-fb435.cloudfunctions.net/compressUploadsFiles";
  //       try {
  //           const response = await this.http.post(functionUrl, { 
  //               imageUrl:  this.fileUrl, 
  //               path: compressedFilePath
  //           }).toPromise();
  //           uploadObject.fileurlMini = response['fileurlMini'];
  //           console.log(" uploadObject.fileurlMini", uploadObject.fileurlMini)

  //           // Ensure fileurlMini is defined before adding to Firestore
  //           if (uploadObject.fileurlMini) {
  //               console.log("uploadObject", uploadObject);
  //               await this.db.collection('userfiles').add(uploadObject);
  //           } else {
  //               console.error('File URL Mini is undefined');
  //           }
  //       } catch (error) {
  //           console.error('Error compressing file:', error);
  //       }
  //       this.db.collection('userfiles').add(uploadObject);
  //     }),
  //   );







  // }

  // async start2DFileUpload() {
  //   const pathEvn = `Uploads/${this.clothId}/${this.file.name}`;
  //   const lastIndex = this.file.name.lastIndexOf('.');
  //   const fileNameWithoutExtension = lastIndex !== -1 ? this.file.name.slice(0, lastIndex) : this.file.name;
  //   const filename = `${this.file.name}`;
  //   const ref = this.storage.ref(pathEvn);
  //   this.task = this.storage.upload(pathEvn, this.file);
  //   this.percentage = this.task.percentageChanges();
  //   let new_date = new Date();
  //   const date = this.datepipe.transform(new_date, 'd MMM, y, h:mm:ss a');
  //   const uid = generateUUID();
  //   let uploadObject = {
  //     id: uid,
  //     filename: this.envName !== '' ? this.envName : fileNameWithoutExtension,
  //     fileurl: '',
  //     fileurlMini: '',
  //     filepath: pathEvn,
  //     date: date,
  //     fileType: 'GarmentUpload2D',
  //     userId: this.userid,
  //     link: this.clothId
  //   };
  
  //   // Determine file type
  //   const fileExtension = this.file.name.split('.').pop().toLowerCase();
  //   const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp'];
  //   const videoExtensions = ['mp4', 'avi', 'mov', 'wmv', 'flv'];
  //   let fileType;
  
  //   if (imageExtensions.includes(fileExtension)) {
  //     console.log('File is an image:', this.file.name);
  //     fileType = 'image';
  //   } else if (videoExtensions.includes(fileExtension)) {
  //     console.log('File is a video:', this.file.name);
  //     fileType = 'video';
  //   } else {
  //     console.log('File type is unknown:', this.file.name);
  //   }
  
  //   this.snapshot = this.task.snapshotChanges().pipe(
  //     tap(console.log),
  //     // The file's download URL
  //     finalize(async () => {
  //       this.fileUrl = await ref.getDownloadURL().toPromise();
  //       uploadObject.fileurl = this.fileUrl;
  //       const uid = generateUUID();
  
  //       if (fileType === 'video') {
  //         try {
  //           this.videoUrl = this.fileUrl
  //           const thumbnailUrl = await this.getThumbnailForVideo(this.fileUrl);
  //           const modifiedFileName = filename.replace(/\.[^/.]+$/, "_thumbnail.png");
  //           const compressedFilePath = `Uploads/${this.clothId}/${modifiedFileName}`;
  //           const thumbRef = this.storage.ref(compressedFilePath);
  //           await thumbRef.putString(thumbnailUrl, 'data_url');
  //           uploadObject.fileurlMini = await thumbRef.getDownloadURL().toPromise();
  
  //           // Ensure fileurlMini is defined before adding to Firestore
  //           if (uploadObject.fileurlMini) {
  //             console.log("uploadObject", uploadObject);
  //             const docRef = await this.db.collection('userfiles').add(uploadObject);
  //             if (fileType === 'video') {
               
  //               await docRef.update({ '2DType': 'Video' });
                
  //             }
  //           } else {
  //             console.error('File URL Mini is undefined');
  //           }
  //         } catch (error) {
  //           console.error('Error generating or uploading thumbnail:', error);
  //         }
  //       } else {
  //         const modifiedFileName = filename.replace(/\.[^/.]+$/, "_128x128$&");
  //         const compressedFilePath = `Uploads/${this.clothId}/${modifiedFileName}`;
  //         let functionUrl = "https://us-central1-yolomoves-fb435.cloudfunctions.net/compressUploadsFiles";
  //         try {
  //           const response = await this.http.post(functionUrl, {
  //             imageUrl: this.fileUrl,
  //             path: compressedFilePath
  //           }).toPromise();
  //           uploadObject.fileurlMini = response['fileurlMini'];
  //           console.log("uploadObject.fileurlMini", uploadObject.fileurlMini);
  
  //           // Ensure fileurlMini is defined before adding to Firestore
  //           if (uploadObject.fileurlMini) {
  //             console.log("uploadObject", uploadObject);
  //             const docRef = await this.db.collection('userfiles').add(uploadObject);
  //           } else {
  //             console.error('File URL Mini is undefined');
  //           }
  //         } catch (error) {
  //           console.error('Error compressing file:', error);
  //         }
  //       }
  //     })
  //   )
  // }

  
  // async getThumbnailForVideo(videoUrl: string): Promise<string> {
  //   return new Promise<string>((resolve, reject) => {
  //     const video = document.createElement('video');
  //     const canvas = document.createElement('canvas');
  //     video.style.display = 'none';
  //     canvas.style.display = 'none';
  
  //     // Set crossOrigin attribute to handle cross-origin video sources
  //     video.crossOrigin = 'anonymous';
  
  //     document.body.appendChild(video);
  //     document.body.appendChild(canvas);
  
  //     video.addEventListener('loadedmetadata', () => {
  //       video.width = video.videoWidth;
  //       video.height = video.videoHeight;
  //       canvas.width = video.videoWidth;
  //       canvas.height = video.videoHeight;
  //       video.currentTime = video.duration * 0.25;
  //     });
  
  //     video.addEventListener('seeked', () => {
  //       canvas.getContext('2d')!.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
  //       const imageUrl = canvas.toDataURL('image/png');
  //       document.body.removeChild(video);
  //       document.body.removeChild(canvas);
  //       resolve(imageUrl);
  //     });
  
  //     video.addEventListener('error', (e) => {
  //       document.body.removeChild(video);
  //       document.body.removeChild(canvas);
  //       reject(e);
  //     });
  
  //     video.src = videoUrl;
  //   });
  // }


  async start2DFileUpload() {
    const pathEvn = `Uploads/${this.clothId}/${this.file.name}`;
    const lastIndex = this.file.name.lastIndexOf('.');
    const fileNameWithoutExtension = lastIndex !== -1 ? this.file.name.slice(0, lastIndex) : this.file.name;
    const filename = `${this.file.name}`;
    const ref = this.storage.ref(pathEvn);
    const new_date = new Date();
    const date = this.datepipe.transform(new_date, 'd MMM, y, h:mm:ss a');
    const uid = generateUUID();
    const fileExtension = this.file.name.split('.').pop().toLowerCase();
    const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp'];
    const videoExtensions = ['mp4', 'avi', 'mov', 'wmv', 'flv'];
    let fileType;

    if (imageExtensions.includes(fileExtension)) {
      console.log('File is an image:', this.file.name);
      fileType = 'image';
    } else if (videoExtensions.includes(fileExtension)) {
      console.log('File is a video:', this.file.name);
      fileType = 'video';
    } else {
      console.log('File type is unknown:', this.file.name);
    }

    let uploadObject = {
      id: uid,
      filename: this.envName !== '' ? this.envName : fileNameWithoutExtension,
      fileurl: '',
      fileurlMini: '',
      filepath: pathEvn,
      date: date,
      fileType: 'GarmentUpload2D',
      userId: this.userid,
      link: this.clothId
    };

    try {
      // Check if file already exists
      const existingFiles = await this.db.collection('userfiles', ref => ref.where('filepath', '==', pathEvn)).get().toPromise();
      if (!existingFiles.empty) {
        alert('File already exists. Upload aborted.')
        console.log('File already exists. Upload aborted.');
        return;
      }
    } catch (error) {
      console.error('Error checking existing files:', error);
    }

    this.task = this.storage.upload(pathEvn, this.file);
    this.percentage = this.task.percentageChanges();

    this.snapshot = this.task.snapshotChanges().pipe(
      tap(console.log),
      finalize(async () => {
        this.fileUrl = await ref.getDownloadURL().toPromise();
        uploadObject.fileurl = this.fileUrl;

        if (fileType === 'video') {
          try {
            this.videoUrl = this.fileUrl;
            const thumbnailUrl = await this.getThumbnailForVideo(this.fileUrl);
            const modifiedFileName = filename.replace(/\.[^/.]+$/, "_thumbnail.png");
            const compressedFilePath = `Uploads/${this.clothId}/${modifiedFileName}`;
            const thumbRef = this.storage.ref(compressedFilePath);
            await thumbRef.putString(thumbnailUrl, 'data_url');
            uploadObject.fileurlMini = await thumbRef.getDownloadURL().toPromise();

            if (uploadObject.fileurlMini) {
              console.log("uploadObject", uploadObject);
              const docRef = await this.db.collection('userfiles').add(uploadObject);
              if (fileType === 'video') {
                await docRef.update({ '2DType': 'Video' });
              }
            } else {
              console.error('File URL Mini is undefined');
            }
          } catch (error) {
            console.error('Error generating or uploading thumbnail:', error);
          }
        } else {
          const modifiedFileName = filename.replace(/\.[^/.]+$/, "_128x128$&");
          const compressedFilePath = `Uploads/${this.clothId}/${modifiedFileName}`;
          const functionUrl = "https://us-central1-yolomoves-fb435.cloudfunctions.net/compressUploadsFiles";
          try {
            const response = await this.http.post(functionUrl, {
              imageUrl: this.fileUrl,
              path: compressedFilePath
            }).toPromise();
            uploadObject.fileurlMini = response['fileurlMini'];
            console.log("uploadObject.fileurlMini", uploadObject.fileurlMini);

            if (uploadObject.fileurlMini) {
              console.log("uploadObject", uploadObject);
              const docRef = await this.db.collection('userfiles').add(uploadObject);
            } else {
              console.error('File URL Mini is undefined');
            }
          } catch (error) {
            console.error('Error compressing file:', error);
          }
        }
      })
    )
  }

  async  getThumbnailForVideo(videoUrl: string): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const video = document.createElement('video');
      const canvas = document.createElement('canvas');
      video.style.display = 'none';
      canvas.style.display = 'none';
  
      // Set crossOrigin attribute to handle cross-origin video sources
      video.crossOrigin = 'anonymous';
  
      document.body.appendChild(video);
      document.body.appendChild(canvas);
  
      video.addEventListener('loadedmetadata', () => {
        video.width = video.videoWidth;
        video.height = video.videoHeight;
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        video.currentTime = video.duration * 0.25;
      });
  
      video.addEventListener('seeked', () => {
        const ctx = canvas.getContext('2d');
        if (ctx) {
          ctx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
  
          // Draw play button watermark
          const playButtonSize = Math.min(video.videoWidth, video.videoHeight) / 4;
          const centerX = video.videoWidth / 2;
          const centerY = video.videoHeight / 2;
  
          // Draw circle
          ctx.fillStyle = 'rgba(0, 0, 0, 0.5)';
          ctx.beginPath();
          ctx.arc(centerX, centerY, playButtonSize / 2, 0, 2 * Math.PI);
          ctx.fill();
  
          // Draw triangle (play icon)
          ctx.fillStyle = 'white';
          ctx.beginPath();
          ctx.moveTo(centerX - playButtonSize / 6, centerY - playButtonSize / 4);
          ctx.lineTo(centerX - playButtonSize / 6, centerY + playButtonSize / 4);
          ctx.lineTo(centerX + playButtonSize / 3, centerY);
          ctx.closePath();
          ctx.fill();
  
          const imageUrl = canvas.toDataURL('image/png');
          document.body.removeChild(video);
          document.body.removeChild(canvas);
          resolve(imageUrl);
        } else {
          document.body.removeChild(video);
          document.body.removeChild(canvas);
          reject(new Error('Unable to get canvas context'));
        }
      });
  
      video.addEventListener('error', (e) => {
        document.body.removeChild(video);
        document.body.removeChild(canvas);
        reject(e);
      });
  
      video.src = videoUrl;
    });
  }
  
  

  // start3DFileUpload() {
  //   const pathEvn = `Uploads/${this.clothId}/${this.file.name}`;
  //   const filename = `${this.file.name}`;
  //   const lastIndex =  this.file.name.lastIndexOf('.');
  //   const fileNameWithoutExtension = lastIndex !== -1 ?  this.file.name.slice(0, lastIndex) :  this.file.name;
  //   console.log(fileNameWithoutExtension);
  //   const ref = this.storage.ref(pathEvn);
  //   this.task = this.storage.upload(pathEvn, this.file);
  //   this.percentage = this.task.percentageChanges();
  //   let new_date = new Date();
  //   const date = this.datepipe.transform(new_date, 'd MMM, y, h:mm:ss a');
  //   const uid = generateUUID();
  //   let uploadObject = {
  //     id: uid,
  //     filename: this.envName != ''  ? this.envName :fileNameWithoutExtension ,
  //     fileurl: '',
  //     filepath: pathEvn,
  //     date: date,
  //     fileType: 'GarmentUpload3D',
  //     userId: this.userid,
  //     link: this.clothId
  //   }
  //   this.snapshot = this.task.snapshotChanges().pipe(
  //     tap(console.log),
  //     finalize(async () => {
  //       this.fileUrl = await ref.getDownloadURL().toPromise();
        
  //       uploadObject.fileurl = this.fileUrl
  //       const uid = generateUUID();
  //       this.db.collection('userfiles').add(uploadObject);
  //     }),
  //   );
  // }
  start3DFileUpload() {
    const pathEvn = `Uploads/${this.clothId}/${this.file.name}`;
    const filename = `${this.file.name}`;
    const lastIndex =  this.file.name.lastIndexOf('.');
    const fileNameWithoutExtension = lastIndex !== -1 ?  this.file.name.slice(0, lastIndex) :  this.file.name;
    console.log(fileNameWithoutExtension);
    const ref = this.storage.ref(pathEvn);
    this.task = this.storage.upload(pathEvn, this.file);
    this.percentage = this.task.percentageChanges();
    let new_date = new Date();
    const date = this.datepipe.transform(new_date, 'd MMM, y, h:mm:ss a');
    const uid = generateUUID();
    let uploadObject = {
      id: uid,
      filename: this.envName != ''  ? this.envName :fileNameWithoutExtension ,
      fileurl: '',
      filepath: pathEvn,
      date: date,
      fileType: 'GarmentUpload3D',
      userId: this.userid,
      link: this.clothId,
      fileurlMini: '' // Add this field to the uploadObject
    };
    this.snapshot = this.task.snapshotChanges().pipe(
      tap(console.log),
      finalize(async () => {
        this.fileUrl = await ref.getDownloadURL().toPromise();
        
        uploadObject.fileurl = this.fileUrl;
        this.minImage3DUploadFlage = true

        // Generate and upload the image
        const miniImageUrl = await this.generateAndUploadImage(ref, fileNameWithoutExtension);
        uploadObject.fileurlMini = miniImageUrl;
        this.fileurlMini= miniImageUrl
        console.log('uploadObject',uploadObject)
        this.db.collection('userfiles').add(uploadObject);
        this.minImage3DUploadFlage = false
      }),
    );
}

async generateAndUploadImage(ref: any, fileNameWithoutExtension: string) {
  await new Promise(resolve => setTimeout(resolve, 5000));
  const modelViewer = document.getElementById("viewer") as ModelViewerElement;
  if (modelViewer) {
      const blob = await modelViewer.toBlob({ idealAspect: false });
      const url = URL.createObjectURL(blob);

      // Add a 2-second delay
      await new Promise(resolve => setTimeout(resolve, 2000));
      
      // Create a reference for the mini image
      const miniImagePath = `Uploads/${this.clothId}/${fileNameWithoutExtension}_mini.png`;
      const miniImageRef = this.storage.ref(miniImagePath);
      
      // Upload the mini image
      await miniImageRef.put(blob);
      const miniImageUrl = await miniImageRef.getDownloadURL().toPromise();
      
      URL.revokeObjectURL(url);
      
      return miniImageUrl;
  } else {
      console.error('ModelViewer element not found');
      return '';
  }
}



  //Method to start uploading
  startUpload() {
    const path = `RuntimeAvatarSkinner/Source/${this.file.name}`;
    const filename = `${this.file.name}`;
    let new_date = new Date();
    const date = this.datepipe.transform(new_date, 'd MMM, y, h:mm:ss a');
    // Reference to storage bucket
    const ref = this.storage.ref(path);

    // The main task
    this.task = this.storage.upload(path, this.file);
    // Progress monitoring
    this.percentage = this.task.percentageChanges();
    const uid = generateUUID();
    const currentDate = new Date().getTime();
    let uploadObject = {
      id: uid,
      filename: filename,
      fileurl: '',
      filepath: path,
      date: date,
      fileType: 'ClothSource',
      userId: this.userid,
    }

    this.snapshot = this.task.snapshotChanges().pipe(
      tap(console.log),
      // The file's download URL
      finalize(async () => {
        this.fileUrl = await ref.getDownloadURL().toPromise();
        uploadObject.fileurl = this.fileUrl
        const uid = generateUUID();

        this.db.collection('userfiles').add(uploadObject);
      }),
    );
  }

  isActive(snapshot: any) {
    return snapshot.state === 'running' && snapshot.bytesTransferred < snapshot.totalBytes;
  }


  async downloadPosterToDataURL() {
    const modelViewer = document.getElementById("viewer") as ModelViewerElement;
    if (modelViewer) {
      const blob = await modelViewer.toBlob({ idealAspect: false });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "modelViewer_toBlob.png";
      a.click();
      URL.revokeObjectURL(url);
    } else {
      console.error('ModelViewer element not found');
    }
}

}
