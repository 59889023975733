<div class="modal-header">
  <button type="button" (click)="closePopUp()" class="btn-close btn-close-white-border" data-bs-dismiss="modal"
    aria-label="Close"></button>
</div>
<div class="modal-body">
  <div id="imageCarousel" class="carousel slide" data-bs-ride="false" *ngIf="allGarmentUploadImages">
    <div class="carousel-inner">
      <div class="carousel-item" *ngFor="let data of allGarmentUploadImages"
        [class.active]="data.id === imageID ||   data.image  === imageID">
        <!-- <img  *ngIf="data.2DType == undefined" class="d-block w-100 " style=" height:300px ; object-fit: contain;" [src]="data.fileurl != undefined ?  data.fileurl  :  data.image "   [alt]="data.filename">
          
        <video  *ngIf="data.2DType == 'Video'" width="320" height="240" controls>
          <source [src]="data.fileurl" type="video/mp4">
      
        </video> -->
        <img *ngIf="data['2DType'] == undefined && !modelViewerFlag" class="d-block w-100"
          style="height: 300px; object-fit: contain;" [src]="data.fileurl != undefined ? data.fileurl : data.image"
          [alt]="data.filename">

        <video class="d-block w-100 object-fit: contain" *ngIf="data['2DType'] == 'Video' && !modelViewerFlag"
          width="300" height="240" controls>
          <source [src]="data.fileurl" type="video/mp4">
        </video>

        <model-viewer id="viewer" *ngIf="modelViewerFlag" camera-controls camera-orbit="45deg 55deg 2.5m" ar auto-rotate
          [src]="data.fileurl " alt="A 3D avatar-model-view" data-js-focus-visible="" ar-status="not-presenting"
          class="model-viewer-element">
        </model-viewer>

        <div class="button-container" *ngIf="storeFrontFlag">
          <button class="btn btn-primary m-1"  *ngIf="data.fileurlMini == undefined" (click)="saveCacheFRomStore(data)">Generate</button>
          <button class="btn btn-primary m-1" *ngIf="data.fileurlMini != undefined" (click)="save(data)">Save</button>
        </div>

        <a class="band" *ngIf="delete || deleteGarmentUpload && UserProfile != 'userProfile'">
          <i class="bi bi-trash-fill class-delete" [style.margin-left]="left" [style.margin-top]="top"
            (click)=" deleteSnapshotFile(data)"></i>
          <div class="justify-content-center text-center div-s-n-s p-2">
            <div *ngIf="nameFlag" class="d-flex col-12">
              <div class="col-10 col-sm-8">
                <h6 class="p-1 text-start">{{data.filename}}</h6>
              </div>
              <div class="col-2 col-sm-4 text-end">
                <i class="bi bi-pencil" (click)="selectName()"></i>
              </div>
            </div>
            <div *ngIf="!nameFlag" class="d-flex justify-content-center text-center col-12">
              <div class="col-10 col-sm-8">
                <input type="text" class="form-control text-start transparent-input text-black"
                  placeholder="Enter File name" (change)="editFileName($event)" [value]="data.filename">
              </div>
              <div class="col-2 col-sm-4 text-end">
                <button type="button" class="btn btn-sm btn-primary" data-bs-dismiss="modal"
                  (click)="updateFileName(data)">Save</button>
              </div>
            </div>
          </div>`
        </a>
      </div>


    </div>



    <a *ngIf="!storeSelectedFlag" class="carousel-control-prev buttons-c-s" (click)="detectTopLeftCorners()"
      href="#imageCarousel" role="button" data-bs-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="sr-only text-black"></span>
    </a>
    <a *ngIf="!storeSelectedFlag" class="carousel-control-next buttons-c-s" href="#imageCarousel"
      (click)="detectTopLeftCorners()" role="button" data-bs-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="sr-only text-black"></span>
    </a>
  </div>

  <div class="seleted-store-view" *ngIf="storeSelectedFlag">
    <div class="store-back" *ngIf="storeSelectedImage || storeSelectedVideo || storeSelectedGLTFFile "
      style="background-image: url('assets/store_back.jpg'); ">
      <img class="tag-image" *ngIf="storeSelectedImage" class="" [src]="storeSelectedImage">


      <video class="tag-image" *ngIf="storeSelectedVideo" controls>
        <source [src]="storeSelectedVideo" type="video/mp4">
      </video>

      <model-viewer class="tag-image" *ngIf="storeSelectedGLTFFile" camera-controls camera-orbit="45deg 55deg 2.5m" ar
        auto-rotate [src]="storeSelectedGLTFFile" alt="A 3D avatar-model-view" data-js-focus-visible=""
        ar-status="not-presenting" style="width: 200px; height: 200px;">
      </model-viewer>
    </div>
  </div>

<div *ngIf="GarmentSelectedFlag" class="text-center">
  <model-viewer  id="viewer" *ngIf="storeSelectedGLTFFile" camera-controls camera-orbit="45deg 55deg 2.5m" ar auto-rotate
    [src]="storeSelectedGLTFFile" alt="A 3D avatar-model-view" data-js-focus-visible="" ar-status="not-presenting"
    style="width: 250px; height: 250px;">
  </model-viewer>
  <button class="btn btn-primary" (click)="saveCache(storeSelectedGLTFFile)">Save</button>
</div>

</div>






<div *ngIf="storeManquin">
  <div style="width: 190px;" class="text-center">
      <div class="mannequin-div-Left">
          <div class="store-back-mannquin  d-flex" style="background-image: url('assets/store_back.jpg');">
              <div  *ngIf="storeFrontData.LeftColumnType == '3DObj' && storeFrontData.LeftDocumentID">
                  <!-- <img  class="tag-left" style="width: 50px; height: 50px; "
                      [src]="showStoreFrontSaveImage?.fileurlMini"


                     /> -->

                     <model-viewer class="tag-left" *ngIf="showStoreFrontSaveImage" camera-controls camera-orbit="45deg 55deg 2.5m" ar
                     auto-rotate [src]="showStoreFrontSaveImage?.fileurl" alt="A 3D avatar-model-view" data-js-focus-visible=""
                     ar-status="not-presenting" style="width: 100px; height: 100px;">
                   </model-viewer>

              </div>
              <div  *ngIf="storeFrontData.RightColumnType == '3DObj' && storeFrontData.RightDocumentID ">
                  <!-- <img class= "tag-right " style="width: 50px; height: 50px;"
                      [src]="showStoreFrontSaveImageRight?.fileurlMini"
                       /> -->

                       <model-viewer class="tag-right" *ngIf="showStoreFrontSaveImageRight?.fileurl" camera-controls camera-orbit="45deg 55deg 2.5m" ar
                       auto-rotate [src]="showStoreFrontSaveImageRight?.fileurl" alt="A 3D avatar-model-view" data-js-focus-visible=""
                       ar-status="not-presenting" style="width: 100px; height: 100px;">
                     </model-viewer>
                     

              </div>
              <div class="mannequin-store-Left" *ngIf="selectedGarmentCacheUrlLeft">
                  <div class="head-div-small">
                      <img class="img-responsive-foot-small" *ngIf="selectedGarmentCacheUrlLeft?.headgear"
                          [src]="selectedGarmentCacheUrlLeft?.headgear" />
                  </div>
                  <div class="upper-div-small">
                      <img class="img-responsive-upper-small" *ngIf="selectedGarmentCacheUrlLeft?.upperwear"
                          [src]="selectedGarmentCacheUrlLeft?.upperwear" />
                  </div>
                  <div class="bottom-div-small">
                      <img class="img-responsive-bottom-small" *ngIf="selectedGarmentCacheUrlLeft?.bottomwear"
                          [src]="selectedGarmentCacheUrlLeft?.bottomwear" />
                  </div>
                  <div class="foot-div-small">
                      <img class="img-responsive-foot-small" *ngIf="selectedGarmentCacheUrlLeft?.footwear"
                          [src]="selectedGarmentCacheUrlLeft?.footwear" />
                  </div>
              </div>
              <div class="mannequin-store-Right" *ngIf="selectedGarmentCacheUrlRight">
                  <div class="head-div-small">
                      <img class="img-responsive-foot-small" *ngIf="selectedGarmentCacheUrlRight?.headgear"
                          [src]="selectedGarmentCacheUrlRight?.headgear" />
                  </div>
                  <div class="upper-div-small">
                      <img class="img-responsive-upper-small" *ngIf="selectedGarmentCacheUrlRight?.upperwear"
                          [src]="selectedGarmentCacheUrlRight?.upperwear" />
                  </div>
                  <div class="bottom-div-small">
                      <img class="img-responsive-bottom-small" *ngIf="selectedGarmentCacheUrlRight?.bottomwear"
                          [src]="selectedGarmentCacheUrlRight?.bottomwear" />
                  </div>
                  <div class="foot-div-small">
                      <img class="img-responsive-foot-small" *ngIf="selectedGarmentCacheUrlRight?.footwear"
                          [src]="selectedGarmentCacheUrlRight?.footwear" />
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>