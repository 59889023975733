import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as JSZip from 'jszip';

@Injectable({
  providedIn: 'root'
})
export class DownloadPackZipService {
  zipList: any = [];
  packJsonObject: any;
  isloading: boolean = false;

  constructor(private httpClient: HttpClient) {}


  async downloadAndProcessPack(url: string): Promise<{ zipList: any, packJsonObject: any }> {
    console.log('pack0 loading initialize...');
    this.isloading = true;  // Indicate that a download is in progress
    try {
      const response = await this.httpClient.get(url, { observe: 'response', responseType: 'blob' }).toPromise();
      const result = await this.processZipFile(response.body);
      console.log('pack0 loaded........');
      return result;  // This will return the aggregated result with both zipList and packJsonObject
    } catch (error) {
      this.isloading = false;
      console.error('Error during download and processing:', error);
      throw error;
    }
  }
  private async processZipFile(data: Blob): Promise<{ zipList: any, packJsonObject: any }> {
    try {
      const zip = await JSZip.loadAsync(data);
      const jsonResponseData = await this.extractJsonData(zip);
      const imageUrls = await this.extractImageUrls(zip);
      const result = {
        zipList: imageUrls,
        packJsonObject: jsonResponseData
      };
      this.isloading = false;
      return result;
    } catch (error) {
      this.isloading = false;
      console.error('Error processing ZIP file:', error);
      throw error;
    }
  }
  private async extractJsonData(zip: JSZip): Promise<any> {
    const jsonExtn = /(.json)$/;
    let packJsonObject = {};
    for (let fileName of Object.keys(zip.files)) {
      if (jsonExtn.test(fileName.toLowerCase())) {
        const jsonFile = zip.files[fileName];
        const jsonString = await jsonFile.async('string');
        packJsonObject[fileName.split('.')[0]] = JSON.parse(jsonString);
      }
    }
    return packJsonObject;
  }
  private async extractImageUrls(zip: JSZip): Promise<any> {
    const imageExtn = /(.jpg|.png|.gif|.jpeg)$/;
    let zipList = {};
    for (let fileName of Object.keys(zip.files)) {
      if (imageExtn.test(fileName.toLowerCase()) && !fileName.includes('.json')) {
        const file = zip.files[fileName];
        const blob = await file.async('blob');
        zipList[fileName.substring(0, fileName.lastIndexOf('.'))] = URL.createObjectURL(blob);
      }
    }
    return zipList;
  }
}
