import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Brand } from 'src/app/models/brand';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-brand-panel',
  templateUrl: './brand-panel.component.html',
  styleUrls: ['./brand-panel.component.css']
})
export class BrandPanelComponent {
  brands = this.firestore.collection('brands').valueChanges({ idField: 'id' }) as Observable<Brand[]>;

  @Output() closePanelEmitter = new EventEmitter<any>();

  @Input() brandObject: any = {};

  @Input() brandObjectId: any;
  @Input() closebuttonFlag: boolean;
  brandheartFlag: boolean = false;
  tab: any = 'story';
  userSnapShotList: any = [];
  brandList: any = [];
  brandStoryScrollerHeight: any;
  selectedImageIndex: number;
  pageNumberBrand: number = 1;
  selectedBrandId: any;
  sideNavHeight: number
  showChild: boolean;
  genderselet: any;

  constructor(private commonService: CommonService,  public router: Router, private firestore: AngularFirestore,) { }

  ngOnInit() {
    let brandId = this.brandObject.id
    this.selectedBrandId = brandId

    this.brands.subscribe((brandList) => {
      this.brandList = brandList

    })
  }


  ngOnChanges(changes) {
    this.userSnapShotList = []
    let brandId = this.brandObject.id

    this.selectedBrandId = brandId
    if (changes['brandObject']) {
      console.log('brandObject', this.brandObject)
      this.userSnapShotList = this.brandObject.object
      // this.getBrnadStoryImage(this.brandObject.id)
    }

   
    // this.setBrandStoryScrollerHeight()
  }

  setBrandStoryScrollerHeight() {
    this.sideNavHeight = window.innerHeight - 222;
    this.brandStoryScrollerHeight = window.innerHeight - 270;
    console.log("this.brandStoryScrollerHeight", this.brandStoryScrollerHeight)
  }

  getBrandImage(imgURL: string) {
    let brandEncodedurl = encodeURI(imgURL).replace('/', '%2F');
    let url =
      'https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/' +
      brandEncodedurl +
      '?alt=media&token=550e7e88-bed3-4668-84ff-ad491c19e06c';
    return url;
  }

  clickOnHeart(event: any) {
    event.stopPropagation();
    this.brandheartFlag = !this.brandheartFlag;
  }

  setTab(selectedtab: any, gender) {
    const confirmNavigation = window.confirm('Are you sure you want to navigate to another brand?');
    if (confirmNavigation) {
      this.tab = selectedtab;
      this.genderselet = gender
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        // Now navigate to the desired route after reloading
        this.router.navigate([
          '/threads',
          { 'brandId': this.selectedBrandId, 'M': gender, 'Ex': 'N' },
        ]);
      });
    }
  }


  
  setBrandSelection() {
    const confirmNavigation = window.confirm('Are you sure you want to navigate to another brand?');
    if (confirmNavigation) {
      const currentUrl = this.router.url;
     let brandObj = this.brandList.find((b)=> b.BrandName == this.brandObject.brand.BrandName)
     this.selectedBrandId =  brandObj.id
      // Navigate to the current URL to force a reload
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        // Now navigate to the desired route after reloading
        this.router.navigate(['/threads', { 'brandId': this.selectedBrandId, 'M': 'S', 'Ex': 'N' }]);
      });
    }
  }
  

  getBrnadStoryImage(BrandId: any) {
    let reqInputObject = { id: BrandId, pageNumber: 1 };
    this.commonService.yologramSnapshotByBrands(reqInputObject).subscribe(async (response: any) => {
        this.userSnapShotList = response.object;
      });
  }


  // selectImage(index: number, snapObjet) {
  //   this.selectedImageIndex = index;
  //   console.log('selectedImageIndex drips===>', this.selectedImageIndex);
  //   console.log('snapObjet===>', snapObjet);
  //   this.commonService.setIndex(index);
  //   let page = this.pageNumberBrand;
  //   // this.router.navigate([
  //   //   '/threads',
  //   //   {
  //   //     brandId: this.selectedBrandId,
  //   //     M: 'S',
  //   //     Ex: 'N',
  //   //     storyId: snapObjet.StoryID,
  //   //     pageNumber: page,
  //   //   },
  //   // ]);
  // }

  incrementImgCounter() {
    this.pageNumberBrand++;
    //= nextbutton + 1;
    //this.outPageNumber.emit(this.pageNumber);
    let reqInputObject = {
      id: this.selectedBrandId,
      pageNumber: this.pageNumberBrand,
    };
    this.commonService
      .yologramSnapshotByBrands(reqInputObject)
      .subscribe(async (response: any) => {
        // if (this.pagecount > this.userSnapShotList.length) {
        this.userSnapShotList.push(...response.object);
        this.userSnapShotList.forEach(async (snap: any, index) => {
          // let userObjet = await this.allUserDetails.find(
          //   (userObject) => userObject.uid == snap.UserID
          // );
          // snap.profileName =
          //   userObjet.displayName != undefined
          //     ? userObjet.displayName
          //     : snap.UserID;
          // userObjet.profileName = snap.profileName;
          // snap.userData = userObjet;
          // snap.firstName = snap.profileName.split(' ', 1);
          // snap.AvatarLastFive = snap.AvatarID.substr(snap.AvatarID.length - 5);
        });
        // }
      });
  }
  
  toggleChild() {
    this.showChild = !this.showChild;
    this.closePanelEmitter.emit(this.showChild)
  }


  shareBrand() {
    let brandId = this.selectedBrandId
    let mode = this.tab === 'womens-wear' ? 'W' : this.tab === 'mens-wear' ? 'M' : 'S';
    let link = "https://yolomove-social-live.web.app/threads;brandId=" + brandId + ';M=' + mode + ';Ex=Y';
    // let text = 'Check out '+ this.brandNameSelected +' on Yologram '
    // console.log(text)
    if (navigator.share) {
      navigator.share({
        title: 'Share Brand',
        text: 'Check out ' + this.brandObject.BrandName + ' on Yologram ',
        url: link,
      })
        .then(() => console.log('Successful share'))
        .catch((error) => console.log('Error sharing', error));
    }
  }


  

  // selectImage(index: number, snapObjet) {
  //   this.selectedImageIndex = index;
  //   console.log('selectedImageIndex drips===>', this.selectedImageIndex);
  //   console.log('snapObjet===>', snapObjet);
  //   this.commonService.setIndex(index);
  //   let dataToSend = { brandFlag:true,brandTab:"news-stories"}
  //   this.commonService.setSharedObject(dataToSend);
  //   let page = this.pageNumberBrand;
  //   this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
  //   this.router.navigate([
  //     '/threads',
  //     {
  //       brandId: this.brandObject.id,
  //       M: 'S',
  //       Ex: 'N',
  //       storyId: snapObjet.StoryID,
  //       pageNumber: page,
  //     },
  //   ]);
  // })
  // }
  selectImage(index: number, snapObjet) {
    const confirmNavigation = window.confirm('Are you sure you want to navigate to another brand?');
    if (confirmNavigation) {
      this.selectedImageIndex = index;
      console.log('selectedImageIndex drips===>', this.selectedImageIndex);
      console.log('snapObjet===>', snapObjet);
      this.commonService.setIndex(index);
      let dataToSend = { brandFlag: true, brandTab: "news-stories" };
      this.commonService.setSharedObject(dataToSend);
      let page = this.pageNumberBrand;
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate([
          '/threads',
          {
            brandId: this.brandObject.id,
            M: 'S',
            Ex: 'N',
            storyId: snapObjet.StoryID,
            pageNumber: page,
          },
        ]);
      });
    }
  }
  
}
