<!-- 

<div *ngIf="!loading"  class="brand-slide  brand-profile-color ">
     <div class="slider-css brand-profile-color">
        <div class="slider-css">
            <div class="" *ngFor="let brand of brandDetails" (click)="selectBrandForScroll()">
                <div *ngIf="snapShotBrandList.indexOf(brand.id) >= 0">
                    <img class="m-1" style="height:60px !important; width:80px !important;"    [ngClass]="selectedBrand === brand ? 'shadow-brand' : (selectedBrand != undefined ? 'blug-css' :'')"
                        (click)="selectBrandForStory(brand.id,brands)"
                        [src]="getBrandImage(brand.LogoURL)" />
                </div>
            </div>
        </div>
        <div *ngIf="loading" class="loading">Loading&#8230;</div>
     </div>
     <div class="card brand-profile-color" *ngIf="selectedBrand != undefined">
        <div class="col-lg-12  justify-content-center text-center brand-profile-color">
            <div class="container">
                <div class="col-lg-12 d-block justify-content-center">
                    <h3  class="color-class">{{selectedBrand.BrandName}}</h3>
                    <button type="button" class="btn btn-sm btn-outline-info color-class">Follow</button>
                    <h6  class="color-class" *ngIf="boardDetails != undefined "><img style="width:15px !important"
                            src="./assets/pinterest-512.jpg">:{{boardDetails.name}}
                    </h6>
                    <h6 class="color-class p-1 m-1" *ngIf="boardDetails == undefined "><img style="width:15px !important"
                            src="./assets/pinterest-512.jpg">:Not Linked To board
                    </h6>
                </div>
            </div>
        </div>
        <div class="justify-content-center text-center d-flex set-margin brand-profile-color m-1" >
            <h6 class="text-primary brandbtn m-1 color-class" (click)="setTab('story')">Story</h6>
            <h6 class="text-primary brandbtn m-1 color-class" 
               (click)="setTab('mens-wear')"
               [ngClass]="selectedBrand === 'M' ? 'border-bottom border-dark' : ''">Menswear</h6>
            <h6 class="text-primary brandbtn m-1 color-class"
            (click)="setTab('womens-wear')"
            [ngClass]="selectedBrand === 'F' ? 'border-bottom border-dark' : ''">Womenswear</h6>
        </div>
        <div class="row m-2 brand-profile-color" *ngIf="userSnapShotList.length >= 0 ">
            <div class="column d-block" *ngFor="let snapshot of userSnapShotList ; let i=index;">
                <img class="w-90 shadow-1-strong rounded mb-1" 
                    [src]="snapshot.SnapshotFileUrl">
            </div>
        </div>
     </div>
<app-brand-stories-news *ngIf="brandIds != undefined " id="target"
    [brandGarments]="brandGarmentIds"
    [tab]="selectedTab"
    [onChangeFlag] = "onChangeFlag"
    (changeSelectedTab)="changeTab($event)"
    [brandsIDdetailsAll] ="brandsIDdetailsAll"  
    [selectBrandGender] ="genderID"
    >
</app-brand-stories-news>
</div>
 -->
 <app-public-brand-stories   [brandGarments]="brandGarmentIds"
    [tab]="selectedTab"
    [onChangeFlag]="onChangeFlag"
    (changeSelectedTab)="changeTab($event)"
    [brandsIDdetailsAll]="brandsIDdetailsAll"  
    [selectBrandGender]="genderID"
    [rootFromBrandFlag]="rootFromBrandFlag"
    [rootBrandPage]="brandFlag"
    [routLogInFlag]="routLogInFlag"
>
</app-public-brand-stories>
