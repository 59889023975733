import { Component } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Brand } from 'src/app/models/brand';
import { CommonService } from 'src/app/services/common.service';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-group-brands',
  templateUrl: './group-brands.component.html',
  styleUrls: ['./group-brands.component.css']
})
export class GroupBrandsComponent {
  securityGroupCollection = this.firestore.collection('SecurityGroup').valueChanges({ idField: 'id' }) as Observable<[]>;
  brands = this.firestore.collection('brands').valueChanges({ idField: 'id' }) as Observable<Brand[]>;
  groupBrands = this.firestore.collection('GroupBrands').valueChanges({ idField: 'id' }) as Observable<[]>;

  



  logInFlag: boolean;
  securityGroupAll:any=[]
  selectedSecGrpId: any;
  loading: boolean;
  brandList: any = [];
  groupBrandsList: any = [];


  constructor(public commonService: CommonService, private firestore: AngularFirestore,private router: Router){
    this.logInFlag = this.commonService.isLoggedIn()
      this.securityGroupCollection.subscribe((groupList) => {
      groupList.forEach((secGrp: any) => {
         if(secGrp.status == 'A') this.securityGroupAll.push(secGrp)
       });
     });

     this.brands.subscribe((brand) => {
      this.brandList = brand
     })

     this.groupBrands.subscribe((brandGp) => {
      this.groupBrandsList = brandGp
      console.log("this.groupBrandsList", this.groupBrandsList)
     })

  }

  ngOnInit(): void {
    this.checkPageAuthorization()
    let title = 'Yologram - group-brands'
    this.commonService.changeTitle(title);
    this.logInFlag = this.commonService.isLoggedIn()
    if(!this.logInFlag){
      this.router.navigateByUrl("/drip");
    }
  }


  selectSecGroup($event) {
    this.selectedSecGrpId = $event.target.value
    console.log("this.selectedSecGrpId",this.selectedSecGrpId)
    this.brandList.sort((a, b) => {
      // Convert the condition to a numeric value for sorting: true (has a role ID) comes before false (no role ID)
      let aHasRoleId = this.getBrandRoleId(a.id) != undefined ? 1 : 0;
      let bHasRoleId = this.getBrandRoleId(b.id) != undefined ? 1 : 0;

      // We want brands with a role ID (true) to come first, so we sort in descending order of the boolean condition
      return bHasRoleId - aHasRoleId;
  });
  }

   getBrandImage(imgURL: string) {
    let brandEncodedurl = encodeURI(imgURL).replace('/', '%2F');
    const newPath = brandEncodedurl.replace('Brands%2F', 'Brands%2Fcompressed%2F');
    let url = "https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/" + newPath + "?alt=media&token=550e7e88-bed3-4668-84ff-ad491c19e06c"
    return url;
  }

  updateBrandStatus($event,selectedBrandRole,brand){
    this.loading = true
    if(!this.selectedSecGrpId){
      this.selectedSecGrpId = undefined
      alert("Please Select Group..")
      return
    }
    console.log("selectbrand",selectedBrandRole)
    let brandId = brand.id
    let selectedBrandGrpRole = selectedBrandRole
    if(selectedBrandGrpRole != undefined) {
      this.firestore.collection('GroupBrands').doc(selectedBrandGrpRole.id).delete().then(()=>{
        this.loading = false
      });
    } else {
      let brandRoleObject = {
        SecGpId : this.selectedSecGrpId,
        BrandId : brandId
      }
      console.log(brandRoleObject)
      this.firestore.collection('GroupBrands').add(brandRoleObject).then(()=>{
        this.loading = false
      });
    }
  }

  getBrandRoleId(id){
    let selectedBrand = id
    let selectedGroup = this.selectedSecGrpId
    let  selectbrand = this.groupBrandsList.find((brand)=> brand.BrandId == selectedBrand && brand.SecGpId == selectedGroup)
    return selectbrand
  }

  
  TestgetBrandRoleId(){
    let selectedBrand = 'axK6zfRiMiTuiBCW5VK7'
    let selectedGroup = 'OS8UoVPLU3UwfYo5UMvM'
    let  selectbrand = this.groupBrandsList.find((brand)=> brand.BrandId == selectedBrand && brand.SecGpId == selectedGroup)
    console.log("selectbrand",selectbrand)
  }


  

  checkPageAuthorization() {
    this.loading = true;
    this.commonService.getUser().subscribe((user: any) => {
      let userObject = {'userid': user?.uid, 'pagename': '/group-brands' }
      this.commonService.checkViewPageRights(userObject).subscribe((res) => {
        console.log(res)
        this.loading = false;
        if (!res) {
          this.router.navigateByUrl("/drip");
        }
      });
    });
  }

  

}

// Example usage


