
import { Component, ElementRef, Inject, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { PinterestService } from 'src/app/services/pinterest-service';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { catchError, map, Observable, Subscription, tap } from 'rxjs';
import { Brand } from 'src/app/models/brand';
import { Snapshot } from 'src/app/models/snapshot';
import { ApiHttpService } from 'src/app/services/api-http.service';
import { environment } from 'src/environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { CommonService } from 'src/app/services/common.service';
import { ActivatedRoute, Router, Routes } from '@angular/router';
import { DataSharingService } from 'src/app/services/data-sharing-service';
import { DOCUMENT } from '@angular/common';
import { PopupThreeDComponent } from 'src/app/common/popup-three-d/popup-three-d.component';
import { UserPopupComponent } from 'src/app/common/templets/user-popup/user-popup.component';
import { AnyARecord } from 'dns';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BrandPopupComponent } from 'src/app/common/templets/brand-popup/brand-popup.component';
import { GarmentPopupComponent } from 'src/app/common/templets/garment-popup/garment-popup.component';
import { PinPublishViewerDilog } from '../templets/pin-publish-popup/pin-publish';
import { error } from 'console';
@Component({
  selector: 'app-news-common',
  templateUrl: './news-common.component.html',
  styleUrls: ['./news-common.component.css']
})
export class NewsCommonComponent implements OnInit {



@ViewChild('pinModel', { static: false }) pinModal: ElementRef<HTMLInputElement> = {} as ElementRef;
@ViewChild("snapshotfeeds") snapShotFeeds?: ElementRef;
@Input() brand: string | undefined;

environmentCollection = this.firestore.collection('Environments').valueChanges({ idField: 'id' }) as Observable<Brand[]>;
userFilesList = this.firestore.collection('userfiles').valueChanges({ idField: 'id' }) as Observable<any[]>;
brandsFilesList = this.firestore.collection('brands').valueChanges({ idField: 'id' }) as Observable<Brand[]>;
brandBoardList = this.firestore.collection('brand-board').valueChanges({ idField: 'id' }) as Observable<any[]>;
pinterestBoardPinsStatus = this.firestore.collection('pinterest-board-pins-status').valueChanges({ idField: 'id' }) as Observable<Snapshot[]>;
garmentsList = this.firestore.collection('garments').valueChanges({ idField: 'id' }) as Observable<any[]>;
brandBoardCollection = this.firestore.collection('brand-board').valueChanges({ idField: 'id' }) as Observable<any[]>;
profileName: any
currentlySelected;
color = 'primary';
mode = 'indeterminate';
selectedAvtar:any
value = 50;
displayProgressSpinner = false;
spinnerWithoutBackdrop = false;
offsetPrevious = 0;
scrolling = 'down';
scrollingWidth = 50;
indexMapping = 0;
scrollPixel = 0;
private timeout: any;
snapshotCollection: any = [];
garmentsCollection: any = [];
brandboardCollection: any = [];
brandsCollection: any = [];
storyPinterestPinsList: any = [];
subscription: Subscription | undefined;
scrollselected: any = [];
identifierList: any = {};
slides: any[] = new Array(3).fill({ id: -1, src: '', title: '', subtitle: '' });
clothDetails: any = {};
selectedBrand: any = {};
brandDetails: any;
brands: any = [];
boardId: any ="";
boards: any;
pinImg: string = "";
SnapShotFileDetails: any = []
filterSnapShotFileDetails: any = []
isLoading = false;
topScroll:number;
pageScrollFlag: boolean = false;
hideFlag: boolean = false;
brandCollectionIdcollection: any = []
brandhShapShotsList: any = []
clocthCollectionIdcollection: any = []
clothShapShotsList: any = []
clothShapShotsListAll: any = []
profileDetails:any={}
filterSnapShotForProfile:any=[]
brandGarmentIds:any=[]
usersplitted :any=[]
pindetails: any = [];
urlEvn: any ;
gltfImgSrc: any= '';
skyBoXall :  any;
evsname: any= '';
evsdiplaynameALL: any= '';
filterSnapShotAvatarFileDetails: any = [];
filterSnapShotFileDetailsall: any = [];
popupLoadingIMg:boolean = true
pinterstJsonMap = {
  "StoryID": undefined,
  "upperwear": undefined,
  "footwear": undefined,
  "headwear": undefined
}
setIndex:number
imageList: any = this.pinImg;
brandId: any;
StoryID: any;
UpperwearId: any;
pinId: any;
loading: any;
typeSelected: any;
boardsName: any;
filterByUserFlag: boolean = false;
userId: string;
snpUrl: any
storyId: string;
userUploadedFileList: any = [];
userFiledetails: any = [];
counter: number = 2;
showImages:any={}
filterEnvironmentSnpShot:any=[];
evnFileListFileName : any
evnFileList: any = []
allUserDetails: any = []
allUserSnapDetails: any = []
brandboards: any;
garmentRealtype:any={}
twoDprivew = '';
twoDprivewGltf: any;
filterSnapWitApi: any = []
popupLoading:boolean = true
popupLoadingFor2D:boolean = true
brandsnaploading:boolean = true
snapByApi: any = []
Key:any
clothId:any
userSnapShotList: any = []
garmentUid:any    ;
environmentSnapAllfileAll: any = []




//  identifierDetail

constructor(
  @Inject(DOCUMENT) document: Document,

  private dataSharingService: DataSharingService,
  public dialog: MatDialog,
  private firestore: AngularFirestore,
  public router: Router,
  private route: ActivatedRoute,
  private commonService: CommonService,
  private modal: NgbModal,
  private apiHttpService: ApiHttpService, private pinterestAPI: PinterestService) {
  this.typeSelected = 'ball-fussion';
  this.brandBoardList.subscribe(
    brandboard => {
      this.brandboardCollection = brandboard;
    });

    this.garmentsList.subscribe(
      garments => {
        this.garmentsCollection = garments;
      });
  
  
    this.brandsFilesList.subscribe((brands) => {
      this.brandsCollection = brands;
    });
  
  
  
    this.pinterestBoardPinsStatus.subscribe(
      response => {
        this.storyPinterestPinsList = response
      });
      this.environmentCollection.subscribe(
        (evnFile) => {
          this.evnFileList = evnFile
        });
  
      this.userFilesList.subscribe((userFile) => {
        this.userUploadedFileList = userFile
        let clothId = this.clothDetails.id
        let filelist = this.userUploadedFileList.filter((uploadlist) => uploadlist.link == clothId).slice(0,2)
        this.userFiledetails = filelist
      })

}


  ngOnChanges(changes: SimpleChanges): void {
    let reqInputObject = { id: this.brand, pageNumber: 1 }
    this.commonService.yologramSnapshotByBrands(reqInputObject).subscribe(async (response: any) => {
      this.userSnapShotList = response.object
      this.userSnapShotList.forEach(async (snap: any, index) => {
        let userObjet = this.allUserDetails.find(userObject => userObject.uid == snap.UserID)
        snap.profileName = userObjet.displayName != undefined ? userObjet.displayName : snap.UserID;
        userObjet.profileName = snap.profileName
        snap.userData = userObjet;
        snap.firstName = snap.profileName.split(" ", 1);
        snap.AvatarLastFive = snap.AvatarID.substr(snap.AvatarID.length - 5);
      })
      this.filterSnapShotFileDetails = this.userSnapShotList
          
    })
  }

ngOnInit(): void {

  this.displayProgressSpinner = true;
  this.slides[0] = {
    src: 'snapshot.SnapshotFileUrl',
  };

  this.route.paramMap.subscribe(paramMap => {
    this.userId = paramMap.get('id');
    this.storyId = paramMap.get('storyId');
    this.brandId = paramMap.get('brandId');
   
  });
  this.getPinterestBoards();

  //   this.getAllUserPRofile()
  //   if(this.storyId != undefined) {
  //   this.snapShotInit()   
  //   }

  this.commonService.getAllUser().subscribe(async (allUsers: any) => {
    this.allUserDetails = allUsers.users;
      this.dataSharingService.userSnapShotObservable.subscribe(snapshot => {
        this.userSnapShotList = snapshot
        this.userSnapShotList.forEach(async (snap: any,index) => {
          let userObjet = this.allUserDetails.find(userObject => userObject.uid == snap.UserID)
          snap.profileName = userObjet.displayName != undefined ? userObjet.displayName : snap.UserID;
          userObjet.profileName = snap.profileName 
          snap.userData =  userObjet;         
          snap.firstName =  snap.profileName.split(" ", 1); 
          snap.AvatarLastFive =  snap.AvatarID.substr(snap.AvatarID.length - 5);
        })
        this.filterSnapShotFileDetails = this.userSnapShotList
      });

      if( this.userId == undefined && this.storyId != undefined && this.brandId == undefined){
        this.getStoryDetails(this.storyId)
      } else{
        this.snapShotInit()
     }
     
    })

}


getAllUserPRofile(){
  this.commonService.getAllUser().subscribe(async (allUsers: any) => {
    this.allUserDetails = allUsers.users;
  })
}

getStoryDetails(storyId:any){
    let reqInputObject2 = { id:storyId, pageNumber: 1 }
    this.commonService.yologramSnapshotByStoryID(reqInputObject2).subscribe(async (response: any) => {
      this.userSnapShotList = response
      this.filterSnapShotFileDetails = response
      this.filterSnapShotFileDetails.forEach(async (snap: any,index) => {
        let userObjet = this.allUserDetails.find(userObject => userObject.uid == snap.UserID)
        snap.profileName = userObjet.displayName != undefined ? userObjet.displayName : snap.UserID;
        userObjet.profileName = snap.profileName 
        snap.userData =  userObjet;         
        snap.firstName =  snap.profileName.split(" ", 1); 
        snap.AvatarLastFive =  snap.AvatarID.substr(snap.AvatarID.length - 5);
      })
   })   
}


getProfileNameAll(UserID) {
  this.commonService.getAllUser().subscribe(async (allUsers: any) => {
    this.allUserDetails = allUsers.users;
    let userObjet = this.allUserDetails.find((user) => user.uid == UserID)
    let profileName = userObjet.displayName != undefined ? userObjet.displayName : UserID;
    return profileName;
  })
}

share(storyId){
    this.router.navigate(['/public/news', {'storyId': storyId}])
 }

getProfileName(uuid) {
  let userObjet = this.allUserDetails.find(userObject => userObject.uid == uuid)
  let profileName = userObjet.displayName != undefined ? userObjet.displayName : uuid;
  return profileName;
}



snapShotInit(){
  let reqsInputObject = {id:this.userId,pageNumber:1}
  if(this.userSnapShotList != undefined){
    this.filterSnapShotFileDetails = this.userSnapShotList
    this.filterSnapShotFileDetails.forEach(async (snap: any,index) => {
      let userObjet = this.allUserDetails.find(userObject => userObject.uid == snap.UserID)
      snap.profileName = userObjet.displayName != undefined ? userObjet.displayName : snap.UserID;
      userObjet.profileName = snap.profileName 
      snap.userData =  userObjet;         

      snap.firstName =  snap.profileName.split(" ", 1); 
      snap.AvatarLastFive =  snap.AvatarID.substr(snap.AvatarID.length - 5);
    })
  } else {
    this.commonService.yologramSnapshotDetailsByUserId(reqsInputObject).subscribe(async (snapshot: any) => {
    this.filterSnapShotFileDetails = snapshot.object;
    this.filterSnapShotFileDetails.forEach(async (snap: any,index) => {
      let userObjet = this.allUserDetails.find(userObject => userObject.uid == snap.UserID)
      snap.profileName = userObjet.displayName != undefined ? userObjet.displayName : snap.UserID;
      userObjet.profileName = snap.profileName 
      snap.userData =  userObjet;
      snap.firstName =  snap.profileName.split(" ", 1); 
      snap.AvatarLastFive =  snap.AvatarID.substr(snap.AvatarID.length - 5);
    })
    })
  }


}



// snapShotInit() {
//   this.commonService.snapshotfileDetais().subscribe(async (snapshot: any) => {
//     this.SnapShotFileDetails = snapshot;
//     var snapShotFilter;
//     if (this.userId != undefined && this.userId != null) {
//       snapShotFilter = snapshot.filter((snapshotObject) => snapshotObject.UserID == this.userId);
//     } else {
//       snapShotFilter = snapshot;
//     }
//     snapShotFilter.forEach(async (snap: any,index) => {
//       if(this.storyId != undefined && this.storyId === snap.StoryID) {
//         this.topScroll = index * 670;
//       }
//       let userObjet = this.allUserDetails.find(userObject => userObject.uid == snap.UserID)
//     snap.profileName = userObjet.displayName != undefined ? userObjet.displayName : snap.UserID;
//     snap.firstName =  snap.profileName.split(" ", 1); 
//    snap.AvatarLastFive =  snap.AvatarID.substr(snap.AvatarID.length - 5);

//       // let url = environment.functionBaseUrl + "userProfileIdenifierById?id=" + snap.UserID;
//       // await this.apiHttpService.get(url).subscribe((data: any) => {
//       //   snap['profileName'] = data.displayName != undefined ? data.displayName : snap.UserID;
//       //   this.displayProgressSpinner = false;
//       //   snap.firstName =  snap.profileName.split(" ", 1); 
//       //   snap.AvatarLastFive =  snap.AvatarID.substr(snap.AvatarID.length - 5);
//       // },
//       //   ((error) => {
//       //     console.log(error);
//       //   }
//       //   ));
//     });

//     this.filterSnapShotFileDetails = snapShotFilter
//   });

// }

getSnapShotList() {
  this.filterByUserFlag = false;
  this.filterSnapShotFileDetails = this.SnapShotFileDetails;
}

getBrandStyle(brandName:any,gender:any,){
  this.dataSharingService.sendUserSnapShots(this.brandhShapShotsList);
  this.router.navigate(['public/public-brand-style', { 'brandId':brandName, 'Gender': gender  }])
}


getUserSnapShotDashboar(userId) {
  this.dataSharingService.sendUserSnapShots(this.filterSnapShotFileDetailsall);
  this.router.navigate(['public/news-user', { 'id': userId }])
}

getSelectSnapshotFeed(userSnap) {
  let userId = userSnap.UserID
  let storyid = userSnap.StoryID
  let userSnapShotList = this.filterSnapShotFileDetailsall.filter((snapshot) => snapshot.UserID == userId );
  // this.dataSharingService.sendUserSnapShots();
  this.dataSharingService.sendUserSnapShots(userSnapShotList);

  this.router.navigate(['public/public-userStory-feed', { 'id': userId, 'storyId': storyid }])
}
getSnapShotListByUser(snapshot: any) {
  let userId = snapshot.UserID;
  this.filterByUserFlag = true
  this.filterSnapShotFileDetails = this.SnapShotFileDetails.filter((snapshot) => snapshot.UserID == userId);
}

get snapshotCollectionSort() {
  return this.filterSnapShotFileDetails.sort((a, b) => {
    return <any>new Date(b.timestamp) - <any>new Date(a.timestamp);
  });
}

onItemChange($event: any): void {
  //    this.snapshotFilesList;
}

handleError(error: any) {
  this.isLoading = false;
  this.loading = false;
  console.log(error)
}


setClothDetails(key:string,garmentId: string) {
  this.popupLoadingFor2D = true
  this.Key = key 
  this.clothId = garmentId 
  let reqInputObject = {
      fileType:key,
      id:garmentId,
      pageNumber:1
    }
 
  this.clothShapShotsListAll = []
  if (this.garmentsCollection.length > 0) {
    let grandmentObject = this.garmentsCollection.find((b: any) => b.id === garmentId);
    this.clothDetails = this.garmentsCollection.find((b: any) => b.id === garmentId);
    let brandObject = this.brandsCollection.find((b: any) => b.id === this.clothDetails.BrandID);
    this.clothDetails['BrandName'] = brandObject != undefined ? brandObject.BrandName : '';
    this.clothDetails['LogoURL'] = brandObject != undefined ? brandObject.LogoURL : '';
    let brandGarments = this.garmentsCollection.filter((gaementList) => gaementList.id == this.clothDetails.id );
    this.clocthCollectionIdcollection = brandGarments.map((garment) => garment.id);
    this.clothShapShotsList = this.SnapShotFileDetails.filter((snapshot) => {
      return  this.clocthCollectionIdcollection.indexOf(snapshot.BottomwearID) >= 0 ||
        this.clocthCollectionIdcollection.indexOf(snapshot.HeadwearID.trim()) >= 0 ||
        this.clocthCollectionIdcollection.indexOf(snapshot.FootwearID.trim()) >= 0 ||
        this.clocthCollectionIdcollection.indexOf(snapshot.UpperwearID.trim()) >= 0
      }).slice(0, 9);
      this.clothShapShotsListAll = this.SnapShotFileDetails.filter((snapshot) => {
        return this.clocthCollectionIdcollection.indexOf(snapshot.BottomwearID) >= 0 ||
          this.clocthCollectionIdcollection.indexOf(snapshot.HeadwearID.trim()) >= 0 ||
          this.clocthCollectionIdcollection.indexOf(snapshot.FootwearID.trim()) >= 0 ||
          this.clocthCollectionIdcollection.indexOf(snapshot.UpperwearID.trim()) >= 0
        })
      if (grandmentObject && grandmentObject['Name'] != undefined) {
        let brandId = grandmentObject["BrandID"];
        this.brandDetails = this.brandsCollection.find((b: any) => b.id === brandId);
        this.brandDetails['boardName'] = 'Not Link to Board';
        let brandBoardObject = this.brandboardCollection.find((b: any) => b.brandId == brandId);
        if (brandBoardObject != undefined) {
          let boardId = brandBoardObject && brandBoardObject['boardId'] != undefined ? brandBoardObject['boardId'] : undefined;
          let boardDetails = this.boards.find((b: any) => b.id == boardId);
          // this.brandDetails['boardName'] = boardDetails['name'];
          if(boardDetails !=undefined && boardDetails['name'] != undefined )this.brandDetails['boardName'] = boardDetails['name'];
        }
      }
      this.commonService.yologramSnapshotDetailsByType(reqInputObject).subscribe(async (response: any) => {
        this.popupLoadingFor2D = false
        this.snapByApi =  response.object
        this.clothShapShotsListAll =  this.snapByApi
      })
      let clothId = this.clothDetails.id
      let filelist = this.userUploadedFileList.filter((uploadlist) => uploadlist.link == clothId).slice(0,2)
      this.userFiledetails = filelist
  } 
}


setBrandDetails(garmentId:any) {
  this.brandsnaploading = true
  if (this.garmentsCollection.length > 0) {
    let grandmentObject = this.garmentsCollection.find((b: any) => b.id === garmentId);
    let brandGarments = this.garmentsCollection.filter((gaementList) => gaementList.BrandID === grandmentObject.BrandID);
    this.brandCollectionIdcollection = brandGarments.map((garment) => garment.id);
    this.brandhShapShotsList = this.SnapShotFileDetails.filter((snapshot) => {
      return this.brandCollectionIdcollection.indexOf(snapshot.BottomwearID.trim()) >= 0 ||
        this.brandCollectionIdcollection.indexOf(snapshot.HeadwearID.trim()) >= 0 ||
        this.brandCollectionIdcollection.indexOf(snapshot.FootwearID.trim()) >= 0 ||
        this.brandCollectionIdcollection.indexOf(snapshot.UpperwearID.trim()) >= 0
    });
    if (grandmentObject && grandmentObject['Name'] != undefined) {
      let brandId = grandmentObject["BrandID"];
      this.brandDetails = this.brandsCollection.find((b: any) => b.id === brandId);
      this.brandDetails['boardName'] = 'Not Link to Board';
      let brandBoardObject = this.brandboardCollection.find((b: any) => b.brandId == brandId);
      if (brandBoardObject != undefined) {
        let boardId = brandBoardObject && brandBoardObject['boardId'] != undefined ? brandBoardObject['boardId'] : undefined;
        let boardDetails = this.boards.find((b: any) => b.id == boardId);
        // this.brandDetails['boardName'] = boardDetails['name'];
        if(boardDetails !=undefined && boardDetails['name'] != undefined )this.brandDetails['boardName'] = boardDetails['name'];
      }
    }
  }
}

getGarmentsDetails(garmentId: string) {
  let garmentTitle = "";
  let name = garmentId;
  let brandName = ""
  if (this.garmentsCollection.length > 0) {

    let grandmentObject = this.garmentsCollection.find((b: any) => b.id === garmentId);
    //   return 
    if (grandmentObject && grandmentObject['Name'] != undefined) {
      name = grandmentObject['Name'];
      let brandId = grandmentObject["BrandID"];
      let brandObject = this.brandsCollection.find((b: any) => b.id === brandId);
      brandName = brandObject != undefined && brandObject.BrandName != undefined ? brandObject.BrandName : '';
    }
  }
  garmentTitle = "<a (click)=\"showBrand('12232')\" href=\"showBrand('12232')\" />" + name + "</a>";
  // if(brandName != " ") garmentTitle += "   ( <a data-bs-toggle='modal' data-bs-target='#brand-details' >"+ brandName + " <a/>)";
  return garmentTitle;
}


getGarmentName(garmentId: string) {
  let garmentTitle = "";
  let name = garmentId;
  let brandName = ""
  if (this.garmentsCollection.length > 0) {
    let grandmentObject = this.garmentsCollection.find((b: any) => b.id === garmentId);
    if (grandmentObject && grandmentObject['Name'] != undefined) {
      garmentTitle = grandmentObject['Name'];
    }
  }
  return garmentTitle;
}

getGarmentrealtype(garmentId: string) {
  let garmentTitle = "";
  let name = garmentId;
  let brandName = ""
  if (this.garmentsCollection.length > 0) {
    let grandmentObject = this.garmentsCollection.find((b: any) => b.id === garmentId);
    if (grandmentObject && grandmentObject['RealType'] != undefined) {
      garmentTitle = grandmentObject['RealType'];
    }
  }
  return garmentTitle;
}


getBrandName(garmentId: string) {
  let garmentTitle = "";
  let name = garmentId;
  let brandName = ""
  if (this.garmentsCollection.length > 0) {
    let grandmentObject = this.garmentsCollection.find((b: any) => b.id === garmentId);
    if (grandmentObject && grandmentObject['Name'] != undefined) {
      let brandId = grandmentObject["BrandID"];
      let brandObject = this.brandsCollection.find((b: any) => b.id === brandId);
      brandName = brandObject != undefined && brandObject.BrandName != undefined ? brandObject.BrandName : '';
    }
  }
  return brandName;
}



getBrandLogoURL(garmentId: string) {
  let garmentTitle = "";
  let name = garmentId;
  let brandlogoUrl = ""
  if (this.garmentsCollection.length > 0) {
    let grandmentObject = this.garmentsCollection.find((b: any) => b.id === garmentId);
    if (grandmentObject && grandmentObject['BrandID'] != undefined) {
      let brandId = grandmentObject["BrandID"];
      let brandObject = this.brandsCollection.find((b: any) => b.id === brandId);
      brandlogoUrl = brandObject != undefined && brandObject.LogoURL != undefined ? brandObject.LogoURL : '';
    }
  }
  return brandlogoUrl;
}



showBrand(brand: any) {
  this.selectedBrand = brand;
}



showPintrestIcon(clothId:any){
  let flag = false;
  let grandmentObject = this.garmentsCollection.find((b: any) => b.id === clothId);
  let brandBoardObject = this.brandboardCollection.find((b: any) => b.brandId == grandmentObject.BrandID);
  //let show = './assets/Pinterest-logo.png'
  if(brandBoardObject != undefined){
    flag = true;
  }
  return flag;
}
 getBrandImageByGarmetnID(garmentId: string) {
  let imgURL =  this.getBrandLogoURL(garmentId);
  let brandEncodedurl = encodeURI(imgURL).replace('/', '%2F');
  let url = "https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/" + brandEncodedurl + "?alt=media&token=550e7e88-bed3-4668-84ff-ad491c19e06c"
  return url;
}

getBrandImage(imgURL: string) {
  let brandEncodedurl = encodeURI(imgURL).replace('/', '%2F');
  let url = "https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/" + brandEncodedurl + "?alt=media&token=550e7e88-bed3-4668-84ff-ad491c19e06c"
  return url;
}

getPinterestBoards() {
  this.pinterestAPI.getBoardList().subscribe((data: {
    name: any; items: any;
  }) => {
    this.boards = data.items
  });
}
selectedBoard($event: any) {
  this.boardId = $event.target.value;
}

shareOnPinterest(garmentId: any, type: any, snapshotFileUrl: any, storyID: any) {
  // this.isLoading = true;
  this.loading = true;
  // setTimeout(() =>{
  //   this.loading = false
  // }, 5000);
  let brandId: any;
  let boardId: any;
  if (this.garmentsCollection.length > 0) {
    let grandmentObject = this.garmentsCollection.find((b: any) => b.id === garmentId);
    if (grandmentObject && grandmentObject['Name'] != undefined) {
      brandId = grandmentObject["BrandID"];
      let brandBoardObject = this.brandboardCollection.find((b: any) => b.brandId == brandId);
      boardId = brandBoardObject && brandBoardObject['boardId'] != undefined ? brandBoardObject['boardId'] : undefined;
    }
  }

  if (boardId == undefined || brandId == '') {
    this.isLoading = false;
    this.loading = false;
    alert("This brand is not linked with Pinterest board.")
    return true;
  }

  let jsonObject = {
    'avatarURL': snapshotFileUrl,
    'boardId': boardId
  }
  let jsonInput = this.pinterstJsonMap
  let storyObject = this.storyPinterestPinsList.find((b: any) => b.StoryID == storyID);
  if (storyObject && storyObject[type] != undefined) {
    let jsonInpt = { 'pinId': storyObject[type], };
    this.pinterestAPI.getPinterestPin(jsonInpt).subscribe((data) => {
      this.displayProgressSpinner = false;
      this.pinImg = data.media.images['400x300'].url;
      this.openModleForPublishedPin();
      this.loading = false;
    });
    return true
  }
  this.pinterestAPI.createPinterestPin(jsonObject).subscribe((result) => {
    if (storyObject == undefined) {
      let pinsMappingObject: any = {
        'StoryID': storyID,
      }
      if (type == 'headwear') pinsMappingObject['headwear'] = result.id
      if (type == 'footwear') pinsMappingObject['footwear'] = result.id
      if (type == 'upperwear') pinsMappingObject['upperwear'] = result.id
      if (type == 'headwear') pinsMappingObject['headwear'] = result.id
      this.firestore.collection('pinterest-board-pins-status').add(pinsMappingObject)
    } else if (storyObject && storyObject[type] == undefined) {
     

      if (type == 'headwear') storyObject['headwear'] = result.id
      if (type == 'footwear') storyObject['footwear'] = result.id
      if (type == 'upperwear') storyObject['upperwear'] = result.id
      if (type == 'headwear') storyObject['headwear'] = result.id
      this.firestore.collection('pinterest-board-pins-status').doc(storyObject['id']).update(storyObject);
    }
    
    this.isLoading = false;
    this.loading = false;
    alert("Pin published successfully.");
    return true;
  }),(error) => this.handleError(error)


  return false;
}




openModleForPublishedPin() {
  const dialogRef = this.dialog.open(PinPublishViewerDilog);
  dialogRef.componentInstance.pinImgSrc = this.pinImg;
  dialogRef.afterClosed().subscribe(result => {
    this.isLoading = false;
    this.loading = false;
  });
}




openUserPopup(SnapShotObject:any) {
  const modalRef = this.modal.open(UserPopupComponent,{
    modalDialogClass:"model-garmentPopup"
     });
  modalRef.componentInstance.id = 10;
  modalRef.componentInstance.UserShapShotObject = SnapShotObject;
  modalRef.componentInstance.userData = SnapShotObject.userData;
  modalRef.result.then((result) => {
    console.log("Console resule",result);
  }).catch((error) => {
    console.log("Console error",error);
  });
}
openAvatarPopUp(SnapShotObject:any,AvatarID:any) {
  const modalRef = this.modal.open(UserPopupComponent,{
    modalDialogClass:"model-garmentPopup"
     });
  modalRef.componentInstance.id = 10;
  modalRef.componentInstance.AvatarID = AvatarID;
  modalRef.componentInstance.UserShapShotObject = SnapShotObject;
  modalRef.componentInstance.userData = SnapShotObject.userData;
  modalRef.result.then((result) => {
    console.log(result);
  }).catch((error) => {
    console.log(error);
  });
}

openGarmentPopup(fileType:any,key:any) {
  const modalRef = this.modal.open(GarmentPopupComponent,{
    modalDialogClass:"model-garmentPopup"
  });
  modalRef.componentInstance.id = 10;
  modalRef.componentInstance.key = fileType;
  modalRef.componentInstance.garmentId = key;
  modalRef.result.then((result) => {
    console.log(result);
  }).catch((error) => {
    console.log(error);
  });
}

openBrandPoup(garmentId:any){
  this.setBrandDetails(garmentId)
  const modalRef = this.modal.open(BrandPopupComponent,{
    modalDialogClass:"model-garmentPopup"
  });
  modalRef.componentInstance.garmentId = garmentId;
  modalRef.result.then((result) => {
    console.log(result);
  }).catch((error) => {
    console.log(error);
  });
}


linkBrandToBoard() {

  if (this.boardId == "" || this.brandId == '') {
    return false;
  }
  let brandBoardMappingObject = {
    'brandId': this.brandId,
    'boardId': this.boardId
  }
  this.firestore.collection('brand-board').add(brandBoardMappingObject)
  return true;
}

ngAfterViewChecked():void {
  if (this.storyId != undefined && this.snapShotFeeds != undefined && this.snapShotFeeds.nativeElement != undefined && !this.pageScrollFlag) {
   document.getElementById(this.storyId)
    this.snapShotFeeds.nativeElement.scrollTop = this.topScroll;
    this.pageScrollFlag = true
  }
}
getBrandProfile(brand: any,board:any) {
  this.brandboards = board
  let brandGarments = this.garmentsCollection.filter((gaementList)=>gaementList.BrandID === brand);
  this.brandGarmentIds = brandGarments.map((garment) => garment.id);
  let SnapshotWithbrandGarment =  this.brandhShapShotsList = this.SnapShotFileDetails.filter((snapshot) => {
    return  this.brandCollectionIdcollection.indexOf(snapshot.BottomwearID)  >= 0 ||
    this.brandCollectionIdcollection.indexOf(snapshot.HeadwearID.trim())  >= 0 ||
    this.brandCollectionIdcollection.indexOf(snapshot.FootwearID.trim())  >= 0 ||
    this.brandCollectionIdcollection.indexOf(snapshot.UpperwearID.trim()) >= 0
  })
  this.dataSharingService.sendUserSnapShots(SnapshotWithbrandGarment);
  this.dataSharingService.sendBrandGarments(this.brandGarmentIds);
  this.dataSharingService.sendBrandBrandBoard(board)  
  this.router.navigate(['public/public-brand-profile', {'brandId': brand}])
}


getBrandProfileByGarment(brand: any,board:any) {
  // this.brandGarmentIds = []
  // let brandGarments = this.garmentsCollection.filter((gaementList)=>gaementList.BrandID === brand);
  // this.brandGarmentIds = brandGarments.map((garment) => garment.id);
  // this.brandhShapShotsList = this.SnapShotFileDetails.filter((snapshot) => {
  //   return  this.brandGarmentIds.indexOf(snapshot.BottomwearID)  >= 0 ||
  //   this.brandGarmentIds.indexOf(snapshot.HeadwearID.trim())  >= 0 ||
  //   this.brandGarmentIds.indexOf(snapshot.FootwearID.trim())  >= 0 ||
  //   this.brandGarmentIds.indexOf(snapshot.UpperwearID.trim()) >= 0
  // })
  this.dataSharingService.sendUserSnapShots(this.clothShapShotsListAll );
  // this.dataSharingService.sendBrandGarments( this.brandGarmentIds);
  this.dataSharingService.sendBrandBrandBoard(this.brandDetails.boardName)  
  this.router.navigate(['public/public-brand-profile', {'brandId': brand}])
}


getProfileByAvator(snapshot:any){
  let userId = snapshot.UserID;
  let filterSnapShotFileDetails = this.SnapShotFileDetails.filter((snapshotlist) => snapshotlist.AvatarID == snapshot.AvatarID);
  this.dataSharingService.sendUserSnapShots(filterSnapShotFileDetails);
  this.router.navigate(['public/news-user', { 'id': userId,'avatarID': snapshot.AvatarID }])
}

getFeed(brand: any ,brandName:any){
   this.router.navigate(['public/public-brand-stories', { 'brandId':brandName.id,'storyId':brand.StoryID }])
  }
  
  getBrandStories(brand: any) {
    this.router.navigate(['public/public-brand-stories', {'brandId': brand.id,}])
  }
  getClothFeed(brand: any,brandsDetails){   
    this.dataSharingService.sendUserSnapShots(this.clothShapShotsList)
    this.router.navigate(['public/public-brand-stories', { 'brandId':brandsDetails,'storyId':brand.StoryID}])
    }

    getgarmentStyle(brandName: any, cloths: any) {
      let editgarment = 'edit'
      let snap = this.clothShapShotsList 
      this.dataSharingService.sendUserSnapShots(snap)
      this.dataSharingService.sendClothDetails(cloths)
      this.router.navigate(['public/public-garment-style', { 'brandId': cloths.BrandID, 'clothsType': cloths.GarmentType, 'Gender': cloths.Gender, 'id': cloths.id, 'edit': editgarment}])
    }
    getStoryCloth(brandIds) {
      let snap = this.clothShapShotsList 
      this.dataSharingService.sendUserSnapShots(snap)
      this.dataSharingService.sendClothDetails(brandIds)
      let brandid = brandIds.BrandID
      this.router.navigate(['public/public-garment-profile', { 'brandId': brandid, }])
    }


    getSnapShotListByUserPopup(snapshot: any) {  
      this.popupLoading = true;
      this.popupLoadingFor2D = true;
      setInterval(() => {
          this.popupLoading = false; 
     }, 4000);
     this.popupLoading = true;
      let userId = snapshot.UserID;
      //  let url = environment.functionBaseUrl + "userProfileIdenifierById?id=" + userId;
      //      this.apiHttpService.get(url).subscribe((data: any) => {
      //       this.profileDetails = data
      //     })
      // this.filterSnapShotForProfile = this.SnapShotFileDetails.filter((snapshot) => snapshot.UserID == userId).slice(0,3);
      // this.dataSharingService.sendUserSnapShots(this.filterSnapShotForProfile);
      // let clothId = this.clothDetails.id
      // let filelist = this.userUploadedFileList.filter((uploadlist) => uploadlist.link == clothId).slice(0,2)
      // this.userFiledetails = filelist
      this.profileDetails = this.allUserDetails.find(userObject => userObject.uid == userId)
        let reqInputObject = {id:userId,pageNumber:1}
        this.commonService.yologramSnapshotDetailsByUserId(reqInputObject).subscribe(async (response: any) => {
          this.allUserSnapDetails = []
          this.allUserSnapDetails = response.object;
          this.filterSnapShotFileDetailsall = this.allUserSnapDetails.filter((snapshot) => snapshot.UserID == userId)
          this.filterSnapWitApi = this.allUserSnapDetails.filter((snapshot) => snapshot.UserID == userId).slice(0,3);
          this.dataSharingService.sendUserSnapShots(this.filterSnapShotFileDetailsall);
          this.popupLoading = false
        })
        this.popupLoadingIMg = true;
        setInterval(() => {
            this.popupLoadingFor2D = false;
            this.popupLoadingIMg = false; 
       }, 500);
    }

    getSnapShotListByUserPopupAvatar(snapshot: any) {  
      this.popupLoading = true;
      this.popupLoadingFor2D = true;
      setInterval(() => {
          this.popupLoading = false; 
     }, 4000);
     this.popupLoading = true;
      let userId = snapshot.UserID; 
      let avatarId =  snapshot.AvatarID;
       this.selectedAvtar =  avatarId
      this.profileDetails = this.allUserDetails.find(userObject => userObject.uid == userId)
        let reqInputObject = {id:avatarId,pageNumber:1}
        this.commonService.yologramSnapshotDetailsByAvatar(reqInputObject).subscribe(async (response: any) => {
          this.allUserSnapDetails = []
          this.allUserSnapDetails = response.object;
          this.filterSnapShotFileDetailsall = this.allUserSnapDetails.filter((snapshot) => snapshot.AvatarID == avatarId)
          this.filterSnapWitApi = this.allUserSnapDetails.filter((snapshot) => snapshot.AvatarID == avatarId).slice(0,3);
          this.dataSharingService.sendUserSnapShots(this.filterSnapShotFileDetailsall);
          this.popupLoading = false
        })
        this.popupLoadingIMg = true;
        setInterval(() => {
            this.popupLoadingFor2D = false;
            this.popupLoadingIMg = false; 
       }, 500);
    }


    getuserfeed(uid){
      this.filterSnapShotFileDetails = this.SnapShotFileDetails.filter((snapshot) => snapshot.UserID == uid)
     
    }

    getuserAvatarfeed(uid){
      this.filterSnapShotFileDetails = this.SnapShotFileDetails.filter((snapshot) => snapshot.AvatarID == uid)
      
    }


    getSnapShotListAvatarPopup(snapshot: any) {
      let userId = snapshot.UserID;
      let avatarId =  snapshot.AvatarID
      this.selectedAvtar =  avatarId
      //  let url = environment.functionBaseUrl + "userProfileIdenifierById?id=" + userId;
      //      this.apiHttpService.get(url).subscribe((data: any) => {
      //       this.profileDetails = data
      //     })
     // filterSnapShotForProfile
     //  this.profileDetails = data
       this.profileDetails = this.allUserDetails.find(userObject => userObject.uid == userId)
      this.filterSnapShotForProfile = this.SnapShotFileDetails.filter((snapshot) => snapshot.AvatarID == avatarId).slice(0,3);
      this.dataSharingService.sendUserSnapShots(this.filterSnapShotForProfile);
    }
 
    getProfileByAvatorpage(snapshot: any) {
      // calling 12 snap from plus button
      const userId =  this.filterSnapShotFileDetailsall.find((snapshotlist)=> snapshotlist.UserID )
      this.dataSharingService.sendUserSnapShots(this.filterSnapShotFileDetailsall);
      this.router.navigate(['public/news-user', { 'id': userId.UserID, 'avatarID': snapshot }])
    }

    getProfileByAvatorpagfeed(snapshot: any){
      this.dataSharingService.sendUserSnapShots(this.filterSnapShotFileDetailsall);
      const userId = snapshot.UserID
      //  this.filterSnapShotFileDetailsall.find((snapshotlist)=> snapshotlist.UserID )
      this.router.navigate(['public/public-userStory-feed', { 'id': userId.UserID, 'avatarID': snapshot }])
    }
    getSelectSnapshotAvatarFeed(snapshot) {
      const userId =  this.filterSnapShotFileDetailsall.find((snapshotlist)=> snapshotlist.UserID )
      this.dataSharingService.sendUserSnapShots(this.filterSnapShotFileDetailsall);
      this.router.navigate(['public/public-userStory-feed', { 'id': snapshot.UserID, 'avatarID': snapshot.AvatarID }])
      // this.filterSnapShotAvatarFileDetails = this.filterSnapShotFileDetails.filter((snapshotlist) => snapshotlist.AvatarID == snapshot.AvatarID);
      // this.dataSharingService.sendUserSnapShots(this.filterSnapShotFileDetails);
  
    }

    getClothProfile(brand: any,){
      let clothAllDetails = this.clothDetails
      let brandGarments = this.garmentsCollection.filter((gaementList) => gaementList.id == this.clothDetails.id );
      this.clocthCollectionIdcollection = brandGarments.map((garment) => garment.id);
      this.clothShapShotsList = this.SnapShotFileDetails.filter((snapshot) => {
        return this.clocthCollectionIdcollection.indexOf(snapshot.BottomwearID) >= 0 ||
          this.clocthCollectionIdcollection.indexOf(snapshot.HeadwearID.trim()) >= 0 ||
          this.clocthCollectionIdcollection.indexOf(snapshot.FootwearID.trim()) >= 0 ||
          this.clocthCollectionIdcollection.indexOf(snapshot.UpperwearID.trim()) >= 0
        }).slice(0, 9);
        let snanpDetails = this.SnapShotFileDetails.filter((snapshot) => {
          return this.clocthCollectionIdcollection.indexOf(snapshot.BottomwearID) >= 0 ||
            this.clocthCollectionIdcollection.indexOf(snapshot.HeadwearID.trim()) >= 0 ||
            this.clocthCollectionIdcollection.indexOf(snapshot.FootwearID.trim()) >= 0 ||
            this.clocthCollectionIdcollection.indexOf(snapshot.UpperwearID.trim()) >= 0
          })

        this.dataSharingService.sendUserSnapShots( this.snapByApi );
        this.dataSharingService.sendBrandGarments(this.clocthCollectionIdcollection);
         this.dataSharingService.sendClothDetails(clothAllDetails)
        this.router.navigate(['public/public-garment-profile', { 'brandId': brand ,'key':this.Key ,'id':this.clothId  }])
    }

    
    openImageBox(fileurl){
      let url = fileurl
      let showImage = this.userFiledetails.find((file)=> file.fileurl == url)
      this.showImages = showImage
     }
  

  

     environmentPopup(environment) {

      let reqInputObject = {id:environment,pageNumber:1}
      this.commonService.yologramSnapshotEnvironment(reqInputObject).subscribe(async (evn: any) => {
         this.environmentSnapAllfileAll = evn.object;
        this.dataSharingService.sendUserEnvSnapShots(this.environmentSnapAllfileAll);
      })
      let evenListName = "Environments/"
      this.evnFileListFileName = this.evnFileList.find((snap) => snap.storageURL == evenListName+environment)
      let environmentSnapAllfile = this.SnapShotFileDetails.filter((snap) => snap.Environment === environment).slice(0,3)
      this.filterEnvironmentSnpShot = environmentSnapAllfile
      this.evnFileListFileName['envFileName'] = environment
      let evnUrl = evenListName+environment
      this.urlEvn = environment
      this.gltfImgSrc = 'https://firebasestorage.googleapis.com/v0/b/yolouploader.appspot.com/o/userData%2Ffiles%2Fcrystal_ball.glb?alt=media&token=963e0d80-5809-4e1b-9034-caf31fb33945'
      // let environmentSnapAllfileAll = this.SnapShotFileDetails.filter((snap) => snap.Environment === environment)
      // this.dataSharingService.sendUserSnapShots(environmentSnapAllfileAll);
      this.openModelevn(evnUrl)

    }

    openModelevn(skyBoXsrc: any) {
      this.dataSharingService.sendUserEnvSnapShots(this.environmentSnapAllfileAll);
      let  evnEncodedurl = encodeURI(skyBoXsrc).replace('/','%2F');
      let  evNurl ="https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/"+evnEncodedurl+"?alt=media&token=db5ebcc3-36d0-4651-9250-471d6faf6baf"
      this.twoDprivew  = evNurl
      const dialogRef = this.dialog.open(PopupThreeDComponent,{
        panelClass: 'dailog-padding-css'
      });
      this.gltfImgSrc = 'https://firebasestorage.googleapis.com/v0/b/yolouploader.appspot.com/o/userData%2Ffiles%2Fcrystal_ball.glb?alt=media&token=963e0d80-5809-4e1b-9034-caf31fb33945'
      this.skyBoXall  =   this.twoDprivew 
      this.evsname    = this.evnFileListFileName['envFileName']
      this.evsdiplaynameALL = this.evnFileListFileName['displayName']
      dialogRef.componentInstance.evsdiplaynameALL =   this.evsdiplaynameALL
      dialogRef.componentInstance.evsname = this.evsname 
      dialogRef.componentInstance.gltfImgSrc = this.gltfImgSrc;
      dialogRef.componentInstance.skyBoXall = this.skyBoXall;
      dialogRef.componentInstance.hideFlag = this.hideFlag;
      dialogRef.afterClosed().subscribe(result => {
      });
    }

    getEvnUrl(environment:any)
         {
          this.twoDprivewGltf ="https://firebasestorage.googleapis.com/v0/b/yolouploader.appspot.com/o/userData%2Ffiles%2Fcrystal_ball.glb?alt=media&token=963e0d80-5809-4e1b-9034-caf31fb33945" 
          let  evnEncodedurl = encodeURI(environment).replace('/','%2F');
          let  evNurl ="https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/"+evnEncodedurl+"?alt=media&token=db5ebcc3-36d0-4651-9250-471d6faf6baf"
          this.twoDprivew  = evNurl
         
        }
        
    getEnvironmentName(environment){
      let evenListName = "Environments/"
      let env = this.evnFileList.find((snap) => snap.storageURL == evenListName+environment)
      return env != undefined ? env['displayName'] : 'default';
    }


    closeEnviro(){

    }
 
    getEnvironmentProfile(envFileName) {
      alert("helo")
      let evenListName = "Environments/"
      this.evnFileListFileName = this.evnFileList.find((snap) => snap.storageURL == evenListName+envFileName)
      let environmentSnapAllfile = this.SnapShotFileDetails.filter((snap) => snap.Environment === envFileName)
      this.dataSharingService.sendUserSnapShots(environmentSnapAllfile)
      this.router.navigate(['public/public-evn-profile', { 'evnFile': this.evnFileListFileName.displayName, 'evnName':this.evnFileListFileName['envFileName'] }])
    }


    getgarmentfeed(brandName:any,cloths:any){
      this.dataSharingService.sendUserSnapShots(this.SnapShotFileDetails);
  
      // let boardName = this.brandBoard
      this.router.navigate(['public/public-garment-feed', { 'brandId':cloths.BrandID, 'clothsType': cloths.GarmentType ,'Gender':cloths.Gender,'id':cloths.id }])
    }
  
    getgarmentStylefeed(brandName: any, cloths: any) {
      let snap = this.clothShapShotsListAll 
      this.dataSharingService.sendUserSnapShots(snap)
      this.dataSharingService.sendClothDetails(cloths)
      this.router.navigate(['public/public-garment-feed', { 'brandId': cloths.BrandID, 'clothsType': cloths.GarmentType, 'Gender': cloths.Gender, 'id': cloths.id ,'key': this.Key }])
    }





}








@Component({
selector: 'model-viewer-dilog',
templateUrl: './model-viewer.html',
})
export class ModelViewerDilog {
@Input() pinImgSrc: string | undefined;

constructor(public dialog: MatDialog) {

}

}