<app-yologram-social *ngIf="logInFlag"></app-yologram-social>
<div class="container p-4">
    <div>
        <h4>Select Group</h4>
    </div>
    <div class="col-2">
        <select  (change)="setSecurityGroup($event)" class="form-select group-select">
            <option value="">Change Group</option>
            <option [value]="group.SecGpId" [selected]="group.SecGpId == selectedSecGrpId"
                *ngFor="let group of userSecurityGrp ; let i=index">{{secGrpList[group.SecGpId]}}</option>
        </select>

    </div>
    <div class="col-2 text-center p-4">
        <button class="btn btn-primary"  (click)="updateUserPrefernce()">Submit</button>
    </div>
</div>

<!--
    <select (change)="updateUserPrefernce($event)"  class="form-select ">
    <option value="">Change Group</option>
    <option  [value]="group.SecGpId" [selected]="group.SecGpId == selectedSecGrpId" *ngFor="let group of userSecurityGrp ; let i=index">{{secGrpList[group.SecGpId]}}</option>
    </select>  
-->
