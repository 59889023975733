<app-yologram-social *ngIf="logInFlag"></app-yologram-social>
<div class="your-backdrop-class">
    <input type="text" class="form-control p-1 m-1" placeholder="Search Brand" (input)="suggest($event)">
    <div class="slider-css">
        <div class="slider-img" *ngFor="let brand of filterBrandList ">
            <img style="height:60px !important; width:80px !important;" (click)="showBrandDetails(brand)"
                [src]="brand.Brandurl"/>
        </div>
    </div>
    <!-- <div *ngIf="loading" class="loading">Loading&#8230;</div> -->
    <div class="container" *ngIf="selectedBrand.BrandName != undefined ">
        <div class="row text-center">
            <div class="row d-flex p-2">
                <div class="d-block  p-2">
                    <h5>{{ selectedBrand.BrandName }}</h5>
                    <img width="200" height="250" [src]="getBrandImage(selectedBrand.LogoURL)" />
                </div>
            </div>
            <div class="row text-center p-4">
                <div class="col-3 offset-3">
                    <h6>Gender</h6>
                    {{ selectedBrand.Gender }}
                    <h6>Accessories</h6>
                    {{ selectedBrand.Accessories }}
                    <h6>BottomWear</h6>
                    {{ selectedBrand.BottomWear }}
                    <h6>FootWear</h6>
                    {{ selectedBrand.FootWear }}
                </div>
                <div class="col-3">
                    <h6>HeadGear</h6>
                    {{ selectedBrand.HeadGear }}
                    <h6>UpperWear</h6>
                    {{ selectedBrand.UpperWear }}
                    <h6>LogoSizeX</h6>
                    {{ selectedBrand.LogoSizeX }}
                    <h6>LogoSizeY</h6>
                    {{ selectedBrand.LogoSizeY }}
                </div>
            </div>
        </div>
    </div>
</div>
