import { Component, HostListener, OnInit, SimpleChange } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LoginPopupComponent } from '../common/templets/login-popup/login-popup.component';
import { CommonService } from '../services/common.service';
declare const WURFL: any; // This declaration is needed to use WURFL which is loaded globally

@Component({
  selector: 'app-public',
  templateUrl: './public.component.html',
  styleUrls: ['./public.component.css']
})
export class PublicComponent implements OnInit {
  showFooter: boolean = false;
  showHeader: boolean = false;
  bodyTag: HTMLBodyElement = document.getElementsByTagName('body')[0];
  htmlTag: HTMLElement = document.getElementsByTagName('html')[0];
  activeShare:boolean = false
  menuFlag:boolean = true
  menuflagInactive :boolean = false
  url: any;
  brandsFlage: boolean;
  BrandId: any;
  pageFlagRout: string;
  routFalg: boolean;
  deviceInfo: any;
  private localStorageKey = 'app-opened';
 
  constructor(public router: Router, private modal: NgbModal,   public commonService: CommonService,  private route: ActivatedRoute,
    ) { 
      this.deviceInfo = WURFL;
      // this.adjustVisibility()
    }

  ngOnInit(): void {
    window.addEventListener('resize', () => {
      this.adjustVisibility();
    });

    this.adjustVisibility()
    this.route.url.subscribe(url => {
      console.log('url',url)
      this.pageFlagRout = url[0].path
      console.log('url thi',url[0].path)
      if(this.pageFlagRout == 'threads'){
        this.routFalg = true
      }
      // Check if the URL matches your condition
      });



    this.route.paramMap.subscribe(paramMap => {
      this.BrandId = paramMap.get('brandId')
    });


    this.bodyTag.classList.add('login-page');
    this.htmlTag.classList.add('login-page');
    this.menuFlag = false
    this.activeShare = false
    this.router.events.subscribe((route: any) => this.route= route);
  if(this.router.url == '/Login')
    this.activeShare = false
  }




//   adjustVisibility() {
//     const width = window.innerWidth;
//    const height = window.innerHeight;
//    console.log(height, width);
 
//    // Reset visibility states before applying the new conditions
//    this.showFooter = false;
//    this.showHeader = false;
 
//    if (height > width) {
//      // If height is greater than width, show the footer and hide the header
//      this.showFooter = true;
//    } else {
//      // Otherwise, show the header and hide the footer
//      this.showHeader = true;
//    }
//      // // Show footer for width below 700px or for specific resolution 1080x1920
//    }

// adjustVisibility() {
//   const width = window.innerWidth;
//   const height = window.innerHeight;
//   console.log('public component',height, width);
//   // Reset visibility states before applying the new conditions
//   this.showFooter = false;
//   this.showHeader = false;
//   // Update deviceInfo on every resize event
//   this.deviceInfo = WURFL;
//   console.log('Device Info: ', this.deviceInfo);
//   if (this.deviceInfo.is_mobile) {
//     // For mobile devices, always show the footer
//     this.showFooter = true;
//   } else if(!this.deviceInfo.is_mobile){
//     // For non-mobile devices, show the header
//     this.showHeader = true;
//   }
//   // Additionally, if the height is greater than the width, show the header
// }

adjustVisibility() {
  const width = window.innerWidth;
  const height = window.innerHeight;

  // Reset visibility states before applying the new conditions
  this.showFooter = false;
  this.showHeader = false;

  // Using the hypothetical 'deviceInfo' object that should contain information about the device
  // This information needs to be obtained beforehand, for example, during component initialization
  // Here, it's assumed 'deviceInfo' is already available and contains 'form_factor' and 'is_mobile' properties
  if (this.deviceInfo) {
    const isDesktopOrSmartTV = this.deviceInfo.form_factor === 'Desktop' || this.deviceInfo.form_factor === 'Smart-TV';
    
    if (isDesktopOrSmartTV) {
      // Show header for Desktop and Smart-TV
      this.showHeader = true;
    } else {
      // Show footer for other devices
      this.showFooter = true;
    }
  } else {
    // Default behavior in case device information is not available
    // This can be adjusted according to your application's needs
    if (width > height) {
      // Assuming landscape orientation generally for desktops and TVs
      this.showHeader = true;
    } else {
      // Assuming portrait orientation for mobile devices
      this.showFooter = true;
    }
  }
}

  
  openSignInPoup(){

   const modalRef = this.modal.open(LoginPopupComponent,{
      modalDialogClass:"model-login-popup"
    });
    modalRef.result.then((result) => {
      this.activeShare = false
      if(!this.activeShare || result == 0 ){
        this.menuFlag = false
      }
    }).catch((error) => {
      if(error == 0 ){
        this.menuFlag = false
      }
      console.log(error);
    });
  }

  signInPopup(){
    this.menuFlag = true
    this.openSignInPoup()
  }
  clickOnIconHIden(){
    this.closeModelPopUP()
    this.menuFlag = false
  }


//   getBackgroundColor() {
//     if (this.url) {
//         if (this.route.url == '/public/public-brand-stories'){
//         return 'black'
//         } else {
//         return '#6772e5'
//         }
//     } else {
//         return '#6772e5'
//     }
// }


closeModelPopUP() {
 this.modal.dismissAll(LoginPopupComponent);
}

ngOnChanges(changes:SimpleChange) {
  console.log(changes)
}

}

