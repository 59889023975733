import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as JSZip from 'jszip/dist/jszip'
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { Brand } from 'src/app/models/brand';
import { CommonService } from 'src/app/services/common.service';
import { environment } from 'src/environments/environment';
import { ApiHttpService } from 'src/app/services/api-http.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DataSharingService } from 'src/app/services/data-sharing-service';
import { PopupTwoDComponent } from 'src/app/common/popup-two-d/popup-two-d.component';
import { MatDialog } from '@angular/material/dialog';
import { PopupThreeDComponent } from 'src/app/common/popup-three-d/popup-three-d.component';
import { PinterestService } from 'src/app/services/pinterest-service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserPopupComponent } from 'src/app/common/templets/user-popup/user-popup.component';
@Component({
  selector: 'app-garment-feed',
  templateUrl: './garment-feed.component.html',
  styleUrls: ['./garment-feed.component.css']
})
export class GarmentFeedComponent implements OnInit {

  @ViewChild("garmentsnapShotFeeds") garmentsnapShotFeeds?: ElementRef;
  userFiles = this.firestore.collection("userfiles");
  userFileStateChange = this.userFiles.valueChanges();
  userFilesList = this.firestore.collection('userfiles').valueChanges({ idField: 'id' }) as Observable<any[]>;
  brandsCollection = this.firestore.collection('brands').valueChanges({ idField: 'id' }) as Observable<Brand[]>;
  brandsFilesList = this.firestore.collection('brands').valueChanges({ idField: 'id' }) as Observable<Brand[]>;
  brandsCollections = this.firestore.collection('brands').valueChanges({ idField: 'id' }) as Observable<Brand[]>;
  brandBoardList = this.firestore.collection('brand-board').valueChanges({ idField: 'id' }) as Observable<any[]>;
  garmentsList = this.firestore.collection('garments').valueChanges({ idField: 'id' }) as Observable<any[]>;
  environmentCollection = this.firestore.collection('Environments').valueChanges({ idField: 'id' }) as Observable<Brand[]>;
  displayedColumns: string[] = ['position', 'name', 'weight', 'symbol'];
  public text = `{ "text": "This is text file!中文" }`;
  public fileName: string | undefined;
  routLogInFlag:boolean = true
  //zipFiles: Observable<ZipFile[]>;
  zipFile: any;
  zipList: any = {};
  binaryImg: any;
  testVar: any = 'Prashatn';
  keyvalue: any;
  boardId: any;
  brands: any;
  womensBrands: any;
  mensBrands: any;
  packJsonObject = []
  isShowDiv = false;
  hFiles: any[];
  IdBrands: Brand[];
  brandIDDeatils: any;
  womensBrandId: any;
  womensBrandss: Brand[];
  bName: any;
  userFiledetails: any = []
  counter: number = 2;
  selectedGender: any = "";
  selectedBrandId: any = "";
  selectedBrand: any;
  errMessage: any = "Select The Brand ";
  isloading: boolean = true;
  userUploadedAvatars: any;
  loading = false;
  brandId: any
  gender: any
  brandDetails: any
  boardName: any
  userSnapShotList: any = [];
  clothType: any;
  clothId: any
  clothDetailsList: any = {}
  userUploadedFileList: any = [];
  showImages: any = {}
  gltfImgSrc = ''
  selectedTab: any = "story"
  profileDetails: any = {}
  evnFileList: any = [];
  evnFileListFileName: any
  filterEnvironmentSnpShot: any = [];
  selectedAvtar: any
  garmentsCollection: any = [];
  clothDetails: any = {};
  clocthCollectionIdcollection: any = []
  brandsCollectionall: any = [];
  clothShapShotsList: any = []
  brandboardCollection: any = [];
  boards: any;
  brandCollectionIdcollectionAll: any = {};
  brandCollectionIdcollection: any = []
  brandhShapShotsList: any = []
  SnapShotFileDetails: any = []
  filterSnapShotForProfile:any=[]
  filterSnapShotFileDetails:any=[]
  filterByUserFlag: boolean = false;
  brandGarmentIds: any = []
  topScroll: number;
  cloct: any = {}
  pageScrollFlag: boolean = false;
  storyID: any
  stroyIdAll: any

  constructor(public commonService: CommonService,
    private router: Router,
    private dataSharingService: DataSharingService,
    private apiHttpService: ApiHttpService,
    private firestore: AngularFirestore,
    public sanitizer: DomSanitizer,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private _httpClient: HttpClient,
    private modal: NgbModal,
    private pinterestAPI: PinterestService, //, //  private _FileSaverService: FileSaverService
  ) {
    this.downloadZipFile();
    this.brandsCollection.subscribe(
      (brandList) => {
        this.brands = brandList;
        this.brandDetails = this.brands.find((brandList) => brandList.id === this.selectedBrandId)
      });


    this.dataSharingService.userclothDetailsListObservable.subscribe(cloth => {
      this.clothDetailsList = cloth
    })

    this.userFilesList.subscribe((userFile) => {
      this.userUploadedFileList = userFile
      let clothId = this.clothDetailsList
      let filelist = this.userUploadedFileList.filter((uploadlist) => uploadlist.link == clothId.id)
      this.userFiledetails = filelist
    })


    this.environmentCollection.subscribe(
      (evnFile) => {
        this.evnFileList = evnFile
      });

    this.garmentsList.subscribe(
      garments => {
        this.garmentsCollection = garments;
      });

    this.brandsFilesList.subscribe((brands) => {
      this.brandsCollectionall = brands;
    });

    this.brandBoardList.subscribe(
      brandboard => {
        this.brandboardCollection = brandboard;
      });


  }
  ngOnInit() {
    this.route.paramMap.subscribe(paramMap => {
      this.isloading = false
      this.boardName = paramMap.get('board')
      this.selectedBrandId = paramMap.get('brandId')
      this.selectedGender = paramMap.get('Gender')
      this.clothType = paramMap.get('clothsType')
      this.clothId = paramMap.get('id')
      this.stroyIdAll = paramMap.get('id')
      this.downloadZipFile()
      if (this.selectedBrandId == undefined) {
        this.errMessage = ''
      }
    })

    this.dataSharingService.userSnapShotObservable.subscribe(snapshot => {
      this.userSnapShotList = snapshot

    });

    this.dataSharingService.userclothDetailsListObservable.subscribe(cloth => {
      this.clothDetailsList = cloth
    })
    this.commonService.snapshotfileDetais().subscribe(async (snapshot: any) => {
      this.SnapShotFileDetails = snapshot; })


     this.scrollToStory(this.clothDetailsList)
     this.getPinterestBoards()
  }


  ngAfterViewChecked(): void {
    if (this.storyID != undefined && this.topScroll != undefined && this.garmentsnapShotFeeds != undefined && this.garmentsnapShotFeeds.nativeElement != undefined && !this.pageScrollFlag) {
      this.garmentsnapShotFeeds.nativeElement.scrollTop = this.topScroll;
      this.pageScrollFlag = true
    }
  }
  getPinterestBoards() {
    this.pinterestAPI.getBoardList().subscribe((data: {
      name: any; items: any;
    }) => {
      this.boards = data.items
    });

  }
  getStyleForGender(brand: any, gendar: string) {
    let snap = this.userSnapShotList
    this.dataSharingService.sendUserSnapShots(snap)
    this.selectedBrandId = brand.id;
    this.selectedGender = gendar
    this.errMessage = ''
    this.downloadZipFile()
  }
  getStoryForGender(brandIds) {
    let snap = this.userSnapShotList
    this.dataSharingService.sendUserSnapShots(snap)
    let brandid = brandIds.id
    this.router.navigate(['public/public-brand-profile', { 'brandId': brandid, }])
  }


  createImageFromBlob(image: Blob) {
    let reader = new FileReader();
    reader.addEventListener(
      'load',
      () => {
        this.binaryImg = reader.result;
      },
      false
    );
    if (image) {
      reader.readAsDataURL(image);
    }
  }

  async getZipFileContent(fileName: any) {
    var binary = await this.zipFile
      .file(fileName)
      .async('blob')
      .then(function (text: any) {
        return text;
      });
    this.binaryImg = URL.createObjectURL(binary); // create an url. img.src = URL.
  }

  downloadZipFile() {
    this.isloading = true;
    this.testVar = 'Change';
    let brandname = this.selectedBrand;;
    let brandId = this.selectedBrandId
    let zipfilename = "%2Fpack0"
    let gender = this.selectedGender;
    let packlocation = "AvatarBlenderClothes%2F" + brandId + "_" + gender + zipfilename;
    let brandEncodedurl = packlocation
    let url = 'https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/' + brandEncodedurl + '?alt=media&token=46df48b9-1f7a-4b61-98c6-bff029734da4'
    this._httpClient
      .get(
        url,
        //        'https://firebasestorage.googleapis.com/v0/b/yolouploader.appspot.com/o/AvatarBlenderClothes%2F6XE3yz64QMFyblSyIHpf_M%2FtestforyoloUpload?alt=media&token=0738d062-2c7c-4ca1-8300-5963dba8f93a',
        {
          observe: 'response',
          responseType: 'blob',
        }

      )
      .subscribe(async (res) => {
        await this.processZipFile(res.body, this.processResponse.bind(this));
        this.isloading = false;
      },
        (error) => this.handleError(error)
      );
  }

  handleError(error) {
    this.packJsonObject = [];
    this.isloading = false;
    this.errMessage = "Pack is not found for this selection !!!"
    this.isloading = false;

  }

  processResponse(res: any[]) {
    this.clothId
    this.packJsonObject = res;
    this.errMessage = "";

  }

  processZipFile(data, callBackSetJsonObject) {
    this.testVar = 'Change 121';
    this.zipList = JSZip.loadAsync(data, callBackSetJsonObject, this.testVar)
      .then(function (zip) {
        /* Parse json file */
        var jsonExtn = /(.json)$/;
        Object.keys(zip.files)
          .filter((fileName) => {
            return jsonExtn.test(fileName.toLowerCase());
          })
          .map((jsonFileName) => {
            var jsonFile = zip.files[jsonFileName];
            return jsonFile.async('string').then((jsonString) => {
              let packJsonObject = JSON.parse(jsonString);

              let jsonResponseData =
                [
                  {
                    title: 'headgear',
                    value:
                    {
                      'files': packJsonObject['hFiles'].filter((data) => data != ''),
                      'name': packJsonObject['hName'].filter((data) => data.indexOf('deleted') < 0)
                    }
                  },
                  {
                    title: 'upperwear',
                    value: {
                      'files': packJsonObject['uFiles'].filter((data) => data != ''),
                      'name': packJsonObject['uName'].filter((data) => data.indexOf('deleted') < 0),

                    }
                  },
                  {
                    title: 'bottomwear',
                    value: {
                      'files': packJsonObject['bFiles'].filter((data) => data != ''),
                      'name': packJsonObject['bName'].filter((data) => data.indexOf('deleted') < 0)
                    }
                  },
                  {
                    title: "footwear",
                    value: {
                      'files': packJsonObject['fFiles'].filter((data) => data != ''),
                      'name': packJsonObject['fName'].filter((data) => data.indexOf('deleted') < 0)
                    },
                  }
                ]
              callBackSetJsonObject(jsonResponseData);

            });

          });
        var re = /(.jpg|.png|.gif|.ps|.jpeg | .json)$/;
        var promises = Object.keys(zip.files)
          .filter(function (fileName) {
            // don't consider non image files
            return re.test(fileName.toLowerCase());
          })
          .map(function (fileName) {
            var file = zip.files[fileName];

            if (fileName.indexOf('.json') > 0) {

            } else {
              return file.async('blob').then(function (blob) {
                return [
                  fileName.substring(0, fileName.lastIndexOf('.')), // keep the link between the file name and the content
                  URL.createObjectURL(blob), // create an url. img.src = URL.createObjectURL(...) will work
                ];
              });
            }
          });
        // `promises` is an array of promises, `Promise.all` transforms it
        // into a promise of arrays
        return Promise.all(promises);
      })
      .then(function (result) {
        // we have here an array of [fileName, url]
        // if you want the same result as imageSrc:
        return result.reduce(function (acc, val) {
          acc[val[0]] = val[1];
          return acc;
        }, {});
      })
      .then((result) => {
        this.testVar = 'Prasahtn Rahulkar';
        this.zipList = JSON.parse(JSON.stringify(result));
        // callback(result);
        return;
        //return result.reduce(...);
      })
      .catch(function (e) {
        console.error(e);
      });

  }

  returnZero() {
    return 0
  }

  getBrandImage(imgURL: string) {
    let brandEncodedurl = encodeURI(imgURL).replace('/', '%2F');
    let url = "https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/" + brandEncodedurl + "?alt=media&token=550e7e88-bed3-4668-84ff-ad491c19e06c"
    return url;
  }


  showBrandPackDetails(brand: any, gendar: string) {
    this.selectedBrandId = brand.id;
    this.selectedGender = gendar
    this.selectedBrand = brand;
    this.errMessage = ""
    this.downloadZipFile()
  }

  // selectBrandForScroll(){
  //   document.querySelector('#target').scrollIntoView({ behavior: 'smooth', block: 'center' });
  // }

  selectGender($event) {
    this.selectedGender = $event.target.value
    this.isShowDiv = !this.isShowDiv;
  }
  getBrandStories(brand) {
    this.router.navigate(['yologram-social/yologram-brand-stories', { 'brandId': brand }])
  }
  getBrandStyle(cloths: any) {
    this.dataSharingService.sendUserSnapShots(this.userSnapShotList);
    this.router.navigate(['yologram-social/yologram-garment-style', { 'brandId': cloths.BrandID, 'clothsType': cloths.GarmentType, 'Gender': cloths.Gender, 'id': cloths.id }])
  }


  getBrandStylegender(cloths: any,gender: any) {
    this.dataSharingService.sendUserSnapShots(this.userSnapShotList);
    this.router.navigate(['yologram-social/yologram-garment-style', { 'brandId': cloths.BrandID, 'clothsType': cloths.GarmentType, 'Gender':gender, 'id': cloths.id }])
  }
  open2DModelPopUp(garmetBinaryImg, garmentType: any) {

    let garmentImg = this.sanitizer.bypassSecurityTrustUrl(garmetBinaryImg)
    const dialogRef = this.dialog.open(PopupTwoDComponent, {
      panelClass: 'dailog-transparent-css',
  
    });
    dialogRef.componentInstance.garmentImg = garmentImg;
    dialogRef.componentInstance.garmentType = garmentType;
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  openImageBox(fileurl) {
    let url = fileurl
    let showImage = this.userFiledetails.find((file) => file.fileurl == url)
    this.showImages = showImage
  }


  incrementImgCounter() {
    this.counter = this.counter + 2;
  }

  getStoryForGenderCloth(brandIds) {
    this.selectedTab = "story"
    let snap = this.userSnapShotList
    this.dataSharingService.sendUserSnapShots(snap)
    let brandid = brandIds.BrandID
  }


  getStory() {
    this.selectedTab = "Menswears"
   
  }
  getStyle(brandIds) {
    this.selectedTab = "Menswear"

  }

  getSnapShotListByUserPopup(snapshot: any) {
    // let userId = snapshot.UserID;
    // let url = environment.functionBaseUrl + "userProfileIdenifierById?id=" + userId;
    // this.apiHttpService.get(url).subscribe((data: any) => {
    //   this.profileDetails = data
    // })
    // this.userSnapShotList = this.userSnapShotList.filter((snapshot) => snapshot.UserID == userId).slice(0, 9);
    // this.dataSharingService.sendUserSnapShots(this.userSnapShotList);
    this.openUserPopup(snapshot)
  }
  openUserPopup(item: any) {
    const modalRef = this.modal.open(UserPopupComponent, {
      modalDialogClass: "model-garmentPopup"
    });
    modalRef.componentInstance.id = 10;
    modalRef.componentInstance.UserShapShotObject = item;
    modalRef.componentInstance.UserID = item.UserID;
    modalRef.componentInstance.userData = item.userData;
    modalRef.componentInstance.userProfile = 'userProfile';
    modalRef.componentInstance.rootFlag = 'yologram-social/yologram-user-profile-feed';
    modalRef.result.then((result) => {
    }).catch((error) => {
      console.log(error);
    });
  }

  
  openAvatarPopUp(UserShapShotObject: any, AvatarID: any) {
    const modalRef = this.modal.open(UserPopupComponent, {
      modalDialogClass: "model-garmentPopup"
    });
    modalRef.componentInstance.id = 10;
    modalRef.componentInstance.UserShapShotObject = UserShapShotObject;
    modalRef.componentInstance.UserID = UserShapShotObject.UserID;
    modalRef.componentInstance.AvatarID = UserShapShotObject.AvatarID;
    modalRef.componentInstance.userData = UserShapShotObject.userData;
    modalRef.componentInstance.userProfile = 'userProfile';
    modalRef.componentInstance.rootFlag = 'yologram-social/yologram-user-profile-feed';


    modalRef.result.then((result) => {
      console.log(result);
    }).catch((error) => {
      console.log(error);
    });
  }





  getEnvironmentName(environment) {
    let evenListName = "Environments/"
    let env = this.evnFileList.find((snap) => snap.storageURL == evenListName + environment)
    return env != undefined ? env['displayName'] : 'default';
  }

  environmentPopup(environment) {
    let evenListName = "Environments/"
    this.evnFileListFileName = this.evnFileList.find((snap) => snap.storageURL == evenListName + environment)
    let environmentSnapAllfile = this.SnapShotFileDetails.filter((snap) => snap.Environment === environment).slice(0, 9)
    this.filterEnvironmentSnpShot = environmentSnapAllfile
    this.evnFileListFileName['envFileName'] = environment
  }

  getSnapShotListAvatarPopup(snapshot: any) {
    // let userId = snapshot.UserID;
    // let avatarId = snapshot.AvatarID
    // this.selectedAvtar = avatarId
    // let url = environment.functionBaseUrl + "userProfileIdenifierById?id=" + userId;
    // this.apiHttpService.get(url).subscribe((data: any) => {
    //   this.profileDetails = data
    // })
    // this.userSnapShotList = this.SnapShotFileDetails.filter((snapshot) => snapshot.AvatarID == avatarId).slice(0, 9);
    // this.dataSharingService.sendUserSnapShots(this.userSnapShotList);
    this.openAvatarPopUp(snapshot,snapshot.AvatarID)
  }


  setClothDetails(garmentId: string) {
    if (this.garmentsCollection.length > 0) {
      let grandmentObject = this.garmentsCollection.find((b: any) => b.id === garmentId);
      this.clothDetails = this.garmentsCollection.find((b: any) => b.id === garmentId);
      let brandObject = this.brandsCollectionall.find((b: any) => b.id === this.clothDetails.BrandID);
      let brandGarments = this.garmentsCollection.filter((gaementList) => gaementList.id == this.clothDetails.id);
      this.clocthCollectionIdcollection = brandGarments.map((garment) => garment.id);
      this.clothShapShotsList = this.userSnapShotList.filter((snapshot) => {
        return this.clocthCollectionIdcollection.indexOf(snapshot.BottomwearID.trim()) >= 0 ||
          this.clocthCollectionIdcollection.indexOf(snapshot.HeadwearID.trim()) >= 0 ||
          this.clocthCollectionIdcollection.indexOf(snapshot.FootwearID.trim()) >= 0 ||
          this.clocthCollectionIdcollection.indexOf(snapshot.UpperwearID.trim()) >= 0
      }).slice(0, 9);
      this.clothDetails['BrandName'] = brandObject != undefined ? brandObject.BrandName : '';
      this.clothDetails['LogoURL'] = brandObject != undefined ? brandObject.LogoURL : '';
      if (grandmentObject && grandmentObject['Name'] != undefined) {
        let brandId = grandmentObject["BrandID"];
        this.brandDetails = this.brandsCollectionall.find((b: any) => b.id === brandId);
        this.brandDetails['boardName'] = 'Not Link to Board';
        let brandBoardObject = this.brandboardCollection.find((b: any) => b.brandId == brandId);
        if (brandBoardObject != undefined) {
          let boardId = brandBoardObject && brandBoardObject['boardId'] != undefined ? brandBoardObject['boardId'] : undefined;
          let boardDetails = this.boards.find((b: any) => b.id == boardId);
          // this.brandDetails['boardName'] = boardDetails['name'];
          if (boardDetails != undefined && boardDetails['name'] != undefined) this.brandDetails['boardName'] = boardDetails['name'];
        }
      }
      let clothId = this.clothDetails.id
      let filelist = this.userUploadedFileList.filter((uploadlist) => uploadlist.link == clothId).slice(0, 2)
      this.userFiledetails = filelist
    }

  }
  getGarmentName(garmentId: string) {
    let garmentTitle = "";
    let name = garmentId;
    let brandName = ""
    if (this.garmentsCollection.length > 0) {
      let grandmentObject = this.garmentsCollection.find((b: any) => b.id === garmentId);
      if (grandmentObject && grandmentObject['Name'] != undefined) {
        garmentTitle = grandmentObject['Name'];
      }
    }
    return garmentTitle;
  }
  // setBrandDetails(garmentId: string) {
  //   if (this.garmentsCollection.length > 0) {
  //     let grandmentObject = this.garmentsCollection.find((b: any) => b.id === garmentId);
  //     if (grandmentObject && grandmentObject['Name'] != undefined) {
  //       let brandId = grandmentObject["BrandID"];
  //       this.brandDetails = this.brandsCollectionall.find((b: any) => b.id === brandId);
  //       this.brandDetails['boardName'] = 'Not Link to Board';
  //       let brandBoardObject = this.brandboardCollection.find((b: any) => b.brandId == brandId);
  //       if (brandBoardObject != undefined) {
  //         let boardId = brandBoardObject && brandBoardObject['boardId'] != undefined ? brandBoardObject['boardId'] : undefined;
  //         let boardDetails = this.boards.find((b: any) => b.id == boardId);
  //         // this.brandDetails['boardName'] = boardDetails['name'];
  //         if (boardDetails != undefined && boardDetails['name'] != undefined) this.brandDetails['boardName'] = boardDetails['name'];
  //       }
  //     }
    
  //     let brandGarments = this.garmentsCollection.filter((gaementList) => gaementList.BrandID === grandmentObject.BrandID);
  //     this.brandCollectionIdcollection = brandGarments.map((garment) => garment.id);
  //     this.brandhShapShotsList = this.userFiledetails.filter((snapshot) => {
  //       return this.brandCollectionIdcollection.indexOf(snapshot.BottomwearID.trim()) >= 0 ||
  //         this.brandCollectionIdcollection.indexOf(snapshot.HeadwearID.trim()) >= 0 ||
  //         this.brandCollectionIdcollection.indexOf(snapshot.FootwearID.trim()) >= 0 ||
  //         this.brandCollectionIdcollection.indexOf(snapshot.UpperwearID.trim()) >= 0
  //     }).slice(0, 9);
   
  //   }
  //   // if(this.garmentsCollection.length > 0) {
  //   //   this.brandDetails = this.garmentsCollection.find((b: any) => b.id === garmentId);
  //   // }}
  // }
  setBrandDetails(garmentId: string) {
    if (this.garmentsCollection.length > 0) {
      let grandmentObject = this.garmentsCollection.find((b: any) => b.id === garmentId);
      let brandGarments = this.garmentsCollection.filter((gaementList) => gaementList.BrandID === grandmentObject.BrandID);
      this.brandCollectionIdcollection = brandGarments.map((garment) => garment.id);
      this.brandhShapShotsList = this.SnapShotFileDetails.filter((snapshot) => {
        return this.brandCollectionIdcollection.indexOf(snapshot.BottomwearID.trim()) >= 0 ||
          this.brandCollectionIdcollection.indexOf(snapshot.HeadwearID.trim()) >= 0 ||
          this.brandCollectionIdcollection.indexOf(snapshot.FootwearID.trim()) >= 0 ||
          this.brandCollectionIdcollection.indexOf(snapshot.UpperwearID.trim()) >= 0
      }).slice(0, 9);
      if (grandmentObject && grandmentObject['Name'] != undefined) {
        let brandId = grandmentObject["BrandID"];
        this.brandDetails = this.brandsCollectionall.find((b: any) => b.id === brandId);
        this.brandDetails['boardName'] = 'Not Link to Board';
        let brandBoardObject = this.brandboardCollection.find((b: any) => b.brandId == brandId);
        if (brandBoardObject != undefined) {
          let boardId = brandBoardObject && brandBoardObject['boardId'] != undefined ? brandBoardObject['boardId'] : undefined;
          let boardDetails = this.boards.find((b: any) => b.id == boardId);
          this.brandDetails['boardName'] = boardDetails['name'];
          if (boardDetails != undefined && boardDetails['name'] != undefined) this.brandDetails['boardName'] = boardDetails['name'];
        }
      }
    }
    // if(this.garmentsCollection.length > 0) {
    //   this.brandDetails = this.garmentsCollection.find((b: any) => b.id === garmentId);
    // }}
  }
  getGarmentrealtype(garmentId: string) {
    let garmentTitle = "";
    let name = garmentId;
    let brandName = ""
    if (this.garmentsCollection.length > 0) {
      let grandmentObject = this.garmentsCollection.find((b: any) => b.id === garmentId);
      if (grandmentObject && grandmentObject['RealType'] != undefined) {
        garmentTitle = grandmentObject['RealType'];
      }
    }
    return garmentTitle;
  }

  getBrandName(garmentId: string) {
    let garmentTitle = "";
    let name = garmentId;
    let brandName = ""
    if (this.garmentsCollection.length > 0) {
      let grandmentObject = this.garmentsCollection.find((b: any) => b.id === garmentId);
      if (grandmentObject && grandmentObject['Name'] != undefined) {
        let brandId = grandmentObject["BrandID"];
        let brandObject = this.brandsCollectionall.find((b: any) => b.id === brandId);
        brandName = brandObject != undefined && brandObject.BrandName != undefined ? brandObject.BrandName : '';
      }
    }
    return brandName;
  }
  getEnvironmentProfile(envFileName) {
    let evenListName = "Environments/"
    this.evnFileListFileName = this.evnFileList.find((snap) => snap.storageURL == evenListName+envFileName)
    let environmentSnapAllfile = this.SnapShotFileDetails.filter((snap) => snap.Environment === envFileName)
    this.dataSharingService.sendUserSnapShots(environmentSnapAllfile)
    this.router.navigate(['yologram-social/yologram-env-profile', { 'evnFile': this.evnFileListFileName.displayName }])
  }

 


  getFeed(brand: any ,brandName:any){
    this.router.navigate(['yologram-social/yologram-brand-stories', { 'brandId':brandName.id,'storyId':brand.StoryID }])
    }

  getUserSnapShotDashboar(userId) {
    let userSnapShotList = this.SnapShotFileDetails.filter((snapshot) => snapshot.UserID == userId);
    this.dataSharingService.sendUserSnapShots(userSnapShotList);
    this.router.navigate(['yologram-social/yologram-public-news-feed', { 'id': userId }])
  }
  getProfileByAvatorpage(snapshot: any) {
        this.filterSnapShotFileDetails = this.SnapShotFileDetails.filter((snapshotlist) => snapshotlist.AvatarID == snapshot);
        const userId =  this.filterSnapShotFileDetails.find((snapshotlist)=> snapshotlist.UserID )
        this.dataSharingService.sendUserSnapShots(this.filterSnapShotFileDetails);
        this.router.navigate(['yologram-social/yologram-public-news-feed', { 'id': userId.UserID, 'avatarID': snapshot }])
      }

     

      getSnapShotListByUser(snapshot: any) {
        let userId = snapshot.UserID;
        this.filterByUserFlag = true
        this.filterSnapShotFileDetails = this.SnapShotFileDetails.filter((snapshot) => snapshot.UserID == userId);
      }
      getuserfeed(uid){
        this.filterSnapShotFileDetails = this.SnapShotFileDetails.filter((snapshot) => snapshot.UserID == uid)
      }


      getBrandProfile(brand: any,board:any) {
        let brandGarments = this.garmentsCollection.filter((gaementList)=>gaementList.BrandID === brand);
        this.brandGarmentIds = brandGarments.map((garment) => garment.id);
        let SnapshotWithbrandGarment =  this.brandhShapShotsList = this.SnapShotFileDetails.filter((snapshot) => {
          return  this.brandCollectionIdcollectionAll.indexOf(snapshot.BottomwearID.trim())  >= 0 ||
          this.brandCollectionIdcollectionAll.indexOf(snapshot.HeadwearID.trim())  >= 0 ||
          this.brandCollectionIdcollectionAll.indexOf(snapshot.FootwearID.trim())  >= 0 ||
          this.brandCollectionIdcollectionAll.indexOf(snapshot.UpperwearID.trim()) >= 0
        })
        this.dataSharingService.sendUserSnapShots(SnapshotWithbrandGarment);
        this.dataSharingService.sendBrandGarments(this.brandGarmentIds);
        this.dataSharingService.sendBrandBrandBoard(board)  
        this.router.navigate(['yologram-social/yologram-brand-profile', {'brandId': brand}])
      }


      getBrandProfileByGarment(brand: any,board:any) {
        this.brandGarmentIds = []
        let brandGarments = this.garmentsCollection.filter((gaementList)=>gaementList.BrandID === brand);
        this.brandGarmentIds = brandGarments.map((garment) => garment.id);
        this.brandhShapShotsList = this.SnapShotFileDetails.filter((snapshot) => {
          return  this.brandGarmentIds.indexOf(snapshot.BottomwearID)  >= 0 ||
          this.brandGarmentIds.indexOf(snapshot.HeadwearID.trim())  >= 0 ||
          this.brandGarmentIds.indexOf(snapshot.FootwearID.trim())  >= 0 ||
          this.brandGarmentIds.indexOf(snapshot.UpperwearID.trim()) >= 0
        })
        this.dataSharingService.sendUserSnapShots(this.brandhShapShotsList);
        this.dataSharingService.sendBrandGarments( this.brandGarmentIds);
        this.dataSharingService.sendBrandBrandBoard(this.brandDetails.boardName)  
        this.router.navigate(['yologram-social/yologram-brand-profile', {'brandId': brand}])
      }

     getClothProfile(brand: any,){
        let clothAllDetails = this.clothDetails
        let brandGarments = this.garmentsCollection.filter((gaementList) => gaementList.id == this.clothDetails.id );
        this.clocthCollectionIdcollection = brandGarments.map((garment) => garment.id);
        this.clothShapShotsList = this.SnapShotFileDetails.filter((snapshot) => {
          return this.clocthCollectionIdcollection.indexOf(snapshot.BottomwearID) >= 0 ||
            this.clocthCollectionIdcollection.indexOf(snapshot.HeadwearID.trim()) >= 0 ||
            this.clocthCollectionIdcollection.indexOf(snapshot.FootwearID.trim()) >= 0 ||
            this.clocthCollectionIdcollection.indexOf(snapshot.UpperwearID.trim()) >= 0
          }).slice(0, 9);
          
          let snanpDetails = this.SnapShotFileDetails.filter((snapshot) => {
            return this.clocthCollectionIdcollection.indexOf(snapshot.BottomwearID) >= 0 ||
              this.clocthCollectionIdcollection.indexOf(snapshot.HeadwearID.trim()) >= 0 ||
              this.clocthCollectionIdcollection.indexOf(snapshot.FootwearID.trim()) >= 0 ||
              this.clocthCollectionIdcollection.indexOf(snapshot.UpperwearID.trim()) >= 0
            })
          this.dataSharingService.sendUserSnapShots(snanpDetails);
          this.dataSharingService.sendBrandGarments(this.clocthCollectionIdcollection);
          this.dataSharingService.sendClothDetails(clothAllDetails)
          this.router.navigate(['yologram-social/yologram-garment-profile', { 'brandId': brand }])
      }


      getStoryCloth(brandIds) {
        let snap = this.clothShapShotsList 
        this.dataSharingService.sendUserSnapShots(snap)
        this.dataSharingService.sendClothDetails(brandIds)
        let brandid = brandIds.BrandID
        this.router.navigate(['yologram-social/yologram-garment-profile', { 'brandId': brandid, }])
      }
    getgarmentStyle(brandName: any, cloths: any) {
        let editgarment = 'edit'
        let snap = this.clothShapShotsList 
        this.dataSharingService.sendUserSnapShots(snap)
        this.dataSharingService.sendClothDetails(cloths)
        this.router.navigate(['yologram-social/yologram-garment-style', { 'brandId': cloths.BrandID, 'clothsType': cloths.GarmentType, 'Gender': cloths.Gender, 'id': cloths.id, 'edit': editgarment}])
      }

         
      getgarmentStylefeed(brandName: any, cloths: any) {
        let snap = this.clothShapShotsList 
        this.dataSharingService.sendUserSnapShots(snap)
        this.dataSharingService.sendClothDetails(cloths)
        this.router.navigate(['yologram-social/yologram-garment-feed', { 'brandId': cloths.BrandID, 'clothsType': cloths.GarmentType, 'Gender': cloths.Gender, 'id': cloths.id }])
      }



      scrollToStory(stroyId){
        if (this.storyID != undefined && this.topScroll != undefined && this.garmentsnapShotFeeds != undefined && this.garmentsnapShotFeeds.nativeElement != undefined && !this.pageScrollFlag) {
          this.garmentsnapShotFeeds.nativeElement.scrollTop = this.topScroll;
          this.pageScrollFlag = true
        }
        this.storyID = stroyId.id
        let brandIndex = 1;
        let brandGarments = this.garmentsCollection.filter((gaementList) => gaementList.id == stroyId.id );
        this.clocthCollectionIdcollection = brandGarments.map((garment) => garment.id);
        this.userSnapShotList.forEach(async (snap: any, index) => {
          if (stroyId.StoryID != undefined && stroyId.StoryID === snap.StoryID) {
            this.topScroll = brandIndex * 670;
            this.garmentsnapShotFeeds.nativeElement.scrollTop = this.topScroll;
          }
          if ((
            this.clocthCollectionIdcollection.indexOf(snap.BottomwearID.trim()) >= 0 ||
            this.clocthCollectionIdcollection.indexOf(snap.HeadwearID.trim()) >= 0 ||
            this.clocthCollectionIdcollection.indexOf(snap.FootwearID.trim()) >= 0 ||
            this.clocthCollectionIdcollection.indexOf(snap.UpperwearID.trim()) >= 0
          )) { brandIndex = brandIndex + 1; }
         
        });
        this.selectedTab = "story"
      }


   
  openModel(gltfImgSrc: string) {
    const dialogRef = this.dialog.open(PopupThreeDComponent);
    this.gltfImgSrc = gltfImgSrc
    dialogRef.componentInstance.gltfImgSrc = this.gltfImgSrc;
    dialogRef.afterClosed().subscribe(result => {
    });



















  }
}


