export const environment = {
  production: false,
  functionBaseUrl: "https://us-central1-yolomoves-fb435.cloudfunctions.net/",
  storageWebURL: "https://firebasestorage.googleapis.com/v0/b/",
  pininteresttoken: "pina_AEATRDYWABCMGAIAGCAOIDDH52XKVBIBACGSOQWWKDNICIOE6RYRM6UQH3TAMCVBQX4T6LA3OQUDJ7EOSSO4IFNVV3A2KWYA",
  
  firebase: {
    apiKey: "AIzaSyDTgemNNQj_PegqRMp60hyOE0s07dPAoi0",
    authDomain: "yolomoves-fb435.firebaseapp.com",
    databaseURL: "https://yolomoves-fb435-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "yolomoves-fb435",
    storageBucket: "yolomoves-fb435.appspot.com",
    messagingSenderId: "143752816307",
    appId: "1:143752816307:web:0a3e434767f25c6a8572c6",
    measurementId: "G-QRWKY2SLX4",
  }


  // firebase : {
  //   apiKey: "AIzaSyA4qNuGc97iwrH7C5L2OR5_F9MJfrfVhrM",
  //   authDomain: "yologramsocialdev.firebaseapp.com",
  //   databaseURL: "https://yologramsocialdev-default-rtdb.firebaseio.com",
  //   projectId: "yologramsocialdev",
  //   storageBucket: "yologramsocialdev.appspot.com",
  //   messagingSenderId: "366568386991",
  //   appId: "1:366568386991:web:1a6bcb37984018bb423f5a",
  //   measurementId: "G-Y4JMHDJGHQ"
  // }

};

// for test yoloUloder
// export const environment = {
//   production: true,
//   functionBaseUrl:"https://us-central1-yolouploader.cloudfunctions.net/",
//   storageWebURL: "https://firebasestorage.googleapis.com/v0/b/",
//   pininteresttoken: "pina_AEATRDYWABCMGAIAGCAOIDEGXHYJ3AYBACGSPWQ6JVNR5CVWD72FUAOI7KIGJE2QV5LOCD4RTUNXPTCRHEOFBW3ZOVET3WAA",
//   firebase  : {
//     apiKey: "AIzaSyCL5HrHqSB34fT7sHN4v0TGmnRlynlZFwI",
//     authDomain: "yolouploader.firebaseapp.com",
//     databaseURL: "https://yolouploader-default-rtdb.asia-southeast1.firebasedatabase.app",
//     projectId: "yolouploader",
//     storageBucket: "yolouploader.appspot.com",
//     messagingSenderId: "1050403599413",
//     appId: "1:1050403599413:web:93aa320d604bb001b1ad7c",
//     measurementId: "G-D9XY4VM4N6"
//   }
// };


