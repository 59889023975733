
import { ChangeDetectorRef, Component, ElementRef, Inject, Input, OnInit, QueryList, SimpleChanges, ViewChild, ViewChildren } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';
import { Snapshot } from 'src/app/models/snapshot';
import { PinterestService } from 'src/app/services/pinterest-service';
import { NavigationCard } from 'src/app/services/navigation-card-services';
import { Brand } from 'src/app/models/brand';
import { BrandPopupComponent } from '../templets/brand-popup/brand-popup.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GarmentPopupComponent } from '../templets/garment-popup/garment-popup.component';
import { UserPopupComponent } from '../templets/user-popup/user-popup.component';
import { PopupThreeDComponent } from '../popup-three-d/popup-three-d.component';
import { MatDialog } from '@angular/material/dialog';
import { DataSharingService } from 'src/app/services/data-sharing-service';

declare var $: any; // Declare jQuery
@Component({
  selector: 'app-dummy-public-news',
  templateUrl: './dummy-public-news.component.html',
  styleUrls: ['./dummy-public-news.component.css']
})
export class DummyPublicNewsComponent {
  @ViewChild('scrollContainer') scrollContainer: ElementRef;

  user = this.firestore.collection('user-profile').valueChanges({ idField: 'id' }) as Observable<any[]>;
  brandBoardList = this.firestore.collection('brand-board').valueChanges({ idField: 'id' }) as Observable<any[]>;
  garmentsList = this.firestore.collection('garments').valueChanges({ idField: 'id' }) as Observable<any[]>;
  brandsFilesList = this.firestore.collection('brands').valueChanges({ idField: 'id' }) as Observable<Brand[]>;
  environmentCollection = this.firestore.collection('Environments').valueChanges({ idField: 'id' }) as Observable<Brand[]>;
  pinterestBoardPinsStatus = this.firestore.collection('pinterest-board-pins-status').valueChanges({ idField: 'id' }) as Observable<Snapshot[]>;


  @ViewChild('newSliderSCroll', { read: ElementRef }) public newSliderSCroll: ElementRef<any>;
  @ViewChild('verticalSCrolls', { read: ElementRef }) public verticalSCrolls: ElementRef<any>;
  @ViewChild('swipeMainContent', { read: ElementRef }) public swipeMainContent: ElementRef<any>;
  @ViewChild('owlCarousel', { read: ElementRef }) public owlCarousel: ElementRef<any>;
  @ViewChildren('horizontalScroll') horizontalScrolls!: QueryList<ElementRef>;
  @ViewChild('widgetsContent', { read: ElementRef })
  public widgetsContent!: ElementRef<any>;
  public arr = ['foo', 'bar', 'baz'];
  @ViewChild('panel', { read: ElementRef })
  public panel!: ElementRef<any>;


  cloth1 = 'cloth_20210607205214909'
  cloth2 = 'cloth_20210611065003676'
  cloth3 = 'cloth_20210611170737501'
  cloth4 = 'cloth_20210611170755820'
  brandCollectionIdcollection: any = []
  brandhShapShotsList: any = []
  items: string[] = [];



  UpperwearID: string = "UpperwearID"
  BottomwearID: string = "BottomwearID"
  HeadwearID: string = "HeadwearID"
  FootwearID: string = "FootwearID"
  pinterstJsonMap = {
    "StoryID": undefined,
    "upperwear": undefined,
    "footwear": undefined,
    "headwear": undefined
  }
  dummyFlag: boolean = true
  flag: boolean = false
  imagesOdd = [
    { "image": "./assets/user-upload-dummy/img-1.jpg" },
    { "image": "./assets/user-upload-dummy/img-2.jpg" },
    { "image": "./assets/user-upload-dummy/img-3.jpg" },
    { "image": "./assets/user-upload-dummy/img-4.jpg" },
    { "image": "./assets/user-upload-dummy/img-5.jpg" },
    { "image": "./assets/user-upload-dummy/img-6.jpg" },
    { "image": "./assets/user-upload-dummy/img-1.jpg" },
    { "image": "./assets/user-upload-dummy/img-5.jpg" },
    { "image": "./assets/user-upload-dummy/img-3.jpg" },
    { "image": "./assets/user-upload-dummy/img-4.jpg" },
  ];

  images = [
    { "image": "./assets/garment-upload-dummy/img-1.jpg", 'id': '0' },
    { "image": "./assets/garment-upload-dummy/img-2.jpg", 'id': '1' },
    { "image": "./assets/garment-upload-dummy/img-3.jpg", 'id': '2' },
    { "image": "./assets/garment-upload-dummy/img-4.jpg", 'id': '3' },
    { "image": "./assets/garment-upload-dummy/img-5.jpg", 'id': '4' },
    { "image": "./assets/garment-upload-dummy/img-6.jpg", 'id': '5' },
    { "image": "./assets/garment-upload-dummy/img-7.jpg", 'id': '6' },
    { "image": "./assets/garment-upload-dummy/img-8.jpg", 'id': '7' },
    { "image": "./assets/garment-upload-dummy/img-9.jpg", 'id': '8' },
    { "image": "./assets/garment-upload-dummy/img-10.jpg", 'id': '9' },
  ]
  images1 = "./assets/garment-upload-dummy/img-8.jpg"
  images2 = "./assets/garment-upload-dummy/img-8.jpg"
  images3 = "./assets/garment-upload-dummy/img-8.jpg"
  images4 = "./assets/garment-upload-dummy/img-8.jpg"


  shotDetails: any = []
  filterEnvironmentSnpShot: any = []
  allImageDetails: any[] = [
    {
      imageType: [
        { name: 'https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/Snapshots%2F1024x1024_2023-11-02_15-36-04.png?alt=media&token=f533a083-6cef-4c02-84ef-333e771863cd' },
        { desc: './assets/user-upload-dummy/new-img-57.jpg' },
        { price: './assets/user-upload-dummy/new-img-57.jpg' },
        { contact: './assets/user-upload-dummy/new-img-57.jpg' }
      ]
    },
    {
      imageType: [
        { name: 'https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/Snapshots%2F1024x1024_2023-11-02_15-36-04.png?alt=media&token=f533a083-6cef-4c02-84ef-333e771863cd' },
        { desc: './assets/user-upload-dummy/new-img-57.jpg' },
        { price: './assets/user-upload-dummy/new-img-57.jpg' },
        { contact: './assets/user-upload-dummy/new-img-57.jpg' }
      ]
    },
    {
      imageType: [
        { name: 'https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/Snapshots%2F1024x1024_2023-11-02_15-36-04.png?alt=media&token=f533a083-6cef-4c02-84ef-333e771863cd' },
        { desc: './assets/user-upload-dummy/new-img-57.jpg' },
        { price: './assets/user-upload-dummy/new-img-57.jpg' },
        { contact: './assets/user-upload-dummy/new-img-57.jpg' }
      ]
    },
    {
      imageType: [
        { name: './assets/user-upload-dummy/new-img-4.jpg' },
        { desc: './assets/user-upload-dummy/new-img-57.jpg' },
        { price: './assets/user-upload-dummy/new-img-57.jpg' },
        { contact: './assets/user-upload-dummy/new-img-57.jpg' }
      ]
    },
    {
      imageType: [
        { name: './assets/user-upload-dummy/new-img-5.jpg' },
        { desc: './assets/user-upload-dummy/new-img-57.jpg' },
        { price: './assets/user-upload-dummy/new-img-57.jpg' },
        { contact: './assets/user-upload-dummy/new-img-57.jpg' }
      ]
    },
    {
      imageType: [
        { name: './assets/user-upload-dummy/new-img-6.jpg' },
        { desc: './assets/user-upload-dummy/new-img-57.jpg' },
        { price: './assets/user-upload-dummy/new-img-57.jpg' },
        { contact: './assets/user-upload-dummy/new-img-57.jpg' }
      ]
    },
    {
      imageType: [
        { name: './assets/user-upload-dummy/new-img-7.jpg' },
        { desc: './assets/user-upload-dummy/new-img-57.jpg' },
        { price: './assets/user-upload-dummy/new-img-57.jpg' },
        { contact: './assets/user-upload-dummy/new-img-57.jpg' }
      ]
    },
    {
      imageType: [
        { name: './assets/user-upload-dummy/new-img-8.jpg' },
        { desc: './assets/user-upload-dummy/new-img-57.jpg' },
        { price: './assets/user-upload-dummy/new-img-57.jpg' },
        { contact: './assets/user-upload-dummy/new-img-57.jpg' }
      ]
    },
    {
      imageType: [
        { name: './assets/user-upload-dummy/new-img-9.jpg' },
        { desc: './assets/user-upload-dummy/new-img-57.jpg' },
        { price: './assets/user-upload-dummy/new-img-57.jpg' },
        { contact: './assets/user-upload-dummy/new-img-57.jpg' }
      ]
    },
    {
      imageType: [
        { name: './assets/user-upload-dummy/new-img-9.jpg' },
        { desc: './assets/user-upload-dummy/new-img-57.jpg' },
        { price: './assets/user-upload-dummy/new-img-57.jpg' },
        { contact: './assets/user-upload-dummy/new-img-57.jpg' }
      ]
    },
    {
      imageType: [
        { name: './assets/user-upload-dummy/new-img-10.jpg' },
        { desc: './assets/user-upload-dummy/new-img-57.jpg' },
        { price: './assets/user-upload-dummy/new-img-57.jpg' },
        { contact: './assets/user-upload-dummy/new-img-57.jpg' }
      ]
    },
    {
      imageType: [
        { name: './assets/user-upload-dummy/new-img-11.jpg' },
        { desc: './assets/user-upload-dummy/new-img-57.jpg' },
        { price: './assets/user-upload-dummy/new-img-57.jpg' },
        { contact: './assets/user-upload-dummy/new-img-57.jpg' }
      ]
    },

  ];

  scrollingImages: any = []
  itemWidth = 0
  loading: boolean = false
  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    margin: 1,
    navSpeed: 700,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      740: {
        items: 1,
      },
      940: {
        items: 1,
      },
    },
    nav: true,
  };
  indexValue: any;
  SnapshotDetails: any[] = [];
  clothIndexing: any[];
  alertFlag: boolean;
  next: number = 1;
  filterSnapShotFileDetails: any;
  stroyLodaingFlag: boolean;
  successMsgs: string;
  storyIdByindex: any;
  displayProgressSpinner: boolean;
  pinImg: any;
  userData: any;
  allUserDetails: any = [];
  garmentsCollection: any = [];
  brandboardCollection: any = [];
  storyPinterestPinsList: any = [];
  brandsCollection: any = [];
  evnFileList: any = [];
  selectedIndex: number = 0;
  selectedGarmentType: any;
  scrollSnap: any;
  useFeedPagelink: string;
  brandPageLink: string;
  envirmentPageLink: string;
  brandDetails: any;
  boards: any;
  evnFileListFileName: any;
  environmentFileName: any;
  gltfImgSrc: string;
  urlEvn: any;
  twoDprivew: string;
  skyBoXall: string;
  evsname: any;
  evsdiplaynameALL: any;
  hideFlag: any = true;
  pintrestFlag: boolean = false;
  envUrl: any;
  avatraUrls: any;
  garmenturl: any;
  garmentbottom: any;
  genders: any;
  headgear: any;
  passId: any;
  threeDopen: boolean = false;
  avtarUrl: string;
  GarmentsGender: any;
  garmentLink: string;
  evnLink: string;
  footwear: any;





  constructor(private commonService: CommonService,
    private scrollService: CommonService,
    private cdr: ChangeDetectorRef,
    private firestore: AngularFirestore,
    private pinterestAPI: PinterestService,
    private navigate: NavigationCard,
    private modal: NgbModal,
    public dialog: MatDialog,
    private dataSharingService: DataSharingService,


  ) {



    this.garmentsList.subscribe(
      garments => {
        this.garmentsCollection = garments;
      });


    this.brandBoardList.subscribe(
      brandboard => {
        this.brandboardCollection = brandboard;
      });


    this.pinterestBoardPinsStatus.subscribe(
      response => {
        this.storyPinterestPinsList = response
      });

    this.brandsFilesList.subscribe((brands) => {
      this.brandsCollection = brands;
    });

    this.environmentCollection.subscribe(
      (evnFile) => {
        this.evnFileList = evnFile
      });



  }

  ngAfterViewInit() {

//    setTimeout(() => this.horiZonatalScroll(), 0); 
    // this.adjustVerticalScroll()
   }


  ngOnInit() {
    this.loadContent();
    this.snapShotInit()
    // this.horiZonatalScroll()
    // this.brand();
    this.useFeedPagelink = 'public/news-user'
    this.brandPageLink = 'public/public-brand-stories'
    this.envirmentPageLink = '/public/public-evn-profile'
    this.envirmentPageLink = 'public/public-garment-feed'
    this.getPinterestBoards()

  }

  brand() {
    this.horizontalScrolls.forEach((scrollContainer: ElementRef) => {
      if (scrollContainer.nativeElement instanceof Element) {
        this.infiniteXScrolls(scrollContainer.nativeElement)
      }
    });
  }


  scrollDown(){
    // this.verticalSCrolls.nativeElement.scrollTop
    // this.verticalSCrolls.nativeElement.scrollTop = -20
    // console.log('verticalSCrolls',this.verticalSCrolls.nativeElement.scrollTop)
  }



  snapShotInit() {
    this.commonService.getAllUser().subscribe(async (allUsers: any) => {
      this.allUserDetails = allUsers.users;
      this.user.subscribe(userdetails => {
        this.userData = userdetails
        let reqInputObject = { "pageNumber": 1 }
        this.commonService.yologramPublicSnapshot(reqInputObject).subscribe((snapshot: any) => {
          this.SnapshotDetails = snapshot.object;
          this.SnapshotDetails.forEach(async (snap: any, index) => {
            if (!this.userData || !this.allUserDetails) {
              console.error('userData or allUserDetails is undefined');
              return;
            }
            let UserProfileObject = this.userData.find((user) => user.UserID == snap.UserID);
            let userObjet = this.allUserDetails.find(userObject => userObject.uid == snap.UserID);

            snap.profileName = UserProfileObject.DisplayName != undefined ? UserProfileObject.DisplayName : (userObjet.displayName != undefined ? userObjet.displayName : snap.UserID);
            userObjet.profileName = snap.profileName;
            snap.userData = userObjet
            snap.firstName = snap.profileName.split(" ", 1);
            snap.AvatarLastFive = snap.AvatarID.substr(snap.AvatarID.length - 5);
          });
          console.log("SnapshotDetails", this.SnapshotDetails);
          this.cdr.detectChanges(); // Ensure view is updated
          this.horiZonatalScroll(); // Reinitialize horizontal scroll
          this.flag = true;
        },
          error => {
            console.error('Error fetching SnapshotDetails:', error);
          }
        );
      });
    })
  }





  onScroll(snapShot) {
    this.clothIndexing = []
    if (snapShot.HeadwearID != "") this.clothIndexing.push('HeadwearID');
    if (snapShot.UpperwearID != "") this.clothIndexing.push('UpperwearID');
    if (snapShot.BottomwearID != "") this.clothIndexing.push('BottomwearID');
    if (snapShot.FootwearID != "") this.clothIndexing.push('FootwearID');
  }


  // inifiniteXScrolls(el) {
  // //   let lastScrollX = 0;
  // //   let overlapCount = 0;
  // //   let clones = [];
  
  // //   // Check if getClientRects() returns a non-empty array
  // //   const clientRects = el.getClientRects();
  // //   if (clientRects.length === 0) {
  // //     // console.error('Element has no client rectangles');
  // //     return null;
  // //   }
  // //   while (el.children[overlapCount] && ((el.children[overlapCount].offsetLeft - el.offsetLeft)) <= el.getClientRects()[0].width) {
  // //     const clone = el.children[overlapCount].cloneNode(true);
  // //     clones.push(clone);
  // //     el.appendChild(clone);
  // //     overlapCount++;
  // //   }
  // //   el.scrollTo({ left: lastScrollX });
  // //   overlapCount -= 1;
  // //   let lastScroll: number = el.scrollLeft = 1;
  // //   function scrollHandler(): void {
  // //     const maxScroll: number = el.scrollWidth - el.clientWidth;
  // //     const overlapOverflow =
  // //       (el.children[overlapCount].offsetLeft - el.offsetLeft)
  // //       - el.getClientRects()[0].width
  // //       + el.children[overlapCount].getClientRects()[0].width;
  // //     if (el.scrollLeft >= maxScroll && el.scrollLeft > lastScroll) {
  // //       el.scrollLeft = overlapOverflow;
  // //     } else if (el.scrollLeft <= 0 && el.scrollLeft < lastScroll) {
  // //       el.scrollLeft = maxScroll - overlapOverflow;
  // //     }
  // //     lastScroll = el.scrollLeft;
  // //   }
  // //   el.addEventListener('scroll', scrollHandler);
  // //   return function () {
  // //     const scroll = el.scrollLeft;
  // //     clones.forEach((el) => el.replaceWith(""));
  // //     el.scrollTo({ auto: scroll });
  // //     el.removeEventListener('scroll', scrollHandler);
  // //   }
  // // }

  // // trackByFn(index, item) {
  // //   return item.id; // or whatever unique identifier you have in your objects
  // // }

  // // updateScroll() {
  // //   // Your scroll logic here
  // //   this.cdr.detectChanges(); // Manually trigger change detection
  // // }
  // // scrollVertical() {
  // //   setTimeout(() => {
  // //     // this.newSliderSCroll.nativeElement.scrollTo({ left: -50, behavior: 'auto' });
  // //   }, 1000)
  // //   this.scrollToElementfast()
  // // }
  // // scrollToElementfast() {
  // //   document.getElementById('0').scrollIntoView({
  // //     behavior: 'smooth'
  // //     // this.newSliderSCroll.nativeElement.scrollTo({ left: -50, behavior: 'auto' });
  // //   });



  // let lastScrollX = 0;
  //   let overlapCount = 0;
  //   let clones = [];
  //   // Check if getClientRects() returns a non-empty array
  //   const clientRects = el.getClientRects();
  //   if (clientRects.length == 0) {
  //     console.error('Element has no client rectangles');
  //     return null;
  //   }
  //   const elWidth = clientRects[0].width;
  //   while (el.children[overlapCount] && ((el.children[overlapCount].offsetLeft - el.offsetLeft)) <= elWidth) {
  //     const clone = el.children[overlapCount].cloneNode(true);
  //     clones.push(clone);
  //     el.appendChild(clone);
  //     overlapCount++;
  //   }
  //   el.scrollTo({ left: lastScrollX });
  //   overlapCount -= 1;
  //   let lastScroll = el.scrollLeft = 1;
  
  //   function scrollHandler() {
  //     const maxScroll = el.scrollWidth - el.clientWidth;
  //     const overlapOverflow =
  //       (el.children[overlapCount].offsetLeft - el.offsetLeft)
  //       - elWidth
  //       + el.children[overlapCount].getClientRects()[0].width;
  
  //     if (el.scrollLeft >= maxScroll && el.scrollLeft > lastScroll) {
  //       el.scrollLeft = overlapOverflow;
  //     } else if (el.scrollLeft <= 0 && el.scrollLeft < lastScroll) {
  //       el.scrollLeft = maxScroll - overlapOverflow;
  //     }
  //     lastScroll = el.scrollLeft;
  //   }
  
  //   el.addEventListener('scroll', scrollHandler);
  //   return function () {
  //     const scroll = el.scrollLeft;
  //     clones.forEach((clone) => clone.replaceWith(""));
  //     el.scrollTo({ auto: scroll });
  //     el.removeEventListener('scroll', scrollHandler);
  //   }
  // }
  // inifiniteXScrolls(el) {
  //   let lastScrollX = 0;
  //   let overlapCount = 0;
  //   let clones = [];
    
  //   // Ensure el is an HTMLElement to access offset properties
  //   if (!(el instanceof HTMLElement)) {
  //     console.error('Passed element is not an HTMLElement');
  //     return null;
  //   }
  
  //   const clientRects = el.getClientRects();
  //   if (clientRects.length === 0) {
  //     console.error('Element has no client rectangles');
  //     return null;
  //   }
  
  //   while (el.children[overlapCount] && el.children[overlapCount] instanceof HTMLElement && ((el.children[overlapCount] as HTMLElement).offsetLeft - el.offsetLeft) <= el.clientWidth) {
  //     const child = el.children[overlapCount] as HTMLElement;
  //     const clone = child.cloneNode(true) as HTMLElement;
  //     clone.classList.add('clone'); // Optionally add a class to cloned elements
  //     clones.push(clone);
  //     el.appendChild(clone);
  //     overlapCount++;
  //   }


  
  //   function scrollHandler() {
  //     // const maxScroll = el.scrollWidth - el.clientWidth;
  //     // const scrollX = el.scrollLeft;
  
  //     // if (scrollX >= maxScroll) {
  //     //   el.scrollLeft = 1; // A small offset to ensure scroll event can still trigger
  //     // } else if (scrollX <= 0) {
  //     //   el.scrollLeft = maxScroll - 1;
  //     // }
  
  //     // lastScrollX = scrollX;
  //     const maxScroll = el.scrollWidth - el.clientWidth;
  // const scrollX = el.scrollLeft;

  // // Debugging
  // console.log(`ScrollX: ${scrollX}, MaxScroll: ${maxScroll}`);

  // if (scrollX >= maxScroll - 1) {
  //   // Near the end, reset to start
  //   el.scrollLeft = 1;
  // } else if (scrollX <= 0) {
  //   // Near the start, reset to just before the end
  //   el.scrollLeft = maxScroll - 1;
  // }

  // lastScrollX = scrollX;
  //   }

  //   el.addEventListener('scroll', scrollHandler);
  //   return function cleanup() {
  //     clones.forEach(clone => clone.remove());
  //     el.removeEventListener('scroll', scrollHandler);
  //   };


    
  // }
  
  // inifiniteXScrolls(el) {
  //   console.log("inifiniteXScrolls init")
  //   let overlapCount = 0;
  //   let clones = [];
  
  //   // Ensure el is an HTMLElement to access offset properties
  //   if (!(el instanceof HTMLElement)) {
  //     console.error('Passed element is not an HTMLElement');
  //     return null;
  //   }
  
  //   const clientRects = el.getClientRects();
  //   if (clientRects.length === 0) {
  //     console.error('Element has no client rectangles');
  //     return null;
  //   }
  
  //   // Clone child elements until the clones fill up the visible area
  //   while (el.children[overlapCount] && el.children[overlapCount] instanceof HTMLElement 
  //          && ((el.children[overlapCount] as HTMLElement).offsetLeft - el.offsetLeft) <= el.clientWidth) {

  //     const child = el.children[overlapCount] as HTMLElement;
  //     const clone = child.cloneNode(true) as HTMLElement;
  //     clone.classList.add('clone');
  //     console.log("Clone  init")
  //     clones.push(clone);

  //     el.appendChild(clone);
  //     overlapCount++;
  //   }
  
  //   // Scroll event handler
  //   function scrollHandler() {
  //     //setTimeout(() => {
  //       console.log("element");
  //       console.log(el);
  //       const maxScroll = el.scrollWidth - el.clientWidth;
  //       const scrollX = el.scrollLeft;
  //       console.log(scrollX +">=" +maxScroll );
  //       if (scrollX >= maxScroll-5) {
  //         console.log("if")
  //         el.scrollLeft = scrollX-maxScroll+5; // reset to start

  //       } else if (scrollX <= 0) {
  //         console.log("else")
  //         el.scrollLeft = maxScroll - 1; // reset to just before the end
  //       }
  //    // }
  //     //, 350); // Delay of 350ms or adjust based on your transition time
  //   }
  
  //   // el.addEventListener('scroll', scrollHandler);
  //   //el.addEventListener('scroll', this.debounce(scrollHandler, 10)); // Adjust the time as neede
  //   el.addEventListener('scroll', scrollHandler); // Adjust the time as neede

  //   // Cleanup function to remove clones and event listener
  //   return function cleanup() {
  //     clones.forEach(clone => clone.remove());
  //     el.removeEventListener('scroll', scrollHandler);
  //   };
  // }
  


  // infiniteXScrolls(el) {
  //   let lastScrollX = 0;
  //   let overlapCount = 0;
  //   let clones = [];
  //   // Check if getClientRects() returns a non-empty array
  //   const clientRects = el.getClientRects();
  //   if (clientRects.length == 0) {
  //     // console.error('Element has no client rectangles');
  //     return null;
  //   }
  //   const elWidth = clientRects[0].width;
  //   while (el.children[overlapCount] && ((el.children[overlapCount].offsetLeft - el.offsetLeft)) <= elWidth) {
  //     const clone = el.children[overlapCount].cloneNode(true);
  //     clones.push(clone);
  //     el.appendChild(clone);
  //     overlapCount++;
  //   }
  //   el.scrollTo({ left: lastScrollX });
  //   overlapCount -= 1;
  //   let lastScroll = el.scrollLeft = 1;
  
  //   function scrollHandler() {
  //     console.log(el)
  //     const maxScroll = el.scrollWidth - el.clientWidth - 50;
  //     const overlapOverflow =
  //       (el.children[overlapCount].offsetLeft - el.offsetLeft)
  //       - elWidth
  //       + el.children[overlapCount].getClientRects()[0].width;
  //       console.log(el.scrollLeft +">="+ maxScroll)
  //       console.log(el.scrollLeft +">"+ lastScroll)
  //     if (el.scrollLeft >= maxScroll && el.scrollLeft > lastScroll) {
  //       el.scrollLeft = overlapOverflow;
  //     } else if (el.scrollLeft <= 0 && el.scrollLeft < lastScroll) {
  //       el.scrollLeft = maxScroll - overlapOverflow;
  //     }
  //     lastScroll = el.scrollLeft;
  //   }
  
  //   el.addEventListener('scroll', scrollHandler);
  //   return function () {
  //     const scroll = el.scrollLeft;
  //     clones.forEach((clone) => clone.replaceWith(""));
  //     el.scrollTo({ auto: scroll });
  //     el.removeEventListener('scroll', scrollHandler);
  //   }
  // }
   infiniteXScrolls(el) {
    let lastScrollX = 0;
    let overlapCount = 0;
    let clones = [];
    // Check if getClientRects() returns a non-empty array
    const clientRects = el.getClientRects();
    if (clientRects.length == 0) {
      // console.error('Element has no client rectangles');
      return null;
    }
    const elWidth = clientRects[0].width;
    while (el.children[overlapCount] && ((el.children[overlapCount].offsetLeft - el.offsetLeft)) <= elWidth) {
      const clone = el.children[overlapCount].cloneNode(true);
      clones.push(clone);
      el.appendChild(clone);
      overlapCount++;
    }
    el.scrollTo({ left: lastScrollX });
    overlapCount -= 1;
    let lastScroll = el.scrollLeft = 1;
  
    function scrollHandler() {
      console.log(el)
      const maxScroll = el.scrollWidth - el.clientWidth - 50;
      const overlapOverflow =
        (el.children[overlapCount].offsetLeft - el.offsetLeft)
        - elWidth
        + el.children[overlapCount].getClientRects()[0].width;
        console.log(el.scrollLeft + ">=" + maxScroll)
        console.log(el.scrollLeft + ">" + lastScroll)
      if (el.scrollLeft >= maxScroll && el.scrollLeft > lastScroll) {
        el.scrollLeft = overlapOverflow;
      } else if (el.scrollLeft <= 0 && el.scrollLeft < lastScroll) {
        el.scrollLeft = maxScroll - overlapOverflow;
      }
      lastScroll = el.scrollLeft;
    }
  
    function touchHandler(e) {
      // Handle touch events here
      // You might want to use e.touches for touch details
      scrollHandler(); // Calling the same logic as in scrollHandler
    }
  
    // Attach event listeners
    el.addEventListener('scroll', scrollHandler);
    el.addEventListener('touchmove', touchHandler); // Added for touch support
  
    return function () {
      const scroll = el.scrollLeft;
      clones.forEach((clone) => clone.remove());
      el.scrollTo({ auto: scroll });
      el.removeEventListener('scroll', scrollHandler);
      el.removeEventListener('touchmove', touchHandler); // Remove touch event listener
    }
  }
  

  private debounce(func, delay) {
    let debounceTimer;
    return function() {
      const context = this;
      const args = arguments;
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => func.apply(context, args), delay);
    };
  }
  
  private adjustVerticalScroll() {
    setTimeout(() => {
      this.verticalSCrolls.nativeElement.scrollTop = -19;
    }, 0); // Adjust timing based on your requirements
  }
  selectedItem(index) {
    this.indexValue = index
    this.newSliderSCroll.nativeElement.scrollTo({ left: -50, behavior: 'auto' });
    // this.scrollService.scrollToElementById(this.indexValue);
    document.getElementById(this.indexValue).scrollIntoView({
      behavior: 'auto'
      // this.newSliderSCroll.nativeElement.scrollTo({ left: -50, behavior: 'auto' });
    });

  }
   horiZonatalScroll() {
    this.horizontalScrolls.forEach((scrollContainer: ElementRef) => {
      if (scrollContainer.nativeElement instanceof Element) {
        setTimeout(() => this.infiniteXScrolls(scrollContainer.nativeElement), 0);
      }
    });


    // if (this.verticalSCrolls &&  this.verticalSCrolls.nativeElement && this.verticalSCrolls.nativeElement.scrollTop > 10) {
    //   // Adjust horizontal scroll container here

    // }
  }


  trackBySnapshot(index: number, item: any): any {
    return item.id; // Replace 'id' with a unique identifier for your items
  }


  newscroll(){
    this.horizontalScrolls.forEach((scrollContainer: ElementRef) => {
      // if (scrollContainer.nativeElement.scrollLeft = 0) {
      //   console.log('hi',scrollContainer.nativeElement.scrollLeft)
      // }
    });
  }

  
  onVerticalScroll(): void {
    this.horizontalScrolls.forEach((scrollContainer: ElementRef) => {
      if (scrollContainer.nativeElement instanceof Element) {
        scrollContainer.nativeElement.scrollLeft = 0;
      }
    });
    console.log('verticalSCrolls',this.verticalSCrolls.nativeElement.scrollTop)
    // this.verticalSCrolls.nativeElement.scrollTop = 0
  }
 


  moreButtonForNext() {
    this.next = this.next + 1
    let reqInputObject = { pageNumber: this.next }
    this.commonService.yologramPublicSnapshot(reqInputObject).subscribe(async (response: any) => {
      this.SnapshotDetails.push(...response.object);
      this.SnapshotDetails.forEach(async (snap: any, index) => {
        let UserProfileObject = this.userData.find((user) => user.UserID == snap.UserID)
        let userObjet = this.allUserDetails.find(userObject => userObject.uid == snap.UserID)
        snap.profileName = UserProfileObject.DisplayName != undefined ? UserProfileObject.DisplayName : (userObjet.displayName != undefined ? userObjet.displayName : snap.UserID);
        userObjet.profileName = snap.profileName;
        snap.userData = userObjet
        snap.firstName = snap.profileName.split(" ", 1);
        snap.AvatarLastFive = snap.AvatarID.substr(snap.AvatarID.length - 5);
      });
      if (response.object.length > 0) {
        this.stroyLodaingFlag = false
        this.alertFlag = true
        this.successMsgs = "story loaded successfully";
        this.storyIdByindex = response.object[0]
        let id = this.storyIdByindex.StoryID
        setTimeout(() => {
          this.scrollService.scrollToElementById(id);
        },
          110);
        setTimeout(() => {
          this.homeclick()
        },
          500);
        setTimeout(() => {
          this.alertFlag = false
        },
          2000);
      } else {
        this.stroyLodaingFlag = true
        this.successMsgs = "no more story available";
        this.alertFlag = true
        setTimeout(() => {
          this.alertFlag = false
        },
          2000);
      }
      // this.openModleForToast(this.stroyLodaingFlag)

    })


  }
  homeclick() {
    this.horizontalScrolls.forEach((scrollContainer: ElementRef) => {
      if (scrollContainer.nativeElement instanceof Element) {
        scrollContainer.nativeElement.scrollLeft = -300;
      }
    });
  }

  showPintrestIcon(clothId: any) {
    let flag = false;
    let grandmentObject = this.garmentsCollection.find((b: any) => b.id === clothId);
    let brandBoardObject;
    if (grandmentObject != undefined && grandmentObject.BrandID != undefined) {
      brandBoardObject = this.brandboardCollection.find((b: any) => b.brandId == grandmentObject.BrandID);
      //let show = './assets/Pinterest-logo.png'
    }
    if (brandBoardObject != undefined) {
      flag = true;
    }
    return flag;
  }

  shareOnPinterest(garmentId: any, type: any, snapshotFileUrl: any, storyID: any) {
    setInterval(() => {
      this.loading = false;
    }, 9000);
    this.loading = true;
    let brandId: any;
    let boardId: any;
    if (this.garmentsCollection.length > 0) {
      let grandmentObject = this.garmentsCollection.find((b: any) => b.id === garmentId);
      if (grandmentObject && grandmentObject['Name'] != undefined) {
        brandId = grandmentObject["BrandID"];
        let brandBoardObject = this.brandboardCollection.find((b: any) => b.brandId == brandId);
        boardId = brandBoardObject && brandBoardObject['boardId'] != undefined ? brandBoardObject['boardId'] : undefined;
      }
    }
    if (boardId == undefined || brandId == '') {
      this.loading = false;
      alert("This brand is not linked with Pinterest board.")
      return true;
    }

    let jsonObject = {
      'avatarURL': snapshotFileUrl,
      'boardId': boardId
    }
    let jsonInput = this.pinterstJsonMap
    let storyObject = this.storyPinterestPinsList.find((b: any) => b.StoryID == storyID);
    if (storyObject && storyObject[type] != undefined) {
      let jsonInpt = { 'pinId': storyObject[type], };
      this.pinterestAPI.getPinterestPin(jsonInpt).subscribe((data) => {
        this.displayProgressSpinner = false;
        this.pinImg = data.media.images['400x300'].url;
        this.openModleForPublishedPin();
        this.loading = false;
      });
      return true
    }
    this.pinterestAPI.createPinterestPin(jsonObject).subscribe((result) => {
      if (storyObject == undefined) {
        let pinsMappingObject: any = {
          'StoryID': storyID,
        }
        if (type == 'headwear') pinsMappingObject['headwear'] = result.id
        if (type == 'footwear') pinsMappingObject['footwear'] = result.id
        if (type == 'upperwear') pinsMappingObject['upperwear'] = result.id
        if (type == 'headwear') pinsMappingObject['headwear'] = result.id
        this.firestore.collection('pinterest-board-pins-status').add(pinsMappingObject)
      } else if (storyObject && storyObject[type] == undefined) {


        if (type == 'headwear') storyObject['headwear'] = result.id
        if (type == 'footwear') storyObject['footwear'] = result.id
        if (type == 'upperwear') storyObject['upperwear'] = result.id
        if (type == 'headwear') storyObject['headwear'] = result.id
        this.firestore.collection('pinterest-board-pins-status').doc(storyObject['id']).update(storyObject);
      }

      this.loading = false;
      alert("Pin published successfully.");
      return true;
    })
    return false;
  }
  openModleForPublishedPin() {
    throw new Error('Method not implemented.');
  }


  getGarmentrealtype(garmentId: string) {
    let garmentTitle = "";
    let name = garmentId;
    let brandName = ""
    if (this.garmentsCollection.length > 0) {
      let grandmentObject = this.garmentsCollection.find((b: any) => b.id === garmentId);
      if (grandmentObject && grandmentObject['RealType'] != undefined) {
        garmentTitle = grandmentObject['RealType'];
      }
    }
    return garmentTitle;
  }

  getBrandImageByGarmetnID(garmentId: string) {
    let imgURL = this.getBrandLogoURL(garmentId);
    let brandEncodedurl = encodeURI(imgURL).replace('/', '%2F');
    let url = "https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/" + brandEncodedurl + "?alt=media&token=550e7e88-bed3-4668-84ff-ad491c19e06c"
    return url;
  }
  scrollToGarmentId(snapShot, id, garmenttype) {
    this.onScroll(snapShot)
    this.scrollSnap = snapShot
    this.selectedGarmentType = garmenttype
    const selectedValue = garmenttype;
    setTimeout(() => {
      this.horizontalScrolls.forEach((scrollContainer: ElementRef) => {
        if (scrollContainer.nativeElement instanceof Element) {
          this.selectedIndex = this.clothIndexing.indexOf(selectedValue);
          scrollContainer.nativeElement.scrollLeft = 300 + (this.selectedIndex * 300);
          console.log( 'scrollContainer',scrollContainer.nativeElement.scrollLeft)
        }
      });
    }, 0);
  }





  getBrandLogoURL(garmentId: string) {
    let garmentTitle = "";
    let name = garmentId;
    let brandlogoUrl = ""
    if (this.garmentsCollection.length > 0) {
      let grandmentObject = this.garmentsCollection.find((b: any) => b.id === garmentId);
      if (grandmentObject && grandmentObject['BrandID'] != undefined) {
        let brandId = grandmentObject["BrandID"];
        let brandObject = this.brandsCollection.find((b: any) => b.id === brandId);
        brandlogoUrl = brandObject != undefined && brandObject.LogoURL != undefined ? brandObject.LogoURL : '';
      }
    }
    return brandlogoUrl;
  }
  openBrandPoup(garmentId: any) {
    let grandmentObject = this.garmentsCollection.find((b: any) => b.id === garmentId);
    const modalRef = this.modal.open(BrandPopupComponent, {
      modalDialogClass: "model-garmentPopup"
    });
    modalRef.componentInstance.BrandId = grandmentObject.BrandID;
    modalRef.componentInstance.garmentId = garmentId;
    modalRef.componentInstance.routeFlageBrandPage = this.brandPageLink;
    modalRef.result.then((result) => {
    }).catch((error) => {
      console.log(error);
    });
  }



  openGarmentPopup(fileType: any, key: any) {
    const modalRef = this.modal.open(GarmentPopupComponent, {
      modalDialogClass: "model-garmentPopupClass"
    });
    modalRef.componentInstance.id = 10;
    modalRef.componentInstance.key = fileType;
    modalRef.componentInstance.garmentId = key;
    modalRef.componentInstance.routeFlageEvenGarment = this.envirmentPageLink;
    modalRef.result.then((result) => {
      console.log(result);
    }).catch((error) => {
      console.log(error);
    });
  }

  getGarmentName(garmentId: string) {
    let garmentTitle = "";
    let name = garmentId;
    let brandName = ""
    if (this.garmentsCollection.length > 0) {
      let grandmentObject = this.garmentsCollection.find((b: any) => b.id === garmentId);
      if (grandmentObject && grandmentObject['Name'] != undefined) {
        garmentTitle = grandmentObject['Name'];
      }
    }
    return garmentTitle;
  }

  setBrandDetails(garmentId: string) {
    if (this.garmentsCollection.length > 0) {
      let grandmentObject = this.garmentsCollection.find((b: any) => b.id === garmentId);
      let brandGarments = this.garmentsCollection.filter((gaementList) => gaementList.BrandID === grandmentObject.BrandID);
      this.brandCollectionIdcollection = brandGarments.map((garment) => garment.id);
      this.brandhShapShotsList = this.SnapshotDetails.filter((snapshot) => {
        return this.brandCollectionIdcollection.indexOf(snapshot.BottomwearID.trim()) >= 0 ||
          this.brandCollectionIdcollection.indexOf(snapshot.HeadwearID.trim()) >= 0 ||
          this.brandCollectionIdcollection.indexOf(snapshot.FootwearID.trim()) >= 0 ||
          this.brandCollectionIdcollection.indexOf(snapshot.UpperwearID.trim()) >= 0
      });
      if (grandmentObject && grandmentObject['Name'] != undefined) {
        let brandId = grandmentObject["BrandID"];
        this.brandDetails = this.brandsCollection.find((b: any) => b.id === brandId);
        this.brandDetails['boardName'] = 'Not Link to Board';
        let brandBoardObject = this.brandboardCollection.find((b: any) => b.brandId == brandId);
        if (brandBoardObject != undefined) {
          let boardId = brandBoardObject && brandBoardObject['boardId'] != undefined ? brandBoardObject['boardId'] : undefined;
          let boardDetails = this.boards.find((b: any) => b.id == boardId);
          this.brandDetails['boardName'] = boardDetails['name'];
          if (boardDetails != undefined && boardDetails['name'] != undefined) this.brandDetails['boardName'] = boardDetails['name'];
        }
      }
    }
    // this.openBrandPoup(garmentId)
  }
  getPinterestBoards() {
    this.pinterestAPI.getBoardList().subscribe((data: {
      name: any; items: any;
    }) => {
      this.boards = data.items
    });
  }

  getEnvironmentName(environment) {
    if (this.evnFileList == undefined) return
    let evenListName = "Environments/"
    let env = this.evnFileList.find((snap) => snap.storageURL == evenListName + environment)
    return env != undefined ? env['displayName'] : 'default';
  }


  openUserPopup(SnapShotObject: any) {
    const modalRef = this.modal.open(UserPopupComponent, {
      modalDialogClass: "model-garmentPopup"
    });
    modalRef.componentInstance.id = 10;
    modalRef.componentInstance.UserShapShotObject = SnapShotObject;
    modalRef.componentInstance.userData = SnapShotObject.userData;
    modalRef.componentInstance.UserID = SnapShotObject.UserID;
    modalRef.componentInstance.rootFlag = this.useFeedPagelink;

    modalRef.result.then((result) => {
      console.log("Console resule", result);
    }).catch((error) => {
      console.log("Console error", error);
    });
  }

  openAvatarPopUp(SnapShotObject: any, AvatarID: any) {
    const modalRef = this.modal.open(UserPopupComponent, {
      modalDialogClass: "model-garmentPopup"
    });
    modalRef.componentInstance.id = 10;
    modalRef.componentInstance.AvatarID = AvatarID;
    modalRef.componentInstance.UserShapShotObject = SnapShotObject;
    modalRef.componentInstance.userData = SnapShotObject.userData;
    modalRef.componentInstance.rootFlag = this.useFeedPagelink;
    modalRef.componentInstance.UserID = SnapShotObject.UserID;
    modalRef.result.then((result) => {
      console.log(result);
    }).catch((error) => {
      console.log(error);
    });
  }
  environmentPopup(environment) {
    let reqInputObject = { id: environment, pageNumber: 1 }
    this.commonService.yologramSnapshotEnvironment(reqInputObject).subscribe(async (evn: any) => {
      let environmentSnapAllfileAll = evn.object;
      this.dataSharingService.sendUserEnvSnapShots(environmentSnapAllfileAll);
    })
    let evenListName = "Environments/"
    this.evnFileListFileName = this.evnFileList.find((snap) => snap.storageURL == evenListName + environment)
    let environmentSnapAllfile = this.SnapshotDetails.filter((snap) => snap.Environment === environment).slice(0, 3)
    this.filterEnvironmentSnpShot = environmentSnapAllfile
    this.evnFileListFileName['envFileName'] = environment
    let evnUrl = evenListName + environment
    this.urlEvn = environment
    this.gltfImgSrc = 'https://firebasestorage.googleapis.com/v0/b/yolouploader.appspot.com/o/userData%2Ffiles%2Fcrystal_ball.glb?alt=media&token=963e0d80-5809-4e1b-9034-caf31fb33945'
    this.environmentFileName = environment.Environment
    this.openModelevn(evnUrl, this.environmentFileName)
  }

  openModelevn(skyBoXsrc: any, eVnName: any) {
    let evnEncodedurl = encodeURI(skyBoXsrc).replace('/', '%2F');
    let evNurl = "https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/" + evnEncodedurl + "?alt=media&token=db5ebcc3-36d0-4651-9250-471d6faf6baf"
    this.twoDprivew = evNurl
    const dialogRef = this.dialog.open(PopupThreeDComponent, {
      panelClass: 'dailog-padding-css'
    });
    this.gltfImgSrc = 'https://firebasestorage.googleapis.com/v0/b/yolouploader.appspot.com/o/userData%2Ffiles%2Fcrystal_ball.glb?alt=media&token=963e0d80-5809-4e1b-9034-caf31fb33945'
    this.skyBoXall = this.twoDprivew
    this.evsname = this.evnFileListFileName['envFileName']
    this.evsdiplaynameALL = this.evnFileListFileName['displayName']
    dialogRef.componentInstance.evsdiplaynameALL = this.evsdiplaynameALL
    dialogRef.componentInstance.evsname = this.evsname
    dialogRef.componentInstance.gltfImgSrc = this.gltfImgSrc;
    dialogRef.componentInstance.skyBoXall = this.skyBoXall;
    dialogRef.componentInstance.routeFlageEven = this.envirmentPageLink;
    dialogRef.componentInstance.hideFlag = this.hideFlag;
    dialogRef.componentInstance.environmentFileName = eVnName;
    dialogRef.afterClosed().subscribe(result => {
    });
  }



  openNews3DPassValue(envUrl, AvatarUrl, snap, upperwear, bottomWear, footWear, headwear) {
    let Garments = this.garmentsCollection.find((gaementList) => gaementList.id === (snap.HeadwearID || snap.UpperwearID || snap.BottomwearID || snap.FootwearID));
    this.envUrl = envUrl
    this.avatraUrls = AvatarUrl
    this.garmenturl = upperwear
    this.garmentbottom = bottomWear
    this.genders = Garments.Gender;
    this.headgear = headwear;
    this.footwear = footWear
    // this.threeDopen = true

    this.passId = snap.id
    console.log()
    this.threeDopen = !this.threeDopen
    document.getElementById('loadingScreen')
  }
  closeThreeDBUTTON() {
    this.threeDopen = !this.threeDopen
  }
  copyTextToClipAvtar(user, avatar) {
    this.avtarUrl = 'https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/AvatarBlenderSaves%2F' + user + '%2F' + avatar + '?alt=media&token=20b1dc1e-681b-4d96-a342-7a4407fab4e0&_gl=1*10jumsf*_ga*MjExMjI5MTIyMi4xNjg3NTA3MDk1*_ga_CW55HF8NVT*MTY5NzUyMjU5NC4xNzAuMS4xNjk3NTIyNjE1LjM5LjAuMA..'
    return this.avtarUrl
  }

  copyTextToClipGarment(text: string) {
    let Garments = this.garmentsCollection.find((garmentList) => garmentList.id === text);
    if (Garments) {
      let brandId = Garments.BrandID;
      let gender = Garments.Gender;
      this.GarmentsGender = Garments.Gender;
      let garmentType = Garments.GarmentType;
      let zipfilename = Garments.id;
      // alert(zipfilename)
      let packlocation = "AvatarBlenderClothes%2F" + brandId + "_" + gender + "%2F" + garmentType + "%2F" + zipfilename;
      this.garmentLink = 'https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/' + packlocation + '?alt=media&token=2c1dc261-ac91-4c32-a5ec-ac5e06f0a9bc&_gl=1*goch74*_ga*MjExMjI9MTIyMi4xNjg3NTA3MDk1*_ga_CW55HF8NVT*MTY5NjkzMDY1Ny4xNTcuMS4xNjk2OTM2NzA4LjU5LjAuMA..';
      return this.garmentLink;
    } else {
      // Handle the case when Garments is not found
      // console.error('Garments not found for id: ' + text);
      return null; // or return an appropriate value or throw an error
    }
  }
  copyTextToClipEven(snap) {
    let evnFileName = snap
    this.evnLink = 'https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/Environments%2F' + evnFileName + '?alt=media&token=db5ebcc3-36d0-4651-9250-471d6faf6baf'
    return this.evnLink
  }

  horizontalonScroll(): void {
    const container = this.scrollContainer.nativeElement;
    if (container!.scrollLeft + container!.offsetWidth >= container!.scrollWidth - 100) {
      this.loadContent();
    }
  }

  private loadContent(): void {
    const newItems = Array.from({ length: 10 }, (_, i) => `Item ${this.items.length + i + 1}`);
    this.items.push(...newItems);
  }
  


  private logScrollOffset(element: HTMLElement): void {
    console.log(`Element scrollLeft: ${element.scrollLeft}, scrollTop: ${element.scrollTop}`);
  }

}


