<div class="card">
  Total Uploaded Files : {{userUploadedAvatars.length}}
  <!-- <div class="card-header font-bold">List of Files</div> -->
  <div class="dt-responsive selectlist font-14">
    <div class="dataTables_wrapper dt-bootstrap4" id="add-row-table_wrapper">
      <div *ngIf="userUploadedAvatars && userUploadedAvatars?.length == 0 || !userUploadedAvatars" class="p-3">No file
        found.</div>
      <div class="col-sm-12 p-0" *ngIf="userUploadedAvatars != undefined && userUploadedAvatars.length > 0">
        <table class="table " id="dataTable" width="100%" cellspacing="0" >
          <thead class="custom-header">
            <tr role="row">
             
              <th>File Name</th>
              <th>File Type</th>
              <th>Date</th>
              <th>View</th>

            </tr>
          </thead>
          <tbody>

            <tr class="row-list-detail" *ngFor="let list of userUploadedAvatars; let i = index">
              <td class="">
                <span class="user-info word-break text-underline-offset: 3px;">{{list.filename}}</span>
              </td>
              <td class="">
                <span class="user-info text-underline-offset: 3px;">{{list.fileType}}</span>
              </td>
              <td class="">
                <span class="user-info">{{list.date}}</span>
              </td>
             
              <td class="catalog-info ">
                <span *ngIf="list.fileType == 'ClothSource'">
                  <a  (click)="openModel(list.fileurl)"> <ins>View</ins></a>
                </span>
                <span *ngIf="list.fileType == 'GarmentUpload3D'">
                  <a  (click)="openModel(list.fileurl)"> <ins>View</ins></a>
                </span>
                
                <span *ngIf="list.fileType == 'GarmentUpload2D'">
                  <a  href="{{list.fileurl}}" target="_blank"><ins class="text-dark">View</ins></a>
                </span>
                     
                <span *ngIf="list.fileType == 'Environment'">
                  <a  href="{{list.fileurl}}" target="_blank"><ins class="text-dark">View</ins></a>
                </span>
              </td>
              <!-- <td>
                <button type="button" (click)="deleteUserfiles(list.id,list.fileType)"   class="btn btn-labeled btn-danger">
                  <span class=""></span> <i class="bi bi-trash"></i></button>
            </td> -->
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
