
<!-- <div (scroll)="scrollHandler()" #userFeeds class="roksan">
    <div class="col-lg-12  page-scroll sticky-band" *ngIf="pageScroll > 30">
        <span class="d-flex justify-content-center band">
            <div class=" col-lg-12">
                <div class="static-band " style="margin-left: 0px  !important;">
                    <div class="d-flex m-1 profile-popup text-white" data-bs-dismiss="modal">
                        <img *ngIf="userProfileData.ProfilePicture != undefined" class="img-thumbnail rounded-circle"
                            style="
                              width: 60px !important;
                              height: 60px !important;
                              margin-top: -53px;
                          " [src]="userProfileData.ProfilePicture">
                        <img *ngIf="userProfileData.ProfilePicture == undefined" class="img-thumbnail rounded-circle"
                            style="
                              width: 60px !important;
                              height: 60px !important;
                              margin-top: -53px;
                          " [src]="userData.ProfilePicture">
                    </div>
            
                    <div class="w-100 col-12 d-flex">
                        <div class="col-9 text-start" (click)="getSnapShotListByUserID()"> 
                            <h5 *ngIf="userProfileData.DisplayName == undefined" class="m-1 text-start text-black profile-name"
                                (click)="getSnapShotListByUserID()">{{userData.profileName}}</h5>
                            <h5 *ngIf="userProfileData.DisplayName != undefined" class="m-1 text-black profile-name" (click)="getSnapShotListByUserID()">
                                {{userProfileData.DisplayName}}
                            </h5>
                        </div>
                        <div class="col-3 "><button type="button" style="font-size: 10px;" class="btn btn-outline-info ">Follow</button>
                        </div>
                    </div>
                    <div class="text-start" style="height: 25px; width: 100%;">
                        <h6 *ngIf="this.avatarID != undefined && this.userId != undefined" class="avatr  text-start ">🧍🏽{{avatarID}}
                        </h6>
                    </div>
                    <div>
                        <div class="static-band-five-icon col-4 text-center d-flex justify-content-center">
                            <div class="brandbtn">
                                <h6 class="m-1" (click)="selectSummeryView('Story')"
                                    [ngClass]="secteTab == 'Story'   ? 'story-menu-card' : 'story-menu-card-not-selected'">
                                    <a>
                                        <img *ngIf="secteTab  == 'Story'" style="width:15px !important"
                                            src="  ./assets/storystyle/story-white.png">
                                        <img *ngIf="secteTab  != 'Story'" style="width:15px !important"
                                            src=" ./assets/storystyle/storyblack.png">


                                    </a>
                                </h6>
                                <h6 *ngIf="secteTab  == 'Story'" class="m-1 font-class">Story</h6>
                            </div>
                            <div class="brandbtn" (click)="selectTab('Brands')">
                                <h6 class="m-1"
                                    [ngClass]="secteTab == 'Brands'   ? 'story-menu-card' : 'story-menu-card-not-selected'">
                                    <a>
                                        <img *ngIf="secteTab  == 'Brands'" style="width:15px !important"
                                            src="./assets/brand_white.png">
                                        <img *ngIf="secteTab  != 'Brands'" style="width:15px !important"
                                            src="./assets/brand_black.png">
                                    </a>
                                </h6>
                                <h6 *ngIf="secteTab  == 'Brands'" class="m-1 font-class">Brands </h6>
                            </div>
                            <div class="brandbtn" (click)="selectTab('Style')">
                                <h6 class="m-1 "
                                    [ngClass]="secteTab == 'Style'   ? 'story-menu-card' : 'story-menu-card-not-selected'">
                                    <a>
                                        <img *ngIf="secteTab  == 'Style'" style="width:15px !important"
                                            src="./assets/t_shirt_white.png">
                                        <img *ngIf="secteTab  != 'Style'" style="width:15px !important"
                                            src="./assets/t_shirt_black.png">
                                    </a>
                                </h6>
                                <h6 *ngIf="secteTab  == 'Style'"  class="m-1 font-class">Style</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </span>
    </div>
    <div class="brand-profile-color">
        <div class="col-lg-12   m-3 justify-content-center text-center ">

            <div *ngIf="snaploader2D" class="text-center">
                <div class="spinner-border m-2 p-2" role="status">
                    <span class="sr-only"></span>
                </div>
            </div>
            <div class="band d-block">
                <div class="static-band" *ngIf=" pageScroll == undefined ||  pageScroll < 30    ">
                    <div class="d-flex m-1 profile-popup text-white" data-bs-dismiss="modal">
                        <img *ngIf="userProfileData.ProfilePicture != undefined" class="img-thumbnail rounded-circle"
                            style="
                      width: 60px !important;
                      height: 60px !important;
                      margin-top: -53px;
                  " src={{userProfileData.ProfilePicture}}>
                        <img *ngIf="userProfileData.ProfilePicture == undefined" class="img-thumbnail rounded-circle"
                            style="
                      width: 60px !important;
                      height: 60px !important;
                      margin-top: -53px;
                  " src={{userProfileData.photoURL}}>
                    </div>

                    <div class="w-100 col-12 d-flex">
                        <div class="col-9 text-start">
                            <h5 *ngIf="userProfileData.DisplayName == undefined" class="m-1 text-start text-black"
                                (click)="getSnapShotListByUserID()">{{userData.profileName}}</h5>
                            <h5 *ngIf="userProfileData.DisplayName != undefined" class="m-1 text-black" (click)="getSnapShotListByUserID()">
                                {{userProfileData.DisplayName}}
                            </h5>
                        </div>
                        <div class="col-3 "><button type="button" style="font-size: 10px;" class="btn btn-outline-info ">Follow</button>
                        </div>
                    </div>
                    <div class="text-start" style="height: 25px; width: 100%;">
                        <h6 *ngIf="this.avatarID != undefined && this.userId != undefined" class="avatr  text-start ">🧍🏽{{avatarID}}
                        </h6>
                    </div>

                    <div>
                        <div class="static-band-five-icon col-4 text-center d-flex justify-content-center">
                            <div class="brandbtn">
                                <h6 class="m-1   " (click)="selectSummeryView('Story')"
                                    [ngClass]="secteTab == 'Story'   ? 'story-menu-card' : 'story-menu-card-not-selected'">
                                
                                            <a> <img *ngIf="secteTab  == 'Story'" style="width:15px !important"
                                                src="  ./assets/storystyle/story-white.png">
                                            <img *ngIf="secteTab  != 'Story'" style="width:15px !important"
                                                src=" ./assets/storystyle/storyblack.png"></a>



                                </h6>
                                <h6 *ngIf="secteTab  == 'Story'" class="m-1 font-class">Story</h6>
                            </div>
                            <div class="brandbtn" (click)="selectTab('Brands')">
                                <h6 class="m-1 "
                                    [ngClass]="secteTab == 'Brands'   ? 'story-menu-card' : 'story-menu-card-not-selected'">
                                    <a>
                                        <img *ngIf="secteTab  == 'Brands'" style="width:15px !important"
                                        src="./assets/brand_white.png">
                                    <img *ngIf="secteTab  != 'Brands'" style="width:15px !important"
                                        src="./assets/brand_black.png">                                    </a>
                                </h6>
                                <h6 *ngIf="secteTab  == 'Brands'" class="m-1 font-class">Brands </h6>
                            </div>
                            <div class="brandbtn">
                                <h6 class="m-1  " (click)="selectTab('Style')"
                                    [ngClass]="secteTab == 'Style'   ? 'story-menu-card' : 'story-menu-card-not-selected'">
                                    <a>
                                        
                                        <img *ngIf="secteTab  == 'Style'" style="width:15px !important"
                                        src="./assets/t_shirt_white.png">
                                    <img *ngIf="secteTab  != 'Style'" style="width:15px !important"
                                        src="./assets/t_shirt_black.png">                                    
                                    </a>
                                </h6>


                                <h6 *ngIf="secteTab  == 'Style'" class="m-1 font-class ">Style</h6>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="justify-content-center story-center d-flex">
                    <h6 class="m-2 text-primary  color-class">
                        <div *ngIf="!snaploader2D" class="row d-flex twoimages">
                            <div *ngIf="userFiledetails.length == 0 "
                                class="text-center col-12 justify-content-center twoimages">
                                <app-slider-two-d [images]="images"></app-slider-two-d>
                            </div>
                            <div *ngIf="userFiledetails.length != 0 "
                                class="text-center col-12 justify-content-center twoimages">
                                <app-slider-two-d [images]="userFiledetails" [UserId]="userId"></app-slider-two-d>
                            </div>
                        </div>
                    </h6>
                </div>
            </div>
    
            <div *ngIf="snaploader" class="text-center">
                <div class="spinner-border m-5 p-5" role="status">
                    <span class="sr-only"></span>
                </div>
            </div>
        </div>
        <div *ngIf="selectedTab  == 'user-story'" class="story-slass brand-profile-color">
            <app-user-story [userSnapShotsInput]="filterSnap" [UserId]="userId" [AvatrId]="avatarID"
                [StoryIds]="selectedStoryId" [PageCount]="pagecount" [pageNumber]="pageNumber"
                (outPageNumber)="setPageNumber($event)" [rootFlag]="rootForNAvigation" 
                [brandPageFlag]="brandFlag"
                >
            </app-user-story>
        </div>
        <div *ngIf="selectedTab  == 'story'"
            class="row m-2 height-avatr-id  brand-profile-color justify-content-center story mb-5 my-story ">
            <div class="column d-block" *ngFor="let snapshot of filterSnap ; let i=index;">
                <img class="w-90 shadow-1-strong rounded m-2 " (click)="scrollToStory(snapshot)"
                    [src]="snapshot.SnapshotFileUrl">
            </div>
            <div *ngIf="filterSnap.length >= 12">
                <div class="col-12 text-center justify-content-center morebtn"
                    [ngClass]="{'hide': this.avatarID != undefined }">
                    <h6 (click)="nextButton(counter)"><b>More</b></h6>
                </div>
                <div class="col-12 text-center justify-content-center morebtn"
                    [ngClass]="{'hide': this.avatarID == undefined }">
                    <h6 (click)="nextButtonForAvatar(counter)"><b>More</b></h6>
                </div>
            </div>
        </div>
    </div>
</div> -->


<app-news-user [routLogInFlag]="routLogInFlag"
 [brandPageFlag]="brandFlag" [rootFlag]="rootForNAvigation" ></app-news-user>