<app-yologram-social *ngIf="logInFlag"></app-yologram-social>
<app-public *ngIf="!logInFlag"></app-public>
<div>
    <button class="btn btn-primary" style=" z-index: 100000; position: fixed; top: 20px; right: 0;" (click)="toggle()">
        <i class="bi bi-arrow-right-circle" *ngIf="isShowStats"></i>
        <i class="bi bi-arrow-left-circle" *ngIf="!isShowStats"></i>
    </button>

    <div class="fixed-container">
        <div (click)="closeButtonForBrand()" (scroll)="onScroll()" #brandStoriyScroll class="div-margin roksan box1"
            [ngClass]="this.changeTabFlag ? 'blur-side' : ''">
            <div class="brand-profile-color" *ngIf="portpfolioName == undefined">
                <div #brandStoriyScrollA [ngClass]="scrollFlag ? 'd-none' : ''" class="slider-css brand-profile-color"
                    (scroll)="selectByScroll()">
                    <div class="" *ngFor="let brand of filterBrandList"
                        [ngClass]="byScroll != true && !portfolioFLAGnew  ? 'prt-Evt' :''">
                        <div *ngIf="selectedBrand == brand ">
                            <div
                                [ngClass]="selectedBrand == brand ? 'shadow-brand-slide' : (selectedBrand != undefined && !byScroll ? 'd-none' :'')">
                                <div class="text-end me-1 mt-1" style="pointer-events: auto;">
                                    <div>
                                        <img (click)="clickOnHeart()" *ngIf="brandheartFlag" class="heart"
                                            style="width:18px !important; color:#000000"
                                            src="./assets/storystyle/newStoryIcon/heart.png">
                                    </div>
                                    <div><img (click)="clickOnHeart()" *ngIf="!brandheartFlag" class="heart"
                                            style="width:18px !important; color:black"
                                            src="./assets/storystyle/newStoryIcon/heartGray.png">
                                    </div>
                                    <div class="second-share"><img (click)="shareBrand(brand.id)"
                                            style="width:20px !important;" src="./assets/share_copy.png"></div>
                                </div>
                            </div>
                        </div>

                        <img *ngIf="this.exclosiveMode == 'N' || this.exclosiveMode == null " [id]="brand.id"
                            class="m-1 " style="height:60px !important; width:80px !important; background: white;"
                            [ngClass]="selectedBrand == brand ? 'shadow-brand' : (selectedBrand != undefined && !byScroll ? 'blug-css' :'')"
                            (click)="selectBrandForStory(brand.id,brand)" [src]="brand.Brandurl" />

                        <img *ngIf="this.exclosiveMode == 'Y'" [id]="brand.id" class="m-1"
                            style="height:60px !important; width:80px !important; background: white;"
                            [ngClass]="selectedBrand == brand ? '' : 'd-none'"
                            (click)="selectBrandForStory(brand.id,brand)" [src]="brand.Brandurl" />

                    </div>

                    <div *ngIf="loading" class="loading">Loading&#8230;</div>
                </div>

                <div [ngClass]="!scrollFlag ? 'd-none' : ''" class="slider-css brand-profile-color">
                    <div class="" *ngFor="let brand of filterBrandListNew" [ngClass]="byScroll != true ? 'prt-Evt' :''">
                        <img [id]="brand.id" class="m-1" style="height:60px !important; width:80px !important; background: white;"
                            [ngClass]="selectedBrand == brand ? 'shadow-brand' : (selectedBrand != undefined && !byScroll ? 'blug-css' :'')"
                            (click)="selectBrandBySearch(brand.id,brand)" [src]="brand.Brandurl" />
                    </div>
                </div>



                <div class="input-wrapper">
                    <input [disabled]="exclosiveMode == 'Y'" id="BrandName" type="text"
                        class="form-control custom-input no-rounded" (input)="suggest($event)"
                        (click)="blankplaceHolder()">
                    <label for="BrandName" class="placeholder-start">{{brandNameSelected}}</label>
                    <label for="BrandName" class="placeholder-end">


                        <h6 class="color-class-board m-2 "
                            *ngIf="boardDetails != undefined  && boardDetails.name != undefined">
                            <img style="width:12px !important" src="./assets/pinterest-512.png">:{{boardDetails.name}}
                        </h6>
                        <h6 class="color-class-board m-2"
                            *ngIf="boardDetails == undefined || boardDetails.name == undefined">
                            <img style="width:12px !important" src="./assets/pinterest-512.png">:Not Linked
                        </h6>


                    </label>
                </div>
            </div>

            <div class="brand-profile-color" *ngIf="portpfolioName != undefined">
                <div #brandSliderPortFolio [ngClass]="scrollFlag ? 'd-none' : ''" class="slider-css brand-profile-color"
                    (scroll)="selectByScroll()">
                    <div (click)="portfolioBrandSelection()" class="" *ngFor="let brand of filterBrandList"
                        [ngClass]="byScroll != true  ? 'prt-Evt' :''">
                        <div *ngIf="selectedBrand == brand ">
                            <div
                                [ngClass]="selectedBrand == brand ? 'shadow-brand-slide' : (selectedBrand != undefined && !byScroll ? 'd-none' :'')">
                                <div class="text-end me-1 mt-1" style="pointer-events: auto;">
                                    <div>
                                        <img (click)="clickOnHeart()" *ngIf="brandheartFlag" class="heart"
                                            style="width:18px !important; color:#000000"
                                            src="./assets/storystyle/newStoryIcon/heart.png">
                                    </div>
                                    <div><img (click)="clickOnHeart()" *ngIf="!brandheartFlag" class="heart"
                                            style="width:18px !important; color:black"
                                            src="./assets/storystyle/newStoryIcon/heartGray.png">
                                    </div>
                                    <div class="second-share"><img (click)="shareBrand(brand.id)"
                                            style="width:20px !important;" src="./assets/share_copy.png"></div>
                                </div>
                            </div>
                        </div>
                        <img *ngIf="this.exclosiveMode == 'N' || this.exclosiveMode == null " [id]="brand.id"
                            class="m-1" style="height:60px !important; width:80px !important;"
                            [ngClass]="selectedBrand == brand ? 'shadow-brand' : (selectedBrand != undefined && !byScroll ? 'blug-css' :'')"
                            (click)="selectBrandForStory(brand.id,brand)" [src]="brand.Brandurl" />

                        <img *ngIf="this.exclosiveMode == 'Y'" [id]="brand.id" class="m-1"
                            style="height:60px !important; width:80px !important;"
                            [ngClass]="selectedBrand == brand ? '' : 'd-none'"
                            (click)="selectBrandForStory(brand.id,brand)" [src]="brand.Brandurl" />
                    </div>
                    <div *ngIf="loading" class="loading">Loading&#8230;</div>
                </div>
                <div [ngClass]="!scrollFlag ? 'd-none' : ''" class="slider-css brand-profile-color">
                    <div class="" *ngFor="let brand of filterBrandListNew" [ngClass]="byScroll != true ? 'prt-Evt' :''">
                        <img [id]="brand.id" class="m-1" style="height:60px !important; width:80px !important;"
                            [ngClass]="selectedBrand == brand ? 'shadow-brand' : (selectedBrand != undefined && !byScroll ? 'blug-css' :'')"
                            (click)="selectBrandBySearch(brand.id,brand)" [src]="getBrandImage(brand.LogoURL)" />
                    </div>
                </div>
                <div class="input-wrapper">
                    <input [disabled]="exclosiveMode == 'Y'" id="BrandName" type="text"
                        class="form-control custom-input no-rounded" (input)="suggest($event)"
                        (click)="blankplaceHolder()">
                    <label for="BrandName" class="placeholder-start">{{brandNameSelected}}</label>
                    <label for="BrandName" class="placeholder-end">
                        <h6 class="color-class-board m-2 "
                            *ngIf="boardDetails != undefined  && boardDetails.name != undefined">
                            <img style="width:12px !important" src="./assets/pinterest-512.png">:{{boardDetails.name}}
                        </h6>
                        <h6 class="color-class-board m-2"
                            *ngIf="boardDetails == undefined || boardDetails.name == undefined">
                            <img style="width:12px !important" src="./assets/pinterest-512.png">:Not Linked
                        </h6>
                    </label>
                </div>
            </div>
        </div>
        <!-- <button class="btn btn-primary"(click)="clearThreadsPage()">clear</button> -->

    </div>





    <div class="scrollable-container" *ngIf="clearTab">
        <app-threads-child id="target" [tab]="selectedTab" [onChangeFlag]="onChangeFlag"  (outnewStoryIndeX)="newindex($event)"
            (changeSelectedTab)="changeTab($event)" [brandsIDdetailsAll]="brandsIDdetailsAll" [mode]="mode"
            (changeSelctedCloth)="changeCloth($event)" [selectBrandGender]="genderID || SelectedGender"
            [selectedBrand]="selectedBrand" [brandId]="brandsIDdetailsAll" [pageNumber]="pageNumber"
            [clickEventFlag]="clickEventFlag" [stylePageFlag]="rootFlag" [stylePage]="stylePage"
            [routLogInFlag]="routLogInFlag" [isShowBrandBand]="isShowBrandBand"
            (changeSelctedState)="changeLoadingState($event)" (receivePackData)="receveidPackdata($event)"
            (receivePackZip)="receveidZipList($event)" (changeCloseBanner)="changeClose($event)"
            [BrandSelection]="brandSelectionFlag" [byScroll]="byScroll" [pageTrueFlage]="pageTrueFlage"
            [portFolioFlag]="portFolioFlag" [showBrandFlag]="showBrandFlag" [setTabFlag]="modeInsatnceFlageVlaue"
            [StoryId]="this.storyId" [index]="index" [pageiIndex]="pageiIndex" [pageNumberByDrips]=" this.pageNumberByDrips" [garmentId]="newGarmentId"
            (receiveAvatarPanelDetails)="receveidPanelDetails($event)" [compressUrl]="compressUrl"
            (receiveAvatarbrandPanel)="receveidPanelbrand($event)" (receveidPanelEven)="receveidPanelEven($event)"
            (receveidPanelUser)="receveidPanelUser($event)" (receveidPanelGarment)="receveidPanelGarment($event)" 
            [isShowStats]="isShowStats"

            (opentheSearchPanel)="handleSearchPanlOnThreads($event)"

            [selectedSnapData]="selectedSnapData"

            (receveidSearchData)="receveidSearchData($event)" 
            [panel]="panelFlag" [Drape]="Drape"
            
            [searchFilterData]="nonsSelectedBrand"

            [StoryId]="selectedStory"
            >
        </app-threads-child>
    </div>

</div>

<div class="drips-panel" *ngIf="avatarflagPanel">
    <div *ngIf="this.dripsPageAvatarData">
        <app-avatar-panel (closeAvatarEmitter)="closeAvatarPalen($event)"
            [userData]="dripsPageAvatarData" [closebuttonFlag]="closebuttonFlag">
        </app-avatar-panel>
    </div>
</div>


<div class="drips-panel" *ngIf="userflagPanel">
    <div *ngIf="dripsPageUserData">
        <app-user-panel (closeAvatarEmitter)="closeUserPanel($event)"
            [userData]="dripsPageUserData" [closebuttonFlag]="closebuttonFlag">
        </app-user-panel>
    </div>
</div>


<div class="drips-panel" *ngIf="brandflagPanel">
    <div *ngIf="this.dripsPageBrandData">
        <app-brand-panel (closePanelEmitter)="closeBrandPanel($event)" [brandObjectId]="this.dripsPageAvatarData" [brandObject]="this.dripsPageBrandData" [closebuttonFlag]="closebuttonFlag">
        </app-brand-panel>
    </div>
</div>

<div class="drips-panel" *ngIf="evenpanelFlag">
    <div>
        <app-environment-panel (closeEvenEmitter)="closeEnvironmentPanel($event)"   (evnCompressUrl)="handleevnCompressUrl($event)" [evndata]="evndata" 
         [skyBoXall]="evndata.skyBoXall" [twoDprivew]="evndata.twoDprivew" [evsname]="evndata.evsname"
            [evsdiplaynameALL]="evndata.evsdiplaynameALL" [environmentFileName]="evndata.environmentFileName" [closebuttonFlag]="closebuttonFlag"
            [hideFlag]="evndata.hideFlag"
            [brandStoryScrollerHeight]="evndata.brandStoryScrollerHeight" [evnProfilePageFlag]="evnProfilePageFlag"></app-environment-panel>
    </div>
</div>



<div class="drips-panel" *ngIf="garmentflagPanel">
    <div>
        <app-garment-panel (closeGarmentEmitter)="closeGarmentPanel($event)" [brandObject]="garmetData"
            [closebuttonFlag]="closebuttonFlag">
        </app-garment-panel>
    </div>
</div>

<div class="drips-panel" *ngIf="searchPanel" [style.width.px]="sideNavWidth_Fixed" [ngClass]="{ 'expand': sideNavWidth_Fixed == 380}">

    <!-- <div>
        <div class="menu-arrow text-end">
            <button   (click)="setSearchNavBar()" class="yologram-btn rounded-pill text-white"></button>
        </div>
    </div> -->

    <div [ngClass]="{ 'd-block': searchNavBarFlag, 'd-none':!searchNavBarFlag}">
        <app-search-tag-panel (emitrightPanelFlag)="handleSideNavSize($event)"
            (closePanelEmitter)="closeSearchPanel($event)" (serchedStory)="handleSerchedStory($event)"
            (selectedSnap)="handleSelectedSnap($event)" [exclosiveMode]="exclosiveMode" [snapData]="singleSnap"
            [snapshots]="tempSnapShot" [selectedBrand]="selectedBrand" [page]="pageName" [nonBrand]="nonsSelectedBrand"
            (snapListEmit)="handlesnapListEmit($event)"
            (selectTheStory)="handlesnapSelectedStory($event)"></app-search-tag-panel>
    </div>

</div>


<!-- <div class="loader-overlay" *ngIf="!garmentflagPanel">
    <div class="loader"></div>
</div>  -->
