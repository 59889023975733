import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonService } from 'src/app/services/common.service';
import { DataSharingService } from 'src/app/services/data-sharing-service';

@Component({
  selector: 'app-usermenu-popup',
  templateUrl: './usermenu-popup.component.html',
  styleUrls: ['./usermenu-popup.component.css']
})
export class UsermenuPopupComponent {
  @Input() PageAll: any = [];
  @Input() userSecurityGrp: any = [];
  @Input() filterSnapShotForProfile: any = [];
  @Input() selectedSecurityGroupName:any;
  @Input() securityGroupList:any;
  @Input() selectedSecGrpId:any;
  @Input() id:any;
  @Input() userDetails:any = {};
  @Input() SnapshotListForProfile: any = [];
  @Input() userFiledetails: any = [];
  @Input() images: any = [];


  

  


  

  

 constructor( public router: Router,
  private commonService: CommonService,public activeModal: NgbActiveModal,
  private dataService: DataSharingService
  ) 
{

  console.log("PageAll====>",this.PageAll)
}

  

  

  





getProfileForUSer() {
  let userId = this.id
  let userObjectForProfile = this.SnapshotListForProfile.filter((snapObject) => snapObject.UserID === userId)
  this.filterSnapShotForProfile = userObjectForProfile
  // this.dataSharingService.sendUserSnapShots(userObjectForProfile)
  let id = userId
  let  pagetab =  'story'
  this.id = {UserId : userId, Pagetab :pagetab}
  this.dataService.setNavigationData({
    UserId : userId,
     Pagetab :pagetab
   
  });
  this.router.navigate(['/me'])
  this.closePopUp()

}



  getSecurityGroupNameById(id) {

    let userSecGrp = this.userSecurityGrp.find((b: any) => b.SecGpId === id);
    let securityGroup = this.securityGroupList && this.securityGroupList.find((b: any) => b.id === id);
    this.selectedSecurityGroupName =  userSecGrp != undefined  && securityGroup['name'] != undefined ? securityGroup['name'] : "Select Group";
    return true;
  }

  async handleSignOut() {
    this.router.navigateByUrl("/drips");
    try {
      await this.commonService.signOut();
      this.router.navigateByUrl("/drips");
      // this.email = null;
    } catch (error) {
    }
    this.closePopUp()

  }
  getProfileForUSerFeed(snapshot){
    let userId = this.id
    let userObjectForProfile = this.SnapshotListForProfile.filter((snapObject) => snapObject.UserID === userId)
    this.filterSnapShotForProfile = userObjectForProfile
    // this.dataSharingService.sendUserSnapShots(userObjectForProfile)
    let id = userId
    let  pagetab =  'user-story'
    this.dataService.setNavigationData({
      UserId : userId,
       Pagetab :pagetab
     
    });
    // this.id = {UserId : userId, Pagetab :pagetab, StoryId:snapshot.StoryID}
    this.router.navigate(['/me'])
    this.closePopUp()
    // this.router.navigate(['yologram-social/yologram-user-myprofile', { 'id': userId ,'pagetab' :'user-story','storyId':snapshot.StoryID}])
  }



  
  closePopUp() {
    this.activeModal.close();
  }

  
}
