import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';
import { CommonService } from 'src/app/services/common.service';
import { nextTick } from 'process';

@Component({
  selector: 'app-menu-page',
  templateUrl: './menu-page.component.html',
  styleUrls: ['./menu-page.component.css']
})
export class MenuPageComponent implements OnInit {
  securityGroupCollection = this.firestore.collection('SecurityGroup').valueChanges({ idField: 'id' }) as Observable<[]>;
  pages = this.firestore.collection('Pages').valueChanges({ idField: 'id' }) as Observable<[]>;
  role = this.firestore.collection('GroupRole').valueChanges({ idField: 'id' }) as Observable<[]>;
  // securityGroupUser = this.firestore.collection('SecurityGroup').valueChanges({ idField: 'id' }) as Observable<[]>;
  securityGroupAll: any = {};
  pagesObjects: any = {};
  roleobject: any = {};
  securityGroupList: any = [];
  securityGroupPageList: any = [];
  uid: any;
  userUploadedAvatars: any
  logInFlag: boolean;

  constructor(private router: Router, public commonService: CommonService, private firestore: AngularFirestore) {
    this.securityGroupCollection.subscribe(
      (groupList) => {
        groupList.forEach((group: any) => {
          this.securityGroupAll[group.id] = group.name;
        });
      });

      this.pages.subscribe(
      (pages) => {
        pages.forEach((page: any) => {
          this.pagesObjects[page.id] = {
            "pageAddress": page.PageAddress,
            "pageName": page.PageName,
          };

        });
      });

    this.role.subscribe(
      (role) => {
        role.forEach((role: any) => {
          this.roleobject[role.id] = role.roleobject;
        });
      });

  }

  ngOnInit(): void {
    let title = 'Yologram-Menu-Page'
    this.commonService.changeTitle(title);
    this.logInFlag = this.commonService.isLoggedIn()
    this.commonService.getUser().subscribe(async (user: any) => {
      let userId = user?.uid;
      await this.firestore.collection("GroupUser").ref.where("UserId", "==", userId)
        .get()
        .then((objectList) => {
          objectList.forEach((doc) => {
            this.securityGroupList.push(doc.data());
          });
          if (this.securityGroupList.length == 0) {
            try {
              //this.commonService.signOut();
              //this.router.navigateByUrl("/Login");
              this.router.navigateByUrl('/drip');
            } catch (error) {
            }
          }
        });
    });

  }


  selectActionGroupId($event) {
    this.securityGroupPageList = [];
    let SecGpId = $event.target.value;
    this.firestore.collection("GroupRole").ref.where("SecGpId", "==", SecGpId)
      .get()
      .then((linkPageList) => {
        linkPageList.forEach((doc) => {          
          let pageId = doc.data()['PageId'];
          if (this.pagesObjects[pageId] != undefined) {           
            let pageAddress = this.pagesObjects[pageId]['pageAddress'];
            let pageName = this.pagesObjects[pageId]['pageName']
            let tmpObject = {
              "pageName": pageName,
              "pageAddress": pageAddress
            }
            this.securityGroupPageList.push(tmpObject);
          }
        })
      })

  }

  pageRedirect(pageName) {
    this.router.navigateByUrl(pageName);
  }
}
