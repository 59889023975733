<div
  class="carousel"
  [ngClass]="{ 'carousel-large': dripFlag }"
  #garmentDummySCroll
>
  <div
    class="item"
    [ngClass]="{ 'item-small': !dripFlag, 'item-large': dripFlag }"
    *ngFor="let data of scrollingImages"
  >
    <img
      class="imageBorder-class"
      [ngClass]="{ 'image-small': !dripFlag, 'image-large': dripFlag }"
      *ngIf="!loading && (data.fileurl != undefined || data.fileurlMini != undefined) && data.image == undefined"
      (click)="open2dPoup3(data)"
      [appLazyLoad]="data.fileurlMini"
      [defaultImage]="data.fileurlMini"
    />
    <img
      class="imageBorder-class"
      [ngClass]="{ 'image-small': !dripFlag, 'image-large': dripFlag }"
      *ngIf="!loading && data.fileurl == undefined && data.image != undefined"
      (click)="open2dPoupDummy(data)"
      [appLazyLoad]="data.image"
      [defaultImage]="data?.image"
    />
  </div>
</div>
