import { Component, OnInit } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { Router } from '@angular/router';
import '@google/model-viewer';
import { Observable } from 'rxjs/internal/Observable';
import { CommonService } from 'src/app/services/common.service';

interface Avater {
  id?: string;
}

@Component({
  selector: 'app-avatar-model-view',
  templateUrl: './avatar-model-view.component.html',
  styleUrls: ['./avatar-model-view.component.css']
})

export class AvatarModelViewComponent implements OnInit {
  userFileStateChange = this.firestore.collection("userfiles").valueChanges();
  AvaterSnapshot!: any[];
  avaterListselect: any;
  AvaterList: any;
  isloading=false;
   loading =false;
  ;

  constructor(private storage: AngularFireStorage, public commonService: CommonService, private firestore: AngularFirestore, private dbstore: AngularFireDatabase, private router: Router) {
    this.commonService.getUser().subscribe((user: any) => {
      this.fetchUploadedFiles();
    });
    this.userFileStateChange.subscribe((object) => {
      this.listAll()
    })
  }


  ngOnInit(): void {
    this.isloading =true,5000;
    this.checkPageAuthorization();
  }
  fetchUploadedFiles() {
    const path = `RuntimeAvatarSkinner/Source`;
    //Now get all data list
    this.listAll();
  }
  listAll() {
    
    this.firestore.collection("userfiles").get().subscribe((AvaterSnapshot) => {
      AvaterSnapshot.forEach((doc) => {
      });
    });
  }
  
  // checkPageAuthorization() {
  //   this.loading = true;
  //   this.commonService.getUser().subscribe((user: any) => {
  //     let userObject = { 'userid': user?.uid, 'pagename': '/3D-model-view' }
  //     this.commonService.checkViewPageRights(userObject).subscribe((res) => {
  //       this.loading = false;
  //       if (!res) {
  //         this.router.navigateByUrl("/menu-page");
  //       }
  //     });
  //   });
  // }


  checkPageAuthorization() {
    this.loading = true;
    this.commonService.getUser().subscribe((user: any) => {
      let userObject = { 'userid': user?.uid, 'pagename': '/3D-model-view' }
      this.commonService.checkViewPageRights(userObject).subscribe((res) => {
        console.log(res)
        this.loading = false;
        let id = user?.uid
        // this.getUserPreference(id)
        if (!res) {
          this.router.navigateByUrl("/drip");
        }
      });
    });
  }
}
