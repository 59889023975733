import { Component, ElementRef, ViewChild, AfterViewInit, OnInit, EventEmitter, Output, Input } from '@angular/core'
  ;
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { map, Observable } from 'rxjs';
import { CommonService } from 'src/app/services/common.service';
import { collection, getDocs, query } from 'firebase/firestore';
import { Brand } from 'src/app/models/brand';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-search-selection-panel',
  templateUrl: './search-selection-panel.component.html',
  styleUrls: ['./search-selection-panel.component.css']
})
export class SearchSelectionPanelComponent implements AfterViewInit, OnInit {
  user = this.firestore.collection('user-profile').valueChanges({ idField: 'id' }) as Observable<any[]>;
  userAVRT = this.firestore.collection('user-profile').valueChanges({ idField: 'id' }) as Observable<any[]>;
  userFilesList = this.firestore.collection('userfiles').valueChanges({ idField: 'id' }) as Observable<any[]>;
  environmentCollection = this.firestore.collection('Environments').valueChanges({ idField: 'id' }) as Observable<any[]>;
  brands = this.firestore.collection('brands').valueChanges({ idField: 'id' }) as Observable<Brand[]>;
  garmentsList = this.firestore.collection('garments').valueChanges({ idField: 'id' }) as Observable<any[]>;



  evnFileList: any = [];
  filpFlag: boolean = true
  gltfImgSrc = 'https://firebasestorage.googleapis.com/v0/b/yolouploader.appspot.com/o/userData%2Ffiles%2Fcrystal_ball.glb?alt=media&token=963e0d80-5809-4e1b-9034-caf31fb33945';

  filterBrandListNew: any = [];
  selectedTab: string; // Default selected tab
  isFemale: boolean = false; // Default value for the switch

  private sanitizer: DomSanitizer;


  @Output() selectedUserDetails: EventEmitter<any> = new EventEmitter();
  @Output() selectedAvatarDetails: EventEmitter<any> = new EventEmitter();
  @Output() selectedSpaceDetails: EventEmitter<any> = new EventEmitter();
  @Output() selectedBrandDetails: EventEmitter<any> = new EventEmitter();
  @Output() selectedgGarmentDetails: EventEmitter<any> = new EventEmitter();

  @Output() selectedgConditionSearch: EventEmitter<any> = new EventEmitter();







  @Input() allsnapObject: any = [];
  @Input() Avatarsnapshots: any = [];


  @ViewChild('brandStoriyScrollA', { static: false }) brandStoriyScrollA: ElementRef;
  @ViewChild('brandStoriyScrollGarment', { static: false }) brandStoriyScrollGarment: ElementRef;

  @ViewChild('sliderRefAll', { static: false }) sliderRefAll: ElementRef;


  // @ViewChild('sliderRefAll') sliderRefAll: ElementRef;

  searchTerm: string = '';
  seletctUser: string = ''
  userData: any = []
  filterUser: any = []
  rightPanelFlag:boolean

  imagesDummy = [
    { "image": "./assets/user-upload-dummy/new-img-1.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-2.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-3.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-4.jpg" },
  ]

  images = [
    { img: 'assets/search/user.png', value: 'User' },
    { img: 'assets/search/avatar.png', value: 'Avatar' },
    { img: 'assets/search/spaces.png', value: 'Space' },
    { img: 'assets/search/brand.png', value: 'Brand' },
    { img: 'assets/search/garment.png', value: 'Garment' },

  ];
  itemWidth: number;
  filelist: any = [];
  userFiledetails: any = [];
  userUploadedFileList: any = [];
  UserID: any;
  userSnap: any = [];
  filterAvatar: any = [];
  selectedAvatarFromList: any;
  newArray: any = [];
  AvatrUserDeatils: any;
  filterSpaces: any = [];
  brandList: any = [];
  avatarlistFlag: boolean;
  newCompressImages: any;
  selectedSpaceFromList: any;
  spaceLoadFlag: boolean;
  scrollFlag: boolean;
  byScroll: boolean;
  selectedBrand: any;
  scrolnewlFlag: boolean;
  brandNameSelected: any;
  filterGarment: any = [];
  gementFileList: any = [];
  selectedGarmentFromList: any;
  isloading: any;
  source: any;
  style: string = 'treads';
  isCheckedFocus: any = true;
  testVar: string;
  isLoadingStyle: boolean;
  selectedGender: any;
  packJsonObject = []
  zipList: any = {};
  pack0 = "%2Fpack0";
  pack1 = "%2Fpack1"; callDownloadcheck: any;
  errMessage: string;

  newZipLists: any = {};
  showGrmentBrandslier: boolean;
  loadedPack: any;
sideNavHeight: any;

  constructor(private firestore: AngularFirestore, sanitizer: DomSanitizer, private commonService: CommonService, private _httpClient: HttpClient,) {
    this.user.subscribe(userdetails => {
      this.userData = userdetails
    });
    this.userFilesList.subscribe((userFile) => {
      this.userUploadedFileList = userFile
    })
    this.environmentCollection.subscribe(
      (evnFile) => {
        this.evnFileList = evnFile
      })

    this.garmentsList.subscribe(
      (g) => {
        this.gementFileList = g
      })

    this.sanitizer = sanitizer;
    this.brands.subscribe(async (brand) => {
      this.brandList = brand
      this.brandList.forEach(async (brand) => {
        let type = 'b';
        let url = await this.commonService.compressImage(brand.LogoURL, type); // Assuming each brand has 'imagePath' property
        brand.Brandurl = url.downloadUrl;  // Save the compressed image URL back into the brand object
      });
    })
    // let avatr = this.getUserAvatarsByName("Jack SquidGames")
    // console.log("avatar name test", avatr)
  }
  imagesValue = [
    { img: 'assets/profilepic.png', value: 'User-1' },
    { img: 'assets/profilepic.png', value: 'User-2' },
    { img: 'assets/profilepic.png', value: 'User-3' },
    { img: 'assets/profilepic.png', value: 'User-4' },
    { img: 'assets/profilepic.png', value: 'User-5' },
    { img: 'assets/profilepic.png', value: 'User-6' },
    { img: 'assets/profilepic.png', value: 'User-7' },
    { img: 'assets/profilepic.png', value: 'User-8' },
  ];

  selectedImage: any;
  showSearchContainer: boolean;
  selectedUserFromList: any;

  ngAfterViewInit() {
    let scrollLeft = this.sliderRefAll.nativeElement
    // this.inifiniteXScrolls(scrollLeft)
  }

  ngOnInit() {
    // if (this.allsnapObject.length === 0) {
    //   this.commonService.getSnapshots().subscribe(async (snapshots: any) => {
    //     if (snapshots?.object && snapshots?.object.length > 0) {
    //       console.log("snapshots", snapshots?.object);
    //       let snap = snapshots?.object.slice(0, 100); // Limit to the first 400 snapshots
    
    //       try {
    //         // Map over the snapshots and resolve all promises using Promise.all
    //         const updatedSnapshots = await Promise.all(
    //           snap.map(async (snapshot: any) => {
    //             const avtrOBJ = await this.getAvatartDetails(snapshot);
    //             if (avtrOBJ?.AvatarName) {
    //               snapshot.avatarName = avtrOBJ.AvatarName;
    //             }
    //             return snapshot; // Return the updated snapshot
    //           })
    //         );
    
    //         // Save all updated snapshots at once
    //         this.allsnapObject = updatedSnapshots;
    //         console.log("Updated allsnapObject:", this.allsnapObject);
    //       } catch (error) {
    //         console.error("Error updating snapshots:", error);
    //       }
    //     }
    //   });
    // }
     
  }

  ngOnChanges() {

    console.log("Avatarsnapshots,",this.Avatarsnapshots)

  }

  // private setupInfiniteScroll(): void {

  //   const slider = this.sliderRefAll.nativeElement;
  //   const moveElement = () => {
  //     // Use requestAnimationFrame for smoother visual updates
  //     requestAnimationFrame(() => {
  //       const firstChild = slider.firstElementChild as HTMLElement;
  //       const lastChild = slider.lastElementChild as HTMLElement;
  //       // Calculate the buffer based on the child width
  //       const childWidth = firstChild.offsetWidth;
  //       const buffer = childWidth * 2; // Adjust the buffer size as needed
  //       if (slider.scrollLeft + buffer >= slider.scrollWidth - slider.offsetWidth) {
  //         // Move the first child to the end and adjust scrollLeft
  //         slider.appendChild(firstChild);
  //         slider.scrollLeft -= firstChild.offsetWidth; // Adjust instantly without animation
  //       } else if (slider.scrollLeft <= buffer) {
  //         // Move the last child to the beginning and adjust scrollLeft
  //         slider.insertBefore(lastChild, firstChild);
  //         slider.scrollLeft += lastChild.offsetWidth; // Adjust instantly without animation
  //       }
  //     });
  //   };


  //   // Throttle scroll events
  //   let isScrolling;
  //   const onScroll = () => {
  //     window.clearTimeout(isScrolling);
  //     isScrolling = setTimeout(moveElement, 0); // Increase the timeout to delay the element movement
  //   };
  //   // Add smooth scrolling CSS transition if not already present
  //   slider.style.transition = 'scroll-left 0.1s ease-out';
  //   slider.addEventListener('scroll', onScroll);

  // }

  // Handle movement during touch

  clearSearch() {
    this.searchTerm = '';
    this.selectedUserFromList = null
    this.selectedAvatarFromList = null
    this.selectedSpaceFromList = null
    this.selectedGarmentFromList = null
    this.filterUser = null
    if(this.selectedImage?.value === 'Garment'){
      this.showSearchContainer = true
      this.filterGarment = null
    }else{
      this.showSearchContainer = false
    }

  }

  seletctOption(item) {
    this.searchTerm = ''
    this.selectedImage = item;
    this.seletctUser = item.value
    this.showGrmentBrandslier = false
    this.selectedBrand = null
    if (this.selectedImage?.value == 'Brand') {
      this.showSearchContainer = true
      this.scrollFlag = false
    }
    if (this.selectedImage?.value == 'Garment') {
      this.showGrmentBrandslier = true
      this.scrollFlag = false
    }
  }

  clickSearch() {
    console.log("searchTerm", this.searchTerm)
    if (this.searchTerm.length < 3) {
      alert('Please enter more than three letters.');
      return; // Exit the method early if the condition is not met
    }
    if (!this.selectedImage) {
      alert('Please Select Option.');
      return; // Exit the method early if the condition is not met
    }

    if (this.selectedImage?.value == 'User') {
      this.searchUserList()
    }
    if (this.selectedImage?.value == 'Avatar') {
      this.searchAvatarList()
    }
    if (this.selectedImage?.value == 'Space') {
      this.searchSpaceList()
    }

    if (this.selectedImage?.value == 'Brand') {
      this.scrollFlag = false
    }

    if (this.selectedImage?.value == 'Garment') {
      this.searchGarmentList()
    }



    this.showSearchContainer = true
  }

  seletctUserFromList(item) {
    this.selectedUserFromList = item;
    this.seletctUser = item.value
    this.filelist = this.userUploadedFileList.filter((uploadlist) => uploadlist.fileType == 'UserUploads')
    let filelist = this.filelist.filter((uploadlist) => uploadlist.userId == this.selectedUserFromList.UserID)
    this.userFiledetails = filelist
    this.UserID = this.selectedUserFromList.UserID
    console.log(" this.selectedUserFromList,", this.selectedUserFromList)
    console.log("this.userFiledetails", this.userFiledetails)
  }

  seletctAvatarFromList(item) {
    this.selectedAvatarFromList = item;
    this.AvatrUserDeatils = this.userData.find((u) => u.UserID == item.UserID)
    console.log(" this.selectedUserFromList,", this.selectedAvatarFromList)
    this.newArray = this.processAvatarUrls()
  }

  async seletctSpaceFromList(item) {
    this.spaceLoadFlag = true
    this.selectedSpaceFromList = item;
    let evnpath = item.storageURL
    let type = 'e'
    this.newCompressImages = await this.commonService.compressImage(evnpath, type);
    this.spaceLoadFlag = false
  }


  seletctGarmentFromList(item) {
    this.selectedGarmentFromList = item;
    this.isloading = true
    let bucketName: string = 'yolomoves-fb435.appspot.com';
    const filePath = 'Uploads/' + this.selectedGarmentFromList.id + '/' + this.selectedGarmentFromList.id
    const encodedFilePath = encodeURIComponent(filePath);
    console.log(" this.selectedUserFromList,", this.selectedGarmentFromList)
    let filelist = this.userUploadedFileList.find((file) => file.filename == this.selectedGarmentFromList.id && file.fileType == 'Garment360Cache')
    let firebaseUrl = `https://firebasestorage.googleapis.com/v0/b/${bucketName}/o/${encodedFilePath}?alt=media&token=60fff406-7125-43cb-ad09-f6488bf40ee6`;
    console.log("this.filelist,", filelist)
    this.source = firebaseUrl || '';
    this.isloading = false
    console.log("this.filterGarment,", this.filterGarment)
  }

  flip() {
    this.filpFlag = true
  }

  seletctGarment(item) {
    this.filpFlag = false
    this.selectedGarmentFromList = this.gementFileList.find((g) => g.id == item)
    this.isloading = true
    let bucketName: string = 'yolomoves-fb435.appspot.com';
    const filePath = 'Uploads/' + this.selectedGarmentFromList.id + '/' + this.selectedGarmentFromList.id
    const encodedFilePath = encodeURIComponent(filePath);
    console.log(" this.selectedUserFromList,", this.selectedGarmentFromList)
    let filelist = this.userUploadedFileList.find((file) => file.filename == this.selectedGarmentFromList.id && file.fileType == 'Garment360Cache')
    let firebaseUrl = `https://firebasestorage.googleapis.com/v0/b/${bucketName}/o/${encodedFilePath}?alt=media&token=60fff406-7125-43cb-ad09-f6488bf40ee6`;
    console.log("this.filelist,", filelist)
    this.source = firebaseUrl || '';
    this.isloading = false
    console.log("this.filterGarment,", this.filterGarment)
  }

  searchUserList() {
    this.filterUser = []
    let users = this.userData.filter((u) => u?.DisplayName.toLowerCase().includes(this.searchTerm?.toLowerCase()));
    this.filterUser = users
    console.log("User", users)
  }

  searchGarmentList() {
    this.filterGarment = []
    let garment = this.gementFileList.filter((u) => u?.Name.toLowerCase().includes(this.searchTerm?.toLowerCase()));
    this.filterGarment = garment
    console.log("g", this.filterGarment)
  }

  async searchAvatarList() {
    this.filterAvatar = [];
    console.log("searchTerm", this.searchTerm);
  
  
   
   

    console.log("this.allsnapObject", this.Avatarsnapshots.length)


    // Check if searchTerm and allsnapObject are defined
    if (this.searchTerm !== undefined && this.Avatarsnapshots?.length > 0) {
      // Filter the avatars based on the search term
      this.avatarlistFlag = true;
      let snap = this.Avatarsnapshots.filter((u) => {
        // Ensure avatarName exists before calling toLowerCase()
        return u?.avatarName && u?.avatarName.toLowerCase().includes(this.searchTerm.toLowerCase());
      });

      console.log("snap", snap);
  
      // Map the snap array to a list of promises for fetching avatar details
      const promises = snap.map(async (object) => {
        let data = await this.getAvatartDetails(object); // Await the async function
        data.UserID = object.UserID;
        data.AvatarID = object.AvatarID;
        return data;
      });
  
      // Use Promise.all to wait for all promises to resolve
      const results = await Promise.all(promises);
  
      // Use a Set to filter out duplicates based on AvatarID
      const uniqueAvatarIds = new Set();
      this.filterAvatar = results.filter((avatar) => {
        if (!uniqueAvatarIds.has(avatar.AvatarID)) {
          uniqueAvatarIds.add(avatar.AvatarID);
          return true; // Keep this avatar
        }
        return false; // Skip this avatar
      });
  
      console.log("List of avatars", this.filterAvatar);
    
 

  
        this.avatarlistFlag = false;

      
    } else {
      console.warn("Search term or allsnapObject is not available");
    }
  
  }
  


  searchSpaceList() {
    this.filterSpaces = []
    let space = this.evnFileList.filter((u) => u?.displayName.toLowerCase().includes(this.searchTerm?.toLowerCase()));
    this.filterSpaces = space
    console.log("User", space)
  }

  selectUser() {
    this.selectedUserDetails.emit(this.selectedUserFromList)
  }

  selectAvatar() {
    this.selectedAvatarDetails.emit(this.selectedAvatarFromList)

  }

  selectSpace() {
    this.selectedSpaceDetails.emit(this.selectedSpaceFromList)
  }

  selecteBrand() {
    this.selectedBrandDetails.emit(this.selectedBrand)
  }
  selectGarment() {
    this.selectedgGarmentDetails.emit(this.selectedGarmentFromList)
  }



  switchgarmentSearch() {
    this.isCheckedFocus = !this.isCheckedFocus
    this.showSearchContainer = true
    this.packJsonObject = []
    this.selectedBrand = null
    if (!this.isCheckedFocus) {
      this.scrollFlag = false
    }

  }



  switchTab(gender: string) {
    this.selectedTab = gender;
    this.isFemale = (gender === 'female'); // Sync checkbox state
    if (this.selectedTab == 'female') {
      this.selectedGender = "F"
    } else if (this.selectedTab == 'male') {
      this.selectedGender = "M"
    }this.downloadPack0()
  }

  // Handle toggle button change
  toggleGender() {
    this.selectedTab = this.isFemale ? 'female' : 'male';
  }

  inifiniteXScrolls(el) {
    let lastScrollX = 0;
    let overlapCount = 0;
    let clones = [];

    // Check if getClientRects() returns a non-empty array
    const clientRects = el.getClientRects();
    if (clientRects.length == 0) {
      return null;
    }

    const elWidth = clientRects[0].width;

    // Create clones to simulate the infinite scroll effect
    while (el.children[overlapCount] && ((el.children[overlapCount].offsetLeft - el.offsetLeft)) <= elWidth) {
      const clone = el.children[overlapCount].cloneNode(true);
      clones.push(clone);
      el.appendChild(clone);
      overlapCount++;
    }

    el.scrollTo({ left: lastScrollX });
    overlapCount -= 1;
    let lastScroll = el.scrollLeft = 10;

    function scrollHandler() {
      const maxScroll = el.scrollWidth - el.clientWidth;
      const overlapOverflow =
        (el.children[overlapCount].offsetLeft - el.offsetLeft) -
        elWidth +
        el.children[overlapCount].getClientRects()[0].width;

      // Scrolling to the right
      if (el.scrollLeft >= maxScroll && el.scrollLeft > lastScroll) {
        el.scrollLeft = overlapOverflow;
      }
      // Scrolling to the left
      else if (el.scrollLeft <= 0 && el.scrollLeft < lastScroll) {
        el.scrollLeft = maxScroll - overlapOverflow;
      }

      lastScroll = el.scrollLeft;
    }

    el.addEventListener('scroll', scrollHandler);

    // Cleanup function to remove clones and event listeners
    return function cleanup() {
      const scroll = el.scrollLeft;
      clones.forEach((clone) => clone.replaceWith(""));
      el.scrollTo({ left: scroll });
      el.removeEventListener('scroll', scrollHandler);
    };
  }

  async getAvatartDetails(snapShots) {
    try {
      // Check if UserID and AvatarID are available before proceeding
      if (!snapShots.UserID || !snapShots.AvatarID) {
        snapShots.avatarName = snapShots.AvatarID || 'Unknown Avatar';
        return;
      }
      // Fetch avatar details from the common service
      const documents = await this.commonService.getUserAvatarDetails(snapShots.UserID, snapShots.AvatarID);
      if (documents?.length > 0) {
        const firstDocument = documents[0];
        return firstDocument
      }
    } catch (error) {
      console.error('Error in getAvatarDetails:', error);
      throw error; // Re-throw the error or handle it here as needed
    }
  }

  processAvatarUrls(): { fileurl: string }[] {
    const urls = [];
    if (this.selectedAvatarFromList?.AvatarPreviewFullBodyUrl) {
      urls.push({ fileurlMini: this.selectedAvatarFromList.AvatarPreviewFullBodyUrl });
    }
    if (this.selectedAvatarFromList?.AvatarPreviewPortraitUrl) {
      urls.push({ fileurlMini: this.selectedAvatarFromList.AvatarPreviewPortraitUrl });

    }
    return urls;
  }

  async getUserAvatarsByName(avatarName: string): Promise<Observable<any[]>> {
    // Reference to 'UserAvatar' collection
    const userAvatarsRef: AngularFirestoreCollection<any> = await this.firestore.collection('UserAvatar', ref =>
      ref.where('AvatarData.AvatarName', '==', avatarName) // Perform the filtering in the query
    );

    // Return the filtered data as an observable
    return userAvatarsRef.snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data();
        const id = a.payload.doc.id;
        return { id, ...data };
      }))
    );
  }

  selectBrand(brand) {
    // this.selectedEvenObj = {}
    this.selectedBrand = brand
    this.scrollFlag = false
    this.scrolnewlFlag = false
    let obj = this.getBrandDetails(this.selectedBrand.id)
    this.brandNameSelected = obj.BrandName
    // this.setTab(tab, gender)
    setTimeout(() => {
      if(this.selectedImage?.value === 'Brand'){
        let brandId = brand.id
        this.commonService.scrollToElementByIdbrand(brandId, this.brandStoriyScrollA);
        // Adjust the scroll position by -60px after the service call
        const element = document.getElementById(brandId);
        if (element) {
          element.scrollLeft -= 60;
        }
      }

    }, 150); // Adjust the timeout duration as needed (500ms in this example)

    setTimeout(() => {
      this.byScroll = false
      this.scrolnewlFlag = false
    }, 500);


    console.log("Brand ->",this.selectedBrand)

    if(this.selectedImage?.value === 'Garment'){
      this.commonService.scrollToElementByIdbrand(this.selectedBrand.id, this.brandStoriyScrollGarment);

      let brandGender = this.selectedBrand.Gender == 'F' ? 'female' : 'male'
      this.switchTab(brandGender)
    }


  }
  getBrandDetails(id) {
    let brand = this.brandList.find((brand) => brand.id == id)
    return brand
  }
  selectByScroll() {
    this.byScroll = true
  }

  suggest($event) {
    this.scrollFlag = true;
    this.byScroll = true;
    console.log("byScroll 7", this.byScroll)
    let inputValue = $event.target.value.toLowerCase();
    const startsWithMatches = this.brandList.filter(brand =>
      brand.BrandName && brand.BrandName.toLowerCase().startsWith(inputValue)
    );

    const includesMatches = this.brandList.filter(brand =>
      brand.BrandName && brand.BrandName.toLowerCase().includes(inputValue)
    ).filter(brand => !startsWithMatches.includes(brand));
    this.filterBrandListNew = startsWithMatches.slice(0, 10).concat(includesMatches.slice(0, 10));

  }

  getGarmentTypeLogo(option) {
    switch (option) {
      case 0:
        return 'assets/newsfeed/headgear-new.png';
      case 1:
        return 'assets/realtype/upperwear.png'; // Replace with correct image path
      case 2:
        return 'assets/newsfeed/bottomwear-new.png'; // Replace with correct image path
      case 3:
        return 'assets/realtype/footwear.png'; // Replace with correct image path
      case 4:
        return 'assets/realtype/fullbody.png'; // Replace with correct image path
      case 5:
        return './assets/realtype/fullbody.png'; // Replace with correct image path
      default:
        return ''; // Default or placeholder image
    }
  }

  async downloadprocessPackZip(url: string) {
    try {
      let functionUrl = "https://us-central1-yolomoves-fb435.cloudfunctions.net/downloadPack0";
      const response = await this._httpClient.get<any>(functionUrl, { params: { packlocation: url } }).toPromise();
      console.log('Zip response:', response);
      return response;
    } catch (error) {
      console.error('Error processing cloth zip:', error);
      throw error;
    }
  }
  async downloadPack0() {
    this.packJsonObject = []
    this.errMessage = ''
    console.log('pack0 loading intilze...')
    this.isloading = true;
    this.isLoadingStyle = true;
    this.testVar = 'Change';
    let brandId = this.selectedBrand.id;
    let zipfilename = this.pack0;  // Assuming this is the zip file name
    let gender = this.selectedGender;
    let packlocation = "AvatarBlenderClothes%2F" + brandId + "_" + gender + zipfilename;
    let packlocationTemp = "AvatarBlenderClothes/" + brandId + "_" + gender + '/pack0';

    console.log("packlocation", packlocation)
    let brandEncodedurl = packlocation;
    this.callDownloadcheck = brandId + gender
    let uniqueKey = packlocation;
    if( this.loadedPack == packlocationTemp){
      return
    }
    this.loadedPack = packlocationTemp
    

    if (!brandId || !gender || !zipfilename) {
      this.isloading = false;
      this.isLoadingStyle = false;
      return;
    }
    let url = 'https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/' + brandEncodedurl + '?alt=media&token=46df48b9-1f7a-4b61-98c6-bff029734da4';
    try {
      // const res = await this._httpClient.get(url, { observe: 'response', responseType: 'blob' }).toPromise();
      // await this.processZipFile(res.body, this.processResponse.bind(this));
      let newPacketData = await this.downloadprocessPackZip(packlocationTemp)
      this.extractFileData(newPacketData)

    } catch (error) {
      this.handleErrors(error);
    } finally {
      this.isloading = false;
      this.isLoadingStyle = false;
    }
    console.log('pack0 loaded........')
  }
  handleErrors(error) {
    console.log('error', error)
    this.packJsonObject = [];
    this.isloading = false;
    this.errMessage = "Pack0 is not found for this selection !!!"
    this.isLoadingStyle = false;
  }

  async extractFileData(result) {
    const packJsonObject = result.packJsonObject;
    let data = result.zipList;
    this.newZipLists = data;

    for (const key in this.newZipLists) {
      if (this.newZipLists.hasOwnProperty(key)) {
        const base64String = this.newZipLists[key];
        const blobUrl = await this.base64ToBlobUrl(base64String);
        this.newZipLists[key] = blobUrl; // Assuming you sanitize in base64ToBlobUrl method
      }
    }
    this.zipList = this.newZipLists;
    console.log("Updated newZipLists:", this.zipList);
    // Refactor using loop for categories
    ['hFiles', 'uFiles', 'bFiles', 'fFiles'].forEach((category, index) => {
      this.extractFiles(packJsonObject, category, index);
    });
  }
  extractFiles(packJsonObject: any, category: string, index: number) {
    const filesArray: string[] = packJsonObject.brandSaves[category];
    const nonEmptyFilesArray: string[] = filesArray.filter((file: string) => file !== '');
    let tcount = packJsonObject.brandSaves['totalCount']
    let newPackJsonObject: any = {
      value: {
        files: nonEmptyFilesArray,
        count: tcount
      }
    };
    this.packJsonObject[index] = newPackJsonObject; // Assign to the appropriate index
    console.log(`Updated files for ${category}`, this.packJsonObject);
  }
  async base64ToBlobUrl(base64String: string): Promise<string> {
    return new Promise((resolve, reject) => {
      const byteCharacters = atob(base64String);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray]);
      const blobUrl = URL.createObjectURL(blob);
      resolve(blobUrl);
    });
  }
  returnUrl(obj) {
    let url = this.sanitizer.bypassSecurityTrustUrl(obj)
    return url
  }


  selectCondition() {
    console.log('Selection of search:', this.selectedImage?.value);
    console.log('Input of search:', this.searchTerm);
    // Emit both values as an object with different keys
    this.selectedgConditionSearch.emit({
      selectedValue: this.selectedImage?.value,  // Key for selected image value
      searchInput: this.searchTerm               // Key for the search term
    });
  }

  
}
