import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DataSharingService } from 'src/app/services/data-sharing-service';

@Component({
  selector: 'app-environment-profile',
  templateUrl: './environment-profile.component.html',
  styleUrls: ['./environment-profile.component.css']
})
export class EnvironmentProfileComponent implements OnInit {
  rootFromEven :boolean = true
  userSnapShotList:any=[]
  evnFile:any
  constructor(
    private dataSharingService: DataSharingService,
    private route: ActivatedRoute,


  ) {
    this.dataSharingService.userSnapShotObservable.subscribe(snapshot => {
      this.userSnapShotList = snapshot
    })

  }

  ngOnInit(): void {


    this.route.paramMap.subscribe(paramMap => {
      this.evnFile = paramMap.get('evnFile');})
  }

}
