<div *ngIf="loading" class="loading">Loading&#8230;</div>
<div class="dt-responsive selectlist font-14 brand-profile-color">
    <div class=" brand-profile-color" *ngIf="userSnapShotList">
        <div class="col-lg-12 m-3 photo-name">
            <div class="text-start" *ngIf="userDetails">
                <img 
                    class="img-profile img-thumbnail img-fluid rounded-circle m-2 profileImg  photo " 
                    style="width: 70px  !important; height:70px !important; margin-left: -5px !important" [src]="userDetails.photoURL">
                <h5 class="avatr color-class"  style="margin-left: -5px !important">{{userDetails.displayName}}</h5>
            </div>
            <div class="d-block text-start"  style="margin-left: -11px !important" >
                <div><h6 [ngClass]="{'hide': this.AvatarId == undefined }">🧍🏽{{this.AvatarId}}</h6></div>
            </div>
            <div class="d-block text-start"  style="margin-left: -14px !important">
                <div> <button type="button"  class="btn btn-outline-info m-2 color-class-2">Follow</button></div>
            </div>
        </div>
    </div>
    <div class="row d-flex">
        <div data-bs-dismiss="modal"
            class="text-center col-6">
            <span>
                <div >
                    <img class="img-fluid img-thumbnail" style="height: 100px; width: 100px !important; object-fit: contain;
                " src="./assets/imgplaceholder.jpg">
                </div>
            </span>
        </div>
        <div data-bs-dismiss="modal"
            class="text-center col-6">
            <span>
                <div >
                    <img class="img-fluid img-thumbnail" style="height: 100px; width: 100px !important; object-fit: contain;
                " src="./assets/imgplaceholder1.jpg">
                </div>
            </span>
        </div>
    </div>
    <div class="justify-content-center story-center text-center d-flex">
        <h6     (click)="getuserNews()" class="m-2 text-primary brandbtn color-class">
            <img style="width:15px !important; background-color:black"
                src="./assets/storystyle/story-white.png">
        </h6>
    </div>
    <div class="dataTables_wrapper dt-bootstrap4" id="add-row-table_wrapper">
        <div *ngIf="filterSnapShotFileDetails && filterSnapShotFileDetails?.length == 0 || !filterSnapShotFileDetails"
            class="p-3"><span class="danger">
                <div class="alert alert-danger text-center" role="alert">
                    <b>No Post Found.</b>
                </div>
            </span>
        </div>
    </div>
    <div class="d-flex justify-content-center">
        <div class="dots-bars-2" *ngIf="isLoading" role="status">
            <span class=""></span>
        </div>
    </div>
    <div *ngIf="loading" class="loading">Loading&#8230;</div>
    <!-- <app-news-common></app-news-common> -->     
    <app-user-story 
        [userSnapShotsInput]="userSnapShotList" [UserId]="userId" [AvatrId] ="AvatarId" [StoryIds]="selectedStoryId" [PageCount]="pagecount" 
        [pageNumber]="pageNumber"
        (outPageNumber) = "setPageNumber($event)">
    </app-user-story>

</div>
<div class="modal fade imp-bg" id="fileView-profile" tabindex="-1" aria-labelledby="user-profile" aria-hidden="true"
    data-bs-backdrop="false">
    <div class="modal-dialog justify-content-center ">
        <div class="modal-content imp-block justify-content-center">
            <h5 type="button" class="btn-close  closBtn" data-bs-dismiss="modal" aria-label="Close"></h5>
            <img src={{showImages.fileurl}}>
        </div>
    </div>
</div>
