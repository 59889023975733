<button type="button" *ngIf="closebuttonFlag"  class="btn-close main-close-button" aria-label="Close" (click)="toggleChild()" style="color: white;"></button>
<span *ngIf="skyBoXall == undefined">
  <mat-dialog-content class="mat-typography">
    <h4 class="text-center"></h4>
    <model-viewer camera-controls ar auto-rotate camera-orbit="45deg 55deg 2.5m" [src]="gltfImgSrc"
      alt="A 3D avatar-model-view" data-js-focus-visible="" quick-look ar-status="not-presenting"
      skybox-image={{newCompressImages?.downloadUrl}}>
    </model-viewer>
  </mat-dialog-content>

  <a class="band" *ngIf="fileDeletFlag">
    <i class="bi bi-trash-fill class-delete" (click)=" deleteSnapshotFile(fileObject)"></i>
    <div class="justify-content-center text-center div-s-n-s p-2">
      <div class="d-flex col-12 " *ngIf="nameFlag">
        <div class="col-10 col-sm-8">
          <h6 class="p-1 text-start">{{fileObject.filename}}</h6>
        </div>
        <div class="col-2 col-sm-4 text-end">
          <i class="bi bi-pencil" (click)="selectName()"></i>
        </div>
      </div>
      <div class="d-flex justify-content-center text-center col-12" *ngIf="!nameFlag">
        <div class="col-10 col-sm-8">
          <input type="text" class="form-control text-start transparent-input text-black" placeholder="Enter File name"
            (change)="editFileName($event)" [value]="fileObject.filename">
        </div>
        <div class="col-2 col-sm-4 text-end">
          <button type="button" class="btn btn-sm btn-primary" data-bs-dismiss="modal"
            (click)="updateFileName(fileObject)">Save</button>
        </div>
      </div>
    </div>
  </a>
  <mat-dialog-actions align="end">
    <button class="btn btn-outline-secondary  btn-sm" mat-dialog-close>Close</button>
  </mat-dialog-actions>
</span>


<div *ngIf="!userSnapShotListSlice" class="text-center">
  <div class="spinner-border m-5" role="status">
    <span class="sr-only"></span>
  </div>
</div>
<div *ngIf="skyBoXall != undefined && userSnapShotListSlice">
  <div *ngIf="snaploader" class="text-center">
    <div class="spinner-border m-5" role="status">
      <span class="sr-only"></span>
    </div>
  </div>

  <!-- <div class="col-12 justify-content-center">
    <img *ngIf="hideFlag != undefined"  (click)="getEnvironmentProfile()" class="shadow-1-strong rounded mb-1 image-popup"
      [src]="newCompressImages.downloadUrl != undefined ? newCompressImages.downloadUrl :  this.skyBoXall">
    <h2 class="evn-name"(click)="getEnvironmentProfile()" data-bs-dismiss="modal">
      {{this.evnData?.displayName ? this.evnData?.displayName :  evsdiplaynameALL}}</h2>
  </div> -->

 <div class="col-12 justify-content-center position-relative">
<!-- 
  <div *ngIf="this.pageFlagRout == 'spaces'" class="toggle-container" (click)="toggleQuality($event)">
    <span class="toggle-option">{{ selectedQuality }}</span>
  </div> -->
  

  <div class="cropped-image-container">
    <img  [src]="newCompressImages?.downloadUrl != undefined ? newCompressImages?.downloadUrl :  this.skyBoXall"   (click)="getEnvironmentProfile()"  alt="Cropped Image" class="cropped-image">
  </div>
  
  <!-- <img 
    *ngIf="hideFlag != undefined"  
    (click)="getEnvironmentProfile()" 
    class="shadow-1-strong rounded mb-1 image-popup"
    [src]="newCompressImages?.downloadUrl != undefined ? newCompressImages?.downloadUrl :  this.skyBoXall"> -->
  
  <h2 class="evn-name" (click)="getEnvironmentProfile()" data-bs-dismiss="modal">
    {{ this.evnData?.displayName ? this.evnData?.displayName : evsdiplaynameALL }}
  </h2>
  <i 
      *ngIf="isIconRight && this.pageFlagRout == 'spaces'" (click)="sliderSLIDEbutton($event)"
      class="bi bi-chevron-right chevron-icon">
    </i>
    <i 
      *ngIf="!isIconRight && this.pageFlagRout == 'spaces'" (click)="sliderSLIDEbutton($event)"
      class="bi bi-chevron-left chevron-icon">
    </i>
</div>


  
 
  <div *ngIf="this.snaploaderSnap" class="text-center">
    <div class="spinner-border m-4 p-5" role="status">
      <span class="sr-only"></span>
    </div>
  </div>

  <model-viewer *ngIf="!snaploader2D" id="poster" camera-controls touch-action="pan-y" data-js-focus-visible="false"
    [attr.skybox-image]="newCompressImages?.downloadUrl  || downloadedUrl || this.skyBoXall" alt="A 3D model of a sphere reflecting a sunrise"
    [attr.src]="gltfImgSrc" (environmentchange)="onSkyboxLoad()">
  </model-viewer>

  <div *ngIf="snaploader" class="text-center">
    <div class="spinner-border m-5" role="status">
      <span class="sr-only"></span>
    </div>
  </div>

  <div class="div-center class-desktop" *ngIf="!snaploader && hideFlag != undefined">
    <h6 *ngIf="pageiIndex" (click)="previousbutton($event)"><b *ngIf="callpageNumer > 0 ">Previous</b></h6>
    <div class="row w-100 image-margin"  [ngStyle]="userSnapShotListSlice?.length < 12 ? {} : { 'height.px': brandaStoryScrollerHeight - 180}">
      <!-- Change "col-4" to "col-6" to make two images per row -->
      <div class="col-6 p-0" *ngFor="let snapshot of userSnapShotListSlice; let i=index;" [ngClass]="{'d-none': snapshot.SnapshotFileUrl == ''}">
        <div class="d-flex align-items-center justify-content-center">
          <img class="shadow-1-strong rounded m-1 story-img" (click)="getEnvironmentProfileFeed(snapshot,i)" [src]="snapshot.SnapshotFileUrlMini">
        </div>
      </div>
    </div>
  </div>
</div>
<div>
  <div class="col-12 text-center text-black justify-content-center">
    <h6 (click)="incrementImgCounter($event)">
      <b>More</b>
    </h6>
  </div>
</div>