import { Component, OnInit, Input } from '@angular/core';

import { AngularFireStorage,  } from '@angular/fire/compat/storage';
import firebase from "firebase/compat/app";
import '@google/model-viewer';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { Observable } from 'rxjs';
import { CommonService } from 'src/app/services/common.service';
import { MatDialog } from '@angular/material/dialog';



@Component({
  selector: 'avatar-index',
  templateUrl: './avatar-index.component.html'
})

export class AvatarIndexComponent implements OnInit {

  @Input()
  file!: File;

  task:any;
  percentage!: Observable<number>;
  snapshot!: Observable<any>;
  downloadURL!: string;
  items:any;
  fileUploads!: any[];
  allFileList:any = [];
  userId!: number;
  uid= "";
  username = "";
  gltfImgSrc = "";
 
  userUploadedAvatars: any[] = [];
  userFiles = this.firestore.collection("userfiles");
  userFileStateChange = this.userFiles.valueChanges();



  constructor(public dialog: MatDialog, private storage: AngularFireStorage, private firestore: AngularFirestore, private dbstore: AngularFireDatabase, private commonService: CommonService) { 
    this.commonService.getUser().subscribe((user:any) => {
      this.uid = user?.uid;

    });
    this.userFileStateChange.subscribe((object)=>{
      this.listAll();

      firestore.collection("userfiles").doc('id').delete().then(() => {
    }).catch((error) => {
        console.error("Error removing document: ", error);
    });
    }); 
  }
  
  ngOnInit(): void {
  }
  
  
 
  listAll() {
    this.firestore.collection("userfiles").ref.where("userId", "==", this.uid)
    .get()
    .then((objectList) => {
       this.userUploadedAvatars = [] 
        objectList.forEach((doc) => {
        this.userUploadedAvatars.push(doc.data());
        });
   
    }).catch((error) => {
        console.log("Error getting documents: ", error);
    });
  }
  
  deleteUserfiles(userFileId: any, fileType: string) {
    var confirmation = confirm('Are you sure to delete this file ?' );
    if (!confirmation) return;
    this.userFiles.ref.where("id", "==", userFileId)
      .get()
      .then((objectList) => {
        objectList.forEach((doc) => {
          let docid = doc.id
          let userFilePath = doc.data()['filepath'];
          this.storage.ref(userFilePath).delete();

          if (fileType === 'Environment') {
            let environmentId = doc.data()['link'];
            this.firestore.collection("Environments").doc(environmentId).delete().then(() => {
            }).catch((error) => {
              console.error("Error removing document: ", error);
            });;
          }
          this.userFiles.doc(docid).delete().then(() => {
          }).catch((error) => {
            console.error("Error removing document: ", error);
          });;
        });

      });


  }


  
  openModel(gltfImgSrc:string) {
    const dialogRef = this.dialog.open(GLTFAvatarDialog);
    this.gltfImgSrc = gltfImgSrc
    dialogRef.componentInstance.gltfImgSrc = this.gltfImgSrc;
    dialogRef.afterClosed().subscribe(result => {
    });
  }
}
@Component({
  selector: 'avatar-upload-popup',
  templateUrl: './avatar-upload-popup.html',
})
export class GLTFAvatarDialog {
  @Input() gltfImgSrc: string | undefined;

  constructor(public dialog: MatDialog) {

  }

}
