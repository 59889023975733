import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';
import { Brand } from 'src/app/models/brand';
import { MatDialog } from '@angular/material/dialog';
import { CommonService } from 'src/app/services/common.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DataSharingService } from 'src/app/services/data-sharing-service';
import { PinterestService } from 'src/app/services/pinterest-service';
import { ApiHttpService } from 'src/app/services/api-http.service';
export interface ZipFile {
  readonly name: string;
  readonly dir: boolean;
  readonly date: Date;
  readonly data: any;
}

@Component({
  selector: 'app-style3-d',
  templateUrl: './style3-d.component.html',
  styleUrls: ['./style3-d.component.css']
})
export class Style3DComponent {

  @ViewChild('brandStoriyScroll', { read: ElementRef }) public BrandStoriyScroll: ElementRef<any>;
  brands = this.firestore.collection('brands').valueChanges({ idField: 'id' }) as Observable<Brand[]>;
  garmentsList = this.firestore.collection('garments').valueChanges({ idField: 'id' }) as Observable<any[]>;
  brandsBoardCollections = this.firestore.collection('brand-board').valueChanges({ idField: 'id' }) as Observable<Brand[]>;
  userFiles = this.firestore.collection("userfiles");
  userFileStateChange = this.userFiles.valueChanges();
  brandBoardList = this.firestore.collection('brand-board').valueChanges({ idField: 'id' }) as Observable<any[]>;
  SnapShotsList = this.firestore.collection('SnapshotDetails').valueChanges({ idField: 'id' }) as Observable<any[]>;
  brandsCollection = this.firestore.collection('brands').valueChanges({ idField: 'id' }) as Observable<Brand[]>;
  brandsCollections = this.firestore.collection('brands').valueChanges({ idField: 'id' }) as Observable<Brand[]>;
  displayedColumns: string[] = ['position', 'name', 'weight', 'symbol'];
  public text = `{ "text": "This is text file!中文" }`;
  public fileName: string | undefined;


  @Input() rootFlag: string | undefined;
  @Input() stylePage: string | undefined;
  @Input() routLogInFlag: boolean | undefined;





  boardDetails: any
  selectedBrandType: any
  selectedBrand: any;
  getZipcollection: any;
  zipfile: any;
  loading = true;
  brandDetails: any = []
  brandDetailsForStories: any = []
  snapDetails: any = []
  garmentsCollection: any = []
  brandId: any
  brandGarmentIds: any = [];
  SnapShotFileDetails = []
  BrandDetails = []
  snapShotBrandList = [];
  filterStories = []
  storyId: any
  userId: any
  brandIds: any
  boards: any = []
  brandBoard: any = []
  boardNameInBrandStory: any
  brandDetailsId: any = {}
  showBrandName: boolean = false
  showBCollasps: boolean = false;
  showRender: boolean = false;
  pageTrueFlage: boolean = true;

  userSnapShotList: any = []
  SnapShotDetails: any = []
  // selectedTab:any = "news-stories"

  selectedTab: any = "mens-wear"
  filterBrandList:any=[]
  brandsIDdetailsAll: any = {}
  genderID: any
  isloading = true
  brandDetailsAll: any = []
  byScroll: boolean = false
  showBrandFlag: boolean = false
  heartFlag: boolean = false
  thresholdHeightPagination: number = 0
  pageNumber: number = 0
  clickEventFlag: boolean = false;
  genderFlag: boolean;
  onChangeFlag = true;
  height: any;
  url: any;
  urlAvatar: any = '';
  urlAvatarFeMale: any = 'https://firebasestorage.googleapis.com/v0/b/rishtest-96467.appspot.com/o/20230112114731661.zip?alt=media&token=5ef19c50-0361-46c1-949e-0f78b16c9c20&_gl=1*1i8j5xe*_ga*MTY2MTMzOTE3NC4xNjk2ODgxNDM4*_ga_CW55HF8NVT*MTY5NzQ2MDA1OC4yOC4xLjE2OTc0NjAzMTYuNjAuMC4w';
  urlAvatarMale: any = 'https://firebasestorage.googleapis.com/v0/b/rishtest-96467.appspot.com/o/20230112115118542.zip?alt=media&token=3ea78817-7886-4806-9963-77717f09be64&_gl=1*1w2i935*_ga*MTY2MTMzOTE3NC4xNjk2ODgxNDM4*_ga_CW55HF8NVT*MTY5NzM2MTkzNi4yMS4xLjE2OTczNjE5NzQuMjIuMC4w';
  GarmentsGender: any;
  garmentLink: any;
  SelectedGender: any = 'M';
  isShowBrandBand: boolean = true;
  loadingState: boolean;

  logInFlag: boolean;
  brandNameSelected: any;
  inValue: HTMLElement;
  //New

  constructor(private route: ActivatedRoute, private pinterestAPI: PinterestService, private apiHttpService: ApiHttpService,
    private firestore: AngularFirestore, private dataSharingService: DataSharingService, public commonService: CommonService, private router: Router) {
    this.route.paramMap.subscribe(paramMap => {
      this.brandId = paramMap.get('brandId')
      this.userId = paramMap.get('id')
      this.storyId = paramMap.get('storyId')
    });

    this.brands.subscribe((brandList) => {
      this.brandDetailsAll = brandList
      this.filterBrandList = this.brandDetailsAll;
      this.loading = false
      if(this.brandId != undefined){
        console.log('brandObject object', this.brandDetailsAll)
        setTimeout(() => {
          let brandObject = this.brandDetailsAll.find((b)=> b.id === this.brandId)
          console.log('brandObject object',brandObject)
          this.selectBrandForStory(this.brandId,brandObject)
           },
            1000)
      };
    })
  }



  ngOnChanges() {
  }


  ngOnInit() {
    this.logInFlag = this.commonService.isLoggedIn()
    let title = 'Yologram - Style'
    this.commonService.changeTitle(title);
    this.loading = true;
    this.showBrandFlag = true
    this.pinterestAPI.getBoardList().subscribe((data) => {
      this.boards = data.items;
      this.brandsBoardCollections.subscribe((brandBoard) => {
        this.brandBoard = brandBoard;
      });
      // this.commonService.snapshotfileDetais().subscribe(async (snapshot: any) => {
      //   this.SnapShotDetails = snapshot
      //   this.loading = false;
      // })
    })
    // this.snapShotInit()
    this.brandCollecton()
    // this.checkPageAuthorization()



    this.garmentsList.subscribe((grList) => {
      this.garmentsCollection = grList
      // if (this.brandId != undefined) { this.selectBrandForStory(this.brandId, this.brands) }
      this.brands.subscribe((brandList) => {
        this.brandDetails = brandList
        let object = this.brandDetails
        this.brandDetailsId = object.find((bBoard) => bBoard.id == this.brandId)
        if (this.brandId != undefined) {
          this.selectedBrand = this.brandDetailsId;
          this.boardDetails = this.boardNameInBrandStory
          let brandBoardFilter = this.brandBoard.find((bBoard) => bBoard.brandId == this.brandId)
          this.boardDetails = (brandBoardFilter != undefined) ? this.boards.find((board) => board.id == brandBoardFilter.boardId) : undefined;
        }
      })


      let garmentIds = this.garmentsCollection.map((garment) => garment.id);
      let garmentsObject = this.garmentsCollection.reduce((acc, obj) => {
        const key = obj.id;
        const curGroup = acc[key] ?? [];
        return { ...acc, [key]: obj.BrandID };
      }, {});
      this.commonService.snapshotfileDetais().subscribe(async (snapshot: any) => {
        this.SnapShotFileDetails = snapshot;
        this.SnapShotFileDetails.forEach((snapshot) => {
          if (garmentsObject[snapshot.HeadwearID] != undefined
            || garmentsObject[snapshot.BottomwearID] != undefined
            || garmentsObject[snapshot.UpperwearID] != undefined
            || garmentsObject[snapshot.FootwearID] != undefined
          )
            if (garmentsObject[snapshot.HeadwearID] != undefined && this.snapShotBrandList.indexOf(garmentsObject[snapshot.FootwearID]) == -1) this.snapShotBrandList.push(garmentsObject[snapshot.FootwearID]);
            if (garmentsObject[snapshot.BottomwearID] != undefined && this.snapShotBrandList.indexOf(garmentsObject[snapshot.BottomwearID]) == -1) this.snapShotBrandList.push(garmentsObject[snapshot.BottomwearID]);
            if (garmentsObject[snapshot.UpperwearID] != undefined && this.snapShotBrandList.indexOf(garmentsObject[snapshot.UpperwearID]) == -1) this.snapShotBrandList.push(garmentsObject[snapshot.UpperwearID]);
            if (garmentsObject[snapshot.FootwearID] != undefined && this.snapShotBrandList.indexOf(garmentsObject[snapshot.FootwearID]) == -1) this.snapShotBrandList.push(garmentsObject[snapshot.FootwearID]);
        })
      })
    })



  }



  selectByScroll() {
    this.byScroll = true
  }
  closeButtonForBrand() {
    this.byScroll = false
    this.brandNameSelected = this.selectedBrand.BrandName
    const inputElement = document.getElementById("BrandName") as HTMLInputElement;
    // Now you can safely set its value to blank
    if (inputElement) {
      inputElement.value = "";
    }
  }
  changeClose(Evenvt){
    if(!Evenvt){
      this.closeButtonForBrand()
    }
  }

  getBrandImage(imgURL: string) {
    let brandEncodedurl = encodeURI(imgURL).replace('/', '%2F');
    let url = "https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/" + brandEncodedurl + "?alt=media&token=550e7e88-bed3-4668-84ff-ad491c19e06c"
    return url;
  }


  // showBrandDetails(brand: any) {
  //   this.selectedBrand = brand;

  // }


  // selectBrandForStory(brandId: any, brands: any) {
  //   this.brandsIDdetailsAll = brandId
  //   this.thresholdHeightPagination = 0
  //   this.showBrandFlag = false
  //   this.byScroll = false
  //   this.selectedBrand = brands
  //   this.brandId = brandId;
  //   this.boardDetails = {}



  //   let brandBoardFilter = this.brandBoard.find((bBoard) => bBoard.brandId == brandId);
  //   let brandName = this.boards.find((bname) => bname.id == brandBoardFilter.boardId != undefined ? brandBoardFilter.boardId : ' ' );
   
  //   this.boardDetails = brandName != undefined ?  brandName : ':Not Linked';


  //   if (this.isShowBrandBand) {
  //     this.selectedTab = "news-stories"
  //   }

  //   this.clickEventFlag = !this.clickEventFlag
  //   this.addState()

  //   // if (!brandBoardFilter) {
  //   //   console.error('Brand board filter not found for brandId:', brandId);
  //   //   return; // or handle the error appropriately
  //   // }
  //   // if (!brandName) {
  //   //   console.error('Brand name not found for boardId:', brandBoardFilter.boardId);
  //   //   return; // or handle the error appropriately
  //   // }
  // }se

  selectBrandForStory(brandId: any, brands: any) {
   const inputElement = document.getElementById("BrandName") as HTMLInputElement;
   // Now you can safely set its value to blank
      if (inputElement) {
          inputElement.value = "";
      }
    this.filterBrandList = this.brandDetailsAll
    this.brandsIDdetailsAll = brandId;
    this.thresholdHeightPagination = 0;
    this.showBrandFlag = false;
    this.byScroll = false;
    this.selectedBrand = brands;
    this.brandId = brandId;
    this.boardDetails = {};
    let brandBoardFilter = this.brandBoard.find((bBoard) => bBoard.brandId == brandId);
    if (brandBoardFilter) {
      let brandName = this.boards.find((bname) => bname.id == brandBoardFilter.boardId);
      this.boardDetails = brandName;
    }
    this.brandNameSelected = this.selectedBrand.BrandName

    if (this.isShowBrandBand) {
      this.selectedTab = "news-stories";
    }
    this.clickEventFlag = !this.clickEventFlag;
    this.addState();
}
blankplaceHolder(){
  this.brandNameSelected = ''
}


  colaaps() {
    this.showBCollasps = true
    this.showRender = false
  }
  selectBrandForScroll() {
    document.querySelector('#target').scrollIntoView({ behavior: 'smooth', block: 'center' });
  }
  brandCollecton() {
    let brandsNID = this.brandDetails.filter((nameOfBrand) => nameOfBrand.id === this.garmentsCollection.filter((brandOfGArment) => brandOfGArment.BrandID))
  }

  // snapShotInit() {
  //   this.commonService.snapshotfileDetais().subscribe(async (snapshot: any) => {
  //     this.SnapShotDetails = snapshot
  //   })
  // }

  setTab(tab, gender) {
    this.isShowBrandBand = false
    this.selectedTab = "";
    this.selectedTab = tab;
    this.SelectedGender = gender
    // this.urlAvatar = urlMale
    // this.urlAvatar = this.selectedTab == 'mens-wear' ?  this.urlAvatarMale : this.urlAvatarFeMale
    if (tab == 'story' || tab == 'news-stories') {
      this.isShowBrandBand = true
    }

    console.log('tab event', tab)
    if (tab == 'mens-wear') {
      this.genderFlag = true
    } else {
      this.genderFlag = false
    }

  }

  changeTab(event) {
    this.isShowBrandBand = false
    this.onChangeFlag = false;
    this.selectedTab = event;
    console.log('Tab', event)
    if (event == 'story' || event == 'news-stories') {
      this.isShowBrandBand = true
    }
    console.log('tab event', event)

  }

  changeCloth(event) {
    let clothId = event;
    this.colaaps()
    this.copyTextToClipGarment(clothId)
  }

  changeLoadingState(event) {
    this.loadingState = event
    console.log('loadingState otutside component', event)

  }


  clickOnHeart() {
    this.heartFlag = true
  }
  onScroll() {
    this.height = this.BrandStoriyScroll.nativeElement.scrollTop
  }

  addState() {
    let stateObj = { id: "100" };
    let setUrl = this.stylePage
    if (setUrl != undefined) {
      this.url = this.stylePage
    }
    
    let style3DPage = '/threads;brandId=';
    // let pageUrl = this.stylePage != undefined ||  this.stylePage != ''? this.stylePage+";brandId=" : "/public/public-brand-stories;brandId="
    let url = style3DPage + this.brandId
    window.history.pushState(stateObj, "Page", url);
  }



  copyTextToClipGarment(text: string) {
    let Garments = this.garmentsCollection.find((gaementList) => gaementList.id === text);
    let brandId = Garments.BrandID
    let gender = Garments.Gender;
    this.GarmentsGender = Garments.Gender;
    let garmentType = Garments.GarmentType
    let zipfilename = Garments.id
    let packlocation = "AvatarBlenderClothes%2F" + brandId + "_" + gender + "%2F" + garmentType + "%2F" + zipfilename;
    this.garmentLink = 'https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/' + packlocation + '?alt=media&token=2c1dc261-ac91-4c32-a5ec-ac5e06f0a9bc&_gl=1*goch74*_ga*MjExMjI5MTIyMi4xNjg3NTA3MDk1*_ga_CW55HF8NVT*MTY5NjkzMDY1Ny4xNTcuMS4xNjk2OTM2NzA4LjU5LjAuMA..'
  }
  suggest($event) {
    this.byScroll=true;


    let inputValue = $event.target.value.toLowerCase(); // Convert input to lowercase for case-insensitive matching
    if (!inputValue) {
      // If inputValue is blank or null, show all brands
      this.filterBrandList = this.brandDetailsAll;
    } else {
      const startsWithMatches = this.brandDetailsAll.filter(brand => {
        const brandName = brand.BrandName;
        return brandName && brandName.toLowerCase().startsWith(inputValue);
      });
      const includesMatches = this.brandDetailsAll.filter(brand => {
        const brandName = brand.BrandName;
        return brandName && brandName.toLowerCase().includes(inputValue);
      }).filter(brand => !startsWithMatches.includes(brand)); // Exclude items already included in startsWithMatches
      this.filterBrandList = startsWithMatches.slice(0, 10).concat(includesMatches.slice(0, 10));
    }
  }

}

