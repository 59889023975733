<div class="card">
  <div class="dt-responsive selectlist font-14">
    <div class="dataTables_wrapper dt-bootstrap4" id="add-row-table_wrapper">
      <div class="parent ">
        <div class="d-flex">
          <div class="child p-2">&nbsp;&nbsp;&nbsp;Menswear&nbsp;&nbsp;&nbsp;</div>
          <div class="slider-css ">
            <div class="img-male-female p-2" *ngFor="let brand of mensBrands ">
              <img style="width: 100px; height:100px;" (click)="showBrandPackDetails(brand,'M')"
              [ngClass]="selectedBrand === brand ? 'shadow-brand' : (selectedBrand != undefined ? 'blug-css' :'')"  [src]="getBrandImage(brand.LogoURL)" />
            </div>
          </div>
        </div>
        <div class="d-flex">
          <div class="child p-2">Womenswear</div>
          <div class="slider-css ">
            <div class="img-male-female p-1" *ngFor="let brand of womensBrands ">
              <img style="width: 100px; height:100px;" (click)="showBrandPackDetails(brand,'F')"
              [ngClass]="selectedBrand === brand ? 'shadow-brand' : (selectedBrand != undefined ? 'blug-css' :'')"  [src]="getBrandImage(brand.LogoURL)" />
            </div>
          </div>
        </div>

      </div>

    </div>
    <div class="col-lg-12 p-1 dataTables_wrapper dt-bootstrap4">
      <div class="card col-lg-0 text-center justify-content-center">
        <span *ngIf="selectedBrand != undefined " class="d-block">
          <span>
            <h3 class="col-lg-0 m-2">{{selectedBrand.BrandName}}</h3>
            <button type="button" class="btn btn-sm btn-outline-info">Follow</button>
          </span>
          <h6 class="m-1" *ngIf="boardDetails != undefined"><img style="width: 15px !important" src="./assets/pinterest-512.jpg">:
            {{boardDetails.name}}</h6>
          <h6  class="m-1" *ngIf="boardDetails == undefined"><img style="width:15px !important" src="./assets/pinterest-512.jpg">:
            Not Linked To Board</h6>
        </span>
      </div>
      <div class="col-lg-12 p-1">
        <span *ngIf="errMessage != '' " class="danger">
          <div class="alert alert-danger" role="alert">
            {{errMessage}}
          </div>
        </span>
      </div>
    </div>

    <div class="card file" id="target" *ngIf="!isloading">
      <div class="row g-2 form d-block  " *ngIf="packJsonObject.length > 0">
        <div class="slider-css211 ">
          <div *ngFor="let object of packJsonObject[0].value['files'];let i=index" class="slider-img211">
            <img class="img-responsive1" [src]="sanitizer.bypassSecurityTrustUrl(zipList[object])" />
          </div>
        </div>

        <div class="slider-css2">
          <div *ngFor="let object of packJsonObject[1].value['files'];let i=index" class="slider-img2">
            <img class="img-responsive" [src]="sanitizer.bypassSecurityTrustUrl(zipList[object])" />
          </div>
        </div>

        <div class="slider-css2">
          <div *ngFor="let object of packJsonObject[2].value['files'];let i=index" class="slider-img2">
            <img class="img-responsive" [src]="sanitizer.bypassSecurityTrustUrl(zipList[object])" />
          </div>
        </div>

        <div class="slider-css211">
          <div *ngFor="let object of packJsonObject[3].value['files'];let i=index" class="slider-img211">
            <img class="img-responsive1" [src]="sanitizer.bypassSecurityTrustUrl(zipList[object])" />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="d-flex justify-content-center">
  <div class="spinner-border" *ngIf="isloading" role="status">
    <span class=""></span>
  </div>
</div>