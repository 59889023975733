import { Component, ElementRef, EventEmitter, Inject, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { PinterestService } from 'src/app/services/pinterest-service';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { catchError, map, Observable, Subscription, tap } from 'rxjs';
import { Brand } from 'src/app/models/brand';
import { Snapshot } from 'src/app/models/snapshot';
import { ApiHttpService } from 'src/app/services/api-http.service';
import { environment } from 'src/environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { CommonService } from 'src/app/services/common.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DataSharingService } from 'src/app/services/data-sharing-service';
import { DOCUMENT } from '@angular/common';
import * as JSZip from 'jszip/dist/jszip'
import { DomSanitizer } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { Console } from 'console';
import { PopupTwoDComponent } from 'src/app/common/popup-two-d/popup-two-d.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GarmentPopupComponent } from 'src/app/common/templets/garment-popup/garment-popup.component';
@Component({
  selector: 'app-zip-style',
  templateUrl: './zip-style.component.html',
  styleUrls: ['./zip-style.component.css']
})
export class ZipStyleComponent implements OnInit {

  @Input() brandGarments: any;
  @Input() brandsIDdetailsAll: any;
  @Input() selectBrandGender: any;
  @Input() tab: any;
  @Input() brandsDetails: any;
  @Input() onChangeFlag: boolean;
  @Input() loading: boolean;
  @Input() brandId: any;

  @Output() sendBrandData = new EventEmitter();

  @ViewChild('pinModel', { static: false }) pinModal: ElementRef<HTMLInputElement> = {} as ElementRef;
  @ViewChild("snapshotfeeds") snapShotFeeds?: ElementRef;

  @Output() changeSelectedTab = new EventEmitter<string>();

  environmentCollection = this.firestore.collection('Environments').valueChanges({ idField: 'id' }) as Observable<Brand[]>;
  userFilesList = this.firestore.collection('userfiles').valueChanges({ idField: 'id' }) as Observable<any[]>;
  brandsFilesList = this.firestore.collection('brands').valueChanges({ idField: 'id' }) as Observable<Brand[]>;
  brandBoardList = this.firestore.collection('brand-board').valueChanges({ idField: 'id' }) as Observable<any[]>;
  pinterestBoardPinsStatus = this.firestore.collection('pinterest-board-pins-status').valueChanges({ idField: 'id' }) as Observable<Snapshot[]>;
  garmentsList = this.firestore.collection('garments').valueChanges({ idField: 'id' }) as Observable<any[]>;
  brandBoardCollection = this.firestore.collection('brand-board').valueChanges({ idField: 'id' }) as Observable<any[]>;
  profileName: any
  currentlySelected;
  color = 'primary';
  mode = 'indeterminate';
  value = 50;
  displayProgressSpinner = false;
  spinnerWithoutBackdrop = false;
  offsetPrevious = 0;
  scrolling = 'down';
  scrollingWidth = 50;
  indexMapping = 0;
  scrollPixel = 0;
  private timeout: any;
  snapshotCollection: any = [];
  garmentsCollection: any = [];
  brandboardCollection: any = [];
  brandsCollection: any = [];
  storyPinterestPinsList: any = [];
  subscription: Subscription | undefined;
  scrollselected: any = [];
  identifierList: any = {};
  slides: any[] = new Array(3).fill({ id: -1, src: '', title: '', subtitle: '' });
  clothDetails: any = {};
  selectedBrand: any = {};
  brandDetails: any;
  brands: any = [];
  boardId: any;
  boards: any;
  pinImg: string = "";
  SnapShotFileDetails: any = []
  filterSnapShotFileDetails: any = []
  filterSnapShotFileDetailsDetalis: any = []
  isLoading = false;
  topScroll: number;
  pageScrollFlag: boolean = false;
  SnapShotDetails: any = []
  clocthCollectionIdcollection: any = []
  clothShapShotsList: any = []
  profileDetails: any = {}
  filterSnapShotForProfile: any = [];
  filterSnapShotForAvatar: any = [];
  //For Style
  binaryImg: any;
  zipFile: any;
  testVar: any = 'Prashatn';
  selectedBrandId: any = "";
  errMessage: any = "Select The Brand ";
  errMessageForMen: any = "Select The Brand ";
  errMessageForWomen: any = "Select The Brand ";
  packJsonObject = []
  zipList: any = {};
  isloading: boolean = false
  isLoadingStyle: boolean = false
  garmetBinaryImgDetails: any
  garmentTypeDetails: any
  imageObject: any
  evnFileListFileName: any
  filterEnvironmentSnpShot: any = [];
  clothShapShotsListALL: any = [];
  next: number = 1
  isLoadingStory: boolean = true
  keyType: any
  rootForNAvigation :any ='yologram-social/yologram-user-profile-feed'








  pindetails: any = [];

  pinterstJsonMap = {
    "StoryID": undefined,
    "upperwear": undefined,
    "footwear": undefined,
    "headwear": undefined
  }
  setIndex: number
  imageList: any = this.pinImg;
  // brandId: any;
  StoryID: any;
  UpperwearId: any;
  pinId: any;
  typeSelected: any;
  boardsName: any;
  filterByUserFlag: boolean = false;
  userId: string;
  snpUrl: any
  storyId: string;
  brandCollectionIdcollection: any = [];
  brandhShapShotsList: any = []
  selectedAvtar: any
  userSnapShotList: any = []
  userUploadedFileList: any = [];
  userFiledetails: any = [];
  counter: number = 4;
  showImages: any = {}
  selectedGender: any;
  evnFileList: any = [];
  SnapShotFileEnv: any = [];
  id: any
  allUserDetails: any;


  //  identifierDetail

  constructor(
    @Inject(DOCUMENT) document: Document,
    private dataSharingService: DataSharingService,
    public dialog: MatDialog,
    private firestore: AngularFirestore,
    public router: Router,
    private route: ActivatedRoute,
    private commonService: CommonService,
    private apiHttpService: ApiHttpService,
    private pinterestAPI: PinterestService,
    public sanitizer: DomSanitizer,
    private _httpClient: HttpClient,
    private modal: NgbModal,
  ) {

    this.typeSelected = 'ball-fussion';
    this.brandBoardList.subscribe(
      brandboard => {
        this.brandboardCollection = brandboard;
      });


    this.dataSharingService.userIdObservable.subscribe(snapshot => {
      this.id = snapshot

    })
    this.garmentsList.subscribe(
      garments => {
        this.garmentsCollection = garments;
      });

    this.brandsFilesList.subscribe((brands) => {
      this.brandsCollection = brands;
    });

    this.environmentCollection.subscribe(
      (evnFile) => {
        this.evnFileList = evnFile
      });


    this.pinterestBoardPinsStatus.subscribe(
      response => {
        this.storyPinterestPinsList = response

      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.isLoadingStyle = false

    this.isLoadingStory = true
    let reqInputObject = { id: this.brandId, pageNumber: 1 }
    this.commonService.yologramSnapshotByBrands(reqInputObject).subscribe(async (response: any) => {
      this.userSnapShotList = response.object;
      this.userSnapShotList.forEach(async (snap: any, index) => {
        let userObjet = this.allUserDetails.find(userObject => userObject.uid == snap.UserID)
        snap.profileName = userObjet.displayName != undefined ? userObjet.displayName : snap.UserID;
        userObjet.profileName = snap.profileName
        snap.userData = userObjet;
        snap.firstName = snap.profileName.split(" ", 1);
        snap.AvatarLastFive = snap.AvatarID.substr(snap.AvatarID.length - 5);
      })
    })


    this.slides[0] = {
      src: 'snapshot.SnapshotFileUrl',
    };
    //if (this.onChangeFlag) {
    this.getPinterestBoards();
    this.displayProgressSpinner = true;
    // this.snapShotInit()
    this.showBrandPackDetails()
    //}
    // this.infoPopup()
  }




  ngOnInit(): void {
    this.isLoadingStyle = false
    this.getAllUsers()

  }

  getAllUsers() {
    this.commonService.getAllUser().subscribe(async (allUsers: any) => {
      this.allUserDetails = allUsers.users;
    })
  }

  getSnapShotList() {
    this.filterByUserFlag = false;
    this.filterSnapShotFileDetails = this.SnapShotFileDetails;
    this.dataSharingService.sendUserSnapShots(this.filterSnapShotFileDetails)
  }

  getUserSnapShotDashboar(userId) {
    let userSnapShotList = this.filterSnapShotFileDetails.filter((snapshot) => snapshot.UserID == userId);
    this.dataSharingService.sendUserSnapShots(userSnapShotList);
    this.router.navigate(['public/news', { 'id': userId }])
  }

  getSnapShotListByUser(snapshot: any) {
    let userId = snapshot.UserID;
    this.filterByUserFlag = true
    this.filterSnapShotFileDetails = this.SnapShotFileDetails.filter((snapshot) => snapshot.UserID == userId);
  }

  get snapshotCollectionSort() {
    return this.snapshotCollection.sort((a, b) => {
      return <any>new Date(b.timestamp) - <any>new Date(a.timestamp);
    });
  }

  onItemChange($event: any): void {
  }

  handleError(error: any) {
    console.log(error)
  }

  setClothDetails(garmentId: string) {
    if (this.garmentsCollection.length > 0) {
      this.clothDetails = this.garmentsCollection.find((b: any) => b.id === garmentId);
      let brandObject = this.brandsCollection.find((b: any) => b.id === this.clothDetails.BrandID);
      this.clothDetails['BrandName'] = brandObject != undefined ? brandObject.BrandName : '';
      this.clothDetails['LogoURL'] = brandObject != undefined ? brandObject.LogoURL : '';
      let brandGarments = this.garmentsCollection.filter((gaementList) => gaementList.id == this.clothDetails.id);
      this.clocthCollectionIdcollection = brandGarments.map((garment) => garment.id);
      this.clothShapShotsList = this.SnapShotDetails.filter((snapshot) => {
        return this.clocthCollectionIdcollection.indexOf(snapshot.BottomwearID) >= 0 ||
          this.clocthCollectionIdcollection.indexOf(snapshot.HeadwearID.trim()) >= 0 ||
          this.clocthCollectionIdcollection.indexOf(snapshot.FootwearID.trim()) >= 0 ||
          this.clocthCollectionIdcollection.indexOf(snapshot.UpperwearID.trim()) >= 0
      }).slice(0, 9);
      this.clothShapShotsListALL = this.SnapShotDetails.filter((snapshot) => {
        return this.clocthCollectionIdcollection.indexOf(snapshot.BottomwearID) >= 0 ||
          this.clocthCollectionIdcollection.indexOf(snapshot.HeadwearID.trim()) >= 0 ||
          this.clocthCollectionIdcollection.indexOf(snapshot.FootwearID.trim()) >= 0 ||
          this.clocthCollectionIdcollection.indexOf(snapshot.UpperwearID.trim()) >= 0
      }).slice(0, 9);
    }
  }

  setBrandDetails(garmentId: string) {
    if (this.garmentsCollection.length > 0) {
      let grandmentObject = this.garmentsCollection.find((b: any) => b.id === garmentId);
      if (grandmentObject && grandmentObject['Name'] != undefined) {
        let brandId = grandmentObject["BrandID"];
        this.brandDetails = this.brandsCollection.find((b: any) => b.id === brandId);
        this.brandDetails['boardName'] = 'Not Link to Board';
        let brandBoardObject = this.brandboardCollection.find((b: any) => b.brandId == brandId);
        if (brandBoardObject != undefined) {
          let boardId = brandBoardObject && brandBoardObject['boardId'] != undefined ? brandBoardObject['boardId'] : undefined;
          let boardDetails = this.boards.find((b: any) => b.id == boardId);
          if (boardDetails != undefined && boardDetails['name'] != undefined) this.brandDetails['boardName'] = boardDetails['name'];
        }
      }
      let brandGarments = this.garmentsCollection.filter((gaementList) => gaementList.BrandID === grandmentObject.BrandID);
      this.brandCollectionIdcollection = brandGarments.map((garment) => garment.id);
      this.brandhShapShotsList = this.SnapShotDetails.filter((snapshot) => {
        return this.brandCollectionIdcollection.indexOf(snapshot.BottomwearID) >= 0 ||
          this.brandCollectionIdcollection.indexOf(snapshot.HeadwearID.trim()) >= 0 ||
          this.brandCollectionIdcollection.indexOf(snapshot.FootwearID.trim()) >= 0 ||
          this.brandCollectionIdcollection.indexOf(snapshot.UpperwearID.trim()) >= 0
      }).slice(0, 9);
    }
  }

  getGarmentsDetails(garmentId: string) {
    let garmentTitle = "";
    let name = garmentId;
    let brandName = ""
    if (this.garmentsCollection.length > 0) {
      let grandmentObject = this.garmentsCollection.find((b: any) => b.id === garmentId);
      //   return 
      if (grandmentObject && grandmentObject['Name'] != undefined) {
        name = grandmentObject['Name'];
        let brandId = grandmentObject["BrandID"];
        let brandObject = this.brandsCollection.find((b: any) => b.id === brandId);
        brandName = brandObject != undefined && brandObject.BrandName != undefined ? brandObject.BrandName : '';
      }
    }
    garmentTitle = "<a (click)=\"showBrand('12232')\" href=\"showBrand('12232')\" />" + name + "</a>";
    // if(brandName != " ") garmentTitle += "   ( <a data-bs-toggle='modal' data-bs-target='#brand-details' >"+ brandName + " <a/>)";
    return garmentTitle;
  }


  getGarmentName(garmentId: string) {
    let garmentTitle = "";
    let name = garmentId;
    let brandName = ""
    if (this.garmentsCollection.length > 0) {
      let grandmentObject = this.garmentsCollection.find((b: any) => b.id === garmentId);
      if (grandmentObject && grandmentObject['Name'] != undefined) {
        garmentTitle = grandmentObject['Name'];
      }
    }
    return garmentTitle;
  }

  getBrandName(garmentId: string) {
    let garmentTitle = "";
    let name = garmentId;
    let brandName = ""
    if (this.garmentsCollection.length > 0) {
      let grandmentObject = this.garmentsCollection.find((b: any) => b.id === garmentId);
      if (grandmentObject && grandmentObject['Name'] != undefined) {
        let brandId = grandmentObject["BrandID"];
        let brandObject = this.brandsCollection.find((b: any) => b.id === brandId);
        brandName = brandObject != undefined && brandObject.BrandName != undefined ? brandObject.BrandName : '';

      }
    }
    return brandName;
  }


  // showBrand(tab: any) {
  //   this.selectedBrand = brand;
  // }

  getBrandImage(imgURL: string) {
    let brandEncodedurl = encodeURI(imgURL).replace('/', '%2F');
    let url = "https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/" + brandEncodedurl + "?alt=media&token=550e7e88-bed3-4668-84ff-ad491c19e06c"


    return url;
  }
  getPinterestBoards() {
    this.pinterestAPI.getBoardList().subscribe((data: {
      name: any; items: any;
    }) => {
      this.boards = data.items
    });

  }
  selectedBoard($event: any) {
    this.boardId = $event.target.value;
  }

  shareOnPinterest(garmentId: any, type: any, snapshotFileUrl: any, storyID: any) {
    // this.loading = true;
    this.loading = true;

    let brandId: any;
    let boardId: any;

    if (this.garmentsCollection.length > 0) {
      let grandmentObject = this.garmentsCollection.find((b: any) => b.id === garmentId);
      if (grandmentObject && grandmentObject['Name'] != undefined) {
        brandId = grandmentObject["BrandID"];
        let brandBoardObject = this.brandboardCollection.find((b: any) => b.brandId == brandId);
        boardId = brandBoardObject && brandBoardObject['boardId'] != undefined ? brandBoardObject['boardId'] : undefined;
      }

    }

    if (boardId == undefined || brandId == '') {
      this.isLoading = false;
      this.loading = false;
      alert("This brand is not linked with Pinterest board.")
      return true;
    }

    let jsonObject = {
      'avatarURL': snapshotFileUrl,
      'boardId': boardId
    }
    let jsonInput = this.pinterstJsonMap
    let storyObject = this.storyPinterestPinsList.find((b: any) => b.StoryID == storyID);




    if (storyObject && storyObject[type] != undefined) {
      let jsonInpt = { 'pinId': storyObject[type], };
      this.pinterestAPI.getPinterestPin(jsonInpt).subscribe((data) => {
        this.displayProgressSpinner = false;
        this.pinImg = data.media.images['400x300'].url;
        this.openModel();
        this.loading = false;
      });
      return true
    }
    this.pinterestAPI.createPinterestPin(jsonObject).subscribe((result) => {

      if (storyObject == undefined) {
        let pinsMappingObject: any = {
          'StoryID': storyID,
        }
        if (type == 'headwear') pinsMappingObject['headwear'] = result.id
        if (type == 'footwear') pinsMappingObject['footwear'] = result.id
        if (type == 'upperwear') pinsMappingObject['upperwear'] = result.id
        if (type == 'headwear') pinsMappingObject['headwear'] = result.id
        this.firestore.collection('pinterest-board-pins-status').add(pinsMappingObject)
      } else if (storyObject && storyObject[type] == undefined) {

        if (type == 'headwear') storyObject['headwear'] = result.id
        if (type == 'footwear') storyObject['footwear'] = result.id
        if (type == 'upperwear') storyObject['upperwear'] = result.id
        if (type == 'headwear') storyObject['headwear'] = result.id
        this.firestore.collection('pinterest-board-pins-status').doc(storyObject['id']).update(storyObject);
      }

      this.isLoading = false;
      alert("Pin published successfully.");
      return true;
    });

    return false;
  }





  linkBrandToBoard() {

    if (this.boardId == "" || this.brandId == '') {
      return false;
    }
    let brandBoardMappingObject = {
      'brandId': this.brandId,
      'boardId': this.boardId
    }
    this.firestore.collection('brand-board').add(brandBoardMappingObject)
    return true;
  }

  ngAfterViewChecked(): void {


    if (this.storyId != undefined && this.topScroll != undefined && this.snapShotFeeds != undefined && this.snapShotFeeds.nativeElement != undefined && !this.pageScrollFlag) {

      this.snapShotFeeds.nativeElement.scrollTop = this.topScroll;
      this.pageScrollFlag = true
    }
  }

  getBrandStories(brand: any) {
    this.router.navigate(['yologram-social/yologram-brand-stories', { 'brandId': brand, }])
  }


  getBrandProfile(brand: any, board: any) {
    let brandGarments = this.garmentsCollection.filter((gaementList) => gaementList.BrandID === brand.id);
    this.brandCollectionIdcollection = brandGarments.map((garment) => garment.id);
    let SnapshotWithbrandGarment = this.SnapShotDetails.filter((snapshot) => {
      return this.brandCollectionIdcollection.indexOf(snapshot.BottomwearID) >= 0 ||
        this.brandCollectionIdcollection.indexOf(snapshot.HeadwearID.trim()) >= 0 ||
        this.brandCollectionIdcollection.indexOf(snapshot.FootwearID.trim()) >= 0 ||
        this.brandCollectionIdcollection.indexOf(snapshot.UpperwearID.trim()) >= 0
    })

    this.dataSharingService.sendUserSnapShots(SnapshotWithbrandGarment);
    this.dataSharingService.sendBrandBrandBoard(brand.boardName)
    this.dataSharingService.sendBrandBrandBoard(board)
    this.router.navigate(['public/public-brand-profile', { 'brandId': brand.id, 'board': board }])
  }


  getProfileByAvator(snapshot: any) {
    let userId = snapshot.UserID;
    this.filterByUserFlag = true
    let filterSnapShotFileDetails = this.SnapShotDetails.filter((snapshotlist) => snapshotlist.AvatarID == snapshot.AvatarID);
    this.dataSharingService.sendUserSnapShots(filterSnapShotFileDetails);
    this.router.navigate(['public/news', { 'id': userId, 'avatarID': snapshot.AvatarID }])
  }

  scrollToStory(stroyId) {
    let brandIndex = 1;
    this.SnapShotDetails.forEach(async (snap: any, index) => {
      if (stroyId.StoryID != undefined && stroyId.StoryID === snap.StoryID) {
        this.topScroll = brandIndex * 670;
      }
      if ((
        this.brandGarments.indexOf(snap.BottomwearID.trim()) >= 0 ||
        this.brandGarments.indexOf(snap.HeadwearID.trim()) >= 0 ||
        this.brandGarments.indexOf(snap.FootwearID.trim()) >= 0 ||
        this.brandGarments.indexOf(snap.UpperwearID.trim()) >= 0
      )) { brandIndex = brandIndex + 1; }
    });

  }


  scrollToStoryId(snapshot) {
    this.tab = "news-stories"
    this.changeSelectedTab.emit('news-stories');
    let brandIndex = 1;
    // this.filterSnapShotFileDetails.forEach(async (snap: any, index) => {
    //   // if (snapshot.StoryID != undefined && snapshot.StoryID === snap.StoryID) {
    //   //   this.topScroll = brandIndex * 670;
    //   //   this.snapShotFeeds.nativeElement.scrollTop = this.topScroll;
    //   //   this.pageScrollFlag = true;
    //   // }
    //   if ((
    //     this.brandGarments.indexOf(snap.BottomwearID.trim()) >= 0 ||
    //     this.brandGarments.indexOf(snap.HeadwearID.trim()) >= 0 ||
    //     this.brandGarments.indexOf(snap.FootwearID.trim()) >= 0 ||
    //     this.brandGarments.indexOf(snap.UpperwearID.trim()) >= 0
    //   )) { brandIndex = brandIndex + 1; }
    // });

    // this.dataSharingService.sendUserSnapShots( this.filterSnapShotFileDetails)
    // console.log( "this.filterSnapShotFileDetails",this.filterSnapShotFileDetails)
  }

  getFeed(StoryID: any, brandName: any) {
    this.scrollToStory(StoryID);

  }
  getBrandStyle(brandName: any, gender: any,) {
    this.dataSharingService.sendUserSnapShots(this.brandhShapShotsList);
    this.router.navigate(['public/public-brand-style', { 'brandId': brandName, 'Gender': gender }])
  }

  getClothFeed(brand: any, brandId: any) {
    this.dataSharingService.sendUserSnapShots(this.clothShapShotsList)
    this.router.navigate(['public/public-brand-stories', { 'brandId': brandId, 'storyId': brand.StoryID }])
  }



  getClothProfile(brand: any,) {
    let clothAllDetails = this.clothDetails
    let brandGarments = this.garmentsCollection.filter((gaementList) => gaementList.id == this.clothDetails.id);
    this.clocthCollectionIdcollection = brandGarments.map((garment) => garment.id);
    this.clothShapShotsList = this.SnapShotFileDetails.filter((snapshot) => {
      return this.clocthCollectionIdcollection.indexOf(snapshot.BottomwearID) >= 0 ||
        this.clocthCollectionIdcollection.indexOf(snapshot.HeadwearID.trim()) >= 0 ||
        this.clocthCollectionIdcollection.indexOf(snapshot.FootwearID.trim()) >= 0 ||
        this.clocthCollectionIdcollection.indexOf(snapshot.UpperwearID.trim()) >= 0
    }).slice(0, 9);
    let snanpDetails = this.clothShapShotsList
    this.dataSharingService.sendUserSnapShots(snanpDetails);
    this.dataSharingService.sendBrandGarments(this.clocthCollectionIdcollection);
    this.dataSharingService.sendClothDetails(clothAllDetails)
    this.router.navigate(['public/public-garment-profile', { 'brandId': brand }])
  }

  getgarmentStyle(cloths: any) {
    this.dataSharingService.sendUserSnapShots(this.userSnapShotList);
    this.dataSharingService.sendClothDetails(cloths)
    this.router.navigate(['public/public-garment-style', { 'brandId': cloths.BrandID, 'clothsType': cloths.GarmentType, 'Gender': cloths.Gender, 'id': cloths.id }])
  }

  getStoryCloth(brandIds) {
    let snap = this.clothShapShotsList
    this.dataSharingService.sendUserSnapShots(snap)
    this.dataSharingService.sendClothDetails(brandIds)
    let brandid = brandIds.BrandID
    this.router.navigate(['public/public-garment-profile', { 'brandId': brandid, }])

  }
  getSnapShotListByUserPopup(snapshot: any) {
    let userId = snapshot.UserID;
    let url = environment.functionBaseUrl + "userProfileIdenifierById?id=" + userId;
    this.apiHttpService.get(url).subscribe((data: any) => {
      this.profileDetails = data
    })
    this.filterByUserFlag = true
    this.filterSnapShotForProfile = this.SnapShotDetails.filter((snapshot) => snapshot.UserID == userId).slice(0, 3);
    this.dataSharingService.sendUserSnapShots(this.filterSnapShotForProfile);
  }
  getuserfeed(uid) {
    this.filterSnapShotFileDetails = this.SnapShotDetails.filter((snapshot) => snapshot.UserID == uid)
  }

  getuserfeedPopup(uid) {
    this.filterSnapShotFileDetails = this.SnapShotDetails.filter((snapshot) => snapshot.UserID == uid)
  }
  getSnapShotListAvatarPopup(snapshot: any) {
    let userId = snapshot.UserID;
    let avatarId = snapshot.AvatarID
    let snap = this.SnapShotFileEnv.filter((snapshot) => snapshot.AvatarID == avatarId).slice(0, 9);
    this.filterSnapShotForAvatar = snap
    this.selectedAvtar = avatarId
    let url = environment.functionBaseUrl + "userProfileIdenifierById?id=" + userId;
    this.apiHttpService.get(url).subscribe((data: any) => {
      this.profileDetails = data
    })
    this.dataSharingService.sendUserSnapShots(this.filterSnapShotForProfile);
  }


  getProfileByAvatorpage(snapshot: any) {
    this.filterSnapShotFileDetails = this.SnapShotFileDetails.filter((snapshotlist) => snapshotlist.AvatarID == snapshot);
    const userId = this.filterSnapShotFileDetails.find((snapshotlist) => snapshotlist.UserID)
    this.dataSharingService.sendUserSnapShots(this.filterSnapShotFileDetails);
    this.router.navigate(['public/news', { 'id': userId.UserID, 'avatarID': snapshot }])
  }


  openImageBox(fileurl) {
    let url = fileurl
    let showImage = this.userFiledetails.find((file) => file.fileurl == url)
    this.showImages = showImage
  }


  snapDetails() {
    this.brandCollectionIdcollection = this.brandGarments;
    let SnapshotWithbrandGarment = this.SnapShotDetails.filter((snapshot) => {
      if (this.brandCollectionIdcollection.indexOf(snapshot.BottomwearID) >= 0 ||
        this.brandCollectionIdcollection.indexOf(snapshot.HeadwearID.trim()) >= 0 ||
        this.brandCollectionIdcollection.indexOf(snapshot.FootwearID.trim()) >= 0 ||
        this.brandCollectionIdcollection.indexOf(snapshot.UpperwearID.trim()) >= 0) {
      }
    })
  }






  createImageFromBlob(image: Blob) {
    let reader = new FileReader();
    reader.addEventListener(
      'load',
      () => {
        this.binaryImg = reader.result;
      },
      false
    );
    if (image) {
      reader.readAsDataURL(image);
    }
  }

  async getZipFileContent(fileName: any) {
    var binary = await this.zipFile
      .file(fileName)
      .async('blob')
      .then(function (text: any) {
        return text;
      });
    this.binaryImg = URL.createObjectURL(binary); // create an url. img.src = URL.
  }

  downloadZipFile() {
    this.isloading = true;
    this.isLoadingStyle = true
    this.testVar = 'Change';
    let brandname = this.selectedBrand;;
    let brandId = this.selectedBrandId
    let zipfilename = "%2Fpack0"
    let gender = this.selectedGender;
    let packlocation = "AvatarBlenderClothes%2F" + brandId + "_" + gender + zipfilename;
    let brandEncodedurl = packlocation
    let url = 'https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/' + brandEncodedurl + '?alt=media&token=46df48b9-1f7a-4b61-98c6-bff029734da4'
    this._httpClient
      .get(
        url,
        //        'https://firebasestorage.googleapis.com/v0/b/yolouploader.appspot.com/o/AvatarBlenderClothes%2F6XE3yz64QMFyblSyIHpf_M%2FtestforyoloUpload?alt=media&token=0738d062-2c7c-4ca1-8300-5963dba8f93a',
        {
          observe: 'response',
          responseType: 'blob',
        }

      )
      .subscribe(async (res) => {
        await this.processZipFile(res.body, this.processResponse.bind(this));
        this.isloading = false;
        this.isLoadingStyle = false
      },
        (error) => this.handleErrors(error)
      );
  }

  handleErrors(error) {
    this.packJsonObject = [];
    this.isloading = false;
    this.errMessage = "Pack is not found for this selection !!!"
    // this.errMessageForMen = "Pack is not found for this selection !!!"
    // this.errMessageForWomen = "Pack is not found for Women selection !!!"
    this.isLoading = false;
    this.isLoadingStyle = false;
  }


  processResponse(res: any[]) {
    this.packJsonObject = res;
  }

  processZipFile(data, callBackSetJsonObject) {
    this.testVar = 'Change 121';

    this.zipList = JSZip.loadAsync(data, callBackSetJsonObject, this.testVar)
      .then(function (zip) {
        /* Parse json file */
        var jsonExtn = /(.json)$/;
        Object.keys(zip.files)
          .filter((fileName) => {
            return jsonExtn.test(fileName.toLowerCase());
          })
          .map((jsonFileName) => {
            var jsonFile = zip.files[jsonFileName];
            return jsonFile.async('string').then((jsonString) => {
              let packJsonObject = JSON.parse(jsonString);

              let jsonResponseData =
                [
                  {
                    title: 'headgear',
                    value:
                    {
                      'files': packJsonObject['hFiles'].filter((data) => data != ''),
                      'name': packJsonObject['hName'].filter((data) => data.indexOf('deleted') < 0)
                    }
                  },
                  {
                    title: 'upperwear',
                    value: {
                      'files': packJsonObject['uFiles'].filter((data) => data != ''),
                      'name': packJsonObject['uName'].filter((data) => data.indexOf('deleted') < 0),

                    }
                  },
                  {
                    title: 'bottomwear',
                    value: {
                      'files': packJsonObject['bFiles'].filter((data) => data != ''),
                      'name': packJsonObject['bName'].filter((data) => data.indexOf('deleted') < 0)
                    }
                  },
                  {
                    title: "footwear",
                    value: {
                      'files': packJsonObject['fFiles'].filter((data) => data != ''),
                      'name': packJsonObject['fName'].filter((data) => data.indexOf('deleted') < 0)
                    },
                  }
                ]
              callBackSetJsonObject(jsonResponseData);

            });

          });
        var re = /(.jpg|.png|.gif|.ps|.jpeg | .json)$/;
        var promises = Object.keys(zip.files)
          .filter(function (fileName) {
            // don't consider non image files
            return re.test(fileName.toLowerCase());
          })
          .map(function (fileName) {
            var file = zip.files[fileName];

            if (fileName.indexOf('.json') > 0) {

            } else {
              return file.async('blob').then(function (blob) {
                return [
                  fileName.substring(0, fileName.lastIndexOf('.')), // keep the link between the file name and the content
                  URL.createObjectURL(blob), // create an url. img.src = URL.createObjectURL(...) will work
                ];
              });
            }
          });
        // `promises` is an array of promises, `Promise.all` transforms it
        // into a promise of arrays
        return Promise.all(promises);
      })
      .then(function (result) {
        // we have here an array of [fileName, url]
        // if you want the same result as imageSrc:
        return result.reduce(function (acc, val) {
          acc[val[0]] = val[1];
          return acc;
        }, {});
      })
      .then((result) => {
        this.testVar = 'Prasahtn Rahulkar';
        this.zipList = JSON.parse(JSON.stringify(result));
        // callback(result);
        return;
        //return result.reduce(...);
      })
      .catch(function (e) {
        console.error(e);
      });

  }

  returnZero() {
    return 0
  }

  showBrandPackDetails() {
    this.selectedBrandId = this.brandId
    this.selectedGender = this.tab == 'mens-wear' ? 'M' : this.tab == 'womens-wear' ? 'F' : ""
    this.errMessage = ""
    this.isloading = true
    this.isLoadingStyle = true
    this.downloadZipFile()
  }

  openModel() {
    const dialogRef = this.dialog.open(ModelViewerDilogStyle);
    dialogRef.componentInstance.pinImgSrc = this.pinImg;
    dialogRef.afterClosed().subscribe(result => {
      this.isLoading = false;
    });
  }

  environmentPopup(environment) {
    let evenListName = "Environments/"
    this.evnFileListFileName = this.evnFileList.find((snap) => snap.storageURL == evenListName + environment)
    let environmentSnapAllfile = this.SnapShotFileEnv.filter((snap) => snap.Environment === environment).slice(0, 9)
    this.filterEnvironmentSnpShot = environmentSnapAllfile
    this.evnFileListFileName['envFileName'] = environment
  }

  getEnvironmentName(environment) {
    let evenListName = "Environments/"
    let env = this.evnFileList.find((snap) => snap.storageURL == evenListName + environment)
    return env != undefined ? env['displayName'] : 'default';
  }

  getEnvironmentProfile(envFileName) {
    let evenListName = "Environments/"
    this.evnFileListFileName = this.evnFileList.find((snap) => snap.storageURL == evenListName + envFileName)
    let environmentSnapAllfile = this.SnapShotFileDetails.filter((snap) => snap.Environment === envFileName)
    this.dataSharingService.sendUserSnapShots(environmentSnapAllfile)
    this.router.navigate(['public/public-evn-profile', { 'evnFile': this.evnFileListFileName.displayName }])
  }
  getGarmentrealtype(garmentId: string) {
    let garmentTitle = "";
    let name = garmentId;
    let brandName = ""
    if (this.garmentsCollection.length > 0) {
      let grandmentObject = this.garmentsCollection.find((b: any) => b.id === garmentId);
      if (grandmentObject && grandmentObject['RealType'] != undefined) {
        garmentTitle = grandmentObject['RealType'];
      }
    }
    return garmentTitle;
  }
  DeatailsPopup(garmetBinaryImg, garmentType: any, object, garmentTypeForPopup) {
    this.garmetBinaryImgDetails = garmetBinaryImg
    this.garmentTypeDetails = garmentType
    this.imageObject = object
    this.keyType = garmentTypeForPopup
  }
  getgarmentStylefeed(brandName: any, cloths: any) {
    let snap = this.clothShapShotsListALL
    this.dataSharingService.sendUserSnapShots(snap)
    this.dataSharingService.sendClothDetails(cloths)
    if (this.router.url == '/yologram-social/zip-brand-pack') { this.router.navigate(['yologram-social/yologram-garment-feed', { 'brandId': cloths.BrandID, 'clothsType': cloths.GarmentType, 'Gender': cloths.Gender, 'id': cloths.id }]) }
    else this.router.navigate(['public/public-garment-feed', { 'brandId': cloths.BrandID, 'clothsType': cloths.GarmentType, 'Gender': cloths.Gender, 'id': cloths.id }])
  }

  infoPopup() {
    let infoObject = this.imageObject
    this.brandId = this.imageObject
    this.setClothDetails(infoObject)
    this.dataSharingService.sendUserSnapShots(this.userSnapShotList)
    this.openGarmentPopup(this.keyType, infoObject)
  }

  openGarmentPopup(fileType: any, key: any) {
    const modalRef = this.modal.open(GarmentPopupComponent, {
      modalDialogClass: "model-garmentPopup"
    });
    modalRef.componentInstance.id = 10;
    modalRef.componentInstance.key = fileType;
    modalRef.componentInstance.garmentId = key;
    modalRef.result.then((result) => {
      console.log(result);
    }).catch((error) => {
      console.log(error);
    });
  }
  incrementImgCounter() {
    this.next = this.next + 1;
    this.isLoadingStory = true
    let reqInputObject = { id: this.brandId, pageNumber: this.next }
    this.commonService.yologramSnapshotByBrands(reqInputObject).subscribe(async (response: any) => {
      this.userSnapShotList.push(...response.object)
    })
  }

  threeDPopup() {
    let gBinaryImg = this.garmetBinaryImgDetails
    let gType = this.garmentTypeDetails
    this.open2DModelPopUp(gBinaryImg, gType)
  }

  open2DModelPopUp(garmetBinaryImg, garmentType: any) {
    let garmentImg = this.sanitizer.bypassSecurityTrustUrl(garmetBinaryImg)
    this.isLoading = false;
    const dialogRef = this.dialog.open(PopupTwoDComponent, {
      panelClass: 'dailog-transparent-css'
    });
    dialogRef.componentInstance.garmentImg = garmentImg;
    dialogRef.componentInstance.garmentType = garmentType;
    dialogRef.componentInstance.pageType = "style";
    dialogRef.afterClosed().subscribe(result => {
      this.isLoading = false;
    });
  }

}

@Component({
  selector: 'model-viewer-dilog',
  templateUrl: './style-popup.html',
})
export class ModelViewerDilogStyle {
  @Input() pinImgSrc: string | undefined;

  constructor(public dialog: MatDialog) {
  }

}
