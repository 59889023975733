import { Component, ElementRef, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Brand } from 'src/app/models/brand';
import { ApiHttpService } from 'src/app/services/api-http.service';
import { CommonService } from 'src/app/services/common.service';
import { DataSharingService } from 'src/app/services/data-sharing-service';
import { PinterestService } from 'src/app/services/pinterest-service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-brand-profile',
  templateUrl: './brand-profile.component.html',
  styleUrls: ['./brand-profile.component.css']
})
export class BrandProfileComponent implements OnInit {
  brandsFilesList = this.firestore.collection('brands').valueChanges({ idField: 'id' }) as Observable<Brand[]>;
  brandBoardList = this.firestore.collection('brand-board').valueChanges({ idField: 'id' }) as Observable<any[]>;
  garmentsList = this.firestore.collection('garments').valueChanges({ idField: 'id' }) as Observable<any[]>;
  userId: string;
  userSnapShotList: any = [];
  profileName: any
  userName: any = []
  userDetails: any
  filterSnapShotFileDetails: any = []
  SnapShotFileDetails: any = []
  id: any;
  isLoadingforUserDetails: boolean = true;
  profileNameSrc: any = "profile";
  brandCollection: any = {}
  brandId: any
  brandProfileDetails: any = []
  garmentsCollection: any = []
  snapShotBrandList = [];
  brandGarments: any = []
  brandBoard: any
  womensBrands: any;
  mensBrands: any;
  brandListId:any=[];
  boardsDetails:any
  boards:any=[]
  boardNameDetails:any
  bname :any



  constructor(
    private firestore: AngularFirestore,
    private dataSharingService: DataSharingService,
    private route: ActivatedRoute,
    private apiHttpService: ApiHttpService,
    public router: Router,
    private pinterestAPI:PinterestService,private elementRef: ElementRef,


  ) {
  }
  ngAfterViewInit() {
    this.elementRef.nativeElement.ownerDocument
        .body.style.backgroundColor = '#2d2d2d';
}
  ngOnInit(): void {
    
   
    this.dataSharingService.userBrandGarmentObservable.subscribe(garments => {
      this.brandGarments = garments
    })
    this.dataSharingService.userSnapShotObservable.subscribe(snapshot => {
      this.userSnapShotList = snapshot
    })
    this.dataSharingService.userBrandBoardObservable.subscribe(board => {
      this.brandBoard = board
    })


    this.route.paramMap.subscribe(paramMap => {
      this.brandId = paramMap.get('brandId');
      this.bname = paramMap.get('bname');
      this.getPinterestBoards()
      this.brandsFilesList.subscribe(brandList => {
        this.brandCollection = brandList
        let brandIDforProfile = this.brandCollection.find((brandListProfile) => brandListProfile.id == this.brandId)
        this.brandProfileDetails = brandIDforProfile
        this.mensBrands = brandList.filter((b: any) => b.Gender === 'M' || b.Gender === 'B');
        this.womensBrands = brandList.filter((b: any) => b.Gender === 'F' || b.Gender === 'B');
      })
    })


    this.brandBoardList.subscribe((brandsId)=>{
      this.brandListId = brandsId
      let selectedBrandId =  this.brandListId.find((brandsIdList)=>brandsIdList.brandId == this.brandId)
      this.boardsDetails = selectedBrandId
    })
  }



  getPinterestBoards() {
    this.pinterestAPI.getBoardList().subscribe((data: {
      name: any; items: any;
    }) => {
      this.boards = data.items
      let bordNameForStyle = this.boards.find((boardsName)=> boardsName.id ==  this.boardsDetails.boardId)
      this.boardNameDetails = bordNameForStyle.name
    });
 }

  getBrandImage(imgURL: string) {
    let brandEncodedurl = encodeURI(imgURL).replace('/', '%2F');
    let url = "https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/" + brandEncodedurl + "?alt=media&token=550e7e88-bed3-4668-84ff-ad491c19e06c"
    return url;
  }
  getSnapShotListByUser(userId) {
    let userSnapShotList = this.userSnapShotList.filter((snapshot) => snapshot.UserID == userId);
    this.dataSharingService.sendUserSnapShots(userSnapShotList);
    this.router.navigate(['yologram-social/yologram-public-news', { 'id': userId }])
  }

  getSelectSnapshotFeed(userSnap) {
    let userId = userSnap.UserID
    let storyid = userSnap.StoryID
    let userSnapShotList = this.userSnapShotList.filter((snapshot) => snapshot.UserID == userId && snapshot.StoryID == storyid);
    this.dataSharingService.sendUserSnapShots(userSnapShotList);
  }

  getUserProfile() {
    let userId = this.userId
    this.dataSharingService.sendUserSnapShots(userId);
    this.router.navigate(['yologram-social/yologram-public-news', { 'id': userId }])
  }

  getBrandStories(brand: any) {
    this.router.navigate(['yologram-social/yologram-brand-stories', { 'brandId': this.brandProfileDetails['id'] }])
  }

  getFeed(brand: any, brandName: any) {
    let boardName = this.brandBoard
    this.router.navigate(['yologram-social/yologram-brand-stories', { 'brandId': brandName.id, 'storyId': brand.StoryID,'board':boardName }])
  }


  getBrandStyle(brandName:any,gender:any,){
    this.dataSharingService.sendUserSnapShots(this.userSnapShotList);
    let boardName = this.brandBoard
    this.router.navigate(['yologram-social/yologram-brand-style', { 'brandId':brandName, 'Gender': gender ,'board':boardName }])
  }
}


