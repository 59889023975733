import { Component, Input, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { PopupThreeDComponent } from 'src/app/common/popup-three-d/popup-three-d.component';
import { Brand } from 'src/app/models/brand';
import { ApiHttpService } from 'src/app/services/api-http.service';
import { CommonService } from 'src/app/services/common.service';
import { DataSharingService } from 'src/app/services/data-sharing-service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-garment-profile',
  templateUrl: './garment-profile.component.html',
  styleUrls: ['./garment-profile.component.css']
})
export class GarmentProfileComponent implements OnInit {

  brandsFilesList = this.firestore.collection('brands').valueChanges({ idField: 'id' }) as Observable<Brand[]>;
  userFilesList = this.firestore.collection('userfiles').valueChanges({ idField: 'id' }) as Observable<any[]>;
  garmentsList = this.firestore.collection('garments').valueChanges({ idField: 'id' }) as Observable<any[]>;
  userId: string;
  userSnapShotList: any;
  profileName: any
  userName: any = []
  userDetails: any
  filterSnapShotFileDetails: any = []
  SnapShotFileDetails: any = []
  id: any;
  isLoadingforUserDetails: boolean = true;
  profileNameSrc: any = "profile";
  brandCollection: any = {}
  brandId: any
  brandProfileDetails: any = []
  garmentsCollection: any = []
  snapShotBrandList = [];
  brandGarments: any = []
  brandBoard: any
  womensBrands: any;
  mensBrands: any;
  clothDetailsList:any={}
  userUploadedFileList:any=[]
  userFiledetails:any=[]
  counter: number = 2;
  showImages:any={}
  gltfImgSrc= ''
  brandIdStyle: any;


  constructor(
    private firestore: AngularFirestore,
    private dataSharingService: DataSharingService,
    private route: ActivatedRoute,
    private apiHttpService: ApiHttpService,
    public router: Router,
    public dialog: MatDialog

  ) {
  }
  ngOnInit(): void {
    
   
    this.dataSharingService.userBrandGarmentObservable.subscribe(garments => {
      this.brandGarments = garments
    })
    this.dataSharingService.userSnapShotObservable.subscribe(snapshot => {
      this.userSnapShotList = snapshot
    })

    this.dataSharingService.userclothDetailsListObservable.subscribe(cloth => {
      this.clothDetailsList = cloth
    })
    this.dataSharingService.userBrandBoardObservable.subscribe(board => {
      this.brandBoard = board
    })


    this.route.paramMap.subscribe(paramMap => {
      this.brandId = paramMap.get('brandId');
      this.brandIdStyle = paramMap.get('brandIdStyle');
      this.brandsFilesList.subscribe(brandList => {
        this.brandCollection = brandList
        let brandIDforProfile = this.brandCollection.find((brandListProfile) => brandListProfile.id == this.brandId)
        this.brandProfileDetails = brandIDforProfile
        this.mensBrands = brandList.filter((b: any) => b.Gender === 'M' || b.Gender === 'B');
        this.womensBrands = brandList.filter((b: any) => b.Gender === 'F' || b.Gender === 'B');
      })
    })

    this.userFilesList.subscribe((userFile) => {
      this.userUploadedFileList = userFile
      let clothId = this.clothDetailsList.id
      let filelist = this.userUploadedFileList.filter((uploadlist) => uploadlist.link == clothId)
      this.userFiledetails = filelist
    })


  }

  getBrandImage(imgURL: string) {
    let brandEncodedurl = encodeURI(imgURL).replace('/', '%2F');
    let url = "https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/" + brandEncodedurl + "?alt=media&token=550e7e88-bed3-4668-84ff-ad491c19e06c"
    return url;
  }
  getSnapShotListByUser(userId) {
    let userSnapShotList = this.userSnapShotList.filter((snapshot) => snapshot.UserID == userId);
    this.dataSharingService.sendUserSnapShots(userSnapShotList);
    this.router.navigate(['yologram-social/yologram-public-news', { 'id': userId }])
  }

  getSelectSnapshotFeed(userSnap) {
    this.userSnapShotList = []
    let userId = userSnap.UserID
    let storyid = userSnap.StoryID
    let userSnapShotList = this.userSnapShotList.filter((snapshot) => snapshot.UserID == userId && snapshot.StoryID == storyid);
    this.dataSharingService.sendUserSnapShots(userSnapShotList);
  }

  getUserProfile() {
    let userId = this.userId
    this.dataSharingService.sendUserSnapShots(userId);
    this.router.navigate(['yologram-social/yologram-public-news', { 'id': userId }])
  }

  getBrandStories(brand: any) {
    this.router.navigate(['yologram-social/yologram-brand-stories', { 'brandId': this.brandProfileDetails['id'] }])
  }

  getFeed(brand: any, brandName: any) {
    
    if(brandName == undefined) { brandid  =  this.brandIdStyle }
    else{ 
      var brandid = brandName.id
     }
    let boardName = this.brandBoard
    this.router.navigate(['yologram-social/yologram-brand-stories', { 'brandId': brandid,  'storyId': brand.StoryID,'board':boardName }])
  }


  getBrandStyle(cloths:any){
    this.dataSharingService.sendUserSnapShots(this.userSnapShotList);
    let boardName = this.brandBoard
    this.router.navigate(['yologram-social/yologram-garment-style', { 'brandId':cloths.BrandID, 'clothsType': cloths.GarmentType ,'Gender':cloths.Gender,'id':cloths.id }])
  }

  getStoryForGenderCloth(brandIds) {
    let snap = this.userSnapShotList
    this.dataSharingService.sendUserSnapShots(snap)
    let brandid = brandIds.id
    this.router.navigate(['yologram-social/yologram-garment-profile', { 'brandId': brandid, }])
  }


  incrementImgCounter() {
    this.counter =  this.counter + 2;
  }

  openImageBox(fileurl){
    let url = fileurl
    let showImage = this.userFiledetails.find((file)=> file.fileurl == url)
    this.showImages = showImage
   }

   


  openModel(gltfImgSrc: string) {
    const dialogRef = this.dialog.open(treedialogContentExampleDialog);
    this.gltfImgSrc = gltfImgSrc
    dialogRef.componentInstance.gltfImgSrc = this.gltfImgSrc;
    dialogRef.afterClosed().subscribe(result => {
    });
  }
  getgarmentfeed(brandName: any, cloths: any) {
    let snap = this.userSnapShotList
    this.dataSharingService.sendUserSnapShots(snap)
    this.dataSharingService.sendClothDetails(cloths)
    this.router.navigate(['yologram-social/yologram-garment-feed', { 'brandId': cloths.BrandID, 'clothsType': cloths.GarmentType, 'Gender': cloths.Gender, 'id': cloths.id }])
  }

}


@Component({
  selector: 'threedpopup-dialog',
  templateUrl: './popup3d.html',
})
export class treedialogContentExampleDialog {
  @Input() gltfImgSrc: string | undefined;
  constructor(public dialog: MatDialog) {
  }}