import { Injectable } from "@angular/core";
import { BehaviorSubject, mergeMapTo, Observable, timestamp } from "rxjs";
import { AngularFireMessaging } from "@angular/fire/compat/messaging"
import { __param } from "tslib";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { doc } from "firebase/firestore";
import { HttpClient } from "@angular/common/http";
import { environment } from 'src/environments/environment';
import { CommonService } from "./common.service";
import { Time } from "@angular/common";



@Injectable()
export class MessageService {
  _notificaions: PushSubscriptionOptions
  currentMessage = new BehaviorSubject<any>(null)
  userToken: any = [];
  token: any;
  user_name: string = "";
  id: any;
  SRC_TOKEN: any = "";


  // object: any= {
  //   displayName:"user_name",
  //   uid:"id"
  // }
  // this.firestore.doc<any>(`Tokens/${this.id}`).valueChanges();

  private fcmTokenAcess: string = environment.functionBaseUrl + "sendNotificationApi"
  private messageAcess: string = environment.functionBaseUrl + "sendmessageApi"

  datetime: Time;
  dataTokenprofile: Promise<import("@angular/fire/compat/firestore").DocumentReference<unknown>>;
  displayName: any;
  messAgealert = false;
  dataSource: any = {};
  message: any;
  storageNotification: any = [];
  notificationMessage: any;
  notification: any;
  notificationUser: any;

  //  private fcmTokenAcess: "http://127.0.0.1:5001/yolouploader/us-central1/sendNotificationApi"

  constructor(private messagingServices: AngularFireMessaging,
    private http: HttpClient,
    private firestore: AngularFirestore,
    private commonService: CommonService,
  ) {

  }

  requestPermission() {
    this.commonService.getUser().subscribe((user: any) => {
      this.id = user?.uid;
      this.user_name = user?.displayName
      const timeAnddate = new Date()
      this.messagingServices.requestToken.subscribe(
        (token) => {
          this.SRC_TOKEN = token;
          const diplayprofileName = this.user_name
          const userId = this.id

          let tokencollection = {
            "displayName": diplayprofileName,
            "fcmToken": token,
            "userId": userId,
            "TimeAndDate": timeAnddate
          }
          this.firestore.collection('Tokens').doc(token).set(tokencollection)
        },
        (error) => {
          console.error('Unable to get permission to notify.', error);
        }
      );
    });
  }


  receiveMessage() {
    this.messagingServices.messages.subscribe(
      (payload) => {
        this.storageNotification = [];
        if (!("Notification" in window)) {
          console.error("Notification isn't enabled");
        } else if (Notification.permission === "granted") {
        }

        if (localStorage.getItem("notification") != undefined) {
          this.storageNotification = JSON.parse(localStorage.getItem("notification"));
        }
        // this.storageNotification.push(payload);
        localStorage.setItem('notification', JSON.stringify(this.storageNotification))
        this.currentMessage.next(payload);
        this.notificationMessage = payload.notification.body
        this.notificationUser = payload.fcmOptions.link
        let notificationObject = {
              "link": this.notificationUser,
          "notification": this.notificationMessage
        }
        this.firestore.collection('user-notification').add(notificationObject)
      })



  }

  sendMessage(destToken, message) {
    this.token = destToken
     this.commonService.getUser().subscribe((user: any) => {
      this.id = user?.uid;
      this.user_name = user?.displayName
      let userObject = {
        "uid": this.id,
        "message": message,
        "destToken": destToken
      }
      return this.http.post(this.messageAcess, userObject).subscribe((data) => {
        console.error('Unable to get permission to notify.', data)
      }, (err) => { console.error('Unable to get permission to notify.', err); }
      );

    }


    )
  }


  fcmTokencheck() {
    this.commonService.getUser().subscribe((user: any) => {
      this.id = user?.uid;
      this.user_name = user?.displayName
      const diplayprofileName = this.user_name
      const userId = this.id
      if (diplayprofileName == undefined) return false;
      let userObject = {
        "uid": userId,
        "displayName": diplayprofileName,
        "srcToken": this.SRC_TOKEN
      }
      return this.http.post(this.fcmTokenAcess, userObject).subscribe((data) => {
      }, (err) => {
        console.error('Unable to get permission to notify.', err);
      }
      );
    })
  }



  sendMessageToRegisterUser(object) {
      return this.http.post(this.messageAcess,object).subscribe(() => {
      }, (err) => { console.error('Unable to get permission to notify.', err); }
      );
     }
  }




