// import { Component, OnInit } from '@angular/core';
// import { Router } from '@angular/router';
// import { NgForm, NgModel } from '@angular/forms';
// import { DatePipe } from '@angular/common';
// import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

// import { CommonService } from 'src/app/services/common.service';
// import { MessageService } from 'src/app/services/messaging.service';
// import { AngularFirestore } from '@angular/fire/compat/firestore';
// import { Observable } from 'rxjs';

// @Component({
//   selector: 'app-login',
//   templateUrl: './login-popup.component.html',
//   styleUrls: ['./login-popup.component.css','./login.css'],
//   providers: [DatePipe]
// })
// export class LoginPopupComponent implements OnInit {
//   //private user:User;
//   user = this.firestore.collection('user-profile').valueChanges({ idField: 'id' }) as Observable<any[]>;
//   message:any;
//   allUserDetails:any = {}
//   userData:any = []
//   activeShare:boolean =false
//   activehome:boolean =false
//   closeActive:boolean =false
//   email: any;
//   id: any;
//   constructor(
//     public router: Router,
//     private auth: CommonService,
//      public datepipe: DatePipe,
//      private messageService: MessageService,
//      private modal: NgbModal,
//      public activeModal: NgbActiveModal,
//      private firestore: AngularFirestore,

//        ) { }

//   ngOnInit(): void {
//     let new_date = new Date();
//     const date1 = this.datepipe.transform(new_date, 'd MMM, y');
//     this.messageService.requestPermission();
//     this.user.subscribe(userdetails => {
//       this.userData =  userdetails
//     });
//   }

//   //Methdo to login with google
//   loginWithGoogle(){
//     this.closePopUp()
//     this.addNewUser()
//     this.auth.loginWithGoogle();
//     this.fcmtokenGenrateAndSave()
//     this.messageService.receiveMessage();
//     this.message = this.messageService.currentMessage;

//   }
//   fcmtokenGenrateAndSave(){
//     this.messageService.fcmTokencheck();
//   }
//   closePopUp() {
//     this.activeModal.close()
//   }

//   addNewUser(){
//     this.auth.getUser().subscribe((user: any) => {
//       this.email = user?.email;
//       this.id = user._delegate.uid;
//       console.log("this.id==>", this.id)
//       // let Name = user.get(0).getName();
//     })
//     setTimeout(() => {
//       this.auth.getAllUser().subscribe(async (allUsers: any) => {
//         this.allUserDetails = allUsers.users;
//         let userDetail =  this.allUserDetails.find((user)=>user.uid == this.id )
//         let userDetails =  this.userData.find((user)=>user.UserID == this.id )
//         console.log("userDetail",userDetail)
//         console.log("userDetails",userDetails)
//         let saveUserDetails = {
//           "DisplayName": userDetail.displayName != undefined ? userDetail.displayName : '',
//           "Email": userDetail.email != undefined ? userDetail.email : '',
//           "FirstName": '',
//           "Identifier":'',
//           "LastName":'',
//           "MiddleName":'',
//           "ProfilePicture":userDetail.photoURL != undefined ? userDetail.photoURL : '',
//           "UserID":userDetail.uid != undefined ? userDetail.uid : '',
//           "pagename" : 'loginpage'
//         }
//         console.log("userDetails==>",userDetails);
//              if( userDetails.id  == undefined){
//               this.firestore.collection('user-profile').add(saveUserDetails);
//             }

//       })
//         },
//       10000);
// }

// updateWithSnapPost(){
//   this.auth.getUser().subscribe((user: any) => {
//     this.email = user?.email;
//     this.id = user._delegate.uid;
//     let Name = user.get(0).getName();
//   })
//   setTimeout(() => {
//     this.auth.getAllUser().subscribe(async (allUsers: any) => {
//       this.allUserDetails = allUsers.users;
//       let userDetail =  this.allUserDetails.find((user)=>user.uid == this.id)
//       let userDetails =  this.userData.find((user)=>user.uid == this.id )
//       let saveUserDetails = {
//         "DisplayName": userDetail.displayName,
//         "Email":userDetail.email,
//         "FirstName": '',
//         "Identifier":'',
//         "LastName":'',
//         "MiddleName":'',
//         "ProfilePicture":userDetail.photoURL,
//         "UserID":userDetail.uid,
//       }
//       if(userDetails != undefined){this.firestore.collection('user-profile').add(saveUserDetails);
//     }
//     })
//       },
//     10000);
// }

//   }

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgForm, NgModel } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { CommonService } from 'src/app/services/common.service';
import { MessageService } from 'src/app/services/messaging.service';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';
import { combineLatest } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-login',
  templateUrl: './login-popup.component.html',
  styleUrls: ['./login-popup.component.css', './login.css'],
  providers: [DatePipe],
})
export class LoginPopupComponent implements OnInit {
  //private user:User;
  user = this.firestore
    .collection('user-profile')
    .valueChanges({ idField: 'id' }) as Observable<any[]>;
  message: any;
  allUserDetails: any = {};
  userData: any = [];
  activeShare: boolean = false;
  activehome: boolean = false;
  closeActive: boolean = false;
  email: any;
  id: any;
  constructor(
    public router: Router,
    private auth: CommonService,
    public datepipe: DatePipe,
    private messageService: MessageService,
    private modal: NgbModal,
    public activeModal: NgbActiveModal,
    private firestore: AngularFirestore
  ) {}

  ngOnInit(): void {
    let new_date = new Date();
    const date1 = this.datepipe.transform(new_date, 'd MMM, y');
    this.messageService.requestPermission();
    this.user.subscribe((userdetails) => {
      this.userData = userdetails;
    });
  }

  //Methdo to login with google
  async loginWithGoogle() {
    this.closePopUp();
    let userDetails = await this.auth.loginWithGoogle();
    if (userDetails != undefined && userDetails != null) {
      console.log(userDetails);
      console.log('=========== Started ===========');
      this.firestore
        .collection('user-profile', (ref) =>
          ref.where('UserID', '==', userDetails?.uid)
        )
        .get()
        .subscribe((querySnapshot) => {
          console.log('querySnapshot==>', querySnapshot);
          if (querySnapshot.size === 0) {
            // User does not exist, add user
            this.addLoginUser(userDetails);
            this.fcmtokenGenrateAndSave();
            this.messageService.receiveMessage();
            this.message = this.messageService.currentMessage;
          } else {
            //user exit than navigate to drip page
            console.log('User already exit !!');
            window.location.reload();
            this.router.navigateByUrl('/drip');
          }
        });
    } else {
      console.log('User not allow to access the yologram.');
    }
  }

  addLoginUser(userDetails: any) {
    let displayDetails = userDetails.providerData[0];
    let saveUserDetails = {
      DisplayName: userDetails?.displayName || '',
      Email: userDetails?.email || '',
      FirstName: '',
      Identifier: '',
      LastName: '',
      MiddleName: '',
      ProfilePicture: displayDetails?.photoURL || '',
      UserID: userDetails?.uid || '',
      PageName: 'loginpage',
    };

    this.firestore
      .collection('user-profile')
      .add(saveUserDetails)
      .then((docRef) => {
        console.log('User added successfully with ', docRef.id);

        window.location.reload();
        this.router.navigateByUrl('/drip');
      })
      .catch((error) => {
        console.error('Error adding user: ', error);
      });
  }

  fcmtokenGenrateAndSave() {
    this.messageService.fcmTokencheck();
  }
  closePopUp() {
    this.activeModal.close();
  }

  // checkUserExist(id:any){
  //   let userDetails = this.userData.find((user) => user.UserID == id);
  // }
  //login method old
  // addNewUser() {
  //   let saveUserDetails: any;  // Declare saveUserDetails outside the switchMap block
  //   this.auth.getUser().subscribe((user: any) => {
  //     this.email = user?.email;
  //     this.id = user._delegate.uid;
  //     console.log("userid==>", this.id);
  //     setTimeout(() => {
  //       this.auth.getAllUser().pipe(
  //         switchMap((allUsers: any) => {
  //           this.allUserDetails = allUsers.users;
  //           console.log("userDetail==>", this.allUserDetails);
  //           let userDetail = this.allUserDetails.find((user) => user.uid == this.id);
  //           let userDetails = this.userData.find((user) => user.UserID == this.id);
  //           console.log("userDetail==>", userDetail);
  //           console.log("userDetails==>", userDetails);
  //           let displayDetails = userDetail.providerData[0];
  //           saveUserDetails = {
  //             "DisplayName": userDetail?.displayName || '',
  //             "Email": userDetail?.email || '',
  //             "FirstName": '',
  //             "Identifier": '',
  //             "LastName": '',
  //             "MiddleName": '',
  //             "ProfilePicture": displayDetails?.photoURL || '',
  //             "UserID": userDetail?.uid || '',
  //             "PageName": 'loginpage'
  //           };
  //           //this.firestore.collection('user-profile').add(saveUserDetails);

  //           console.log("userDetails==>", userDetails);
  //           // If both local data and Firestore data do not have the user, add a new entry
  //           return this.firestore.collection('user-profile', ref => ref.where('UserID', '==', this.id))
  //             .get();
  //         })
  //       ).subscribe((querySnapshot) => {
  //         if (querySnapshot.empty) {
  //           this.firestore.collection('user-profile').add(saveUserDetails);
  //           console.log("USER ADDED.....")
  //         } else {
  //           console.log('User already exists:', querySnapshot.docs[0].data());
  //           // You can choose to update the existing entry here if needed
  //         }
  //       });
  //      },
  //     10000);
  //   });

  // }

  updateWithSnapPost() {
    this.auth.getUser().subscribe((user: any) => {
      this.email = user?.email;
      this.id = user._delegate.uid;
      let Name = user.get(0).getName();
    });
    setTimeout(() => {
      this.auth.getAllUser().subscribe(async (allUsers: any) => {
        this.allUserDetails = allUsers.users;
        let userDetail = this.allUserDetails.find(
          (user) => user.uid == this.id
        );
        let userDetails = this.userData.find((user) => user.uid == this.id);
        let saveUserDetails = {
          DisplayName: userDetail.displayName,
          Email: userDetail.email,
          FirstName: '',
          Identifier: '',
          LastName: '',
          MiddleName: '',
          ProfilePicture: userDetail.photoURL,
          UserID: userDetail.uid,
        };
        if (userDetails != undefined) {
          this.firestore.collection('user-profile').add(saveUserDetails);
        }
      });
    }, 10000);
  }
}
