<div class="carousel" #garmentDummySCroll *ngIf="showSliderFlag">
  <div class="item" *ngFor="let data of scrollingImages">
    <img (click)="open2dPoup3(data)" class="imageBorder-class"
      *ngIf="!loading  && data.fileurlMini != undefined && data.image == undefined" style="height: 80px;
                width: 80px !important;object-fit: cover;" [src]="data.fileurlMini">
    <img (click)="open2dPoup3(data)" class="imageBorder-class"
                 *ngIf="!loading  && data.fileurlMini == undefined && modelViewerFlag"  style="height: 80px;
              width: 80px !important;object-fit: cover;" src="assets/Thumbnail_not_available.png">

    <img class="imageBorder-class" *ngIf="!loading  && data.fileurl == undefined && data.image != undefined "
      (click)="open2dPoupDummy(data)" style="height: 80px; width: 80px !important;object-fit: cover;" [src]="data.image">
  </div>
</div>