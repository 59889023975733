import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { ApiHttpService } from 'src/app/services/api-http.service';
import { CommonService } from 'src/app/services/common.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-security-group',
  templateUrl: './security-group.component.html',
  styleUrls: ['./security-group.component.css']
})
export class SecurityGroupComponent implements OnInit {
  securityGroupCollection = this.firestore.collection('SecurityGroup').valueChanges({ idField: 'id' }) as Observable<[]>;

  pagecollection = this.firestore.collection('Pages').valueChanges({ idField: 'id' }) as Observable<[]>;
  groupRolecollection = this.firestore.collection('GroupRole').valueChanges({ idField: 'id' }) as Observable<[]>;
  groupUsercollection = this.firestore.collection('GroupUser').valueChanges({ idField: 'id' }) as Observable<[]>;

  groupNameforDuplicate: any = [];
  GroupName: any = ""
  securityGroupAll: any = [];
  groupUser: any = {};
  securityGroupUserList: any = [];
  uid: any;
  loading: boolean = false;
  selectedSecGpId: any;
  isEditItems: boolean;
  firstField = true;
  editSecurityGroup: any = {};
  modifiedSecGrpName: any;
  selectedpage: any = {}
  pageAll: any = [];
  pagesObject: any = {}
  userObject: any = {}
  selectedSecGrpId: any = "";
  grpRoleList: any = [];
  secGrpPages: any = [];
  secGrpUser: any = [];
  groupRolesList: any = [];
  groupUserList: any = []
  fitlerGroupRoles: any = [];
  fitlerGroupUser: any = [];
  showicone: boolean = false
  gpname:any ={}
  logInFlag: boolean;

  constructor(private apiHttpService: ApiHttpService,  private router: Router, public commonService: CommonService, private firestore: AngularFirestore) {

    this.pagecollection.subscribe((pageListAll) => {
      this.pageAll = pageListAll;
      this.groupRolecollection.subscribe((groupRoles) => {
        this.groupRolesList = groupRoles;
        this.groupUsercollection.subscribe((groupUser) => {
          this.groupUserList = groupUser;
          this.securityGroupCollection.subscribe((secGrpList: any) => {
            this.securityGroupAll = secGrpList;
            this.securityGroupAll.forEach((secGrp) => {
              let secGrpId = secGrp.id;
              let fitlerGroupUser = this.groupUserList.filter((grpUser => grpUser.SecGpId == secGrpId))
              let fitlerGroupRoles = this.groupRolesList.filter((grpRole => grpRole.SecGpId == secGrpId))
              if (fitlerGroupUser.length > 0) secGrp.userList = fitlerGroupUser
              if (fitlerGroupRoles.length > 0) secGrp.groupRoleList = fitlerGroupRoles
            })
          });
        })
      })
    });


  }

  ngOnInit(): void {
    let title = 'Yologram - security-group'
    this.commonService.changeTitle(title);

    this.logInFlag = this.commonService.isLoggedIn()
    this.commonService.getUser().subscribe((user: any) => {
      let userId = user?.uid;
    });
    this.checkPageAuthorization()
  }

  addSecurityGp() {
    if (this.GroupName == '' ) {
      alert('Pleaes enter valid security group name.')
      return;
    }else{
      let duplicateList =  this.securityGroupAll.filter((grpName:any)=> grpName.name.toLowerCase() === this.GroupName.toLowerCase() )
      if(duplicateList.length >0) {
        alert('Duplicate group name is not allow, please enter valid group name.')
        return;
      } 
    }
    let groupObject = {
      name: this.GroupName,
      status: 'A'
    }
    this.firestore.collection('SecurityGroup').add(groupObject)
    this.GroupName = ''
    alert("Group Added Successfully..")
    return
    
  }

  checkPageAuthorization() {
    this.loading = true;
    this.commonService.getUser().subscribe((user: any) => {
      let userObject = { 'userid': user?.uid, 'pagename': '/security-group' }
      this.commonService.checkViewPageRights(userObject).subscribe((res) => {
        this.loading = false;
        if (!res) {
          this.router.navigateByUrl("yologram-social/menu-page");
        }
      });
    });
  }

  editSecurityGrp(secGrp) {
    this.editSecurityGroup = secGrp;
  }

  updateSecGrpActiveStatus($event, secGrp) {
   
    let object = {
      name: secGrp['name'],
      status: ($event.target.checked) ? 'A' : 'I'
    };
    this.firestore.collection('SecurityGroup').doc(secGrp.id).update(object);
  }

  setEditSecGrpId($event) {
    this.modifiedSecGrpName = $event.target.value
  }

  updateGroupName() {
    let duplicateList =  this.securityGroupAll.filter((grpName:any)=> grpName.name.toLowerCase() === this.GroupName.toLowerCase() )
    if(duplicateList) {
      alert('Duplicate group name is not allow, please enter valid group name.')
      return;
    } 
    let object = {
      name: this.modifiedSecGrpName,
      status: this.editSecurityGroup['status']
    };
    this.modifiedSecGrpName;
    this.firestore.collection('SecurityGroup').doc(this.editSecurityGroup.id).update(object);
  }



  showGroupRolePages(groupRolePageList) {
    this.fitlerGroupRoles = groupRolePageList
    this.fitlerGroupRoles.forEach((page) => {
      let pageId = page.PageId
      let pageObject = this.pageAll.filter((page => page.id == pageId))
      if (pageObject.length > 0) page.PageName = pageObject[0]['PageName']
    })
  }


  showSecGroupUsers(groupUserList) {
    this.fitlerGroupUser = groupUserList
    this.fitlerGroupUser.forEach(async (groupUser: any) => {
      let url = environment.functionBaseUrl + "userProfileIdenifierById?id=" + groupUser.UserId;
      this.apiHttpService.get(url).subscribe((data: any) => {
        groupUser['profileName'] = data.displayName != undefined ? data.displayName : groupUser.UserId
      })
    })
  }

}