import { Injectable } from '@angular/core';
import { BehaviorSubject }    from 'rxjs'

@Injectable()
export class DataSharingService
{


  private userSnapShotList = new BehaviorSubject <any> (null);
  userSnapShotObservable = this.userSnapShotList.asObservable();

  private userEnvSnapShotList = new BehaviorSubject <any> (null);
  userEnvSnapShotObservable = this.userEnvSnapShotList.asObservable();

  
  private userIdObslist = new BehaviorSubject <string> (null);
  userIdObservable = this.userIdObslist.asObservable();

  private userBrandGarmentList = new BehaviorSubject <any> (null);
  userBrandGarmentObservable = this.userBrandGarmentList.asObservable();

  private userBrandBoardtList = new BehaviorSubject <any> (null);
  userBrandBoardObservable = this.userBrandBoardtList.asObservable();

  private clothDetailsAllList = new BehaviorSubject <any> (null);
  userclothDetailsListObservable = this.clothDetailsAllList.asObservable();


  private userData = new BehaviorSubject <any> (null);
  userDataObservable = this.userData.asObservable();

  private fileUrl = new BehaviorSubject <any> (null);
  fileUrlObservable = this.fileUrl.asObservable();

  private userfiles = new BehaviorSubject <any> (null);
  userfilesObservable = this.userfiles.asObservable();
  navigationData: any;

  constructor() {}

  sendUserData(userData){
    this.userData.next(userData);
  }
  
  sendUserEnvSnapShots(snapshot)
  {
    this.userEnvSnapShotList.next(snapshot);
  }


  sendUserSnapShots(snapshot)
  {
    this.userSnapShotList.next(snapshot);
  }

  sendBrandGarments(garmentList)
  {
    this.userBrandGarmentList.next(garmentList);
  }

  sendBrandBrandBoard(brandBoardList)
  {
    this.userBrandBoardtList.next(brandBoardList);
  }
  sendClothDetails(clothDetails)
  {
    this.clothDetailsAllList.next(clothDetails);
  }
  sendUser(id)
  {
    this.userSnapShotList.next(id);
  }
  sendUrl(url)
  {
    this.fileUrl.next(url);
  }

  sendUserfiles(url)
  {
    this.userfiles.next(url);
  }
  getNavigationData(): any {
    const data = localStorage.getItem('navigationData');
    return data ? JSON.parse(data) : null;

   
  }
  setNavigationData(data: any) {
    localStorage.setItem('navigationData', JSON.stringify(data));

  }

}