import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { VRMLoaderPlugin, VRMUtils } from '@pixiv/three-vrm';
import * as JSZip from 'jszip/dist/jszip';
import { Observable, from, map, of, switchMap } from 'rxjs';
import { CommonService } from 'src/app/services/common.service';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader';
import { BVHImport } from '../templets/news3-d-templete/BVHImport.js';




@Component({
  selector: 'app-dummy-animation',
  templateUrl: './dummy-animation.component.html',
  styleUrls: ['./dummy-animation.component.css']
})

export class DummyAnimationComponent {

  @ViewChild('threeDModelContainer', { static: true }) threeDModelContainer: ElementRef;

  //Firebase
  logInFlag: boolean;

  //Data field 
  garmentCollections = this.firestore.collection('garments').valueChanges({ idField: 'id' }) as Observable<any[]>;
  garmentListCollection: any = [];
  upperWearList: any = [];
  bottomWearList: any = [];

  animationCollections = this.firestore.collection('Animations').valueChanges({ idField: 'id' }) as Observable<any[]>;
  animationListCollection: any = [];

  downloadUrls$: Observable<string[]>;
  urlPromisesData: any = [];

  //Scene
  scene: any;
  camera: any;
  renderer: any;
  controls: any;

  //VRM
  clock = new THREE.Clock();

  mixer;
  skeletonHelper;
  currentVRM = null;
  helperRoot = new THREE.Group();




  //Avatar
  currentGender: string;

  heightScale: any;

  fatnessSliderValue:any;
  currentFatness: any;
  triangleData: any = [];

  maleFBXObject: any;
  femaleFBXObject: any;
  currentFBXObject: any;

  currentAction: any; // Reference to the currently playing action
  maleOriginalPoses: any = []; // Cache for original bone poses
  femaleOriginalPoses: any = []; // Cache for original bone poses


  debugMaterial = new THREE.MeshNormalMaterial();

  //hair
  maleHair: any;
  femaleHair: any;
  maleHeadBone: any;
  femaleHeadBone: any;

  defaultFemaleAvatar = 'https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/AvatarBlenderSaves%2FPfr7Gql3fxZzgVqcjyH8OUJXwwV2%2F20221101190841095?alt=media&token=20b1dc1e-681b-4d96-a342-7a4407fab4e0&_gl=1*10jumsf*_ga*MjExMjI5MTIyMi4xNjg3NTA3MDk1*_ga_CW55HF8NVT*MTY5NzUyMjU5NC4xNzAuMS4xNjk3NTIyNjE1LjM5LjAuMA..';
  defaultMaleAvatar = "https://firebasestorage.googleapis.com/v0/b/rishtest-96467.appspot.com/o/20230112115118542.zip?alt=media&token=3ea78817-7886-4806-9963-77717f09be64&_gl=1*1w2i935*_ga*MTY2MTMzOTE3NC4xNjk2ODgxNDM4*_ga_CW55HF8NVT*MTY5NzM2MTkzNi4yMS4xLjE2OTczNjE5NzQuMjIuMC4w";

  defaultMaleUpperwear = 'https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/AvatarBlenderClothes%2FetaIco74cM3Y6933sYkL_M%2Fupperwear%2Fcloth_20230727170023385?alt=media&token=dd6b2875-70fc-4d2a-86b3-17d57a012323';
  defaultMaleBottomwear = 'https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/AvatarBlenderClothes%2F1XO6eM0Ed8YYyrcCtT9S_M%2Fbottomwear%2Fcloth_20210727150845087?alt=media&token=9a9711ed-7df6-4740-a608-b663ea3f2273';
 
  defaultFemaleUpperwear = 'https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/AvatarBlenderClothes%2F27SuPAPFafEWRAsBuGe7_F%2Fupperwear%2Fcloth_20210607205131130?alt=media&token=3d145a21-b312-41e0-bc5e-84fc74a03ae4';
  defaultFemaleBottomwear = 'https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/AvatarBlenderClothes%2F2nKPNi8rYWdrO1JTywVK_F%2Fbottomwear%2Fcloth_20211207111724869?alt=media&token=53436a2f-5a3d-4a59-a64f-7041dfbee84e';
  
  defaultAnimation = 'https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/TestMixamo%2FCatwalk%20Twist%20L%20To%20Twist%20R.fbx?alt=media&token=1b28030c-9cee-4a6b-9cee-10a3c69bf812';
  

  //Clothes

  Uclothes: any;
  Bclothes: any;

  maleUpperWear: any;
  maleBottomWear: any;

  femaleUpperWear: any;
  femaleBottomWear: any;

  //animation

  mixamoRigMapJsonAvatar = {
    mixamorigHips: 'Pelvis',
    mixamorigSpine: 'Spine1',
    mixamorigSpine1: 'Spine2',
    mixamorigSpine2: 'Spine3',
    mixamorigNeck: 'Neck',
    mixamorigHead: 'Head',
    mixamorigLeftShoulder: 'L_Collar',
    mixamorigLeftArm: 'L_Shoulder',
    mixamorigLeftForeArm: 'L_Elbow',
    mixamorigLeftHand: 'L_Wrist',
    mixamorigLeftHandThumb1: 'lthumb0',
    mixamorigLeftHandThumb2: 'lthumb1',
    mixamorigLeftHandThumb3: 'lthumb2',
    mixamorigLeftHandIndex1: 'lindex0',
    mixamorigLeftHandIndex2: 'lindex1',
    mixamorigLeftHandIndex3: 'lindex2',
    mixamorigLeftHandMiddle1: 'lmiddle0',
    mixamorigLeftHandMiddle2: 'lmiddle1',
    mixamorigLeftHandMiddle3: 'lmiddle2',
    mixamorigLeftHandRing1: 'lring0',
    mixamorigLeftHandRing2: 'lring1',
    mixamorigLeftHandRing3: 'lring2',
    mixamorigLeftHandPinky1: 'lpinky0',
    mixamorigLeftHandPinky2: 'lpinky1',
    mixamorigLeftHandPinky3: 'lpinky2',
    mixamorigRightShoulder: 'R_Collar',
    mixamorigRightArm: 'R_Shoulder',
    mixamorigRightForeArm: 'R_Elbow',
    mixamorigRightHand: 'R_Wrist',
    mixamorigRightHandThumb1: 'rthumb0',
    mixamorigRightHandThumb2: 'rthumb1',
    mixamorigRightHandThumb3: 'rthumb2',
    mixamorigRightHandIndex1: 'rindex0',
    mixamorigRightHandIndex2: 'rindex1',
    mixamorigRightHandIndex3: 'rindex2',
    mixamorigRightHandMiddle1: 'rmiddle0',
    mixamorigRightHandMiddle2: 'rmiddle1',
    mixamorigRightHandMiddle3: 'rmiddle2',
    mixamorigRightHandRing1: 'rring0',
    mixamorigRightHandRing2: 'rring1',
    mixamorigRightHandRing3: 'rring2',
    mixamorigRightHandPinky1: 'rpinky0',
    mixamorigRightHandPinky2: 'rpinky1',
    mixamorigRightHandPinky3: 'rpinky2',
    mixamorigLeftUpLeg: 'L_Hip',
    mixamorigLeftLeg: 'L_Knee',
    mixamorigLeftFoot: 'L_Ankle',
    mixamorigLeftToeBase: 'L_Foot',
    mixamorigRightUpLeg: 'R_Hip',
    mixamorigRightLeg: 'R_Knee',
    mixamorigRightFoot: 'R_Ankle',
    mixamorigRightToeBase: 'R_Foot',
  };

  mixamoRigMapVRM = {
    mixamorigHips: 'hips',
    mixamorigSpine: 'spine',
    mixamorigSpine1: 'chest',
    mixamorigSpine2: 'upperChest',
    mixamorigNeck: 'neck',
    mixamorigHead: 'head',
    mixamorigLeftShoulder: 'leftShoulder',
    mixamorigLeftArm: 'leftUpperArm',
    mixamorigLeftForeArm: 'leftLowerArm',
    mixamorigLeftHand: 'leftHand',
    mixamorigLeftHandThumb1: 'leftThumbMetacarpal',
    mixamorigLeftHandThumb2: 'leftThumbProximal',
    mixamorigLeftHandThumb3: 'leftThumbDistal',
    mixamorigLeftHandIndex1: 'leftIndexProximal',
    mixamorigLeftHandIndex2: 'leftIndexIntermediate',
    mixamorigLeftHandIndex3: 'leftIndexDistal',
    mixamorigLeftHandMiddle1: 'leftMiddleProximal',
    mixamorigLeftHandMiddle2: 'leftMiddleIntermediate',
    mixamorigLeftHandMiddle3: 'leftMiddleDistal',
    mixamorigLeftHandRing1: 'leftRingProximal',
    mixamorigLeftHandRing2: 'leftRingIntermediate',
    mixamorigLeftHandRing3: 'leftRingDistal',
    mixamorigLeftHandPinky1: 'leftLittleProximal',
    mixamorigLeftHandPinky2: 'leftLittleIntermediate',
    mixamorigLeftHandPinky3: 'leftLittleDistal',
    mixamorigRightShoulder: 'rightShoulder',
    mixamorigRightArm: 'rightUpperArm',
    mixamorigRightForeArm: 'rightLowerArm',
    mixamorigRightHand: 'rightHand',
    mixamorigRightHandPinky1: 'rightLittleProximal',
    mixamorigRightHandPinky2: 'rightLittleIntermediate',
    mixamorigRightHandPinky3: 'rightLittleDistal',
    mixamorigRightHandRing1: 'rightRingProximal',
    mixamorigRightHandRing2: 'rightRingIntermediate',
    mixamorigRightHandRing3: 'rightRingDistal',
    mixamorigRightHandMiddle1: 'rightMiddleProximal',
    mixamorigRightHandMiddle2: 'rightMiddleIntermediate',
    mixamorigRightHandMiddle3: 'rightMiddleDistal',
    mixamorigRightHandIndex1: 'rightIndexProximal',
    mixamorigRightHandIndex2: 'rightIndexIntermediate',
    mixamorigRightHandIndex3: 'rightIndexDistal',
    mixamorigRightHandThumb1: 'rightThumbMetacarpal',
    mixamorigRightHandThumb2: 'rightThumbProximal',
    mixamorigRightHandThumb3: 'rightThumbDistal',
    mixamorigLeftUpLeg: 'leftUpperLeg',
    mixamorigLeftLeg: 'leftLowerLeg',
    mixamorigLeftFoot: 'leftFoot',
    mixamorigLeftToeBase: 'leftToes',
    mixamorigRightUpLeg: 'rightUpperLeg',
    mixamorigRightLeg: 'rightLowerLeg',
    mixamorigRightFoot: 'rightFoot',
    mixamorigRightToeBase: 'rightToes',
  };

  boneMappingJsonAvatar = {
    hips_JNT: 'Pelvis',
    spine_JNT: 'Spine1',
    spine1_JNT: 'Spine2',
    spine2_JNT: 'Spine3',
    neck_JNT: 'Head',
    head_JNT: 'Neck',
    l_shoulder_JNT: 'L_Collar',
    l_arm_JNT: 'L_Shoulder',
    l_forearm_JNT: 'L_Elbow',
    l_hand_JNT: 'L_Wrist',
    l_handThumb1_JNT: 'lthumb0',
    l_handThumb2_JNT: 'lthumb1',
    l_handThumb3_JNT: 'lthumb2',
    l_handIndex1_JNT: 'lindex0',
    l_handIndex2_JNT: 'lindex1',
    l_handIndex3_JNT: 'lindex2',
    l_handMiddle1_JNT: 'lmiddle0',
    l_handMiddle2_JNT: 'lmiddle1',
    l_handMiddle3_JNT: 'lmiddle2',
    l_handRing1_JNT: 'lring0',
    l_handRing2_JNT: 'lring1',
    l_handRing3_JNT: 'lring2',
    l_handPinky1_JNT: 'lpinky0',
    l_handPinky2_JNT: 'lpinky1',
    l_handPinky3_JNT: 'lpinky2',
    r_shoulder_JNT: 'R_Collar',
    r_arm_JNT: 'R_Shoulder',
    r_forearm_JNT: 'R_Elbow',
    r_hand_JNT: 'R_Wrist', // No specific hand bone in FBX, using wrist
    r_handThumb1_JNT: 'rthumb0',
    r_handThumb2_JNT: 'rthumb1',
    r_handThumb3_JNT: 'rthumb2',
    r_handIndex1_JNT: 'rindex0',
    r_handIndex2_JNT: 'rindex1',
    r_handIndex3_JNT: 'rindex2',
    r_handMiddle1_JNT: 'rmiddle0',
    r_handMiddle2_JNT: 'rmiddle1',
    r_handMiddle3_JNT: 'rmiddle2',
    r_handRing1_JNT: 'rring0',
    r_handRing2_JNT: 'rring1',
    r_handRing3_JNT: 'rring2',
    r_handPinky1_JNT: 'rpinky0',
    r_handPinky2_JNT: 'rpinky1',
    r_handPinky3_JNT: 'rpinky2',
    l_upleg_JNT: 'L_Hip',
    l_leg_JNT: 'L_Knee',
    l_foot_JNT: 'L_Ankle',
    l_toebase_JNT: 'L_Foot',
    r_upleg_JNT: 'R_Hip',
    r_leg_JNT: 'R_Knee',
    r_foot_JNT: 'R_Ankle',
    r_toebase_JNT: 'R_Foot',
  };

  boneMappingVRM = {
    hips_JNT: 'hips',
    spine_JNT: 'spine',
    spine1_JNT: 'chest',
    spine2_JNT: 'upperChest',
    neck_JNT: 'neck',
    head_JNT: 'head',
    l_shoulder_JNT: 'leftShoulder',
    l_arm_JNT: 'leftUpperArm',
    l_forearm_JNT: 'leftLowerArm',
    l_hand_JNT: 'leftHand',
    l_handThumb1_JNT: 'leftThumbMetacarpal',
    l_handThumb2_JNT: 'leftThumbProximal',
    l_handThumb3_JNT: 'leftThumbDistal',
    l_handIndex1_JNT: 'leftIndexProximal',
    l_handIndex2_JNT: 'leftIndexIntermediate',
    l_handIndex3_JNT: 'leftIndexDistal',
    l_handMiddle1_JNT: 'leftMiddleProximal',
    l_handMiddle2_JNT: 'leftMiddleIntermediate',
    l_handMiddle3_JNT: 'leftMiddleDistal',
    l_handRing1_JNT: 'leftRingProximal',
    l_handRing2_JNT: 'leftRingIntermediate',
    l_handRing3_JNT: 'leftRingDistal',
    l_handPinky1_JNT: 'leftLittleProximal',
    l_handPinky2_JNT: 'leftLittleIntermediate',
    l_handPinky3_JNT: 'leftLittleDistal',
    r_shoulder_JNT: 'rightShoulder',
    r_arm_JNT: 'rightUpperArm',
    r_forearm_JNT: 'rightLowerArm',
    r_hand_JNT: 'rightHand',
    r_handThumb1_JNT: 'rightThumbMetacarpal',
    r_handThumb2_JNT: 'rightThumbProximal',
    r_handThumb3_JNT: 'rightThumbDistal',
    r_handIndex1_JNT: 'rightIndexProximal',
    r_handIndex2_JNT: 'rightIndexIntermediate',
    r_handIndex3_JNT: 'rightIndexDistal',
    r_handMiddle1_JNT: 'rightMiddleProximal',
    r_handMiddle2_JNT: 'rightMiddleIntermediate',
    r_handMiddle3_JNT: 'rightMiddleDistal',
    r_handRing1_JNT: 'rightRingProximal',
    r_handRing2_JNT: 'rightRingIntermediate',
    r_handRing3_JNT: 'rightRingDistal',
    r_handPinky1_JNT: 'rightLittleProximal',
    r_handPinky2_JNT: 'rightLittleIntermediate',
    r_handPinky3_JNT: 'rightLittleDistal',
    l_upleg_JNT: 'leftUpperLeg',
    l_leg_JNT: 'leftLowerLeg',
    l_foot_JNT: 'leftFoot',
    l_toebase_JNT: 'leftToes',
    r_upleg_JNT: 'rightUpperLeg',
    r_leg_JNT: 'rightLowerLeg',
    r_foot_JNT: 'rightFoot',
    r_toebase_JNT: 'rightToes',
  };

  //#region  init

  constructor(public commonService: CommonService, private http: HttpClient, private storage: AngularFireStorage, private firestore: AngularFirestore) {
    this.logInFlag = this.commonService.isLoggedIn()
    this.garmentCollections.subscribe((garment) => {
      this.garmentListCollection = garment;
      this.upperWearList = this.garmentListCollection.filter((cloth) => cloth.GarmentType === 'upperwear')
      this.bottomWearList = this.garmentListCollection.filter((cloth) => cloth.GarmentType === 'bottomwear')

    });
    this.animationCollections.subscribe((animaition) => {
      this.animationListCollection = animaition;
    })


  }

  ngOnInit(): void {
    this.init();
    this.populateMixamoAnimationDropdown();
    this.threeDModelContainer.nativeElement.appendChild(this.renderer.domElement);
  }

  populateMixamoAnimationDropdown() {
    console.log('populateMixamoAnimationDropdown called');
    const dirRef = this.storage.ref('TestMixamo');
    // Note the change here to switchMap
    this.downloadUrls$ = dirRef.listAll().pipe(
      switchMap(response => {
        // Transform list of items into an array of URL promises
        const urlPromises = response.items.map(item => item.getDownloadURL());
        const urlPromisesDara = response.items.map(item => item.name);
        // Return an observable from the promise that resolves to an array of URLs
        this.urlPromisesData = urlPromises
        console.log('urlPromises', this.urlPromisesData)
        return from(Promise.all(urlPromises));
      })
    );
  }

  async init() {
    console.log("init called");
    this.triangleData = await this.loadJsonFile();

    this.camera = new THREE.PerspectiveCamera(60, window.innerWidth / window.innerHeight, 1, 1000);
    // camera.position.z = 100; // for BVH
    this.camera.position.z = 3; // for VRM
    this.camera.position.y = 2; // for VRM
    this.scene = new THREE.Scene();
    // renderer
    const renderer = new THREE.WebGLRenderer({ antialias: false });
    this.renderer = renderer
    this.renderer.setClearColor(0x777777);
    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.renderer.setSize(window.innerWidth, window.innerHeight);
    document.body.appendChild(renderer.domElement);

    const hdrTextureURL = 'assets/industrial_sunset_puresky_2k.hdr'; // Adjusted path
    const loader = new RGBELoader();

    loader.load(hdrTextureURL, (texture) => {
      texture.mapping = THREE.EquirectangularReflectionMapping;
      this.scene.background = texture;
      this.scene.environment = texture;
    });

    this.loadMaleFBX();
    this.loadFemaleFBX();



    // OrbitControls setup
    const controls = new OrbitControls(this.camera, this.renderer.domElement);
    controls.rotateSpeed = 2.5;
    controls.zoomSpeed = 1.2;
    controls.panSpeed = 0.8;
    controls.enableDamping = true; // enable damping (inertia)
    controls.dampingFactor = 0.3;
    controls.screenSpacePanning = false; // use trackball-style panning
    this.controls = controls
    this.helperRoot.renderOrder = 10000;
    this.scene.add(this.helperRoot);

    window.addEventListener('resize', this.onWindowResize, false);

    this.animate();
  }

  animate = () => {
    requestAnimationFrame(this.animate);
    var delta = this.clock.getDelta();

    if (this.mixer) this.mixer.update(delta);

    if (this.currentVRM) {
      this.currentVRM.update(delta);
    }

    if (this.controls) this.controls.update();
    this.renderer.render(this.scene, this.camera);
  };

  onWindowResize() {
    this.camera.aspect = window.innerWidth / window.innerHeight;
    this.camera.updateProjectionMatrix();
    this.renderer.setSize(window.innerWidth, window.innerHeight);
  }

  async loadJsonFile() {
    return this.http.get<any>('assets/male_triangle/Male_mesh_TriangleData.json').toPromise();
  }

  cacheOriginalPoses(object, gender) {
    // Clear old poses
    if (gender === 'M') {
      this.maleOriginalPoses = [];
    } else if (gender === 'F') {
      this.femaleOriginalPoses = [];
    }

    object.traverse((child) => {
      if (child.isBone) {
        // Store position, rotation (quaternion), and scale
        const pose = {
          bone: child,
          position: child.position.clone(),
          quaternion: child.quaternion.clone(),
          scale: child.scale.clone()
        };

        // Push pose to the appropriate array
        if (gender === 'M') {
          this.maleOriginalPoses.push(pose);
        } else if (gender === 'F') {
          this.femaleOriginalPoses.push(pose);
        }
      }
    });
  }

  loadFemaleFBX() {
    const loader = new FBXLoader();
    const avatarFBXPath = 'assets/avatarFBX/avatarBlendedFemale.fbx';

    loader.load(avatarFBXPath, (object) => {
      // Add the loaded FBX object to the scene
      this.femaleFBXObject = object;
      console.log("*************printing female bone names*************")
      this.femaleFBXObject.traverse(child => {
        if (child.isSkinnedMesh) {
          child.material = this.debugMaterial;
        }

        if (child instanceof THREE.Bone) {
          console.log(child.name);
        }
      });

      this.cacheOriginalPoses(this.femaleFBXObject, 'F');
      console.log(this.femaleFBXObject);
      this.femaleFBXObject.visible = false;
      this.scene.add(this.femaleFBXObject);
      //document.getElementById('loadingScreen').style.display = 'none';

    }, undefined, (error) => {
      console.error('An error occurred while loading the FBX file:', error);
      //document.getElementById('loadingScreen').style.display = 'none';
    });


  }

  loadMaleFBX() {
    // Create an instance of the FBXLoader
    const loader = new FBXLoader();
    const avatarFBXPath = 'assets/avatarFBX/avatarBlendedMale.fbx';

    loader.load(avatarFBXPath, (object) => {
      // Add the loaded FBX object to the scene
      this.maleFBXObject = object;

      console.log("*************printing male bone names*************")
      let maleSkeletonHelper;
      this.maleFBXObject.traverse(child => {
        if (child.isSkinnedMesh) {
          child.material = this.debugMaterial;
        }
      });

      this.cacheOriginalPoses(this.maleFBXObject, 'M');
      console.log(this.maleFBXObject);
      this.maleFBXObject.visible = false;
      this.scene.add(this.maleFBXObject);


    }, undefined, (error) => {
      console.error('An error occurred while loading the FBX file:', error);
      // document.getElementById('loadingScreen').style.display = 'none';
    });
  }
  //#endregion

  //#region  Inputcontrol
  updateRendererSize() {

    this.renderer.setSize(290, 470);
    if (window.innerWidth <= 768) {
    } else {
      this.renderer.setSize(window.innerWidth, window.innerHeight);
    }
    this.camera.aspect = this.renderer.domElement.width / this.renderer.domElement.height;
    this.camera.updateProjectionMatrix();
  }

  onFatnessChange() {
    console.log('Fatness Value:', this.fatnessSliderValue);
    this.SettingFatness(this.fatnessSliderValue)
    // You can perform any other actions here based on the value change
  }

  generateLinkForUpperWear(event$) {
    const uppewearUrl = event$.target.value;
    let clothObjeect = this.upperWearList.find((cloth) => cloth.id == uppewearUrl)
    // const path = 'AvatarBlenderClothes/' + clothObjeect.BrandID + '_' + clothObjeect.Gender + '/' + clothObjeect.GarmentType + '/'+uppewearUrl;    
    let packlocation = "AvatarBlenderClothes%2F" + clothObjeect.BrandID + "_" + clothObjeect.Gender + "%2F" + clothObjeect.GarmentType + "%2F" + uppewearUrl;
    const garmentLink = 'https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/' + packlocation + '?alt=media&token=2c1dc261-ac91-4c32-a5ec-ac5e06f0a9bc&_gl=1*goch74*_ga*MjExMjI9MTIyMi4xNjg3NTA3MDk1*_ga_CW55HF8NVT*MTY5NjkzMDY1Ny4xNTcuMS4xNjk2OTM2NzA4LjU5LjAuMA..';
    console.log("cloth location", garmentLink);
    this.submitClothURL(garmentLink, 'U')
  }

  generateLinkForBottomWear(event$) {

    const uppewearUrl = event$.target.value;
    let clothObjeect = this.bottomWearList.find((cloth) => cloth.id == uppewearUrl)
    let packlocation = "AvatarBlenderClothes%2F" + clothObjeect.BrandID + "_" + clothObjeect.Gender + "%2F" + clothObjeect.GarmentType + "%2F" + uppewearUrl;
    const garmentLink = 'https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/' + packlocation + '?alt=media&token=2c1dc261-ac91-4c32-a5ec-ac5e06f0a9bc&_gl=1*goch74*_ga*MjExMjI9MTIyMi4xNjg3NTA3MDk1*_ga_CW55HF8NVT*MTY5NjkzMDY1Ny4xNTcuMS4xNjk2OTM2NzA4LjU5LjAuMA..';

    console.log("cloth location", garmentLink);
    this.submitClothURL(garmentLink, 'B')

  }

  maleFatness: any;
  maleLoaded: boolean;
  showMaleAvatar() {
    if (this.maleUpperWear) {
      this.Uclothes = this.maleUpperWear;
      console.log("upperwear", this.Uclothes);
    }

    this.upperWearList = this.garmentListCollection.filter((cloth) => cloth.GarmentType === 'upperwear' && cloth.Gender == 'M')
    this.bottomWearList = this.garmentListCollection.filter((cloth) => cloth.GarmentType === 'bottomwear' && cloth.Gender == 'M')

    if (this.maleBottomWear) {
      this.Bclothes = this.maleBottomWear;
      console.log("bottomwear", this.Bclothes);
    }


    console.log("showMaleAvatar called ");

    this.maleFBXObject.visible = true
    this.femaleFBXObject.visible = false

    this.currentFBXObject = null
    this.currentFBXObject = this.maleFBXObject;

    this.currentGender = 'M'
    this.currentFatness = this.maleFatness;

    console.log("this.maleLoaded", this.maleLoaded);

    if (!this.maleLoaded) {
      this.DownloadAndLoadAvatar(this.defaultMaleAvatar, 'M');
      this.maleLoaded = true;
    }
  }

  femaleFatness: any;
  femaleLoaded: boolean;
  showFemaleAvatar() {
    this.currentGender = 'F'
    console.log("showFemaleAvatar called ");
    if (this.femaleUpperWear) {
      const Uclothes = this.femaleUpperWear;
      console.log("upperwear", Uclothes);
    }

    if (this.femaleBottomWear) {
      const Bclothes = this.femaleBottomWear;
      console.log("bottomwear", Bclothes);
    }

    this.maleFBXObject.visible = false
    this.femaleFBXObject.visible = true

    this.upperWearList = this.garmentListCollection.filter((cloth) => cloth.GarmentType === 'upperwear' && cloth.Gender == 'F')
    this.bottomWearList = this.garmentListCollection.filter((cloth) => cloth.GarmentType === 'bottomwear' && cloth.Gender == 'F')


    this.currentFBXObject = null;
    this.currentFBXObject = this.femaleFBXObject;

    this.currentGender = 'F'
    this.currentFatness = this.femaleFatness;
    console.log("currentfatness", this.currentFatness);
    if (!this.femaleLoaded) {
      this.DownloadAndLoadAvatar(this.defaultFemaleAvatar, 'F');
      this.femaleLoaded = true;

    }
  }

  generateLinkForFBX($event) {
    let url = $event.target.value;
    console.log('animation', url);
    this.LoadMixamoAnimationForFBX(url);
  }

  generateLinkForBVH(event$) {
    let vrmUrl = event$.target.value;
    let vrmAnimationObject = this.animationListCollection.find((animation) => animation.id == vrmUrl);
    let urlPath = vrmAnimationObject.storageURL.split('/').join('%2F');
    let encodedPath = 'https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/' + urlPath + '?alt=media&token=56a4086c-7d94-4616-8389-f8a568b41f93';
    console.log('encodedPath', encodedPath);
    this.loadBVHFromURL(encodedPath, true)
  }

  //#endregion

  async arrayBufferToTexture(buffer) {
    const img = new Image();
    const blob = new Blob([buffer], { type: "image/png" });
    const url = URL.createObjectURL(blob);
    img.src = url;
    const texture = new THREE.Texture(img);
    img.onload = () => {
      texture.needsUpdate = true;
    };
    return texture;

  }

  arrayBufferToTextureNonAsync(buffer) {
    const img = new Image();
    const blob = new Blob([buffer], { type: "image/png" });
    const url = URL.createObjectURL(blob);
    img.src = url;
    const texture = new THREE.Texture(img);
    img.onload = () => {
      texture.needsUpdate = true;
    };
    return texture;
  }

  //#region avatar
  async DownloadAndLoadAvatar(avatarURL, gender) {

    let avatarDataReceived;
    avatarDataReceived = await this.downloadZipAndLoadAvatar(avatarURL);

    if (avatarDataReceived == null) {
      console.log("Avatar data not found");
    }

    const { material, hairmaterial, clothSaveFileJSON, fatness } = await this.LoadAvatarMeshData(avatarDataReceived);

    if (this.currentGender == 'M') {
      this.maleFatness = fatness;
    }
    else {
      this.femaleFatness = fatness;
    }

    this.currentFatness = fatness;
    console.log("fatness", fatness);

    await this.updateAvatarMeshWithData(material, hairmaterial, clothSaveFileJSON, gender);

    await new Promise(resolve => setTimeout(resolve, 10));
    if (gender == 'M') {
      // await this.submitClothURL(this.defaultMaleUpperwear, 'U');
      // await this.submitClothURL(this.defaultMaleBottomwear, 'B');
      await this.LoadMixamoAnimationForFBX(this.defaultAnimation);
    }
    else {
      // await this.submitClothURL(this.defaultFemaleUpperwear, 'U');
      // await this.submitClothURL(this.defaultFemaleBottomwear, 'B');
      await this.LoadMixamoAnimationForFBX(this.defaultAnimation);
    }

    console.log("currentFBXObject", this.currentFBXObject);
    await new Promise(resolve => setTimeout(resolve, 30));
    //SettingFatness(fatness);
    // document.getElementById('loadingScreen').style.display = 'none';

  }

  async downloadZipAndLoadAvatar(url) {
    try {
      const filesToProcess = ['savedMetalRoughTex.jpg', 'savedNormalTex.jpg', 'savedHairTex.png', 'savedTex.jpg', 'savefile.json'];
      let downloadedData = {};

      const response = await fetch(url);
      const zipData = await response.arrayBuffer();
      const zip = await JSZip.loadAsync(zipData);

      for (const fileName of filesToProcess) {
        if (zip.files[fileName]) {
          const data = await zip.files[fileName].async('arraybuffer');
          downloadedData[fileName] = data;
        }
      }
      console.log("downloaded data", downloadedData);
      return downloadedData;
      console.log("Worker Done");
    } catch (error) {
      return null;
      console.error("An error occurred:", error);
    }
  }

  async LoadAvatarMeshData(data) {
    let downloadedData = data

    const clothSaveFileJSON = await JSON.parse(new TextDecoder().decode(downloadedData['savefile.json']));
    const albedoTexture = await this.arrayBufferToTexture(downloadedData['savedTex.jpg']);
    const normalTexture = await this.arrayBufferToTexture(downloadedData['savedNormalTex.jpg']);
    const hairTexture = await this.arrayBufferToTexture(downloadedData['savedHairTex.png']);
    const metalTexture = downloadedData['savedMetalRoughTex.jpg'] ? await this.arrayBufferToTexture(downloadedData['savedMetalRoughTex.jpg']) : null;

    albedoTexture.wrapS = albedoTexture.wrapT = THREE.RepeatWrapping;
    normalTexture.wrapS = normalTexture.wrapT = THREE.RepeatWrapping;
    hairTexture.wrapS = hairTexture.wrapT = THREE.RepeatWrapping;

    if (metalTexture) {
      const metal = metalTexture.wrapT = THREE.RepeatWrapping;
      metalTexture.wrapS = await metal;
    }

    const material = new THREE.MeshStandardMaterial({
      map: albedoTexture,
      normalMap: normalTexture,
      metalness: metalTexture ? undefined : 0.3, // If there is no metalness texture, set a default metalness
      envMap: this.scene.environment, // Set the environment map
      side: THREE.DoubleSide,
    });

    const hairmaterial = new THREE.MeshStandardMaterial({
      map: hairTexture,
      transparent: true,
      alphaTest: 0.5
    });

    return {
      material: material,
      hairmaterial: hairmaterial,
      clothSaveFileJSON: clothSaveFileJSON,
      fatness: clothSaveFileJSON.fatBlendshapeValue
    };
  }

  async updateAvatarMeshWithData(material, hairmaterial, clothSaveFileJSON, gender) {
    this.heightScale = clothSaveFileJSON.heightScale;
    let headbone;
    console.log("looking for skinnedMesh");
    console.log(this.triangleData);

    this.currentFBXObject.traverse(child => {
      if (child instanceof THREE.SkinnedMesh) {


        const vertices = clothSaveFileJSON.vertices.map(v => new THREE.Vector3(v.x, v.y, -v.z));

        const triangles = [];
        for (let i = 0; i < this.triangleData.Triangles.length; i += 3) {
          triangles.push( this.triangleData.Triangles[i]);
          triangles.push( this.triangleData.Triangles[i + 2]);
          triangles.push( this.triangleData.Triangles[i + 1]);
        }

        const expandedVertices = [];
        for (let i = 0; i < triangles.length; i++) {
          const vertexIndex = triangles[i];
          const vertex = vertices[vertexIndex];
          expandedVertices.push(vertex.x, vertex.y, vertex.z);
        }


        for (let i = 0; i < expandedVertices.length; i++) {
          child.geometry.attributes.position.array[i] = expandedVertices[i];
        }

        // Mark the position attribute as needing an update
        child.geometry.attributes.position.needsUpdate = true;



        let normals = [];
        if (clothSaveFileJSON.normals) {
          normals = clothSaveFileJSON.normals.map(n => new THREE.Vector3(n.x, n.y, -n.z));
        } else {

          return;
        }

        const expandedNormals = [];
        for (let i = 0; i < triangles.length; i++) {
          const normalIndex = triangles[i];
          const normal = normals[normalIndex];
          expandedNormals.push(normal.x, normal.y, normal.z);
        }


        // Update the normals array
        for (let i = 0; i < expandedNormals.length; i++) {
          child.geometry.attributes.normal.array[i] = expandedNormals[i];
        }
        child.geometry.attributes.normal.needsUpdate = true;



        const uvCoordinates = clothSaveFileJSON.uv.map(uv => new THREE.Vector2(uv.x, uv.y));


        const expandedUVs = [];
        for (let i = 0; i < triangles.length; i++) {
          const uvIndex = triangles[i];
          const uv = uvCoordinates[uvIndex];
          expandedUVs.push(uv.x, uv.y);
        }


        for (let i = 0; i < expandedUVs.length; i++) {
          child.geometry.attributes.uv.array[i] = expandedUVs[i];
        }
        child.geometry.attributes.uv.needsUpdate = true;

        // If your geometry uses morph targets or skinning, you may also need to update the bounding box
        child.geometry.computeBoundingBox();
        child.geometry.computeBoundingSphere();

        var rotationMatrix = new THREE.Matrix4().makeRotationY(Math.PI);
        child.geometry.applyMatrix4(rotationMatrix);

        child.material = material;
      }
      if (child instanceof THREE.Bone) {
        if (child.name == "Head") {
          headbone = child;
        }
      }
    });

    //
    this.LoadHairData(clothSaveFileJSON, hairmaterial, headbone, gender);
    this.currentFBXObject.scale.set(this.heightScale, this.heightScale, this.heightScale);
  }

  removeHair(avatarParent) {
    if (avatarParent.HairObject) {
      avatarParent.HairObject.geometry.dispose();
      avatarParent.HairObject.material.dispose();
      this.scene.remove(avatarParent.HairObject);
      avatarParent.HairObject = null;
    }
  }

  LoadHairData(clothSaveFileJSON, hairmaterial, headbone, gender) {
    const debugMaterial = new THREE.MeshNormalMaterial();
    const hairVertices = clothSaveFileJSON.hairVertices.map(v => new THREE.Vector3(v.x, v.y, -v.z));
    const hairTriangles = [];
    for (let i = 0; i < clothSaveFileJSON.hairTriangles.length; i += 3) {
      hairTriangles.push(clothSaveFileJSON.hairTriangles[i]);
      hairTriangles.push(clothSaveFileJSON.hairTriangles[i + 2]);
      hairTriangles.push(clothSaveFileJSON.hairTriangles[i + 1]);
    }
    const flattenedhairUVs = clothSaveFileJSON.hairUv.map(uv => [uv.x, uv.y]).flat();
    const expandedHairVertices = [];
    for (let i = 0; i < hairTriangles.length; i++) {
      const vertexIndex = hairTriangles[i];
      const vertex = hairVertices[vertexIndex];
      expandedHairVertices.push(vertex.x, vertex.y, vertex.z);
    }
    const hairGeometry = new THREE.BufferGeometry();
    hairGeometry.setFromPoints(hairVertices);
    hairGeometry.setIndex(hairTriangles);
    hairGeometry.setAttribute('uv', new THREE.Float32BufferAttribute(flattenedhairUVs.flat(), 2));
    hairGeometry.computeVertexNormals();

    hairGeometry.scale(1 / this.heightScale, 1 / this.heightScale, 1 / this.heightScale);
    const hairMesh = new THREE.Mesh(hairGeometry, debugMaterial);
    hairMesh.position.set(0, 0, 0);
    hairMesh.rotation.y = Math.PI;
    hairMesh.material = hairmaterial
    this.scene.add(hairMesh);

    const globalPosition = new THREE.Vector3();
    headbone.getWorldPosition(globalPosition);

    let pivot = new THREE.Object3D();
    pivot.position.copy(globalPosition);

    hairMesh.position.sub(globalPosition);

    pivot.add(hairMesh);

    // Now, pivotMesh's position is at the headbone's global position, and hairMesh is correctly positioned relative to pivotMesh
    this.scene.add(pivot);
    headbone.add(pivot);
    pivot.position.set(0, 0, 0);
    if (gender == 'M') {
      this.maleHair = pivot;
      this.maleHeadBone = headbone;
    }
    else {
      this.femaleHair = pivot;
      this.femaleHeadBone = headbone;
    }
  }
  //#endregion

  //#region  cloth
  async submitClothURL(url, type) {
    try {
      // document.getElementById('loadingScreen').style.display = 'block';

      const filesToProcess = ['Albedo.jpg', 'Normal.png', 'Metal.png', 'clothSaveFile.json'];
      let downloadedData = {};

      const response = await fetch(url);
      const zipData = await response.arrayBuffer();
      const zip = await JSZip.loadAsync(zipData);

      for (const fileName of filesToProcess) {
        if (zip.files[fileName]) {
          const data = await zip.files[fileName].async('arraybuffer');
          downloadedData[fileName] = data;
        }
      }
      this.LoadClothes(downloadedData, type);
    } catch (error) {
      console.error("An error occurred:", error);
    } finally {
      // document.getElementById('loadingScreen').style.display = 'none';
    }


  }

  async LoadClothes(downloadedData, type) {

    this.stopAnimationAndResetPose(this.currentGender);
    await new Promise(resolve => setTimeout(resolve, 30));
    const clothSaveFileJSON = JSON.parse(new TextDecoder().decode(downloadedData['clothSaveFile.json']));
    let isMale = clothSaveFileJSON.gender;
    console.log(isMale);

    const albedoTexture = this.arrayBufferToTextureNonAsync(downloadedData['Albedo.jpg']);
    const normalTexture = this.arrayBufferToTextureNonAsync(downloadedData['Normal.png']);
    const metalTexture = downloadedData['Metal.png'] ? this.arrayBufferToTextureNonAsync(downloadedData['Metal.png']) : null;
    albedoTexture.wrapS = albedoTexture.wrapT = THREE.RepeatWrapping;
    normalTexture.wrapS = normalTexture.wrapT = THREE.RepeatWrapping;
    if (metalTexture) {
      metalTexture.wrapS = metalTexture.wrapT = THREE.RepeatWrapping;
    }

    // Extracting mesh data
    const { vertices, normals, flattenedUVs, triangles, blendShapes, boneWeights } = this.extractClothesMeshData(clothSaveFileJSON);

    // Assuming `currentFBXModel` holds your loaded FBX model
    const skeleton = this.getSkeletonFromFBX(this.currentFBXObject);

    const geometry = new THREE.BufferGeometry();
    geometry.setFromPoints(vertices);
    const flattenedNormals = normals.flatMap(v => [v.x, v.y, v.z]);
    geometry.setAttribute('normal', new THREE.Float32BufferAttribute(flattenedNormals, 3));
    geometry.setIndex(triangles);
    geometry.setAttribute('uv', new THREE.Float32BufferAttribute(flattenedUVs, 2));

    const skinIndices = [];
    const skinWeights = [];

    //console.log("boneWeights",boneWeights);
    for (let i = 0; i < boneWeights.length; i++) {
      const bw = boneWeights[i];
      if (bw === undefined) {
        console.log('Undefined bw at index:', i);
      } else {
        skinIndices.push(bw.m_BoneIndex0, bw.m_BoneIndex1, bw.m_BoneIndex2, bw.m_BoneIndex3);
        skinWeights.push(bw.m_Weight0, bw.m_Weight1, bw.m_Weight2, bw.m_Weight3);
      }
    }
    geometry.setAttribute('skinIndex', new THREE.Uint16BufferAttribute(skinIndices, 4));
    geometry.setAttribute('skinWeight', new THREE.Float32BufferAttribute(skinWeights, 4));

    // Apply blend shape data to the geometry
    for (let blendShape of blendShapes) {
      if (!geometry.morphAttributes['position']) {
        geometry.morphAttributes['position'] = [];
      }
      const attribute = new THREE.Float32BufferAttribute(blendShape.vertices, 3);
      attribute.name = blendShape.name;
      geometry.morphAttributes['position'].push(attribute);

    }

    //  const material = new THREE.MeshNormalMaterial();

    const material = new THREE.MeshStandardMaterial({
      map: albedoTexture,
      normalMap: normalTexture,
      metalnessMap: metalTexture,
      side: THREE.DoubleSide,
    });


    var rotationMatrix = new THREE.Matrix4().makeRotationY(Math.PI);
    geometry.applyMatrix4(rotationMatrix);

    if (geometry.morphAttributes['position']) {
      for (let attribute of geometry.morphAttributes['position']) {
        for (let i = 0; i < attribute.count; i++) {
          let vertex = new THREE.Vector3();
          vertex.fromBufferAttribute(attribute, i);
          vertex.applyMatrix4(rotationMatrix);
          attribute.setXYZ(i, vertex.x, vertex.y, vertex.z);
        }
      }
    }

    const newMesh = new THREE.SkinnedMesh(geometry, material);

    this.currentFBXObject.scale.set(1, 1, 1);
    if (this.currentGender == 'M') {
      this.maleHeadBone.remove(this.maleHair);
    }
    else {
      this.femaleHeadBone.remove(this.femaleHair);
    }
    await new Promise(resolve => setTimeout(resolve, 10));

    this.currentFBXObject.add(newMesh);
    newMesh.add(skeleton.bones[0].clone()); // Add the root bone of the skeleton
    newMesh.bind(skeleton);

    await new Promise(resolve => setTimeout(resolve, 10));
    this.currentFBXObject.scale.set(this.heightScale, this.heightScale, this.heightScale);
    if (this.currentGender == 'M') {
      this.maleHeadBone.add(this.maleHair);
    }
    else {
      this.femaleHeadBone.add(this.femaleHair);
    }

    newMesh.castShadow = true;
    if (type == 'U') {

      if (this.Uclothes) {
        this.currentFBXObject.remove(this.Uclothes);
        this.scene.remove(this.Uclothes);
        this.Uclothes.geometry.dispose();
        this.Uclothes.material.dispose();
        this.Uclothes = null; // Set to null to fully remove reference
      }
      this.Uclothes = newMesh;
      this.setMorphIntensity(this.Uclothes, "_fat", this.currentFatness / 100);

      if (this.currentGender == 'M') {
        if (this.maleUpperWear) {
          this.currentFBXObject.remove(this.maleUpperWear);
          this.scene.remove(this.maleUpperWear);
          this.maleUpperWear.geometry.dispose();
          this.maleUpperWear.material.dispose();
          this.maleUpperWear = null; // Set to null to fully remove reference
        }
        this.maleUpperWear = newMesh;
        console.log("maleUpperWear", this.maleUpperWear);
      }
      else {
        if (this.femaleUpperWear) {
          this.currentFBXObject.remove(this.femaleUpperWear);
          this.scene.remove(this.femaleUpperWear);
          this.femaleUpperWear.geometry.dispose();
          this.femaleUpperWear.material.dispose();
          this.femaleUpperWear = null; // Set to null to fully remove reference
        }

        this.femaleUpperWear = newMesh;
        console.log("femaleUpperWear", this.femaleUpperWear);
      }
    }
    if (type == 'B') {
      if (this.Bclothes) {
        this.currentFBXObject.remove(this.Bclothes);
        this.scene.remove(this.Bclothes);
        this.Bclothes.geometry.dispose();
        this.Bclothes.material.dispose();
        this.Bclothes = null; // Set to null to fully remove reference
      }
      this.Bclothes = newMesh;
      this.setMorphIntensity(this.Bclothes, "_fat", this.currentFatness / 100);

      if (this.currentGender == 'M') {
        if (this.maleBottomWear) {
          this.currentFBXObject.remove(this.maleBottomWear);
          this.scene.remove(this.maleBottomWear);
          this.maleBottomWear.geometry.dispose();
          this.maleBottomWear.material.dispose();
          this.maleBottomWear = null; // Set to null to fully remove reference

        }
        this.maleBottomWear = newMesh;
        console.log("maleLowerWear", this.maleBottomWear);
      }
      else {

        if (this.femaleBottomWear) {
          this.currentFBXObject.remove(this.femaleBottomWear);
          this.scene.remove(this.femaleBottomWear);
          this.femaleBottomWear.geometry.dispose();
          this.femaleBottomWear.material.dispose();
          this.femaleBottomWear = null; // Set to null to fully remove reference
        }

        this.femaleBottomWear = newMesh;
        console.log("femaleLowerWear", this.femaleBottomWear);
      }
    }
  }

  extractClothesMeshData(clothSaveFileJSON) {
    const vertices = clothSaveFileJSON.vertices.map(v => new THREE.Vector3(v.x, v.y, -v.z));
    const normals = clothSaveFileJSON.normals.map(n => new THREE.Vector3(n.x, n.y, -n.z));
    const flattenedUVs = clothSaveFileJSON.uv.map(uv => [uv.x, uv.y]).flat();

    const triangles = [];
    for (let i = 0; i < clothSaveFileJSON.triangles.length; i += 3) {
      triangles.push(clothSaveFileJSON.triangles[i]);
      triangles.push(clothSaveFileJSON.triangles[i + 2]);
      triangles.push(clothSaveFileJSON.triangles[i + 1]);
    }

    // Extract blend shape data
    const blendShapes = [];
    const sVertCount = clothSaveFileJSON.vertices.length;
    for (let i = 0; i < clothSaveFileJSON.blendShapeCount; i++) {
      const morphVertices = [];
      for (let j = 0; j < sVertCount; j++) {
        const baseVertex = vertices[j];
        const v = clothSaveFileJSON.deltaVertices[j + (i * sVertCount)];
        const finalVertex = baseVertex.clone().add(new THREE.Vector3(v.x, v.y, -v.z));
        morphVertices.push(finalVertex.x, finalVertex.y, finalVertex.z);
      }
      blendShapes.push({
        name: clothSaveFileJSON.blendShapeNames[i],
        vertices: morphVertices
      });
    }
    const reorderedBoneWeights = clothSaveFileJSON.boneWeights;

    return {
      vertices: vertices,
      normals: normals,
      flattenedUVs: flattenedUVs,
      triangles: triangles,
      blendShapes: blendShapes,
      boneWeights: reorderedBoneWeights
    };
  }

  getSkeletonFromFBX(fbxModel) {
    let skeleton = null;

    // A recursive function to search through all descendants of the model
    fbxModel.traverse((child) => {
      if (child.isSkinnedMesh) {
        skeleton = child.skeleton;
      }
    });

    return skeleton;
  }

  SettingFatness(fatness) {
    const minFatness = 0;
    const maxFatness = 100 - this.currentFatness;
    console.log("minFatness", minFatness, "maxFatness", maxFatness, "currentFatness", this.currentFatness);

    this.currentFBXObject.traverse((child) => {
      if (child.isSkinnedMesh && child.name === 'mesh') {
        console.log("mapped fatness to range", this.mapToRange(fatness, minFatness, maxFatness) / 100);
        this.setMorphIntensity(child, "_fat", this.mapToRange(fatness, minFatness, maxFatness) / 100);
      }
    });

    if (this.currentGender == 'M') {
      if (this.maleUpperWear) {
        this.setMorphIntensity(this.maleUpperWear, "_fat", (this.mapToRange(fatness, this.currentFatness, 100)) / 100);
        console.log("maleUpperWear", this.maleUpperWear);
      }

      if (this.maleBottomWear) {
        this.setMorphIntensity(this.maleBottomWear, "_fat", (this.mapToRange(fatness, this.currentFatness, 100)) / 100);
        console.log("maleBottomWear", this.maleBottomWear);
      }
    }

    if (this.currentGender == 'F') {
      if (this.femaleUpperWear) {
        this.setMorphIntensity(this.femaleUpperWear, "_fat", (this.mapToRange(fatness, this.currentFatness, 100)) / 100);
        console.log("femaleUpperWear", this.femaleUpperWear);
      }

      if (this.femaleBottomWear) {
        this.setMorphIntensity(this.femaleBottomWear, "_fat", (this.mapToRange(fatness, this.currentFatness, 100)) / 100);
        console.log("femaleBottomWear", this.femaleBottomWear);
      }
    }
    

  }
/**
 * Maps a value from an arbitrary range to the range 0 to 100.
 *
 * @param {number} value The value to map.
 * @param {number} min The minimum value of the input range.
 * @param {number} max The maximum value of the input range.
 * @returns {number} The value mapped to the range 0 to 100.
 */
  mapToRange(value, min, max) {
    if (min === max) {
        console.error("Invalid range: min and max cannot be the same.");
        return 0; // Or handle error as appropriate
    }

    // First, normalize value to a 0-1 range relative to min and max
    const normalized = ((value / 100) * (max - min)) + min;

    // Then scale to 0-100 range and return
    return normalized;
}

  setMorphIntensity(mesh, morphName, intensity) {
    const geometry = mesh.geometry;
    const morphAttributes = geometry.morphAttributes.position; // Assuming position attributes
    
    if (!morphAttributes) {
        console.error("No morph attributes found on the geometry!");
        return;
    }

    // Find the index based on userData
    const morphIndex = morphAttributes.findIndex(attr => attr.name === morphName);

    if (morphIndex === -1) {
        console.error(`Morph target ${morphName} not found!`);
        return;
    }

    mesh.morphTargetInfluences[morphIndex] = intensity;
}
  //#endregion


  //#region animation
  async LoadMixamoAnimationForFBX(animationUrl) {
    console.log(animationUrl)
    this.mixer = new THREE.AnimationMixer(this.currentFBXObject);
    console.log(this.currentFBXObject)
    console.log(this.mixer)

    await this.loadMixamoAnimationFBX(animationUrl, this.currentFBXObject).then((clip) => {

      if (this.currentAction) {
        this.currentAction.stop();
      }
      this.currentAction = this.mixer.clipAction(clip);
      this.currentAction.play();
    });

  }

  async loadMixamoAnimationFBX(url, fbx) {
  
    const loader = new FBXLoader(); // A loader which loads FBX
    return loader.loadAsync(url).then((asset) => {

      const clip = THREE.AnimationClip.findByName(asset.animations, 'mixamo.com'); // extract the AnimationClip
      const tracks = []; // KeyframeTracks compatible with VRM will be added here

      const restRotationInverse = new THREE.Quaternion();
      const parentRestWorldRotation = new THREE.Quaternion();
      const _quatA = new THREE.Quaternion();
      const _vec3 = new THREE.Vector3();

      // Adjust with reference to hips height.
      const motionHipsHeight = asset.getObjectByName('mixamorigHips').position.y;


      let pelvisBone = fbx.children[0].children[0];


      const fbxHipsY = pelvisBone.getWorldPosition(_vec3).y;


      const fbxRootY = fbx.getWorldPosition(_vec3).y;


      const fbxHipsHeight = Math.abs(fbxHipsY - fbxRootY);


      const hipsPositionScale = fbxHipsHeight / motionHipsHeight;



      clip.tracks.forEach((track) => {

        // Convert each tracks for VRM use, and push to `tracks`
        const trackSplitted = track.name.split('.');
        const mixamoRigName = trackSplitted[0];
        const fbxBoneName = this.mixamoRigMapJsonAvatar[mixamoRigName];
        const fbxNodeName = fbxBoneName;

        const mixamoRigNode = asset.getObjectByName(mixamoRigName);

        if (fbxNodeName != null) {

          const propertyName = trackSplitted[1];

          // Store rotations of rest-pose.
          mixamoRigNode.getWorldQuaternion(restRotationInverse).invert();
          mixamoRigNode.parent.getWorldQuaternion(parentRestWorldRotation);

          if (track instanceof THREE.QuaternionKeyframeTrack) {

            // Retarget rotation of mixamoRig to NormalizedBone.
            for (let i = 0; i < track.values.length; i += 4) {

              const flatQuaternion = track.values.slice(i, i + 4);

              _quatA.fromArray(flatQuaternion);

              // 親のレスト時ワールド回転 * トラックの回転 * レスト時ワールド回転の逆
              _quatA
                .premultiply(parentRestWorldRotation)
                .multiply(restRotationInverse);

              _quatA.toArray(flatQuaternion);

              flatQuaternion.forEach((v, index) => {

                track.values[index + i] = v;

              });

            }

            tracks.push(
              new THREE.QuaternionKeyframeTrack(
                `${fbxNodeName}.${propertyName}`,
                track.times,
                track.values.map((v, i) => (fbx.meta?.metaVersion === '0' && i % 2 === 0 ? - v : v)),
              ),
            );

          } else if (track instanceof THREE.VectorKeyframeTrack) {

            const value = track.values.map((v, i) => (fbx.meta?.metaVersion === '0' && i % 3 !== 1 ? - v : v) * hipsPositionScale);
            tracks.push(new THREE.VectorKeyframeTrack(`${fbxNodeName}.${propertyName}`, track.times, value));

          }

        }

      });
      console.log('clip', clip)
      return new THREE.AnimationClip('fbxAnimation', clip.duration, tracks);

    });

  }

  async loadBVHFromURL(url, isFBX = false) {
  
    const response = await fetch(url);
    const text = await response.text();

    var lines = text.split(/[\r\n]+/g);
    var root = BVHImport.readBvh(lines);

    var animation = BVHImport.toTHREE(root);
    console.log("animation", animation);

    var geometry = new THREE.BufferGeometry();

    var material = new THREE.MeshPhongMaterial();
    var helpermesh = new THREE.SkinnedMesh(geometry, material);

    helpermesh.add(animation.skeleton.bones[0]);
    helpermesh.bind(animation.skeleton);

    const skeletonHelper = new THREE.SkeletonHelper(helpermesh);
    skeletonHelper.material['linewidth'] = 5;

    this.scene.add(skeletonHelper);
    this.scene.add(helpermesh);

    this.mixer = new THREE.AnimationMixer(helpermesh);
    this.mixer.clipAction(animation.clip).setEffectiveWeight(1.0).play();

    if (isFBX) {
      await this.loadBvhAnimationFBX(animation, this.currentFBXObject).then((clip) => {
        this.mixer = new THREE.AnimationMixer(this.currentFBXObject);
        console.log("clip received", clip);
        if (this.currentAction) {
          this.currentAction.stop();
        }
        this.currentAction = this.mixer.clipAction(clip);
        this.currentAction.play();

      });
    }
    else {
      await this.loadBvhAnimation(animation, this.currentVRM).then((clip) => {
        this.mixer = new THREE.AnimationMixer(this.currentVRM.scene);
        console.log("clip received", clip);
        if (this.currentAction) {
          this.currentAction.stop();
        }
        this.currentAction = this.mixer.clipAction(clip);
        this.currentAction.play();
      });
    }
    this.scene.remove(helpermesh);
    this.scene.remove(skeletonHelper);
  }

  async loadBvhAnimation(url, vrm) {
    console.log("calling load bvh");

    const clip = url.clip; // extract the AnimationClip
    const tracks = []; // KeyframeTracks compatible with VRM will be added here

    const restRotationInverse = new THREE.Quaternion();
    const parentRestWorldRotation = new THREE.Quaternion();
    const _quatA = new THREE.Quaternion();
    const _vec3 = new THREE.Vector3();

    // Adjust with reference to hips height.
    const bones = url.skeleton.bones; // Get the array of bones from the skeleton
    const motionHipsHeightbone = bones.find(bone => bone.name === 'hips_JNT');

    // Introduce a delay
    await new Promise(resolve => setTimeout(resolve, 100));

    const motionHipsHeight = motionHipsHeightbone.position.y;

    const vrmHipsY = vrm.humanoid?.getNormalizedBoneNode('hips').getWorldPosition(_vec3).y;

    const vrmRootY = vrm.scene.getWorldPosition(_vec3).y;

    const vrmHipsHeight = Math.abs(vrmHipsY - vrmRootY);

    const hipsPositionScale = vrmHipsHeight / motionHipsHeight;

    clip.tracks.forEach((track) => {

      // Convert each tracks for VRM use, and push to `tracks`
      const trackSplitted = track.name.split('.');
      const splitStr = trackSplitted[1].split('[')[1];
      const bvhRigName = splitStr.slice(0, -1); // This will remove the last character, giving you "hips_JNT"
      const vrmBoneName = this.boneMappingVRM[bvhRigName];
      const vrmNodeName = vrm.humanoid?.getNormalizedBoneNode(vrmBoneName)?.name;
      const bvhRigNode = bones.find(bone => bone.name === bvhRigName);


      if (vrmNodeName != null) {
        const propertyName = trackSplitted[2];
        // Store rotations of rest-pose.
        bvhRigNode.getWorldQuaternion(restRotationInverse).invert();
        bvhRigNode.parent.getWorldQuaternion(parentRestWorldRotation);

        if (track instanceof THREE.QuaternionKeyframeTrack) {
          tracks.push(
            new THREE.QuaternionKeyframeTrack(
              `${vrmNodeName}.${propertyName}`,
              track.times,
              track.values.map((v, i) => (vrm.meta?.metaVersion === '0' && i % 2 === 0 ? - v : v)),
            ),
          );

        } else if (track instanceof THREE.VectorKeyframeTrack) {
          const value = track.values.map((v, i) => (vrm.meta?.metaVersion === '0' && i % 3 !== 1 ? - v : v) * hipsPositionScale);
          tracks.push(new THREE.VectorKeyframeTrack(`${vrmNodeName}.${propertyName}`, track.times, value));
        }
      }
    });

    return new THREE.AnimationClip('vrmAnimation', clip.duration, tracks);
  }

  async loadBvhAnimationFBX(url, fbx) {
    console.log("calling load bvh");

    const clip = url.clip; // extract the AnimationClip
    const tracks = []; // KeyframeTracks compatible with VRM will be added here

    const restRotationInverse = new THREE.Quaternion();
    const parentRestWorldRotation = new THREE.Quaternion();
    const _quatA = new THREE.Quaternion();
    const _vec3 = new THREE.Vector3();

    // Adjust with reference to hips height.
    const bones = url.skeleton.bones; // Get the array of bones from the skeleton
    const motionHipsHeightbone = bones.find(bone => bone.name === 'hips_JNT');

    // Introduce a delay
    await new Promise(resolve => setTimeout(resolve, 100));

    const motionHipsHeight = motionHipsHeightbone.position.y;
    console.log("motionHipsHeight", motionHipsHeight);

    let pelvisBone = fbx.children[0].children[0];
    console.log("pelvisBone", pelvisBone);

    const fbxHipsY = pelvisBone.getWorldPosition(_vec3).y;
    console.log("fbxHipsY", fbxHipsY);

    const fbxRootY = fbx.getWorldPosition(_vec3).y;
    console.log("fbxRootY", fbxRootY);

    const fbxHipsHeight = Math.abs(fbxHipsY - fbxRootY);
    console.log("fbxHipsHeight", fbxHipsHeight);

    const hipsPositionScale = fbxHipsHeight / motionHipsHeight;
    console.log("hipsPositionScale", hipsPositionScale);

    clip.tracks.forEach((track) => {

      // Convert each tracks for VRM use, and push to `tracks`
      const trackSplitted = track.name.split('.');
      const splitStr = trackSplitted[1].split('[')[1];
      const bvhRigName = splitStr.slice(0, -1);

      const fbxBoneName = this.boneMappingJsonAvatar[bvhRigName];
      const fbxNodeName = fbxBoneName;
      const bvhRigNode = bones.find(bone => bone.name === bvhRigName);

      if (fbxNodeName == null) {
        //Error log
        console.log("fbxNodeName is null");
      }

      if (fbxNodeName != null) {
        const propertyName = trackSplitted[2];
        // Store rotations of rest-pose.
        bvhRigNode.getWorldQuaternion(restRotationInverse).invert();
        bvhRigNode.parent.getWorldQuaternion(parentRestWorldRotation);

        if (track instanceof THREE.QuaternionKeyframeTrack) {
          tracks.push(
            new THREE.QuaternionKeyframeTrack(
              `${fbxNodeName}.${propertyName}`,
              track.times,
              track.values.map((v) => (-v)),
            ),
          );

        } else if (track instanceof THREE.VectorKeyframeTrack) {
          if (fbxNodeName == "Pelvis") {
            const value = track.values.map((v) => (v) * hipsPositionScale);
            tracks.push(new THREE.VectorKeyframeTrack(`${fbxNodeName}.${propertyName}`, track.times, value));
          }
        }
      }
    });

    return new THREE.AnimationClip('vrmAnimation', clip.duration, tracks);
  }

  stopAnimationAndResetPose(gender) {
    if (this.currentAction) {
      this.currentAction.stop();
    }

    // Choose the appropriate array based on the gender
    let originalPoses = gender === 'M' ? this.maleOriginalPoses : this.femaleOriginalPoses;

    // Reset each bone to its original pose
    originalPoses.forEach((pose) => {
      pose.bone.position.copy(pose.position);
      pose.bone.quaternion.copy(pose.quaternion);
      pose.bone.scale.copy(pose.scale);
    });
  }

  //#endregion
}