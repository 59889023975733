import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';
import { Brand } from 'src/app/models/brand';
import { MatDialog } from '@angular/material/dialog';
import { BrandDialogComponent, BrandDialogResult } from './brand-dialog/brand-dialog.component';
import { CommonService } from 'src/app/services/common.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-brands',
  templateUrl: './brands.component.html',
  styleUrls: ['./brands.component.css']
})
export class BrandsComponent implements OnInit {
  brands = this.firestore.collection('brands').valueChanges({ idField: 'id' }) as Observable<Brand[]>;
  
  selectedBrand:any = {};
  getZipcollection: any;
  zipfile: any;
  loading = false;
  logInFlag: boolean;
  brandList :any = []
  filterBrandList :any = []


  constructor(private firestore: AngularFirestore,
    private dialog: MatDialog,
    public commonService: CommonService,
    private router: Router) {
    this.logInFlag = this.commonService.isLoggedIn()
    this.checkPageAuthorization()
  }

  ngOnInit() {
    let title = 'Yologram - brand-info'
    this.commonService.changeTitle(title);
   
        this.brands.subscribe((brand) => {
      this.brandList = brand
      this.filterBrandList = this.brandList;
      this.filterBrandList.forEach(async (brand) => {
        let type = 'b';
        let url = await this.commonService.compressImage(brand.LogoURL, type); // Assuming each brand has 'imagePath' property
        brand.Brandurl = url.downloadUrl;  // Save the compressed image URL back into the brand object
      });
    })
  }

  getBrandImage(imgURL:string){
    let brandEncodedurl = encodeURI(imgURL).replace('/','%2F');
    let url = "https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/"+brandEncodedurl+"?alt=media&token=550e7e88-bed3-4668-84ff-ad491c19e06c"
    return url;
  }

  showBrandDetails(brand:any){
 this.selectedBrand = brand;
}
  
  // suggest($event) {
  //     let inputValue = $event.target.value.toLowerCase(); // Convert input to lowercase for case-insensitive matching
  //     if (!inputValue) {
  //       // If inputValue is blank or null, show all brands
  //       this.filterBrandList = this.brandList;
  //     } else {
  //       this.filterBrandList = this.brandList.filter(brand => {
  //         const brandName = brand.BrandName;
  //         // return brandName && brandName.toLowerCase().startsWith(inputValue);
  //         return brandName && brandName.toLowerCase().includes(inputValue);
  //       }).slice(0, 10);
  //     }
  // }
  

  suggest($event) {
    let inputValue = $event.target.value.toLowerCase(); // Convert input to lowercase for case-insensitive matching
    if (!inputValue) {
      // If inputValue is blank or null, show all brands
      this.filterBrandList = this.brandList;
    } else {
      const startsWithMatches = this.brandList.filter(brand => {
        const brandName = brand.BrandName;
        return brandName && brandName.toLowerCase().startsWith(inputValue);
      });
      const includesMatches = this.brandList.filter(brand => {
        const brandName = brand.BrandName;
        return brandName && brandName.toLowerCase().includes(inputValue);
      }).filter(brand => !startsWithMatches.includes(brand)); // Exclude items already included in startsWithMatches
      this.filterBrandList = startsWithMatches.slice(0, 10).concat(includesMatches.slice(0, 10));
    }
  }

  checkPageAuthorization(){
    this.loading = true;
    this.commonService.getUser().subscribe((user: any) => {
      let userObject = { 'userid': user?.uid, 'pagename': '/brand-info' }
      this.commonService.checkViewPageRights(userObject).subscribe((res) => {
        this.loading = false;
        if (!res) {
          this.router.navigateByUrl("/menu-page");
        }
      });
    });
  }

}
