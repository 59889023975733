import { Component, ElementRef, EventEmitter, Inject, Input, Output, SimpleChanges, ViewChild } from '@angular/core';
import { UserPopupComponent } from '../user-popup/user-popup.component';
import { DOCUMENT } from '@angular/common';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiHttpService } from 'src/app/services/api-http.service';
import { CommonService } from 'src/app/services/common.service';
import { DataSharingService } from 'src/app/services/data-sharing-service';
import { PinterestService } from 'src/app/services/pinterest-service';
import { PopupThreeDComponent } from '../../popup-three-d/popup-three-d.component';
import { BrandPopupComponent } from '../brand-popup/brand-popup.component';
import { GarmentPopupComponent } from '../garment-popup/garment-popup.component';
import { Brand } from 'src/app/models/brand';
import { Observable } from 'rxjs';
import { PinPublishViewerDilog } from '../pin-publish-popup/pin-publish';
import { Snapshot } from 'src/app/models/snapshot';
import { ToastComponent } from '../toast/toast';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import html2canvas from 'html2canvas';
interface Window {
  showSaveFilePicker(options: {
    suggestedName: string;
    types: Array<{
      description: string;
      accept: Record<string, string[]>;
    }>;
  }): Promise<FileSystemFileHandle>;
}

@Component({
  selector: 'app-user-story',
  templateUrl: './user-story.component.html',
  styleUrls: ['./user-story.component.css']
})

export class UserStoryComponent {

  @ViewChild('swipeContent', { read: ElementRef }) public swipeContent: ElementRef<any>;
  @ViewChild('swipeMainContent', { read: ElementRef }) public swipeMainContent: ElementRef<any>;
  @ViewChild('swipeContentApp', { read: ElementRef }) public swipeContentApp: ElementRef<any>;
  // @ViewChild('swipeContentFilter', { read: ElementRef }) public swipeContentFilter: ElementRef<any>;
  @ViewChild('swipeContentFilter', { static: false }) swipeContentFilter: ElementRef;
  @ViewChild('screenshot', { static: false }) screenshot: ElementRef;






  @Input() userSnapShotsInput: any = [];
  @Input() teamplateFlag: any = [];
  @Input() garmentId: any;
  @Input() key: any;
  @Input() UserId: string | undefined;
  @Input() BrandId: string | undefined;
  @Input() EvenVironmentId: any | undefined;
  @Input() AvatrId: string | undefined;
  //@Input() thresholdHeightPagination :number = 3000;
  @Input() StoryIds: string | undefined;
  @Input() StartPageNumber: string | undefined;
  @Input() PageCount: number | undefined;
  @Input() pageNumber: number | undefined;
  @Input() onChangeFlag: boolean;
  @Input() changeTab: string | undefined;
  @Input() clickEventFlag: boolean;
  @Input() rootFlag: string | undefined;
  @Input() brandPageFlag: string | undefined;
  @Input() stylePage: string | undefined;
  @Input() rootFlagEven: any | undefined;
  @Input() routLogInFlag: boolean | undefined;
  @Output() outPageNumber = new EventEmitter<number>();
  @Output() outStoryIndeX = new EventEmitter<number>();

  thresholdHeightPagination: number = 3000;
  environmentCollection = this.firestore.collection('Environments').valueChanges({ idField: 'id' }) as Observable<Brand[]>;
  animationList = this.firestore.collection('Animations').valueChanges({ idField: 'id' }) as Observable<any[]>;
  garmentsCollection = this.firestore.collection('garments').valueChanges({ idField: 'id' }) as Observable<any[]>;
  brandboardCollection = this.firestore.collection('brand-board').valueChanges({ idField: 'id' }) as Observable<any[]>;
  brandsCollection = this.firestore.collection('brands').valueChanges({ idField: 'id' }) as Observable<Brand[]>;
  pinterestBoardPinsStatus = this.firestore.collection('pinterest-board-pins-status').valueChanges({ idField: 'id' }) as Observable<Snapshot[]>;
  public isLoading: boolean = false;
  // public loading: boolean = false;
  filterSnapShotFileDetails: any = []
  animationCollection: any = [];
  evnFileListFileName: any;
  evnFileList: any;
  SnapShotFileDetails: any;
  filterEnvironmentSnpShot: any;
  urlEvn: any;
  gltfImgSrc: string;
  twoDprivew: string;
  skyBoXall: any;
  evsname: any;
  evsdiplaynameALL: any;
  hideFlag: boolean = false;
  twoDprivewGltf: string;
  garmentsList: any = [];
  brandBoardList: any;
  pinterstJsonMap: any;
  storyPinterestPinsList: any;
  displayProgressSpinner: boolean;
  pinImg: any;
  brandsList: any;
  brandsnaploading: boolean;
  brandCollectionIdcollection: any;
  brandhShapShotsList: any;
  brandDetails: any;
  boards: any;
  scrollSnap: any = {};
  UpperwearID: string = "UpperwearID"
  BottomwearID: string = "BottomwearID"
  HeadwearID: string = "HeadwearID"
  FootwearID: string = "FootwearID"
  ScrollNumber: number
  loading: boolean = true;
  allUserDetails: any = [];
  // next:number = 1 
  height: number
  topScroll: number
  currentIndex: number = 0;
  //  thresholdHeightPagination :number = 3000 
  stroyLodaingFlag: boolean = false;
  storyLoadMessge: any = ''
  storyId: any
  iD: any
  previousPageNumber: number = 0;
  pageLoad: boolean = false;
  deleteFlag: any
  imageLoaded: boolean = false; // Add this line

  useFeedPagelink: any = '';
  brandPageLink: any = '';
  envirmentPageLink: any = '';
  garmentPageLink: any = '';
  environmentFileName: any;
  logInFlag: boolean;
  threeDopen: boolean = false;
  errorMessage: string;
  selectedAnimation: string;
  functionflagValue: string;
  isCheckedFocus: boolean;
  isChecked: any;
  envUrl: any;
  avatraUrls: any;
  garmentbottom: any;
  passId: any;
  dataBlobUrl: any;
  snapshotUrl: any;
  storyID: any;
  headgear: any;
  footwear: any;
  vrmAvtarUrl: any;
  garmenturl: any;
  genders: any;
  avtarUrl: any;
  evnLink: any;
  garmentLink: string;
  GarmentsGender: any;
  file: Promise<File>;
  imagBlobUrl: Promise<unknown>;

  constructor(
    @Inject(DOCUMENT) document: Document,
    private firestore: AngularFirestore,
    private storage: AngularFireStorage,
    private scrollService: CommonService,
    private dataSharingService: DataSharingService,
    public dialog: MatDialog,
    private elementRef: ElementRef,

    public router: Router,
    private route: ActivatedRoute,
    private commonService: CommonService,
    private modal: NgbModal,
    private apiHttpService: ApiHttpService, private pinterestAPI: PinterestService) {

    this.environmentCollection.subscribe(
      (evnFile) => {
        this.evnFileList = evnFile
      });
    this.animationList.subscribe(
      animation => {
        this.animationCollection = animation;
      });

    this.route.paramMap.subscribe(paramMap => {
      this.storyId = paramMap.get('storyId');
      this.deleteFlag = paramMap.get('delete');
    })
    if (this.routLogInFlag == undefined) {
      // this.useFeedPagelink = 'public/news-user'
      // this.brandPageLink = 'public/public-brand-stories'
      // this.envirmentPageLink = '/public/public-evn-profile'
      // this.garmentPageLink = 'public/public-garment-feed'
    }
    this.garmentsCollection.subscribe(
      garments => {
        this.garmentsList = garments;
      });

    this.brandboardCollection.subscribe(
      brandboard => {
        this.brandBoardList = brandboard;
      });

    this.brandsCollection.subscribe((brands) => {
      this.brandsList = brands;
    });

    this.commonService.getAllUser().subscribe(async (allUsers: any) => {
      this.allUserDetails = allUsers.users;
    })

    this.pinterestBoardPinsStatus.subscribe(
      response => {
        this.storyPinterestPinsList = response
        this.loading = false
      });

      setTimeout(() => {
        this.imagBlobUrl = this.captureScreenshot()
      }, 2000);

  }


  getDocumentDetails(snapShots) {
    snapShots.avatarName = " ";
    this.commonService.getDocumentsFromSubcollection(snapShots.UserID, snapShots.AvatarID)
      .then((documents) => {
        if (documents && documents.length > 0) {
          const firstDocument = documents[0]; // Assuming there is only one document
          snapShots.avatarName = firstDocument.AvatarName;
        } else {
          snapShots.avatarName = snapShots.AvatarID
        }

      })
      .catch((error) => {
        console.error('Error in component:', error);
        throw error; // You can choose to re-throw the error or handle it here
      });
  }

  ngOnChanges(changes: SimpleChanges) {



    if (changes['clickEventFlag'] != undefined && this.swipeContentApp != undefined) {
      this.swipeContentApp.nativeElement.scrollTop = 0
    }
    // if(changes['clickEventFlag'] && changes['StoryIds']['currentValue'] != ''){
    //   this.swipeContentApp.nativeElement.scrollTop = 0
    // }


    if (changes['StoryIds'] && changes['StoryIds']['currentValue'] != '' && changes['StoryIds']['currentValue'] != undefined) {
      this.storyId = changes['StoryIds']['currentValue'];
      this.scrollToId(this.storyId)
    }
    
    setTimeout(() => {
      this.imagBlobUrl = this.captureScreenshot()
    }, 2000);


  }

  ngAfterViewChecked(): void {
    let elementFlag = this.scrollService.elementExistById(this.storyId);
    if (elementFlag && this.storyId != undefined && this.userSnapShotsInput != undefined && !this.pageLoad) {
      this.scrollToId(this.storyId)
      this.pageLoad = true;
    }
  }

  ngOnInit() {
    this.logInFlag = this.commonService.isLoggedIn()
    if (this.routLogInFlag) {
      // this.useFeedPagelink = 'yologram-social/yologram-user-profile-feed'
      // this.brandPageLink = 'yologram-social/yologram-brand-stories'
      // this.envirmentPageLink = 'yologram-social/yologram-env-profile'
      // this.garmentPageLink = 'yologram-social/yologram-garment-feed'
    }
    this.route.paramMap.subscribe(paramMap => {
      this.deleteFlag = paramMap.get('flag');
    })
    // this.file =  this.commonService.urlToFile(this.userSnapShotsInput[0].SnapshotFileUrl, this.userSnapShotsInput[0].AvatarID);


        setTimeout(() => {
          this.imagBlobUrl = this.captureScreenshot()
        }, 2000);
  }


  findVisibleElementIndex() {
    if (this.swipeContentApp) {
      let scrollContainer = this.swipeContentApp.nativeElement;
      const scrollTop = scrollContainer.scrollTop;
      const containerHeight = scrollContainer.clientHeight;
      const elements = this.swipeContentApp.nativeElement.querySelectorAll('.slider-img');
      let mostVisibleIndex = -1;
      let highestVisibility = 0;
  
      elements.forEach((element, index) => {
        const elementTop = element.offsetTop;
        const elementHeight = element.offsetHeight;
        // Calculate the visible part of the element
        const visibleTop = Math.max(elementTop, scrollTop);
        const visibleBottom = Math.min(elementTop + elementHeight, scrollTop + containerHeight);
        const visibleHeight = Math.max(0, visibleBottom - visibleTop);
        // Calculate visibility percentage
        const visibility = visibleHeight / elementHeight;
        if (visibility > highestVisibility) {
          highestVisibility = visibility;
          mostVisibleIndex = index;
        }
      });
  
      if (mostVisibleIndex !== -1) {
        this.outStoryIndeX.emit(mostVisibleIndex);
      }
    }
  }
  
  convertFirestoreTimestampToDate(timestamp: any): Date {
    return new Date(timestamp._seconds * 1000 + timestamp._nanoseconds / 1000000);
  }

  deleteSnapshotFile(id: any) {
    var confirmation = confirm('Are you sure to delete this post ?');
    if (!confirmation) return;
    this.isLoading = true;
    this.firestore.collection("SnapshotDetails").doc(id).delete().then(() => {
      let filepath = "Snapshots/" + id
      this.storage.ref(filepath).delete();
      this.isLoading = false;
    }).catch((error) => {
    });;
  }

  scrollToId(id: string) {
    if (id != '') this.scrollService.scrollToElementById(id);
  }

  userNameSelection() {
    this.userSnapShotsInput.forEach(async (snap: any, index) => {
      let userObjet = this.allUserDetails.find(userObject => userObject.uid == snap.UserID)
      snap.profileName = userObjet.displayName != undefined ? userObjet.displayName : snap.UserID;
      userObjet.profileName = snap.profileName;
      snap.userData = userObjet
      snap.firstName = snap.profileName.split(" ", 1);
      snap.AvatarLastFive = snap.AvatarID.substr(snap.AvatarID.length - 5);
    })
  }

  openUserPopup(SnapShotObject: any) {
    const modalRef = this.modal.open(UserPopupComponent, {
      modalDialogClass: "model-garmentPopup"
    });
    modalRef.componentInstance.id = 10;
    modalRef.componentInstance.UserShapShotObject = SnapShotObject;
    modalRef.componentInstance.userData = SnapShotObject.userData;
    modalRef.componentInstance.UserID = SnapShotObject.UserID;
    modalRef.componentInstance.rootFlag = '/user';

    modalRef.result.then((result) => {
    }).catch((error) => {
      console.log("Console error", error);
    });
  }
  openAvatarPopUp(SnapShotObject: any, AvatarID: any) {
    const modalRef = this.modal.open(UserPopupComponent, {
      modalDialogClass: "model-garmentPopup"
    });
    modalRef.componentInstance.id = 10;
    modalRef.componentInstance.AvatarID = AvatarID;
    modalRef.componentInstance.UserShapShotObject = SnapShotObject;
    modalRef.componentInstance.userData = SnapShotObject.userData;
    modalRef.componentInstance.rootFlag = '/user';
    modalRef.componentInstance.UserID = SnapShotObject.UserID;
    modalRef.result.then((result) => {
    }).catch((error) => {
      console.log(error);
    });
  }
  environmentPopup(environment) {
    let reqInputObject = { id: environment, pageNumber: 1 }
    this.commonService.yologramSnapshotEnvironment(reqInputObject).subscribe(async (evn: any) => {
      let environmentSnapAllfileAll = evn.object;
      this.dataSharingService.sendUserEnvSnapShots(environmentSnapAllfileAll);
    })
    let evenListName = "Environments/"
    this.evnFileListFileName = this.evnFileList.find((snap) => snap.storageURL == evenListName + environment)
    let environmentSnapAllfile = this.userSnapShotsInput.filter((snap) => snap.Environment === environment).slice(0, 3)
    this.filterEnvironmentSnpShot = environmentSnapAllfile
    this.evnFileListFileName['envFileName'] = environment
    let evnUrl = evenListName + environment
    this.urlEvn = environment
    this.gltfImgSrc = 'https://firebasestorage.googleapis.com/v0/b/yolouploader.appspot.com/o/userData%2Ffiles%2Fcrystal_ball.glb?alt=media&token=963e0d80-5809-4e1b-9034-caf31fb33945'
    this.environmentFileName = environment.Environment
    this.openModelevn(evnUrl, this.environmentFileName)
  }

  openBrandPoup(garmentId: any) {
    const modalRef = this.modal.open(BrandPopupComponent, {
      modalDialogClass: "model-garmentPopup"
    });
    let grandmentObject = this.garmentsList.find((b: any) => b.id === garmentId);
    modalRef.componentInstance.BrandId = grandmentObject.BrandID;
    modalRef.componentInstance.garmentId = garmentId;
    modalRef.componentInstance.routeFlageBrandPage = this.brandPageLink;
    modalRef.result.then((result) => {
      console.log(result);
    }).catch((error) => {
      console.log(error);
    });
  }

  openGarmentPopup(fileType: any, key: any) {
    const modalRef = this.modal.open(GarmentPopupComponent, {
      modalDialogClass: "model-garmentPopupClass"
    });
    modalRef.componentInstance.id = 10;
    modalRef.componentInstance.key = fileType;
    modalRef.componentInstance.garmentId = key;
    modalRef.componentInstance.routeFlageEvenGarment = '/garment';
    modalRef.result.then((result) => {
      console.log(result);
    }).catch((error) => {
      console.log(error);
    });
  }


  openModelevn(skyBoXsrc: any, eVnName: any) {
    let evnEncodedurl = encodeURI(skyBoXsrc).replace('/', '%2F');
    let evNurl = "https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/" + evnEncodedurl + "?alt=media&token=db5ebcc3-36d0-4651-9250-471d6faf6baf"
    this.twoDprivew = evNurl
    const dialogRef = this.dialog.open(PopupThreeDComponent, {
      panelClass: 'dailog-padding-css'
    });
    this.gltfImgSrc = 'https://firebasestorage.googleapis.com/v0/b/yolouploader.appspot.com/o/userData%2Ffiles%2Fcrystal_ball.glb?alt=media&token=963e0d80-5809-4e1b-9034-caf31fb33945'
    this.skyBoXall = this.twoDprivew
    this.evsname = this.evnFileListFileName['envFileName']
    this.evsdiplaynameALL = this.evnFileListFileName['displayName']
    dialogRef.componentInstance.evsdiplaynameALL = this.evsdiplaynameALL
    dialogRef.componentInstance.evsname = this.evsname
    dialogRef.componentInstance.gltfImgSrc = this.gltfImgSrc;
    dialogRef.componentInstance.skyBoXall = this.skyBoXall;
    dialogRef.componentInstance.routeFlageEven = this.envirmentPageLink;
    dialogRef.componentInstance.hideFlag = this.hideFlag;
    dialogRef.componentInstance.environmentFileName = eVnName;


    dialogRef.afterClosed().subscribe(result => {
    });
  }

  getGarmentrealtype(garmentId: string) {
    let garmentTitle = "";
    let name = garmentId;
    let brandName = ""
    if (this.garmentsList.length > 0) {
      let grandmentObject = this.garmentsList.find((b: any) => b.id === garmentId);
      if (grandmentObject && grandmentObject['RealType'] != undefined) {
        garmentTitle = grandmentObject['RealType'];
      }
    }
    return garmentTitle;
  }


  shareOnPinterest(garmentId: any, type: any, snapshotFileUrl: any, storyID: any) {
    this.loading = true;
    let brandId: any;
    let boardId: any;
    if (this.garmentsList.length > 0) {
      let grandmentObject = this.garmentsList.find((b: any) => b.id === garmentId);
      if (grandmentObject && grandmentObject['Name'] != undefined) {
        brandId = grandmentObject["BrandID"];
        let brandBoardObject = this.brandBoardList.find((b: any) => b.brandId == brandId);
        boardId = brandBoardObject && brandBoardObject['boardId'] != undefined ? brandBoardObject['boardId'] : undefined;
      }
    }

    if (boardId == undefined || brandId == '') {
      this.isLoading = false;
      this.loading = false;
      alert("This brand is not linked with Pinterest board.")
      return true;
    }

    let jsonObject = {
      'avatarURL': snapshotFileUrl,
      'boardId': boardId
    }
    let jsonInput = this.pinterstJsonMap
    let storyObject = this.storyPinterestPinsList.find((b: any) => b.StoryID == storyID);
    if (storyObject && storyObject[type] != undefined) {
      let jsonInpt = { 'pinId': storyObject[type], };
      this.pinterestAPI.getPinterestPin(jsonInpt).subscribe((data) => {
        this.displayProgressSpinner = false;
        this.pinImg = data.media.images['400x300'].url;
        this.openModleForPublishedPin();
        this.loading = false;
      });
      return true
    }
    this.pinterestAPI.createPinterestPin(jsonObject).subscribe((result) => {
      if (storyObject == undefined) {
        let pinsMappingObject: any = {
          'StoryID': storyID,
        }
        if (type == 'headwear') pinsMappingObject['headwear'] = result.id
        if (type == 'footwear') pinsMappingObject['footwear'] = result.id
        if (type == 'upperwear') pinsMappingObject['upperwear'] = result.id
        if (type == 'headwear') pinsMappingObject['headwear'] = result.id
        this.firestore.collection('pinterest-board-pins-status').add(pinsMappingObject)
      } else if (storyObject && storyObject[type] == undefined) {


        if (type == 'headwear') storyObject['headwear'] = result.id
        if (type == 'footwear') storyObject['footwear'] = result.id
        if (type == 'upperwear') storyObject['upperwear'] = result.id
        if (type == 'headwear') storyObject['headwear'] = result.id
        this.firestore.collection('pinterest-board-pins-status').doc(storyObject['id']).update(storyObject);
      }

      this.isLoading = false;
      this.loading = false;
      alert("Pin published successfully.");
      return true;
    });

    return false;
  }



  openModleForPublishedPin() {
    const dialogRef = this.dialog.open(PinPublishViewerDilog);
    dialogRef.componentInstance.pinImgSrc = this.pinImg;
    dialogRef.afterClosed().subscribe(result => {
      this.isLoading = false;
      this.loading = false;
    });
  }

  getBrandImageByGarmetnID(garmentId: string) {
    let imgURL = this.getBrandLogoURL(garmentId);
    let brandEncodedurl = encodeURI(imgURL).replace('/', '%2F');
    let url = "https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/" + brandEncodedurl + "?alt=media&token=550e7e88-bed3-4668-84ff-ad491c19e06c"
    return url;
  }

  // getBrandLogoURL(garmentId: string) {
  //   let garmentTitle = "";
  //   let name = garmentId;
  //   let brandlogoUrl = ""
  //   if (this.garmentsList.length > 0) {
  //     let grandmentObject = this.garmentsList.find((b: any) => b.id === garmentId);
  //     if (grandmentObject && grandmentObject['BrandID'] != undefined) {
  //       let brandId = grandmentObject["BrandID"];
  //       let brandObject = this.brandsList.find((b: any) => b.id === brandId);
  //       brandlogoUrl = brandObject != undefined && brandObject.LogoURL != undefined ? brandObject.LogoURL : '';
  //     }
  //   }
  //   return brandlogoUrl;
  // }

  // showPintrestIcon(clothId: any) {
  //   let flag = false;
  //   let grandmentObject = this.garmentsList.find((b: any) => b.id === clothId);
  //   let brandBoardObject = this.brandBoardList.find((b: any) => b.brandId == grandmentObject.BrandID);
  //   if (brandBoardObject != undefined) {
  //     flag = true;
  //   }
  //   return flag;
  // }

  getEnvironmentName(environment) {
    if (this.evnFileList == undefined) return
    let evenListName = "Environments/"
    let env = this.evnFileList.find((snap) => snap.storageURL == evenListName + environment)
    return env != undefined ? env['displayName'] : 'default';
  }




  // showPintrestIcon(clothId: any) {
  //   let flag = false;
  //   let grandmentObject = this.garmentsList.find((b: any) => b.id === clothId);
  //   let brandBoardObject;
  //   if (grandmentObject != undefined && grandmentObject.BrandID != undefined) {
  //     brandBoardObject = this.brandBoardList.find((b: any) => b.brandId == grandmentObject.BrandID);
  //     //let show = './assets/Pinterest-logo.png'
  //   }
  //   if (brandBoardObject != undefined) {
  //     flag = true;
  //   }
  //   return flag;
  // }

  showPintrestIcon(clothId: any) {
    let flag = false;
    if (!this.garmentsList) {
      return flag;
    }
    let garmentObject = this.garmentsList.find((b: any) => b.id === clothId);
    if (!this.brandBoardList) {
      return flag;
    }
    if (garmentObject?.BrandID) {
      let brandBoardObject = this.brandBoardList.find((b: any) => b.brandId === garmentObject.BrandID);
      if (brandBoardObject) {
        flag = true;
      }
    }
    return flag;
  }

  getBrandLogoURL(garmentId: string) {
    let brandlogoUrl = "";
    // Check if garmentsList is defined and has items
    if (this.garmentsList && this.garmentsList.length > 0) {
      let garmentObject = this.garmentsList.find((b: any) => b.id === garmentId);
      if (garmentObject?.BrandID) {
        // Check if brandsList is defined before using 'find'
        if (this.brandsList) {
          let brandObject = this.brandsList.find((b: any) => b.id === garmentObject.BrandID);
          brandlogoUrl = brandObject?.LogoURL ?? '';
        }
      }
    }
    return brandlogoUrl;
  }


  getGarmentName(garmentId: string) {
    let garmentTitle = "";
    let name = garmentId;
    let brandName = ""
    if (this.garmentsList.length > 0) {
      let grandmentObject = this.garmentsList.find((b: any) => b.id === garmentId);
      if (grandmentObject && grandmentObject['Name'] != undefined) {
        garmentTitle = grandmentObject['Name'];
      }
    }
    return garmentTitle;
  }
  onScroll(onScroll) {
    this.scrollSnap = onScroll
  }

  garmentScroll(snapshot) {
    this.ScrollNumber = +1
    if (this.ScrollNumber == 10) {
      this.swipeMainContent.nativeElement.scrollTo({ left: -343, behavior: 'smooth' });
    }
  }
  moerButtonForNext() {
    if (this.AvatrId != undefined) {
      this.pageNumber++;
      this.outPageNumber.emit(this.pageNumber);
      let reqInputObject = { id: this.AvatrId, pageNumber: this.pageNumber }
      this.commonService.yologramSnapshotDetailsByAvatar(reqInputObject).subscribe(async (response: any) => {
        this.userSnapShotsInput.push(...response.object);
        this.userSnapShotsInput.forEach(async (snap: any, index) => {
          let userObjet = this.allUserDetails.find(userObject => userObject.uid == snap.UserID)
          snap.profileName = userObjet.displayName != undefined ? userObjet.displayName : snap.UserID;
          userObjet.profileName = snap.profileName;
          snap.userData = userObjet
          snap.firstName = snap.profileName.split(" ", 1);
          snap.AvatarLastFive = snap.AvatarID.substr(snap.AvatarID.length - 5);
          //  if (response.object.length == 0) {
          //   setTimeout(() => {
          //     this.storyLoadMessge = 'No story';
          //   }, 1000);
          // }
        });
      })
    }
    else if (this.UserId != undefined) {


      this.pageNumber++;
      // =  this.next + 1 ;
      this.outPageNumber.emit(this.pageNumber);
      let reqInputObjectForId = { id: this.UserId, pageNumber: this.pageNumber }
      this.commonService.yologramSnapshotDetailsByUserId(reqInputObjectForId).subscribe(async (response: any) => {
        this.userSnapShotsInput.push(...response.object)
        this.userSnapShotsInput.forEach(async (snap: any, index) => {
          let userObjet = this.allUserDetails.find(userObject => userObject.uid == snap.UserID)
          snap.profileName = userObjet.displayName != undefined ? userObjet.displayName : snap.UserID;
          userObjet.profileName = snap.profileName;
          snap.userData = userObjet
          snap.firstName = snap.profileName.split(" ", 1);
          snap.AvatarLastFive = snap.AvatarID.substr(snap.AvatarID.length - 5);
          //  if (response.object.length == 0) {
          //   setTimeout(() => {
          //     this.storyLoadMessge = 'No story';
          //   }, 1000);
          // }
        });
      })
    } else if (this.garmentId != undefined) {


      let key = this.key
      this.pageNumber++;
      this.outPageNumber.emit(this.pageNumber);
      let reqInputObject = { fileType: key, id: this.garmentId, pageNumber: this.pageNumber }
      this.commonService.yologramSnapshotDetailsByType(reqInputObject).subscribe(async (response: any) => {
        this.userSnapShotsInput.push(...response.object);
        this.userSnapShotsInput.forEach(async (snap: any, index) => {
          let userObjet = this.allUserDetails.find(userObject => userObject.uid == snap.UserID)
          snap.profileName = userObjet.displayName != undefined ? userObjet.displayName : snap.UserID;
          userObjet.profileName = snap.profileName;
          snap.userData = userObjet
          snap.firstName = snap.profileName.split(" ", 1);
          snap.AvatarLastFive = snap.AvatarID.substr(snap.AvatarID.length - 5);
          //  if (response.object.length == 0) {
          //   setTimeout(() => {
          //     this.storyLoadMessge = 'No story';
          //   }, 1000);
          // }
        });

      })
    } else if (this.BrandId != undefined) {


      this.pageNumber++
      // =  this.next + 1;
      this.outPageNumber.emit(this.pageNumber);
      let reqInputObject = { id: this.BrandId, pageNumber: this.pageNumber }
      this.commonService.yologramSnapshotByBrands(reqInputObject).subscribe(async (response: any) => {
        this.userSnapShotsInput.push(...response.object);
        this.userSnapShotsInput.forEach(async (snap: any, index) => {
          let userObjet = this.allUserDetails.find(userObject => userObject.uid == snap.UserID)
          snap.profileName = userObjet.displayName != undefined ? userObjet.displayName : snap.UserID;
          userObjet.profileName = snap.profileName;
          snap.userData = userObjet
          snap.firstName = snap.profileName.split(" ", 1);
          snap.AvatarLastFive = snap.AvatarID.substr(snap.AvatarID.length - 5);
          snap.PageNumber = reqInputObject.pageNumber
          //  if (response.object.length == 0) {
          //   setTimeout(() => {
          //     this.storyLoadMessge = 'No story';
          //   }, 1000);
          // }
        });
      })
    } else if (this.EvenVironmentId != undefined) {
      this.pageNumber++;
      this.outPageNumber.emit(this.pageNumber);
      let reqInputObject = { id: this.EvenVironmentId, pageNumber: this.pageNumber }
      this.commonService.yologramSnapshotEnvironment(reqInputObject).subscribe(async (response: any) => {
        this.userSnapShotsInput.push(...response.object);
        this.userSnapShotsInput.forEach(async (snap: any, index) => {
          let userObjet = this.allUserDetails.find(userObject => userObject.uid == snap.UserID)
          snap.profileName = userObjet.displayName != undefined ? userObjet.displayName : snap.UserID;
          userObjet.profileName = snap.profileName;
          snap.userData = userObjet
          snap.firstName = snap.profileName.split(" ", 1);
          snap.AvatarLastFive = snap.AvatarID.substr(snap.AvatarID.length - 5);
          //  if (response.object.length == 0) {
          //   setTimeout(() => {
          //      this.storyLoadMessge = 'No story';
          //    }, 1000);
          //  }
        });
      })
    }
  }

  findindex(findindex) {
  }

  scrollCard() {
    this.findVisibleElementIndex()
    if (this.userSnapShotsInput.length >= this.PageCount || this.pageNumber == this.previousPageNumber) { return }
    let height = this.swipeContentApp.nativeElement.scrollTop
    this.thresholdHeightPagination = this.pageNumber * 3000;
    this.height = height
    this.height = height
    if (this.height > this.thresholdHeightPagination) {
      this.previousPageNumber = this.pageNumber;
      this.thresholdHeightPagination += 3000;
      setTimeout(() => {
        this.moerButtonForNext()
      }, 10);
    }
  }



  openModleForToast(flag) {
    const dialogRef = this.dialog.open(ToastComponent);
    dialogRef.componentInstance.pinImgSrc = flag;
    dialogRef.afterClosed().subscribe(result => {
      this.isLoading = false;
    });
  }


  showThreeD() {
    alert('works')
    this.threeDopen = true
  }
  closeThreeDBUTTON() {
    this.errorMessage = '';
    this.threeDopen = !this.threeDopen
  }

  onSelectAnimation(event) {
    let url = event.target.value;
    let animationEncodedUrl = encodeURI(url).replace(/\//g, '%2F');
    let storageUrl = 'https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/' + animationEncodedUrl + '?alt=media&token=c6ec5912-f818-429b-81a3-d44b8f92657c'
    this.selectedAnimation = storageUrl
    this.functionflagValue = 'bvhAnimationUrl' + '_' + this.selectedAnimation;
  }

  switchFlagforFocus() {
    this.isCheckedFocus = !this.isCheckedFocus;
    if (this.isCheckedFocus) {
      this.functionflagValue = 'FocusOnStory';
    } else {
      this.functionflagValue = 'FocusOnAvatar';
    }
  }

  toggleSwitch() {
    this.isChecked = !this.isChecked;
    this.switchFlag();
  }

  switchFlag() {
    if (this.isChecked) {
      this.functionflagValue = 'TogglePostCardOn';
    } else {
      this.functionflagValue = 'TogglePostCardOff';
    }
  }


  openNews3DPassValue(envUrl, AvatarUrl, snap, upperwear, bottomWear, footWear, headwear) {
    this.errorMessage = '';
    this.threeDopen = true
    let Garments = this.garmentsList.find((gaementList) => gaementList.id === (snap.HeadwearID || snap.UpperwearID || snap.BottomwearID || snap.FootwearID));
    this.envUrl = envUrl
    this.avatraUrls = AvatarUrl
    this.garmenturl = upperwear
    this.garmentbottom = bottomWear
    this.vrmAvtarUrl = snap.vrmURL;
    this.genders = Garments.Gender;
    this.headgear = headwear;
    this.footwear = footWear;
    this.storyID = snap.StoryID;
    this.dataBlobUrl = snap.imageBase64.__zone_symbol__value
    this.snapshotUrl = snap
    this.passId = snap.id
    // this.threeDopen = !this.threeDopen
    document.getElementById('loadingScreen')
    this.functionflagValue = 'LoadAvatar' + '_' + AvatarUrl + '_StoryID' + this.storyID;
  }

  openNews3DPassVRMValue(envUrl, AvatarUrl, snap, upperwear, bottomWear, footWear, headwear) {
    this.threeDopen = true
    this.errorMessage = '';
    let Garments = this.garmentsList.find((gaementList) => gaementList.id === (snap.HeadwearID || snap.UpperwearID || snap.BottomwearID || snap.FootwearID));
    this.envUrl = envUrl
    this.avatraUrls = AvatarUrl
    this.garmenturl = upperwear
    this.garmentbottom = bottomWear
    this.vrmAvtarUrl = snap.vrmURL;
    this.genders = Garments.Gender;
    this.headgear = headwear;
    this.footwear = footWear;
    this.storyID = snap.StoryID;
    this.dataBlobUrl = snap.imageBase64.__zone_symbol__value
    this.snapshotUrl = snap
    this.passId = snap.id
    // this.threeDopen = !this.threeDopen
    document.getElementById('loadingScreen')
    this.functionflagValue = 'LoadVRMAvatar' + '_' + this.vrmAvtarUrl + '_StoryID' + this.storyID;
  }

  setEroorMessage(message) {
    this.errorMessage = message
  }

  copyTextToClipGarment(text: string) {
    let Garments = this.garmentsList.find((garmentList) => garmentList.id === text);
    if (Garments) {
      let brandId = Garments.BrandID;
      let gender = Garments.Gender;
      this.GarmentsGender = Garments.Gender;
      let garmentType = Garments.GarmentType;
      let zipfilename = Garments.id;
      // alert(zipfilename)
      let packlocation = "AvatarBlenderClothes%2F" + brandId + "_" + gender + "%2F" + garmentType + "%2F" + zipfilename;
      this.garmentLink = 'https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/' + packlocation + '?alt=media&token=2c1dc261-ac91-4c32-a5ec-ac5e06f0a9bc&_gl=1*goch74*_ga*MjExMjI9MTIyMi4xNjg3NTA3MDk1*_ga_CW55HF8NVT*MTY5NjkzMDY1Ny4xNTcuMS4xNjk2OTM2NzA4LjU5LjAuMA..';
      return this.garmentLink;
    } else {
      // Handle the case when Garments is not found
      // console.error('Garments not found for id: ' + text);
      return null; // or return an appropriate value or throw an error
    }
  }
  copyTextToClipEven(snap) {
    let evnFileName = snap
    if (evnFileName != 'default') {
      this.evnLink = 'https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/Environments%2F' + evnFileName + '?alt=media&token=db5ebcc3-36d0-4651-9250-471d6faf6baf'
    } else if (evnFileName == 'default') {
      this.evnLink = evnFileName;
    }
    return this.evnLink
  }

  copyTextToClipAvtar(user, avatar) {
    this.avtarUrl = 'https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/AvatarBlenderSaves%2F' + user + '%2F' + avatar + '?alt=media&token=20b1dc1e-681b-4d96-a342-7a4407fab4e0&_gl=1*10jumsf*_ga*MjExMjI5MTIyMi4xNjg3NTA3MDk1*_ga_CW55HF8NVT*MTY5NzUyMjU5NC4xNzAuMS4xNjk3NTIyNjE1LjM5LjAuMA..'
    return this.avtarUrl

  }

  // async shareStory(snap: { SnapshotFileUrl: string; StoryID: string; }) {
  //     // this. shareImageFromUrl(this.storyId)
  //     let link = "https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/AvatarBlenderSaves%2F"
  //     // let vrm = this.storyId.vrmURL
  //     let text ='text'
  //     if (navigator.share) {
  //       console.log( 'storyObject',snap)
  //       const file = await this.commonService.urlToFile(snap.SnapshotFileUrl, snap.StoryID);
  //       console.log( 'file',file)
  //       navigator.share({
  //         files: [file],
  //         title :"Share Snap",
  //         text :text,
  //         url: link,
  //       })
  //         .then(() => console.log('Successful share'))
  //         .catch((error) => console.log('Error sharing', error));
  //     }
  //   }


  async shareStory() {
    // this. shareImageFromUrl(this.storyId)
    // let link = "  https://yolomove-social-live.web.app/story?storyId=" + this.storyId.StoryID + "&teamplate=" + this.templateFlag
    // let vrm = this.storyId.vrmURL
    let baseUrl = "https://yolomove-social-live.web.app/story?storyId=";
    let link = baseUrl;
    let text = 'Check out ' + this.userSnapShotsInput[0].id + ' wearing ' + this.userSnapShotsInput[0].id
    let data = this.userSnapShotsInput

    // const file = await this.file
    if (navigator.share) {
      navigator.share({
        // files: [file],
        text: text,
        url: link,
        // title: 'Share Snap',
        // text: text,
        // url: link,
      })
        .then(() => console.log('Successful share'))
        .catch((error) => console.log('Error sharing', error));
    }

  }

  async captureAndShareScreenshot() {
    try {
      const nativeComponentElement = this.screenshot.nativeElement;

      // Capture the screenshot using html2canvas
      const canvas = await html2canvas(nativeComponentElement);

      // Convert canvas to Blob
      const blob = await new Promise<Blob>(resolve => {
        canvas.toBlob(resolve, 'image/png');
      });

      // Create a temporary URL for the blob
      const blobUrl = URL.createObjectURL(blob);

      // Share the blob URL using the Web Share API
      if (navigator.share) {
        await navigator.share({
          title: 'Shared Screenshot',
          files: [new File([blob], 'screenshot.png', { type: 'image/png' })]
        });
      } else {
        console.error('Web Share API is not supported.');
        // Fallback for browsers that don't support Web Share API
        // You can implement your own sharing mechanism here
      }

      // Revoke the blob URL
      URL.revokeObjectURL(blobUrl);
    } catch (error) {
      console.error('Error capturing or sharing screenshot:', error);
    }
  }


  capture() {
    html2canvas(document.getElementById('iconImage')).then(canvas => {
      // You can display the canvas as an image or convert it to a data URL
      // For example, to display it:
      const imgData = canvas.toDataURL('image/png');
      const imgElement = document.createElement('img');
      imgElement.src = imgData;
      document.body.appendChild(imgElement);

      // Or to download it:
      const a = document.createElement('a');
      a.href = imgData;
      a.download = 'screenshot.png';
      a.click();
    });
  }


  captureWhenReady(selector: string, checkCondition: () => boolean, maxAttempts = 10, attemptInterval = 500) {
    let attempts = 0;

    const attemptCapture = () => {
      if (checkCondition() || attempts >= maxAttempts) {
        html2canvas(document.getElementById(selector)).then(canvas => {
          // Your existing code to handle the canvas
          const imgData = canvas.toDataURL('image/png');
          const a = document.createElement('a');
          a.href = imgData;
          a.download = 'screenshot.png';
          a.click();
        });
      } else {
        attempts++;
        setTimeout(attemptCapture, attemptInterval);
      }
    };

    attemptCapture();
  }

  onCaptureClick() {
    this.captureWhenReady('iconImage', () => {
      // Implement your condition to check if dynamic content is ready
      // For example, checking if a specific element exists
      return document.querySelectorAll('.dynamic-content').length > 0;
    });
  }



  //   async captureAndShare() {
  //     // this.loading = true
  //     try {
  //       // Capture the specified element as a canvas
  //       const canvas = await html2canvas(document.getElementById('contentToCapture'),{ useCORS : true});
  //       canvas.toBlob(async (blob) => {
  //         // Check if the Web Share API is available and supports sharing files
  //         if (navigator.canShare && navigator.canShare({ files: [new File([blob], 'screenshot.png', { type: 'image/png' })] })) {
  //            await navigator.share({
  //             files: [new File([blob], 'screenshot.png', { type: 'image/png' })],
  //             title: 'Screenshot',
  //             text: 'Check out this Story!',
  //           });
  //           console.log('Screenshot shared successfully');
  //           alert('Screenshot shared successfully')

  //         } else {
  //           alert('File sharing is not supported or file type is not supported.')
  //           // Fallback for browsers that don't support sharing files via the Web Share API
  //           console.log('File sharing is not supported or file type is not supported.');
  //           // Implement any fallback here, e.g., showing the image in a new window or copying a link to the clipboard
  //           if ('showSaveFilePicker' in window) {
  //             const handle = await (window as any).showSaveFilePicker({
  //               suggestedName: 'screenshot.png',
  //               types: [{
  //                 description: 'PNG image',
  //                 accept: { 'image/png': ['.png'] },
  //               }],
  //             });
  //             const writableStream = await handle.createWritable();
  //             await writableStream.write(blob);
  //             await writableStream.close();
  //           }
  //           const url = URL.createObjectURL(blob);
  //           const link = document.createElement('a');
  //           link.href = url;
  //           link.download = 'screenshot.png'; // Suggests a filename for the download
  //           document.body.appendChild(link); // Required for Firefox
  //           link.click();
  //           document.body.removeChild(link);
  //           URL.revokeObjectURL(url); // Clean up to release object URL
  //           console.log('Screenshot saved successfully');
  //         }
  //       }, 'image/png');
  //     } catch (error) {
  //       console.error('Error capturing or sharing the screenshot:', error);
  //     }
  //     finally {
  //       this.loading = false; // Hide loader regardless of success or failure
  //     }
  //   }

  // }


  async captureScreenshot() {
    try {
      const canvas = await html2canvas(document.getElementById('contentToCapture'), { useCORS: true });
      return new Promise((resolve, reject) => {
        canvas.toBlob(blob => {
          if (blob) {
            resolve(blob);
          } else {
            reject(new Error('Could not generate blob from canvas'));
          }
        }, 'image/png');
      });
    } catch (error) {
      console.error('Error capturing the screenshot:', error);
      throw error; // Re-throw the error to be handled by the caller
    }
  }
  async shareScreenshot(blob) {
    try {
      const file = new File([blob], 'screenshot.png', { type: 'image/png' });
      // Check if the Web Share API is available and supports sharing files
      if (navigator.canShare && navigator.canShare({ files: [file] })) {
        await navigator.share({
          files: [file],
        });
        console.log('Screenshot shared successfully');
        alert('Screenshot shared successfully!');
      } else {
        alert('File sharing is not supported or file type is not supported.');
        console.log('File sharing is not supported or file type is not supported.');
      }
    } catch (error) {
      console.error('Error sharing the screenshot:', error);
      throw error; // Re-throw the error for further handling
    }
  }
  
  async captureAndShare() {
    this.loading = true; 
    // Uncomment if you're managing loading state
    try {
      const blob = await this.imagBlobUrl;
      await this.shareScreenshot(blob);
    } catch (error) {
      console.error('Error in capture and share:', error);
    } finally {
      this.loading = false; 
      // Uncomment if you're managing loading state
    }
  }


}







