<div class="p-2" *ngIf="percentage | async as pct">
  <progress [value]="pct" max="100"></progress>
  {{ pct | number }}%
</div>
  <div class="p-4" *ngIf="snapshot | async as snap">
    {{ snap.bytesTransferred }} of {{ snap.totalBytes }}
    <div *ngIf="fileUrl as url">
      <h3>Results!</h3>
      <video  *ngIf="videoUrl != undefined" width="300" height="240" controls>
        <source [src]="videoUrl" type="video/mp4">
      </video>
      <img  *ngIf="videoUrl === undefined" [src]="url"><br>
      <a [href]="url" target="_blank" rel="noopener">Download Me!</a>
    </div>
  </div>


  
  <model-viewer *ngIf="this.fileUrl "
  id="viewer"
  camera-controls
  camera-orbit="45deg 55deg 2.5m"
  ar
  auto-rotate
  [src]=" this.fileUrl "
  alt="A 3D avatar-model-view"
  data-js-focus-visible=""
  ar-status="not-presenting"
  style="height: 250px; width: 200px; float: none;"
></model-viewer>

<div>
  <div *ngIf="minImage3DUploadFlage" class="text-center">
    <div class="spinner-border m-5" role="status">
        <span class="sr-only"></span>
    </div>
</div>
  <img style="width: 200px;height: 250px;"  *ngIf="this.fileurlMini" [src]=" this.fileurlMini">
</div>
