<!-- 
<div class="slider-width">
  <div class="carousel" #garmentDummySCroll>
    <div class="item" *ngFor="let data of scrollingImages">
      <img *ngIf="!loading  && data.fileurl != undefined" class="img-fluid img-thumbnail"
      style="height: 145px;
      width: 130px !important; object-fit: contain;                                                                                 "
      [src]="data.fileurl">
      <img *ngIf="!loading  && data.fileurl == undefined" class="img-fluid img-thumbnail"
      style="height: 145px;
      width: 130px !important; object-fit: contain;                                                                                 "
      [src]="data.image">
    </div>
  </div>
</div>  -->


  <div class="d-flex upload-image-slider-css" #garmentDummySCroll>
    <div class="text-center col-6 item" *ngFor="let data of scrollingImages" >
        <img *ngIf="!loading  && data.fileurl == undefined" class="imageBorder-class"
            style="height: 150px; width: 145px !important; object-fit: cover;                                                                                 "
             [scrollTarget]="garmentDummySCroll"
            [lazyLoad]="data.image">
            <img *ngIf="data.fileurl != undefined " class="imageBorder-class"
            style="height: 150px; width: 145px !important; object-fit: cover;                                                                                 "
               [scrollTarget]="garmentDummySCroll"
            [lazyLoad]="data.fileurlMini" (click)="open2dPoup3(data) ">
        <span *ngIf="loading"></span>
    </div>
</div>

