<div class="card m-3 container slider-m-div">
    <div #verticalSCrolls [ngClass]="threeDopen == false   ? '' : 'd-none'"
        *ngIf="SnapshotDetails && SnapshotDetails.length > 0" class="allImage vertical-scroll-container"
        (scroll)="onVerticalScroll()">
        <div class="scroll-item d-flex" *ngFor="let snaphot of SnapshotDetails  let i = index;">
            <div #horizontalScroll id="imgrt" class="itemType horizontal-scroll-container"
                id="horizontal-scroll-container">
                <div class="scroll-item">
                    <img class="img-size" [src]="snaphot.SnapshotFileUrl" alt="First slide">
                </div>
                <div class="scroll-item">
                    <img class="img-size" [src]="snaphot.SnapshotFileUrl" >
                </div>
                <div class="scroll-item">
                    <img class="img-size" [src]="snaphot.SnapshotFileUrl" >
                </div>
                <div class="scroll-item">
                    <img class="img-size" [src]="snaphot.SnapshotFileUrl" >
                </div>
            </div>
        </div>
    </div>
</div>