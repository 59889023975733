import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-security-group-change',
  templateUrl: './security-group-change.component.html',
  styleUrls: ['./security-group-change.component.css']
})
export class SecurityGroupChangeComponent implements OnInit {

  private id: string;
  preferenceDocId: any;
  selectedSecGrpId: any;
  userSecurityGrp: any = [];
  secGrpList: any = {};

  groupUser = this.firestore.collection('GroupUser').valueChanges({ idField: 'id' }) as Observable<[]>;
  groupRoleCollection = this.firestore.collection('GroupRole').valueChanges({ idField: 'id' }) as Observable<[]>;
  pageCo0llection = this.firestore.collection('Pages').valueChanges({ idField: 'id' }) as Observable<[]>;
  securityGrpCollection = this.firestore.collection('SecurityGroup').valueChanges({ idField: 'id' }) as Observable<[]>;
  SecGpId: string;
  logInFlag: boolean;


  

  constructor(
    private route: ActivatedRoute,
    private firestore: AngularFirestore,
    public commonService: CommonService
  ) {

    this.groupUser.subscribe((grpList) => {
      //this.userGrpList = grpList;
      this.userSecurityGrp = grpList.filter((grp: any) => grp.UserId === this.id);
    });

    this.securityGrpCollection.subscribe((grpList) => {
      grpList.forEach((group: any) => {
          this.secGrpList[group.id] = group.name;
      });
    });
    

  }

  ngOnInit(): void {
    this.logInFlag = this.commonService.isLoggedIn()
    this.id = this.route.snapshot.paramMap.get('id');
    this.getUserPreference();
  }

  setSecurityGroup($event: any) {
    this.SecGpId = $event.target.value;
  }

  async updateUserPrefernce() {    
    let userPreference = {
      "Page": "/menu-page",
      "Key": "LastUserGroupSelected",
      "KeyValue": this.SecGpId,
      "UserId": this.id,
      "Description": ""
    }
    if(this.SecGpId == '' ) {
      alert("Please select the valid security group");
      return;
    }
    if (this.preferenceDocId != undefined) {
      await this.firestore.collection('UserPreference').doc(this.preferenceDocId).update(userPreference)
    } else {
      await this.firestore.collection('UserPreference').add(userPreference)
    }
    alert("User group change successfully !!");
    window.location.reload();
  }

  getUserPreference() {
    this.firestore.collection("UserPreference").ref.where("Key", "==", "LastUserGroupSelected").get().
      then((objectList: any) => {
        objectList.forEach((doc) => {
          if (doc.data().UserId == this.id) {
            this.selectedSecGrpId = doc.data().KeyValue;
            this.preferenceDocId = doc.id;
            
          }
        });
      }).catch((error) => {
        console.log("Error getting documents: ", error);
      });
  }

}
