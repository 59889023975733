import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { finalize, map, Observable } from 'rxjs';
import { PopupTwoDComponent } from 'src/app/common/popup-two-d/popup-two-d.component';
import { CaraosalPopupComponent } from 'src/app/common/templets/caraosal-popup/caraosal-popup.component';
import { Brand } from 'src/app/models/brand';
import { CommonService } from 'src/app/services/common.service';
import { Modal } from 'bootstrap';

@Component({
  selector: 'app-texture-page',
  templateUrl: './texture-page.component.html',
  styleUrls: ['./texture-page.component.css']
})
export class TexturePageComponent {

  textureLibrary = this.firestore.collection('TextureLibrary').valueChanges({ idField: 'id' }) as Observable<any[]>;
  private editModalInstance: Modal | null = null; // Store the modal instance
  private imagenstance: Modal | null = null; // Store the modal instance

  logInFlag: boolean
  inputValue: string = '';
  baseMapValue: string = 'BaseMap';
  normalMapValue: string = 'NormalMap';
  metallicMapValue: string = 'MetallicMap';
  uploadProgress: number = 0; // To store the upload progress


  imagePath: string | null = null;


  selectedBaseMapFile: File | null = null;
  selectedNormalMapFile: File | null = null;
  selectedMetallicMapFile: File | null = null;

  editBaseMapFile: File | null = null;
  editNormalMapFile: File | null = null;
  editMetallicMapFile: File | null = null;

  editInputValue: string = '';
  editBaseMapValue: string = 'BaseMap';
  editNormalMapValue: string = 'NormalMap';
  editMetallicMapValue: string = 'MetallicMap';
  selectedTextureId: string | null = null;
  seletedPath: string;
  selectedtexture: any;
  editableTexture: any;


  baseMapUrl: string;
  normalMapUrl: string;
  metallicMapUrl: string;
  loading: boolean;

  constructor(public commonService: CommonService, public sanitizer: DomSanitizer, private storage: AngularFireStorage,
    private _httpClient: HttpClient, private firestore: AngularFirestore, public router: Router, public dialog: MatDialog, private modal: NgbModal,) {
    this.logInFlag = this.commonService.isLoggedIn()
    this.checkPageAuthorization() 
  }


  triggerFileInput(fileInputId: string) {
    const fileInput = document.getElementById(fileInputId) as HTMLInputElement;
    fileInput.click();
  }

  // Function to handle file uploads
  handleFileUpload(event: any, type: string) {
    const file = event.target.files[0];
    if (file) {
      switch (type) {
        case 'BaseMap':
          this.selectedBaseMapFile = file;
          console.log('Selected BaseMap File:', file.name);
          break;
        case 'NormalMap':
          this.selectedNormalMapFile = file;
          console.log('Selected NormalMap File:', file.name);
          break;
        case 'MetallicMap':
          this.selectedMetallicMapFile = file;
          console.log('Selected MetallicMap File:', file.name);
          break;
      }
    }
  }

  // saveValues() {
  //   // Prepare data to save
  //   const data = {
  //     TextureName: this.inputValue,
  //     BaseMapPath: '',
  //     NormalMapPath: '',
  //     MetallicMapPath: ''
  //   };

  //   // Save to Firestore first

  //   if (this.inputValue == '') {
  //     alert('Enter the Texture Name.')
  //     return
  //   };

  //   if (this.selectedBaseMapFile == undefined) {
  //     alert('Select Base Map file first.')
  //     return
  //   };
  //   this.firestore.collection('TextureLibrary').add(data)
  //     .then(async (docRef) => {
  //       console.log('Texture data saved successfully with ID:', docRef.id);

  //       const uploadPromises: Promise<string>[] = [];

  //       // Upload BaseMap if a file is selected
  //       if (this.selectedBaseMapFile) {
  //         const baseMapRef = this.storage.ref(`Textures/${docRef.id}/${this.selectedBaseMapFile.name}`);
  //         uploadPromises.push(baseMapRef.put(this.selectedBaseMapFile).then(() => baseMapRef.getDownloadURL()));
  //       }

  //       // Upload NormalMap if a file is selected
  //       if (this.selectedNormalMapFile) {
  //         const normalMapRef = this.storage.ref(`Textures/${docRef.id}/${this.selectedNormalMapFile.name}`);
  //         uploadPromises.push(normalMapRef.put(this.selectedNormalMapFile).then(() => normalMapRef.getDownloadURL()));
  //       }

  //       // Upload MetallicMap if a file is selected
  //       if (this.selectedMetallicMapFile) {
  //         const metallicMapRef = this.storage.ref(`Textures/${docRef.id}/${this.selectedMetallicMapFile.name}`);
  //         uploadPromises.push(metallicMapRef.put(this.selectedMetallicMapFile).then(() => metallicMapRef.getDownloadURL()));
  //       }

  //       // Wait for all uploads to finish
  //       const urls = await Promise.all(uploadPromises);

  //       // Prepare the updated data with URLs
  //       const updatedData = {
  //         BaseMapPath: this.selectedBaseMapFile?.name || '',
  //         NormalMapPath: this.selectedNormalMapFile?.name || '',
  //         MetallicMapPath: this.selectedMetallicMapFile?.name || ''
  //       };

  //       // Update Firestore document with the URLs
  //       await this.firestore.collection('TextureLibrary').doc(docRef.id).update(updatedData);
  //       console.log('Texture URLs updated successfully!');

  //       // Optionally reset the values after saving
  //       this.resetForm();
  //     })
  //     .catch((error) => {
  //       console.error('Error saving texture data:', error);
  //     });
  // }


  async saveValues() {
    // Prepare data to save
    const data = {
      TextureName: this.inputValue,
      BaseMapPath: '',
      NormalMapPath: '',
      MetallicMapPath: ''
    };

    if (this.inputValue === '') {
      alert('Enter the Texture Name.');
      return;
    }

    if (!this.selectedBaseMapFile) {
      alert('Select Base Map file first.');
      return;
    }

    try {
      // Save to Firestore first
      const docRef = await this.firestore.collection('TextureLibrary').add(data);
      console.log('Texture data saved successfully with ID:', docRef.id);

      const uploadPromises: Promise<string>[] = [];

      // Function to upload files and track progress
      const uploadFile = (file: File, path: string): Promise<string> => {
        const fileRef = this.storage.ref(path);
        const task = fileRef.put(file);

        // Track the upload progress
        task.percentageChanges().subscribe((progress) => {
          this.uploadProgress = progress ? progress : 0; // Update the progress
        });

        // Return a promise to get the download URL
        return task.then(() => fileRef.getDownloadURL());
      };

      // Upload BaseMap
      if (this.selectedBaseMapFile) {
        const baseMapPath = `Textures/${docRef.id}/${this.selectedBaseMapFile.name}`;
        uploadPromises.push(uploadFile(this.selectedBaseMapFile, baseMapPath));
      }

      // Upload NormalMap
      if (this.selectedNormalMapFile) {
        const normalMapPath = `Textures/${docRef.id}/${this.selectedNormalMapFile.name}`;
        uploadPromises.push(uploadFile(this.selectedNormalMapFile, normalMapPath));
      }

      // Upload MetallicMap
      if (this.selectedMetallicMapFile) {
        const metallicMapPath = `Textures/${docRef.id}/${this.selectedMetallicMapFile.name}`;
        uploadPromises.push(uploadFile(this.selectedMetallicMapFile, metallicMapPath));
      }

      // Wait for all uploads to finish
      const urls = await Promise.all(uploadPromises);

      // Prepare the updated data with URLs
      const updatedData = {
        BaseMapPath: this.selectedBaseMapFile?.name || '',
        NormalMapPath: this.selectedNormalMapFile?.name || '',
        MetallicMapPath: this.selectedMetallicMapFile?.name || ''
      };

      // Update Firestore document with the URLs
      await this.firestore.collection('TextureLibrary').doc(docRef.id).update(updatedData);
      console.log('Texture URLs updated successfully!');
      this.uploadProgress = 0

      // Optionally reset the values after saving
      this.resetForm();
    } catch (error) {
      console.error('Error saving texture data:', error);
    }
  }

  

  resetForm() {
    this.inputValue = '';
    this.baseMapValue = 'BaseMap';
    this.normalMapValue = 'NormalMap';
    this.metallicMapValue = 'MetallicMap';
    this.selectedBaseMapFile = null;
    this.selectedNormalMapFile = null;
    this.selectedMetallicMapFile = null;
  }

  checkPageAuthorization() {
    this.loading = true;
    this.commonService.getUser().subscribe((user: any) => {
      let userObject = { 'userid': user?.uid, 'pagename': '/texture-library' }
      this.commonService.checkViewPageRights(userObject).subscribe((res) => {
        console.log(res)
        this.loading = false;
        if (!res) {
          this.router.navigateByUrl("/drip");
        }
      });
    });
  }

  openModal(): void {
    this.imagenstance = document.getElementById('imageModal');
    const modal = new Modal( this.imagenstance);
    modal.show();
  }
  closeModal(): void {
    this.imagenstance.hide(); // This will hide the modal
}
  showImage(path: string, texture: any): void {
    const selectedPath = `Textures/${texture.id}/${path}`; // Construct the path
    const storageRef = this.storage.ref(selectedPath); // Use AngularFireStorage to get a reference

    this.seletedPath = path
    this.selectedtexture = texture
    // Get the download URL from Firebase storage
    storageRef.getDownloadURL().subscribe(
      (url) => {
        this.imagePath = url;
        this.openModal(); // Call the method to open the modal
      },
      (error) => {
        console.error('Error getting download URL:', error);
      }
    );
  }

  editableImage(): void {
    // Construct the paths for each texture
    const selectedBaseMapPath = `Textures/${this.editableTexture?.id}/${this.editableTexture.BaseMapPath}`;
    const selectedNormalMapPath = `Textures/${this.editableTexture?.id}/${this.editableTexture.NormalMapPath}`;
    const selectedMetallicMapPath = `Textures/${this.editableTexture?.id}/${this.editableTexture.MetallicMapPath}`;

    console.log(this.editableTexture);

    // Use AngularFireStorage to get a reference to each texture path
    const baseMapRef = this.storage.ref(selectedBaseMapPath);
    const normalMapRef = this.storage.ref(selectedNormalMapPath);
    const metallicMapRef = this.storage.ref(selectedMetallicMapPath);
    this.baseMapUrl = '';
    this.normalMapUrl = '';
    this.metallicMapUrl = '';

    // Get the download URL for the Base Map
    baseMapRef.getDownloadURL().subscribe(
      (url) => {
        this.baseMapUrl = url; // Assign the URL to the global variable
        console.log('Base Map URL:', this.baseMapUrl);
      },
      (error) => {
        console.error('Error getting Base Map URL:', error);
      }
    );

    // Get the download URL for the Normal Map
    normalMapRef.getDownloadURL().subscribe(
      (url) => {
        this.normalMapUrl = url; // Assign the URL to the global variable
        console.log('Normal Map URL:', this.normalMapUrl);
      },
      (error) => {
        console.error('Error getting Normal Map URL:', error);
      }
    );

    // Get the download URL for the Metallic Map
    metallicMapRef.getDownloadURL().subscribe(
      (url) => {
        this.metallicMapUrl = url; // Assign the URL to the global variable
        console.log('Metallic Map URL:', this.metallicMapUrl);
      },
      (error) => {
        console.error('Error getting Metallic Map URL:', error);
      }
    );
  }

  openEditModal(texture: any): void {
    this.editableTexture = texture
    this.selectedTextureId = texture.id;
    this.editInputValue = texture.TextureName;

    if (this.editableTexture) { this.editableImage() }

    const modalElement = document.getElementById('editTextureModal');
    this.editModalInstance = new Modal(modalElement);
    this.editModalInstance.show();
  }

  closeEditModal(): void {
    if (this.editModalInstance) {
      this.editModalInstance.hide(); // Close the modal
    }
  }

  handleEditFileUpload(event: any, type: string): void {
    const file = event.target.files[0];
    if (file) {
      switch (type) {
        case 'BaseMap':
          this.editBaseMapFile = file;
          console.log('Selected BaseMap File for Edit:', file.name);
          break;
        case 'NormalMap':
          this.editNormalMapFile = file;
          console.log('Selected NormalMap File for Edit:', file.name);
          break;
        case 'MetallicMap':
          this.editMetallicMapFile = file;
          console.log('Selected MetallicMap File for Edit:', file.name);
          break;
      }
    }
  }

  // Method to update the texture in Firestore
  // async updateTexture(): Promise<void> {
  //   if (!this.selectedTextureId) {
  //     console.error('No texture selected for update.');
  //     return;
  //   }

  //   // Prepare the updated data object
  //   const updatedData: any = {
  //     TextureName: this.editInputValue,
  //   };

  //   // Handle image uploads
  //   const uploadPromises: Promise<string>[] = [];

  //   if (this.editBaseMapFile) {
  //     const baseMapRef = this.storage.ref(`Textures/${this.selectedTextureId}/${this.editBaseMapFile.name}`);
  //     uploadPromises.push(baseMapRef.put(this.editBaseMapFile).then(() => baseMapRef.getDownloadURL()));
  //   }

  //   if (this.editNormalMapFile) {
  //     const normalMapRef = this.storage.ref(`Textures/${this.selectedTextureId}/${this.editNormalMapFile.name}`);
  //     uploadPromises.push(normalMapRef.put(this.editNormalMapFile).then(() => normalMapRef.getDownloadURL()));
  //   }

  //   if (this.editMetallicMapFile) {
  //     const metallicMapRef = this.storage.ref(`Textures/${this.selectedTextureId}/${this.editMetallicMapFile.name}`);
  //     uploadPromises.push(metallicMapRef.put(this.editMetallicMapFile).then(() => metallicMapRef.getDownloadURL()));
  //   }

  //   // Wait for all uploads to complete
  //   const urls = await Promise.all(uploadPromises);

  //   // Assign the URLs to the correct fields
  //   if (this.editBaseMapFile) {
  //     updatedData.BaseMapPath = this.editBaseMapFile.name;
  //   }
  //   if (this.editNormalMapFile) {
  //     updatedData.NormalMapPath = this.editNormalMapFile.name;
  //   }
  //   if (this.editMetallicMapFile) {
  //     updatedData.MetallicMapPath = this.editMetallicMapFile.name;
  //   }

  //   // Update Firestore with the new data
  //   this.firestore.collection('TextureLibrary').doc(this.selectedTextureId).update(updatedData)
  //     .then(() => {
  //       console.log('Texture updated successfully!');
  //       const modalElement = document.getElementById('editTextureModal');
  //       const modal = new Modal(modalElement!);
  //       modal.hide();
  //       this.closeEditModal()
  //     })
  //     .catch((error) => {
  //       console.error('Error updating texture:', error);
  //     });
  // }
  async updateTexture(): Promise<void> {
    if (!this.selectedTextureId) {
      console.error('No texture selected for update.');
      return;
    }

    // Prepare the updated data object
    const updatedData: any = {
      TextureName: this.editInputValue,
    };

    // Handle image uploads
    const uploadPromises: Promise<string>[] = [];
    let totalProgress = 0; // Track cumulative progress
    const uploadTasks: any[] = []; // To store upload tasks for progress tracking

    if (this.editBaseMapFile) {
      const baseMapRef = this.storage.ref(`Textures/${this.selectedTextureId}/${this.editBaseMapFile.name}`);
      const baseMapTask = baseMapRef.put(this.editBaseMapFile);
      uploadTasks.push(baseMapTask);
      uploadPromises.push(baseMapTask.then(() => baseMapRef.getDownloadURL()));
    }

    if (this.editNormalMapFile) {
      const normalMapRef = this.storage.ref(`Textures/${this.selectedTextureId}/${this.editNormalMapFile.name}`);
      const normalMapTask = normalMapRef.put(this.editNormalMapFile);
      uploadTasks.push(normalMapTask);
      uploadPromises.push(normalMapTask.then(() => normalMapRef.getDownloadURL()));
    }

    if (this.editMetallicMapFile) {
      const metallicMapRef = this.storage.ref(`Textures/${this.selectedTextureId}/${this.editMetallicMapFile.name}`);
      const metallicMapTask = metallicMapRef.put(this.editMetallicMapFile);
      uploadTasks.push(metallicMapTask);
      uploadPromises.push(metallicMapTask.then(() => metallicMapRef.getDownloadURL()));
    }

    // Track upload progress
    uploadTasks.forEach((task) => {
      task.percentageChanges().subscribe((progress) => {
        if (progress !== undefined) {
          totalProgress += progress / uploadTasks.length; // Calculate the average progress
          this.uploadProgress = totalProgress; // Update the overall progress
        }
      });
    });

    try {
      // Wait for all uploads to complete
      const urls = await Promise.all(uploadPromises);

      // Assign the URLs to the correct fields
      if (this.editBaseMapFile) {
        updatedData.BaseMapPath = this.editBaseMapFile.name;
      }
      if (this.editNormalMapFile) {
        updatedData.NormalMapPath = this.editNormalMapFile.name;
      }
      if (this.editMetallicMapFile) {
        updatedData.MetallicMapPath = this.editMetallicMapFile.name;
      }

      // Update Firestore with the new data
      await this.firestore.collection('TextureLibrary').doc(this.selectedTextureId).update(updatedData);
      console.log('Texture updated successfully!');
      this.uploadProgress = 0

      // Close the modal and reset the form
      this.closeEditModal();
    } catch (error) {
      console.error('Error updating texture:', error);
    }
  }

  deleteSelectedMap(path: string, texture: any): void {
    if (!path) {
      console.error('No path provided for deletion.');
      return;
    }

    // Determine the map name for confirmation
    let mapName = '';
    if (path === texture.BaseMapPath) {
      mapName = 'BaseMap';
    } else if (path === texture.NormalMapPath) {
      mapName = 'NormalMap';
    } else if (path === texture.MetallicMapPath) {
      mapName = 'MetallicMap';
    }

    // Show a confirmation dialog
    const confirmDelete = confirm(`Are you sure you want to delete the ${mapName}?`);
    if (!confirmDelete) {
      return; // Exit if the user cancels the action
    }

    // Construct the path in Firebase Storage
    const selectedPath = `Textures/${texture.id}/${path}`;
    const storageRef = this.storage.ref(selectedPath);

    // Delete the file from Firebase Storage
    storageRef.delete().subscribe({
      next: () => {
        console.log('File deleted successfully from Firebase Storage.');

        // Prepare the update data for Firestore
        let updatedData: any = {};
        if (mapName === 'BaseMap') {
          updatedData.BaseMapPath = '';
        } else if (mapName === 'NormalMap') {
          updatedData.NormalMapPath = '';
        } else if (mapName === 'MetallicMap') {
          updatedData.MetallicMapPath = '';
        }

        // Update the Firestore document with the empty field
        this.firestore.collection('TextureLibrary').doc(texture.id).update(updatedData)
          .then(() => {
            console.log('Firestore document updated successfully.');
            this.closeModal()

          })
          .catch((error) => {
            console.error('Error updating Firestore document:', error);
          });
      },
      error: (error) => {
        console.error('Error deleting file from Firebase Storage:', error);
      }



    });

  }


  deleteEditableTextures(): void {
    // Construct the paths for each texture to be deleted
    const selectedBaseMapPath = `Textures/${this.editableTexture.id}/${this.editableTexture.BaseMapPath}`;
    const selectedNormalMapPath = `Textures/${this.editableTexture.id}/${this.editableTexture.NormalMapPath}`;
    const selectedMetallicMapPath = `Textures/${this.editableTexture.id}/${this.editableTexture.MetallicMapPath}`;

    // Use AngularFireStorage to get a reference to each texture path
    const baseMapRef = this.storage.ref(selectedBaseMapPath);
    const normalMapRef = this.storage.ref(selectedNormalMapPath);
    const metallicMapRef = this.storage.ref(selectedMetallicMapPath);

    const confirmDelete = confirm(`Are you sure you want to delete the Texture?`);
    if (!confirmDelete) {
      return; // Exit if the user cancels the action
    }


    // Delete the Base Map
    baseMapRef.delete().subscribe(
      () => {
        console.log('Base Map deleted successfully');
      },
      (error) => {
        console.error('Error deleting Base Map:', error);
      }
    );

    // Delete the Normal Map
    normalMapRef.delete().subscribe(
      () => {
        console.log('Normal Map deleted successfully');
      },
      (error) => {
        console.error('Error deleting Normal Map:', error);
      }
    );

    // Delete the Metallic Map
    metallicMapRef.delete().subscribe(
      () => {
        console.log('Metallic Map deleted successfully');
      },
      (error) => {
        console.error('Error deleting Metallic Map:', error);
      }
    );

    // Delete the document from Firestore
    this.firestore.collection('TextureLibrary').doc(this.editableTexture.id).delete().then(() => {
      console.log('Texture document deleted successfully from Firestore');
      this.closeEditModal()


    }).catch((error) => {
      console.error('Error deleting texture document from Firestore:', error);
    });
  }
}




