<div *ngIf="logInFlag">
    <app-yologram-social></app-yologram-social>
    <div (scroll)="onScroll()" #brandStoriyScroll class="div-margin roksan box1">
        <div class=" brand-profile-color">
            <div class="brand-profile-color m-1">
                <div class="slider-css brand-profile-color" (scroll)="selectByScroll()">
                    <div class="" *ngFor="let brand of brandDetailsAll" [ngClass]="byScroll != true ? 'prt-Evt' :''">
                        <img class="m-1" style="height:60px !important; width:80px !important;"
                            [ngClass]="selectedBrand == brand ? 'shadow-brand' : (selectedBrand != undefined && !byScroll ? 'blug-css' :'')"
                            (click)="selectBrandForStory(brand.id,brand)" [src]="brand.Brandurl" />
                        <div *ngIf="snapShotBrandList.indexOf(brand.id) >= 0">
                        </div>
                    </div>
                </div>
                <div *ngIf="loading" class="loading">Loading&#8230;</div>
            </div>
            <div class="brand-profile-color">
                <div class="container garment-alingn" *ngIf="byScroll == true || showBrandFlag == true">
                    <div class="col-lg-12 justify-content-center  info-tag  garmentName outer">
                        <div class="close-butn" *ngIf="byScroll == true || showBrandFlag != true"
                            (click)="closeButtonForBrand()">X</div>
                        <div class="contain-fluid m-1 ">
                            <div class="col-lg-12  pt-4px  text-center w-100  d-flex justify-content-center  d-flex">
                                <div class="m-0 col-8">
                                    <div class="col-12 container vertical-center-band">
                                        <h5 class="p-2 text-white">Please Select Brand.</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="contain-fluid " [ngClass]="selectedBrand != undefined  ? 'garment-card-sticky' :''">
                    <div class="container pb-2 garment-alingn"
                        *ngIf="selectedBrand && selectedBrand.BrandName && byScroll == false">
                        <div *ngIf="boardDetails != undefined || boardDetails == undefined && selectedBrand"
                            class="justify-content-center text-end container garment-alingn container-fluid -1">
                            <h6 class="color-class-board m-2 "
                                *ngIf="boardDetails != undefined  && boardDetails.name != undefined "><img
                                    style="width:12px !important"
                                    src="./assets/pinterest-512.png">:{{boardDetails.name}}
                            </h6>
                            <h6 class="color-class-board m-2"
                                *ngIf="boardDetails == undefined || boardDetails.name == undefined">
                                <img style="width:12px !important" src="./assets/pinterest-512.png">:Not Linked
                            </h6>
                        </div>
                        <div class="col-lg-12 justify-content-center garmentName">
                            <div class="contain-fluid m-1 ">
                                <div
                                    class="col-lg-12  pt-4px  text-center w-100  d-flex justify-content-center  d-flex">
                                    <div class="text-center d-flex col-2">
                                        <img class="profileImg-brand" style="width:35px; height:35px !important;"
                                            [src]="getBrandImage(selectedBrand.LogoURL)">
                                    </div>
                                    <div class="m-0 outer col-8">
                                        <div class="col-12 container vertical-center">
                                            <h6 class="color-class brand-font-size">{{selectedBrand.BrandName}}</h6>
                                        </div>
                                    </div>
                                    <div class="m-0 col-2 text-end">
                                        <span><img *ngIf="heartFlag == true" class="heart"
                                                style="width:18px !important; color:black"
                                                src="./assets/storystyle/newStoryIcon/heart.png"></span>
                                        <span (click)="clickOnHeart()"><img *ngIf="heartFlag == false" class="heart"
                                                style="width:18px !important; color:black"
                                                src="./assets/storystyle/newStoryIcon/heartGray.png">
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="justify-content-center text-center d-flex brand-profile-sy p-1"
                                *ngIf="selectedBrand && selectedBrand.BrandName != undefined && byScroll == false">
                                <div class="justify-content-center text-center d-flex set-margin ">
                                    <h6 class="text-primary brandbtn m-1 py-2 d-block class" (click)="setTab('story')">
                                        <div *ngIf="selectedTab == 'story'"
                                            [ngClass]="selectedTab == 'story' ? 'story-menu-card' : 'story-menu-icon'">
                                            <img style="width: 15px;" src="./assets/storystyle/storyblack.png">
                                        </div>
                                        <img *ngIf="selectedTab != 'story'"
                                            [ngClass]="selectedTab != 'story' ? 'story-menu-icon' : ''"
                                            style="color:black ;padding-bottom: 5px;"
                                            src="./assets/storystyle/story-white.png">
                                        <h6 *ngIf="selectedTab == 'story'" class="color-class-sy m-1">Stories</h6>
                                    </h6>
                                    <h6 class="text-primary brandbtn m-1 py-2 d-block color-class">
                                        <img *ngIf="selectedTab == 'mens-wear'"
                                            [ngClass]="selectedTab == 'mens-wear' ? 'story-menu' : 'story-menu-icon'"
                                            style="color:black ;padding-bottom: 5px;"
                                            src="./assets/storystyle/MenIconBlack.png">
                                        <img (click)="setTab('mens-wear')" *ngIf="selectedTab != 'mens-wear'"
                                            [ngClass]="selectedTab != 'mens-wear' ? 'story-menu-icon' : ''"
                                            style="color:black ;padding-bottom: 5px;"
                                            src="./assets/storystyle/MenIconWhite.png">
                                        <h6 *ngIf="selectedTab == 'mens-wear'" class="color-class-sy m-1">MensWear</h6>
                                    </h6>
                                    <h6 class="text-primary brandbtn m-1 py-2 d-block color-class">
                                        <img *ngIf="selectedTab == 'womens-wear'"
                                            [ngClass]="selectedTab == 'womens-wear' ? 'story-menu' : 'story-menu-icon'"
                                            style="color:black ;padding-bottom: 5px;"
                                            src="./assets/storystyle/WomenIconBlack.png">
                                        <img (click)="setTab('womens-wear')" *ngIf="selectedTab != 'womens-wear'"
                                            [ngClass]="selectedTab != 'womens-wear' ? 'story-menu-icon' : ''"
                                            style="color:black ;padding-bottom: 5px;"
                                            src="./assets/storystyle/WomenIconWhite.png">
                                        <h6 *ngIf="selectedTab == 'womens-wear'" class="color-class-sy m-1">Womenswear
                                        </h6>
                                    </h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <app-style-stories class="brand-profile-color" id="target" [tab]="selectedTab"
                    [onChangeFlag]="onChangeFlag" (changeSelectedTab)="changeTab($event)"
                    [brandsIDdetailsAll]="brandsIDdetailsAll" (changeSelctedCloth)="changeCloth($event)"
                    [selectBrandGender]="genderID" [selectedBrand]="" [brandId]="brandId" [pageNumber]="pageNumber"
                    [clickEventFlag]="clickEventFlag" [stylePageFlag]="rootFlag" [stylePage]="stylePage"
                    [routLogInFlag]="routLogInFlag">
                </app-style-stories>
            </div>
        </div>
    </div>
</div>
<div *ngIf="!logInFlag">
    <app-public></app-public>
    <div (scroll)="onScroll()" #brandStoriyScroll class="div-margin roksan box1">
        <div class=" brand-profile-color">
            <div class="brand-profile-color m-1">
                <div class="slider-css brand-profile-color" (scroll)="selectByScroll()">
                    <div class="" *ngFor="let brand of brandDetailsAll" [ngClass]="byScroll != true ? 'prt-Evt' :''">
                        <img class="m-1" style="height:60px !important; width:80px !important;"
                            [ngClass]="selectedBrand == brand ? 'shadow-brand' : (selectedBrand != undefined && !byScroll ? 'blug-css' :'')"
                            (click)="selectBrandForStory(brand.id,brand)" [src]="getBrandImage(brand.LogoURL)" />
                        <div *ngIf="snapShotBrandList.indexOf(brand.id) >= 0">
                        </div>
                    </div>
                </div>
                <div *ngIf="loading" class="loading">Loading&#8230;</div>
            </div>
            <div class="brand-profile-color">
                <div class="container garment-alingn" *ngIf="byScroll == true || showBrandFlag == true">
                    <div class="col-lg-12 justify-content-center  info-tag  garmentName outer">
                        <div class="close-butn" *ngIf="byScroll == true || showBrandFlag != true"
                            (click)="closeButtonForBrand()">X</div>
                        <div class="contain-fluid m-1 ">
                            <div class="col-lg-12  pt-4px  text-center w-100  d-flex justify-content-center  d-flex">
                                <div class="m-0 col-8">
                                    <div class="col-12 container vertical-center-band">
                                        <h5 class="p-2 text-white">Please Select Brand.</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="contain-fluid " [ngClass]="selectedBrand != undefined  ? 'garment-card-sticky' :''">
                    <div class="container pb-2 garment-alingn"
                        *ngIf="selectedBrand && selectedBrand.BrandName && byScroll == false">
                        <div *ngIf="boardDetails != undefined || boardDetails == undefined && selectedBrand"
                            class="justify-content-center text-end container garment-alingn container-fluid -1">
                            <h6 class="color-class-board m-2 "
                                *ngIf="boardDetails != undefined  && boardDetails.name != undefined "><img
                                    style="width:12px !important"
                                    src="./assets/pinterest-512.png">:{{boardDetails.name}}
                            </h6>
                            <h6 class="color-class-board m-2"
                                *ngIf="boardDetails == undefined || boardDetails.name == undefined">
                                <img style="width:12px !important" src="./assets/pinterest-512.png">:Not Linked
                            </h6>
                        </div>
                        <div class="col-lg-12 justify-content-center garmentName">
                            <div class="contain-fluid m-1 ">
                                <div
                                    class="col-lg-12  pt-4px  text-center w-100  d-flex justify-content-center  d-flex">
                                    <div class="text-center d-flex col-2">
                                        <img class="profileImg-brand" style="width:35px; height:35px !important;"
                                            [src]="getBrandImage(selectedBrand.LogoURL)">
                                    </div>
                                    <div class="m-0 outer col-8">
                                        <div class="col-12 container vertical-center">
                                            <h6 class="color-class brand-font-size">{{selectedBrand.BrandName}}</h6>
                                        </div>
                                    </div>
                                    <div class="m-0 col-2 text-end">
                                        <span><img *ngIf="heartFlag == true" class="heart"
                                                style="width:18px !important; color:black"
                                                src="./assets/storystyle/newStoryIcon/heart.png"></span>
                                        <span (click)="clickOnHeart()"><img *ngIf="heartFlag == false" class="heart"
                                                style="width:18px !important; color:black"
                                                src="./assets/storystyle/newStoryIcon/heartGray.png">
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="justify-content-center text-center d-flex brand-profile-sy p-1"
                                *ngIf="selectedBrand && selectedBrand.BrandName != undefined && byScroll == false">
                                <div class="justify-content-center text-center d-flex set-margin ">
                                    <h6 class="text-primary brandbtn m-1 py-2 d-block class" (click)="setTab('story')">
                                        <div *ngIf="selectedTab == 'story'"
                                            [ngClass]="selectedTab == 'story' ? 'story-menu-card' : 'story-menu-icon'">
                                            <img style="width: 15px;" src="./assets/storystyle/storyblack.png">
                                        </div>
                                        <img *ngIf="selectedTab != 'story'"
                                            [ngClass]="selectedTab != 'story' ? 'story-menu-icon' : ''"
                                            style="color:black ;padding-bottom: 5px;"
                                            src="./assets/storystyle/story-white.png">
                                        <h6 *ngIf="selectedTab == 'story'" class="color-class-sy m-1">Stories</h6>
                                    </h6>
                                    <h6 class="text-primary brandbtn m-1 py-2 d-block color-class">
                                        <img *ngIf="selectedTab == 'mens-wear'"
                                            [ngClass]="selectedTab == 'mens-wear' ? 'story-menu' : 'story-menu-icon'"
                                            style="color:black ;padding-bottom: 5px;"
                                            src="./assets/storystyle/MenIconBlack.png">
                                        <img (click)="setTab('mens-wear')" *ngIf="selectedTab != 'mens-wear'"
                                            [ngClass]="selectedTab != 'mens-wear' ? 'story-menu-icon' : ''"
                                            style="color:black ;padding-bottom: 5px;"
                                            src="./assets/storystyle/MenIconWhite.png">
                                        <h6 *ngIf="selectedTab == 'mens-wear'" class="color-class-sy m-1">MensWear</h6>
                                    </h6>
                                    <h6 class="text-primary brandbtn m-1 py-2 d-block color-class">
                                        <img *ngIf="selectedTab == 'womens-wear'"
                                            [ngClass]="selectedTab == 'womens-wear' ? 'story-menu' : 'story-menu-icon'"
                                            style="color:black ;padding-bottom: 5px;"
                                            src="./assets/storystyle/WomenIconBlack.png">
                                        <img (click)="setTab('womens-wear')" *ngIf="selectedTab != 'womens-wear'"
                                            [ngClass]="selectedTab != 'womens-wear' ? 'story-menu-icon' : ''"
                                            style="color:black ;padding-bottom: 5px;"
                                            src="./assets/storystyle/WomenIconWhite.png">
                                        <h6 *ngIf="selectedTab == 'womens-wear'" class="color-class-sy m-1">Womenswear
                                        </h6>
                                    </h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <app-style-stories class="brand-profile-color" id="target" [tab]="selectedTab"
                    [onChangeFlag]="onChangeFlag" (changeSelectedTab)="changeTab($event)"
                    [brandsIDdetailsAll]="brandsIDdetailsAll" (changeSelctedCloth)="changeCloth($event)"
                    [selectBrandGender]="genderID" [selectedBrand]="" [brandId]="brandId" [pageNumber]="pageNumber"
                    [clickEventFlag]="clickEventFlag" [stylePageFlag]="rootFlag" [stylePage]="stylePage"
                    [routLogInFlag]="routLogInFlag">
                </app-style-stories>
            </div>
        </div>
    </div>
</div>



<!-- <div  class="div-margin roksan">
    <app-news3-d-popup   [GenderFlag]="genderFlag" [StyleGarmentUrl]="garmentLink" ></app-news3-d-popup>
</div> -->