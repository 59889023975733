<div *ngIf="loading" class="loading">Loading&#8230;</div>
<div *ngIf="!loading">
  <div class="card brand-profile-color">
    <div class="dt-responsive selectlist font-14">
      <div class="dataTables_wrapper dt-bootstrap4" id="add-row-table_wrapper">
        <div class="container text-center" *ngIf="brandDetails">
          <div class="col-lg-12 d-flex justify-content-center">
            <div class="col-lg-12 text-center w-100 m-1"><img
                class="img-profile img-thumbnail img-fluid rounded profileImg"
                style="width: 70px; height:70px !important;"
                [src]="getBrandImage(brandDetails.LogoURL)"></div>
          </div>
          <h3 class="color-class" >{{brandDetails.BrandName}}</h3>
          <button type="button" class="btn btn-sm btn-outline-info m-1 color-class">Follow</button>
          <h6 class="color-class" *ngIf="boardNameDetails != undefined"> <img style="width:15px !important" src="./assets/pinterest-512.jpg">:
            {{boardNameDetails}}</h6>
          <h6 class="color-class" *ngIf="boardNameDetails  == undefined"><img style="width:15px !important" src="./assets/pinterest-512.jpg">: Not
            Linked To Board</h6>

        </div>
        <div class="p-2 m-2 justify-content-center text-center d-flex"  *ngIf="clothType == undefined">
          <h6 class=" color-class text-primary brandbtn m-2" (click)="getStoryForGender(brandDetails)">Story</h6>
          <h6 class="color-class text-primary brandbtn m-2" (click)="getStyleForGender(brandDetails,'M')"
            [ngClass]="selectedGender === 'M' ? 'border-bottom border-dark' : ''">Menswear</h6>
          <h6 class="color-class text-primary brandbtn m-2" (click)="getStyleForGender(brandDetails,'F') "
            [ngClass]="selectedGender === 'F' ? 'border-bottom border-dark' : ''">Womenswear</h6>
        </div>
        <div class="p-2 m-2 justify-content-center text-center d-flex" *ngIf="clothType != undefined">
          <h6 class="text-primary brandbtn m-2 color-class" (click)="getStoryForGenderCloth(brandDetails)">Story</h6>
          <h6 *ngIf="selectedGender == 'M'" class="text-primary brandbtn m-2 color-class" 
            [ngClass]="selectedGender === 'M' ? 'border-bottom border-dark' : ''">Menswear</h6>
          <h6 *ngIf="selectedGender == 'F'" class="text-primary brandbtn m-2 color-class"
            [ngClass]="selectedGender === 'F' ? 'border-bottom border-dark color-class' : ''">Womenswear</h6>
        </div>
        <div class="col-lg-12 p-1">
          <span *ngIf="errMessage != ''" class="danger">
            <div class="alert alert-danger" role="alert">
              {{errMessage}}
            </div>
          </span>
        </div>
      </div>
   
      <div class="card  brand-profile-color" *ngIf="!isloading">
        <div class="row g-2 form d-block  " id="target" *ngIf="packJsonObject.length > 0">
          <div class="slider-css211 ">
            <div *ngFor="let object of packJsonObject[0].value['files'];let i=index" class="slider-img211">
              <img *ngIf="clothType == packJsonObject[0].title && clothId == object " class="img-responsive1"
               [src]="sanitizer.bypassSecurityTrustUrl(zipList[object])" />
            </div>
          </div>
          <div class="slider-css2-upper">
            <div *ngFor="let object of packJsonObject[1].value['files'];let i=index" class="slider-img2-upper" 
                 data-bs-toggle="collapse" data-bs-target="#info-3D"
                  saria-expanded="false" aria-controls="info-3D">
                  <div class="upper-div">
                     <img class="img-responsive-upper" [src]="sanitizer.bypassSecurityTrustUrl(zipList[object])" 
              /></div>
            </div>
        </div>
          <div class="slider-css2">
            <div *ngFor="let object of packJsonObject[2].value['files'];let i=index" class="slider-img2">
              <img *ngIf="clothType == packJsonObject[2].title && clothId == object "  class="img-responsive" 
              [src]="sanitizer.bypassSecurityTrustUrl(zipList[object])" />
            </div>
          </div>
          <div class="slider-css211">
            <div *ngFor="let object of packJsonObject[3].value['files'];let i=index" class="slider-img211">
              <img *ngIf="clothType == packJsonObject[3].title && clothId == object "  class="img-responsive1"
               [src]="sanitizer.bypassSecurityTrustUrl(zipList[object])" />
            </div>
          </div>

        </div>
        <div class="row g-2 form d-block " *ngIf="packJsonObject.length > 0 && clothType == undefined">
          <div class="slider-css211 ">
            <div *ngFor="let object of packJsonObject[0].value['files'];let i=index" class="slider-img211">
              <img class="img-responsive1" [src]="sanitizer.bypassSecurityTrustUrl(zipList[object])" />
            </div>
          </div>
          <!-- <div class="slider-css2">
            <div *ngFor="let object of packJsonObject[1].value['files'];let i=index" class="slider-img2">
              <img class="img-responsive" [src]="sanitizer.bypassSecurityTrustUrl(zipList[object])" />
            </div>
          </div> -->
          <div class="slider-css2">
            <div *ngFor="let object of packJsonObject[2].value['files'];let i=index" class="slider-img2">
              <img class="img-responsive" [src]="sanitizer.bypassSecurityTrustUrl(zipList[object])" />
            </div>
          </div>
          <div class="slider-css211">
            <div *ngFor="let object of packJsonObject[3].value['files'];let i=index" class="slider-img211">
              <img class="img-responsive1" [src]="sanitizer.bypassSecurityTrustUrl(zipList[object])" />
            </div>
          </div>
          <div class="d-flex justify-content-center">
            <div class="spinner-border" *ngIf="isloading" role="status">
              <span class=""></span>
            </div>
          </div>
        </div>
      </div> 
    </div>
  </div>
  <div class="d-flex justify-content-center " *ngIf="isloading">
    <div class="spinner-border"  role="status">
      <span class=""></span>
    </div>
  </div>
</div>
<div  class="collap-popup p-1" style="min-height: 100px;">
  <div class="collapse collapse-horizontal" id="info-3D">
    <div class="card card-body d-flex imp-block text-center" style="width: 70px;"  saria-expanded="false">
    <h6 (click)="infoPopup()"  data-bs-toggle="modal"
           data-bs-target="#garment-details"><i class="bi bi-info-circle">info</i></h6>
    <h6 (click)="threeDPopup()"><i class="bi bi-badge-3d p-1"></i>3D</h6>
    </div>
  </div>
</div>