import { HttpClient, HttpParams } from '@angular/common/http'
import * as JSZip from 'jszip/dist/jszip';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, lastValueFrom, map } from 'rxjs';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DownloadZipService {
  packJsonObject: any = []
  zipDownladeObject: any = []
  downloadedAvatarZipData: any = []

  downloadedZipData: any;
  downloadedZipDataBottom: any;
  zipListBottom: any
  zipList: any
  zipAvatrList: any
  // private downloadedZipDataSubject = new Subject<any[]>();
  // downloadedZipData$: Observable<any[]> = this.downloadedZipDataSubject.asObservable();
  private downloadedzipData = new BehaviorSubject<any>(null);
  udownloadedzipDataObservable = this.downloadedzipData.asObservable();

  private downloadeAvatardzipData = new BehaviorSubject<any>(null);
  udownloadedAvatarzipDataObservable = this.downloadeAvatardzipData.asObservable();

  private downloadedZipDataBottomWear = new BehaviorSubject<any>(null);
  downloadedZipDataBottomObservable = this.downloadedZipDataBottomWear.asObservable();

  private processAvatarData: string = environment.functionBaseUrl + "processAvatarDataZip"


  private dataSubject = new BehaviorSubject<any>(null);
  data$ = this.dataSubject.asObservable();
  response: any;
  errorMessage: string;
  testVar: string;
  errMessage: string;
  constructor(private _httpClient: HttpClient,private storage: AngularFireStorage,) { }

  async downloadZipFilesSequentially(urls: string[]) {
    const allUrls = []
    allUrls.push(urls)
    console.log("allUrls", allUrls)
    for (const url of allUrls) {
      await this.downloadZipFileForCloth(url);
    }
    console.log("loop is End", allUrls)
    this.zipDownladeObject = []
  }

  downloadZipFileForCloth(dataUrl: any) {
    let url = dataUrl
    this._httpClient
      .get(
        url,
        {
          observe: 'response',
          responseType: 'blob',
        }
      )
      .subscribe(async (res) => {
        await this.procesZipFileLoad(res.body, this.processResponse.bind(this));
        ;
      },
        (error) => (error)
      );

  }

  processResponse(res: any[]) {
    this.packJsonObject = res;
    this.downloadedZipData = res;
    this.getDownloadedDat(this.downloadedZipData)
  }

  procesZipFileLoad(data, callBackSetJsonObject) {
    var filesToProcess = ['Albedo.jpg', 'Normal.png', 'Metal.png', 'clothSaveFile.json'];
    try {
      this.zipList = JSZip.loadAsync(data, callBackSetJsonObject, this.downloadedZipData)
        .then(async function (zip) {
          let fileObject = [];
          for (const fileName of filesToProcess) {

            if (zip.files[fileName]) {
              let data = await zip.files[fileName].async('arraybuffer');
              fileObject[fileName] = data
            } else {
              fileObject[fileName] = "Not found"
            }

          }
          callBackSetJsonObject(fileObject);
          return Promise.all(fileObject);
        })
        .then(function (result) {
          return result
        })
        .then((result) => {
          this.downloadedZipData = result;
          return;
        })
        .catch(function (e) {
          console.error(e);
        });
      return this.zipList

    } catch (error) {
      self.postMessage({ error: 'Failed to process ZIP file', details: error.message });
    }
    return this.downloadedZipData;
  }

  downloadAvatarZipFile(AvatarUrl) {
    let value = ''
    let url = AvatarUrl
    this._httpClient
      .get(
        url
        ,
        {
          observe: 'response',
          responseType: 'blob',
        }
      )
      .subscribe(async (res) => {
        await this.procesAvatarZipFileLoad(res.body, this.processAvatarResponse.bind(this));
        ;

      },
        (error) => (error)
      );
  }

  processAvatarResponse(res: any[]) {
    this.packJsonObject = res;

    this.downloadedAvatarZipData = res;
    this.getDownloadedAvatarData(this.downloadedAvatarZipData)

  }

  procesAvatarZipFileLoad(data, callBackSetJsonObject) {
    var filesToProcesss = ['savedMetalRoughTex.jpg', 'savedNormalTex.jpg', 'savedHairTex.png', 'savedTex.jpg', 'savefile.json'];
    try {

      this.zipAvatrList = JSZip.loadAsync(data, callBackSetJsonObject, this.downloadedAvatarZipData)
        .then(async function (zip) {
          let fileObject = [];
          for (const fileName of filesToProcesss) {

            if (zip.files[fileName]) {
              let data = await zip.files[fileName].async('arraybuffer');
              fileObject[fileName] = data
            } else {
              fileObject[fileName] = "Not found"
            }

          }
          callBackSetJsonObject(fileObject);

          return Promise.all(fileObject);

        })
        .then(function (result) {
          return result

        })
        .then((result) => {
          this.downloadedAvatarZipData = result;
          return;
        })
        .catch(function (e) {
          console.error(e);
        });
      return this.zipAvatrList

    } catch (error) {
      self.postMessage({ error: 'Failed to process ZIP file', details: error.message });
    }
  }

  async getDownloadedDat(downloadedZipData) {
    this.zipDownladeObject.push(this.downloadedZipData)
    await this.downloadedzipData.next(this.zipDownladeObject);
    console.log("downloadedZipData Received ", this.zipDownladeObject)
  }

  async getDownloadedAvatarData(downloadedZipData) {
    // this.zipDownladeObject.push(this.downloadedZipData)
    await this.downloadeAvatardzipData.next(downloadedZipData);
    console.log("downloadedZipAvatarData Received ", downloadedZipData)
  }

  getDownloadedBottomData(downloadedZipDataBottom) {
    this.downloadedZipDataBottomWear.next(downloadedZipDataBottom);
  }

  getImageSize(url: string): Observable<number> {
    return this._httpClient.get(url, { responseType: 'blob' }).pipe(
      map(blob => blob.size)
    );
  }

  async downloadAndProcessClothDataZipsTest(urls: string[]): Promise<any[]> {
    let responses = [];
    for (let url of urls) {
      const response = await lastValueFrom(this.processClothZip(url));
      responses.push(response);
    }
    return responses;  // Now correctly typed as Promise<any[]>
  }

  processClothZip(url: string) {
    // Assuming this returns an Observable from an HTTP request
    let functionUrl = 'https://us-central1-yolomoves-fb435.cloudfunctions.net/downloadAndExtractClothZipData';
    console.log("url",url)
    return this._httpClient.post<any>(functionUrl, { url: url });
  }

  async downloadAndProcessClothDataZips(urls: string[]): Promise<any[]> {

    const downloadPromises = urls.map(url => this.downloadAndProcessClothDataZip(url));

    const processedClothData = await Promise.all(downloadPromises);

    const extractedData = [];
    
    for(const data of processedClothData){
      await new Promise(resolve => setTimeout(resolve, 30));
      extractedData.push(await this.ExtractZipFile(data));
    }
    return extractedData;
  }

  private async downloadAndProcessClothDataZip(url: string): Promise<any> {
    const response = await this._httpClient.get(url, {
      observe: 'response',
      responseType: 'blob'
    }).toPromise();

    return response;
  }

  async downloadAndProcessSingleClothDataZip(url: string): Promise<any> {
    const response = await this._httpClient.get(url, {
      observe: 'response',
      responseType: 'blob'
    }).toPromise();

    const zip = await JSZip.loadAsync(response.body);
    const filesToProcess = ['Albedo.jpg', 'Normal.png', 'Metal.png', 'clothSaveFile.json'];
    const fileObject = {};

    for (const fileName of filesToProcess) {
      if (zip.files[fileName]) {
        fileObject[fileName] = await zip.files[fileName].async('arraybuffer');
      } else {
        fileObject[fileName] = "Not found";
      }
    }
    return fileObject;
  }

  private async ExtractZipFile(response: any) {

    const zip = await JSZip.loadAsync(response.body);
    const filesToProcess = ['Albedo.jpg', 'Normal.png', 'Metal.png', 'clothSaveFile.json'];
    const fileObject = {};

    for (const fileName of filesToProcess) {
      if (zip.files[fileName]) {
        fileObject[fileName] = await zip.files[fileName].async('arraybuffer');
      } else {
        fileObject[fileName] = "Not found";
      }
    }
    return fileObject;

  }

  async downloadAndProcessAvatarDataZip(url: string){
    try {
      const response = await this._httpClient.get(url, {
        observe: 'response',
        responseType: 'blob'
      }).toPromise();

      console.log(response, 'response');
      this.response = response;
      const zip = await JSZip.loadAsync(response.body);
      const filesToProcess = ['savedMetalRoughTex.jpg', 'savedNormalTex.jpg', 'savedHairTex.png', 'savedTex.jpg', 'savefile.json'];
      const fileObject = {};

      for (const fileName of filesToProcess) {
        if (zip.files[fileName]) {
          fileObject[fileName] = await zip.files[fileName].async('arraybuffer');
        } else {
          fileObject[fileName] = "Not found";
        }
      }
      console.log("fileObject",fileObject)
      return fileObject;
    } catch (error) {
      console.error(error);
      // Show message when the avatar is not found
      if (error.status === 404) {
        console.log('Avatar is not found');
        this.errorMessage = 'Avatar is not found'
      }
      // Rethrow the error or handle it as needed
      throw error;
    }
  }


  // async downloadAndProcessAvatarDataZip(url: string) {
  //   const functionUrl = this.processAvatarDataZip;
  //   // const functionUrl = 'http://127.0.0.1:5001/yolomoves-fb435/us-central1/processAvatarDataZip';
  //   return await this._httpClient.get<any>(`${functionUrl}?url=${encodeURIComponent(url)}`);
  // }

  
  async downloadPack0DZipFileForDripsPageServices(brandEncodedurl) {
    console.log('pack0 loading intilze...')
    this.testVar = 'Change';
    // this.tempMessage = uniqueKey
    let url = 'https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/' + brandEncodedurl + '?alt=media&token=46df48b9-1f7a-4b61-98c6-bff029734da4';
    try {
      console.log('res loaded........',brandEncodedurl)
      const res = await this._httpClient.get(brandEncodedurl, { observe: 'response', responseType: 'blob' }).toPromise();
      console.log('res loaded........',res)
     const  data = await this.processZipFile(res.body, this.processResponse.bind(this));
     return data;
    } catch (error) {
      this.handleErrors(error);
    } finally {
    }
    console.log('pack0 loaded........')
  }

  processZipFile(data, callBackSetJsonObject) {
    // this.processZipFileCopy(data, callBackSetJsonObject)   
    const startTime = performance.now();
    this.testVar = 'Change Update';
    JSZip.loadAsync(data, callBackSetJsonObject, this.testVar)
      .then(function (zip) {
        /* Parse json file */
        var jsonExtn = /(.json)$/;
        Object.keys(zip.files)
          .filter((fileName) => {
            return jsonExtn.test(fileName.toLowerCase());
          })
          .map((jsonFileName) => {
            var jsonFile = zip.files[jsonFileName];
            return jsonFile.async('string').then((jsonString) => {
              let packJsonObject = JSON.parse(jsonString);

              let jsonResponseData =
                [
                  {
                    title: 'headgear',
                    value:
                    {
                      'files': packJsonObject['hFiles'].filter((data) => data != ''),
                      'filesIndex': packJsonObject['hFiles'].filter((data) => data == '' || data != undefined),
                      'name': packJsonObject['hName'].filter((data) => data.indexOf('deleted') < 0),
                      'count': packJsonObject['hCount'],
                    }
                  },
                  {
                    title: 'upperwear',
                    value: {
                      'files': packJsonObject['uFiles'].filter((data) => data != ''),
                      'filesIndex': packJsonObject['uFiles'].filter((data) => data == '' || data != undefined),
                      'name': packJsonObject['uName'].filter((data) => data.indexOf('deleted') < 0),
                      'count': packJsonObject['uCount'],
                    }
                  },
                  {
                    title: 'bottomwear',
                    value: {
                      'files': packJsonObject['bFiles'].filter((data) => data != ''),
                      'filesIndex': packJsonObject['bFiles'].filter((data) => data == '' || data != undefined),
                      'name': packJsonObject['bName'].filter((data) => data.indexOf('deleted') < 0),
                      'count': packJsonObject['bCount'],

                    }
                  },
                  {
                    title: "footwear",
                    value: {
                      'files': packJsonObject['fFiles'].filter((data) => data != ''),
                      'filesIndex': packJsonObject['fFiles'].filter((data) => data == '' || data != undefined),
                      'name': packJsonObject['fName'].filter((data) => data.indexOf('deleted') < 0),
                      'count': packJsonObject['fCount'],
                    },
                  }
                ]

              callBackSetJsonObject(jsonResponseData);
            });
          });
        var re = /(.jpg|.png|.gif|.ps|.jpeg | .json)$/;
        var promises = Object.keys(zip.files)
          .filter(function (fileName) {
            // don't consider non image files
            return re.test(fileName.toLowerCase());
          })
          .map(function (fileName) {
            var file = zip.files[fileName];
            if (fileName.indexOf('.json') > 0) {
            } else {
              return file.async('blob').then(function (blob) {
                return [
                  fileName.substring(0, fileName.lastIndexOf('.')), // keep the link between the file name and the content
                  URL.createObjectURL(blob), // create an url. img.src = URL.createObjectURL(...) will work
                ];
              });
            }
          });
        return Promise.all(promises);
      })
      .then(function (result) {
        return result.reduce(function (acc, val) {
          acc[val[0]] = val[1];
          return acc;
        }, {});
      })
      .then((result) => {
        let newZipList = JSON.parse(JSON.stringify(result));
        this.zipList = newZipList
        return;
      })
      .catch(function (e) {
        console.error(e);
      });

    // this.reload(data,callBackSetJsonObject)
  }
  
  handleErrors(error) {
    console.log('error', error)
    this.packJsonObject = [];
    this.errMessage = "Pack0 is not found for this selection !!!"
  }

  async newZipSErvices(url) {
    let data = await this.downloadPack0DZipFileForDripsPageServices(url)
    console.log("new services", this.zipList)
    console.log("new services", data)
    return this.zipList;
  }



  
//   async downloadAndBrandAvatarDataZip(url: string, targetFolder: string, targetFilename: string): Promise<any> {
//     const response = await this._httpClient.get(url, { responseType: 'blob' }).toPromise();
//     const zip = new JSZip();
//     const data = await zip.loadAsync(response);
//     const targetFiles = ['ss1.jpg', 'ss1a.jpg'];
//     const uploadResults = {};
//     for (const fileName of targetFiles) {
//         if (data.files[fileName]) {
//             const fileContent = await data.files[fileName].async('blob');
//             let modifiedFileName;
//             let downloadURL;
//             let tempUrl
//             // Determine the new file name based on the original file name
//             if (fileName === 'ss1.jpg') {
//                 modifiedFileName = `${targetFilename}_FullBody.jpg`;
//             } else if (fileName === 'ss1a.jpg') {
//                 modifiedFileName = `${targetFilename}_Portrait.jpg`;
//             } else {
//                 continue; // Skip if the file name does not match the expected names
//             }

//             // Check if both targetFolder and targetFilename are defined and not empty
//             if (targetFolder && targetFilename) {
//                 const firebasePath = `${targetFolder}/${modifiedFileName}`;
//                 const fileRef = this.storage.ref(firebasePath);

//                 // Upload to Firebase Storage with content type set to image/jpg
//                 await fileRef.put(fileContent, { contentType: 'image/jpg' });

//                 // Get public URL
//                 downloadURL = await fileRef.getDownloadURL();
//             } else {
//                 // If targetFolder or targetFilename is empty or undefined, set downloadURL to undefined
//                 downloadURL = undefined;
//             }

//             // Store URLs in result object
//             if (!modifiedFileName){ 
//               tempUrl =  fileContent, { contentType: 'image/jpg' }
//               uploadResults[tempUrl] = downloadURL;
//             }else
//             { 
//               uploadResults[modifiedFileName] = downloadURL;

//             }
//         }
//     }

//     return uploadResults;
// }


async downloadAndBrandAvatarDataZip(url: string, targetFolder: string, targetFilename: string): Promise<any> {
  const response = await this._httpClient.get(url, { responseType: 'blob' }).toPromise();
  const zip = new JSZip();
  const data = await zip.loadAsync(response);
  const targetFiles = ['ss1.jpg', 'ss1a.jpg'];
  const uploadResults = {};

  for (const fileName of targetFiles) {
      if (data.files[fileName]) {
          const fileContent = await data.files[fileName].async('blob');
          let modifiedFileName;
          let downloadURL;

          // Determine the new file name based on the original file name
          if (fileName === 'ss1.jpg') {
              modifiedFileName = `${targetFilename}_FullBody.jpg`;
          } else if (fileName === 'ss1a.jpg') {
              modifiedFileName = `${targetFilename}_Portrait.jpg`;
          } else {
              continue; // Skip if the file name does not match the expected names
          }

          // Check if both targetFolder and targetFilename are defined and not empty
          if (targetFolder && targetFilename) {
              const firebasePath = `${targetFolder}/${modifiedFileName}`;
              const fileRef = this.storage.ref(firebasePath);

              // Upload to Firebase Storage with content type set to image/jpg
              await fileRef.put(fileContent, { contentType: 'image/jpg' });

              // Get public URL
              downloadURL = await fileRef.getDownloadURL();

              // Store URLs in result object
              uploadResults[modifiedFileName] = downloadURL;
          } else {
              // If both targetFolder and targetFilename are undefined, save the blob buffer array
              const blobUrl = URL.createObjectURL(fileContent);
              uploadResults[blobUrl] = blobUrl; // Set download URL to null as we're not saving to Firebase Storage
          }
      }
  }

  return uploadResults;
}

processAvatarDataZip(url: string): Observable<any> {
  const functionurl = this.processAvatarData;
  // Setting up HttpParams
  const params = new HttpParams().set('url', url);
  return this._httpClient.get<any>(functionurl, { params });
}


}
