
<h2 mat-dialog-title class="text-center">
<mat-dialog-content class="mat-typography"  >
  <h4 class="text-center" ></h4>
  <model-viewer 
  camera-controls
  ar
  auto-rotate
  camera-orbit="45deg 55deg 2.5m"
  [src]="gltfImgSrc"
  alt="A 3D avatar-model-view"
  data-js-focus-visible=""
  quick-look
  ar-status="not-presenting"

>
</model-viewer>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button class="btn btn-outline-secondary  btn-sm" mat-dialog-close>Close</button>
</mat-dialog-actions>


