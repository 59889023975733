import { Component } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { ListResult } from '@angular/fire/compat/storage/interfaces';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Observable, map } from 'rxjs';
import { Brand } from 'src/app/models/brand';
import { CommonService } from 'src/app/services/common.service';
import { DownloadZipService } from 'src/app/services/download-zip.service';

@Component({
  selector: 'app-brand-avatar',
  templateUrl: './brand-avatar.component.html',
  styleUrls: ['./brand-avatar.component.css']
})
export class BrandAvatarComponent {
  groupBrands = this.firestore.collection('GroupBrands').valueChanges({ idField: 'id' }) as Observable<[]>;
  groupUser = this.firestore.collection('GroupUser').valueChanges({ idField: 'id' }) as Observable<[]>;
  groupRoleCollection = this.firestore.collection('GroupRole').valueChanges({ idField: 'id' }) as Observable<[]>;
  pageCollection = this.firestore.collection('Pages').valueChanges({ idField: 'id' }) as Observable<[]>;
  securityGrpCollection = this.firestore.collection('SecurityGroup').valueChanges({ idField: 'id' }) as Observable<[]>;
  brands = this.firestore.collection('brands').valueChanges({ idField: 'id' }) as Observable<Brand[]>;
  userAvatar = this.firestore.collection('UserAvatar').valueChanges({ idField: 'id' }) as Observable<Brand[]>;
  userBrandAvatar = this.firestore.collection('BrandAvatar').valueChanges({ idField: 'id' }) as Observable<Brand[]>;
  garmentsList = this.firestore.collection('garments').valueChanges({ idField: 'id' }) as Observable<any[]>;





  flagForShowThreeJs: boolean;
  functionflagValue: string;
  defaultEnvironment: any = 'https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/Environments%2FAdobeStock_209580496.jpeg?alt=media&token=34dad92c-ec1f-4b7d-a293-5443ab42fd78'

  envUrl: any = "";
  avatraUrls: any = "";
  garmenturl: any = "";
  garmentbottom: any = "";
  vrmAvtarUrl: any = "";
  genders: any = "";
  headgear: any = "";
  footwear: any = "";
  passId: any = "";
  avtarUrl: any = "";
  logInFlag: boolean;
  loading: boolean;
  groupBrandsList: any = [];
  garmentsCollection: any = [];
  userSecurityGrp: any = [];
  securityGroupList: any = [];
  selectedSecurityGroupName: any;
  userReferenceList = [];
  selectedSecGrpId: any;
  selectedSecGrpIdName: any;
  preferenceDocId: any;
  pageAll: any = [];
  userObject: any = {}
  selectedAssignSecGrpId: any;
  assignBrandList: any = [];
  brandList: any = [];
  userAvatarList: any = [];
  subcollections: any = [];
  userBrandAvatarList: any = [];
  userAvatarData: any = {};
  userAvatarDataList: any = [];
  filterAvatarList: any = [];
  userBrandAvatarFilterList: any[];
  selectedbrandId: any;
  selectedAvatarId: any;
  gender: any;
  avtarFlag: boolean = false;
  docIdToFind = "20240319191611150";
  data: any = [];
  categories: any = [];
  garmentLink: any;
  GarmentsGender: any;
  newDATA: any;
  selectedGEnder: any;
  avatrPreview: any = {};
  newblobData: any[] = [];
  flagforview: boolean;
  avatarFiles: any = {};
  blobUrls: any = {};


  constructor(public sanitizer: DomSanitizer, private storage: AngularFireStorage, public commonService: CommonService, private firestore: AngularFirestore, private downzipServices: DownloadZipService, public router: Router,) {
    this.checkPageAuthorization()

    this.groupBrands.subscribe((brandGp) => {
      this.groupBrandsList = brandGp
      console.log("this.groupBrandsList", this.groupBrandsList)
    })

    this.securityGrpCollection.subscribe((groupList: any) => {
      this.securityGroupList = groupList
    })

    this.brands.subscribe((brand) => {
      this.brandList = brand
    })

    this.userAvatar.subscribe((avatarData) => {
      this.userAvatarList = avatarData
    })

    this.userBrandAvatar.subscribe((avatarData) => {
      this.userBrandAvatarList = avatarData
    })

    this.garmentsList.subscribe((garments) => {
      this.garmentsCollection = garments;
    });

  }


  ngOnInit(): void {
    let title = 'Yologram - brand-avatar';
    this.commonService.changeTitle(title);
    this.checkPageAuthorization()
    this.logInFlag = this.commonService.isLoggedIn()
    const subcollectionId = '20240220143820663';
    this.commonService.findSubcollectionById(subcollectionId).then(parentDocId => {
      if (parentDocId) {
        console.log(`Subcollection is found in parent document with ID: ${parentDocId}`);
      } else {
        console.log('Subcollection not found.');
      }
    });

    if (!this.logInFlag) {
      this.router.navigateByUrl("/drip");
    }
  }

  callgpIdFunction(userObject) {
    this.getUserPreference(userObject.userid)
  }

  getSecurityGroupNameById(id) {
    let userSecGrp = this.userSecurityGrp.find((b: any) => b.SecGpId === id);
    let securityGroup = this.securityGroupList && this.securityGroupList.find((b: any) => b.id === id);
    this.selectedSecurityGroupName = userSecGrp != undefined && securityGroup['name'] != undefined ? securityGroup['name'] : "Select Group";
    console.log("this.selectedSecGrpId", this.selectedSecGrpId)
  }

  getUserPreference(id) {
    this.groupUser.subscribe((grpList) => {
      this.userSecurityGrp = grpList.filter((grp: any) => grp.UserId === id);
      this.firestore.collection("UserPreference").ref
        .where("Key", "==", "LastUserGroupSelected")
        .where("UserId", "==", id.toString())
        .get().
        then((objectList: any) => {
          this.userReferenceList = objectList.docs;
          if (objectList.docs.length > 0 && this.userSecurityGrp.length > 0) {
            objectList.forEach((doc) => {
              if (doc.data().UserId == id && this.userSecurityGrp.find(b => b.SecGpId == doc.data().KeyValue) != undefined
              ) {
                this.selectedSecGrpId = doc.data().KeyValue;
                this.preferenceDocId = doc.id;
                this.groupRoleCollection.subscribe(((groupRole: any) => {
                  let accessPageListselectGroup = groupRole.find((obj) => obj.SecGpId == this.selectedSecGrpId)
                  this.selectedAssignSecGrpId = accessPageListselectGroup.SecGpId
                  this.getAssignBrandList(this.selectedAssignSecGrpId)
                }))
              }
            });
          }
        }).catch((error) => {
          console.log("Error getting documents: ", error);
        });
    });
  }

  checkPageAuthorization() {
    this.loading = true;
    this.commonService.getUser().subscribe((user: any) => {
      let userObject = { 'userid': user?.uid, 'pagename': '/group-brands' }
      this.userObject = userObject
      this.callgpIdFunction(userObject)
      this.getAvatarSubcollections(userObject.userid)
      this.commonService.checkViewPageRights(userObject).subscribe((res) => {
        console.log(res)
        this.loading = false;
        if (!res) {
          this.router.navigateByUrl("/drip");
        }
      });
    });
  }


  getAssignBrandList(gpId) {
    this.assignBrandList = this.groupBrandsList.filter(b => b.SecGpId == gpId);

    let groupBrandsList = this.groupBrandsList.filter(grp => grp.SecGpId === gpId);

    this.userBrandAvatar.subscribe(avatarData => {
      this.userBrandAvatarList = avatarData;

      // Step 1: Filter based on BrandId matches.
      this.userBrandAvatarFilterList = [];
      groupBrandsList.forEach(groupBrand => {
        this.userBrandAvatarFilterList = this.userBrandAvatarFilterList.concat(
          this.userBrandAvatarList.filter(avatar => avatar.BrandId === groupBrand.BrandId)
        );
      });

      // Step 2: Append 'Default' avatars if they haven't been included yet.
      // First, check if default avatars are already in the filtered list to avoid duplicates.
      const hasDefault = this.userBrandAvatarFilterList.some(avatar => avatar.BrandId === 'Default');
      if (!hasDefault) {
        const defaultAvatars = this.userBrandAvatarList.filter(avatar => avatar.BrandId === 'Default');
        this.userBrandAvatarFilterList = this.userBrandAvatarFilterList.concat(defaultAvatars);
      }
    });
  }



  getBrandDetails(id) {
    let brand = this.brandList.find((brand) => brand.id == id)
    return brand
  }

  async getuserAvatarDetails(object) {
    try {
      let parentId = object.CreatedBy;
      let subId = object.AvatarId;
      let data = await this.commonService.getFirstDocumentFromSubcollection(parentId, subId);
      return data.AvatarPreviewPortraitUrl; // Return the data object
    } catch (error) {
      console.error("Error getting user avatar details:", error);
      throw error;
    }
  }




  async getAvatraDetails(id) {
    let dummyId = id
    let data = await this.commonService.getUserAvatarDocumentById(dummyId)
    console.log("data", data)
  }


  getAvatarSubcollections(id) {
    const docId = id; // Use the actual document ID here
    this.commonService.listSubcollections(docId)
      .then(subcollections => {
        this.subcollections = subcollections
        this.getDocumentDetails(subcollections, id)
      })
      .catch(error => {
        console.error('Error fetching subcollections:', error);
      });
  }


  async getDocumentDetails(subcollectionIds: any[], id) {
    try {
      // Step 1: Get the list of subcollection IDs.
      let avatarData = {};
      let avatarDataAll = []
      // Step 2: For each subcollection ID, get the documents and add them to `avatarData`.
      await this.subcollections.forEach(async (doc) => {
        await this.commonService
          .getDocumentsFromSubcollection(id, doc)
          .then((documents) => {
            // console.log("this.id, this.avatarID", dummyId,doc)
            if (documents && documents.length > 0) {
              const firstDocument = documents[0]; // Assuming there is only one document
              firstDocument.avatarID = doc
              avatarData = firstDocument;
              avatarDataAll.push(avatarData)
              // this.openNews3DPassValue()
            }
          })
        this.userAvatarDataList = avatarDataAll
        // console.log(this.userAvatarDataList)
      })

      console.log(avatarData);
    } catch (error) {
      console.error('Error in component:', error);
    }
  }

  filterByGender($event) {
    this.gender = $event.target.value
    this.filterAvatarList = this.userAvatarDataList.filter((avatar) => avatar.Gender == this.gender && avatar.isDeleted == false)
    this.avatrPreview = {}
    this.newblobData = []
    this.avtarFlag = false

  }



  filterAvatarname(id) {
    let filterAvatarList = this.userAvatarDataList.find((avatar) => avatar.avatarID == id);
    // Return the found avatar or a default object with an AvatarName
    return filterAvatarList ? filterAvatarList : { AvatarName: ' ' };
  }


  getfilterBrandId($event) {
    this.selectedbrandId = $event.target.value
    this.avatrPreview = {}
    this.newblobData = []
    this.avtarFlag = false


  }

  getfilterAvatrId($event) {
    this.selectedAvatarId = $event.target.value
    this.newDATA = this.userAvatarDataList.find((avatar) => avatar.avatarID == this.selectedAvatarId)
    this.avatrPreview = {}
    this.newblobData = []
    this.avtarFlag = false
  }


  async addAvatraDetailsInCollection() {
    this.userAvatarData = this.newDATA
    console.log("this.userAvatarData", this.userAvatarData)
    if (this.userAvatarData) {
      let newObject = {
        'BrandId': this.selectedbrandId,
        'Gender': this.gender,
        'AvatarId': this.selectedAvatarId,
        'CreatedBy': this.userObject.userid,
        'url': this.userAvatarData.AvatarURL
      };

      this.loading = true;
      (await this.commonService.saveAvatarData(newObject)).subscribe({
        next: (response) => {
          console.log('Response:', response);
          this.loading = false
        },
        error: (error) => {
          console.error('Error:', error);
        }
      });

    }

  }

  // addAvatraDetailsInCollection() {

  //   let newObject = {
  //     'BrandId': this.selectedbrandId,
  //     'Gender': this.gender,
  //     'AvatarId': this.selectedAvatarId,
  //     'CreatedBy': this.userObject.userid
  //   };


  //   // Check if newObject properties are defined
  //   if (!newObject.BrandId || !newObject.Gender || !newObject.AvatarId) {
  //     alert('Please make sure all selections are made.');
  //     return;
  //   }

  //   // Now using AngularFire's syntax for queries
  //   this.firestore.collection('BrandAvatar', ref => ref
  //     .where('BrandId', '==', newObject.BrandId)
  //     .where('Gender', '==', newObject.Gender)
  //     .where('AvatarId', '==', newObject.AvatarId))
  //     .get()
  //     .subscribe(querySnapshot => {
  //       if (querySnapshot.empty) {
  //         // Document doesn't exist, safe to add
  //         this.firestore.collection('BrandAvatar').add(newObject).then(() => {
  //           console.log('New Avatar details added successfully.');
  //           // Optionally update local data or UI here
  //         }).catch(error => {
  //           console.error('Error adding Avatar details:', error);
  //           alert('There was an error adding the Avatar details. Please try again.');
  //         });
  //       } else {
  //         // Document exists, handle duplicate
  //         alert('This Avatar detail already exists.');
  //       }
  //     }, error => {
  //       console.error('Error querying for existing Avatar details:', error);
  //       alert('There was an error checking for existing Avatar details. Please try again.');
  //     });

  //     this.unzipAndSave(this.avtarUrl)
  //     this.avatrPreview = this.userBrandAvatarFilterList.find((avatar) => avatar.Gender == this.gender && avatar.BrandId == this.selectedbrandId
  //     &&  avatar.AvatarId == this.selectedAvatarId )
  //     console.log('avatrPreview',this.avatrPreview)
  // }

  // deleteUserAvatarDetails(brand) {
  //   // Validation: Check if the brand object has an id
  //   if (!brand || !brand.id) {
  //     alert('Invalid brand details provided. Cannot delete.');
  //     return;
  //   }

  //   // Confirmation: Ask the user to confirm the deletion
  //   const confirmation = confirm(`Are you sure you want to delete .`);

  //   if (confirmation) {
  //     // User confirmed the deletion, proceed with the delete operation
  //     this.firestore.collection('BrandAvatar').doc(brand.id).delete()
  //       .then(() => {
  //         // Handle successful deletion, maybe refresh the list or navigate away
  //         console.log(`Successfully deleted brand with id: ${brand.id}`);
  //       })
  //       .catch((error) => {
  //         // Handle errors, such as showing a user-friendly message
  //         console.error('Error deleting brand:', error);
  //         alert('An error occurred while deleting the brand. Please try again.');
  //       });
  //   } else {
  //     // User did not confirm the deletion, do not proceed
  //     console.log('Deletion cancelled by the user.');
  //   }
  // }

  // async deleteUserAvatarDetails(brand) {
  //     // Validation: Check if the brand object has an id
  //     if (!brand || !brand.id) {
  //         alert('Invalid brand details provided. Cannot delete.');
  //         return;
  //     }

  //     try {
  //         // Confirmation: Ask the user to confirm the deletion
  //         const confirmation = confirm(`Are you sure you want to delete?`);

  //         if (!confirmation) {
  //             // User did not confirm the deletion, do not proceed
  //             console.log('Deletion cancelled by the user.');
  //             return;
  //         }

  //         // Delete the Firestore document
  //         await this.firestore.collection('BrandAvatar').doc(brand.id).delete();

  //         // Delete associated storage files
  //         const storage =  this.storage;
  //         const storageRef = storage;

  //         // Assuming the URLs are stored in the brand object
  //         const urlsToDelete = [brand.BrandAvatarUrl];

  //         for (const url of urlsToDelete) {
  //             // Extract the path from the URL
  //             const path = decodeURIComponent(url.split('/o/')[1].split('?')[0]);
  //             console.log("path",path)

  //             // Delete the file
  //             await storageRef.ref(path).delete();
  //             console.log(`Deleted storage file: ${path}`);
  //         }
  //              let deletefolder = "BrandAvatar/"+ brand.BrandId+ "_"+brand.AvatarId+"/"

  //             await storageRef.ref(deletefolder).delete();

  //         console.log(`Successfully deleted brand with id: ${brand.id}`);
  //         alert('Brand deleted successfully.');
  //     } catch (error) {
  //         // Handle errors
  //         console.error('Error deleting brand:', error);
  //         alert('An error occurred while deleting the brand. Please try again.');
  //     }
  // }

  // async deleteUserAvatarDetails(brand) {
  //   // Validation: Check if the brand object has an id
  //   if (!brand || !brand.id) {
  //       alert('Invalid brand details provided. Cannot delete.');
  //       return;
  //   }

  //   try {
  //       // Confirmation: Ask the user to confirm the deletion
  //       const confirmation = confirm(`Are you sure you want to delete?`);

  //       if (!confirmation) {
  //           // User did not confirm the deletion, do not proceed
  //           console.log('Deletion cancelled by the user.');
  //           return;
  //       }

  //       // Delete the Firestore document
  //       await this.firestore.collection('BrandAvatar').doc(brand.id).delete();

  //       // Delete associated storage files
  //       const storage = this.storage;
  //       const storageRef =storage

  //       // Assuming the URLs are stored in the brand object
  //       const urlsToDelete = [brand.BrandAvatarUrl];

  //       for (const url of urlsToDelete) {
  //           // Extract the path from the URL
  //           const path = decodeURIComponent(url.split('/o/')[1].split('?')[0]);
  //           console.log("path", path);

  //           // Delete the file
  //           await storageRef.child(path).delete();
  //           console.log(`Deleted storage file: ${path}`);
  //       }

  //       // Delete all files in the folder
  //       const folderPath = `BrandAvatar/${brand.BrandId}_${brand.AvatarId}/`;
  //       const listAllFiles = await storageRef.child(folderPath).listAll();

  //       // Map the file deletions
  //       const deletePromises = listAllFiles.items.map((fileRef) => fileRef.delete());

  //       // Wait for all deletions to complete
  //       await Promise.all(deletePromises);

  //       console.log(`Successfully deleted all files in folder: ${folderPath}`);
  //       alert('Brand deleted successfully.');
  //   } catch (error) {
  //       // Handle errors
  //       console.error('Error deleting brand:', error);
  //       alert('An error occurred while deleting the brand. Please try again.');
  //   }
  // }


  async deleteUserAvatarDetails(brand) {
    // Validation: Check if the brand object has an id
    if (!brand || !brand.id) {
      alert('Invalid brand details provided. Cannot delete.');
      return;
    }

    try {
      // Confirmation: Ask the user to confirm the deletion
      const confirmation = confirm(`Are you sure you want to delete?`);

      if (!confirmation) {
        // User did not confirm the deletion, do not proceed
        console.log('Deletion cancelled by the user.');
        return;
      }

      // Delete the Firestore document
      await this.firestore.collection('BrandAvatar').doc(brand.id).delete();

      // Delete associated storage files
      const storageRef = this.storage.ref("BrandAvatar");

      // Assuming the URLs are stored in the brand object
      const urlsToDelete = [brand.BrandAvatarUrl];

      for (const url of urlsToDelete) {
        // Extract the path from the URL
        const path = decodeURIComponent(url.split('/o/')[1].split('?')[0]);
        console.log("path", path);

        // Delete the file
        await this.storage.refFromURL(url).delete().toPromise();
        console.log(`Deleted storage file: ${path}`);
      }

      // Delete all files in the folder
      const folderPath = `/UnzippedFiles/${brand.BrandId}_${brand.AvatarId}/`;
      const folderRef = storageRef.child(folderPath);

      // List all items (files and subdirectories) under the folder
      const listResult: ListResult = await this.listAll(folderRef).toPromise();

      // Map the file deletions
      const deletePromises = listResult.items.map((fileRef) => fileRef.delete());

      // Wait for all deletions to complete
      await Promise.all(deletePromises);

      console.log(`Successfully deleted all files in folder: ${folderPath}`);
      alert('Brand deleted successfully.');
    } catch (error) {
      // Handle errors
      console.error('Error deleting brand:', error);
      alert('An error occurred while deleting the brand. Please try again.');
    }
  }

  listAll(ref: any): Observable<ListResult> {
    return new Observable(observer => {
      ref.listAll().subscribe({
        next: (result) => {
          observer.next(result);
          observer.complete();
        },
        error: (error) => observer.error(error)
      });
    });
  }



  async avatarOpen() {
    this.avatrPreview = {}
    this.blobUrls = {}
    this.avtarFlag = true
    this.flagforview = true
    this.userAvatarData = this.newDATA
    setTimeout(() => {
      this.openNews3DPassValue();
    }, 1000);
    await this.fetchAvatarData(this.userAvatarData.AvatarURL)

    // this.newblobData =  await this.downzipServices.downloadAndBrandAvatarDataZip( this.userAvatarData.AvatarURL, '','');
  }

  fetchDataall() {
    this.commonService.fetchData().subscribe(categories => {
      this.categories = categories;
      console.log(this.categories);
    });
  }

  getfilterNew(avatarOpen) {
    this.blobUrls = {}
    this.flagforview = false
    this.userAvatarData = avatarOpen
    this.openNews3DPassValue()
  }

  async getDocumentDetailsAll(snapShots) {
    snapShots.avatarName = ' ';
    await this.commonService
      .getDocumentsFromSubcollection(snapShots.CreatedBy, snapShots.AvatarId)
      .then((documents) => {
        if (documents && documents.length > 0) {
          const firstDocument = documents[0]; // Assuming there is only one document
          snapShots.avatarName = firstDocument.AvatarName;

        }
      })
      .catch((error) => {
        console.error('Error in component:', error);
        throw error; // You can choose to re-throw the error or handle it here
      });
    // this.userAvatarDat(snapShots)
  }


  async openNews3DPassValue() {

    this.avtarFlag = true

    console.log('calleing.. this.userAvatarData', this.userAvatarData)
    if (this.userAvatarData) {
      this.genders = this.userAvatarData.Gender == 'MALE' ? 'M' : 'F';
      console.log('calleing....')
      this.avtarUrl = this.userAvatarData.AvatarURL;
      console.log('this.avtarUrl', this.userAvatarData);
      this.garmenturl = this.copyTextToClipGarment(this.userAvatarData.cUpperwearID);
      this.garmentbottom = this.copyTextToClipGarment(this.userAvatarData.cBottomwearID);;
      this.headgear = this.copyTextToClipGarment(this.userAvatarData.cHeadgearID);
      this.footwear = this.copyTextToClipGarment(this.userAvatarData.cFootwearID);
      this.flagForShowThreeJs = true;
      console.log('called....', this.garmenturl, this.garmentbottom, this.headgear, this.footwear)
      console.log('genders....', this.genders)
      this.functionflagValue = 'LoadOnlyAvatar_' + this.avtarUrl;
    }
  }

  calledVlueIntilized(event) {
    let value = event
    if (value == "Initialized") {

    }
  }

  async passAvatar(data) {
    console.log("data", data)
    this.userAvatarData = {};
    this.avatrPreview = {}
    this.blobUrls = null
    this.flagforview = false

    await this.commonService
      .getDocumentsFromSubcollection(data.CreatedBy, data.AvatarId)
      .then((documents) => {
        if (documents && documents.length > 0) {
          const firstDocument = documents[0]; // Assuming there is only one document
          this.userAvatarData = firstDocument;
          setTimeout(() => {
            this.openNews3DPassValue();
          }, 1000);
          this.avatrPreview = this.userBrandAvatarFilterList.find((avatar) => avatar.Gender == data.Gender &&
            avatar.BrandId == data.BrandId
            && avatar.AvatarId == data.AvatarId)
          console.log('avatrPreview', this.avatrPreview)
          console.log("this.id, this.avatarID", data.CreatedBy, data.AvatarId)
        }
      })
      .catch((error) => {
        console.error('Error in component:', error);
        throw error; // You can choose to re-throw the error or handle it here
      });
  }

  copyTextToClipGarment(text: string) {
    let Garments = this.garmentsCollection.find(
      (garmentList) => garmentList.id === text
    );
    if (Garments) {
      let brandId = Garments.BrandID;
      let gender = Garments.Gender;
      this.GarmentsGender = Garments.Gender;
      let garmentType = Garments.GarmentType;
      let zipfilename = Garments.id;
      // alert(zipfilename)
      let packlocation =
        'AvatarBlenderClothes%2F' +
        brandId +
        '_' +
        gender +
        '%2F' +
        garmentType +
        '%2F' +
        zipfilename;
      this.garmentLink =
        'https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/' +
        packlocation +
        '?alt=media&token=2c1dc261-ac91-4c32-a5ec-ac5e06f0a9bc&_gl=1*goch74*_ga*MjExMjI9MTIyMi4xNjg3NTA3MDk1*_ga_CW55HF8NVT*MTY5NjkzMDY1Ny4xNTcuMS4xNjk2OTM2NzA4LjU5LjAuMA..';
      console.log(' this.garmentLink', this.garmentLink)
      return this.garmentLink;
    } else {
      // Handle the case when Garments is not found
      // console.error('Garments not found for id: ' + text);
      return null; // or return an appropriate value or throw an error
    }
  }



  async unzipAndSave(url) {
    let targetPathPreview = 'AvatarPreview'
    let newurl
    const fullPath = `${targetPathPreview}`;
    let fileName = this.selectedbrandId + '_' + this.selectedAvatarId
    const targetPath = '/BrandAvatar/' + fileName; // Path in Firebase where the file will be stored
    this.commonService.uploadFileFromUrl(url, this.selectedbrandId, this.selectedAvatarId).subscribe(
      downloadURL => {
        console.log('Download URL:', downloadURL);
        // let newentry = this.userBrandAvatarList.find((a ) =>a.BrandId ==  this.selectedbrandId && a.AvatarId == this.selectedAvatarId)
        // let brandAvatrUrl  = { 
        //   BrandAvatarUrl : downloadURL
        // }
        // this.newADDEntry(downloadURL,fullBody,portrat)
        newurl = downloadURL
        // Do something with the download URL

        let fullBody = 'https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/AvatarPreview%2F' + fileName + '_FullBody.jpg?alt=media&token=bef01c11-b8dc-476d-b72f-d968b8319c35'
        let portrat = 'https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/AvatarPreview%2F' + fileName + '_Portrait.jpg?alt=media&token=bef01c11-b8dc-476d-b72f-d968b8319c35'
        // this.newPrviewEntry(fullBody,portrat)
        if (newurl) {
          this.newADDEntry(newurl, fullBody, portrat)
        }

      },
      error => {
        console.error('Error uploading file:', error);
      }
    );

    const zipData = await this.downzipServices.downloadAndBrandAvatarDataZip(url, fullPath, fileName);
    console.log('new data', zipData)

  }

  newADDEntry(brandAvatarUrl: string, fullAvatarUrl: string, potratAvatarUrl: string): void {
    console.log('New  potratAvatarUrl.', brandAvatarUrl, fullAvatarUrl, potratAvatarUrl);
    this.firestore.collection('BrandAvatar', ref => ref
      .where('BrandId', '==', this.selectedbrandId)
      .where('AvatarId', '==', this.selectedAvatarId)
      .where('Gender', '==', this.gender)
    ).get().toPromise().then(querySnapshot => {
      if (querySnapshot.empty) {
        // Document doesn't exist, add a new one
        this.firestore.collection('BrandAvatar').add({
          BrandAvatarUrl: brandAvatarUrl,
          BrandId: this.selectedbrandId,
          AvatarId: this.selectedAvatarId,
          Gender: this.gender,
          CreatedBy: this.userObject.userid,
          FullBodyPreviewUrl: fullAvatarUrl,
          PortraitPreviewUrl: potratAvatarUrl,
        }).then(() => {
          console.log('New Avatar details added successfully.');
          // Optionally update local data or UI here
        }).catch(error => {
          console.error('Error adding Avatar details:', error);
          alert('There was an error adding the Avatar details. Please try again.');
        });
      } else {
        // Document exists, update the existing one
        querySnapshot.forEach(doc => {
          doc.ref.set({ BrandAvatarUrl: brandAvatarUrl, FullBodyPreviewUrl: fullAvatarUrl, PortraitPreviewUrl: potratAvatarUrl, }, { merge: true }).then(() => {
            console.log('Avatar details updated successfully.');
            // Optionally update local data or UI here
          }).catch(error => {
            console.error('Error updating Avatar details:', error);
            alert('There was an error updating the Avatar details. Please try again.');
          });
        });
      }
    }).catch(error => {
      console.error('Error querying for existing Avatar details:', error);
      alert('There was an error checking for existing Avatar details. Please try again.');
    });
  }

  // newPrviewEntry(fullAvatarUrl: string,potratAvatarUrl: string): void {
  //   this.firestore.collection('BrandAvatar', ref => ref
  //     .where('BrandId', '==', this.selectedbrandId)
  //     .where('AvatarId', '==', this.selectedAvatarId)
  //     .where('Gender', '==', this.gender)
  //   ).get().toPromise().then(querySnapshot => {
  //     if (querySnapshot.empty) {
  //       // Document doesn't exist, add a new one
  //       this.firestore.collection('BrandAvatar').add({
  //         FullBodyPreviewUrl: fullAvatarUrl,
  //         PortraitPreviewUrl: potratAvatarUrl,
  //         BrandId: this.selectedbrandId,
  //         AvatarId: this.selectedAvatarId,
  //         Gender: this.gender
  //       }).then(() => {
  //         console.log('New Avatar details added successfully.');
  //         // Optionally update local data or UI here
  //       }).catch(error => {
  //         console.error('Error adding Avatar details:', error);
  //         alert('There was an error adding the Avatar details. Please try again.');
  //       });
  //     } else {
  //       // Document exists, update the existing one
  //       querySnapshot.forEach(doc => {
  //         doc.ref.set({ FullBodyPreviewUrl: fullAvatarUrl , PortraitPreviewUrl: potratAvatarUrl}, { merge: true }).then(() => {
  //           console.log('Avatar details updated successfully.');
  //           // Optionally update local data or UI here
  //         }).catch(error => {
  //           console.error('Error updating Avatar details:', error);
  //           alert('There was an error updating the Avatar details. Please try again.');
  //         });
  //       });
  //     }
  //   }).catch(error => {
  //     console.error('Error querying for existing Avatar details:', error);
  //     alert('There was an error checking for existing Avatar details. Please try again.');
  //   });
  // }


  async fetchAvatarData(url) {
    try {
      const dataAvatarBlob = await this.downzipServices.processAvatarDataZip(url).toPromise();
      // Define specific files to process as Blob URLs
      console.log("dataAvatarBlob", dataAvatarBlob)
      const specialFiles = ['ss1.jpg', 'ss1a.jpg'];
      Object.keys(dataAvatarBlob).forEach(key => {
        if (specialFiles.includes(key)) {
          // Handling specific image files and creating Blob URLs only for them
          const blob = this.base64ToBlob(dataAvatarBlob[key], `image/${key.split('.').pop()}`);
          this.blobUrls[key] = URL.createObjectURL(blob);
        } else {
          // Handling non-image files (e.g., JSON)
          this.avatarFiles[key] = this.convertBase64ToArrayBuffer(dataAvatarBlob[key]);
        }
      });

      console.log('Special Blob URLs:', this.blobUrls);  // Log only special blob URLs
      console.log('Other avatar files:', this.avatarFiles);
    } catch (error) {
      console.error('Error fetching avatar data:', error);
    }
  }


  base64ToBlob(base64: string, mimeType: string): Blob {
    const byteCharacters = atob(base64);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    return new Blob(byteArrays, { type: mimeType });
  }

  convertBase64ToArrayBuffer(base64: string): ArrayBuffer {
    const binaryString = window.atob(base64);
    const length = binaryString.length;
    const bytes = new Uint8Array(new ArrayBuffer(length));
    for (let i = 0; i < length; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer;
  }

  objectKeys(obj: any): string[] {
    return Object.keys(obj);
  }


  // async userAvatarDat(brand) {
  //   try {
  //     const firstDocument = await this.commonService.getFirstDocumentFromSubcollection(brand.CreatedBy, brand.AvatarId);
  //     console.log('firstDocument.AvatarPreviewPortraitUrl', firstDocument.AvatarPreviewPortraitUrl);
  //     return firstDocument.AvatarPreviewPortraitUrl;
  //   } catch (error) {
  //     console.error('Error in component:', error);
  //     throw error;
  //   }
  // }
}
