import { DatePipe } from '@angular/common';
import { Component, VERSION, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/compat/storage';
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';
import { Observable, tap, finalize, switchMap, from, fromEvent, takeUntil } from 'rxjs';
import { CommonService } from 'src/app/services/common.service';
import { generateUUID } from 'three/src/math/MathUtils';
import { v4 as uuidv4 } from 'uuid';
@Component({
  selector: 'app-test-lottieanimation',
  templateUrl: './test-lottieanimation.component.html',
  styleUrls: ['./test-lottieanimation.component.css']
})
export class TestLottieanimationComponent {
  options: AnimationOptions = {
    path: './assets/a.json',
  };
  @ViewChild('lottie', { static: true }) lottie: TestLottieanimationComponent;
  // public lottieConfig: AnimationOptions;
  // public animationProgress: number = 0;
  // private animationItem: AnimationItem;
  // logInFlag: boolean;

  // file!: File;
  // task!: AngularFireUploadTask;
  // percentage!: Observable<number | undefined>;
  // snapshot!: Observable<any>;
  // fileUrl: any = '';
  // urlPromisesData: any = [];

  isDragging = false;
  startX: number = 0; // Initialize startX to 0
  downloadUrls$: Observable<string[]>;
  constructor(public commonService: CommonService, private db: AngularFirestore, private storage: AngularFireStorage, public datepipe: DatePipe) {
    // this.logInFlag = this.commonService.isLoggedIn()
    // this.lottieConfig = {
    //   path: '/assets/a.json',
    //   renderer: 'svg',
    //   autoplay: false,
    //   loop: false
    // };
  };
//   ngOnInit(): void {
//     this.lottieDropdown()
//   };
//   animationCreated(animationItem: AnimationItem): void {
//     this.animationItem = animationItem;
//     // this.initTouchEvents()
//   }
//   onSliderChange(): void {
//     if (this.animationItem) {
//       const goToFrame = this.animationItem.totalFrames * this.animationProgress;
//       console.log("deltaX",goToFrame)
//       this.animationItem.goToAndStop(goToFrame, true);
//     }
//   }
//   lottieDropdown() {
//     console.log('populateMixamoAnimationDropdown called');
//     const dirRef = this.storage.ref('Snapshots/lottiefiles');
//     this.downloadUrls$ = dirRef.listAll().pipe(
//       switchMap(response => {
//         const urlPromises = response.items.map(item => item.getDownloadURL());
//         this.urlPromisesData = response.items.map(item => item.name);
//         console.log('urlPromises', this.urlPromisesData);
//         return from(Promise.all(urlPromises));
//       })
//     );
//   }
//   selectLottieUrl(event) {
//     this.lottieConfig = {  }
//     let url = event.target.value;
//     console.log('url', url);
//     this.lottieConfig = {
//       path: url,
//       autoplay: false,
//       loop: true
//     };
//   }



//   onMove(event: MouseEvent | TouchEvent) {
//     if (this.animationItem) {
//         const rangeMax = this.animationItem.totalFrames;
//         let progress = 0;
//         let clientX = 0;

//         if (event instanceof MouseEvent) {
//             clientX = event.clientX;
//         } else if (event instanceof TouchEvent) {
//             clientX = event.touches[0].clientX;
//         }

//         if (clientX <= 250) {
//             // First half of the container
//             progress = clientX / 250;
//         } else {
//             // Second half of the container
//             progress = (clientX - 250) / 250;
//         }

//         progress = Math.min(Math.max(progress, 0), 1);
//         let frame = Math.round(rangeMax * progress);
//         // frame = ((frame + 1) % rangeMax) || 1;
//         frame %= rangeMax
//         // Shift frame index if it calculates to 0, given no desired frame is at 0
//         if (frame === 0 && rangeMax > 1) {
//             frame = 1;
//         }



//         this.animationItem.goToAndStop(frame, true);
//         console.log("Current Frame", frame);
//     }
// }

  
  



}

