


<div class="model-v" *ngIf="!loading" >
<model-viewer
  camera-controls
  camera-orbit="45deg 55deg 2.5m"
  ar
  auto-rotate
  src="https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/RuntimeAvatarSkinner%2FSource%2F3_max.gltf?alt=media&token=a5f411b5-e513-4f9d-b8d1-743359f6f1e5"
  alt="A 3D avatar-model-view"
  data-js-focus-visible=""
  ar-status="not-presenting"
>

</model-viewer>
<model-viewer
  camera-controls
  camera-orbit="45deg 55deg 2.5m"
  ar
  auto-rotate
  src="assets/5_min.gltf"
  alt="A 3D avatar-model-view"
  data-js-focus-visible=""
  ar-status="not-presenting"
>
</model-viewer>
<model-viewer
  camera-controls
  ar
  auto-rotate
  camera-orbit="45deg 55deg 2.5m"
  src="https://firebasestorage.googleapis.com/v0/b/yolouploader.appspot.com/o/RuntimeAvatarSkinner%2FSource%2F10_min%20(5).gltf?alt=media&token=2f457232-e567-4a58-b2a9-14cdf2a0e3ef"
  alt="A 3D avatar-model-view"
  data-js-focus-visible=""
  ar-status="not-presenting"
>
</model-viewer>

</div>

<div *ngIf="loading" class="loading">&#8230;</div>
