import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-infinite-scroller-cloth',
  templateUrl: './infinite-scroller-cloth.component.html',
  styleUrls: ['./infinite-scroller-cloth.component.css']
})
export class InfiniteScrollerClothComponent {
  @ViewChild('scrollContainer') scrollContainer: ElementRef;
  @ViewChild('brandStoriyScrollA', { static: false }) scrollContainerMUpper: ElementRef;
  @Input() packJsonObject: any = [];

  items: string[] = [];
  private numberOfItemsToDisplay: number = 3; //

  @Input() zipList: any = {};
  images: any;
  constructor(public sanitizer: DomSanitizer,
    ){
      // this.setupInfiniteScroll();

  }

  ngOnInit() {
     setTimeout(() => {
    this. setupInfiniteScroll()
  }, 1000)
  }

  ngAfterViewInit() {
    // Append the first image to the end to start the infinite loop
    // this.packJsonObject.push(this.packJsonObject[0]);
  }

  // private setupInfiniteScroll(): void {
  //   const slider = this.scrollContainerMUpper.nativeElement;
  //   const moveElement = () => {
  //     // Use requestAnimationFrame for smoother visual updates
  //     requestAnimationFrame(() => {
  //       const firstChild = slider.firstElementChild as HTMLElement;
  //       const lastChild = slider.lastElementChild as HTMLElement;
  
  //       // Calculate the buffer based on the child width
  //       const childWidth = firstChild.offsetWidth;
  //       const buffer = childWidth * 2; // Adjust the buffer size as needed
  
  //       if (slider.scrollLeft + buffer >= slider.scrollWidth - slider.offsetWidth) {
  //         // Move the first child to the end and adjust scrollLeft
  //         slider.appendChild(firstChild);
  //         slider.scrollLeft -= firstChild.offsetWidth; // Adjust instantly without animation
  //       } else if (slider.scrollLeft <= buffer) {
  //         // Move the last child to the beginning and adjust scrollLeft
  //         slider.insertBefore(lastChild, firstChild);
  //         slider.scrollLeft += lastChild.offsetWidth; // Adjust instantly without animation
  //       }
  //     });
  //   };

  
  //   // Throttle scroll events
  //   let isScrolling;
  //   const onScroll = () => {
  //     window.clearTimeout(isScrolling);
  //     isScrolling = setTimeout(moveElement, 0); // Increase the timeout to delay the element movement
  //   };
  
  //   // Add smooth scrolling CSS transition if not already present
  //   slider.style.transition = 'scroll-left 0.1s ease-out';
  //   slider.addEventListener('scroll', onScroll);
  // }



  private setupInfiniteScroll(): void {
    
    const slider = this.scrollContainerMUpper.nativeElement;
    const moveElement = () => {
      // Use requestAnimationFrame for smoother visual updates
      requestAnimationFrame(() => {
        const firstChild = slider.firstElementChild as HTMLElement;
        const lastChild = slider.lastElementChild as HTMLElement;
        // Calculate the buffer based on the child width
        const childWidth = firstChild.offsetWidth;
        const buffer = childWidth * 2; // Adjust the buffer size as needed
        if (slider.scrollLeft + buffer >= slider.scrollWidth - slider.offsetWidth) {
          // Move the first child to the end and adjust scrollLeft
          slider.appendChild(firstChild);
          slider.scrollLeft -= firstChild.offsetWidth; // Adjust instantly without animation
        } else if (slider.scrollLeft <= buffer) {
          // Move the last child to the beginning and adjust scrollLeft
          slider.insertBefore(lastChild, firstChild);
          slider.scrollLeft += lastChild.offsetWidth; // Adjust instantly without animation
        }
      });
    };

  
    // Throttle scroll events
    let isScrolling;
    const onScroll = () => {
      window.clearTimeout(isScrolling);
      isScrolling = setTimeout(moveElement, 0); // Increase the timeout to delay the element movement
    };
    // Add smooth scrolling CSS transition if not already present
    slider.style.transition = 'scroll-left 0.1s ease-out';
    slider.addEventListener('scroll', onScroll);

  }
  

}


