import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { Observable ,  throwError } from 'rxjs';

import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root',
})


export class ApiHttpService {
  createPinterestPin: any;

  constructor(
    private http: HttpClient,
  ) {}

  private formatErrors(error: any) {
    return  throwError(error.error);
  }

  get(path: string, params: HttpParams = new HttpParams()): Observable<any> {
    return this.http.get(`${path}`, { params })
      .pipe(catchError(this.formatErrors));
  }

  put(path: string, body: Object = {}): Observable<any> {
    return this.http.put(
      `${path}`,
      JSON.stringify(body)
    ).pipe(catchError(this.formatErrors));
  }

  post(path: string, body: Object = {}): Observable<any> {
    const headers = { 'Content-Type':'application/json','Authorization': 'Bearer my-token', 'My-Custom-Header': 'foobar' }
//     const headers = { 'Authorization': 'Bearer my-token', 'My-Custom-Header': 'foobar' }
     return this.http.post<any>(`${path}`, body, { headers })
  // this.http.post(
    //   `${environment.EduAppWebApiUrl}${path}`,
    //   body
    // 
    .pipe(catchError(this.formatErrors));
  }

  getPinterestAPI(path: string, params: HttpParams = new HttpParams()): Observable<any> {
  //  const headers = { 'Content-Type':'application/x-www-form-urlencoded','Authorization': 'Bearer '+environment.pininteresttoken,}

    return this.http.get(`${path}`,{})
      .pipe(catchError(this.formatErrors));
  }


  createPinpost(path: string, body: Object = {}): Observable<any> {
    const headers = { 'Content-Type':'application/json','Authorization': 'Bearer my-token', 'My-Custom-Header': 'foobar' }
//     const headers = { 'Authorization': 'Bearer my-token', 'My-Custom-Header': 'foobar' }
     return this.http.post<any>(`${path}`, body, { headers })
  // this.http.post(
    //   `${environment.EduAppWebApiUrl}${path}`,
    //   body
    // 
    .pipe(catchError(this.formatErrors));
  }


  isLoggedIn(): boolean {
    console.log(localStorage,"tocken")
    return !!localStorage.getItem('userToken');
  }
}
