import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonService } from 'src/app/services/common.service';
import { DataSharingService } from 'src/app/services/data-sharing-service';
import { NavigationEnd, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AngularFirestore } from '@angular/fire/compat/firestore';

@Component({
  selector: 'app-user-popup',
  templateUrl: './user-popup.component.html',
  styleUrls: ['./user-popup.component.css']
})
export class UserPopupComponent implements OnInit {
  userFilesList = this.firestore.collection('userfiles').valueChanges({ idField: 'id' }) as Observable<any[]>;
  user = this.firestore.collection('user-profile').valueChanges({ idField: 'id' }) as Observable<any[]>;

  newArray: any = [];
  @Input() UserShapShotObject: any = {};
  @Input() AvatarID: string;
  @Input() UserID: any | undefined;
  @Input() userData: any;
  @Input() rootFlag:  string | undefined;
  @Input() userProfile: string;

  allUserSnapDetails: any = []
  AllDetails: any = []
  userImfo: any = []
  selectedSanp: any = []
  allUserDetails: any = []
  userFiledetails: any = []
  SnapShotFileDetails: any = []
  filelist: any = []
  userDetails: any = []
  userUploadedFileList: any = []
  userDetail: any = []
  userAllDetails : any = []
  userDetailsObject: any = {}
  avatarFlag = 'avatar'
  userFlag = 'user'
  logInFlag = ''
  filterSnapShotFileDetailsall: any = []
  userDetailsloading: boolean = false
  brandsnaploadingSnap: boolean = true
  selectedTab: any
  secteTab: any = 'Story';


  images = [
    { "image": "./assets/user-upload-dummy/new-img-1.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-2.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-3.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-4.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-5.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-6.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-7.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-8.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-9.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-10.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-11.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-12.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-13.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-14.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-15.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-16.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-17.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-18.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-19.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-20.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-21.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-22.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-23.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-24.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-25.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-26.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-27.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-28.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-29.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-30.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-31.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-32.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-33.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-34.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-35.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-36.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-37.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-38.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-39.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-40.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-41.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-42.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-43.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-44.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-45.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-46.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-47.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-48.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-49.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-50.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-51.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-52.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-53.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-54.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-55.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-56.jpg" },
    { "image": "./assets/user-upload-dummy/new-img-57.jpg" },
  ]
  page: number = 1;
  dismiss:boolean = true;
  userAvatarData: any;
  constructor(
    public router: Router,
    private dataSharingService: DataSharingService,
    private commonService: CommonService,
    public activeModal: NgbActiveModal,
    private firestore: AngularFirestore,
  ) {

    this.user.subscribe(userdetails => {
      console.log("this.userDetailsObject",this.UserShapShotObject.UserID)
      this.userDetails = userdetails
      let userId = this.UserShapShotObject.UserID != undefined ? this.UserShapShotObject.UserID : (this.userData.uid || this.UserID);
      console.log("userId data",userId)
      this.userDetailsObject = this.userDetails.find((user) => user.UserID == userId)
      console.log("this.userDetailsObject",this.userDetailsObject)
   
   
    });
    this.commonService.getAllUser().subscribe(async (allUsers: any) => {
      this.allUserDetails = allUsers.users;
      this.AllDetails = allUsers.users;
      console.log("this.AllDetails", allUsers)

    })
    this.commonService.snapshotfileDetais().subscribe(async (snapshot: any) => {
      this.SnapShotFileDetails = snapshot;
      // this.update()  
    })
  }

  ngOnInit(): void {
    this.getDocumentDetails()
    if (this.AvatarID != undefined) {
      let reqInputObject = { id: this.AvatarID, pageNumber: 1 }
      this.commonService.yologramSnapshotDetailsByAvatar(reqInputObject).subscribe(async (response: any) => {
        this.filterSnapShotFileDetailsall = response.object
        this.allUserSnapDetails = response.object.slice(0, 3)
        this.brandsnaploadingSnap = false
        this.filterSnapShotFileDetailsall.forEach(async (snap: any, index) => {
        });
      })
    } else {
      let userId = this.UserShapShotObject && this.UserShapShotObject.UserID != undefined ? this.UserShapShotObject.UserID : this.userData.uid;
      let reqInputObject = { id: userId || this.UserID, pageNumber: 1 }
      this.commonService.yologramSnapshotDetailsByUserId(reqInputObject).subscribe(async (response: any) => {
        this.filterSnapShotFileDetailsall = response.object
        this.allUserSnapDetails = response.object.slice(0, 3)
        this.brandsnaploadingSnap = false
        this.filterSnapShotFileDetailsall.forEach(async (snap: any, index) => {
        });
      })
    }
    this.userFilesList.subscribe((userFile) => {
      let userId = this.UserShapShotObject &&
      this.UserShapShotObject.UserID != undefined ? this.UserShapShotObject.UserID : (this.userData.uid || this.UserID);
      this.userUploadedFileList = userFile
      this.filelist = this.userUploadedFileList.filter((uploadlist) => uploadlist.fileType == 'UserUploads')
      let filelist = this.filelist.filter((uploadlist) => uploadlist.userId == userId)
      // this.userFiledetails = filelist

    });
    // this.setuserDetails()
  }


  ngOnChanges(changes) {
    new window['bootstrap'].Carousel(document.getElementById('imageCarousel'), {
      interval: true // Disable automatic sliding if needed
    });    }



    update() {
      this.commonService.getAllUser().subscribe(async (allUsers: any) => {
        this.AllDetails = allUsers.users;
        this.AllDetails.forEach(object => {
          let filterSnapShots = this.SnapShotFileDetails.filter((snapShot) => snapShot.UserID == object.uid);
          this.selectedSanp = filterSnapShots;
          if (filterSnapShots.length > 0) {
            this.userImfo.push(object);
          }
        });
    
        // Assuming this.userData is an array that holds the UserIDs that should not be added again
        const userIDsInUserData = this.userDetails.map(user => user.UserID);
        this.userImfo.forEach(object => {
          let details = object;
          let userDetail = details.providerData[0];
          let saveUserDetails = {
            "DisplayName": userDetail.displayName != undefined ? userDetail.displayName : '',
            "Email": userDetail.email != undefined ? userDetail.email : '',
            "FirstName": '',
            "Identifier": '',
            "LastName": '',
            "MiddleName": '',
            "ProfilePicture": userDetail.photoURL != undefined ? userDetail.photoURL : '',
            "UserID": details.uid != undefined ? details.uid : '',
            "PageName":'userpopup'
          };
    
          // Check if UserID is already in this.userData
          if (!userIDsInUserData.includes(saveUserDetails.UserID)) {
            // If not, then check Firestore
            this.firestore.collection('user-profile', ref =>
              ref.where('UserID', '==', saveUserDetails.UserID))
              .get()
              .subscribe(querySnapshot => {
                if (querySnapshot.empty) {
                  this.firestore.collection('user-profile').add(saveUserDetails).then((docRef) => {
                    console.log(`User profile added with ID: ${docRef.id}`);
                    // Optionally, you could add this ID to this.userData to keep the local array updated
                    // this.userData.push({ ...saveUserDetails, UserID: docRef.id });
                  });
                } else {
                  console.log("User profile already exists in Firestore");
                }
              });
          } else {
            console.log(`User profile for UserID ${saveUserDetails.UserID} already exists in userData, not adding to Firestore.`);
          }
        });
      });
    }


  userSnapShots() {
    this.closePopUp();
    this.dataSharingService.sendUserSnapShots(this.filterSnapShotFileDetailsall);
    this.dataSharingService.sendUserData(this.userData)
  }


//   setuserDetails(){
//     if(this.userDetailsObject.ProfilePicture == undefined || 
//       this.userDetailsObject.DisplayName  == undefined || 
//       this.userDetailsObject.ProfilePicture == '' || 
//       this.userDetailsObject.DisplayName  == ''  || 
//       this.userDetailsObject.ProfilePicture == null || 
//       this.userDetailsObject.DisplayName  == null 
//       )
// {

//    this.commonService.getAllUser().subscribe(async (allUsers: any) => {
//       this.allUserDetails = allUsers.users;
//       let userDetail =  this.allUserDetails.find((user)=>user.uid == this.UserID )
//       let dispalydetails = userDetail.providerData[0]
//       let saveUserDetails = {
//         "DisplayName":dispalydetails.displayName != undefined ? dispalydetails.displayName : "",
//         "Email":dispalydetails.email != undefined ? dispalydetails.email : "",
//         "FirstName": '',
//         "Identifier":'',
//         "LastName":'',
//         "MiddleName":'',
//         "ProfilePicture":dispalydetails.photoURL != undefined ? dispalydetails.photoURL : "",
//         "UserID":userDetail.uid,
//       }
//       this.firestore.collection('user-profile').doc(this.userDetailsObject.id)update.update(saveUserDetails);
//     })
//   } 
//   }

// setuserDetails() {
//   // Check if ProfilePicture or DisplayName are undefined, empty, or null
//   console.log("this.userDetailsObject",this.userDetailsObject)
//   console.log("this.UserID",this.UserID)

  
//   if (this.userDetailsObject.ProfilePicture == undefined || 
//           this.userDetailsObject.DisplayName  == undefined || 
//           this.userDetailsObject.ProfilePicture == '' || 
//           this.userDetailsObject.DisplayName  == ''  || 
//           this.userDetailsObject.ProfilePicture == null || 
//           this.userDetailsObject.DisplayName  == null ) {
//             this.commonService.getAllUser().subscribe((allUsers: any) => {
//               this.allUserDetails = allUsers.users;
//               let userDetail = this.allUserDetails.find((user) => user.uid == this.UserID);
//               console.log("userDetail",userDetail)

//               if (userDetail) {
//                 let displayDetails = userDetail.providerData[0];
//                 let saveUserDetails = {
//                   "DisplayName":displayDetails.displayName != undefined ? displayDetails.displayName : "",
//                   "Email":displayDetails.email != undefined ? displayDetails.email : "",
//                   "FirstName": '',
//                   "Identifier":'',
//                   "LastName":'',
//                   "MiddleName":'',
//                   "ProfilePicture":displayDetails.photoURL != undefined ? displayDetails.photoURL : "",
//                   "UserID":userDetail.uid,
//                 };
//                 console.log("saveUserDetails",saveUserDetails)
//                 // const userDocRef = this.firestore.collection('user-profile').doc(saveUserDetails.UserID);
//                 const userProfileRef = this.firestore.collection('user-profile').doc(saveUserDetails.UserID);
//                 console.log("userProfileRef",userProfileRef)

//                 userProfileRef.get().subscribe((docSnapshot) => {
//                   if (docSnapshot.exists) {
//                     // Update the existing user profile
//                     userProfileRef.update(saveUserDetails);
//                     console.log("Update the existing user profile")
//                   } else {
//                     // Create a new user profile if it doesn't exist
//                     userProfileRef.set(saveUserDetails);
//                     console.log("Create a new user profile if it doesn't exist")
//                   }
//                 })
//               }
//             });
          
//           }

  
// }




  async getUserSnapShotDashboard(page, snapshot) {
    console.log("profile Flag",this.rootFlag)
    let id =  snapshot.UserID != undefined ?  snapshot.UserID : this.UserID
    let  pagetab =  page
    let  storyId = snapshot.StoryID != undefined ?  snapshot.StoryID : "BlanckStoryID"
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (this.router.url.includes('/user')) {
          window.location.reload();
        }
      }
    });
    this.closePopUp();
          // this.router.navigate([ '/user' , { 'id':snapshot.UserID || this.UserID, 'pagetab': page, 'storyId': storyId }])
    if( this.rootFlag ==  "/edit-user"){
      this.router.navigate([this.rootFlag,id,pagetab,storyId])
    }
    else if(this.rootFlag ==  "/me" )
    {
      this.router.navigate([ this.rootFlag , { 'id':snapshot.UserID || this.UserID, 'pagetab': page, 'storyId': storyId }])
      
    } else if(this.rootFlag ==  '/user'   )
    {
      this.router.navigate([ '/user' , { 'id':snapshot.UserID || this.UserID, 'pagetab': page, 'storyId': storyId }])
    }
  }

  getUserSnapShotByAvatarId(page, snap) {
    let sToryId  =  snap.StoryID != undefined ? snap.StoryID : ''
    console.log(this.rootFlag) 
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (this.router.url.includes('/avatar')) {
          window.location.reload();
        }
      }
    });
    this.closePopUp();
    // this.router.navigate(['/user',(this.AvatarID || snap ),page,(this.userDetailsObject.UserID || this.UserID),sToryId])
          this.router.navigate(['/avatar', { 'avatarID': snap.AvatarID || this.AvatarID, 'pagetab': page, 'id': (this.userDetailsObject.UserID || this.UserID)}])


    // if(this.rootFlag ==  "yologram-social/yologram-user-profile-feed"){
    //   this.router.navigate([this.rootFlag,(this.AvatarID || snap ),page,(this.userDetailsObject.UserID || this.UserID),sToryId])
    //   // this.router.navigate([ this.rootFlag , { 'avatarID': snap.AvatarID || this.AvatarID, 'pagetab': page, 'id': this.userDetailsObject.UserID }])
    // }
    // else if( this.rootFlag !=  "yologram-social/yologram-user-profile-feed"){
    //   this.router.navigate([this.rootFlag, { 'avatarID': snap.AvatarID || this.AvatarID, 'pagetab': page, 'id': (this.userDetailsObject.UserID || this.UserID)}])
    // }
  }

  grtProfile(page) {
    this.closePopUp()
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (this.router.url.includes('/avatar')) {
          window.location.reload();
        }
      }
    });
          this.router.navigate(['/avatar', { 'id':this.UserID, 'pagetab': page }])

    // if(this.rootFlag != undefined || this.UserID != undefined){
    //   this.router.navigate(['yologram-social/yologram-user-myprofile', { 'id':this.UserID, 'pagetab': page }])
    // }
    // else{
    //   this.router.navigate([ this.rootFlag, { 'id':this.userDetailsObject.UserID, 'pagetab': page }])
    // }
  }


  selectSummeryView(tab) {
    this.selectedTab = 'story'
    this.selectTab(tab)
  }

  selectTab(tab:any){
    this.secteTab = tab
  }
  getSelectSnapshotFeed(userSnap) {
    this.closePopUp()
    let avatar = this.AvatarID
    let userId = userSnap.UserID
    let storyid = userSnap.StoryID
    let userSnapShotList = this.filterSnapShotFileDetailsall.filter((snapshot) => snapshot.UserID == userId);
    userSnapShotList.forEach(async (snap: any, index) => {
      let userObjet = this.allUserDetails.find(userObject => userObject.uid == snap.UserID)
      snap.profileName = userObjet && userObjet.displayName != undefined ? userObjet.displayName : snap.UserID;
      snap.firstName = snap.profileName.split(" ", 1);
      snap.AvatarLastFive = snap.AvatarID.substr(snap.AvatarID.length - 5);
    })

    let storyObject = { 'id': userId, 'storyId': storyid, 'pageNamuber': this.page, }
    this.dataSharingService.sendUserSnapShots(userSnapShotList);
    if (this.AvatarID != undefined) storyObject['avatarID'] = this.AvatarID
    this.router.navigate(['public/public-userStory-feed', storyObject])
  }


  closePopUp() {
    this.activeModal.close();
  }


  async getDocumentDetails() {
    await this.commonService
      .getDocumentsFromSubcollection(this.UserID, this.AvatarID)
      .then((documents) => {
        console.log("this.id, this.avatarID", this.UserID, this.AvatarID)
        if (documents && documents.length > 0) {
          const firstDocument = documents[0]; // Assuming there is only one document
          this.userAvatarData = firstDocument;
        }
      })
      .catch((error) => {
        console.error('Error in component:', error);
        throw error; // You can choose to re-throw the error or handle it here
      });
      this.userFiledetails = this.processAvatarUrls()
      console.log("this.newArray")
  }

  processAvatarUrls(): { fileurl: string }[] {
    const urls = [];
    if (this.userAvatarData?.AvatarPreviewFullBodyUrl) {
      urls.push({ fileurl: this.userAvatarData.AvatarPreviewFullBodyUrl });
    }
    if (this.userAvatarData?.AvatarPreviewPortraitUrl) {
      urls.push({ fileurl: this.userAvatarData.AvatarPreviewPortraitUrl });
    }
    return urls;
  }
}
