import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ApiHttpService } from 'src/app/services/api-http.service';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-web-pages',
  templateUrl: './web-pages.component.html',
  styleUrls: ['./web-pages.component.css']
})
export class WebPagesComponent implements OnInit {
pageCollection = this.firestore.collection('Pages').valueChanges({ idField: 'id' }) as Observable<[]>;
  pageName: any = ""
  pageAddress: any = ""
  pageAll: any = [];
  groupUser: any = {};
  securityGroupUserList: any = [];
  uid: any;
  loading: boolean = false;
  selectedSecGpId: any;
  isEditItems: boolean;
  firstField = true;
  editPageAddress: any = {};
  modifiedSecGrpName: any;
  modifiedPageAdderess:any;
  editSecurityGroup: any = {};
  editPageName: any = {};
  pageStatus:any ="A";
  logInFlag: boolean;

  constructor(private apiHttpService: ApiHttpService, private router: Router, public commonService: CommonService, private firestore: AngularFirestore) {

    this.pageCollection.subscribe((pageList) => {
      this.pageAll = [];
       pageList.forEach((pageName: any) => {
        if(pageName.status == 'A')this.pageAll.push(pageName)
      });
    
    });
  }

  ngOnInit(): void {
    let title = 'Yologram - web-pages'
    this.commonService.changeTitle(title);
    this.logInFlag = this.commonService.isLoggedIn()
    this.commonService.getUser().subscribe((user: any) => {
      let userId = user?.uid;
    });
    this.checkPageAuthorization()
  }


  addSPages() {
    if(this.pageName == '' &&  this.pageAddress== '' ) 
    {
      alert('Pleaes enter PageName and PageAddress.')
      return;
    }
    else {
      let duplicateList =  this.pageAll.filter((page:any)=> page.PageName.toLowerCase() === this.pageName.toLowerCase() &&  page.PageAddress.toLowerCase() === this.pageAddress.toLowerCase())
      if(duplicateList.length > 0) {
        alert('Duplicate PageName & PageAddress is not allow.')
        return;
      } 
    }

    let pageObject = {
      PageName: this.pageName,
      PageAddress : this.pageAddress,
      status:"A"
    }
    this.firestore.collection('Pages').add(pageObject)
    this.pageName = ''
    this.pageAddress = ''
    alert("Page Added Successfully..")
    return
  }



  checkPageAuthorization() {
    this.loading = true;
    this.commonService.getUser().subscribe((user: any) => {
      let userObject = { 'userid': user?.uid, 'pagename': '/web-pages' }
      this.commonService.checkViewPageRights(userObject).subscribe((res) => {
        this.loading = false;
        if (!res) {
          this.router.navigateByUrl("/menu-page");
        }
      });
    });
  }  setPageAddress($event) {
    this.editPageName.PageAddress = $event.target.value
   }

   setPageName($event) {
    this.editPageName.PageName = $event.target.value
   }


  callEditPageName(editPage){
    this.editPageName = editPage;
  }

  
    updatePageNameandAddress()
  {
    let object = {
      PageName : this.editPageName.PageName,
      PageAddress : this.editPageName.PageAddress,
    };
    this.modifiedSecGrpName; 
    this.firestore.collection('Pages').doc(this.editPageName["id"]).update(object);
  }
}
