<div class="modal-dialog content ">
    <div class="modal-content  imp-block">
        <button type="button" class="btn-close btan-class btn-close-white" data-bs-dismiss="modal"
            aria-label="Close" (click)="closePopUp()"></button>
        <div class="">
            <div class="d-flex mar-navbar justify-content-center" [ngClass]=" PageAll  == '' ? 'blank-grp' : ''">
                <div class="text-white p-1">
                    <img style="width: 50px !important; height:50px !important; padding: 5px;"
                        src="./assets/YLogoTransparent.png" alt="Logo" />
                </div>
                <div>
                    <h6 class="text-white p-1 grp-name"
                        *ngIf="this.userSecurityGrp.length > 0 && getSecurityGroupNameById(selectedSecGrpId)"
                        (click)="router.navigate(['/security-group-change',{'id':id}])"
                        data-bs-dismiss="modal">
                        {{selectedSecurityGroupName}}</h6>
                </div>
                <div class="container-fluid navbar navbar-light light-blue lighten-4 m-1 nav-width "
                    *ngIf="PageAll  != '' ">
                    <button class="navbar-toggler menu-btn" type="button" data-bs-toggle="collapse"
                        data-bs-target="#navbarsExample09" aria-controls="navbarsExample09" aria-expanded="false"
                        aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon"></span>
                    </button>
                    <div class="collapse  navbar-collapse justify-content-md-left" #yologramnavbarProfile
                        id="navbarsExample09">
                        <ul class="navbar-nav">
                            <span *ngFor="let pages of PageAll">
                                <li  (click)="closePopUp()"  class="nav-item text-start text-primary nav-item-width" data-bs-dismiss="modal">
                                    <a (click)="router.navigate([pages.PageAddress])"
                                        class="nav-link px-2 text-white">{{pages.PageName}}</a>
                                </li>
                            </span>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="text-center text-white popup-containt">
            <div class="popup-containt m-1">
                <div class="w-100 col-12 d-block band"
                    [ngClass]="this.filterSnapShotForProfile != undefined  ? 'band-bottom' : ''">
                    <div data-bs-dismiss="modal" class="static-band text-center col-12 justify-content-center m-1">
                        <div class="d-flex m-1 profile-popup text-white" data-bs-dismiss="modal"
                            (click)="getProfileForUSer()">
                            <img *ngIf="userDetails.ProfilePicture != undefined" class="img-thumbnail rounded-circle"
                                style="
                width: 60px !important;
                height: 60px !important;
                margin-top: -53px;
            " src={{userDetails.ProfilePicture}}>


                            <!-- <img *ngIf="userDetails.ProfilePicture == undefined" class="img-thumbnail rounded-circle"
                                style="
                /* width: 60px !important;
                height: 60px !important;
                margin-top: -53px;
            " src={{imgpath}}>  -->

                        </div>
                        <h6 *ngIf="userDetails.ProfilePicture != undefined" (click)="getProfileForUSer()"
                            data-bs-dismiss="modal" class="m-1 text-start text-black profile-name ">
                            {{userDetails.DisplayName}}
                        </h6>
                        <!-- <h6 *ngIf="userDetails.ProfilePicture == undefined" (click)="getProfileForUSer()" data-bs-dismiss="modal"
                class="m-1 text-start text-black profile-name ">{{user_name}} </h6> -->

                        <div>
                            <div class="static-band-five-icon col-4 text-center d-flex justify-content-center">
                                <div class="brandbtn">
                                    <h6 class="m-1  story-menu-card" (click)="getProfileForUSer()"
                                        data-bs-dismiss="modal">
                                        <a><img style="width:15px !important"
                                                src="  ./assets/storystyle/story-white.png">
                                        </a>
                                    </h6>
                                    <h6 class="m-1 font-class">Story</h6>
                                </div>
                                <div class="brandbtn" data-bs-dismiss="modal"
                                    (click)="router.navigate(['yologram-social/yologram-public-view-file',{'id':id}])">
                                    <h6 class="m-1  story-menu-card"><a><img style="width:15px !important"
                                                src="./assets/brand_white.png"></a>
                                    </h6>
                                    <h6 class="m-1 font-class">Brands </h6>
                                </div>
                                <div class="brandbtn">
                                    <h6 class="m-1  story-menu-card" data-bs-dismiss="modal"
                                        (click)="router.navigate(['yologram-social/news-admin',{'id':id}])"><a><img
                                                style="width:15px !important" src="./assets/t_shirt_white.png"></a>
                                    </h6>
                                    <h6 class="m-1 font-class ">Style</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="child" style="height: 147px !important; width: 100% !important;">
                        <div class="">
                            <div data-bs-dismiss="modal" class="m-1 "
                                [ngClass]="filterSnapShotForProfile.length < 0 ? '' : 'popup-imfo'">
                                <div (click)="getProfileForUSer()"
                                    *ngIf="userFiledetails.length == 0 && images != undefined ">
                                    <app-slider-two-d [images]="images" style="all:unset !important"></app-slider-two-d>
                                </div>
                                <div (click)="getProfileForUSer()" *ngIf="userFiledetails !=  0">
                                    <app-slider-two-d [images]="userFiledetails" [UserId]="id"
                                        style="all:unset !important"></app-slider-two-d>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="story_image">
                    <div class="row w-100"
                        *ngIf="filterSnapShotForProfile.length >= 0  && filterSnapShotForProfile != undefined && filterSnapShotForProfile.length >= 0">
                        <div class="column d-block m-1"
                            *ngFor="let snapshot of filterSnapShotForProfile  ; let i=index;">
                            <img class="w-100 shadow-1-strong rounded" data-bs-dismiss="modal"
                                (click)="getProfileForUSerFeed(snapshot)" [src]="snapshot.SnapshotFileUrl">
                        </div>
                        <div class="columnImg d-block m-1" *ngIf="filterSnapShotForProfile.length > 2">
                            <img class="w-80 shadow-1-strong rounded image-pop plus-image" (click)="getProfileForUSer()"
                                data-bs-dismiss="modal" src="./assets/plus-8-512.png">
                        </div>
                    </div>
                </div>
            </div>
            <button type="button m-2 " data-bs-dismiss="modal" (click)="handleSignOut()"
          class="btn  btn-sm btn-primary btnFoPopUp text-white ">Logout</button>
            <div>
            </div>
        </div>
    </div>
</div>
