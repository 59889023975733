<app-yologram-social *ngIf="logInFlag"></app-yologram-social>
<div class="card">
    <!-- <div class="card-header font-bold">List of Files</div> -->
    <div class="dt-responsive selectlist font-14">
      <div class="dataTables_wrapper dt-bootstrap4" id="add-row-table_wrapper">
        <div class="p-3">
          <select
          (change)="selectActionGroupId($event)" 
          class="form-select " *ngIf="securityGroupList.length >0">
            <option value="">Select Group</option>
            <option [value]="group.SecGpId" *ngFor="let group of securityGroupList ;let i=index">{{this.securityGroupAll[group.SecGpId]}}</option>
            <!-- {{this.securityGroupAll[group.SecGpId]}}</option> -->
        </select>
        <div class="col-sm-12 p-0"  >
          <table class="table " id="dataTable" width="100%" cellspacing="0"   >
            <thead class="custom-header" >
              <tr role="row">
                 <th>Page Name</th>      
                <th>Link</th>
              </tr>
            </thead>
            <tbody>
              <tr class="row-list-detail"  *ngFor="let page of securityGroupPageList">
                <td class="">
                  <span class="user-info word-break text-underline-offset: 3px;">{{page.pageName}}</span>
                </td>
                <td class="catalog-info ">
                  <span >
    
                  </span>
                  <span>
                    <div class="pagename" (click)="pageRedirect(page.pageAddress)"><ins>Go</ins></div>
                  </span>
                  
                </td>
       
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  
