import { Component, Input, OnInit, SimpleChange } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, filter } from 'rxjs';
import { Brand } from 'src/app/models/brand';
import { ApiHttpService } from 'src/app/services/api-http.service';
import { CommonService } from 'src/app/services/common.service';
import { DataSharingService } from 'src/app/services/data-sharing-service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-popup-three-d',
  templateUrl: './popup-three-d.component.html',
  styleUrls: ['./popup-three-d.component.css']
})
export class PopupThreeDComponent implements OnInit {

  @Input() gltfImgSrc: string | undefined;
  @Input() skyBoXall: string | undefined;
  @Input() twoDprivew: string | undefined;
  @Input() evsname: string | undefined;
  @Input() evsdiplaynameALL: string | undefined;
  @Input() environmentFileName: string | undefined;
  @Input() hideFlag: boolean | undefined;
  @Input() fileDeletFlag: boolean | undefined;
  @Input() fileObject: any = {}
  @Input() routeFlageEven:string | undefined;

  

  
  

  srcfile ='./assets/scene.gltf'
  environmentCollection = this.firestore.collection('Environments').valueChanges({ idField: 'id' }) as Observable<Brand[]>;

  SnapShotFileDetails: any = []
  evnFileListFileName: any = []
  evnFileList: any = []
  filterEnvironmentSnpShot : any = []
  snapshot: any;
  userSnapShotList: any = []
  userSnapShotListSlice: any = []
  snaploader2D:boolean = false
  snaploader:boolean = false
  nameFlag: boolean = true;
  fileName: any;

  // gltfImgSrc  :any = "https://firebasestorage.googleapis.com/v0/b/yolouploader.appspot.com/o/userData%2Ffiles%2Fcrystal_ball.glb?alt=media&token=963e0d80-5809-4e1b-9034-caf31fb33945"
 
  constructor(public dialog: MatDialog,

    private dataSharingService: DataSharingService,   
    private commonService: CommonService,
    private firestore: AngularFirestore,
    public router: Router,
    private route: ActivatedRoute,
    private apiHttpService: ApiHttpService,
    private modal: NgbModal,
    private storage: AngularFireStorage,
    private scrollService: CommonService,

    
    private dialogRef: MatDialogRef<PopupThreeDComponent>) {

   
    this.dataSharingService.userEnvSnapShotObservable.subscribe(snapshot => {
      this.userSnapShotList = snapshot
      this.userSnapShotListSlice = snapshot.slice(0,3)
      let evnName =  snapshot.Environment
    })

   
    this.environmentCollection.subscribe(
      (evnFile) => {
        this.evnFileList = evnFile
        let envFileName = this.evsname
        let evenListName = "Environments/"
        this.evnFileListFileName = this.evnFileList.find((snap) => snap.storageURL == evenListName+envFileName)
      });
  }


  ngOnInit(): void {
  console.log(this.fileDeletFlag)
  console.log(this.fileObject)

    this.snaploader2D = true;
    this.snaploader = true;
    setInterval(() => {
        this.snaploader2D = false; 
   }, 2000);
    
    setInterval(() => {
        // this.popupLoadingFor2D = false;
        this.snaploader = false; 
   }, 3000);
  }



   scrollToId(id: string) {
    if(id != '')this.scrollService.scrollToElementById(id);
  } 
  getEnvironmentProfile() {
    this.closeDialog()
    const previousUrl = this.router.url;
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if ( previousUrl.includes('/spaces') && this.router.url.includes('/spaces')) {
          window.location.reload();
         }
}
});
    // let evnName = snapShot.Environment
    let envFileName = this.evsname
    let evenListName = "Environments/"
    console.log("envFileName",envFileName)
    this.evnFileListFileName = this.evnFileList.find((snap) => snap.storageURL == evenListName+envFileName)
    this.dataSharingService.sendUserSnapShots(this.userSnapShotList)
    // this.router.navigate([this.routeFlageEven != undefined ? this.routeFlageEven  :  '/environment', {  'evnFileName':this.environmentFileName || envFileName,'evnFile': this.evnFileListFileName.displayName,'evnFileImage': this.skyBoXall,'envName': envFileName ,'storyFeed':'story' }])
    this.router.navigate([ '/spaces', {  'evnFileName':this.environmentFileName || envFileName,'evnFile': this.evnFileListFileName.displayName,'evnFileImage': '','envName': envFileName ,'storyFeed':'story' }])
    console.log('this.skyBoXall',this.skyBoXall)

  }

  getEnvironmentProfileFeed(snapShot) {
    this.closeDialog()
//     const previousUrl = this.router.url;
//     this.router.events.subscribe((event) => {
//       if (event instanceof NavigationEnd) {
//         if ( previousUrl.includes('/spaces') && this.router.url.includes('/spaces')) {
//           window.location.reload();
//          }
// }
// });
    let evnName = snapShot.Environment
    let envFileName = this.evsname
    let evenListName = "Environments/"
    this.evnFileListFileName = this.evnFileList.find((snap) => snap.storageURL == evenListName+envFileName)
    let reqInputObject = {id:envFileName,pageNumber:1}
    this.dataSharingService.sendUserSnapShots(this.userSnapShotList)
    // this.router.navigate([this.routeFlageEven != undefined ? this.routeFlageEven  :  '/environment', {'storyId':snapShot.StoryID,'evnFileName':this.environmentFileName || envFileName,  'evnFile': this.evnFileListFileName.displayName,'evnFileImage': this.skyBoXall,'storyFeed':'storyFeed' }])
    this.router.navigate(['/spaces', {'storyId':snapShot.StoryID,'evnFileName':this.environmentFileName || envFileName,  'evnFile': this.evnFileListFileName.displayName,'evnFileImage': '','storyFeed':'storyFeed' }])

console.log('this.skyBoXall',this.skyBoXall)
    // this.router.navigate([this.routeFlageEven != undefined ? this.routeFlageEven : 'public/public-evn-profile'], {
    //   queryParams: {
    //     'storyId': snapShot.StoryID,
    //     'evnFileName': this.environmentFileName || envFileName,
    //     'evnFile': this.evnFileListFileName.displayName,
    //     'evnFileImage': this.skyBoXall,
    //     'storyFeed': 'storyFeed'
    //   },
    //   queryParamsHandling: 'merge' // or 'preserve' depending on your need
    // });
  }
  editFileName($event){
    this.fileName = $event.target.value
  }
  updateFileName(data){
    this.nameFlag = true
    let object  = {
      'filename':  this.fileName 
    }
    this.firestore.collection('userfiles').doc(data["id"]).update(object);
    this.closeDialog()
  }
  closeDialog(){
    this.dialogRef.close();
  }
  selectName(){
    this.nameFlag = false
  }


  deleteSnapshotFile(data: any) {
    console.log(data)
    if(this.fileDeletFlag){
      var confirmation = confirm('Are you sure to delete this File ?');
      if (!confirmation) return;
      this.firestore.collection("userfiles").doc(data.id).delete().then(() => {
        
        // let filepath = "Uploads/" + data.link +"/"+ data.filename

          let filepath = data.filepath
        this.storage.ref(filepath).delete();
      }).catch((error) => {
      });;
    }else{
      var confirmation = confirm('Are you sure to delete this File ?');
      if (!confirmation) return;
      this.firestore.collection("userfiles").doc(data.id).delete().then(() => {
        let filepath = data.filepath
        // let filepath = "Uploads/" + data.userId +"/"+ data.filename
        this.storage.ref(filepath).delete();
      }).catch((error) => {
      });;
    }
      // this.images = []

      this.closeDialog()
  }

}
