import { ActivatedRoute } from '@angular/router';
import { Component, ElementRef, Input, Renderer2, ViewChild } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { HttpClient } from '@angular/common/http';
// import * as JSZip from 'jszip';
import * as JSZip from 'jszip/dist/jszip'





@Component({
  selector: 'app-new-three-d-file',
  templateUrl: './new-three-d-file.component.html',
  styleUrls: ['./new-three-d-file.component.css']
})
export class NewThreeDFileComponent {
  @Input() inputGender: string | undefined;
  @Input() inputBrandId: string | undefined;
  @Input() inputclothType: string | undefined;
  @Input() inputClothId: string | undefined;

  @ViewChild('threeDModelContainer', { static: true }) threeDModelContainer: ElementRef;
  externalUrl: any;
  mainUrl: any = "https://jsontothreejs-parser.web.app/"
  dangerousUrl: string;
  trustedUrl: any;
  urlName: any;
  urlNameSecond: SafeResourceUrl;
  zipList: any = {};
  packJsonObject: any[];
  errMessage: string;
  clothType: any;
  clothId: any;
  ClothId: any;

  downloadedZipData:any;
  scene:any;
  camera:any;
  renderer:any;
  mesh:any;
  Gender: string;
  brandId: string;
  loading: boolean = true;
  constructor(private route: ActivatedRoute, private sanitizer: DomSanitizer, private _httpClient: HttpClient) {


  }




  ngOnInit(): void {
    this.route.paramMap.subscribe(paramMap => {
      this.brandId = paramMap.get('brandId')
      this.Gender = paramMap.get('Gender')
      this.clothType = paramMap.get('ClothType')
      this.ClothId = paramMap.get('ClothId')
      console.log("Url", this.urlName)
    })



    this.setUrl()
    this.initializeScene()
    this.downloadZipFile()

  }
  ngAfterViewInit() {
    // Append the renderer's domElement to the container
    // this.setContainerDimensions('75%', '400px');
    // this.threeDModelContainer.nativeElement.appendChild(this.renderer.domElement);
  }

  public setUrl(): void {
    // Sanitize the URL using DomSanitizer
    this.urlNameSecond = this.sanitizer.bypassSecurityTrustResourceUrl(this.urlName);
    console.log("Url", this.urlNameSecond)
  }


  openExternalUrl(url: string) {
    window.open(url);
  }












  initializeScene() {
    this.scene = new THREE.Scene();
    this.camera = new THREE.PerspectiveCamera(45, window.innerWidth / window.innerHeight, 0.1, 1000);
    this.renderer = new THREE.WebGLRenderer({ antialias: true });
    this.renderer.shadowMap.enabled = true;
    this.updateRendererSize(); 


    // Set the initial renderer size
    this.threeDModelContainer.nativeElement.appendChild(this.renderer.domElement);

    const controls = new OrbitControls(this.camera, this.renderer.domElement);
    controls.rotateSpeed = 1.0;
    controls.zoomSpeed = 1.2;
    controls.update();
    
    this.camera.position.set(2.606786417618793, 0.467976999473361, 1.3690350351054419);
    this.camera.rotation.x = THREE.MathUtils.degToRad(10.369661937141052);
    this.camera.rotation.y = THREE.MathUtils.degToRad(59.83999954718239);
    this.camera.rotation.z = THREE.MathUtils.degToRad(-9.202868550804395);



    this.scene.background = new THREE.Color(0xdddddd);

    const ambientLight = new THREE.AmbientLight(0x333333);
    this.scene.add(ambientLight);

    const directionalLight1 = new THREE.DirectionalLight(0xFFFFFF, 2);
    directionalLight1.position.set(0, 50, 20);
    this.scene.add(directionalLight1);

    const directionalLight2 = new THREE.DirectionalLight(0xFFFFFF, 2);
    directionalLight2.position.set(0, 50, -20);
    directionalLight2.castShadow = true;
    this.scene.add(directionalLight2);


    // const floorGeometry = new THREE.PlaneGeometry(100, 100);
    // const floorMaterial = new THREE.MeshStandardMaterial({ color: 0xdddddd, side: THREE.DoubleSide });
    // const floor = new THREE.Mesh(floorGeometry, floorMaterial);
    // floor.rotation.x = -Math.PI / 2;
    // floor.receiveShadow = true;
    // this.scene.add(floor);

    this.renderer.setAnimationLoop(() => {
      this.renderer.render(this.scene, this.camera);
    });
    window.addEventListener('resize', () => {
      this.updateRendererSize(); // Update the renderer size on window resize
    });
  }
  
  updateRendererSize() {
    if (window.innerWidth <= 768) {
      // Mobile view: Width 300px and Height 400px
      this.renderer.setSize(300, 400);
    } else {
      // Desktop view: Width 100% and Height 100%
      this.renderer.setSize(window.innerWidth, window.innerHeight);
    }
    this.camera.aspect = this.renderer.domElement.width / this.renderer.domElement.height;
    this.camera.updateProjectionMatrix();
  }
  

  downloadZipFile() {
    let brandId = this.brandId != undefined  ?  this.brandId : this.inputBrandId
    let zipfilename = "%2Fpack0"
    let gender =  this.Gender != undefined ?  this.Gender : this.inputGender 
    this.clothType = this.clothType != undefined ?  this.clothType :  this.inputclothType
    this.clothId = this.ClothId != undefined ? this.ClothId :  this.inputClothId
    let packlocation = "AvatarBlenderClothes%2F" + brandId + "_" + gender + "%2F" + this.clothType + "%2F" + this.clothId;
    let brandEncodedurl = packlocation
    let url = 'https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/' + brandEncodedurl + '?alt=media&token=2c1dc261-ac91-4c32-a5ec-ac5e06f0a9bc&_gl=1*goch74*_ga*MjExMjI5MTIyMi4xNjg3NTA3MDk1*_ga_CW55HF8NVT*MTY5NjkzMDY1Ny4xNTcuMS4xNjk2OTM2NzA4LjU5LjAuMA..'
    console.log("url", url)
    this._httpClient
      .get(
        url,
        {
          observe: 'response',
          responseType: 'blob',
        }
      )
      .subscribe(async (res) => {
        console.log("Zip data", res)
        await this.procesZipFileLoad(res.body,this.processResponse.bind(this));
       console.log("this.downloadedZipData",this.downloadedZipData);
      
      },
        (error) => (error)
      );

  }


  processResponse(res: any[]) {
    console.log("resasfasf");
    this.clothId
    this.packJsonObject = res;
    this.errMessage = "";
    console.log("res");
    console.log(res)
    this.downloadedZipData = res;
    this.updateMeshWithData()

  }

  procesZipFileLoad(data,callBackSetJsonObject) {
    var filesToProcess = ['Albedo.jpg', 'Normal.png', 'Metal.png', 'clothSaveFile.json'];
    try {
      console.log('rezips', data);
      this.zipList = JSZip.loadAsync(data,callBackSetJsonObject, this.downloadedZipData)
      .then(async function (zip) {
        console.log("First image ")
        console.log("Zip inside function+"+zip)
      let fileObject = [];
        for (const fileName of filesToProcess) {
          console.log("Filename to be check"+fileName);
          if (zip.files[fileName]) {
            let data = await zip.files[fileName].async('arraybuffer');
            fileObject[fileName] = data        
          } else {
            fileObject[fileName] = "Not found"
          }
         
        }
        console.log(fileObject)
        callBackSetJsonObject(fileObject);
        return Promise.all(fileObject);

      })  
      .then(function (result) {
        console.log("result")
        console.log(result)
        return result

      })
      .then((result) => {
        console.log("result")
        console.log(result)
        this.downloadedZipData =result;
        return;
      })
      .catch(function (e) {
        console.error(e);
      });
      console.log("Zip List")
      console.log( this.zipList)
      return this.zipList
   
    } catch (error) {
      self.postMessage({ error: 'Failed to process ZIP file', details: error.message });
    }


  }





  arrayBufferToTexture(buffer: ArrayBuffer): THREE.Texture {
    const img = new Image();
    const blob = new Blob([buffer], { type: "image/png" });
    const url = URL.createObjectURL(blob);
    img.src = url;
    console.log(url);
    console.log(blob);
    const texture = new THREE.Texture(img);
    img.onload = () => {
      texture.needsUpdate = true;
      console.log("Texture loaded successfully");
      this.loading = false
    };
    return texture;
  }


  
  updateMeshWithData(): void {
    const clothSaveFileJSON = JSON.parse(new TextDecoder().decode(this.downloadedZipData['clothSaveFile.json']));
    const albedoTexture = this.arrayBufferToTexture(this.downloadedZipData['Albedo.jpg']);
    const normalTexture = this.arrayBufferToTexture(this.downloadedZipData['Normal.png']);
    const metalTexture = this.downloadedZipData['Metal.png'] ? this.arrayBufferToTexture(this.downloadedZipData['Metal.png']) : null;
    albedoTexture.wrapS = albedoTexture.wrapT = THREE.RepeatWrapping;
    normalTexture.wrapS = normalTexture.wrapT = THREE.RepeatWrapping;
    if (metalTexture) {
      metalTexture.wrapS = metalTexture.wrapT = THREE.RepeatWrapping;
    }

    const vertices = clothSaveFileJSON.vertices.map((v: any) => new THREE.Vector3(v.x, v.y, -v.z));
    const normals = clothSaveFileJSON.normals.map((n: any) => new THREE.Vector3(n.x, n.y, -n.z));
    const flattenedUVs = clothSaveFileJSON.uv.map((uv: any) => [uv.x, uv.y]).flat();
    const triangles: number[] = [];
    for (let i = 0; i < clothSaveFileJSON.triangles.length; i += 3) {
      triangles.push(clothSaveFileJSON.triangles[i]);
      triangles.push(clothSaveFileJSON.triangles[i + 2]);
      triangles.push(clothSaveFileJSON.triangles[i + 1]);
    }

    const geometry = new THREE.BufferGeometry();
    geometry.setFromPoints(vertices);
    const flattenedNormals: number[] = normals.flatMap((v: THREE.Vector3) => [v.x, v.y, v.z]);
    geometry.setAttribute('normal', new THREE.Float32BufferAttribute(flattenedNormals, 3));
    geometry.setIndex(triangles);
    geometry.setAttribute('uv', new THREE.Float32BufferAttribute(flattenedUVs, 2));

    const material = new THREE.MeshStandardMaterial({
      map: albedoTexture,
      normalMap: normalTexture,
      metalnessMap: metalTexture,
      side: THREE.DoubleSide,
    });
    if (this.mesh) {
     // Update existing mesh
     this.scene.remove(this.mesh);
     this.mesh.geometry.dispose();
     this.mesh.material.dispose();
     this.mesh.geometry = geometry;
     this.mesh.material = material;
    } else {
      this.mesh = new THREE.Mesh(geometry, material);
      if (this.mesh) {
        this.mesh.castShadow = true;
        console.log(this.mesh)
        this.scene.add(this.mesh);
      }
    }
  }

  setContainerDimensions(width: string, height: string) {
    this.renderer.setStyle(this.threeDModelContainer.nativeElement, 'width', width);
    this.renderer.setStyle(this.threeDModelContainer.nativeElement, 'height', height);
  }
}
