import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { find, Observable } from 'rxjs';
import { Brand } from 'src/app/models/brand';
import { MatDialog } from '@angular/material/dialog';
import { CommonService } from 'src/app/services/common.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DataSharingService } from 'src/app/services/data-sharing-service';
import { PinterestService } from 'src/app/services/pinterest-service';
import { ApiHttpService } from 'src/app/services/api-http.service';
import { DomSanitizer } from '@angular/platform-browser';
export interface ZipFile {
  readonly name: string;
  readonly dir: boolean;
  readonly date: Date;
  readonly data: any;
}

@Component({
  selector: 'app-threads',
  templateUrl: './threads.component.html',
  styleUrls: ['./threads.component.css']
})
export class ThreadsComponent {
  // @ViewChild('brandStoriyScroll') brandStoriyScroll: ElementRef;
  @ViewChild('brandStoriyScrollA', { static: false }) brandStoriyScrollA: ElementRef;
  @ViewChild('brandSliderPortFolio', { static: false }) brandSliderPortFolio: ElementRef;
  @ViewChild('brandStoriyScrollA', { static: false }) scrollContainerMUpper: ElementRef;

  @ViewChild('brandStoriyScroll', { read: ElementRef }) public BrandStoriyScroll: ElementRef<any>;
  brands = this.firestore.collection('brands').valueChanges({ idField: 'id' }) as Observable<Brand[]>;
  garmentsList = this.firestore.collection('garments').valueChanges({ idField: 'id' }) as Observable<any[]>;
  brandsBoardCollections = this.firestore.collection('brand-board').valueChanges({ idField: 'id' }) as Observable<Brand[]>;
  userFiles = this.firestore.collection("userfiles");
  userFileStateChange = this.userFiles.valueChanges();
  brandBoardList = this.firestore.collection('brand-board').valueChanges({ idField: 'id' }) as Observable<any[]>;
  SnapShotsList = this.firestore.collection('SnapshotDetails').valueChanges({ idField: 'id' }) as Observable<any[]>;
  brandsCollection = this.firestore.collection('brands').valueChanges({ idField: 'id' }) as Observable<Brand[]>;
  brandsCollections = this.firestore.collection('brands').valueChanges({ idField: 'id' }) as Observable<Brand[]>;
  brandsPortfoliocollection = this.firestore.collection('BrandPortfolio').valueChanges({ idField: 'id' }) as Observable<[]>;
  brandsPortfolioDetailscollection = this.firestore.collection('BrandPortfolioDetails').valueChanges({ idField: 'id' }) as Observable<[]>;


  displayedColumns: string[] = ['position', 'name', 'weight', 'symbol'];
  public text = `{ "text": "This is text file!中文" }`;
  public fileName: string | undefined;


  @Input() rootFlag: string | undefined;
  @Input() stylePage: string | undefined;
  @Input() routLogInFlag: boolean | undefined;
  @Input() panel: boolean | undefined;

  tempSnapShot: any = []
  searchFilterData: any = []
  singleSnap: any;
  pageName: any = 'threads'
  isShowStats: boolean = true

  nonsSelectedBrand: any = {}



  // @ViewChild('brandsScroll', { read: ElementRef }) public brandsScroll: ElementRef<any>;


  searchPanel: boolean
  evnProfilePageFlag: boolean = true
  boardDetails: any
  selectedBrandType: any
  selectedBrand: any;
  getZipcollection: any;
  zipfile: any;
  loading = true;
  brandDetails: any = []
  brandDetailsForStories: any = []
  snapDetails: any = []
  garmentsCollection: any = []
  brandId: any
  brandGarmentIds: any = [];
  SnapShotFileDetails = []
  BrandDetails = []
  snapShotBrandList = [];
  filterStories = []
  storyId: any
  userId: any
  brandIds: any
  boards: any = []
  brandBoard: any = []
  boardNameInBrandStory: any
  brandDetailsId: any = {}
  showBrandName: boolean = false
  showBCollasps: boolean = false;
  showRender: boolean = false;
  pageTrueFlage: boolean = true;
  brandSelectionFlag: boolean = false;
  userSnapShotList: any = []
  SnapShotDetails: any = []
  // selectedTab:any = "news-stories"
  selectedTab: any = ""
  filterBrandList: any = []
  filterBrandListNew: any = []
  brandsIDdetailsAll: any = {}
  genderID: any
  isloading = true
  brandDetailsAll: any = []
  byScroll: boolean = true
  showBrandFlag: boolean = false
  heartFlag: boolean = false
  brandheartFlag: boolean = true
  thresholdHeightPagination: number = 0
  pageNumber: number = 0
  clickEventFlag: boolean = false;
  portFolioFlag: boolean = false
  genderFlag: boolean;
  onChangeFlag = true;
  height: any;
  url: any;
  urlAvatar: any = '';
  urlAvatarFeMale: any = 'https://firebasestorage.googleapis.com/v0/b/rishtest-96467.appspot.com/o/20230112114731661.zip?alt=media&token=5ef19c50-0361-46c1-949e-0f78b16c9c20&_gl=1*1i8j5xe*_ga*MTY2MTMzOTE3NC4xNjk2ODgxNDM4*_ga_CW55HF8NVT*MTY5NzQ2MDA1OC4yOC4xLjE2OTc0NjAzMTYuNjAuMC4w';
  urlAvatarMale: any = 'https://firebasestorage.googleapis.com/v0/b/rishtest-96467.appspot.com/o/20230112115118542.zip?alt=media&token=3ea78817-7886-4806-9963-77717f09be64&_gl=1*1w2i935*_ga*MTY2MTMzOTE3NC4xNjk2ODgxNDM4*_ga_CW55HF8NVT*MTY5NzM2MTkzNi4yMS4xLjE2OTczNjE5NzQuMjIuMC4w';
  GarmentsGender: any;
  garmentLink: any;
  SelectedGender: any = 'M';
  isShowBrandBand: boolean = true;
  portfolioFLAGnew: boolean = false;
  loadingState: boolean;

  logInFlag: boolean;
  brandNameSelected: any = 'Search Brand';
  inValue: HTMLElement;
  scrollFlag: boolean;
  brandSerchFlag: boolean;
  settimeOutFun = 0;
  changeTabFlag: boolean;
  boardListCollection: any = []
  boardFlag: boolean;
  packJsonObject: any[];
  zipList: {};
  newSelectedbrandId: any;
  mode: string = '';
  lastSelectedBrandId: any;
  flagselected: boolean;
  modeflag: boolean;
  modeInsatnceFlageVlaue: string;
  Exmode: string;
  exclosiveMode: string;
  containLoad: boolean = true;
  newHeight: number;
  newWidth: number;
  marginTop: string;
  pageNumberByDrips: string;
  newGarmentId: string;
  portpfolioName: string;
  brandPortfolioList: any = [];
  brandPortfolioDetailsList: any = [];
  matchingBrandList: any = [];
  maxLength: number
  //New
  byScrollPortFolio: boolean = true;
  dripsPageAvatarData: any;
  dripsPageBrandData: any;
  dripsPageUserData: any

  brandflagPanel: boolean = false;
  avatarflagPanel: boolean = false;
  userflagPanel: boolean = false;
  garmentflagPanel: boolean;
  evenpanelFlag: boolean = false;

  evndata: any;
  userDetails: any;
  garmetData: any;

  closebuttonFlag: boolean = true;
  storyInex: any;
  panelFlag: string;
  Drape: string = 'N';
  isloadingGarment: boolean = false;
  index: any;
  pageiIndex: any;
  compressUrl: any;
  sideNavWidth_Fixed: number = 181;
  paginatedSnapshots: any = [];
  clearTab: boolean = true;
  selectedSnapData: boolean;
  searchNavBarFlag: boolean = true;
  selectedStory: any;
  searchBrandId: any;

  constructor(public sanitizer: DomSanitizer,
    private route: ActivatedRoute, private pinterestAPI: PinterestService, private apiHttpService: ApiHttpService,
    private firestore: AngularFirestore, private cdr: ChangeDetectorRef,
    private dataSharingService: DataSharingService, public commonService: CommonService, private router: Router) {

    this.route.paramMap.subscribe(paramMap => {
      this.brandId = paramMap.get('brandId')
      this.userId = paramMap.get('id')
      this.storyId = paramMap.get('storyId')
      this.mode = paramMap.get('M');
      this.pageNumberByDrips = paramMap.get('pageNumber');
      this.exclosiveMode = paramMap.get('Ex');
      this.newGarmentId = paramMap.get('garmentId');
      this.portpfolioName = paramMap.get('Gp');
      this.panelFlag = paramMap.get('panel')
      this.Drape = paramMap.get('Drape')
      this.index = paramMap.get('index');
      this.pageiIndex = paramMap.get('pageNumber');
      this.getPortfolioId()
    });

    console.log("pageNumberByDrips==>?", this.pageNumberByDrips)
    this.brandBoardList.subscribe((BoardList) => {
      this.boardListCollection = BoardList
    })

    this.pinterestAPI.getBoardList().subscribe((data) => {
      this.boards = data.items;
    })


    this.brands.subscribe((brandList) => {
      this.brandDetailsAll = brandList
      this.filterBrandList = this.brandDetailsAll;
      this.filterBrandList.forEach(async (brand) => {
        let type = 'b';
        let url = await this.commonService.compressImage(brand.LogoURL, type); // Assuming each brand has 'imagePath' property
        brand.Brandurl = url.downloadUrl;  // Save the compressed image URL back into the brand object
      });
      console.log("this.filterBrandList", this.filterBrandList)
      this.loading = false
      if (this.brandId != undefined) {
        setTimeout(() => {
          let brandObject = this.brandDetailsAll.find((b) => b.id === this.brandId)
          // this.brandStoriyScrollA.nativeElement.scrollLeft -= 500;      
          this.commonService.scrollToElementByIdbrand(this.brandId, this.brandStoriyScrollA); // Adjust scroll amount as needed
          setTimeout(() => {
            this.selectBrandBySearch(this.brandId, brandObject)
            //  this.selectBrandForStory(this.brandId, brandObject)
          }, 100);
        },
          20)
        this.byScroll = false
      };
    })

  }



  ngOnChanges() {

  }



  ngOnInit() {
    this.newWidth = window.innerWidth;
    this.newHeight = window.innerHeight;
    this.updateMarginTop()
    this.logInFlag = this.commonService.isLoggedIn()
    let title = 'Yologram - Threads'
    this.commonService.changeTitle(title);
    this.loading = true;
    this.showBrandFlag = true
    this.byScroll = false
    console.log("byScroll 11", this.byScroll)
    // this.byScroll=false
    // this.pinterestAPI.getBoardList().subscribe((data) => {
    //   this.boards = data.items;
    //   console.log('this.boards', this.boards)
    //   this.brandsBoardCollections.subscribe((brandBoard) => {
    //     this.brandBoard = brandBoard;
    //   });
    // })

    this.brandCollecton()
    this.brandSerchFlag = true

    this.garmentsList.subscribe((grList) => {
      this.garmentsCollection = grList
      // if (this.brandId != undefined) { this.selectBrandForStory(this.brandId, this.brands) }
      this.brands.subscribe((brandList) => {
        this.brandDetails = brandList
        let object = this.brandDetails
        this.brandDetailsId = object.find((bBoard) => bBoard.id == this.brandId)
        if (this.brandId != undefined) {
          this.selectedBrand = this.brandDetailsId;
          this.boardDetails = this.boardNameInBrandStory
          let brandBoardFilter = this.brandBoard.find((bBoard) => bBoard.brandId == this.brandId)
          this.boardDetails = (brandBoardFilter != undefined) ? this.boards.find((board) => board.id == brandBoardFilter.boardId) : undefined;
        }
      })


      let garmentIds = this.garmentsCollection.map((garment) => garment.id);
      let garmentsObject = this.garmentsCollection.reduce((acc, obj) => {
        const key = obj.id;
        const curGroup = acc[key] ?? [];
        return { ...acc, [key]: obj.BrandID };
      }, {});
      this.commonService.snapshotfileDetais().subscribe(async (snapshot: any) => {
        this.SnapShotFileDetails = snapshot;
        this.SnapShotFileDetails.forEach((snapshot) => {
          if (garmentsObject[snapshot.HeadwearID] != undefined
            || garmentsObject[snapshot.BottomwearID] != undefined
            || garmentsObject[snapshot.UpperwearID] != undefined
            || garmentsObject[snapshot.FootwearID] != undefined
          )
            if (garmentsObject[snapshot.HeadwearID] != undefined && this.snapShotBrandList.indexOf(garmentsObject[snapshot.FootwearID]) == -1) this.snapShotBrandList.push(garmentsObject[snapshot.FootwearID]);
          if (garmentsObject[snapshot.BottomwearID] != undefined && this.snapShotBrandList.indexOf(garmentsObject[snapshot.BottomwearID]) == -1) this.snapShotBrandList.push(garmentsObject[snapshot.BottomwearID]);
          if (garmentsObject[snapshot.UpperwearID] != undefined && this.snapShotBrandList.indexOf(garmentsObject[snapshot.UpperwearID]) == -1) this.snapShotBrandList.push(garmentsObject[snapshot.UpperwearID]);
          if (garmentsObject[snapshot.FootwearID] != undefined && this.snapShotBrandList.indexOf(garmentsObject[snapshot.FootwearID]) == -1) this.snapShotBrandList.push(garmentsObject[snapshot.FootwearID]);
        })
      })
    })

    // let brandObject = this.brandDetailsAll.find((b) => b.id === this.brandId)
    // this.selectBrandBySearch(this.brandId,brandObject)


    // if (this.brandId) {
    //   this.newSelectBrand()
    // }



  }
  updateMarginTop(): void {
    this.marginTop = `-${window.innerHeight}px`;
  }


  // ngAfterViewInit(){
  //   console.log('o')
  //   let scrollLeft = this.brandsScroll.nativeElement
  //   this.inifiniteXScrolls(scrollLeft)
  // }

  ngAfterViewInit() {


    if (this.portpfolioName) {
      setTimeout(() => {
        this.setupInfiniteScrollPackForPortfolio();
      }, 100)
    }
    this.setupInfiniteScroll();
    this.setupInfiniteScrollPack();



    setTimeout(() => {


      this.setupInfiniteScrollPack();

    }, 100)


    setTimeout(() => {
      this.scrollTo(100);
    }, 100)

  }

  private scrollTo(offset: number) {
    this.brandStoriyScrollA.nativeElement.scrollBy(1, offset);
    this.brandStoriyScrollA.nativeElement.scrollBy(1, offset);
    this.brandSliderPortFolio.nativeElement.scrollBy(1, offset);
  }


  receveidPackdata(data) {
    this.packJsonObject = data
  }

  receveidZipList(data) {
    this.zipList = data
  }


  private setupInfiniteScroll(): void {

    const slider = this.brandStoriyScrollA.nativeElement;
    const moveElement = () => {
      // Use requestAnimationFrame for smoother visual updates
      requestAnimationFrame(() => {
        const firstChild = slider.firstElementChild as HTMLElement;
        const lastChild = slider.lastElementChild as HTMLElement;
        // Calculate the buffer based on the child width
        const childWidth = firstChild.offsetWidth;
        const buffer = childWidth * 2; // Adjust the buffer size as needed
        if (slider.scrollLeft + buffer >= slider.scrollWidth - slider.offsetWidth) {
          // Move the first child to the end and adjust scrollLeft
          slider.appendChild(firstChild);
          slider.scrollLeft -= firstChild.offsetWidth; // Adjust instantly without animation
        } else if (slider.scrollLeft <= buffer) {
          // Move the last child to the beginning and adjust scrollLeft
          slider.insertBefore(lastChild, firstChild);
          slider.scrollLeft += lastChild.offsetWidth; // Adjust instantly without animation
        }
      });
    };


    // Throttle scroll events
    let isScrolling;
    const onScroll = () => {
      window.clearTimeout(isScrolling);
      isScrolling = setTimeout(moveElement, 0); // Increase the timeout to delay the element movement
    };
    // Add smooth scrolling CSS transition if not already present
    slider.style.transition = 'scroll-left 0.1s ease-out';
    slider.addEventListener('scroll', onScroll);

  }

  private setupInfiniteScrollPack(): void {

    const slider = this.scrollContainerMUpper.nativeElement;
    const moveElement = () => {
      // Use requestAnimationFrame for smoother visual updates
      requestAnimationFrame(() => {
        const firstChild = slider.firstElementChild as HTMLElement;
        const lastChild = slider.lastElementChild as HTMLElement;
        // Calculate the buffer based on the child width
        const childWidth = firstChild.offsetWidth;
        const buffer = childWidth * 2; // Adjust the buffer size as needed
        if (slider.scrollLeft + buffer >= slider.scrollWidth - slider.offsetWidth) {
          // Move the first child to the end and adjust scrollLeft
          slider.appendChild(firstChild);
          slider.scrollLeft -= firstChild.offsetWidth; // Adjust instantly without animation
        } else if (slider.scrollLeft <= buffer) {
          // Move the last child to the beginning and adjust scrollLeft
          slider.insertBefore(lastChild, firstChild);
          slider.scrollLeft += lastChild.offsetWidth; // Adjust instantly without animation
        }
      });
    };


    // Throttle scroll events
    let isScrolling;
    const onScroll = () => {
      window.clearTimeout(isScrolling);
      isScrolling = setTimeout(moveElement, 0); // Increase the timeout to delay the element movement
    };
    // Add smooth scrolling CSS transition if not already present
    slider.style.transition = 'scroll-left 0.1s ease-out';
    slider.addEventListener('scroll', onScroll);

  }

  // private setupInfiniteScrollPackForPortfolio(): void {
  //   alert("hii"); // For debugging purposes

  //   const slider = this.brandSliderPortFolio.nativeElement;

  //   const moveElement = () => {
  //     // Use requestAnimationFrame for smoother visual updates
  //     // requestAnimationFrame(() => {
  //       const firstChild = slider.firstElementChild as HTMLElement;
  //       const lastChild = slider.lastElementChild as HTMLElement;
  //       // Calculate the buffer based on the child width
  //       const childWidth = firstChild.offsetWidth;
  //       const buffer = childWidth * 2; // Adjust the buffer size as needed
  //       if (slider.scrollLeft + buffer >= slider.scrollWidth - slider.offsetWidth) {
  //         // Move the first child to the end and adjust scrollLeft
  //         slider.appendChild(firstChild);
  //         slider.scrollLeft -= firstChild.offsetWidth; // Adjust instantly without animation
  //       } else if (slider.scrollLeft <= buffer) {
  //         // Move the last child to the beginning and adjust scrollLeft
  //         slider.insertBefore(lastChild, firstChild);
  //         slider.scrollLeft += lastChild.offsetWidth; // Adjust instantly without animation
  //       }
  //     // });
  //   };

  //   // Throttle scroll events
  //   let isScrolling;
  //   const onScroll = () => {
  //     window.clearTimeout(isScrolling);
  //     isScrolling = setTimeout(moveElement, 100); // Increase the timeout to delay the element movement
  //   };

  //   // Add smooth scrolling CSS transition if not already present
  //   // slider.style.transition = 'scroll-left 2s ease-in-out'; // Change the duration and easing function here
  //   slider.addEventListener('scroll', onScroll);
  // }


  private setupInfiniteScrollPackForPortfolio(): void {
    const slider = this.brandSliderPortFolio.nativeElement;
    const moveElement = () => {
      // Use requestAnimationFrame for smoother visual updates
      // requestAnimationFrame(() => {
      const firstChild = slider.firstElementChild as HTMLElement;
      const lastChild = slider.lastElementChild as HTMLElement;
      // Calculate the buffer based on the child width
      const childWidth = firstChild.offsetWidth;
      const buffer = childWidth - 30; // Adjust the buffer size as needed
      if (slider.scrollLeft + buffer >= slider.scrollWidth - slider.offsetWidth) {
        // Move the first child to the end and adjust scrollLeft
        slider.appendChild(firstChild);
        slider.scrollLeft -= firstChild.offsetWidth; // Adjust instantly without animation
      } else if (slider.scrollLeft <= buffer) {
        // Move the last child to the beginning and adjust scrollLeft
        slider.insertBefore(lastChild, firstChild);
        slider.scrollLeft += lastChild.offsetWidth; // Adjust instantly without animation
      }
      // });
    };

    // Throttle scroll events
    let isScrolling;
    const onScroll = () => {
      window.clearTimeout(isScrolling);
      isScrolling = setTimeout(moveElement, 80); // Increase the timeout to delay the element movement
    };

    // Add smooth scrolling CSS transition if not already present
    slider.style.transition = 'scroll-left 2s ease-in-out'; // Change the duration and easing function here
    slider.addEventListener('scroll', onScroll);
  }






  selectByScroll() {
    this.byScroll = true
    this.portFolioFlag = true;
  }
  portfolioBrandSelection() {
    if (this.portpfolioName) {
      this.byScroll = true;
      this.portFolioFlag = true;

    }
  }


  closeButtonForBrand() {
    // this.byScroll = false
    const isMobile = window.innerWidth <= 400;
    const maxLength = isMobile ? 3 : 16;
    console.log("maxlength", maxLength)
    if (this.portpfolioName && (this.filterBrandList.length <= maxLength || this.matchingBrandList.length <= maxLength)) {
      this.portfolioBrandSelection()

      console.log("byScroll 14", this.byScroll)
    }

    this.scrollFlag = false
    this.brandNameSelected = this.selectedBrand.BrandName != undefined ? this.selectedBrand.BrandName : 'Search Brand'
    const inputElement = document.getElementById("BrandName") as HTMLInputElement;
    // Now you can safely set its value to blank
    if (inputElement) {
      inputElement.value = "";
    }

  }


  changeClose(event) {
    console.log('Evenvt', event)
    if (!event) {
      if (this.portpfolioName) {
        this.commonService.scrollToElementByIdbrand(this.brandId, this.brandSliderPortFolio);
      } else {
        this.commonService.scrollToElementByIdbrand(this.brandId, this.brandStoriyScrollA);
      }
    }
    setTimeout(() => {
      this.byScroll = false
      console.log("byScroll 15", this.byScroll)
    }, 700)
  }

  toggle() {
    this.isShowStats = !this.isShowStats
  }

  async getBrandImage(imgURL: string) {


    let brandEncodedurl = encodeURI(imgURL).replace('/', '%2F');
    let url = "https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/" + brandEncodedurl + "?alt=media&token=550e7e88-bed3-4668-84ff-ad491c19e06c"
    return url;
  }


  closeButton() {


  }

  selectBrandBySearch(brandId, brand) {
    this.searchBrandId = brandId





    this.selectBrandForStory(brandId, brand);
    if (window.innerWidth > 768) {
      // Increase the threshold for desktop mode
      this.settimeOutFun++;
      if (this.settimeOutFun > 6) return;
    } else {
      // Default threshold for mobile mode
      this.settimeOutFun++;
      if (this.settimeOutFun > 3) return;
    }
    setTimeout(() => {
      this.selectBrandBySearch(brandId, brand);
      setTimeout(() => {
        if (this.portpfolioName) {
          this.byScroll = true
        } else {
          this.byScroll = false
        }
      }, 300);
    }, 100);


  }

  changeBrand(event) {
    this.newSelectedbrandId = event
    this.commonService.scrollToElementByIdbrand(this.newSelectedbrandId, this.brandStoriyScrollA);

  }


  selectBrandForStory(brandId: any, brands: any) {
    this.scrollFlag = false;
    this.containLoad = false;
    this.portFolioFlag = true;
    this.searchPanel = false

    if (this.lastSelectedBrandId === brandId) {
      if (this.portpfolioName) {
        setTimeout(() => {
          this.commonService.scrollToElementByIdbrand(brandId, this.brandSliderPortFolio);
        }, 200);
      } else {
        this.commonService.scrollToElementByIdbrand(brandId, this.brandStoriyScrollA);

      }
      // Adjust scroll amount as needed

      return; // Exit the function early
    }


    // setTimeout(() => {
    //   if(this.portpfolioName){
    //     this.commonService.scrollToElementByIdbrand(brandId,this.brandSliderPortFolio); // Adjust scroll amount as needed
    //    return; 
    //   }
    // },100);


    // Store the current brandId for future comparisons
    this.lastSelectedBrandId = brandId;

    // Reset data for new brand selection
    this.filterBrandListNew = [];
    this.brandSelectionFlag = true;
    const inputElement = document.getElementById("BrandName") as HTMLInputElement;
    if (inputElement) {
      inputElement.value = "";
    }

    // Continue with the rest of the function's logic...
    this.brandsIDdetailsAll = brandId;
    this.thresholdHeightPagination = 0;
    this.showBrandFlag = false;
    this.selectedBrand = brands;
    this.brandId = brandId;
    this.boardDetails = {};
    let brandBoardFilter = this.boardListCollection.find((bBoard) => bBoard.brandId == brandId);


    if (!brandBoardFilter) {
      this.boardFlag = false;
    }

    if (brandBoardFilter) {
      this.boardFlag = true;
      let brandName = this.boards.find((bname) => bname.id == brandBoardFilter.boardId);
      this.boardDetails = brandName;
    }
    let title = this.selectedBrand.BrandName + '- Yologram';
    this.commonService.changeTitle(title);
    this.brandNameSelected = this.selectedBrand.BrandName;

    if (window.innerWidth > 768) {


      if (this.portpfolioName) {

        this.commonService.scrollToElementByIdbrand(brandId, this.brandSliderPortFolio);

      } else {
        setTimeout(() => {
          this.commonService.scrollToElementByIdbrand(brandId, this.brandStoriyScrollA);
        }, 350);
      }


    } else {

      if (this.portpfolioName) {

        setTimeout(() => {
          this.commonService.scrollToElementByIdbrand(brandId, this.brandSliderPortFolio);
        }, 300);
      } else {

        setTimeout(() => {
          this.commonService.scrollToElementByIdbrand(brandId, this.brandStoriyScrollA);
        }, 100);
      }


    }


    this.addState();

    setTimeout(() => {
      this.closeButtonForBrand();

    }, 500);
    // this.scrollFlag = true;
    setTimeout(() => {
      this.modeflag = true
    }, 1000);

    setTimeout(() => {
      this.byScroll = false;
      if (this.portpfolioName) {
        this.portfolioBrand();
        this.portfolioFLAGnew = true;
        this.byScroll = false;
      }
    }, 1000);

  }

  clickToRemoveBlur() {
    this.byScroll = true
    console.log("byScroll 5", this.byScroll)
  }

  portfolioBrand() {
    // this.byScroll = false;
    this.portFolioFlag = false
    if (this.portpfolioName) {
      const isMobile = window.innerWidth <= 400;
      console.log("innerWidth==>", innerWidth)


      const maxLength = isMobile ? 3 : 16;
      console.log("maxlength", maxLength)


      if (this.filterBrandList.length <= maxLength || this.matchingBrandList.length <= maxLength) {

        this.byScroll = false;
        console.log("byScroll 6", this.byScroll)
        this.portFolioFlag = false;
        // const index = this.filterBrandList.indexOf(this.selectedBrand);
        // if (index !== -1) {
        //   const selectedBrand = this.filterBrandList[index];
        //   this.filterBrandList.splice(index, 1);  
        //   this.filterBrandList.unshift(selectedBrand);  
        // }

        // Find the index of selectedBrand in filterBrandList
        const index = this.filterBrandList.indexOf(this.selectedBrand);
        if (index !== -1) {
          // Remove selectedBrand from its current position
          this.filterBrandList.splice(index, 1);
        }

        // Add selectedBrand to the first position of filterBrandList
        this.filterBrandList.unshift(this.selectedBrand);






      }
    }
  }




  blankplaceHolder() {
    this.brandNameSelected = ''
  }


  colaaps() {
    this.showBCollasps = true
    this.showRender = false
  }
  selectBrandForScroll() {
    document.querySelector('#target').scrollIntoView({ behavior: 'smooth', block: 'center' });
  }
  brandCollecton() {
    let brandsNID = this.brandDetails.filter((nameOfBrand) => nameOfBrand.id === this.garmentsCollection.filter((brandOfGArment) => brandOfGArment.BrandID))
  }


  setTab(tab, gender) {
    this.isShowBrandBand = false
    this.selectedTab = "";
    this.selectedTab = tab;
    this.SelectedGender = gender
    this.selectedSnapData = false
    if (tab == 'story' || tab == 'news-stories') {
      this.isShowBrandBand = true
    }
    if (tab == 'mens-wear') {
      this.genderFlag = true
    } else {
      this.genderFlag = false
    }

  }

  changeTab(event) {
    console.log('event', event)
    this.changeTabFlag = false
    this.isShowBrandBand = false
    this.onChangeFlag = false;
    this.selectedTab = event;
    if (event == 'story' || event == 'news-stories') {
      // this.mode = 'S' 
      this.isShowBrandBand = true
    }

    if (event == 'womens-wear') {
      this.mode = 'W'
    }
    if (event == 'mens-wear') {
      this.mode = 'M'
    }
    if (event == 'story') {
      this.mode = 'S'
    }

    if (event == 'womens-wear' || event == 'mens-wear') {
      this.changeTabFlag = true
    }

    if (this.exclosiveMode == null) {
      this.exclosiveMode = 'N'
    }

    if (this.selectedBrand.Gender === 'M' && this.mode == null) {
      this.mode = 'M';
      this.selectedTab = 'mens-wear';
    } else if (this.selectedBrand.Gender === 'F' && this.mode == null) {
      this.mode = 'W'; // Assuming 'W' is for women's wear, considering 'F' was not used previously for mode
      this.selectedTab = 'womens-wear';
    } else if (this.selectedBrand.Gender === 'B' && this.mode == null) {
      this.mode = 'S'; // Assuming 'W' is for women's wear, considering 'F' was not used previously for mode
      this.selectedTab = 'story';
    }

    let stateObj = { id: "100" };
    let style3DPage = '/threads;brandId=';
    let url
    if (this.panelFlag == 'brandPanel') {
      url = style3DPage + this.brandId + ';M=' + this.mode + ';Ex=' + this.exclosiveMode;

    } else if (this.newGarmentId != undefined) {
      url = style3DPage + this.brandId + ';M=' + this.mode + ';Ex=' + this.exclosiveMode + ';garmentId=' + this.newGarmentId + ';Drape=' + this.Drape; +'index=' + this.index; + 'pageNumber=' + this.pageiIndex; + 'storyId' + this.storyId
    } else if (this.panelFlag != 'brandPanel') {
      url = style3DPage + this.brandId + ';M=' + this.mode + ';Ex=' + this.exclosiveMode;
    }

    window.history.pushState(stateObj, "Page", url);
  }


  changeCloth(event) {
    let clothId = event;
    this.colaaps()
    this.copyTextToClipGarment(clothId)
  }

  changeLoadingState(event) {
    this.loadingState = event

  }

  clickOnbrandHeart() {
    this.brandheartFlag = !this.brandheartFlag
  }

  clickOnHeart() {
    this.brandheartFlag = !this.brandheartFlag
  }

  onScroll() {
    this.height = this.BrandStoriyScroll.nativeElement.scrollTop
  }



  addState() {
    console.log('this.mode...', this.mode);
    let stateObj = { id: "100" };
    let setUrl = this.stylePage;
    if (setUrl !== undefined) {
      this.url = this.stylePage;
    }

    // Initial setting for mode based on Gender 'B'
    if (this.selectedBrand.Gender === 'B' && this.mode == null) {
      this.mode = 'S';
      this.selectedTab = 'story';
    }

    // Update mode and selectedTab based on Gender 'M' or 'F'
    if (this.selectedBrand.Gender === 'M' && this.modeInsatnceFlageVlaue) {
      this.mode = 'M';
      this.selectedTab = 'mens-wear';
    } else if (this.selectedBrand.Gender === 'F' && this.modeInsatnceFlageVlaue) {
      this.mode = 'W'; // Assuming 'W' is for women's wear, considering 'F' was not used previously for mode
      this.selectedTab = 'womens-wear';
    } else if (this.selectedBrand.Gender === 'B' && this.modeInsatnceFlageVlaue) {
      this.mode = 'S'; // Assuming 'W' is for women's wear, considering 'F' was not used previously for mode
      this.selectedTab = 'story';
    }



    // Additional logic if needed for handling mode when it's already defined
    if (this.mode !== null && !this.modeInsatnceFlageVlaue) {
      switch (this.mode) {
        case 'M':
          this.selectedTab = 'mens-wear';
          this.modeInsatnceFlageVlaue = this.mode;
          break;
        case 'W':
          this.selectedTab = 'womens-wear';
          this.modeInsatnceFlageVlaue = this.mode;
          break;
        case 'S':
          this.selectedTab = 'story';
          this.modeInsatnceFlageVlaue = this.mode;
          break;
        default:
          console.log('Unexpected mode value:', this.mode);
          break;
      }
    }
    if (this.exclosiveMode == null) {
      this.exclosiveMode = 'N'
    }
    this.changeTab(this.selectedTab);
    let style3DPage = '/threads;brandId=';
    let urlbrand
    console.log('this.exclosiveMode', this.exclosiveMode)
    let url
    urlbrand = style3DPage + this.brandId + ';M=' + this.mode + ';Ex=' + this.exclosiveMode;
    url = urlbrand


    // window.history.pushState(stateObj, "Page", url);
    console.log('url', url)


  }


  copyTextToClipGarment(text: string) {
    let Garments = this.garmentsCollection.find((gaementList) => gaementList.id === text);
    let brandId = Garments.BrandID
    let gender = Garments.Gender;
    this.GarmentsGender = Garments.Gender;
    let garmentType = Garments.GarmentType
    let zipfilename = Garments.id
    let packlocation = "AvatarBlenderClothes%2F" + brandId + "_" + gender + "%2F" + garmentType + "%2F" + zipfilename;
    this.garmentLink = 'https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/' + packlocation + '?alt=media&token=2c1dc261-ac91-4c32-a5ec-ac5e06f0a9bc&_gl=1*goch74*_ga*MjExMjI5MTIyMi4xNjg3NTA3MDk1*_ga_CW55HF8NVT*MTY5NjkzMDY1Ny4xNTcuMS4xNjk2OTM2NzA4LjU5LjAuMA..'
  }




  // suggest($event) {
  //   this.settimeOutFun = 0
  //   this.scrollFlag = true;
  //   this.byScroll = true;
  //   let inputValue = $event.target.value.toLowerCase();

  //   if (!inputValue) {
  //     console.log("matchingBrandList==>",this.matchingBrandList)

  //     if(this.portpfolioName){
  //       this.filterBrandList = this.matchingBrandList;
  //     }else{
  //       this.filterBrandList = this.brandDetailsAll;
  //     }
  //   } else {
  //     const startsWithMatches = this.brandDetailsAll.filter(brand =>
  //       brand.BrandName && brand.BrandName.toLowerCase().startsWith(inputValue)
  //     );

  //     const includesMatches = this.brandDetailsAll.filter(brand =>
  //       brand.BrandName && brand.BrandName.toLowerCase().includes(inputValue)
  //     ).filter(brand => !startsWithMatches.includes(brand));
  //     this.filterBrandListNew = startsWithMatches.slice(0, 10).concat(includesMatches.slice(0, 10));
  //   }

  // }

  suggest($event) {
    this.settimeOutFun = 0;
    this.scrollFlag = true;
    this.byScroll = true;
    console.log("byScroll 7", this.byScroll)
    let inputValue = $event.target.value.toLowerCase();

    if (!inputValue) {
      if (this.portpfolioName) {
        // If brandPortfolioname is available, search only within matchingBrandList
        this.filterBrandList = this.matchingBrandList;
      } else {
        // If brandPortfolioname is not available, search within brandDetailsAll
        this.filterBrandList = this.brandDetailsAll;
      }
    } else {
      if (this.portpfolioName) {
        // If brandPortfolioname is available, search only within matchingBrandList
        const startsWithMatches = this.matchingBrandList.filter(brand =>
          brand.BrandName && brand.BrandName.toLowerCase().startsWith(inputValue)
        );
        const includesMatches = this.matchingBrandList.filter(brand =>
          brand.BrandName && brand.BrandName.toLowerCase().includes(inputValue)
        ).filter(brand => !startsWithMatches.includes(brand));

        this.filterBrandListNew = startsWithMatches.slice(0, 10).concat(includesMatches.slice(0, 10));
      } else {
        // If brandPortfolioname is not available, search within brandDetailsAll
        const startsWithMatches = this.brandDetailsAll.filter(brand =>
          brand.BrandName && brand.BrandName.toLowerCase().startsWith(inputValue)
        );

        const includesMatches = this.brandDetailsAll.filter(brand =>
          brand.BrandName && brand.BrandName.toLowerCase().includes(inputValue)
        ).filter(brand => !startsWithMatches.includes(brand));

        this.filterBrandListNew = startsWithMatches.slice(0, 10).concat(includesMatches.slice(0, 10));
      }
    }
  }


  shareBrand(brandId: any) {
    let link = "https://yolomove-social-live.web.app/threads;brandId=" + brandId + ';M=' + this.mode + ';Ex=Y';
    // let text = 'Check out '+ this.brandNameSelected +' on Yologram '
    // console.log(text)
    if (navigator.share) {
      navigator.share({
        title: 'Share Brand',
        text: 'Check out ' + this.brandNameSelected + ' on Yologram ',
        url: link,
      })
        .then(() => console.log('Successful share'))
        .catch((error) => console.log('Error sharing', error));
    }
  }

  // getPortfolioId(){
  //   this.brandsPortfoliocollection.subscribe((portfolio) => {
  //     this.brandPortfolioList = portfolio
  //     console.log('details',this.brandPortfolioList)
  //     let porfolioName = this.brandPortfolioList.find((p) =>p.BrandPortfolioName == this.portpfolioName ) 
  //     this.brandsPortfolioDetailscollection.subscribe((portfolio) => {
  //       this.brandPortfolioDetailsList = portfolio
  //         let getBrandId =  this.brandPortfolioDetailsList.filter((p) =>p.BrandPortfolioId == porfolioName.BrandPortfolioId ) 
  //         console.log('getBrandId',getBrandId)
  //         this.filterBrandList

  //       })
  //   })
  // }

  getPortfolioId() {
    this.byScroll = false
    console.log("byScroll 8", this.byScroll)
    this.brandsPortfoliocollection.subscribe((portfolio) => {
      this.brandPortfolioList = portfolio;
      console.log('details', this.brandPortfolioList);
      let portfolioName = this.brandPortfolioList.find((p) => p.BrandPortfolioName == this.portpfolioName);

      if (portfolioName) {
        this.brandsPortfolioDetailscollection.subscribe((portfolioDetails) => {
          this.brandPortfolioDetailsList = portfolioDetails;
          let getBrandId = this.brandPortfolioDetailsList.filter((p) => p.BrandPortfolioId == portfolioName.BrandPortfolioId);
          console.log('getBrandId', getBrandId);

          // Assuming this.filterBrandList is already populated with the required data
          // Now, let's filter this array to find elements with BrandId that match any BrandId in getBrandId array
          this.matchingBrandList = this.brandDetailsAll.filter(brand =>
            getBrandId.find(detail => detail.BrandId === brand.id)
          );
          this.byScroll = true
          console.log("byScroll 9", this.byScroll)
          this.filterBrandList = this.matchingBrandList
          // matchingBrandList now contains elements from this.filterBrandList that have a matching BrandId in getBrandId
          console.log('Matching Brands', this.matchingBrandList);
        });
      }
    });
  }



  dummyselectBrandForStory() {
    let brandId = 'IKsMd2LU5Fru8yCJgpzs'
    this.scrollFlag = false;
    this.containLoad = false;
    this.portFolioFlag = true;
    let brandObject = this.brandDetailsAll.find((b) => b.id === brandId)
    this.filterBrandListNew = [];
    this.brandSelectionFlag = true;
    this.selectBrandBySearch(brandId, brandObject)
    const inputElement = document.getElementById("BrandName") as HTMLInputElement;
    if (inputElement) {
      inputElement.value = "";
    }
    // Continue with the rest of the function's logic...
    this.brandsIDdetailsAll = brandId;
    this.thresholdHeightPagination = 0;
    this.showBrandFlag = false;
    this.selectedBrand = brandObject;
    this.brandId = brandId;
    this.boardDetails = {};
    let brandBoardFilter = this.boardListCollection.find((bBoard) => bBoard.brandId == brandId);


    if (!brandBoardFilter) {
      this.boardFlag = false;
    }

    if (brandBoardFilter) {
      this.boardFlag = true;
      let brandName = this.boards.find((bname) => bname.id == brandBoardFilter.boardId);
      this.boardDetails = brandName;
    }




  }

  // selectByBrandDropDown($event) {
  //   let Brand = $event.target.value
  //   let brandObject = this.brandDetailsAll.find((brand) => brand.id == Brand)
  //   this.selectBrandForStory(Brand, brandObject)
  // }

  // newSelectBrand() {
  //   let Brand = this.brandId;
  //   this.brands.subscribe((brandList) => {
  //     let brandObject = brandList.find((brand) => brand.id == Brand);
  //     console.log("Brand", Brand);
  //     console.log("Brand", brandObject);
  //     console.log("Brand", brandList);

  //     // Move the logic that depends on brandObject inside the subscription callback
  //     this.selectBrandForStory(Brand, brandObject);
  //   });
  // }


  receveidPanelDetails(data) {

    this.isloadingGarment = false
    this.dripsPageAvatarData = data
    this.userflagPanel = false
    this.avatarflagPanel = true
    this.brandflagPanel = false
    this.evenpanelFlag = false
    this.garmentflagPanel = false
    this.searchPanel = false

    console.log("data data", data)
  }

  receveidPanelUser(data) {
    this.avatarflagPanel = false
    this.userflagPanel = true
    this.brandflagPanel = false
    this.evenpanelFlag = false
    this.garmentflagPanel = false
    this.searchPanel = false

    this.dripsPageUserData = data
    console.log("UserData", data)
  }


  receveidPanelbrand(data) {
    this.brandflagPanel = true
    this.avatarflagPanel = false
    this.evenpanelFlag = false
    this.userflagPanel = false
    this.garmentflagPanel = false
    this.searchPanel = false

    this.dripsPageBrandData = data
    console.log("dripsPageBrandData", data)
  }

  receveidPanelEven(data) {
    this.evndata = null
    this.brandflagPanel = false
    this.userflagPanel = false
    this.avatarflagPanel = false
    this.evenpanelFlag = true
    this.garmentflagPanel = false
    this.searchPanel = false

    this.evndata = data
    console.log("dripsPageBrandData", data)
  }


  receveidPanelGarment(data) {
    this.garmentflagPanel = true
    this.userflagPanel = false
    this.brandflagPanel = false
    this.avatarflagPanel = false
    this.evenpanelFlag = false
    this.searchPanel = false
    this.garmetData = data
    console.log("receveidPanelGarment", data)
  }



  receveidSearchData(data) {
    this.garmentflagPanel = false
    this.userflagPanel = false
    this.brandflagPanel = false
    this.avatarflagPanel = false
    this.evenpanelFlag = false
    this.searchPanel = true
    this.tempSnapShot = data.tempSnapShots
    this.singleSnap = data.snap
  }

  closeAvatarPalen(data: any) {
    console.log("Closing avatar panel", data);
    this.avatarflagPanel = false;
  }

  newindex(data: any) {
    this.storyInex = data
  }
  // Function to close the user panel
  closeUserPanel(data: any) {
    console.log("Closing user panel", data);
    this.userflagPanel = false;
  }
  closeSearchPanel(data) {
    this.searchPanel = false
    console.log("Closing user panel", data);

  }
  // Function to close the brand panel
  closeBrandPanel(data: any) {
    console.log("Closing brand panel", data);
    this.brandflagPanel = false;
  }

  // Function to close the environment panel
  closeEnvironmentPanel(data: any) {
    console.log("Closing environment panel", data);
    this.evenpanelFlag = false;
  }

  // Function to close the garment panel
  closeGarmentPanel(data: any) {
    console.log("Closing garment panel", data);
    this.garmentflagPanel = false;
  }

  handleevnCompressUrl(url) {
    this.compressUrl = url
  }


  handleSideNavSize(flagSize) {
    if (flagSize) {
      this.sideNavWidth_Fixed = 380
    } else if (!flagSize) {
      this.sideNavWidth_Fixed = 181
    }
  }

  handleSerchedStory(data) {
    if (data) {
      this.paginatedSnapshots = data
      this.searchPanel = true
      this.searchNavBarFlag = true
      this.sideNavWidth_Fixed = 181

    }
  }

  clearThreadsPage() {
    this.selectedBrand = null
    this.clearTab = false
    this.selectedTab = null
    this.showBrandFlag = true
    this.brandNameSelected = null
    this.brandSelectionFlag = false
    setTimeout(() => {
      this.clearTab = true
    }, 1);
  }


  handleSelectedSnap(data) {
    let snap = data.snapshot

    if( this.selectedTab == 'womens-wear' || this.selectedTab == 'mens-wear'){
      this.selectedSnapData = true
      this.selectedTab = 'story'
      this.nonsSelectedBrand = data
      this.nonsSelectedBrand.
      console.log("this.nonsSelectedBrand", this.nonsSelectedBrand)
      return
    }

    let selectedBrand = [];
      for (let garment of this.garmentsCollection) {
        if (
          garment.id === snap.HeadwearID ||
          garment.id === snap.UpperwearID ||
          garment.id === snap.BottomwearID ||
          garment.id === snap.FootWearID
        ) {
          selectedBrand.push(garment);
        }
      }
    

  
       
   
    console.log("selectedBrand", selectedBrand)
    console.log("selectedBrand", this.selectedBrand)

    let brand = selectedBrand.find((b)=>b.BrandID == this.selectedBrand.id)
    console.log("brand", brand)
    let brandFlag = true

    if(brand){
      console.log("selectedBrand", selectedBrand)
      brandFlag = false
      return
    }


    if (data) {
      if (this.exclosiveMode == 'N' && brandFlag) {
        console.log("snapData as selected", data)
        this.selectedSnapData = true
        this.clearThreadsPage()
        setTimeout(() => {
          this.searchPanel = true
          setTimeout(() => {
            this.nonsSelectedBrand = data
            console.log("this.nonsSelectedBrand", this.nonsSelectedBrand)
            console.log("this.nonsSelectedBrand", this.nonsSelectedBrand.userSnafilter)
          }, 200);

          let stateObj = { id: "100" };
          let url = '/threads'
          window.history.pushState(stateObj, "Page", url);
        }, 100);
      }
    }
  }

  setSearchNavBar() {
    if (this.sideNavWidth_Fixed === 181) {
      this.sideNavWidth_Fixed = 20;
      this.searchNavBarFlag = false
    } else if (this.sideNavWidth_Fixed === 20) {
      this.sideNavWidth_Fixed = 181;
      this.searchNavBarFlag = true
    }
  }


  
  handleSearchPanlOnThreads(data) {
    console.log(data)
    if (data) {
      this.searchPanel = true
    }
    if(!data){
      this.searchPanel = false
    }
  }


  handlesnapListEmit(data) {
    this.searchFilterData = data
    console.log("this.searchFilterData", this.searchFilterData)
    
  }

  handlesnapSelectedStory(data) {
    console.log("SnapSlected", data)
    if (data) {
      this.selectedStory = data
    }
  }

}

