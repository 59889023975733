<div *ngIf="loading" class="loading">Loading&#8230;</div>
<div class="dt-responsive selectlist font-14" >
    <div class="dataTables_wrapper dt-bootstrap4"  id="add-row-table_wrapper">
        <div #snapshotfeeds class="card m-3  slider-css"  [ngClass]=" tab =='news-stories' ? '' : 'hidden-class'">
          <div *ngFor="let snapshot of filterSnapShotFileDetails; let i=index; ">
                <div class="slider-img" >
                    <div class="m-2">
                        <!-- <h6>
                            <span><a class="col-lg-12" data-bs-toggle="modal" data-bs-target="#user-profile"
                                    (click)="getSnapShotListByUserPopup(snapshot)">{{snapshot.profileName}}</a>
                            </span>
                        </h6>
                        <h6>{{snapshot.timeStamp | date : 'medium' }}</h6> -->
                    <h6>
                        <div class="col-lg-12 d-flex">
                            <a class="col-lg-6 w-70 display-name" data-bs-toggle="modal" data-bs-target="#user-profile"
                                (click)="getSnapShotListByUserPopup(snapshot)">{{(snapshot.profileName)}}</a>
                                <h6  data-bs-toggle="modal"
                                data-bs-target="#Avatar-profile" (click)="getSnapShotListAvatarPopup(snapshot)"class="col-lg-6 text-end avatar-diaplay">🧍🏽{{snapshot.firstName}}{{snapshot.AvatarLastFive}}</h6>
                        </div>
                    </h6>
                    <div class="col-lg-12 d-flex">
                        <h6 class="col-lg-6 date-class">{{snapshot.timestamp | date : 'MMM d, y, h:mm a'}}</h6>
                        <h6 data-bs-toggle="modal" data-bs-target="#Envitonment-profile"
                        (click)="environmentPopup(snapshot.Environment)" class="col-lg-6 evn-font text-end">
                            {{getEnvironmentName(snapshot.Environment)}}</h6>
                    </div>
                        <span class="spinner-border second-spineer" *ngIf="isloading" role="status"></span>

                    </div>
                    <img class="img-fluid" width="480 !important" height="280" [src]="snapshot.SnapshotFileUrl" />
                    <h6 class="second-txt">
                        {{snapshot.StoryType}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        {{snapshot.StoryID}}
                        <span data-bs-toggle="modal" data-bs-target="#Envitonment-profile"
                        (click)="environmentPopup(snapshot.Environment)">
                        <h6 class="p-2">
                            {{getEnvironmentName(snapshot.Environment)}}
                        </h6>
                    </span>
                    </h6>
                    <div class="">
                        <div class="col-12 col-sm-12 slider-box">
                            <div class="col-12 col-sm-12" *ngIf="filterSnapShotFileDetails"><img
                                    *ngIf="getGarmentrealtype(snapshot.HeadwearID) == '0' "
                                    style="width:15px !important; color:black" src="./assets/newsfeed/headgear-new.png">
                                <span class="caption-values"
                                    *ngIf="snapshot.HeadwearID != undefined && snapshot.HeadwearID != ''">
                                    <span class="garment-name" data-toggle="tooltip" data-placement="top"
                                        title="Cloth Details" (click)="setClothDetails(snapshot.HeadwearID)"
                                        data-bs-toggle="modal" data-bs-target="#garment-details">
                                        {{getGarmentName(snapshot.HeadwearID)}}
                                    </span>&nbsp;
                                    <span class="brand-name" data-toggle="tooltip" data-placement="top"
                                        title="Brands Details" (click)="setBrandDetails(snapshot.HeadwearID)"
                                        data-bs-toggle="modal"
                                        data-bs-target="#brand-details">{{getBrandName(snapshot.HeadwearID)}}</span>
                                    <a
                                        (click)="shareOnPinterest(snapshot.HeadwearID,'headwear',snapshot.SnapshotFileUrl,snapshot.StoryID) ">
                                        <img class="img-thumbnail2" src="./assets/Pinterest-logo.png" />
                                    </a>
                                </span>
                            </div>
                            <div class="col-12 col-sm-12 " *ngIf="filterSnapShotFileDetails">
                                <img *ngIf="getGarmentrealtype(snapshot.UpperwearID) == '1' "
                                    style="width:15px !important; color:black" src="./assets/realtype/upperwear.png">
                                <img *ngIf="getGarmentrealtype(snapshot.UpperwearID) == '5' "
                                    style="width:15px !important; color:black" src="./assets/realtype/fullbody.png">
                                <span class="caption-values"
                                    *ngIf="snapshot.UpperwearID != undefined && snapshot.UpperwearID != ''">
                                    <span class="garment-name" data-toggle="tooltip" data-placement="top"
                                        title="Cloth Details" (click)="setClothDetails(snapshot.UpperwearID)"
                                        data-bs-toggle="modal" data-bs-target="#garment-details">
                                        {{getGarmentName(snapshot.UpperwearID)}}
                                    </span>&nbsp;
                                    <span class="brand-name" data-toggle="tooltip" data-placement="top"
                                        title="Brands Details" (click)="setBrandDetails(snapshot.UpperwearID)"
                                        data-bs-toggle="modal"
                                        data-bs-target="#brand-details">{{getBrandName(snapshot.UpperwearID)}}</span>
                                    <a
                                        (click)="shareOnPinterest(snapshot.UpperwearID,'upperwear',snapshot.SnapshotFileUrl,snapshot.StoryID)">
                                        <img class="img-thumbnail2" src="./assets/Pinterest-logo.png" />
                                    </a>
                                </span>
                            </div>
                            <div class="col-12 col-sm-12 " *ngIf="filterSnapShotFileDetails">
                                <img *ngIf="getGarmentrealtype(snapshot.BottomwearID) == '2'"
                                    style="width:15px !important; color:black" src="./assets/newsfeed/bottomwear-new.png">
                                <span class="caption-values"
                                    *ngIf="snapshot.BottomwearID != undefined && snapshot.BottomwearID != ''">
                                    <span class="garment-name" data-toggle="popover" data-placement="left"
                                        title="Cloth Details" (click)="setClothDetails(snapshot.BottomwearID)"
                                        data-bs-toggle="modal" data-bs-target="#garment-details">
                                        {{getGarmentName(snapshot.BottomwearID)}}
                                    </span>&nbsp;
                                    <span class="brand-name" data-toggle="popover" data-placement="right"
                                        title="Cloth Details" (click)="setBrandDetails(snapshot.BottomwearID)"
                                        data-bs-toggle="modal" data-bs-target="#brand-details">
                                        {{getBrandName(snapshot.BottomwearID)}}
                                    </span>
    
                                    <a d
                                        (click)="shareOnPinterest(snapshot.BottomwearID,'upperwear',snapshot.SnapshotFileUrl,snapshot.StoryID)">
                                        <img class="img-thumbnail2" src="./assets/Pinterest-logo.png" />
                                    </a>
                                </span>
                            </div>
                            <div class="col-12 col-sm-12 " *ngIf="filterSnapShotFileDetails">
                                <img *ngIf="getGarmentrealtype(snapshot.FootwearID) == '3' "
                                    style="width:15px !important; color:black" src="./assets/realtype/footwear.png">
                                <span class="caption-values"
                                    *ngIf="snapshot.FootwearID != undefined && snapshot.FootwearID != ''">
                                    <span class="garment-name" data-toggle="popover" data-placement="left"
                                        title="Cloth Details" (click)="setClothDetails(snapshot.FootwearID)"
                                        data-bs-toggle="modal" data-bs-target="#garment-details">
                                        {{getGarmentName(snapshot.FootwearID)}}
                                    </span>&nbsp;
                                    <span class="brand-name" data-toggle="popover" data-placement="right"
                                        title="Cloth Details" (click)="setBrandDetails(snapshot.FootwearID)"
                                        data-bs-toggle="modal" data-bs-target="#brand-details">
                                        {{getBrandName(snapshot.FootwearID)}}
                                    </span>
    
                                    <a
                                        (click)="shareOnPinterest(snapshot.FootwearID,'footwear',snapshot.SnapshotFileUrl,snapshot.StoryID)">
                                        <img class="img-thumbnail2 " src="./assets/Pinterest-logo.png" />
                                    </a>
    
                                </span>
    
                            </div>
                        </div>
                    </div>
                </div>
   
        </div>
        </div>
    </div>
    <div class="modal fade imp-bg" id="user-profile" tabindex="-1" aria-labelledby="user-profile" aria-hidden="true"
        data-bs-backdrop="false">
        <div class="modal-dialog  ">
            <div class="modal-content imp-block" *ngIf="filterSnapShotForProfile">
                <h5 type="button" class="btn-close  closBtn" data-bs-dismiss="modal" aria-label="Close"></h5>

                <div class="container">
                    <div class="row text-center">
                        <div class="row text-start p-2">
                            <div class="col-12 m-3">
                                <img style="width: 70px; height:70px !important;" class="rounded-circle"
                                    data-bs-dismiss="modal" (click)="getUserSnapShotDashboar(profileDetails.uid)"
                                    src="{{profileDetails.photoURL}}" />
                                <h2 class="w-100" data-bs-dismiss="modal"
                                    (click)="getUserSnapShotDashboar(profileDetails.uid)">
                                    {{profileDetails.displayName}}</h2>
                                <div><button type="button" class="btn btn-outline-info m-1 follow">Follow</button></div>


                            </div>
                            <div class="row d-flex">
                                <div data-bs-dismiss="modal"
                                    class="text-center col-6">
                                    <span>
                                        <div >
                                            <img class="img-fluid img-thumbnail" style="height: 100px; width: 100px !important; object-fit: contain;
                                        " src="./assets/imgplaceholder.jpg">
                                        </div>
                                    </span>
                                </div>
                                <div data-bs-dismiss="modal"
                                    class="text-center col-6">
                                    <span>
                                        <div >
                                            <img class="img-fluid img-thumbnail" style="height: 100px; width: 100px !important; object-fit: contain;
                                        " src="./assets/imgplaceholder.jpg">
                                        </div>
                                    </span>
                                </div>
                            </div>
                            <div class="row w-100 m-2"
                                *ngIf="filterSnapShotForProfile.length >= 0  && filterSnapShotForProfile != undefined && filterSnapShotForProfile.length >= 0 ">
                                <div class="column d-block m-1"
                                    *ngFor="let snapshot of filterSnapShotForProfile  ; let i=index;">
                                    <img class="w-100 shadow-1-strong rounded" data-bs-dismiss="modal"
                                    (click)="getUserSnapShotDashboar(profileDetails.uid)" [src]="snapshot.SnapshotFileUrl">
                                </div>
                                <div class="columnImg d-block m-1">
                                    <img class="w-80 shadow-1-strong rounded" data-bs-dismiss="modal">
                                </div>
                                <div class="columnImg d-block m-1">
                                    <img class="w-80 shadow-1-strong rounded" data-bs-dismiss="modal">
                                </div>
                                <div class="columnImg d-block m-1" *ngIf="filterSnapShotForProfile.length > 2"
                                    (click)="getUserSnapShotDashboar(profileDetails.uid)">
                                    <img style="margin-top: -165px;" class="w-80 shadow-1-strong rounded"
                                        data-bs-dismiss="modal" src="./assets/plus-8-512.png">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" id="garment-details" tabindex="-1" aria-labelledby="garment-details" aria-hidden="true"
        data-bs-backdrop="false">
        <div class="modal-dialog" id="garment-details">
            <div class="modal-content imp-block-garment">
                <div class="modal-body">
                    <h5 type="button" class="btn-close  closBtn" data-bs-dismiss="modal" aria-label="Close"></h5>
                    <div class="container">
                        <div class="row text-center justify-content-center">
                            <div class="row text-center p-2 justify-content-center">
                                <div class="col-12 m-2 details" *ngIf="clothDetails.BrandID != undefined">
                                    <div class="col-lg-12 text-center w-100 m-1 d-flex justify-content-center  d-flex">
                                        <div class="text-center d-flex  ">
                                            <img data-bs-dismiss="modal"
                                                (click)="getBrandProfileByGarment(clothDetails.BrandID,clothDetails.boardName)"
                                                class="rounded profileImg-brand"
                                                style="width:35px !important; height:35px !important;"
                                                [src]="getBrandImage(clothDetails.LogoURL)">
                                        </div>
                                        <div class="p-1"><b
                                                (click)="getBrandProfileByGarment(clothDetails.BrandID,clothDetails.boardName)" data-bs-dismiss="modal">{{clothDetails.BrandName}}</b>
                                        </div>
                                        <div><button type="button"
                                                class="btn btn-sm btn-outline-info follow">Follow</button></div>
                                    </div>



                                    <!-- <b>

                                        <h5 data-bs-dismiss="modal" (click)="getClothProfile(clothDetails.BrandID)"
                                            [ngClass]="clothDetails.Name.length >= 20  ? 'class-font' : ''"
                                            class=" text-black clothName">{{(clothDetails.Name)}}</h5>
                                    </b> -->
<!-- 
                                    <b class="d-flex" (click)="getClothProfile(clothDetails.BrandID)">
                                        <span *ngIf="clothDetails.RealType == '0'" class="brandbtn py-1"><img
                                                           style="width:16px !important; color:black ;padding-bottom: 3px;"
                                                           src="./assets/realtype/headgear.png"></span>
                                                   <span *ngIf="clothDetails.RealType == '1'" class="brandbtn py-1"><img
                                                           style="width:16px !important; color:black ;padding-bottom: 3px;"
                                                           src="./assets/realtype/upperwear.png"></span><span *ngIf="clothDetails.RealType == '2'"
                                                       class="brandbtn py-1"><img style="width:16px !important; color:black ;padding-bottom: 5px;"
                                                           src="./assets/realtype/bottomwear.png"></span><span *ngIf="clothDetails.RealType == '3'"
                                                       class="brandbtn py-1"><img style="width:16px !important; color:black ;padding-bottom: 3px;"
                                                           src="./assets/realtype/footwear.png"></span><span *ngIf="clothDetails.RealType == '4'"
                                                       class="brandbtn py-1"><img style="width:16px !important; color:black ;padding-bottom: 3px;"
                                                           src="./assets/realtype/heeledfootwear.png"></span><span *ngIf="clothDetails.RealType == '5'"
                                                       class="brandbtn py-1"><img style="width:16px !important; color:black ;padding-bottom: 3px;"
                                                           src="./assets/realtype/fullbody.png"></span>
                                       <h5 class="text-black clothName"  [ngClass]="clothDetails.Name.length >= 20  ? 'class-font' : ''" data-bs-dismiss="modal"
                                      >
                                           {{(clothDetails.Name)}}</h5>
                                   </b> -->
   
                                   <!-- <b>
                                       <h6 class="color-class"> {{clothDetails.Gender == 'F' ? 'Womenswear' :
                                           (clothDetails.Gender == 'M') ? 'Menswear' : 'N/A'}}</h6>
                                   </b> -->







                                   <b>
                                    <h5 data-bs-dismiss="modal" (click)="getClothProfile(clothDetails.BrandID)"
                                        [ngClass]="clothDetails.Name.length >= 20  ? 'class-font' : ''"
                                        class="text-black clothName">
                                                 <span *ngIf="clothDetails.RealType == '0'" class="brandbtn py-1"><img
                                                        style="width:16px !important; color:black ;padding-bottom: 3px;"
                                                        src="./assets/realtype/headgear.png"></span>
                                                <span *ngIf="clothDetails.RealType == '1'" class="brandbtn py-1"><img
                                                        style="width:16px !important; color:black ;padding-bottom: 3px;"
                                                        src="./assets/realtype/upperwear.png"></span><span *ngIf="clothDetails.RealType == '2'"
                                                    class="brandbtn py-1"><img style="width:16px !important; color:black ;padding-bottom: 5px;"
                                                        src="./assets/realtype/bottomwear.png"></span><span *ngIf="clothDetails.RealType == '3'"
                                                    class="brandbtn py-1"><img style="width:16px !important; color:black ;padding-bottom: 3px;"
                                                        src="./assets/realtype/footwear.png"></span><span *ngIf="clothDetails.RealType == '4'"
                                                    class="brandbtn py-1"><img style="width:16px !important; color:black ;padding-bottom: 3px;"
                                                        src="./assets/realtype/heeledfootwear.png"></span><span *ngIf="clothDetails.RealType == '5'"
                                                    class="brandbtn py-1"><img style="width:16px !important; color:black ;padding-bottom: 3px;"
                                                        src="./assets/realtype/fullbody.png"></span>
                                        {{(clothDetails.Name)}}</h5>
                                </b>
                                <b>
                                    <h6 class="color-class"> {{clothDetails.Gender == 'F' ? 'Womenswear' :
                                        (clothDetails.Gender == 'M') ? 'Menswear' : 'N/A'}}</h6>
                                </b>

                                    <h6 data-bs-dismiss="modal" (click)="getClothProfile(clothDetails.BrandID)" >Collection:
                                        {{clothDetails.CollectionName}}</h6>
                                    <h6 data-bs-dismiss="modal" (click)="getClothProfile(clothDetails.BrandID)">Details:
                                        {{clothDetails.Details}}</h6>
                                    <h6 data-bs-dismiss="modal" (click)="getClothProfile(clothDetails.BrandID)">SKU: {{clothDetails.SKU}}</h6>
                                </div>
                                <div class="row">
                                    <div class=" text-center col-6"
                                        *ngFor="let fileList of userFiledetails;let p=index">
                                        <span *ngIf="p < counter">
                                            <div *ngIf="fileList.fileType == 'GarmentUpload2D'" data-bs-dismiss="modal" (click)="getClothProfile(clothDetails.BrandID)">
                                                <img class="img-fluid img-thumbnail" style="height: 100px; width: 100px !important; object-fit: contain;
                                                " src={{fileList.fileurl}}>
                                            </div>
                                            <div class="justify-content-center col-6 "
                                                *ngIf="fileList.fileType == 'GarmentUpload3D'" data-bs-dismiss="modal" (click)="getClothProfile(clothDetails.BrandID)">
                                                <div class="text-center col-6 ">
                                                    <model-viewer camera-controls camera-orbit="45deg 55deg 2.5m" ar
                                                        auto-rotate src={{fileList.fileurl}}
                                                        alt="A 3D avatar-model-view" data-js-focus-visible=""
                                                        ar-status="not-presenting"
                                                        style="height: 100px; width: 150px; float: none;">
                                                    </model-viewer>
                                                </div>
                                            </div>
                                        </span>
                                    </div>
                                </div>
                              

                                <!-- <div class="p-2 m-1 text-center d-flex justify-content-center">
                                    <h6  *ngIf="clothDetails.ThumbCenterURL  != undefined" class="m-1 brandbtn"><a href="{{clothDetails.ThumbCenterURL}}" target="_blank"><img
                                          style="width:15px !important; color:black" src="./assets/details.png"></a></h6>
                                    <h6 *ngIf="clothDetails.ThumbLeftURL  != undefined" class="m-1 brandbtn"><a href="{{clothDetails.ThumbLeftURL}}" target="_blank"><img
                                          style="width:15px !important" src="./assets/gallary.png"></a></h6>
                                    <h6  *ngIf="clothDetails.ThumbRightURL  != undefined" class="m-1 brandbtn"><a href="{{clothDetails.ThumbRightURL}}" target="_blank"><img
                                          style="width:15px !important; color:black" src="./assets/shop.png"></a></h6>
                                  </div>
                                  <div class="p-2 m-1 text-center d-flex justify-content-center">
                                    <h6  *ngIf="clothDetails.ThumbCenterURL  == undefined" class="m-1 brandbtn"><a><img style="width:15px !important; color:black" src="./assets/details-gray.png"></a> </h6>
                                    <h6  *ngIf="clothDetails.ThumbLeftURL  == undefined" class="m-1 brandbtn"><a><img style="width:15px !important" src="./assets/gallary-gray.png"></a></h6>
                                    <h6  *ngIf="clothDetails.ThumbRightURL  == undefined" class="m-1 brandbtn"><a><img style="width:15px !important; color:black" src="./assets/cart-gray.png"></a>
                                    </h6>
                                  </div> -->



                                  <div class="p-2 m-1 text-center d-flex justify-content-center">
                                    <h6 data-bs-dismiss="modal" class="text-white brandbtn m-1" (click)="getStoryCloth(clothDetails)">
                                        <img style="width:15px !important; color:black" src="./assets/storystyle/story-white.png">
                                    </h6>
                                    <h6 data-bs-dismiss="modal" (click)="getgarmentStyle(clothDetails)"
                                        class="text-white brandbtn m-1">
                                        <img style="width:18px !important; color:black" src="./assets/storystyle/style-white.png">
                                    </h6>
                                    <h6  *ngIf="clothDetails.ThumbCenterURL  != undefined" class="m-1 brandbtn"><a href="{{clothDetails.ThumbCenterURL}}" target="_blank"><img
                                          style="width:15px !important; color:black" src="./assets/details.png"></a></h6>
                                          <h6  *ngIf="clothDetails.ThumbCenterURL  == undefined" class="m-1 brandbtn"><a><img style="width:15px !important; color:black" src="./assets/details-gray.png"></a></h6>
            
                                    <h6 *ngIf="clothDetails.ThumbLeftURL  != undefined" class="m-1 brandbtn"><a href="{{clothDetails.ThumbLeftURL}}" target="_blank"><img
                                          style="width:15px !important" src="./assets/gallary.png"></a></h6>
                                          <h6  *ngIf="clothDetails.ThumbLeftURL  == undefined" class="m-1 brandbtn"><a><img style="width:15px !important" src="./assets/gallary-gray.png"></a></h6>
            
                                    <h6  *ngIf="clothDetails.ThumbRightURL  != undefined" class="m-1 brandbtn"><a href="{{clothDetails.ThumbRightURL}}" target="_blank"><img
                                          style="width:15px !important; color:black" src="./assets/shop.png"></a></h6>
                                          <h6  *ngIf="clothDetails.ThumbRightURL  == undefined" class="m-1 brandbtn"><a><img style="width:15px !important; color:black" src="./assets/cart-gray.png"></a>
                                          </h6>
                                  </div>

                                <!-- <div class="p-2 m-2 justify-content-center text-center d-flex"
                                    *ngIf="clothDetails != undefined">
                                    <h6 data-bs-dismiss="modal" class="text-white brandbtn m-2"
                                        (click)="getStoryCloth(clothDetails)">Story</h6>
                                    <h6 data-bs-dismiss="modal" (click)="getgarmentStyle(clothDetails)"
                                        class="text-white brandbtn m-2">Style</h6>
                                </div> -->
                                <div class="row w-100 m-1"
                                    *ngIf="clothShapShotsList.length >= 0  && clocthCollectionIdcollection != undefined && clocthCollectionIdcollection.length >= 0 ">
                                    <div class="column d-block m-1"
                                        *ngFor="let snapshot of clothShapShotsList  ; let i=index;">
                                        <img class="w-100 shadow-1-strong rounded" data-bs-dismiss="modal"
                                            *ngIf="clothDetails.BrandID != undefined"
                                            (click)="getgarmentfeed(brandDetails,clothDetails)"
                                            [src]="snapshot.SnapshotFileUrl">
                                    </div>
                                    <div class="columnImg d-block m-1">
                                        <img class="w-80 shadow-1-strong rounded" data-bs-dismiss="modal">
                                    </div>
                                    <div class="columnImg d-block m-1">
                                        <img class="w-80 shadow-1-strong rounded" data-bs-dismiss="modal">
                                    </div>
                                    <div class="columnImg d-block m-1" *ngIf="clothShapShotsList.length > 8"
                                        (click)="getClothProfile(clothDetails.BrandID)">
                                        <img style="margin-top: -165px;" class="w-80 shadow-1-strong rounded"
                                            data-bs-dismiss="modal" src="./assets/plus-8-512.png">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade imp-bg" id="brand-details" tabindex="-1" aria-labelledby="brand-details" aria-hidden="true"
        data-bs-backdrop="false">
        <div class="modal-dialog  ">
            <div class="modal-content imp-block" *ngIf="brandDetails && brandDetails.BrandName">
                <h5 type="button" class="btn-close  closBtn" data-bs-dismiss="modal" aria-label="Close"></h5>
                <div class="container">
                    <div class="row text-center">
                        <div class="row text-center p-2">
                            <div class="col-12 m-3">
                                <img style="width: 75px; height:75px !important;" class="rounded profileImg-brand p-1"
                                    data-bs-dismiss="modal" style="width: 75px; height:75px !important; "
                                    (click)="getBrandProfile(brandDetails,brandDetails.boardName)"
                                    [src]="getBrandImage(brandDetails.LogoURL)" />
                                <h2 class="w-100" data-bs-dismiss="modal"
                                    (click)="getBrandProfile(brandDetails,brandDetails.boardName)">
                                    {{(brandDetails.BrandName)}}</h2>

                                <div><button type="button" class="btn btn-outline-info m-2 follow">Follow</button></div>
                                <h6><img style="width:15px !important" src="./assets/pinterest-512.png">:
                                    {{brandDetails.boardName}}</h6>
                            </div>
                            <div class="p-1 text-center d-flex justify-content-center">
                                <h6 class="m-1 text-white brandbtn"
                                    [ngClass]="brandDetails.id == brandId  ? 'border-bottom border-light' : 'border-bottom border-light'">
                                    <b>Story</b>
                                </h6>
                                <h6 data-bs-dismiss="modal" (click)="getBrandStyle(brandDetails.id,'M')"
                                    class="m-1 text-white brandbtn">Menswear</h6>
                                <h6 data-bs-dismiss="modal" (click)="getBrandStyle(brandDetails.id,'F')"
                                    class="m-1 text-white brandbtn">Womenswear</h6>
                            </div>
                            <div class="row w-100 m-2"
                                *ngIf="brandhShapShotsList.length >= 0  && brandCollectionIdcollection != undefined && brandCollectionIdcollection.length >= 0 ">
                                <div class="column d-block m-1"
                                    *ngFor="let snapshot of brandhShapShotsList  ; let i=index;">
                                    <img class="w-100 shadow-1-strong rounded" data-bs-dismiss="modal"
                                    (click)="scrollToStoryId(snapshot)" [src]="snapshot.SnapshotFileUrl">
                                    <!-- (click)="getFeed(snapshot,brandDetails)" -->
                                </div>
                                <div class="columnImg d-block m-1">
                                    <img class="w-80 shadow-1-strong rounded" data-bs-dismiss="modal">
                                </div>
                                <div class="columnImg d-block m-1">
                                    <img class="w-80 shadow-1-strong rounded" data-bs-dismiss="modal">
                                </div>
                                <div class="columnImg d-block m-1" *ngIf="brandhShapShotsList.length > 8"
                                    (click)="getBrandProfile(brandDetails,brandDetails.boardName)">
                                    <img style="margin-top: -165px;" class="w-80 shadow-1-strong rounded"
                                        data-bs-dismiss="modal" src="./assets/plus-8-512.png">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade imp-bg" id="Avatar-profile" tabindex="-1" aria-labelledby="Avatar-profile" aria-hidden="true"
        data-bs-backdrop="false">
        <div class="modal-dialog  ">
            <div class="modal-content imp-block" *ngIf="filterSnapShotForProfile">
                <h5 type="button" class="btn-close  closBtn" data-bs-dismiss="modal" aria-label="Close"></h5>
                <div class="container">
                    <div class="row text-center">
                        <div class="row text-center p-2">
                            <div class="col-12 m-3">
                                <img style="width: 70px; height:70px !important;" class="rounded-circle"
                                    data-bs-dismiss="modal" (click)="getSnapShotListByUser(profileDetails.uid)"
                                    src="{{profileDetails.photoURL}}" />
                                <h2 class="w-100" data-bs-dismiss="modal"
                                    (click)="getSnapShotListByUser(profileDetails.uid)">
                                    {{profileDetails.displayName}}</h2>
                                <a (click)="getProfileByAvatorpage(selectedAvtar)">🧍🏽{{selectedAvtar}} </a>
                                <div><button type="button" class="btn btn-outline-info m-1 follow">Follow</button></div>
                                <div><button type="button" class="btn btn-outline-info m-1 follow"
                                        data-bs-dismiss="modal" (click)="getuserfeed(selectedAvtar)">Avatar
                                        Feed</button></div>
                            </div>
                            <div class="row d-flex">
                                <div data-bs-dismiss="modal"
                                    class="text-center col-6">
                                    <span>
                                        <div >
                                            <img class="img-fluid img-thumbnail" style="height: 100px; width: 100px !important; object-fit: contain;
                                        " src="./assets/imgplaceholder.jpg">
                                        </div>
                                    </span>
                                </div>
                                <div data-bs-dismiss="modal"
                                    class="text-center col-6">
                                    <span>
                                        <div >
                                            <img class="img-fluid img-thumbnail" style="height: 100px; width: 100px !important; object-fit: contain;
                                        " src="./assets/imgplaceholder.jpg">
                                        </div>
                                    </span>
                                </div>
                            </div>
                            <div class="row w-100 m-2"
                                *ngIf="filterSnapShotForAvatar.length >= 0  && filterSnapShotForAvatar != undefined && filterSnapShotForAvatar.length >= 0 ">
                                <div class="column d-block m-1"
                                    *ngFor="let snapshot of filterSnapShotForAvatar  ; let i=index;">
                                    <img class="w-100 shadow-1-strong rounded" data-bs-dismiss="modal"
                                        (click)="getFeed(snapshot,brandDetails)" [src]="snapshot.SnapshotFileUrl">
                                </div>
                                <div class="columnImg d-block m-1">
                                    <img class="w-80 shadow-1-strong rounded" data-bs-dismiss="modal">
                                </div>
                                <div class="columnImg d-block m-1">
                                    <img class="w-80 shadow-1-strong rounded" data-bs-dismiss="modal">
                                </div>
                                <div class="columnImg d-block m-1" *ngIf="filterSnapShotForProfile.length > 2"
                                    (click)="getBrandProfile(brandDetails.id,brandDetails.boardName)">
                                    <img style="margin-top: -165px;" class="w-80 shadow-1-strong rounded"
                                        data-bs-dismiss="modal" src="./assets/plus-8-512.png">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade imp-bg" id="fileView-profile" tabindex="-1" aria-labelledby="user-profile" aria-hidden="true"
        data-bs-backdrop="false">
        <div class="modal-dialog justify-content-center ">
            <div class="modal-content imp-block justify-content-center">
                <h5 type="button" class="btn-close  closBtn" data-bs-dismiss="modal" aria-label="Close"></h5>
                <img src={{showImages.fileurl}}>
            </div>
        </div>
    </div>
    <div class="modal fade imp-bg" id="Envitonment-profile" tabindex="-1" aria-labelledby="Avatar-profile"
    aria-hidden="true" data-bs-backdrop="false">
    <div class="modal-dialog  ">
        <div class="modal-content imp-block" *ngIf="filterEnvironmentSnpShot">
            <h5 type="button" class="btn-close  closBtn" data-bs-dismiss="modal" aria-label="Close"></h5>
            <div class="container">
                <div class="row text-center">
                    <div class="row text-center p-2">
                        <div class="col-12 m-3"
                            *ngIf="evnFileListFileName && evnFileListFileName['displayName']  && evnFiledefault == undefined">
                            <h2 (click)="getEnvironmentProfile(evnFileListFileName['envFileName'])">
                                {{evnFileListFileName['displayName']}}</h2>
                            <div><button type="button" class="btn btn-outline-info m-1 follow" data-bs-dismiss="modal">Preview</button></div>
                        </div>
                        <div class="col-12 m-3"
                            *ngIf="evnFiledefault != undefined && evnFileListFileName ==  undefined">
                            <h2 (click)="getEnvironmentProfile(evnFiledefault)">{{evnFiledefault}}</h2>
                            <div><button type="button" class="btn btn-outline-info m-1 follow" data-bs-dismiss="modal">Preview</button></div>
                        </div>

                        <div class="row w-100 m-2"
                            *ngIf="filterEnvironmentSnpShot.length >= 0  && filterEnvironmentSnpShot != undefined && filterEnvironmentSnpShot.length >= 0 ">
                            <div class="column d-block m-1"
                                *ngFor="let snapshot of filterEnvironmentSnpShot  ; let i=index;">
                                <img class="w-100 shadow-1-strong rounded" data-bs-dismiss="modal"
                                (click)="getEnvironmentProfile(evnFiledefault)" [src]="snapshot.SnapshotFileUrl">
                            </div>
                            <div class="columnImg d-block m-1">
                                <img class="w-80 shadow-1-strong rounded" data-bs-dismiss="modal">
                            </div>
                            <div class="columnImg d-block m-1">
                                <img class="w-80 shadow-1-strong rounded" data-bs-dismiss="modal">
                            </div>
                            <div class="columnImg d-block m-1" *ngIf="filterEnvironmentSnpShot.length > 8"
                                (click)="getEnvironmentProfile(evnFileListFileName['envFileName'])">
                                <img style="margin-top: -165px;" class="w-80 shadow-1-strong rounded"
                                    data-bs-dismiss="modal" src="./assets/plus-8-512.png">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</div>
<!-- && tab !='news-stories' -->

<div *ngIf="tab =='story' " class="story justify-content-center  brand-profile-color"  >
    <div class="row m-1 justify-content-center"
        *ngIf="filterSnapShotFileDetails.length >= 0  && filterSnapShotFileDetails != undefined && filterSnapShotFileDetails.length >= 0">
        <div class="column d-block m-1 justify-content-center " *ngFor="let snapshot of filterSnapShotFileDetails  ; let i=index;">
            <img class="columnImg w-90 shadow-1-strong rounded d-block m-1" (click)="scrollToStoryId(snapshot)" [src]="snapshot.SnapshotFileUrl">
        </div>
    </div>
</div>
<div *ngIf="tab =='mens-wear'" class="brand-profile-color"  >
    <div class="col-lg-12 p-1">
        <span *ngIf="errMessage != '' " class="danger">
          <div class="alert alert-danger" role="alert">
            {{errMessage}}
          </div>
        </span>
      </div>
    <div class="card file brand-profile-color " *ngIf="!isloading">
        <div class="row   g-2 form d-block " *ngIf="packJsonObject.length > 0">
          <div class="slider-css211 ">
            <div *ngFor="let object of packJsonObject[0].value['files'];let i=index" class="slider-img211">
              <img class="img-responsive1" [src]="sanitizer.bypassSecurityTrustUrl(zipList[object])" />
            </div>
          </div>
          <!-- <div class="slider-css2">
            <div *ngFor="let object of packJsonObject[1].value['files'];let i=index" class="slider-img2">
              <img class="img-responsive" [src]="sanitizer.bypassSecurityTrustUrl(zipList[object])" />
            </div>
          </div> -->
          <div class="slider-css2-upper">
            <div *ngFor="let object of packJsonObject[1].value['files'];let i=index" class="slider-img2-upper" 
                 data-bs-toggle="collapse" data-bs-target="#info-3D"
                  saria-expanded="false" aria-controls="info-3D" >
                  <div class="upper-div">
                     <img class="img-responsive-upper" [src]="sanitizer.bypassSecurityTrustUrl(zipList[object])" 
              /></div>
            </div>
        </div>
          <div class="slider-css2">
            <div *ngFor="let object of packJsonObject[2].value['files'];let i=index" class="slider-img2">
              <img class="img-responsive" [src]="sanitizer.bypassSecurityTrustUrl(zipList[object])" />
            </div>
          </div>
          <div class="slider-css211">
            <div *ngFor="let object of packJsonObject[3].value['files'];let i=index" class="slider-img211">
              <img class="img-responsive1" [src]="sanitizer.bypassSecurityTrustUrl(zipList[object])" />
            </div>
          </div>
          <div class="d-flex justify-content-center">
            <div class="spinner-border" *ngIf="isloading" role="status">
              <span class=""></span>
            </div>
          </div>
        </div>
      </div>

</div>

<div *ngIf="tab =='womens-wear'" class="brand-profile-color">
        <div class="card file brand-profile-color " *ngIf="!isloading">
            <div class="col-lg-12 p-1">
                <span *ngIf="errMessage != '' " class="danger">
                  <div class="alert alert-danger" role="alert">
                    {{errMessage}}
                  </div>
                </span>
              </div>
            <div class="row   g-2 form d-block " *ngIf="packJsonObject.length >= 0">
              <div class="slider-css211 ">
                <div *ngFor="let object of packJsonObject[0].value['files'];let i=index" class="slider-img211">
                  <img class="img-responsive1" [src]="sanitizer.bypassSecurityTrustUrl(zipList[object])" />
                </div>
              </div>
              <!-- <div class="slider-css2">
                <div *ngFor="let object of packJsonObject[1].value['files'];let i=index" class="slider-img2">
                  <img class="img-responsive" [src]="sanitizer.bypassSecurityTrustUrl(zipList[object])" />
                </div>
              </div> -->
              
              <div class="slider-css2-upper">
                <div *ngFor="let object of packJsonObject[1].value['files'];let i=index" class="slider-img2-upper" 
                     data-bs-toggle="collapse" data-bs-target="#info-3D"
                      saria-expanded="false" aria-controls="info-3D" >
                      <div class="upper-div">
                         <img class="img-responsive-upper" [src]="sanitizer.bypassSecurityTrustUrl(zipList[object])" 
                  /></div>
                </div>
            </div>
              <div class="slider-css2">
                <div *ngFor="let object of packJsonObject[2].value['files'];let i=index" class="slider-img2">
                  <img class="img-responsive" [src]="sanitizer.bypassSecurityTrustUrl(zipList[object])" />
                </div>
              </div>
              <div class="slider-css211">
                <div *ngFor="let object of packJsonObject[3].value['files'];let i=index" class="slider-img211">
                  <img class="img-responsive1" [src]="sanitizer.bypassSecurityTrustUrl(zipList[object])" />
                </div>
              </div>
              <div class="d-flex justify-content-center">
                <div class="spinner-border" *ngIf="isloading" role="status">
                  <span class=""></span>
                </div>
              </div>
            </div>
          </div>
</div>
