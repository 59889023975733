<div class="card m-3  slider-css  brand-profile-color">
      <div class="col-lg-12 m-1 justify-content-center text-center">
        <div class="container" *ngIf="brandProfileDetails">
             <div class="col-lg-12 d-flex justify-content-center">
                <div class="col-lg-12 text-center w-100 m-1" ><img class="img-profile img-thumbnail img-fluid rounded profileImg-brand"
                   style="width: 75px; height:75px !important;" [src]="getBrandImage(brandProfileDetails.LogoURL)"></div> 
                   <!-- <div class="col-lg-0"><button type="button" class="btn btn-sm btn-outline-info m-1 brand-feed color-class"
                     (click)="getBrandStories(brandProfileDetails.id)">Brand Feed</button></div> -->
               </div>
            <h3 class="color-class" >{{brandProfileDetails.BrandName}}</h3>
            <button type="button" class="btn btn-sm btn-outline-info m-1 color-class">Follow</button>
            <h6   class="color-class"*ngIf="boardNameDetails != undefined"><img style="width:15px !important" src="./assets/pinterest-512.jpg">:
                {{boardNameDetails}}
            </h6>
            <h6 class="color-class" *ngIf="boardNameDetails == undefined"><img style="width:15px !important" src="./assets/pinterest-512.jpg">:
              Not Linked To Board
            </h6>
        </div>
        <div class="p-2 m-1 text-center d-flex justify-content-center ">
                    <h6  class="m-1 text-primary brandbtn color-class"
                [ngClass]="brandProfileDetails.id == brandId  ? 'border-bottom border-dark' : ''"><b>Story</b></h6>
            <h6  (click)="getBrandStyle(brandProfileDetails.id,'M')"
            class="m-1 text-primary brandbtn color-class">Menswear</h6>
            <h6 (click)="getBrandStyle(brandProfileDetails.id,'F')"
            class="m-1 text-primary brandbtn color-class">Womenswear</h6>
        </div>
        <div class="row justify-content-center mb-1"
            *ngIf="userSnapShotList.length >= 0 ">
            <div class="column d-block" *ngFor="let snapshot of userSnapShotList ; let i=index;">
                <img class="w-90 shadow-1-strong rounded mb-3" (click)="getFeed(snapshot,brandProfileDetails)"
                    [src]="snapshot.SnapshotFileUrl">
                   
            </div>
            
        </div>
    </div>
</div>
<!-- <app-brand-style></app-brand-style> -->