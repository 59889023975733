<div class="col-lg-12  justify-content-center scroll text-center" *ngIf="clothDetailsList != undefined">
  <div class="container garment-alingn">
    <div class="col-lg-12 justify-content-center garmentname">
      <div class="contain-fluid m-1 ">
        <div class="col-lg-12 text-center w-100   pt-4px  d-flex justify-content-center  d-flex">
          <div class="text-center col-2 d-flex  "><img class="profileImg-brand"
              style="width:35px; height:35px !important;" (click)="openBrandPoup()"
              [src]="getBrandImage(brandName.LogoURL)">
          </div>
          <div class="m-0 col-8 ">
            <div class="container d-block col-12  ">
              <h6 class=" color-class brand-font-size m-1" (click)="openBrandPoup()">{{brandName.BrandName}}</h6>
              <div class=" col-12 details " *ngIf="clothDetailsList != undefined">
                <b>
                  <h6 class=" color-class">{{clothDetailsList.Gender == 'F' ? 'Womenswear' :
                    (clothDetailsList.Gender == 'M') ? 'Menswear' : 'N/A'}}</h6>
                </b>
              </div>
            </div>
          </div>
          <div class="m-0  col-2 text-end">
              <span><img *ngIf="heartFlag == true" class="heart" style="width:18px !important; color:black"
                  src="./assets/storystyle/newStoryIcon/heart.png"></span>
              <span (click)="clickOnHeart()"><img *ngIf="heartFlag == false" class="heart"
                  style="width:18px !important; color:black" src="./assets/storystyle/newStoryIcon/heartGray.png">
              </span>
          </div>
        </div>
      </div>
    </div>
    <b>

      <h6 class="clothName color-class brand-font-size"> <span *ngIf="clothDetailsList.RealType == '0'"
          class="brandbtn py-1"><img style="width:16px !important; color:black ;padding-bottom: 3px;"
            src="./assets/realtype/headgear.png"></span>
        <span *ngIf="clothDetailsList.RealType == '1'" class="brandbtn py-1"><img
            style="width:18px !important; color:black ;padding-bottom: 3px;"
            src="./assets/realtype/upperwear.png"></span><span *ngIf="clothDetailsList.RealType == '2'"
          class="brandbtn py-1"><img style="width:18px !important; color:black ;padding-bottom: 5px;"
            src="./assets/realtype/bottomwear.png"></span><span *ngIf="clothDetailsList.RealType == '3'"
          class="brandbtn py-1"><img style="width:18px !important; color:black ;padding-bottom: 3px;"
            src="./assets/realtype/footwear.png"></span><span *ngIf="clothDetailsList.RealType == '4'"
          class="brandbtn py-1"><img style="width:18px !important; color:black ;padding-bottom: 3px;"
            src="./assets/realtype/heeledfootwear.png"></span><span *ngIf="clothDetailsList.RealType == '5'"
          class="brandbtn py-1"><img style="width:18px !important; color:black ;padding-bottom: 3px;"
            src="./assets/realtype/fullbody.png"></span>
        {{(clothDetailsList.Name)}}
      </h6>
    </b>
    <div class="details">
      <h6 class=" color-class">Collection: {{clothDetailsList.CollectionName}}</h6>
      <h6 class=" color-class">Details: {{clothDetailsList.Details}}</h6>
      <h6 class=" color-class">SKU: {{clothDetailsList.SKU}}</h6>
    </div>
  </div>
  <div class="imagesDiv">
    <div class="row twoDImage">
      <div class="col-6" *ngFor="let fileList of userFiledetails;let p=index">
        <span *ngIf="p < counter">
          <div *ngIf="fileList.fileType == 'GarmentUpload2D'">
            <img class="img-fluid img-thumbnail" style="height: 100px; width: 100px !important;     object-fit: contain;
                " src={{fileList.fileurl}} data-bs-toggle="modal" data-bs-target="#user-profile"
              (click)="openImageBox(fileList.fileurl)">
          </div>
          <div (click)="openModel(fileList.fileurl)" class="d-inline-block"
            *ngIf="fileList.fileType == 'GarmentUpload3D'">
            <model-viewer camera-controls camera-orbit="45deg 55deg 2.5m" ar auto-rotate src={{fileList.fileurl}}
              alt="A 3D avatar-model-view" data-js-focus-visible="" ar-status="not-presenting"
              style="height: 100px; width: 150px; float: none;">
            </model-viewer>
          </div>
        </span>
      </div>
    </div>
  </div>
  <div *ngIf="userFiledetails != undefined">
    <button class="btn btn-sm color-class"
      *ngIf="userFiledetails.length != 0 && counter <= userFiledetails.length && counter != userFiledetails.length "
      (click)="incrementImgCounter()">More</button>
  </div>
  <div class="p-2 m-1 text-center d-flex justify-content-center">

    <h6 class="text-primary brandbtn m-2 color-class"
      [ngClass]=" brandId != undefined ? 'border-bottom border-dark' : ''"
      (click)="getStoryForGenderCloth(clothDetailsList)"><img style="width:15px !important; color:black"
        src="./assets/storystyle/story-white.png"></h6>
    <h6 class="m-1 text-primary brandbtn color-class"><img style="width:18px !important; color:black"
        src="./assets/storystyle/style-white.png">
    </h6>
    <h6 *ngIf="clothDetailsList.ThumbCenterURL  != undefined" class="m-1 brandbtn"><a
        href="{{clothDetailsList.ThumbCenterURL}}" target="_blank"><img style="width:15px !important; color:black"
          src="./assets/details.png"></a></h6>
    <h6 *ngIf="clothDetailsList.ThumbCenterURL  == undefined" class="m-1 brandbtn"><a><img
          style="width:15px !important; color:black" src="./assets/details-gray.png"></a> </h6>
    <h6 *ngIf="clothDetailsList.ThumbLeftURL  != undefined" class="m-1 brandbtn"><a
        href="{{clothDetailsList.ThumbLeftURL}}" target="_blank"><img style="width:15px !important"
          src="./assets/gallary.png"></a></h6>
    <h6 *ngIf="clothDetailsList.ThumbLeftURL  == undefined" class="m-1 brandbtn"><a><img style="width:15px !important"
          src="./assets/gallary-gray.png"></a></h6>
    <h6 *ngIf="clothDetailsList.ThumbRightURL  != undefined" class="m-1 brandbtn"><a
        href="{{clothDetailsList.ThumbRightURL}}" target="_blank"><img style="width:15px !important; color:black"
          src="./assets/shop.png"></a></h6>
    <h6 *ngIf="clothDetailsList.ThumbRightURL  == undefined" class="m-1 brandbtn"><a><img
          style="width:15px !important; color:black" src="./assets/cart-gray.png"></a>
    </h6>
  </div>



  <div class="row g-2 form d-block  " id="target" *ngIf="packJsonObject.length > 0">
    <div class="slider-css211 " data-bs-toggle="modal" data-bs-target="#twoD-Modal">
      <div *ngFor="let object of packJsonObject[0].value['files'];let i=index" class="slider-img211">
        <img (click)="open2DModelPopUp(zipList[object],'headgear',object, 'HeadwearID')"
          *ngIf="clothType == packJsonObject[0].title && clothId == object " class="img-responsive1"
          [src]="sanitizer.bypassSecurityTrustUrl(zipList[object])" />
      </div>
    </div>
    <div class="slider-css2" data-bs-toggle="modal" data-bs-target="#2D-view">
      <div *ngFor="let object of packJsonObject[1].value['files'];let i=index" class="slider-img2">
        <img (click)="open2DModelPopUp(zipList[object],'upperwear',object,'UpperwearID')"
          *ngIf="clothType == packJsonObject[1].title && clothId == object " class="img-responsive"
          [src]="sanitizer.bypassSecurityTrustUrl(zipList[object])" />
      </div>
    </div>
    <div class="slider-css2" data-bs-toggle="modal" data-bs-target="#2D-view">
      <div *ngFor="let object of packJsonObject[2].value['files'];let i=index" class="slider-img2">
        <img (click)="open2DModelPopUp(zipList[object],'bottomwear',object ,'BottomwearID')"
          *ngIf="clothType == packJsonObject[2].title && clothId == object " class="img-responsive"
          [src]="sanitizer.bypassSecurityTrustUrl(zipList[object])" />
      </div>
    </div>
    <div class="slider-css211" data-bs-toggle="modal" data-bs-target="#2Dview">
      <div *ngFor="let object of packJsonObject[3].value['files'];let i=index" class="slider-img211">
        <img (click)="open2DModelPopUp(zipList[object],'footwear',object,'FootwearID')"
          *ngIf="clothType == packJsonObject[3].title && clothId == object " class="img-responsive1"
          [src]="sanitizer.bypassSecurityTrustUrl(zipList[object])" />
      </div>
    </div>
  </div>
  <div class="d-flex justify-content-center " *ngIf="isloading">
    <div class="spinner-border" role="status">
      <span class=""></span>
    </div>
  </div>
  <div class="modal fade imp-bg" id="user-profile" tabindex="-1" aria-labelledby="user-profile" aria-hidden="true"
    data-bs-backdrop="false">
    <div class="modal-dialog justify-content-center ">
      <div class="modal-content imp-block justify-content-center">
        <h5 type="button" class="btn-close  closBtn" data-bs-dismiss="modal" aria-label="Close"></h5>
        <img src={{showImages.fileurl}}>
      </div>
    </div>
  </div>
</div>