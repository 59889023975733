<app-yologram-social *ngIf="logInFlag"></app-yologram-social>
<div #brandStoriyScroll class="div-margin roksan box1">
    <div class="brand-profile-color" (scroll)="onScroll()">
        <button class="scroll-button left" (click)="scrollsLeft()">
            &#8249; <!-- Left arrow icon -->
        </button>
        <div #brandStoriyScrollA (mousedown)="onMouseDown($event)" (mouseleave)="onMouseLeave()" (mouseup)="onMouseUp()"
            (mousemove)="onMouseMove($event)" [ngClass]="scrollFlag ? 'd-none' : ''"
            class="slider-css brand-profile-color" (scroll)="selectByScroll()">
            <div class="" *ngFor="let brand of assignBrandList" [ngClass]="byScroll != true   ? 'prt-Evt' :''">
                <div *ngIf="selectedBrand == brand  ">
                    <div
                        [ngClass]="selectedBrand == brand  ? 'shadow-brand-slide' : (selectedBrand != undefined && !byScroll ? 'd-none' :'')">
                        <div class="text-end me-1 mt-1" style="pointer-events: auto;">
                        </div>
                    </div>
                </div>
                
                <img [id]="brand.id" class="m-1" style="height:60px !important; width:80px !important;"
                    [ngClass]="selectedBrand == brand ? 'shadow-brand' : (selectedBrand != undefined && !byScroll ? 'blug-css' :'')"
                    (click)="selectBrand(brand)" [src]="brand.logoUrl" />
            </div>
            <div *ngIf="loading" class="loading">Loading&#8230;</div>
            <button class="scroll-button right" (click)="scrollRight()">
                &#8250; <!-- Right arrow icon -->
            </button>
        </div>
         <div [ngClass]="!scrollFlag ? 'd-none' : ''" class="slider-css brand-profile-color">
        <div class="" *ngFor="let brand of filterBrandListNew" [ngClass]="byScroll != true ? 'prt-Evt' :''">
            <img [id]="brand.id" class="m-1" style="height:60px !important; width:80px !important; background: white;"
                [ngClass]="selectedBrand == brand ? 'shadow-brand' : (selectedBrand != undefined && !byScroll ? 'blug-css' :'')"
                (click)="selectBrand(brand)" [src]="brand.logoUrl" />
        </div>
    </div>
        <div class="input-wrapper">
            <input id="BrandName" type="text" class="form-control custom-input no-rounded" (input)="suggest($event)"
                (click)="blankplaceHolder()">
            <label for="BrandName" class="placeholder-start">{{brandNameSelected}}</label>
        </div>

        <div class="fixed-container" *ngIf="this.selectedBrandId">
            <div class="justify-content-center text-center  brand-profile-sy p-1">
                <div class="col-lg-12 garmentName">
                    <div class="justify-content-center text-center d-flex set-margin">
                        <h6 class="text-primary brandbtn m-1 py-2 d-block color-class" (click)="setTab('story','')">
                            <div *ngIf="tab == 'story'"
                                [ngClass]="tab == 'story' ? 'story-menu-card' : 'story-menu-icon'">
                                <img style="width: 8px; padding-bottom: 4px;" src="./assets/storystyle/storyblack.png">
                            </div>
                            <div *ngIf="tab == 'news-stories'"
                                [ngClass]="tab == 'news-stories' ? 'story-menu-card-gray' : 'story-menu-icon'">
                                <img style="width: 8px; padding-bottom: 4px;" src="./assets/storystyle/story-white.png">
                            </div>
                            <img *ngIf="tab != 'story' && tab != 'news-stories' "
                                [ngClass]="tab != 'story' && tab != 'news-stories' ? 'story-menu-icon' : ''"
                                style="color:black ;padding-bottom: 5px;" src="./assets/storystyle/story-white.png">
                            <h6 *ngIf="tab == 'story' || tab == 'news-stories' " class="color-class-sy m-1  story_name">
                                Stories</h6>
                        </h6>
                        <h6 class="text-primary brandbtn m-1 py-2 d-block color-class"
                            [ngClass]="this.loadingState   ? 'loadingState-disabled' : ''">
                            <img *ngIf="tab == 'mens-wear'"
                                [ngClass]="tab == 'mens-wear' ? 'story-menu' : 'story-menu-icon'"
                                style="color:black ;padding-bottom: 5px;" src="./assets/storystyle/MenIconBlack.png">
                            <img (click)="setTab('mens-wear','M')" *ngIf="tab != 'mens-wear'"
                                [ngClass]="tab != 'mens-wear' ? 'story-menu-icon' : ''"
                                style="color:black ;padding-bottom: 5px;" src="./assets/storystyle/MenIconWhite.png">
                            <h6 *ngIf="tab == 'mens-wear'" class="color-class-sy m-1">Menswear</h6>
                        </h6>
                        <h6 class="text-primary brandbtn m-1 py-2 d-block color-class"
                            [ngClass]="this.loadingState   ? 'loadingState-disabled' : ''">
                            <img *ngIf="tab == 'womens-wear'"
                                [ngClass]="tab == 'womens-wear' ? 'story-menu' : 'story-menu-icon'"
                                style="color:black ;padding-bottom: 5px;" src="./assets/storystyle/WomenIconBlack.png">
                            <img (click)="setTab('womens-wear','F')" *ngIf="tab != 'womens-wear'"
                                [ngClass]="tab != 'womens-wear' ? 'story-menu-icon' : ''"
                                style="color:black ;padding-bottom: 5px;" src="./assets/storystyle/WomenIconWhite.png">
                            <h6 *ngIf="tab == 'womens-wear'" class="color-class-sy m-1 w-margin">Womenswear</h6>
                        </h6>
                    </div>
                </div>

                <div id="mySidenav" class="sidenav" [style.height.px]="sideNavHeight"
                    [ngClass]="!showTheBrandMode && storeFrontData != undefined ? 'main-store-select' : ''">
                    <div #storyContainer
                        *ngIf="!showBrandFlag  && !isloadingBrandPanel &&  (tab =='story' || tab =='news-stories') "
                        class="my-story" style="overflow: auto;">
                        <div *ngIf="tab =='story' && userSnapShotList.length == 0" class="danger">
                            <div class="alert alert-danger" role="alert">
                                "Stories will available soon"
                            </div>
                        </div>
                        <div *ngIf="tab =='story' && userSnapShotList.length == 0" class="text-center">
                            <div class="spinner-border m-5" role="status">
                                <span class="sr-only"></span>
                            </div>
                        </div>
                        <div class="grid-container"
                            *ngIf="userSnapShotList.length > 0 && userSnapShotList !== undefined">
                            <div class="grid-item" *ngFor="let snapshot of userSnapShotList; let i = index;">
                                <img (click)="selectImage(i, snapshot.StoryID)" [src]="snapshot.SnapshotFileUrlMini" />
                            </div>
                        </div>
                    </div>
                    <div *ngIf="!flipFlag">
                        <div class="seleted-store-view"
                            *ngIf="!showTheBrandMode && storeFrontData && ((this.storeFrontData.StoreFrontType == '2DVideo' || this.storeFrontData.StoreFrontType == '2DImage'))">
                            <div class="store-back-twice" style="background-image: url('assets/store_back.jpg'); ">
                                <i *ngIf="!showTheBrandMode && storeFrontData" class="bi bi-trash-fill class-delete"
                                    (click)="deleteStoreFront(storeFrontData)"></i>

                                <img (click)="openStoreImageLargView(showStoreFrontSaveImage?.fileurl)"
                                    *ngIf="!showTheBrandMode && storeFrontData.StoreFrontType == '2DImage'"
                                    class="tag-image"
                                    style="width: 180px; height: 180px; margin-top: 60%; margin-right: 25%;"
                                    [src]="showStoreFrontSaveImage?.fileurl" />


                                <img (click)="openStoreVideoLargView(showStoreFrontSaveImage?.fileurl)"
                                    *ngIf="!showTheBrandMode && storeFrontData.StoreFrontType == '2DVideo'"
                                    class="tag-image"
                                    style="width: 180px; height: 180px; margin-top: 60%; margin-right: 25%; "
                                    [src]="showStoreFrontSaveImage?.fileurlMini" />

                                <!-- <img (click)="openStoreGltfLargView(showStoreFrontSaveImage?.fileurl)"
                                    *ngIf="!showTheBrandMode && storeFrontData.StoreFrontType == '3DSingle'"
                                    class="tag-image" style="width: 80px; height: 80px;"
                                    [src]="showStoreFrontSaveImage?.fileurlMini" /> -->

                                <!-- <model-viewer id="viewer"
                                    (click)="openStoreGltfLargView(showStoreFrontSaveImage?.fileurl)"
                                    *ngIf="!showTheBrandMode && storeFrontData.StoreFrontType == '3DSingle'"
                                    camera-controls camera-orbit="45deg 55deg 2.5m" ar auto-rotate
                                    [src]="showStoreFrontSaveImage?.fileurl" alt="A 3D avatar-model-view"
                                    data-js-focus-visible="" ar-status="not-presenting"
                                    style="height: 80px; width: 80px; float: none; margin-left: 60px;"
                                    class="tag-image">
                                </model-viewer> -->


                                <div *ngIf="!showTheBrandMode && storeFrontData.StoreFrontType == '2DVideo'"
                                    class="tag-lebel">
                                    Video is tag to the brand
                                </div>
                                <div *ngIf="!showTheBrandMode && storeFrontData.StoreFrontType == '2DImage'"
                                    class="tag-lebel">
                                    Image is tag to the brand
                                </div>
                            </div>
                        </div>
                        <div *ngIf="tab =='womens-wear' &&  isLoadingStyle == false && showTheBrandMode"
                            class="d-flex my" [ngClass]="this.loadingState   ? 'no-touch' : ''">
                            <div class=" file womenswear_scroller ">
                                <div class="file" *ngIf="!isLoading ">
                                    <div class="row form "
                                        *ngIf="packJsonObject?.length > 0 && packJsonObject[0]?.value != undefined">
                                        <div #scrollContainerWHeadGear class="slider-css-head"
                                            (scroll)="scrollAndUnZipFileIntilize('headgear')">
                                            <div *ngFor="let object of packJsonObject[0]?.value.files;let i=index"
                                                id="elementwomens-wearheadgear{{i}}" class="slider-img2-upper">
                                                <div class="d-flex justify-content-center" *ngIf="!zipList[object]">
                                                    <div class="spinner-border spn-load text-white" role="status">
                                                    </div>
                                                </div>
                                                <div class="head-div">
                                                    <img class="img-responsive-foot" *ngIf="zipList[object]"
                                                        [src]="zipList[object] ? sanitizer.bypassSecurityTrustUrl(zipList[object]) : ' '"
                                                        (click)="DeatailsPopup(zipList[object],'headgear', object,'HeadwearID',brandFlag )" />
                                                </div>
                                                <img (click)="open3Dflag(zipList[object],'headgear',object ,'HeadwearID')"
                                                    class="profileInfo  upperwear_info col-sm-2"
                                                    style="width:25px; height:25px !important;"
                                                    src="/assets/realtype/planinfoicon.png">
                                            </div>
                                        </div>
                                        <div #scrollContainerWUpper class="slider-css2-upper"
                                            (scroll)="scrollAndUnZipFileIntilize('upperwear')">
                                            <div *ngFor="let object of packJsonObject[1]?.value.files;let i=index"
                                                id="elementwomens-wearupperwear{{i}}" class="slider-img2-upper">
                                                <div class="upper-div">
                                                    <div class="d-flex justify-content-center" *ngIf="!zipList[object]">
                                                        <div class="spinner-border spn-load text-white" role="status">
                                                        </div>
                                                    </div>
                                                    <img *ngIf="zipList[object]" class="img-responsive-upper"
                                                        [src]="zipList[object] ? sanitizer.bypassSecurityTrustUrl(zipList[object]) : ' '"
                                                        (click)="DeatailsPopup(zipList[object],'upperwear',object,'UpperwearID',brandFlag)" />
                                                    <img (click)="open3Dflag(zipList[object],'upperwear',object ,'UpperwearID')"
                                                        class="profileInfo   col-sm-2"
                                                        style="width:25px; height: 25px !important;"
                                                        src="./assets/realtype/planinfoicon.png">
                                                </div>
                                            </div>
                                        </div>
                                        <div #scrollContainerWBottom class="slider-css2-upper"
                                            (scroll)="scrollAndUnZipFileIntilize('bottomwear')">
                                            <div *ngFor="let object of packJsonObject[2]?.value.files;let i=index"
                                                id="elementwomens-wearbottomwear{{i}}" class="slider-img2-upper">
                                                <div class="d-flex justify-content-center" *ngIf="!zipList[object]">
                                                    <div class="spinner-border spn-load text-white" role="status">
                                                    </div>
                                                </div>
                                                <div class="bottom-div">
                                                    <img class="img-responsive-bottom" *ngIf="zipList[object]"
                                                        [src]="zipList[object] ? sanitizer.bypassSecurityTrustUrl(zipList[object]) : ' '"
                                                        (click)="DeatailsPopup(zipList[object],'bottomwear', object,'BottomwearID',brandFlag )" />
                                                </div>
                                                <img (click)="open3Dflag(zipList[object],'bottomwear',object ,'BottomwearID')"
                                                    class="profileInfo  upperwear_info col-sm-2"
                                                    style="width:25px; height:25px !important;"
                                                    src="/assets/realtype/planinfoicon.png">
                                            </div>
                                        </div>
                                        <div #scrollContainerWFootwear class="slider-css2-upper"
                                            (scroll)="scrollAndUnZipFileIntilize('footwear')">
                                            <div *ngFor="let object of packJsonObject[3]?.value.files;let i=index"
                                                id="elementwomens-wearfootwear{{i}}" class="slider-img2-upper">
                                                <div class="d-flex justify-content-center" *ngIf="!zipList[object]">
                                                    <div class="spinner-border spn-load text-white" role="status">
                                                    </div>
                                                </div>
                                                <div class="foot-div">
                                                    <img class="img-responsive-foot" *ngIf="zipList[object]"
                                                        [src]="zipList[object] ? sanitizer.bypassSecurityTrustUrl(zipList[object]) : ' '"
                                                        (click)="DeatailsPopup(zipList[object],'footwear', object,'FootwearID',brandFlag )" />
                                                </div>
                                                <img (click)="open3Dflag(zipList[object],'footwear',object ,'FootwearID')"
                                                    class="profileInfo  upperwear_info col-sm-2"
                                                    style="width:25px; height:25px !important;"
                                                    src="/assets/realtype/planinfoicon.png">
                                            </div>
                                        </div>

                                        <div *ngIf="isLoading" class="d-flex justify-content-center">
                                            <div class="spinner-border" role="status">
                                                <span class=""></span>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="tab =='mens-wear' &&  isLoadingStyle == false && showTheBrandMode" class="d-flex my"
                            [ngClass]="this.loadingState   ? 'no-touch' : ''">
                            <div class=" file womenswear_scroller ">
                                <div class="file" *ngIf="!isLoading ">
                                    <div class="row form d-block " *ngIf="packJsonObject != undefined ">
                                        <div #scrollContainerMHeadGear class="slider-css-head"
                                            (scroll)="scrollAndUnZipFileIntilize('headgear')">
                                            <div *ngFor="let object of packJsonObject[0]?.value.files;let i=index"
                                                id="elementmens-wearheadgear{{i}}" class="slider-img2-upper">
                                                <div class="d-flex justify-content-center" *ngIf="!zipList[object]">
                                                    <div class="spinner-border spn-load text-white" role="status">
                                                    </div>
                                                </div>
                                                <div class="head-div">
                                                    <img class="img-responsive-foot" *ngIf="zipList[object]"
                                                        [src]="zipList[object] ? sanitizer.bypassSecurityTrustUrl(zipList[object]) : ' '"
                                                        (click)="DeatailsPopup(zipList[object],'headgear', object,'HeadwearID',brandFlag )" />
                                                </div>
                                                <img (click)="open3Dflag(zipList[object],'headgear',object ,'HeadwearID')"
                                                    class="profileInfo  upperwear_info col-sm-2"
                                                    style="width:25px; height:25px !important;"
                                                    src="/assets/realtype/planinfoicon.png">
                                            </div>
                                        </div>


                                        <div #scrollContainerMUpper class="slider-css2-upper"
                                            (scroll)="scrollAndUnZipFileIntilize('upperwear')">
                                            <div *ngFor="let object of packJsonObject[1]?.value.files;let i=index"
                                                id="elementmens-wearupperwear{{i}}" class="slider-img2-upper">
                                                <div class="upper-div">
                                                    <div class="d-flex justify-content-center" *ngIf="!zipList[object]">
                                                        <div class="spinner-border spn-load text-white" role="status">
                                                        </div>
                                                    </div>
                                                    <img *ngIf="zipList[object]" class="img-responsive-upper"
                                                        [src]="zipList[object] ? sanitizer.bypassSecurityTrustUrl(zipList[object]) : ' '"
                                                        (click)="DeatailsPopup(zipList[object],'upperwear',object,'UpperwearID',brandFlag)" />
                                                    <img (click)="open3Dflag(zipList[object],'upperwear',object ,'UpperwearID')"
                                                        class="profileInfo   col-sm-2"
                                                        style="width:25px; height:25px !important;"
                                                        src="./assets/realtype/planinfoicon.png">
                                                </div>
                                            </div>
                                        </div>
                                        <div #scrollContainerMBottom class="slider-css2-upper"
                                            (scroll)="scrollAndUnZipFileIntilize('bottomwear')">
                                            <div *ngFor="let object of packJsonObject[2]?.value.files;let i=index"
                                                id="elementmens-wearbottomwear{{i}}" class="slider-img2-upper">
                                                <div class="d-flex justify-content-center" *ngIf="!zipList[object]">
                                                    <div class="spinner-border spn-load text-white" role="status">
                                                    </div>
                                                </div>
                                                <div class="bottom-div">
                                                    <img class="img-responsive-bottom" *ngIf="zipList[object]"
                                                        [src]="zipList[object] ? sanitizer.bypassSecurityTrustUrl(zipList[object]) : ' '"
                                                        (click)="DeatailsPopup(zipList[object],'bottomwear', object,'BottomwearID',brandFlag )" />
                                                </div>
                                                <img (click)="open3Dflag(zipList[object],'bottomwear',object ,'BottomwearID')"
                                                    class="profileInfo  upperwear_info col-sm-2"
                                                    style="width:25px; height:25px !important;"
                                                    src="/assets/realtype/planinfoicon.png">
                                            </div>
                                        </div>
                                        <div #scrollContainerMFootwear class="slider-css2-upper"
                                            (scroll)="scrollAndUnZipFileIntilize('footwear')">
                                            <div *ngFor="let object of packJsonObject[3]?.value.files;let i=index"
                                                id="elementmens-wearfootwear{{i}}" class="slider-img2-upper">
                                                <div class="d-flex justify-content-center" *ngIf="!zipList[object]">
                                                    <div class="spinner-border spn-load text-white" role="status">
                                                    </div>
                                                </div>
                                                <div class="foot-div">
                                                    <img class="img-responsive-foot" *ngIf="zipList[object]"
                                                        [src]="zipList[object] ? sanitizer.bypassSecurityTrustUrl(zipList[object]) : ' '"
                                                        (click)="DeatailsPopup(zipList[object],'footwear', object,'FootwearID',brandFlag )" />
                                                </div>
                                                <img (click)="open3Dflag(zipList[object],'footwear',object ,'FootwearID')"
                                                    class="profileInfo  upperwear_info col-sm-2"
                                                    style="width:25px; height:25px !important;"
                                                    src="/assets/realtype/planinfoicon.png">
                                            </div>
                                        </div>
                                        <div *ngIf="isLoading" class="d-flex justify-content-center">
                                            <div class="spinner-border" role="status">
                                                <span class=""></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div *ngIf="isloading" class="text-center">
                            <div class="spinner-border m-5" role="status">
                                <span class="sr-only"></span>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="flipFlag" class="flipFlag-class" id="scrollableDiv" #setclothBand
                        [style.height.px]="sideNavHeight">
                        <div (click)="flipClose() " class="text-white text-end class-close-btn">X
                        </div>
                        <div class="set-main-div">
                            <div class="set-img-div" *ngIf="!swichImageFlage">
                                <img *ngIf="garmentClothType != 'bottomwear'" class="img-responsive-flip-class"
                                    [src]="source" />
                                <img *ngIf="garmentClothType == 'bottomwear'" class="img-responsive-flip-class-bottom"
                                    [src]="source" />
                            </div>
                            <div *ngIf="swichImageFlage">
                                <div class="" id="target" *ngIf="packJsonObject.length > 0 ">
                                    <app-twod-image [garmentImg]="this.source"
                                        (triggedCloth)="setSelectedGarmetCloth($event)"
                                        [garmentType]="this.garmentClothType" [pageType]="style"></app-twod-image>
                                </div>
                                <div class="d-flex justify-content-center" *ngIf="isloading">
                                    <div class="spinner-border" role="status">
                                        <span class=""></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="setcloth-band">
                            <div class="mb-2 text-black clothName color-class-band">
                                <div class="d-flex garment-title">
                                    <div class="text-center d-inline-flex garmen-name-in-band">
                                        <div class="">
                                            <span *ngIf="clothDetails?.RealType == '0'" class="brandbtn py-1"><img
                                                    style="width:18px !important; color:black ;padding-bottom: 3px; opacity: 0.6;"
                                                    src="./assets/realtype/headgear.png"></span>
                                            <span *ngIf="clothDetails?.RealType == '1'" class="brandbtn py-1 "><img
                                                    style="width:18px !important; color:black ;padding-bottom: 12px; opacity: 0.6; 
                                                        margin-right: 4px;"
                                                    src="./assets/realtype/upperwear.png"></span>
                                            <span *ngIf="clothDetails?.RealType == '2'" class="brandbtn py-1"><img
                                                    style="width:18px !important; color:black ;padding-bottom: 5px; opacity: 0.6;"
                                                    src="./assets/realtype/bottomwear.png"></span>
                                            <span *ngIf="clothDetails?.RealType == '3'" class="brandbtn py-1"><img
                                                    style="width:18px !important; color:black ;padding-bottom: 3px; opacity: 0.6;"
                                                    src="./assets/realtype/footwear.png"></span>
                                            <span *ngIf="clothDetails?.RealType == '4'" class="brandbtn py-1"><img
                                                    style="width:18px !important; color:black ;padding-bottom: 3px; opacity: 0.6;"
                                                    src="./assets/realtype/heeledfootwear.png"></span>
                                            <span *ngIf="clothDetails?.RealType == '5'" class="brandbtn py-1"><img
                                                    style="width:18px !important; color:black ;padding-bottom: 10px; opacity: 0.6;"
                                                    src="./assets/realtype/fullbody.png"></span>
                                        </div>
                                        <h6 class="brand-font class-band-name" style="wrap: break-word;">
                                            {{(clothDetails?.Name).split()}}
                                        </h6>
                                    </div>
                                    <div>
                                        <div class="text-center band-3d-icon d-flex col-sm-1">
                                            <h6 class=" text-primary  color-class">
                                                <img *ngIf="!garmentScrollFlag" (click)="switch3DImage()"
                                                    class="threeD_icon " src="./assets/realtype/3d-paranoma.png">
                                                <img *ngIf="garmentScrollFlag" (click)="flipClose()"
                                                    class="threeD_icon " src="./assets/realtype/paraCloseBlack.png">
                                            </h6>
                                        </div>
                                    </div>
                                </div>
                                <div class="static-band-five-icon col-4 text-center d-flex justify-content-center">
                                    <h6 (click)="selectIcon('popup')" class="text-primary brandbtn m-1 d-block class">
                                        <div *ngIf="selectedIcon == 'popup'"
                                            [ngClass]="selectedIcon == 'popup'    ? 'story-menu-card-band'  : 'story-menu-icon'">
                                            <img style="width: 12px;" src="./assets/storystyle/story-white.png">
                                        </div>
                                        <img *ngIf="selectedIcon != 'popup'" class="story-menu-icon"
                                            style="color:black ;margin-top: 0px; width: 10px;"
                                            src="./assets/storystyle/storyblack.png">

                                        <h6 *ngIf="selectedIcon == 'popup'" class="selected-name p-1">Story</h6>
                                    </h6>
                                    <h6 (click)="selectIcon('detail')"
                                        *ngIf="clothDetails?.ThumbCenterURL  != undefined" class="m-1 brandbtn">
                                        <div *ngIf="selectedIcon == 'detail'"
                                            [ngClass]="selectedIcon == 'detail' ? 'story-link-menu' : 'story-menu-icon'">
                                            <a href="{{clothDetails?.ThumbCenterURL}}" target="_blank">
                                                <img style="width: 12px;;" src="./assets/details.png"></a>
                                        </div>
                                        <a href="{{clothDetails?.ThumbCenterURL}}" target="_blank"><img
                                                *ngIf="selectedIcon != 'detail'"
                                                [ngClass]="selectedIcon != 'detail' ? 'story-menu-icon' : ''"
                                                style="color:black ;"
                                                src="./assets/storystyle/newStoryIcon/detailGray.png"></a>

                                        <h6 *ngIf="selectedIcon == 'detail'" class="selected-name p-1">Details
                                        </h6>
                                    </h6>
                                    <h6 *ngIf="clothDetails?.ThumbCenterURL  == undefined" class="m-1 brandbtn">
                                        <a><img style="width:15px !important; color:black"
                                                src="./assets/storystyle/newStoryIcon/detailGrayLight.png"></a>
                                    </h6>
                                    <h6 (click)="selectIcon('gallary')" *ngIf="clothDetails?.ThumbLeftURL  != undefined"
                                        class="m-1 brandbtn">
                                        <div *ngIf="selectedIcon == 'gallary'"
                                            [ngClass]="selectedIcon == 'gallary' ? 'story-link-menu' : 'story-menu-icon'">
                                            <a href="{{clothDetails?.ThumbLeftURL}}" target="_blank">
                                                <img style="width: 12px; " src="./assets/gallary.png"></a>
                                        </div>
                                        <a href="{{clothDetails?.ThumbLeftURL}}" target="_blank"><img
                                                *ngIf="selectedIcon != 'gallary'"
                                                [ngClass]="selectedIcon != 'gallary' ? 'story-menu-icon' : ''"
                                                style="color:black;"
                                                src="./assets/storystyle/newStoryIcon/gallaryGray.png"></a>
                                        <h6 *ngIf="selectedIcon == 'gallary'" class="selected-name p-1">Gallary
                                        </h6>
                                    </h6>
                                    <h6 *ngIf="clothDetails?.ThumbLeftURL  == undefined" class="m-1 brandbtn">
                                        <a><img style="width:15px !important ;"
                                                src="./assets/storystyle/newStoryIcon/gallaryGrayLight.png"></a>
                                    </h6>
                                    <h6 (click)="selectIcon('cart')" *ngIf="clothDetails?.ThumbRightURL  != undefined"
                                        class="m-1 brandbtn">
                                        <div *ngIf="selectedIcon == 'cart'"
                                            [ngClass]="selectedIcon == 'cart' ? 'story-link-menu' : 'story-menu-icon'">
                                            <a href="{{clothDetails?.ThumbRightURL}}" target="_blank">
                                                <img style="width: 12px; " src="./assets/shop.png"></a>
                                        </div>
                                        <a href="{{clothDetails?.ThumbRightURL}}" target="_blank"><img
                                                *ngIf="selectedIcon != 'cart'"
                                                [ngClass]="selectedIcon != 'cart' ? 'story-menu-icon' : ''"
                                                style="color:black; width: 1px;"
                                                src="./assets/storystyle/newStoryIcon/cartGray.png"></a>
                                        <h6 *ngIf="selectedIcon == 'cart'" class="selected-name m-1">Shopping
                                        </h6>
                                    </h6>
                                    <h6 *ngIf="clothDetails?.ThumbRightURL  == undefined" class="m-1 brandbtn">
                                        <a><img style="width:15px !important; color:black"
                                                src="./assets/storystyle/newStoryIcon/cartGrayLight.png"></a>
                                    </h6>
                                </div>
                            </div>
                        </div>
                        <div class="img-detail-story">
                            <div class="d-flex justify-content-center" *ngIf="isloadingPanel">
                                <div class="spinner-border is-loading m-3  " role="status">
                                    <span class=""></span>
                                </div>
                            </div>
                            <div class="twoDImage d-block mb-2" *ngIf="!isloadingPanel">

                                <div class=""
                                    *ngIf="userFileList != undefined && ( this.selectStoreFrontType =='2DImage' || selectStoreFrontType=='2DVideo' )">
                                    <app-image-slide [storeFrontFlag]="storeFrontFlag"
                                        (selectuserUploadImage)="handleupladimage($event)"
                                        (saveFlage)="handleSaveData($event)" [images]="userFileList"></app-image-slide>
                                </div>

                                <div class="d-flex text-center m-5"
                                    *ngIf="userFileList == undefined || userFileList.length == 0 && selectStoreFrontType=='2DVideo'">
                                    <!-- <app-image-slide [images]="images"></app-image-slide> -->
                                    No 2D Video
                                </div>

                                <div class="d-flex text-center m-5"
                                    *ngIf="userFileList == undefined || userFileList.length == 0 && this.selectStoreFrontType =='2DImage'">
                                    <!-- <app-image-slide [images]="images"></app-image-slide> -->
                                    No 2D images
                                </div>

                                <!-- <div *ngIf="userFiledetails3d != undefined &&  selectStoreColomnType == '3DObj'"
                                        class="text-center d-flex upload-image-slider-css-both twoDImage-both">
                                        <div *ngFor="let fileList of userFiledetails3d;let p=index" class="">
                                            <model-viewer *ngIf="fileList.fileType == 'GarmentUpload3D'" (click)="addI3dObject(fileList)"
                                                camera-controls camera-orbit="45deg 55deg 2.5m" ar auto-rotate
                                                src={{fileList.fileurl}} alt="A 3D avatar-model-view"
                                                data-js-focus-visible="" ar-status="not-presenting"
                                                style="height: 80px; width: 100px; float: none;">
                                            </model-viewer>
                                        </div>
                                    </div> -->

                                <div *ngIf="userFiledetails3d != undefined && selectStoreColomnType == '3DObj'">
                                    <app-image-slide [storeFrontFlag]="storeFrontFlag"
                                        [modelViewerFlag]="modelViewerFlag"
                                        (selectuserUploadImage)="handleupladimage($event)"
                                        (saveFlage)="handleSaveData($event)" [images]="userFiledetails3d">
                                    </app-image-slide>
                                    <div class="slider-wrapper">
                                        <!-- <div *ngFor="let fileList of userFiledetails3d; let p = index" class="slide">
                                            <model-viewer *ngIf="fileList.fileType == 'GarmentUpload3D'" (click)="addI3dObject(fileList)"
                                                camera-controls camera-orbit="45deg 55deg 2.5m" ar auto-rotate [src]="fileList.fileurl"
                                                alt="A 3D avatar-model-view" data-js-focus-visible="" ar-status="not-presenting"
                                                class="model-viewer-element">
                                            </model-viewer>
                                        </div> -->
                                    </div>
                                </div>

                                <div class="d-flex text-center m-5"
                                    *ngIf="userFiledetails3d == undefined || userFiledetails3d.length == 0 &&  selectStoreColomnType == '3DObj'">
                                    <!-- <app-image-slide [images]="images"></app-image-slide> -->
                                    No 3D images
                                </div>

                            </div>
                            <div
                                [ngClass]="userFiledetails3d.length != 0   ? 'details collection-details-sku p-2' : 'collection-details-sku3d details'">
                                <h6 class="color-class "
                                    *ngIf="clothDetails?.CollectionName != undefined && clothDetails?.CollectionName != '' ">
                                    Collection: {{(clothDetails.CollectionName)}}</h6>
                                <h6 class=" color-class "
                                    *ngIf="clothDetails?.CollectionName == '' ||  clothDetails?.CollectionName == null">
                                    Collection: None</h6>

                                <h6 class=" color-class"
                                    *ngIf="clothDetails?.Details != undefined &&  userFiledetails3d.length != 0">
                                    {{(showMore) ? clothDetails .Details : clothDetails .Details | slice:0:120}}
                                    <span *ngIf="!showMore  &&  userFiledetails3d.length != 0 ">...</span>
                                    <a href="javascript:;" *ngIf="!showMore &&  userFiledetails3d.length != 0"
                                        (click)="showMore=true">Read More</a>
                                    <a href="javascript:;" *ngIf="showMore &&  userFiledetails3d.length != 0"
                                        (click)="showMore=false">Read less</a>
                                </h6>

                                <h6 class="color-class"
                                    *ngIf="clothDetails?.Details != undefined  &&  userFiledetails3d.length == 0 ">
                                    {{(showMore) ? clothDetails.Details : clothDetails .Details| slice:0:400}}
                                    <span *ngIf="!showMore && clothDetails.Details.length < 400">...</span>
                                    <a href="javascript:;"
                                        *ngIf="!showMore && clothDetails.Details.length > 400  &&  userFiledetails3d.length == 0"
                                        (click)="showMore=true">Read More</a>
                                    <a href="javascript:;" *ngIf="showMore  &&  userFiledetails3d.length == 0 "
                                        (click)="showMore=false">Read less</a>
                                </h6>
                                <h6 class="color-class"
                                    *ngIf="clothDetails?.Details == undefined  &&  userFiledetails3d.length < 0 ">
                                    {{(showMore) ? dummyText : dummyText | slice:0:150}} <span
                                        *ngIf="!showMore">...</span>
                                    <a href="javascript:;" *ngIf="!showMore &&  userFiledetails3d.length < 0 "
                                        (click)="showMore=true">Read More</a>
                                    <a href="javascript:;" *ngIf="showMore  && userFiledetails3d.length < 0 "
                                        (click)="showMore=false">Read less</a>
                                </h6>
                                <h6 class="color-class"
                                    *ngIf="clothDetails?.Details == undefined &&  userFiledetails3d.length == 0">

                                    Your brand's apparel is digitised in 3D
                                    <br><br>
                                    On a gamified experience, consumers style avatars & share animated avatar
                                    stories
                                    (linked to ecommerce)
                                    <br><br>
                                    Defining a new paradigm in immersive storytelling for social media & the
                                    metaverse
                                </h6>
                                <h6 class="color-class"
                                    *ngIf="clothDetails?.Details == undefined &&  userFiledetails3d.length > 0  && userFiledetails.length > 0">
                                    {{(showMore) ? dummyText : dummyText}} "
                                    <a href="javascript:;" *ngIf="!showMore &&  
                                clothDetails .Details == undefined &&  
                                userFiledetails3d.length > 0  && userFiledetails.length > 0 "
                                        (click)="showMore=true">Read More</a>
                                    <a href="javascript:;"
                                        *ngIf="showMore  &&  userFiledetails3d.length > 0  && userFiledetails.length > 0"
                                        (click)="showMore=false">Read less</a>
                                </h6>

                                <h6 class="color-class"
                                    *ngIf="clothDetails?.Details == undefined &&  userFiledetails3d.length > 0 && userFiledetails.length == 0">
                                    {{(showMore) ? dummyText : dummyText}}<a href="javascript:;"
                                        *ngIf="!showMore && clothDetails?.Details == undefined &&  userFiledetails3d.length > 0 && userFiledetails.length == 0"
                                        (click)="showMore=true">Read More</a>
                                    <a href="javascript:;" *ngIf="showMore " (click)="showMore=false">Read
                                        less</a>
                                </h6>
                                <h6 class="color-class">SKU: {{clothDetails?.SKU}}</h6>
                            </div>
                            <div #storyContainer class="mb-5  my-storyzz" *ngIf="!isloadingPanel">
                                <div class=" row m-1 p-1 justify-content-center "
                                    *ngIf="userSnapShotListGarment.length >= 0  && userSnapShotListGarment != undefined && userSnapShotListGarment.length >= 0">
                                    <!-- <div class="column d-block  "
                                            *ngFor="let snapshot of userSnapShotListGarment; let i=index;">
                                            <img [ngClass]="{'story_image_selection': i == selectedImageIndex}"
                                                class="columnImg d-block  shadow-1-strong rounded mb-1 "
                                                [src]="snapshot.SnapshotFileUrlMini">
                                        </div> -->

                                    <div class="grid-container"
                                        *ngIf="userSnapShotListGarment.length > 0 && userSnapShotListGarment !== undefined">
                                        <div class="grid-item"
                                            *ngFor="let snapshot of userSnapShotListGarment; let i = index;">
                                            <img [ngClass]="{'story_image_selection': i === selectedImageIndex}"
                                                [src]="snapshot.SnapshotFileUrlMini"
                                                (click)="selectImage(i, snapshot.StoryID)">
                                        </div>
                                    </div>
                                    <div class="col-12 text-center text-white stify-content-center">
                                        <h6 *ngIf="this.userSnapShotListGarment.length >= 12 ">
                                            <b>More</b>
                                        </h6>
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex justify-content-center" *ngIf="isloadingPanel">
                                <div class="spinner-border is-loading m-3 " role="status">
                                    <span class=""></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="!showBrandFlag  &&  (tab =='story' || tab =='news-stories') "
                class="col-12 text-center text-white stify-content-center">
                <h6>
                    <b>More</b>
                </h6>
            </div>
        </div>
        <div class="dw-item">
            <div class="d-flex justify-content-center justify-content-md-center custom-select-container"
                *ngIf="(showTheBrandMode && showDropDown)">
                <div class="col-md-3 col-6 p-1 custom-select-dropdown">
                    <select class="form-select" (change)="selectStoreWindowtexture($event)"
                        [(ngModel)]="selectStoreFrontWindowTexture"
                        [disabled]="storeFrontData.StoreFrontType == '3DDouble'">
                        <option selected value="">Window Texture</option>
                        <!-- Loop through textureLibraryList to create options -->
                        <option *ngFor="let texture of textureLibraryList" [value]="texture.id"
                        [disabled]="storeFrontData.WindowTexture && texture.WindowTexture" >
                         {{ texture.TextureName }}
                        </option>
                    </select>
                </div>
            </div>

            <div class="d-flex justify-content-center justify-content-md-center custom-select-container"
                *ngIf="(showTheBrandMode && showDropDown)">
                <div class="col-md-3 col-6 p-1 custom-select-dropdown">
                    <select class="form-select" (change)="selectStoreoutertexture($event)"
                        [(ngModel)]="selectStoreFrontOuterTexture"
                        [disabled]="storeFrontData.StoreFrontType == '3DDouble'">
                        <option selected value="">Outer Texture</option>
                        <!-- Loop through textureLibraryList to create options -->
                        <option *ngFor="let texture of textureLibraryList" [value]="texture.id"
                            [disabled]="storeFrontData.WindowTexture && texture.WindowTexture">
                            {{ texture.TextureName }}
                        </option>
                    </select>
                </div>
            </div>
            
            <div class="d-flex justify-content-center justify-content-md-center custom-select-container"
            *ngIf="(showTheBrandMode && showDropDown)">
            <div class="col-md-3 col-6 p-1 custom-select-dropdown"  [class.disabled-option]="storeFrontData.StoreFrontType == '3DDouble'">
                <!-- Custom dropdown trigger -->
                <div class="custom-select" (click)="toggleDropdown()"
                    [ngStyle]="{'background': selectedcolor ? 'linear-gradient(to left, ' + selectedcolor.ColorHexCode + ' 30%, transparent 30%)' : 'none'}">
                    {{ selectedcolor ? selectedcolor.ColorText : 'Brand Color' }}
                </div>
                <!-- Custom dropdown list -->
                <div class="dropdown-menu-s" *ngIf="dropdownOpen">
                    <div *ngIf="filteredBrandcolor.length === 0" class="dropdown-item disabled">No Brand Color</div>
                    <div *ngFor="let color of filteredBrandcolor" (click)="selectBrandColor(color)" class="dropdown-item"
                        [ngStyle]="{'background': 'linear-gradient(to left, ' + color.ColorHexCode + ' 30%, transparent 30%)'}">
                        {{ color.ColorText }}
                    </div>
                </div>
            </div>
        </div>

<div class="d-flex justify-content-center justify-content-md-center custom-select-container"
*ngIf="(showTheBrandMode && showDropDown)  || (storeFrontData.WindowType)">
<div class="col-md-3 col-6 p-1 custom-select-dropdown">
    <select class="form-select" (change)="selectStoreWindowType($event)"
        [(ngModel)]="selectStoreFrontWindowType"
        [disabled]="  storeFrontData.StoreFrontType == '3DDouble'">
        <option selected value="">Window Type</option>
        <option [disabled]=" storeFrontData.WindowType == 'Slanting'" value="Slanting">Slanting</option>
        <option [disabled]=" storeFrontData.WindowType == 'Rectangular'" value="Rectangular">Rectangular
        </option>
        <option [disabled]=" storeFrontData.WindowType == 'Curved'" value="Curved">Curved</option>
    </select>
</div>
</div>
        

            <div class="d-flex justify-content-center justify-content-md-center custom-select-container"
                *ngIf="showTheBrandMode && showDropDown && selectStoreFrontWindowType">
                <div class="col-md-3 col-6 p-1 custom-select-dropdown">
                    <select class="form-select" (change)="selectStoreWindowStyle($event)"
                        [(ngModel)]="selectStoreFrontWindowStyle"
                        [disabled]="  storeFrontData.StoreFrontType == '3DDouble'">
                        <option selected value="">Window Style</option>
                        <option [disabled]=" storeFrontData.WindowStyle == 'Elegant'" value="Elegant">Elegant</option>
                        <option [disabled]=" storeFrontData.WindowStyle == 'Classic'" value="Classic">Classic</option>
                    </select>
                </div>
            </div>
            </div>
            <div class="d-flex justify-content-center justify-content-md-center custom-select-container"
                *ngIf="showTheBrandMode && showDropDown &&   sideCheck != 'Both' && (selectStoreFrontWindowStyle != '' &&  selectStoreFrontWindowType != '')"
                [ngClass]="{'d-none': sideCheck === 'Both'}">
                <div class="col-md-3 col-6 p-1 custom-select-dropdown">
                    <select class="form-select" (change)="selectStoreFront($event)" [(ngModel)]="selectStoreFrontType"
                        [disabled]=" storeFrontData.StoreFrontType == '3DDouble'">
                        <option value="">Store Front Type</option>
                        <option [disabled]=" storeFrontData.StoreFrontType == '3DDouble'" value="2DImage">2D image
                        </option>
                        <option [disabled]=" storeFrontData.StoreFrontType == '3DDouble'" value="2DVideo">2D video
                        </option>
                        <option [disabled]=" storeFrontData.StoreFrontType == '3DDouble'" value="3DSingle">3D Single
                            Column
                        </option>
                        <option [selected]="selectStoreFrontType == '3DDouble'" value="3DDouble">3D Double Column
                        </option>
                    </select>
                </div>
            </div>
            <div class="d-flex justify-content-center justify-content-md-center custom-select-container"
                *ngIf="selectStoreFrontType == '3DDouble' || this.storeFrontData.StoreFrontType == '3DDouble' &&  sideCheck != 'Both'">
                <div class="col-md-3 col-6 p-1 custom-select-dropdown">
                    <select class="form-select" (change)="selectColunm($event)" [(ngModel)]="selectColunmSide"
                        [disabled]=" storeFrontData.StoreFrontType == '3DDouble'">
                        <option value="">Column Side</option>
                        <option [selected]="sideCheck == 'Left'" [disabled]="sideCheck == 'Left'" value="Right">Right
                        </option>
                        <option [selected]="sideCheck == 'Right'" [disabled]="sideCheck == 'Right'" value="Left">Left
                        </option>
                    </select>
                </div>
            </div>
            <div class="d-flex justify-content-center justify-content-md-center custom-select-container"
                *ngIf="selectStoreFrontType == '3DDouble'  ||  selectStoreFrontType == '3DSingle'">
                <div class="col-md-3 col-6 p-1">
                    <select class="form-select" (change)="selectStoreColoumnType($event)"
                        [(ngModel)]="selectStoreColomnType">
                        <option selected value="">ColumnType</option>
                        <option value="Mannequin">Mannequin</option>
                        <option value="Avatar">Avatar</option>
                        <option value="3DObj">3D Object</option>
                    </select>
                </div>




                <!-- <div class="container">
            <div class="dt-responsive selectlist font-14">
                <div class="row"
                    *ngIf="selectStoreColomnType == 'Mannequin' && selectStoreFrontType == '3DSingleColumn' ">
                    <div class="col-12 d-flex mt-3">
                        <button type="button" class="btn btn-success m-1"
                            (click)="addManquinInStoreFront()">Submit</button>
                    </div>
                </div>
                <div class="row" *ngIf="selectStoreColomnType == 'Avatar' && selectStoreFrontType == '3DSingleColumn' ">
                    <div class="col-md-3 col-6 p-1">
                        <select class="form-select" (click)="filterAvatar($event)" [(ngModel)]="selectedGender">
                            <option selected value="">Avatar - Gender</option>
                            <option value="M">Menswear</option>
                            <option value="F">Womenswear</option>
                        </select>
                    </div>
                    <div class="col-md-3 col-6 p-1" *ngIf="selectedGender">
                        <select class="form-select" (change)="selectAvatar($event)" [(ngModel)]="selectedAvatar">
                            <option selected value="">Select Avatar</option>
                            <option *ngIf="!avatarList || avatarList.length === 0" value="">No avatar</option>
                            <option *ngFor="let avatar of avatarList" [value]="avatar.AvatarId">
                                {{avatar.AvatarName}}
                            </option>
                        </select>
                    </div>
                    <div class="col-md-3 col-6 p-1" *ngIf="selectedAvatar">
                        <select class="form-select" (change)="selectClothType($event)" [(ngModel)]="selectedClothType">
                            <option selected value="">GarmentType</option>
                            <option value="headgear">Headgear</option>
                            <option value="upperwear">Upperwear</option>
                            <option value="bottomwear">Bottomwear</option>
                            <option value="footwear">Footwear</option>
                        </select>
                    </div>
                    <div class="col-md-3 col-6 p-1" *ngIf="selectedAvatar">
                        <select class="form-select" [(ngModel)]="selectedCloth" (change)="selectCloth($event)">
                            <option selected value="">GarmentName</option>
                            <option [value]="garment.id" *ngFor="let garment of filterGarmrntList">{{garment.Name}}
                            </option>
                        </select>
                    </div>

                    <div class="col-12 d-flex mt-3">
                        <button type="button" class="btn btn-success m-1"
                            (click)="addManquinInStoreFront()">Submit</button>
                    </div>
                </div>
            </div>
        </div> -->
            </div>
        </div>
    </div>

    <!-- <div class="d-flex position-absolute" style="width: 375px;" *ngIf="storeFrontData.StoreFrontType == '3DDouble'"
        [ngClass]="sideCheck == 'Both' ? 'both-side-manege' : ''">
        <i class="bi bi-trash-fill class-delete" (click)="deleteStoreFront(storeFrontData)"></i>
        <div style="width: 190px;" class="text-center" class="text-center">
            <div class="store-back" style="background-image: url('assets/store_back.jpg');"
                *ngIf="storeFrontData.LeftColumnType == '3DObj' && storeFrontData.LeftDocumentID">
                <img class="tag-image mr-0" style="width: 80px; height: 80px; margin-right: 0px;"
                    [src]="showStoreFrontSaveImage?.fileurlMini"
                    (click)="openStoreGltfLargView(showStoreFrontSaveImage?.fileurl)" />

            </div>
        </div>
        <div style="width: 190px;" class="text-center">
            <div class="store-back" style="width: 190px;" style="background-image: url('assets/store_back.jpg');"
                *ngIf="storeFrontData.RightColumnType == '3DObj' && storeFrontData.RightDocumentID">
                <img class="tag-image" style="width: 80px; height: 80px; margin-right: 0px;"
                    [src]="showStoreFrontSaveImageRight?.fileurlMini"
                    (click)="openStoreGltfLargView(showStoreFrontSaveImageRight?.fileurl)" />
            </div>
        </div>
    </div> -->



    <div class="mannequin-div-Right" *ngIf="cachepanel">
        <div class="head-div">
            <img class="img-responsive-foot" *ngIf="selectedGarmentCacheUrl?.headgear"
                [src]="selectedGarmentCacheUrl?.headgear" />
        </div>
        <div class="upper-div">
            <img class="img-responsive-upper" *ngIf="selectedGarmentCacheUrl?.upperwear"
                [src]="selectedGarmentCacheUrl?.upperwear" />
        </div>
        <div class=" bottom-div">
            <img class="img-responsive-bottom" *ngIf="selectedGarmentCacheUrl?.bottomwear"
                [src]="selectedGarmentCacheUrl?.bottomwear" />
        </div>
        <div class=" foot-div">
            <img class="img-responsive-foot" *ngIf="selectedGarmentCacheUrl?.footwear"
                [src]="selectedGarmentCacheUrl?.footwear" />
        </div>

    </div>



    <!-- <div class="mannequin-div-Right" *ngIf="storeFrontData.StoreFrontType == '3DSingle' && storeFrontData.LeftColumnType == 'Mannequin'">
        <div class="head-div">
            <img class="img-responsive-foot" *ngIf="selectedGarmentCacheUrlLeft?.headgear"
                [src]="selectedGarmentCacheUrlLeft?.headgear" />
        </div>
        <div class="upper-div">
            <img class="img-responsive-upper" *ngIf="selectedGarmentCacheUrlLeft?.upperwear"
                [src]="selectedGarmentCacheUrlLeft?.upperwear" />
        </div>
        <div class=" bottom-div">

            <img class="img-responsive-bottom" *ngIf="selectedGarmentCacheUrlLeft?.bottomwear"
                [src]="selectedGarmentCacheUrlLeft?.bottomwear" />
        </div>
        <div class=" foot-div">
            <img class="img-responsive-foot" *ngIf="selectedGarmentCacheUrlLeft?.footwear"
                [src]="selectedGarmentCacheUrlLeft?.footwear" />
        </div>
    </div> -->



    <div *ngIf="getStoreDataFlage && storeFrontData.StoreFrontType == '3DSingle'">
        <i *ngIf="mannequinFlag && getStoreDataFlage" class="bi bi-trash-fill class-delete"
            (click)="deleteStoreFront(storeFrontData)"></i>
        <i *ngIf="storeFrontData.LeftColumnType == '3DObj' || storeFrontData.RightColumnType == '3DObj'"
            class="bi bi-trash-fill class-delete" (click)="deleteStoreFront(storeFrontData)"></i>
        <div style="width: 190px;" class="text-center">
            <div class="mannequin-div-Left">
                <div class="store-back-twice d-flex" style="width: 190px;"
                    style="background-image: url('assets/store_back.jpg');">
                    <div class="store-avatar"
                        *ngIf="getStoreDataFlage && storeFrontData.StoreFrontType == '3DSingle' && (storeFrontData.LeftColumnType == 'Avatar' || storeFrontData.ColumnType == 'Avatar')">
                        <app-news3-d-popup (lodingStateFlag)="setPageloading($event)" [EnvUrl]="evnDefault"
                            [FunctionFlag]="this.functionFlag" [FunctionFlag]="this.functionForStore"
                            [queryHeadgear]="queryHeadgear" [queryUpperwear]="queryUpperwear"
                            [queryBottomwear]="queryBottomwear" [queryFootwear]="queryFootwear"
                            [DefaultAvatar_M]="this.defaultAvatar_M" [DefaultAvatar_F]="this.defaultAvatar_F"
                            [headgearURL]="headgear" [upperwearURL]="upperwear" [bottomwearURL]="bottomwear"
                            [footwearURL]="footwear">
                        </app-news3-d-popup>
                    </div>

                    <div *ngIf="storeFrontData.LeftColumnType == '3DObj' && storeFrontData.LeftDocumentID">
                        <!-- <img class="tag-left" style="width: 100px; height: 125px; left: 45%; "
                            [src]="showStoreFrontSaveImage?.fileurlMini"
                            (click)="openStoreGltfLargView(showStoreFrontSaveImage?.fileurl)" /> -->

                        <model-viewer camera-controls camera-orbit="45deg 55deg 2.5m" ar auto-rotate
                            [src]="showStoreFrontSaveImage?.fileurl" alt="A 3D avatar-model-view"
                            data-js-focus-visible="" ar-status="not-presenting" class="model-viewer-element" style="width: 200px;
                                                height: 200px;
                                                position: relative;
                                                left: 100%;
                                                transform: translateX(-50%);
                                                top: 40%;">
                        </model-viewer>

                    </div>

                    <div *ngIf="storeFrontData.RightColumnType == '3DObj' && storeFrontData.RightDocumentID ">
                        <!-- <img class="tag-right " style="width: 100px; height: 125px; left: 45%; "
                            [src]="showStoreFrontSaveImageRight?.fileurlMini"
                            (click)="openStoreGltfLargView(showStoreFrontSaveImageRight?.fileurl)" /> -->


                        <model-viewer camera-controls camera-orbit="45deg 55deg 2.5m" ar auto-rotate
                            [src]="showStoreFrontSaveImage?.fileurl" alt="A 3D avatar-model-view"
                            data-js-focus-visible="" ar-status="not-presenting" class="model-viewer-element" style="width: 200px;
                            height: 200px;
                            position: relative;
                            left: 100%;
                            transform: translateX(-50%);
                            top: 40%;">
                        </model-viewer>

                    </div>
                    <div class="mannequin-store-Left"
                        (click)="openStoreManquinLargView(showStoreFrontSaveImageRight?.fileurl)"
                        *ngIf="selectedGarmentCacheUrlLeft" style="left: 130px;">
                        <div class="head-div-big">
                            <img class="img-responsive-foot-big" *ngIf="selectedGarmentCacheUrlLeft?.headgear"
                                [src]="selectedGarmentCacheUrlLeft?.headgear" />
                        </div>
                        <div class="upper-div-big">
                            <img class="img-responsive-upper-big" *ngIf="selectedGarmentCacheUrlLeft?.upperwear"
                                [src]="selectedGarmentCacheUrlLeft?.upperwear" />
                        </div>
                        <div class="bottom-div-big">
                            <img class="img-responsive-bottom-big" *ngIf="selectedGarmentCacheUrlLeft?.bottomwear"
                                [src]="selectedGarmentCacheUrlLeft?.bottomwear" />
                        </div>
                        <div class="foot-div-big">
                            <img class="img-responsive-foot-big" *ngIf="selectedGarmentCacheUrlLeft?.footwear"
                                [src]="selectedGarmentCacheUrlLeft?.footwear" />
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>

    <div *ngIf="getStoreDataFlage && storeFrontData.StoreFrontType == '3DDouble'">
        <i *ngIf="mannequinFlag && getStoreDataFlage " class="bi bi-trash-fill class-delete"
            (click)="deleteStoreFront(storeFrontData)"></i>
        <!-- <div class="mannequin-div-Left"
            [ngClass]="storeFrontData.StoreFrontType == '3DDouble' && storeFrontData.LeftColumnType == 'Mannequin' ? '' : (this.sideCheck == 'Both' ? 'margin-top:0px': 'd-none')"
            *ngIf="storeFrontData.StoreFrontType == '3DDouble' && storeFrontData.LeftColumnType == 'Mannequin' && mannequinFlag" style="background-image: url('assets/store_back.jpg');">
            <div class="head-div">
                <img class="img-responsive-foot" *ngIf="selectedGarmentCacheUrlLeft?.headgear"
                    [src]="selectedGarmentCacheUrlLeft?.headgear" />
            </div>
            <div class="upper-div">
                <img class="img-responsive-upper" *ngIf="selectedGarmentCacheUrlLeft?.upperwear"
                    [src]="selectedGarmentCacheUrlLeft?.upperwear" />
            </div>
            <div class="bottom-div">
                <img class="img-responsive-bottom" *ngIf="selectedGarmentCacheUrlLeft?.bottomwear"
                    [src]="selectedGarmentCacheUrlLeft?.bottomwear" />
            </div>
            <div class=" foot-div">
                <img class="img-responsive-foot" *ngIf="selectedGarmentCacheUrlLeft?.footwear"
                    [src]="selectedGarmentCacheUrlLeft?.footwear" />
            </div>
        </div>
        <div [ngClass]="storeFrontData.StoreFrontType == '3DDouble' && (storeFrontData.RightColumnType == 'Mannequin' &&
         storeFrontData.LeftColumnType == '') ? 'mannquin-ng-class' : (this.sideCheck == 'Both' ? 'margin-top:0px': 'd-none')"
            class="mannequin-div-Right" style="background-image: url('assets/store_back.jpg');"
            *ngIf="storeFrontData.StoreFrontType == '3DDouble' && storeFrontData.RightColumnType == 'Mannequin' && mannequinFlag">
            <div class="head-div">
                <img class="img-responsive-foot" *ngIf="selectedGarmentCacheUrlRight?.headgear"
                    [src]="selectedGarmentCacheUrlRight?.headgear" />
            </div>
            <div class="upper-div">
                <img class="img-responsive-upper" *ngIf="selectedGarmentCacheUrlRight?.upperwear"
                    [src]="selectedGarmentCacheUrlRight?.upperwear" />
            </div>
            <div class=" bottom-div">
                <img class="img-responsive-bottom" *ngIf="selectedGarmentCacheUrlRight?.bottomwear"
                    [src]="selectedGarmentCacheUrlRight?.bottomwear" />
            </div>
            <div class=" foot-div">
                <img class="img-responsive-foot" *ngIf="selectedGarmentCacheUrlRight?.footwear"
                    [src]="selectedGarmentCacheUrlRight?.footwear" />
            </div>
        </div> -->

        <div style="width: 190px;" class="text-center">
            <div class="mannequin-div-Left">
                <div class="store-back-twice d-flex" style="background-image: url('assets/store_back.jpg');"
                    [ngClass]="getStoreDataFlage && storeFrontData.StoreFrontType == '3DDouble' && (storeFrontData.LeftColumnType == '' || storeFrontData.RightColumnType  == '') ? 'top-class' : ''">

                    <div class="store-avatar"
                        *ngIf="getStoreDataFlage && storeFrontData.StoreFrontType == '3DDouble' && (storeFrontData.LeftColumnType == 'Avatar' || storeFrontData.RightColumnType == 'Avatar')">
                        <app-news3-d-popup (lodingStateFlag)="setPageloading($event)" [EnvUrl]="evnDefault"
                            [FunctionFlag]="this.functionFlag" [FunctionFlag]="this.functionForStore"
                            [queryHeadgear]="queryHeadgear" [queryUpperwear]="queryUpperwear"
                            [queryBottomwear]="queryBottomwear" [queryFootwear]="queryFootwear"
                            [DefaultAvatar_M]="this.defaultAvatar_M" [DefaultAvatar_F]="this.defaultAvatar_F"
                            [headgearURL]="headgear" [upperwearURL]="upperwear" [bottomwearURL]="bottomwear"
                            [footwearURL]="footwear">
                        </app-news3-d-popup>
                    </div>
                    <div *ngIf="storeFrontData.LeftColumnType == '3DObj' && storeFrontData.LeftDocumentID">
                        <img class="tag-left" style="width: 100px; height: 125px; left: 45%;  "
                            [src]="showStoreFrontSaveImage?.fileurlMini"
                            (click)="openStoreGltfLargView(showStoreFrontSaveImage?.fileurl)" />
                    </div>
                    <div *ngIf="storeFrontData.RightColumnType == '3DObj' && storeFrontData.RightDocumentID ">
                        <img class="tag-right " style=" width: 100px; height: 125px; left: 100%; "
                            [src]="showStoreFrontSaveImageRight?.fileurlMini"
                            (click)="openStoreGltfLargView(showStoreFrontSaveImageRight?.fileurl)" />
                    </div>


                    <div class="mannequin-store-Left-big"
                        (click)="openStoreManquinLargView(showStoreFrontSaveImageRight?.fileurl)"
                        *ngIf="selectedGarmentCacheUrlLeft">
                        <div class="head-div-big">
                            <img class="img-responsive-foot-big" *ngIf="selectedGarmentCacheUrlLeft?.headgear"
                                [src]="selectedGarmentCacheUrlLeft?.headgear" />
                        </div>
                        <div class="upper-div-big">
                            <img class="img-responsive-upper-big" *ngIf="selectedGarmentCacheUrlLeft?.upperwear"
                                [src]="selectedGarmentCacheUrlLeft?.upperwear" />
                        </div>
                        <div class="bottom-div-big">
                            <img class="img-responsive-bottom-big" *ngIf="selectedGarmentCacheUrlLeft?.bottomwear"
                                [src]="selectedGarmentCacheUrlLeft?.bottomwear" />
                        </div>
                        <div class="foot-div-big">
                            <img class="img-responsive-foot-big" *ngIf="selectedGarmentCacheUrlLeft?.footwear"
                                [src]="selectedGarmentCacheUrlLeft?.footwear" />
                        </div>
                    </div>
                    <div class="mannequin-store-Right-big"
                        (click)="openStoreManquinLargView(showStoreFrontSaveImageRight?.fileurl)"
                        *ngIf="selectedGarmentCacheUrlRight">
                        <div class="head-div-big">
                            <img class="img-responsive-foot-big" *ngIf="selectedGarmentCacheUrlRight?.headgear"
                                [src]="selectedGarmentCacheUrlRight?.headgear" />
                        </div>
                        <div class="upper-div-big">
                            <img class="img-responsive-upper-big" *ngIf="selectedGarmentCacheUrlRight?.upperwear"
                                [src]="selectedGarmentCacheUrlRight?.upperwear" />
                        </div>
                        <div class="bottom-div-big">
                            <img class="img-responsive-bottom-big" *ngIf="selectedGarmentCacheUrlRight?.bottomwear"
                                [src]="selectedGarmentCacheUrlRight?.bottomwear" />
                        </div>
                        <div class="foot-div-big">
                            <img class="img-responsive-foot-big" *ngIf="selectedGarmentCacheUrlRight?.footwear"
                                [src]="selectedGarmentCacheUrlRight?.footwear" />
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>

    <div class="main-avatar-slider" *ngIf="getAvatrFlag && selectStoreColomnType == 'Avatar'">No Avatar Vailable</div>
    <div class="main-avatar-slider m-1" *ngIf="defaultSelectedAvatarFeMale || defaultSelectedAvatarMale">
        <div class="selected-avatar" (click)="showAvatarSlider()">
            <img *ngIf="tab == 'womens-wear'" [src]="defaultSelectedAvatarFeMale?.PortraitPreviewUrl"
                class="selected-avatar-image" />
            <img *ngIf="tab == 'mens-wear'" [src]="defaultSelectedAvatarMale?.PortraitPreviewUrl"
                class="selected-avatar-image" />
        </div>

        <div class="avatar-slider" *ngIf="tab == 'womens-wear' && showSlider">
            <div class="avatar-item p-1" *ngFor="let avatar of defaultFemaleAvatarsList"
                (click)="selectFemaleAvatar(avatar)">
                <img *ngIf="defaultSelectedAvatarFeMale?.PortraitPreviewUrl != avatar?.PortraitPreviewUrl"
                    [src]="avatar?.PortraitPreviewUrl" class="avatar-image" />
            </div>
        </div>
        <div class="avatar-slider" *ngIf="tab == 'mens-wear' && showSlider">
            <div class="avatar-item p-1" *ngFor="let avatar of defaultMaleAvatarsList"
                (click)="selectMaleAvatar(avatar)">
                <img *ngIf="defaultSelectedAvatarMale?.PortraitPreviewUrl != avatar?.PortraitPreviewUrl"
                    [src]="avatar?.PortraitPreviewUrl" class="avatar-image" />
            </div>
        </div>
        <button
            *ngIf="defaultSelectedAvatarFeMale || defaultSelectedAvatarMale && (tab == 'womens-wear'  || tab == 'mens-wear')"
            class="btn btn-primary m-1 p-1" (click)="saveAvatarInStoreFront()">Save</button>


    </div>




    <button *ngIf="cachepanel && this.selectStoreColomnType ==
     'Mannequin'" class="btn btn-primary mannquin-button" (click)="saveMannquinInStoreFront()">Save</button>



    <i *ngIf="!showTheBrandMode && (storeFrontData.RightAvatarID == 'Avatar' || storeFrontData.LeftAvatarID == 'Avatar' )"
        class="bi bi-trash-fill class-delete" (click)="deleteStoreFront(storeFrontData)"></i>

    <i *ngIf="!showTheBrandMode && (storeFrontData.RightAvatarID == 'Avatar' || storeFrontData.LeftAvatarID == 'Avatar' )"
        class="bi bi-trash-fill class-delete" (click)="deleteStoreFront(storeFrontData)"></i>


    <app-news3-d-popup *ngIf="functionForStore == undefined" (lodingStateFlag)="setPageloading($event)"
        [EnvUrl]="evnDefault" [FunctionFlag]="this.functionFlag" [queryHeadgear]="queryHeadgear"
        [queryUpperwear]="queryUpperwear" [queryBottomwear]="queryBottomwear" [queryFootwear]="queryFootwear"
        [DefaultAvatar_M]="this.defaultAvatar_M" [DefaultAvatar_F]="this.defaultAvatar_F" [headgearURL]="headgear"
        [upperwearURL]="upperwear" [bottomwearURL]="bottomwear" [footwearURL]="footwear">
    </app-news3-d-popup>
    <app-news3-d-popup *ngIf="functionForStore != undefined" [EnvUrl]="evnDefault">
    </app-news3-d-popup>