<button type="button" *ngIf="closebuttonFlag" class="btn-close main-close-button" aria-label="Close" (click)="toggleChild()" style="color: white;"></button>

<div *ngIf="this.brandObject != undefined" class="text-center d-flex col-2 shadow-brand-slide">
    <img class="brandLogo " (click)="setBrandSelection()" [src]="getBrandImage(this.brandObject.brand.LogoURL)">
    <div class="text-end me-1 mt-1" style="pointer-events: auto;">
        <div>
            <img class="heart" (click)="clickOnHeart($event)" *ngIf="brandheartFlag"
                style="width:18px !important; color:#000000 ;margin-left: 57px"
                src="./assets/storystyle/newStoryIcon/heart.png">

            <img (click)="clickOnHeart($event)" *ngIf="!brandheartFlag" class="heart"
                style="width:18px !important; color:black;margin-left: 57px"
                src="./assets/storystyle/newStoryIcon/heartGray.png">
        </div>
        <div>
            <img (click)="shareBrand()" style="width:20px !important; margin-top: 11px" src="./assets/share_copy.png">
        </div>
    </div>
</div>

<div class="brand-name" *ngIf="this.brandObject != undefined">
    <h6 class="text-white"  (click)="setBrandSelection()" >{{this.brandObject.brand.BrandName}}</h6>
</div>

<div class="col-lg-12 garmentName">
    <div class="justify-content-center text-center d-flex set-margin">
        <h6 class="text-primary brandbtn  py-2 d-block color-class" (click)="setTab('story','S')">
            <div *ngIf="tab =='story'" [ngClass]="tab == 'story' ? 'story-menu-card' : 'story-menu-icon'">
                <img style="width: 8px; padding-bottom: 4px;" src="./assets/storystyle/storyblack.png">
            </div>
            <img (click)="setTab('story','S')" *ngIf="tab != 'story' && tab != 'news-stories' "
                [ngClass]="tab != 'story' && tab != 'news-stories' ? 'story-menu-icon' : ''"
                style="color:black ;padding-bottom: 5px;" src="./assets/storystyle/story-white.png">
            <h6 *ngIf="tab == 'story' || tab == 'news-stories' " class="color-class-sy ms-3">
                Stories</h6>
        </h6>
        <h6 class="text-primary brandbtn py-2 d-block color-class">
            <img *ngIf="tab == 'mens-wear'" [ngClass]="tab == 'mens-wear' ? 'story-menu' : 'story-menu-icon'"
                (click)="setTab('mens-wear','M')" style="color:black ;padding-bottom: 5px;"
                src="./assets/storystyle/MenIconBlack.png">
            <img (click)="setTab('mens-wear','M')" *ngIf="tab != 'mens-wear'"
                [ngClass]="tab != 'mens-wear' ? 'story-menu-icon' : ''" style="color:black ;padding-bottom: 5px;"
                src="./assets/storystyle/MenIconWhite.png">
            <h6 *ngIf="tab == 'mens-wear'" class="color-class-sy m-1">Menswear</h6>
        </h6>
        <h6 class="text-primary brandbtn py-2 d-block color-class">
            <img *ngIf="tab == 'womens-wear'" (click)="setTab('womens-wear','W')"
                [ngClass]="tab == 'womens-wear' ? 'story-menu' : 'story-menu-icon'"
                style="color:black ;padding-bottom: 5px;" src="./assets/storystyle/WomenIconBlack.png">
            <img (click)="setTab('womens-wear','W')" *ngIf="tab != 'womens-wear'"
                [ngClass]="tab != 'womens-wear' ? 'story-menu-icon' : ''" style="color:black ;padding-bottom: 5px;"
                src="./assets/storystyle/WomenIconWhite.png">
            <h6 *ngIf="tab == 'womens-wear'" class="color-class-sy  w-margin">Womenswear</h6>
        </h6>
    </div>
</div>
<div>
    <div class="row p-1 justify-content-center d-flex" [ngStyle]="userSnapShotList.length < 12 ? {} : { 'height.px': brandStoryScrollerHeight }"
        style="overflow: auto;">
        <div class="col-6 col-md-4 p-1" *ngFor="let snapshot of userSnapShotList; let i = index;">
            <img class="img-fluid rounded mb-1" [src]="snapshot.SnapshotFileUrlMini" (click)="selectImage(i, snapshot)"
                [ngClass]="{'story_image_selection': i === selectedImageIndex}">
        </div>
    </div>
    <div class="col-12 text-center text-black justify-content-center">
        <h6 (click)="incrementImgCounter()">
            <b>More</b>
        </h6>
    </div>
</div>