import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ApiHttpService } from 'src/app/services/api-http.service';
import { CommonService } from 'src/app/services/common.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-avatar-uploader',
  templateUrl: './avatar-uploader.component.html',
  styleUrls: ['./avatar-uploader.component.css']
})
export class AvatarUploaderComponent implements OnInit {
  userFiles = this.firestore.collection("userfiles");
  userFileStateChange = this.userFiles.valueChanges();
  brandsCollection = this.firestore.collection('brands').valueChanges({ idField: 'id' }) as Observable<[]>;
  garmentsCollection = this.firestore.collection('garments').valueChanges({ idField: 'id' }) as Observable<[]>;
  isHovering: boolean = false;
  fileType: any;
  file: any;
  envName: string = "";
  errorMessage: string;
  brandsGender: any
  userUploadedAvatars: any = [];
  loading = false;
  test: any = ""
  userid: any
  brands: any = []
  brandsDetails: any
  brandGenderDetails: any
  garments: any = []
  brandRealType:any
  brandGarments:any
  garmentFilterCloth:any = []
  clothId:any
  logInFlag: boolean;

  fileSelected:File[] = []; // Assuming this array is populated with your file objects
  constructor(public commonService: CommonService,
    private router: Router,
    private firestore: AngularFirestore,
    private apiHttpService: ApiHttpService) {
  }



  ngOnInit() {
    let title = 'Yologram - file-upload'
    this.commonService.changeTitle(title);
    this.logInFlag = this.commonService.isLoggedIn()
    this.brandsCollection.subscribe(
      (brandList) => {
        this.brands = brandList;
      });

    this.garmentsCollection.subscribe((garment) =>{
      this.garments = garment
    })
    this.checkPageAuthorization()
  }

  files: File[] = [];

  toggleHover(event: any) {
    this.isHovering = event;
  }
  onDrop($event: any) {
    this.onDropFiles($event.target.files);
  }

  onDropFiles(filesUploaded: any) {


    this.fileSelected = filesUploaded
    
    this.errorMessage = "";
    if (this.fileType == 'Environment' && filesUploaded.length > 1) {
      this.errorMessage = "Please select single file for environment file type."
      return;
    }
    if (this.fileType == 'Environment' && this.envName == "") {
      this.errorMessage = "Please enter the environment file name."
      return;
    }

    for (let i = 0; i < filesUploaded.length; i++) {
      this.files.push(filesUploaded.item(i));
    }
  }

  selectFileType($target) {
    this.fileType = $target.target.value;
  }

  setEnvName($event) {
    this.envName = $event.target.value;
  }


  selectbrand($event) {
    this.brandsDetails = $event.target.value;
    let brandsValue = this.brands.find((brandDetails) => brandDetails.id == this.brandsDetails)
    this.brandsGender = brandsValue.Gender
  }
  selectGender($event) {
    this.brandGenderDetails = $event.target.value;
    this.brandRealType = ''
  }

  selectRealType($event) {
    this.brandRealType =  $event.target.value;
    this.brandGarments = ''
    this.setbrandsValue() 
  }

  selectGarment($event) {
    this.brandGarments =  $event.target.value;
    this.clothId = this.brandGarments
    this.findClothId()
  }

  setbrandsValue() {
  let brandId = this.brandsDetails
  let gender = this.brandGenderDetails
  let realtype = this.brandRealType
  let garmentsCloth = this.garments.filter((cloths)=>cloths.BrandID  ==  brandId &&  cloths.Gender == gender && cloths.RealType == realtype)
  this.garmentFilterCloth =garmentsCloth
  }

  findClothId(){
    let garmentsClothId = this.garments.filter((cloths)=> cloths.id == this.brandGarments)
  }
  


  setTestOrUser($event) {
    if ($event.target.checked) {
      this.test = "test"
    } else {
      this.test = ""
    }
  }

  checkPageAuthorization() {
    this.loading = true;
    this.commonService.getUser().subscribe((user: any) => {
      this.errorMessage = ""
      this.commonService.getUser().subscribe((user: any) => {
        let userObject2 = { 'userid': user?.uid, 'pagename': '/file-upload' }
        this.commonService.checkViewPageRights(userObject2).subscribe((res) => {
          this.loading = false;
          if (res) {
            this.userFileStateChange.subscribe(async (object) => {
              object.forEach(async (userFilesObject: any) => {
                let tmpUserFilesObject = userFilesObject

                let url = environment.functionBaseUrl + "userProfileIdenifierById?id=" + tmpUserFilesObject['userId'];
                await this.apiHttpService.get(url).subscribe((data: any) => {
                  tmpUserFilesObject['UserProfileName'] = data.displayName != undefined ? data.displayName : tmpUserFilesObject['userId'];
                },
                  ((error) => {
                    console.log(error);
                  }
                  ));
                this.userUploadedAvatars.push(tmpUserFilesObject);
              });
            });
          } else {
            this.router.navigateByUrl("yologram-social/menu-page");
            // this.errorMessage = "You are not authorized to check this page."
          }
        });
      });
    });
  }




}

