import { HttpClient } from '@angular/common/http';
import { Component, Input, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-drips-brand-panel',
  templateUrl: './drips-brand-panel.component.html',
  styleUrls: ['./drips-brand-panel.component.css']
})
export class DripsBrandPanelComponent {
  newBrandObject: any = [];
  branUrl: any;
  userSnapShotList: any = [];
  selectedBrandId: any;
  brandheartFlag: any;

  tab: string = "story";

  @Input()  newselectedBrandId : any
  @Input()  newbrandUrl : any
  @Input()  brandData : any 
  @Input()  brandStoryScrollerHeight : any 
  

  
  pageNumberBrand: number = 1;
  selectedImageIndex: any;



  constructor(  private commonService: CommonService,  private http: HttpClient, public router: Router){

  }

ngOnInit(){
  
  this.loadBrandData( this.newselectedBrandId )
  this.selectedBrandId =  this.newselectedBrandId

}



  ngOnChanges(changes: SimpleChanges) {
          console.log('Brand Data newbrandUrl:', this.newbrandUrl);
    if (changes['brandData']) {
      console.log('Brand Data Updated:', this.brandData);
      // Perform any additional processing if needed
    }
    console.log('brandStoryScrollerHeight',this.brandStoryScrollerHeight)
  }






  async loadBrandData(brand) {
  this.newBrandObject = null
  this.branUrl = null
  this.userSnapShotList = null

 if(this.brandData.length != 0){
  console.log("data updated",this.brandData)
  this.userSnapShotList = this.brandData.object;
  this.newBrandObject = this.brandData.brand
  this.branUrl= this.brandData.brandLogoUrl
  return
 }
  this.brandData = await this.brandPanelDataFunction(brand);
  this.userSnapShotList = this.brandData.object;
  this.newBrandObject = this.brandData.brand
  this.branUrl= this.brandData.brandLogoUrl
  console.log("data by function",this.brandData)
  }


  async brandPanelDataFunction(brandId){
    let functionUrl = "https://us-central1-yolomoves-fb435.cloudfunctions.net/brandPanelDetailswithLogoUrl";
    return this.http.post<any>(functionUrl, { brandId: brandId }).toPromise();
  }

  navigateOnThread() {
  
      this.router.navigate([
        '/threads',
        { brandId: this.selectedBrandId, M: 'S', Ex: 'N' },
      ]);
  

  }

  
  clickOnHeart(event: any) {
    event.stopPropagation();
    this.brandheartFlag = !this.brandheartFlag;
  }

  shareBrand() {
    let garmentObjet = this.newBrandObject;
    let brandId = garmentObjet.BrandID;
    let mode = this.tab === 'womens-wear' ? 'W' : this.tab === 'mens-wear' ? 'M' : 'S';
    let link = "https://yolomove-social-live.web.app/threads;brandId=" + brandId + ';M=' + mode + ';Ex=Y';
    // let text = 'Check out '+ this.brandNameSelected +' on Yologram '
    // console.log(text)
    if (navigator.share) {
      navigator.share({
        title: 'Share Brand',
        text: 'Check out ' + this.newBrandObject.BrandName + ' on Yologram ',
        url: link,
      })
        .then(() => console.log('Successful share'))
        .catch((error) => console.log('Error sharing', error));
    }
  }

  setTab(selectedtab: any, gender) {
    this.tab = selectedtab;

    // this.genderselet = gender

    // if( this.tab=='story'){
    //   this.router.navigate(['/threads', { 'brandId': this.selectedBrandId, 'M': 'S', 'Ex': 'N' }])
    // }
    // let gen = gender == 'F' ? 'W' : gender;
    this.router.navigate([
      '/threads',
      { brandId: this.selectedBrandId, M: gender, Ex: 'N' },
    ]);
  }


  incrementImgCounter() {
    this.pageNumberBrand++;
    //= nextbutton + 1;
    //this.outPageNumber.emit(this.pageNumber);
    let reqInputObject = {
      id: this.selectedBrandId,
      pageNumber: this.pageNumberBrand,
    };
    this.commonService
      .yologramSnapshotByBrands(reqInputObject)
      .subscribe(async (response: any) => {
        // if (this.pagecount > this.userSnapShotList.length) {
        this.userSnapShotList.push(...response.object);
        this.userSnapShotList.forEach(async (snap: any, index) => {
          // let userObjet = await this.allUserDetails.find(
          //   (userObject) => userObject.uid == snap.UserID
          // );
          // snap.profileName =
          //   userObjet.displayName != undefined
          //     ? userObjet.displayName
          //     : snap.UserID;
          // userObjet.profileName = snap.profileName;
          // snap.userData = userObjet;
          // snap.firstName = snap.profileName.split(' ', 1);
          // snap.AvatarLastFive = snap.AvatarID.substr(snap.AvatarID.length - 5);
        });
        // }
      });
  }

  selectImage(index: number, snapObjet) {
    this.selectedImageIndex = index;
    console.log('selectedImageIndex drips===>', this.selectedImageIndex);
    console.log('snapObjet===>', snapObjet);
    this.commonService.setIndex(index);
    let dataToSend = { brandFlag:true,brandTab:"story"}
    this.commonService.setSharedObject(dataToSend);
    let page = this.pageNumberBrand;
    let count = 12
    const currentPage = Math.floor(index / count) + 1;
    this.router.navigate([
      '/threads',
      {
        brandId: this.selectedBrandId,
        M: 'S',
        Ex: 'N',
        storyId: snapObjet.StoryID,
        pageNumber: currentPage,
        panel:'brandPanel',
        'index':index,
      },
    ]);
  }
}
