
import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as JSZip from 'jszip/dist/jszip'
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { Brand } from 'src/app/models/brand';
import { CommonService } from 'src/app/services/common.service';
import { environment } from 'src/environments/environment';
import { ApiHttpService } from 'src/app/services/api-http.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DataSharingService } from 'src/app/services/data-sharing-service';
import { PinterestService } from 'src/app/services/pinterest-service';
import { MatDialog } from '@angular/material/dialog';
import { PopupTwoDComponent } from 'src/app/common/popup-two-d/popup-two-d.component';

@Component({
  selector: 'app-brand-style',
  templateUrl: './brand-style.component.html',
  styleUrls: ['./brand-style.component.css']
})
export class BrandStyleComponent implements OnInit {

  userFiles = this.firestore.collection("userfiles");
  userFileStateChange = this.userFiles.valueChanges();
  brandBoardList = this.firestore.collection('brand-board').valueChanges({ idField: 'id' }) as Observable<any[]>;
  brandsCollection = this.firestore.collection('brands').valueChanges({ idField: 'id' }) as Observable<Brand[]>;
  brandsCollections = this.firestore.collection('brands').valueChanges({ idField: 'id' }) as Observable<Brand[]>;
  displayedColumns: string[] = ['position', 'name', 'weight', 'symbol'];
  public text = `{ "text": "This is text file!中文" }`;
  public fileName: string | undefined;
  //zipFiles: Observable<ZipFile[]>;
  zipFile: any;
  zipList: any = {};
  binaryImg: any;
  testVar: any = 'Prashatn';
  keyvalue: any;
  boardId: any;
  brands: any;
  womensBrands: any;
  mensBrands: any;
  packJsonObject = []
  isShowDiv = false;
  hFiles: any[];
  IdBrands: Brand[];
  brandIDDeatils: any;
  womensBrandId: any;
  womensBrandss: Brand[];
  bName: any;

  selectedGender: any = "";
  selectedBrandId: any = "";
  selectedBrand: any;
  errMessage: any = "Select The Brand ";
  isloading :boolean=true;
  userUploadedAvatars: any;
  loading = false;
  brandId: any
  gender: any
  brandDetails: any
  boardName: any
  userSnapShotList: any = [];
  clothType:any;
  clothId:any
  boards:any=[]
  brandListId:any=[]
  boardsDetails:any
  boardNameDetails:any

  constructor(public commonService: CommonService,
    private router: Router,
    private dataSharingService: DataSharingService,
    private apiHttpService: ApiHttpService,
    private firestore: AngularFirestore,
    public sanitizer: DomSanitizer,
    private route: ActivatedRoute,
    private pinterestAPI:PinterestService,
    public dialog: MatDialog,
    private _httpClient: HttpClient //, //  private _FileSaverService: FileSaverService
  ) {
    this.downloadZipFile();
    this.brandsCollection.subscribe(
      (brandList) => {
        this.brands = brandList;
        this.brandDetails = this.brands.find((brandList) => brandList.id === this.selectedBrandId)
      });
  }
  ngOnInit() {
      this.route.paramMap.subscribe(paramMap => {
       this.isloading = false
       this.boardName = paramMap.get('board')
       this.selectedBrandId = paramMap.get('brandId')
       this.selectedGender = paramMap.get('Gender')
       this.clothType = paramMap.get('clothsType')
       this.clothId = paramMap.get('id')
       this.getPinterestBoards()
       this.downloadZipFile()
     if(this.selectedBrandId == undefined){
      this.errMessage = ''
     }
    })

    this.dataSharingService.userSnapShotObservable.subscribe(snapshot => {
      this.userSnapShotList = snapshot
    });


  this.brandBoardList.subscribe((brandsId)=>{
    this.brandListId = brandsId
    let selectedBrandId =  this.brandListId.find((brandsIdList)=>brandsIdList.brandId == this.selectedBrandId)
    this.boardsDetails = selectedBrandId
  })


  
  }


  getPinterestBoards() {
    this.pinterestAPI.getBoardList().subscribe((data: {
      name: any; items: any;
    }) => {
      this.boards = data.items
      let bordNameForStyle = this.boards.find((boardsName)=> boardsName.id ==  this.boardsDetails.boardId)
      this.boardNameDetails = bordNameForStyle.name
    });

 
    
  }
 
  getStyleForGender(brand: any, gendar: string) {
    this.selectedBrandId = brand.id;
    this.selectedGender = gendar
    this.errMessage = ''
    this.downloadZipFile()
  }
  getStoryForGender(brandIds) {
    let snap = this.userSnapShotList
    this.dataSharingService.sendUserSnapShots(snap)
    let brandid = brandIds.id
    this.router.navigate(['yologram-social/yologram-brand-profile', { 'brandId': brandid, }])
  }

  getStoryForGenderCloth(brandIds) {
    let snap = this.userSnapShotList
    this.dataSharingService.sendUserSnapShots(snap)
    let brandid = brandIds.id
    this.router.navigate(['yologram-social/yologram-garment-profile', { 'brandId': brandid, }])
  }

  createImageFromBlob(image: Blob) {
    let reader = new FileReader();
    reader.addEventListener(
      'load',
      () => {
        this.binaryImg = reader.result;
      },
      false
    );
    if (image) {
      reader.readAsDataURL(image);
    }
  }

  async getZipFileContent(fileName: any) {
    var binary = await this.zipFile
      .file(fileName)
      .async('blob')
      .then(function (text: any) {
        return text;
      });
    this.binaryImg = URL.createObjectURL(binary); // create an url. img.src = URL.
  }

  downloadZipFile() {
    this.isloading = true;
    this.testVar = 'Change';
    let brandname = this.selectedBrand;;
    let brandId = this.selectedBrandId
    let zipfilename = "%2Fpack0"
    let gender = this.selectedGender;
    let packlocation = "AvatarBlenderClothes%2F" + brandId + "_" + gender + zipfilename;
    let brandEncodedurl = packlocation
    let url = 'https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/' + brandEncodedurl + '?alt=media&token=46df48b9-1f7a-4b61-98c6-bff029734da4'
    this._httpClient
      .get(
        url,
        //        'https://firebasestorage.googleapis.com/v0/b/yolouploader.appspot.com/o/AvatarBlenderClothes%2F6XE3yz64QMFyblSyIHpf_M%2FtestforyoloUpload?alt=media&token=0738d062-2c7c-4ca1-8300-5963dba8f93a',
        {
          observe: 'response',
          responseType: 'blob',
        }

      )
      .subscribe(async (res) => {
        await this.processZipFile(res.body, this.processResponse.bind(this));
        this.isloading = false;
      },
        (error) => this.handleError(error)
      );
  }

  handleError(error) {
    this.packJsonObject = [];
    this.isloading = false;
    this.errMessage = "Pack is not found for this selection !!!"
    this.isloading = false;

  }

  processResponse(res: any[]) {this.clothId
    this.packJsonObject = res;
    this.errMessage ="";
  }

  processZipFile(data, callBackSetJsonObject) {
    this.testVar = 'Change 121';
    this.zipList = JSZip.loadAsync(data, callBackSetJsonObject, this.testVar)
      .then(function (zip) {
        /* Parse json file */
        var jsonExtn = /(.json)$/;
        Object.keys(zip.files)
          .filter((fileName) => {
            return jsonExtn.test(fileName.toLowerCase());
          })
          .map((jsonFileName) => {
            var jsonFile = zip.files[jsonFileName];
            return jsonFile.async('string').then((jsonString) => {
              let packJsonObject = JSON.parse(jsonString);

              let jsonResponseData =
                [
                  {
                    title: 'headgear',
                    value:
                    {
                      'files': packJsonObject['hFiles'].filter((data) => data != ''),
                      'name': packJsonObject['hName'].filter((data) => data.indexOf('deleted') < 0)
                    }
                  },
                  {
                    title: 'upperwear',
                    value: {
                      'files': packJsonObject['uFiles'].filter((data) => data != ''),
                      'name': packJsonObject['uName'].filter((data) => data.indexOf('deleted') < 0),

                    }
                  },
                  {
                    title: 'bottomwear',
                    value: {
                      'files': packJsonObject['bFiles'].filter((data) => data != ''),
                      'name': packJsonObject['bName'].filter((data) => data.indexOf('deleted') < 0)
                    }
                  },
                  {
                    title: "footwear",
                    value: {
                      'files': packJsonObject['fFiles'].filter((data) => data != ''),
                      'name': packJsonObject['fName'].filter((data) => data.indexOf('deleted') < 0)
                    },
                  }
                ]
              callBackSetJsonObject(jsonResponseData);

            });

          });
        var re = /(.jpg|.png|.gif|.ps|.jpeg | .json)$/;
        var promises = Object.keys(zip.files)
          .filter(function (fileName) {
            // don't consider non image files
            return re.test(fileName.toLowerCase());
          })
          .map(function (fileName) {
            var file = zip.files[fileName];

            if (fileName.indexOf('.json') > 0) {

            } else {
              return file.async('blob').then(function (blob) {
                return [
                  fileName.substring(0, fileName.lastIndexOf('.')), // keep the link between the file name and the content
                  URL.createObjectURL(blob), // create an url. img.src = URL.createObjectURL(...) will work
                ];
              });
            }
          });
        // `promises` is an array of promises, `Promise.all` transforms it
        // into a promise of arrays
        return Promise.all(promises);
      })
      .then(function (result) {
        // we have here an array of [fileName, url]
        // if you want the same result as imageSrc:
        return result.reduce(function (acc, val) {
          acc[val[0]] = val[1];
          return acc;
        }, {});
      })
      .then((result) => {
        this.testVar = 'Prasahtn Rahulkar';
        this.zipList = JSON.parse(JSON.stringify(result));
        // callback(result);
        return;
        //return result.reduce(...);
      })
      .catch(function (e) {
        console.error(e);
      });

  }

  returnZero() {
    return 0
  }

  getBrandImage(imgURL: string) {
    let brandEncodedurl = encodeURI(imgURL).replace('/', '%2F');
    let url = "https://firebasestorage.googleapis.com/v0/b/yolomoves-fb435.appspot.com/o/" + brandEncodedurl + "?alt=media&token=550e7e88-bed3-4668-84ff-ad491c19e06c"
    return url;
  }


  showBrandPackDetails(brand: any, gendar: string) {
    this.selectedBrandId = brand.id;
    this.selectedGender = gendar
    this.selectedBrand = brand;
    this.errMessage = ""
    this.downloadZipFile()
  }

  // selectBrandForScroll(){
  //   document.querySelector('#target').scrollIntoView({ behavior: 'smooth', scroll: 'left' });
  // }

  selectGender($event) {
    this.selectedGender = $event.target.value
    this.isShowDiv = !this.isShowDiv;
  }
  getBrandStories(brand){
      this.router.navigate(['yologram-social/yologram-brand-stories',{'brandId': brand }])
  }
  infoPopup(){
    // let infoObject = this.imageObject
    // this.brandId =  this.imageObject
    // this.setClothDetails(infoObject)
 }
 
threeDPopup(){
  // let gBinaryImg = this.garmetBinaryImgDetails
  // let gType =  this.garmentTypeDetails
  // this.open2DModelPopUp(gBinaryImg,gType)
}





  open2DModelPopUp(garmetBinaryImg,garmentType:any) {
    let garmentImg = this.sanitizer.bypassSecurityTrustUrl(garmetBinaryImg)
    const dialogRef = this.dialog.open(PopupTwoDComponent,{
      panelClass: 'dailog-transparent-css'
    });
    dialogRef.componentInstance.garmentImg = garmentImg;
    dialogRef.componentInstance.garmentType = garmentType;
    dialogRef.afterClosed().subscribe(result => {
    });
  }
 
}


