<div class="modal-content imp-block-brand">
    <h5 type="button" (click)="closePopUp()" class="btn-close  closBtn" data-bs-dismiss="modal" aria-label="Close"></h5>
    <div class="container m-2 ">
        <div class="text-center m-3">
            <img src="./assets/YLogoTransparent.png" style="width: 60px !important ; height:60px;" alt="Logo" />
            <div class="d-flex justify-content-center">
                <img src="./assets/YOLOgramLogoTextWhite.png" style="
                width: 139px !important;
                height: 35px; margin-top: 2px;" alt="Logo" />
                <p class="text-light-900 mb-2 m-1"><b>Social</b></p></div>
                <button type="button" (click)="loginWithGoogle()"
                class="m-2  band">
                <img class="img-1" src='/assets/favicon-96x96.png' />
                Sign In 
            </button>
        </div>
    </div>
</div>