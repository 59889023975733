<div class="font-14 brand-profile-color-all " (scroll)="scrollHandler()" #stylesnapShotFeeds>
    <div class="m-3 p-2"  *ngIf="tab =='news-stories'" [ngClass]=" tab =='news-stories' ? '' : 'hidden-class' ">
        <app-user-story [userSnapShotsInput]="userSnapShotList" [BrandId]="brandId"
            [thresholdHeightPagination]="thresholdHeightPagination" [StoryIds]="selectedStoryId" 
            [PageCount]="pagecount"
            [pageNumber]="pageNumber"
            (outPageNumber) = "setPageNumber($event)"
            [clickEventFlag]="clickEventFlag"
            [rootFlag]="stylePageFlag"
            [stylePage]="stylePage"
            [routLogInFlag]='routLogInFlag'
            ></app-user-story>
    </div>
    <!-- && tab !='news-stories' -->
    <div *ngIf="tab =='story' " class="story mb-5 brand-profile-color my-story ">
        <div class=" row m-1 p-1 justify-content-center"
            *ngIf="userSnapShotList.length >= 0  && userSnapShotList != undefined && userSnapShotList.length >= 0">
            <div class="column d-block " *ngFor="let snapshot of userSnapShotList; let i=index;">
                <img class="columnImg d-block  shadow-1-strong rounded mb-1 " (click)="scrollToStoryId(snapshot)"
                    [src]="snapshot.SnapshotFileUrl">
            </div>
            <div class="col-12 text-center text-white stify-content-center">
                <h6 *ngIf="this.pagecount > this.userSnapShotList.length" (click)="incrementImgCounter()"><b>More</b></h6>
            </div>
        </div>
    </div>
    <div class="col-lg-12 p-1">
        <span *ngIf="errMessage != '' && tab !='story' &&  tab !='news-stories' && this.brandId != undefined "
            class="danger">
            <div class="alert alert-danger" role="alert">
                {{errMessage}}
            </div>
        </span>
        <div *ngIf="isLoadingStyle" class="d-flex justify-content-center">
            <div class="spinner-border text-white" role="status">
                <span class=""></span>
            </div>
        </div>
    </div>
    <div *ngIf="tab =='mens-wear' &&  isLoadingStyle == false " class="d-flex brand-profile-color my">
        <div class="file " *ngIf="!isLoadingStyle">
            <div class="row form d-block " *ngIf="packJsonObject.length >= 0 && packJsonObject[0] != undefined">
                <div class="slider-css211 ">
                    <div *ngFor="let object of packJsonObject[0].value['files'];let i=index" class="slider-img211"
                        (click)="DeatailsPopup(zipList[object],'bottomwear', object,'HeadwearID' )">
                        <img class="img-responsive1" [src]="sanitizer.bypassSecurityTrustUrl(zipList[object])">
                    </div>
                </div>
                <div class="slider-css2-upper">
                    <div *ngFor="let object of packJsonObject[1].value['files'];let i=index" class="slider-img2-upper"
                       
                        (click)="DeatailsPopup(zipList[object],'bottomwear',object ,'UpperwearID')">
                        <div class="upper-div">
                            <img class="img-responsive-upper"
                                [src]="sanitizer.bypassSecurityTrustUrl(zipList[object])" />
                        </div>
                    </div>
                </div>
                <div class="slider-css2">
                    <div *ngFor="let object of packJsonObject[2].value['files'];let i=index" class="slider-img2"
                       
                        (click)="DeatailsPopup(zipList[object],'bottomwear', object,'BottomwearID' )">
                        <img class="img-responsive" [src]="sanitizer.bypassSecurityTrustUrl(zipList[object])">
                    </div>
                </div>
                <div class="slider-css211">
                    <div *ngFor="let object of packJsonObject[3].value['files'];let i=index" class="slider-img211"
                        (click)="DeatailsPopup(zipList[object],'bottomwear', object,'FootwearID' )">
                        <img class="img-responsive1" [src]="sanitizer.bypassSecurityTrustUrl(zipList[object])">
                    </div>
                </div>
            </div>
        </div>
        <div class="collap-popup p-1" style="min-height: 100px;">
            <div class="collapse collapse-horizontal" >
                <div class="card card-body d-flex imp-block text-center" style="width: 70px;" saria-expanded="false">
                    <h6 (click)="infoPopup()"><i class="bi bi-info-circle">info</i></h6>
                    <h6 (click)="threeDPopup()"><i class="bi bi-badge-3d p-1"></i>3D</h6>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="tab =='womens-wear' &&  isLoadingStyle == false " class="d-flex brand-profile-color my">
        <div class=" file ">
            <div class="row form d-block " *ngIf="packJsonObject.length > 0 && packJsonObject[0].value != undefined">
                <div class=" file" *ngIf="!isLoading ">
                    <div class="row form d-block " *ngIf="packJsonObject.length > 0">
                        <div class="slider-css211 ">
                            <div *ngFor="let object of packJsonObject[0].value['files'];let i=index"
                                class="slider-img211" 
                                (click)="DeatailsPopup(zipList[object],'bottomwear',object, 'HeadwearID' )">
                                <img class="img-responsive1" [src]="sanitizer.bypassSecurityTrustUrl(zipList[object])">
                            </div>
                        </div>
                        <div class="slider-css2-upper">
                            <div *ngFor="let object of packJsonObject[1].value['files'];let i=index"
                                class="slider-img2-upper"
                                (click)="DeatailsPopup(zipList[object],'bottomwear',object,'UpperwearID')">
                                <div class="upper-div">
                                    <img class="img-responsive-upper"
                                        [src]="sanitizer.bypassSecurityTrustUrl(zipList[object])" />
                                </div>
                            </div>
                        </div>
                        <div class="slider-css2">
                            <div *ngFor="let object of packJsonObject[2].value['files'];let i=index" class="slider-img2"
                                (click)="DeatailsPopup(zipList[object],'bottomwear',object ,'BottomwearID' )">
                                <img class="img-responsive" [src]="sanitizer.bypassSecurityTrustUrl(zipList[object])">
                            </div>
                        </div>
                        <div class="slider-css211">
                            <div *ngFor="let object of packJsonObject[3].value['files'];let i=index"
                                class="slider-img211" 
                                (click)="DeatailsPopup(zipList[object],'bottomwear',object,'FootwearID' )">
                                <img class="img-responsive1" [src]="sanitizer.bypassSecurityTrustUrl(zipList[object])">
                            </div>
                        </div>
                        <div *ngIf="isLoading" class="d-flex justify-content-center">
                            <div class="spinner-border" role="status">
                                <span class=""></span>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <!-- <div class="collap-popup p-1" style="min-height: 100px;">
            <div class="collapse collapse-horizontal">
                <div class="card card-body d-flex imp-block text-center" style="width: 70px;" saria-expanded="false">
                    <h6 (click)="infoPopup()"><i class="bi bi-info-circle">info</i></h6>
                    <h6 (click)="threeDPopup()"><i class="bi bi-badge-3d p-1"></i>3D</h6>
                </div>
            </div>
        </div> -->
    </div>
    <div class="modal fade imp-bg" id="Envitonment-profile" tabindex="-1" aria-labelledby="Avatar-profile"
        aria-hidden="true" data-bs-backdrop="false">
        <div class="modal-dialog  ">
            <div class="modal-content imp-block" *ngIf="filterEnvironmentSnpShot">
                <h5 type="button" class="btn-close  closBtn" data-bs-dismiss="modal" aria-label="Close"></h5>
                <div class="container">
                    <div class="row text-center">
                        <div class="row text-center p-2">
                            <div class="col-12 m-3" *ngIf="evnFileListFileName && evnFileListFileName['displayName']">
                                <h2 (click)="getEnvironmentProfile(evnFileListFileName['envFileName'])"
                                    data-bs-dismiss="modal">{{evnFileListFileName['displayName']}}</h2>
                                <div><button type="button" class="btn btn-outline-info m-1 follow"
                                        data-bs-dismiss="modal">Preview</button></div>
                            </div>
                            <div class="row w-100 m-2"
                                *ngIf="filterEnvironmentSnpShot.length >= 0  && filterEnvironmentSnpShot != undefined && filterEnvironmentSnpShot.length >= 0 ">
                                <div class="column d-block m-1"
                                    *ngFor="let snapshot of filterEnvironmentSnpShot  ; let i=index;">
                                    <img class="w-100 shadow-1-strong rounded" data-bs-dismiss="modal"
                                        (click)="getFeed(snapshot,brandDetails)" [src]="snapshot.SnapshotFileUrl">
                                </div>
                                <div class="columnImg d-block m-1">
                                    <img class="w-80 shadow-1-strong rounded" data-bs-dismiss="modal">
                                </div>
                                <div class="columnImg d-block m-1">
                                    <img class="w-80 shadow-1-strong rounded" data-bs-dismiss="modal">
                                </div>
                                <div class="columnImg d-block m-1" *ngIf="filterEnvironmentSnpShot.length > 8"
                                    (click)="getEnvironmentProfile(evnFileListFileName['envFileName'])">
                                    <img style="margin-top: -165px;" class="w-80 shadow-1-strong rounded"
                                        data-bs-dismiss="modal" src="./assets/plus-8-512.png">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
