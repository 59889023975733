<!-- <div class="d-div" *ngIf="showDisplayText != '' && (!flag_v2 || flag_v2 == undefined )" -->
<div class="d-div" *ngIf="showDisplayText != '' && (!flag_v2 || flag_v2 == undefined )"
    [ngClass]="this.BlobUrl != undefined  ? 'd-div'  : 'd-div-blurr'">
    <img *ngIf="this.BlobUrl" class="img-size"
        [src]="this.BlobUrl ? sanitizer.bypassSecurityTrustUrl(this.BlobUrl) : ''" alt="">
</div>

<!-- *ngIf="showDisplayText != '' && flag_v2 "   [ngClass]="this.BlobUrl == undefined  ? 'epand-d-div-blurr'  : 'expand-d-div'" -->
<div *ngIf="showDisplayText != '' && flag_v2" [ngClass]="showDisplayText != ''  ? 'epand-d-div-blurr'  : 'expand-d-div'" >
    <img *ngIf="this.BlobUrl"  [style.width.px]="expandImgWidth" class="expand-img-size"
        [src]="this.BlobUrl ? sanitizer.bypassSecurityTrustUrl(this.BlobUrl) : ''" alt="">
</div>


<div>
    <div #threeDModelContainer></div>
</div>

<div class="d-flex justify-content-center m-2" *ngIf="showDisplayText != ''">
    <div class="spinner-border text-white top-scroll p-2 " role="status">
    </div>
</div>

<div class="evnLoading text-white " *ngIf="showDisplayText != '' ">
    {{showDisplayText}}
</div>




<!-- *ngIf="showDisplayText != ''" -->