<!-- <div *ngIf="!loading"  class="brand-profile-color">
  <div class="slider-css ">
     <div class="slider-css">
         <div class="" *ngFor="let brand of brandDetailsAll" (click)="selectBrandForScroll()">
            <img class="m-1" style="height:60px !important; width:80px !important;"    [ngClass]="selectedBrandType == brand.id ? 'shadow-brand' : (selectedBrandType != undefined ? 'blug-css' :'')"
            (click)="selectBrandForStory(brand.id,brands)"
            [src]="getBrandImage(brand.LogoURL)" />
             <div *ngIf="snapShotBrandList.indexOf(brand.id) >= 0">
             </div>
         </div>
     </div>
     <div *ngIf="loading" class="loading">Loading&#8230;</div>
  </div>
  <div class="card brand-profile-color" *ngIf="selectedBrand != undefined">
     <div class="col-lg-12  justify-content-center text-center">
         <div class="container">
             <div class="col-lg-12 d-block justify-content-center">
                 <h3 class="color-class">{{selectedBrand.BrandName}}</h3>
                 <button type="button" class="btn btn-sm btn-outline-info color-class">Follow</button>
                 <h6 class="color-class" *ngIf="boardDetails != undefined "><img style="width:15px !important"
                         src="./assets/pinterest-512.jpg">:{{boardDetails.name}}
                 </h6>
                 <h6 class="color-class" *ngIf="boardDetails == undefined "><img style="width:15px !important"
                         src="./assets/pinterest-512.jpg">:Not Linked To board
                 </h6>
             </div>
         </div>
     </div>
     <div class="justify-content-center text-center d-flex set-margin" >
         <h6 class="text-primary brandbtn m-1 color-class" (click)="setTab('story')"  [ngClass]="selectedTab === 'story' ? 'border-bottom border-dark' : ''">Story</h6>
         <h6 class="text-primary brandbtn m-1 color-class" 
            (click)="setTab('mens-wear')"
            [ngClass]="selectedTab === 'mens-wear' ? 'border-bottom border-dark' : ''">Menswear</h6>
         <h6 class="text-primary brandbtn m-1 color-class"
         (click)="setTab('womens-wear')"
         [ngClass]="selectedTab === 'womens-wear' ? 'border-bottom border-dark' : ''">Womenswear</h6>
     </div>
     <div class="row m-2" *ngIf="userSnapShotList.length >= 0 ">
         <div class="column d-block" *ngFor="let snapshot of userSnapShotList ; let i=index;">
             <img class="w-90 shadow-1-strong rounded mb-1" 
                 [src]="snapshot.SnapshotFileUrl">
         </div>
     </div>
  </div>
</div>
<app-zip-style class="card brand-profile-color"  *ngIf="brandIds != undefined " id="target"
 [brandGarments]="brandGarmentIds"
 [tab]="selectedTab"
 [onChangeFlag] = "onChangeFlag"
 (changeSelectedTab)="changeTab($event)"
 [brandsIDdetailsAll] ="brandsIDdetailsAll"  
 [selectBrandGender] ="genderID"
 [loading]="isloading"
 [brandId]="brandId"
 [rootFlag]="rootForNAvigation"
 >
</app-zip-style> -->


<app-style  [rootFlag]="rootForNAvigation" [stylePage]="StyleFlag" 
[routLogInFlag]="routLogInFlag"
 ></app-style>